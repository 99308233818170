import { css } from 'styled-components';

export const elevationShadowValues = {
  subtle: '0px 1px 2px rgba(0, 0, 0, 0.3), 0px 2px 6px 2px rgba(0, 0, 0, 0.15)',
  moderate:
    '0px 2px 3px rgba(0, 0, 0, 0.3), 0px 6px 10px 4px rgba(0, 0, 0, 0.15)',
  strong:
    '0px 4px 4px rgba(0, 0, 0, 0.3), 0px 8px 12px 6px rgba(0, 0, 0, 0.15)',
};

export const elevation = {
  subtle: css`
    box-shadow: ${elevationShadowValues.subtle};
  `,
  moderate: css`
    box-shadow: ${elevationShadowValues.moderate};
  `,
  strong: css`
    box-shadow: ${elevationShadowValues.strong};
  `,
};
