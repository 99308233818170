import {
  AssetClassName,
  AssetClassNames,
  AssetSubClassName,
  AssetSubClassNames,
} from '~/api/entities/entity-constants';
import { isEntityFromUS } from '~/shared/utils/entity-from-usa/isEntityFromUS';
import { isInsightFromUS } from '~/shared/utils/insight-from-usa/isInsightFromUS';

export enum MODAL_TYPES {
  DEFAULT = 'DEFAULT',
  DEFAULT_PRO = 'DEFAULT_PRO',
  MARKET_INDICATORS = 'MARKET_INDICATORS',
  STARS_6_TO_8 = 'STARS_6_TO_8',
  CRYPTO = 'CRYPTO',
  EXPLORE_FILTERS = 'EXPLORE_FILTERS',
  SAVED_FILTERS = 'SAVED_FILTERS',
  BOOKMARKS = 'BOOKMARKS',
  BEYOND_STOCKS = 'BEYOND_STOCKS',
  AI_NEWS = 'AI_NEWS',
  GEOGRAPHY_OUTSIDE_US = 'GEOGRAPHY_OUTSIDE_US',
  WATCHLIST_LIMIT = 'WATCHLIST_LIMIT',
  CHART_PAGE = 'CHART_PAGE',
  ARTICLE_LIMIT = 'ARTICLE_LIMIT',
}

const featureSubClasses = new Set<AssetSubClassNames>([
  AssetSubClassName.FutureBonds,
  AssetSubClassName.FutureInterestRate,
  AssetSubClassName.FutureCurrency,
  AssetSubClassName.FutureEquity,
  AssetSubClassName.FutureElectricity,
  AssetSubClassName.FutureEnergy,
  AssetSubClassName.FutureFood,
  AssetSubClassName.FutureGrains,
  AssetSubClassName.FutureSofts,
  AssetSubClassName.FutureMetals,
]);

const featureSubClassesLegacy = new Set([
  'future_bonds',
  'future_interest_rate',
  'future_currency',
  'future_equity',
  'future_electricity',
  'future_energy',
  'future_food',
  'future_grains',
  'future_softs',
  'future_metals',
]);

interface GetModalTypeByClassProps {
  asset_class: AssetClassNames | '';
  sub_class: AssetSubClassNames | '';
  stars?: number;
  isBasic?: boolean;
  eco?: string;
  country?: { long: string };
}

const ARTICLE_STARS_LIMIT = 5;

export const getModalType = ({
  asset_class,
  sub_class,
  stars = 0,
  isBasic = false,
  eco,
  country,
}: GetModalTypeByClassProps) => {
  if (asset_class === AssetClassName.Fi) {
    return MODAL_TYPES.BEYOND_STOCKS;
  }

  if (asset_class === AssetClassName.Commodity) {
    return MODAL_TYPES.BEYOND_STOCKS;
  }

  if (sub_class) {
    if (featureSubClasses.has(sub_class)) {
      return MODAL_TYPES.BEYOND_STOCKS;
    }

    if (featureSubClassesLegacy.has(sub_class)) {
      return MODAL_TYPES.BEYOND_STOCKS;
    }

    if (sub_class === AssetSubClassName.FxCrypto) {
      return MODAL_TYPES.CRYPTO;
    }
  }

  if (isBasic) {
    if (stars > ARTICLE_STARS_LIMIT) {
      return MODAL_TYPES.STARS_6_TO_8;
    }

    if (eco && !isInsightFromUS({ eco })) {
      return MODAL_TYPES.GEOGRAPHY_OUTSIDE_US;
    }

    if (country && !isEntityFromUS({ country })) {
      return MODAL_TYPES.GEOGRAPHY_OUTSIDE_US;
    }
  }

  return MODAL_TYPES.DEFAULT;
};
