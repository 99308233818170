import { ChartAssetData, PaneData } from '@toggle/chart';
import { Entity, SnakeMeta } from '@toggle/toggle';
import { Dispatch, SetStateAction } from 'react';
import { v4 } from 'uuid';

import { fetchSnakeByName } from '~/shared/services/overview-widget/overview-widget-service';
import { Tracking } from '~/shared/services/tracking/sentry';

import {
  createChartDataItem,
  createTsDataFromSnake,
  getLineColor,
} from '../../utils/chart-utils/chart-utils';

export interface UseChartPaneProps {
  setAssetWithError: Dispatch<SetStateAction<Entity | undefined>>;
  setHasError: Dispatch<SetStateAction<boolean>>;
  handleEmptyChart: (bannerLabel: string) => void;
  chartData: ChartAssetData[];
  chartPanes: PaneData[];
  setChartPanes: Dispatch<SetStateAction<PaneData[]>>;
}

export type UseChartPaneReturn = ReturnType<typeof useChartPane>;

export const useChartPane = ({
  setAssetWithError,
  setHasError,
  handleEmptyChart,
  chartData,
  chartPanes,
  setChartPanes,
}: UseChartPaneProps) => {
  const addIndicatorPane = async (indicator: SnakeMeta, asset: Entity) => {
    try {
      const snakeData = await fetchSnakeByName(indicator.snake);
      if (snakeData instanceof Error) {
        throw snakeData;
      }

      const prices = createTsDataFromSnake(snakeData.result.data);

      if (!prices?.length) {
        setAssetWithError(asset);
        handleEmptyChart(asset.name);
        return;
      }

      const newLineColor = getLineColor(chartData);

      const newChartData = createChartDataItem({
        entity: asset,
        ts: prices,
        lineColor: newLineColor,
        primaryAsset: chartData[0],
        snakeMeta: indicator,
      });

      setChartPanes([
        ...chartPanes,
        {
          id: v4(),
          chartAssetData: [newChartData],
          seriesType: 'line',
          priceDisplay: 'price',
          yAxisType: 'merged',
        },
      ]);
    } catch (error) {
      Tracking.captureException(error as Error);
      setHasError(true);
    }
  };

  const removePane = (id: string) => {
    setChartPanes(chartPanes.filter(p => p.id !== id));
  };

  const movePane = (direction: 'up' | 'down', paneIndex: number) => {
    const nextChartPanes = [...chartPanes];
    const newIndex = direction === 'up' ? paneIndex - 1 : paneIndex + 1;

    [nextChartPanes[newIndex], nextChartPanes[paneIndex]] = [
      nextChartPanes[paneIndex],
      nextChartPanes[newIndex],
    ];

    setChartPanes(nextChartPanes);
  };

  const updatePaneField =
    (
      field: Extract<keyof PaneData, 'collapsed' | 'maximized'>,
      paneId: string
    ) =>
    (pane: PaneData) =>
      pane.id === paneId ? { ...pane, [field]: !pane[field] } : pane;

  const collapsePane = (id: string) => {
    const nextChartPanes = chartPanes.map(updatePaneField('collapsed', id));
    setChartPanes(nextChartPanes);
  };

  const maximizePane = (id: string) => {
    const nextChartPanes = chartPanes.map(updatePaneField('maximized', id));
    setChartPanes(nextChartPanes);
  };

  return {
    removePane,
    addIndicatorPane,
    maximizePane,
    collapsePane,
    movePane,
  };
};
