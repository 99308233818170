import React, { createRef } from 'react';
import { useTranslation } from 'react-i18next';

import { ANALYZE_SECTION_IDS } from '~/views/helper/HelperConfig';

import {
  HelperWidgetAccordion,
  HelperWidgetSection,
} from '../../partials/accordion/HelperWidgetAccordion';
import { HelperContentProps } from '../content';
import { ArrowsContent } from './ArrowsContent';
import { AssetContent } from './AssetContent';
import { AssetOverviewIntro } from './AssetOverviewIntro';
import { KeyValuesContent } from './KeyValuesContent';
import { NewsContent } from './NewsContent';
import { RelatedAssetsContent } from './RelatedAssetsContent';
import { WhatIsMoving } from './WhatIsMoving';

const analyzeSectionsRefs = {
  [ANALYZE_SECTION_IDS.ANALYZE_ASSET_CHECK_ID]: createRef<HTMLDivElement>(),
  [ANALYZE_SECTION_IDS.ANALYZE_ARROWS_CHECK_ID]: createRef<HTMLDivElement>(),
  [ANALYZE_SECTION_IDS.ANALYZE_KEYS_VALUES_CHECK_ID]:
    createRef<HTMLDivElement>(),
  [ANALYZE_SECTION_IDS.ANALYZE_NEWS_CHECK_ID]: createRef<HTMLDivElement>(),
  [ANALYZE_SECTION_IDS.ANALYZE_RELATED_ASSETS_CHECK_ID]:
    createRef<HTMLDivElement>(),
  [ANALYZE_SECTION_IDS.ANALYZE_WHAT_IS_MOVING_CHECK_ID]:
    createRef<HTMLDivElement>(),
};

export const AnalyzeHelperContent = (props: HelperContentProps) => {
  const { t } = useTranslation('helperWidget');

  const sections: HelperWidgetSection[] = [
    {
      id: ANALYZE_SECTION_IDS.ANALYZE_INTRO_ID,
      title: t('helperWidget:analyze.content.sectionsTitles.intro'),
      gaTitle: 'Asset Overview Intro',
      content: activateSection => (
        <AssetOverviewIntro
          onVideoClick={() =>
            activateSection(ANALYZE_SECTION_IDS.ANALYZE_INTRO_ID)
          }
          t={t}
        />
      ),
      separateSection: true,
      hideMarker: true,
    },
    {
      id: ANALYZE_SECTION_IDS.ANALYZE_MASTERCLASS_ID,
      title: t('helperWidget:analyze.content.sectionsTitles.masterAsset'),
      gaTitle: 'Asset Overview Masterclass',
      hideMarker: true,
    },
    {
      id: ANALYZE_SECTION_IDS.ANALYZE_ASSET_CHECK_ID,
      title: t('helperWidget:analyze.content.sectionsTitles.asset'),
      gaTitle: 'Asset',
      content: <AssetContent />,
      handleMouse: true,
    },
    {
      id: ANALYZE_SECTION_IDS.ANALYZE_ARROWS_CHECK_ID,
      title: t('helperWidget:analyze.content.sectionsTitles.arrows'),
      gaTitle: 'Arrows & Icons',
      content: <ArrowsContent />,
      handleMouse: true,
    },
    {
      id: ANALYZE_SECTION_IDS.ANALYZE_WHAT_IS_MOVING_CHECK_ID,
      title: t('helperWidget:analyze.content.sectionsTitles.whatIsMoving'),
      gaTitle: "What's moving the asset",
      content: <WhatIsMoving />,
      handleMouse: true,
    },
    {
      id: ANALYZE_SECTION_IDS.ANALYZE_KEYS_VALUES_CHECK_ID,
      title: t('helperWidget:analyze.content.sectionsTitles.keyValues'),
      gaTitle: 'Key Values',
      content: <KeyValuesContent />,
      handleMouse: true,
    },
    {
      id: ANALYZE_SECTION_IDS.ANALYZE_NEWS_CHECK_ID,
      title: t('helperWidget:analyze.content.sectionsTitles.news'),
      gaTitle: 'News',
      content: <NewsContent />,
      handleMouse: true,
    },
    {
      id: ANALYZE_SECTION_IDS.ANALYZE_RELATED_ASSETS_CHECK_ID,
      title: t('helperWidget:analyze.content.sectionsTitles.relatedAssets'),
      gaTitle: 'Related Assets',
      content: <RelatedAssetsContent />,
      handleMouse: true,
    },
  ];

  return (
    <HelperWidgetAccordion
      sections={sections}
      sectionRefs={analyzeSectionsRefs}
      sendGaOnExpand
      {...props}
    />
  );
};
