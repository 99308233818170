import { typographyV2 } from '@toggle/design-system';
import styled from 'styled-components';

import SomethingWentWrong from '../../something-went-wrong.svg';

const gradientStart = 'rgba(26, 29, 30, 1)';
const gradientStop = 'rgba(26, 29, 30, 0.3)';

export const ChartEmptyRoot = styled.div`
  position: absolute;
  z-index: 1;
  inset: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 300px;
  margin: 0 auto;
  background: linear-gradient(
    180deg,
    ${gradientStart} 0%,
    ${gradientStop} 100%
  );
`;

export const EmptyImage = styled(SomethingWentWrong)`
  width: 70px;
  height: 70px;
`;

export const Title = styled.div`
  ${typographyV2.titleBase}
  color: var(--text-default);
`;

export const Description = styled.div`
  ${typographyV2.bodySm}
  color: var(--text-soft);
  margin-bottom: 16px;
`;

export const Link = styled.a`
  color: var(--text-link);
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;
