// Cannot use createStore function as the order of resetting the store matters.
// See useAuth logout function for more info
import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';

import { AuthData, getRefreshToken } from '~/api/auth/auth-services';
import { UserRole } from '~/api/subscription/subscription-schema';
import { Tracking } from '~/shared/services/tracking';

import { useUser } from '../../use-user/useUser';

interface AuthStore {
  token?: string;
  email?: string;
  expiry?: string;
  source?: number;
  subscription?: UserRole;
  user_id?: string;
  user_role?: string;
  authProccessing: boolean;
  destroy: () => void;
  update: (data: AuthData) => void;
  refreshToken: () => Promise<void>;
  setAuthProccessing: (authProcessing: boolean) => void;
}

export const useAuthStore = createWithEqualityFn<AuthStore>(
  set => ({
    token: undefined,
    email: undefined,
    expiry: undefined,
    source: undefined,
    subscription: undefined,
    user_id: undefined,
    user_role: undefined,
    authProccessing: true,
    destroy: () =>
      set({
        token: undefined,
        email: undefined,
        expiry: undefined,
        source: undefined,
        subscription: undefined,
        user_id: undefined,
        user_role: undefined,
      }),
    update: data => set(data),
    refreshToken: async () => {
      try {
        const session = await getRefreshToken();
        if (session instanceof Error) {
          throw session;
        }
        set(session);
        await useUser.getState().initialize();
      } catch (error) {
        Tracking.captureException(error as Error);
      }
    },
    setAuthProccessing: (authProccessing: boolean) => set({ authProccessing }),
  }),
  shallow
);
