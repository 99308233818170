import { TFunction } from 'i18next';
import React, { FC } from 'react';

import { getPortfolioName } from '~/shared/utils/portfolio-name/getPortfolioName';
import { Portfolio } from '~/stores/use-portfolio/usePortfolio';

import { SelectPortfolioItem } from '../select-portfolio-item/SelectPortfolioItem';
import * as S from './SelectTradePortfolio.styles';

interface SelectTradePortfolioProps {
  tradingPortfolios: Portfolio[];
  t: TFunction;
  isMobile: boolean;
  onBackToSearch: () => void;
  onPortfolioClick: (portfolio: Portfolio) => void;
}

export const SelectTradePortfolio: FC<SelectTradePortfolioProps> = ({
  t,
  tradingPortfolios,
  isMobile,
  onBackToSearch,
  onPortfolioClick,
}) => (
  <S.SelectTradeWrapper isMobile={isMobile}>
    <S.SelectPortfolioText>
      {t('header:search.selectPortfolio')}
    </S.SelectPortfolioText>
    {tradingPortfolios.map(p => (
      <SelectPortfolioItem
        key={p.id}
        name={getPortfolioName(p)}
        logo={p.provider.logo}
        altText={t('header:logo.altImage')}
        positionsCount={
          p.position_count
            ? t('portfolio:positions', {
                count: p.position_count,
              })
            : ' '
        }
        onClick={() => onPortfolioClick(p)}
      />
    ))}
    <S.BackToSearchText onClick={onBackToSearch}>
      {t('header:search.backToSearch')}
    </S.BackToSearchText>
  </S.SelectTradeWrapper>
);
