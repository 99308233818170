import { TFunction } from 'i18next';
import chunk from 'lodash/chunk';
import React, { FC } from 'react';

import { ArticleV3 } from '~/declarations/toggle-api-news';
import { useMediaQuery } from '~/shared/hooks/MediaQueryHook';
import { MediaQueries } from '~/shared/hooks/MediaQueryList';

import { CollapsePanel } from '../../collapse-panel';
import * as S from './AllEpisodeDatesPanel.styles';

interface AllEpisodeDatesPanelProps {
  data: ArticleV3;
  className?: string;
  t: TFunction;
}

function getAllEpisodeDatesTables(
  listOfEpisodeDates: string[],
  isMobileOrTablet: boolean
) {
  if (!listOfEpisodeDates || listOfEpisodeDates.length === 0) {
    return null;
  }
  const tablesCount = isMobileOrTablet ? 2 : 3;
  const tableChunkSize = Math.ceil(listOfEpisodeDates.length / tablesCount);
  const formattedData = listOfEpisodeDates.reduce((acc, cur, i) => {
    acc.push(`${i + 1}`);
    acc.push(cur);
    return acc;
  }, [] as string[]);
  const tablesRowData = chunk(formattedData, 2);
  const tablesData = chunk(tablesRowData, tableChunkSize);

  return tablesData.map((rows, i) => (
    <S.StyledTable
      key={i}
      rows={[['#', 'Episode date'], ...rows]}
      columnWidths={['25%', '75%']}
      headerType="horizontal"
      zebraType="columns"
      zebraOrder="odd"
    />
  ));
}

export const AllEpisodeDatesPanel: FC<AllEpisodeDatesPanelProps> = ({
  data,
  className,
  t,
}) => {
  const currentMedia = useMediaQuery(s => s.currentMedia);
  const isMobileOrTablet = [
    MediaQueries.SMALL_MOBILE,
    MediaQueries.MOBILE,
    MediaQueries.TABLET,
  ].includes(currentMedia);

  const { list_of_episode_dates, num_trading_days, num_episodes } = data;

  const allEpisodeDatesTable = getAllEpisodeDatesTables(
    list_of_episode_dates,
    isMobileOrTablet
  );

  return (
    <CollapsePanel
      collapsedText={t(
        'article:whyItMightMove.fundamentals.viewAllEpisodeDates'
      )}
      defaultText={t('article:whyItMightMove.fundamentals.hideAllEpisodeDates')}
      className={className}
      isThemeButtonStyle={false}
      isDisabledBtn={false}
    >
      <S.TablesWrapper>{allEpisodeDatesTable}</S.TablesWrapper>
      <S.Note>
        {t('article:whyItMightMove.fundamentals.episodeDatesNote', {
          episodes: num_episodes,
          tradingDays: num_trading_days,
        })}
      </S.Note>
    </CollapsePanel>
  );
};
