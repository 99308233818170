import { deviceConstraints, Icon } from '@toggle/design-system';
import styled, { css, keyframes } from 'styled-components';

import { TooltipContainer } from '~/shared/components/tooltip-container/TooltipContainer';

const pulse = keyframes`
  0% {
    transform: scale(0.6);
    opacity: 0.5;
  }
  50% {
    opacity: 0.25;
  }
  75% {
    opacity: 0.1;
  }
  100% {
    transform: scale(1);
    opacity: 0.04;
  }
`;

export const StyledTooltip = styled(TooltipContainer)`
  white-space: nowrap;
`;

export const SecondaryMenu = styled.section`
  display: flex;
  gap: 8px;
`;

export const SupportIconContainer = styled.div`
  display: flex;
  height: 32px;
  width: 32px;
  align-items: center;
  justify-content: center;
  @media ${deviceConstraints.tablet} {
    position: relative;
  }

  &:hover {
    background-color: var(--base--80);
    border-radius: 50%;
    cursor: pointer;
  }
`;

export const SupportIconWrapper = styled.div<{ $notMasteredHover: boolean }>`
  position: relative;
  margin: auto 14px;
  & > * {
    vertical-align: middle;
  }
  ${({ $notMasteredHover }) =>
    $notMasteredHover &&
    css`
      &:after {
        content: '';
        position: absolute;
        top: -25%;
        left: -25%;
        z-index: -1;
        width: 150%;
        height: 150%;
        border-radius: 50%;
        animation: ${pulse} 2s linear infinite;
        pointer-events: none;
        background-color: var(--primary-color);
      }
    `}
`;

export const MyAccountIconContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
`;

export const MyAccountIcon = styled(Icon)`
  grid-area: 1 / 1 / 2 / 2;
  background-color: var(--base--80);
  border-radius: 50%;
  cursor: pointer;
`;

export const MyAccountIconNotification = styled.div`
  grid-area: 1 / 1 / 2 / 2;
  justify-self: flex-end;
  background-color: var(--primary-color);
  border-radius: 50%;
  height: 8px;
  width: 8px;
`;
