import { Button } from '@toggle/design-system';
import styled from 'styled-components';

export const Name = styled.p`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const ActionButton = styled(Button)<{ $isActive?: boolean }>`
  justify-self: flex-end;
  color: ${props =>
    props.$isActive ? 'var(--text-success)' : 'var(--text-default)'};
  svg {
    fill: ${props =>
      props.$isActive ? 'var(--icon-success)' : 'var(--icon-default)'};
  }
`;
