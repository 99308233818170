import { z } from 'zod';

export const conditionSchema = z.object({
  entity: z.string(),
  snake: z.string().optional(),
  condition: z.string(),
  order: z.number().optional(),
  value: z.number().optional(),
  duration: z.number().optional(),
  duration_unit: z.string().optional(),
  min_value: z.number().optional(),
  max_value: z.number().optional(),
});

export const scenarioEntitySchema = z.object({
  tag: z.string(),
  name: z.string(),
  snake: z.string(),
  ticker: z.string(),
  exchange: z.string(),
});

export const scenarioCommanderSchema = z.object({
  portfolio_name: z.string().optional(),
  conditions: z.array(conditionSchema),
  entities: z.array(scenarioEntitySchema),
});

export type ScenarioEntity = z.infer<typeof scenarioEntitySchema>;
export type ScenarioCommanderResponse = z.infer<typeof scenarioCommanderSchema>;

const period = z.union([
  z.literal('1w'),
  z.literal('2w'),
  z.literal('1m'),
  z.literal('3m'),
  z.literal('6m'),
]);

const pastEpisode = z.object({
  data: z.array(z.number()),
  date: z.string(),
});

const episodes = z.array(
  z.object({
    median: z.number(),
    period,
    dates: z.array(pastEpisode).optional(),
  })
);

export const dominoScenarioSchema = z.record(
  z
    .object({
      episodes,
    })
    .nullable()
);

export type Period = z.infer<typeof period>;
export type PastEpisode = z.infer<typeof pastEpisode>;
export type Episodes = z.infer<typeof episodes>;
export type DominoScenarioResponse = z.infer<typeof dominoScenarioSchema>;
export type Condition = z.infer<typeof conditionSchema>;

const cognitiveSearchDocumentsSchema = z.object({
  id: z.string().optional(),
  title: z.string().optional(),
  date: z.string().optional(),
  link: z.string().optional(),
});

const cognitiveSearchMetadataSchema = z.object({
  documents: z.array(cognitiveSearchDocumentsSchema).optional(),
});

export type CognitiveSearch = z.infer<typeof cognitiveSearchSchema>;
export const cognitiveSearchSchema = z.object({
  request_id: z.string().optional(),
  summary: z.string().optional(),
  metadata: cognitiveSearchMetadataSchema.optional(),
});
