import { format } from 'date-fns';
import utcToZonedTime from 'date-fns-tz/utcToZonedTime';
import React, { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import Logo from '~/assets/core/toggle-copilot-logo.svg';
import WebMaintenance from '~/assets/core/web-maintenance.svg';
import { useSystemFlags } from '~/stores/use-system-flags/useSystemFlags';

import * as S from './Maintenance.styles';

export const Maintenance: FC = () => {
  const { t } = useTranslation('common');
  const { maintenance } = useSystemFlags(state => state.systemFlags);

  const maintenanceEndDate = () => {
    if (maintenance) {
      const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const endDate = utcToZonedTime(new Date(maintenance.to), currentTimezone);
      return format(endDate, 'h:mmaa');
    } else {
      return undefined;
    }
  };

  return (
    <S.MaintenanceRoot data-testid="maintenance-mode">
      <S.Logo>
        <Logo />
      </S.Logo>
      <S.Tagline>
        <Trans
          t={t}
          i18nKey="common:maintenance.tagline"
          components={[<S.TaglineAlt key="" />]}
        />
      </S.Tagline>
      <S.Description>
        <S.TextContent>
          <Trans
            t={t}
            i18nKey="common:maintenance.textContent"
            components={[<span key="" />]}
            values={{ endDate: maintenanceEndDate() }}
          />
        </S.TextContent>
        <S.Image>
          <WebMaintenance />
        </S.Image>
      </S.Description>
    </S.MaintenanceRoot>
  );
};
