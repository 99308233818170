import { CreateChartOptions } from '~/types/create.types';

export function createCanvasContext(
  canvasElement: HTMLCanvasElement,
  options: CreateChartOptions,
  contextOptions?: CanvasRenderingContext2DSettings
): CanvasRenderingContext2D | null {
  const context = canvasElement.getContext('2d', contextOptions);
  if (context === null) {
    return null;
  }
  const scale = window.devicePixelRatio;
  canvasElement.width = Math.floor(options.width * scale);
  canvasElement.height = Math.floor(options.height * scale);
  context.scale(scale, scale);
  context.font = options.font.mono;

  return context;
}
