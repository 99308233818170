import React, { Fragment } from 'react';

const escapeRegExp = (string: string) => {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
};

export const highlight = (text: string, highlighted: string) => {
  const parts = text.split(new RegExp(`(${escapeRegExp(highlighted)})`, 'gi'));
  return parts.map((part, i) => (
    <Fragment key={i}>
      {part.toUpperCase() === highlighted.toUpperCase() ? (
        <mark data-testid="highlighted">{part}</mark>
      ) : (
        part
      )}
    </Fragment>
  ));
};
