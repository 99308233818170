import { Entity } from '@toggle/toggle';
import { useEffect, useState } from 'react';

import {
  CHART_WIDGET_RECENTLY_OPENED_ENTITIES,
  useRemoteStorage,
} from '~/shared/hooks/use-remote-storage';

import { isEntity } from '../../utils/entity-utils/entity-utils';

export const useRecentList = () => {
  const [recentList, setRecentList] = useState<Entity[]>([]);

  const { recentlyOpenedEntities, storeItems } = useRemoteStorage(
    ({ items, storeItems }) => ({
      recentlyOpenedEntities:
        (items?.[CHART_WIDGET_RECENTLY_OPENED_ENTITIES] as Entity[]) || [],
      storeItems,
    })
  );

  useEffect(() => {
    if (recentlyOpenedEntities) {
      const recentlyOpened = recentlyOpenedEntities.filter(isEntity);
      setRecentList(recentlyOpened);
    }
  }, [recentlyOpenedEntities.length]);

  const addAssetToRecent = async (entity: Entity) => {
    const filteredRecent = recentList.filter(
      item => item.default_snake !== entity.default_snake
    );
    const nextRecentList = [entity, ...filteredRecent.slice(0, 4)];

    setRecentList(nextRecentList);

    await storeItems({
      [CHART_WIDGET_RECENTLY_OPENED_ENTITIES]: nextRecentList,
    });
  };

  return {
    addAssetToRecent,
    recentList,
  };
};
