import React, {
  ButtonHTMLAttributes,
  forwardRef,
  ForwardRefExoticComponent,
  MouseEvent,
  RefAttributes,
} from 'react';

import { SvgIconNames } from '~/design-tokens/iconography/SvgIcons';

import * as S from './Chip.styles';

export type ChipType = 'input' | 'choice' | 'filter';
export type ChipVariation = 'outline' | 'solid';

export interface ChipProps
  extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'type'> {
  label?: string;
  isActive?: boolean;
  isFocused?: boolean;
  type?: ChipType;
  variation?: ChipVariation;
  leftIcon?: SvgIconNames;
  rightIcon?: SvgIconNames;
  leftIconSize?: number;
  leftIconViewBox?: string;
  rightIconSize?: number;
  assetLogo?: React.ReactNode;
  onClick?: (e: MouseEvent) => void;
  onLeftIconClick?: () => void;
  onRightIconClick?: (e: MouseEvent) => void;
}

export const Chip = forwardRef<HTMLButtonElement, ChipProps>(function Chip(
  {
    label,
    isActive = false,
    isFocused = false,
    disabled = false,
    type = 'input',
    variation = 'solid',
    leftIconSize = 16,
    leftIconViewBox,
    rightIconSize = 16,
    leftIcon,
    rightIcon,
    assetLogo,
    onLeftIconClick,
    onRightIconClick,
    onClick,
    ...rest
  },
  ref
) {
  return (
    <S.Container
      ref={ref}
      data-testid="chip"
      aria-selected={isActive}
      isActive={isActive}
      isFocused={isFocused}
      disabled={disabled}
      $type={type}
      variation={variation}
      onClick={onClick}
      {...rest}
    >
      {leftIcon && (
        <S.IconContainer>
          <S.LeftIcon
            size={leftIconSize}
            iconName={leftIcon}
            viewBox={leftIconViewBox}
            onClick={onLeftIconClick}
          />
        </S.IconContainer>
      )}

      {assetLogo}
      {label && <span>{label}</span>}
      {rightIcon && type !== 'filter' && (
        <S.IconContainer>
          <S.RightIcon
            size={rightIconSize}
            iconName={rightIcon}
            onClick={onRightIconClick}
          />
        </S.IconContainer>
      )}
      {type === 'filter' && (
        <S.IconContainer>
          <S.CloseIcon onClick={onRightIconClick} />
        </S.IconContainer>
      )}
    </S.Container>
  );
}) as ForwardRefExoticComponent<
  ChipProps & RefAttributes<HTMLButtonElement>
> & { Styled: typeof S };

Chip.Styled = S;
