import styled from 'styled-components';

import { borderRadius } from '~/design-tokens/border-radius/borderRadius';

export const ScrollingIndicator = styled.div`
  position: absolute;
  z-index: 1;
  top: -16px;
  left: 50%;
  transform: translate(-50%, -50%);
  display: inline-flex;
  padding: 12px;
  ${borderRadius.rFull};
  cursor: pointer;
  background: color-mix(in srgb, currentColor 50%, transparent);

  &:hover {
    background-color: var(--surface-neutral);
  }
`;

export const ShadedArea = styled.div`
  position: sticky;
  height: 90px;
  bottom: 0px;
  background: linear-gradient(
    180deg,
    #090909 0%,
    rgba(9, 9, 9, 0) 0.01%,
    rgba(9, 9, 9, 0.5) 32.29%,
    rgba(9, 9, 9, 0.8) 100%
  );
`;
