import styled from 'styled-components';

export const LinePlotPath = styled.path`
  fill: none;
  stroke: currentColor;
`;

export const Circle = styled.circle`
  fill: currentColor;
`;
