import { typeToFlattenedError, ZodIssueCode } from 'zod';

export class SchemaValidationError extends Error {
  constructor(
    endpoint: string,
    public schemaError: typeToFlattenedError<
      any,
      {
        message: string;
        errorCode?: ZodIssueCode;
        path?: (string | number)[];
      }
    >
  ) {
    super(endpoint);
    this.name = 'SchemaValidationError';
  }
}
