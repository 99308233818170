import { MappedDomino } from '@toggle/design-system';

import {
  DominoScenarioResponse,
  Period,
  ScenarioCommanderResponse,
  ScenarioEntity,
} from '~/api/scenario-commander/scenario-commander-schema';

import { spx } from '../../store/store-helpers';

const setLabel = (tag: string, entities: ScenarioEntity[]): string => {
  if (tag === spx.tag) {
    return spx.name;
  }
  const entity = entities.find(e => e.tag === tag);
  if (entity) {
    return `${entity.ticker} ${entity.exchange}`.toUpperCase();
  }
  return tag;
};

export const mapDomino = (
  domino: DominoScenarioResponse,
  entities: ScenarioEntity[]
): Record<Period, MappedDomino[]> => {
  return Object.entries(domino).reduce((accumulator, [tag, domino]) => {
    if (domino) {
      domino.episodes.forEach(episode => {
        const period = accumulator[episode.period] || [];
        const median = episode.median;
        const label = setLabel(tag, entities);
        period.push({ tag, label, median });
        accumulator[episode.period] = period;
      });
    }
    return accumulator;
  }, {} as Record<Period, MappedDomino[]>);
};

export const isWatchlistScenario = (scenario: ScenarioCommanderResponse) =>
  !scenario.portfolio_name && scenario.entities.length > 1;
