import { typographyV2 } from '@toggle/design-system';
import styled, { css } from 'styled-components';

export const LegendPriceRoot = styled.div`
  display: flex;
  align-items: center;
`;

export const PriceContainer = styled.div<{
  $color: string;
  $isPrimary: boolean;
}>`
  margin-left: 8px;
  display: flex;
  align-items: center;
  font-family: var(--font-roboto-mono);
  color: ${props => props.$color};
  gap: 8px;
  ${({ $isPrimary }) =>
    $isPrimary
      ? css`
          ${typographyV2.bodySm}
        `
      : css`
          ${typographyV2.bodyXs}
        `}
`;
