import ReactGA from 'react-ga4';

const CATEGORIES = {
  chart: 'Chart',
};
export { CATEGORIES as CHART_CATEGORIES };

const ACTIONS = {
  chartInsightToggleClick: 'Click_insights_toggle',
  chartSetCondition: 'Set_condition',
  hoverOnInsight: 'Hover_on_insight',
  clickOnInsight: 'Click_on_insight',
  changeHorizon: 'Horizon_change',
  chartZoomIn: 'Zoom_in',
  chartZoomOut: 'Zoom_out',
  chartMoveLeft: 'Chart_move_left',
  chartMoveRight: 'Chart_move_right',
  clickOnReadFullInsight: 'Read_full_insight',
  clickOnChartHelp: 'Help',
  clickOnChartUndoLast: 'Undo_last',
  searchChartFromGlobalSearch: 'Search_chart_from_global_search',
};
export { ACTIONS as CHART_ACTIONS };

export function gaChartToggleInsights(showInsights: boolean) {
  ReactGA.event({
    category: CATEGORIES.chart,
    action: ACTIONS.chartInsightToggleClick,
    label: `showInsights:${showInsights}`,
  });
}

export function gaChartChangeCondition(seriesName: string) {
  ReactGA.event({
    category: CATEGORIES.chart,
    action: ACTIONS.chartSetCondition,
    label: `seriesName:${seriesName}`,
  });
}

export function gaChartInsightAction(
  direction: string,
  insightId: string,
  action: 'click' | 'hover'
) {
  ReactGA.event({
    category: CATEGORIES.chart,
    action:
      action === 'click' ? ACTIONS.clickOnInsight : ACTIONS.hoverOnInsight,
    label: `direction:${direction};insightId:${insightId}`,
  });
}

export function gaChartChangeHorizon(horizon: string) {
  ReactGA.event({
    category: CATEGORIES.chart,
    action: ACTIONS.changeHorizon,
    label: `horizon:${horizon}`,
  });
}

export function gaChartZoom(zoomedIn: boolean) {
  ReactGA.event({
    category: CATEGORIES.chart,
    action: zoomedIn ? ACTIONS.chartZoomIn : ACTIONS.chartZoomOut,
  });
}

export function gaChartMove(movedLeft: boolean) {
  ReactGA.event({
    category: CATEGORIES.chart,
    action: movedLeft ? ACTIONS.chartMoveLeft : ACTIONS.chartMoveRight,
  });
}

export function gaChartClickReadFullInsight(
  articleId: string,
  defaultMethod: string
) {
  ReactGA.event({
    category: CATEGORIES.chart,
    action: ACTIONS.clickOnReadFullInsight,
    label: `articleId:${articleId};defaultMethod:${defaultMethod}`,
  });
}

export function gaChartClickHelpButton() {
  ReactGA.event({
    category: CATEGORIES.chart,
    action: ACTIONS.clickOnChartHelp,
  });
}

export function gaChartUndoLast() {
  ReactGA.event({
    category: CATEGORIES.chart,
    action: ACTIONS.clickOnChartUndoLast,
  });
}

export function gaChartButtonClickInGlobalSearch(tag: string, snake: string) {
  ReactGA.event({
    category: CATEGORIES.chart,
    action: ACTIONS.searchChartFromGlobalSearch,
    label: `tag:${tag};snake:${snake}`,
  });
}
