export const countFractionDigits = (number: number) => {
  const numStr = number.toString();

  const decimalIndex = numStr.indexOf('.');

  if (decimalIndex === -1) {
    return 0;
  }

  return numStr.length - decimalIndex - 1;
};

export const roundToPrecision = (value: number, precision: number): number => {
  if (precision < 1) {
    let fractionDigits = countFractionDigits(precision);
    return +value.toFixed(fractionDigits);
  }
  return +value.toFixed(precision);
};
