import { formatPriceWithCurrency } from '@toggle/helpers';

import { AssetSubClassCode } from '~/api/entities/entity-constants';
import { PortfolioSettings } from '~/declarations/toggle-api-portfolio';
import { formatPriceWithCurrencyCode } from '~/shared/utils/currency/currency';

import { SinglePosition } from '../portfolio-statements/portfolioStatements';

export interface StatementsSummary {
  value: string;
}

interface GetPortfolioTotalProps {
  portfolioSettings: PortfolioSettings;
  mappedPositions: SinglePosition[];
}

export const getStatementsSummary = ({
  portfolioSettings,
  mappedPositions,
}: GetPortfolioTotalProps): StatementsSummary => {
  const reportingCurrency = portfolioSettings.reportingCurrency;
  const currentReportedCurrency = reportingCurrency?.currency ?? 'USD';

  const portfolioTotalValue = mappedPositions.reduce(
    (totalValue, statement) => totalValue + statement.amount_reporting,
    0
  );

  return {
    value:
      reportingCurrency?.sub_class === AssetSubClassCode.FxCrypto
        ? formatPriceWithCurrencyCode(
            portfolioTotalValue,
            currentReportedCurrency,
            true
          )
        : formatPriceWithCurrency(portfolioTotalValue, currentReportedCurrency),
  };
};
