import { borderRadius, typographyV2 } from '@toggle/design-system';
import styled, { css } from 'styled-components';

export const IndicatorItem = styled.button<{ $isFocused?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  ${borderRadius.r1}
  width: 100%;
  padding: 8px;
  cursor: pointer;
  border: none;
  background: none;

  &:hover {
    background-color: var(--surface-hover);
  }

  ${props =>
    props.$isFocused &&
    css`
      outline: 2px solid var(--border-focus);
      ${borderRadius.r1};
    `}
`;

export const IndicatorText = styled.p`
  ${typographyV2.bodySm};
  text-align: left;
  color: var(--text-default);
`;

export const Secondary = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4px;
  max-width: 200px;
`;

export const IndicatorSmallText = styled.p`
  ${typographyV2.bodyXs};
  text-align: right;
  color: var(--text-soft);
  text-overflow: ellipsis;
  text-align: right;
  overflow: hidden;
  white-space: nowrap;
`;
