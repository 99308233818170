import {
  RangeHorizon,
  RangeTypes,
  WORKING_DAYS_IN_RANGE,
} from '@toggle/helpers';
import { StoreApi } from 'zustand';

import { OptionalU } from '~/declarations/standard';
import { MappedEntity } from '~/shared/hooks/use-entities';
import { gaScenarioChangeHorizon } from '~/shared/utils/ganalytics';
import { create } from '~/stores/create-store/createStore';

import { JointInsightGtkData } from '../asset-overview/components/sidebar/components/rocketship-tab/helpers/RocketshipTabHelpers';
import { ConditionsPayload } from '../experiment/components/condition-builder/utils/conditionHelpers';
import { fetchScenarioConditions } from '../services/experiment-service/experimentService';
import { compassSlice } from './slices/compass/compassSlice';
import { jointInsightGtkSlice } from './slices/jointInsightGtk/jointInsightGtkSlice';
import { templateConditionsSlice } from './slices/template-condition/templateConditionsSlice';
import {
  CompassData,
  ConditionListItem,
  ExperimentStoreState,
  FetchExperimentByScenarioIdPayload,
  LoadCompassPayload,
} from './useExperimentStore.d';
import { CompassFormat } from './useExperimentStore.enums';

export * from './useExperimentStore.d';

const getDomainByRange = (
  compassData: CompassData,
  range: string
): [number, number] => {
  const ts = compassData.prediction;
  const dateRangeType = range[range.length - 1].toLowerCase() as RangeTypes;
  const count = Number(range.replace(/(W|M)$/, ''));
  const endIndex = Math.min(
    ts.length,
    WORKING_DAYS_IN_RANGE[dateRangeType] * count
  );
  return [0, endIndex - 1];
};

const updateDomain = (
  set: StoreApi<ExperimentStoreState>['setState'],
  get: StoreApi<ExperimentStoreState>['getState']
) => {
  const {
    compass: { compassData },
    dateRange,
  } = get();
  set({
    domain: compassData && getDomainByRange(compassData, dateRange),
  });
};

const hoverEpisodesGroupSlice = (
  set: StoreApi<ExperimentStoreState>['setState']
): Pick<ExperimentStoreState, 'hoverEpisodes'> => ({
  hoverEpisodes: {
    hoverEpisodesGroup: undefined,
    setHoverEpisodes: (groupDate?: string) => {
      set(state => ({
        hoverEpisodes: {
          ...state.hoverEpisodes,
          hoverEpisodesGroup: groupDate,
        },
      }));
    },
  },
});

export const useExperimentStore = create<ExperimentStoreState>((set, get) => ({
  dateRange: RangeHorizon.ThreeMonths,
  bestDateRange: undefined,
  dateRanges: [],
  scenarioConditions: [],
  domain: undefined,
  updateDateRange: (range: RangeHorizon) => {
    set({
      dateRange: range,
    });
    updateDomain(set, get);
    gaScenarioChangeHorizon(range);
  },
  setScenarioConditions: (scenarioConditions: ConditionListItem[]) => {
    set({ scenarioConditions });
  },
  loadGtkCompass: async (gtk: JointInsightGtkData, entity: MappedEntity) => {
    const condition: OptionalU<ConditionsPayload[]> =
      gtk.scenarioCondition?.map(v => ({
        ...v,
        entity: entity.tag,
      }));
    get().loadCompass({
      entityTag: entity.tag,
      snake: entity.default_snake,
      scenarioConditions: condition,
      date: gtk.lastDate?.split('T')[0],
    });
  },
  loadCompass: async (compassPayload: LoadCompassPayload) => {
    const { getData } = get().compass;
    await getData(
      compassPayload.entityTag,
      compassPayload.snake,
      compassPayload.scenarioConditions,
      compassPayload.date
    );
    const { compassData: data } = get().compass;
    if (data?.episodes.length) {
      const dateRanges = data.episodes.map(({ period }) =>
        period.toUpperCase()
      );
      const bestDateRange = data.episodes
        .find(range => range.is_best)
        ?.period.toUpperCase();
      set({
        dateRanges,
        dateRange: bestDateRange || dateRanges[dateRanges.length - 2],
        bestDateRange,
      });
      updateDomain(set, get);
    }
  },
  fetchExperimentDataByScenarioId: async (
    scenarioEntity: FetchExperimentByScenarioIdPayload
  ) => {
    set(state => ({
      compass: {
        ...state.compass,
        loading: true,
        error: undefined,
      },
    }));
    try {
      const scenario = await fetchScenarioConditions(scenarioEntity.scenarioId);
      if (scenario instanceof Error) {
        throw scenario;
      }

      get().loadCompass({
        entityTag: scenarioEntity.entityTag,
        snake: scenarioEntity.snake,
        scenarioConditions: scenario.conditions,
        date: scenarioEntity.date,
      });
    } catch (error) {
      if (error instanceof Error) {
        set(state => ({
          compass: { ...state.compass, error: error as Error, loading: false },
        }));
      }
    }
  },

  resetData: () =>
    set(state => ({
      compass: {
        ...state.compass,
        compassData: undefined,
        empty: false,
        error: undefined,
      },
      dateRanges: [],
      scenarioConditions: [],
    })),
  formatValue: (value: number, fractionDigits = 2) => {
    const compassData = get().compass.compassData;

    const defaultFormatOptions = {
      minimumFractionDigits: fractionDigits,
      maximumFractionDigits: fractionDigits,
    };

    switch (compassData?.format) {
      case CompassFormat.ABSOLUTE:
      case CompassFormat.PERCENT:
        return (
          (value * 100).toLocaleString('en-US', defaultFormatOptions) +
          compassData?.format
        );
      default:
        return value.toLocaleString('en-US', defaultFormatOptions);
    }
  },
  ...hoverEpisodesGroupSlice(set),
  compass: compassSlice(
    data =>
      set(state => ({
        compass: {
          ...state.compass,
          ...(data instanceof Function ? data(state.compass) : data),
        },
      })),
    get
  ),
  templateConditions: templateConditionsSlice(data =>
    set(state => ({
      templateConditions: {
        ...state.templateConditions,
        ...(data instanceof Function ? data(state.templateConditions) : data),
      },
    }))
  ),
  jointInsightsGtk: jointInsightGtkSlice(data =>
    set(state => ({
      jointInsightsGtk: {
        ...state.jointInsightsGtk,
        ...(data instanceof Function ? data(state.jointInsightsGtk) : data),
      },
    }))
  ),
}));
