import styled from 'styled-components';

import { TableCell, TableHeader } from '../table';
import { AddColumnTableHeader } from './table-columns-editor/TableColumnsEditor.styles';

export const ActionTableCell = styled(TableCell)`
  > div {
    visibility: hidden;
  }
`;

export const TableRow = styled.tr`
  &:hover {
    cursor: ${props => props.onClick && 'pointer'};

    ${ActionTableCell} {
      > div {
        visibility: visible;
      }
    }
  }
`;

export const StickyTableHeader = styled(TableHeader)<{
  $displayBorder?: boolean;
}>`
  position: sticky;
  left: 0;
  z-index: calc(var(--z-index-smarttable-sticky-header-cell) + 1);
  ${({ $displayBorder }) =>
    $displayBorder &&
    `
      border-right: 1px solid var(--border-default);
    `}
`;

export const Table = styled.table<{
  $withBodyRowHover: boolean;
  $isHeaderSticky: boolean;
}>`
  border-spacing: 0;
  width: 100%;

  tr {
    background-color: var(--background-default);
    td {
      background-color: var(--background-default);
    }
  }

  ${props =>
    props.$withBodyRowHover &&
    `
      tbody tr:hover {
        background-color: var(--surface-hover);
        td {
          background-color: var(--surface-hover);
        }
      }
    `}

  ${props =>
    props.$isHeaderSticky &&
    `th { 
      position: sticky;
      top: 0;
      z-index: var(--z-index-smarttable-sticky-header-cell);
     }
     ${AddColumnTableHeader} { 
        z-index: calc(var(--z-index-smarttable-sticky-header-cell) + 2);
      }
      ${StickyTableHeader} {
        z-index: calc(var(--z-index-smarttable-sticky-header-cell) + 1);
      }
     `}
`;

export const FixedTableCell = `
  &::before {
    position: absolute;
    top: 0;
    right: -1px;
    bottom: 0;
    width: 15px;
    transform: translate(100%);
    transition: box-shadow 0.3s;
    content: '';
    pointer-events: none;
  }
  position: sticky;
  left: 0px;
  z-index: 1;
  border-right: 1px solid var(--border-default);
  background-color: var(--background-soft);
`;
