import { deviceConstraints, typography } from '@toggle/design-system';
import styled from 'styled-components';

import LetterIcon from '~/assets/icons/letter.svg';

import * as AuthSharedStyles from '../auth-shared-styles/AuthSharedStyles.styles';

export const Main = styled(AuthSharedStyles.Main)`
  @media ${deviceConstraints.smartphone} {
    padding: 14px;
  }
`;

export const Form = styled.form`
  margin-top: 38px;
  border-radius: 4px;
`;

export const EmailSentWrapper = styled(AuthSharedStyles.WrapperWithBg)`
  @media ${deviceConstraints.smartphone} {
    padding: 38px 12px;
  }
`;

export const ForgotPassWrapper = styled(AuthSharedStyles.WrapperWithBg)`
  @media ${deviceConstraints.smartphone} {
    background: transparent;
    padding: 0;
  }
`;

export const Description = styled.p`
  ${typography.emu};
  margin-bottom: 20px;
`;

export const SuccessText = styled.p`
  margin-bottom: 32px;
  ${typography.emu};
`;

export const LetterSvg = styled(LetterIcon)`
  margin: 0px 0 28px 0;
`;
