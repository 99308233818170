import {
  Accordion as DefaultAccordion,
  Input as SharedInput,
} from '@toggle/design-system';
import styled from 'styled-components';

export const GlossarySearch = styled.div`
  padding: 16px 24px 0 24px;
`;

export const Input = styled(SharedInput)`
  ${SharedInput.Styles.InputWrapper} {
    padding: 0;
    height: 40px;
  }
`;

export const Accordion = styled(DefaultAccordion)`
  ${DefaultAccordion.Styled.TitleContainer} {
    justify-content: space-between;
    padding: 8px;
    text-decoration: none !important;
    font-weight: 500;
    line-height: 1.41;
    color: inherit;
    font-size: 16px;

    &:hover {
      background-color: #ffffff0a;
    }
  }

  ${DefaultAccordion.Styled.IconStyled} {
    width: auto;
    transform: rotate(0deg);
  }

  ${DefaultAccordion.Styled.Content} {
    padding: 8px;
  }

  ${DefaultAccordion.Styled.AccordionItem} {
    border-radius: 0;
    font-size: 16px;
    color: var(--base--0);
    border-bottom: 1px solid var(--base--80);
    margin-bottom: 8px;

    &:hover {
      border-bottom-color: var(--primary-color);
      background-color: transparent;
    }
  }

  .glossaryLetter {
    border-bottom: none !important;
    margin-bottom: 16px;

    & > p {
      flex-direction: row;
      padding: 0;
      font-size: 18px;

      &:hover {
        background-color: transparent;
      }
    }
  }

  .emptyGlossaryLetter {
    & > p {
      color: var(--base--40);
    }
  }

  .glossaryItem + .glossaryLetter {
    margin-top: 40px;
  }

  .accordion-expanded {
    background-color: var(--base--90);

    ${DefaultAccordion.Styled.IconStyled} {
      transform: rotate(180deg);
    }
  }
`;
