import { typography } from '@toggle/design-system';
import styled from 'styled-components';

interface ContainerProps {
  isMobile: boolean;
  showOutOfSyncPortfolios: boolean;
  isAllOutOfSync: boolean;
}

export const Container = styled.div<ContainerProps>`
  position: absolute;
  inset: ${props => props.showOutOfSyncPortfolios && 0};
  top: ${props => (props.isMobile || props.isAllOutOfSync ? '42px' : '81px')};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--base--100);
`;

export const Text = styled.p`
  ${typography.dingo};
  margin-bottom: 12px;
  max-width: 360px;
  text-align: center;
`;

export const Banner = styled.div`
  width: 100%;
  padding: 4px 42px;
  background-color: var(--warning--default);
  color: var(--base--black);
  ${typography.emu};
`;

interface OutOfSyncContainerProps {
  size: 'sm' | 'md';
  isAllOutOfSync: boolean;
}

export const OutOfSyncContainer = styled.div<OutOfSyncContainerProps>`
  display: flex;
  flex-direction: column;
  flex-grow: ${props => !props.isAllOutOfSync && 1};
  justify-content: center;
  width: ${props => (props.size === 'md' ? '300px' : '200px')};
`;

export const OutOfSyncPortfolio = styled.div`
  display: flex;
  padding: 8px 12px;
  border: 1px solid var(--base--80);
  border-radius: 8px;
  margin-bottom: 8px;
  width: 100%;

  &:last-child {
    margin-bottom: 0px;
  }

  & > span {
    ${typography.emu};
  }
`;

export const BtnsContainer = styled.div`
  margin-top: 20px;
  display: flex;
  gap: 8px;

  > button {
    width: 100%;
    flex: 1 1 0;
  }
`;
