import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';
// Consider changing this for native URLSearchParams
import { parse as parseQuery, ParsedQuery } from 'query-string';

export function createQueryObjectFromString(query: string): ParsedQuery {
  let queryObj: ParsedQuery;

  if (query) {
    const queryToParse = query.indexOf('?') === 0 ? query.substring(1) : query;
    queryObj = parseQuery(queryToParse);

    //Assume unassigned query properties default to boolean true
    for (const param in queryObj) {
      if (queryObj[param] === '') {
        queryObj[param] = 'true';
      }
    }
  } else {
    queryObj = {};
  }

  return queryObj;
}

export function escapeBackslashQuery(query: string): string {
  return query.replace(/\\/g, '\\\\');
}

export const queryString = (values: { [key: string]: unknown }) => {
  const cleanValues = omitBy(values, isNil);
  const params = new URLSearchParams(
    cleanValues as Record<string, string>
  ).toString();

  return params ? `?${params}` : '';
};

const lodashGet = <T>(object: Record<string, any>, path: string[] | string) => {
  let next = object;
  const keys = Array.isArray(path) ? path : path.split('.');
  keys.forEach(key => {
    next = next[key];
  });
  return next as T;
};

export const compareObjectKeys = (
  object1: Record<string, any>,
  object2: Record<string, any>,
  keys: string[]
) => keys.every(key => lodashGet(object1, key) === lodashGet(object2, key));
