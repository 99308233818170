import React, { Fragment } from 'react';

import { Message } from '../../store/useScenarioCommander';
import { CogSearchBreakdown } from '../cog-search-breakdown/CogSearchBreakdown';
import { DominoWidget } from '../domino-widget/DominoWidget';
import { LoadingBars } from '../loading-bars/LoadingBars';
import { PastEpisodes } from '../past-episodes/PastEpisodes';
import * as S from './Messages.styles';

export interface MessagesProps {
  messages: Message[];
}

export const Messages = ({ messages }: MessagesProps) => {
  const isLatestMessage = (i: number) => i + 1 >= messages.length;
  return (
    <Fragment>
      {messages.map((message, i) => {
        const { id, response, question } = message;

        return (
          <S.MessageGroup key={id}>
            <S.Message tabIndex={0} $isActive={false} $isUserMessage>
              <S.UserMessageText data-testid="question">
                {question}
              </S.UserMessageText>
            </S.Message>

            <S.Message
              data-testid="answer"
              tabIndex={0}
              $isActive
              $isUserMessage
            >
              {response?.data ? (
                <>
                  {response.type === 'CognitiveSearch' && (
                    <CogSearchBreakdown data={response.data} />
                  )}
                  {response.type === 'DominoScenario' && (
                    <DominoWidget
                      data={response.data}
                      scenario={response.scenario}
                      showPastEpisodesButton={isLatestMessage(i)}
                      occurrences={response.occurrences}
                    />
                  )}

                  {response.type === 'PastEpisodes' && (
                    <PastEpisodes
                      domino={response.data}
                      scenario={response.scenario}
                      condition={response.condition}
                    />
                  )}
                </>
              ) : (
                <LoadingBars />
              )}
            </S.Message>
          </S.MessageGroup>
        );
      })}
    </Fragment>
  );
};
