import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { ConditionLiveBadge } from '~/views/analyze/experiment/components/condition-live-badge/ConditionLiveBadge';
import { HelperContentProps } from '~/views/helper/helper-widget/content/content';
import {
  HelperWidgetAccordion,
  HelperWidgetSection,
} from '~/views/helper/helper-widget/partials/accordion/HelperWidgetAccordion';
import { SCENARIO_SECTION_IDS } from '~/views/helper/HelperConfig';

import * as S from '../Content.styles';

export const ScenarioHelperContent = (props: HelperContentProps) => {
  const { t } = useTranslation('helperWidget');

  const sections: HelperWidgetSection[] = [
    {
      id: SCENARIO_SECTION_IDS.MASTERCLASS_ID,
      title: t('helperWidget:scenario.content.sectionsTitles.masterAsset'),
      gaTitle: 'Scenario Masterclass',
      hideMarker: true,
    },
    {
      id: SCENARIO_SECTION_IDS.SELECT_ASSET_ID,
      title: t('helperWidget:scenario.content.sectionsTitles.selectAsset'),
      gaTitle: 'Select your asset',
      content: (
        <S.Content data-testid="asset-content">
          <S.Paragraph>
            <Trans
              t={t}
              i18nKey="helperWidget:scenario.content.selectAssetContent.content"
            />
          </S.Paragraph>
        </S.Content>
      ),
    },
    {
      id: SCENARIO_SECTION_IDS.ADD_CONDITIONS_ID,
      title: t('helperWidget:scenario.content.sectionsTitles.addConditions'),
      gaTitle: 'Add conditions',
      content: (
        <S.Content data-testid="asset-content">
          <S.Paragraph>
            <Trans
              t={t}
              i18nKey="helperWidget:scenario.content.addConditionsContent.content"
            />
          </S.Paragraph>
        </S.Content>
      ),
    },
    {
      id: SCENARIO_SECTION_IDS.QUALITY_CHECK_ID,
      title: t('helperWidget:scenario.content.sectionsTitles.qualityCheck'),
      gaTitle: 'Test Quality Check',
      content: (
        <S.Content data-testid="asset-content">
          <S.Paragraph>
            <Trans
              t={t}
              i18nKey="helperWidget:scenario.content.qualityCheckContent.content"
            />
          </S.Paragraph>
        </S.Content>
      ),
    },
    {
      id: SCENARIO_SECTION_IDS.RESULTS_ID,
      title: t('helperWidget:scenario.content.sectionsTitles.results'),
      gaTitle: 'Results',
      content: (
        <S.Content data-testid="asset-content">
          <S.Paragraph>
            <Trans
              t={t}
              i18nKey="helperWidget:scenario.content.resultsContent.content"
            />
          </S.Paragraph>
        </S.Content>
      ),
    },
    {
      id: SCENARIO_SECTION_IDS.PAST_EPISODES_ID,
      title: t('helperWidget:scenario.content.sectionsTitles.pastEpisodes'),
      gaTitle: 'Past Episodes',
      content: (
        <S.Content data-testid="asset-content">
          <S.Paragraph>
            <Trans
              t={t}
              i18nKey="helperWidget:scenario.content.pastEpisodesContent.content"
              components={[<ConditionLiveBadge key="" withTooltip={false} />]}
            />
          </S.Paragraph>
        </S.Content>
      ),
    },
  ];

  return (
    <HelperWidgetAccordion sections={sections} sendGaOnExpand {...props} />
  );
};
