import { ButtonGroupProps } from '@toggle/design-system';
import React, { FC, useRef } from 'react';

import { UseTurboChartReturn } from '../../hooks/use-turbo-chart/useTurboChart';
import { GutterSize } from '../chart-area-overlay/ChartAreaOverlay';
import * as S from './PriceDisplayGroup.styles';

export interface PriceDisplayGroupProps {
  gutterSize: GutterSize;
  labels: ButtonGroupProps['labels'];
  activeIndex: ButtonGroupProps['activeIndex'];
  changeYAxisSettings: UseTurboChartReturn['changeYAxisSettings'];
  chartSettings: UseTurboChartReturn['chartSettings'];
}

export const PriceDisplayGroup: FC<PriceDisplayGroupProps> = ({
  gutterSize,
  labels,
  changeYAxisSettings,
  activeIndex,
  chartSettings,
}) => {
  const previousYAxisType = useRef(chartSettings.yAxisType);

  const onClick = (index: number) => {
    if (index === activeIndex) {
      return;
    }

    const priceDisplay = index === 0 ? 'price' : 'percentage';
    changeYAxisSettings({
      priceDisplay,
      type:
        priceDisplay === 'percentage' ? 'merged' : previousYAxisType.current,
    });
    previousYAxisType.current = chartSettings.yAxisType;
  };

  return (
    <S.Container style={{ right: gutterSize.y + 8 }}>
      <S.StyledButtonGroup
        data-testid="price-display-button-group"
        labels={labels}
        onClick={onClick}
        activeIndex={activeIndex}
      />
    </S.Container>
  );
};
