import { format } from 'date-fns';

import { ArticleSummary, ExploreFeedResponse } from '~/declarations/toggle-api';
import { ENDPOINT_URLS } from '~/global/toggle-api';
import { getArticleDisplayDate } from '~/views/analyze/utils/insights-helpers';

import { APIFetch } from '../api-fetch';

const API_DATE_FORMAT = 'yyyyMMdd';
const PAGE_LIMIT = 20;

interface FetchFeedProps {
  entityTag: string;
  startDate: Date;
  endDate: Date;
  abort?: AbortSignal;
  pageLimit?: number;
}

const fetchSinglePage = async ({
  entityTag,
  fromDate,
  abort,
  endDate,
}: {
  entityTag: string;
  fromDate: Date;
  endDate: Date;
  abort?: AbortSignal;
}) => {
  const startFormatDate = format(fromDate, API_DATE_FORMAT);
  const endFormatDate = format(endDate, API_DATE_FORMAT);
  const endPoint = `${ENDPOINT_URLS.ARTICLE_EXPLORE}?inc_gtk=true&entity=${entityTag}&limit=${PAGE_LIMIT}&from_date=${startFormatDate}&to_date=${endFormatDate}`;

  const articles = await APIFetch<ExploreFeedResponse>(endPoint, {
    signal: abort,
  });

  if (articles instanceof Error) {
    throw new Error(`Failed to get Articles from ${endPoint}`);
  }

  return {
    minDate: articles.result.length
      ? getArticleDisplayDate(articles.result[articles.result.length - 1])
      : null,
    articles,
  };
};

export const fetchFeed = async ({
  entityTag,
  startDate,
  endDate,
  abort,
  pageLimit = PAGE_LIMIT,
}: FetchFeedProps): Promise<ExploreFeedResponse> => {
  const resArticles: ArticleSummary[] = [];
  let count = 0,
    date = endDate,
    prevDate;

  while (date > startDate && !abort?.aborted) {
    const { minDate, articles } = await fetchSinglePage({
      entityTag,
      fromDate: startDate,
      endDate: date,
      abort,
    });
    const isSameData =
      !!minDate && !!prevDate && prevDate.getTime() === minDate.getTime();

    if (!isSameData) {
      resArticles.push(...articles.result);
      count += articles.meta.count;
    }
    if (articles.result.length < pageLimit || !minDate || isSameData) {
      break;
    }

    prevDate = date = minDate;
  }

  return {
    result: resArticles,
    meta: {
      count,
    },
  };
};
