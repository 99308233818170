import {
  amber,
  amberDark,
  blueDark,
  crimsonDark,
  goldDark,
  grassDark,
  slateDark,
  tealDark,
  tomatoDark,
  violetDark,
  yellowDark,
} from '@radix-ui/colors';

import { hslToHex } from '../../utils/color-conversion/color-conversion';

const background = {
  '--background-default': '#090909',
  '--background-soft': '#101010',
  '--background-backdrop': 'rgba(0, 0, 0, .7)',
  '--blue-dark': blueDark.blue3,
};

const surface = {
  '--surface-default': slateDark.slate3,
  '--surface-hover': slateDark.slate4,
  '--surface-pressed': slateDark.slate5,
  '--surface-inverse': '#fff',
  '--surface-primary': amberDark.amber9,
  '--surface-success': grassDark.grass3,
  '--surface-warning': yellowDark.yellow3,
  '--surface-critical': tomatoDark.tomato3,
  '--surface-neutral': slateDark.slate9,
  '--surface-white': '#fff',
};

const text = {
  '--text-default': '#fff',
  '--text-soft': slateDark.slate11,
  '--text-disabled': slateDark.slate8,
  '--text-inverse': slateDark.slate1,
  '--text-primary': amberDark.amber9,
  '--text-success': grassDark.grass11,
  '--text-warning': yellowDark.yellow11,
  '--text-critical': tomatoDark.tomato11,
  '--text-on-primary': slateDark.slate1,
  '--text-on-critical': '#fff',
  '--text-link': blueDark.blue11,
};

const icon = {
  '--icon-default': '#fff',
  '--icon-soft': slateDark.slate11,
  '--icon-disabled': slateDark.slate8,
  '--icon-inverse': slateDark.slate1,
  '--icon-primary': amberDark.amber9,
  '--icon-success': grassDark.grass11,
  '--icon-warning': yellowDark.yellow11,
  '--icon-critical': tomatoDark.tomato11,
  '--icon-on-primary': slateDark.slate1,
  '--icon-on-critical': '#fff',
};

const border = {
  '--border-default': slateDark.slate9,
  '--border-soft': slateDark.slate6,
  '--border-hover': slateDark.slate11,
  '--border-disabled': slateDark.slate6,
  '--border-primary': amberDark.amber9,
  '--border-success': grassDark.grass11,
  '--border-warning': yellowDark.yellow11,
  '--border-critical': tomatoDark.tomato11,
  '--border-focus': amber.amber4,
};

const action = {
  '--action-primary-default': amberDark.amber9,
  '--action-primary-hover': amberDark.amber10,
  '--action-primary-disabled': slateDark.slate4,
  '--action-primary-soft-hover': 'hsla(39, 100%, 57%, 0.15)',
  '--action-critical-default': tomatoDark.tomato9,
  '--action-critical-hover': tomatoDark.tomato10,
  '--action-critical-disabled': slateDark.slate4,
  '--action-soft-hover': tomatoDark.tomato4,
};

const tomatoDark11Rgb = '241, 106, 80';
const grassDark11Rgb = '99, 193, 116';
const blueDark11Rgb = '82, 169, 255';

const visual = {
  '--chart-colors-list': `
    ${hslToHex(amberDark.amber9)},
    ${hslToHex(tealDark.teal10)},
    ${hslToHex(crimsonDark.crimson10)},
    ${hslToHex(goldDark.gold10)},
    ${hslToHex(violetDark.violet10)},
  `,
  '--viz-bearish': hslToHex(tomatoDark.tomato11),
  '--viz-bearish-rgb': tomatoDark11Rgb,
  '--viz-bearish-soft': tomatoDark.tomato4,
  '--viz-bullish': hslToHex(grassDark.grass11),
  '--viz-bullish-rgb': grassDark11Rgb,
  '--viz-bullish-soft': grassDark.grass4,
};

export const orangeDark = {
  ...background,
  ...surface,
  ...border,
  ...text,
  ...icon,
  ...action,
  ...visual,
};

export const orangeDark_protanopia = {
  ...orangeDark,
  '--viz-bearish': hslToHex(blueDark.blue11),
  '--viz-bearish-rgb': blueDark11Rgb,
  '--viz-bearish-soft': blueDark.blue4,
};

export const orangeDark_deuteranopia = {
  ...orangeDark,
  '--viz-bullish': hslToHex(blueDark.blue11),
  '--viz-bullish-rgb': blueDark11Rgb,
  '--viz-bullish-soft': blueDark.blue4,
};
