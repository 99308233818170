import { getStartOfRangeUTC, RangeHorizon } from '@toggle/helpers';

import { TSDatum } from '~/api/timeseries/timeseries-schema';

const isDataPointAfterDate = (dataPoint: TSDatum, date: number) =>
  new Date(dataPoint.index).getTime() >= date;

export function filterDataPoints(
  dataPoints: TSDatum[],
  timeRange = RangeHorizon.SixMonths
) {
  const startDate = getStartOfRangeUTC(timeRange);

  if (!startDate) {
    return [];
  }

  return dataPoints.filter(point => isDataPointAfterDate(point, startDate));
}

export function isDataPointWithinTimeRange(
  dataPoint: TSDatum,
  timeRange = RangeHorizon.TwelveDays
) {
  const timeRangeStart = getStartOfRangeUTC(timeRange);

  if (!timeRangeStart) {
    return false;
  }

  return isDataPointAfterDate(dataPoint, timeRangeStart);
}
