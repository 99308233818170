import {
  Button as DefaultButton,
  Icon as DefaultIcon,
  typography,
} from '@toggle/design-system';
import styled, { css } from 'styled-components';

export const Button = styled(DefaultButton)<{
  isActive: boolean;
  notMobile: boolean;
}>`
  position: absolute;
  min-width: 24px;
  max-width: 32px;
  top: 4px;
  bottom: 4px;
  border-radius: 2px;
  border: none;
  display: flex;
  justify-content: flex-start;
  width: auto;
  padding: 0;
  overflow: hidden;
  transition: all 0.4s;
  height: auto;

  ${({ isActive }) =>
    isActive &&
    css`
      background-color: var(--success--default);

      &:hover {
        background-color: var(--success--default);
      }
    `}

  ${({ notMobile }) =>
    notMobile &&
    css`
      &&:hover {
        max-width: 300px;
        top: 0;
        bottom: 0;
        background-image: none;
        padding-right: 8px;
      }
    `}
`;

export const Icon = styled(DefaultIcon)`
  margin: 0 6px;
  fill: var(--base--100);
`;

export const IconWrapper = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
`;

export const Wrapper = styled.div<{ isSmall?: boolean }>`
  position: relative;
  width: 32px;
  height: 40px;
  margin-right: 12px;

  ${({ isSmall }) =>
    isSmall &&
    css`
      width: 24px;
      height: 32px;

      ${Button} {
        top: 4px;
        bottom: 4px;
        max-width: 24px;
      }

      ${Icon} {
        margin: 0 4px;
        height: 16px;
        width: 16px;

        &:before {
          ${typography.koala};
        }
      }
    `}
`;
