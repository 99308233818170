import { deviceConstraints, Icon } from '@toggle/design-system';
import styled from 'styled-components';

export const StyledIcon = styled(Icon).attrs({
  size: 36,
})`
  transform: translateY(-14px);
`;

const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  list-style: none;
`;

export const MainHelperList = styled(List)`
  && {
    margin-bottom: 40px;
  }
`;

export const AdditionalHelpersList = styled(List)`
  && {
    margin-bottom: 20px;
  }
`;

export const HomeHelperContent = styled.div`
  height: 100%;
  padding-top: 10px;
`;

export const HelperItem = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  height: 70px;
  width: 140px;
  margin-bottom: 43px;
  font-size: 16px;
  cursor: pointer;

  @media ${deviceConstraints.desktopSmall} {
    width: 110px;
  }
`;
