import { useState } from 'react';

import { useUserEntities } from '~/stores/use-user-entities/useUserEntities';

export const useWatchlist = (entityTag: string) => {
  const { checkIsInWatchlist, addTag, removeTag } = useUserEntities(
    ({ watchlist: { checkIsInWatchlist, addTag, removeTag } }) => ({
      checkIsInWatchlist,
      addTag,
      removeTag,
    })
  );
  const [isInWatchlist, setIsInWatchlist] = useState(
    checkIsInWatchlist(entityTag)
  );

  const updateWatchlist = async () => {
    if (isInWatchlist) {
      await removeTag(entityTag);
      setIsInWatchlist(false);
    } else {
      await addTag(entityTag);
      setIsInWatchlist(true);
    }
  };
  return {
    updateWatchlist,
    isInWatchlist,
  };
};
