import React, { useState } from 'react';

import { EntityHeader } from '~/global/widgets/shared/entity-header/EntityHeader';
import { useEntities } from '~/shared/hooks/use-entities';
import { GtkCardData } from '~/views/analyze/asset-overview/components/sidebar/components/rocketship-tab/helpers/RocketshipTabHelpers';
import { AnalyzeStore } from '~/views/analyze/store/AnalyzeStore';

import { AoWidgetChart } from './ao-chart/AoWidgetChart';
import * as S from './AoWidget.styles';
import { GtkDetails } from './gtk-details/GtkDetails';
import { WidgetTabs } from './widget-tabs/WidgetTabs';

export interface AoWidgetProps {
  entityTag: string;
}

export const AoWidget = ({ entityTag }: AoWidgetProps) => {
  const [selectedGtk, setSelectedGtk] = useState<GtkCardData | undefined>();

  const { data } = useEntities({
    entities: [entityTag],
  });

  const entity = data?.[0];

  if (entity) {
    return (
      <S.AoWidgetRoot data-testid="ao-widget">
        <AnalyzeStore entity={entity} shouldHideLoader>
          <EntityHeader entity={entity} />

          {selectedGtk ? (
            <GtkDetails
              selectedGtk={selectedGtk}
              backButtonClick={() => setSelectedGtk(undefined)}
            />
          ) : (
            <>
              <AoWidgetChart entity={entity} />
              <WidgetTabs
                entity={entity}
                updateGtk={gtk => setSelectedGtk(gtk)}
              />
            </>
          )}
        </AnalyzeStore>
      </S.AoWidgetRoot>
    );
  }

  return null;
};
