import styled, { css } from 'styled-components';

import { borderRadius } from '~/design-tokens/border-radius/borderRadius';
import { typographyV2 } from '~/design-tokens/typography/Typography.styles';

import { Icon } from '../icon/Icon';
import type { ChipType, ChipVariation } from './Chip';

interface Config {
  default?: string;
  hover?: string;
  active?: string;
}

type ColorsConfig = {
  [type in ChipType]: {
    [variation in ChipVariation]: {
      bgColor: Config;
      color: Config;
      borderColor?: Config;
    };
  };
};

const inputSolid = {
  bgColor: {
    default: 'var(--surface-default)',
    hover: 'var(--surface-hover)',
    active: 'var(--surface-primary)',
  },
  color: {
    default: 'var(--text-default)',
    active: 'var(--text-inverse)',
  },
};

const choiceSolid = {
  bgColor: {
    default: 'var(--surface-default)',
    hover: 'var(--surface-hover)',
    active: 'transparent',
  },
  color: {
    default: 'var(--text-default)',
    active: 'var(--text-primary)',
  },
  borderColor: {
    active: 'var(--border-primary)',
  },
};

const map: ColorsConfig = {
  input: {
    solid: {
      ...inputSolid,
    },
    outline: {
      ...inputSolid,
      bgColor: {
        ...inputSolid.bgColor,
      },
      borderColor: {
        default: 'var(--border-default)',
        hover: 'var(--border-hover)',
        active: 'var(--border-primary)',
      },
    },
  },

  choice: {
    solid: {
      bgColor: {
        default: 'var(--surface-default)',
        hover: 'var(--surface-hover)',
        active: 'transparent',
      },
      color: {
        default: 'var(--text-default)',
        active: 'var(--text-primary)',
      },
      borderColor: {
        active: 'var(--border-primary)',
      },
    },
    outline: {
      ...choiceSolid,
      bgColor: {
        ...choiceSolid.bgColor,
        default: 'transparent',
      },
      borderColor: {
        default: 'var(--border-default)',
        hover: 'var(--border-hover)',
        active: 'var(--border-primary)',
      },
    },
  },

  filter: {
    solid: {
      bgColor: {
        default: 'var(--surface-default)',
      },
      color: {
        default: 'var(--text-default)',
      },
    },
    outline: {
      bgColor: {
        default: 'transparent',
      },
      color: {
        default: 'var(--text-default)',
      },
      borderColor: {
        default: 'var(--border-default)',
      },
    },
  },
};

interface ContainerProps {
  $type: ChipType;
  variation: ChipVariation;
  isActive: boolean;
  isFocused: boolean;
  disabled: boolean;
}

const getField = (
  colorsConfig: Config | undefined,
  fieldName: 'default' | 'active' | 'hover'
) => {
  return colorsConfig?.[fieldName];
};

const getStyles = ({
  $type: type,
  variation,
  isActive,
  disabled,
}: Omit<ContainerProps, 'isFocused'>) => {
  if (disabled) {
    return css`
      background-color: transparent;
      border-color: ${variation === 'outline'
        ? 'var(--border-disabled)'
        : 'transparent'};
      color: var(--surface-neutral);
      pointer-events: none;
    `;
  }

  return isActive
    ? css`
        color: ${getField(map[type][variation].color, 'active')};
        background-color: ${getField(map[type][variation].bgColor, 'active')};
        border-color: ${getField(map[type][variation].borderColor, 'active')};
      `
    : css`
        color: ${map[type][variation].color.default};
        background-color: ${getField(map[type][variation].bgColor, 'default')};
        border-color: ${getField(map[type][variation].borderColor, 'default') ??
        'transparent'};

        &:hover {
          color: ${getField(map[type][variation].color, 'hover')};
          background-color: ${getField(map[type][variation].bgColor, 'hover')};
          border-color: ${getField(map[type][variation].borderColor, 'hover')};
        }
      `;
};

export const CloseIcon = styled(Icon).attrs({
  iconName: 'Close',
  size: 12,
})`
  margin-left: 8px;
  fill: var(--icon-default);
`;

export const Container = styled.button<ContainerProps>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 5px 11px;
  border: 1px solid;
  cursor: pointer;
  ${borderRadius.r2};

  > span {
    ${typographyV2.bodySm};
  }

  ${({ $type, variation, isActive, disabled }) =>
    getStyles({ $type, variation, isActive, disabled })}

  ${props =>
    props.$type === 'filter' &&
    css`
      &:hover ${CloseIcon} {
        fill: var(--icon-critical);
      }
    `}  
  ${props =>
    props.isFocused &&
    'outline: 2px solid var(--border-focus); border-color: var(--surface-default);'};
`;

export const IconContainer = styled.div`
  display: inline-flex;
`;

export const LeftIcon = styled(Icon)`
  fill: currentColor;
`;

export const RightIcon = styled(Icon)`
  fill: currentColor;
`;
