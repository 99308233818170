import React, { FC, PropsWithChildren, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { appPaths, queryKeys } from '~/routes/app-paths';
import { TooltipContainer } from '~/shared/components/tooltip-container/TooltipContainer';
import { APIFetch } from '~/shared/services/api-fetch/APIFetch';
import { Tracking } from '~/shared/services/tracking';
import {
  gaArticleImageAction,
  gaArticleOpenInScenario,
} from '~/shared/utils/ganalytics';
import { AnalyzeViews } from '~/views/analyze/AnalyzeViews';

import * as S from './ImageOverlay.styles';

interface ImageOverlayProps {
  className?: string;
  link: string;
  imageName: string;
  tag?: string;
  articleID?: string;
}

async function handleChartDownload(
  event: React.MouseEvent,
  imgLink: string,
  imageName: string
) {
  event.preventDefault();
  gaArticleImageAction('download');
  try {
    const blobImage = await APIFetch<Blob>(imgLink);
    if (blobImage instanceof Error) {
      throw blobImage;
    }

    const linkHref = URL.createObjectURL(blobImage);
    const link = document.createElement('a');
    link.setAttribute('href', linkHref);
    link.setAttribute('download', imageName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(linkHref);
  } catch (error) {
    Tracking.captureException(error as Error);
  }
}

export const ImageOverlay: FC<PropsWithChildren<ImageOverlayProps>> = ({
  children,
  className,
  link,
  imageName,
  tag = '',
  articleID,
}) => {
  const { t } = useTranslation('article');
  const [copiedTooltip, setCopiedTooltip] = useState(false);
  const copied = () => {
    setCopiedTooltip(true);
    gaArticleImageAction('copy');
    window.setTimeout(() => setCopiedTooltip(false), 2000);
  };

  const scenarioParams = articleID ? `&articleId=${articleID}` : '';

  return (
    <S.ImageOverlayFigure className={className}>
      <S.ImageOverlayFigcaption>
        <div>
          {tag && articleID && (
            <Link
              to={{
                pathname: `${appPaths.analyze}/${tag}`,
                search: `${queryKeys.view}=${AnalyzeViews.SCENARIO}${scenarioParams}`,
              }}
              data-testid="open-experiment-link"
              onClick={() => gaArticleOpenInScenario()}
            >
              <S.StyledButtonLink
                label={t('article:overview.overlay.experiment')}
                iconName="ChartBar"
              />
            </Link>
          )}
        </div>
        <S.Group>
          <TooltipContainer
            isOpen={copiedTooltip}
            placement="top-start"
            label={t('article:overview.overlay.copied')}
            disabled
          >
            <div data-testid="copy-btn">
              <CopyToClipboard
                text={`<img src='${link}'>`}
                onCopy={copied}
                options={{ format: 'text/html' }}
              >
                <S.StyledButtonLink
                  label={t('article:overview.overlay.copy')}
                  iconName="Copy"
                />
              </CopyToClipboard>
            </div>
          </TooltipContainer>
          <S.StyledButtonLink
            data-testid="download"
            label={t('article:overview.overlay.download')}
            iconName="Download"
            onClick={e => handleChartDownload(e, link, imageName)}
          />
        </S.Group>
      </S.ImageOverlayFigcaption>
      {children}
    </S.ImageOverlayFigure>
  );
};
