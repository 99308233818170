import { NumericalCellValueStyle } from '@toggle/helpers/src/utils/table/table';
import styled from 'styled-components';

export const Rect = styled.rect<{ $numericalStyle: NumericalCellValueStyle }>`
  fill: ${({ $numericalStyle }) =>
    ({
      [NumericalCellValueStyle.Positive]: 'var(--viz-bullish)',
      [NumericalCellValueStyle.Negative]: 'var(--viz-bearish)',
      [NumericalCellValueStyle.Neutral]: 'var(--text-default)',
    }[$numericalStyle])};
`;
