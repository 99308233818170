import { Icon } from '@toggle/design-system';
import React, { useCallback, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { TooltipContainer } from '~/shared/components/tooltip-container/TooltipContainer';
import { gaShare } from '~/shared/utils/ganalytics';
import { SocialUrls } from '~/shared/utils/url-helper/url-utils';
import {
  SHARE_BUTTONS,
  SHARE_BUTTONS_NAMES,
} from '~/views/article/partials/article-menu/ShareButtons';
import { SocialShareButton } from '~/views/news/components/social-share-button/SocialShareButton';

import { ActionMenuProps } from '../action-menu/ActionMenu';
import * as S from './ShareMenu.styles';

interface ShareMenuProps {
  article: ActionMenuProps;
  gaLocation: string;
}

export function ShareMenu({ article, gaLocation }: ShareMenuProps) {
  const { t } = useTranslation('common');
  const { pathname } = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenCopyToClipboardTooltip, setIsOpenCopyToClipboardTooltip] =
    useState(false);
  const toggleOpen = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const sendGa = (shareType: SHARE_BUTTONS_NAMES) =>
    gaShare(shareType, gaLocation, pathname);

  const copyCallback = () => {
    sendGa(SHARE_BUTTONS.COPYLINK);
    setIsOpenCopyToClipboardTooltip(true);
    setTimeout(() => setIsOpenCopyToClipboardTooltip(false), 2000);
  };

  return (
    <S.ShareMenuContainer $isActive={isOpen}>
      <S.ShareMenuHeader
        onClick={toggleOpen}
        data-testid="share-button"
        $isActive={isOpen}
      >
        {t('common:share.label', { context: 'share' })}
        <Icon iconName="Share" size={20} fillColor="var(--base--20)" />
      </S.ShareMenuHeader>
      <S.CollapseWrapper collapsed={!isOpen}>
        <SocialShareButton
          provider={{
            provider: SocialUrls.Mail,
            params: {
              subject: t('common:share.emailSubject'),
              body: article.title,
              url: article.sharedLink,
            },
          }}
          label={t('common:share.emailLabel')}
          beforeOnClick={() => sendGa(SHARE_BUTTONS.EMAIL)}
        />
        <CopyToClipboard
          text={article.sharedLink}
          options={{ format: 'text/plain' }}
          onCopy={copyCallback}
          data-testid="copy-to-clipboard-button"
        >
          <S.ShareMenuItem>
            <S.StyledIcon iconName="Copy" />
            <TooltipContainer
              isOpen={isOpenCopyToClipboardTooltip}
              label={t('common:share.copied')}
              placement="right-start"
              disabled
            >
              <span>{t('common:share.copy')}</span>
            </TooltipContainer>
          </S.ShareMenuItem>
        </CopyToClipboard>
        <SocialShareButton
          provider={{
            provider: SocialUrls.Facebook,
            params: { u: article.sharedLink, quote: article.title },
          }}
          label={t('common:share.facebook')}
          beforeOnClick={() => sendGa(SHARE_BUTTONS.FACEBOOK)}
        />
        <SocialShareButton
          provider={{
            provider: SocialUrls.Twitter,
            params: {
              title: article.title,
              url: article.sharedLink,
            },
          }}
          label={t('common:share.twitter')}
          beforeOnClick={() => sendGa(SHARE_BUTTONS.TWITTER)}
        />
      </S.CollapseWrapper>
    </S.ShareMenuContainer>
  );
}
