export const FUNDAMENTAL_FILTER = {
  properties: {
    method_sub_cls:
      'valuation,equity_growth_gauge,equity_consensus_trend,equity_yield,economic_growth_gauge,economic_inflation_gauge,financial_conditions,geopolitical,sentiment',
  },
};

export const TECHNICAL_FILTER = {
  properties: {
    method_sub_cls:
      'momentum,volume_analysis,entry_point,technical_analysis,positioning,main_market_price,main_yield,volatility,seasonality',
  },
};
