import ReactGA from 'react-ga4';

import { GaLocations } from '.';

const CATEGORIES = {
  login: 'Log_in',
};
export { CATEGORIES as LOGIN_CATEGORIES };

const ACTIONS = {
  loginClick: 'User_login',
  clickedOk: 'Clicked_ok',
};
export { ACTIONS as LOGIN_ACTIONS };

export function gaUserLogin() {
  ReactGA.event({
    category: CATEGORIES.login,
    action: ACTIONS.loginClick,
  });
}

export function gaNotificationClickedOk(
  isChecked: boolean,
  location: GaLocations
) {
  ReactGA.event({
    category: CATEGORIES.login,
    action: ACTIONS.clickedOk,
    label: `checked:${isChecked};location:${location}`,
  });
}
