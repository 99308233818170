import { ButtonLink, Icon } from '@toggle/design-system';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ChevronRight } from '~/global/trading-drawer/shared/chevron-right/ChevronRight.styles';
import { Collapse } from '~/shared/components/collapse';
import { gaExpandNewPositionMessage } from '~/shared/utils/ganalytics/tradingGA';

import * as S from './PositionChange.styles';

export interface PositionChangeProps {
  orderPosition: number;
  statementPosition: number;
}

export const PositionChange: FC<PositionChangeProps> = ({
  orderPosition,
  statementPosition,
}) => {
  const { t } = useTranslation('portfolio');
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpanding = () => {
    setIsExpanded(prevState => {
      const nextState = !prevState;

      if (nextState) {
        gaExpandNewPositionMessage();
      }

      return nextState;
    });
  };

  const isShort = (p: number) => p < 0;

  const newPosition = statementPosition + orderPosition;
  const positionChanged =
    newPosition !== 0 &&
    Math.sign(newPosition) !== Math.sign(statementPosition);

  if (!positionChanged) {
    return null;
  }

  const newPositionText = t('portfolio:newPositionMsg', {
    positionType:
      isShort(statementPosition) && !isShort(newPosition)
        ? t('portfolio:long')
        : t('portfolio:short'),
    position: Math.abs(newPosition),
  });

  return (
    <>
      <ButtonLink
        data-testid="position-change"
        label={
          <>
            <ChevronRight $isExpanded={isExpanded} />
            <span data-testid="position-change-text">{newPositionText}</span>
          </>
        }
        onClick={toggleExpanding}
      />
      <Collapse collapsed={!isExpanded}>
        <S.Container data-testid="position-tiles">
          <S.PositionTile>
            <S.Title>{t('portfolio:currentPosition')}</S.Title>
            <S.PositionNumber>{statementPosition}</S.PositionNumber>
          </S.PositionTile>
          <S.IconWrapper>
            <Icon size={16} iconName="ChevronLightRight" />
          </S.IconWrapper>
          <S.PositionTile $right>
            <S.Title>{t('portfolio:newPosition')}</S.Title>
            <S.PositionNumber>{newPosition}</S.PositionNumber>
          </S.PositionTile>
        </S.Container>
      </Collapse>
    </>
  );
};
