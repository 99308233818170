import { Icon } from '@toggle/design-system';
import React from 'react';

import * as S from './SearchIcon.styles';

export const SearchIcon = () => (
  <S.SearchIconContainer>
    <Icon iconName="Search" size={20} data-testid="search-icon" />
  </S.SearchIconContainer>
);
