import {
  Chip,
  customScrollMinimal,
  deviceConstraints,
  Icon,
} from '@toggle/design-system';
import styled, { css } from 'styled-components';

import { EllipsisDetector as SharedEllipsisDetector } from '~/shared/components/elipsis-detector/EllipsisDetector';

export const FilterScreens = styled.section`
  margin-bottom: 15px;

  @media ${deviceConstraints.desktopSmall} {
    margin-bottom: 20px;
  }
`;

export const ScreenTabsWrapper = styled.div`
  position: relative;
`;

export const TabsMobileSelect = styled.div`
  margin-bottom: 8px;
  display: flex;
  align-items: center;
`;

const TabMobileBtn = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  border-radius: 4px;
  border: 1px solid var(--base--80);
  padding: 0 12px;
`;

export const TabMobileSelectBtn = styled(TabMobileBtn)`
  margin-right: 12px;
  flex: 1;
  max-width: 300px;
  background-color: var(--base--95);
  justify-content: space-between;
  line-height: 1;
`;

export const MobileSelectName = styled.span`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const MobileSelectChevronIcon = styled(Icon)`
  margin: 7px 0 3px 10px;
`;

export const TabMobileMenuBtn = styled(TabMobileBtn)`
  flex: 0 0 40px;
  justify-content: center;
  color: var(--primary-color);
  position: relative;
`;

export const TabMenuIcon = styled.div`
  display: none;
  padding: 8px;
`;

const tabName = css`
  display: inline-block;
  align-items: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 0 8px;
`;

export const TooltipTarget = styled.div<{ isHomeScreenTab: boolean }>`
  ${props =>
    props.isHomeScreenTab
      ? css`
          display: flex;
          align-items: center;
          padding: 0px 8px;
        `
      : css`
          ${tabName};
          max-width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          align-items: center;
        `}
`;

export const EllipsisDetector = styled(SharedEllipsisDetector)`
  white-space: normal;
`;

export const ScreenTab = styled.div<{
  isHomeScreenTab: boolean;
  isActive: boolean;
  isMobile: boolean;
}>`
  ${({ isMobile }) =>
    isMobile &&
    css`
      display: flex;
      align-items: center;
      width: 100%;
      height: 40px;
      padding: 0 4px;

      ${({ isActive }: { isActive: boolean }) =>
        isActive &&
        css`
          background-color: var(--primary-color);
          color: var(--base--100);

          ${TooltipTarget} svg {
            fill: inherit;
          }
        `}
    `}

  ${({ isMobile }) =>
    !isMobile &&
    css`
      * {
        outline: none;
      }

      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      flex: 1 1 0;
      width: 0;
      max-width: max-content;
      border-radius: 4px 4px 0 0;
      background-color: var(--base--100);
      border: 1px solid var(--base--80);

      border-bottom: none;
      margin-right: 4px;

      cursor: pointer;
      user-select: none;

      transition: width 0.5s;

      &:last-child {
        margin-right: 0 !important;
      }

      &:hover {
        background-color: var(--primary-outline-btn-background-color);
      }

      ${({ isActive }: { isActive: boolean }) =>
        isActive &&
        css`
          &,
          &:hover {
            background-color: var(--base--95);

            &:after {
              content: '';
              width: 100%;
              height: 1px;
              background-color: var(--base--95);
              position: absolute;
              left: 0;
              bottom: -1px;
            }

            ${TabMenuIcon} {
              display: flex;
              cursor: pointer;
            }
          }
        `}
    `};

  ${({ isHomeScreenTab }) =>
    isHomeScreenTab &&
    css`
      &,
      &:hover {
        @media ${deviceConstraints.desktopSmall} {
          font-size: 17px;
          width: 40px;
          height: 40px;
          max-width: 40px;
          min-width: 40px;
          padding: 0;

          ${EllipsisDetector} {
            padding: 0;
          }

          ${TooltipTarget} {
            padding: 0;
          }
        }
      }
    `}
`;

const DesktopScreenTabs = css`
  display: flex;
  align-items: center;
`;

const MobileScreenTabs = css<{ isSelectOpened: boolean }>`
  display: none;
  position: absolute;
  z-index: 1;
  left: 0;
  top: 44px;
  max-height: 344px;
  width: calc(100vw - 84px);
  max-width: 300px;
  min-width: 260px;
  overflow-y: auto;
  ${customScrollMinimal};
  background-color: var(--base--100);
  border: 1px solid var(--base--80);
  border-radius: 4px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25), 0 0 1px rgba(0, 0, 0, 0.3);

  ${({ isSelectOpened }) => isSelectOpened && 'display: block;'}
`;

export const ScreenTabs = styled.div<{
  isMobile: boolean;
  isSelectOpened: boolean;
}>`
  ${({ isMobile }) => (isMobile ? MobileScreenTabs : DesktopScreenTabs)}
`;

export const TabLabel = styled.div`
  outline: none;
  display: flex;
  white-space: nowrap;
  overflow: hidden;
  align-items: center;

  height: 40px;
  padding: 0 4px;
`;

export const HomeName = styled.span`
  margin-left: 12px;
`;

export const ScreenContent = styled.div`
  overflow-x: auto;
  width: calc(100vw - 32px);
  white-space: nowrap;

  &::-webkit-scrollbar {
    display: none;
  }

  @media ${deviceConstraints.desktopSmall} {
    overflow-x: unset;
    width: 100%;
    white-space: unset;
    padding: 14px 8px 4px;
    border-radius: 0 4px 4px 4px;
    background-color: var(--base--95);
    border: 1px solid var(--base--80);
  }
`;

export const FilterChip = styled(Chip).attrs({
  type: 'filter',
  variation: 'outline',
})`
  & > span {
    font-size: 16px;
  }

  ${Chip.Styled.IconContainer}:first-child {
    align-self: flex-start;
  }
`;

export const EmptyScreenMessage = styled.p`
  margin-bottom: 8px;
`;

export const ScreenButtons = styled.div<{ noMarginButtons: boolean }>`
  display: flex;
  align-items: center;
  margin: 10px 0 8px;

  ${({ noMarginButtons }) => noMarginButtons && 'margin-top: 2px;'}
`;

export const ScreenButtonsMsg = styled.span`
  margin-right: 19px;
  cursor: default;
`;

export const ScreenButton = styled.span`
  margin-right: 14px;
  font-size: 14px;
  color: var(--primary-color);
  cursor: pointer;
`;

export const HomeTabWrapper = styled.div`
  display: flex;
  align-items: center;

  > svg {
    fill: var(--primary-color);
  }
`;

export const ChipsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;
