import { z } from 'zod';

import { TwoFactorMethod } from '~/api/auth/auth-services';

export type TwofaStatus = z.infer<typeof twofaStatusSchema>;
export const twofaStatusSchema = z.object({
  enabled: z.array(z.nativeEnum(TwoFactorMethod)),
});

export type TwofaEnableTotp = z.infer<typeof twofaEnableTotpSchema>;
export const twofaEnableTotpSchema = z.union([
  z.object({
    enabled: z.literal(false),
    key: z.string().optional(),
    code: z.string().optional(),
  }),
  z.object({
    enabled: z.literal(true),
    key: z.string(),
    code: z.string(),
  }),
]);

export type TwofaTotpCode = z.infer<typeof twofaTotpCodeSchema>;
export const twofaTotpCodeSchema = z.object({
  key: z.string(),
  qr_code: z.string(),
});

export type BackupCodesType = z.infer<typeof backupCodesTypeSchema>;
export const backupCodesTypeSchema = z.object({
  available: z.array(z.string()),
  used: z.array(z.string()),
});
