import { create } from '~/stores/create-store/createStore';

import { MODAL_TYPES } from '../services/modal-types/modalTypes';

interface PaywallStore {
  isOpen: boolean;
  type?: MODAL_TYPES;
  openModal: (type?: MODAL_TYPES) => void;
  hideModal: () => void;
}

export const usePaywallStore = create<PaywallStore>(set => ({
  isOpen: false,
  type: undefined,
  openModal: (type = MODAL_TYPES.DEFAULT) =>
    set(() => ({ isOpen: true, type })),
  hideModal: () => set(() => ({ isOpen: false })),
}));
