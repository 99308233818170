import { formatPercentage } from '@toggle/helpers';
import * as d3 from 'd3';
import React, { FC, useId } from 'react';

import { getHumanFriendlyTicks } from '../helpers/chart-ticks/chart-ticks';
import { checkHasProfit } from '../helpers/chart-values/chart-values';
import { TextLabel } from '../high-low-chart/components/text-label/TextLabel';
import * as S from '../SharedStyles.styles';

export interface HorizontalHighLowProps {
  data: {
    low: number;
    median: number;
    high: number;
  };
  width: number;
  height: number;
  withLabels?: boolean;
}

const margin = {
  top: 44,
  right: 20,
  bottom: 20,
  left: 20,
};

const BAR_HEIGHT_PX = 12;
const BAR_HALF_WIDTH_PX = BAR_HEIGHT_PX / 2;
const BAR_THIRD_WIDTH_PX = BAR_HEIGHT_PX / 3;
const CIRCLE_Y_POSITION = margin.top + BAR_HALF_WIDTH_PX;

export const HorizontalHighLow: FC<HorizontalHighLowProps> = ({
  data: { low, median, high },
  width,
  height,
  withLabels,
}) => {
  const { chartTicks, maxRounded, minRounded } = getHumanFriendlyTicks({
    min: low,
    max: high,
  });

  const Scale = d3
    .scaleLinear()
    .domain([maxRounded, minRounded])
    .range([width - margin.right, margin.left]);

  const xHigh = Scale(high);
  const xLow = Scale(low);
  const yMedian = Scale(median);

  const x0 = Scale(0);

  const clipPathId = useId();
  const hasMedianProfit = checkHasProfit(median);

  const lowStart = xLow;
  const lowEnd = high < 0 ? xHigh : x0;
  const lowBarWidth = lowEnd - lowStart;

  const highEnd = xHigh;

  const highBarWidth = highEnd - lowEnd;
  const fullWidth = highEnd - lowStart;

  return (
    <S.RootSVG
      data-testid="horizontal-high-low"
      viewBox={`0 0 ${width} ${height}`}
    >
      <g data-testid="high-low-bar">
        <clipPath id={clipPathId}>
          <rect
            width={fullWidth}
            x={xLow}
            y={margin.top}
            height={BAR_HEIGHT_PX}
            rx={BAR_HALF_WIDTH_PX}
            ry={BAR_HALF_WIDTH_PX}
          />
        </clipPath>

        <g clipPath={`url(#${clipPathId})`} data-testid="bars">
          {low < 0 && (
            <S.Rect
              data-testid="low-bar"
              height={BAR_HEIGHT_PX}
              x={xLow}
              y={margin.top}
              width={lowBarWidth}
              hasProfit={checkHasProfit(low)}
            />
          )}
          {high > 0 && (
            <S.Rect
              data-testid="high-bar"
              height={BAR_HEIGHT_PX}
              x={x0}
              y={margin.top}
              width={highBarWidth}
              hasProfit={checkHasProfit(high)}
            />
          )}
        </g>

        <g
          data-testid="median-label"
          transform={`translate(${yMedian}, ${CIRCLE_Y_POSITION})`}
        >
          <S.AnimatedGroup>
            <S.Circle r={BAR_THIRD_WIDTH_PX} />
            {withLabels && (
              <TextLabel
                hasProfit={hasMedianProfit}
                label={formatPercentage(median, { suffix: '%' })}
                x={-24}
                y={-(margin.top - BAR_HEIGHT_PX)}
              />
            )}
          </S.AnimatedGroup>
        </g>
      </g>

      {withLabels && (
        <g data-testid="horizontal-high-low-ticks">
          {chartTicks.map(tickValue => (
            <g
              key={tickValue}
              transform={`translate(${Scale(tickValue)}, 80)`}
              data-testid="horizontal-high-low-tick"
            >
              <S.AxisLine
                y1={-8}
                y2={-16}
                strokeWidth={tickValue === 0 ? 2 : 1}
              />
              <S.AxisText
                textAnchor="middle"
                x={0}
                y={8}
                alignmentBaseline="middle"
              >
                {tickValue}%
              </S.AxisText>
            </g>
          ))}
        </g>
      )}
    </S.RootSVG>
  );
};
