import { deviceConstraints } from '@toggle/design-system';
import styled from 'styled-components';

const arrowBgWidth = '180px';

export const StatsCard = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas:
    'heading'
    'content';
  padding: 24px 8px;
  border-radius: 4px;
  border: solid 1px var(--base--80);
  margin-bottom: 16px;

  @media ${deviceConstraints.tablet} {
    padding: 24px;
  }

  @media ${deviceConstraints.desktopSmall} {
    grid-template-columns: 1fr auto;
    grid-template-rows: auto 1fr;
    grid-template-areas:
      'heading heading'
      'content image';
  }
`;

export const StatsCardHeading = styled.p`
  grid-area: heading;
  font-size: 18px;
  color: var(--base--0);
  margin-bottom: 20px;
`;

export const StatsCardStats = styled.div`
  position: relative;
  grid-area: content;
  align-self: center;
`;

export const StatsCardImage = styled.div`
  display: none;
  grid-area: image;
  max-width: 220px;

  @media ${deviceConstraints.desktop} {
    display: flex;
    align-items: center;
  }
`;

export const ArrowBg = styled.div`
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 50%;
  max-width: ${arrowBgWidth};

  @media ${deviceConstraints.tablet} {
    display: block;
  }
`;

export const ArrowDown = styled.div`
  width: 0;
  height: 0;
  border-left: 77px solid transparent;
  border-right: 77px solid transparent;
  border-top: 180px solid var(--base--90);
  margin: 0 auto;
`;

export const StatsContent = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: 1fr 1fr;
  grid-template-areas:
    'number title'
    'number subtitle';
  align-items: center;
  align-self: center;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid var(--base--80);

  @media ${deviceConstraints.desktop} {
    max-width: 500px;
    grid-template-columns: minmax(auto, ${arrowBgWidth}) 1fr;
  }

  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 0;
  }
`;

export const StatsContentNumber = styled.p`
  grid-area: number;
  font-size: 22px;
  max-width: ${arrowBgWidth};
  font-weight: 600;
  text-align: center;
  color: var(--base--0);
`;

export const StatsContentTitle = styled.p`
  grid-area: title;
  font-size: 16px;
  color: var(--base--0);
  margin-left: 10px;
`;

export const StatsContentSubtitle = styled.p`
  grid-area: subtitle;
  font-size: 14px;
  color: var(--base--40);
  margin-left: 10px;
`;
