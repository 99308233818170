import styled from 'styled-components';

import { typographyV2 } from '~/design-tokens/typography/Typography.styles';

export const GridLinesGroup = styled.g`
  text {
    ${typographyV2.bodySm};
    color: var(--text-soft);
    font-family: var(--font-roboto-mono);
  }

  line {
    stroke: var(--border-soft);
  }
`;
