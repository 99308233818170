import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import * as S from './ClearButtons.styles';

export interface ClearButtonsProps {
  showClearButton?: boolean;
  handleClear: () => void;
}

export const ClearButtons: FC<ClearButtonsProps> = ({
  handleClear,
  showClearButton = true,
}) => {
  const { t } = useTranslation('header');

  return showClearButton ? (
    <S.ClearLabel onClick={handleClear} data-testid="clear-button">
      {t('header:search.clear')}
    </S.ClearLabel>
  ) : (
    <S.ClearIcon iconName="CloseBoldCircle" size={18} onClick={handleClear} />
  );
};
