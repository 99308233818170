import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Balloon from '~/assets/explore/balloon.svg';
import { BetaModal } from '~/shared/components';
import { POP_UP_KEYS } from '~/shared/hooks/use-remote-storage/';
import {
  GaLocations,
  gaNotificationClickedOk,
} from '~/shared/utils/ganalytics';
import { UpdateBrowserTitle } from '~/shared/utils/update-browser-title/UpdateBrowserTitle';
import { useSubscription } from '~/stores/use-subscription/useSubscription';

import { ExploreFeed } from './feed';

export const Explore: FC = () => {
  const { t } = useTranslation('explore');
  const { plan, isPro } = useSubscription(state => ({
    plan: state.userSubscription?.name,
    isPro: state.isPro(),
  }));

  return (
    <>
      <UpdateBrowserTitle
        title={t('explore:documentTitle', { plan: isPro ? 'Pro' : plan })}
        withMetadataTitle={false}
      />
      <BetaModal
        title={t('explore:modal.title')}
        message={t('explore:modal.content')}
        checkBoxLabel={t('explore:modal.checkboxLabel')}
        btnLabel={t('explore:modal.btnLabel')}
        image={<Balloon />}
        storageKey={POP_UP_KEYS.EXPLORE_BANNER_NO_REPEAT}
        closeCallback={isChecked =>
          gaNotificationClickedOk(isChecked, GaLocations.explore)
        }
      />
      <ExploreFeed />
    </>
  );
};
