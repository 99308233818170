import ReactGA from 'react-ga4';

const CATEGORIES = {
  helperRobot: 'Helper_robot',
};
export { CATEGORIES as HELPER_CATEGORIES };

const ACTIONS = {
  showHelper: 'Show',
  hideHelper: 'Hide',
  clickHelperContent: 'Click_content',
  clickHelperHome: 'Click_home',
  clickHelperSection: 'Click_section',
  clickVideo: 'Clicked_video',
};
export { ACTIONS as HELPER_ACTIONS };

export function gaHelperToggleExpand(isExpanded: boolean, page: string) {
  ReactGA.event({
    category: CATEGORIES.helperRobot,
    action: isExpanded ? ACTIONS.showHelper : ACTIONS.hideHelper,
    label: `page:${page}`,
  });
}

export function gaHelperClickContent(page: string, content?: string) {
  ReactGA.event({
    category: CATEGORIES.helperRobot,
    action: ACTIONS.clickHelperContent,
    label: `page:${page};content:${content ?? ''}`,
  });
}

export function gaHelperClickHome(page: string) {
  ReactGA.event({
    category: CATEGORIES.helperRobot,
    action: ACTIONS.clickHelperHome,
    label: `page:${page}`,
  });
}

export function gaHelperClickSection(page: string) {
  ReactGA.event({
    category: CATEGORIES.helperRobot,
    action: ACTIONS.clickHelperSection,
    label: `page:${page}`,
  });
}

export function gaHelperClickedVideo() {
  ReactGA.event({
    category: CATEGORIES.helperRobot,
    action: ACTIONS.clickVideo,
  });
}
