import { NumericalCellValueStyle } from '@toggle/helpers/src/utils/table/table';
import { Property } from 'csstype';
import React, { TableHTMLAttributes } from 'react';

import * as S from './TableCell.styles';

export interface TableCellProps
  extends TableHTMLAttributes<HTMLTableCellElement> {
  alignment?: Property.TextAlign;
  condensed?: boolean;
  isNumber?: boolean;
  isUpperCase?: boolean;
  colspan?: number;
  fixed?: boolean;
  empty?: boolean;
  numericalValueStyle?: NumericalCellValueStyle;
}

export const TableCell = ({
  alignment = 'end',
  condensed,
  isNumber = true,
  isUpperCase = false,
  colspan,
  fixed,
  numericalValueStyle = NumericalCellValueStyle.Neutral,
  children,
  empty,
  ...rest
}: TableCellProps) => {
  return (
    <S.TableCell
      data-testid="table-cell"
      $alignment={alignment}
      $condensed={condensed}
      $numericalStyle={numericalValueStyle}
      isNumber={isNumber}
      isUpperCase={isUpperCase}
      fixed={fixed}
      colSpan={colspan}
      {...rest}
    >
      {children ?? (empty ? '' : '-')}
    </S.TableCell>
  );
};
