import { customScrollMinimal, typography } from '@toggle/design-system';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const MessageContainer = styled.div`
  padding: 64px 16px 20px;
  flex-grow: 1;
  overflow-y: auto;
  ${customScrollMinimal}
`;

export const Message = styled.p`
  white-space: pre-line;
  ${typography.emu}
  &:not(:first-child) {
    margin-top: 24px;
  }
`;

export const Footer = styled.div`
  width: 100%;
  padding: 16px;
  border-top: 1px solid var(--base--80);
  align-self: flex-end;
`;
