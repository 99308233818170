import { Icon, Input } from '@toggle/design-system';
import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

import * as S from './SearchInput.styles';

export interface SearchInputProps {
  value: string;
  onChange: (value: string) => void;
}

export const SearchInput = ({ value, onChange }: SearchInputProps) => {
  const { t } = useTranslation(['common', 'chart']);
  const onSearchClear = () => onChange('');

  const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };

  return (
    <S.SearchInputRoot>
      <Input
        data-testid="search-input"
        value={value}
        placeholder={t('chart:widget.searchPlaceholder')}
        leftIcon={<Icon iconName="Search" size={20} />}
        rightIcon={
          value && (
            <S.ClearButton
              type="button"
              data-testid="clear-button"
              onClick={onSearchClear}
            >
              {t('common:clear')}
            </S.ClearButton>
          )
        }
        onChange={changeHandler}
        autoFocus
      />
    </S.SearchInputRoot>
  );
};
