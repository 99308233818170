import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { MODAL_TYPES } from '~/global/paywall/services/modal-types/modalTypes';
import { usePaywallStore } from '~/global/paywall/stores/usePaywallStore';
import { useSubscription } from '~/stores/use-subscription/useSubscription';
import { useUser } from '~/stores/use-user/useUser';
import { useBookmark } from '~/views/analyze/hooks/use-bookmark';

import * as S from './BookmarkButton.styles';

interface BookmarkButtonComponentProps {
  articleID: string;
  onBookmarkToggle?: (bookmarked: boolean) => void;
  bookmarked?: boolean;
  className?: string;
}

function BookmarkButton({
  articleID,
  bookmarked = false,
  onBookmarkToggle,
  className,
}: BookmarkButtonComponentProps) {
  const { t } = useTranslation('article');
  const isGuest = useUser(state => !state.isAuthenticated());
  const isBasic = useSubscription(state => state.isBasic());
  const { handleBookmark, isBookmarked } = useBookmark(articleID, bookmarked);
  const openModal = usePaywallStore(state => state.openModal);

  const bookmarkAction = () => {
    if (isBasic || isGuest) {
      openModal(MODAL_TYPES.BOOKMARKS);
      return;
    }

    const nextValue = !isBookmarked;
    onBookmarkToggle?.(nextValue);
    handleBookmark();
  };

  return (
    <S.BookmarkButton
      size="medium"
      onClick={bookmarkAction}
      data-testid="bookmark-button"
      className={className}
      label={t('article:bookmark')}
      iconName={isBookmarked ? 'BookmarkFill' : 'BookmarkOutline'}
      iconPosition="right"
    />
  );
}

export { BookmarkButton };
