export type Colors = typeof defaultColors;

export const defaultColors = {
  primary: '#FFB224',
  white: '#fff',
  typography: {
    soft: '#9BA1A6',
    primary: '#FFB224',
    black: '#000',
    highlight: '#fff',
  },
  border: {
    default: '#697177',
    soft: '#313538',
    hover: '#A0A0A0',
  },
  surface: {
    default: '#232323',
  },
  line: {
    default: '#FFB224',
  },
  bars: {
    up: '#63C174',
    down: '#F16A50',
  },
  threshold: {
    bullish: '#16301d',
    bearish: '#481a14',
  },
  paneGradient: '#101010',
  multi: [
    '#FFB224',
    '#849DFF',
    '#F76190',
    '#CBA393',
    '#F16A50',
    '#63C174',
    '#00C2D7',
    '#D864D8',
    '#9AA299',
    '#DBA16E',
  ],
};
