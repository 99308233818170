import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ZendeskAPI } from 'react-zendesk';

import { config } from '~/config';
import { useSubscription } from '~/stores/use-subscription/useSubscription';

import * as S from './ZendeskLauncher.styles';

const { supportUrl } = config;

interface ZendeskLauncherProps {
  className?: string;
  zendeskOnline?: boolean;
}

export const ZendeskLauncher = ({
  className,
  zendeskOnline,
}: ZendeskLauncherProps) => {
  const { t } = useTranslation('common');
  const [isWidgetClosed, setIsWidgetClosed] = useState(true);
  const isBasic = useSubscription(state => state.isBasic());

  useEffect(() => {
    ZendeskAPI('webWidget:on', 'open', () => {
      ZendeskAPI('webWidget', 'show');
      setIsWidgetClosed(false);
    });
    ZendeskAPI('webWidget:on', 'close', () => {
      ZendeskAPI('webWidget', 'hide');
      setIsWidgetClosed(true);
    });
  }, []);

  const openWidget = () => {
    ZendeskAPI('webWidget', 'open');
  };

  return (
    <>
      {isWidgetClosed && zendeskOnline && !isBasic && (
        <S.ZendeskCustomLauncher
          className={className}
          label={t('common:zendesk.buttonLabel')}
          onClick={openWidget}
        />
      )}
      {(!zendeskOnline || isBasic) && (
        <S.ZendeskCustomLauncher
          onClick={() => window.open(supportUrl, '_blank')}
          className={className}
          label={t('common:zendesk.moreHelp')}
        />
      )}
    </>
  );
};
