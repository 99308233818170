import { EntityDetails } from '@toggle/design-system';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { EllipsisDetector } from '~/shared/components/elipsis-detector/EllipsisDetector';
import { Logo } from '~/shared/components/logo/Logo';
import { MappedEntity } from '~/shared/hooks/use-entities/useEntities';
import { useWatchlist } from '~/shared/hooks/use-watchlist/useWatchlist';

import * as S from './EntityHeader.styles';

export interface EntityHeaderProps {
  entity: MappedEntity;
}

export const EntityHeader = ({ entity }: EntityHeaderProps) => {
  const { t } = useTranslation('common');
  const { isInWatchlist, updateWatchlist } = useWatchlist(entity.tag);

  return (
    <EntityDetails
      isButtonActive={isInWatchlist}
      entityNameComponent={
        <EllipsisDetector label={entity.name}>
          <S.Name>{entity.name}</S.Name>
        </EllipsisDetector>
      }
      formattedTicker={entity.entitySubheading}
      leftImgNode={<Logo entity={entity} logoUrl={entity.logo_url} />}
      buttonNode={
        <S.ActionButton
          data-testid="entity-details-btn"
          $isActive={isInWatchlist}
          size="small"
          iconName={isInWatchlist ? 'Checkmark' : undefined}
          variant="tertiary"
          label={
            isInWatchlist ? t('chat:inYourWatchlist') : t('chat:addToWatchlist')
          }
          onClick={() => updateWatchlist()}
        />
      }
    />
  );
};
