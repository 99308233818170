export enum ActionTypes {
  Loading = 'loading',
  Success = 'success',
  Error = 'error',
  Reset = 'reset',
}

export type Action<P, E> =
  | ActionRequest
  | ActionSuccess<P>
  | ActionError<E>
  | ActionReset<P>;

interface ActionRequest {
  type: ActionTypes.Loading;
}
interface ActionSuccess<P> {
  type: ActionTypes.Success;
  payload: P;
}

interface ActionError<E> {
  type: ActionTypes.Error;
  payload: E;
}

interface ActionReset<S> {
  type: ActionTypes.Reset;
  payload: S;
}

export interface APIState<D> {
  loading: boolean;
  data: D;
  error?: Error;
}

export interface HookState<D> extends APIState<D> {
  refetch: () => Promise<void>;
  resetState: () => void;
}
export type InitialData = typeof initialState.data;
type OmitInitStateType = Omit<Partial<DefaultOptions>, 'initialState'>;
export type DefaultOptionsWithoutInitState = OmitInitStateType;

interface DefaultOptions {
  shouldFetch: boolean;
  initialState: APIState<undefined>;
  deps: unknown[];
}

export interface Options<D, M> {
  mapDataFn?: (next: D, current: D | M | InitialData) => M;
  initialState?: APIState<D | M>;
  shouldFetch?: boolean;
}

export interface OptionsWithInitState<I> {
  initialState?: APIState<I>;
  shouldFetch?: boolean;
  deps?: unknown[];
}

export type PartialOptionsWithInitState<D, I, M> = OmitInitStateType & {
  mapDataFn?: (next: D, current: I | M) => M;
  initialState: APIState<I>;
};

export type OptionsWithMapFnOnly<D, M> = OmitInitStateType & {
  mapDataFn: (next: D, current: D | M) => M;
};

const initialState = {
  loading: false,
  data: undefined,
  error: undefined,
};

export const DEFAULT_OPTIONS: DefaultOptions = {
  shouldFetch: true,
  initialState,
  deps: [],
};

export const apiReducer = <S>(
  state: APIState<S>,
  action: Action<S, Error>
): APIState<S> => {
  switch (action.type) {
    case ActionTypes.Loading:
      return { ...state, loading: true, error: undefined };
    case ActionTypes.Success:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case ActionTypes.Error:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case ActionTypes.Reset:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: undefined,
      };
    default:
      return state;
  }
};
