import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { postReqResetPassword } from '~/api/auth/auth-services';
import { appPaths } from '~/routes/app-paths';

interface RequestState {
  isPending: boolean;
  isSuccess: boolean;
  isError: boolean;
}

export interface ForgotPasswordHookResult {
  email: string;
  setEmail: Dispatch<SetStateAction<string>>;
  postReqResetPassword: (email: string) => Promise<void>;
  requestState: RequestState;
}

const initialRequestState = {
  isPending: false,
  isSuccess: false,
  isError: false,
};

export function useForgotPasswordState() {
  const searchParams = useLocation().search;
  const emailToReset = useMemo(() => {
    const params = new URLSearchParams(searchParams);
    return params.get(appPaths.emailReset);
  }, [searchParams]);
  const [email, setEmail] = useState(emailToReset || '');
  const [requestState, setRequestState] = useState(initialRequestState);

  async function request(email: string) {
    setRequestState({ ...requestState, isPending: true });
    const result = await postReqResetPassword({ email });

    if (result && result.message === 'ok') {
      setRequestState({ ...initialRequestState, isSuccess: true });
    } else {
      setRequestState({ ...initialRequestState, isError: true });
    }
  }

  return {
    email,
    setEmail,
    postReqResetPassword: request,
    requestState,
  };
}
