import { useEffect, useMemo } from 'react';

import {
  SnakeMeta,
  SnakeMetaResponseV2,
  TimeSeriesData,
} from '~/api/timeseries/timeseries-schema';
import { IndexedTsPoint } from '~/shared/components/snake-chart';
import { useAPI } from '~/shared/hooks/use-api/useAPI';
import {
  fetchSnakeByName,
  fetchSnakeMetaV2,
} from '~/shared/services/overview-widget/overview-widget-service';

interface UseAoWidgetChartProps {
  entitySnake: string;
  horizon: number;
}

interface UseAoWidgetChartReturnProps {
  tsData: IndexedTsPoint[];
  snakeMeta?: SnakeMeta;
}

export const useAoWidgetChart = ({
  entitySnake,
  horizon,
}: UseAoWidgetChartProps): UseAoWidgetChartReturnProps => {
  const { data: snakeByName, resetState: resetStateSnakeByName } = useAPI(
    abortSignal => fetchSnakeByName(entitySnake, horizon, abortSignal),
    {
      mapDataFn: ({ result, meta }: TimeSeriesData) => ({
        tsData: result.data,
        meta,
      }),
      deps: [entitySnake, horizon],
    }
  );

  const { data: snakeMetaV2, resetState: resetStateSnakeMetaV2 } = useAPI(
    abortSignal => fetchSnakeMetaV2([entitySnake], abortSignal),
    {
      mapDataFn: (result: SnakeMetaResponseV2) => ({
        meta: result[entitySnake],
      }),
      deps: [entitySnake, horizon],
    }
  );

  useEffect(() => {
    resetStateSnakeByName();
    resetStateSnakeMetaV2();
  }, [entitySnake, horizon]);

  const tsData: IndexedTsPoint[] = useMemo(() => {
    if (!snakeByName?.tsData) {
      return [];
    }
    return snakeByName.tsData.map((p, i) => {
      return {
        index: i,
        value: p.value,
        date: new Date(p.index),
      };
    });
  }, [snakeByName?.tsData]);

  return {
    tsData,
    snakeMeta: snakeMetaV2?.meta,
  };
};
