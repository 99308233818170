import React from 'react';

import { Zendesk } from '~/shared/components/zendesk';
import { HelperWidget } from '~/views/helper/helper-widget/HelperWidget';

import * as S from './Helper.styles';

export const Helper = () => {
  return (
    <S.Helper>
      <HelperWidget />
      <Zendesk />
    </S.Helper>
  );
};
