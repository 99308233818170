import classNames from 'classnames';
import React, { cloneElement, ReactElement, ReactNode } from 'react';

import { StyledComponent } from '~/common/styled-component';

import { IconProps } from '../icon/Icon';
import { TooltipProps } from '../tooltip/Tooltip';
import * as S from './RadioGroup.styles';

export interface RadioGroupItem {
  id: string;
  testId?: string;
  label: ReactNode;
  name?: string;
  tooltip?: ReactElement<Omit<TooltipProps, 'children'>>;
  error?: boolean;
  icon?: IconProps;
  disabled?: boolean;
  locked?: boolean;
}

export interface RadioGroupProps {
  items: RadioGroupItem[];
  activeId?: string;
  direction?: 'column' | 'row';
  size?: 'medium' | 'small';
  groupLabel?: string;
  className?: string;
  onClick?: (id: string, idx: number) => void;
  onChange?: (id: string, idx: number) => void;
}

export const RadioGroup: StyledComponent<RadioGroupProps, typeof S> = ({
  className,
  items,
  activeId,
  groupLabel,
  onChange,
  onClick,
  direction = 'column',
  size = 'medium',
}) => {
  return (
    <S.Container className={className}>
      {groupLabel && <S.GroupLabel>{groupLabel}</S.GroupLabel>}
      <S.GroupContainer $direction={direction} role="radiogroup">
        {items.map((item, idx) => {
          const TooltipWithLabel = item.tooltip
            ? cloneElement(
                item.tooltip,
                {
                  placement: item.tooltip.props.placement ?? 'top-start',
                  inPortal: true,
                  ...item.tooltip.props,
                },
                <S.Label $withTooltip>{item.label}</S.Label>
              )
            : null;

          const testId = item.testId ?? item.id;
          return (
            <S.RadioLabel
              key={item.id}
              className={classNames(item.id === activeId && 'checked')}
              $locked={!!item.locked}
              $disabled={!!item.disabled}
              $size={size}
              data-testid={testId}
              htmlFor={item.id}
            >
              {item.locked && <S.LockIcon iconName="LockLight" size={20} />}
              <S.RadioInput
                type="radio"
                data-testid={`radio-${testId}`}
                id={item.id}
                value={item.id}
                $locked={!!item.locked}
                disabled={!!item.disabled}
                $error={!!item.error}
                onChange={_ => onChange?.(item.id, idx)}
                onClick={onClick && (_ => onClick(item.id, idx))}
                checked={item.id === activeId}
              />
              <S.LabelContainer>
                <>
                  {item.icon && <S.StyledIcon {...item.icon} />}
                  {TooltipWithLabel ?? (
                    <S.Label $withTooltip={false}>{item.label}</S.Label>
                  )}
                </>
              </S.LabelContainer>
            </S.RadioLabel>
          );
        })}
      </S.GroupContainer>
    </S.Container>
  );
};

RadioGroup.Styled = S;
