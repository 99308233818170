import { ChatStreamChunk } from '~/views/chat/services/readable-streams/readable-streams.types';

export default function convertChunksToJson(
  inputString: string
): ChatStreamChunk[] {
  const lines = inputString.trim().split('\n');

  return lines.reduce((result, line) => {
    try {
      if (line) {
        const singleChunk = JSON.parse(line);
        result.push(singleChunk);
      }
      return result;
    } catch (error) {
      return [];
    }
  }, [] as ChatStreamChunk[]);
}
