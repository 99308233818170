export interface ComparisonTable {
  section: string;
  data: ComparisonTableData[];
}

export interface ComparisonTableData {
  name: string;
  description: string;
  feature: ComparisonTableFeature[];
}

interface ComparisonTableFeature {
  isIncluded: boolean;
  text?: string;
}

export const comparisonTableData = (): ComparisonTable[] => {
  return [
    assetCoverageTableData,
    aiInsightsTableData,
    monitoringTableData,
    analyticalToolsData,
    indicatorsTableData,
    othersTableData,
  ];
};

const onlyProFeature = [
  {
    isIncluded: false,
  },
  {
    isIncluded: false,
  },
  {
    isIncluded: true,
  },
];

const copilotAndProFeature = [
  {
    isIncluded: false,
  },
  {
    isIncluded: true,
  },
  {
    isIncluded: true,
  },
];

export const assetCoverageData = [
  {
    name: 'subscription:planMatrix.comparisonTable.equities',
    description: 'subscription:planMatrix.comparisonTable.stocksEtfs',
    feature: [
      {
        isIncluded: true,
        text: 'subscription:planMatrix.comparisonTable.limitedAccess',
      },
      {
        isIncluded: true,
      },
      {
        isIncluded: true,
      },
    ],
  },
  {
    name: 'subscription:planMatrix.comparisonTable.otherAssetClasses',
    description: 'subscription:planMatrix.comparisonTable.futuresFx',
    feature: onlyProFeature,
  },
  {
    name: 'subscription:planMatrix.comparisonTable.crypto',
    description: 'subscription:planMatrix.comparisonTable.cryptoDescription',

    feature: [
      {
        isIncluded: true,
        text: 'subscription:planMatrix.comparisonTable.limitedAccess',
      },
      {
        isIncluded: true,
      },
      {
        isIncluded: true,
      },
    ],
  },
];

export const starInsightsData = [
  {
    name: 'subscription:planMatrix.comparisonTable.fiveStar',
    description: 'subscription:planMatrix.comparisonTable.fiveStarDescription',
    feature: [
      {
        isIncluded: true,
        text: 'subscription:planMatrix.comparisonTable.limited',
      },
      {
        isIncluded: true,
      },
      {
        isIncluded: true,
      },
    ],
  },
  {
    name: 'subscription:planMatrix.comparisonTable.sixEightStar',
    description:
      'subscription:planMatrix.comparisonTable.sixEightStarDescription',
    feature: copilotAndProFeature,
  },
];

export const savedFiltersData = [
  {
    name: 'subscription:planMatrix.comparisonTable.savedFilters',
    description: 'subscription:planMatrix.comparisonTable.filtersHelp',
    feature: copilotAndProFeature,
  },
];

export const aiNewsData = [
  {
    name: 'subscription:planMatrix.comparisonTable.aiNews',
    description: 'subscription:planMatrix.comparisonTable.aiNewsDescription',
    feature: copilotAndProFeature,
  },
];

export const marketIndicatorsData = [
  {
    name: 'subscription:planMatrix.comparisonTable.tli',
    description: 'subscription:planMatrix.comparisonTable.tliDescription',
    feature: copilotAndProFeature,
  },
  {
    name: 'subscription:planMatrix.comparisonTable.rangefinder',
    description:
      'subscription:planMatrix.comparisonTable.rangefinderDescription',
    feature: onlyProFeature,
  },
  {
    name: 'subscription:planMatrix.comparisonTable.ptIndex',
    description: 'subscription:planMatrix.comparisonTable.ptIndexDescription',
    feature: onlyProFeature,
  },
  {
    name: 'subscription:planMatrix.comparisonTable.candleBreadth',
    description:
      'subscription:planMatrix.comparisonTable.candleBreadthDescription',
    feature: onlyProFeature,
  },
  {
    name: 'subscription:planMatrix.comparisonTable.marketPhaseShift',
    description:
      'subscription:planMatrix.comparisonTable.marketPhaseDescription',
    feature: onlyProFeature,
  },
  {
    name: 'subscription:planMatrix.comparisonTable.aggregated',
    description:
      'subscription:planMatrix.comparisonTable.aggregatedDescription',
    feature: onlyProFeature,
  },
];

export const watchlistData = [
  {
    name: 'subscription:planMatrix.comparisonTable.watchlist',
    description: 'subscription:planMatrix.comparisonTable.addingAssets',
    feature: [
      {
        isIncluded: true,
        text: 'subscription:planMatrix.comparisonTable.limited',
      },
      {
        isIncluded: true,
      },
      {
        isIncluded: true,
      },
    ],
  },
];

export const articleData = [...starInsightsData, ...aiNewsData];

export const chartData = [
  {
    name: 'subscription:planMatrix.comparisonTable.chartingTool',
    description: 'subscription:planMatrix.comparisonTable.ourHedgeFund',
    feature: copilotAndProFeature,
  },
];

export const monitoringSectionTitle =
  'subscription:planMatrix.comparisonTable.monitoring';
export const assetCoverageSectionTitle =
  'subscription:planMatrix.comparisonTable.assetCoverage';
export const aiInsightsCoverageSectionTitle =
  'subscription:planMatrix.comparisonTable.aiInsights';
export const marketIndicatorsSectionTitle =
  'subscription:planMatrix.comparisonTable.indicators';
export const analyticalToolsSectionTitle =
  'subscription:planMatrix.comparisonTable.analyticalTools';

const assetCoverageTableData = {
  section: assetCoverageSectionTitle,
  data: assetCoverageData,
};

const aiInsightsTableData = {
  section: aiInsightsCoverageSectionTitle,
  data: articleData,
};

const monitoringTableData = {
  section: monitoringSectionTitle,
  data: [
    {
      name: 'subscription:planMatrix.comparisonTable.realTimePrices',
      description: 'subscription:planMatrix.comparisonTable.livePrices',
      feature: copilotAndProFeature,
    },
    ...watchlistData,
    ...savedFiltersData,
    {
      name: 'subscription:planMatrix.comparisonTable.realTimeNotifications',
      description: 'subscription:planMatrix.comparisonTable.inAppPush',
      feature: copilotAndProFeature,
    },
    {
      name: 'subscription:planMatrix.comparisonTable.realTimeCrypto',
      description: 'subscription:planMatrix.comparisonTable.livePriceCrypto',
      feature: copilotAndProFeature,
    },
  ],
};

const analyticalToolsData = {
  section: analyticalToolsSectionTitle,
  data: [
    {
      name: 'subscription:planMatrix.comparisonTable.scenarioAnalysis',
      description: 'subscription:planMatrix.comparisonTable.testHypotheses',
      feature: onlyProFeature,
    },
    {
      name: 'subscription:planMatrix.comparisonTable.chartCommander',
      description: 'subscription:planMatrix.comparisonTable.commandChart',
      feature: onlyProFeature,
    },
    ...chartData,
  ],
};

const indicatorsTableData = {
  section: marketIndicatorsSectionTitle,
  data: marketIndicatorsData,
};

const othersTableData = {
  section: 'subscription:planMatrix.comparisonTable.others',
  data: [
    {
      name: 'subscription:planMatrix.comparisonTable.dailyEmail',
      description: 'subscription:planMatrix.comparisonTable.belovedEmail',
      feature: [
        {
          isIncluded: true,
        },
        {
          isIncluded: true,
        },
        {
          isIncluded: true,
        },
      ],
    },
    {
      name: 'subscription:planMatrix.comparisonTable.customerSupport',
      description: 'subscription:planMatrix.comparisonTable.talkToHuman',
      feature: copilotAndProFeature,
    },
  ],
};
