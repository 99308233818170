import styled, { css } from 'styled-components';

import { Badge } from '~/components/badge/Badge';

export const ListOption = styled.li<{
  $hasV2Styles?: boolean;
  $isDisabled: boolean;
}>`
  cursor: ${props => !props.$isDisabled && 'pointer'};
  list-style-type: none;
  ${({ $hasV2Styles }) =>
    !$hasV2Styles &&
    css`
      &.focused,
      &.active,
      &:hover {
        color: var(--primary-color);
        background-color: var(--primary-hover-8pc);

        ${Badge.Styled.BadgeTextual} {
          color: var(--primary-color);
          border-color: var(--border-primary);
        }
      }
    `}
`;
