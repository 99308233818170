import { typography } from '@toggle/design-system';
import styled, { css } from 'styled-components';

export const LeftPart = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
  width: 77%;
  padding-left: 12px;
  [class*='label'] {
    align-self: center;
  }
`;

const sharedText = css`
  height: 100%;
  display: inline-block;
  padding-right: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const LeftText = styled.span`
  ${sharedText}
  ${typography.dingo}
  flex-basis: 40%;
  padding-left: 12px;
`;

export const RightText = styled.span`
  ${sharedText}
  flex-basis: 60%;
  flex-grow: 1;
`;

export const HighlightedMark = styled.mark`
  background-color: transparent;
  color: var(--primary-color);
`;
