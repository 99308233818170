import { TFunction } from 'i18next';
import React from 'react';

import { Video } from '~/shared/components/video/Video';

import { HELPER_VIDEO_LINKS } from '../../../HelperConfig';
import * as S from '../Content.styles';

interface AssetOverviewIntroProps {
  onVideoClick: () => void;
  t: TFunction;
}

export function AssetOverviewIntro({
  onVideoClick,
  t,
}: AssetOverviewIntroProps) {
  return (
    <div data-testid="asset-overview">
      <S.Topic>
        {t('helperWidget:analyze.content.assetOverviewIntro.title')}
      </S.Topic>
      <S.VideoWrapper>
        <Video
          src={HELPER_VIDEO_LINKS.ANALYZE_LINK}
          onVideoClick={onVideoClick}
        />
      </S.VideoWrapper>
    </div>
  );
}
