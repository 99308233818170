import { formatAssetPrice } from '~/shared/utils/currency/currency';

import { UserStatement } from '../fetch-user-statements/fetchUserStatements';

export interface PortfolioPnL {
  amountValue: string;
  percentValue: string;
  hasProfit?: boolean;
}

export const calculatePnL = (
  currentPrice: number,
  purchasePrice: number,
  numShares: number
) => {
  const delta = currentPrice - purchasePrice;
  const profitLoss = delta * numShares;
  let profitLossPercentage = (delta / purchasePrice) * 100;

  const isShort = numShares < 0;
  if (isShort) {
    profitLossPercentage = -profitLossPercentage;
  }

  return {
    profitLoss,
    profitLossPercentage,
  };
};

export const formatPnL = (
  currentPrice: number,
  purchasePrice: number,
  currency: string,
  numShares = 1
) => {
  const { profitLoss, profitLossPercentage } = calculatePnL(
    currentPrice,
    purchasePrice,
    numShares
  );

  const hasProfit = profitLoss >= 0;
  const sign = hasProfit ? '+' : '-';

  return {
    amountValue: `${sign}${formatAssetPrice(Math.abs(profitLoss), currency)}`,
    percentValue: `${profitLossPercentage.toFixed(2)}%`,
    hasProfit,
  };
};

export const calculateTodayPnL = (
  userStatement: UserStatement,
  livePrice?: number
): PortfolioPnL => {
  const {
    price,
    position,
    security: { currency },
  } = userStatement;

  if (!livePrice) {
    return {
      amountValue: '',
      percentValue: '',
    };
  }

  const currentPrice = livePrice;
  const purchasePrice = price;

  return formatPnL(currentPrice, purchasePrice, currency, position);
};

export const calculateTotalPnL = (
  userStatement: UserStatement,
  livePrice = 0
): PortfolioPnL => {
  const {
    average_cost,
    price,
    position,
    security: { currency },
  } = userStatement;

  const currentPrice = livePrice || price;
  const purchasePrice = average_cost;

  return formatPnL(currentPrice, purchasePrice, currency, position);
};
