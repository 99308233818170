import {
  deviceConstraints,
  Icon,
  typography,
  typographyV2,
} from '@toggle/design-system';
import styled, { css } from 'styled-components';

import { Collapse } from '~/shared/components/collapse';

export const StyledIcon = styled(Icon).attrs({
  size: 20,
  fillColor: 'var(--base--20)',
})`
  margin-right: 8px;
`;

export const ShareMenuContainer = styled.div<{ $isActive: boolean }>`
  width: 100%;
  border: 1px solid rgba(255, 255, 255, 0.16);
  border-radius: 4px;
  margin-top: 0;
  text-align: left;
  padding: 0;
  height: auto;

  &:hover {
    border-color: var(--menu-active-item-border);
  }

  ${({ $isActive }) =>
    $isActive &&
    css`
      border-color: var(--menu-active-item-border);
      cursor: pointer;
    `}
`;

export const ShareMenuHeader = styled.div<{ $isActive: boolean }>`
  ${typography.emu}
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-transform: uppercase;
  color: var(--base--20);
  @media ${deviceConstraints.tablet} {
    padding: 12px;
  }

  &:hover {
    background-color: rgba(var(--menu-active-item-border-rgb), 0.4);
    color: var(--primary-color);
    svg {
      fill: var(--primary-color);
    }
  }
  ${({ $isActive }) =>
    $isActive &&
    css`
      color: var(--primary-color);
      svg {
        fill: var(--primary-color);
      }
      border-color: var(--menu-active-item-border);
      border-bottom: 1px solid;
    `}
`;

export const CollapseWrapper = styled(Collapse)`
  > * {
    display: block;
    width: 100%;
  }
`;

export const ShareMenuItem = styled.div`
  ${typographyV2.bodyBase}
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 40px;
  border: none;
  margin: 0;
  padding: 0 16px;
  color: var(--text-default);
  text-transform: uppercase;
  border-radius: 0;

  &:hover {
    background-color: rgba(var(--menu-active-item-border-rgb), 0.4);
    color: var(--primary-color);
    svg {
      fill: var(--primary-color);
    }
  }
`;
