import { DependencyList, useMemo, useRef } from 'react';

import { OptionalU } from '~/types';

export const usePreviousMemo = <R>(
  cb: (prev: OptionalU<R>) => R,
  deps: DependencyList,
  compare?: (a: R, b: R) => boolean
) => {
  const prev = useRef<R>();
  return useMemo(() => {
    const data = cb(prev.current);
    if (prev.current && compare && compare(data, prev.current)) {
      return prev.current;
    }
    prev.current = data;
    return data;
  }, deps);
};
