import {
  ChartAssetData,
  chartResamples,
  Domain,
  DomainChangeEvent,
  isIntradayResample,
  PaneData,
  RangeHorizon,
  RESAMPLE_DEFAULT,
  ResampleIntervals,
} from '@toggle/chart';
import { Entity } from '@toggle/toggle';
import { differenceInCalendarMonths } from 'date-fns';
import { Dispatch, SetStateAction, useCallback, useRef, useState } from 'react';

import { getPrices } from '~/api/price/price-service';

import {
  ChartSearchParamsProps,
  ChartSettings,
} from '../../utils/chart-settings-utils.ts/chart-settings-utils';
import { updatePrimaryPane } from '../../utils/chart-utils/chart-utils';
import {
  allowedResamplesForHorizon,
  createNewResampleData,
  getResampleCacheKey,
  getResampleForHorizon,
  shouldDisable1DHorizon,
} from '../../utils/resample-utils/resample-utils';

export interface UseResampleProps {
  chartPanes: PaneData[];
  chartData: ChartAssetData[];
  hasIndicatorInList: boolean;
  setHasError: Dispatch<SetStateAction<boolean>>;
  setChartPanes: Dispatch<SetStateAction<PaneData[]>>;
  chartSettings: ChartSettings;
  handleEmptyChart: (text: string) => void;
  updateSearchParams: (props: Partial<ChartSearchParamsProps>) => void;
}

// eslint-disable-next-line max-lines-per-function
export const useResample = ({
  chartPanes,
  chartData,
  hasIndicatorInList,
  setHasError,
  setChartPanes,
  handleEmptyChart,
  chartSettings,
  updateSearchParams,
}: UseResampleProps) => {
  const [isIntradayEnabled, setIsIntradayEnabled] = useState(false);
  const [emptyResamples, setEmptyResamples] = useState<ResampleIntervals[]>([]);
  const resamplesData = useRef<
    Record<ReturnType<typeof getResampleCacheKey>, ChartAssetData>
  >({});
  const emptyAssetRef = useRef<Entity>();

  const { horizon: selectedRange, resample: selectedResample } = chartSettings;
  const disable1DHorizon = shouldDisable1DHorizon({
    chartData,
    emptyResamples,
    resample: selectedResample,
    hasIndicatorInList,
  });
  const enabledResamples = chartResamples.filter(
    item =>
      !emptyResamples.includes(item) &&
      (isIntradayResample(item) ? isIntradayEnabled : true)
  );

  const getCachedResampleData = (
    chartData: ChartAssetData[],
    resample: ResampleIntervals
  ) =>
    chartData.map(item => ({
      ...resamplesData.current[getResampleCacheKey(item, resample)],
      //handle case when cache value has a different color/isHidden from the current one in chartData
      lineColorOverride: item.lineColorOverride,
      isHidden: item.isHidden,
    }));

  const updateChartData = (
    chartPanes: PaneData[],
    resample = selectedResample
  ) => {
    setChartPanes(chartPanes);
    if (chartPanes[0].chartAssetData.length) {
      updateResampleCache(chartPanes[0].chartAssetData, resample);
    }
  };

  const updateResampleCache = (
    data: ChartAssetData[],
    resample = selectedResample
  ) => {
    data.forEach(item => {
      resamplesData.current = {
        ...resamplesData.current,
        [getResampleCacheKey(item, resample)]: item,
      };
    });
  };

  const enableIntradayResamples = (
    domain: Domain,
    chartData: ChartAssetData[]
  ) => {
    const ts = chartData[0].ts;
    const lastItem = ts[ts.length - 1];
    const datesDomain: [Date, Date] = [
      new Date(ts[domain[0]].time),
      new Date(ts[domain[1]].time),
    ];
    const showIntraday =
      differenceInCalendarMonths(new Date(lastItem.time), datesDomain[0]) <= 1;
    setIsIntradayEnabled(showIntraday);
  };

  //useCallback is used as this function will be used as a deps in useEffect
  const onDomainChange = useCallback(
    (event: Event) => {
      const { domain, roundedDomain, isSameDomain } = (
        event as DomainChangeEvent
      ).detail;
      updateSearchParams({
        horizon: isSameDomain ? undefined : null,
        domain,
      });

      if (!disable1DHorizon) {
        enableIntradayResamples(roundedDomain, chartData);
      }
    },
    [emptyResamples, chartSettings, chartData]
  );

  const changeRange = async (range: RangeHorizon) => {
    const isSameSelected = selectedRange && selectedRange === range;
    if (isSameSelected) {
      return;
    }

    const resample = getResampleForHorizon({
      selectedResample,
      hasIndicatorInList,
      range,
      emptyResamples,
    });
    if (resample === null) {
      return;
    }
    changeResample(resample, range);
  };

  const changeResample = async (
    resample: ResampleIntervals,
    range = selectedRange
  ) => {
    const data = await fetchResampleIfNeeded(chartData, resample);
    const isSameData = data === chartData;

    if (emptyAssetRef.current) {
      const isResampleAllowed =
        !range || allowedResamplesForHorizon[range].includes(selectedResample);
      const nextResample = isResampleAllowed
        ? selectedResample
        : RESAMPLE_DEFAULT;
      handleEmptyChart(`${resample} ${emptyAssetRef.current.name}`);
      setEmptyResamples([...emptyResamples, resample]);
      if (!isResampleAllowed && range === RangeHorizon.OneDay) {
        return;
      }
      const newChartPanes = updatePrimaryPane(
        chartPanes,
        getCachedResampleData(chartData, nextResample)
      );
      setChartPanes(newChartPanes);
      updateSearchParams({
        resample: selectedResample,
        horizon: range,
      });
    } else if (!isSameData) {
      const newChartPanes = updatePrimaryPane(chartPanes, data);
      updateChartData(newChartPanes, resample);
      updateSearchParams({
        chartPanes: newChartPanes,
        resample,
        horizon: range === selectedRange ? null : range,
      });
    }
  };

  const fetchResampleIfNeeded = async (
    chartData: ChartAssetData[],
    resample: ResampleIntervals
  ) => {
    const unCachedChartData = chartData.filter(
      item => !resamplesData.current[getResampleCacheKey(item, resample)]
    );

    if (unCachedChartData.length) {
      try {
        setHasError(false);
        const prices = await fetchNewResampleData(unCachedChartData, resample);
        const emptyAssetIndex = prices.findIndex(item => !item.length);
        const emptyAsset = unCachedChartData[emptyAssetIndex];
        const data = emptyAsset
          ? chartData
          : createNewResampleData({
              unCachedChartData,
              chartData,
              resample,
              prices,
              resamplesData: resamplesData.current,
            });
        emptyAssetRef.current = emptyAsset?.entity;
        return data;
      } catch {
        emptyAssetRef.current = undefined;
        setHasError(true);
        return chartData;
      }
    }

    emptyAssetRef.current = undefined;
    return getCachedResampleData(chartData, resample);
  };

  const fetchNewResampleData = async (
    unCachedChartData: ChartAssetData[],
    resample: ResampleIntervals
  ) =>
    Promise.all(
      unCachedChartData.map(c =>
        getPrices({
          ticker: c.entity.subscribable_ticker,
          entityTag: c.entity.tag,
          resolution: resample,
        })
      )
    );

  return {
    selectedRange,
    changeRange,
    updateChartData,
    onDomainChange,
    disable1DHorizon,
    resample: {
      selectedResample,
      enabledResamples,
      emptyResamples,
      changeResample,
      fetchResampleIfNeeded,
      setEmptyResamples,
    },
  };
};
