import { Icon } from '@toggle/design-system';
import React, { ReactChild, RefObject } from 'react';

import { FilterScreenPopup } from '~/views/explore/filter-screen-popup/FilterScreenPopup';
import * as S from '~/views/explore/filter-screens/FilterScreens.styles';
import { FilterScreen } from '~/views/explore/store/use-explore-filters';

export interface ScreensTabsSelectProps {
  toggleTabSelect: () => void;
  activeScreen: FilterScreen;
  togglePopup: () => void;
  saveAction: () => void;
  activeScreenId: string;
  screenChanged: boolean;
  revertScreen: () => void;
  onRename: () => void;
  onDelete: () => void;
  onHide: () => void;
  parentRef?: RefObject<HTMLElement>;
  homeTabLabel?: ReactChild;
}

export const ScreensTabsSelect = ({
  toggleTabSelect,
  activeScreen,
  togglePopup,
  saveAction,
  activeScreenId,
  screenChanged,
  revertScreen,
  onRename,
  onDelete,
  onHide,
  parentRef,
  homeTabLabel,
}: ScreensTabsSelectProps) => {
  const showMenuBtn =
    !!activeScreen.originFilters.length || !!activeScreen.filters.length;

  return (
    <S.TabsMobileSelect data-testid="screens-tabs-select">
      <S.TabMobileSelectBtn
        onClick={toggleTabSelect}
        data-testid="screen-mobile-select-btn"
      >
        <S.MobileSelectName>
          {activeScreen.name || homeTabLabel}
        </S.MobileSelectName>
        <S.MobileSelectChevronIcon iconName="ChevronLightDown" size={16} />
      </S.TabMobileSelectBtn>
      {showMenuBtn && (
        <S.TabMobileMenuBtn
          as="span"
          onClick={activeScreen.originFilters.length ? togglePopup : saveAction}
          data-testid="screen-mobile-menu-btn"
        >
          <Icon
            iconName={activeScreen.originFilters.length ? 'Ellipsis' : 'Save'}
            fillColor="var(--primary-color)"
            size={16}
          />

          {activeScreenId && (
            <FilterScreenPopup
              isTouchDevice
              showSave={screenChanged && !!activeScreen.filters.length}
              showRevert={screenChanged}
              onSave={saveAction}
              onRevert={revertScreen}
              onRename={onRename}
              onDelete={onDelete}
              onHide={onHide}
              outsideClickRef={parentRef}
            />
          )}
        </S.TabMobileMenuBtn>
      )}
    </S.TabsMobileSelect>
  );
};
