import { Icon } from '@toggle/design-system';
import React, { FC, ReactNode } from 'react';

import * as S from './PlanCardFeatureItem.styles';

interface PlanCardFeatureItemProps {
  children: ReactNode;
}

export const PlanCardFeatureItem: FC<PlanCardFeatureItemProps> = ({
  children,
}) => {
  return (
    <S.Item>
      <Icon iconName="Checkmark" size={20} />
      {children}
    </S.Item>
  );
};
