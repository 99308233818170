import { Button, deviceConstraints } from '@toggle/design-system';
import styled from 'styled-components';

import { Collapse } from '~/shared/components/collapse/Collapse';

export const CollapseWrapper = styled(Collapse)<{ $collapsed: boolean }>`
  margin-bottom: ${({ $collapsed }) => ($collapsed ? '40px' : '56px')};
`;

export const ButtonWrapper = styled(Button)`
  display: flex;
  align-items: center;
  font-size: 18px;
  margin-left: 16px;
  @media ${deviceConstraints.tablet} {
    margin-left: 0px;
  }
`;
