import { numberWithCommas } from '@toggle/helpers';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ArticleSummary } from '~/declarations/toggle-api';
import { useMetricsBreadcrumbs } from '~/shared/hooks/use-metrics-breadcrumbs/useMetricsBreadcrumbs';

import * as S from './ExploreFeedHeader.styles';

interface ExploreFeedHeaderProps {
  dataNotEmpty: boolean;
  filtersApplied: boolean;
  isLoadingFilters: boolean;
  insightCount: number;
  feedData: ArticleSummary[];
}

export const ExploreFeedHeader = ({
  dataNotEmpty,
  filtersApplied,
  isLoadingFilters,
  insightCount,
  feedData,
}: ExploreFeedHeaderProps) => {
  const { t } = useTranslation(['explore']);
  const { data: metrics } = useMetricsBreadcrumbs();

  return !isLoadingFilters && dataNotEmpty ? (
    <S.HeaderSection>
      <S.FilteredInsightsLabel data-testid="header-all-insights-label">
        {filtersApplied ? (
          <>
            <span>{insightCount} </span>
            {t('explore:header.filteredInsightsLabel')}
            {!!feedData.length &&
              insightCount === 0 &&
              t('explore:header.filteredOldInsightsLabel')}
          </>
        ) : (
          !!metrics?.articles_produced && (
            <>
              <span>{numberWithCommas(metrics.articles_produced)} </span>
              {t('explore:header.newInsightsToday')}
            </>
          )
        )}
      </S.FilteredInsightsLabel>
    </S.HeaderSection>
  ) : null;
};
