import styled, { keyframes } from 'styled-components';

interface Props {
  animationDelay: string;
  animationDuration: string;
  isFrozen?: boolean;
}

const progress = keyframes`
    from {
      width: 0%;
    }
    to {
      width: 100%;
    }
`;

export const Bar = styled.div`
  height: 4px;
  width: 100%;
  position: relative;
  background: var(--base--90);
`;

export const Line = styled.div<Props>`
  height: 100%;
  width: 0%;
  border-radius: 2px;
  animation-name: ${progress};
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  animation-delay: ${({ animationDelay }) => animationDelay};
  animation-duration: ${({ animationDuration }) => animationDuration};
  background-color: var(--primary-color);
  animation-play-state: ${({ isFrozen }) => isFrozen && 'paused'};
`;
