import { TFunction } from 'i18next';
import uniqBy from 'lodash/uniqBy';

import { Class } from '~/api/entities/entity-constants';
import { Entity } from '~/api/entities/entity-schema';
import { SnakeMetaResponseV2 } from '~/api/timeseries/timeseries-schema';
import { OptionalN } from '~/declarations/standard';
import { ArticleSummary } from '~/declarations/toggle-api';
import {
  ArticleDirection,
  ExploreArticleDirection,
} from '~/declarations/toggle-api-enums';
import { News, NewsItem } from '~/declarations/toggle-api-news';
import { METHOD_SUB_CLS_GROUPING } from '~/global/method_sub_cls_grouping';
import { formatEntity, MappedEntity } from '~/shared/hooks/use-entities';
import { ApiFetchResponse } from '~/shared/services/api-fetch/APIFetch';
import { priceSuffix } from '~/shared/utils/currency/currency';
import { isEntityFromUS } from '~/shared/utils/entity-from-usa/isEntityFromUS';

import { cardDirection } from '../../asset-overview/components/sidebar/components/bullish-bearish-card/BullishBearishCard';
import { getInsightCardConfig } from '../../InsightCardsConfig';
import { RelatedAssetType } from './useRelatedAssets';

export const getInsightsInfo = (
  news: NewsItem[],
  articles: ArticleSummary[],
  entity: MappedEntity,
  t: TFunction
) => {
  let analysisType = '',
    direction: OptionalN<ExploreArticleDirection | ArticleDirection> = null,
    recentArticleInsightDate = '';

  const articlesInsights = uniqBy(
    articles,
    a =>
      getInsightCardConfig(a.insight_card, cardDirection(a.direction), t).name
  );

  const newsInsights =
    entity.class === Class.ClassStock && isEntityFromUS(entity) ? news : [];

  if (
    articlesInsights[0]?.created_on > newsInsights[0]?.publication_date ||
    (articlesInsights.length && !newsInsights.length)
  ) {
    recentArticleInsightDate = articlesInsights[0].created_on;
    analysisType = METHOD_SUB_CLS_GROUPING[articlesInsights[0].type_name];
    direction = articlesInsights[0].direction;
  } else if (
    articlesInsights[0]?.created_on < newsInsights[0]?.publication_date ||
    (newsInsights.length && !articlesInsights.length)
  ) {
    analysisType = 'group_news';
    direction = newsInsights[0].direction;
  }

  return {
    analysisType,
    direction,
    recentArticleInsightDate,
    articlesInsightsCount: articlesInsights.length,
    newsInsightsCount: newsInsights.length,
    totalInsightsExcludingRecentCount: Math.max(
      articlesInsights.length + newsInsights.length - 1,
      0
    ),
  };
};

export const batchRequestHandler = <T>(p: ApiFetchResponse<T>) => {
  return (p instanceof Error ? {} : p) as T;
};

export const mapRelatedEntity = ({
  entity,
  snakeMeta,
  articles = [],
  news = {},
  t,
}: {
  entity: Entity;
  snakeMeta: SnakeMetaResponseV2;
  news?: News;
  articles?: ArticleSummary[];
  t: TFunction;
}): RelatedAssetType => {
  const meta = snakeMeta[entity.default_snake];
  const formattedEntity = formatEntity(entity);
  const entityNews = news[entity.ticker] || [];
  const {
    totalInsightsExcludingRecentCount,
    articlesInsightsCount,
    newsInsightsCount,
    analysisType,
    direction,
    recentArticleInsightDate,
  } = getInsightsInfo(entityNews, articles, formattedEntity, t);

  return {
    id: entity.id,
    analysisType,
    recentArticleInsightDate,
    direction,
    newInsightsCount: totalInsightsExcludingRecentCount,
    hasNewArticles: articlesInsightsCount > 0,
    hasNewsHighlights: newsInsightsCount > 0,
    name: formattedEntity.name_short || formattedEntity.name,
    priceBefore: meta?.before_last_value,
    latestPrice: meta?.last_value,
    lastTimestamp: meta?.last_timestamp,
    entity: formattedEntity,
    priceSuffix: priceSuffix(
      meta?.display_format || '',
      formattedEntity.currency
    ),
    tag: formattedEntity.ticker
      ? formattedEntity.ticker.toUpperCase()
      : formattedEntity.name_short,
  };
};
