import { getCookie, setCookie } from '@toggle/helpers';
import { endOfDay } from 'date-fns';
import { useMemo } from 'react';

export const setEndOfDayCookie = (key: string) => {
  const date = new Date();
  const expires = endOfDay(new Date());
  setCookie(key, date.toISOString(), { expires });
};

export const useIsTagNewToday = (tag: string) => {
  return useMemo(() => {
    const cookie = getCookie(tag);
    if (cookie) {
      return new Date(cookie);
    } else {
      setEndOfDayCookie(tag);
      return undefined;
    }
  }, [tag]);
};
