import React, { FC } from 'react';

import { Icon } from '~/components/icon/Icon';

import {
  Category,
  CategoryGroup,
} from '../side-navigation/components//category-group/CategoryGroup';
import * as S from './SideNavigationV2.styles';

export const EXPANDED_WIDTH_PX = '200px';
export const COLLAPSED_WIDTH_PX = '68px';

export interface SideNavigationV2Props {
  activeKey?: string;
  categories: Category[];
  className?: string;
  disabledKeys?: string[];
  isExpanded?: boolean;
  onLogoClick: () => void;
  onItemClick: (id: string) => void;
  onSidebarToggle?: () => void;
}

export const SideNavigationV2: FC<SideNavigationV2Props> = ({
  activeKey,
  categories,
  className,
  disabledKeys,
  isExpanded = true,
  onLogoClick,
  onItemClick,
  onSidebarToggle,
}) => {
  return (
    <S.AsideExpandable
      className={className}
      data-testid="side-navigation-v2"
      isExpanded={isExpanded}
    >
      <S.Header>
        <S.LogoButton onClick={onLogoClick}>
          <S.StyledLogotype isExpanded={isExpanded} />
        </S.LogoButton>

        <S.ToggleButtonWrapper>
          <S.ToggleButton isExpanded={isExpanded} onClick={onSidebarToggle}>
            <Icon iconName="ChevronBoldRight" size={8} />
          </S.ToggleButton>
        </S.ToggleButtonWrapper>
      </S.Header>

      <S.Categories isExpanded={isExpanded}>
        {categories.map(category => (
          <CategoryGroup
            key={category.id ?? category.title}
            category={category}
            activeKey={activeKey}
            disabledKeys={disabledKeys}
            onClick={onItemClick}
          />
        ))}
      </S.Categories>
    </S.AsideExpandable>
  );
};
