import { scaleLinear } from 'd3-scale';

import { YAxisType } from '~/types/axis.types';
import {
  ChartPane,
  CreateChartOptions,
  PaneData,
  XAxis,
  YAxis,
} from '~/types/create.types';
import { ResampleIntervals } from '~/types/resample.types';
import { Timeseries } from '~/types/timeseries.types';
import { getYAxisDetails } from '~/utils/axis/axis-utils';
import { rangeIndexesToYCoordinate } from '~/utils/visible-range/visibleRange';
import { calculateXAxisTicks } from '~/utils/x-scale/x-scale';

export const isMergedAxis = (axisType: YAxisType) => axisType === 'merged';

interface XAxisProps {
  timeseries: Timeseries;
  options: CreateChartOptions;
  domain: [number, number];
  resampleInterval: ResampleIntervals;
}

export const xAxis = ({
  timeseries,
  domain,
  options,
  resampleInterval,
}: XAxisProps): XAxis => {
  const xAxisTicks = calculateXAxisTicks({
    timeseries,
    domain,
    resampleInterval,
  });

  return {
    axis: xAxisTicks,
    xScale: scaleLinear()
      .domain([domain[0], domain[1]])
      .range([0, options.width - options.gutters.y]),
  };
};

export interface YAxisProps {
  ticksCount: number;
  paneOptions: ChartPane['options'];
  minDomainValue?: number;
  domainTimeSeries: ChartPane['domainTimeSeries'];
  paneData: PaneData;
}

export const yAxis = ({
  ticksCount,
  paneOptions,
  minDomainValue = -Infinity,
  domainTimeSeries,
  paneData,
}: YAxisProps): YAxis[] => {
  let allAxis: YAxis[] = [];

  if (paneData.collapsed && !paneData.maximized) {
    return allAxis;
  }

  const { yAxisType, seriesType, priceDisplay, chartAssetData } = paneData;
  const isAxisMerged = isMergedAxis(yAxisType);
  const dataRange = rangeIndexesToYCoordinate({
    chartAssetData,
    //TO-DO need to properly handle domain changes for bars and candlestick
    //there is an edge case when the bar with highest/lowest value in the visible chart range is no longer visible
    //but it's value is still being considered
    seriesType,
    isAxisMerged,
    domainTimeSeries,
    priceDisplay,
  });

  if (isAxisMerged) {
    const mergedAxisDetails = getYAxisDetails({
      paneOptions,
      dataRange: dataRange.mergedRange,
      minDomainValue,
      defaultSnake: chartAssetData[0].entity.default_snake,
      ticksCount,
    });

    allAxis.push(mergedAxisDetails);
  } else {
    for (let index = chartAssetData.length - 1; index >= 0; index--) {
      const asset = chartAssetData[index];
      const foundRange = dataRange.allRanges.find(
        e => e.entityDefaultSnake === asset.entity.default_snake
      );

      if (
        (asset.isHidden && index) ||
        !foundRange ||
        !domainTimeSeries[index]
      ) {
        continue;
      }

      const splitAxisDetails = getYAxisDetails({
        paneOptions,
        ticksCount,
        dataRange: foundRange.range,
        minDomainValue,
        defaultSnake: foundRange.entityDefaultSnake,
      });

      allAxis.push(splitAxisDetails);
    }
  }

  return allAxis;
};
