import { v4 } from 'uuid';

import { postFeedback } from '~/api/chat/chat-services';
import { create } from '~/stores/create-store/createStore';
import { createReadableStream } from '~/views/chat/services/readable-streams/readable-streams-service';

import { ChatStore, SendMessageProps } from './chat-store.types';
import { handleMessageChunks } from './handleMessageChunks';
import { handleSentMessage } from './handleSentMessage';

export const useChatStore = create<ChatStore>((set, get) => ({
  sessionId: v4(),
  isResponsePending: false,
  messages: [],

  handleFeedback: (id: string, feedback: string) => {
    const { messages } = get();
    postFeedback(id, feedback);
    const messagesUpdatedWithFeedback = messages.map(message => {
      if (message.requestId === id && message.reply) {
        return { ...message, reply: { ...message.reply, feedback } };
      }
      return message;
    });

    set({ messages: messagesUpdatedWithFeedback });
  },

  updateActiveMessage: index => {
    const { messages } = get();
    const updatedMessages = messages.map((m, i) => ({
      ...m,
      isActive: i === index,
    }));

    set({
      messages: updatedMessages,
    });
  },

  sendMessage: async (
    sendMessageProps: SendMessageProps,
    abortSignal: AbortSignal
  ) => {
    const { sessionId, appendChunkToMessage, messages } = get();

    const { requestId, url, options, updatedMessages } = handleSentMessage(
      sendMessageProps,
      messages,
      abortSignal,
      sessionId
    );

    set({
      isResponsePending: true,
      messages: updatedMessages,
    });

    await createReadableStream({
      url,
      options,
      onReadChunk: chunks => {
        chunks.forEach(chunk => {
          appendChunkToMessage(requestId, chunk);
        });
      },
    });

    set({
      isResponsePending: false,
    });
  },

  appendChunkToMessage: (requestId, chunk) => {
    const { messages } = get();
    const updatedMessages = messages.map(message => {
      if (message.requestId === requestId) {
        return handleMessageChunks(message, chunk, requestId);
      }
      return message;
    });

    set({
      messages: updatedMessages,
    });
  },
}));
