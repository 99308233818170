import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import {
  gaClickCloseDrawer,
  gaClickClosePopup,
  gaClickConfirm,
  OrderFormType,
} from '~/shared/utils/ganalytics/tradingGA';
import { getPortfolioName } from '~/shared/utils/portfolio-name/getPortfolioName';
import { PriceWebSocketState } from '~/stores/use-broker-ws/useBrokerWs';
import { Portfolio } from '~/stores/use-portfolio/usePortfolio';
import { TradingProvider } from '~/stores/use-portfolio/utils/use-portfolio-utils';

import { BrokerTitle } from '../broker-title/BrokerTitle';
import { CloseModal, CloseModalProps } from '../close-modal/CloseModal';
import { ProcessingState } from '../confirmation-screen/ConfirmationScreen';
import { Connection } from '../connection/Connection';
import { Trading } from '../trading/Trading';
import { SubmittedOrderState } from '../trading/utils/trading-utils';
import { useTradingBrokerWs } from '../use-broker-ws/useTradingBrokerWs';
import { useTransaction } from '../use-transaction/useTransaction';
import * as S from './TradingDrawerContent.styles';

export interface CloseModalSettings
  extends Partial<
    Pick<CloseModalProps, 'continueLabel' | 'title' | 'secondaryAction'>
  > {
  isOpen: boolean;
}

export const TradingDrawerContent: FC = () => {
  const { t } = useTranslation('portfolio');
  const location = useLocation();
  const [closeModalSettings, setCloseModalSettings] =
    useState<CloseModalSettings>({
      isOpen: false,
    });
  const [processingState, setProcessingState] =
    useState<ProcessingState>('processing');
  const [orderState, setOrderState] = useState<SubmittedOrderState>(
    SubmittedOrderState.Idle
  );
  const { connection, initialise, unsubscribe, error } = useTradingBrokerWs(
    ({ connection, initialise, unsubscribe, error }) => ({
      connection,
      initialise,
      unsubscribe,
      error,
    })
  );
  const {
    hideTradingView,
    isViewOpen,
    transaction: { order },
    portfolio,
  } = useTransaction(
    ({ hideTradingView, portfolio, isViewOpen, transaction }) => ({
      hideTradingView,
      portfolio: portfolio as Portfolio,
      isViewOpen,
      transaction,
    })
  );

  useEffect(() => {
    initialise({
      loginId: portfolio.login_id,
      brokerId: portfolio.provider.external_id,
      securityIds: [order.securityId],
    });
    return () => unsubscribe();
  }, []);

  const webSocketConnection = connection === PriceWebSocketState.Connected;

  const onClose = () => {
    setCloseModalSettings({ isOpen: false });
    gaClickClosePopup(location.pathname, OrderFormType.Form);
  };

  const primaryAction = () => {
    setCloseModalSettings({ isOpen: false });
    hideTradingView();
    gaClickConfirm();
  };

  const secondaryAction = () => {
    setCloseModalSettings({ isOpen: false });
    closeModalSettings.secondaryAction?.();
    gaClickClosePopup(location.pathname, OrderFormType.Form);
  };

  const handleDrawerCloseClick = () => {
    if (
      connection !== PriceWebSocketState.Connected ||
      ['submitted', 'cannotTrade'].includes(processingState)
    ) {
      hideTradingView();
    } else {
      setCloseModalSettings({
        isOpen: true,
        title: t('portfolio:closeTradingDrawer'),
        continueLabel: t('portfolio:continueEditing'),
        secondaryAction: () => {
          setOrderState(SubmittedOrderState.Idle);
        },
      });
      gaClickCloseDrawer(location.pathname, OrderFormType.Form);
    }
  };

  const portfolioName = getPortfolioName(portfolio);

  const tradingProviderLabel = t('portfolio:tradingProvider', {
    context: portfolio.provider.external_id,
  });

  return (
    <>
      <S.StyledDrawer
        headerTitle={
          webSocketConnection && (
            <BrokerTitle
              portfolioName={portfolioName}
              tradingProvider={
                portfolio.provider.external_id as TradingProvider
              }
            />
          )
        }
        isOpen={isViewOpen}
        $isIdle={orderState === SubmittedOrderState.Idle}
        handleCloseClick={handleDrawerCloseClick}
        closeIcon={webSocketConnection}
      >
        {webSocketConnection ? (
          <Trading
            setCloseModalSettings={setCloseModalSettings}
            handleDrawerCloseClick={handleDrawerCloseClick}
            processingState={processingState}
            setProcessingState={setProcessingState}
            orderState={orderState}
            setOrderState={setOrderState}
            tradingProviderLabel={tradingProviderLabel}
          />
        ) : (
          <Connection
            error={error}
            handleRetry={() =>
              initialise({
                brokerId: portfolio.provider.external_id,
                loginId: portfolio.login_id,
                securityIds: [order.securityId],
              })
            }
            handleClose={hideTradingView}
            portfolioName={portfolioName}
            tradingProvider={portfolio.provider.external_id as TradingProvider}
          />
        )}
      </S.StyledDrawer>
      <CloseModal
        {...closeModalSettings}
        secondaryAction={secondaryAction}
        primaryAction={primaryAction}
        onClose={onClose}
        confirmLabel={t('portfolio:closeDraft')}
      />
    </>
  );
};
