import { Entity } from '@toggle/toggle';
import { useState } from 'react';

import { ChartSearchType } from '../../types/search.types';

export const useSearchModal = () => {
  const [searchType, setSearchType] = useState(ChartSearchType.Primary);
  const [changingAsset, setChangingAsset] = useState<Entity>();
  const [isAssetSearchOpen, setIsAssetSearchOpen] = useState(false);

  const openSearch = (type: ChartSearchType, changingAsset?: Entity) => {
    setIsAssetSearchOpen(true);
    setChangingAsset(changingAsset);
    setSearchType(type);
  };

  const closeSearch = () => {
    setIsAssetSearchOpen(false);
    setChangingAsset(undefined);
  };

  return {
    searchType,
    changingAsset,
    closeSearch,
    isAssetSearchOpen,
    openSearch,
  };
};
