import ReactGA from 'react-ga4';

import { authPaths } from '~/routes/app-paths';

const CATEGORIES = {
  promo: 'Promo',
};
export { CATEGORIES as PROMO_CATEGORIES };

const ACTIONS = {
  promoApplied: 'Promo_applied',
  clickedAddPromo: 'Clicked_add_promo',
  removedPromo: 'Removed_promo',
};
export { ACTIONS as PROMO_ACTIONS };

export function gaPromoApplied(
  passed: boolean,
  code: string,
  pathname: string
) {
  ReactGA.event({
    category: CATEGORIES.promo,
    action: ACTIONS.promoApplied,
    label: `code:${code};isValid:${passed};location:${
      pathname === authPaths.signUp ? 'registration' : 'subscription'
    }`,
  });
}

export function gaPromoClickAdd(location: 'sign-up' | 'subscribe') {
  ReactGA.event({
    category: CATEGORIES.promo,
    action: ACTIONS.clickedAddPromo,
    label: `location:${location}`,
  });
}

export function gaPromoClickRemove(
  code: string,
  location: 'sign-up' | 'subscribe'
) {
  ReactGA.event({
    category: CATEGORIES.promo,
    action: ACTIONS.removedPromo,
    label: `code:${code};location:${location}`,
  });
}
