import React from 'react';
import { useTranslation } from 'react-i18next';

import { gaHelperClickSection } from '~/shared/utils/ganalytics';
import { useSubscription } from '~/stores/use-subscription/useSubscription';
import { HelperContentProps } from '~/views/helper/helper-widget/content/content';
import { helperViewConfigs } from '~/views/helper/HelperViewConfigs';

import {
  HELPER_VIEW_IDS,
  helperViewKeys,
  helperViewKeysList,
} from '../../../HelperConfig';
import * as S from './HomeHelperContent.styles';

const viewTitles = {
  onboarding: 'Onboarding',
  explore: 'Explore',
  analyze: 'Analyze',
  scenario: 'Scenario',
  myToggle: 'My TOGGLE',
  insight: 'Insight',
  portfolio: 'Portfolio',
  glossary: 'Glossary',
  faq: 'FAQ’s',
  support: 'Support',
};

export const HomeHelperContent = ({ setActiveViewKey }: HelperContentProps) => {
  const { t } = useTranslation('helperWidget');
  const isPro = useSubscription(state => state.isPro());

  const { main: viewMainKeys, additional: viewAdditionalKeys } = helperViewKeys;

  const getViewsList = (keys: helperViewKeysList) =>
    Object.entries(keys).map(([helperId, helperKey]) => {
      const hasViewConfig = helperViewConfigs[helperId as HELPER_VIEW_IDS]; // PREVENTS CRASH. REMOVE IT LATER.
      return hasViewConfig?.proOnly && !isPro ? null : (
        <S.HelperItem
          key={helperKey}
          onClick={() => {
            gaHelperClickSection(
              viewTitles[helperKey as keyof typeof viewTitles]
            );
            if (hasViewConfig) {
              setActiveViewKey(helperId as HELPER_VIEW_IDS);
            }
          }}
        >
          {hasViewConfig?.icon && (
            <S.StyledIcon iconName={hasViewConfig?.icon} />
          )}
          {t(`helperWidget:home.viewTitles.${helperKey}`)}
        </S.HelperItem>
      );
    });

  return (
    <S.HomeHelperContent>
      <S.MainHelperList data-testid="main-views-list">
        {getViewsList(viewMainKeys)}
      </S.MainHelperList>
      <S.AdditionalHelpersList data-testid="additional-views-list">
        {getViewsList(viewAdditionalKeys)}
      </S.AdditionalHelpersList>
    </S.HomeHelperContent>
  );
};
