import { ENDPOINT_URLS } from '~/global/toggle-api';
import { APIFetch } from '~/shared/services/api-fetch';

import { SystemFlags } from '../system-flags.types';

export const getSystemFlags = async () => {
  const endpoint = ENDPOINT_URLS.SYSTEM_FLAGS;
  const res = await APIFetch<SystemFlags>(endpoint);

  if (res instanceof Error) {
    throw new Error(`Failed to get flags from ${endpoint}`);
  }

  return res;
};
