import styled, { keyframes } from 'styled-components';

export const ProgressRoot = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  background-color: var(--surface-pressed);
  border-radius: 8px;
  margin: 8px 20px;
  width: max-content;
`;

const growAnimation = keyframes`
  0% {
    opacity: 0.2;
    transform: scale(1);
  }

  25% {
    opacity: 1;
    transform: scale(1.2);
  }

  50% {
    opacity: 0.2;
    transform: scale(1);
  }
`;

export const Dot = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: var(--icon-default);
  margin: 0 4px;
  opacity: 0.2;
  animation-name: ${growAnimation};
  animation-duration: 1.2s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;

  &:nth-child(1) {
    animation-delay: 0;
  }

  &:nth-child(2) {
    animation-delay: 0.15s;
  }

  &:nth-child(3) {
    animation-delay: 0.3s;
  }
`;
