import {
  Accordion,
  customScrollMinimal,
  deviceConstraints,
} from '@toggle/design-system';
import styled, { css, keyframes } from 'styled-components';

import HelperRobotImg from '~/assets/helper-widget/helper-robot.svg';
import HelperRobotCompleteImg from '~/assets/helper-widget/helper-robot-complete.svg';
import { Breadcrumbs } from '~/shared/components/breadcrumbs/Breadcrumbs';
import { ZendeskLauncher } from '~/shared/components/zendesk/ZendeskLauncher';
import { HelperWidgetAccordion } from '~/views/helper/helper-widget/partials/accordion/HelperWidgetAccordion';

const desktopHelperHeaderHeight = '136px';
const widgetWidthDesktop = '396px';
const widgetWidthMobile = '368px';
const zendeskOffset = '24px';

const ExpandedHelperSizes = styled.div`
  height: 400px;

  @media ${deviceConstraints.tablet} {
    width: ${widgetWidthMobile};
  }

  @media ${deviceConstraints.desktopSmall} {
    height: calc(100vh - var(--app-header-height));
    width: ${widgetWidthDesktop};
  }
`;

export const HelperWrapper = styled(ExpandedHelperSizes)`
  position: relative;
`;

export const FixedWrapper = styled(ExpandedHelperSizes)`
  position: fixed;
  right: 0;
`;

export const Header = styled.header`
  height: 64px;
  padding: 12px 12px 9px 26px;
  background-color: var(--base--90);

  @media ${deviceConstraints.desktopSmall} {
    height: ${desktopHelperHeaderHeight};
    padding: 16px 16px 32px 24px;
  }
`;

export const Control = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 3px;

  @media ${deviceConstraints.desktopSmall} {
    align-items: center;
  }
`;

export const Nav = styled.nav`
  margin-top: 19px;

  @media ${deviceConstraints.desktopSmall} {
    margin-top: 0;
  }
`;

export const HelperBreadcrumbs = styled(Breadcrumbs)`
  li {
    &:after {
      font-size: 12px;
      color: var(--base--35) !important;
    }

    & > {
      a,
      span {
        font-size: 16px;
        color: var(--base--35);
      }
    }
  }

  li:first-child {
    & > a:hover {
      color: var(--primary-color);
      text-decoration: underline;
    }
  }
`;

export const CollapseBtn = styled.button`
  position: relative;
  z-index: 1;
  height: 19px;
  padding: 0;
  border: none;
  font-size: 18px;
  background-color: transparent;
  cursor: pointer;
  outline: none;
  color: var(--primary-color);
`;

export const ContentWrapper = styled.div`
  position: relative;
  height: 336px;
  background-color: var(--base--85);
  padding-bottom: 65px;

  @media ${deviceConstraints.desktopSmall} {
    height: calc(100% - ${desktopHelperHeaderHeight});
  }
`;

export const HelperWidgetContentContainer = styled.div`
  ${customScrollMinimal};
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  padding: 24px;
`;

export const HelperRegisterBannerAccordion = styled(HelperWidgetAccordion)`
  ${Accordion.Styled.AccordionItem} {
    background-color: var(--base--100) !important;
    border-radius: 4px;
    margin-bottom: 8px !important;
  }

  ${Accordion.Styled.TitleContainer} {
    padding: 15px 11px 15px 20px;
  }
`;

export const Title = styled.h1`
  margin-bottom: 3px;
  font-size: 24px;
  font-weight: 500;
`;

export const SubTitle = styled.h3`
  font-size: 16px;
  max-width: 240px;
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const RobotImgStyles = css`
  position: absolute;
  top: 8px;
  right: 44px;
  animation: ${fadeIn} 0.5s 0s ease-in;

  @media ${deviceConstraints.desktopSmall} {
    top: 24px;
  } ;
`;

export const RobotCompleteImg = styled(HelperRobotCompleteImg)`
  ${RobotImgStyles}
`;

export const RobotImg = styled(HelperRobotImg)`
  ${RobotImgStyles};

  @media ${deviceConstraints.desktopSmall} {
    right: 32px;
  }
`;

export const HelperZendeskLauncher = styled(ZendeskLauncher)`
  position: fixed;
  width: calc(${widgetWidthMobile} - ${zendeskOffset} * 2);
  right: calc(${zendeskOffset} * 1.5);

  @media ${deviceConstraints.smartphone} {
    width: calc(100% - ${zendeskOffset} * 3);
    right: calc(${zendeskOffset} * 1.5);
  }

  @media ${deviceConstraints.smartphoneOrTablet} {
    width: calc(100% - ${zendeskOffset} * 3);
    right: calc(${zendeskOffset} * 1.5);
  }

  @media ${deviceConstraints.tablet} {
    width: calc(${widgetWidthMobile} - ${zendeskOffset} * 2);
    right: calc(${zendeskOffset} * 1.5);
  }

  @media ${deviceConstraints.desktopSmall} {
    width: calc(${widgetWidthDesktop} - ${zendeskOffset} * 2);
    right: ${zendeskOffset};
  }
`;
