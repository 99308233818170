export enum QUALITY_CHECKLIST {
  CROSS_BUSINESS_CYCLE = 'cross_business_cycle',
  SEVERAL_EPISODES = 'several_episodes',
  SPARSE = 'sparse',
  FREQUENT = 'frequent',
  HIGH_HIT_RATIO_IN_SAMPLE = 'high_hit_ratio_in_sample',
  HIGH_HIT_RATIO_OUT_SAMPLE = 'high_hit_ratio_out_sample',
  HIGH_PATH_RISK_RETURN = 'high_path_risk_return',
  HIGH_PNL_DRAWDOWN_RATIO = 'high_pnl_drawdown_ratio',
}

export enum CompassFormat {
  PERCENT = '%',
  ABSOLUTE = 'bps',
}
