import { Button } from '@toggle/design-system';
import styled from 'styled-components';

export const Root = styled.div`
  max-width: 270px;
`;

export const Group = styled.div`
  display: flex;
`;

export const StyledButton = styled(Button)<{
  $isActive?: boolean;
}>`
  ${({ $isActive }) =>
    $isActive &&
    `
      color: var(--text-on-primary);
      background: var(--surface-primary);
      &:hover{
        background: var(--surface-primary);
      }
    `}

  ${({ disabled }) =>
    disabled &&
    `
      &:disabled {
        color: var(--text-default);
        border: none;
      }
    `}
`;
