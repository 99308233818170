import { Alert, customScrollMinimal, typography } from '@toggle/design-system';
import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: 1fr;
  overflow-y: auto;
  flex-grow: 1;
`;

export const HeadingContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0px 16px;
`;

export const Heading = styled.h2`
  ${typography.tiger}
`;

export const ContentContainer = styled.div`
  padding: 0px 16px 8px;
  overflow-y: auto;
  ${customScrollMinimal}
`;

export const OrderForm = styled.div`
  display: flex;
  flex-direction: column;
  background-color: var(--base--0);
  border: 1px solid var(--base--80);
  border-radius: 4px;
  padding-top: 16px;
  margin: 32px 0px;
`;

export const OrderItem = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0px 16px 16px;
  &:last-child {
    padding-bottom: 0px;
  }
`;

interface OrderItemText {
  whiteText?: boolean;
  uppercase?: boolean;
}

export const OrderItemName = styled.p<OrderItemText>`
  ${typography.dingo}
  color: ${({ whiteText }) =>
    whiteText ? 'var(--base--0)' : 'var(--base--100)'};
  text-transform: ${({ uppercase }) => (uppercase ? 'uppercase' : 'none')};
`;

export const OrderItemValue = styled.p<OrderItemText>`
  ${typography.dingo}
  font-weight: 500;
  color: ${({ whiteText }) =>
    whiteText ? 'var(--base--0)' : 'var(--base--100)'};
`;

export const OrderTotal = styled.div`
  background-color: var(--base--95);
`;

export const OrderTotalItem = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 24px;
`;

export const OrderMargin = styled.div`
  background-color: var(--base--90);
`;

export const OrderMarginItem = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px;
`;

export const FooterContainer = styled.div`
  border-top: 1px solid var(--base--80);
  padding: 16px;
  align-self: flex-end;
`;

export const ValueEstimate = styled.p`
  ${typography.koala}
  color: var(--base--20);
  text-align: center;
  margin-top: 34px;
`;

export const IbAlert = styled(Alert)`
  margin-top: 20px;
`;
