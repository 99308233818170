import { deviceConstraints, Icon, typography } from '@toggle/design-system';
import styled from 'styled-components';

import {
  BaseDropdownListItem,
  BaseDropdownMenu,
} from '../header-my-account-dropdown/HeaderMyAccountDropdown.styles';

export const DropdownMenu = styled.div`
  ${BaseDropdownMenu};
  right: 0;
  top: calc(100% + 14px);

  @media ${deviceConstraints.tablet} {
    top: calc(100% + 6px);
  }
`;

export const DropdownListItem = styled.a`
  ${typography.emu};
  ${BaseDropdownListItem};
  grid-template-columns: auto 1fr;
  grid-template-rows: auto;
`;

export const MenuIcon = styled(Icon)`
  justify-self: flex-end;
`;
