import { useSearchParams } from 'react-router-dom';

export function useQuery() {
  const [searchParams, setSearchParams] = useSearchParams();

  const deleteParam = (param: string) => {
    searchParams.delete(param);
    setSearchParams(searchParams.toString());
  };

  return {
    query: searchParams,
    deleteParam,
  };
}
