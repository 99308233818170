import debounce from 'lodash/debounce';
import { useEffect, useRef, useState } from 'react';

import { ExploreFilterOption } from '~/api/explore-filter/explore-filter-schema';
import { OptionalN } from '~/declarations/standard';
import { ArticleSummary } from '~/declarations/toggle-api';
import { ENDPOINT_URLS } from '~/global/toggle-api';
import { APIFetch } from '~/shared/services/api-fetch/APIFetch';
import { Tracking } from '~/shared/services/tracking';

import { Filter, useExploreFilters } from '../store/use-explore-filters';
import { makeFilterSlug } from './makeFilterSlug';

interface Meta {
  count: number;
}
export interface ExploreFeedResponse {
  result: ArticleSummary[];
  meta: Meta;
}

export const WATCHLIST_KEY = 'MY_WATCHLIST';

export const useFilterData = () => {
  const { filters, filterOptions } = useExploreFilters(s => ({
    filters: s.filters,
    filterOptions: s.filterOptions,
  }));

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<ArticleSummary[]>([]);
  const [error, setError] = useState(false);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [noMoreData, setNoMoreData] = useState(false);

  const watchListFilter = filters.some(f => f.key === WATCHLIST_KEY);

  const isEmptyWatchFilter = (
    filters: Filter[],
    filterOptions: ExploreFilterOption[]
  ) => {
    if (!filters.some(f => f.key === WATCHLIST_KEY)) {
      return false;
    }

    const watchlistOption = filterOptions
      .find(f => f.key === 'TOP_SCREENS')
      ?.options.find(o => o.key === WATCHLIST_KEY);

    return !watchlistOption?.properties?.entity.length ? true : false;
  };

  const setNewArticles = (articles: ExploreFeedResponse, page: number) =>
    setData(feed => {
      const prevFeed = page === 0 ? [] : feed;
      return articles.result.length
        ? [...prevFeed, ...articles.result]
        : prevFeed;
    });

  const callApi = useRef(
    (() => {
      let abortController: OptionalN<AbortController> = null;

      return debounce(
        async (
          page: number,
          filters: Filter[],
          filterOptions: ExploreFilterOption[],
          noMoreData,
          isReset = false
        ) => {
          if (abortController) {
            abortController.abort();
            abortController = null;
          }
          if (noMoreData && !isReset) {
            setLoading(false);
            return;
          }

          try {
            if (isEmptyWatchFilter(filters, filterOptions)) {
              setData([]);
              return;
            }

            const slug = makeFilterSlug(filterOptions, filters);

            const endPoint = `${ENDPOINT_URLS.ARTICLE_EXPLORE}?${slug}${
              slug && '&'
            }page=${isReset ? 0 : page}`;
            abortController = new AbortController();
            const articles = await APIFetch<ExploreFeedResponse>(endPoint, {
              signal: abortController.signal,
            });

            if (articles instanceof Error) {
              if (articles.name === 'AbortError') {
                return;
              }
              throw new Error(`Failed to get Articles from ${endPoint}`);
            }

            setNoMoreData(!articles.result.length);
            setCount(articles.meta.count);
            setNewArticles(articles, page);
          } catch (error) {
            setError(true);
            Tracking.captureException(error as Error);
          } finally {
            setLoading(false);
            abortController = null;
          }
        },
        500
      );
    })()
  );

  useEffect(() => {
    if (!noMoreData) {
      setLoading(true);
    }
    callApi.current(page, filters, filterOptions, noMoreData);
  }, [page]);

  const loadNextPage = () => {
    setPage(prevPage => prevPage + 1);
  };

  useEffect(() => {
    const page = 0;
    setLoading(true);
    setNoMoreData(false);
    setData([]);
    setPage(page);
    setError(false);
    callApi.current(page, filters, filterOptions, noMoreData, true);
  }, [filters]);

  return {
    loading,
    data,
    count,
    error,
    loadData: loadNextPage,
    watchListFilter,
  };
};
