import styled from 'styled-components';

import { TableCell } from '~/components/table';

export const StickyTableCell = styled(TableCell)<{ $displayBorder?: boolean }>`
  position: sticky;
  left: 0;
  display: flex;
  gap: 12px;
  ${({ $displayBorder }) =>
    $displayBorder &&
    `
      border-right: 1px solid var(--border-default);
    `}
`;
