import React from 'react';
import { useTranslation } from 'react-i18next';

import { config } from '~/config';

import * as S from './ChatWelcome.styles';

export const ChatWelcome = () => {
  const { t } = useTranslation('chat');
  return (
    <S.ChatWelcomeRoot data-testid="chat-welcome">
      <S.WaveEmoji>👋</S.WaveEmoji>
      <S.WelcomeMessage>{t('chat:welcomeMessage')}</S.WelcomeMessage>
      <S.ChatDisclaimer
        title={t('chat:disclaimerLinkTitle')}
        href={config.urls.disclaimerGenAi}
        target="_blank"
      >
        {t('chat:disclaimerLink')}
      </S.ChatDisclaimer>
    </S.ChatWelcomeRoot>
  );
};
