import { Icon } from '@toggle/design-system';
import styled, { css } from 'styled-components';

import { pulse } from '~/global/styles/Animation.styles';

export const TooltipWrapper = styled.div`
  display: inline-flex;
  position: relative;
  height: 100%;
  justify-content: flex-end;
  align-items: center;
`;

export const BookmarkWrapper = styled.div<{ isAnimating?: boolean }>`
  outline: none;
  cursor: pointer;

  ${({ isAnimating }) =>
    isAnimating &&
    css`
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        animation: ${pulse} 2s linear forwards;
        pointer-events: none;

        background-color: var(--primary-color);
      }
    `};
`;

export const StyledIcon = styled(Icon)<{
  $isBookmarked?: boolean;
  $isLocked?: boolean;
}>`
  ${({ $isBookmarked }) => $isBookmarked && 'fill: var(--primary-color);'};

  ${({ $isLocked }) => $isLocked && 'fill: var(--base--80);'};
`;
