import styled, { css } from 'styled-components';

import { Icon } from '~/components/icon/Icon';
import { typographyV2 } from '~/design-tokens/typography/Typography.styles';

export interface RadioStyledProps {
  disabled: boolean;
  $locked: boolean;
  $error: boolean;
}

interface RadioLabelProps {
  $disabled: boolean;
  $locked: boolean;
  $size: 'medium' | 'small';
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const GroupContainer = styled.div<{ $direction: 'column' | 'row' }>`
  display: flex;
  flex-direction: ${props => props.$direction};
`;

export const GroupLabel = styled.span`
  ${typographyV2.bodyMd}
`;

export const LabelContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledIcon = styled(Icon)`
  margin-right: 4px;
`;

export const LockIcon = styled(Icon)`
  margin-right: 14px;
  fill: var(--surface-neutral);
`;

export const InputIcon = styled(Icon)`
  margin-right: 6px;
`;

export const RadioInput = styled.input<RadioStyledProps>`
  appearance: none;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  border: 1px solid var(--border-default);

  &:focus-visible {
    outline-offset: 2px;
    outline: 2px solid var(--border-focus);
  }

  &:checked {
    box-shadow: inset 0 0 0 2px var(--surface-default);
    background: var(--surface-primary);
    border-color: var(--border-primary);
  }

  &:checked::before {
    transform: scale(1);
  }

  ${({ disabled, $locked, $error }) =>
    disabled || $locked
      ? css`
          border-color: var(--border-disabled);
        `
      : $error &&
        css`
          border-color: var(--border-critical);
        `}

  ${({ $locked }) =>
    $locked
      ? css`
          width: 0;
          height: 0;
          padding: 0;
          overflow: hidden;
          margin: 0;
          border: none;
        `
      : css`
          &:hover:not(:checked) {
            border-color: var(--border-hover);
          }
        `}
`;

export const RadioLabel = styled.label<RadioLabelProps>`
  display: flex;
  align-items: center;
  margin: 4px 6px;
  pointer-events: ${({ $disabled }) => $disabled && 'none'};
  ${props =>
    props.$size === 'medium' ? typographyV2.bodyBase : typographyV2.bodySm}
  ${({ $disabled, $locked }) =>
    ($disabled || $locked) &&
    css`
      color: var(--surface-neutral);
    `}
  
    ${({ $disabled }) =>
    !$disabled &&
    css`
      &,
      & ${RadioInput} {
        cursor: pointer;
      }
    `}
   

  & ${RadioInput} {
    margin: ${({ $locked }) => ($locked ? '0' : '0 12px 0 0')};
  }
`;

export const Label = styled.div<{ $withTooltip: boolean }>`
  display: inline-flex;
  align-items: center;
  flex-grow: 1;
  border-bottom: ${props =>
    props.$withTooltip && '1px dashed var(--border-default)'};
`;
