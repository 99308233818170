import { Icon } from '@toggle/design-system';
import classNames from 'classnames';
import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { ClearButtons } from '~/global/app-search/search/components/clear-buttons/ClearButtons';
import { SearchIcon } from '~/global/app-search/search/components/search-icon/SearchIcon';

import { useGlossaryHandleSearch } from '../../hooks/useGlossaryHandleSearch';
import { useMapGlossaryItems } from '../../hooks/useMapGlossaryItems';
import * as S from './GlossaryHelperContent.styles';

export const GlossaryHelperContent = () => {
  const { t } = useTranslation('helperWidget');

  const { value, handleSearch, handleClear } = useGlossaryHandleSearch();

  const glossarySections = useMapGlossaryItems(t, value);

  const accordionItems = glossarySections.map((accordionItem, idx) => ({
    ...accordionItem,
    hideIcon: !accordionItem.content,
    icon: <Icon iconName="ChevronLightDown" size={14} />,
    className: classNames(
      accordionItem.content && 'glossaryItem',
      !accordionItem.content && 'glossaryLetter',
      !accordionItem.content &&
        !glossarySections[idx + 1]?.content &&
        'emptyGlossaryLetter'
    ),
  }));

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    handleSearch(e.target.value);
  };

  return (
    <>
      <S.GlossarySearch>
        <S.Input
          value={value}
          placeholder={t('helperWidget:glossary.searchPlaceholder')}
          autoFocus={false}
          leftIcon={<SearchIcon />}
          rightIcon={
            !!value.length && (
              <ClearButtons showClearButton={false} handleClear={handleClear} />
            )
          }
          onChange={onChange}
        />
      </S.GlossarySearch>

      <S.Accordion items={accordionItems} withBackground={false} />
    </>
  );
};
