import styled from 'styled-components';

import { Icon } from '~/components/icon/Icon';
import {
  typography,
  typographyV2,
} from '~/design-tokens/typography/Typography.styles';

export const Header = styled.div`
  display: flex;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const Title = styled.div`
  ${typographyV2.titleLg};
  font-weight: 500;
  margin-bottom: 8px;
`;

export const Description = styled.div`
  ${typographyV2.bodyBase};
`;

export const Separator = styled.div`
  margin: 20px 0;
  border-bottom: 1px solid var(--base--70);
`;

export const Picture = styled.picture`
  display: flex;
  max-width: 80px;
  height: 80px;
  flex-direction: column;
  justify-content: center;
  margin-left: 16px;
`;

export const Footer = styled.div`
  ${typography.koala}
  display: flex;
  flex-direction: column;

  & > button:first-child {
    margin-bottom: 8px;
  }
`;

export const PlanName = styled.p`
  ${typographyV2.titleBase}
`;

export const PlanPrice = styled.div`
  ${typographyV2.title2XL};
  font-weight: 500;
  font-family: var(--font-nort);
`;

export const PlanPriceAndBillingContainer = styled.div`
  display: flex;
  align-items: baseline;
`;

export const PlanBilling = styled.div`
  ${typographyV2.titleSm};
  font-weight: 500;
`;

export const List = styled.div`
  padding: 16px 0px 32px 0px;
`;

export const ListItem = styled.div`
  ${typographyV2.bodySm}
  display: flex;
  align-items: center;
  margin-bottom: 12px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const CustomIcon = styled(Icon)`
  fill: var(--primary-color);
  margin-right: 6px;
`;
