import { z } from 'zod';

export type User = z.infer<typeof userSchema>;
export const userSchema = z.object({
  id: z.string(),
  first_name: z.string(),
  last_name: z.string(),
  full_name: z.string(),
  username: z.string(),
  email: z.string(),
  onboarding_state: z.number(),
  tz_name: z.string(),
  tz_offset: z.number(),
  email_verified: z.boolean(),
  created_at: z.string(),
  promo_code: z.string().optional(),
});

export type UserTimeZone = z.infer<typeof userTimeZoneSchema>;
export const userTimeZoneSchema = z.object({
  name: z.string(),
  offset: z.number(),
});

// TODO remove after goal deployment
export type UserAddress = z.infer<typeof userAddressSchema>;
export const userAddressSchema = z.object({
  line1: z.string().optional(),
  line2: z.string().optional(),
  line3: z.string().optional(),
  country: z.string(),
  postal_code: z.string(),
  city: z.string(),
  region: z.string(),
});

export interface UserAddressPayload extends Partial<UserAddress> {
  country: string;
}
