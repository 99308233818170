import { useEffect } from 'react';

import { STORAGE_KEYS, useRemoteStorage } from '../use-remote-storage';

export interface UseDismissOnceProps {
  storageKey: STORAGE_KEYS;
  shouldSetKey: boolean;
  shouldRemoveKey?: boolean;
}

export const useDismissOnce = ({
  storageKey,
  shouldSetKey,
  shouldRemoveKey = !shouldSetKey,
}: UseDismissOnceProps) => {
  const { items, deleteItem, storeItems } = useRemoteStorage(state => ({
    items: state.items,
    deleteItem: state.deleteItem,
    storeItems: state.storeItems,
  }));
  const key = items?.[storageKey];

  useEffect(() => {
    if (shouldSetKey && key === undefined) {
      storeItems({ [storageKey]: new Date().toISOString() });
    } else if (shouldRemoveKey && key) {
      deleteItem(storageKey);
    }
  }, []);

  const handleDismiss = () => {
    storeItems({ [storageKey]: new Date().toISOString() });
  };

  return { hasKey: !!key, handleDismiss };
};
