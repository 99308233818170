import {
  AssetLogo,
  borderRadius,
  customScrollMinimal,
  Modal,
  typographyV2,
} from '@toggle/design-system';
import styled from 'styled-components';

export const StyledModal = styled(Modal)`
  ${Modal.Styled.Header} {
    padding-bottom: 16px;
  }

  ${Modal.Styled.TextContainer} {
    margin-top: 12px;
    margin-bottom: 0;
  }

  ${Modal.Styled.ModalContainer} {
    display: flex;
    flex-direction: column;
    width: 600px;
    height: 100%;
    max-height: 471px;
    padding: 0;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 406px;
  padding: 0;
`;

export const ScrollableList = styled.div`
  ${customScrollMinimal};
  flex-grow: 1;
  overflow: auto;
  position: relative;
`;

export const HelpText = styled.p`
  ${typographyV2.bodySm};
  color: var(--text-soft);
  padding: 12px 16px;
`;

export const ChipContainer = styled.div`
  padding: 12px 16px;
  display: flex;
  gap: 8px;
`;

export const ListContainer = styled.div`
  padding: 8px 8px;
`;

export const IndicatorItem = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${borderRadius.r1}
  width: 100%;
  padding: 8px;
  cursor: pointer;
  border: none;
  background: none;

  &:hover {
    background-color: var(--surface-hover);
  }

  &:focus-visible {
    outline: 2px solid var(--border-focus);
  }
`;

export const IndicatorText = styled.p`
  ${typographyV2.bodySm};
  color: var(--text-default);
`;

export const Secondary = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4px;
`;

export const StyledLogo = styled(AssetLogo)`
  height: 12px;
  width: 12px;
`;

export const IndicatorSmallText = styled.p`
  ${typographyV2.bodyXs};
  color: var(--text-soft);
`;
