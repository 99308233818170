import { Table, TableCell, typographyV2 } from '@toggle/design-system';
import { NumericalCellValueStyle } from '@toggle/helpers';
import styled from 'styled-components';

export const EpisodesTable = styled(Table)`
  width: 100%;
  margin-bottom: 40px;
`;

export const QualityText = styled.span<{ isBest: boolean }>`
  ${typographyV2.bodySm};
  color: ${({ isBest }) =>
    isBest ? 'var(--text-primary)' : 'var(--text-soft)'};
`;

export const QualityIconWrapper = styled.span`
  display: flex;
  justify-content: end;
  align-items: center;
`;

export const NumericText = styled.span<{
  $numericalStyle: NumericalCellValueStyle;
}>`
  color: ${({ $numericalStyle }) => {
    const isPositive =
      $numericalStyle === NumericalCellValueStyle.Positive
        ? 'var(--viz-bullish)'
        : 'var(--viz-bearish)';
    return $numericalStyle === NumericalCellValueStyle.Neutral
      ? 'var(--text-default)'
      : isPositive;
  }};
`;

export const Dash = styled.span`
  padding: 0px 4px;
`;

export const BodyRow = styled.tr`
  td {
    cursor: pointer;
  }
`;

export const EvidenceTableCell = styled(TableCell)`
  font-family: var(--font-nort);
`;
