import styled from 'styled-components';

export const ViewControllerRoot = styled.div<{
  $isSimpleChart: boolean;
}>`
  display: flex;
  justify-content: left;
  border-bottom: 2px solid var(--border-soft);
  padding: 10px 20px 10px 10px;
  gap: 8px;
  min-height: 56px;

  ${({ $isSimpleChart }) =>
    $isSimpleChart &&
    `
      justify-content: space-between;
      padding: 10px 0;
    `}
`;

export const FlexWrapper = styled.div`
  display: flex;
  gap: 4px;
`;
