import { DropdownListItem, Icon, Tooltip } from '@toggle/design-system';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { SeriesType } from '~/types/series.types';

import {
  ChartTypeSelector,
  chartTypeToIconName,
} from '../chart-type-selector/ChartTypeSelector';
import {
  MultiDropdown,
  MultiDropdownItem,
} from '../multi-dropdown/MultiDropdown';
import * as S from './LegendMoreMenu.styles';

export const LEGEND_MORE_MENU_ID = 'legend-more-menu';

export interface LegendMoreMenuProps {
  chartColor: string;
  colorArray: string[];
  onColorSelect: (colorIndex: number) => void;
  changeSeriesType?: (
    chartType: SeriesType,
    { snake, paneIndex }: { snake?: string; paneIndex?: number }
  ) => void;
  renderMovePane?: () => JSX.Element;
  onMenuOpen: (open: boolean) => void;
  isPrimaryAssetSinglePrice?: boolean;
  selectedSeriesType?: SeriesType;
  defaultSnake?: string;
}

export const LegendMoreMenu = ({
  colorArray,
  chartColor,
  onColorSelect,
  changeSeriesType,
  onMenuOpen,
  isPrimaryAssetSinglePrice = true,
  selectedSeriesType = 'line',
  defaultSnake,
  renderMovePane,
}: LegendMoreMenuProps) => {
  const { t } = useTranslation('chart');

  return (
    <S.LegendMoreMenuRoot>
      <MultiDropdown
        baseComponent={
          <div>
            <Tooltip
              label={t('chart:more')}
              closeLabel={t('chart:tapToClose')}
              isTouchDevice={false}
              inPortal
            >
              <S.IconWrapper>
                <S.MenuIcon iconName="Ellipsis" size={12} />
              </S.IconWrapper>
            </Tooltip>
          </div>
        }
        onOpen={open => onMenuOpen(open)}
      >
        <MultiDropdown
          baseComponent={
            <DropdownListItem
              id={LEGEND_MORE_MENU_ID}
              label={t('chart:changeChartColor')}
              trailingIcon={<Icon iconName="ChevronLightRight" size={16} />}
              leadingIcon={<S.SmallColorIcon $color={chartColor} />}
              isActive={false}
              isFocused={false}
            />
          }
        >
          <MultiDropdownItem
            customComponent={
              <S.ColorPicker>
                {colorArray.map((c, i) => (
                  <S.ColorIcon
                    key={i}
                    $color={c}
                    $isSelected={c === chartColor}
                    onClick={() => onColorSelect(i)}
                  >
                    {c === chartColor && (
                      <Icon iconName="Checkmark" size={16} />
                    )}
                  </S.ColorIcon>
                ))}
              </S.ColorPicker>
            }
          />
        </MultiDropdown>
        {changeSeriesType && (
          <MultiDropdown
            baseComponent={
              <DropdownListItem
                id={`${LEGEND_MORE_MENU_ID}-series`}
                label={t(`chart:seriesType.${selectedSeriesType}`)}
                trailingIcon={<Icon iconName="ChevronLightRight" size={16} />}
                leadingIcon={
                  <S.LeftIcon
                    data-testid={`${LEGEND_MORE_MENU_ID}-${selectedSeriesType}`}
                    size={12}
                    iconName={chartTypeToIconName[selectedSeriesType]}
                  />
                }
                isActive={false}
                isFocused={false}
              />
            }
          >
            <MultiDropdownItem
              customComponent={
                <ChartTypeSelector
                  selectedSeriesType={selectedSeriesType}
                  onChange={changeSeriesType}
                  disableMenuBar={false}
                  isPrimaryAssetSinglePrice={isPrimaryAssetSinglePrice}
                  defaultSnake={defaultSnake}
                />
              }
            />
          </MultiDropdown>
        )}
        {renderMovePane && renderMovePane()}
      </MultiDropdown>
    </S.LegendMoreMenuRoot>
  );
};
