import { typographyV2 } from '@toggle/design-system';
import styled from 'styled-components';

export const ChatWelcomeRoot = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const WelcomeMessage = styled.p`
  ${typographyV2.bodyBase}
`;

export const WaveEmoji = styled.p`
  font-size: 40px;
  line-height: 48px;
`;
