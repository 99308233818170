import { typography } from '@toggle/design-system';
import styled from 'styled-components';

export const LogoPlaceholderStyled = styled.i`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: var(--base--70);
  color: var(--base--0);
  ${typography.emu}
  font-weight: 500;
  font-style: normal;
`;

export const IconWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: var(--base--75);
`;

export const CryptoImage = styled.img`
  background-color: var(--base--100);
  width: 100%;
`;
