import React from 'react';
import { useTranslation } from 'react-i18next';

import { NewsItem as NewsItemType } from '~/declarations/toggle-api.d';
import { aiNewsMapping } from '~/views/analyze/asset-overview/components/sidebar/components/rocketship-tab/helpers/RocketshipTabHelpers';

import * as SharedStyles from '../shared.styles';
import * as S from './NewsItem.styles';

export interface NewsItemProps {
  aiNews: NewsItemType[];
}

export const NewsItem = ({ aiNews }: NewsItemProps) => {
  const { t } = useTranslation('analyze');

  return (
    <div>
      {aiNews.map(item => {
        const mapped = aiNewsMapping(item, t);
        return (
          <S.Card
            key={item.id}
            data-testid="ai-news-card"
            href={item.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            <S.CardInfo>
              <S.CardHeading $direction={mapped.direction}>
                {mapped.insightTypeText}
              </S.CardHeading>
              <SharedStyles.CardText>{mapped.name}</SharedStyles.CardText>
            </S.CardInfo>
            <SharedStyles.CardImageWrapper>
              {mapped.imgNode}
            </SharedStyles.CardImageWrapper>
          </S.Card>
        );
      })}
    </div>
  );
};
