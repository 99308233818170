import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import * as S from './FaqHelperContent.styles';

export function DataSourceContent() {
  const { t } = useTranslation('helperWidget');

  return (
    <S.FaqContent>
      <Trans
        t={t}
        i18nKey="helperWidget:faq.dataSource.content"
        components={[
          <S.Link
            decorated
            key="0"
            href="https://www.refinitiv.com/en"
            target="_blank"
            rel="noopener noreferrer"
          />,
          <S.Link
            decorated
            key="1"
            href="https://www.refinitiv.com/en/financial-data/company-data/institutional-brokers-estimate-system-ibes"
            target="_blank"
            rel="noopener noreferrer"
          />,
          <S.Link
            decorated
            key="2"
            href="https://fred.stlouisfed.org/"
            target="_blank"
            rel="noopener noreferrer"
          />,
          <S.Link
            decorated
            key="3"
            href="https://fredhelp.stlouisfed.org/fred/about/about-fred/what-is-fred/"
            target="_blank"
            rel="noopener noreferrer"
          />,
        ]}
      />
    </S.FaqContent>
  );
}
