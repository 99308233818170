import { create } from '~/stores/create-store/createStore';
import { ArticleLimits } from '~/views/article/services/article-service';

interface ArticleLimitStore {
  data?: ArticleLimits;
  isLimitExceeded: boolean;
  setData: (data: ArticleLimits) => void;
  clearData: () => void;
}

const initData = {
  data: undefined,
  isLimitExceeded: false,
};

export const useArticleLimit = create<ArticleLimitStore>(set => ({
  ...initData,
  setData: data =>
    set(() => ({
      data,
      isLimitExceeded: data.limit > -1 && data.consumed >= data.limit,
    })),
  clearData: () => set(() => initData),
}));
