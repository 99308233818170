import { Button } from '@toggle/design-system';
import React from 'react';

export interface ChatMessageActionButtonProps {
  label: string;
  link: string;
}

export const ChatMessageActionButton = ({
  label,
  link,
}: ChatMessageActionButtonProps) => {
  return (
    <a
      data-testid="action-btn"
      href={link}
      target="_blank"
      rel="noopener noreferrer"
      tabIndex={-1}
    >
      <Button
        label={label}
        variant="tertiary"
        iconName="ExternalLink"
        iconPosition="right"
      />
    </a>
  );
};
