import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import * as S from '../Content.styles';

export const CustomScreensContent = () => {
  const { t } = useTranslation('helperWidget');
  return (
    <S.Content>
      <S.Paragraph>
        {t('helperWidget:explore.content.customScreens.firstParagraph')}
      </S.Paragraph>
      <S.Paragraph>
        {
          <Trans
            i18nKey="helperWidget:explore.content.customScreens.secondParagraph"
            components={[
              <S.MiddleAlignedIcon
                key="0"
                iconName="Home"
                size={22}
                fillColor="var(--primary-color)"
              />,
              <S.MiddleAlignedIcon
                key="1"
                iconName="Ellipsis"
                size={16}
                fillColor="var(--primary-color)"
              />,
            ]}
          />
        }
      </S.Paragraph>
    </S.Content>
  );
};
