import React, { RefObject } from 'react';

import { ArticleV3 } from '~/declarations/toggle-api';

import { ArticleSidebarMenu, ArticleSidebarSummary } from '../../../partials';
import { ActionMenu } from '../../share-section/action-menu/ActionMenu';
import * as S from './SidebarItems.styled';

interface SidebarItemsProps {
  article: ArticleV3;
  isGuest: boolean;
  displayBanner: boolean;
  isBasic: boolean;
  sectionsRefs: RefObject<HTMLHeadingElement>[];
  parentRef: RefObject<CollapseRef | undefined>;
}

export interface CollapseRef {
  uncollapseIfNeeded: Function;
  collapsed: boolean;
}

export const SidebarItems = ({
  isGuest,
  displayBanner,
  article,
  sectionsRefs,
  parentRef,
  isBasic,
}: SidebarItemsProps) => {
  const handleCollapseOnClick = (sectionRef: RefObject<HTMLHeadingElement>) => {
    if (
      sectionsRefs.slice(1, -1).includes(sectionRef) &&
      parentRef &&
      parentRef.current
    ) {
      parentRef.current.uncollapseIfNeeded();
    }
  };

  return (
    <>
      <S.Menu isGuest={isGuest} withBanner={displayBanner}>
        <ArticleSidebarMenu
          assetName={article.entity.tag_name}
          assetEntity={article.entity.tag}
          sectionsRefs={sectionsRefs}
          onBeforeScroll={handleCollapseOnClick}
          collapseRef={parentRef}
          isLockedView={isGuest}
          isBasic={isBasic}
        />
      </S.Menu>

      <S.Summary withBanner={displayBanner}>
        <ArticleSidebarSummary article={article} />
      </S.Summary>

      <S.Share>
        <ActionMenu
          title={article.title}
          sharedLink={window.location.href}
          tags={[]}
        />
      </S.Share>
    </>
  );
};
