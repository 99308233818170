import { useAuthStore } from '~/stores/use-auth/store/authStore';

export enum CloseReason {
  InactiveTab = 'InactiveTab',
  NoConnectionRequired = 'NoConnectionRequired',
}

const NORMAL_CLOSURE = 1000;

export class BaseWs<T> {
  private ws!: WebSocket;
  private reconnectionId = 0;
  readonly token = useAuthStore.getState().token;
  readonly reconnectionsLimit = 5;
  private reconnections = 0;
  readonly reconnectionTimeout = 500;
  onopen: ((event: Event) => void) | null = null;
  onmessage: ((data: T) => void) | null = null;
  onerror: ((error: Error) => void) | null = null;
  onclose: ((event: CloseEvent) => void) | null = null;
  closed = false;

  constructor(websocketUrl: string) {
    this.initializeWs(websocketUrl);
  }

  private waitForWSConnection() {
    const executor = (
      resolve: (value: void) => void,
      reject: (reason: string) => void
    ) => {
      if (this.ws.readyState === WebSocket.OPEN) {
        resolve();
      } else if (this.reconnections < this.reconnectionsLimit) {
        this.reconnections++;
        this.reconnectionId = window.setTimeout(
          executor,
          this.reconnectionTimeout,
          resolve,
          reject
        );
      } else {
        reject(
          `Failed to connect to WebSocket. WebSocket readyState is ${this.ws.readyState}`
        );
      }
    };

    return new Promise(executor).finally(() => {
      this.reconnections = 0;
    });
  }

  get readyState() {
    return this?.ws.readyState || WebSocket.CONNECTING;
  }

  initializeWs(websocketUrl: string) {
    const token = this.token;
    if (!token) {
      throw new Error('No token');
    }

    this.ws = new WebSocket(websocketUrl, ['bearer', token]);

    this.ws.onopen = async (event: Event) => {
      try {
        await this.waitForWSConnection();
        window.clearTimeout(this.reconnectionId);
        this.closed = false;
        this.onopen?.(event);
      } catch (error) {
        this.onerror?.(error as Error);
      }
    };

    this.ws.onmessage = (event: MessageEvent) => {
      this.onmessage?.(JSON.parse(event.data));
    };

    this.ws.onclose = (event: CloseEvent) => {
      this.closed = true;
      this.onclose?.(event);

      if (event.reason || this.reconnectionsLimit === this.reconnections) {
        return;
      }

      this.reconnectionId = window.setTimeout(
        () => this.initializeWs(websocketUrl),
        this.reconnectionTimeout
      );
      this.reconnections++;
    };
  }

  sendMessage(message: string) {
    if (this.ws.readyState === WebSocket.OPEN) {
      this.ws.send(message);
    }
  }

  close(reason: CloseReason) {
    this.ws.close(NORMAL_CLOSURE, reason);
  }

  unsubscribe() {
    if (this.reconnectionId) {
      clearTimeout(this.reconnectionId);
    }
    this.close(CloseReason.NoConnectionRequired);
  }
}
