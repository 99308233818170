import {
  CheckBox,
  deviceConstraints,
  Modal,
  typography,
} from '@toggle/design-system';
import styled from 'styled-components';

export const StyledCheckbox = styled(CheckBox)`
  ${CheckBox.Styled.CheckboxLabel} {
    ${typography.emu};
    color: var(--base--60);
  }
`;

export const StyledModal = styled(Modal)`
  ${Modal.Styled.ModalContainer} {
    top: 50%;
    width: calc(100% - 16px);
    left: 8px;
    transform: translateY(-50%);
    bottom: unset;

    @media ${deviceConstraints.desktopSmall} {
      width: auto;
      left: 50%;
      transform: translate(-50%, -50%);
      max-width: 660px;
    }
  }
`;

export const Container = styled.section`
  display: flex;
`;

export const Content = styled.p`
  ${typography.dingo};
  padding-bottom: 8px;
`;

export const Main = styled.main`
  flex: 3;
`;

export const CheckboxContainer = styled.div`
  padding-bottom: 16px;
`;

export const ImageContainer = styled.aside`
  flex: 1;
  text-align: right;
  margin-left: 8px;
  max-width: 120px;

  @media ${deviceConstraints.desktopSmall} {
    max-with: unset;
  }
`;
