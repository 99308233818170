import {
  ChartAssetData,
  chartResamples,
  Domain,
  PaneData,
  PriceDisplay,
  RANGE_HORIZON_DEFAULT,
  RangeHorizon,
  RESAMPLE_DEFAULT,
  ResampleIntervals,
  SeriesType,
  YAxisType,
} from '@toggle/chart';
import { v4 } from 'uuid';

import { shouldDisable1DHorizon } from '../resample-utils/resample-utils';

export interface ChartSearchParamsProps {
  searchParams: URLSearchParams;
  chartPanes: PaneData[];
  resample: ResampleIntervals;
  horizon: RangeHorizon | null;
  seriesType: SeriesType;
  domain: Domain | null;
  hidden: string[] | null;
  yAxisType: YAxisType;
  priceDisplay: PriceDisplay;
}

interface ChartSearchParamsAsset {
  tag: string;
  snake: string;
  name: string;
  paneIndex: number;
}

type ChartSearchParamsAssetValue = [
  tag: string,
  snake: string,
  name: string,
  paneIndex: number
];

export interface ChartSettings
  extends ReturnType<typeof getChartSettingsFromSearchParams> {}

export interface ValidChartSettings extends ChartSettings {
  assets: ChartSearchParamsAsset[];
  resample: ResampleIntervals;
}

export enum ChartSearchParams {
  Asset = 'asset',
  Resample = 'resample',
  SeriesType = 'seriesType',
  Horizon = 'horizon',
  Domain = 'domain',
  Hidden = 'hidden',
  YAxisType = 'yAxisType',
  PriceDisplay = 'priceDisplay',
}

const isChartParamsAssetValid = (asset: ChartSearchParamsAsset) =>
  (['tag', 'name', 'snake'] as (keyof ChartSearchParamsAsset)[]).every(
    key => asset[key]
  );

export const checkSearchParams = (
  chartSettings: ChartSettings
): chartSettings is ValidChartSettings =>
  !!chartSettings.assets.length &&
  chartSettings.assets.every(isChartParamsAssetValid);

export const getChartSettingsFromSearchParams = (
  searchParams: URLSearchParams
) => {
  const assets = searchParams.getAll(ChartSearchParams.Asset);
  const assetsValue = assets.map(string => {
    const [tag, snake, name, paneIndex = 0] = string.split(',');
    return {
      tag,
      snake,
      name,
      paneIndex: +paneIndex,
    };
  });

  const seriesType = searchParams.get(ChartSearchParams.SeriesType);
  const seriesTypeValue =
    (['line', 'bars', 'candlestick'] as SeriesType[]).find(
      item => item === seriesType
    ) ?? 'line';
  const customDomain = searchParams
    .get(ChartSearchParams.Domain)
    ?.split(',')
    .map(Number)
    .filter(number => !Number.isNaN(number));

  const customDomainValue =
    customDomain?.length === 2 ? (customDomain as Domain) : null;
  const resample = searchParams.get(ChartSearchParams.Resample);
  const resampleValue =
    chartResamples.find(item => item === resample) ?? RESAMPLE_DEFAULT;

  const horizon = searchParams.get(ChartSearchParams.Horizon);
  const horizonValue =
    Object.values(RangeHorizon).find(item => item === horizon) || null;
  const hidden = searchParams.get(ChartSearchParams.Hidden)?.split(',');
  const yAxisType = searchParams.get(ChartSearchParams.YAxisType);
  const yAxisTypeValue: YAxisType =
    yAxisType && ['merged', 'split'].includes(yAxisType)
      ? (yAxisType as YAxisType)
      : 'merged';
  const priceDisplay = searchParams.get(ChartSearchParams.PriceDisplay);
  const priceDisplayValue: PriceDisplay =
    priceDisplay && ['price', 'percentage'].includes(priceDisplay)
      ? (priceDisplay as PriceDisplay)
      : 'price';

  return {
    assets: assetsValue,
    seriesType: seriesTypeValue,
    resample: resampleValue,
    horizon: horizonValue,
    domain: customDomainValue,
    yAxisType: yAxisTypeValue,
    priceDisplay: priceDisplayValue,
    hidden,
  };
};

export const buildChartSearchParams = ({
  searchParams,
  chartPanes,
  ...rest
}: Partial<ChartSearchParamsProps>) => {
  const urlSearchParams = new URLSearchParams(searchParams);
  const keys = Object.keys(rest) as Exclude<
    ChartSearchParams,
    ChartSearchParams.Asset
  >[];

  if (chartPanes) {
    urlSearchParams.delete(ChartSearchParams.Asset);
    chartPanes.forEach((pane, index) => {
      pane.chartAssetData.forEach(item => {
        const value: ChartSearchParamsAssetValue = [
          item.entity.tag,
          item.entity.default_snake,
          item.entity.name,
          index,
        ];
        urlSearchParams.append(ChartSearchParams.Asset, value.toString());
      });
    });
  }

  keys.forEach(key => {
    const value = rest[key];
    if (value) {
      urlSearchParams.set(key, value.toString());
    } else if (value === null) {
      urlSearchParams.delete(key);
    }
  });

  urlSearchParams.sort();
  return urlSearchParams;
};

export const getValidHorizon = ({
  horizon,
  chartData,
  emptyResamples,
  hasIndicatorInList,
  resample,
}: {
  horizon: RangeHorizon | null;
  chartData: ChartAssetData[];
  emptyResamples: ResampleIntervals[];
  hasIndicatorInList: boolean;
  resample: ResampleIntervals;
}) => {
  if (horizon !== RangeHorizon.OneDay) {
    return horizon;
  }

  return shouldDisable1DHorizon({
    chartData,
    emptyResamples,
    hasIndicatorInList,
    resample,
  })
    ? RANGE_HORIZON_DEFAULT
    : horizon;
};

export interface CreateChartPaneDataProps {
  chartAssetData: ChartAssetData[];
  paneStartIndexes: number[];
  seriesType: ChartSettings['seriesType'];
  priceDisplay: ChartSettings['priceDisplay'];
  yAxisType: ChartSettings['yAxisType'];
}

export const createChartPanesData = ({
  chartAssetData,
  paneStartIndexes,
  seriesType,
  priceDisplay,
  yAxisType,
}: CreateChartPaneDataProps): PaneData[] =>
  paneStartIndexes.map((index, idx) => {
    const isPrimaryPane = index === 0;
    if (isPrimaryPane) {
      const data = chartAssetData.slice(0, paneStartIndexes[idx + 1]);
      return {
        id: v4(),
        chartAssetData: data,
        seriesType,
        priceDisplay,
        yAxisType,
      };
    }

    const data = chartAssetData.slice(index, paneStartIndexes[idx + 1]);

    return {
      id: v4(),
      chartAssetData: data,
      seriesType: 'line',
      priceDisplay: 'price',
      yAxisType: 'merged',
    };
  });
