import { AlertType } from '@toggle/design-system';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { accountRoutes, appPaths } from '~/routes/app-paths';
import { HeaderNotificationTooltip } from '~/shared/components/header-notification-buttons/HeaderNotificationTooltip';
import {
  gaHeaderClickPill,
  gaHeaderNotificationAction,
} from '~/shared/utils/ganalytics';
import { useSubscription } from '~/stores/use-subscription/useSubscription';
import { useNavigateToCheckout } from '~/views/my-account/subscription/hooks/use-navigate-to-checkout/useNavigateToCheckout';
import { PlanCardFeatureItem } from '~/views/my-account/subscription/plan-card-features/PlanCardFeatureItem';

import { Markdown } from '../../markdown/Markdown';
import * as S from './PromoPill.styles';

export interface PromoPillProps {
  pillLabel?: string;
  promoCode: string;
  messageMd?: string;
}

export const PromoPill = ({
  pillLabel,
  promoCode,
  messageMd = '',
}: PromoPillProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const userRole = useSubscription(s => s.userSubscription.role);
  const upgradeToPro = useNavigateToCheckout();
  const [isTooltipOpened, setIsTooltipOpened] = useState(false);

  const showTooltip = () => {
    gaHeaderNotificationAction('promo-warning', userRole, 'open', promoCode);
    setIsTooltipOpened(true);
  };

  const onClose = () => {
    setIsTooltipOpened(false);
  };

  const handleSubscribe = () => {
    gaHeaderNotificationAction('promo-warning', userRole, 'confirm', promoCode);
    gaHeaderClickPill();
    onClose();
    upgradeToPro();
  };

  const viewPlans = () => {
    onClose();
    navigate(`${appPaths.myAccount.subscription}/${accountRoutes.selectPlan}`);
  };

  return (
    <HeaderNotificationTooltip
      isOpen={isTooltipOpened}
      offsetX={80}
      showArrow={false}
      label={
        <S.StyledAlert
          data-testid="promo-notification-alert"
          type={AlertType.Info}
          message={
            <Markdown
              markdownProps={{
                children: messageMd,
                components: {
                  h2: ({ children }) => <S.Title>{children}</S.Title>,
                  p: ({ children }) => <S.Text>{children}</S.Text>,
                  ul: ({ children }) => <S.Ul>{children}</S.Ul>,
                  li: ({ children }) => (
                    <PlanCardFeatureItem>{children}</PlanCardFeatureItem>
                  ),
                },
              }}
            />
          }
          primaryBtn={{
            'data-testid': 'subscribe-to-pro-button',
            label: t('subscription:subscribeToPro'),
            onClick: handleSubscribe,
          }}
          secondaryBtn={{
            'data-testid': 'view-plans-button',
            label: t('subscription:viewAllPlans'),
            onClick: viewPlans,
          }}
          onClose={onClose}
        />
      }
      onClose={onClose}
    >
      <S.StyledBadge
        color="neutral"
        size="small"
        onClick={showTooltip}
        leftIcon="InformationLightCircle"
        label={pillLabel}
      />
    </HeaderNotificationTooltip>
  );
};
