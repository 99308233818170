import { typographyV2 } from '@toggle/design-system';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: space-between;
  padding: 12px;
  border-bottom: 1px solid var(--border-soft);
  background: var(--surface-hover);
`;

export const AssetTicker = styled.span`
  margin-left: 8px;
  ${typographyV2.bodySm}
  text-transform: uppercase;
`;

export const AssetWrapper = styled.span`
  display: flex;
  align-items: center;
  flex: 1;
  margin-right: 10px;
`;
