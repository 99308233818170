import { deviceConstraints, Icon, typography } from '@toggle/design-system';
import styled, { css } from 'styled-components';

export const Footer = styled.div`
  display: block;

  @media ${deviceConstraints.tablet} {
    display: none;
  }
`;

const sharedButtonStyles = css`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const ReadFullBtn = styled.a`
  padding: 8px 14px 8px 40px;
  color: var(--base--100);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  background-color: var(--primary-color);
  ${sharedButtonStyles};

  &:hover {
    color: var(--base--100);
  }
`;

export const ReadText = styled.p`
  ${typography.dingo};
  font-weight: 500;
  flex-grow: 1;
`;

export const ListIcon = styled(Icon)`
  margin-right: 12px;
`;

export const ArticleButton = styled.div`
  &:hover {
    color: var(--base--100);
  }
`;

export const ReadMoreBtn = styled.div`
  padding-left: 12px;
  border-top: 1px solid var(--primary-color);
  color: var(--primary-color);
  ${sharedButtonStyles};

  ${ArticleButton} {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 39px;
    border-bottom-right-radius: 4px;
    background-color: var(--primary-color);
  }
`;

export const FooterChevronDown = styled(Icon).attrs({
  iconName: 'ChevronBoldDown',
  size: 16,
  fillColor: 'var(--base--100)',
})<{ $isExpanded?: boolean }>`
  ${({ $isExpanded }) => $isExpanded && 'transform: rotate(-90deg);'};
`;
