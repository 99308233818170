import { Icon } from '@toggle/design-system';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Expiration,
  Order,
} from '~/global/trading-drawer/use-transaction/useTransaction';
import { TooltipContainer } from '~/shared/components/tooltip-container/TooltipContainer';
import { formatAssetPrice } from '~/shared/utils/currency/currency';

import { ExpirationList } from '../expiration-list/ExpirationList';
import { LinkedInput } from './linked-Input/LinkedInput';
import * as S from './MarketOrder.styles';

export interface LinkedInputValues {
  quantity: string;
  price: string;
}

export interface MarketOrderProps {
  handleMarketOrderPrice: (amount: LinkedInputValues) => void;
  handleMarketOrderExpiration: (expiration: Expiration) => void;
  marketPrice?: number;
  order: Order;
  isDelayed: boolean;
  isFrozen: boolean;
  priceDecimals: number;
  tradingProviderLabel: string;
}

export const MarketOrder: FC<MarketOrderProps> = ({
  handleMarketOrderPrice,
  handleMarketOrderExpiration,
  marketPrice,
  order,
  isDelayed,
  isFrozen,
  priceDecimals,
  tradingProviderLabel,
}) => {
  const { t } = useTranslation('portfolio');

  const getPriceStatus = (): { type: S.PriceType; label: string } => {
    if (isFrozen) {
      return { type: 'frozen', label: t('portfolio:lastClose') };
    }
    if (isDelayed) {
      return { type: 'delayed', label: t('portfolio:delayed') };
    }
    return { type: 'live', label: t('portfolio:live') };
  };
  const priceStatus = getPriceStatus();
  const hasMarketPrice = marketPrice !== undefined;

  return (
    <S.Container data-testid="market-order">
      {hasMarketPrice && (
        <>
          <S.Row>
            <S.Label>
              {t('portfolio:marketPrice')}
              <TooltipContainer label={t('portfolio:marketPriceTooltip')}>
                <S.ToolTipWrapper>
                  <Icon iconName="QuestionMarkFill" size={14} />
                </S.ToolTipWrapper>
              </TooltipContainer>
            </S.Label>

            <S.Price data-testid="market-price">
              {formatAssetPrice(marketPrice, order.currency)}
              <TooltipContainer label={priceStatus.label}>
                <S.Dot type={priceStatus.type} data-testid={priceStatus.type} />
              </TooltipContainer>
            </S.Price>
          </S.Row>
          <LinkedInput
            marketPrice={marketPrice}
            currencySymbol={order.currencySymbol}
            currency={order.currency}
            onChange={handleMarketOrderPrice}
            defaultValue={{
              quantity: order.quantity ? order.quantity.toString() : '',
              totalPrice: '',
            }}
            priceDecimals={priceDecimals}
            tradingProviderLabel={tradingProviderLabel}
          />
          <S.Row>
            <S.Label>{t('portfolio:expiration')}</S.Label>
            <S.InputWrapper>
              <ExpirationList
                value={order.expiration}
                onChange={handleMarketOrderExpiration}
              />
            </S.InputWrapper>
          </S.Row>
        </>
      )}
    </S.Container>
  );
};
