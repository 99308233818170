import { deviceConstraints } from '@toggle/design-system';
import styled, { css } from 'styled-components';

import { Box } from '../box';
import Commodities from './icons/commodities.svg';
import Crypto from './icons/crypto.svg';
import Currencies from './icons/currencies.svg';
import EtFs from './icons/et-fs.svg';
import FixedIncome from './icons/fixed-income.svg';
import Stock from './icons/stock.svg';

export const Container = styled.div`
  display: grid;
  grid-template-areas:
    'stocks'
    'currencies'
    'commodities'
    'crypto'
    'options'
    'fixedIncome'
    'geo';

  @media ${deviceConstraints.desktopSmall} {
    grid-template-areas:
      'stocks currencies commodities'
      'crypto options fixedIncome'
      'geo geo geo';
    grid-gap: 8px 16px;
  }
`;

export const StocksBox = styled(Box)`
  grid-area: stocks;
`;

export const CurrenciesBox = styled(Box)`
  grid-area: currencies;
`;

export const CommoditiesBox = styled(Box)`
  grid-area: commodities;
`;

export const CryptoBox = styled(Box)`
  grid-area: crypto;
`;

export const OptionsBox = styled(Box)`
  grid-area: options;
`;

export const FixedIncomeBox = styled(Box)`
  grid-area: fixedIncome;
`;

const svgCss = css`
  width: 24px;
  height: 24px;
  color: var(--primary-color);

  @media ${deviceConstraints.desktopSmall} {
    width: 57px;
    height: 57px;
  }
`;

export const StockSvg = styled(Stock)`
  ${svgCss}
`;

export const CurrenciesSvg = styled(Currencies)`
  ${svgCss}
`;

export const CommoditiesSvg = styled(Commodities)`
  ${svgCss}
`;

export const CryptoSvg = styled(Crypto)`
  ${svgCss}
`;

export const OptionsSvg = styled(EtFs)`
  ${svgCss}
`;

export const FixedIncomeSvg = styled(FixedIncome)`
  ${svgCss}
`;

export const GeoWrapper = styled.div`
  grid-area: geo;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0 8px;

  @media ${deviceConstraints.desktopSmall} {
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 0 16px;
  }
`;
