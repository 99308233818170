import { Input } from '@toggle/design-system';
import styled from 'styled-components';

export const EyeButton = styled.button`
  cursor: pointer;
  padding: 10px;
  display: block;
  border: none;
  background: transparent;
  color: var(--base--0);
  outline: 0;
  position: absolute;
  right: 0;
  top: 0;

  &:hover,
  &:focus,
  &:active {
    color: var(--copilot--hover);
  }
`;

export const StyledInput = styled(Input)`
  ${Input.Styles.InputElement} {
    &::-ms-reveal {
      display: none;
    }
  }
`;

export const IconWrap = styled.div`
  display: flex;
`;
