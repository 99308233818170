import React, { FC } from 'react';

import { TooltipContainer } from '~/shared/components/tooltip-container/TooltipContainer';

import * as S from './QualityCheckListItem.styles';

interface QualityCheckListItemProps {
  star: boolean;
  title: string;
  tooltipContent: string;
  value: string;
  subText: string;
}

export const QualityCheckListItem: FC<QualityCheckListItemProps> = ({
  star,
  title,
  tooltipContent,
  value,
  subText,
}) => (
  <li data-testid="quality-checklist-item">
    <S.Star iconName="Rating" size={24} $active={star} />
    <div>
      <TooltipContainer label={tooltipContent} showUnderline>
        <S.TooltipText>{title}</S.TooltipText>
      </TooltipContainer>
      <S.SmallMessage data-testid="quality-checklist-sub-text" $active={star}>
        {value} <S.SubMessage>{subText}</S.SubMessage>
      </S.SmallMessage>
    </div>
  </li>
);
