import React, { FC } from 'react';
import ReactMarkdown from 'react-markdown';
import { ReactMarkdownOptions } from 'react-markdown/lib/react-markdown';

import { ErrorBoundary } from '~/shared/components/error-boundary';

interface MarkdownProps {
  markdownProps: ReactMarkdownOptions;
  className?: string;
}

export const Markdown: FC<MarkdownProps> = ({ markdownProps, className }) => {
  return (
    <ErrorBoundary fallback={null}>
      <ReactMarkdown className={className} {...markdownProps} />
    </ErrorBoundary>
  );
};
