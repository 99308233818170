import styled, { css, keyframes } from 'styled-components';

const loading = keyframes`
  from {
    background-position: 0 0;
  }
  to {
    background-position: -200% 0;
  }
`;

const viewWrapperShared = css`
  background-color: var(--base--95);
  padding: 12px;
`;

const defaultShared = css`
  opacity: 0.35;
  background-image: linear-gradient(
    to right,
    var(--base--80),
    var(--base--35) 85%,
    var(--base--35),
    var(--base--80) 100%
  );
  background-size: 200%;
  animation: ${loading} 1.4s infinite;
`;

export const Wrapper = styled.section<{
  $withWrapper?: boolean;
  $defaultView?: boolean;
}>`
  width: 100%;
  height: fit-content;
  ${({ $withWrapper }) => $withWrapper && viewWrapperShared}
  ${({ $defaultView }) =>
    $defaultView &&
    css`
      ${viewWrapperShared}
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
    `}
`;

export const DefaultTitle = styled.div`
  ${defaultShared}
  width: 30%;
  height: 12px;
  border-radius: 100px;
  margin: 8px 0 24px;
`;

export const DefaultContent = styled.div`
  ${defaultShared}
  opacity: 0.1;
  border-radius: 10px;
  flex-grow: 1;
`;

export const Area = styled.div`
  ${defaultShared}
`;
