import React, { FC } from 'react';

import { ArticleView } from '~/views/article/ArticleView';

import { ArticleProvider } from './context/ArticleContext';

export const Article: FC = () => {
  return (
    <ArticleProvider>
      <ArticleView />
    </ArticleProvider>
  );
};
