import React, { FC, MouseEvent } from 'react';

import * as S from './CardFooter.styles';

export interface CardFooterProps {
  showFullBtn?: boolean;
  readText: string;
  url: string;
  onReadBtnClick?: (event: MouseEvent) => void;
  toggleSummaryExpanding?: () => void;
}

export const CardFooter: FC<CardFooterProps> = ({
  showFullBtn = true,
  readText,
  url,
  onReadBtnClick,
  toggleSummaryExpanding,
}) => {
  return (
    <S.Footer>
      {showFullBtn ? (
        <S.ReadFullBtn
          href={url}
          target="_blank"
          onClick={onReadBtnClick}
          rel="noopener noreferrer"
          data-testid="card-footer-read-full-btn"
        >
          <S.ReadText data-testid="read-text">{readText}</S.ReadText>
          <S.ArticleButton>
            <S.FooterChevronDown $isExpanded={showFullBtn} />
          </S.ArticleButton>
        </S.ReadFullBtn>
      ) : (
        <S.ReadMoreBtn>
          <S.ListIcon
            iconName="Watchlist"
            size={20}
            fillColor="var(--primary-color)"
            data-testid="list-icon"
          />
          <S.ReadText onClick={toggleSummaryExpanding} data-testid="read-text">
            {readText}
          </S.ReadText>
          <S.ArticleButton
            as="a"
            href={url}
            target="_blank"
            onClick={onReadBtnClick}
            rel="noopener noreferrer"
          >
            <S.FooterChevronDown />
          </S.ArticleButton>
        </S.ReadMoreBtn>
      )}
    </S.Footer>
  );
};
