import { typographyV2 } from '@toggle/design-system';
import styled from 'styled-components';

export const ConditionTitle = styled.p`
  ${typographyV2.titleBase};
`;

export const ListContainer = styled.ul`
  list-style-type: none;

  li {
    display: flex;
    margin: 10px 0;
  }
`;
