import React, { FC } from 'react';

import * as S from './SearchDivider.styles';

interface SearchDividerProps {
  text: string;
  isNotEnough?: boolean;
  isNoResults: boolean;
}

export const SearchDivider: FC<SearchDividerProps> = ({
  text,
  isNoResults,
  isNotEnough = false,
}) => (
  <S.Divider
    isNotEnough={isNotEnough}
    isNoResults={isNoResults}
    data-testid="search-divider"
  >
    {text}
  </S.Divider>
);
