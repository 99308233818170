import React, { FC, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { ExploreArticleDirection } from '~/declarations/toggle-api-enums';
import { ArticleSummary } from '~/declarations/toggle-api-news';
import { MODAL_TYPES } from '~/global/paywall/services/modal-types/modalTypes';
import { getModalTypeByArticle } from '~/global/paywall/services/paywall-service/paywallService';
import { usePaywallStore } from '~/global/paywall/stores/usePaywallStore';
import { appPaths } from '~/routes/app-paths';
import { ArticleImage } from '~/shared/components/article-card/article-image/ArticleImage';
import { ALERT_DESCRIPTION_DATA_MAP } from '~/shared/components/signal-summary/AlertSignalDescriptionByType';
import { EntityAnalyticsReportType } from '~/shared/services/overview-widget/overview-widget-service';
import { gaClickArticlePreview } from '~/shared/utils/ganalytics';
import { useArticleLimit } from '~/stores/use-article-limit/useArticleLimit';
import { useHelper } from '~/stores/use-helper/useHelper';
import { useSubscription } from '~/stores/use-subscription/useSubscription';
import { exploreSectionsRefs } from '~/views/helper/helper-widget/content/explore/ExploreHelperContent';
import { EXPLORE_SECTION_IDS } from '~/views/helper/HelperConfig';

import { ArticleCardFooter } from './article-card-footer/ArticleCardFooter';
import { ArticleCardHeader } from './article-card-header/ArticleCardHeader';
import { getArticleSectorKey } from './article-card-sector';
import * as S from './ArticleCard.styles';

export interface ArticleCardProps {
  data: ArticleSummary;
  className?: string;
  articleIdx: number;
  inArticleFeed?: boolean;
  location: 'Article' | 'Explore';
}

export const ArticleCard: FC<ArticleCardProps> = ({
  data,
  className,
  articleIdx,
  inArticleFeed,
  location,
}) => {
  const { t } = useTranslation('explore');
  const openModal = usePaywallStore(state => state.openModal);
  const isLimitExceeded = useArticleLimit(state => state.isLimitExceeded);
  const { setHelperHoverBehavior } = useHelper();
  const alertDescription =
    ALERT_DESCRIPTION_DATA_MAP[data.type as EntityAnalyticsReportType];
  const linkRef = useRef<HTMLAnchorElement>(null);
  const sectorKey = useMemo(() => getArticleSectorKey(data), [data]);
  const sector = sectorKey && t(`explore:sector.${sectorKey}`);

  const { userRole, isBasic } = useSubscription(
    ({ userSubscription, isBasic }) => ({
      userRole: userSubscription.role,
      isBasic: isBasic(),
    })
  );
  const locked = data.article_id === null;

  const handleArticleClick = () => {
    gaClickArticlePreview(
      data.article_id,
      data.ellipsis,
      data.entity,
      userRole,
      locked,
      !!inArticleFeed,
      location
    );

    if (locked) {
      const modalType = getModalTypeByArticle(data, isBasic);
      openModal(modalType);
      return;
    }

    if (isLimitExceeded) {
      openModal(MODAL_TYPES.ARTICLE_LIMIT);
      return;
    }

    linkRef.current?.click();
  };

  return (
    <S.Article
      className={className}
      onClick={handleArticleClick}
      data-testid="card-wrapper"
      ref={
        articleIdx === 0
          ? exploreSectionsRefs[
              EXPLORE_SECTION_IDS.EXPLORE_INSIGHT_CARD_CHECK_ID
            ]
          : null
      }
      {...(articleIdx === 0 &&
        setHelperHoverBehavior(
          EXPLORE_SECTION_IDS.EXPLORE_INSIGHT_CARD_CHECK_ID
        ))}
    >
      {locked && (
        <S.LockedContainer>
          <p>{t('explore:locked.subscribe')}</p>
        </S.LockedContainer>
      )}
      <ArticleCardHeader
        t={t}
        stars={data.stars}
        tag={alertDescription ? alertDescription.alertShortName : data.type}
        sector={sector}
        increase={data.direction === ExploreArticleDirection.Bullish}
        hot={data.hot_topic}
        newArticle={data.new}
        locked={locked}
      />

      <S.Body>
        <h2 data-testid="title">
          {data.ellipsis}
          {locked && <S.LockedIcon />}
        </h2>

        <S.CreatedOn date={data.created_on} />
      </S.Body>
      <S.ImageContainer data-testid="article-image-container">
        <ArticleImage data={data} locked={locked} />
      </S.ImageContainer>

      <ArticleCardFooter data={data} articleIdx={articleIdx} />

      <Link
        data-testid="link-to-article-from-title"
        onClick={event => event.stopPropagation()}
        ref={linkRef}
        target={'_blank'}
        to={`${appPaths.article}/${data.article_id}`}
        state={{ isFromFeed: false }}
      />
    </S.Article>
  );
};
