import React, { FC, ReactNode } from 'react';

import {
  SocialLink,
  socialLink,
  SocialUrls,
} from '~/shared/utils/url-helper/url-utils';

import * as S from './SocialShareButton.styles';

export enum IconSize {
  Large = 'lg',
  Medium = 'md',
  Small = 'sm',
}
export interface SocialShareButtonProps {
  provider: SocialLink;
  label?: string;
  iconSize?: IconSize;
  beforeOnClick?: () => void;
  children?: ReactNode;
}

export const SocialShareButton: FC<SocialShareButtonProps> = ({
  children,
  label,
  iconSize = IconSize.Medium,
  provider,
  beforeOnClick,
  ...rest
}) => {
  const socialRef = socialLink(provider);
  const iconSizeRef = { lg: 30, md: 20, sm: 15 };
  const icons = {
    [SocialUrls.Twitter]: 'Twitter',
    [SocialUrls.Facebook]: 'Facebook',
    [SocialUrls.Reddit]: 'Reddit',
    [SocialUrls.Mail]: 'Mail',
  } as const;

  return (
    <S.SharedButtonContainer $hasLabel={!!label}>
      <S.Shared
        leftIcon={icons[provider.provider]}
        type="choice"
        label={label}
        leftIconSize={iconSizeRef[iconSize]}
        onClick={() => {
          window.open(socialRef, '_blank')?.focus();
          beforeOnClick?.();
        }}
        {...rest}
      >
        {children}
      </S.Shared>
    </S.SharedButtonContainer>
  );
};
