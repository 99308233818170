import throttle from 'lodash/throttle';
import { RefObject, useLayoutEffect } from 'react';

import { Optional, OptionalU } from '~/types';

export function useScrollListener(
  containerRef: RefObject<Optional<HTMLElement>>,
  callback: () => OptionalU<boolean>,
  deps: unknown[] = [],
  isGlobalScrollListened = true
) {
  useLayoutEffect(() => {
    const innerContainer = containerRef.current as HTMLElement;
    if (!innerContainer) {
      return undefined;
    }

    const containerToListen = isGlobalScrollListened ? window : innerContainer;

    function handleScroll() {
      let scrollTop, viewHeight;
      if (isGlobalScrollListened) {
        ({ scrollY: scrollTop, innerHeight: viewHeight } =
          containerToListen as Window);
      } else {
        ({ offsetHeight: viewHeight, scrollTop } =
          containerToListen as HTMLElement);
      }

      const {
        offsetTop: containerTop,
        offsetHeight: containerHeight,
        scrollHeight: containerScrollHeight,
      } = innerContainer;

      const scrolledToBottom = isGlobalScrollListened
        ? viewHeight + scrollTop - containerHeight - containerTop >= 0
        : containerScrollHeight - viewHeight - scrollTop <= 0;

      if (scrolledToBottom) {
        const result = callback();

        if (result !== undefined && !result) {
          //If null or false is explicitly returned, this signals to unbind
          unbindHandleScroll();
        }
      }
    }

    const handleScrollThrottle = throttle(handleScroll, 500);

    containerToListen.addEventListener('scroll', handleScrollThrottle);
    const unbindHandleScroll = () => {
      containerToListen.removeEventListener('scroll', handleScrollThrottle);
      handleScrollThrottle.cancel();
    };

    return unbindHandleScroll;
  }, deps);
}
