import { SvgIconNames } from '@toggle/design-system';
import classnames from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';

import { OptionalU } from '~/declarations/standard';

import * as S from './SelectBtnGroup.styles';

interface SelectBtnGroupItem<T> {
  key: string;
  className?: string;
  data?: T;
  label?: string;
  iconName?: OptionalU<SvgIconNames>;
}

interface SelectBtnGroupProps<T = undefined> {
  className?: string;
  items: SelectBtnGroupItem<T>[];
  value?: string[];
  onChange: (values: string) => void;
  multi?: boolean;
  initialValue?: string[];
  locked?: boolean;
}

export function SelectBtnGroup<T = undefined>({
  className,
  items,
  value,
  onChange,
  multi,
  initialValue,
  locked,
}: SelectBtnGroupProps<T>) {
  const [selectedItems, setSelectedItems] = useState(
    initialValue ?? value ?? []
  );

  const onBtnSelect = useCallback(
    (key: string) => {
      let newSelectedItems;

      if (!multi) {
        newSelectedItems = selectedItems.indexOf(key) >= 0 ? [] : [key];
      } else if (selectedItems.indexOf(key) >= 0) {
        newSelectedItems = selectedItems.filter(itemKey => itemKey !== key);
      } else {
        newSelectedItems = [...selectedItems, key];
      }

      onChange(key);

      if (!value) {
        setSelectedItems(newSelectedItems);
      }
    },
    [selectedItems, multi, value]
  );

  useEffect(() => {
    if (value) {
      setSelectedItems(value);
    }
  }, [value]);

  return (
    <S.BtnGroup className={className} data-testid="custom-buttonGroup">
      {items.map(item => {
        const isSelected = selectedItems.indexOf(item.key) >= 0;
        return (
          <S.Button
            key={item.key}
            locked={locked}
            className={classnames(
              item.className,
              isSelected && 'btnGroupSelected'
            )}
            size="small"
            label={item.label}
            iconName={item.iconName}
            variant="secondary"
            onClick={() => onBtnSelect(item.key)}
            data-testid={`button-${item.key}`}
          />
        );
      })}
    </S.BtnGroup>
  );
}
