import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import IBLogo from '~/assets/core/ib-logo-small.svg';
import TradestationLogo from '~/assets/portfolio/tradestation-logo.svg';
import { TradingProvider } from '~/stores/use-portfolio/utils/use-portfolio-utils';

import * as S from './BrokerTitle.styles';

interface BrokerTitleProps {
  portfolioName: string;
  tradingProvider: TradingProvider;
}

export const BrokerTitle: FC<BrokerTitleProps> = ({
  portfolioName,
  tradingProvider,
}) => {
  const { t } = useTranslation('settings');

  return (
    <S.HeadingContainer>
      {tradingProvider === TradingProvider.Ib && <IBLogo />}
      {tradingProvider === TradingProvider.Tradestation && (
        <TradestationLogo
          width={20}
          height={20}
          alt={t('settings:portfolio.tsPortfolio.logoAlt')}
        />
      )}
      <S.Heading>{portfolioName}</S.Heading>
    </S.HeadingContainer>
  );
};
