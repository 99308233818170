import { News, NewsItem } from '~/declarations/toggle-api-news';
import { ENDPOINT_URLS } from '~/global/toggle-api';

import { APIFetch, ApiFetchResponse } from '../../services/api-fetch';
import { useAPI } from '../use-api/useAPI';

export type FetchNewsParams<T> = {
  type: T;
  tickers: string[];
  page?: number;
  limit?: number;
};

export enum NEWS_TYPE {
  PLAIN,
  AI,
  ALL,
}

const NEWS_ENDPOINTS: Record<NEWS_TYPE, string> = {
  [NEWS_TYPE.PLAIN]: ENDPOINT_URLS.PLAIN_NEWS,
  [NEWS_TYPE.AI]: ENDPOINT_URLS.AI_NEWS,
  [NEWS_TYPE.ALL]: ENDPOINT_URLS.ALL_NEWS,
};

const reduceNewsItemsResponse = (
  responses: ApiFetchResponse<NewsItem[]>[],
  tickers: string[]
) =>
  responses.reduce<News>((tickersMap, response, idx) => {
    if (!(response instanceof Error)) {
      return {
        ...tickersMap,
        [tickers[idx]]: response,
      };
    }

    return tickersMap;
  }, {});

const reduceNewsResponse = (responses: ApiFetchResponse<News>[]) =>
  responses.reduce<News>((tickersMap, response) => {
    if (!(response instanceof Error)) {
      return { ...tickersMap, ...response };
    }

    return tickersMap;
  }, {});

type Response = {
  [NEWS_TYPE.PLAIN]: NewsItem[];
  [NEWS_TYPE.AI]: NewsItem[];
  [NEWS_TYPE.ALL]: News;
};

export const fetchNews = async <T extends NEWS_TYPE>({
  type,
  tickers,
  page,
  limit,
}: {
  type: NEWS_TYPE;
  tickers: string[];
  page: number;
  limit: number;
}) => {
  // filter out / using regex
  const checkForBackslash = /[(\/\)]+/;
  const filteredTickers = tickers.filter(t => !checkForBackslash.test(t));
  const apiCalls = filteredTickers.map(ticker =>
    APIFetch<Response[T]>(
      `${NEWS_ENDPOINTS[type]}/${ticker}?page=${page}&limit=${limit}`
    )
  );

  return Promise.all(apiCalls).then(responses => {
    if (type === NEWS_TYPE.ALL) {
      return reduceNewsResponse(responses as ApiFetchResponse<News>[]);
    }

    return reduceNewsItemsResponse(
      responses as ApiFetchResponse<NewsItem[]>[],
      filteredTickers
    );
  });
};

export const useNews = <T extends NEWS_TYPE>({
  type,
  tickers,
  page = 0,
  limit = 20,
}: FetchNewsParams<T>) =>
  useAPI(() => fetchNews<T>({ type, tickers, page, limit }), {
    initialState: { data: {}, loading: true },
    shouldFetch: !!tickers.length,
    deps: [tickers.join(',')],
  });
