import { Modal, typographyV2 } from '@toggle/design-system';
import styled from 'styled-components';

export const StyledModal = styled(Modal)`
  ${Modal.Styled.TextContainer} {
    margin-top: 18px;
    margin-bottom: 0;
  }

  ${Modal.Styled.ModalContainer} {
    width: 600px;
  }
`;

export const Text = styled.p`
  ${typographyV2.bodyBase};
  color: var(--text-default);
`;
