import React from 'react';
import { useTranslation } from 'react-i18next';

import { authPaths } from '~/routes/app-paths';
import { useLocationKeepQueryParams } from '~/shared/hooks/use-location-keep-query-params/useLocationKeepQueryParams';

import * as S from './GuestHeader.styles';

export const GuestHeader = () => {
  const { t } = useTranslation('auth');
  const loginLink = useLocationKeepQueryParams(authPaths.login, [
    'ref',
    'promo',
  ]);
  const signUpLink = useLocationKeepQueryParams(authPaths.signUp, [
    'ref',
    'promo',
  ]);

  return (
    <S.Header data-testid="guest-header">
      <S.LogoLink to={authPaths.login}>
        <S.StyledLogo />
        <S.StyledMobileLogo />
      </S.LogoLink>
      <S.LoginButton to={loginLink}>
        {t('auth:login.loginButtonLabel')}
      </S.LoginButton>
      <S.SignupButton to={signUpLink}>{t('auth:login.signUp')}</S.SignupButton>
    </S.Header>
  );
};
