import { UserRole } from '~/api/subscription/subscription-schema';
import { AppRouteSections } from '~/routes/app-paths';

const BASIC_PAGES = [
  AppRouteSections.Portfolio,
  AppRouteSections.Analyze,
  AppRouteSections.Account,
  AppRouteSections.Explore,
  AppRouteSections.MyToggle,
  AppRouteSections.Support,
  AppRouteSections.Positions,
  AppRouteSections.GenAI,
];

export const RBACRules: Record<UserRole, AppRouteSections[]> = {
  [UserRole.Pro]: [
    ...BASIC_PAGES,
    AppRouteSections.Chart,
    AppRouteSections.Scenario,
    AppRouteSections.TurboChart,
  ],
  [UserRole.Copilot]: [...BASIC_PAGES, AppRouteSections.Chart],
  [UserRole.Basic]: BASIC_PAGES,
};
