import { ThemeGlobalStyles } from '@toggle/design-system';
import React, { useEffect } from 'react';

import { useMediaQueryColorScheme } from '~/shared/hooks/MediaQueryHook';
import {
  THEME_NAME_STORAGE_KEY,
  useRemoteStorage,
} from '~/shared/hooks/use-remote-storage';

import { useThemeStore } from './stores/useThemeStore';
import { changeFavicon } from './utils/change-favicon/changeFavicon';
import {
  isValidThemeName,
  splitThemeName,
} from './utils/theme-utils/theme-utils';

export const ThemeController = () => {
  const colorScheme = useMediaQueryColorScheme();
  const { changeTheme, getThemeName } = useThemeStore();
  const themeName = getThemeName();

  const storedThemeName = useRemoteStorage(
    state => state.items?.[THEME_NAME_STORAGE_KEY]
  );

  useEffect(() => {
    if (colorScheme) {
      changeFavicon(colorScheme.isDarkMode);
    }
  }, [colorScheme]);

  useEffect(() => {
    if (isValidThemeName(storedThemeName)) {
      const themeParts = splitThemeName(storedThemeName);
      changeTheme(themeParts);
    }
  }, [storedThemeName]);

  return <ThemeGlobalStyles $themeName={themeName} />;
};
