import styled from 'styled-components';

export const Container = styled.div<{ $width: number; $offset?: number }>`
  width: ${props => `${props.$width}px`};
  padding: 4px 8px;
  display: flex;
  justify-content: center;
  z-index: 1;
  position: absolute;
  right: ${props => (props.$offset ? `${props.$offset}px` : 0)};
  top: 8px;
`;

export const Label = styled.span`
  text-transform: uppercase;
`;
