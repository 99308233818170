import { typographyV2 } from '@toggle/design-system';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

interface MenuLinkProps {
  $active?: boolean;
  disabled?: boolean;
}

export const MainMenuNav = styled.nav`
  align-self: center;
  padding-left: 16px;
`;

export const MenuContainer = styled.ul`
  display: flex;
`;

export const MenuItem = styled.li`
  ${typographyV2.bodyMd};
  margin-right: 8px;
  border-radius: 4px;
`;

export const MenuLink = styled(NavLink)<MenuLinkProps>`
  color: var(--base--0);
  padding: 2px 8px;
  border-radius: 4px;
  &:hover {
    background-color: var(--base--90);
    color: var(--primary-color);
  }

  &.active {
    color: var(--primary-color);
  }
`;

export const MenuButton = styled.button`
  ${typographyV2.bodyMd};
  border: none;
  background: none;
  color: inherit;
  cursor: pointer;
`;
