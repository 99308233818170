import { ApiFetchResponse } from '~/shared/services/api-fetch';
import { Tracking } from '~/shared/services/tracking';
import {
  addArticleToBookmarks,
  removeArticleFromBookmarks,
} from '~/views/news/components/bookmark-button/BookmarkStatusService';
import { BookmarkStatusResponse } from '~/views/news/components/bookmark-button/BookmarkStatusService.d';

import { BookmarkState, useBookmarkStore } from '../use-bookmark-store';

enum BOOKMARK_ACTION {
  ADD,
  REMOVE,
}

const equityFn =
  (articleId: string) => (prevState: BookmarkState, nextState: BookmarkState) =>
    prevState.bookmarks[articleId] === nextState.bookmarks[articleId];

export const useBookmark = (articleId: string, bookmarked = false) => {
  const { bookmarks, addBookmark, removeBookmark } = useBookmarkStore(
    s => s,
    equityFn(articleId)
  );

  const isBookmarked = bookmarks[articleId] ?? bookmarked;

  const handleBookmark = async () => {
    const action = isBookmarked ? BOOKMARK_ACTION.REMOVE : BOOKMARK_ACTION.ADD;
    let result: ApiFetchResponse<BookmarkStatusResponse>;

    try {
      if (action === BOOKMARK_ACTION.ADD) {
        result = await addArticleToBookmarks(articleId);
      } else {
        result = await removeArticleFromBookmarks(articleId);
      }

      if (result instanceof Error || result.message !== 'ok') {
        throw result;
      }

      action === BOOKMARK_ACTION.ADD
        ? addBookmark(articleId)
        : removeBookmark(articleId);
    } catch (error) {
      Tracking.captureException(error);
    }
  };

  return { bookmarks, handleBookmark, isBookmarked };
};
