import ReactGA from 'react-ga4';

const CATEGORIES = {
  subscription: 'Subscription',
};
export { CATEGORIES as SUBSCRIPTION_CATEGORIES };

const ACTIONS = {
  cancellation: 'Cancellation',
};
export { ACTIONS as SUBSCRIPTION_ACTIONS };

export function gaSubscriptionCancellationQuestions(
  step1: string,
  step2: string
) {
  ReactGA.event({
    category: CATEGORIES.subscription,
    action: ACTIONS.cancellation,
    label: `stepOne:${step1};stepTwo:${step2}`,
  });
}
