import { Button, ButtonLink, Input } from '@toggle/design-system';
import React, { FormEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { authPaths } from '~/routes/app-paths';
import { gaMyAccountCancelResetPassword } from '~/shared/utils/ganalytics/myAccountGA';

import * as AuthSharedStyles from '../auth-shared-styles/AuthSharedStyles.styles';
import { useForgotPasswordState } from './ForgotPasswordHook';
import * as S from './ForgotPasswordView.styles';

export const ForgotPasswordView = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('auth');
  const {
    email,
    setEmail,
    postReqResetPassword,
    requestState: { isPending, isError, isSuccess },
  } = useForgotPasswordState();
  const [successTabView, setSuccessTabView] = useState(false);

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (!email) {
      return;
    }
    setSuccessTabView(true);
    postReqResetPassword(email);
  };

  const loginRedirect = () => {
    navigate(authPaths.login);
  };

  return (
    <S.Main>
      <AuthSharedStyles.Logo alt="logo" title="logo" />
      <S.Form onSubmit={onSubmit} data-testid="forgot-password">
        {isSuccess && successTabView ? (
          <S.EmailSentWrapper>
            <AuthSharedStyles.CenterContent>
              <S.LetterSvg />
              <S.SuccessText data-testid="success-description">
                {t('auth:forgotResetPassword.detailedNote', { email })}
              </S.SuccessText>
              <ButtonLink
                type="button"
                data-testid="back-to-login"
                label={t('auth:forgotResetPassword.backToLogin')}
                onClick={loginRedirect}
              />
            </AuthSharedStyles.CenterContent>
          </S.EmailSentWrapper>
        ) : (
          <S.ForgotPassWrapper>
            <AuthSharedStyles.Heading>
              {t('auth:forgotResetPassword.heading')}
            </AuthSharedStyles.Heading>
            <S.Description>
              {t('auth:forgotResetPassword.provideEmailNote')}
            </S.Description>
            <Input
              id="email-input"
              name="email-input"
              type="text"
              onChange={e => setEmail(e.target.value)}
              value={email}
              hasError={isError}
              label={t('auth:forgotResetPassword.emailLabel')}
              errorText={t('auth:forgotResetPassword.errorLabel')}
              data-testid="email-input"
            />
            <AuthSharedStyles.CenterContent>
              <AuthSharedStyles.SendButtonWrapper>
                <Button
                  type="submit"
                  fullWidth
                  label={t('auth:forgotResetPassword.sendResetLink')}
                  disabled={!email || isPending}
                  isLoading={isPending}
                  data-testid="submit-button"
                />
              </AuthSharedStyles.SendButtonWrapper>
              <AuthSharedStyles.CancelButton
                to={authPaths.login}
                onClick={() => gaMyAccountCancelResetPassword()}
                data-testid="cancel-button"
              >
                {t('auth:forgotResetPassword.cancelButton')}
              </AuthSharedStyles.CancelButton>
            </AuthSharedStyles.CenterContent>
          </S.ForgotPassWrapper>
        )}
      </S.Form>
    </S.Main>
  );
};
