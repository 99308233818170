import throttle from 'lodash/throttle';

import { CloseReason } from '~/shared/services/base-ws/BaseWs';
import { PriceWS, UpdateHandler } from '~/shared/services/price-ws/PriceWS';
import { create } from '~/stores/create-store/createStore';

interface SubscribeData {
  tickers: string[];
  updateHandler: UpdateHandler;
}

interface LivePriceWebSocketStore {
  initialize: (data: SubscribeData) => PriceWS;
  getPriceWS: (data: SubscribeData) => PriceWS;
  subscribe: (data: SubscribeData) => void;
  unsubscribe: (tickers: string[]) => void;
  closeConnection: () => void;
  priceWS: PriceWS | null;
}

export const livePriceWebSocket = create<LivePriceWebSocketStore>(
  (set, get) => ({
    priceWS: null,
    initialize: ({ updateHandler }) => {
      const throttledHandler = throttle(updateHandler, 500);
      const priceWS = new PriceWS(throttledHandler);
      set({ priceWS });

      return priceWS;
    },

    getPriceWS: ({ tickers, updateHandler }) => {
      const { priceWS, initialize } = get();
      const isWebSocketClosed = !priceWS || priceWS.closed;

      return isWebSocketClosed
        ? initialize({ tickers, updateHandler })
        : priceWS;
    },

    subscribe: ({ tickers, updateHandler }) => {
      const { getPriceWS } = get();
      const priceWS = getPriceWS({ tickers, updateHandler });

      priceWS.subscribe(tickers);
    },

    unsubscribe: (tickers: string[]) => {
      const { priceWS } = get();

      priceWS?.unsubscribe(tickers);
    },

    closeConnection: () => {
      const { priceWS } = get();

      priceWS?.close(CloseReason.NoConnectionRequired);
    },
  })
);
