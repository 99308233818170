import { useEffect, useRef } from 'react';

import { SendMessageProps } from '../../use-chat-store/chat-store.types';
import { useChatStore } from '../../use-chat-store/useChatStore';

export const useStreamRequest = () => {
  const abortControllerRef = useRef<AbortController>();
  const { sendMessage } = useChatStore();

  const sendStreamRequest = async (message: SendMessageProps) => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    abortControllerRef.current = new AbortController();

    sendMessage(message, abortControllerRef.current.signal);
  };

  const abortRequest = () => {
    abortControllerRef.current?.abort();
  };

  useEffect(() => {
    return () => {
      abortRequest();
    };
  }, [abortControllerRef]);

  return {
    sendStreamRequest,
    abortRequest,
  };
};
