import ReactGA from 'react-ga4';

const CATEGORIES = {
  explore: 'Explore',
};
export { CATEGORIES as EXPLORE_CATEGORIES };

const ACTIONS = {
  setExploreFilters: 'Set_explore_filters',
  clearExploreFilters: 'Clear_all_filters',
  renameFilters: 'Rename_filters',
  deleteFilters: 'Delete_filters',
  updateFilters: 'Update_filters',
  revertFilters: 'Revert_filters',
};
export { ACTIONS as EXPLORE_ACTIONS };

export function gaExploreSendFilters(filters: string) {
  ReactGA.event({
    category: CATEGORIES.explore,
    action: ACTIONS.setExploreFilters,
    label: `filters:${filters}`,
  });
}

export function gaExploreClearAllFilters() {
  ReactGA.event({
    category: CATEGORIES.explore,
    action: ACTIONS.clearExploreFilters,
  });
}

export function gaExploreRenameFiltersScreen(screenName: string) {
  ReactGA.event({
    category: CATEGORIES.explore,
    action: ACTIONS.renameFilters,
    label: `newName:${screenName}`,
  });
}

export function gaExploreDeleteFiltersScreen() {
  ReactGA.event({
    category: CATEGORIES.explore,
    action: ACTIONS.deleteFilters,
  });
}

export function gaExploreUpdateFiltersScreen(filters: string) {
  ReactGA.event({
    category: CATEGORIES.explore,
    action: ACTIONS.updateFilters,
    label: `filters:${filters}`,
  });
}

export function gaExploreRevertFiltersScreen(filters: string) {
  ReactGA.event({
    category: CATEGORIES.explore,
    action: ACTIONS.revertFilters,
    label: `filters:${filters}`,
  });
}
