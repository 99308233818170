import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import * as S from '../Content.styles';

export function ProjectionChartContent() {
  const { t } = useTranslation('helperWidget');

  return (
    <S.Content>
      <S.Paragraph>
        {t('helperWidget:insight.content.projectionChart.firstParagraph')}
      </S.Paragraph>
      <S.Paragraph>
        {t('helperWidget:insight.content.projectionChart.secondParagraph')}
      </S.Paragraph>
      <S.Paragraph>
        <Trans
          i18nKey="helperWidget:insight.content.projectionChart.thirdParagraph"
          components={[
            <S.CompleteIcon key="complete" as="i" />,
            <S.InvalidIcon key="invalid" as="i" />,
          ]}
        />
      </S.Paragraph>
    </S.Content>
  );
}
