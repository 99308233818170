// API doc reference: https://www.notion.so/toggle/Backend-API-documentation-c67fc9d833484a878e401d4a55030e70#5d1e85772a5b464f93ce9aa00143c19b
import { config } from '~/config';
import { HttpMethods } from '~/shared/services/api-fetch/constants';
import { validateApiFetch } from '~/shared/services/api-fetch/validate-api-fetch/validateApiFetch';

import {
  BackupCodesType,
  backupCodesTypeSchema,
  TwofaEnableTotp,
  TwofaStatus,
  twofaStatusSchema,
  TwofaTotpCode,
  twofaTotpCodeSchema,
} from './two-factor-auth-schema';

const {
  api: { root: apiRoot },
} = config;

const twofaRoot = `${apiRoot}2fa/v1`;

export const twoFAEndpoints = {
  sendEmail: `${twofaRoot}/send-email`,
  getStatus: `${twofaRoot}`,
  enableEmail: `${twofaRoot}/email`,
  enableTotp: `${twofaRoot}/totp`,
  totpCode: `${twofaRoot}/totp-seed`,
  backupCodes: `${twofaRoot}/backup-codes`,
};

export const postSendEmail = (userId: string) => {
  return validateApiFetch({
    endpoint: twoFAEndpoints.sendEmail,
    requestInfo: {
      method: HttpMethods.Post,
      body: JSON.stringify({ user_id: userId }),
    },
  });
};

export const postEnableEmail = (enabled: boolean) => {
  return validateApiFetch({
    endpoint: twoFAEndpoints.enableEmail,
    requestInfo: {
      method: HttpMethods.Post,
      body: JSON.stringify({ enabled }),
    },
  });
};

export const postEnableTotp = (request: TwofaEnableTotp) => {
  return validateApiFetch({
    endpoint: twoFAEndpoints.enableTotp,
    requestInfo: {
      method: HttpMethods.Post,
      body: JSON.stringify(request),
    },
  });
};

export const get2faStatus = () => {
  return validateApiFetch<TwofaStatus>({
    endpoint: twoFAEndpoints.getStatus,
    schema: twofaStatusSchema,
  });
};

export const getTotpKey = () => {
  return validateApiFetch<TwofaTotpCode>({
    endpoint: twoFAEndpoints.totpCode,
    schema: twofaTotpCodeSchema,
  });
};

export const getBackupCodes = () => {
  return validateApiFetch<BackupCodesType>({
    endpoint: twoFAEndpoints.backupCodes,
    schema: backupCodesTypeSchema,
  });
};
