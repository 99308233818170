import { StrictUnion } from '@toggle/helpers';

export type TwitterProps = {
  url: string;
  title: string;
  via?: string;
  hashtags?: string;
  related?: string;
};

export type FacebookProps = {
  u: string; // u
  quote: string;
};

export type RedditProps = {
  url: string;
  title: string;
};

export type MailProps = {
  subject: string;
  body: string;
  separator?: string;
  url: string;
};

export enum SocialUrls {
  Twitter = 'https://twitter.com/intent/tweet',
  Facebook = 'https://www.facebook.com/sharer/sharer.php',
  Reddit = 'https://www.reddit.com/submit',
  Mail = 'mailto:',
}

export type SocialLink = StrictUnion<
  | { provider: SocialUrls.Twitter; params: TwitterProps }
  | { provider: SocialUrls.Facebook; params: FacebookProps }
  | { provider: SocialUrls.Reddit; params: RedditProps }
  | { provider: SocialUrls.Mail; params: MailProps }
>;

export const socialLink = ({ provider, params }: SocialLink): string => {
  return new URL(`${provider}?${new URLSearchParams(params)}`).href;
};
