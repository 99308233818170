import { Button, ButtonLink } from '@toggle/design-system';
import { times } from 'number-precision';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { OrderType } from '~/api/trading/trading-service';
import {
  OrderSide,
  Transaction,
} from '~/global/trading-drawer/use-transaction/useTransaction';
import { formatAssetPrice } from '~/shared/utils/currency/currency';

import * as S from './OrderConfirmation.styles';
import { PositionChange } from './position-change/PositionChange';

export interface OrderConfirmationProps {
  processOrder: () => void;
  editOrder: () => void;
  transaction: Transaction;
  statementPosition: number;
  errorMessage: string;
  tradingProviderLabel: string;
}

export const OrderConfirmation: FC<OrderConfirmationProps> = ({
  processOrder,
  editOrder,
  transaction,
  statementPosition,
  errorMessage,
  tradingProviderLabel,
}) => {
  const { t } = useTranslation('portfolio');
  const { order } = transaction;

  const isLimitOrder = transaction.type === OrderType.Limit;
  const title =
    order.side === OrderSide.BUY ? 'portfolio:buy' : 'portfolio:sell';
  const type = isLimitOrder ? 'portfolio:limit' : 'portfolio:market';

  const totalValue = times(order?.price, order?.quantity);
  const commission = order?.preview?.est_commission ?? 0;
  const totalValuePlusCommission = totalValue + commission;
  const initialMargin = order?.preview?.initial_margin ?? 0;
  const maintenanceMargin = order?.preview?.initial_margin_before ?? 0;
  const marginCurrency = order?.preview?.margin_currency || 'usd';
  return (
    <S.Container data-testid="order-confirmation">
      <S.HeadingContainer>
        <S.Heading>
          {t(title)} {order.ticker}
        </S.Heading>
        <ButtonLink
          data-testid="edit-order-button"
          iconName="Edit"
          label={t('portfolio:editOrder')}
          onClick={editOrder}
        />
      </S.HeadingContainer>
      <S.ContentContainer>
        {errorMessage && (
          <S.IbAlert
            title={t('portfolio:previewErrorTitle', {
              tradingProvider: tradingProviderLabel,
            })}
            message={errorMessage}
          />
        )}
        <S.OrderForm>
          <S.OrderItem>
            <S.OrderItemName>{t('portfolio:orderType')}</S.OrderItemName>
            <S.OrderItemValue>
              {t(type)} {t(title)}
            </S.OrderItemValue>
          </S.OrderItem>
          <S.OrderItem>
            <S.OrderItemName>{t('portfolio:quantity')}</S.OrderItemName>
            <S.OrderItemValue>{order.quantity}</S.OrderItemValue>
          </S.OrderItem>
          {isLimitOrder && (
            <S.OrderItem>
              <S.OrderItemName>{t('portfolio:limitPrice')}</S.OrderItemName>
              <S.OrderItemValue>
                {formatAssetPrice(order.price, order?.currency)}
              </S.OrderItemValue>
            </S.OrderItem>
          )}
          <S.OrderItem>
            <S.OrderItemName>{t('portfolio:totalValue')}</S.OrderItemName>
            <S.OrderItemValue>
              {formatAssetPrice(totalValue, order?.currency)}
            </S.OrderItemValue>
          </S.OrderItem>
          {isLimitOrder && (
            <S.OrderItem>
              <S.OrderItemName>{t('portfolio:expiration')}</S.OrderItemName>
              <S.OrderItemValue>
                {t(`expiration_${order?.expiration}`)}
              </S.OrderItemValue>
            </S.OrderItem>
          )}
          <S.OrderItem>
            <S.OrderItemName>{t('portfolio:commission')}</S.OrderItemName>
            <S.OrderItemValue>
              {formatAssetPrice(commission, order?.currency)}
            </S.OrderItemValue>
          </S.OrderItem>
          <S.OrderTotal>
            <S.OrderTotalItem>
              <S.OrderItemName whiteText uppercase>
                {t('portfolio:total')}
              </S.OrderItemName>
              <S.OrderItemValue whiteText>
                {formatAssetPrice(totalValuePlusCommission, order?.currency)}
              </S.OrderItemValue>
            </S.OrderTotalItem>
          </S.OrderTotal>
          <S.OrderMargin>
            <S.OrderMarginItem>
              <S.OrderItemName whiteText>
                {t('portfolio:initialMargin')}
              </S.OrderItemName>
              <S.OrderItemValue whiteText>
                {formatAssetPrice(initialMargin, marginCurrency)}
              </S.OrderItemValue>
            </S.OrderMarginItem>
            <S.OrderMarginItem>
              <S.OrderItemName whiteText>
                {t('portfolio:maintenanceMargin')}
              </S.OrderItemName>
              <S.OrderItemValue whiteText>
                {formatAssetPrice(maintenanceMargin, marginCurrency)}
              </S.OrderItemValue>
            </S.OrderMarginItem>
          </S.OrderMargin>
        </S.OrderForm>
        {!!statementPosition && (
          <PositionChange
            orderPosition={
              order.side === OrderSide.SELL ? -order.quantity : order.quantity
            }
            statementPosition={statementPosition}
          />
        )}
        <S.ValueEstimate>{t('portfolio:valueEstimateMsg')}</S.ValueEstimate>
      </S.ContentContainer>
      <S.FooterContainer>
        <Button
          label={t('portfolio:submit')}
          fullWidth
          onClick={processOrder}
        />
      </S.FooterContainer>
    </S.Container>
  );
};
