import { deviceConstraints } from '@toggle/design-system';
import styled from 'styled-components';

import { FixedWrapper } from '~/views/helper/helper-widget/HelperWidget.styles';

export const Helper = styled.section`
  position: fixed;
  bottom: 16px;
  padding: 0 16px;
  overflow: hidden;
  right: 16px;
  left: 0;
  z-index: calc(var(--z-index-app-header) - 1);
  width: calc(100% - 32px);

  @media print {
    display: none;
  }

  @media ${deviceConstraints.smartphone} {
    height: unset;
    padding: 0;
    width: auto;
    left: 16px;

    ${FixedWrapper} {
      position: static;
    }
  }

  @media ${deviceConstraints.smartphoneOrTablet} {
    height: unset;
    padding: 0;
    width: auto;
    left: 16px;

    ${FixedWrapper} {
      position: static;
    }
  }

  @media ${deviceConstraints.tablet} {
    width: auto;
    padding: 0;
    left: unset;
    right: 16px;

    ${FixedWrapper} {
      position: static;
    }
  }

  @media ${deviceConstraints.desktopSmall} {
    position: static;

    ${FixedWrapper} {
      position: fixed;
    }

    overflow: unset;
  }

  @media ${deviceConstraints.desktop} {
    height: 100%;
  }
`;
