import { typography } from '@toggle/design-system';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 20px;
  background: rgba(25, 29, 34, 0.9);
  text-align: center;
`;

export const Title = styled.p`
  margin-top: 20px;
  ${typography.dingo};
`;

export const Message = styled.p`
  white-space: pre-line;
  margin-top: 10px;
  ${typography.emu};

  a {
    color: var(--primary-color);
  }
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 12px;

  button {
    margin: 0 6px;
  }
`;
