import { createQueryObjectFromString } from '@toggle/helpers';
import React, { createContext, FC, PropsWithChildren, useContext } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { OptionalU } from '~/declarations/standard';
import { Article } from '~/declarations/toggle-api';
import {
  useArticleData,
  UseArticleDataResult,
} from '~/views/article/hooks/useArticleData';

const ArticleContext =
  createContext<OptionalU<UseArticleDataResult>>(undefined);

type Params = { articleID: string };

export const ArticleProvider: FC<PropsWithChildren<{}>> = ({ children }) => {
  const { search: query } = useLocation();
  const params = useParams<Params>() as Params;

  const queryObj = createQueryObjectFromString(query);
  const data = useArticleData({
    query: queryObj,
    articleID: params.articleID,
  });

  return (
    <ArticleContext.Provider value={data}>{children}</ArticleContext.Provider>
  );
};

export const useArticleContext = <
  T extends Article
>(): UseArticleDataResult<T> => {
  const articleDataResult: UseArticleDataResult<T> = useContext(
    ArticleContext
  ) as UseArticleDataResult<T>;
  return { ...articleDataResult };
};
