import {
  BaseThemeNames,
  DEFAULT_THEME,
  ThemeNames,
} from '@toggle/design-system';

import { create } from '~/stores/create-store/createStore';

import { BlindOptions, ThemeNameParts } from '../theme.types';
import { getValidThemeName } from '../utils/theme-utils/theme-utils';

interface ThemeStore {
  baseName: BaseThemeNames;
  blindOption?: BlindOptions;
  changeTheme: (themeParts: ThemeNameParts) => void;
  getThemeName: () => ThemeNames;
}

export const useThemeStore = create<ThemeStore>((set, get) => ({
  baseName: DEFAULT_THEME,
  blindOption: undefined,
  changeTheme: ({ baseName, blindOption }: ThemeNameParts) => {
    set({
      baseName,
      blindOption,
    });
  },
  getThemeName: () => {
    const { baseName, blindOption } = get();

    return getValidThemeName(baseName, blindOption);
  },
}));
