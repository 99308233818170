import { ModalProps, StyledComponent } from '@toggle/design-system';
import React from 'react';

import * as S from './ModalLarge.styles';

export const ModalLarge: StyledComponent<ModalProps, typeof S> = props => (
  <S.StyledModal data-testid="modal-large" {...props}>
    <S.Content data-testid="modal-large-content">{props.children}</S.Content>
  </S.StyledModal>
);

ModalLarge.Styled = S;
