import { useState } from 'react';

export const useGlossaryHandleSearch = () => {
  const [value, setValue] = useState('');

  const handleSearch = (value: string) => {
    setValue(value);
  };

  const handleClear = () => {
    setValue('');
  };

  return {
    value,
    handleSearch,
    handleClear,
  };
};
