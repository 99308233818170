/*!*****************************************************

Freak Flags, Copyright ©2021 Michael P. Cohen. Freak flags is licenced under the MIT licence.

For complete information visit: www.freakflagsprite.com

Replay Link:
https://www.freakflagsprite.com/?replay=AF,AD,AI,AG,AR,AM,AW,AU,AT,AZ,BS,BH,BD,BB,BY,BE,BZ,BM,
BO,BA,BW,BR,VG,BG,KH,CA,BQ,KY,CL,CN,CO,CR,HR,CW,CY,CZ,DK,DM,DO,EC,EG,SV,EE,FO,FJ,FI,FR,GF,PF,
GA,GM,GE,DE,GH,GI,GR,GL,GD,GP,GU,GT,GN,GW,GY,HT,HN,HK,HU,IS,IN,ID,IE,IM,IL,IT,JM,JP,JE,JO,KZ,
KE,KI,KW,KG,LA,LV,LB,LR,LY,LI,LT,LU,MO,MK,MG,MW,MY,MV,ML,MH,MQ,MR,MU,YT,MX,FM,MD,MC,MN,ME,MS,
MA,MZ,MM,NA,NR,NP,NL,NC,NZ,NI,NE,NG,NU,NF,MP,NO,OM,PK,PW,PS,PA,PG,PY,PE,PH,PL,PT,PR,QA,MT,RE,
RO,RU,RW,BL,SH,LC,PM,WS,SM,ST,SA,SN,RS,SC,SL,SG,SX,SK,SI,SB,SO,ZA,KR,SS,ES,LK,KN,VC,SD,SR,SE,
CH,TW,TJ,TZ,TH,TL,TG,TK,TO,TT,TN,TR,TM,TC,TV,UG,UA,AE,GB,US,UY,UZ,VU,VA,VE,VN,VI,WF,EH,YE,ZM,ZW

Custom added flags:
AX,Åland Islands
TF,French Southern Territories
GG,Guernsey
PN,Pitcairn
GS,South Georgia and the South Sandwich Islands
HM,Heard Island and McDonald Islands (same as &.fflag-AU)
MF,Saint Martin (same as &.fflag-FR)
SJ,Svalbard and Jan Mayen (same as &.fflag-NO)
UM,United States Minor Outlying Islands (same as &.fflag-US)

******************************************************/

import { borderRadius } from '@toggle/design-system';
import styled, { css } from 'styled-components';

const flagSizes = {
  sm: css`
    width: 18px;
    height: 11px;
  `,
  md: css`
    width: 24px;
    height: 14px;
  `,
  lg: css`
    width: 42px;
    height: 27px;
  `,
};
export const Fallback = styled.span<{ size: 'sm' | 'md' | 'lg' }>`
  color: var(--text-default);
  background-color: var(--surface-default);
  ${borderRadius.r05};
  padding: 8px;
`;

export const Flag = styled.span<{ size: 'sm' | 'md' | 'lg' }>`
  background-image: url('/app/assets/flag-icons/flag-sprite-custom.png');
  background-repeat: no-repeat;
  background-size: 100% 49494%;
  display: inline-block;
  overflow: hidden;
  position: relative;
  vertical-align: middle;
  box-sizing: content-box;
  ${({ size }) => flagSizes[size]};

  &.fflag-BW {
    background-position: center 0.2287%;
  }
  &.fflag-EG {
    background-position: center 0.4524%;
  }
  &.fflag-GA {
    background-position: center 0.6721%;
  }
  &.fflag-GM {
    background-position: center 0.8958%;
  }
  &.fflag-GH {
    background-position: center 1.1162%;
  }
  &.fflag-GN {
    background-position: center 1.3379%;
  }
  &.fflag-GW {
    background-position: left 1.5589%;
  }
  &.fflag-KE {
    background-position: center 1.7805%;
  }
  &.fflag-LR {
    background-position: left 2.0047%;
  }
  &.fflag-LY {
    background-position: center 2.2247%;
  }
  &.fflag-MG {
    background-position: center 2.4467%;
  }
  &.fflag-MW {
    background-position: center 2.6674%;
  }
  &.fflag-ML {
    background-position: center 2.8931%;
  }
  &.fflag-MR {
    background-position: center 3.1125%;
  }
  &.fflag-MU {
    background-position: center 3.3325%;
  }
  &.fflag-YT {
    background-position: center 3.5542%;
  }
  &.fflag-MA {
    background-position: center 3.7759%;
  }
  &.fflag-MZ {
    background-position: left 4.0015%;
  }
  &.fflag-NA {
    background-position: left 4.2229%;
  }
  &.fflag-NE {
    background-position: center 4.441%;
  }
  &.fflag-NG {
    background-position: center 4.66663%;
  }
  &.fflag-RE {
    background-position: center 4.8844%;
  }
  &.fflag-RW {
    background-position: right 5.1061%;
  }
  &.fflag-SH {
    background-position: center 5.3298%;
  }
  &.fflag-ST {
    background-position: center 5.5495%;
  }
  &.fflag-SN {
    background-position: center 5.7712%;
  }
  &.fflag-S {
    background-position: left 5.994%;
  }
  &.fflag-SL {
    background-position: center 6.2156%;
  }
  &.fflag-SO {
    background-position: center 6.4363%;
  }
  &.fflag-ZA {
    background-position: left 6.658%;
  }
  &.fflag-SS {
    background-position: left 6.8805%;
  }
  &.fflag-SD {
    background-position: center 7.1038%;
  }
  &.fflag-SR {
    background-position: center 7.3231%;
  }
  &.fflag-TG {
    background-position: left 7.5448%;
  }
  &.fflag-TN {
    background-position: center 7.7661%;
  }
  &.fflag-UG {
    background-position: center 7.98937%;
  }
  &.fflag-TZ {
    background-position: center 8.2099%;
  }
  &.fflag-EH {
    background-position: center 8.4316%;
  }
  &.fflag-YE {
    background-position: center 8.6533%;
  }
  &.fflag-ZM {
    background-position: center 8.875%;
  }
  &.fflag-ZW {
    background-position: left 9.0967%;
  }
  &.fflag-AI {
    background-position: center 9.32237%;
  }
  &.fflag-AG {
    background-position: center 9.5426%;
  }
  &.fflag-AR {
    background-position: center 9.7628%;
  }
  &.fflag-AW {
    background-position: left 9.9845%;
  }
  &.fflag-BS {
    background-position: left 10.2052%;
  }
  &.fflag-BB {
    background-position: center 10.4269%;
  }
  &.fflag-BQ {
    background-position: center 10.6486%;
  }
  &.fflag-BZ {
    background-position: center 10.8703%;
  }
  &.fflag-BM {
    background-position: center 11.0945%;
  }
  &.fflag-BO {
    background-position: center 11.3135%;
  }
  &.fflag-VG {
    background-position: center 11.5354%;
  }
  &.fflag-BR {
    background-position: center 11.7593%;
  }
  &.fflag-CA {
    background-position: center 11.9799%;
  }
  &.fflag-KY {
    background-position: center 12.2005%;
  }
  &.fflag-CL {
    background-position: left 12.4222%;
  }
  &.fflag-CO {
    background-position: left 12.644%;
  }
  &.fflag-CR {
    background-position: center 12.8664%;
  }
  &.fflag-CW {
    background-position: center 13.0873%;
  }
  &.fflag-DM {
    background-position: center 13.309%;
  }
  &.fflag-DO {
    background-position: center 13.5307%;
  }
  &.fflag-EC {
    background-position: center 13.7524%;
  }
  &.fflag-SV {
    background-position: center 13.9741%;
  }
  &.fflag-GF {
    background-position: center 14.1958%;
  }
  &.fflag-GL {
    background-position: left 14.4175%;
  }
  &.fflag-GD {
    background-position: center 14.6415%;
  }
  &.fflag-GP {
    background-position: center 14.8609%;
  }
  &.fflag-GT {
    background-position: center 15.0826%;
  }
  &.fflag-GY {
    background-position: center 15.306%;
  }
  &.fflag-HT {
    background-position: center 15.528%;
  }
  &.fflag-HN {
    background-position: center 15.7496%;
  }
  &.fflag-JM {
    background-position: center 15.9694%;
  }
  &.fflag-MQ {
    background-position: center 16.1911%;
  }
  &.fflag-MX {
    background-position: center 16.4128%;
  }
  &.fflag-MS {
    background-position: center 16.6345%;
  }
  &.fflag-NI {
    background-position: center 16.8562%;
  }
  &.fflag-PA {
    background-position: center 17.0779%;
  }
  &.fflag-PY {
    background-position: center 17.2996%;
  }
  &.fflag-PE {
    background-position: center 17.5213%;
  }
  &.fflag-PR {
    background-position: left 17.743%;
  }
  &.fflag-BL {
    background-position: center 17.968%;
  }
  &.fflag-KN {
    background-position: center 18.1864%;
  }
  &.fflag-LC {
    background-position: center 18.4081%;
  }
  &.fflag-PM {
    background-position: center 18.6298%;
  }
  &.fflag-VC {
    background-position: center 18.8515%;
  }
  &.fflag-SX {
    background-position: left 19.0732%;
  }
  &.fflag-TT {
    background-position: center 19.2987%;
  }
  &.fflag-TC {
    background-position: center 19.518%;
  }
  &.fflag-US {
    background-position: center 19.7383%;
  }
  &.fflag-VI {
    background-position: center 19.96%;
  }
  &.fflag-UY {
    background-position: left 20.1817%;
  }
  &.fflag-VE {
    background-position: center 20.4034%;
  }
  &.fflag-AF {
    background-position: center 20.6241%;
  }
  &.fflag-AZ {
    background-position: center 20.8468%;
  }
  &.fflag-BD {
    background-position: center 21.0685%;
  }
  &.fflag-KH {
    background-position: center 21.2902%;
  }
  &.fflag-CN {
    background-position: left 21.5119%;
  }
  &.fflag-GE {
    background-position: center 21.7336%;
  }
  &.fflag-HK {
    background-position: center 21.9553%;
  }
  &.fflag-IN {
    background-position: center 22.177%;
  }
  &.fflag-ID {
    background-position: center 22.4002%;
  }
  &.fflag-JP {
    background-position: center 22.6204%;
  }
  &.fflag-KZ {
    background-position: center 22.8421%;
  }
  &.fflag-LA {
    background-position: center 23.0638%;
  }
  &.fflag-MO {
    background-position: center 23.2855%;
  }
  &.fflag-MY {
    background-position: center 23.5072%;
  }
  &.fflag-MV {
    background-position: center 23.732%;
  }
  &.fflag-MN {
    background-position: left 23.9506%;
  }
  &.fflag-MM {
    background-position: center 24.1723%;
  }
  &.fflag-NP {
    background-position: left 24.394%;
  }
  &.fflag-MP {
    background-position: center 24.6157%;
  }
  &.fflag-PW {
    background-position: center 24.8374%;
  }
  &.fflag-PG {
    background-position: center 25.0591%;
  }
  &.fflag-PH {
    background-position: left 25.279%;
  }
  &.fflag-SG {
    background-position: left 25.5025%;
  }
  &.fflag-KR {
    background-position: center 25.7242%;
  }
  &.fflag-LK {
    background-position: right 25.9459%;
  }
  &.fflag-TW {
    background-position: left 26.1676%;
  }
  &.fflag-TJ {
    background-position: center 26.3885%;
  }
  &.fflag-TH {
    background-position: center 26.611%;
  }
  &.fflag-TL {
    background-position: left 26.8327%;
  }
  &.fflag-TM {
    background-position: center 27.0544%;
  }
  &.fflag-VN {
    background-position: center 27.2761%;
  }
  &.fflag-AD {
    background-position: center 27.4978%;
  }
  &.fflag-AM {
    background-position: center 27.7195%;
  }
  &.fflag-AT {
    background-position: center 27.9412%;
  }
  &.fflag-BY {
    background-position: left 28.1615%;
  }
  &.fflag-BE {
    background-position: center 28.3846%;
  }
  &.fflag-BA {
    background-position: center 28.6063%;
  }
  &.fflag-BG {
    background-position: center 28.829%;
  }
  &.fflag-HR {
    background-position: center 29.0497%;
  }
  &.fflag-CY {
    background-position: center 29.2714%;
  }
  &.fflag-CZ {
    background-position: left 29.4931%;
  }
  &.fflag-DK {
    background-position: center 29.7148%;
  }
  &.fflag-EE {
    background-position: center 29.9365%;
  }
  &.fflag-FO {
    background-position: center 30.1582%;
  }
  &.fflag-FI {
    background-position: center 30.3799%;
  }
  &.fflag-FR {
    background-position: center 30.6016%;
  }
  &.fflag-DE {
    background-position: center 30.8233%;
  }
  &.fflag-GI {
    background-position: center 31.045%;
  }
  &.fflag-GR {
    background-position: left 31.2667%;
  }
  &.fflag-HU {
    background-position: center 31.4884%;
  }
  &.fflag-IS {
    background-position: center 31.7101%;
  }
  &.fflag-IE {
    background-position: center 31.9318%;
  }
  &.fflag-IM {
    background-position: center 32.1535%;
  }
  &.fflag-IT {
    background-position: center 32.3752%;
  }
  &.fflag-JE {
    background-position: center 32.5969%;
  }
  &.fflag-LV {
    background-position: center 32.8186%;
  }
  &.fflag-LI {
    background-position: left 33.0403%;
  }
  &.fflag-LT {
    background-position: center 33.25975%;
  }
  &.fflag-LU {
    background-position: center 33.4837%;
  }
  &.fflag-MT {
    background-position: left 33.7054%;
  }
  &.fflag-MD {
    background-position: center 33.9271%;
  }
  &.fflag-MC {
    background-position: center 34.1488%;
  }
  &.fflag-ME {
    background-position: center 34.3705%;
  }
  &.fflag-NL {
    background-position: center 34.5922%;
  }
  &.fflag-MK {
    background-position: center 34.8139%;
  }
  &.fflag-NO {
    background-position: center 35.0356%;
  }
  &.fflag-PL {
    background-position: center 35.2555%;
  }
  &.fflag-PT {
    background-position: center 35.479%;
  }
  &.fflag-RO {
    background-position: center 35.7007%;
  }
  &.fflag-RU {
    background-position: center 35.9224%;
  }
  &.fflag-SM {
    background-position: center 36.1441%;
  }
  &.fflag-RS {
    background-position: center 36.3658%;
  }
  &.fflag-SK {
    background-position: center 36.5875%;
  }
  &.fflag-SI {
    background-position: center 36.8092%;
  }
  &.fflag-ES {
    background-position: left 37.0309%;
  }
  &.fflag-SE {
    background-position: center 37.2526%;
  }
  &.fflag-CH {
    background-position: center 37.4743%;
  }
  &.fflag-TR {
    background-position: center 37.696%;
  }
  &.fflag-UA {
    background-position: center 37.9177%;
  }
  &.fflag-GB {
    background-position: center 38.1394%;
  }
  &.fflag-VA {
    background-position: right 38.3611%;
  }
  &.fflag-BH {
    background-position: center 38.5828%;
  }
  &.fflag-IL {
    background-position: center 38.8045%;
  }
  &.fflag-KW {
    background-position: left 39.0262%;
  }
  &.fflag-JO {
    background-position: left 39.2479%;
  }
  &.fflag-KG {
    background-position: center 39.4696%;
  }
  &.fflag-LB {
    background-position: center 39.6913%;
  }
  &.fflag-OM {
    background-position: left 39.913%;
  }
  &.fflag-PK {
    background-position: center 40.1347%;
  }
  &.fflag-PS {
    background-position: center 40.3564%;
  }
  &.fflag-QA {
    background-position: center 40.5781%;
  }
  &.fflag-SA {
    background-position: center 40.7998%;
  }
  &.fflag-AE {
    background-position: center 41.0215%;
  }
  &.fflag-UZ {
    background-position: left 41.2432%;
  }
  &.fflag-AU {
    background-position: center 41.4649%;
  }
  &.fflag-FJ {
    background-position: center 41.6866%;
  }
  &.fflag-PF {
    background-position: center 41.9083%;
  }
  &.fflag-GU {
    background-position: center 42.13%;
  }
  &.fflag-KI {
    background-position: center 42.3517%;
  }
  &.fflag-MH {
    background-position: left 42.5734%;
  }
  &.fflag-FM {
    background-position: center 42.7951%;
  }
  &.fflag-NC {
    background-position: center 43.0168%;
  }
  &.fflag-NZ {
    background-position: center 43.2385%;
  }
  &.fflag-NR {
    background-position: left 43.4602%;
  }
  &.fflag-NU {
    background-position: center 43.6819%;
  }
  &.fflag-NF {
    background-position: center 43.9036%;
  }
  &.fflag-WS {
    background-position: left 44.1253%;
  }
  &.fflag-SB {
    background-position: left 44.347%;
  }
  &.fflag-TK {
    background-position: center 44.5687%;
  }
  &.fflag-TO {
    background-position: left 44.7904%;
  }
  &.fflag-TV {
    background-position: center 45.0121%;
  }
  &.fflag-VU {
    background-position: left 45.2338%;
  }
  &.fflag-WF {
    background-position: center 45.4555%;
  }

  /* Custom added flags */
  &.fflag-HM {
    background-position: center 41.4649%;
  }
  &.fflag-MF {
    background-position: center 30.6016%;
  }
  &.fflag-SJ {
    background-position: center 35.0356%;
  }
  &.fflag-UM {
    background-position: center 19.7383%;
  }
  &.fflag-AX {
    background-position: center 45.6772%;
  }
  &.fflag-TF {
    background-position: center 45.8989%;
  }
  &.fflag-GG {
    background-position: center 46.1206%;
  }
  &.fflag-PN {
    background-position: center 46.3423%;
  }
  &.fflag-GS {
    background-position: center 46.564%;
  }

  /* Custom to handle alpha 3 codes flags */
  &.fflag-ARG {
    background-position: center 9.7628%;
  }
  &.fflag-AUS {
    background-position: center 41.4649%;
  }
  &.fflag-AUT {
    background-position: center 27.9412%;
  }
  &.fflag-BEL {
    background-position: center 28.3846%;
  }
  &.fflag-BMU {
    background-position: center 11.0945%;
  }
  &.fflag-BRA {
    background-position: center 11.7593%;
  }
  &.fflag-CAN {
    background-position: center 11.9799%;
  }
  &.fflag-CHL {
    background-position: left 12.4222%;
  }
  &.fflag-CHN {
    background-position: left 21.5119%;
  }
  &.fflag-COL {
    background-position: left 12.644%;
  }
  &.fflag-CYM {
    background-position: center 12.2005%;
  }
  &.fflag-CZE {
    background-position: left 29.4931%;
  }
  &.fflag-DEU {
    background-position: center 30.8233%;
  }
  &.fflag-DNK {
    background-position: center 29.7148%;
  }
  &.fflag-EGY {
    background-position: center 0.4524%;
  }
  &.fflag-ESP {
    background-position: left 37.0309%;
  }
  &.fflag-EST {
    background-position: center 29.9365%;
  }
  &.fflag-FIN {
    background-position: center 30.3799%;
  }
  &.fflag-FRA {
    background-position: center 30.6016%;
  }
  &.fflag-HUN {
    background-position: center 31.4884%;
  }
  &.fflag-IDN {
    background-position: center 22.4002%;
  }
  &.fflag-IND {
    background-position: center 22.177%;
  }
  &.fflag-ISR {
    background-position: center 38.8045%;
  }
  &.fflag-ITA {
    background-position: center 32.3752%;
  }
  &.fflag-JPN {
    background-position: center 22.6204%;
  }
  &.fflag-KOR {
    background-position: center 25.7242%;
  }
  &.fflag-LKA {
    background-position: right 25.9459%;
  }
  &.fflag-LTU {
    background-position: center 33.25975%;
  }
  &.fflag-LVA {
    background-position: center 32.8186%;
  }
  &.fflag-MEX {
    background-position: center 16.4128%;
  }
  &.fflag-MYS {
    background-position: center 23.5072%;
  }
  &.fflag-NLD {
    background-position: center 34.5922%;
  }
  &.fflag-NOR {
    background-position: center 35.0356%;
  }
  &.fflag-NZL {
    background-position: center 43.2385%;
  }
  &.fflag-PER {
    background-position: center 17.5213%;
  }
  &.fflag-PHL {
    background-position: left 25.279%;
  }
  &.fflag-POL {
    background-position: center 35.2555%;
  }
  &.fflag-ROU {
    background-position: center 35.7007%;
  }
  &.fflag-RUS {
    background-position: center 35.9224%;
  }
  &.fflag-SAU {
    background-position: center 40.7998%;
  }
  &.fflag-SGP {
    background-position: left 25.5025%;
  }
  &.fflag-SVK {
    background-position: center 36.5875%;
  }
  &.fflag-SVN {
    background-position: center 36.8092%;
  }
  &.fflag-SWE {
    background-position: center 37.2526%;
  }
  &.fflag-THA {
    background-position: center 26.611%;
  }
  &.fflag-TUR {
    background-position: center 37.696%;
  }
  &.fflag-TWN {
    background-position: left 26.1676%;
  }
  &.fflag-UAE {
    background-position: center 41.0215%;
  }
  &.fflag-UK {
    background-position: center 38.1394%;
  }
  &.fflag-VNM {
    background-position: center 27.2761%;
  }
  &.fflag-ZAF {
    background-position: left 6.658%;
  }
`;
