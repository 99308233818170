import { useEffect, useRef, useState } from 'react';

import {
  SnakeMeta,
  TimeSeriesData,
  TSDatum,
} from '~/api/timeseries/timeseries-schema';
import { APICallStatus, OptionalU } from '~/declarations/standard';
import { MappedEntity } from '~/shared/hooks/use-entities';
import {
  fetchSnakeByName,
  fetchSnakeMetaV2,
} from '~/shared/services/overview-widget/overview-widget-service';
import { Tracking } from '~/shared/services/tracking';
import { priceSuffix } from '~/shared/utils/currency/currency';

import { ASSET_PRIMARY_METHOD_MAP } from '../../overview-constants';

interface Meta {
  lastKnownPrice: number;
  currency: string;
  ticker: string;
  lastPriceDate: string;
  primaryMethod: string;
  name: string;
}

export interface TimeSeriesResult {
  ts: TSDatum[];
  meta: Meta;
  snakeMeta: SnakeMeta;
  priceSuffix: string;
}

function parseTsData(response: TimeSeriesData): TSDatum[] {
  const schema = response.result.schema;
  const indexKey = schema.primaryKey[0];
  const valueKey = schema.fields.filter(f => f.name !== indexKey)[0].name;

  return (response.result.data || []).map(val => ({
    index: (val as unknown as { [key: string]: string })[indexKey],
    value: (val as unknown as { [key: string]: number })[valueKey],
  }));
}

const INITIAL_SNAKE_DATA: APICallStatus<TimeSeriesResult> = {
  loading: true,
};

const parseSnakePrimaryMethod = (snake: string): string => {
  try {
    const method = snake.split('.')[1];
    return (
      ASSET_PRIMARY_METHOD_MAP[
        method as keyof typeof ASSET_PRIMARY_METHOD_MAP
      ] || method
    );
  } catch {
    return '';
  }
};

const initialSnakeData: Meta = {
  lastKnownPrice: 0,
  currency: '',
  ticker: '',
  lastPriceDate: '',
  primaryMethod: '',
  name: '',
};

const parseSnakeData = (snakeData: TimeSeriesData): Meta => {
  if (snakeData?.meta) {
    return {
      lastKnownPrice: snakeData.meta.latest_price,
      currency: snakeData.meta.currency || '',
      ticker: (snakeData.meta.ticker || '').replace('$', ''),
      lastPriceDate: snakeData.meta.latest_date,
      primaryMethod: parseSnakePrimaryMethod(snakeData.meta.snake),
      name: snakeData.meta.name,
    };
  }
  return initialSnakeData;
};

export function useSnakeData(
  entity: OptionalU<MappedEntity>,
  snakeName = entity?.default_snake || ''
): APICallStatus<TimeSeriesResult> {
  const snakeNameRef = useRef<string>();

  const [snakeData, setSnakeData] =
    useState<APICallStatus<TimeSeriesResult>>(INITIAL_SNAKE_DATA);

  const getEntitySnake = async (entity: MappedEntity, snakeName: string) => {
    setSnakeData({
      loading: true,
    });
    const [snakeByName, snakeMetaV2] = await Promise.all([
      fetchSnakeByName(snakeName),
      fetchSnakeMetaV2([snakeName]),
    ]);
    if (snakeNameRef.current === snakeName) {
      try {
        if (snakeByName instanceof Error) {
          throw snakeByName;
        }
        if (snakeMetaV2 instanceof Error) {
          throw snakeMetaV2;
        }
        const meta = parseSnakeData(snakeByName);
        const snakeMeta = snakeMetaV2[snakeName];
        setSnakeData({
          loading: false,
          data: {
            ts: parseTsData(snakeByName).filter(
              p => p.value !== null && p.value !== undefined
            ),
            meta,
            snakeMeta: {
              ...snakeMeta,
              last_timestamp: snakeMeta.last_timestamp / 1e6,
              before_last_timestamp: snakeMeta.before_last_timestamp / 1e6,
            },
            priceSuffix: priceSuffix(snakeMeta.display_format, entity.currency),
          },
        });
      } catch (e) {
        Tracking.captureException(e as Error);
        setSnakeData({ loading: false, error: (e as Error).message });
      }
    }
  };

  useEffect(() => {
    snakeNameRef.current = snakeName;
    if (!entity) {
      setSnakeData(INITIAL_SNAKE_DATA);
    } else {
      getEntitySnake(entity, snakeName);
    }
  }, [entity, snakeName]);

  return snakeData;
}
