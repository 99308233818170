import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Location, useLocation, useNavigate } from 'react-router-dom';

import { postVerifyEmail } from '~/api/auth/auth-services';
import ConfirmedEnvelope from '~/assets/verify/confirmed-envelope.svg';
import FailedEnvelope from '~/assets/verify/failed-envelope.svg';
import { appPaths } from '~/routes/app-paths';
import { LoadingView } from '~/shared/components/loading-view/LoadingView';
import { useMediaQuery } from '~/shared/hooks/MediaQueryHook';
import { MediaQueries } from '~/shared/hooks/MediaQueryList';
import { Tracking } from '~/shared/services/tracking';

import * as AuthSharedStyles from '../auth-shared-styles/AuthSharedStyles.styles';
import * as S from './Verify.styles';

export const Verify: FC = () => {
  const [loading, setLoading] = useState(true);
  const [confirmed, setConfirmed] = useState(false);
  const { t } = useTranslation('auth');
  const navigate = useNavigate();
  const location = useLocation();
  const currentMedia = useMediaQuery(s => s.currentMedia);
  const isSmallMobile = [MediaQueries.SMALL_MOBILE].includes(currentMedia);

  const getTokenFromUrl = (location: Location) => {
    const urlParams = new URLSearchParams(location.search);
    return urlParams.get('token') ?? '';
  };

  const verifyToken = async () => {
    try {
      const response = await postVerifyEmail(getTokenFromUrl(location));

      if (response instanceof Error) {
        throw response;
      }
      setConfirmed(true);
    } catch (e) {
      Tracking.captureException(e as Error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    verifyToken();
  }, []);

  const gotToSecurityPage = () => navigate(appPaths.myAccount.security);

  return (
    <>
      <AuthSharedStyles.Main>
        <LoadingView loading={loading}>
          <AuthSharedStyles.Logo alt="logo" title="logo" />
          <S.Container data-testid="verify-screen">
            {confirmed ? <ConfirmedEnvelope /> : <FailedEnvelope />}
            <S.Title $confirmed={confirmed}>
              {confirmed
                ? t('auth:verify.successTitle')
                : t('auth:verify.errorTitle')}
            </S.Title>
            {!confirmed && <S.Text>{t('auth:verify.errorMessage')}</S.Text>}
            <S.MainBtn
              data-testid="verify-button"
              label={
                confirmed
                  ? t('auth:verify.successButtonLabel')
                  : t('auth:verify.errorButtonLabel')
              }
              onClick={gotToSecurityPage}
              $btnConfirmed={confirmed}
            />
          </S.Container>
        </LoadingView>
      </AuthSharedStyles.Main>
      {!isSmallMobile && (
        <S.ImgWaveShape
          src="/app/assets/auth/wave-group.svg"
          alt={t('auth:verify.waveGroupImgAlt')}
          role="image"
        />
      )}
    </>
  );
};
