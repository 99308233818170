import {
  loadStripe,
  StripeCardElementChangeEvent,
  StripeError,
} from '@stripe/stripe-js';
import { useEffect, useRef, useState } from 'react';

import { config } from '~/config';
import { useSubscription } from '~/stores/use-subscription/useSubscription';

import { usePayments } from '../use-payments/usePayments';

export interface UseStripeFormProps {
  onSuccess?: () => void;
  hasError?: boolean;
}

export const stripePromise = loadStripe(config.stripePublicKey);

export const useStripeForm = ({
  onSuccess,
  hasError = false,
}: UseStripeFormProps = {}) => {
  const [ready, setReady] = useState(false);
  const [stripeError, setStripeError] = useState(hasError);
  const [inputError, setInputError] = useState<string>();
  const [formComplete, setFormComplete] = useState(false);
  const [isSubmittingCard, setIsSubmittingCard] = useState(false);
  const stripeFormRef = useRef<{
    onSubmit: () => Promise<null | string | StripeError>;
  }>(null);

  const { clientSecret, getClientSecret, confirmPayment, clearClientSecret } =
    usePayments();
  const getCard = useSubscription(state => state.getCard);

  useEffect(() => {
    getClientSecret();
    return () => {
      clearClientSecret();
    };
  }, []);

  const handleFormChange = (event: StripeCardElementChangeEvent) => {
    setFormComplete(event.complete);
    setStripeError(false);
    setInputError(event.error?.message);
  };

  const onReady = () => {
    setReady(true);
  };

  const handleSubmit = async () => {
    let isSubmitted = false;
    if (!stripeFormRef.current?.onSubmit) {
      return isSubmitted;
    }

    setIsSubmittingCard(true);
    const result = await stripeFormRef.current.onSubmit();

    if (typeof result === 'string') {
      await confirmPayment(result);
      await getCard();
      onSuccess?.();
      isSubmitted = true;
    } else if (result) {
      setStripeError(true);
    }

    setIsSubmittingCard(false);
    return isSubmitted;
  };

  return {
    handleFormChange,
    clientSecret,
    ready,
    stripeError,
    inputError,
    formComplete,
    onReady,
    handleSubmit,
    stripeFormRef,
    isSubmittingCard,
  };
};
