import { borderRadius, typographyV2 } from '@toggle/design-system';
import styled from 'styled-components';

export const ChatEmptyStateRoot = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: var(--surface-default);
  border: 1px solid var(--border-soft);
  ${borderRadius.r3};
  margin: 16px 20px;
`;

export const ChatEmptyStateImage = styled.img`
  padding: 0px 100px 24px;
`;

export const ChatEmptyStateText = styled.p`
  ${typographyV2.bodyBase}
  color: var(--text-soft);
  margin: 0 24px;
  text-align: center;
`;
