import React from 'react';
import { useTranslation } from 'react-i18next';

import * as S from '../Content.styles';

export function RelatedAssetsContent() {
  const { t } = useTranslation('helperWidget');

  return (
    <S.Content data-testid="related-assets-content">
      <S.Paragraph>
        {t('helperWidget:analyze.content.relatedAssetsContent.title')}
      </S.Paragraph>
    </S.Content>
  );
}
