import React, { forwardRef } from 'react';

import { Icon } from '../icon/Icon';
import { Input, InputProps } from '../input/Input';
import * as S from './Select.styles';

export interface SelectProps {
  isActive: boolean;
  inputProps: InputProps;
  isReadonlyInput?: boolean;
}

export const Select = forwardRef<HTMLInputElement, SelectProps>(
  (
    { isActive, inputProps, isReadonlyInput = true, ...rest }: SelectProps,
    ref
  ) => {
    return (
      <S.SelectButton {...rest}>
        <Input
          data-testid="select-input"
          {...inputProps}
          ref={ref}
          readOnly={isReadonlyInput}
          rightIcon={
            <Icon
              iconName={isActive ? 'ChevronLightUp' : 'ChevronLightDown'}
              size={12}
            />
          }
        />
      </S.SelectButton>
    );
  }
);
