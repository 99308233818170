import { OptionalU } from '~/declarations/standard';
import { MappedEntity } from '~/shared/hooks/use-entities';
import { create } from '~/stores/create-store/createStore';

export enum SearchTabsOptions {
  Insights = 'insights',
  Ib = 'ib',
  Tradestation = 'tradestation',
}

export interface GlobalSearchViewProps {
  isSearchOpened: boolean;
  withOverlay: boolean;
  withWatchlistBtn: boolean;
  isWatchlistOnly: boolean;
  navigationUrl?: string;
  activeTab: SearchTabsOptions;
  tradeSearchInitialValue: string;
  navigationCallback?: (tag?: string) => void;
}

interface GlobalSearchStore extends GlobalSearchViewProps {
  isSearchOpened: boolean;
  selectedSearchEntity?: MappedEntity;
  setSelectedSearchEntity: (entity: OptionalU<MappedEntity>) => void;
  openSearch: (props?: Partial<GlobalSearchViewProps>) => void;
  closeSearch: () => void;
  setActiveTab: (activeTab: SearchTabsOptions) => void;
  setTradeSearchInitialValue: (value: string) => void;
}

export const defaultSearchView: GlobalSearchViewProps = {
  isSearchOpened: false,
  withOverlay: true,
  withWatchlistBtn: true,
  activeTab: SearchTabsOptions.Insights,
  tradeSearchInitialValue: '',
  navigationUrl: undefined,
  navigationCallback: undefined,
  isWatchlistOnly: false,
};

export const useGlobalSearch = create<GlobalSearchStore>(set => ({
  ...defaultSearchView,
  openSearch: (viewProps: Partial<GlobalSearchViewProps> = {}) =>
    set({ ...defaultSearchView, ...viewProps, isSearchOpened: true }),
  closeSearch: () => set({ ...defaultSearchView }),
  setSelectedSearchEntity: (entity: OptionalU<MappedEntity>) =>
    set({ selectedSearchEntity: entity }),
  setActiveTab: (activeTab: SearchTabsOptions) => set({ activeTab }),
  setTradeSearchInitialValue: (value: string) =>
    set({ tradeSearchInitialValue: value }),
}));
