import { Entity, MappedEntity } from '../api/entity-schema';
import { formatTicker } from './formatTicker';
import { mapAssetClass } from './mapAssetClass';

export const mapEntity = (entity: Entity): MappedEntity => {
  const { asset_class, sub_class } = mapAssetClass(entity);
  const formattedTicker = formatTicker(entity);

  return {
    ...entity,
    key: entity.default_snake,
    asset_class,
    sub_class,
    formattedTicker,
    sector: entity.gics.sector.code || asset_class || sub_class || '',
  };
};

export const isMappedEntity = (
  entity: Entity | MappedEntity
): entity is MappedEntity => 'formattedTicker' in entity;
