import {
  Alert,
  borderRadius,
  breakpoints,
  Modal,
  typographyV2,
} from '@toggle/design-system';
import styled from 'styled-components';

export const Container = styled.div<{
  $withBackground?: boolean;
  $small?: boolean;
}>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 32px;
  height: ${props => props.$small && 'fit-content'};
  padding: 20px;
  ${borderRadius.r2};
  border: 1px solid var(--border-soft);
  background: ${props => props.$withBackground && 'var(--surface-default)'};

  @media ${breakpoints.lg} {
    max-width: 50%;
  }
`;

export const Title = styled.h4`
  ${typographyV2.titleXL}
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  ${borderRadius.r2};
  border: 1px solid var(--border-soft);
  background: var(--surface-default);
`;

export const Section = styled.section`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const StyledModal = styled(Modal)`
  ${Modal.Styled.ModalContainer} {
    display: flex;
    flex-direction: column;
    max-height: calc(100% - 64px);
    width: calc(100% - 32px);

    @media ${breakpoints.md} {
      width: 500px;
    }

    @media ${breakpoints.lg} {
      width: 600px;
    }
  }
`;

export const StyledAlert = styled(Alert)`
  width: 100%;
`;
