import { env } from '@toggle/helpers';

const hostUrl = new URL(document.URL).origin;

const apiBaseUrl = env(process.env.API_BASE_URL, '864453.co.uk/');
const supportRoot = 'https://support.toggle.ai/';

export const config = {
  app: {
    version: env(process.env.VERSION, ''),
    environment: env(process.env.ENV_LEVEL, 'dev'),
    isDevEnvironment: hostUrl === 'https://864453.co.uk',
    isLocalEnvironment: hostUrl === 'http://localhost:1234',
    isTestEnvironment: hostUrl === 'https://test.864453.co.uk',
    appKey: env(process.env.APP_KEY, 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9'),
    featureFlags: env(process.env.FEATURE_FLAGS)?.split(','),
  },
  gaTrackingId: env(process.env.GA_TRACKING_ID, 'G-YCJVS5X9KK'),
  api: {
    wsBrokerRoot: `wss://api.${apiBaseUrl}`,
    root: `https://api.${apiBaseUrl}`,
    wsRoot: `wss://ws.${apiBaseUrl}v2/prices`,
    wsNotifierRoot: `wss://ws.${apiBaseUrl}v2/notifier`,
    wsChatRoot: `wss://api-demo.${apiBaseUrl}chat/v1/ai`,
    wsForRealzChatRoot: `wss://api.${apiBaseUrl}chat/v1/ai`,
    staticRoot: `https://static.${apiBaseUrl}`,
    authRoot: `https://authn.${apiBaseUrl}`,
  },
  urls: {
    root: hostUrl,
    learnMoreLink: `${hostUrl}/why-toggle`,
    learnScenarioLink:
      'https://www.youtube.com/watch?v=2CPWz6uvLR8&list=PLlTyiNKr3gniqSz3g8_beSDNDiQW2Coy6&index=13',
    signUpLink: `/sign-up?sign-up-entry&utm_source=toggle-ai&utm_medium=article&utm_campaign=article`,
    supportRoot,
    webinarLink: env(
      process.env.WEBINAR_LINK,
      'https://us06web.zoom.us/webinar/register/WN_wXeIKzHpT1OvTSAHOziiHQ'
    ),
    socialMediaImageLink: `https://static.${apiBaseUrl}social-share-image.png`,
    supportNewTicket: `${supportRoot}hc/en-us/requests/new`,
    FAQ: `${supportRoot}/hc/en-us/sections/360007295531-FAQ`,
    securitySupport: `${supportRoot}hc/en-us/articles/4404373498900-Portfolio-Connection-`,
    noInsightsSupport: `${supportRoot}hc/en-us/articles/360049748091-Why-are-there-no-TOGGLE-insights-for-my-stock-`,
    insightLink: 'https://www.youtube.com/watch?v=yKEaKMUFjHU',
    disclaimerGenAi:
      'https://toggle.ai/resources/generative-ai-disclaimers-and-notices',
  },
  ib: {
    orders: env(
      process.env.IB_ORDERS,
      'https://www.interactivebrokers.com/en/home.php'
    ),
    ibLogin: env(
      process.env.IB_LOGIN,
      'https://www.interactivebrokers.co.uk/sso/Login?action=Settings&config=ResearchSubscriptions'
    ),
    linkDisclaimer: env(
      process.env.IB_LINK_DISCLAIMER,
      'https://toggle.ai/resources/direct-link-disclaimer-for-ib'
    ),
    manageSubscription: env(
      process.env.IB_MANAGE_SUBSCRIPTION,
      'https://ndcdyn.interactivebrokers.com/sso/Login?RL=1&locale=en_US'
    ),
  },
  zendeskEmbedKey: env(
    process.env.ZENDESK_EMBED_KEY,
    'bc557a7c-11a5-450a-a0d0-5a30d21e6288'
  ),
  zendeskSubdomain: env(
    process.env.ZENDESK_SUBDOMAIN,
    'https://toggleglobal.zendesk.com/api/v2/requests'
  ),
  supportUrl: 'https://support.toggle.ai/hc/en-us/requests/new',
  appStoreLink: env(
    process.env.APP_STORE_LINK,
    'https://apps.apple.com/us/app/toggle-invest-with-confidence/id1537876211'
  ),
  stripePublicKey: env(
    process.env.STRIPE_PUBLIC_KEY,
    'pk_test_51Mdw3pG1Cyr3tMwe60lnBGMRRRlj3ifDQ3wTTdVe2Lz7rIE8aQVg3ntM8wfhtGPyF431iUVl0Yo9k5pnUCdBUZWO00nRitX4J8'
  ),
  sentry: {
    dsn: env(
      process.env.SENTRY_DSN,
      'https://4e8bf86caeb64a678d3385444c680076@o437249.ingest.sentry.io/5444581'
    ),
  },
  appSessionCookieAuth: '__toggle_dashboard_auth',
  appConfirmedPWCookie: 'confirmed_pw',
  supportEmail: 'support@toggle.ai',
  partnershipsEmail: 'partnerships@toggle.ai',
  tipRanks: 'https://www.tipranks.com/news/toggle-rss/',
  ibGfisLink:
    'https://ndcdyn.interactivebrokers.com/sso/Login?action=TA_MARKET_DATA&loginType=1&serviceID1=1063&serviceID2=1064&serviceID3=1065',
  ibkrContactLink: env(
    process.env.IBKR_CONTACT_LINK,
    'https://www.interactivebrokers.com/en/index.php?f=1560&p=chat'
  ),
  tradestationContactLink: env(
    process.env.TRADESTATION_CONTAT_LINK,
    'https://clientcenter.tradestation.com/support/contact/default.aspx'
  ),
  companyDetails: {
    name: 'Knabble, Inc.',
    addressLine1: '460 Broadway',
    addressLine2: 'New York, NY 10036',
  },
};
