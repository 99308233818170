import { ENDPOINT_URLS } from '~/global/toggle-api';
import { APIFetch } from '~/shared/services/api-fetch/APIFetch';

import { HttpMethods } from '../api-fetch/constants';

export interface PromoMetadata {
  headerBannerMd?: string;
  headerPill?: string;
  paymentsLabel?: string;
  plansBanner?: string;
  settingsPriceLabel?: string;
  signUpBannerMd?: string;
}

export interface Promo {
  promoCode?: string;
  metadata: PromoMetadata;
}

interface ResponseSuccess {
  promo: {
    metadata: PromoMetadata;
  };
}

export const verifyPromoCode = async (promoCode: string) => {
  const url = `${ENDPOINT_URLS.SUBSCRIPTIONS_PROMO_CODE}/${encodeURIComponent(
    promoCode
  )}`;

  const response = await APIFetch<ResponseSuccess>(url, {
    method: HttpMethods.Get,
  });

  if (response instanceof Error) {
    throw response;
  }

  return response.promo;
};
