import { Icon, typography } from '@toggle/design-system';
import styled from 'styled-components';

export const ClearLabel = styled.span`
  ${typography.koala}
  padding-right: 16px;
  cursor: pointer;
  color: var(--base--30);
`;

export const ClearIcon = styled(Icon)`
  margin: 20px;
`;
