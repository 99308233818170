import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { GEO_KEYS } from '../../explore';
import { Box, BoxProps } from '../box';
import { TradeOptions, TradeStyles } from '../store';
import * as S from './Trade.styles';

interface TradeProps {
  handleClick: (id: TradeStyles) => void;
  tradeOptions: TradeOptions;
}

export const OPTIONS_TO_SHOW_GEO: TradeStyles[] = [
  'stocks',
  'options',
  'fixedIncome',
];

export const Trade: FC<TradeProps> = ({ tradeOptions, handleClick }) => {
  const { t } = useTranslation('onboarding');

  return (
    <S.Container>
      <S.StocksBox<FC<BoxProps<'stocks'>>>
        left={t('onboarding:questionnaire.trade.stocks')}
        right={<S.StockSvg data-testid="stocks-svg" />}
        active={!!tradeOptions.stocks}
        id="stocks"
        onClick={handleClick}
      />
      <S.CurrenciesBox<FC<BoxProps<'currencies'>>>
        left={t('onboarding:questionnaire.trade.currencies')}
        right={<S.CurrenciesSvg />}
        active={tradeOptions.currencies}
        id="currencies"
        onClick={handleClick}
      />

      <S.CryptoBox<FC<BoxProps<'crypto'>>>
        left={t('onboarding:questionnaire.trade.crypto')}
        right={<S.CryptoSvg />}
        id="crypto"
        onClick={handleClick}
        active={tradeOptions.crypto}
      />
      <S.CommoditiesBox<FC<BoxProps<'commodities'>>>
        left={t('onboarding:questionnaire.trade.commodities')}
        right={<S.CommoditiesSvg />}
        id="commodities"
        onClick={handleClick}
        active={tradeOptions.commodities}
      />
      <S.OptionsBox<FC<BoxProps<'options'>>>
        left={t('onboarding:questionnaire.trade.options')}
        right={<S.OptionsSvg />}
        id="options"
        onClick={handleClick}
        active={tradeOptions.options}
      />
      <S.FixedIncomeBox<FC<BoxProps<'fixedIncome'>>>
        left={t('onboarding:questionnaire.trade.fixedIncome')}
        right={<S.FixedIncomeSvg />}
        id="fixedIncome"
        onClick={handleClick}
        active={tradeOptions.fixedIncome}
      />
      {Object.entries(tradeOptions).some(
        ([key, value]) =>
          value && OPTIONS_TO_SHOW_GEO.includes(key as TradeStyles)
      ) && (
        <S.GeoWrapper>
          {GEO_KEYS.map((geoKey: string) => {
            return (
              <Box
                key={geoKey}
                left={t(`onboarding:questionnaire.trade.${geoKey}`)}
                active={!!tradeOptions[geoKey as TradeStyles]}
                id={geoKey as TradeStyles}
                onClick={handleClick}
              />
            );
          })}
        </S.GeoWrapper>
      )}
    </S.Container>
  );
};
