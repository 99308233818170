import styled from 'styled-components';

import { typographyV2 } from '~/design-tokens/typography/Typography.styles';

export const RootSVG = styled.svg`
  color: var(--text-soft);
  border-radius: 4px;
`;

export const AxisLine = styled.line`
  stroke: var(--border-default);
`;

export const AxisText = styled.text<{ isHeader?: boolean }>`
  ${typographyV2.bodyXs};
  fill: var(--text-soft);
  text-anchor: ${props => (props.isHeader ? 'end' : 'middle')};
`;

export const Rect = styled.rect<{ hasProfit: boolean }>`
  fill: ${props =>
    props.hasProfit ? 'var(--border-success)' : 'var(--border-critical)'};
`;

export const Overlay = styled.rect`
  fill: var(--background-soft);
`;

export const MedianText = styled.text<{ hasProfit: boolean }>`
  ${typographyV2.bodyBase};
  font-family: var(--font-roboto-mono);
  fill: ${props =>
    props.hasProfit ? 'var(--text-success)' : 'var(--text-critical)'};
  text-anchor: end;
`;

export const LabelHeader = styled.text`
  ${typographyV2.bodyXs};
  fill: var(--text-soft);
`;

export const LabelText = styled.text`
  ${typographyV2.bodyBase};
  fill: var(--text-default);
`;
