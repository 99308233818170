import { subDays } from 'date-fns';

import { useAPI } from '~/shared/hooks/use-api/useAPI';
import { fetchFeed } from '~/shared/services/feed/feed';

const last100Days = subDays(new Date(), 100);
const currentDate = new Date();

interface UseFeedDataOptions {
  entityTag: string;
  startDate?: Date;
  endDate?: Date;
}

export const useFeedData = ({
  entityTag,
  startDate = last100Days,
  endDate = currentDate,
}: UseFeedDataOptions) => {
  return useAPI(
    (abort?: AbortSignal) =>
      fetchFeed({
        entityTag,
        startDate,
        endDate,
        abort,
      }),
    {
      deps: [entityTag, startDate, endDate],
      shouldFetch: !!entityTag,
    }
  );
};
