import { deleteCookie } from '@toggle/helpers';

import { authEndpoint } from '~/api/auth/auth-services';
import { config } from '~/config';
import { resetStores } from '~/stores/create-store/createStore';
import { useAuthStore } from '~/stores/use-auth/store/authStore';

import { APIResponse, HttpMethods } from '../api-fetch/constants';
import { FetchError, FetchUnauthError } from './FetchErrors';
import {
  getContentTypeFromHeaders,
  isJsonContentType,
  isStatusFail,
  isTextContentType,
} from './FetchUtils';

export async function resolveDataByContentType(response: Response) {
  const { headers } = response;
  const contentType = getContentTypeFromHeaders(headers);

  if (isJsonContentType(contentType)) {
    return response.json();
  } else if (isTextContentType(contentType)) {
    return response.text();
  } else {
    return response.blob();
  }
}

const { appConfirmedPWCookie, appSessionCookieAuth } = config;

export async function parseResponse<T>(response: Response): Promise<T | Error> {
  const { status, statusText } = response;
  const data = await resolveDataByContentType(response);

  if (isStatusFail(status)) {
    if (status === APIResponse.UNAUTHORISED) {
      deleteCookie(appConfirmedPWCookie);
      deleteCookie(appSessionCookieAuth);
      resetStores();
      useAuthStore.getState().destroy();

      // Manual logout
      const token = useAuthStore.getState().token;
      await fetch(authEndpoint.logout, {
        method: HttpMethods.Post,
        credentials: 'include',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return new FetchUnauthError(status, statusText, data);
    } else if (status === APIResponse.TOO_MANY_REQUESTS) {
      return new FetchError(status, statusText, data);
    } else {
      return new FetchError(status, statusText, data);
    }
  }
  return data as unknown as T;
}
