import { Property } from 'csstype';
import styled, { css } from 'styled-components';

import { Icon } from '~/components/icon/Icon';
import { typographyV2 } from '~/design-tokens/typography/Typography.styles';

import { FixedTableCell } from '../Table.styles';

interface TableHeaderStyleProps {
  $alignment?: Property.TextAlign;
  $isSorted?: boolean;
  $isSortable?: boolean;
  $fixed?: boolean;
  $isHoverEnabled: boolean;
}

export const FilterIcon = styled(Icon)<{ $isSorted?: boolean }>`
  fill: ${({ $isSorted }) =>
    $isSorted ? 'var(--text-primary)' : 'var(--text-soft)'};
`;

export const TableHeader = styled.th<TableHeaderStyleProps>`
  background-color: var(--background-default);
  border-bottom: 1px solid var(--border-soft);
  padding: 12px;
  text-align: ${({ $alignment }) => $alignment};
  white-space: nowrap;
  ${typographyV2.bodyXs};
  vertical-align: middle;
  ${({ $isSortable }) =>
    $isSortable &&
    css`
      &:hover {
        cursor: pointer;
      }
    `};
  ${({ $fixed }) => $fixed && FixedTableCell};
  ${({ $isSorted, $isHoverEnabled }) => {
    const hoverStyle = $isHoverEnabled
      ? css`
          color: var(--text-soft);
          &:hover {
            color: var(--text-default);
            ${FilterIcon} {
              fill: var(--text-default);
            }
          }
        `
      : css`
          color: var(--text-soft);
        `;
    return $isSorted
      ? css`
          color: var(--text-primary);
        `
      : hoverStyle;
  }};
`;

export const Label = styled.span<{ $alignment?: Property.TextAlign }>`
  display: flex;
  align-items: center;
  gap: 4px;
  justify-content: ${({ $alignment = 'center' }) => {
    switch ($alignment) {
      case 'left':
        return 'start';
      case 'right':
        return 'end';
      default:
        return 'center';
    }
  }};
`;
