import { typographyV2 } from '@toggle/design-system';
import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  bottom: 50px;
  left: 10px;
`;

export const Text = styled.p`
  ${typographyV2.bodyXs}
  color: var(--text-soft);
  margin-bottom: 4px;
`;
