import { typography } from '@toggle/design-system';
import styled from 'styled-components';

export const ItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 300px;
  padding: 12px;
  border-radius: 8px;
  background-color: var(--base--90);
  cursor: pointer;

  &:not(:last-of-type) {
    margin-bottom: 8px;
  }
  &:hover {
    background-color: var(--base--70);
  }
`;

export const NameWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Name = styled.span`
  ${typography.emu};
`;

export const PositionsText = styled.span`
  ${typography.koala};
  color: var(--base--20);
`;
