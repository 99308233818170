import { z } from 'zod';

export const companySchema = z.object({
  tag: z.string(),
  name: z.string(),
  ticker: z.string(),
  exchange: z.string(),
  evidence: z.string(),
});
export type Company = z.infer<typeof companySchema>;

export const companyArrSchema = z.array(companySchema);

export const countrySchema = z.object({
  code: z.string(),
  name: z.string(),
  evidence: z.string(),
});
export type Country = z.infer<typeof countrySchema>;

const countryArrSchema = z.array(countrySchema);

export const themeSchema = z.object({
  id: z.string(),
  name: z.string(),
  evidence: z.string(),
  companies: z.array(companySchema).optional(),
  funds: z.array(companySchema).optional(),
});
export type Theme = z.infer<typeof themeSchema>;

const themeArrSchema = z.array(themeSchema);

const createPath =
  (type: string) =>
  ({ tag, limit, isEtf }: { tag: string; limit?: number; isEtf?: boolean }) => {
    const search = limit ? `?limit=${limit}` : '';
    const assetType = isEtf ? 'fund' : 'company';
    return `ontology/v1/${assetType}/${tag}/${type}${search}`;
  };

export const ontology = {
  getCompetitors: {
    path: createPath('competitors'),
    schema: companyArrSchema,
  },
  getSuppliers: {
    path: createPath('suppliers'),
    schema: companyArrSchema,
  },
  getCountryExposure: {
    path: createPath('country-exposure'),
    schema: countryArrSchema,
  },
  getThemeExposure: {
    path: createPath('theme-exposure'),
    schema: themeArrSchema,
  },
};
