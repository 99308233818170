import isWithinInterval from 'date-fns/isWithinInterval';

import { Tracking } from '~/shared/services/tracking';
import { create } from '~/stores/create-store/createStore';

import { getSystemFlags } from './services/system-flags-service';
import { SystemFlags } from './system-flags.types';

interface SystemFlagsStore {
  systemFlags: SystemFlags;
  fetchSystemFlags(): Promise<void>;
  checkIsInMaintenance(): boolean;
}

export const useSystemFlags = create<SystemFlagsStore>((set, get) => {
  return {
    systemFlags: {},
    fetchSystemFlags: async () => {
      try {
        const systemFlags = await getSystemFlags();
        set({
          systemFlags,
        });
      } catch (error) {
        Tracking.captureException(error);
      }
    },
    checkIsInMaintenance: () => {
      const {
        systemFlags: { maintenance },
      } = get();

      if (maintenance) {
        const start = new Date(maintenance.from);
        const end = new Date(maintenance.to);
        return isWithinInterval(new Date(), {
          start,
          end,
        });
      }
      return false;
    },
  };
});
