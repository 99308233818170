import { BillingCycle } from '~/api/subscription/subscription-schema';

export enum PlanCardType {
  Basic = 'basic',
  Copilot = 'copilot',
  Pro = 'pro',
  Custom = 'custom',
}

export interface PlanCards {
  type: PlanCardType;
  overrideButtonText?: string;
}

export enum ChangingPlanTier {
  Upgrading = 'upgrading',
  DownGrading = 'downGrading',
  Same = 'same',
  Initial = 'initial',
}

// TODO move this to useSubscription store after pro to default goal, replacing the Plan interface
export interface SubscribingPlan {
  type: PlanCardType;
  tier: ChangingPlanTier;
  billingCycle: BillingCycle;
}
