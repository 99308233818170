import { TFunction } from 'i18next';
import { StoreApi } from 'zustand';

import {
  ExploreFilterOption,
  UserFilterResponseFilters,
} from '~/api/explore-filter/explore-filter-schema';
import {
  deleteUserFilter,
  getExploreFilterOptions,
  getUserFilters,
  postUserFilter,
  putUserFilter,
} from '~/api/explore-filter/explore-filter-service';
import { Tracking } from '~/shared/services/tracking';
import {
  gaExploreDeleteFiltersScreen,
  gaExploreRevertFiltersScreen,
} from '~/shared/utils/ganalytics';
import { create } from '~/stores/create-store/createStore';
import { makeFiltersNamesString } from '~/views/explore/filter/makeFilterSlug';

import {
  createScreen,
  createUniqueScreenName,
  EXPLORE_FILTERS_LS_KEY,
  Filter,
  FILTER_SCREEN_LS_KEY,
  FilterScreen,
  formatFilterScreenPayload,
  formatFiltersForSave,
  getScreensConfig,
} from './utils/useExploreFiltersUtils';

// screens will always have homeScreen at index 0
// after fetchScreens, activeScreen will be always defined
const homeScreen = createScreen();
const INITIAL_STATE = {
  screens: [homeScreen],
  filters: [],
  loading: false,
  error: null,
};

export interface UseExploreFiltersStore {
  filters: Filter[];
  filterOptions: ExploreFilterOption[];
  activeScreen: null | FilterScreen;
  screens: FilterScreen[];
  error: null | Error;
  loading: boolean;
  initialize: (
    lastAnalysis: Date | undefined,
    initialFilters: UserFilterResponseFilters | null,
    t: TFunction
  ) => Promise<void>;
  updateFilter: (filter: Filter) => void;
  updateFilterOptions: () => Promise<void>;
  clearFilters: () => void;
  revertScreen: () => void;
  setActiveScreen: (activeScreen: FilterScreen) => void;
  saveScreen: (screenName: string) => Promise<void>;
  deleteScreen: (screen: FilterScreen) => Promise<void>;
  clearExploreFilters: () => void;
  clearScreens: () => void;
}

const fetchExploreFilterOptions = async (
  set: StoreApi<UseExploreFiltersStore>['setState'],
  withLoading = false
) => {
  try {
    if (withLoading) {
      set({ loading: true });
    }
    const res = await getExploreFilterOptions();

    if (res instanceof Error) {
      throw res;
    }

    set({ filterOptions: res.categories, loading: false });

    window.localStorage.setItem(
      EXPLORE_FILTERS_LS_KEY,
      JSON.stringify(res.categories)
    );
  } catch (error) {
    Tracking.captureException(error as Error);
    set({ error: error as Error, loading: false });
  }
};

const fetchScreens = async (
  set: StoreApi<UseExploreFiltersStore>['setState'],
  get: StoreApi<UseExploreFiltersStore>['getState']
) => {
  try {
    const originScreens = await getUserFilters();

    if (originScreens instanceof Error) {
      throw originScreens;
    }

    const { activeScreen, screens } = getScreensConfig(originScreens, get);

    updateScreens(
      set,
      activeScreen,
      [...get().screens, ...screens],
      activeScreen?.filters ?? []
    );
  } catch (error) {
    set({ error: error as Error, activeScreen: get().screens[0] });
  }
};

const saveScreen = async (
  set: StoreApi<UseExploreFiltersStore>['setState'],
  get: StoreApi<UseExploreFiltersStore>['getState'],
  screen: FilterScreen,
  screenName: string
) => {
  const screens = get().screens;
  const isExistingSavedScreen = !!screen.originFilters.length;
  const screenId = isExistingSavedScreen ? screen.id : '';
  const finalScreenName = createUniqueScreenName(screens, screen, screenName);

  try {
    const res = isExistingSavedScreen
      ? await putUserFilter(screenId, {
          name: finalScreenName,
          filters: formatFiltersForSave(screen.filters),
        })
      : await postUserFilter({
          name: finalScreenName,
          filters: formatFiltersForSave(screen.filters),
        });

    if (res instanceof Error) {
      throw res;
    }

    const savedScreen: FilterScreen = {
      id: res.id,
      name: finalScreenName,
      filters: screen.filters,
      originFilters: screen.filters,
    };

    const newScreens = isExistingSavedScreen
      ? screens.map(s => (s.id === screen.id ? savedScreen : s))
      : [...screens, savedScreen];
    if (!isExistingSavedScreen) {
      newScreens[0].filters = [];
    }

    updateScreens(set, savedScreen, newScreens, savedScreen?.filters);
  } catch (error) {
    Tracking.captureException(error as Error);
    set({ error: error as Error });
  }
};

const deleteScreen = async (
  set: StoreApi<UseExploreFiltersStore>['setState'],
  get: StoreApi<UseExploreFiltersStore>['getState'],
  screen: FilterScreen
) => {
  const screens = get().screens;
  const activeScreenIdx = screens.findIndex(s => s.id === screen.id);
  const newScreenIdx =
    activeScreenIdx < screens.length - 1
      ? activeScreenIdx + 1
      : activeScreenIdx - 1;
  const newActiveScreen = screens.length > 2 ? screens[newScreenIdx] : null;
  const newScreens = screens.filter(s => s.id !== screen.id);
  const newFilters = newActiveScreen?.filters ?? [];

  gaExploreDeleteFiltersScreen();

  try {
    const res = await deleteUserFilter(screen.id);

    if (res instanceof Error) {
      throw res;
    }

    updateScreens(set, newActiveScreen, newScreens, newFilters);
  } catch (error) {
    set({ error: error as Error });
    Tracking.captureException(error as Error);
  }
};

const updateFilters = (filters: Filter[], filter: Filter): Filter[] => {
  const exists = !!filters.find(f => f.key === filter.key);

  if (filter.displayType === 'radio') {
    const filtersWithRemovedPreviousRadio = filters.filter(
      f => f.type !== filter.type
    );

    return exists
      ? filtersWithRemovedPreviousRadio
      : [...filtersWithRemovedPreviousRadio, filter];
  }

  return exists
    ? filters.filter(f => f.key !== filter.key)
    : [...filters, filter];
};

const updateScreens = (
  set: StoreApi<UseExploreFiltersStore>['setState'],
  activeScreen: FilterScreen | null,
  screens: FilterScreen[],
  filters: Filter[]
) => {
  const isSavedScreen = !!activeScreen?.originFilters.length;
  const setActiveScreen = activeScreen ? isSavedScreen : false;
  const noActiveScreen = !activeScreen;

  if (setActiveScreen) {
    window.localStorage.setItem(
      FILTER_SCREEN_LS_KEY,
      JSON.stringify({ ...activeScreen, filters: activeScreen?.originFilters })
    );
  } else if (noActiveScreen) {
    activeScreen = screens[0];
    window.localStorage.removeItem(FILTER_SCREEN_LS_KEY);
  }

  set({ activeScreen, screens, filters: filters });
};

const updateFilter = (
  set: StoreApi<UseExploreFiltersStore>['setState'],
  get: StoreApi<UseExploreFiltersStore>['getState'],
  filter: Filter
) => {
  const activeScreen = get().activeScreen;
  if (activeScreen) {
    const updatedFilters = updateFilters(activeScreen.filters, filter);
    const newScreens = get().screens.map(s =>
      s.id === activeScreen.id ? { ...s, filters: updatedFilters } : s
    );
    updateScreens(
      set,
      { ...activeScreen, filters: updatedFilters },
      newScreens,
      updatedFilters
    );
  }
};

const revertScreen = (
  set: StoreApi<UseExploreFiltersStore>['setState'],
  get: StoreApi<UseExploreFiltersStore>['getState']
) => {
  const activeScreen = get().activeScreen;
  if (activeScreen) {
    const newActiveScreen = {
      ...activeScreen,
      filters: activeScreen.originFilters,
    };
    const newScreens = get().screens.map(s =>
      s.id === newActiveScreen?.id ? { ...s, filters: s.originFilters } : s
    );
    gaExploreRevertFiltersScreen(
      makeFiltersNamesString(activeScreen.originFilters)
    );

    updateScreens(set, newActiveScreen, newScreens, newActiveScreen.filters);
  }
};

const clearFilters = (
  set: StoreApi<UseExploreFiltersStore>['setState'],
  get: StoreApi<UseExploreFiltersStore>['getState']
) => {
  const activeScreen = get().activeScreen;
  if (activeScreen) {
    const newScreens = get().screens.map(s =>
      s.id === activeScreen.id ? { ...s, filters: [] } : s
    );
    updateScreens(set, { ...activeScreen, filters: [] }, newScreens, []);
  }
};

const clearExploreFilters = (
  set: StoreApi<UseExploreFiltersStore>['setState']
) => {
  window.localStorage.removeItem(FILTER_SCREEN_LS_KEY);
  window.localStorage.removeItem(EXPLORE_FILTERS_LS_KEY);

  set({
    ...INITIAL_STATE,
    filterOptions: [],
    activeScreen: null,
  });
};

const clearScreens = (set: StoreApi<UseExploreFiltersStore>['setState']) => {
  updateScreens(set, homeScreen, [homeScreen], []);
};

function getValueFromLS<T, F>(key: string, fallback: F): T | F {
  try {
    const localStorageFilterScreen = window.localStorage.getItem(key);
    return localStorageFilterScreen
      ? JSON.parse(localStorageFilterScreen)
      : fallback;
  } catch (error) {
    return fallback;
  }
}

const initialize = async (
  set: StoreApi<UseExploreFiltersStore>['setState'],
  get: StoreApi<UseExploreFiltersStore>['getState'],
  lastAnalysis: Date | undefined,
  initialFilters: UserFilterResponseFilters | null,
  t: TFunction
) => {
  if (!lastAnalysis || !get().filterOptions.length) {
    await fetchExploreFilterOptions(set, true);
  }

  if (initialFilters) {
    const screen = createScreen({
      name: t('explore:screens.myCustomScreen'),
      filters: formatFilterScreenPayload(initialFilters, get().filterOptions),
    });
    saveScreen(set, get, screen, screen.name);
  } else {
    fetchScreens(set, get);
  }
};

export const useExploreFilters = create<UseExploreFiltersStore>((set, get) => ({
  ...INITIAL_STATE,
  filterOptions: getValueFromLS(EXPLORE_FILTERS_LS_KEY, []),
  activeScreen: getValueFromLS<FilterScreen, null>(FILTER_SCREEN_LS_KEY, null),
  initialize: (
    lastAnalysis: Date | undefined,
    initialFilters: UserFilterResponseFilters | null,
    t: TFunction
  ) => initialize(set, get, lastAnalysis, initialFilters, t),
  updateFilter: (filter: Filter) => updateFilter(set, get, filter),
  setActiveScreen: (activeScreen: FilterScreen) =>
    updateScreens(set, activeScreen, get().screens, activeScreen.filters),
  clearFilters: () => clearFilters(set, get),
  revertScreen: () => revertScreen(set, get),
  saveScreen: (screenName: string) =>
    saveScreen(set, get, get().activeScreen!, screenName),
  deleteScreen: (screen: FilterScreen) => deleteScreen(set, get, screen),
  clearExploreFilters: () => clearExploreFilters(set),
  clearScreens: () => clearScreens(set),
  updateFilterOptions: () => fetchExploreFilterOptions(set),
}));
