import { deviceConstraints } from '@toggle/design-system';
import styled from 'styled-components';

import ToggleLogo from '~/assets/core/toggle-logo.svg';

export const HeaderPanel = styled.header`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: var(--app-header-height);
  text-align: center;
  box-shadow: none;
  background-color: var(--base--100);
  z-index: var(--z-index-app-header);

  @media ${deviceConstraints.tablet} {
    box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.8);
  }
`;

export const SvgLogo = styled(ToggleLogo)`
  width: 156px;
  vertical-align: middle;
  [class*='logoColor'] {
    fill: var(--copilot--default);
  }

  @media ${deviceConstraints.tablet} {
    box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.8);
    width: 104px;
  }
`;
