import { YAxisType } from '~/types/axis.types';

export enum OptionsMenuOptions {
  ChangeYAxisType = 'ChangeYAxisType',
  ResetChart = 'ResetChart',
}

export type OptionItem = OptionChangeYAxisType | OptionResetChart;

interface OptionChangeYAxisType {
  label: string;
  key: OptionsMenuOptions.ChangeYAxisType;
  value: YAxisType;
}

interface OptionResetChart {
  label: string;
  key: OptionsMenuOptions.ResetChart;
}
