import React from 'react';

import * as S from './ShortDescription.styles';

export interface ShortDescriptionProps {
  heading: string;
  text: string;
}

export const ShortDescription = ({
  heading,
  text,
  ...rest
}: ShortDescriptionProps) => {
  return (
    <S.ShortDescriptionRoot data-testid="module-short-description" {...rest}>
      <S.Heading>{heading}</S.Heading>
      <S.Text>{text}</S.Text>
    </S.ShortDescriptionRoot>
  );
};
