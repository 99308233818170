import React from 'react';
import { useTranslation } from 'react-i18next';

import { INSIGHT_SECTION_IDS } from '~/views/helper/HelperConfig';

import {
  HelperWidgetAccordion,
  HelperWidgetSection,
} from '../../partials/accordion/HelperWidgetAccordion';
import { HelperContentProps } from '../content';
import { FullAssetPictureContent } from './FullAssetPictureContent';
import { HistoryChartContent } from './HistoryChartContent';
import { InsightIntro } from './InsightIntro';
import { InsightSummaryContent } from './InsightSummaryContent';
import { NavigationMenuContent } from './NavigationMenuContent';
import { PastPerformanceContent } from './PastPerformanceContent';
import { ProjectionChartContent } from './ProjectionChartContent';
import { SharingInsightContent } from './SharingInsightContent';
import { StarsContent } from './StartsContent';

export const InsightHelperContent = (props: HelperContentProps) => {
  const { t } = useTranslation('helperWidget');

  const sections: HelperWidgetSection[] = [
    {
      id: INSIGHT_SECTION_IDS.INSIGHT_INTRO_ID,
      title: t('helperWidget:insight.content.sectionsTitles.intro'),
      gaTitle: 'TOGGLE Insight Intro',
      content: activateSection => (
        <InsightIntro
          onVideoClick={() =>
            activateSection(INSIGHT_SECTION_IDS.INSIGHT_INTRO_ID)
          }
        />
      ),
      separateSection: true,
      hideMarker: true,
    },
    {
      id: INSIGHT_SECTION_IDS.INSIGHT_MASTERCLASS_ID,
      title: t('helperWidget:insight.content.sectionsTitles.master'),
      gaTitle: 'Master TOGGLE Insights',
      hideMarker: true,
    },
    {
      id: INSIGHT_SECTION_IDS.INSIGHT_PROJECTION_CHART_ID,
      title: t('helperWidget:insight.content.sectionsTitles.projectionChart'),
      gaTitle: 'Projection Chart',
      content: <ProjectionChartContent />,
      handleMouse: true,
    },
    {
      id: INSIGHT_SECTION_IDS.INSIGHT_NAVIGATION_MENU_ID,
      title: t('helperWidget:insight.content.sectionsTitles.navigationMenu'),
      gaTitle: 'Navigation Menu',
      content: <NavigationMenuContent />,
      handleMouse: true,
    },
    {
      id: INSIGHT_SECTION_IDS.INSIGHT_SUMMARY_ID,
      title: t('helperWidget:insight.content.sectionsTitles.insightSummary'),
      gaTitle: 'Insight Summary',
      content: <InsightSummaryContent />,
      handleMouse: true,
    },
    {
      id: INSIGHT_SECTION_IDS.INSIGHT_HISTORY_CHART_ID,
      title: t('helperWidget:insight.content.sectionsTitles.historyChart'),
      gaTitle: 'History chart',
      content: <HistoryChartContent />,
      handleMouse: true,
    },
    {
      id: INSIGHT_SECTION_IDS.INSIGHT_STARS_ID,
      title: t('helperWidget:insight.content.sectionsTitles.stars'),
      gaTitle: 'Stars / Quality Checklist',
      content: <StarsContent />,
      handleMouse: true,
    },
    {
      id: INSIGHT_SECTION_IDS.INSIGHT_PAST_PERFORMANCE_ID,
      title: t('helperWidget:insight.content.sectionsTitles.pastPerformance'),
      gaTitle: 'Past Performance',
      content: <PastPerformanceContent />,
      handleMouse: true,
    },
    {
      id: INSIGHT_SECTION_IDS.INSIGHT_SHARING_INSIGHT_ID,
      title: t('helperWidget:insight.content.sectionsTitles.sharingInsight'),
      gaTitle: 'Sharing an insight',
      content: <SharingInsightContent />,
      handleMouse: true,
    },
    {
      id: INSIGHT_SECTION_IDS.INSIGHT_FULL_ASSET_PICTURE_ID,
      title: t('helperWidget:insight.content.sectionsTitles.fullAssetPicture'),
      gaTitle: 'See full asset picture (Go to overview)',
      content: <FullAssetPictureContent />,
      handleMouse: true,
    },
  ];

  return (
    <HelperWidgetAccordion sections={sections} sendGaOnExpand {...props} />
  );
};
