import React, { SyntheticEvent, useEffect, useRef } from 'react';

import { GeneralSearch } from '~/global/app-search/general-search/GeneralSearch';
import { useMediaQuery } from '~/shared/hooks/MediaQueryHook';
import { MediaQueries } from '~/shared/hooks/MediaQueryList';
import { useGlobalSearch } from '~/stores/use-global-search/useGlobalSearch';
import { usePortfolio } from '~/stores/use-portfolio/usePortfolio';

import * as S from './AppSearch.styles';

interface AppSearchProps {
  path: string;
}

export const AppSearch = ({ path }: AppSearchProps) => {
  const { isSearchOpened, withOverlay, closeSearch, isWatchlistOnly } =
    useGlobalSearch(
      ({ isSearchOpened, withOverlay, closeSearch, isWatchlistOnly }) => ({
        isSearchOpened,
        withOverlay,
        closeSearch,
        isWatchlistOnly,
      })
    );
  const hasPortfolioWithTradingEnabled = usePortfolio(s =>
    s.hasPortfolioWithTradingEnabled()
  );
  const currentMedia = useMediaQuery(s => s.currentMedia);
  const isMobile: boolean = [
    MediaQueries.SMALL_MOBILE,
    MediaQueries.MOBILE,
  ].includes(currentMedia);

  const overlayBottomSideAreaRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isSearchOpened) {
      closeSearch();
    }
  }, [path]);

  const handleDesktopOverlay = (event: SyntheticEvent) => {
    event.preventDefault();
    if (
      event.target === event.currentTarget ||
      event.target === overlayBottomSideAreaRef.current
    ) {
      closeSearch();
    }
  };

  const searchArea = (
    <S.SearchArea
      className="search-area"
      isMobile={isMobile}
      data-testid="search-area"
      showSearchTabs={!isWatchlistOnly && hasPortfolioWithTradingEnabled}
    >
      <GeneralSearch
        onSearch={closeSearch}
        isMobile={isMobile}
        isTradingEnabled={!isWatchlistOnly && hasPortfolioWithTradingEnabled}
      />
    </S.SearchArea>
  );

  if (!isSearchOpened) {
    return null;
  }

  if (isMobile) {
    return searchArea;
  }

  return withOverlay ? (
    <S.DesktopOverlay
      onClick={handleDesktopOverlay}
      data-testid="desktop-overlay"
    >
      <S.BottomSideArea ref={overlayBottomSideAreaRef}>
        {searchArea}
      </S.BottomSideArea>
    </S.DesktopOverlay>
  ) : (
    searchArea
  );
};
