import { Table, TableCell, typographyV2 } from '@toggle/design-system';
import styled from 'styled-components';

export const EvidenceTable = styled(Table)`
  width: 100%;
`;

export const SpecialMessage = styled.p`
  position: absolute;
  color: var(--text-soft);
  ${typographyV2.bodyXs};
  padding-top: 12px;
`;

export const BodyRow = styled.tr`
  td {
    padding-bottom: 42px;
    position: relative;
  }
`;

export const EpisodeTableCell = styled(TableCell)`
  font-family: var(--font-nort);
`;
