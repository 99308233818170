import { Alert, deviceConstraints, typography } from '@toggle/design-system';
import styled, { css } from 'styled-components';

const sectionPadding = css`
  padding: 8px 32px;
  @media ${deviceConstraints.tablet} {
    padding: 16px 32px;
  }
`;

export const SectionContainer = styled.section`
  color: var(--base--0);
  background: var(--base--100);
  position: relative;
  max-width: 840px;
  width: 84%;
  margin: 0 auto;
`;

export const BannerContainer = styled.section`
  margin: 40px 16px 16px;
`;

export const Title = styled.section`
  ${sectionPadding}
  grid-area: title;
`;

export const TitleText = styled.h1`
  ${typography.chimp}
`;

export const TitleDetails = styled.span`
  display: block;
  color: var(--copilot--default);
`;

export const Image = styled.aside`
  ${sectionPadding}
  float: right;
`;

export const TextDetails = styled.section`
  ${sectionPadding}
`;

export const List = styled.ul`
  && {
    list-style-type: disc;
    margin: 18px 16px;
  }
  padding: 18px;
`;

export const ListItem = styled.li`
  ${typography.koala}
  color: var(--base--20);
  padding-bottom: 16px;
`;

export const ListTitle = styled.p`
  ${typography.koala}
`;

export const ButtonWrap = styled.div`
  max-width: 380px;
`;

export const ContactText = styled.p`
  ${typography.quokka}
  font-weight: 500;
  text-align: center;
  padding: 16px;
  max-width: 380px;
`;

export const ContactTextLink = styled.a`
  color: var(--copilot--default);
  font-weight: normal;
`;

export const Banner = styled(Alert)`
  width: 100%;
  border-color: var(--primary-color);
`;
