import { useElemSize } from '@toggle/helpers';
import * as d3 from 'd3';
import React, { FC, useMemo, useRef } from 'react';

import { TSPoint } from '~/shared/components/chart/Chart';

import * as S from './SimpleChart.styled';

type NumericPoint = TSPoint<number, number>;

interface SimpleChartProps {
  ts: Array<NumericPoint>;
  base: number;
}

export const SimpleChart: FC<SimpleChartProps> = ({ ts, base, ...rest }) => {
  const svgRef = useRef<SVGSVGElement>(null);
  const { width, height } = useElemSize(svgRef);

  const xScalePartial = useMemo(
    () => d3.scaleLinear().domain(d3.extent(ts.map(t => t.index)) as number[]),
    [ts]
  );
  const yScalePartial = useMemo(
    () => d3.scaleLinear().domain(d3.extent(ts.map(t => t.value)) as number[]),
    [ts]
  );

  const [midLine, plotLine] = useMemo(() => {
    const xScale = xScalePartial.copy().range([0, width]);
    const yScale = yScalePartial.copy().range([height, 0]);

    const plotLine = d3
      .line<NumericPoint>()
      .x(p => xScale(p.index))
      .y(p => yScale(p.value))
      .curve(d3.curveCardinal)(ts);

    const midLine = d3
      .line()
      .x(p => p[0])
      .y(p => p[1])([
      [0, yScale(base)],
      [width, yScale(base)],
    ]);

    return [midLine, plotLine];
  }, [width, height, xScalePartial, yScalePartial, base]);

  return (
    <S.Svg ref={svgRef} data-testid="simple-chart" {...rest}>
      <S.PlotPath d={plotLine ?? ''} />
      <S.MidPath d={midLine ?? ''} />
    </S.Svg>
  );
};
