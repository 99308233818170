import { AssetSubClassNames } from '~/api/entities/entity-constants';
import { MappedEntity } from '~/shared/hooks/use-entities';
import {
  formatAssetPrice,
  priceDecimals,
  SIGNIFICANT_DIGITS_COUNT,
} from '~/shared/utils/currency/currency';

interface AssetPriceProps {
  price: number;
  currency?: string;
  suffix: string;
  entitySubClass: AssetSubClassNames;
  decimals?: number;
  locale?: string;
  shouldShowCurrency?: boolean;
}

export const assetPrice = ({
  price,
  currency,
  suffix,
  entitySubClass,
  decimals,
  locale = window.navigator.language,
  shouldShowCurrency,
}: AssetPriceProps) => {
  const digits = decimals ?? priceDecimals(entitySubClass)(price);

  const formatPrice = () => {
    try {
      const significantNumberRule = price !== 0 && price < 1;
      return `${price.toLocaleString(locale, {
        minimumFractionDigits: significantNumberRule ? undefined : digits,
        maximumSignificantDigits: significantNumberRule
          ? SIGNIFICANT_DIGITS_COUNT
          : undefined,
      })} ${suffix}`;
    } catch {
      return price ? `${price} ${suffix}` : '';
    }
  };

  return suffix === currency
    ? formatAssetPrice(price, currency, digits, locale, shouldShowCurrency)
    : formatPrice();
};

export type PriceFormatterFn = (
  options: {
    entity: MappedEntity;
    price: number;
    priceSuffix: string;
    shouldShowCurrency?: boolean;
  },
  decimals?: number | undefined
) => string;

export const globalPriceFormatter: PriceFormatterFn = (
  { entity, price, priceSuffix, shouldShowCurrency },
  decimals
) => {
  return assetPrice({
    price,
    decimals,
    entitySubClass: entity.sub_class as AssetSubClassNames,
    currency: entity.currency,
    suffix: priceSuffix,
    shouldShowCurrency,
  });
};
