import { SnakeMetaResponseV2 } from '@toggle/toggle';

import { TimeSeriesData } from '~/api/timeseries/timeseries-schema';
import { OptionalU } from '~/declarations/standard';
import { ENDPOINT_URLS } from '~/global/toggle-api';

import { APIFetch } from '../api-fetch/APIFetch';
import { HttpMethods } from '../api-fetch/constants';

export enum EntityAnalyticsReportType {
  VALUATION = 'valuation',
  ENTRY_POINT = 'entry_point',
  POSITIONING = 'positioning',
  MOMENTUM = 'momentum',
  EQUITY_YIELD = 'equity_yield',
  EQUITY_GROWTH_GAUGE = 'equity_growth_gauge',
  EQUITY_CONSENSUS_TREND = 'equity_consensus_trend',
  VOLUME_ANALYSIS = 'volume_analysis',
  ECONOMIC_GROWTH_GAUGE = 'economic_growth_gauge',
  ECONOMIC_INFLATION_GAUGE = 'economic_inflation_gauge',
  FINANCIAL_CONDITIONS = 'financial_conditions',
  VOLATILITY = 'volatility',
  MAIN_MARKET_PRICE = 'main_market_price',
  MAIN_YIELD = 'main_yield',
  TECHNICAL_ANALYSIS = 'technical_analysis',
  GEOPOLITICAL = 'geopolitical',
  SEASONALITY = 'seasonality',
  SENTIMENT = 'sentiment',
}

interface SummaryReportItemDrillDown {
  label: string;
  latest_value: number;
  percentile: number;
  snake: string;
  method: string;
}

interface SummaryReportItem {
  label: string;
  method_sub_cls: EntityAnalyticsReportType;
  percentile: number;
  pill: string;
  drill_down: SummaryReportItemDrillDown[];
  asset_cls: string;
}

export function fetchAnalyseData(entityName: string) {
  return APIFetch<SummaryReportItem[]>(ENDPOINT_URLS.OW_ASSET_ITEMS_V3, {
    method: HttpMethods.Post,
    body: JSON.stringify({ entity: entityName }),
  });
}

export function fetchSnakeByEntity(
  entityName: string,
  nDays: OptionalU<number> = 0
) {
  const params = nDays !== undefined ? { n_days: nDays } : {};
  return APIFetch<TimeSeriesData>(ENDPOINT_URLS.OW_SNAKE_BY_ENTITY, {
    method: HttpMethods.Post,
    body: JSON.stringify({ entity: entityName, ...params }),
  });
}

export function fetchSnakeByName(
  snakeName: string,
  nDays?: number,
  signal?: AbortSignal
) {
  const params = nDays !== undefined ? { date_since: nDays } : {};
  return APIFetch<TimeSeriesData>(ENDPOINT_URLS.OW_SNAKE_BY_NAME, {
    method: HttpMethods.Post,
    body: JSON.stringify({ snake_expression: snakeName, ...params, static: 1 }),
    signal,
  });
}

export function fetchSnakeMetaV2(snakes: string[], signal?: AbortSignal) {
  const snakesString = snakes.map(encodeURIComponent).join('&snakes=');
  return APIFetch<SnakeMetaResponseV2>(
    `${ENDPOINT_URLS.SNAKE_META_V2}?snakes=${snakesString}`,
    {
      method: HttpMethods.Get,
      signal,
    }
  );
}

export function fetchConnectedEntities(entityTag: string) {
  return APIFetch<string[] | Error>(ENDPOINT_URLS.CONNECTED_ENTITIES, {
    method: HttpMethods.Post,
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      entity: entityTag,
    }),
  });
}
