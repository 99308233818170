import { yAxis } from '~/core/axis/axis';
import { Domain, PriceDisplay } from '~/types/axis.types';
import {
  BaseChartAPIProps,
  ChartPane,
  CreateChartOptionsWithColors,
  CreateChartOptionsWithConfig,
  DomainTimeSeries,
  PaneData,
  XAxis,
  YAxis,
  YAxisSize,
} from '~/types/create.types';
import { PaneDetails } from '~/types/events.types';
import { ChartAssetData, ChartInsight } from '~/types/timeseries.types';
import { getDomainTimeSeries } from '~/utils/timeseries/time-series';

import { PANES_DIVIDER_HEIGHT } from '../constants';
import { calculateYAxisWidth } from '../y-scale/y-scale';

export interface PaneStoreBase {
  chartAssetData: ChartAssetData;
  context: CanvasRenderingContext2D;
  y: YAxis;
  x: XAxis;
  options: CreateChartOptionsWithColors;
  priceDisplay: PriceDisplay;
  domain: Domain;
  primaryAsset: ChartAssetData;
  domainTimeSeries: DomainTimeSeries[];
}

export interface PaneProps {
  options: CreateChartOptionsWithConfig;
  top: number;
  height: number;
  insights?: ChartInsight[];
  paneData: PaneData;
  domainTimeSeries: ChartPane['domainTimeSeries'];
  actualHeight: ChartPane['actualHeight'];
}

export const getTotalFreeSpace = (
  options: BaseChartAPIProps['options'],
  totalDividerHeight: number
) => options.height - options.gutters.x - totalDividerHeight;

export const getPaneTop = ({
  chartPanes,
  index,
}: {
  chartPanes: ChartPane[];
  index: number;
}) => {
  if (index === 0) {
    return 0;
  }

  return (
    chartPanes[index - 1].options.gutters.top +
    chartPanes[index - 1].options.height +
    PANES_DIVIDER_HEIGHT
  );
};

export const createChartPane = ({
  options,
  insights,
  paneData,
  top,
  height,
  domainTimeSeries,
  actualHeight,
}: PaneProps): ChartPane => {
  const paneOptions: ChartPane['options'] = {
    width: options.width,
    height,
    gutters: {
      x: 0,
      top,
    },
  };

  const y = yAxis({
    paneOptions,
    domainTimeSeries,
    paneData,
    ticksCount: Math.min(12, Math.ceil(height / 40)),
  });

  return {
    ...paneData,
    actualHeight,
    options: paneOptions,
    y,
    insights,
    domainTimeSeries,
  };
};

export const createChartPanes = ({
  prevBase,
  panes,
  primaryAsset,
  yAxisDomain,
  options,
  paneHeights,
}: {
  prevBase?: BaseChartAPIProps;
  panes: PaneData[];
  primaryAsset: ChartAssetData;
  yAxisDomain: Domain;
  options: BaseChartAPIProps['options'];
  paneHeights: number[];
}) => {
  const chartPanes: ChartPane[] = [];
  const isPaneAdded =
    !!prevBase?.prevPanes && panes.length > prevBase.prevPanes.length;

  panes.forEach((pane, index) => {
    const prevPane = prevBase?.prevPanes?.find(p => p.id === pane.id);
    const top = getPaneTop({ chartPanes, index });
    const height = paneHeights[index];
    const domainTimeSeries = getDomainTimeSeries(
      pane.chartAssetData,
      primaryAsset,
      yAxisDomain[0],
      yAxisDomain[1]
    );

    const createdPane = createChartPane({
      top,
      height,
      paneData: pane,
      options: options,
      insights: prevPane?.insights,
      domainTimeSeries,
      actualHeight: isPaneAdded ? height : prevPane?.actualHeight ?? height,
    });

    chartPanes.push(createdPane);
  });

  return chartPanes;
};

export const getActivePaneIndex = (e: MouseEvent, base: BaseChartAPIProps) =>
  base.panes.findIndex(
    pane =>
      e.offsetY >= pane.options.gutters.top &&
      e.offsetY <= pane.options.gutters.top + pane.options.height
  );

export const getPaneDetails = (
  event: MouseEvent,
  base: BaseChartAPIProps
): PaneDetails | undefined => {
  const paneIndex = getActivePaneIndex(event, base);

  if (base.panes.length > 1 && base.panes[paneIndex]) {
    const pane = base.panes[paneIndex];

    return {
      pane: pane,
      index: paneIndex,
      isPrimary: pane.id === base.primaryPaneId,
    };
  }

  return undefined;
};

export const getActivePanes = <T extends PaneData>(chartPanes: T[]) => {
  const maximizedPane = chartPanes.find(p => p.maximized);
  return maximizedPane ? [maximizedPane] : chartPanes.filter(p => !p.collapsed);
};

export const shouldResizePane = ({
  base,
  dividerIndex,
  diff,
}: {
  base: BaseChartAPIProps;
  dividerIndex: number;
  diff: number;
}) => {
  const collapsedPaneHeight = base.options.config.collapsedPaneHeight;
  return (
    base.panes[dividerIndex].actualHeight - diff >= collapsedPaneHeight &&
    base.panes[dividerIndex - 1].actualHeight + diff >= collapsedPaneHeight
  );
};

export const getPaneDividerIndex = (e: MouseEvent, base: BaseChartAPIProps) => {
  const paneDividerIndex = base.panes.findIndex((pane, index) => {
    const isUpperPaneCollapsed = !!base.panes[index - 1]?.collapsed;
    if (pane.collapsed || isUpperPaneCollapsed) {
      return false;
    }

    const yCoord = pane.options.gutters.top - PANES_DIVIDER_HEIGHT;
    return e.offsetY < yCoord + PANES_DIVIDER_HEIGHT && e.offsetY > yCoord;
  });

  return paneDividerIndex === -1 ? undefined : paneDividerIndex;
};

export const getPaneHeights = ({
  options,
  panes,
  prevBase,
}: {
  panes: PaneData[];
  options: BaseChartAPIProps['options'];
  prevBase?: BaseChartAPIProps;
}) => {
  const panesCount = panes.length;
  const collapsedPanesCount = panes.filter(
    p => !p.maximized && p.collapsed
  ).length;
  const totalDividerHeight = (panesCount - 1) * PANES_DIVIDER_HEIGHT;
  const totalSpace = getTotalFreeSpace(options, totalDividerHeight);
  const collapsedPaneHeight = options.config.collapsedPaneHeight;
  let newPaneHeight =
    (totalSpace - collapsedPanesCount * collapsedPaneHeight) /
    (panesCount - collapsedPanesCount);

  if (panesCount === 1 || !prevBase?.prevPanes) {
    return panes.map(() => newPaneHeight);
  }

  const prevPanes = prevBase.prevPanes;
  const isSamePanes = panes.every(pane =>
    prevPanes.find(prevPane => prevPane.id === pane.id)
  );
  if (panes.length < prevPanes.length || isSamePanes) {
    newPaneHeight = 0;
  }

  const freeSpace =
    totalSpace - collapsedPaneHeight * collapsedPanesCount - newPaneHeight;

  const defaultPanes = panes.filter(p => !p.maximized && !p.collapsed);
  const base =
    (prevPanes.find(p => p.id === defaultPanes[0].id) as ChartPane) ??
    defaultPanes[0];
  const ratios = defaultPanes.reduce((res, pane) => {
    const prev = prevPanes.find(p => p.id === pane.id);
    if (!prev) {
      return res;
    }
    let ratio = pane.id === base.id ? 1 : prev.actualHeight / base.actualHeight;
    res[pane.id] = ratio;
    return res;
  }, {} as Record<string, number>);

  const baseCost =
    freeSpace / Object.values(ratios).reduce((res, next) => res + next, 0);

  return panes.map(pane => {
    if (pane.collapsed) {
      return collapsedPaneHeight;
    }

    const currentRatio = ratios[pane.id];
    return currentRatio ? baseCost * currentRatio : newPaneHeight;
  });
};

export const getYAxisSizes = (chartPanes: ChartPane[]) => {
  const mapIndexToMaxWidth: Record<string, number> = {};

  chartPanes.forEach(chartPane => {
    chartPane.y.forEach((p, index) => {
      const yWidth = calculateYAxisWidth(p.labels);
      mapIndexToMaxWidth[index] = Math.max(
        yWidth,
        mapIndexToMaxWidth[index] ?? 0
      );
    });
  });

  const yAxisSizes = Object.keys(mapIndexToMaxWidth).reduce((res, index) => {
    const width = mapIndexToMaxWidth[index];
    const xStart = res[res.length - 1]
      ? res[res.length - 1].xStart + res[res.length - 1].width
      : 0;

    res.push({ width, xStart });

    return res;
  }, [] as YAxisSize[]);

  const totalWidth = yAxisSizes.reduce((res, next) => res + next.width, 0);

  return { yAxisSizes, totalWidth };
};
