import { Icon } from '@toggle/design-system';
import { getElementHeight } from '@toggle/helpers';
import React, { RefObject, useRef, useState } from 'react';
// import ScrollingMenu from 'react-horizontal-scrolling-menu';
import { useTranslation } from 'react-i18next';

import { appPaths } from '~/routes/app-paths';
import { ScrollingMenu } from '~/shared/components/scrolling-menu/ScrollingMenu';
import { useMediaQuery } from '~/shared/hooks/MediaQueryHook';
import { MediaQueries } from '~/shared/hooks/MediaQueryList';
import {
  gaArticleGoToOverview,
  gaArticleLHSPanelAction,
} from '~/shared/utils/ganalytics';

import { CollapseRef } from '../full-article';
import { allMenuItems, defaultMenuItems } from './article-sidebar-menu-items';
import * as S from './ArticleSidebarMenu.styles';
import { useScrollToSection } from './useScrollToSection';
import { useSpyScrollOnSections } from './useSpyScrollOnSections';

export interface ArticleSidebarMenuProps {
  assetName: string;
  assetEntity: string;
  isLockedView?: boolean;
  isBasic?: boolean;
  sectionsRefs?: Array<RefObject<HTMLHeadingElement>>;
  collapseRef?: RefObject<CollapseRef | undefined>;
  onBeforeScroll?: (ref: RefObject<HTMLHeadingElement>) => void;
}

export const BOOKMARK_HEIGHT = 40;

export function ArticleSidebarMenu({
  assetName,
  assetEntity,
  isLockedView = false,
  isBasic = false,
  sectionsRefs,
  onBeforeScroll,
  collapseRef,
}: ArticleSidebarMenuProps) {
  const { t } = useTranslation('article');
  const menuRef = useRef<HTMLElement>(null);
  const menuItems = isLockedView ? defaultMenuItems : allMenuItems;
  const [activeSection, setActiveSection] = useState(menuItems[0].translateKey);

  function getAdditionalOffset() {
    let offset = BOOKMARK_HEIGHT;
    if (isMobile || isTablet) {
      offset += getElementHeight(menuRef);
    }

    return offset;
  }

  const currentMedia = useMediaQuery(s => s.currentMedia);
  const currentMediaIndex = [
    MediaQueries.MOBILE,
    MediaQueries.SMALL_MOBILE,
    MediaQueries.TABLET,
  ].indexOf(currentMedia);
  const isMobile = currentMediaIndex >= 0 && currentMediaIndex < 2;
  const isTablet = currentMediaIndex === 2;

  const { scrollToSection } = useScrollToSection();
  const isSectionCollapsed = (idx: number) => {
    if (collapseRef?.current) {
      return menuItems[idx]?.couldBeCollapsed && collapseRef.current.collapsed;
    }
    return true;
  };

  useSpyScrollOnSections(setActiveSection, isSectionCollapsed, sectionsRefs);

  const articleSidebarMenuItems = menuItems.map((menuItem, i) => {
    const isLocked = menuItem.couldBeLocked && isBasic;

    const isActive = menuItem.translateKey === activeSection;
    const onClick = () => {
      gaArticleLHSPanelAction(menuItem.translateKey);
      if (sectionsRefs && onBeforeScroll) {
        onBeforeScroll(sectionsRefs[i]);
        scrollToSection(sectionsRefs[i], getAdditionalOffset());
      }
    };

    return (
      <S.MenuItem
        key={menuItem.translateKey}
        $isActive={isActive}
        $isLocked={isLocked}
        onClick={onClick}
      >
        <S.MenuItemContent data-testid="menu-item">
          {t(`article:sidebarMenu.${menuItem.translateKey}`)}
          {isLocked ? (
            <S.LockedIcon />
          ) : (
            <S.ChevronRight fillColor="currentColor" />
          )}
        </S.MenuItemContent>
      </S.MenuItem>
    );
  });

  return (
    <S.Container ref={menuRef}>
      {!isLockedView && (
        <S.OverviewLink
          rel="noopener noreferrer"
          target="_blank"
          to={`${appPaths.analyze}/${assetEntity}`}
          data-testid="asset-link"
          onClick={() => gaArticleGoToOverview(assetEntity)}
        >
          <span>
            {assetName} {t('article:sidebarMenu.overview')}
            <Icon iconName="ShowPassword" size={20} fillColor="currentColor" />
          </span>
        </S.OverviewLink>
      )}
      {isMobile ? (
        <ScrollingMenu
          activeIdx={articleSidebarMenuItems.findIndex(
            i => i.key === activeSection
          )}
        >
          {articleSidebarMenuItems}
        </ScrollingMenu>
      ) : (
        <S.MenuContainer>{articleSidebarMenuItems}</S.MenuContainer>
      )}
    </S.Container>
  );
}
