import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

interface UpdateBrowserTitleProps {
  title: string;
  withMetadataTitle?: boolean;
}

export const UpdateBrowserTitle = ({
  title,
  withMetadataTitle = true,
}: UpdateBrowserTitleProps) => {
  const { t } = useTranslation('seo');
  return (
    <Helmet>
      <title>
        {withMetadataTitle ? `${title} - ${t('seo:default.title')}` : title}
      </title>
    </Helmet>
  );
};
