import React, {
  ChangeEvent,
  InputHTMLAttributes,
  useEffect,
  useState,
} from 'react';

import { StyledComponent } from '~/common/styled-component';
import { SvgIconNames } from '~/design-tokens/iconography/SvgIcons';

import * as S from './Toggle.styles';

export enum ToggleIconState {
  OnlyOn,
  OnlyOff,
  Both,
}

export interface ToggleProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'size'> {
  id?: string;
  isChecked?: boolean;
  onToggle?: (checked: boolean, event: ChangeEvent<HTMLInputElement>) => void;
  leftLabel?: string;
  rightLabel?: string;
  icon?: SvgIconNames;
  iconVisibility?: ToggleIconState;
  size?: 'medium' | 'small';
}

export const Toggle: StyledComponent<ToggleProps, typeof S> = ({
  id,
  isChecked = false,
  onToggle,
  leftLabel,
  rightLabel,
  icon = 'Checkmark',
  iconVisibility = ToggleIconState.OnlyOn,
  size = 'small',
  className,
  ...rest
}) => {
  const [checked, setChecked] = useState(isChecked);

  useEffect(() => {
    setChecked(isChecked);
  }, [isChecked]);

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.checked;

    if (onToggle) {
      onToggle(newValue, e);
    }
  };

  const shouldShowIcon =
    iconVisibility === ToggleIconState.Both ||
    (!checked && iconVisibility === ToggleIconState.OnlyOff) ||
    (checked && iconVisibility === ToggleIconState.OnlyOn);

  return (
    <S.ToggleContainer htmlFor={id} className={className}>
      {leftLabel && <S.Text>{leftLabel}</S.Text>}
      <S.Slider
        $isChecked={checked}
        data-testid="toggle-button-container"
        role="switch"
        aria-checked={checked}
        $size={size}
      >
        {icon && (
          <S.IconContainer>
            <S.SliderIcon
              size={size === 'medium' ? 14 : 10}
              iconName={icon}
              fillColor="var(--base--black)"
              $isVisible={shouldShowIcon}
              $isChecked={checked}
            />
          </S.IconContainer>
        )}
        <S.ToggleInput
          id={id}
          type="checkbox"
          checked={checked}
          aria-checked={isChecked}
          onChange={handleOnChange}
          data-testid="toggle-ds"
          {...rest}
        />
      </S.Slider>
      {rightLabel && <S.Text>{rightLabel}</S.Text>}
    </S.ToggleContainer>
  );
};

Toggle.Styled = S;
