import { Button, Chip, typographyV2 } from '@toggle/design-system';
import styled from 'styled-components';

export const AmbiguousEntitiesRoot = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Container = styled.div`
  margin: 8px 0;
`;

export const MessageHeading = styled.p`
  ${typographyV2.uiXs}
  color: var(--text-soft);
  padding-bottom: 4px;
  border-bottom: var(--border-default) 1px solid;
  margin-top: 24px;
`;

export const Message = styled.p`
  ${typographyV2.bodyBase}
`;

export const StyledChip = styled(Chip)`
  margin: 12px 12px 0 0;
`;

export const StyledButton = styled(Button)`
  align-self: flex-end;
`;
