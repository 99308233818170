import { ChartAssetData } from '@toggle/chart';
import { subMonths } from 'date-fns';
import { useEffect, useState } from 'react';

import { ArticleSummary, NewsItem } from '~/declarations/toggle-api-news';
import { fetchNews, NEWS_TYPE } from '~/shared/hooks/use-news';
import { fetchFeed } from '~/shared/services/feed/feed';

import { EventsConfig } from '../../components/events-modal/EventsModal';

export interface UseChartInsightsProps {
  chartData: ChartAssetData[];
  eventsConfig: EventsConfig;
}

export const useChartInsights = ({
  chartData,
  eventsConfig,
}: UseChartInsightsProps) => {
  const [articleInsights, setArticleInsights] = useState<ArticleSummary[]>([]);
  const [newsInsights, setNewsInsights] = useState<NewsItem[]>([]);
  const [insightsLoading, setInsightsLoading] = useState(false);

  const fetchNewsAndArticleInsights = async () => {
    setInsightsLoading(true);
    const primaryAsset = chartData[0];
    const ts = primaryAsset.ts;
    const endDate = new Date(ts[ts.length - 1].time);
    try {
      const [news, articles] = await Promise.all([
        fetchNews({
          type: NEWS_TYPE.AI,
          tickers: [primaryAsset.entity.ticker],
          page: 0,
          limit: 60,
        }),
        fetchFeed({
          entityTag: primaryAsset.entity.tag,
          pageLimit: 60,
          startDate: subMonths(endDate, 6),
          endDate: new Date(ts[ts.length - 1].time),
        }),
      ]);

      setNewsInsights(news[primaryAsset.entity.ticker]);
      setArticleInsights(articles.result);
      setInsightsLoading(false);
    } catch (error) {
      setInsightsLoading(false);
    }
  };

  useEffect(() => {
    if (!eventsConfig.TMI && !eventsConfig.TNI) {
      return;
    }

    fetchNewsAndArticleInsights();
  }, [chartData[0]?.entity, eventsConfig]);

  return {
    newsInsights,
    articleInsights,
    insightsLoading,
  };
};
