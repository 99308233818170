import styled from 'styled-components';

import { typographyV2 } from '~/design-tokens/typography/Typography.styles';

export const ShortDescriptionRoot = styled.div`
  display: flex;
  flex-direction: column;
  margin: 12px 16px;
`;

export const Heading = styled.p`
  color: var(--text-soft);
  ${typographyV2.bodySm}
`;

export const Text = styled.p`
  ${typographyV2.bodyBase}
`;
