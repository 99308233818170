import React, { FC } from 'react';

import {
  Category,
  CategoryGroup,
} from './components/category-group/CategoryGroup';
import * as S from './SideNavigation.styles';

export interface SideNavigationProps {
  title: string;
  onClick: (id: string) => void;
  categories: Category[];
  activeKey?: string;
  disabledKeys?: string[];
  className?: string;
}

export const SideNavigation: FC<SideNavigationProps> = ({
  title,
  categories,
  className,
  activeKey,
  disabledKeys,
  onClick,
}) => {
  return (
    <S.Aside className={className} data-testid="side-navigation">
      <S.Title>{title}</S.Title>
      <S.Categories>
        {categories.map(category => (
          <CategoryGroup
            key={category.title}
            category={category}
            activeKey={activeKey}
            disabledKeys={disabledKeys}
            onClick={onClick}
          />
        ))}
      </S.Categories>
    </S.Aside>
  );
};
