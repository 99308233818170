import { TFunction } from 'i18next';
import map from 'lodash/map';
import React, { FC } from 'react';

import { ArticleHitRatio } from '~/declarations/toggle-api-news';
import { ContentAlignments } from '~/shared/components/table/Table';

import * as S from './HorizonTableMobile.styles';

interface FundamentalsProps {
  hitRatio: ArticleHitRatio[];
  bestRatioIndex: number;
  t: TFunction;
  suffix: string;
}

export const HorizonTableMobile: FC<FundamentalsProps> = ({
  hitRatio,
  bestRatioIndex,
  t,
  suffix,
}) => {
  const horizonNameList = map(hitRatio, item => {
    /* i18next-extract-mark-context-next-line ["1w", "2w", "1m", "3m", "6m"] */
    return t('article:horizons.horizon_name', {
      context: item.horizon_name.toLowerCase(),
    });
  });
  const medianRow = map(hitRatio, 'median');
  const formattedMedianRow = medianRow.map(v => `${v.toFixed(2)}${suffix}`);
  const hitRatioRow = map(hitRatio, 'hit_ratio');
  const formattedHitRatioRow = hitRatioRow.map(v =>
    v ? `${v.toFixed(2)}%` : 'n/a'
  );
  const rows = horizonNameList.map((name, i) => [
    name,
    formattedMedianRow[i],
    formattedHitRatioRow[i],
  ]);
  rows.unshift([
    '',
    t('article:whyItMightMove.fundamentals.median'),
    t('article:whyItMightMove.fundamentals.hitRatio'),
  ]);

  return (
    <S.StyledTable
      rows={rows}
      columnWidths={['20%', '40%', '40%']}
      columnContentAlignments={[
        ContentAlignments.START,
        ContentAlignments.END,
        ContentAlignments.END,
      ]}
      markedIndex={bestRatioIndex !== -1 ? bestRatioIndex + 1 : -1}
      zebraType="rows"
      zebraOrder="odd"
      headerType="vertical"
    />
  );
};
