import { Button } from '@toggle/design-system';
import React from 'react';
import { useTranslation } from 'react-i18next';

import * as S from './ChartEmpty.styles';

export interface ChartEmptyProps {
  onSearchAgain?: () => void;
  assetName: string;
}

export const ChartEmpty = ({ onSearchAgain, assetName }: ChartEmptyProps) => {
  const { t } = useTranslation('chart');

  return (
    <S.ChartEmptyRoot data-testid="chart-empty">
      <S.EmptyImage alt={t('chart:empty.imgAlt')} />
      <S.Title data-testid="title">
        {t('chart:empty.title', { asset: assetName })}
      </S.Title>
      <S.Description>{t('chart:empty.description')}</S.Description>
      <Button
        data-testid="chart-empty-button"
        label={t('chart:empty.btnLabel')}
        onClick={onSearchAgain}
      />
    </S.ChartEmptyRoot>
  );
};
