import { Drawer } from '@toggle/design-system';
import styled, { css } from 'styled-components';

export const StyledDrawer = styled(Drawer)<{ $isIdle: boolean }>`
  ${Drawer.Styled.Container} {
    overflow-y: clip;

    ${props =>
      props.$isIdle
        ? css`
            max-height: none;
            min-height: 100%;
            height: auto;
          `
        : 'height:100%'}
  }

  ${Drawer.Styled.Body} {
    display: flex;
    flex-direction: column;
  }
`;
