import React, { ReactNode } from 'react';

import { ScenarioWidget } from '~/global/widgets/scenario/ScenarioWidget';
import { ArticleInsightCard } from '~/views/analyze/hooks/use-article-insight-cards/useInsightCards';

import { OrchestratorChatEntity } from '../../helpers/chat.types';
import { ChatEmptyState } from '../chat-empty-state/ChatEmptyState';
import { AoWidget } from './ao-widget/AoWidget';
import * as S from './ChatWidget.styles';
import { WhatIfWidget } from './what-if-widget/WhatIfWidget';

interface ChatWidgetProps {
  intent: string;
  entity: OrchestratorChatEntity;
  articleInsight?: ArticleInsightCard;
}

export const ChatWidget = ({
  intent,
  entity,
  articleInsight,
}: ChatWidgetProps) => {
  const widgets: Record<string, ReactNode> = {
    earnings: (
      <WhatIfWidget exchange={entity.exchange.code} ticker={entity.ticker} />
    ),
    'entity-report': <AoWidget entityTag={entity.tag} />,
    scenario: (
      <ScenarioWidget articleInsight={articleInsight} entityTag={entity.tag} />
    ),
  };

  return (
    <S.ChatWidgetRoot data-testid="chat-widget">
      {intent in widgets ? widgets[intent] : <ChatEmptyState />}
    </S.ChatWidgetRoot>
  );
};
