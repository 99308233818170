import { Spinner } from '@toggle/design-system';
import { useElemSize } from '@toggle/helpers';
import * as d3 from 'd3';
import React, { useId, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import {
  GradientArea,
  primaryGradientStops,
} from '~/shared/components/chart/gradient-area';
import { LivePriceView } from '~/shared/components/live-price-view/LivePriceView';
import { LivePriceLine } from '~/shared/components/snake-chart/components/LivePriceLine';
import { getRightAxisTicks } from '~/shared/components/snake-chart/snake-chart-utils';
import { MappedEntity } from '~/shared/hooks/use-entities';
import { useLivePrice } from '~/shared/hooks/use-live-price';
import { useWindowSize } from '~/shared/hooks/UseWindowSize';
import { useAnalyzeStore } from '~/views/analyze/store/AnalyzeStore';
import { useAoWidgetChart } from '~/views/chat/hooks/use-ao-widget-chart/useAoWidgetChart';

import * as SharedStyles from '../shared.styles';
import * as S from './AoWidgetChart.styles';

// Explainer: 90 days in 3 months - weekends (4*2*3 = 24) = 66
const ENTITY_HORIZON = 66;

export interface AoWidgetChartProps {
  entity: MappedEntity;
}

export const AoWidgetChart = ({ entity }: AoWidgetChartProps) => {
  const { t } = useTranslation('common');
  const { priceFormatter } = useAnalyzeStore();
  const { tsData, snakeMeta } = useAoWidgetChart({
    entitySnake: entity.default_snake,
    horizon: ENTITY_HORIZON,
  });
  useWindowSize();
  const svgRef = useRef<SVGSVGElement>(null);
  const { width, height } = useElemSize(svgRef);
  const [minY = 0, maxY = 0] = d3.extent(tsData.map(p => p.value));
  const uniqueId = useId();

  const xScale = d3
    .scaleLinear()
    .domain([0, tsData.length + 1])
    .range([0, width || 0]);

  const yScale = useMemo(() => {
    const yValPadding = 12;
    const extendedYDomain = [minY, maxY + yValPadding];

    return d3.scaleLinear().domain(extendedYDomain).range([height, 0]);
  }, [tsData, height]);

  const ticks = getRightAxisTicks({ yScale, tickCount: 5 });

  const priceEntities = snakeMeta?.last_value
    ? [
        {
          entity,
          lastKnownPrice: snakeMeta.last_value,
          priceBefore: snakeMeta.before_last_value,
        },
      ]
    : [];

  const livePriceState = useLivePrice(priceEntities);

  if (!tsData.length) {
    return (
      <SharedStyles.SpinnerContainer>
        <Spinner />
      </SharedStyles.SpinnerContainer>
    );
  }

  return (
    <S.ChartContainer>
      <S.LivePriceViewContainer>
        <LivePriceView
          livePriceInfo={livePriceState[entity.ticker]}
          formatPrice={priceFormatter}
          lastKnownPrice={snakeMeta?.last_value}
          lastTimestamp={snakeMeta?.last_timestamp}
          keepLayout
        />
      </S.LivePriceViewContainer>

      <S.Plot data-testid="ao-chart" ref={svgRef}>
        <clipPath id={`clip-${uniqueId}`}>
          <rect width={width} height={height} x="0" y="0" />
        </clipPath>

        <g clipPath={`url(#clip-${uniqueId})`}>
          {livePriceState[entity.ticker]?.isLive && (
            <LivePriceLine
              x1={xScale(tsData.length - 1)}
              x2={xScale(tsData.length)}
              y1={yScale(livePriceState[entity.ticker].lastKnownPrice)}
              y2={yScale(livePriceState[entity.ticker].price)}
            />
          )}
        </g>

        <S.LinePlot ts={tsData} scaleX={xScale} scaleY={yScale} />

        <GradientArea
          scaleX={xScale}
          scaleY={yScale}
          ts={tsData}
          stops={primaryGradientStops}
        />

        <g data-testid="y-axis" clipPath={`url(#clip-${uniqueId})`}>
          {ticks.labels.map(tickValue => (
            <g
              key={tickValue.label}
              transform={`translate(0, ${yScale(tickValue.index)})`}
            >
              <S.AxisText
                textAnchor="end"
                x={width - 12}
                alignmentBaseline="middle"
              >
                {tickValue.label}
              </S.AxisText>
            </g>
          ))}
        </g>
        <g clipPath={`url(#clip-${uniqueId})`}>
          <S.AxisText
            x={24}
            y={yScale(ticks.labels[0].index)}
            textAnchor="end"
            alignmentBaseline="middle"
          >
            {t('chat:threeMonthHorizon')}
          </S.AxisText>
        </g>
      </S.Plot>
    </S.ChartContainer>
  );
};
