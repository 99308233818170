import { Button } from '@toggle/design-system';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ArticleDirection } from '~/declarations/toggle-api-enums';
import { NewsItem } from '~/declarations/toggle-api-news';

import * as Shared from '../chart-article-insight-card/ChartArticleInsightCard.styles';
import * as S from './ChartNewsInsightCard.styles';

interface ChartNewsInsightCardProps {
  data: NewsItem;
}

export const ChartNewsInsightCard: FC<ChartNewsInsightCardProps> = ({
  data,
}) => {
  const { t } = useTranslation('chart');

  const isBullish = data.direction === ArticleDirection.Bullish;

  const openNews = () => {
    window.open(data.url, '_blank', 'noopener=yes,noreferrer=yes');
  };

  return (
    <Shared.Container
      $isBullish={isBullish}
      data-testid="chart-news-insight-card"
    >
      <Shared.TypeTitle>
        {t('chart:newsInsight', { context: data.direction })}
        <mark>&#8480;</mark>
      </Shared.TypeTitle>
      <Shared.Title>{data.title}</Shared.Title>
      <S.Summary>{data.summary}</S.Summary>
      <Button
        label={t('chart:readMore')}
        size="small"
        fullWidth
        onClick={openNews}
      />
    </Shared.Container>
  );
};
