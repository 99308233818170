import {
  Dropdown,
  DropdownList,
  DropdownListOption,
  Icon,
  Input,
} from '@toggle/design-system';
import styled, { css } from 'styled-components';

import {
  searchDropdownListItem,
  searchDropdownWrapper,
} from '../search/SharedSearch.styles';

export const TypedStyledDropdown = styled(Dropdown)<{
  $showBanner: boolean;
  $showSelectTradePortfolio: boolean;
}>`
  height: calc(100% - 32px);
  ${({ $showBanner }) =>
    $showBanner &&
    css`
      gap: 48px;
    `}

  ${DropdownListOption.Styled.ListOption} {
    ${searchDropdownListItem}
  }

  ${Dropdown.Styled.DropdownListContainer} {
    ${searchDropdownWrapper}
    position: relative;
    top: 0;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    z-index: unset;
  }

  ${Input.Styles.InputElement} {
    width: 100%;
  }

  ${DropdownList.Styled.DropdownListRoot} {
    display: ${props => props.$showSelectTradePortfolio && 'none'};
  }
`;

export const CloseIcon = styled(Icon)`
  margin-right: 16px;
`;
