import { format, subDays } from 'date-fns';
import pick from 'lodash/pick';
import pickBy from 'lodash/pickBy';
import shuffle from 'lodash/shuffle';
import take from 'lodash/take';
import uniq from 'lodash/uniq';

import { UserFilterResponseFilters } from '~/api/explore-filter/explore-filter-schema';
import { POJSObject } from '~/declarations/standard';
import { groupFilters, makeSlug } from '~/views/explore';
import {
  BEYOND_STOCKS_FILTER,
  COMMODITIES_FILTER,
  CRYPTO_FILTER,
  CURRENCIES_FILTER,
  EFT_FILTER,
  EQUITIES_FILTER,
  FUNDAMENTAL_FILTER,
  GEO_KEYS,
  TECHNICAL_FILTER,
} from '~/views/explore/store/filterOptions';

import { OptionWithPropertiesOnly } from '../../explore/store/use-explore-filters';
import { InvestorOptions, TradeOptions, TradeStyles } from '../store';
import {
  BEYOND_STOCKS_FILTERS,
  COMMODITIES_FILTERS,
  CRYPTO_FILTERS,
  CURRENCIES_FILTERS,
  GEOGRAPHY_OPTIONS,
  OPTIONS_FILTERS,
  STOCKS_FILTERS,
} from './onboarding-filters';

const xItems = <T extends {}>(i: T[], n: number): T[] => take(shuffle(i), n);

const EXPLORE_FILTERS_MAP: Record<TradeStyles, UserFilterResponseFilters> = {
  stocks: STOCKS_FILTERS,
  currencies: CURRENCIES_FILTERS,
  commodities: COMMODITIES_FILTERS,
  crypto: CRYPTO_FILTERS,
  options: OPTIONS_FILTERS,
  fixedIncome: BEYOND_STOCKS_FILTERS,
};

export const generateExploreFilters = (
  tradeOptions: TradeOptions
): UserFilterResponseFilters =>
  Object.entries(tradeOptions).reduce<UserFilterResponseFilters>(
    (filters, [key, booleanValue]) => {
      if (booleanValue) {
        if (key in EXPLORE_FILTERS_MAP) {
          Object.entries(EXPLORE_FILTERS_MAP[key as TradeStyles]).forEach(
            ([type, values]) => {
              if (filters[type]) {
                filters[type] = uniq(filters[type].concat(values));
              } else {
                filters[type] = values;
              }
            }
          );
        } else {
          filters.GEOGRAPHY = (filters.GEOGRAPHY || []).concat(key);
        }
      }
      return filters;
    },
    {}
  );

const FILTER_ASSET_MAP: POJSObject<OptionWithPropertiesOnly> = {
  stocks: EQUITIES_FILTER,
  currencies: CURRENCIES_FILTER,
  commodities: COMMODITIES_FILTER,
  crypto: CRYPTO_FILTER,
  options: EFT_FILTER,
  fixedIncome: BEYOND_STOCKS_FILTER,
  fundamentals: FUNDAMENTAL_FILTER,
  technical: TECHNICAL_FILTER,
  ...GEOGRAPHY_OPTIONS,
};

export const generateFilterSlug = (
  tradeOptions: TradeOptions,
  investorOptions: InvestorOptions
): string => {
  const getExploreSlug = (filters: OptionWithPropertiesOnly[]) => {
    const group = groupFilters(filters);
    return makeSlug(group);
  };

  const generateAssetFilters = (items: string[]): string => {
    const values = Object.values<OptionWithPropertiesOnly>(
      pick(FILTER_ASSET_MAP, items)
    );
    return getExploreSlug(values);
  };

  const options = Object.keys(pickBy(tradeOptions));
  const styles = Object.keys(pickBy(investorOptions));
  const assetClass = options.filter(i => !GEO_KEYS.includes(i));
  const geography = options.filter(i => GEO_KEYS.includes(i));

  const investorType = styles.length === 1 ? styles : [];

  const items = [
    ...xItems(assetClass, 1),
    ...xItems(geography, 1),
    ...xItems(investorType, 1),
  ];

  const slug = generateAssetFilters(items);
  const twoWeeksAgo = format(subDays(new Date(), 14), 'yyyyMMdd');
  return `direction=bullish&${slug}&from_date=${twoWeeksAgo}`;
};
