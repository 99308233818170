import { ScaleLinear } from 'd3-scale';

export const numZoom = (
  origin: ScaleLinear<number, number>,
  dest: ScaleLinear<number, number>
) => {
  const originalRange = origin.range();
  const destRange = origin.domain().map(dest);

  const zoom =
    originalRange[1] == originalRange[0]
      ? 1
      : (destRange[1] - destRange[0]) / (originalRange[1] - originalRange[0]);
  const start = origin.range()[0];
  const destStart = dest(origin.domain()[0]) / zoom;
  const offset = destStart - start;

  return { zoom, offset };
};
