import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
  BankCard,
  DowngradeReason,
  UserSubscription,
} from '~/api/subscription/subscription-schema';
import { appPaths } from '~/routes/app-paths';
import { SubscriptionAlertKeys } from '~/shared/hooks/use-remote-storage';
import { EditPaymentMethodModal } from '~/views/my-account/subscription/edit-payment-method-modal/EditPaymentMethodModal';
import { checkCardExpiry } from '~/views/my-account/subscription/utils/subscription-view-utils';

import { AlertCardIsExpiring } from '../alert-card-is-expiring/AlertCardIsExpiring';
import { AlertGracePeriod } from '../alert-grace-period/AlertGracePeriod';
import { AlertSubscriptionEnded } from '../alert-subscription-ended/AlertSubscriptionEnded';
import * as S from '../alert-trial-ends/AlertTrialEnds.styles';

export interface AlertPaymentMethodProps {
  card: BankCard;
  userSubscription: UserSubscription;
}

export const AlertPaymentMethod: FC<AlertPaymentMethodProps> = ({
  card,
  userSubscription,
}) => {
  const { t } = useTranslation('subscription');
  const navigate = useNavigate();
  const [isEditCardModalOpen, setIsEditCardModalOpen] = useState(false);
  const { expiryDate } = checkCardExpiry(card);

  const isInGracePeriod = !!userSubscription.grace_hours_remaining;
  const isDowngradeDueToPaymentIssues =
    userSubscription.downgrade_reason === DowngradeReason.PaymentIssue;

  const openEditCardModal = () => {
    setIsEditCardModalOpen(true);
  };

  if (isEditCardModalOpen) {
    return (
      <EditPaymentMethodModal
        isInGracePeriod={isInGracePeriod}
        onClose={() => setIsEditCardModalOpen(false)}
      />
    );
  }

  const onButtonClick = () => {
    navigate(appPaths.myAccount.subscription);
  };

  return (
    <S.AlertTrialEndsRoot>
      <AlertSubscriptionEnded
        isDowngradeDueToPaymentIssues={isDowngradeDueToPaymentIssues}
        buttonLabel={t(
          'subscription:alerts.paymentMethodSubscriptionEnded.primaryBtnLabel'
        )}
        dismissOptions={{
          storageKey: SubscriptionAlertKeys.ShowDowngradeAlert,
          shouldSetKey: isDowngradeDueToPaymentIssues,
        }}
        onButtonClick={onButtonClick}
        variant="toast"
      />
      {userSubscription.grace_hours_remaining && (
        <AlertGracePeriod
          hoursRemaining={userSubscription.grace_hours_remaining}
          openEditCardModal={openEditCardModal}
        />
      )}
      <AlertCardIsExpiring
        expiryDate={expiryDate}
        openEditCardModal={openEditCardModal}
      />
    </S.AlertTrialEndsRoot>
  );
};
