import { ChartPane, HoverEvent } from '@toggle/chart';
import { Button } from '@toggle/design-system';
import React, { FC } from 'react';

import * as S from './PaneActions.styles';

export interface PaneActionsProps {
  chartPanes: ChartPane[];
  paneDetails?: HoverEvent['paneDetails'];
  onPaneMove: (direction: 'up' | 'down', index: number) => void;
  onPaneRemove: (id: string) => void;
  onPaneCollapse: (id: string) => void;
  onPaneMaximize: (id: string) => void;
}

export const PaneActions: FC<PaneActionsProps> = ({
  chartPanes,
  onPaneMove,
  onPaneCollapse,
  onPaneMaximize,
  onPaneRemove,
  paneDetails,
}) => {
  const maximizedPaneId = chartPanes.find(p => p.maximized)?.id;

  const paneId = maximizedPaneId ?? paneDetails?.pane.id;
  const maximizeButton = paneId && (
    <Button
      onClick={() => onPaneMaximize(paneId)}
      aria-label="Maximize pane"
      variant={maximizedPaneId ? 'primary' : 'empty'}
      iconName="Expand"
      data-testid="maximize-pane"
    />
  );

  if (maximizedPaneId) {
    return (
      <S.Container data-testid="pane-actions">{maximizeButton}</S.Container>
    );
  }

  if (!paneDetails) {
    return null;
  }

  const canMoveDown = paneDetails.index < chartPanes.length - 1;
  const canMoveUp = paneDetails.index > 0;
  const canRemovePane = !paneDetails.isPrimary;
  const nonCollapsedPanes = chartPanes.filter(p => !p.collapsed);
  const canCollapse =
    nonCollapsedPanes.length > 1 ||
    nonCollapsedPanes[0].id !== paneDetails.pane.id;

  return (
    <S.Container data-testid="pane-actions">
      {canMoveUp && (
        <Button
          onClick={() => onPaneMove('up', paneDetails.index)}
          aria-label="Move pane up"
          variant="empty"
          iconName="ArrowUp"
          data-testid="move-pane-up"
        />
      )}
      {canMoveDown && (
        <Button
          onClick={() => onPaneMove('down', paneDetails.index)}
          aria-label="Move pane down"
          variant="empty"
          iconName="ArrowDown"
          data-testid="move-pane-down"
        />
      )}
      {canCollapse && (
        <Button
          onClick={() => onPaneCollapse(paneDetails.pane.id)}
          aria-label="Collapse pane"
          variant={paneDetails.pane.collapsed ? 'primary' : 'empty'}
          iconName="Compress"
          data-testid="collapse-pane"
        />
      )}
      {maximizeButton}
      {canRemovePane && (
        <Button
          onClick={() => onPaneRemove(paneDetails.pane.id)}
          aria-label="Remove pane"
          variant="empty"
          iconName="Close"
          data-testid="remove-pane"
        />
      )}
    </S.Container>
  );
};
