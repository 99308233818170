import { Button, SvgIconNames } from '@toggle/design-system';
import React from 'react';
import { useTranslation } from 'react-i18next';

import * as S from './MenuBar.styles';

interface MenuBarItem {
  id: ChartMenuBarIds;
  iconName: SvgIconNames;
}

interface MenuBarProps {
  onPrimarySearch: () => void;
  onMenuItemClick: (id: ChartMenuBarIds) => void;
  disabledItems: boolean;
}

export type ChartMenuBarIds = 'compare' | 'indicators' | 'events';

export const MenuBar = ({
  onPrimarySearch,
  onMenuItemClick,
  disabledItems,
}: MenuBarProps) => {
  const { t } = useTranslation('chart');

  const items: MenuBarItem[] = [
    {
      id: 'compare',
      iconName: 'Add',
    },
    {
      id: 'indicators',
      iconName: 'Indicators',
    },
    {
      id: 'events',
      iconName: 'Rating',
    },
  ];

  return (
    <S.MenuBarRoot data-testid="menu-bar">
      <S.MenuItemSearch>
        <Button
          data-testid="search-button"
          iconName="Search"
          label={t('chart:menuBar', { context: 'search' })}
          variant="empty"
          fullWidth
          aria-label={t('chart:menuBar', { context: 'search' })}
          onClick={onPrimarySearch}
        />
      </S.MenuItemSearch>
      {items.map(item => (
        <S.MenuItem key={item.iconName}>
          <S.StyledButton
            iconName={item.iconName}
            /* i18next-extract-mark-context-next-line ["compare", "events"] */
            label={t('chart:menuBar', { context: item.id })}
            variant="empty"
            onClick={() => onMenuItemClick(item.id)}
            disabled={disabledItems}
            data-testid={`${item.id}-button`}
          />
        </S.MenuItem>
      ))}
    </S.MenuBarRoot>
  );
};
