import { typography } from '@toggle/design-system';
import styled from 'styled-components';

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  padding: 20px 16px;
  gap: 20px;
  height: 100%;
  overflow-y: auto;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
`;

export const ExpirationWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: self-start;
`;

export const Label = styled.p`
  flex: 1;
  ${typography.emu}

  text-align: left;
  display: flex;
`;

export const Price = styled.p`
  ${typography.emu}
  padding: 8px 0px;
`;

export const Total = styled.span`
  float: right;
`;

export const InputWrapper = styled.div`
  flex: 1;
  min-width: 65%;

  input {
    width: 0px;
  }
`;

export const ToolTipWrapper = styled.span`
  margin-left: 10px;
`;

export const CurrencyIcon = styled.span`
  ${typography.emu}
  color: var(--base--40);
`;
