import { RefObject } from 'react';

export const DEFAULT_HEIGHT = 50;

const getHeaderHeightFromCss = (): number => {
  const DIGITS_REG_EX = /\d/g;
  try {
    const headerOffset = getComputedStyle(document.documentElement)
      .getPropertyValue('--app-header-height')
      .match(DIGITS_REG_EX)
      ?.join('');

    return headerOffset ? parseInt(headerOffset) : DEFAULT_HEIGHT;
  } catch {
    return DEFAULT_HEIGHT;
  }
};

export function useScrollToSection() {
  const scrollToSection = (
    sectionRef: RefObject<HTMLHeadingElement>,
    additionalOffset: number
  ) => {
    if (!sectionRef?.current) {
      return;
    }
    const currentOffset = getHeaderHeightFromCss() + additionalOffset;
    const initialTop = sectionRef.current.style.marginTop;

    sectionRef.current.style.marginTop = `-${currentOffset}px`;
    sectionRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    sectionRef.current.style.marginTop = initialTop;
  };

  return { scrollToSection };
}
