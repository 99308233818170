import { borderRadius, Chip } from '@toggle/design-system';
import styled from 'styled-components';

export const ResampleSelectorRoot = styled.div`
  display: flex;
  ${borderRadius.r2}
  padding: 4px;
  background-color: var(--surface-default);

  ul {
    width: 185px;
    max-height: 500px;
    overflow: auto;
  }

  li > div {
    margin: 8px;
  }

  li:nth-child(n + 5) {
    border-top: 1px solid var(--border-soft);
  }
`;

export const StyledChip = styled(Chip)`
  white-space: nowrap;
`;
