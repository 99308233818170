import { Entity } from '~/api/entities/entity-schema';
import { postEntities } from '~/api/entities/entity-service';

import { UserStatement } from '../fetch-user-statements/fetchUserStatements';
import { StatementWithEntity } from '../portfolio-statements/portfolioStatements';

export const getStatementsEntities = async (
  statements?: UserStatement[]
): Promise<Error | StatementWithEntity[]> => {
  if (!statements) {
    return [];
  }

  const entityNames = statements.map(s => s.security.entity);
  const uniqueEntities = entityNames
    .filter(Boolean)
    .filter((e, i, arr) => arr.indexOf(e) === i);

  if (!uniqueEntities.length) {
    return statements as StatementWithEntity[];
  }

  const entities = await postEntities({ args: uniqueEntities, filter: 2 });
  if (entities instanceof Error) {
    return entities;
  }

  const entityMapping: { [key: string]: Entity } = entities.reduce(
    (acc, ent) => {
      return Object.assign(acc, { [ent.tag]: ent });
    },
    {}
  );

  return statements.map(statement => ({
    ...statement,
    ...(statement.security.entity && {
      entity: entityMapping[statement.security.entity],
    }),
  }));
};
