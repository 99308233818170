import { BaseChartAPIProps } from '~/types/create.types';
import { getBarColor, isBarBullish } from '~/utils/timeseries/time-series';

import { DrawLineProps } from '../line/line';

export const getCandleWidth = (
  x: BaseChartAPIProps['x'],
  spaceBetweenRatio = 0.25
) => {
  const xMax = x.xScale.range()[1];
  const length = x.xScale.domain()[1] - x.xScale.domain()[0];
  return xMax / ((1 + spaceBetweenRatio) * length - spaceBetweenRatio);
};

export const drawCandles = ({
  x,
  options,
  context,
  timeseries,
  domain,
  getYCoordinate,
  tsByTime,
  primaryAsset,
}: DrawLineProps) => {
  const barWidth = getCandleWidth(x);
  const wickWidth = 1;
  const wickOffset = wickWidth / 2;
  const xMax = x.xScale.range()[1];
  const drawThickWax = barWidth >= 3;

  for (let i = Math.floor(domain[0]); i <= domain[1]; i++) {
    const idx = tsByTime.get(primaryAsset.ts[i].time);
    if (idx === undefined) {
      continue;
    }
    const bar = timeseries[idx];
    const isBullish = isBarBullish(bar);
    const color = getBarColor(isBullish, options);
    context.fillStyle = color;

    const high = getYCoordinate(bar.high);
    const low = getYCoordinate(bar.low);
    const open = getYCoordinate(bar.open);
    const close = getYCoordinate(bar.close);

    const barHigh = Math.round(Math.min(high, low));
    const barLow = Math.round(Math.max(high, low));
    const top = Math.round(Math.min(open, close));
    const bottom = Math.round(Math.max(open, close));

    const bodyCenter = Math.round(x.xScale(i));
    const left = bodyCenter - wickOffset;
    const right = left + wickWidth - 1;
    const width = right - left + 1;

    if (drawThickWax) {
      //draw wicks
      context.fillRect(left, barHigh, width, top - barHigh);
      context.fillRect(left, bottom + 1, width, barLow - bottom);
      //draw wax
      const borderLeft = bodyCenter - barWidth / 2;
      context.strokeStyle = color;
      context.beginPath();
      //it picks up the type of context from the d3 for some reason during linting rather than Canvas API typescript lib type
      //@ts-ignore
      context.roundRect(
        borderLeft,
        top,
        Math.min(xMax - borderLeft, barWidth),
        bottom - top + 1,
        1
      );
      context.fill();
      context.stroke();
    } else {
      context.fillRect(
        bodyCenter,
        barHigh,
        width,
        Math.max(1, barLow - barHigh)
      );
    }
  }
};
