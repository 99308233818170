import {
  ChartAssetData,
  intradayResamples,
  isIntradayResample,
  nonIntradayResamples,
  RangeHorizon,
  rangeToResampleMapping,
  RESAMPLE_DEFAULT,
  ResampleIntervals,
  TimeSeriesItem,
} from '@toggle/chart';
import { Entity } from '@toggle/toggle';

import { ChartSearchType, SearchAssetPayload } from '../../types/search.types';
import { createChartDataItem } from '../chart-utils/chart-utils';
import { checkIsIndicator } from '../entity-utils/entity-utils';

export const getResampleCacheKey = (
  data: ChartAssetData,
  resample: ResampleIntervals
) => `${data.entity.default_snake}_${resample}` as const;

export const allowedResamplesForHorizon = {
  [RangeHorizon.OneDay]: intradayResamples,
  [RangeHorizon.OneWeek]: [...intradayResamples, ResampleIntervals.OneDay],
  [RangeHorizon.OneMonth]: [...intradayResamples, ResampleIntervals.OneDay],
  [RangeHorizon.ThreeMonths]: nonIntradayResamples,
  [RangeHorizon.SixMonths]: nonIntradayResamples,
  [RangeHorizon.OneYear]: nonIntradayResamples,
  [RangeHorizon.ThreeYears]: nonIntradayResamples,
  [RangeHorizon.FiveYears]: nonIntradayResamples,
  [RangeHorizon.TenYears]: nonIntradayResamples,
  [RangeHorizon.Max]: nonIntradayResamples,
};

export const getResampleForHorizon = ({
  selectedResample,
  hasIndicatorInList,
  range,
  emptyResamples,
}: {
  selectedResample: ResampleIntervals;
  hasIndicatorInList: boolean;
  range: RangeHorizon;
  emptyResamples: ResampleIntervals[];
}) => {
  if (hasIndicatorInList) {
    return RESAMPLE_DEFAULT;
  }

  const resample = rangeToResampleMapping[range];
  if (!emptyResamples.includes(resample)) {
    return resample;
  }
  const isSelectedAllowed =
    allowedResamplesForHorizon[range].includes(selectedResample);

  if (range === RangeHorizon.OneDay && !isSelectedAllowed) {
    return null;
  }

  return isSelectedAllowed ? selectedResample : RESAMPLE_DEFAULT;
};

export const getAssetResample = ({
  asset,
  isIndicator,
  selectedResample,
  type,
}: {
  asset: Entity;
  isIndicator: boolean;
  selectedResample: ResampleIntervals;
  type: SearchAssetPayload['type'];
}) => {
  // if the subscribable_ticker is missing but the primary_method is price we can still call getPrices
  // but we have to call it with resolution (resample) of 1d (RESAMPLE_DEFAULT) or above
  // as it means we will not have <1d data for non websocket (subscribable) assets
  if (type === ChartSearchType.Primary) {
    return RESAMPLE_DEFAULT;
  }

  return isIndicator ||
    (!asset.subscribable_ticker && isIntradayResample(selectedResample))
    ? RESAMPLE_DEFAULT
    : selectedResample;
};

export const shouldDisable1DHorizon = ({
  chartData,
  emptyResamples,
  hasIndicatorInList,
  resample,
}: {
  chartData: ChartAssetData[];
  emptyResamples: ResampleIntervals[];
  resample: ResampleIntervals;
  hasIndicatorInList: boolean;
}) =>
  (emptyResamples.includes(ResampleIntervals.OneMinute) &&
    nonIntradayResamples.includes(resample)) ||
  hasIndicatorInList ||
  chartData.some(item => !item.entity.subscribable_ticker);

interface CreateNewResampleProps {
  unCachedChartData: ChartAssetData[];
  chartData: ChartAssetData[];
  resample: ResampleIntervals;
  prices: TimeSeriesItem[][];
  resamplesData: Record<ReturnType<typeof getResampleCacheKey>, ChartAssetData>;
}

export const createNewResampleData = ({
  unCachedChartData,
  chartData,
  resample,
  prices,
  resamplesData,
}: CreateNewResampleProps) =>
  chartData.reduce((result, item) => {
    const idx = unCachedChartData.findIndex(
      d => d.entity.default_snake === item.entity.default_snake
    );
    const data =
      idx >= 0
        ? createChartDataItem({
            entity: item.entity,
            ts: prices[idx],
            lineColor: item.lineColorOverride,
            primaryAsset: result[0],
            isHidden: item.isHidden,
            snakeMeta: item.snakeMeta,
          })
        : resamplesData[getResampleCacheKey(item, resample)];
    result.push(data);
    return result;
  }, [] as ChartAssetData[]);

export const getAssetEmptyResamples = (
  entity: Entity,
  isIndicator: boolean
) => {
  if (isIndicator) {
    return [
      ...intradayResamples,
      ResampleIntervals.OneMonth,
      ResampleIntervals.OneWeek,
    ];
  }

  return entity.subscribable_ticker ? [] : intradayResamples;
};

export const getChartDataEmptyResamples = (chartData: ChartAssetData[]) => {
  const emptyResamplesSet = new Set<ResampleIntervals>();

  chartData.forEach(data => {
    const entity = data.entity;
    const empty = getAssetEmptyResamples(entity, checkIsIndicator(entity));
    empty.forEach(item => emptyResamplesSet.add(item));
  });

  return [...emptyResamplesSet];
};
