import { SvgIconNames } from '@toggle/design-system';
import { TFunction } from 'i18next';

import { config } from '~/config';
import {
  appPaths,
  AppRouteSections,
  featurePaths,
  publicRoutes,
} from '~/routes/app-paths';
import { FeatureFlags } from '~/stores/use-feature-flags/feature-flags.types';

const {
  urls: { supportRoot, webinarLink },
} = config;

export enum MobileMenuSections {
  HELP = 'Help',
}

export enum MenuLinkTypes {
  INTERNAL,
  EXTERNAL,
}

export interface HeaderMenuItems {
  section: AppRouteSections;
  heading?: MobileMenuSections;
  label: string;
  link?: string;
  gaName: string;
  linkType?: MenuLinkTypes;
  iconName?: SvgIconNames;
  onClick?: () => void;
  requiredFeatureFlag?: string;
  hiddenOnMobile?: boolean;
}

export const getMenuData = (t: TFunction): HeaderMenuItems[] => [
  {
    section: AppRouteSections.MyToggle,
    label: t('header:menu.myToggle'),
    link: appPaths.myToggle,
    gaName: 'My Toggle',
  },
  {
    section: AppRouteSections.Portfolio,
    label: t('header:menu.portfolio'),
    link: appPaths.portfolio,
    gaName: 'Portfolio',
  },
  {
    section: AppRouteSections.Explore,
    label: t('header:menu.explore'),
    link: appPaths.explore,
    gaName: 'Explore',
  },
  {
    section: AppRouteSections.GenAI,
    label: t('header:menu.chat'),
    gaName: 'Chat',
    requiredFeatureFlag: FeatureFlags.ENABLE_CHAT_REALZ,
    link: appPaths.aiChat,
  },
  {
    section: AppRouteSections.TurboChart,
    label: t('header:menu.chart'),
    gaName: 'Chart',
    requiredFeatureFlag: FeatureFlags.ENABLE_CHART_V2,
    link: featurePaths.turboChart,
    hiddenOnMobile: true,
  },
];

export const getMobileMenuData = (t: TFunction): HeaderMenuItems[] => [
  ...getMenuData(t),
  {
    section: AppRouteSections.Support,
    heading: MobileMenuSections.HELP,
    label: t('header:secondaryMenu.learningCenter'),
    link: publicRoutes.learnCenter,
    linkType: MenuLinkTypes.EXTERNAL,
    iconName: 'ExternalLink',
    gaName: 'Learning Center',
  },
  {
    section: AppRouteSections.Support,
    label: t('header:secondaryMenu.joinOurWebinar'),
    link: webinarLink,
    linkType: MenuLinkTypes.EXTERNAL,
    gaName: 'Join our webinar',
  },
  {
    section: AppRouteSections.Support,
    label: t('header:secondaryMenu.contactUs'),
    link: supportRoot,
    linkType: MenuLinkTypes.EXTERNAL,
    gaName: 'Contact Us',
  },
];
