export type DropdownArrowPressVariant =
  | 'onlyLoopInList'
  | 'onlyLoopEnd'
  | 'loopWithOutsideElement';

export const getArrowUpSuggestion = <T>(
  current: number,
  dropdownItems: T[],
  arrowPressVariant: DropdownArrowPressVariant
) => {
  const map: Record<DropdownArrowPressVariant, number> = {
    loopWithOutsideElement:
      current === -1 ? dropdownItems.length - 1 : current - 1,
    onlyLoopInList: current <= 0 ? dropdownItems.length - 1 : current - 1,
    onlyLoopEnd: current <= 0 ? -1 : current - 1,
  };

  return map[arrowPressVariant];
};

export const getArrowDownSuggestion = <T>(
  current: number,
  dropdownItems: T[],
  arrowPressVariant: DropdownArrowPressVariant
) => {
  if (arrowPressVariant === 'onlyLoopInList') {
    return current === dropdownItems.length - 1 ? 0 : current + 1;
  }

  return current === dropdownItems.length - 1 ? -1 : current + 1;
};
