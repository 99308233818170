import { AlertType, Button } from '@toggle/design-system';
import { formatPriceWithCurrency } from '@toggle/helpers';
import React, { FC, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { UserRole } from '~/api/subscription/subscription-schema';
import UpgradeToCopilot from '~/assets/core/upgrade-to-copilot.svg';
import UpgradeToPro from '~/assets/core/upgrade-to-pro.svg';
import { config } from '~/config';
import {
  accountRoutes,
  appPaths,
  SubscriptionUpgradeRouteParams,
} from '~/routes/app-paths';
import { useMediaQuery } from '~/shared/hooks/MediaQueryHook';
import { MediaQueries } from '~/shared/hooks/MediaQueryList';
import { useArticleLimit } from '~/stores/use-article-limit/useArticleLimit';
import { useSubscription } from '~/stores/use-subscription/useSubscription';
import { getYearlyPricePerMonth } from '~/views/my-account/subscription/utils/subscription-view-utils';

import * as S from './UpgradeNotice.styles';

const {
  ib: { ibLogin },
  urls: { supportNewTicket },
} = config;

export const UpgradeNotice: FC = () => {
  const [isBannerVisible, setIsBannerVisible] = useState(true);
  const { t } = useTranslation('subscription');
  const currentMedia = useMediaQuery(s => s.currentMedia);
  const navigate = useNavigate();
  const location = useLocation();
  const articlesLimits = useArticleLimit(state => state.data);
  const { getSubscriptionByRole, isCopilot, isBasic, isFromIb } =
    useSubscription(state => ({
      getSubscriptionByRole: state.getSubscriptionByRole,
      isBasic: state.isBasic(),
      isCopilot: state.isCopilot(),
      isFromIb: state.isFromIb(),
    }));

  const upgradeUserRole = isCopilot ? UserRole.Pro : UserRole.Copilot;
  const subscriptionByRole = getSubscriptionByRole(upgradeUserRole);

  const isMobileAndTablet = [
    MediaQueries.SMALL_MOBILE,
    MediaQueries.MOBILE,
    MediaQueries.TABLET,
  ].includes(currentMedia);

  const hideBanner = () => {
    setIsBannerVisible(false);
  };

  const bulletListItems = [
    'subscription:bulletTli',
    'subscription:item4',
    'subscription:item1',
    'subscription:item7',
    'subscription:item5',
    'subscription:item6',
  ];

  const handleClick = () => {
    isFromIb
      ? window.location.replace(ibLogin)
      : navigate(
          `${appPaths.myAccount.subscription}/${accountRoutes.selectPlan}`
        );
  };

  const yearlyPricePerMonth = getYearlyPricePerMonth(subscriptionByRole);
  const perMonthLabel = t('subscription:plan.perMonth', {
    price: formatPriceWithCurrency(yearlyPricePerMonth),
  });

  const bannerId =
    location.state ||
    (isBasic && articlesLimits && SubscriptionUpgradeRouteParams.BASIC_LIMIT);

  const bannerMessage =
    bannerId &&
    t(`subscription:noticeBannerContent.item${bannerId}.content`, {
      limit: articlesLimits?.limit,
      consumed: articlesLimits?.consumed,
    });

  const bannerTitle =
    bannerId && t(`subscription:noticeBannerContent.item${bannerId}.title`);

  return (
    <S.SectionContainer data-testid="upgrade-notice">
      <S.BannerContainer>
        {isBannerVisible && bannerId && (
          <S.Banner
            title={bannerTitle || bannerMessage}
            message={bannerTitle ? bannerMessage : ''}
            type={AlertType.Info}
            onClose={hideBanner}
          />
        )}
      </S.BannerContainer>
      {!isMobileAndTablet && (
        <S.Image>
          {isCopilot ? (
            <UpgradeToPro
              data-testid="upgrade-to-pro-svg"
              alt="Upgrade To Pro"
            />
          ) : (
            <UpgradeToCopilot alt="Upgrade To Copilot" />
          )}
        </S.Image>
      )}
      {subscriptionByRole ? (
        <S.Title>
          <S.TitleText>
            {isCopilot ? t('subscription:title3') : t('subscription:title')}
            <S.TitleDetails>{perMonthLabel}</S.TitleDetails>
          </S.TitleText>
        </S.Title>
      ) : (
        <S.Title>
          <S.TitleText>
            {t('subscription:upgrade', {
              context: isCopilot ? UserRole.Pro : UserRole.Copilot,
            })}
          </S.TitleText>
        </S.Title>
      )}

      <S.TextDetails>
        <S.ListTitle>{t('subscription:youGet')}:</S.ListTitle>
        <S.List>
          {bulletListItems.map(listItem => (
            <S.ListItem key={listItem}>{t(listItem)}</S.ListItem>
          ))}
        </S.List>
        <S.ButtonWrap>
          <Button
            data-testid="subscription-upgrade-now-btn"
            label={t('subscription:button')}
            onClick={handleClick}
            fullWidth
          />
        </S.ButtonWrap>

        <S.ContactText>
          <Trans
            components={[
              <strong key="strong" />,
              <S.ContactTextLink
                key="link"
                href={supportNewTicket}
                target="_blank"
                rel="noopener noreferrer"
              />,
            ]}
            i18nKey={
              isCopilot
                ? 'subscription:buttonHelpSupport'
                : 'subscription:buttonHelp'
            }
            t={t}
          />
        </S.ContactText>
      </S.TextDetails>
    </S.SectionContainer>
  );
};
