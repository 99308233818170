import { deviceConstraints, typography } from '@toggle/design-system';
import styled from 'styled-components';

import { Markdown } from '~/shared/components/markdown/Markdown';

import { ImageOverlay } from '../image-overlay';

export const OverviewSection = styled.section`
  p {
    ${typography.dingo}

    @media ${deviceConstraints.smartphone} {
      padding-left: 16px;
    }
    @media ${deviceConstraints.smartphoneOrTablet} {
      padding-left: 16px;
    }

    @media ${deviceConstraints.tablet} {
      padding-left: 0;
    }
  }
`;

export const ImageSection = styled(ImageOverlay)`
  width: 100%;
  margin: 16px 0;
`;

export const Image = styled.img`
  width: 100%;
`;

export const Title = styled.h1`
  color: var(--base--0);
  padding-bottom: 8px;

  @media ${deviceConstraints.smartphone} {
    padding-left: 16px;
    padding-top: 24px;
    ${typography.chimp}
  }

  @media ${deviceConstraints.smartphoneOrTablet} {
    padding-left: 16px;
    padding-top: 24px;
    ${typography.chimp}
  }

  @media ${deviceConstraints.tablet} {
    padding-left: 0;
    padding-top: 32px;
  }

  @media ${deviceConstraints.desktopSmall} {
    padding-top: 0;
    ${typography.tiger}
  }
`;

export const Details = styled.p`
  color: var(--base--35);
  padding-bottom: 32px;

  @media ${deviceConstraints.smartphone} {
    padding-left: 16px;
  }
  @media ${deviceConstraints.smartphoneOrTablet} {
    padding-left: 16px;
  }

  @media ${deviceConstraints.tablet} {
    padding-left: 0;
  }
`;

export const Author = styled.span`
  padding-right: 16px;
`;

export const MdContainer = styled(Markdown)`
  strong {
    color: var(--primary-color);
  }
`;
