import { customScrollMinimal } from '@toggle/design-system';
import { css } from 'styled-components';

export const searchDropdownWrapper = css`
  ${customScrollMinimal}
  max-height: 402px;
  border: none;
  overflow-y: auto;
  overflow-x: hidden;
  position: absolute;
  top: 100%;
  width: 100%;
  background-color: var(--base--100);
`;

export const searchDropdownListItem = css`
  height: 44px;
  line-height: 43px;
  padding-right: 4px;
`;
