import * as d3 from 'd3';
import React, { FC, useEffect, useRef } from 'react';

import { maybeAnimated } from '../snake-chart-utils';
import * as S from './LivePriceLine.styles';

interface LivePriceLineProps {
  x1: number;
  x2: number;
  y1: number;
  y2: number;
  transition?: d3.Transition<SVGSVGElement, unknown, null, undefined>;
}

export const LivePriceLine: FC<LivePriceLineProps> = ({
  x1,
  x2,
  y1,
  y2,
  transition,
}) => {
  const lineRef = useRef<SVGLineElement>(null);

  useEffect(() => {
    if (lineRef.current) {
      const line = d3.select(lineRef.current);
      maybeAnimated(
        line,
        transition
      )(selection => {
        selection.attr('x1', x1).attr('x2', x2).attr('y1', y1).attr('y2', y2);
      });
    }
  }, [x1, x2, y1, y2, transition]);

  return (
    <S.PriceLine
      data-testid="live-price-line"
      ref={lineRef}
      stroke="currentColor"
    />
  );
};
