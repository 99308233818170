import styled, { css } from 'styled-components';

import { typographyV2 } from '~/design-tokens/typography/Typography.styles';

export const BarChartRoot = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  margin-bottom: 12px;
`;

export const Title = styled.span<{ $showUnderline: boolean }>`
  ${typographyV2.titleBase};

  ${props =>
    props.$showUnderline &&
    css`
      display: inline-block;
      text-decoration: underline dashed;
      text-underline-offset: 6px;
      text-decoration-thickness: 1px;
      text-decoration-color: var(--border-default);
    `}
`;

export const AxisLine = styled.line<{ $highlight: boolean }>`
  stroke-width: 1px;
  stroke: var(--border-soft);

  ${props =>
    props.$highlight &&
    css`
      stroke: var(--border-default);
    `}
`;

export const AxisText = styled.text<{ $highlight?: boolean }>`
  color: var(--text-soft);
  font-size: 12px;
  fill: var(--text-soft);

  ${props =>
    props.$highlight &&
    css`
      fill: var(--text-default);
    `}
`;

export const LabelAxisY = styled(AxisText)`
  font-family: var(--font-roboto-mono);
`;

export const LabelAxisX = styled(AxisText)`
  transform: translateY(-8px);
`;
