import { customScrollMinimal, typography } from '@toggle/design-system';
import styled, { css } from 'styled-components';

export type HeaderType = 'vertical' | 'horizontal';
export type BorderType = 'Bottom' | 'Right';

export const StyledTable = styled.div`
  ${customScrollMinimal}
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

export const RowCell = styled.div<{
  borderType: BorderType;
  headerType?: HeaderType;
}>`
  ${typography.emu};
  display: flex;
  flex: 1;
  align-items: center;
  padding: 13px 16px 11px;
  border: 0 solid var(--base--80);
  background-color: var(--base--95);

  ${({ headerType }) => setHeaderStyles(headerType)};
  ${({ borderType }) => setBorderStyles(borderType)};

  &.zebraCell {
    background-color: var(--base--100);
  }
`;

export const HeaderCell = styled(RowCell)``;

export const Row = styled.div<{ isHovered: boolean }>`
  display: flex;
  width: 100%;

  ${({ isHovered }) =>
    isHovered &&
    css`
      ${RowCell} {
        background-color: var(--input-hover);
      }
    `};

  // last cell in row
  & > ${RowCell}:last-child {
    border-right-width: 0;
  }

  // cells in last row
  &:last-child ${RowCell} {
    border-bottom-width: 0;
  }
`;

const setHeaderStyles = (headerType?: HeaderType) => {
  switch (headerType) {
    case 'horizontal':
      return css`
        text-transform: capitalize;
        font-weight: 600;
        border-bottom-width: 2px;
      `;
    case 'vertical':
      return css`
        text-transform: capitalize;
        font-weight: 600;
        border-right-width: 2px;
      `;
    default:
      return '';
  }
};

const setBorderStyles = (borderType?: BorderType) => {
  switch (borderType) {
    case 'Bottom':
      return css`
        border-bottom-width: 1px;
      `;
    case 'Right':
      return css`
        border-right-width: 1px;
      `;
    default:
      return '';
  }
};
