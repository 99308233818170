import { SortDirection } from '@toggle/design-system';
import orderBy from 'lodash/orderBy';

import { PastEpisode } from '~/api/scenario-commander/scenario-commander-schema';

export const sortDateData = (
  sort: SortDirection,
  pastEpisodes: PastEpisode[]
): PastEpisode[] => {
  return orderBy(
    pastEpisodes,
    e => new Date(e.date),
    sort === SortDirection.asc ? 'asc' : 'desc'
  );
};

export const sortNumberData = (
  sort: SortDirection,
  pastEpisodes: PastEpisode[]
): PastEpisode[] => {
  return pastEpisodes.sort((a, b) => {
    const firstValue = a.data.length;
    const nextValue = b.data.length;

    return sort === SortDirection.asc
      ? firstValue - nextValue
      : nextValue - firstValue;
  });
};
