import mapValues from 'lodash/mapValues';
import { StoreApi } from 'zustand';

import { ENDPOINT_URLS } from '~/global/toggle-api';
import { APIFetch } from '~/shared/services/api-fetch/APIFetch';
import { HttpMethods } from '~/shared/services/api-fetch/constants';
import { create } from '~/stores/create-store/createStore';
import { useFeatureFlags } from '~/stores/use-feature-flags/useFeatureFlags';

import { STORAGE_ITEMS, STORAGE_KEYS } from './storage-keys';
import { mapItemsToFeatureFlags } from './utils/remote-storage-utils';

type Actions = {
  storeItems: (items: STORAGE_ITEMS) => Promise<void>;
  deleteItem: (item: STORAGE_KEYS) => Promise<void>;
  initialize: () => Promise<void>;
};

type State = {
  items?: STORAGE_ITEMS;
  error?: Error;
  loading: boolean;
};

type UseRemoteStorageValue = Actions & State;

const parseItem = (item: string) => {
  try {
    return JSON.parse(item);
  } catch {
    return item;
  }
};

const stringifyObject = (item: object | string | boolean) => {
  if (typeof item === 'object' && item !== null) {
    return JSON.stringify(item);
  }
  return item;
};

const initialize = async (set: StoreApi<UseRemoteStorageValue>['setState']) => {
  try {
    const items = await APIFetch<STORAGE_ITEMS>(ENDPOINT_URLS.REMOTE_STORAGE);
    if (items instanceof Error) {
      throw items;
    }
    const parsedItems = mapValues(items, parseItem);

    const featureFlags = mapItemsToFeatureFlags(parsedItems);
    if (featureFlags) {
      useFeatureFlags.getState().addFlags(featureFlags);
    }

    set({ items: parsedItems, loading: false });
  } catch (error) {
    set({ error: error as Error, loading: false });
  }
};

const storeItems = async (
  items: STORAGE_ITEMS,
  set: StoreApi<UseRemoteStorageValue>['setState']
) => {
  set({ loading: true });
  const stringifyItem = mapValues(items, stringifyObject);
  try {
    const store = await APIFetch<STORAGE_ITEMS>(ENDPOINT_URLS.REMOTE_STORAGE, {
      method: HttpMethods.Put,
      body: JSON.stringify(stringifyItem),
    });
    if (store instanceof Error) {
      throw store;
    }
    set(state => ({ items: { ...state.items, ...items }, loading: false }));
  } catch (error) {
    set({ error: error as Error, loading: false });
  }
};

const deleteItem = async (
  key: STORAGE_KEYS,
  set: StoreApi<UseRemoteStorageValue>['setState']
) => {
  set({ loading: true });
  try {
    const deleteItem = await APIFetch<STORAGE_ITEMS>(
      `${ENDPOINT_URLS.REMOTE_STORAGE}/${key}`,
      {
        method: HttpMethods.Delete,
      }
    );

    if (deleteItem instanceof Error) {
      throw deleteItem;
    }

    const filterItem = (storedItems: STORAGE_ITEMS | undefined) => {
      if (storedItems) {
        const { [key]: _, ...rest } = storedItems;
        return rest;
      }
      return undefined;
    };
    set(state => ({ items: filterItem(state.items), loading: false }));
  } catch (error) {
    set({ error: error as Error, loading: false });
  }
};

const initialState: State = {
  items: undefined,
  error: undefined,
  loading: true,
};

export const useRemoteStorage = create<UseRemoteStorageValue>(set => ({
  ...initialState,
  storeItems: async items => storeItems(items, set),
  deleteItem: async item => deleteItem(item, set),
  initialize: async () => initialize(set),
}));
