import { deviceConstraints, typography } from '@toggle/design-system';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { PasswordInput } from '~/shared/components/forms/password-input/PasswordInput';

import * as AuthSharedStyles from '../auth-shared-styles/AuthSharedStyles.styles';

const wrapperCss = css`
  padding: 40px;
  @media ${deviceConstraints.smartphone} {
    background: transparent;
    padding: 14px;
  }
`;

export const ErrorContainer = styled.div`
  margin-top: 38px;
  border-radius: 4px;
`;

export const ErrorView = styled.section`
  display: flex;
  flex-direction: column;
`;

export const BackToLoginLink = styled(Link)`
  margin-top: 20px;
  align-self: center;
`;

export const NoLongerValidWrapper = styled(AuthSharedStyles.WrapperWithBg)`
  ${wrapperCss}
`;

export const Form = styled.form`
  ${AuthSharedStyles.wrapperCss};
  ${wrapperCss};
`;

export const ErrorTitle = styled.h3`
  margin-bottom: 32px;
  color: var(--failure--default);
  ${typography.emu};
`;

export const StyledPasswordInput = styled(PasswordInput)`
  margin-bottom: 20px;
`;
