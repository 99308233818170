import { decimalCount } from '@toggle/helpers';
import { times } from 'number-precision';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { formatAssetPrice } from '~/shared/utils/currency/currency';

import { QuantityInput } from '../../quantity-input/QuantityInput';
import * as S from './LinkedInput.styles';

export interface LinkedInputValue {
  quantity: string;
  totalPrice: string;
}

export interface LinkedInputProps {
  marketPrice: number;
  currencySymbol: string;
  currency: string;
  onChange: (amount: { price: string; quantity: string }) => void;
  defaultValue: LinkedInputValue;
  priceDecimals: number;
  tradingProviderLabel: string;
}

export const LinkedInput: FC<LinkedInputProps> = ({
  marketPrice,
  currencySymbol,
  currency,
  onChange,
  defaultValue,
  priceDecimals,
  tradingProviderLabel,
}) => {
  const { t } = useTranslation('portfolio');
  const [values, setValues] = useState<LinkedInputValue>(defaultValue);
  const [active, setActive] = useState(false);

  const convert = (quantity: string) => {
    const totalPrice = quantity
      ? times(parseFloat(quantity), marketPrice).toFixed(2)
      : quantity;

    return {
      quantity,
      price: formatAssetPrice(marketPrice),
      totalPrice,
    };
  };

  const handleChange = (value: string) => {
    const { totalPrice, price, quantity } = convert(value);
    onChange({ price, quantity });
    setValues({ totalPrice, quantity });
  };

  useEffect(() => {
    if (defaultValue.quantity) {
      const { totalPrice, quantity } = convert(defaultValue.quantity);
      setValues({ totalPrice, quantity });
    }
  }, [defaultValue.quantity, marketPrice]);

  return (
    <S.Container data-testid="linked-input">
      <S.Labels>
        <S.Label>{t('portfolio:quantity')}</S.Label>
        <S.Label>{currency}</S.Label>
      </S.Labels>
      <S.IconWrapper $active={active} data-testid="linked-icon-wrapper">
        <S.LinkIcon iconName="Chain" size={16} />
      </S.IconWrapper>
      <S.Inputs>
        <QuantityInput
          value={values.quantity}
          onChange={handleChange}
          onFocus={() => setActive(true)}
          onBlur={() => setActive(false)}
          isDecimalExceeded={decimalCount(values.quantity) > priceDecimals}
          tradingProviderLabel={tradingProviderLabel}
        />
        <S.InputTooltip
          label={t('portfolio:ibMarketOrderQuantity')}
          disabled={!!values.quantity}
          placement="bottom"
        >
          <S.CurrencyInput
            leftIcon={<S.CurrencyIcon>{currencySymbol}</S.CurrencyIcon>}
            type="number"
            name="price"
            rightAlignText
            value={values.totalPrice}
            readOnly
            onFocus={() => setActive(true)}
            onBlur={() => setActive(false)}
            data-testid="linked-input-price"
          />
        </S.InputTooltip>
      </S.Inputs>
    </S.Container>
  );
};
