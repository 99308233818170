import { deviceConstraints, typography } from '@toggle/design-system';
import styled from 'styled-components';

export const AutocompletePopup = styled.div`
  background-color: var(--base--90);
  box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px, rgba(0, 0, 0, 0.3) 0px 0px 1px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  margin-top: 4px;
  right: 16px;
  z-index: 1;
  padding: 16px;
  @media ${deviceConstraints.tablet} {
    flex-direction: row;
    padding: 10px 20px 10px 8px;
  }
`;

export const AutocompletePopupDivider = styled.div`
  width: 1px;
  height: 12px;
  margin: 0px 10px;
  @media ${deviceConstraints.tablet} {
    background: var(--base--70);
  }
`;

export const AutocompletePopupText = styled.a`
  ${typography.koala}
  cursor: pointer;
  word-wrap: normal;
  white-space: nowrap;
  padding: 12.1px;
  @media ${deviceConstraints.tablet} {
    padding: 0px;
  }
`;

export type PillType = 'live' | 'delayed' | 'close';

const pillTypeColor: Record<PillType, string> = {
  live: 'var(--success--default)',
  delayed: 'var(--warning--default)',
  close: 'var(--base--5)',
};

export const Dot = styled.span<{ type: PillType }>`
  display: inline-block;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  margin: 0px 8px 1px 0px;
  background-color: ${p => pillTypeColor[p.type]};
`;

export const AutocompletePill = styled.div<{ type: PillType }>`
  background-color: var(--base--85);
  ${typography.quokka}
  cursor: pointer;
  word-wrap: normal;
  white-space: nowrap;
  border-radius: 100px;
  padding: 2px 8px;
  margin-bottom: 8px;
  color: ${p => pillTypeColor[p.type]};
  width: 100%;
  @media ${deviceConstraints.tablet} {
    width: auto;
    margin-right: 16px;
    margin-bottom: auto;
  }
`;
