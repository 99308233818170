import { Alert, borderRadius, typographyV2 } from '@toggle/design-system';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledAlert = styled(Alert)`
  margin-bottom: 20px;
`;

export const HelperText = styled.p`
  color: var(--text-soft);
  margin-top: 20px;
  ${typographyV2.bodyXs}
`;

export const CardDetails = styled.div`
  display: flex;
  padding: 8px 12px;
  justify-content: space-between;
  align-items: center;
  border: 1px solid var(--border-default);
  ${borderRadius.r1};
  color: var(--text-disabled);
`;

export const LeftPart = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const RightPart = styled.div`
  display: flex;
  gap: 20px;
`;

export const AlertTitle = styled.p`
  ${typographyV2.titleSm};
`;

export const Label = styled.label`
  display: inline-flex;
  ${typographyV2.bodySm};
  margin-bottom: 4px;
`;
