import { deviceConstraints } from '@toggle/design-system';
import styled from 'styled-components';

export const Wrapper = styled.div`
  position: absolute;
  right: 0;
  top: 44px;
  z-index: 1;
  width: 140px;
  border: 1px solid var(--base--80);
  border-radius: 4px;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.3), 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  background-color: var(--base--100);

  @media ${deviceConstraints.desktopSmall} {
    right: 12px;
    top: 30px;
  }
`;

export const Text = styled.p`
  font-size: 16px;
  padding: 16px;
  cursor: pointer;
  color: var(--base--0);

  &:hover {
    background-color: var(--list-item-hover-bg);
  }
`;

export const Red = styled(Text)`
  color: var(--failure--default);
`;
