import { drawXAxisMark } from '~/core/axis/x-axis/drawXAxis';
import { PriceDisplay } from '~/types/axis.types';
import { BaseChartAPIProps, YAxis } from '~/types/create.types';

import { formatXAxisPoint } from '../../utils/dates';
import { drawYAxisMark } from '../axis/y-axis/drawYAxis';

export interface DrawCrosshairProps extends BaseChartAPIProps {
  x1: number;
  hoveredPoints: {
    value: number;
    circleColor: string;
    y: YAxis;
    yCoord: number;
    priceDisplay: PriceDisplay;
    shouldDrawCircle: boolean;
    yAxisSize: BaseChartAPIProps['yAxisSizes'][0];
  }[];
  date: Date;
}

export const drawCrosshair = ({
  x1,
  options,
  context,
  hoveredPoints,
  resampleInterval,
  date,
}: DrawCrosshairProps) => {
  const dashWidth = 6;
  const dashOffset = 6;

  context.save();
  context.lineWidth = 1;
  context.strokeStyle = options.colors.border.hover;

  //draw vertical/horizontal lines
  context.setLineDash([dashWidth, dashOffset]);
  context.beginPath();
  context.moveTo(x1, 0);
  context.lineTo(x1, options.height - options.gutters.x);

  hoveredPoints.forEach(point => {
    const yCoord = point.yCoord;
    context.moveTo(0, yCoord);
    context.lineTo(options.width - options.gutters.y, yCoord);
  });

  context.stroke();
  context.closePath();
  context.setLineDash([0, 0]);

  hoveredPoints.forEach(point => {
    if (point.shouldDrawCircle) {
      context.strokeStyle = options.colors.typography.highlight;
      context.fillStyle = point.circleColor;
      context.beginPath();
      context.arc(x1, point.yCoord, 3, 0, 2 * Math.PI);
      context.fill();
      context.stroke();

      drawYAxisMark({
        context,
        options,
        ...point,
      });
    }
  });

  drawXAxisMark({
    context,
    options,
    x: x1,
    y: options.height - options.gutters.x + 1,
    label: formatXAxisPoint(date, resampleInterval),
  });

  context.restore();
};
