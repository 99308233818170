import React, { FC } from 'react';
import { CSSTransition } from 'react-transition-group';

import { useTransaction } from '~/global/trading-drawer/use-transaction/useTransaction';

import { TradingDrawerContent } from './trading-drawer-content/TradingDrawerContent';

export const TradingDrawer: FC = () => {
  const isViewOpen = useTransaction(state => state.isViewOpen);

  return (
    <CSSTransition
      in={isViewOpen}
      timeout={300}
      mountOnEnter
      unmountOnExit={!isViewOpen}
    >
      <TradingDrawerContent />
    </CSSTransition>
  );
};
