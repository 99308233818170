import {
  ANALYZE_SECTION_IDS,
  EXPLORE_SECTION_IDS,
  INSIGHT_SECTION_IDS,
  MY_TOGGLE_SECTION_IDS,
  ONBOARDING_SECTION_IDS,
} from '~/views/helper/HelperConfig';

export enum ONBOARDING_KEYS {
  ONBOARDING_TRADE_PREFERENCE = 'ONBOARDING_TRADE_PREFERENCE',
  ONBOARDING_INVESTMENT_STYLE = 'ONBOARDING_INVESTMENT_STYLE',
  ONBOARDING_NAVIGATION_OPTION = 'ONBOARDING_NAVIGATION_OPTION',
  ONBOARDING_TOS_ACCEPTANCE = 'ONBOARDING_TOS_ACCEPTANCE',
  ONBOARDING_PP_ACCEPTANCE = 'ONBOARDING_PP_ACCEPTANCE',
  ONBOARDING_INITIAL_EXPLORE_FILTERS = 'ONBOARDING_INITIAL_EXPLORE_FILTERS',
  ONBOARDING_TRADE_OPTIONS = 'ONBOARDING_TRADE_OPTIONS',
  ONBOARDING_INVESTOR_STYLE = 'ONBOARDING_INVESTOR_STYLE',
  ONBOARDING_INVESTOR_ACTION = 'ONBOARDING_INVESTOR_ACTION',
  ONBOARDING_COMPLETE = 'ONBOARDING_COMPLETE',
  ONBOARDING_SKIPPED = 'ONBOARDING_SKIPPED',
}

export enum POP_UP_KEYS {
  EXPLORE_BANNER_NO_REPEAT = 'EXPLORE_BANNER_NO_REPEAT',
  MY_TOGGLE_BANNER_NO_REPEAT = 'MY_TOGGLE_BANNER_NO_REPEAT',
  PORTFOLIO_CONNECTION_BANNER_NO_REPEAT = 'PORTFOLIO_CONNECTION_BANNER_NO_REPEAT',
}

export enum SubscriptionAlertKeys {
  WasTrial = 'TRIAL_STARTED',
  ShowDowngradeAlert = 'SHOW_DOWNGRADE_ALERT',
  SubscriptionEndedInlineAlertDismissed = 'SUBSCRIPTION_ENDED_INLINE_ALERT_DISMISSED',
}

export const AO_SELECTED_HORIZON = 'AO_SELECTED_HORIZON';
export const THEME_NAME_STORAGE_KEY = 'THEME_NAME';
export const CHART_WIDGET_RECENTLY_OPENED_ENTITIES =
  'CHART_WIDGET_RECENTLY_OPENED_ENTITIES';

export type STORAGE_KEYS =
  | ONBOARDING_KEYS
  | EXPLORE_SECTION_IDS
  | INSIGHT_SECTION_IDS
  | MY_TOGGLE_SECTION_IDS
  | ANALYZE_SECTION_IDS
  | ONBOARDING_SECTION_IDS
  | POP_UP_KEYS
  | SubscriptionAlertKeys
  | typeof AO_SELECTED_HORIZON
  | typeof THEME_NAME_STORAGE_KEY
  | typeof CHART_WIDGET_RECENTLY_OPENED_ENTITIES
  | 'feature_flags';

export type STORAGE_ITEMS = Partial<
  Record<STORAGE_KEYS, string | string[] | object | boolean>
>;
