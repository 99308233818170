import { SchemaValidationError } from '@toggle/helpers';
import { Reducer, useEffect, useReducer } from 'react';

import { ApiFetchResponse } from '~/shared/services/api-fetch/APIFetch';
import { Tracking } from '~/shared/services/tracking';

import {
  Action,
  ActionTypes,
  apiReducer,
  APIState,
  DEFAULT_OPTIONS,
  DefaultOptionsWithoutInitState,
  HookState,
  InitialData,
  Options,
  OptionsWithInitState,
  OptionsWithMapFnOnly,
  PartialOptionsWithInitState,
} from './useAPI-types';

type ReqFn<T> = (abort?: AbortSignal) => Promise<T>;

export function useAPI<D>(
  reqFn: ReqFn<D | Error>,
  options?: DefaultOptionsWithoutInitState
): HookState<D | InitialData>;

export function useAPI<D>(
  reqFn: ReqFn<D | Error>,
  options?: OptionsWithInitState<D>
): HookState<D>;

export function useAPI<D, M>(
  reqFn: ReqFn<D | Error>,
  options?: OptionsWithMapFnOnly<D, M>
): HookState<M | InitialData>;

export function useAPI<D, I, M = D>(
  reqFn: ReqFn<D | Error>,
  options?: PartialOptionsWithInitState<D, I, M>
): HookState<M | I>;

export function useAPI<D, M>(
  reqFn: ReqFn<D | Error>,
  options: Options<D, M> = {}
): HookState<D | M | InitialData> {
  type Data = D | M | InitialData;
  const mergedOptions = { ...DEFAULT_OPTIONS, ...options };

  const [state, dispatch] = useReducer<
    Reducer<APIState<Data>, Action<Data, Error>>
  >(apiReducer, mergedOptions.initialState);

  const resetState = () => {
    dispatch({
      type: ActionTypes.Reset,
      payload: mergedOptions.initialState.data,
    });
  };

  const fetchData = async (abort?: AbortSignal) => {
    dispatch({ type: ActionTypes.Loading });

    try {
      let data: ApiFetchResponse<D> | M = await reqFn(abort);
      if (abort?.aborted) {
        return;
      }
      if (data instanceof Error) {
        throw data;
      }

      if (options.mapDataFn) {
        data = options.mapDataFn(data, state.data);
      }

      dispatch({ type: ActionTypes.Success, payload: data });
    } catch (e) {
      if (abort?.aborted) {
        dispatch({
          type: ActionTypes.Reset,
          payload: mergedOptions.initialState.data,
        });
        return;
      }
      if (!(e instanceof SchemaValidationError)) {
        Tracking.captureException(e as Error);
      }
      dispatch({ type: ActionTypes.Error, payload: e as Error });
    }
  };

  useEffect(() => {
    const abort = new AbortController();
    if (!mergedOptions.shouldFetch) {
      return undefined;
    }
    fetchData(abort.signal);

    return () => abort.abort();
  }, [...mergedOptions.deps, mergedOptions.shouldFetch]);

  return { ...state, refetch: fetchData, resetState };
}
