import { typographyV2 } from '@toggle/design-system';
import styled from 'styled-components';

export const ChatDisclaimerRoot = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

export const ExternalLink = styled.a`
  ${typographyV2.bodyXs}
  padding: 8px;
  color: var(--text-soft);
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;
