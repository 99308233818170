import {
  Button,
  customScrollMinimal,
  deviceConstraints,
  SkeletonLoader,
  typography,
} from '@toggle/design-system';
import styled from 'styled-components';

import { Filter } from '~/views/explore/feed/partials/filters/Filter';

export const ExploreFilterContainer = styled.div`
  padding: 0;

  @media ${deviceConstraints.desktopSmall} {
    padding-right: 28px;
  }

  ul {
    list-style-type: none;
  }
`;

export const ExploreFilterHeader = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 1;
  padding: 16px 16px 0px 16px;
  margin-bottom: 20px;
  background-color: var(--base--95);

  @media ${deviceConstraints.desktopSmall} {
    padding-top: 40px;
    padding-right: 0px;
    background-color: var(--base--100);
  }
`;

export const ExploreFilterTitle = styled.h2`
  flex: 1;
  ${typography.coyote};
  font-weight: 500;
`;

export const ExploreFilterGroup = styled(Filter)`
  &:not(:first-child) {
    margin-top: 40px;
  }
`;

export const FilterMobileFooter = styled.div`
  position: sticky;
  bottom: 0;
  width: 100%;
  padding: 16px;
  border-top: 1px solid var(--base--80);
  background-color: var(--base--95);

  @media ${deviceConstraints.desktopSmall} {
    display: none;
  }
`;

export const ShowInsightsBtn = styled(Button)`
  height: 40px;
  width: 268px;
`;

export const ExploreFiltersList = styled.div`
  padding-bottom: 68px;
  height: calc(100vh - 48px - 93px);
  ${customScrollMinimal};
  overflow-y: auto;

  @media ${deviceConstraints.desktopSmall} {
    height: auto;
    overflow: unset;
  }
`;

export const FiltersLoader = styled(SkeletonLoader)`
  height: 100%;
  border-radius: 4px;
  margin: 16px 28px 0px 0px;
  width: auto;

  @media ${deviceConstraints.desktopSmall} {
    margin-top: 40px;
  }
`;
