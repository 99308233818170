import React from 'react';
import { useTranslation } from 'react-i18next';

import { QuestionnaireContent } from '~/views/helper/helper-widget/content/onboard/QuestionnaireContent';
import { ONBOARDING_SECTION_IDS } from '~/views/helper/HelperConfig';

import {
  HelperWidgetAccordion,
  HelperWidgetSection,
} from '../../partials/accordion/HelperWidgetAccordion';
import { HelperContentProps } from '../content';

export const OnboardingHelperContent = (props: HelperContentProps) => {
  const { t } = useTranslation('helperWidget');

  const sections: HelperWidgetSection[] = [
    {
      id: ONBOARDING_SECTION_IDS.ONBOARDING_QUESTIONNAIRE_ID,
      title: t('helperWidget:onboarding.content.sectionsTitles.questionnaire'),
      gaTitle: 'Onboarding Questionnaire',
      content: <QuestionnaireContent />,
      separateSection: true,
      hideMarker: true,
    },
  ];

  return (
    <HelperWidgetAccordion sections={sections} sendGaOnExpand {...props} />
  );
};
