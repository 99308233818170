import { create } from '~/stores/create-store/createStore';

interface MobileMenuStore {
  isMobileMenuOpened: boolean;
  setIsMobileMenuOpened: () => void;
  setIsMobileMenuClosed: () => void;
}

export const useMobileMenu = create<MobileMenuStore>(set => ({
  isMobileMenuOpened: false,
  setIsMobileMenuOpened: () => set({ isMobileMenuOpened: true }),
  setIsMobileMenuClosed: () => set({ isMobileMenuOpened: false }),
}));
