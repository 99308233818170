import classNames from 'classnames';
import React, { LiHTMLAttributes } from 'react';

import { StyledComponent } from '~/common/styled-component';

import { DropdownListProps } from '../dropdown-list/DropdownList';
import * as S from './DropdownListOption.styles';

interface DropdownListOptionProps<T>
  extends LiHTMLAttributes<HTMLButtonElement> {
  isActive: boolean;
  isFocused: boolean;
  isDisabled: boolean;
  item: T;
  onClick: () => void;
  onMouseEnter?: () => void;
  listItemRef: DropdownListProps<T>['listItemRef'];
  onMouseLeave: DropdownListProps<T>['onItemMouseLeave'];
  renderDropdownItem: DropdownListProps<T>['renderDropdownItem'];
  focusedItemClassName: DropdownListProps<T>['focusedItemClassName'];
  hasV2Styles?: boolean;
}

export const DropdownListOption: StyledComponent<
  DropdownListOptionProps<any>,
  typeof S
> = <T,>({
  isActive,
  isFocused,
  isDisabled = false,
  item,
  onClick,
  listItemRef,
  onMouseEnter,
  onMouseLeave,
  renderDropdownItem,
  focusedItemClassName,
  hasV2Styles,
}: DropdownListOptionProps<T>) => {
  return (
    <S.ListOption
      role="option"
      aria-selected={isActive}
      data-testid="dropdown-list-option"
      onMouseLeave={onMouseLeave}
      onMouseEnter={onMouseEnter}
      ref={isFocused || isActive ? listItemRef : undefined}
      onClick={isDisabled ? undefined : onClick}
      className={classNames(
        isActive && 'active',
        isFocused && (focusedItemClassName || 'focused')
      )}
      $hasV2Styles={hasV2Styles}
      $isDisabled={isDisabled}
    >
      {renderDropdownItem(item, isActive, isFocused, isDisabled)}
    </S.ListOption>
  );
};

DropdownListOption.Styled = S;
