export enum Class {
  ClassUnspecified,
  ClassEco,
  ClassCommodity,
  ClassCredit,
  ClassEquity,
  ClassEtf,
  ClassFi,
  ClassFuture,
  ClassFx,
  ClassStock,
}

export enum EntitySearchType {
  Entity,
  Currency,
  Economies,
  EntityAndEconomies,
}

export enum AssetClassCode {
  Unspecified,
  Commodity,
  Fx,
  Equity,
  Fi,
  Currency,
}

export const AssetClassName = {
  Unspecified: 'class_unspecified',
  Commodity: 'class_commodity',
  Fx: 'class_fx',
  Equity: 'class_equity',
  Fi: 'class_fi',
  Currency: 'class_currency',
} as const;

export type AssetClassNames =
  typeof AssetClassName[keyof typeof AssetClassName];

export const AssetClassMap: {
  [key in AssetClassCode]: AssetClassNames;
} = {
  [AssetClassCode.Unspecified]: AssetClassName.Unspecified,
  [AssetClassCode.Commodity]: AssetClassName.Commodity,
  [AssetClassCode.Fx]: AssetClassName.Fx,
  [AssetClassCode.Equity]: AssetClassName.Equity,
  [AssetClassCode.Fi]: AssetClassName.Fi,
  [AssetClassCode.Currency]: AssetClassName.Currency,
};

export enum AssetSubClassCode {
  Unspecified,
  FxCrypto,
  FutureElectricity,
  FutureEnergy,
  FutureFood,
  FutureGrains,
  CommodityIndex,
  FutureMetals,
  FutureSofts,
  FutureCurrency,
  GenericCross,
  MajorCross,
  EcoCountry,
  EcoArea,
  EquityIndex,
  EquityFactorIndex,
  FutureEquity,
  EquitySector,
  EquityStock,
  FiBe,
  CreditCds,
  FiInflationSwap,
  FiInflationSov,
  FiInterbank,
  FiIrs,
  FutureInterestRate,
  FiSov,
  FutureBonds,
  FiEtf,
  EquityEtf,
  CommodityEtf,
  CurrencyEtf,
  OtherEtf,
}

export const AssetSubClassName = {
  Unspecified: 'SUB_CLASS_UNSPECIFIED',
  FxCrypto: 'fx_crypto',
  FutureElectricity: 'SUB_CLASS_FUTURE_ELECTRICITY',
  FutureEnergy: 'FUTURE_ENERGY',
  FutureFood: 'FUTURE_FOOD',
  FutureGrains: 'SUB_CLASS_FUTURE_GRAINS',
  CommodityIndex: 'SUB_CLASS_COMMODITY_INDEX',
  FutureMetals: 'SUB_CLASS_FUTURE_METALS',
  FutureSofts: 'SUB_CLASS_FUTURE_SOFTS',
  FutureCurrency: 'SUB_CLASS_FUTURE_CURRENCY',
  GenericCross: 'SUB_CLASS_GENERIC_CROSS',
  MajorCross: 'SUB_CLASS_MAJOR_CROSS',
  EcoCountry: 'SUB_CLASS_ECO_COUNTRY',
  EcoArea: 'SUB_CLASS_ECO_AREA',
  EquityIndex: 'SUB_CLASS_EQUITY_INDEX',
  EquityFactorIndex: 'SUB_CLASS_EQUITY_FACTOR_INDEX',
  FutureEquity: 'SUB_CLASS_FUTURE_EQUITY',
  EquitySector: 'SUB_CLASS_EQUITY_SECTOR',
  EquityStock: 'SUB_CLASS_EQUITY_STOCK',
  FiBe: 'SUB_CLASS_FI_BE',
  CreditCds: 'SUB_CLASS_CREDIT_CDS',
  FiInflationSwap: 'SUB_CLASS_FI_INFLATION_SWAP',
  FiInflationSov: 'SUB_CLASS_FI_INFLATION_SOV',
  FiInterbank: 'SUB_CLASS_FI_INTERBANK',
  FiIrs: 'SUB_CLASS_FI_IRS',
  FutureInterestRate: 'SUB_CLASS_FUTURE_INTEREST_RATE',
  FiSov: 'SUB_CLASS_FI_SOV',
  FutureBonds: 'SUB_CLASS_FUTURE_BONDS',
  FiEtf: 'SUB_CLASS_FI_ETF',
  EquityEtf: 'SUB_CLASS_EQUITY_ETF',
  CommodityEtf: 'SUB_CLASS_COMMODITY_ETF',
  CurrencyEtf: 'SUB_CLASS_CURRENCY_ETF',
  OtherEtf: 'SUB_CLASS_OTHER_ETF',
} as const;

export type AssetSubClassNames =
  typeof AssetSubClassName[keyof typeof AssetSubClassName];

export const AssetSubClassMap: {
  [key in AssetSubClassCode]: AssetSubClassNames;
} = {
  [AssetSubClassCode.Unspecified]: AssetSubClassName.Unspecified,
  [AssetSubClassCode.FxCrypto]: AssetSubClassName.FxCrypto,
  [AssetSubClassCode.FutureElectricity]: AssetSubClassName.FutureElectricity,
  [AssetSubClassCode.FutureEnergy]: AssetSubClassName.FutureEnergy,
  [AssetSubClassCode.FutureFood]: AssetSubClassName.FutureFood,
  [AssetSubClassCode.FutureGrains]: AssetSubClassName.FutureGrains,
  [AssetSubClassCode.CommodityIndex]: AssetSubClassName.CommodityIndex,
  [AssetSubClassCode.FutureMetals]: AssetSubClassName.FutureMetals,
  [AssetSubClassCode.FutureSofts]: AssetSubClassName.FutureSofts,
  [AssetSubClassCode.FutureCurrency]: AssetSubClassName.FutureCurrency,
  [AssetSubClassCode.GenericCross]: AssetSubClassName.GenericCross,
  [AssetSubClassCode.MajorCross]: AssetSubClassName.MajorCross,
  [AssetSubClassCode.EcoCountry]: AssetSubClassName.EcoCountry,
  [AssetSubClassCode.EcoArea]: AssetSubClassName.EcoArea,
  [AssetSubClassCode.EquityIndex]: AssetSubClassName.EquityIndex,
  [AssetSubClassCode.EquityFactorIndex]: AssetSubClassName.EquityFactorIndex,
  [AssetSubClassCode.FutureEquity]: AssetSubClassName.FutureEquity,
  [AssetSubClassCode.EquitySector]: AssetSubClassName.EquitySector,
  [AssetSubClassCode.EquityStock]: AssetSubClassName.EquityStock,
  [AssetSubClassCode.FiBe]: AssetSubClassName.FiBe,
  [AssetSubClassCode.CreditCds]: AssetSubClassName.CreditCds,
  [AssetSubClassCode.FiInflationSwap]: AssetSubClassName.FiInflationSwap,
  [AssetSubClassCode.FiInflationSov]: AssetSubClassName.FiInflationSov,
  [AssetSubClassCode.FiInterbank]: AssetSubClassName.FiInterbank,
  [AssetSubClassCode.FiIrs]: AssetSubClassName.FiIrs,
  [AssetSubClassCode.FutureInterestRate]: AssetSubClassName.FutureInterestRate,
  [AssetSubClassCode.FiSov]: AssetSubClassName.FiSov,
  [AssetSubClassCode.FutureBonds]: AssetSubClassName.FutureBonds,
  [AssetSubClassCode.FiEtf]: AssetSubClassName.FiEtf,
  [AssetSubClassCode.EquityEtf]: AssetSubClassName.EquityEtf,
  [AssetSubClassCode.CommodityEtf]: AssetSubClassName.CommodityEtf,
  [AssetSubClassCode.CurrencyEtf]: AssetSubClassName.CurrencyEtf,
  [AssetSubClassCode.OtherEtf]: AssetSubClassName.OtherEtf,
};

export const AssetSector = {
  CommunicationServices: 'sector_communication_services',
  ConsumerDiscretionary: 'sector_consumer_discretionary',
  ConsumerStaples: 'sector_consumer_staples',
  Energy: 'sector_energy',
  Financials: 'sector_financials',
  HealthCare: 'sector_health_care',
  Industrials: 'sector_industrials',
  Materials: 'sector_materials',
  Other: 'sector_other',
  RealEstate: 'sector_real_estate',
  Utilities: 'sector_utilities',
  InformationTechnology: 'sector_information_technology',
} as const;

export type AssetSectors = typeof AssetSector[keyof typeof AssetSector];
