import { borderRadius, typography } from '@toggle/design-system';
import styled from 'styled-components';

export const BaseSection = styled.section`
  background: var(--surface-default);
  position: relative;
  padding: 12px 13px;
  margin-bottom: 20px;
  border: 1px solid var(--border-soft);
  margin: 16px;
  ${typography.emu};
  ${borderRadius.r2};
`;
