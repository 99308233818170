import maxBy from 'lodash/maxBy';
import mean from 'lodash/mean';

import {
  CompassDataWithFormat,
  CompassPastEpisodes,
  QualityChecklist,
} from '~/views/analyze/store/useExperimentStore';

export const formatPercentage = (n: number) => (n * 100).toFixed(2);

interface StarTotalReturn {
  checklistPeriod?: QualityChecklist;
  starCount?: number;
}

export const getStarTotal = (
  period: string,
  experimentData: CompassDataWithFormat
): StarTotalReturn => {
  const checklistPeriodData = experimentData.quality_checklist.find(
    i => i.period === period
  )?.checklist;
  if (checklistPeriodData) {
    const starCount = Object.values(checklistPeriodData).filter(
      i => i.star
    ).length;
    return { checklistPeriod: checklistPeriodData, starCount };
  }

  return { checklistPeriod: undefined, starCount: undefined };
};

export interface EvidenceEpisodeData {
  averageData: number;
}

export interface EvidenceEpisode {
  date: string;
  count: number;
  episodeData: EvidenceEpisodeData[];
}

export const mapExperimentToEvidenceData = (
  periods: CompassPastEpisodes[]
): EvidenceEpisode[] => {
  // Get max length as some periods can have different length of episode dates
  const maxLengthEpisode = maxBy(periods, e => e.dates) as CompassPastEpisodes;
  return maxLengthEpisode.dates.map((_, index) => {
    // Using max length as that will have the all possible dates and data count
    const episode = maxLengthEpisode.dates[index];
    const episodeDate = episode.date;
    const episodeCount = episode.data.length;

    // Map through periods and then episode dates to find average
    const mappedEpisode = periods.map(e => ({
      averageData: e.dates[index] ? mean(e.dates[index].data) : 0,
    }));
    return {
      date: episodeDate,
      count: episodeCount,
      episodeData: mappedEpisode,
    };
  });
};
