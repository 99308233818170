import { useIsomorphicLayoutEffect } from '../use-isomorphic-layout-effect/useIsomorphicLayoutEffect';

const getScrollBarWidth = () => window.innerWidth - document.body.clientWidth;

export const useDisableScroll = (
  disable = true,
  overflow: CSSStyleDeclaration['overflow'] = 'overlay'
): void => {
  useIsomorphicLayoutEffect(() => {
    if (disable) {
      const scrollBarWidth = getScrollBarWidth();
      if (scrollBarWidth) {
        document.body.style.paddingRight = `${scrollBarWidth}px`;
      }
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = overflow;
      document.body.style.paddingRight = '0px';
    };
  }, [disable]);
};
