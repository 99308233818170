import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { OrderSide } from '~/global/trading-drawer/use-transaction/useTransaction';

import * as S from './Position.styles';

interface PositionProps {
  statementPosition: number;
  side: OrderSide;
  onClick: (p: number) => void;
}

export const Position: FC<PositionProps> = ({
  statementPosition,
  onClick,
  side,
}) => {
  const { t } = useTranslation('portfolio');

  const canClose = () => {
    if (!statementPosition) {
      return false;
    }

    const isShort = statementPosition < 0;

    return (
      (side === OrderSide.SELL && !isShort) ||
      (side === OrderSide.BUY && isShort)
    );
  };

  const quantity = Math.abs(statementPosition);

  return (
    <S.PositionText>
      {t('portfolio:currentPositions', { position: statementPosition })}
      {canClose() && (
        <S.PositionSubText
          onClick={() => onClick(quantity)}
          data-testid="close-link"
        >
          {t('portfolio:closePosition')}
        </S.PositionSubText>
      )}
    </S.PositionText>
  );
};
