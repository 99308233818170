import { OptionalU } from '@toggle/helpers';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { QualityChecklist } from '~/views/analyze/store/useExperimentStore';
import { QUALITY_CHECKLIST } from '~/views/analyze/store/useExperimentStore.enums';

import { QualityCheckListItem } from '../quality-checklist-item/QualityCheckListItem';
import * as Shared from '../QualityChecklistShared.styles';
import * as S from './QualityChecklistSection.styles';

export const FREQUENT_THRESHOLD = 1260;

export interface QualityChecklistSectionProps {
  title?: string;
  listItems: QUALITY_CHECKLIST[];
  checklistData: OptionalU<QualityChecklist>;
}

const fixedFloor = (num: number, fractionDigits: number) => {
  const multiplier = Math.pow(10, fractionDigits);
  return (Math.floor(num * multiplier) / multiplier).toFixed(fractionDigits);
};

export const QualityChecklistSection = ({
  title,
  listItems,
  checklistData,
  ...rest
}: QualityChecklistSectionProps) => {
  const { t } = useTranslation(['analyze', 'qualityChecklist']);

  const getValue = (key: string, val: null | number): string => {
    switch (key) {
      case QUALITY_CHECKLIST.CROSS_BUSINESS_CYCLE:
        return val
          ? t('analyze:experiment.qualityChecklist.yes')
          : t('analyze:experiment.qualityChecklist.no');
      case QUALITY_CHECKLIST.FREQUENT:
        return val && val < FREQUENT_THRESHOLD
          ? t('analyze:experiment.qualityChecklist.yes')
          : t('analyze:experiment.qualityChecklist.no');
      case QUALITY_CHECKLIST.SEVERAL_EPISODES:
        return t('analyze:experiment.qualityChecklist.episodes', {
          number: val,
        });
      case QUALITY_CHECKLIST.SPARSE:
      case QUALITY_CHECKLIST.HIGH_HIT_RATIO_IN_SAMPLE:
      case QUALITY_CHECKLIST.HIGH_HIT_RATIO_OUT_SAMPLE:
        return t(`analyze:experiment.qualityChecklist.${key}`, {
          number: val ? fixedFloor(val * 100, 1) : 0,
        });
      case QUALITY_CHECKLIST.HIGH_PATH_RISK_RETURN:
      case QUALITY_CHECKLIST.HIGH_PNL_DRAWDOWN_RATIO:
        return t(`analyze:experiment.qualityChecklist.${key}`, {
          number: val ? fixedFloor(val, 1) : 0,
        });
      default:
        return '';
    }
  };

  const SUB_MESSAGES: Record<string, string> = {
    [QUALITY_CHECKLIST.SPARSE]: t(
      'analyze:experiment.qualityChecklist.maxRequirement',
      { number: 10 }
    ),
    [QUALITY_CHECKLIST.HIGH_HIT_RATIO_IN_SAMPLE]: t(
      'analyze:experiment.qualityChecklist.minRequirement',
      { number: 75 }
    ),
    [QUALITY_CHECKLIST.HIGH_HIT_RATIO_OUT_SAMPLE]: t(
      'analyze:experiment.qualityChecklist.minRequirement',
      { number: 75 }
    ),
    [QUALITY_CHECKLIST.HIGH_PATH_RISK_RETURN]: t(
      'analyze:experiment.qualityChecklist.minRequirementX',
      { number: 1.5 }
    ),
    [QUALITY_CHECKLIST.HIGH_PNL_DRAWDOWN_RATIO]: t(
      'analyze:experiment.qualityChecklist.minRequirementX',
      { number: 1.5 }
    ),
  };

  return (
    <Shared.BaseSection {...rest}>
      <S.ConditionTitle>{title}</S.ConditionTitle>
      <S.ListContainer>
        {listItems.map(k => {
          const values = checklistData?.[k];
          return (
            <QualityCheckListItem
              key={k}
              star={!!values?.star}
              title={t(`common:qualityChecklist.labels.${k}`)}
              tooltipContent={t(`common:qualityChecklist.tooltips.${k}`)}
              value={getValue(k, values?.value ?? 0)}
              subText={SUB_MESSAGES[k]}
            />
          );
        })}
      </S.ListContainer>
    </Shared.BaseSection>
  );
};
