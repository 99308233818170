import { keyboard } from '@toggle/helpers';
import React, {
  ButtonHTMLAttributes,
  FocusEvent,
  ReactNode,
  useState,
} from 'react';

import {
  DropdownItem,
  DropdownListItem,
} from '~/components/dropdown/dropdown-list-item/DropdownListItem';

import * as S from './PillDropdown.styles';

export interface Item extends DropdownItem {
  label: string;
  onClick: (value: string) => void;
  dataTestId?: string;
}

export interface PillDropdownProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  label: ReactNode;
  value: string;
  onClickPill?: () => void;
  renderIcon?: () => ReactNode;
  iconSize?: number;
  dropdownItems: Item[];
}

export const PillDropdown = ({
  label,
  value,
  renderIcon,
  dropdownItems,
  onClickPill,
  ...rest
}: PillDropdownProps) => {
  const [showOptions, setShowOptions] = useState(false);

  const handleKeyDown = (event: React.KeyboardEvent, item?: Item | null) => {
    if (
      event.key === keyboard.enter.key ||
      event.code === keyboard.space.code
    ) {
      event.preventDefault();
      updateShowOptions(!showOptions);
      item?.onClick?.(value);
      onClickPill?.();
    }
  };

  const handleBlur = ({
    currentTarget,
    relatedTarget,
  }: FocusEvent<HTMLDivElement>) => {
    if (currentTarget?.contains(relatedTarget)) {
      return;
    }
    updateShowOptions(false);
  };

  const updateShowOptions = (value: boolean) => {
    if (dropdownItems.length) {
      setShowOptions(value);
    }
  };
  return (
    <S.StyledPillContainer onBlur={handleBlur}>
      <S.StyledPillDropdown<Item>
        dropdownItems={dropdownItems}
        activeItemIdx={0}
        shouldShowItems={showOptions}
        onItemsShowChange={value => updateShowOptions(value)}
        onChildKeyDown={(_, item, __, event) => {
          handleKeyDown(event, item);
        }}
        shouldCloseOnOutsideClick
        strategy="fixed"
        placement="right-start"
        offsetValue={{ x: 30, y: -30 }}
        renderDropdownItem={(item, _, isFocused) => (
          <DropdownListItem
            data-testid="pill-dropdown-item"
            label={item.label}
            tabIndex={0}
            onKeyDown={event => handleKeyDown(event, item)}
            onClick={() => item.onClick?.(value)}
            supportiveText={item.supportiveText}
            isActive={false}
            isFocused={isFocused}
            separated={item.separated}
          />
        )}
        onClick={onClickPill}
      >
        <S.PillDropdownRoot
          role="button"
          tabIndex={0}
          $showOptions={showOptions}
          $hasOptions={!!dropdownItems.length}
          onKeyDown={handleKeyDown}
          data-testid="button-link"
        >
          <S.Label>
            {renderIcon?.()}
            {label}
          </S.Label>
          <S.StyledButton
            tabIndex={0}
            data-testid="pill-options-icon"
            iconName="Ellipsis"
            variant="tertiary"
            size="xsmall"
            {...rest}
          />
        </S.PillDropdownRoot>
      </S.StyledPillDropdown>
    </S.StyledPillContainer>
  );
};

PillDropdown.Styled = S;
