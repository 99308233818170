import times from 'lodash/times';

import { roundToPrecision } from '../chart-precision/chart-precision';

interface HumenFrindlyTicksResult {
  ticks: number[];
  step: number;
}

const DEFAULT_STEPS = [1, 2, 3, 5, 10, 25];
const DEFAULT_RANGE_LENGTH = 1;

export const humanFriendlyTicks = (
  range: [number, number] | number[],
  maxTicks: number
): HumenFrindlyTicksResult => {
  const [rangeMin, rangeMax] = [Math.min(...range), Math.max(...range)];
  const rangeLength = rangeMax - rangeMin || DEFAULT_RANGE_LENGTH;

  //calculate min/max bounds to get human readable ticks
  const max = DEFAULT_STEPS[DEFAULT_STEPS.length - 1] * maxTicks;
  const min = DEFAULT_STEPS[0] * maxTicks;

  //calc scale required to get range into bounds
  let scale = 1;
  if (rangeLength > max) {
    while (rangeLength / scale > max) {
      scale *= 10;
    }
  } else if (rangeLength < min) {
    while (rangeLength / scale < min) {
      scale /= 10;
    }
  }

  // rescale range to be in min/max bouds
  const rescaledRange = [rangeMin, rangeMax].map(x => x / scale);

  // find a step which met the maxTicks criteria
  const step =
    DEFAULT_STEPS.find(step => {
      const trimmedRange = [
        Math.ceil(rescaledRange[0] / step) * step,
        Math.floor(rescaledRange[1] / step) * step,
      ];
      return (trimmedRange[1] - trimmedRange[0]) / step <= maxTicks;
    }) ?? 1;

  // calc ticks using step calculated above
  const trimmedRange = [
    Math.ceil(rescaledRange[0] / step) * step,
    Math.floor(rescaledRange[1] / step) * step,
  ];

  const ticks = times((trimmedRange[1] - trimmedRange[0]) / step + 1, i => {
    const tick = (trimmedRange[0] + step * i) * scale;

    if (scale < 1) {
      return roundToPrecision(tick, scale);
    }

    return tick;
  });

  return {
    step: scale * step,
    ticks,
  };
};
