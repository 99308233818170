const IOS_DEVICES = [
  'iPad Simulator',
  'iPhone Simulator',
  'iPod Simulator',
  'iPad',
  'iPhone',
  'iPod',
];

export const isiOSDevice = () => {
  return (
    IOS_DEVICES.includes(navigator.platform) ||
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  );
};

export const isMac = () =>
  typeof navigator !== 'undefined' ? /Mac/.test(navigator.userAgent) : false;
