import React from 'react';

import { BAR_HEIGHT, CHART_WIDTH, margin, ROW_HEIGHT } from '../constants';
import * as S from '../HorizontalBarChart.styles';

interface HorizontalLabelsProps {
  value: number;
  index: number;
  leftLabel: string;
  rightLabel: string;
  paddingLeft?: number;
}

export const HorizontalLabels = ({
  value,
  index,
  leftLabel,
  rightLabel,
  paddingLeft = 0,
}: HorizontalLabelsProps) => (
  <g
    transform={`translate(0, ${BAR_HEIGHT / 2})`}
    data-testid="left-right-labels"
  >
    <S.MedianText
      x={CHART_WIDTH - margin.right}
      y={index * ROW_HEIGHT}
      dy="0.35em"
      hasProfit={value > 0}
    >
      {rightLabel}
    </S.MedianText>
    <S.LabelText
      x={margin.left + paddingLeft}
      y={index * ROW_HEIGHT}
      dy="0.35em"
    >
      {/* TODO: https://app.clickup.com/t/8693518xt include portfolio image + ticker  */}
      {leftLabel}
    </S.LabelText>
  </g>
);
