import { TFunction } from 'i18next';
import React, { FC } from 'react';

import { TradeSearchSecurity } from '~/api/trading/trading-schema';
import { OrderSide } from '~/global/trading-drawer/use-transaction/useTransaction';
import { EllipsisDetector } from '~/shared/components/elipsis-detector/EllipsisDetector';

import { highlightParts } from '../../../search/components/search-autocomplete-item/SearchAutocompleteItem';
import * as S from './TradeSearchItem.styles';

interface TradeSearchItemProps {
  isFocused: boolean;
  highlighted: string;
  item: TradeSearchSecurity;
  t: TFunction;
  onBtnClick: (actionType: OrderSide, item: TradeSearchSecurity) => void;
}

export const TradeSearchItem: FC<TradeSearchItemProps> = ({
  item,
  isFocused,
  highlighted,
  t,
  onBtnClick,
}) => (
  <S.ItemContainer isFocused={isFocused} data-testid="trade-search-item">
    <EllipsisDetector label={item.name}>
      <S.LeftText>
        {isFocused ? item.name : highlightParts(item.name, highlighted)}
      </S.LeftText>
    </EllipsisDetector>
    <S.InfoContainer>
      <S.RightText>
        {isFocused ? item.ticker : highlightParts(item.ticker, highlighted)}
      </S.RightText>
      <S.Dot />
      <S.ContractType>Stock</S.ContractType>
      <S.Dot />
      <EllipsisDetector label={item.exchange}>
        <S.Exchange>{item.exchange}</S.Exchange>
      </EllipsisDetector>
    </S.InfoContainer>
    <S.BtnsContainer>
      <S.BuyBtn
        label={t('header:search.buy')}
        onClick={() => onBtnClick(OrderSide.BUY, item)}
        data-testid="buy-button"
      />
      <S.SellBtn
        label={t('header:search.sell')}
        onClick={() => onBtnClick(OrderSide.SELL, item)}
        variant="secondary"
        data-testid="sell-button"
      />
    </S.BtnsContainer>
  </S.ItemContainer>
);
