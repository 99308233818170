import { Icon, typographyV2 } from '@toggle/design-system';
import styled from 'styled-components';

export const Star = styled(Icon)<{ $active: boolean }>`
  &:not(:first-child) {
    padding-left: 6px;
  }
  margin-right: 12px;
  fill: ${({ $active }) =>
    $active ? 'var(--icon-primary)' : 'var(--icon-soft)'};
`;

export const TooltipText = styled.p`
  ${typographyV2.bodyBase}
  margin-bottom: 4px;
`;

export const SmallMessage = styled.p<{ $active: boolean }>`
  ${typographyV2.bodySm}
  color: ${({ $active }) =>
    $active ? 'var(--viz-bullish)' : 'var(--viz-bearish)'};
`;

export const SubMessage = styled.span`
  ${typographyV2.bodyXs}
  margin-left: 12px;
  color: var(--base--40);
`;
