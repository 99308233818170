import { Icon } from '@toggle/design-system';
import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

import {
  InsightStatus,
  MappedArticle,
} from '~/views/analyze/utils/insights-helpers';

import * as S from './InsightSummary.styles';

interface InsightSummaryProps {
  insight: MappedArticle;
  className?: string;
}

export const InsightSummary = forwardRef<HTMLDivElement, InsightSummaryProps>(
  ({ insight, className }, ref) => {
    const { t } = useTranslation('article');
    return (
      <S.InsightSummary className={className} ref={ref}>
        <S.InsightSummaryItem data-testid="insight-direction">
          <S.LeftSide>{t('article:summary.direction')}</S.LeftSide>
          <S.DirectionText type={insight.direction}>
            {t(`chart:tooltip.${insight.direction}`)}
            <S.RatingIcon
              iconName={insight.direction === 0 ? 'ArrowUp' : 'ArrowDown'}
              fillColor={
                insight.direction === 0
                  ? 'var(--viz-bullish)'
                  : 'var(--viz-bearish)'
              }
              size={16}
            />
          </S.DirectionText>
        </S.InsightSummaryItem>
        <S.InsightSummaryItem data-testid="insight-stars">
          <S.LeftSide>{t('article:summary.stars')}</S.LeftSide>
          <S.StarsText>
            {insight.stars}
            <Icon
              iconName="Rating"
              size={16}
              fillColor="var(--warning--default)"
            />
          </S.StarsText>
        </S.InsightSummaryItem>
        {[InsightStatus.WORKED, InsightStatus.FAILED].includes(
          insight.status
        ) ? (
          <S.InsightSummaryItem data-testid="insight-pnl">
            <S.LeftSide>{t('article:summary.pnl')}</S.LeftSide>
            <S.RightSideWithPriceStatus status={insight.pnlStatus}>
              {insight.pnlText}
            </S.RightSideWithPriceStatus>
          </S.InsightSummaryItem>
        ) : (
          <>
            <S.InsightSummaryItem data-testid="insight-status">
              <S.LeftSide>{t('article:summary.status')}</S.LeftSide>
              <S.RightSide>{t('article:summary.activeStatus')}</S.RightSide>
            </S.InsightSummaryItem>
            <S.InsightSummaryItem data-testid="insight-occurrences">
              <S.LeftSide>{t('article:summary.occurrences')}</S.LeftSide>
              <S.RightSide>
                {t('article:summary.time', {
                  count: insight.occurrencesNumber,
                })}
              </S.RightSide>
            </S.InsightSummaryItem>
            <S.InsightSummaryItem data-testid="insight-rate">
              <S.LeftSide>{t('article:summary.rate')}</S.LeftSide>
              <S.RightSide>{insight.hitRate}</S.RightSide>
            </S.InsightSummaryItem>
          </>
        )}
      </S.InsightSummary>
    );
  }
);
