import { Button } from '@toggle/design-system';
import { useDisableScroll } from '@toggle/helpers';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { UserRole } from '~/api/subscription/subscription-schema';
import { MainMenu } from '~/global/app-header/components/main-menu/MainMenu';
import { MobileMenu } from '~/global/app-header/components/mobile-menu/MobileMenu';
import {
  getMenuData,
  getMobileMenuData,
  HeaderMenuItems,
} from '~/global/app-header/config';
import { useHandleMobileMenu } from '~/global/app-header/hooks/useHandleMobileMenu';
import { SearchButton } from '~/global/app-search/search/components/search-button/SearchButton';
import { appPaths } from '~/routes/app-paths';
import { useTouchDevice } from '~/shared/hooks/MediaQueryHook';
import { gaClickMenuHeader } from '~/shared/utils/ganalytics/commonGA';
import { useFeatureFlags } from '~/stores/use-feature-flags/useFeatureFlags';
import {
  SearchTabsOptions,
  useGlobalSearch,
} from '~/stores/use-global-search/useGlobalSearch';
import { usePortfolio } from '~/stores/use-portfolio/usePortfolio';
import { mapTradingProviderToSearchTab } from '~/stores/use-portfolio/utils/use-portfolio-utils';
import { useSubscription } from '~/stores/use-subscription/useSubscription';
import { useUser } from '~/stores/use-user/useUser';

import { SecondaryMenu } from '../secondary-menu/SecondaryMenu';
import * as S from './AppHeaderDefault.styles';

export enum MenuParentItem {
  MainMenu = 'Main Menu',
  MyAccount = 'My Account',
  Support = 'Support',
  MobileMenu = 'Mobile Menu',
  Announcement = 'Announcement',
}

export const AppHeaderDefault = ({ path }: { path: string }) => {
  const { t } = useTranslation(['header']);
  const logoTitle = t('header:logo.altImage');

  const isAuthenticated = useUser(s => s.isAuthenticated);
  const { hasPermission, isPro, isBasic } = useSubscription(s => ({
    hasPermission: s.hasPermission,
    isPro: s.isPro(),
    isBasic: s.isBasic(),
  }));

  const isTouchDevice = useTouchDevice();
  const userFlags = useFeatureFlags(state => state.flags);
  const getConnectedTradingProviders = usePortfolio(
    s => s.getConnectedTradingProviders
  );
  const { isMobileMenuOpened, openMobileMenu, closeMobileMenu } =
    useHandleMobileMenu();
  const { isSearchOpened, closeSearch, openSearch, selectedSearchEntity } =
    useGlobalSearch(
      ({ isSearchOpened, closeSearch, openSearch, selectedSearchEntity }) => ({
        isSearchOpened,
        closeSearch,
        openSearch,
        selectedSearchEntity,
      })
    );

  useDisableScroll(isSearchOpened);

  const handleOpenMobileMenu = () => {
    openMobileMenu();
  };

  const handleOpenSearch = () => {
    let activeSearchTab = SearchTabsOptions.Insights;
    if (path.includes(appPaths.portfolio)) {
      const connectedTradingProviders = getConnectedTradingProviders();
      activeSearchTab = connectedTradingProviders[0]
        ? mapTradingProviderToSearchTab[connectedTradingProviders[0]]
        : SearchTabsOptions.Insights;
    }

    openSearch({ activeTab: activeSearchTab });
    if (isMobileMenuOpened) {
      closeMobileMenu();
    }
  };

  const filterDesktopMenuItems = (menuItems: HeaderMenuItems) => {
    if (menuItems.requiredFeatureFlag) {
      return (
        hasPermission(menuItems.section) &&
        userFlags[menuItems.requiredFeatureFlag]
      );
    }

    return hasPermission(menuItems.section);
  };

  const filterMobileMenuItems = (menuItems: HeaderMenuItems) => {
    if (menuItems.hiddenOnMobile) {
      return false;
    }
    return filterDesktopMenuItems(menuItems);
  };

  const menuItemData = useMemo(
    () => getMenuData(t).filter(filterDesktopMenuItems),
    [isAuthenticated]
  );

  const mobileMenuItemData = useMemo(
    () => getMobileMenuData(t).filter(filterMobileMenuItems),
    [isAuthenticated]
  );

  const handleSearchIconClick = () => {
    isSearchOpened ? closeSearch() : handleOpenSearch();
  };

  return (
    <>
      <S.Container className="app-header" data-testid="default-header">
        {isTouchDevice && !isSearchOpened ? (
          <Button
            variant="tertiary"
            data-testid="header-hamburgerMenu-icon"
            iconName="HamburgerMenu"
            onClick={handleOpenMobileMenu}
          />
        ) : (
          <S.LogoContainer>
            {(isTouchDevice ? !isSearchOpened : true) && (
              <S.ToggleLink to={appPaths.myToggle}>
                <S.ToggleLogoStyled
                  alt={logoTitle}
                  title={logoTitle}
                  onClick={() =>
                    gaClickMenuHeader('Toggle logo', MenuParentItem.MainMenu)
                  }
                />
                {isPro && (
                  <S.ProBadge>
                    {t('subscription:role', { context: UserRole.Pro })}
                  </S.ProBadge>
                )}
              </S.ToggleLink>
            )}
          </S.LogoContainer>
        )}
        <S.MainMenu>
          <SearchButton
            onClick={handleSearchIconClick}
            isMobile={isTouchDevice}
            selectedSearchEntity={selectedSearchEntity}
          />
          {!isTouchDevice && <MainMenu items={menuItemData} />}
        </S.MainMenu>
        <SecondaryMenu
          isSearchOpened={isSearchOpened}
          isMobileMenuOpened={isMobileMenuOpened}
          isTouchDevice={isTouchDevice}
        />
      </S.Container>

      {isMobileMenuOpened && (
        <MobileMenu
          items={mobileMenuItemData}
          closeMobileMenu={closeMobileMenu}
          isBasic={isBasic}
          searchButton={
            <SearchButton
              onClick={handleSearchIconClick}
              selectedSearchEntity={selectedSearchEntity}
              isMobile
              data-testid="mobile-search-button"
            />
          }
        />
      )}
    </>
  );
};
