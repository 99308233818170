import throttle from 'lodash/throttle';
import { useState } from 'react';

import { config } from '~/config';
import { usePortfolio } from '~/stores/use-portfolio/usePortfolio';

import { BaseWs } from '../../services/base-ws/BaseWs';

export enum EventAction {
  ConnectionSuccess = 'connection.success',
  ConnectionError = 'connection.error',
}

export enum ResponseEventType {
  Portfolio = 'portfolio',
}

export interface WsNotifierResponse {
  title: string;
  body: string;
  event: {
    action: EventAction;
    type: ResponseEventType;
  };
  metadata?: PortfolioMetadata;
}

interface PortfolioMetadata {
  portfolio_id: string;
  provider_id: string;
  provider_name: string;
}

interface WsNotifierHook {
  wsResponse?: WsNotifierResponse;
  portfolioWsResponse?: PortfolioMetadata;
  init: () => BaseWs<WsNotifierResponse>;
}

const {
  api: { wsNotifierRoot },
} = config;

export const useWsNotifier = (): WsNotifierHook => {
  const [portfolioData, setPortfolioData] = useState<PortfolioMetadata>();
  const [data, setData] = useState<WsNotifierResponse>();
  const handlePortfolioWebsocketResponse = usePortfolio(
    state => state.handlePortfolioWebsocketResponse
  );

  const init = () => {
    const wsNotifier = new BaseWs<WsNotifierResponse>(wsNotifierRoot);
    wsNotifier.onmessage = throttle((wsResponse: WsNotifierResponse) => {
      if (wsNotifier.closed) {
        return;
      }
      setData(wsResponse);
      setPortfolioData(wsResponse.metadata);

      if (wsResponse.event.type === ResponseEventType.Portfolio) {
        handlePortfolioWebsocketResponse(wsResponse);
      }
    }, 100);
    return wsNotifier;
  };

  return {
    init,
    wsResponse: data,
    portfolioWsResponse: portfolioData,
  };
};
