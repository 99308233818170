import React, { FC } from 'react';
import { Navigate, useMatch } from 'react-router-dom';

export interface RedirectProps {
  to: string;
  from: string;
}

export const Redirect: FC<RedirectProps> = ({ to, from }) => {
  const match = useMatch(from);

  const redirectUrl = match?.params
    ? Object.entries(match.params).reduce(
        (redirectUrl, [paramName, paramValue]) =>
          paramValue
            ? redirectUrl.replace(`:${paramName}`, paramValue)
            : redirectUrl,
        to
      )
    : to;

  return <Navigate to={redirectUrl} replace />;
};
