import {
  borderRadius,
  customScrollMinimal,
  typographyV2,
} from '@toggle/design-system';
import styled from 'styled-components';

export const PastEpisodesTableRoot = styled.div`
  ${borderRadius.r1};
  display: flex;
  position: relative;
  width: 100%;
  border: 1px solid var(--border-soft);
  overflow: hidden;

  tbody {
    ${customScrollMinimal};
    display: block;
    width: 100%;
    overflow-y: auto;
    max-height: 328px;

    tr {
      background-color: var(--background-soft);
    }
  }

  tr {
    display: table;
    width: 100%;
    table-layout: fixed;

    &:last-child > td {
      border-bottom: none;
    }
  }

  th,
  td {
    padding: 12px;
  }
`;

export const ExtraTableHeaderRow = styled.tr`
  th {
    ${typographyV2.bodyXs};
    color: var(--text-soft);
    background-color: var(--surface-default);
    border-bottom: 1px solid var(--border-soft);
    padding: 4px 12px;
    text-align: start;
  }
`;
