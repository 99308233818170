import { subWeeks } from 'date-fns';

export const ASSET_PRIMARY_METHOD_MAP = {
  price: 'Price',
  yield: 'Yield',
  option_adj_spread: 'Spread',
};

export const MIN_ARTICLES_STARS = 5;
const INSIGHTS_LIMIT_IN_WEEKS = 1;
export const ARTICLE_INSIGHTS_START_DATE = subWeeks(
  new Date(),
  INSIGHTS_LIMIT_IN_WEEKS
);

export enum CARD_TYPES {
  BULLISH = 'bullish',
  BEARISH = 'bearish',
  LOCKED = 'locked',
  NEWS = 'news',
}
