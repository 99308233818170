import { Toggle } from '@toggle/design-system';
import React, { Dispatch, FC, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import * as S from './EventsModal.styles';

export type ChartEvents = 'TMI' | 'TNI';

export type EventsConfig = {
  [K in ChartEvents]: boolean;
};

interface EventsModalProps {
  onClose: () => void;
  eventsConfig: EventsConfig;
  setEventsConfig: Dispatch<SetStateAction<EventsConfig>>;
}

export const EventsModal: FC<EventsModalProps> = ({
  onClose,
  eventsConfig,
  setEventsConfig,
}) => {
  const { t } = useTranslation('chart');

  const toggleHandler = (isChecked: boolean, id: ChartEvents) => {
    setEventsConfig(prev => ({ ...prev, [id]: isChecked }));
  };

  return (
    <S.StyledModal
      data-testid="events-modal"
      isOpen
      title={t('chart:widget.events')}
      onClose={onClose}
    >
      <S.Container>
        {Object.keys(eventsConfig).map(id => (
          <S.Row key={id}>
            <span>
              {/* i18next-extract-mark-context-next-line ["TMI", "TNI"] */}
              {t('chart:widget.event', { context: id })}
            </span>
            <Toggle
              size="medium"
              isChecked={eventsConfig[id as ChartEvents]}
              onToggle={checked => toggleHandler(checked, id as ChartEvents)}
            />
          </S.Row>
        ))}
      </S.Container>
    </S.StyledModal>
  );
};
