import { typography } from '@toggle/design-system';
import styled from 'styled-components';

import { Table } from '~/shared/components/table/Table';

export const StyledTable = styled(Table)`
  ${Table.Styled.RowCell} {
    ${typography.dingo};
    min-width: 90px;

    &.markedCell {
      border: 0px solid var(--primary-color);
      border-top-width: 2px;
      border-bottom-width: 2px !important;

      &:last-child {
        border-right-width: 2px !important;
      }
    }
  }

  ${Table.Styled.HeaderCell} {
    justify-content: flex-start;
    border-right: 2px solid var(--primary-color);

    &.markedCell {
      background-color: var(--primary-color);
      border-radius: 8px 0 0 8px;
      color: var(--base--black);
    }
  }
`;
