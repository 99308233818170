import { TFunction } from 'i18next';

// Checks that a number is no longer than 4 decimals and can start with a leading dot.
// https://regex101.com/r/qaP9Gg/1
export const fourDecimalRegex = /^-?((\d+)?(\.)?(\d{1,4})?)?$/;
// Whole number no decimals
export const wholeNumberRegex = /^(0|[1-9]\d*(?!.))$/;

export enum ConditionSteps {
  asset = 0,
  data = 1,
  condition = 2,
  value = 3,
}

export interface ConditionTile {
  step: number;
  value: string;
}

export enum ConditionEnum {
  crosses_above = 'crosses_above',
  crosses_below = 'crosses_below',
  above = 'above',
  below = 'below',
  between = 'between',
  rises_by = 'rises_by',
  falls_by = 'falls_by',
  rises_by_level = 'rises_by_level',
  falls_by_level = 'falls_by_level',
}

export enum ConditionDateFormat {
  days = 'd',
  weeks = 'w',
  months = 'm',
}

export enum ValueFields {
  minValue = 'min_value',
  maxValue = 'max_value',
  value = 'value',
  durationValue = 'duration',
}

interface ConditionsPayloadOptional {
  order?: number;
  entity?: string;
  entityName?: string;
  snake?: string;
  snakeName?: string;
  condition?: ConditionEnum;
  value?: number;
  display?: string;
  duration?: number;
  duration_unit?: ConditionDateFormat;
  min_value?: number;
  max_value?: number;
}

interface SuggestionsResponse {
  conditions: ConditionsPayload[];
}

export interface ConditionsPayload extends ConditionsPayloadOptional {
  order: number;
  entity: string;
  snake: string;
  condition: ConditionEnum;
}

export interface GTKConditionResponse {
  snake: string;
  condition: ConditionEnum;
  order: number;
  value: number;
  duration?: number;
  durationUnit?: string;
}

export interface ScenarioResponse extends SuggestionsResponse {
  entity: string;
  entityName: string;
  id: string;
  snake: string;
  snakeName: string;
}
export interface SnakeObject {
  snake: string;
  entity: string;
  value?: number;
  snakeName?: string;
}

export const BaseConditions: ConditionsPayload = {
  order: 0,
  entity: '',
  entityName: '',
  snake: '',
  snakeName: '',
  display: '',
  condition: ConditionEnum.above,
};

const DATE_FORMAT = (t: TFunction) => ({
  [ConditionDateFormat.days]: (count: number) =>
    t('analyze:experiment.conditionsList.days', {
      count,
    }),
  [ConditionDateFormat.weeks]: (count: number) =>
    t('analyze:experiment.conditionsList.weeks', {
      count,
    }),
  [ConditionDateFormat.months]: (count: number) =>
    t('analyze:experiment.conditionsList.months', {
      count,
    }),
});

export interface ConditionPermissions {
  isMinMaxValue: boolean;
  isDuration: boolean;
  isPercentageValue: boolean;
}

export const getConditionPermissions = (condition: ConditionEnum) => {
  const permissionsObj: ConditionPermissions = {
    isMinMaxValue: false,
    isDuration: false,
    isPercentageValue: false,
  };

  switch (condition) {
    case ConditionEnum.above:
    case ConditionEnum.below:
    case ConditionEnum.crosses_above:
    case ConditionEnum.crosses_below:
      break;
    case ConditionEnum.between:
      permissionsObj.isMinMaxValue = true;
      break;
    case ConditionEnum.rises_by:
    case ConditionEnum.falls_by:
      permissionsObj.isPercentageValue = true;
      permissionsObj.isDuration = true;
      break;
    case ConditionEnum.rises_by_level:
    case ConditionEnum.falls_by_level:
      permissionsObj.isDuration = true;
      break;
    default:
      return permissionsObj;
  }
  return permissionsObj;
};

const numFormat = (value: number): string => {
  return value.toLocaleString('en-US', {
    maximumFractionDigits: 2,
  });
};

const getConditionFeatureLabel = (c: ConditionsPayload, t: TFunction) => {
  const featureTranslationKey = `analyze:experiment.conditionsList.${c.condition}`;
  let featureLabel = '';

  switch (c.condition) {
    case ConditionEnum.above:
    case ConditionEnum.below:
    case ConditionEnum.crosses_above:
    case ConditionEnum.crosses_below:
      featureLabel = `${t(featureTranslationKey)} ${numFormat(c.value ?? 0)}`;
      break;
    case ConditionEnum.between:
      featureLabel = t('analyze:experiment.conditionsList.betweenValues', {
        value1: numFormat(c.min_value ?? 0),
        value2: numFormat(c.max_value ?? 0),
      });
      break;
    case ConditionEnum.rises_by:
    case ConditionEnum.falls_by:
      featureLabel = `${t(featureTranslationKey, {
        percentage: numFormat(c.value ?? 0),
      })}`;
      break;
    case ConditionEnum.rises_by_level:
    case ConditionEnum.falls_by_level:
      featureLabel = `${t(featureTranslationKey, {
        change: numFormat(c.value ?? 0),
      })}`;
      break;
    default:
      return featureLabel;
  }

  if (c.duration && c.duration_unit) {
    featureLabel += ` ${DATE_FORMAT(t)[c.duration_unit](c.duration)}`;
  }

  return featureLabel;
};

export const buildCustomConditionLabel = (
  c: ConditionsPayload,
  t: TFunction,
  company?: string,
  dataSeries?: string
) => {
  const featureLabel = getConditionFeatureLabel(c, t);
  return featureLabel ? `${company} ${dataSeries} ${featureLabel}` : '';
};

export const clearStepsPayloads = (
  stepNumber: number,
  editedPayload: ConditionsPayload
): ConditionsPayload => {
  const { order, display, entity, entityName, snake, snakeName, condition } =
    editedPayload;
  switch (stepNumber) {
    case ConditionSteps.asset:
      return { ...BaseConditions, order, display, entity, entityName };
    case ConditionSteps.data:
      return {
        order,
        display,
        entity,
        entityName,
        snake,
        snakeName,
        condition: ConditionEnum.above,
      };
    case ConditionSteps.condition:
      return {
        order,
        display,
        entity,
        entityName,
        snake,
        snakeName,
        condition,
      };
    default:
      return BaseConditions;
  }
};
