import { config } from '~/config';
import { TradingProvider } from '~/stores/use-portfolio/utils/use-portfolio-utils';

import {
  OrderError,
  PreviewSuccess,
  Transaction,
} from '../../use-transaction/useTransaction';

export enum SubmittedOrderState {
  Idle,
  ReviewLoading,
  Review,
  Processing,
  ReviewError,
  ConfirmationRequired,
}

export const isPreviewSuccess = (
  response: PreviewSuccess | OrderError
): response is PreviewSuccess => response.error === null;

export const isReviewRequired = (transaction: Transaction) =>
  transaction.brokerId === TradingProvider.Ib;

export const mapTradingProviderToContactLink = {
  [TradingProvider.Ib]: config.ibkrContactLink,
  [TradingProvider.Tradestation]: config.tradestationContactLink,
};
