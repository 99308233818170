import { HorizontalHighLow } from '@toggle/design-system';
import { checkCellValueStyle } from '@toggle/helpers';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { CompassPastEpisodes } from '~/views/analyze/store/useExperimentStore';

import { formatPercentage } from '../helpers/scenario-helpers';
import * as S from './RiskReturn.styles';

interface RiskReturnProps {
  selectedPeriod: CompassPastEpisodes;
}

export const RiskReturn = ({ selectedPeriod }: RiskReturnProps) => {
  const { t } = useTranslation(['widget', 'analyze']);

  const riskReturnStats = [
    {
      title: t('analyze:experiment.summary.best'),
      value: selectedPeriod.best,
    },
    {
      title: t('analyze:experiment.summary.upside'),
      value: selectedPeriod.upside,
    },
    {
      title: t('analyze:experiment.summary.median'),
      value: selectedPeriod.median,
    },
    {
      title: t('analyze:experiment.summary.downside'),
      value: selectedPeriod.downside,
    },
    {
      title: t('analyze:experiment.summary.worst'),
      value: selectedPeriod.worst,
    },
  ];

  const formattedBarData = {
    low: selectedPeriod.downside * 100,
    median: selectedPeriod.median * 100,
    high: selectedPeriod.upside * 100,
  };

  return (
    <S.RiskReturnContainer>
      <S.RiskReturnTitle>{t('scenario.riskReturn')}</S.RiskReturnTitle>
      {riskReturnStats.map(stat => (
        <S.RiskReturnText
          key={stat.title}
          $numericalStyle={checkCellValueStyle(stat.value)}
        >
          {stat.title} <span>{formatPercentage(stat.value)}%</span>
        </S.RiskReturnText>
      ))}
      <HorizontalHighLow
        width={420}
        height={100}
        withLabels
        data={formattedBarData}
      />
    </S.RiskReturnContainer>
  );
};
