import { typographyV2 } from '@toggle/design-system';
import { OptionalN } from '@toggle/helpers';
import styled from 'styled-components';

import { ArticleDirection } from '~/declarations/toggle-api-enums';

import { sharedAOWidgetCard } from '../shared.styles';

export const Card = styled.a`
  ${sharedAOWidgetCard}
`;

const directionToColor = {
  [ArticleDirection.Bullish]: 'var(--viz-bullish)',
  [ArticleDirection.Bearish]: 'var(--viz-bearish)',
};

export const CardHeading = styled.p<{
  $direction: OptionalN<ArticleDirection>;
}>`
  ${typographyV2.titleXs}
  color:  ${props =>
    props.$direction !== null
      ? directionToColor[props.$direction]
      : 'var(--base--0)'};
`;

export const CardInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 85%;
`;
