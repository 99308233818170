import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import * as S from '../Content.styles';

export function StarsContent() {
  const { t } = useTranslation('helperWidget');

  return (
    <S.Content>
      <S.Paragraph>
        <Trans
          i18nKey="helperWidget:insight.content.stars.firstParagraph"
          components={[
            <S.MiddleAlignedIcon
              iconName="Rating"
              size={18}
              fillColor="var(--primary-color)"
              key=""
            />,
          ]}
        />
      </S.Paragraph>
      <S.Paragraph>
        {t('helperWidget:insight.content.stars.secondParagraph')}
      </S.Paragraph>
      <S.Paragraph>
        {t('helperWidget:insight.content.stars.thirdParagraph')}
      </S.Paragraph>
    </S.Content>
  );
}
