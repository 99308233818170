import { Side } from '@floating-ui/react';
import styled, { css } from 'styled-components';

import { borderRadius } from '~/design-tokens/border-radius/borderRadius';
import { elevation } from '~/design-tokens/elevation/elevation';
import {
  fontWeight,
  typographyV2,
} from '~/design-tokens/typography/Typography.styles';

interface ArrowProps {
  x?: number;
  y?: number;
  placement: Side;
}

const position = ({ x, y, placement }: ArrowProps) => {
  switch (placement) {
    case 'left':
      return css`
        left: -4px;
        top: ${y}px;
      `;
    case 'right':
      return css`
        right: -4px;
        top: ${y}px;
      `;
    case 'bottom':
      return css`
        bottom: -4px;
        left: ${x}px;
      `;
    case 'top':
    default:
      return css`
        top: -4px;
        left: ${x}px;
      `;
  }
};

export const ArrowIcon = styled.div<ArrowProps>`
  position: absolute;
  background-color: var(--surface-inverse);
  width: 8px;
  height: 8px;
  transform: rotate(45deg);
  z-index: 1;
  ${({ placement, x, y }) => position({ x, y, placement })}
`;

export const TooltipBubble = styled.div`
  background-color: var(--surface-inverse);
  border: solid 1px var(--surface-inverse);
  padding: 8px 12px;
  ${borderRadius.r1}
  ${typographyV2.bodySm}
  ${elevation.strong}
  color: var(--text-inverse);
  max-width: 340px;
  overflow-wrap: break-word;
  white-space: normal;
  z-index: 1;
  text-align: left;
`;

export const MobileTapClose = styled.span`
  display: block;
  ${typographyV2.uiXs}
  margin-top: 4px;
  font-weight: ${fontWeight.medium};
  color: var(--text-disabled);
  text-transform: uppercase;
`;
