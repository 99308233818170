import { typography } from '@toggle/design-system';
import styled from 'styled-components';

export const MobileNotSupportedRoot = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  margin: 0 40px;
`;

export const Message = styled.p`
  ${typography.emu}
  padding: 60px 0 20px;
  text-align: center;
  color: var(--base--0);
`;
