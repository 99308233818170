import React, { FC } from 'react';

import { appPaths } from '~/routes/app-paths';

import * as S from './AppHeaderNoNav.styles';

export const AppHeaderNoNav: FC = () => (
  <S.HeaderPanel data-testid="no-nav-header">
    <a href={appPaths.base}>
      <S.SvgLogo />
    </a>
  </S.HeaderPanel>
);
