import { deviceConstraints } from '@toggle/design-system';
import styled from 'styled-components';

import { BookmarkButton } from '~/views/news/components/bookmark-button/BookmarkButton';

export const SectorHeader = styled.div<{ $bookmarked: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 40px;
  width: 100%;

  & > * {
    background-color: ${({ $bookmarked }) =>
      $bookmarked ? 'var(--primary-color)' : 'var(--base--45)'};
  }
`;

export const Bar = styled.div`
  height: 8px;
  width: 100%;
  order: 0;
`;

export const HeaderBookmarkButton = styled(BookmarkButton)`
  order: 1;
  padding: 2px 24px;
  color: var(--base--100);
  border-radius: 0 0 4px 4px;
  margin: 0 auto;

  @media ${deviceConstraints.tablet} {
    border-radius: 0 0 4px;
    margin: 0;
  }

  svg {
    fill: var(--base--100);
  }

  &:hover {
    color: var(--base--100);
    text-decoration: none;
  }

  &:hover + ${Bar} {
    background-color: var(--action-primary-hover);
  }
`;
