import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  CompassPastEpisodes,
  QualityChecklist,
} from '~/views/analyze/store/useExperimentStore';
import { QUALITY_CHECKLIST } from '~/views/analyze/store/useExperimentStore.enums';

import { RiskReturn } from '../risk-return/RiskReturn';
import * as S from './PeriodView.styles';

export interface PeriodViewProps {
  selectedPeriod: CompassPastEpisodes;
  goBack: () => void;
  checklistData?: QualityChecklist;
  starsAmount?: number;
}

export const PeriodView = ({
  selectedPeriod,
  goBack,
  starsAmount,
  checklistData,
}: PeriodViewProps) => {
  const { t } = useTranslation(['widget', 'common', 'analyze']);

  return (
    <S.PeriodViewRoot>
      <S.BackButton
        label={t('chat:goBack')}
        iconName="ArrowLeft"
        onClick={goBack}
      />
      <S.QualityTitle>
        {t('scenario.periodHorizonSummary', {
          period: selectedPeriod.period.toUpperCase(),
        })}
      </S.QualityTitle>
      <RiskReturn selectedPeriod={selectedPeriod} />
      <S.QualityTitle>
        {t('scenario.scenarioQuality')}
        {starsAmount && (
          <div>
            {Array.from({ length: 8 }, (_, idx) => (
              <S.ActiveStar
                key={idx}
                $active={idx + 1 <= starsAmount}
                iconName="Rating"
                size={16}
              />
            ))}
          </div>
        )}
      </S.QualityTitle>
      <S.QualityChecklistSectionStyled
        $isTop
        title={t('analyze:experiment.qualityChecklist.robustSection')}
        listItems={[
          QUALITY_CHECKLIST.CROSS_BUSINESS_CYCLE,
          QUALITY_CHECKLIST.SEVERAL_EPISODES,
          QUALITY_CHECKLIST.SPARSE,
          QUALITY_CHECKLIST.FREQUENT,
        ]}
        checklistData={checklistData}
      />

      <S.QualityChecklistSectionStyled
        title={t('analyze:experiment.qualityChecklist.attractiveSection')}
        listItems={[
          QUALITY_CHECKLIST.HIGH_HIT_RATIO_IN_SAMPLE,
          QUALITY_CHECKLIST.HIGH_HIT_RATIO_OUT_SAMPLE,
          QUALITY_CHECKLIST.HIGH_PATH_RISK_RETURN,
          QUALITY_CHECKLIST.HIGH_PNL_DRAWDOWN_RATIO,
        ]}
        checklistData={checklistData}
      />
    </S.PeriodViewRoot>
  );
};
