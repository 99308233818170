import { JSONData } from '~/declarations/standard';

export class FetchError extends Error {
  constructor(
    public status: number,
    public statusText: string,
    public data: JSONData<unknown> | Blob | string
  ) {
    super(statusText);
    Object.setPrototypeOf(this, new.target.prototype);
  }
}

export class FetchUnauthError extends FetchError {}
