import styled, { css } from 'styled-components';

import { borderRadius } from '~/design-tokens/border-radius/borderRadius';
import { typographyV2 } from '~/design-tokens/typography/Typography.styles';
import { timingFunction } from '~/utils/transitions';

export const CategoryGroupRoot = styled.section`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const CategoryTitle = styled.h4`
  color: var(--text-soft);
  text-transform: uppercase;
  ${typographyV2.bodyXs};
  padding: 0 8px 0 24px;
`;

export const Items = styled.nav`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const IconWrapper = styled.span`
  display: flex;

  svg {
    fill: var(--icon-default);
  }
`;

export const MainLabel = styled.span`
  color: var(--text-default);
`;

export const BadgeWrapper = styled.span`
  margin-left: auto;
`;

export const ItemWrapper = styled.div<{ $isActive: boolean }>`
  position: relative;
  width: 100%;

  ::before {
    content: '';
    position: absolute;
    left: 0;
    border-radius: 0 2px 4px 0;
    height: 100%;
    width: 4px;
    background-color: var(--surface-primary);
    transform: translate(-100%);
    opacity: 0;
    transition: transform 300ms ${timingFunction.base};
  }

  ${({ $isActive }) =>
    $isActive &&
    css`
      ::before {
        opacity: 1;
        transform: translate(0);
      }
    `}
`;

export const ItemBackground = styled.div`
  ${borderRadius.r1};
  position: absolute;
  inset: 0;
  pointer-events: none;
  margin-left: 8px;
  width: calc(100% - 8px);
  transition: transform 300ms ${timingFunction.base};
`;

export const Item = styled.button<{ $isActive: boolean }>`
  ${typographyV2.bodyBase};
  position: relative;
  z-index: 1;
  background: none;
  border: none;
  text-wrap: nowrap;
  cursor: pointer;
  outline: none;
  display: flex;
  align-items: center;
  gap: 8px;
  width: calc(100% - 8px);
  height: 40px;
  margin-left: 8px;
  padding: 8px 16px;

  :hover,
  :focus-visible {
    & + ${ItemBackground} {
      background-color: var(--surface-hover);
    }
  }

  :disabled {
    pointer-events: none;

    svg {
      fill: var(--icon-disabled);
    }

    ${MainLabel} {
      color: var(--text-disabled);
    }
  }

  ${({ $isActive }) =>
    $isActive &&
    css`
      & + ${ItemBackground} {
        background-color: var(--surface-pressed);
      }

      ${MainLabel} {
        color: var(--text-primary);
      }

      svg {
        fill: var(--icon-primary);
        path {
          fill: var(--icon-primary);
        }
      }
    `}
`;
