import { TFunction } from 'i18next';

import { appPaths, queryKeys } from '~/routes/app-paths';

import { AnalyzeViews } from '../../analyze/AnalyzeViews';
import { ArticleInsightCard } from '../../analyze/hooks/use-article-insight-cards/useInsightCards';
import { Metadata, SupportedIntents } from '../use-chat-store/chat-store.types';

export const getWhatIfExchangeName = (exchange?: string) => {
  // TODO:Should be replaced once BE has a better solution. Thread: https://toggleglobal.slack.com/archives/C053NT28Q8M/p1683637367471909
  if (exchange === 'nasd') {
    return 'nasdaq';
  }
  return exchange;
};

interface ActionButtonConfigProps {
  t: TFunction;
  metaData?: Metadata;
  activeArticleInsight?: ArticleInsightCard;
}

export const getActionButtonConfig = ({
  t,
  metaData,
  activeArticleInsight,
}: ActionButtonConfigProps) => {
  if (!metaData?.entity || !metaData.supported) {
    return null;
  }

  const { entity, intent } = metaData;
  const config = {} as { link: string; label: string };

  switch (intent as SupportedIntents) {
    case 'earnings':
      config.label = t('chat:actionButtons.openWhatIfEarnings');
      config.link = `/earnings/${getWhatIfExchangeName(
        entity.exchange?.code
      )}-${entity.ticker}`;
      break;

    case 'scenario':
      if (activeArticleInsight) {
        config.label = t('chat:actionButtons.openArticleScenario');
        config.link = `${appPaths.analyze}/${entity.tag}?${queryKeys.view}=${AnalyzeViews.SCENARIO}&articleId=${activeArticleInsight.article_id}`;
      } else {
        config.label = t('chat:actionButtons.openScenario');
        config.link = `/analyze/${entity.tag}?view=scenario`;
      }
      break;

    default:
      config.label = t('chat:actionButtons.openAssetOverview', {
        asset: entity.entitySubheading,
      });
      config.link = `${appPaths.analyze}/${entity.tag}`;
      break;
  }

  return config;
};
