import { deviceConstraints } from '@toggle/design-system';
import styled from 'styled-components';

import { Box } from '../box';

export const Container = styled.div`
  display: grid;
  grid-template-areas:
    'fundamentals'
    'technical'
    'both';

  @media ${deviceConstraints.desktopSmall} {
    grid-template-areas:
      'fundamentals technical'
      'both both';
    grid-gap: 16px;
  }
`;

export const FundamentalsBox = styled(Box)`
  grid-area: fundamentals;
  @media ${deviceConstraints.desktopSmall} {
    flex-direction: column;
    & > div {
      &:first-child {
        padding-bottom: 13px;
      }
      &:last-child {
        margin-left: auto;
      }
    }
  }
`;

export const TechnicalBox = styled(Box)`
  grid-area: technical;
  @media ${deviceConstraints.desktopSmall} {
    flex-direction: column;
    & > div {
      &:first-child {
        padding-bottom: 13px;
      }
      &:last-child {
        margin-left: auto;
      }
    }
  }
`;

export const BothBox = styled(Box)`
  grid-area: both;
  text-align: center;
`;
