import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { DocumentItem } from '~/api/legal-documents/legal-documents-schema';
import { Markdown } from '~/shared/components/markdown/Markdown';

import { PDFViewer } from '../PDFViewer';
import * as S from './ReAgreeTos.styles';

interface ReAgreeTosProps {
  doc: DocumentItem;
  handleDocumentAcceptance: (id: string) => void;
}

export const ReAgreeTos: FC<ReAgreeTosProps> = ({
  doc,
  handleDocumentAcceptance,
}) => {
  const { t } = useTranslation('common');

  return (
    <S.StyledModal
      isOpen={!!doc}
      withClose={false}
      outsideClickClose={false}
      primaryBtnProps={{
        label: t('common:documents.acceptAndContinue'),
        onClick: () => handleDocumentAcceptance(doc.id),
        'data-testid': 'accept-button',
      }}
    >
      {doc && (
        <S.ViewerSection>
          <S.Header>
            {doc.changes && (
              <Markdown
                markdownProps={{
                  children: doc.changes,
                  components: {
                    h1: ({ children }) => <S.Title>{children}</S.Title>,
                    h2: ({ children }) => <S.Subtitle>{children}</S.Subtitle>,
                    p: ({ children }) => <S.Subtext>{children}</S.Subtext>,
                    ul: ({ children }) => (
                      <S.WhatsNewList>{children}</S.WhatsNewList>
                    ),
                    li: ({ children }) => (
                      <S.WhatsNewListItem>{children}</S.WhatsNewListItem>
                    ),
                  },
                }}
              />
            )}
          </S.Header>
          <PDFViewer doc={doc} />
          <S.ContactText>{t('common:documents.contactUs')}</S.ContactText>
        </S.ViewerSection>
      )}
    </S.StyledModal>
  );
};
