import Add from './icons/add.svg';
import AddBoldCircle from './icons/add-bold-circle.svg';
import AddLightCircle from './icons/add-light-circle.svg';
import AddRemove from './icons/add-remove.svg';
import Ai from './icons/ai.svg';
import AlarmClock from './icons/alarm-clock.svg';
import AmericanExpress from './icons/american-express.svg';
import AngleLightDown from './icons/angle-light-down.svg';
import AngleLightLeft from './icons/angle-light-left.svg';
import AngleLightRight from './icons/angle-light-right.svg';
import AngleLightUp from './icons/angle-light-up.svg';
import ArrowCircleDown from './icons/arrow-circle-down.svg';
import ArrowCircleLeft from './icons/arrow-circle-left.svg';
import ArrowCircleRight from './icons/arrow-circle-right.svg';
import ArrowCircleUp from './icons/arrow-circle-up.svg';
import ArrowDown from './icons/arrow-down.svg';
import ArrowDownLeft from './icons/arrow-down-left.svg';
import ArrowFilter from './icons/arrow-filter.svg';
import ArrowLeft from './icons/arrow-left.svg';
import ArrowRight from './icons/arrow-right.svg';
import ArrowUp from './icons/arrow-up.svg';
import Astronaut from './icons/astronaut.svg';
import BadgePercentage from './icons/badge-percentage.svg';
import Bars from './icons/bars.svg';
import BarsSortDown from './icons/bars-sort-down.svg';
import BarsSortUp from './icons/bars-sort-up.svg';
import BearishBear from './icons/bearish-bear.svg';
import Binary from './icons/binary.svg';
import Binoculars from './icons/binoculars.svg';
import Bolt from './icons/bolt.svg';
import Book from './icons/book.svg';
import BookOpenReader from './icons/book-open-reader.svg';
import BookmarkFill from './icons/bookmark-fill.svg';
import BookmarkOutline from './icons/bookmark-outline.svg';
import Briefcase from './icons/briefcase.svg';
import BriefcaseLight from './icons/briefcase-light.svg';
import BullishBull from './icons/bullish-bull.svg';
import Calendar from './icons/calendar.svg';
import CalendarClock from './icons/calendar-clock.svg';
import Candlestick from './icons/candlestick.svg';
import Chain from './icons/chain.svg';
import ChartArrow from './icons/chart-arrow.svg';
import ChartBar from './icons/chart-bar.svg';
import ChartCandlestick from './icons/chart-candlestick.svg';
import ChartLine from './icons/chart-line.svg';
import ChartNetwork from './icons/chart-network.svg';
import ChartPie from './icons/chart-pie.svg';
import ChartPieSimple from './icons/chart-pie-simple.svg';
import Chat from './icons/chat.svg';
import ChatNew from './icons/chat-new.svg';
import Checkmark from './icons/checkmark.svg';
import CheckmarkBoldCircle from './icons/checkmark-bold-circle.svg';
import CheckmarkFill from './icons/checkmark-fill.svg';
import CheckmarkLightCircle from './icons/checkmark-light-circle.svg';
import ChevronBoldDown from './icons/chevron-bold-down.svg';
import ChevronBoldLeft from './icons/chevron-bold-left.svg';
import ChevronBoldRight from './icons/chevron-bold-right.svg';
import ChevronBoldUp from './icons/chevron-bold-up.svg';
import ChevronFillDown from './icons/chevron-fill-down.svg';
import ChevronFillLeft from './icons/chevron-fill-left.svg';
import ChevronFillRight from './icons/chevron-fill-right.svg';
import ChevronFillUp from './icons/chevron-fill-up.svg';
import ChevronLightDown from './icons/chevron-light-down.svg';
import ChevronLightLeft from './icons/chevron-light-left.svg';
import ChevronLightRight from './icons/chevron-light-right.svg';
import ChevronLightUp from './icons/chevron-light-up.svg';
import Clock from './icons/clock.svg';
import ClockRotateLeft from './icons/clock-rotate-left.svg';
import Close from './icons/close.svg';
import CloseBoldCircle from './icons/close-bold-circle.svg';
import CloseFillCircle from './icons/close-fill-circle.svg';
import CloseLightCircle from './icons/close-light-circle.svg';
import Coin from './icons/coin.svg';
import Comment from './icons/comment.svg';
import CommentSmile from './icons/comment-smile.svg';
import Compass from './icons/compass.svg';
import Compress from './icons/compress.svg';
import ComputerClassic from './icons/computer-classic.svg';
import Construction from './icons/construction.svg';
import Copy from './icons/copy.svg';
import Corn from './icons/corn.svg';
import Cow from './icons/cow.svg';
import CreditCard from './icons/credit-card.svg';
import CreditCardLight from './icons/credit-card-light.svg';
import Crop from './icons/crop.svg';
import Crosshair from './icons/crosshair.svg';
import DashedLine from './icons/dashed-line.svg';
import Desktop from './icons/desktop.svg';
import DinersClub from './icons/diners-club.svg';
import Discover from './icons/discover.svg';
import Dollar from './icons/dollar.svg';
import Dot from './icons/dot.svg';
import Download from './icons/download.svg';
import Edit from './icons/edit.svg';
import Ellipsis from './icons/ellipsis.svg';
import EnvelopeSolid from './icons/envelope-solid.svg';
import Euro from './icons/euro.svg';
import Exclamation from './icons/exclamation.svg';
import ExclamationBoldCircle from './icons/exclamation-bold-circle.svg';
import ExclamationFill from './icons/exclamation-fill.svg';
import ExclamationLightCircle from './icons/exclamation-light-circle.svg';
import ExclamationTriangle from './icons/exclamation-triangle.svg';
import Expand from './icons/expand.svg';
import Explore from './icons/explore.svg';
import ExploreLine from './icons/explore-line.svg';
import Extension from './icons/extension.svg';
import ExternalLink from './icons/external-link.svg';
import Facebook from './icons/facebook.svg';
import FaucetDrip from './icons/faucet-drip.svg';
import File from './icons/file.svg';
import FileChart from './icons/file-chart.svg';
import Filter from './icons/filter.svg';
import Fire from './icons/fire.svg';
import Gift from './icons/gift.svg';
import Globe from './icons/globe.svg';
import Grid from './icons/grid.svg';
import GripsDots from './icons/grips-dots.svg';
import HamburgerMenu from './icons/hamburger-menu.svg';
import HandBack from './icons/hand-back.svg';
import Handshake from './icons/handshake.svg';
import HeartEmpty from './icons/heart-empty.svg';
import HeartFill from './icons/heart-fill.svg';
import HidePassword from './icons/hide-password.svg';
import Home from './icons/home.svg';
import HospitalAlt from './icons/hospital-alt.svg';
import Indicators from './icons/indicators.svg';
import InformationLightCircle from './icons/info-light-circle.svg';
import InformationBoldCircle from './icons/information-bold-circle.svg';
import Insights from './icons/insights.svg';
import Instagram from './icons/instagram.svg';
import Jcb from './icons/jcb.svg';
import Lightbulb from './icons/lightbulb.svg';
import Line from './icons/line.svg';
import Linkedin from './icons/linkedin.svg';
import Lock from './icons/lock.svg';
import LockLight from './icons/lock-light.svg';
import Mail from './icons/mail.svg';
import MarkAsRead from './icons/mark-as-read.svg';
import Mastercard from './icons/mastercard.svg';
import Megaphone from './icons/megaphone.svg';
import Minus from './icons/minus.svg';
import MinusFillCircle from './icons/minus-fill-circle.svg';
import MoneyCheckEdit from './icons/money-check-edit.svg';
import MoneyWave from './icons/money-wave.svg';
import Moon from './icons/moon.svg';
import MugHot from './icons/mug-hot.svg';
import Newspaper from './icons/newspaper.svg';
import Notification from './icons/notification.svg';
import NotificationLine from './icons/notification-line.svg';
import OilCan from './icons/oil-can.svg';
import Pencil from './icons/pencil.svg';
import PencilRuler from './icons/pencil-ruler.svg';
import Play from './icons/play.svg';
import Plug from './icons/plug.svg';
import QrCode from './icons/qr-code.svg';
import Question from './icons/question.svg';
import QuestionMarkFill from './icons/questionmark-fill.svg';
import Rating from './icons/rating.svg';
import Reddit from './icons/reddit.svg';
import RightLeftLarge from './icons/right-left-large.svg';
import Rocket from './icons/rocket.svg';
import SatelliteDish from './icons/satellite-dish.svg';
import Save from './icons/save.svg';
import Search from './icons/search.svg';
import SendMessage from './icons/send-message.svg';
import Settings from './icons/settings.svg';
import Share from './icons/share.svg';
import Shield from './icons/shield.svg';
import ShowPassword from './icons/show-password.svg';
import Sign from './icons/sign.svg';
import SignOut from './icons/sign-out.svg';
import SignPost from './icons/sign-post.svg';
import Sort from './icons/sort.svg';
import StarEmpty from './icons/star-empty.svg';
import StarFill from './icons/star-fill.svg';
import Stop from './icons/stop.svg';
import Summarise from './icons/summarise.svg';
import Sun from './icons/sun.svg';
import SunRise from './icons/sunrise.svg';
import Swap from './icons/swap.svg';
import Sword from './icons/sword.svg';
import Sync from './icons/sync.svg';
import Telescope from './icons/telescope.svg';
import ThumbsDown from './icons/thumbs-down.svg';
import ThumbsUp from './icons/thumbs-up.svg';
import ToggleOff from './icons/toggle-off.svg';
import Tools from './icons/tools.svg';
import Trash from './icons/trash.svg';
import TShirt from './icons/tshirt.svg';
import Twitter from './icons/twitter.svg';
import TwitterColor from './icons/twitter-color.svg';
import User from './icons/user.svg';
import UserLine from './icons/user-line.svg';
import Vial from './icons/vial.svg';
import Vials from './icons/vials.svg';
import Visa from './icons/visa.svg';
import Washer from './icons/washer.svg';
import Watchlist from './icons/watchlist.svg';
import WavePulse from './icons/wave-pulse.svg';
import Youtube from './icons/youtube.svg';

export const SvgIconsMap = {
  Add,
  AddBoldCircle,
  Ai,
  AddLightCircle,
  AlarmClock,
  AmericanExpress,
  AngleLightDown,
  AngleLightLeft,
  AngleLightRight,
  AngleLightUp,
  ArrowCircleDown,
  ArrowCircleLeft,
  ArrowCircleRight,
  ArrowCircleUp,
  ArrowDown,
  ArrowFilter,
  ArrowLeft,
  ArrowRight,
  ArrowUp,
  Astronaut,
  BadgePercentage,
  Bars,
  BarsSortDown,
  BarsSortUp,
  BearishBear,
  Binary,
  Binoculars,
  Bolt,
  Book,
  BookOpenReader,
  BookmarkFill,
  BookmarkOutline,
  Briefcase,
  BriefcaseLight,
  BullishBull,
  Calendar,
  CalendarClock,
  Candlestick,
  Chain,
  ChartArrow,
  ChartNetwork,
  ChartLine,
  ChartBar,
  ChartCandlestick,
  Chat,
  ChatNew,
  Checkmark,
  CheckmarkBoldCircle,
  CheckmarkFill,
  CheckmarkLightCircle,
  ChevronFillDown,
  ChevronFillLeft,
  ChevronFillRight,
  ChevronFillUp,
  ChevronLightDown,
  ChevronLightLeft,
  ChevronLightRight,
  ChevronLightUp,
  ChevronBoldDown,
  ChevronBoldLeft,
  ChevronBoldRight,
  ChevronBoldUp,
  Clock,
  ClockRotateLeft,
  Close,
  CloseBoldCircle,
  CloseFillCircle,
  CloseLightCircle,
  Coin,
  Comment,
  CommentSmile,
  Compass,
  Compress,
  ComputerClassic,
  Construction,
  Copy,
  Corn,
  Cow,
  CreditCard,
  CreditCardLight,
  Crop,
  Crosshair,
  DashedLine,
  Desktop,
  DinersClub,
  Discover,
  Dollar,
  Dot,
  Download,
  Edit,
  Ellipsis,
  ArrowDownLeft,
  EnvelopeSolid,
  Euro,
  ExclamationBoldCircle,
  ExclamationFill,
  ExclamationLightCircle,
  ExclamationTriangle,
  Exclamation,
  Expand,
  Explore,
  ExploreLine,
  Extension,
  ExternalLink,
  Facebook,
  FaucetDrip,
  FileChart,
  File,
  Filter,
  Fire,
  Gift,
  Globe,
  Grid,
  GripsDots,
  HamburgerMenu,
  HandBack,
  Handshake,
  HeartEmpty,
  HeartFill,
  HidePassword,
  Home,
  HospitalAlt,
  Indicators,
  InformationBoldCircle,
  InformationLightCircle,
  Insights,
  Instagram,
  Jcb,
  Lightbulb,
  Line,
  Linkedin,
  Lock,
  LockLight,
  Mail,
  MarkAsRead,
  Mastercard,
  Megaphone,
  Minus,
  MinusFillCircle,
  MoneyCheckEdit,
  MoneyWave,
  Moon,
  MugHot,
  Newspaper,
  Notification,
  NotificationLine,
  OilCan,
  Pencil,
  PencilRuler,
  ChartPieSimple,
  ChartPie,
  Play,
  Plug,
  QrCode,
  Question,
  QuestionMarkFill,
  Rating,
  Reddit,
  RightLeftLarge,
  Rocket,
  SatelliteDish,
  Save,
  SendMessage,
  Search,
  Settings,
  Share,
  Shield,
  ShowPassword,
  Sign,
  SignOut,
  SignPost,
  Sort,
  StarFill,
  StarEmpty,
  Stop,
  Sun,
  SunRise,
  Summarise,
  AddRemove,
  Swap,
  Sync,
  Sword,
  Telescope,
  ThumbsUp,
  ThumbsDown,
  ToggleOff,
  Tools,
  TShirt,
  Trash,
  Twitter,
  TwitterColor,
  UserLine,
  User,
  Vial,
  Vials,
  Visa,
  Washer,
  Watchlist,
  WavePulse,
  Youtube,
} as const;

export type SvgIconNames = keyof typeof SvgIconsMap;
