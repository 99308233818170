import { deviceConstraints } from '@toggle/design-system';
import styled from 'styled-components';

import { ArticleCard } from '~/shared/components/article-card/ArticleCard';
import { LoadingView } from '~/shared/components/loading-view/LoadingView';

export const LastArticleCard = styled(ArticleCard)`
  margin: 0px 16px 16px;

  @media ${deviceConstraints.tablet} {
    margin: 0px 0px 16px;
  }
`;

export const LoadingContainer = styled(LoadingView)`
  height: 56px;
`;

export const Container = styled.section`
  margin-top: 40px;

  @media (min-width: 880px) and (max-width: 980px) {
    max-width: 550px;
  }

  @media ${deviceConstraints.desktop} {
    max-width: 816px;
  }

  @media ${deviceConstraints.desktopLarge} {
    align-items: center;
    justify-content: center;
    margin-right: 0;
    width: 100%;
  }
`;

export const FeedTitle = styled.h2`
  color: var(--base--70);
  position: relative;
  padding-bottom: 6px;
  margin-bottom: 32px;
  padding-left: 16px;

  @media ${deviceConstraints.tablet} {
    padding-left: 0;
  }

  &:before {
    content: '';
    display: inline-block;
    width: 100%;

    @media ${deviceConstraints.tablet} {
      width: 288px;
    }

    left: 0;
    height: 2px;
    position: absolute;
    top: 100%;
    background-color: var(--base--70);
  }
`;
