import {
  deviceConstraints,
  Icon,
  IconProps,
  Modal,
  typographyV2,
} from '@toggle/design-system';
import styled from 'styled-components';

export const StyledModal = styled(Modal)`
  ${Modal.Styled.ModalContainer} {
    max-height: calc(100% - 32px);
    overflow-y: auto;
    max-width: 800px;
    width: 95%;
  }
`;

export const Container = styled.div`
  padding: 0px 32px;
`;

export const PlansContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  width: 100%;
  padding: 0px 16px;
`;

export const MobilePlanContainer = styled.div`
  padding-bottom: 32px;

  & + & {
    border-top: 1px solid var(--border-soft);
    padding-top: 32px;
  }
`;

export const FeatureRow = styled.div`
  display: flex;
  align-items: center;
  padding-top: 12px;

  @media ${deviceConstraints.desktopSmall} {
    padding: 16px;
  }
`;

export const Column = styled.div`
  flex-grow: 1;
`;

export const Label = styled.p`
  max-width: 260px;
  ${typographyV2.bodyBase};
`;

export const Text = styled.p`
  margin-left: 20px;
  text-align: center;
  ${typographyV2.bodyBase};

  @media ${deviceConstraints.desktopSmall} {
    width: 200px;
  }
`;

export const Footer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 16px;
  border-top: 1px solid var(--border-soft);

  @media ${deviceConstraints.desktopSmall} {
    margin-top: 16px;
  }
`;

export const StyledIcon = styled(Icon).attrs<
  { $isIncluded: boolean },
  { iconName: IconProps['iconName'] }
>(props => ({
  iconName: props.$isIncluded ? 'Checkmark' : 'Minus',
  fillColor: props.$isIncluded ? 'var(--icon-primary)' : 'var(--icon-soft)',
}))<{ $isIncluded: boolean }>``;
