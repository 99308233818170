import { CheckBox, CheckBoxProps } from '@toggle/design-system';
import React, { FC } from 'react';

import { TooltipContainer } from '../tooltip-container/TooltipContainer';

export interface CheckBoxGroupItems {
  key: string;
  label: string;
  id: string;
  tooltip?: string;
}

export interface CheckBoxGroupProps {
  className?: string;
  itemClassName?: string;
  items: CheckBoxGroupItems[];
  onChange: CheckBoxProps['onChange'];
  value: string[];
}

export const CheckBoxGroup: FC<CheckBoxGroupProps> = ({
  items,
  className,
  itemClassName,
  onChange,
  value,
}) => {
  return (
    <ul className={className}>
      {items.map(option => (
        <li key={option.key}>
          <CheckBox
            onChange={onChange}
            className={itemClassName}
            label={option.label}
            name={option.key}
            id={option.id}
            checked={value?.includes(option.key)}
            tooltip={
              option.tooltip ? (
                <TooltipContainer label={option.tooltip} inPortal />
              ) : undefined
            }
            testId={option.id}
            data-testid={`checkbox-${option.id}`}
          />
        </li>
      ))}
    </ul>
  );
};
