import { Alert, AlertType } from '@toggle/design-system';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { UserRole } from '~/api/subscription/subscription-schema';
import { useDismissOnce } from '~/shared/hooks/use-dismiss-once/useDismissOnce';
import { SubscriptionAlertKeys } from '~/shared/hooks/use-remote-storage';
import { useSubscription } from '~/stores/use-subscription/useSubscription';

import * as S from '../alert-trial-ends/AlertTrialEnds.styles';

export interface AlertEndOfTrialProps {
  upgradeToPro: () => void;
  redirectToPlanMatrixPage: () => void;
}

export const AlertEndOfTrial: FC<AlertEndOfTrialProps> = ({
  upgradeToPro,
  redirectToPlanMatrixPage,
}) => {
  const { t } = useTranslation('subscription');
  const userSubscription = useSubscription(state => state.userSubscription);
  const { hasKey: wasTrial } = useDismissOnce({
    storageKey: SubscriptionAlertKeys.WasTrial,
    shouldSetKey: userSubscription.trial,
    shouldRemoveKey: !userSubscription.trial,
  });
  const isTrialEnded = !userSubscription.trial && wasTrial;
  const [show, setShow] = useState(isTrialEnded);

  if (!show) {
    return null;
  }

  const onClose = () => {
    setShow(false);
  };

  const onPrimaryBtnClick = () => {
    upgradeToPro();
    onClose();
  };

  const isSubscribedAfterTrial =
    userSubscription.role === UserRole.Copilot ||
    userSubscription.role === UserRole.Pro;

  return (
    <S.AlertTrialEndsRoot>
      {isSubscribedAfterTrial ? (
        <Alert
          data-testid="alert-subscribed-to-plan"
          type={AlertType.Warning}
          title={t('subscription:alerts.subscribedToPlanTitle', {
            plan: userSubscription.name,
          })}
          message={t('subscription:alerts.subscribedToPlanMessage')}
          onClose={onClose}
        />
      ) : (
        <Alert
          data-testid="alert-trial-has-ended"
          type={AlertType.Warning}
          title={t('subscription:alerts.trialHasEndedTitle', {
            plan: userSubscription.name,
          })}
          message={t('subscription:alerts.trialHasEndedMessage')}
          onClose={onClose}
          primaryBtn={{
            'data-testid': 'subscribe-pro-button',
            label: t('subscription:alerts.trialEnds.primaryBtnLabel'),
            onClick: onPrimaryBtnClick,
          }}
          secondaryBtn={{
            label: t('subscription:alerts.trialEnds.secondaryBtnLabel'),
            onClick: redirectToPlanMatrixPage,
          }}
        />
      )}
    </S.AlertTrialEndsRoot>
  );
};
