import { formatPercentage } from '@toggle/helpers/src/utils/numbers/numbers';
import React from 'react';

import { LABEL_WIDTH, margin } from '../constants';
import * as S from '../HorizontalBarChart.styles';

export interface AxisProps extends React.SVGProps<SVGGElement> {
  headerX: number;
  headerLabel: string;
  xScale: d3.ScaleLinear<number, number, never>;
  tickValues: number[];
  height: number;
}

export const Axis = ({
  headerX,
  headerLabel,
  xScale,
  tickValues,
  height,
  ...props
}: AxisProps) => {
  const isOdd = (i: number) => Boolean(i % 2);
  const lineStroke = (i: number) => (!isOdd(i) ? '2,2' : undefined);
  const strokeWidth = (tick: number) => (tick === 0 ? 2 : 1);

  return (
    <g data-testid="axis-root" {...props}>
      <S.AxisText
        data-testid="y-axis-right-header"
        x={headerX}
        y={0}
        dy="0.71em"
        isHeader
      >
        {headerLabel}
      </S.AxisText>

      {tickValues.map((tick, i) => (
        <g
          key={i}
          transform={`translate(${xScale(tick) + LABEL_WIDTH}, 0)`}
          data-testid="x-axis-item"
        >
          <S.AxisLine
            data-testid="x-axis-vertical-line"
            y1={margin.top}
            y2={height}
            strokeWidth={strokeWidth(tick)}
            strokeDasharray={lineStroke(i)}
          />
          {isOdd(i) && (
            <S.AxisText y={0} dy="0.71em" data-testid="x-axis-text-item">
              {tick > 0
                ? `+${formatPercentage(tick, { decimals: 0 })}`
                : `${formatPercentage(tick, { decimals: 0 })}`}
            </S.AxisText>
          )}
        </g>
      ))}
    </g>
  );
};
