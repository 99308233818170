import React, { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { IB_OAUTH_CODE_EVENT } from '~/api/portfolio-ib-oauth/oauth-ib-schema';

export function IBCallback() {
  const search = useLocation().search;
  const query = useMemo(() => {
    return new URLSearchParams(search);
  }, [search]);

  useEffect(() => {
    if (window.opener) {
      const event = new CustomEvent(IB_OAUTH_CODE_EVENT, {
        detail: {
          request_token: query.get('oauth_token'),
          verifier_token: query.get('oauth_verifier'),
        },
      });
      window.opener.dispatchEvent(event);
    }
  }, []);

  return <></>;
}
