import React, { ReactNode } from 'react';

import * as S from './Box.styles';

export interface BoxProps<T> {
  right?: string | ReactNode;
  left?: string | ReactNode;
  active?: boolean;
  onClick: (id: T) => void;
  id: T;
  className?: string;
}

export function Box<T>({
  right,
  left,
  active = false,
  onClick,
  id,
  className,
}: BoxProps<T>) {
  return (
    <S.BoxContainer
      data-testid={id}
      className={className}
      onClick={() => onClick(id)}
      $isActive={active}
      aria-selected={active}
    >
      <div>{left}</div>
      <div>{right}</div>
    </S.BoxContainer>
  );
}
