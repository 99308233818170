import { formatPercentage } from '@toggle/helpers/src/utils/numbers/numbers';
import * as d3 from 'd3';
import React from 'react';

import { getHumanFriendlyTicks } from '../helpers/chart-ticks/chart-ticks';
import { Axis } from './axis/Axis';
import { Bar } from './bar/Bar';
import {
  BAR_CHART_WIDTH,
  BAR_HEIGHT,
  BAR_PADDING_VERTICAL,
  CHART_WIDTH,
  LABEL_HEIGHT,
  margin,
  PORTFOLIO_ITEM_LEFT_PADDING,
  ROW_HEIGHT,
} from './constants';
import { HorizontalLabels } from './horizontal-labels/HorizontalLabels';
import * as S from './HorizontalBarChart.styles';
import { Waves } from './waves/Waves';

export interface MappedDomino {
  label: string;
  tag: string;
  median: number;
}
export interface HorizontalBarChartProps {
  data: MappedDomino[];
  headerData: MappedDomino[];
  headerTitle: string;
  headerLabel: string;
  medianLabel: string;
}

export const HorizontalBarChart = ({
  data,
  headerData,
  headerTitle,
  headerLabel,
  medianLabel,
}: HorizontalBarChartProps) => {
  const MIDDLE_POSITION = 3;
  const MAX_DISPLAY_ITEMS = 6;
  const hasMaximum = data.length >= MAX_DISPLAY_ITEMS;
  const values = data.map(i => i.median);
  const headerValues = headerData.map(i => i.median);
  const TOTAL_HEADER_HEIGHT = headerData.length * ROW_HEIGHT;
  const HALF_BAR_HEIGHT = BAR_HEIGHT / 2;

  const portfolioData = ((): MappedDomino[] => {
    const sorted = data.sort((a, b) => a.median - b.median);
    if (hasMaximum) {
      const IN_PLACE_OF_WAVE = { label: '', median: 0, tag: '' };
      return [
        ...sorted.slice(-MIDDLE_POSITION).sort((a, b) => b.median - a.median),
        IN_PLACE_OF_WAVE,
        ...sorted.slice(0, MIDDLE_POSITION).sort((a, b) => b.median - a.median),
      ];
    }
    return sorted;
  })();

  const height =
    (portfolioData.length + headerData.length) * ROW_HEIGHT +
    LABEL_HEIGHT * 2 +
    margin.top +
    margin.bottom;

  const minValue = Math.min(...values, ...headerValues);
  const maxValue = Math.max(...values, ...headerValues);

  const { maxRounded, minRounded, chartTicks } = getHumanFriendlyTicks({
    min: minValue,
    max: maxValue,
  });

  const xScale = d3
    .scaleLinear()
    .domain([minRounded, maxRounded])
    .range([margin.left, BAR_CHART_WIDTH]);

  return (
    <S.RootSVG width={CHART_WIDTH} height={height} data-testid="high-low-chart">
      <S.Overlay
        width={CHART_WIDTH}
        height={height + margin.bottom}
        y={margin.top + LABEL_HEIGHT + TOTAL_HEADER_HEIGHT}
        rx={4}
        ry={4}
      />
      <Axis
        transform={`translate(0, ${margin.top})`}
        headerX={CHART_WIDTH - margin.right}
        headerLabel={medianLabel}
        xScale={xScale}
        tickValues={chartTicks}
        height={height}
      />
      <g
        transform={`translate(0, ${margin.top + BAR_PADDING_VERTICAL})`}
        data-testid="header-chart-bars-root"
        width={CHART_WIDTH}
      >
        <S.LabelHeader x={margin.left} y={-HALF_BAR_HEIGHT}>
          {headerTitle}
        </S.LabelHeader>

        {headerData.map((d, i) => {
          return (
            <React.Fragment key={i}>
              <>
                {!!d.median && (
                  <Bar value={d.median} index={i} xScale={xScale} />
                )}
                {i === 1 && (
                  <S.AxisLine
                    transform={`translate( 0, ${ROW_HEIGHT - BAR_HEIGHT})`}
                    x1={0}
                    x2={CHART_WIDTH}
                    strokeWidth={0.5}
                  />
                )}
                <HorizontalLabels
                  index={i}
                  value={d.median}
                  leftLabel={d.label}
                  rightLabel={d.median ? formatPercentage(d.median) : ''}
                />
              </>
            </React.Fragment>
          );
        })}
      </g>

      <g
        transform={`translate(0, ${
          margin.top + ROW_HEIGHT + TOTAL_HEADER_HEIGHT
        })`}
        data-testid="chart-bars-root"
        width={CHART_WIDTH}
      >
        <S.LabelHeader
          x={margin.left + PORTFOLIO_ITEM_LEFT_PADDING}
          y={-HALF_BAR_HEIGHT}
        >
          {headerLabel}
        </S.LabelHeader>

        {portfolioData.map((d, i) => (
          <React.Fragment key={i}>
            {hasMaximum && i === MIDDLE_POSITION ? (
              <Waves transform={`translate(0, ${i * ROW_HEIGHT})`} />
            ) : (
              <>
                <Bar value={d.median} index={i} xScale={xScale} />
                <HorizontalLabels
                  index={i}
                  value={d.median}
                  leftLabel={d.label}
                  rightLabel={formatPercentage(d.median)}
                  paddingLeft={PORTFOLIO_ITEM_LEFT_PADDING}
                />
              </>
            )}
          </React.Fragment>
        ))}
      </g>
    </S.RootSVG>
  );
};
