import { breakpoints, typographyV2 } from '@toggle/design-system';
import styled, { css } from 'styled-components';

export const SearchLarge = styled.button<{ isTouchDevice: boolean }>`
  display: flex;
  align-items: center;
  padding: 6px 16px;
  border-radius: 100px;
  background-color: var(--base--90);
  ${({ isTouchDevice }) =>
    isTouchDevice &&
    css`
      width: 100%;
    `};
  border: none;
  cursor: pointer;
`;

export const SearchLargeText = styled.span`
  ${typographyV2.bodyBase}
  color: var(--text-soft);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0px 8px;
  max-width: 200px;
  @media ${breakpoints.sm} {
    max-width: 320px;
  }
  @media ${breakpoints.md} {
    max-width: 100%;
  }
`;

export const Hotkey = styled.span`
  color: var(--text-soft);
  padding-left: 46px;
  ${typographyV2.bodyXs}
`;
