import {
  AssetClassMap,
  AssetSector,
  AssetSubClassCode,
  AssetSubClassMap,
} from '~/api/entities/entity-constants';
import { ArticleSummary } from '~/declarations/toggle-api';

export function getArticleSectorKey(article: ArticleSummary): string {
  if (Object.values(AssetSector).includes(article.sector)) {
    return article.sector;
  }

  if (Object.values(AssetClassMap).includes(article.asset_cls)) {
    return article.asset_cls;
  }

  if (Object.values(AssetSubClasses).includes(article.sub_cls)) {
    return article.sub_cls;
  }

  return '';
}

const AssetSubClasses = {
  [AssetSubClassCode.FxCrypto]: AssetSubClassMap[AssetSubClassCode.FxCrypto],
};
