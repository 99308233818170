import {
  chartAPI,
  ChartAPIReturn,
  ChartAssetData,
  CreateChartOptions,
  Domain,
  OptionsMenu,
} from '@toggle/chart';
import React, { MutableRefObject, RefObject, useEffect } from 'react';

import { useChartInsights } from '../../hooks/use-chart-insights/useChartInsights';
import { UseTurboChartReturn } from '../../hooks/use-turbo-chart/useTurboChart';
import { ChartAreaOverlay } from '../chart-area-overlay/ChartAreaOverlay';
import { EventsConfig } from '../events-modal/EventsModal';
import { Insights } from '../insights/Insights';

export interface ChartAreaProps {
  canvasRef: RefObject<HTMLCanvasElement>;
  chartApiRef: MutableRefObject<null | ChartAPIReturn>;
  options: CreateChartOptions;
  chartData: ChartAssetData[];
  eventsConfig: EventsConfig;
  domain: Domain | null;
  changeYAxisSettings: UseTurboChartReturn['changeYAxisSettings'];
  chartSettings: UseTurboChartReturn['chartSettings'];
  chartPanes: UseTurboChartReturn['chartPanes'];
  chartPaneActions: UseTurboChartReturn['chartPaneActions'];
}

export const ChartArea = ({
  chartApiRef,
  canvasRef,
  options,
  chartData,
  eventsConfig,
  domain,
  changeYAxisSettings,
  chartSettings,
  chartPanes,
  chartPaneActions,
}: ChartAreaProps) => {
  const { insightsLoading, newsInsights, articleInsights } = useChartInsights({
    chartData,
    eventsConfig,
  });

  useEffect(() => {
    if (canvasRef.current) {
      chartApiRef.current = chartAPI({
        canvasElement: canvasRef.current,
        options,
      });

      chartApiRef.current?.init({
        horizon: chartSettings.horizon,
        resampleInterval: chartSettings.resample,
        domain,
        panes: chartPanes,
        primaryAsset: chartPanes[0].chartAssetData[0],
        primaryPaneId: chartPanes[0].id,
      });
    }

    return () => {
      chartApiRef.current?.removeListeners();
    };
  }, [options.width, options.height, chartPanes]);

  const primaryPane = chartPanes[0];

  return (
    <>
      <canvas
        data-testid="chart-area-canvas"
        ref={canvasRef}
        style={{ width: options.width, height: options.height }}
      />
      <ChartAreaOverlay
        chartData={chartData}
        canvasRef={canvasRef}
        priceDisplay={chartSettings.priceDisplay}
        changeYAxisSettings={changeYAxisSettings}
        insightsLoading={insightsLoading}
        showPriceDisplayGroup={chartData.length > 1}
        chartSettings={chartSettings}
        chartPaneActions={chartPaneActions}
      />
      {(!!articleInsights.length || !!newsInsights.length) &&
        !insightsLoading && (
          <Insights
            chartApiRef={chartApiRef}
            canvasRef={canvasRef}
            articleInsights={articleInsights}
            newsInsights={newsInsights}
            eventsConfig={eventsConfig}
          />
        )}
      <OptionsMenu
        canvasRef={canvasRef}
        changeYAxisSettings={changeYAxisSettings}
        pane={primaryPane}
      />
    </>
  );
};
