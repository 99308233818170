export const defaultMenuItems = [
  {
    translateKey: 'overview',
    couldBeLocked: false,
    couldBeCollapsed: false,
  },
  {
    translateKey: 'mightMove',
    couldBeLocked: true,
    couldBeCollapsed: false,
  },
  {
    translateKey: 'qualityChecklist',
    couldBeLocked: true,
    couldBeCollapsed: false,
  },
  {
    translateKey: 'backtest',
    couldBeLocked: true,
    couldBeCollapsed: false,
  },
];

export const allMenuItems = [
  ...defaultMenuItems,
  {
    translateKey: 'moreLikeThis',
    couldBeLocked: false,
    couldBeCollapsed: false,
  },
];
