import { Button } from '@toggle/design-system';
import React, { useState } from 'react';
import { CopyToClipboard as Copy } from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';

export interface CopyToClipboardProps {
  text: string;
}

export const CopyToClipboard = ({ text }: CopyToClipboardProps) => {
  const [isCopied, setIsCopied] = useState(false);
  const { t } = useTranslation('common');

  const onCopy = () => {
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 2000);
  };

  return (
    <Copy
      text={text}
      options={{
        format: 'text/plain',
      }}
      onCopy={onCopy}
    >
      <Button
        data-testid="copy-to-clipboard"
        label={isCopied ? t('chat:copied') : t('chat:copy')}
        variant="tertiary"
        iconName={isCopied ? 'Checkmark' : 'Copy'}
        iconPosition="left"
      />
    </Copy>
  );
};
