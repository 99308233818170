import styled from 'styled-components';

const colors: Record<string, string> = {
  bullish: 'var(--viz-bullish-soft)',
  bearish: 'var(--viz-bearish-soft)',
};

export const Rectangle = styled.rect<{ $direction: string }>`
  fill: ${({ $direction }) => colors[$direction]};
  opacity: 1;
  stroke: var(--background-default);
  stroke-width: 1px;
`;

export const SectionAreaWrapper = styled.g`
  width: 100%;
`;
