import { z } from 'zod';

export const listSchema = z.object({
  id: z.string(),
  name: z.string(),
  last_update: z.string(),
});

const entitySchema = z.object({
  tag: z.string(),
});

const watchlistEntitySchema = z.object({
  id: z.string(),
  name: z.string(),
  entities: z.array(entitySchema),
});

export const watchlist = {
  getLists: {
    path: 'watchlist/v1',
    schema: z.array(listSchema),
  },
  getList: {
    path: (id: string) => `watchlist/v1/${id}`,
    schema: watchlistEntitySchema,
  },
  postList: {
    path: 'watchlist/v1',
    schema: watchlistEntitySchema,
  } as const,
  putList: {
    path: (id: string) => `watchlist/v1/${id}`,
    schema: watchlistEntitySchema,
  } as const,
  deleteList: {
    path: (id: string) => `watchlist/v1/${id}`,
  } as const,
  postListEntities: {
    path: (id: string) => `watchlist/v1/${id}`,
    schema: entitySchema,
  },
  deleteListEntities: {
    path: (id: string, tag: string) =>
      `watchlist/v1/${id}/entity/${tag}` as const,
  },
  // not used awaiting use case
  putListEntities: {
    path: (id: string, tag: string) =>
      `watchlist/v1/${id}/entity/${tag}` as const,
    schema: entitySchema,
  },
  getListsByEntities: {
    path: (tag: string) => `watchlist/v1/entity-lists/${tag}` as const,
    schema: z.array(listSchema),
  },
} as const;
