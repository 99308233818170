import { z } from 'zod';

import { AssetClassName } from './entity-constants';

export enum ArticleComponentType {
  Text = 'text',
  Png = 'png',
}

export enum HighConvictionReportKeys {
  CrossBusinessCycle = 'cross_business_cycle',
  SeveralEpisodes = 'several_episodes',
  Sparse = 'sparse',
  Frequent = 'frequent',
  HighHitRatioInSample = 'high_hit_ratio_in_sample',
  HighHitRatioOutSample = 'high_hit_ratio_out_sample',
  HighPathRiskReturn = 'high_path_risk_return',
  HighPnlDrawdownRatio = 'high_pnl_drawdown_ratio',
}

export enum ArticleHorizon {
  Horizon1d = 'horizon_1d',
  Horizon1w = 'horizon_1w',
  Horizon2w = 'horizon_2w',
  Horizon1m = 'horizon_1m',
  Horizon3m = 'horizon_3m',
  Horizon6m = 'horizon_6m',
}

const articleComponentSchema = z.object({
  component_type: z.nativeEnum(ArticleComponentType),
  component_data: z.string(),
  link: z.string().optional(),
});

const highConvictionReportSchema = z.record(
  z.nativeEnum(HighConvictionReportKeys),
  z.boolean()
);

export const articleSchema = z.object({
  compass_id: z.string(),
  title: z.string(),
  why_it_might_move: z.array(articleComponentSchema),
  modified_on: z.string(),
  stars: z.number(),
  num_episodes: z.number(),
  num_trading_days: z.number(),
  median_return: z.number(),
  entity: z.object({
    tag: z.string(),
  }),
  article_topic: z.object({
    tag_name: z.string(),
  }),
  asset_cls: z.object({
    tag: z.nativeEnum(AssetClassName),
  }),
  high_conviction_report: highConvictionReportSchema,
  list_of_episode_dates: z.array(z.string()),
  horizon: z.object({
    tag: z.nativeEnum(ArticleHorizon),
  }),
});

const articleResponseSchema = z.object({
  article: articleSchema,
});

export const article = {
  getArticle: {
    path: (articleId: string) => `a/v2/a/${articleId}`,
    schema: articleResponseSchema,
  },
};

export type Article = z.infer<typeof articleSchema>;
export type ArticleResponse = z.infer<typeof articleResponseSchema>;
export type HighConvictionReport = z.infer<typeof highConvictionReportSchema>;
