import { Icon, InputProps } from '@toggle/design-system';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import * as S from './PasswordInput.styles';

export const PasswordInput: FC<InputProps> = ({ id, name, label, ...rest }) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const { t } = useTranslation('auth');

  const togglePassword = () => {
    setIsPasswordVisible(isPasswordVisible => !isPasswordVisible);
  };

  return (
    <S.StyledInput
      id={id}
      label={label || t('auth:form.passwordLabel')}
      name={name}
      type={isPasswordVisible ? 'text' : 'password'}
      rightIcon={
        <S.EyeButton
          type="button"
          onClick={togglePassword}
          onMouseUp={e => e.preventDefault()}
        >
          <S.IconWrap>
            {isPasswordVisible ? (
              <Icon
                size={18}
                iconName="HidePassword"
                fillColor="var(--primary-color)"
              />
            ) : (
              <Icon size={18} iconName="ShowPassword" />
            )}
          </S.IconWrap>
        </S.EyeButton>
      }
      {...rest}
    />
  );
};
