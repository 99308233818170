import ReactGA from 'react-ga4';

import { FILTER_TYPE } from '~/views/my-toggle/components/filter-buttons';

const CATEGORIES = {
  mySecurities: 'My_securities',
  myTogglePortfolio: 'My_toggle_portfolio',
};
export { CATEGORIES as MY_TOGGLE_CATEGORIES };

const ACTIONS = {
  clickFilterTab: 'Click_filter_tab',
  clickAssetRow: 'Click_asset_row',
  clickGoToPortfolio: 'Click_go_to_portfolio',
  addMoreAssets: 'Add_more_assets',
  securitiesUpgrade: 'Upgrade',
  setupWatchlist: 'Set_up_watchlist',
  myTogglePortfolioConnect: 'Connect_portfolio',
  portfolioWinnersTabClick: 'Top_winners_click',
  portfolioLosersTabClick: 'Top_losers_click',
};
export { ACTIONS as MY_TOGGLE_ACTIONS };

export function gaMyToggleSendFilterTab(filter: FILTER_TYPE) {
  ReactGA.event({
    category: CATEGORIES.mySecurities,
    action: ACTIONS.clickFilterTab,
    label: `tabName:${filter}`,
  });
}

export function gaMyToggleClickAssetRow(entityTicker: string) {
  ReactGA.event({
    category: CATEGORIES.mySecurities,
    action: ACTIONS.clickAssetRow,
    label: `tag:${entityTicker}`,
  });
}

export function gaMyToggleAddMoreAssets() {
  ReactGA.event({
    category: CATEGORIES.mySecurities,
    action: ACTIONS.addMoreAssets,
  });
}

export function gaMyToggleUpgradeSecurities() {
  ReactGA.event({
    category: CATEGORIES.mySecurities,
    action: ACTIONS.securitiesUpgrade,
  });
}

export function gaMyToggleSetupWatchlist() {
  ReactGA.event({
    category: CATEGORIES.mySecurities,
    action: ACTIONS.setupWatchlist,
  });
}

export function gaMyToggleConnectPortfolio() {
  ReactGA.event({
    category: CATEGORIES.myTogglePortfolio,
    action: ACTIONS.myTogglePortfolioConnect,
  });
}

export function gaMyToggleGoToPortfolioPage() {
  ReactGA.event({
    category: CATEGORIES.mySecurities,
    action: ACTIONS.clickGoToPortfolio,
  });
}
