import { borderRadius } from '@toggle/design-system';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 4px;

  ${borderRadius.r1};
  border: 1px solid var(--border-soft);
  background: var(--background-soft);

  & button {
    padding: 4px;
    width: 20px;
    height: 20px;
    min-width: 20px;

    & svg {
      width: 12px;
      height: 12px;
    }
  }
`;
