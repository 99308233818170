import groupBy from 'lodash/groupBy';

import { Entity } from '~/api/entities/entity-schema';
import { PortfolioSettings } from '~/declarations/toggle-api-portfolio';
import {
  formatAssetPrice,
  formatPortfolioPrice,
} from '~/shared/utils/currency/currency';
import { checkIsUserStatementInSync } from '~/shared/utils/portfolio-sync-state/portfolioSyncState';
import { Portfolio } from '~/stores/use-portfolio/usePortfolio';

import {
  StatementAssetClass,
  statementAssetClassValues,
  UserStatement,
} from '../fetch-user-statements/fetchUserStatements';
import {
  calculateTotalPnL,
  PortfolioPnL,
} from '../portfolio-profit-and-lost/portfolioPnL';
import {
  getStatementsSummary,
  StatementsSummary,
} from '../statements-summary/statementsSummary';

export type GroupedPositions = Record<string, SinglePosition[]>;

export interface MappedSingleStatement {
  summary: StatementsSummary;
  groupedPositions: GroupedPositions;
}

export type MappedStatements = Record<Portfolio['id'], MappedSingleStatement>;

export interface StatementWithEntity extends UserStatement {
  entity?: Entity;
}

export type SinglePosition = StatementWithEntity & {
  priceFormatted: string;
  totalPnL: PortfolioPnL;
  averageCostFormatted: string;
  latestPrices: number[];
  isStatementInSync: boolean;
};

export const mapSinglePosition = (statement: UserStatement) => ({
  ...statement,
  priceFormatted: formatPortfolioPrice(
    statement.price,
    statement.security.currency
  ),
  averageCostFormatted: formatAssetPrice(
    statement.average_cost,
    statement.security.currency
  ),
  latestPrices: statement.prices?.map(price => price.value) ?? [],
  totalPnL: calculateTotalPnL(statement),
  isStatementInSync: checkIsUserStatementInSync(statement),
});

const sortStatements = (a: UserStatement, b: UserStatement) =>
  a.security.ticker.localeCompare(b.security.ticker);

const groupPositionsByAssetClass = (mappedPositions: SinglePosition[]) =>
  mappedPositions.reduce((groupedPositions, mappedPosition) => {
    const securityAssetClass = mappedPosition.security.asset_class;

    const assetClass = statementAssetClassValues.includes(securityAssetClass)
      ? securityAssetClass
      : StatementAssetClass.Unknown;

    if (!groupedPositions[assetClass]) {
      groupedPositions[assetClass] = [];
    }

    groupedPositions[assetClass].push(mappedPosition);

    return groupedPositions;
  }, {} as GroupedPositions);

export const mapStatements = (
  statementsWithEntities: StatementWithEntity[],
  portfolioSettings: PortfolioSettings
): MappedStatements => {
  const groupedPortfolios = groupBy(
    statementsWithEntities,
    statement => statement.portfolio.id
  );

  const mappedStatements: MappedStatements = {};

  for (const portfolioId in groupedPortfolios) {
    const groupedPortfolio = groupedPortfolios[portfolioId];

    const mappedPositions = groupedPortfolio
      .map(mapSinglePosition)
      .sort(sortStatements);

    const groupedPositions = groupPositionsByAssetClass(mappedPositions);

    const summary = getStatementsSummary({
      mappedPositions,
      portfolioSettings,
    });

    mappedStatements[portfolioId] = {
      summary,
      groupedPositions,
    };
  }

  return mappedStatements;
};
