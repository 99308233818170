import { CustomMixDomain } from '~/types/axis.types';
import { CreateChartOptionsWithColors, PaneData } from '~/types/create.types';
import { RangeHorizon, ResampleIntervals } from '~/types/resample.types';
import { ChartAssetData } from '~/types/timeseries.types';

import { initChartStore } from '../chart-store/chartStore';
import { listenerModel } from '../listeners/listeners';
import { reDraw } from '../redraw/redraw';

export interface InitProps {
  resampleInterval: ResampleIntervals;
  horizon: RangeHorizon | null;
  domain: CustomMixDomain | null;
  panes: PaneData[];
  primaryAsset: ChartAssetData;
  primaryPaneId: PaneData['id'];
}

export const setup = (
  canvasElement: HTMLCanvasElement,
  options: CreateChartOptionsWithColors,
  context: CanvasRenderingContext2D
) => {
  const chartStore = initChartStore(canvasElement);
  const listeners = listenerModel({
    chartStore: chartStore,
    canvasElement,
    options,
  });

  const initialiseData = (props: InitProps) => {
    chartStore.getState().initialise({
      options,
      context,
      canvasElement,
      ...props,
    });
    reDraw({ chartStore });
  };

  return {
    setListeners: listeners.setListeners,
    initialiseData,
    chartStore,
  };
};
