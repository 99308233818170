import {
  SnakeMeta,
  snakeMetaArrSchema,
  SnakeMetaResponseV2,
  snakeMetaResponseV2Schema,
} from '@toggle/toggle';

import {
  FeatureIds,
  TimeSeriesData,
  timeSeriesDataSchema,
} from '~/api/timeseries/timeseries-schema';
import { config } from '~/config';
import { HttpMethods } from '~/shared/services/api-fetch/constants';
import { validateApiFetch } from '~/shared/services/api-fetch/validate-api-fetch/validateApiFetch';

const {
  api: { root: apiRoot },
} = config;

const indicatorEndpointUrls = {
  leadingIndicator: `${apiRoot}calculator/v1/toggle-leading-indicator`,
  rangeFinderIndicator: `${apiRoot}calculator/v1/range-finder-index`,
  peakIndicator: `${apiRoot}calculator/v1/peak-valley-indicator`,
  throughIndicator: `${apiRoot}calculator/v1/trough-probability-indicator`,
};

export const timeseriesEndpoints = {
  timeSeries: `${apiRoot}snake/v1/filtered`,
  toggleIndicators: `${apiRoot}snake/indicators`,
};

export const indicatorQueryEndpoints = {
  [FeatureIds.TLI]: indicatorEndpointUrls.leadingIndicator,
  [FeatureIds.RFI]: indicatorEndpointUrls.rangeFinderIndicator,
  [FeatureIds.PI]: indicatorEndpointUrls.peakIndicator,
  [FeatureIds.TI]: indicatorEndpointUrls.throughIndicator,
};

export const getIndicatorSnakes = () => {
  return validateApiFetch<SnakeMetaResponseV2>({
    endpoint: timeseriesEndpoints.toggleIndicators,
    schema: snakeMetaResponseV2Schema,
  });
};

export const getIndicatorData = (id: FeatureIds) => {
  return validateApiFetch<TimeSeriesData>({
    endpoint: indicatorQueryEndpoints[id],
    schema: timeSeriesDataSchema,
  });
};

export const postTimeSeriesFiltered = (entities: string[]) => {
  return validateApiFetch<SnakeMeta[]>({
    endpoint: timeseriesEndpoints.timeSeries,
    schema: snakeMetaArrSchema,
    requestInfo: {
      method: HttpMethods.Post,
      body: JSON.stringify({ entities }),
    },
  });
};
