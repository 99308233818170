import { Icon } from '@toggle/design-system';
import { useOutsideClick } from '@toggle/helpers';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { HeaderMyAccountDropdown } from '~/global/app-header/components/header-my-account-dropdown';
import { HeaderNotification } from '~/global/app-header/components/header-notification';
import { useHelper } from '~/stores/use-helper/useHelper';
import { useSubscription } from '~/stores/use-subscription/useSubscription';

import { HeaderSupportDropdown } from '../header-support-dropdown/HeaderSupportDropdown';
import * as S from './SecondaryMenu.styles';

interface SecondaryMenuProps {
  isSearchOpened: boolean;
  isMobileMenuOpened: boolean;
  isTouchDevice: boolean;
}

export const SecondaryMenu: FC<SecondaryMenuProps> = ({
  isSearchOpened,
  isMobileMenuOpened,
  isTouchDevice,
}) => {
  const { t } = useTranslation('helperWidget');
  const { isTrialUser } = useSubscription(s => ({
    isTrialUser: s.isTrialUser(),
  }));

  const [isMyAccountDropdownMenuOpen, setIsMyAccountDropdownMenuOpen] =
    useState(false);
  const [isSupportDropdownMenuOpen, setIsSupportDropdownMenuOpen] =
    useState(false);

  useEffect(() => {
    setIsMyAccountDropdownMenuOpen(false);
    setIsSupportDropdownMenuOpen(false);
  }, [isSearchOpened, isMobileMenuOpened]);

  const myAccountDropdownRef = useRef(null);
  const supportDropdownRef = useRef(null);
  useOutsideClick({
    ref: myAccountDropdownRef,
    callback: () => setIsMyAccountDropdownMenuOpen(false),
  });
  useOutsideClick({
    ref: supportDropdownRef,
    callback: () => setIsSupportDropdownMenuOpen(false),
  });

  const [isHelperTriggerHovered, setIsHelperTriggerHovered] = useState(false);
  const {
    isHoveredUiNotMastered,
    isTriggerTooltipShownOnLoad,
    setIsTriggerTooltipShownOnLoad,
  } = useHelper();

  useEffect(() => {
    if (!isHelperTriggerHovered && isTriggerTooltipShownOnLoad) {
      setIsTriggerTooltipShownOnLoad(false);
    }
  }, [isHelperTriggerHovered]);

  const showSupportIcon = isTouchDevice ? !isSearchOpened : true;

  return (
    <S.SecondaryMenu data-testid="secondary-menu-container">
      {!isTouchDevice && <HeaderNotification />}
      {showSupportIcon && (
        <>
          {!isTouchDevice && (
            <S.SupportIconContainer ref={supportDropdownRef}>
              {isSupportDropdownMenuOpen && (
                <HeaderSupportDropdown
                  setIsDropdownMenuOpen={setIsSupportDropdownMenuOpen}
                />
              )}
              <S.StyledTooltip
                label={t('helperWidget:tooltipLabel')}
                placement="bottom-end"
                isOpen={
                  !isSearchOpened &&
                  !isSupportDropdownMenuOpen &&
                  (isHelperTriggerHovered || isTriggerTooltipShownOnLoad)
                }
              >
                <S.SupportIconWrapper
                  $notMasteredHover={isHoveredUiNotMastered}
                >
                  <Icon
                    data-testid="header-questionMarkFill-icon"
                    iconName="QuestionMarkFill"
                    size={24}
                    onMouseEnter={() => setIsHelperTriggerHovered(true)}
                    onMouseLeave={() => setIsHelperTriggerHovered(false)}
                    onClick={() =>
                      setIsSupportDropdownMenuOpen(!isSupportDropdownMenuOpen)
                    }
                  />
                </S.SupportIconWrapper>
              </S.StyledTooltip>
            </S.SupportIconContainer>
          )}
          <S.MyAccountIconContainer ref={myAccountDropdownRef}>
            {isMyAccountDropdownMenuOpen && (
              <HeaderMyAccountDropdown
                setIsDropdownMenuOpen={setIsMyAccountDropdownMenuOpen}
              />
            )}

            <S.MyAccountIcon
              data-testid="header-astronaut-icon"
              iconName="Astronaut"
              size={32}
              viewBox="0 0 40 32"
              onClick={() =>
                setIsMyAccountDropdownMenuOpen(!isMyAccountDropdownMenuOpen)
              }
            />

            {isTrialUser && (
              <S.MyAccountIconNotification data-testid="my-account-icon-notification" />
            )}
          </S.MyAccountIconContainer>
        </>
      )}
    </S.SecondaryMenu>
  );
};
