import { AlertProps, AlertType } from '@toggle/design-system';
import find from 'lodash/find';
import sortBy from 'lodash/sortBy';
import { StoreApi } from 'zustand';

import { AbstractItem, DateString } from '~/declarations/toggle-api';
import {
  EventAction,
  WsNotifierResponse,
} from '~/shared/hooks/use-ws-notifier/useWsNotifier';
import { useUserEntities } from '~/stores/use-user-entities/useUserEntities';
import { userStatementsStore } from '~/stores/user-statements-store/userStatementsStore';
import {
  PortfolioErrors,
  PortfolioSuccess,
  PortfolioToast,
} from '~/views/my-account/portfolio/hooks/portfolio-toasts/usePortfolioToasts';
import {
  fetchPendingPortfolios,
  fetchPortfolios,
  removeSinglePortfolio,
  setPortfolioAlias,
} from '~/views/my-account/portfolio/services/portfolioService';
import { createPortfolioGroups } from '~/views/my-account/portfolio/utils/createPortfolioGroups';

import { create } from '../create-store/createStore';
import {
  ENABLED_TRADING_PROVIDERS,
  isTradingEnabledForPortfolio,
  TradingProvider,
} from './utils/use-portfolio-utils';

export interface PortfolioGroup {
  id: string;
  lastStatementDate: string;
  createdAt: string;
  provider: PortfolioProvider;
  portfolios: Omit<Portfolio, 'provider'>[];
}

export interface PendingPortfolio {
  id: string;
  user_id: string;
  provider: PortfolioProvider;
  login_id: string;
}

export interface PortfolioProvider extends AbstractItem {
  active: boolean;
  logo: string;
  external_id: string;
}

export interface Portfolio extends AbstractItem {
  id: string;
  external_id: string;
  login_id: string;
  has_mapping_issues: boolean;
  active: boolean;
  last_statement_date: DateString;
  created_at: DateString;
  provider: PortfolioProvider;
  alias?: string;
  name: string;
  position_count: number | null;
}

type State = {
  portfolios: Portfolio[];
  portfolioGroups: PortfolioGroup[];
  pendingPortfolios: PendingPortfolio[];
  error: boolean;
  loading: boolean;
  toast: PortfolioToast;
};

type Actions = {
  initialize: () => Promise<void>;
  getPortfolioById: (portfolioId: string) => Portfolio | undefined;
  getPortfoliosByProvider: (providerId: string) => Portfolio[];
  getConnectedTradingProviders: () => TradingProvider[];
  getPortfolios: () => Promise<{
    portfolios: Portfolio[];
    portfolioGroups: PortfolioGroup[];
  }>;
  getPendingPortfolios: () => Promise<{
    pendingPortfolios: PendingPortfolio[];
  }>;
  handlePortfolioWebsocketResponse: (
    response?: WsNotifierResponse
  ) => Promise<void>;
  disconnectSinglePortfolio: (loginId: string) => Promise<void>;
  saveAlias: (
    providerId: string | number,
    portfolioId: string | number,
    name: string
  ) => Promise<void>;
  showPortfolioToast: (
    type?: PortfolioErrors | PortfolioSuccess,
    customToast?: AlertProps
  ) => void;
  clearCurrentToast: () => void;
  hasPortfolioWithTradingEnabled: () => boolean;
};

export type PortfolioStore = State & Actions;

const getPendingPortfolios = async () => {
  const pendingPortfolios = await fetchPendingPortfolios();
  if (pendingPortfolios instanceof Error) {
    throw pendingPortfolios;
  }
  return {
    pendingPortfolios: pendingPortfolios.result,
  };
};

const getPortfolios = async () => {
  const portfolios = await fetchPortfolios();
  if (portfolios instanceof Error) {
    throw portfolios;
  }

  const orderedPortfolios = sortBy(portfolios.result, 'created_at');

  return {
    portfolios: orderedPortfolios,
    portfolioGroups: createPortfolioGroups(orderedPortfolios),
  };
};

const disconnectSinglePortfolio = async (
  set: StoreApi<PortfolioStore>['setState'],
  get: StoreApi<PortfolioStore>['getState'],
  loginId: string
) => {
  try {
    const res = await removeSinglePortfolio(loginId);
    if (res instanceof Error) {
      throw new Error(`Failed to delete portfolio`);
    }
    const removedPortfolioGroup = get().portfolioGroups.filter(
      p => p.id !== loginId
    );
    const removedPortfolios = get().portfolios.filter(
      p => p.login_id !== loginId
    );
    get().showPortfolioToast(PortfolioSuccess.PortfolioRemoved);

    set({
      portfolioGroups: removedPortfolioGroup,
      portfolios: removedPortfolios,
    });
    useUserEntities.getState().init();
  } catch (error) {
    get().showPortfolioToast(PortfolioErrors.Unknown);
  }
};

const saveAlias = async (
  set: StoreApi<PortfolioStore>['setState'],
  get: StoreApi<PortfolioStore>['getState'],
  providerId: string | number,
  portfolioId: string | number,
  name: string
) => {
  try {
    const res = await setPortfolioAlias(portfolioId, name);
    if (res instanceof Error) {
      throw new Error(`Failed to add alias name to portfolio`);
    }

    const updatedPortfolioGroups = get().portfolioGroups.map(p => {
      if (p.provider.id === providerId) {
        return {
          ...p,
          portfolios: p.portfolios.map(obj =>
            obj.id === portfolioId ? { ...obj, alias: name } : obj
          ),
        };
      }
      return p;
    });
    const updatedPortfolios = get().portfolios.map(portfolio =>
      portfolio.id === portfolioId ? { ...portfolio, alias: name } : portfolio
    );

    set({
      portfolioGroups: updatedPortfolioGroups,
      portfolios: updatedPortfolios,
    });
  } catch (error) {
    get().showPortfolioToast(PortfolioErrors.Unknown);
  }
};

const handlePortfolioWebsocketResponse = async (
  get: StoreApi<PortfolioStore>['getState'],
  response?: WsNotifierResponse
) => {
  if (response?.event.action === EventAction.ConnectionSuccess) {
    get().initialize();
    get().showPortfolioToast(undefined, {
      withIcon: true,
      type: AlertType.Success,
      title: response.title,
      message: response.body,
    });
    useUserEntities.getState().init();
  }

  if (response?.event.action === EventAction.ConnectionError) {
    get().showPortfolioToast(undefined, {
      withIcon: true,
      type: AlertType.Error,
      title: response.title,
      message: response.body,
    });
  }
};

const initialize = async (set: StoreApi<PortfolioStore>['setState']) => {
  try {
    set({ loading: true, error: false });
    const [portfolios, pendingPortfolios] = await Promise.all([
      getPortfolios(),
      getPendingPortfolios(),
    ]);
    set({ ...portfolios, ...pendingPortfolios, loading: false });

    if (portfolios.portfolios.length) {
      userStatementsStore.getState().fetchStatements();
    }
  } catch (e) {
    set({ loading: false, error: true });
  }
};

const initialState: State = {
  portfolios: [],
  portfolioGroups: [],
  pendingPortfolios: [],
  error: false,
  loading: false,
  toast: {
    id: 0,
    type: undefined,
    customToast: undefined,
  },
};

export const usePortfolio = create<PortfolioStore>((set, get) => ({
  ...initialState,
  initialize: async () => initialize(set),
  getPortfolios: async () => getPortfolios(),
  getPortfolioById: portfolioId =>
    find(get().portfolios, portfolio => portfolio.id === portfolioId),
  getPortfoliosByProvider: (providerId: string) =>
    get().portfolios.filter(
      portfolio => portfolio.provider.external_id === providerId
    ),
  getConnectedTradingProviders: () =>
    get().portfolios.reduce((res, portfolio) => {
      const tradingProvider = (
        ENABLED_TRADING_PROVIDERS as TradingProvider[]
      ).find(provider => portfolio.provider.external_id === provider);

      if (tradingProvider && !res.includes(tradingProvider)) {
        res.push(tradingProvider);
      }

      return res;
    }, [] as TradingProvider[]),
  getPendingPortfolios: async () => getPendingPortfolios(),
  handlePortfolioWebsocketResponse: async (response?: WsNotifierResponse) =>
    handlePortfolioWebsocketResponse(get, response),
  disconnectSinglePortfolio: async (loginId: string) =>
    disconnectSinglePortfolio(set, get, loginId),
  saveAlias: async (
    providerId: string | number,
    portfolioId: string | number,
    name: string
  ) => saveAlias(set, get, providerId, portfolioId, name),
  showPortfolioToast: (type, customToast) =>
    set({
      toast: {
        id: get().toast.id + 1,
        type: type,
        customToast: customToast,
      },
    }),
  clearCurrentToast: () =>
    set({ toast: { id: 0, type: undefined, customToast: undefined } }),
  hasPortfolioWithTradingEnabled: () =>
    get().portfolios.some(isTradingEnabledForPortfolio),
}));
