import React from 'react';
import { useTranslation } from 'react-i18next';

import * as S from '../Content.styles';

export function ArrowsContent() {
  const { t } = useTranslation('helperWidget');
  return (
    <S.Content>
      <S.Paragraph>
        {t('helperWidget:analyze.content.arrowContent.title1')}
      </S.Paragraph>
      <S.Paragraph>
        {t('helperWidget:analyze.content.arrowContent.title2')}
      </S.Paragraph>

      <p>
        <S.CompleteIcon />
        <S.CompleteTitle>
          {' '}
          {t('helperWidget:analyze.content.arrowContent.tipsTitleComplete')}
        </S.CompleteTitle>
      </p>
      <S.DescComplete>
        {t('helperWidget:analyze.content.arrowContent.tipsDescComplete')}
      </S.DescComplete>

      <p>
        <S.InvalidIcon />
        <S.InvalidTitle>
          {' '}
          {t('helperWidget:analyze.content.arrowContent.tipsTitleInvalid')}
        </S.InvalidTitle>
      </p>
      <S.DescInvalid>
        {t('helperWidget:analyze.content.arrowContent.tipsDescInvalid')}
      </S.DescInvalid>

      <p>{t('helperWidget:analyze.content.arrowContent.title3')}</p>
    </S.Content>
  );
}
