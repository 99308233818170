import React, { RefObject, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { TagBasic } from '~/declarations/toggle-api';
import { gaShare } from '~/shared/utils/ganalytics';
import { SocialUrls } from '~/shared/utils/url-helper/url-utils';
import { SocialShareButton } from '~/views/news/components/social-share-button/SocialShareButton';

import {
  SHARE_BUTTONS,
  SHARE_BUTTONS_NAMES,
} from '../../../article/partials/article-menu/ShareButtons';
import * as S from './ShareButtons.styles';
import { ShareButtonsTooltip } from './ShareButtonsTooltip';

export interface ShareButtonsComponentProp {
  link: string;
  title: string;
  tags: TagBasic[];
  isOpen?: boolean;
  showTitle?: boolean;
  containerEl?: keyof JSX.IntrinsicElements;
  className?: string;
  refLink?: RefObject<HTMLElement>;
  isFacebook?: boolean;
  isReddit?: boolean;
  iconSize?: 'lg' | 'md' | 'sm';
  gaLocation: string;
}

export function ShareButtons({
  link,
  title,
  isOpen = false,
  showTitle = true,
  containerEl = 'div',
  className,
  refLink,
  isFacebook = false,
  isReddit = false,
  iconSize = 'lg',
  gaLocation,
}: ShareButtonsComponentProp) {
  const { t } = useTranslation('common');
  const [showShareButtons, setShowShareButtons] = useState(isOpen);
  const [copyText, setCopyText] = useState<string>(t('common:share.copy'));
  const { pathname } = useLocation();

  function onCopy() {
    gaShare(SHARE_BUTTONS.COPYLINK, gaLocation, pathname);
    setCopyText(t('common:share.copied'));
  }

  const onShareButtonClick = (shareType: SHARE_BUTTONS_NAMES) => {
    gaShare(shareType, gaLocation, pathname);
  };

  return (
    <S.ShareButtons
      as={containerEl}
      ref={refLink}
      inOpenMode={isOpen}
      titleHidden={!showTitle}
      className={className}
    >
      {showShareButtons ? (
        <>
          {isOpen && showTitle && (
            <S.ShareButton inOpenMode data-testid="show-share-buttons">
              <S.ShareButtonText isUppercase>
                {t('common:share.label', { context: 'share' })}
              </S.ShareButtonText>
            </S.ShareButton>
          )}
          <ShareButtonsTooltip text={t('common:share.email')}>
            <a
              href={`mailto:?subject=${t(
                'share.emailSubject'
              )}&body=${title} ${link}`}
              onClick={() => onShareButtonClick(SHARE_BUTTONS.EMAIL)}
              data-testid="email-share-button"
            >
              <S.StyledIcon $size={iconSize} iconName="Mail" />
            </a>
          </ShareButtonsTooltip>

          <ShareButtonsTooltip text={copyText}>
            <S.CopyLink>
              <CopyToClipboard
                text={link}
                onCopy={onCopy}
                options={{ format: 'text/plain' }}
                data-testid="copy-share-button"
              >
                <S.StyledIcon $size={iconSize} iconName="Copy" />
              </CopyToClipboard>
            </S.CopyLink>
          </ShareButtonsTooltip>

          {isFacebook && (
            <ShareButtonsTooltip text={t('common:share.facebook')}>
              <SocialShareButton
                provider={{
                  provider: SocialUrls.Facebook,
                  params: {
                    u: link,
                    quote: title,
                  },
                }}
                data-testid="facebook-share-button"
                beforeOnClick={() => onShareButtonClick(SHARE_BUTTONS.FACEBOOK)}
              />
            </ShareButtonsTooltip>
          )}

          {isReddit && (
            <ShareButtonsTooltip text={t('common:share.reddit')}>
              <SocialShareButton
                provider={{
                  provider: SocialUrls.Reddit,
                  params: {
                    title,
                    url: link,
                  },
                }}
                data-testid="reddit-share-button"
                beforeOnClick={() => onShareButtonClick(SHARE_BUTTONS.REDDIT)}
              />
            </ShareButtonsTooltip>
          )}

          <ShareButtonsTooltip text={t('common:share.twitter')}>
            <SocialShareButton
              provider={{
                provider: SocialUrls.Twitter,
                params: {
                  title,
                  url: link,
                },
              }}
              data-testid="twitter-share-button"
              beforeOnClick={() => onShareButtonClick(SHARE_BUTTONS.TWITTER)}
            />
          </ShareButtonsTooltip>
        </>
      ) : (
        <S.ShareButton
          data-testid="show-share-buttons"
          onClick={() => setShowShareButtons(!showShareButtons)}
        >
          <S.StyledIcon $size={iconSize} iconName="Twitter" />
          <S.ShareButtonText as="span">
            {t('common:share.label', { context: 'share' })}
          </S.ShareButtonText>
        </S.ShareButton>
      )}
    </S.ShareButtons>
  );
}
