import { Icon } from '@toggle/design-system';
import { keyboard } from '@toggle/helpers';
import React, { KeyboardEvent, useEffect, useRef, useState } from 'react';

import { useChatStore } from '../../use-chat-store/useChatStore';
import { ChatInput } from '../chat-input/ChatInput';
import * as S from './ChatSubmit.styles';

interface ChatSubmitProps {
  placeholder: string;
  submitCallback: (message: string) => void;
}

export const ChatSubmit = ({
  placeholder,
  submitCallback,
}: ChatSubmitProps) => {
  const [inputValue, setInputValue] = useState('');
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const { isResponsePending } = useChatStore();

  const isSubmitDisabled = !inputValue || isResponsePending;

  const clearInput = () => {
    setInputValue('');
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    } else {
      clearInput();
    }
  }, [inputRef]);

  const submitHandler = () => {
    if (!isSubmitDisabled) {
      submitCallback(inputValue.trim());
      clearInput();

      if (inputRef.current) {
        inputRef.current.rows = 1;
      }
    }
  };

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    submitHandler();
  };

  const onKeyDown = (event: KeyboardEvent<HTMLTextAreaElement>) => {
    if (
      event.key === keyboard.enter.key &&
      !event.shiftKey &&
      !event.nativeEvent.isComposing // fix: [861n3tz5g] Japanese IME: not finished question is sent via 'enter'
    ) {
      event.preventDefault();
      submitHandler();
    }
  };

  return (
    <S.Form onSubmit={onSubmit}>
      <ChatInput
        ref={inputRef}
        data-testid="chat-input"
        autoFocus
        placeholder={placeholder}
        value={inputValue}
        onChange={event => setInputValue(event.target.value)}
        onKeyDown={onKeyDown}
      >
        <S.SubmitButton data-testid="submit-button" disabled={isSubmitDisabled}>
          <Icon iconName="SendMessage" fillColor="currentColor" size={20} />
        </S.SubmitButton>
      </ChatInput>
    </S.Form>
  );
};
