import { i18n as i18nTurboChart } from '@toggle/chart';
import i18n, { ReadCallback } from 'i18next';
import resourcesToBackend from 'i18next-resources-to-backend';
import { initReactI18next } from 'react-i18next';

i18n
  .use(initReactI18next)
  .use(
    resourcesToBackend(
      (language: string, namespace: string, callback: ReadCallback) => {
        import(`./locales/${language}/${namespace}.json`)
          .then(({ default: resources }) => {
            callback(null, resources);
          })
          .catch(error => {
            callback(error, null);
          });
      }
    )
  )
  .init({
    fallbackLng: 'en',
    lng: 'en',
    debug: false,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    react: {
      useSuspense: true,
    },
  });

i18n.on('languageChanged', lng => {
  i18nTurboChart.changeLanguage(lng);
});
