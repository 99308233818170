import { Entity } from '~/api/entities/entity-schema';
import { OptionalN } from '~/declarations/standard';
import { ArticleDirection } from '~/declarations/toggle-api-enums';
import { ENDPOINT_URLS } from '~/global/toggle-api';
import { useAPI } from '~/shared/hooks/use-api/useAPI';
import {
  APIState,
  PartialOptionsWithInitState,
} from '~/shared/hooks/use-api/useAPI-types';
import { APIFetch } from '~/shared/services/api-fetch';

export type InsightEventType =
  | 'group_technical'
  | 'group_fundamental'
  | 'group_macro'
  | 'group_relatedAsset'
  | 'group_news';

export interface ArticleInsightCard {
  article_id: OptionalN<string>;
  bookmarked: boolean;
  card_icon: string;
  card_text: string;
  card_type: string;
  created_at: string;
  entity: Entity;
  direction: OptionalN<ArticleDirection>;
  driver_entity: Entity;
  event_icon: string;
  event_text: string;
  event_type: InsightEventType;
  is_new: boolean;
  stars: number;
  compass_id: string;
}

export const fetchInsightCards = (entityTag: string, articleId?: string) => {
  const queryParams = articleId
    ? `?article_id=${encodeURIComponent(articleId)}`
    : '';
  return APIFetch<ArticleInsightCard[]>(
    `${ENDPOINT_URLS.ARTICLE_INSIGHT_CARDS}/${entityTag}${queryParams}`
  );
};

export const useInsightCards = <I = ArticleInsightCard[], M = I>(
  entityTag: string,
  options?: PartialOptionsWithInitState<ArticleInsightCard[], I, M>
) =>
  useAPI<ArticleInsightCard[], I, M>(() => fetchInsightCards(entityTag), {
    deps: [entityTag],
    initialState: { data: [], loading: true } as unknown as APIState<I>,
    ...options,
  });
