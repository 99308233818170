import React from 'react';

import { TradingDrawer } from '~/global/trading-drawer/TradingDrawer';
import { AppRoute } from '~/routes/AppRoutes.d';

import { AuthenticatedRedirect } from '../authenticated-redirect/AuthenticatedRedirect';
import { View } from './view/View';

export interface AuthenticatedRouteProps {
  isAuthenticated: boolean;
  config: AppRoute;
}

export function AuthenticatedView({
  isAuthenticated,
  config: { element, helperViewId, section },
}: AuthenticatedRouteProps) {
  return isAuthenticated ? (
    <AuthenticatedRedirect section={section}>
      <>
        <TradingDrawer />
        <View helperViewId={helperViewId} component={element} />
      </>
    </AuthenticatedRedirect>
  ) : (
    <View helperViewId={helperViewId} component={element} />
  );
}
