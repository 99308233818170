import { Alert, AlertType } from '@toggle/design-system';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useToasts } from '~/stores/use-toasts/useToasts';
import { ALERT_AMOUNT_TOAST_ID } from '~/stores/use-user-entities/useUserEntities';

interface WatchlistAlertContentProps {
  onClose: () => void;
}

export const WatchlistAmountAlert: FC<WatchlistAlertContentProps> = ({
  onClose,
}) => {
  const { t } = useTranslation('subscription');
  const removeToast = useToasts(state => state.removeToast);

  return (
    <Alert
      type={AlertType.Warning}
      title={t('subscription:watchlist.alerts.title', { context: 'amount' })}
      message={t('subscription:watchlist.alerts.message', {
        context: 'amount',
      })}
      onClose={() => {
        removeToast(ALERT_AMOUNT_TOAST_ID);
        onClose();
      }}
    />
  );
};
