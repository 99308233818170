import { Portal } from '@toggle/design-system';
import React, { FC, MouseEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ArticleDirection,
  ExploreArticleDirection,
} from '~/declarations/toggle-api-enums';
import { MODAL_TYPES } from '~/global/paywall/services/modal-types/modalTypes';
import { usePaywallStore } from '~/global/paywall/stores/usePaywallStore';
import { appPaths } from '~/routes/app-paths';
import { useMediaQuery } from '~/shared/hooks/MediaQueryHook';
import { MediaQueries } from '~/shared/hooks/MediaQueryList';
import {
  gaAnalyzeToggleExpandExplanation,
  gaAnalyzeToggleExpandInsight,
  gaBookmark,
} from '~/shared/utils/ganalytics';
import { useArticleLimit } from '~/stores/use-article-limit/useArticleLimit';
import { useSubscription } from '~/stores/use-subscription/useSubscription';
import { getInsightCardConfig } from '~/views/analyze/InsightCardsConfig';
import { CARD_TYPES } from '~/views/analyze/overview-constants';
import { MappedArticle } from '~/views/analyze/utils/insights-helpers';

import { CardFooter } from '../card-footer/CardFooter';
import { CardHeader } from '../card-header/CardHeader';
import { BullishBearishCardBody } from './bullish-bearish-card-body/BullishBearishCardBody';
import * as S from './CardSharedStyles.styles';

interface BullishBearishCardProps {
  insight: MappedArticle;
  bookmarked: boolean;
  handleBookmark: () => void;
  onClick?: (id: string) => void;
}

export const cardDirection = (
  direction: ArticleDirection | ExploreArticleDirection
) =>
  direction === ArticleDirection.Bullish
    ? CARD_TYPES.BULLISH
    : CARD_TYPES.BEARISH;

export const BullishBearishCard: FC<BullishBearishCardProps> = ({
  handleBookmark,
  bookmarked,
  insight,
  onClick,
}) => {
  const { t } = useTranslation('analyze');
  const openModal = usePaywallStore(state => state.openModal);
  const isLimitExceeded = useArticleLimit(state => state.isLimitExceeded);
  const isBasic = useSubscription(state => state.isBasic());
  const [showInfo, setShowInfo] = useState(false);
  const [isSummaryExpanded, setIsSummaryExpanded] = useState(false);
  const summaryRef = useRef<HTMLDivElement>(null);
  const articleRef = useRef<HTMLElement>(null);
  const currentMedia = useMediaQuery(s => s.currentMedia);
  const isMobile = [MediaQueries.SMALL_MOBILE, MediaQueries.MOBILE].includes(
    currentMedia
  );
  const isTablet = currentMedia === MediaQueries.TABLET;
  const isDesktop = [
    MediaQueries.DESKTOP,
    MediaQueries.SMALL_DESKTOP,
    MediaQueries.LARGE_DESKTOP,
  ].includes(currentMedia);

  const toggleSummaryExpanding = () => {
    gaAnalyzeToggleExpandInsight(!isSummaryExpanded, insight.id);
    setIsSummaryExpanded(s => !s);
  };

  useEffect(() => {
    if (articleRef.current) {
      articleRef.current.style.height = '';
      setShowInfo(false);
    }
  }, [insight.id]);

  const onBookmarkBtnClick = () => {
    gaBookmark(
      insight.articleId,
      bookmarked,
      'analyze-insights-panel',
      isBasic
    );

    if (isBasic) {
      openModal(MODAL_TYPES.BOOKMARKS);
      return;
    }

    handleBookmark();
  };

  const onReadBtnClick = (event: MouseEvent) => {
    if (isLimitExceeded) {
      event.preventDefault();
      openModal(MODAL_TYPES.ARTICLE_LIMIT);
    }
  };

  const toggleShowInfo = () => {
    if (articleRef.current) {
      gaAnalyzeToggleExpandExplanation(!showInfo, cardType);
      const fullHeight = articleRef.current.clientHeight;
      articleRef.current.style.height = summaryRef.current?.clientHeight
        ? `${fullHeight}px`
        : '';
      setShowInfo(s => !s);
    }
  };

  const cardType =
    ExploreArticleDirection.Bearish === insight.direction
      ? CARD_TYPES.BEARISH
      : CARD_TYPES.BULLISH;

  const insightCard = getInsightCardConfig(insight.insight_card, cardType, t);

  return (
    <>
      {isSummaryExpanded && (
        <Portal>
          <S.Overlay data-testid="card-overlay" />
        </Portal>
      )}
      <S.InsightCard
        data-testid="insight-card"
        ref={articleRef}
        onClick={onClick ? () => onClick(insight.id) : undefined}
      >
        <S.Wrapper $fullHeight={showInfo} $isExpanded={isSummaryExpanded}>
          <CardHeader
            insightId={insight.id}
            type={cardType}
            config={{ name: insightCard.name }}
            showBookmarkTooltip={isBasic ? undefined : false}
            showInfo={showInfo}
            bookmarked={bookmarked}
            withBookmarkAnimation={!isBasic}
            isBookmarkLocked={isBasic}
            onChevronBtnClick={toggleShowInfo}
            onBookmarkBtnClick={onBookmarkBtnClick}
            bookmarkTooltip={
              isBasic
                ? t('analyze:insights.lockedBookmark')
                : t('analyze:insights.bookmarked')
            }
          />
          {!showInfo ? (
            <>
              <BullishBearishCardBody
                insight={insight}
                isSummaryExpanded={isSummaryExpanded}
                toggleSummaryExpanding={toggleSummaryExpanding}
                cardType={cardType}
                isMobile={isMobile}
                isTablet={isTablet}
              />
              <CardFooter
                showFullBtn={isSummaryExpanded}
                onReadBtnClick={onReadBtnClick}
                toggleSummaryExpanding={toggleSummaryExpanding}
                url={`${appPaths.article}/${insight.articleId}`}
                readText={t('analyze:insights.cards.read', {
                  context: isSummaryExpanded ? 'fullInsight' : 'more',
                })}
              />
            </>
          ) : (
            <S.Info data-testid="info-text">{insightCard.intuition}</S.Info>
          )}
        </S.Wrapper>
        {!showInfo && isDesktop && (
          <S.StyledInsightSummary
            constraint="desktop"
            insight={insight}
            ref={summaryRef}
          />
        )}
      </S.InsightCard>
    </>
  );
};
