const DEFAULT_SUFFIX = '%';

enum DEFAULT_METHOD_TO_SUFFIX {
  option_adj_spread = 'bps',
  yield = 'bps',
}

export function createSuffixFromMethod(method: string) {
  let suffix = DEFAULT_SUFFIX;
  if (Object.keys(DEFAULT_METHOD_TO_SUFFIX).includes(method)) {
    suffix =
      DEFAULT_METHOD_TO_SUFFIX[method as keyof typeof DEFAULT_METHOD_TO_SUFFIX];
  }
  return suffix;
}
