import React from 'react';

import { config } from '~/config';

import { getWhatIfExchangeName } from '../../../helpers/chat-helpers';
import * as S from './WhatIfWidget.styles';

export interface WhatIfWidgetProps {
  exchange: string;
  ticker: string;
}

export const WhatIfWidget = ({ exchange, ticker }: WhatIfWidgetProps) => (
  <S.WhatIfWidgetIframe
    data-testid="what-if-widget"
    src={`${config.urls.root}/earnings/${getWhatIfExchangeName(
      exchange
    )}-${ticker}?layout=simple`}
    title="WhatIF Earnings"
  />
);
