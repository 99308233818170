import React, { ReactNode } from 'react';

import * as S from './EntityDetails.styles';

export interface EntityDetailsProps {
  entityNameComponent: ReactNode;
  formattedTicker: string;
  leftImgNode: ReactNode;
  isButtonActive?: boolean;
  buttonNode?: ReactNode;
  isLargeTitle?: boolean;
}

export const EntityDetails = ({
  entityNameComponent,
  formattedTicker,
  leftImgNode,
  isButtonActive,
  buttonNode,
  isLargeTitle = true,
  ...rest
}: EntityDetailsProps) => {
  return (
    <S.EntityDetailsRoot data-testid="module-entity-details" {...rest}>
      {leftImgNode && (
        <S.LeftImageContainer>{leftImgNode}</S.LeftImageContainer>
      )}
      <S.TitleContainer $isLargeTitle={isLargeTitle}>
        {entityNameComponent}
        <S.Tag>{formattedTicker}</S.Tag>
      </S.TitleContainer>
      {buttonNode}
    </S.EntityDetailsRoot>
  );
};
