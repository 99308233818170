import { Chip } from '@toggle/design-system';
import {
  Entity,
  fundamentalMethods,
  macroMethods,
  MethodSubClass,
  SnakeMeta,
  technicalsMethods,
} from '@toggle/toggle';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { postEntities } from '~/api/entities/entity-service';

import { useIndicatorModal } from '../../hooks/use-indicator-modal/useIndicatorModal';
import { SearchInput } from '../search-modal/components/search-input/SearchInput';
import * as S from './IndicatorModal.styles';

type ChipType = 'fundamentals' | 'technicals' | 'macro' | 'tli';

interface ChipFilteredData {
  name: ChipType;
  data: SnakeMeta[];
}

export interface IndicatorModalProps {
  activeAssets: Entity[];
  onClose: () => void;
  onIndicatorSelect: (indicator: SnakeMeta, asset: Entity) => void;
}

export const IndicatorModal = ({
  activeAssets,
  onClose,
  onIndicatorSelect,
}: IndicatorModalProps) => {
  const { t } = useTranslation(['chart', 'common']);
  const [searchQuery, setSearchQuery] = useState('');
  const [activeChip, setActiveChip] = useState<ChipType>();
  const [chipData, setChipData] = useState<ChipFilteredData[]>([]);

  const { data, isSnakesLoading } = useIndicatorModal(activeAssets);

  useEffect(() => {
    filterChipData();
  }, [data]);

  const containsIndicator = (list: Entity[], indicator: SnakeMeta) =>
    list.some(item => item.default_snake === indicator.snake);

  const addIndicator = async (indicator: SnakeMeta, asset?: Entity) => {
    // TODO ask designers for UI when the user click an already existing indicator
    const exists = containsIndicator(activeAssets, indicator);

    if (!exists) {
      if (!asset) {
        const entity = await postEntities({
          args: [indicator.entity],
          filter: 2,
        });
        onIndicatorSelect(indicator, entity[0]);
      } else {
        onIndicatorSelect(indicator, asset);
      }
      onClose();
    }
  };

  const onChangeChip = (selectedChip: ChipType) => {
    if (selectedChip === activeChip) {
      setActiveChip(undefined);
    } else {
      setActiveChip(selectedChip);
    }
  };

  const filterByMethod = (data: SnakeMeta[], methods: MethodSubClass[]) =>
    data.filter(item => methods.includes(item.method_sub_class));

  const filterChipData = () => {
    const uniqueSnakes = data.snakeItems.filter(
      item => !containsIndicator(activeAssets, item)
    );

    const filterMap: ChipFilteredData[] = [
      {
        name: 'fundamentals',
        data: filterByMethod(uniqueSnakes, fundamentalMethods),
      },
      {
        name: 'technicals',
        data: filterByMethod(uniqueSnakes, technicalsMethods),
      },
      { name: 'macro', data: filterByMethod(uniqueSnakes, macroMethods) },
      { name: 'tli', data: data.tliItems },
    ];

    setChipData(filterMap.filter(({ data }) => data.length > 0));
  };

  const getFilteredData = (): SnakeMeta[] => {
    const uniqueSnakes = data.snakeItems.filter(
      item => !containsIndicator(activeAssets, item)
    );
    if (searchQuery) {
      return uniqueSnakes.filter(item =>
        item.name.english.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    if (activeChip) {
      return chipData.filter(e => e.name === activeChip)[0].data;
    }

    return uniqueSnakes;
  };

  const filteredSnakes = getFilteredData();

  const showNoSearchResults = !filteredSnakes.length && !isSnakesLoading;

  return (
    <S.StyledModal
      data-testid="indicator-modal"
      isOpen
      title={t('chart:widget.indicators.addIndicators')}
      onClose={onClose}
    >
      <S.Container>
        <SearchInput
          value={searchQuery}
          onChange={value => setSearchQuery(value)}
        />

        {!searchQuery && (
          <S.ChipContainer data-testid="indicator-chips-wrapper">
            {chipData.map(chip => (
              <Chip
                key={chip.name}
                isActive={chip.name === activeChip}
                /* i18next-extract-mark-context-next-line ["fundamentals", "technicals", "macro", "tli"] */
                label={t('chart:widget.indicators.chipsSelector', {
                  context: chip.name,
                })}
                onClick={() => onChangeChip(chip.name)}
                variation="outline"
              />
            ))}
          </S.ChipContainer>
        )}

        <S.ScrollableList>
          <S.ListContainer data-testid="search-results">
            {filteredSnakes.map((item, index) => {
              const matchedEntity = activeAssets.find(
                a => a.tag === item.entity
              );
              return (
                <S.IndicatorItem
                  key={index}
                  onClick={() => addIndicator(item, matchedEntity)}
                  data-testid="list-item"
                >
                  <S.IndicatorText>{item.name.english}</S.IndicatorText>
                  <S.Secondary>
                    {matchedEntity?.name && (
                      <S.StyledLogo entity={matchedEntity} isDarkTheme />
                    )}
                    <S.IndicatorSmallText>
                      {matchedEntity?.name_short}
                    </S.IndicatorSmallText>
                  </S.Secondary>
                </S.IndicatorItem>
              );
            })}

            {showNoSearchResults && (
              <S.HelpText data-testid="no-search-results">
                {t('chart:widget.indicators.noSearchResults')}
              </S.HelpText>
            )}
          </S.ListContainer>
        </S.ScrollableList>
      </S.Container>
    </S.StyledModal>
  );
};
