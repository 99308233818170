import { changeLanguage } from 'i18next';

import {
  FlagsLanguage,
  SupportedLanguages,
} from '~/stores/use-system-flags/system-flags.types';

export const setCurrentLanguage = (languages: FlagsLanguage | undefined) => {
  if (languages) {
    let browserLanguages = window.navigator.languages;

    // Workaround for Chromium
    // When user update their language, there is an issue on chromium, that doesnt update `navigator.languages` list order
    // But strangely `navigator.language` does update, so we manuall unshift it to `browserLanguages`
    if (browserLanguages[0] !== window.navigator.language) {
      browserLanguages = [window.navigator.language, ...browserLanguages];
    }

    const matchedLang = browserLanguages
      .find(element => languages[element.substring(0, 2) as SupportedLanguages])
      ?.substring(0, 2);

    if (matchedLang) {
      changeLanguage(matchedLang);
    }
  }
};
