import { createSearchParams, useNavigate } from 'react-router-dom';

import { BillingCycle } from '~/api/subscription/subscription-schema';
import { accountRoutes, appPaths } from '~/routes/app-paths';
import { useSubscription } from '~/stores/use-subscription/useSubscription';

import {
  ChangingPlanTier,
  PlanCardType,
} from '../../plan-matrix/utils/plan-matrix.types';

export const useNavigateToCheckout = () => {
  const navigate = useNavigate();

  const { card } = useSubscription(state => ({
    card: state.card,
  }));

  const upgradeTo = ({
    type = PlanCardType.Pro,
    tier = card ? ChangingPlanTier.Upgrading : ChangingPlanTier.Initial,
    billingCycle = BillingCycle.year,
  } = {}) => {
    navigate({
      pathname: `${appPaths.myAccount.subscription}/${accountRoutes.checkout}`,
      search: `?${createSearchParams({
        billingCycle,
        tier,
        type,
      })}`,
    });
  };

  return upgradeTo;
};
