import { Button, deviceConstraints, typography } from '@toggle/design-system';
import styled from 'styled-components';

import { wrapperCss } from '../auth-shared-styles/AuthSharedStyles.styles';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  ${wrapperCss}
  padding: 40px;
  text-align: center;
  margin-top: 32px;
  @media ${deviceConstraints.smartphone} {
    margin: 32px 16px 16px;
  }
`;

export const Title = styled.h1<{ $confirmed: boolean }>`
  ${typography.coyote};
  margin: 20px 0 12px;
  color: ${({ $confirmed }) =>
    $confirmed ? 'var(--success--default)' : 'var(--failure--default)'};
`;

export const Text = styled.p`
  ${typography.emu};
  margin-bottom: 20px;
`;

export const ImgWaveShape = styled.img`
  width: 100%;
  position: absolute;
  bottom: 0;
  z-index: -1;
`;

export const MainBtn = styled(Button)<{ $btnConfirmed: boolean }>`
  ${({ $btnConfirmed }) => !$btnConfirmed && 'padding: 8px 41px;'}
`;
