import { StyledComponent } from '@toggle/design-system';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { PriceStatus } from '~/views/analyze/utils/asset-box/asset-box-helpers';

import { LivePriceInfo } from '../../hooks/use-live-price';
import * as S from './LivePriceView.styles';

export interface LivePriceViewProps {
  livePriceInfo?: LivePriceInfo;
  lastKnownPrice?: number;
  datePresentation?: string;
  className?: string;
  formatPrice: (price: number, decimals?: number) => string;
  showMarketIndicator?: boolean;
  lastTimestamp?: number;
  keepLayout?: boolean;
}

export const LivePriceView: StyledComponent<LivePriceViewProps, typeof S> = ({
  livePriceInfo,
  lastKnownPrice,
  className,
  formatPrice,
  showMarketIndicator = true,
  lastTimestamp,
  keepLayout = false,
}) => {
  const { t } = useTranslation('analyze');

  if ((!livePriceInfo && lastKnownPrice === undefined) || lastTimestamp === 0) {
    return null;
  }

  const livePrice =
    livePriceInfo?.lastKnownPrice !== undefined
      ? livePriceInfo.lastKnownPrice
      : lastKnownPrice;
  const price =
    livePriceInfo?.price !== undefined ? livePriceInfo.price : livePrice;

  const validPrice = price !== undefined;
  const validChange =
    livePriceInfo?.priceChange.status &&
    livePriceInfo?.priceChange.proportionChange;

  return (
    <S.LivePriceBox className={className} data-testid="live-price-view">
      <S.PriceContainer data-testid="price-container">
        {validPrice && (
          <S.LivePrice data-testid="price">{formatPrice(price)}</S.LivePrice>
        )}
      </S.PriceContainer>
      {validChange && (
        <S.PriceChange
          data-testid="price-change"
          $status={livePriceInfo.priceChange.status}
        >
          <span>{livePriceInfo.priceChange.change}</span>
          <S.Divider $status={livePriceInfo.priceChange.status}>/</S.Divider>
          <span>{livePriceInfo.priceChange.proportionChange}</span>
        </S.PriceChange>
      )}
      {!validChange && keepLayout && (
        <S.PriceChange
          $status={PriceStatus.default}
          data-testid="price-change-placeholder"
        />
      )}
      {showMarketIndicator && (
        <S.MarketIndicatorStyled
          isMarketOpen={!!livePriceInfo && livePriceInfo?.isLive}
          closeText={t('analyze:analysis.lastClose')}
          liveText={t('analyze:analysis.livePrice')}
        />
      )}
    </S.LivePriceBox>
  );
};

LivePriceView.Styled = S;
