import React from 'react';

import * as S from './CategoryGroup.styles';

export interface CategoryItem {
  label: string;
  key: string;
  icon?: JSX.Element;
  badge?: JSX.Element;
}

export interface Category {
  id?: string;
  title?: string;
  items: CategoryItem[];
}

interface CategoryGroupProps {
  activeKey?: string;
  category: Category;
  disabledKeys?: string[];
  onClick: (id: string) => void;
}

export const CategoryGroup = ({
  activeKey,
  category,
  disabledKeys,
  onClick,
}: CategoryGroupProps) => {
  const handleClick = (id: string) => () => {
    onClick(id);
  };

  return (
    <S.CategoryGroupRoot>
      {category.title && <S.CategoryTitle>{category.title}</S.CategoryTitle>}
      <S.Items>
        {category.items.map(item => (
          <S.ItemWrapper key={item.key} $isActive={activeKey === item.key}>
            <S.Item
              data-testid="side-navigation-item"
              $isActive={activeKey === item.key}
              disabled={disabledKeys?.includes(item.key)}
              onClick={handleClick(item.key)}
            >
              {item.icon && <S.IconWrapper>{item.icon}</S.IconWrapper>}
              <S.MainLabel>{item.label}</S.MainLabel>
              {item.badge && <S.BadgeWrapper>{item.badge}</S.BadgeWrapper>}
            </S.Item>
            <S.ItemBackground />
          </S.ItemWrapper>
        ))}
      </S.Items>
    </S.CategoryGroupRoot>
  );
};

CategoryGroup.Styled = S;
