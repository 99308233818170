import { Button as DefaultButton } from '@toggle/design-system';
import styled, { css } from 'styled-components';

export const BtnGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const Button = styled(DefaultButton)<{ locked?: boolean }>`
  height: 28px;
  font-size: 14px;
  border-color: var(--base--15);
  color: var(--base--15);

  &:not(:first-child) {
    margin-left: 4px;
  }

  ${({ locked }) =>
    locked &&
    css`
      background-color: var(--base--100);
      color: var(--base--85);

      &:not(:hover) {
        border-color: var(--base--85);
      }
    `}
`;
