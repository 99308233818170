import styled from 'styled-components';

export const HighLowTripleLineChartRoot = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  min-height: 300px;
`;

export const Chart = styled.svg`
  width: 100%;
  min-height: 100%;
`;

export const BaseLine = styled.line`
  stroke: var(--border-default);
  stroke-width: 2px;
`;
