import { Icon } from '@toggle/design-system';
import styled from 'styled-components';

export const Topic = styled.h3`
  margin-bottom: 8px;
  font-size: 16px;
`;

export const VideoWrapper = styled.div`
  height: 182px;
  background-color: var(--base--75);
`;

export const Content = styled.div`
  padding: 8px 16px 8px 40px;
  font-size: 16px;
`;

export const Paragraph = styled.p`
  margin-bottom: 8px;
  &:last-child {
    margin-bottom: 0;
  }
`;

const Title = styled.span`
  margin-left: 3px;
`;

export const CompleteTitle = styled(Title)`
  color: var(--success--default);
`;

export const InvalidTitle = styled(Title)`
  color: var(--failure--default);
`;

const Desc = styled.p`
  padding-left: 18px;
`;

export const DescComplete = styled(Desc)`
  margin-bottom: 16px;
  margin-left: 5px;
`;

export const DescInvalid = styled(Desc)`
  margin-bottom: 8px;
  margin-left: 5px;
`;

export const CompleteIcon = styled.span`
  color: var(--success--default);
  &:before {
    font-family: color-emoji, sans-serif;
    content: '\\1F3C6';
  }
`;

export const InvalidIcon = styled.span`
  color: var(--failure--default);
  &:before {
    font-family: color-emoji, sans-serif;
    content: '\\1F616';
  }
`;

export const MiddleAlignedIcon = styled(Icon)`
  vertical-align: middle;
`;

export const DriversImgBox = styled.div`
  margin: 10px 0;
`;
