import {
  customScrollMinimal,
  Input,
  typographyV2,
} from '@toggle/design-system';
import styled from 'styled-components';

export const ChatInputWrapper = styled(Input.Styles.InputWrapper)`
  display: flex;
  width: 100%;
  position: relative;
  padding: 8px 0 8px 12px;
`;

export const TextareaElement = styled(Input.Styles.InputElement)`
  ${customScrollMinimal};
  ${typographyV2.bodyBase};
  display: block;
  width: 100%;
  resize: none;
  padding-right: 44px;
  padding-left: 0;
`;
