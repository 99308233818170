import React, { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import {
  useZendeskService,
  ZendeskTickets,
} from '~/shared/services/zendesk/useZendeskService';
import { TradingProvider } from '~/stores/use-portfolio/utils/use-portfolio-utils';

import * as S from './ConfirmationScreen.styles';

export type ProcessingState =
  | 'processing'
  | 'processingExtended'
  | 'submitted'
  | 'cannotTrade'
  | 'error'
  | 'fatal';

export interface ConfirmationScreenProps {
  errorMessage?: string;
  state: ProcessingState;
  tradingProvider: TradingProvider;
  handleCloseWindow: () => void;
  handleTryAgain: () => void;
  handleCancelation: (withOutWarning?: boolean) => void;
  handleOrderStatus: () => void;
  handleContactProvider: (tradingProvider: TradingProvider) => void;
}

interface States {
  title: string;
  message?: string;
  img?: ReactElement;
  primaryAction?: ReactElement;
  secondaryAction?: ReactElement;
}
export const ConfirmationScreen: FC<ConfirmationScreenProps> = ({
  errorMessage,
  state,
  tradingProvider,
  handleCloseWindow,
  handleTryAgain,
  handleCancelation,
  handleOrderStatus,
  handleContactProvider,
}) => {
  const { createTicket } = useZendeskService();
  const { t } = useTranslation('portfolio');
  const handleContact = async () => {
    await createTicket(ZendeskTickets.FailedTradingOrder);
  };

  const errorState = (fatal = false) => ({
    title: t('portfolio:confirmationScreens.titleError', {
      context: fatal ? 'fatal' : undefined,
    }),
    message: errorMessage,
    img: (
      <S.Float
        src="/app/assets/trading/lonely.png"
        data-testid="processing-img"
      />
    ),
    primaryAction: (
      <>
        <S.ActionButton
          data-testid="primary-btn"
          label={t('portfolio:confirmationScreens.cancelOrder')}
          variant="secondary"
          size="medium"
          onClick={() => handleCancelation(fatal)}
          fullWidth
        />
        {!fatal && (
          <S.TryAgainButton
            data-testid="primary-btn-2"
            label={t('portfolio:confirmationScreens.tryAgain')}
            variant="primary"
            size="medium"
            onClick={handleTryAgain}
            fullWidth
          />
        )}
      </>
    ),
    secondaryAction: (
      <S.CustomButtonLink
        data-testid="secondary-btn"
        label={t('portfolio:confirmationScreens.contact')}
        onClick={handleContact}
      />
    ),
  });

  const closeWindow = t('portfolio:confirmationScreens.closeWindow');
  const stateElements: Record<ProcessingState, States> = {
    processing: {
      title: t('portfolio:confirmationScreens.submitting'),
      img: (
        <S.Float
          src="/app/assets/trading/whale.png"
          data-testid="processing-img"
        />
      ),
    },
    processingExtended: {
      title: t('portfolio:confirmationScreens.submitting'),
      message: t('portfolio:confirmationScreens.messageLoadingExtended'),
      img: (
        <S.Float
          src="/app/assets/trading/whale.png"
          data-testid="processing-img"
        />
      ),
      primaryAction: (
        <S.ActionButton
          data-testid="primary-btn"
          label={closeWindow}
          variant="secondary"
          size="medium"
          onClick={handleCloseWindow}
          fullWidth
        />
      ),
    },
    submitted: {
      title: t('portfolio:confirmationScreens.submitted'),
      img: (
        <img
          src="/app/assets/trading/wave.png"
          data-testid="processing-img"
          alt=""
        />
      ),
      primaryAction: (
        <S.ActionButton
          data-testid="primary-btn"
          label={t('portfolio:confirmationScreens.btnOrderStatus')}
          variant="secondary"
          size="medium"
          onClick={handleOrderStatus}
          fullWidth
        />
      ),
      secondaryAction: (
        <S.CustomButtonLink
          data-testid="secondary-btn"
          label={closeWindow}
          onClick={handleCloseWindow}
        />
      ),
    },
    cannotTrade: {
      title: t('portfolio:confirmationScreens.cannotTrade', {
        context: tradingProvider,
      }),
      message: errorMessage,
      img: (
        <img
          src="/app/assets/trading/oops.png"
          data-testid="processing-img"
          alt=""
        />
      ),
      primaryAction: (
        <S.ActionButton
          data-testid="primary-btn"
          label={closeWindow}
          variant="secondary"
          size="medium"
          onClick={() => handleCancelation(true)}
          fullWidth
        />
      ),
      secondaryAction: (
        <S.CustomButtonLink
          data-testid="secondary-btn"
          label={t('portfolio:confirmationScreens.contact', {
            context: tradingProvider,
          })}
          onClick={() => handleContactProvider(tradingProvider)}
        />
      ),
    },
    error: errorState(),
    fatal: errorState(true),
  };
  const activeState = stateElements[state];
  return (
    <S.Container state={state}>
      <S.Image state={state}>{activeState.img}</S.Image>
      <S.Body>
        <S.Title state={state} data-testid="title">
          {activeState.title}
        </S.Title>
        {activeState.message && (
          <S.Message state={state} data-testid="message">
            {activeState.message}
          </S.Message>
        )}
      </S.Body>
      <S.ActionButtonContainer>
        {activeState.primaryAction}
      </S.ActionButtonContainer>
      <S.SecondaryActionButtonContainer>
        {activeState.secondaryAction}
      </S.SecondaryActionButtonContainer>
    </S.Container>
  );
};
