import React, { FC } from 'react';

import { AppHeaderDefault } from '~/global/app-header/components/default-header/AppHeaderDefault';
import {
  NO_HEADER_PARAMS,
  NO_HEADER_PATHS,
  QUESTIONNAIRE_SUGGESTION_PATHS,
} from '~/routes/app-paths';

import { GuestHeader } from './components';
import { AppHeaderNoNav } from './components/no-nav-header/AppHeaderNoNav';

export interface AppHeaderProps {
  isAuthenticated: boolean;
  path: string;
  params: string;
}

export const AppHeader: FC<AppHeaderProps> = ({
  path,
  params,
  isAuthenticated,
}) => {
  if (NO_HEADER_PATHS.includes(path) || NO_HEADER_PARAMS.includes(params)) {
    return null;
  }

  if (QUESTIONNAIRE_SUGGESTION_PATHS.includes(path)) {
    return <AppHeaderNoNav />;
  }

  return isAuthenticated ? <AppHeaderDefault path={path} /> : <GuestHeader />;
};
