import { Modal, typographyV2 } from '@toggle/design-system';
import styled from 'styled-components';

export const StyledModal = styled(Modal)`
  ${Modal.Styled.ModalContainer} {
    width: 504px;
    padding: 0;
  }
`;

export const Container = styled.div`
  padding: 12px 20px;
`;

export const Row = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0px;

  &:not(:last-child) {
    border-bottom: 1px solid var(--border-soft);
  }

  & span {
    ${typographyV2.bodyBase}
  }
`;
