import { OrchestratorChatMetadata } from '../helpers/chat.types';
import { Metadata } from './chat-store.types';

export const getIntentFromResponse = (
  metadata?: OrchestratorChatMetadata
): Metadata | undefined => {
  try {
    if (
      metadata?.intents &&
      metadata.entities &&
      metadata.entities.length === 1
    ) {
      const entity = metadata.entities[0];
      const intent = metadata.intents[0];
      const entitySubheading = entity.exchange.code
        ? `${entity.ticker}:${entity.exchange.code}`
        : entity.ticker;

      return {
        // TODO temp solution news will be replaced on BE
        intent: ['news', 'performance'].includes(intent)
          ? 'entity-report'
          : intent,
        supported: [
          'entity-report',
          'earnings',
          'scenario',
          'news',
          'performance',
        ].includes(intent),
        entity: {
          ...entity,
          entitySubheading: entitySubheading.toUpperCase(),
        },
      };
    }
    return undefined;
  } catch {
    return undefined;
  }
};
