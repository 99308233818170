import React from 'react';

import { Icon } from '../icon/Icon';
import { Tab } from '../tab-lines/TabLines';
import * as S from './TabPanel.styles';

export interface TabPanelProps<T> {
  tabs: Tab<T>[];
  activeTab: T;
  onChange: (index: number, tabKey: T) => void;
}

export const TabPanel = <T extends string>({
  tabs,
  activeTab,
  onChange,
  ...rest
}: TabPanelProps<T>) => {
  const currentPanel = tabs.find(i => i.tabKey === activeTab)?.renderPanel;

  return (
    <S.TabContainer data-testid="tab-panel" {...rest}>
      <S.Tablist role="tablist">
        {tabs.map(
          ({ tabKey, tabLabel, iconName, imgNode, ...restTab }, index) => (
            <S.Tab
              key={tabKey}
              data-testid={`tab-panel-${tabKey}`}
              $active={activeTab === tabKey}
              {...restTab}
              role="tab"
              onClick={() => onChange(index, tabKey)}
            >
              {imgNode && <S.ImageWrapper>{imgNode}</S.ImageWrapper>}
              {iconName && <Icon size={20} iconName={iconName} />}
              <S.TabLabel>{tabLabel}</S.TabLabel>
            </S.Tab>
          )
        )}
      </S.Tablist>

      <S.TabPanel>{currentPanel}</S.TabPanel>
    </S.TabContainer>
  );
};

TabPanel.Styled = S;
