import React, { FC, ReactElement, useEffect } from 'react';

import { useHelper } from '~/stores/use-helper/useHelper';
import { Helper } from '~/views/helper/Helper';
import { HELPER_VIEW_IDS } from '~/views/helper/HelperConfig';
import { helperViewConfigs } from '~/views/helper/HelperViewConfigs';

interface ViewProps {
  helperViewId?: HELPER_VIEW_IDS;
  component: ReactElement;
}

export const View: FC<ViewProps> = ({ helperViewId, component: Component }) => {
  const { setCurrentAppViewKey } = useHelper();

  useEffect(() => {
    setCurrentAppViewKey(helperViewId);
  }, [helperViewId]);

  if (helperViewId) {
    return (
      <div
        data-testid="helper-wrapper"
        className={
          helperViewConfigs[helperViewId]?.wrapperHelperClassName ||
          'helper-compatible-container'
        }
      >
        {Component}
        <Helper />
      </div>
    );
  }

  return Component;
};
