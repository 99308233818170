import { Badge } from '@toggle/design-system';
import styled from 'styled-components';

export const Container = styled.div<{
  $width: number;
  $offset?: number;
  $top: number;
}>`
  width: ${props => `${props.$width}px`};
  padding: 4px 8px;
  display: flex;
  justify-content: center;
  z-index: 1;
  position: absolute;
  right: ${props => (props.$offset ? `${props.$offset}px` : 0)};
  top: ${props => `${props.$top}px`};
`;

export const Label = styled.span`
  text-transform: uppercase;
`;

export const StyledBadge = styled(Badge)`
  ${Badge.Styled.BadgeTextual} {
    background-color: var(--background-default);
  }
`;
