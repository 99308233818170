import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useEntities } from '~/shared/hooks/use-entities';

import { OrchestratorChatMetadata } from '../../helpers/chat.types';
import * as S from './AmbiguousEntities.styles';

export interface AmbiguousEntitiesProps {
  orchestratorMetadata: OrchestratorChatMetadata;
  onConfirm: (selectedTags: string[]) => void;
}
interface SelectedChipGroup {
  groupNumber: number;
  selectedTag: string;
}

export const AmbiguousEntities = ({
  orchestratorMetadata,
  onConfirm,
}: AmbiguousEntitiesProps) => {
  const currentTags = orchestratorMetadata.ambiguous_entities
    ?.flat()
    .map(e => e.tag);
  const { data: entities } = useEntities({
    entities: currentTags ?? [],
  });
  const [selectedChips, setSelectedChips] = useState<SelectedChipGroup[]>();
  const [hasNoneMatches, setHasNoneMatches] = useState(false);
  const { t } = useTranslation('chat');
  const noMatchesText = t('chat:ambiguousEntities.noneMatches');
  const isNoneMatchesSelected = selectedChips?.some(
    item => item.selectedTag === noMatchesText
  );

  const handleChipClick = (group: number, tag: string) => {
    const selectedItem = { groupNumber: group, selectedTag: tag };

    if (selectedChips) {
      const isTagInSameGroup = selectedChips.find(
        item => item.groupNumber === group
      );
      const isAlreadySelected = isTagInSameGroup?.selectedTag === tag;

      if (isAlreadySelected) {
        setSelectedChips(
          selectedChips.filter(
            item => item.groupNumber !== group || item.selectedTag !== tag
          )
        );
      } else if (isTagInSameGroup) {
        setSelectedChips([
          ...selectedChips.filter(item => item.groupNumber !== group),
          selectedItem,
        ]);
      } else {
        setSelectedChips([...selectedChips, selectedItem]);
      }
    } else {
      setSelectedChips([selectedItem]);
    }
  };

  const handleConfirmClick = () => {
    if (isNoneMatchesSelected) {
      setHasNoneMatches(true);
      return;
    }
    if (selectedChips) {
      const currentEntityTags =
        orchestratorMetadata?.entities?.map(e => e.tag) ?? [];
      const selectedTags = selectedChips.map(e => e.selectedTag);
      onConfirm([...selectedTags, ...currentEntityTags]);
    }
  };

  const isChipActive = (label: string, groupIndex: number) => {
    return selectedChips
      ?.filter(item => item.selectedTag === label)
      .some(i => i.groupNumber === groupIndex);
  };

  const getEntityName = (tag: string) => {
    const foundEntity = entities?.find(e => e.tag === tag);
    return foundEntity
      ? `${foundEntity.name} (${foundEntity.entitySubheading})`
      : tag;
  };

  const isValidEntitiesSelected =
    orchestratorMetadata.ambiguous_entities?.length === selectedChips?.length;

  const entitiesCount = orchestratorMetadata.ambiguous_entities?.length;

  return (
    <S.AmbiguousEntitiesRoot data-testid="ambiguous-entities">
      {hasNoneMatches ? (
        <S.Message>{t('chat:ambiguousEntities.noneMatchesMessage')}</S.Message>
      ) : (
        <>
          <S.Message>{t('chat:ambiguousEntities.reply')}</S.Message>
          {orchestratorMetadata.ambiguous_entities?.map((entity, index) => (
            <S.Container key={index}>
              {entitiesCount && entitiesCount > 1 ? (
                <S.MessageHeading data-testid="chip-heading">
                  {t('chat:ambiguousEntities.chipHeading', {
                    number: index + 1,
                  })}
                </S.MessageHeading>
              ) : null}
              {entity.map((e, i) => (
                <S.StyledChip
                  key={i}
                  data-testid={`chip-${index}`}
                  label={getEntityName(e.tag)}
                  isActive={isChipActive(e.tag, index)}
                  type="choice"
                  onClick={() => handleChipClick(index, e.tag)}
                />
              ))}
              <S.StyledChip
                data-testid="none-matches-btn"
                label={noMatchesText}
                isActive={isChipActive(noMatchesText, index)}
                type="choice"
                onClick={() => handleChipClick(index, noMatchesText)}
              />
            </S.Container>
          ))}
          <S.StyledButton
            data-testid="confirm-btn"
            label={t('chat:ambiguousEntities.confirm')}
            variant="tertiary"
            size="small"
            iconName="Checkmark"
            iconPosition="right"
            disabled={!isValidEntitiesSelected}
            onClick={handleConfirmClick}
          />
        </>
      )}
    </S.AmbiguousEntitiesRoot>
  );
};
