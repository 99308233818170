import React, { FC, useEffect, useState } from 'react';
import ZendeskWidget, { ZendeskAPI } from 'react-zendesk';

import { config } from '~/config';
import { useHelper } from '~/stores/use-helper/useHelper';
import { useUser } from '~/stores/use-user/useUser';

const { zendeskEmbedKey } = config;

// Take contact form as an example
// Let's customise our contact form appearance, launcher and add prefill content
const setting = {
  launcher: {
    chatLabel: {
      'en-US': 'Need Help',
    },
  },
  contactForm: {
    fields: [],
  },
};

const zendeskColorSchemes = {
  theme: '#ffb224',
  buttonText: '#fff',
};

interface ZendeskProps {
  onStatusChange?: (online: boolean) => void;
}

type ZendeskChatStatus = 'online' | 'offline' | 'away';

export const Zendesk: FC<ZendeskProps> = ({ onStatusChange }) => {
  const { setIsZendeskOnline } = useHelper();

  const [zendeskLoaded, setZendeskLoaded] = useState(false);
  const [chatStatus, setChatStatus] = useState<ZendeskChatStatus>('offline');
  const zendeskOnline = chatStatus === 'online';

  const { user } = useUser(({ user }) => ({ user }));

  const zendeskSettings = {
    ...setting,
    color: zendeskColorSchemes,
  };

  useEffect(() => {
    setIsZendeskOnline(zendeskOnline);
    onStatusChange?.(zendeskOnline);
  }, [zendeskOnline, onStatusChange]);

  useEffect(() => {
    if (zendeskLoaded) {
      ZendeskAPI('webWidget', 'hide');
      ZendeskAPI('webWidget:on', 'chat:status', (status: ZendeskChatStatus) => {
        setChatStatus(status);
      });
    }
    if (user?.email && zendeskLoaded) {
      ZendeskAPI('webWidget', 'identify', {
        name: user.first_name,
        email: user.email,
      });
    }
  }, [zendeskLoaded, user?.first_name, user?.email]);

  return (
    <ZendeskWidget
      zendeskKey={zendeskEmbedKey}
      {...zendeskSettings}
      onLoaded={() => setZendeskLoaded(true)}
    />
  );
};
