import { create } from '../create-store/createStore';
import type { Flags } from './feature-flags.types';
import {
  getConfigFlags,
  getPathnameFlags,
} from './services/feature-flags-services';

type State = {
  flags: Flags;
};

type Actions = {
  initialize: () => void;
  addFlags: (flags: Flags) => void;
};

type FlagStore = State & Actions;

const initialState: State = {
  flags: {},
};

export const useFeatureFlags = create<FlagStore>((set, get) => ({
  ...initialState,
  initialize: () => {
    const { addFlags } = get();
    const configFlags = getConfigFlags();
    const pathnameFlags = getPathnameFlags();
    addFlags({
      ...configFlags,
      ...pathnameFlags,
    });
  },
  addFlags: (flags: Flags) =>
    set(prevFlags => ({
      flags: {
        ...prevFlags.flags,
        ...flags,
      },
    })),
}));
