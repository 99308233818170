import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { OptionalU } from '~/declarations/standard';

const PASSWORD_LENGTH_MIN = 8;

type ErrorCallback = (error: OptionalU<string>) => void;

export const usePasswordValidation = (errorCallback?: ErrorCallback) => {
  const { t } = useTranslation('auth');
  const [error, setError] = useState<string>();

  const validate = (password: string) => {
    if (password.length < PASSWORD_LENGTH_MIN) {
      setError(
        t('auth:signUp.errors.passwordMinLength', {
          number: PASSWORD_LENGTH_MIN,
        })
      );
    } else {
      clearError();
    }
  };

  const clearError = () => {
    setError(undefined);
  };

  useEffect(() => {
    errorCallback?.(error);
  }, [error]);

  return {
    validate,
    hasError: !!error,
    clearError,
    error,
  };
};
