import { Button } from '@toggle/design-system';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { PortfolioIconWrapper } from '~/global/app-search/search-tabs/SearchTabs.styles';
import { appPaths } from '~/routes/app-paths';
import { getPortfolioName } from '~/shared/utils/portfolio-name/getPortfolioName';
import { useGlobalSearch } from '~/stores/use-global-search/useGlobalSearch';
import { Portfolio } from '~/stores/use-portfolio/usePortfolio';

import * as S from './OutOfSyncView.styles';

export interface OutOfSyncViewProps {
  outOfSyncPortfolios: Portfolio[];
  onDoItLaterClick: () => void;
  showBanner: boolean;
  showOutOfSyncPortfolios: boolean;
  isAllOutOfSync: boolean;
  isMobile: boolean;
}

export const OutOfSyncView: FC<OutOfSyncViewProps> = ({
  showBanner,
  showOutOfSyncPortfolios,
  outOfSyncPortfolios,
  isAllOutOfSync,
  isMobile,
  onDoItLaterClick,
}) => {
  const { t } = useTranslation('portfolio');
  const navigate = useNavigate();

  const onSyncPortfolioClick = () => {
    navigate(appPaths.myAccount.portfolio);
    useGlobalSearch.getState().closeSearch();
  };

  return (
    <S.Container
      isMobile={isMobile}
      isAllOutOfSync={isAllOutOfSync}
      showOutOfSyncPortfolios={showOutOfSyncPortfolios}
      data-testid="out-of-sync-view"
    >
      {showBanner && (
        <S.Banner>{t('portfolio:limitedFunctionalityBanner')}</S.Banner>
      )}
      {showOutOfSyncPortfolios && (
        <>
          {isAllOutOfSync && (
            <S.Text>
              {t('portfolio:limitedFunctionality', {
                count: outOfSyncPortfolios.length,
              })}
            </S.Text>
          )}
          <S.OutOfSyncContainer
            isAllOutOfSync={isAllOutOfSync}
            size={isAllOutOfSync ? 'sm' : 'md'}
            data-testid="out-of-sync-container"
          >
            {outOfSyncPortfolios.map(p => (
              <S.OutOfSyncPortfolio
                key={p.id}
                data-testid="out-of-sync-portfolio"
              >
                <PortfolioIconWrapper>
                  <img src={p.provider.logo} alt={t('header:logo.altImage')} />
                </PortfolioIconWrapper>
                <span>{getPortfolioName(p)}</span>
              </S.OutOfSyncPortfolio>
            ))}
            <S.BtnsContainer>
              {!isAllOutOfSync && (
                <Button
                  label={t('portfolio:syncLater')}
                  variant="secondary"
                  onClick={onDoItLaterClick}
                  size="medium"
                />
              )}
              <Button
                label={t('portfolio:syncPortfolio')}
                onClick={onSyncPortfolioClick}
                size="medium"
              />
            </S.BtnsContainer>
          </S.OutOfSyncContainer>
        </>
      )}
    </S.Container>
  );
};
