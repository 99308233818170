import { keyframes } from 'styled-components';

export const pulse = keyframes`
  0% {
    transform: scale(0.01);
    opacity: 0;
  }
  50% {
    opacity: 0.2;
  }
  70% {
    opacity: 0.4;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
`;
