import { useIsomorphicLayoutEffect } from '@toggle/helpers/src/hooks/use-isomorphic-layout-effect/useIsomorphicLayoutEffect';
import { MutableRefObject, useEffect, useRef } from 'react';

interface UseDropdownScrollProps<T> {
  dropdownItems: T[];
  shouldResetScrollOnItemsChange: boolean;
  showDropdownItems: boolean;
  activeItemIndex?: number;
  currentAction: 'hover' | 'focus';
  activeSuggestion: number;
}

export const useDropdownScroll = <T>({
  shouldResetScrollOnItemsChange,
  dropdownItems,
  showDropdownItems,
  activeItemIndex,
  currentAction,
  activeSuggestion,
}: UseDropdownScrollProps<T>) => {
  const dropdownContainerRef: MutableRefObject<HTMLDivElement | null> =
    useRef(null);
  const listRef: MutableRefObject<HTMLUListElement | null> = useRef(null);
  const listItemRef = useRef<HTMLLIElement>(null);

  useEffect(() => {
    if (shouldResetScrollOnItemsChange) {
      getScrollingContainer()?.scrollTo({ top: 0 });
    }
  }, [dropdownItems]);

  useIsomorphicLayoutEffect(() => {
    if (showDropdownItems && activeItemIndex !== undefined) {
      scrollToItem('center');
    }
  }, [activeItemIndex, showDropdownItems]);

  useIsomorphicLayoutEffect(() => {
    if (currentAction === 'focus' && activeSuggestion >= 0) {
      scrollToItem();
    }
  }, [activeSuggestion, currentAction]);

  const getScrollingContainer = () => {
    if (!dropdownContainerRef.current || !listRef.current) {
      return null;
    }

    const container = dropdownContainerRef.current;
    const list = listRef.current;
    return container.clientHeight < container.scrollHeight ? container : list;
  };

  const scrollToItem = (block: ScrollLogicalPosition = 'nearest') => {
    const element = listItemRef.current;
    const container = getScrollingContainer();
    if (!element || !container) {
      return;
    }

    if (block === 'center') {
      element.scrollTop =
        element.offsetTop - (container.clientHeight - element.clientHeight) / 2;
    } else {
      element.scrollIntoView({
        block,
      });
    }
  };

  return {
    dropdownContainerRef,
    listRef,
    listItemRef,
  };
};
