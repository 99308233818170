import { StyledComponent } from '@toggle/design-system';
import classNames from 'classnames';
import React, { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  TooltipContainer,
  TooltipContainerProps,
} from '~/shared/components/tooltip-container/TooltipContainer';
import { useMediaQuery } from '~/shared/hooks/MediaQueryHook';
import { MediaQueries } from '~/shared/hooks/MediaQueryList';
import { useUserEntities } from '~/stores/use-user-entities/useUserEntities';

import * as S from './WatchlistButton.styles';

export interface WatchlistButtonProps {
  onClickCallback?: (isActive: boolean) => void;
  onTooltipAnimated?: () => void;
  entityId: string;
  entityTag: string;
  wrapperClassName?: string;
  buttonClassName?: string;
  small?: boolean;
  placement?: TooltipContainerProps['placement'];
  hideTooltip?: boolean;
  className?: string;
}

const TOOLTIP_SHOW_DURATION = 1000; // ms

enum TOOLTIP_VALUE {
  ADDED = 'added',
  REMOVED = 'removed',
}

export const WatchlistButton: StyledComponent<
  WatchlistButtonProps,
  typeof S
> = ({
  onClickCallback,
  entityTag,
  wrapperClassName,
  buttonClassName,
  small = false,
  onTooltipAnimated,
  placement = 'top-start',
  hideTooltip,
  className,
}: WatchlistButtonProps) => {
  const [tooltipValue, setTooltipValue] = useState<TOOLTIP_VALUE>();
  const { t } = useTranslation(['common', 'subscription']);

  const { checkIsInWatchlist, addTag, removeTag } = useUserEntities(
    ({ watchlist: { checkIsInWatchlist, addTag, removeTag } }) => ({
      checkIsInWatchlist,
      addTag,
      removeTag,
    })
  );
  const lastCalled = useRef(0);
  const isActive = checkIsInWatchlist(entityTag);

  const currentMedia = useMediaQuery(s => s.currentMedia);
  const isMobile: boolean = [
    MediaQueries.SMALL_MOBILE,
    MediaQueries.MOBILE,
  ].includes(currentMedia);

  const handleClick = async (event: React.MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();
    if (event.timeStamp - lastCalled.current <= TOOLTIP_SHOW_DURATION) {
      return;
    }
    lastCalled.current = event.timeStamp;
    if (isActive) {
      await removeTag(entityTag);
      setTooltip(TOOLTIP_VALUE.REMOVED);
    } else {
      const response = await addTag(entityTag);
      if (!(response instanceof Error)) {
        setTooltip(TOOLTIP_VALUE.ADDED);
      }
    }
    onClickCallback?.(!isActive);
  };

  const setTooltip = (value: TOOLTIP_VALUE) => {
    setTooltipValue(value);
    setTimeout(() => {
      setTooltipValue(undefined);
      onTooltipAnimated?.();
    }, TOOLTIP_SHOW_DURATION);
  };

  const buttonLabel = isActive
    ? t('common:components.watchlistBtn.removeLabel')
    : t('common:components.watchlistBtn.addToWatchlistLabel');

  const tooltipOffset = useMemo(() => {
    const hoverOffset = !isMobile ? 4 : 0;
    if (small) {
      return {
        offsetX: 0,
        offsetY: -(4 + hoverOffset),
      };
    } else {
      return {
        offsetX: 0,
        offsetY: -(8 + hoverOffset),
      };
    }
  }, [small, isMobile]);

  return (
    <S.Wrapper
      isSmall={small}
      className={classNames(className, wrapperClassName)}
    >
      <S.Button
        data-testid="watchlist-btn"
        className={buttonClassName}
        isActive={isActive}
        notMobile={!isMobile}
        size="small"
        onClick={handleClick}
        label={
          <>
            <TooltipContainer
              isOpen={!!tooltipValue && !hideTooltip}
              label={t(`common:components.watchlistBtn.${tooltipValue}`)}
              {...tooltipOffset}
              placement={placement}
              strategy="fixed"
              disabled
              inPortal
            >
              <S.IconWrapper>
                <S.Icon
                  data-testid="icon"
                  size={20}
                  iconName={isActive ? 'Checkmark' : 'Add'}
                />
              </S.IconWrapper>
            </TooltipContainer>
            {buttonLabel}
          </>
        }
      />
    </S.Wrapper>
  );
};

WatchlistButton.Styled = S;
