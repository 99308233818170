import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { OnboardingStates } from '~/declarations/toggle-api-enums';
import { SearchAutocomplete } from '~/global/app-search/search/components/search-autocomplete-item/SearchAutocompleteItem';
import { Search } from '~/global/app-search/search/Search';
import { appPaths } from '~/routes/app-paths';
import { Video } from '~/shared/components/video/Video';
import { useRemoteStorage } from '~/shared/hooks/use-remote-storage/';
import { gaHelperClickedVideo } from '~/shared/utils/ganalytics';
import { useUser } from '~/stores/use-user/useUser';
import { ANALYZE_SECTION_IDS } from '~/views/helper/HelperConfig';

import { OnboardingStore, useOnboardingContext } from '../store';
import { AssetSuggestions } from './asset-suggestions';
import * as S from './Suggestion.styles';

const ANALYZE_VIDEO = 'https://www.youtube.com/embed/KszsaurkJn8';

export const SuggestionView: FC = () => {
  const { t } = useTranslation(['onboarding', 'header']);
  const { storeItems } = useRemoteStorage(({ storeItems }) => ({ storeItems }));
  const navigate = useNavigate();
  const { updateOnboardingState } = useUser(({ updateOnboardingState }) => ({
    updateOnboardingState,
  }));
  const { tradeOptions, investorOptions } = useOnboardingContext();

  const assetOverview = async (entity: string) => {
    await updateOnboardingState(OnboardingStates.COMPLETE);
    navigate(`${appPaths.analyze}/${entity}`);
  };

  const storeVideoWatched = () => {
    gaHelperClickedVideo();
    const key = ANALYZE_SECTION_IDS.ANALYZE_INTRO_ID;
    storeItems({
      [key]: 'active',
    });
  };

  const navigationCallback = (item: SearchAutocomplete) => {
    navigate(`${appPaths.analyze}/${item.tag}`, { replace: true });
  };

  return (
    <S.MainContainer>
      <S.RobotImg
        src="/app/assets/onboarding/robot.png"
        alt={t('onboarding:questionnaire.header.robotAlt')}
      />
      <S.Title>{t('onboarding:questionnaire.suggestions.asset.title')}</S.Title>
      <S.VideoContainer>
        <Video src={ANALYZE_VIDEO} onVideoClick={storeVideoWatched} />
      </S.VideoContainer>

      <S.SuggestionsSection>
        <S.SuggestionsText>
          {t('onboarding:questionnaire.suggestions.asset.text')}
        </S.SuggestionsText>
        <S.TagSearchContainer>
          <Search navigationCallback={navigationCallback} />
        </S.TagSearchContainer>

        <AssetSuggestions
          tradeOptions={tradeOptions}
          investorOptions={investorOptions}
          handleClick={assetOverview}
        />
      </S.SuggestionsSection>
    </S.MainContainer>
  );
};

export const Suggestion: FC = () => (
  <OnboardingStore>
    <SuggestionView />
  </OnboardingStore>
);
