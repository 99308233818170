import { Button } from '@toggle/design-system';
import React, {
  FC,
  ReactNode,
  RefObject,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import * as S from './CollapsePanel.styles';

interface CollapsePanelProps {
  className?: string;
  collapsedText: string;
  defaultText: string;
  isThemeButtonStyle: boolean;
  parentRef?: RefObject<unknown>;
  isDisabledBtn: boolean;
  onCollapsed?: (isCollapsed: boolean) => void;
  children: ReactNode;
}

export const CollapsePanel: FC<CollapsePanelProps> = ({
  children,
  className,
  collapsedText,
  defaultText,
  isThemeButtonStyle,
  parentRef,
  isDisabledBtn,
  onCollapsed,
}) => {
  const collapseRef = useRef<HTMLDivElement>(null);
  const [collapsed, setCollapsed] = useState(true);
  const toggleCollapse = () => {
    onCollapsed?.(!collapsed);
    setCollapsed(!collapsed);
  };

  useImperativeHandle(
    parentRef,
    () => ({
      uncollapseIfNeeded: () => {
        if (collapseRef?.current && !collapseRef?.current?.clientHeight) {
          collapseRef.current.style.height = 'auto';
          isDisabledBtn ? setCollapsed(true) : setCollapsed(false);
        }
      },
      collapsed,
    }),
    [collapsed]
  );

  return (
    <div className={className}>
      <S.CollapseWrapper
        collapsed={collapsed}
        $collapsed={collapsed}
        ref={collapseRef}
      >
        {children}
      </S.CollapseWrapper>
      {isThemeButtonStyle ? (
        <S.ButtonWrapper
          data-testid="collapse-panel-btn"
          onClick={toggleCollapse}
          label={collapsed ? collapsedText : defaultText}
          iconName={collapsed ? 'ChevronLightDown' : 'ChevronLightUp'}
          iconPosition="right"
          size="large"
          disabled={isDisabledBtn}
        />
      ) : (
        <Button
          size="large"
          label={collapsed ? collapsedText : defaultText}
          iconName={collapsed ? 'ChevronLightDown' : 'ChevronLightUp'}
          iconPosition="right"
          data-testid="collapse-panel-text"
          onClick={toggleCollapse}
          variant="tertiary"
        />
      )}
    </div>
  );
};
