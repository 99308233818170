export function isStatusSuccess(status: number): boolean {
  return status === 200;
}

export function isStatusFail(status: number): boolean {
  return status >= 400;
}

export function getContentTypeFromHeaders(headers: Headers): string {
  return headers.get('Content-Type') || '';
}

export function isJsonContentType(contentType: string) {
  return contentType.toLowerCase().includes('application/json');
}

export function isTextContentType(contentType: string) {
  return contentType.toLowerCase().includes('text/plain');
}
