import styled from 'styled-components';

import { borderRadius } from '~/design-tokens/border-radius/borderRadius';

import type { SizeType } from './Card';

type CardSizes = {
  [type in SizeType]: string;
};

const sizes: CardSizes = {
  small: '12px',
  medium: '16px',
  large: '20px',
};

export const CardRoot = styled.div<{ size: SizeType }>`
  ${borderRadius.r2};
  padding: ${props => sizes[props.size]};
  border: 1px solid var(--border-soft);
  background-color: var(--surface-default);
`;
