import ReactGA from 'react-ga4';

import { UserRole } from '~/api/subscription/subscription-schema';
import { config } from '~/config';
import { MenuParentItem } from '~/global/app-header/components/default-header/AppHeaderDefault';
import { SearchAutocomplete } from '~/global/app-search/search/components/search-autocomplete-item/SearchAutocompleteItem';
import { SearchTabsOptions } from '~/stores/use-global-search/useGlobalSearch';
import { SHARE_BUTTONS_NAMES } from '~/views/article/partials/article-menu/ShareButtons';

const CATEGORIES = {
  registration: 'Registration',
  search: 'Search',
  bookmark: 'Bookmark',
  sharing: 'Sharing',
  headers: 'Headers',
  articlePreview: 'Article_preview',
  myAccount: 'My_account',
};
export { CATEGORIES as COMMON_CATEGORIES };

const ACTIONS = {
  completeRegistration: 'Registered',
  setOverviewSearch: 'Global_search',
  setFailedSearch: 'Failed_search',
  addBookmark: 'Add_bookmark',
  removeBookmark: 'Remove_bookmark',
  shareArticle: 'Share_article',
  clickHeader: 'Click_header',
  headerNotificationOpen: 'Header_notification_open',
  headerNotificationConfirm: 'Header_notification_confirm',
  clickLogoutButton: 'Click_logout_button',
  articlePreviewOpen: 'Article_preview_open',
  clickSearchTab: 'Click_search_tab',
  clickSearchToTradeButton: 'Click_search_to_trade_button',
  clickGoToWebsite: 'Click_go_to_website',
};
export { ACTIONS as COMMON_ACTIONS };

export function gaInit(trackingId: string) {
  const options = {
    testMode: config.app.isTestEnvironment,
  };
  ReactGA.initialize(trackingId, { ...options });
}

export enum GaLocations {
  asset_overview = 'asset_overview',
  scenario = 'scenario',
  my_toggle = 'my_toggle',
  my_account = 'my_account',
  analyze = 'analyze',
  explore = 'explore',
  article = 'article',
  my_account_watchlist = 'my_account_watchlist',
  my_account_portfolio = 'my_account_portfolio',
  my_account_subscription = 'my_account_subscription',
  my_account_security = 'my_account_security',
  my_account_appearance = 'my_account_appearance',
  chart = 'chart',
  login = 'login',
  sign_up = 'sign_up',
  onboarding = 'onboarding',
  portfolio_positions = 'portfolio_positions',
  portfolio_performance = 'portfolio_performance',
  portfolio_orders = 'portfolio_orders',
}

export let pageSource = '';
export function gaSetScreenName(screenName?: GaLocations) {
  pageSource = screenName ?? '';
  ReactGA.set({ screenName: pageSource });
}

export function gaSetUserIDInGA(userId: string) {
  ReactGA.set({ userId });
}

// Events
export function gaCompleteRegistrationEvent() {
  ReactGA.event({
    category: CATEGORIES.registration,
    action: ACTIONS.completeRegistration,
  });
}

export function gaSendSearch(
  value: string,
  item: SearchAutocomplete,
  labelPosition: number
) {
  const tag = item.leftText.trim();
  const tagName = item.rightText ? `/${item.rightText.trim()}` : '';

  ReactGA.event({
    category: CATEGORIES.search,
    action: ACTIONS.setOverviewSearch,
    label: `searchedValue:${value};displayedLabel:${tag}${tagName};tag:${item.tag};labelPosition:${labelPosition};location:${pageSource}`,
  });
}

export function gaSendFailedSearch(value: string, isUserInitiated: boolean) {
  ReactGA.event({
    category: CATEGORIES.search,
    action: ACTIONS.setFailedSearch,
    label: `searchedValue:${value};userInitiated:${isUserInitiated}`,
  });
}

export function gaBookmark(
  uuid: string | null,
  bookmarked: boolean,
  location: 'article-preview' | 'analyze-insights-panel' | 'article-header',
  locked = false
) {
  ReactGA.event({
    category: CATEGORIES.bookmark,
    action: bookmarked ? ACTIONS.addBookmark : ACTIONS.removeBookmark,
    label: `uuid:${uuid ?? ''};location:${location};locked:${locked}`,
  });
}

export function gaShare(
  shareType: SHARE_BUTTONS_NAMES,
  location: string,
  pathname: string
) {
  const urlLocation = pathname.split('/')[1];
  ReactGA.event({
    category: CATEGORIES.sharing,
    action: ACTIONS.shareArticle,
    label: `shareType:${shareType};location:${location};urlLocation=${urlLocation}`,
  });
}

export function gaClickMenuHeader(
  menuItem: string,
  parentItem: MenuParentItem,
  tag?: string
) {
  const tagMsg = `tag:${tag};`;
  ReactGA.event({
    category: CATEGORIES.headers,
    action: ACTIONS.clickHeader,
    label: `menuItem:${menuItem};parentItem:${parentItem};${tag ? tagMsg : ''}`,
  });
}

export function gaClickGoToWebsite() {
  ReactGA.event({
    category: CATEGORIES.headers,
    action: ACTIONS.clickGoToWebsite,
  });
}

export function gaClickLogoutButton() {
  ReactGA.event({
    category: CATEGORIES.headers,
    action: ACTIONS.clickLogoutButton,
  });
}

export function gaHeaderNotificationAction(
  type: 'plan-warning' | 'trial-error' | 'promo-warning',
  userRole: UserRole,
  action: 'open' | 'confirm',
  promoCode?: string
) {
  const promo = promoCode ? `;promo:${promoCode}` : '';
  ReactGA.event({
    category: CATEGORIES.headers,
    action:
      action === 'open'
        ? ACTIONS.headerNotificationOpen
        : ACTIONS.headerNotificationConfirm,
    label: `type:${type};subscription:${userRole}${promo}`,
  });
}

export function gaClickArticlePreview(
  uuid: string | null,
  title: string,
  tag: string,
  userRole: UserRole,
  locked: boolean,
  isRelatedArticle: boolean,
  location: 'Explore' | 'Article' | 'Analyze'
) {
  ReactGA.event({
    category: CATEGORIES.articlePreview,
    action: ACTIONS.articlePreviewOpen,
    label: `uuid:${
      uuid ?? ''
    };title:${title};tag:${tag};subscription:${userRole};locked:${locked};isRelated:${isRelatedArticle};location:${location}`,
  });
}

export function gaClickSearchTab(searchTabsOption: SearchTabsOptions) {
  const mapTabToSearchType = {
    [SearchTabsOptions.Ib]: 'IB',
    [SearchTabsOptions.Tradestation]: 'TradeStation',
    [SearchTabsOptions.Insights]: 'toggle',
  };

  ReactGA.event({
    category: CATEGORIES.search,
    action: ACTIONS.clickSearchTab,
    label: `searchType:${mapTabToSearchType[searchTabsOption]}`,
  });
}

export function gaClickSearchToTradeButton(
  tag: string,
  numberToggleResults: number
) {
  ReactGA.event({
    category: CATEGORIES.search,
    action: ACTIONS.clickSearchToTradeButton,
    label: `tag:${tag};numberToggleResults:${numberToggleResults}`,
  });
}
