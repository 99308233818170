import { typographyV2 } from '@toggle/design-system';
import styled from 'styled-components';

import {
  Label,
  LoaderItem,
} from '~/shared/components/splash-screen/partials/splash-loader-item/SplashLoaderItem.styles';
import {
  Loaders,
  SplashScreen,
} from '~/shared/components/splash-screen/SplashScreen.styles';

export const LoadingBarsRoot = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 60px 0;
`;

export const Container = styled.div`
  width: 222px;

  ${SplashScreen} {
    height: auto;
  }

  ${Loaders} {
    margin: 0;
  }

  ${Label} {
    ${typographyV2.bodyXs};
    color: var(--text-soft);
    margin-bottom: 4px;
  }

  ${LoaderItem} {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;
