import { deviceConstraints } from '@toggle/design-system';
import styled from 'styled-components';

export const Container = styled.div`
  padding-top: 20px;
  padding-bottom: 64px;
`;

export const Title = styled.h2`
  color: var(--base--70);
  position: relative;
  padding-bottom: 6px;
  margin-bottom: 32px;
  padding-left: 16px;

  @media ${deviceConstraints.tablet} {
    padding-left: 0;
  }

  &:before {
    content: '';
    display: inline-block;
    width: 100%;

    @media ${deviceConstraints.tablet} {
      width: 288px;
    }

    left: 0;
    height: 2px;
    position: absolute;
    top: 100%;
    background-color: var(--base--70);
  }
`;
