import uniqBy from 'lodash/uniqBy';
import React, { FC, useEffect, useMemo, useState } from 'react';

import { ENDPOINT_URLS } from '~/global/toggle-api';
import { LoadingView } from '~/shared/components/loading-view/LoadingView';
import { useEntities } from '~/shared/hooks/use-entities';
import { APIFetch } from '~/shared/services/api-fetch/APIFetch';
import { Tracking } from '~/shared/services/tracking';
import { ExploreFeedResponse } from '~/views/explore';

import { Box } from '../../box';
import { generateFilterSlug } from '../../filters';
import { InvestorOptions, TradeOptions } from '../../store';
import * as S from './AssetSuggestions.styles';

interface AssetSuggestionsProps {
  tradeOptions: TradeOptions;
  investorOptions: InvestorOptions;
  handleClick: (entity: string) => void;
}

export const AssetSuggestions: FC<AssetSuggestionsProps> = ({
  tradeOptions,
  investorOptions,
  handleClick,
}) => {
  const [entities, setEntities] = useState<string[]>([]);
  const [processing, setProcessing] = useState(false);
  const { data, loading } = useEntities({ entities });

  const slug = useMemo(
    () => generateFilterSlug(tradeOptions, investorOptions),
    [tradeOptions, investorOptions]
  );

  const FALLBACK_FILTER = 'hot_topic=true';
  const callExplore = async (slug: string) => {
    setProcessing(true);
    try {
      const insights = await APIFetch<ExploreFeedResponse>(
        `${ENDPOINT_URLS.ARTICLE_EXPLORE}?${slug}`
      );

      if (insights instanceof Error) {
        throw insights;
      }
      if (insights?.result.length) {
        const filteredInsightsUniqueness = uniqBy(insights.result, 'entity');
        const sortedBYStars = [...filteredInsightsUniqueness].sort(
          (a, b) => b.stars - a.stars
        );
        const items = sortedBYStars.map(insight => insight.entity);
        setEntities(items);
      } else {
        if (slug === FALLBACK_FILTER) {
          throw new Error();
        }
        callExplore(FALLBACK_FILTER);
      }
    } catch (e) {
      Tracking.captureException(e as Error);
    } finally {
      setProcessing(false);
    }
  };

  useEffect(() => {
    if (slug) {
      callExplore(slug);
    }
  }, [slug]);

  useEffect(() => {
    setProcessing(loading);
  }, [loading]);

  return (
    <LoadingView loading={processing}>
      {data &&
        data.slice(0, 3).map(entity => {
          return (
            <Box
              key={entity.tag}
              left={
                <>
                  <S.BoxTextLg>{entity.name}</S.BoxTextLg>
                  <S.BoxText>{entity.name}</S.BoxText>
                </>
              }
              id={entity.tag}
              onClick={i => handleClick(i)}
              active={false}
            />
          );
        })}
    </LoadingView>
  );
};
