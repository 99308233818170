import { useEffect } from 'react';

import { useSubscription } from '~/stores/use-subscription/useSubscription';

import {
  LivePriceData,
  PriceEntity,
  SubscribeFn,
  useLivePriceStore,
} from './use-live-price-store/useLivePriceStore';

interface StateSlice {
  subscribe: SubscribeFn;
  livePriceData: LivePriceData;
}

const bindEquityFnWithEntities =
  (priceEntities: PriceEntity[]) =>
  (prevState: StateSlice, currentState: StateSlice) =>
    priceEntities.every(e => {
      const ticker = e.entity.ticker;
      return (
        prevState.livePriceData[ticker] === currentState.livePriceData[ticker]
      );
    });

export const useLivePrice = (priceEntities: PriceEntity[]) => {
  const isBasic = useSubscription(state => state.isBasic());
  const { subscribe, livePriceData } = useLivePriceStore(
    s => ({
      subscribe: s.subscribe,
      livePriceData: s.livePriceData,
    }),
    bindEquityFnWithEntities(priceEntities)
  );

  useEffect(() => {
    if (isBasic || !priceEntities?.length) {
      return undefined;
    }

    return subscribe(priceEntities);
  }, [isBasic, priceEntities.map(e => e.entity.ticker).join(' ')]);

  return livePriceData;
};
