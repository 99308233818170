import React from 'react';
import { useNavigate } from 'react-router-dom';

import { accountRoutes, appPaths } from '~/routes/app-paths';
import { useSubscription } from '~/stores/use-subscription/useSubscription';
import { useNavigateToCheckout } from '~/views/my-account/subscription/hooks/use-navigate-to-checkout/useNavigateToCheckout';

import { AlertEndOfTrial } from './components/alert-end-of-trial/AlertEndOfTrial';
import { AlertPaymentMethod } from './components/alert-payment-method/AlertPaymentMethod';
import { AlertTrialEnds } from './components/alert-trial-ends/AlertTrialEnds';
import * as S from './NotificationAlerts.styles';

export const NotificationAlerts = () => {
  const navigate = useNavigate();
  const { card, userSubscription } = useSubscription(state => ({
    userSubscription: state.userSubscription,
    card: state.card,
  }));

  const upgradeToPro = useNavigateToCheckout();

  const redirectToPlanMatrixPage = () => {
    navigate(`${appPaths.myAccount.subscription}/${accountRoutes.selectPlan}`);
  };

  return (
    <S.NotificationAlertsRoot data-testid="notification-alerts">
      <AlertTrialEnds
        upgradeToPro={upgradeToPro}
        redirectToPlanMatrixPage={redirectToPlanMatrixPage}
      />
      <AlertEndOfTrial
        upgradeToPro={upgradeToPro}
        redirectToPlanMatrixPage={redirectToPlanMatrixPage}
      />
      {card && (
        <AlertPaymentMethod card={card} userSubscription={userSubscription} />
      )}
    </S.NotificationAlertsRoot>
  );
};
