import React from 'react';

import { BasicUpgradeButton } from '~/shared/components/header-notification-buttons/basic-upgrade-button/BasicUpgradeButton';
import { PromoPill } from '~/shared/components/header-notification-buttons/promo-pill/PromoPill';
import { usePromoCodeStore } from '~/stores/use-promo-code/usePromoCodeStore';
import { useSubscription } from '~/stores/use-subscription/useSubscription';

export const HeaderNotification = () => {
  const { userSubscription, hasActivePromo, isBasic, isPro } = useSubscription(
    ({ userSubscription, hasActivePromo, isBasic, isPro }) => ({
      userSubscription,
      hasActivePromo,
      isBasic: isBasic(),
      isPro: isPro(),
    })
  );

  const { promoMetadata, promoCode, hasPromo } = usePromoCodeStore(state => ({
    promoMetadata: state.promoMetadata,
    hasPromo: !!state.promoMetadata.headerPill,
    promoCode: state.promoCode,
  }));

  if (!userSubscription) {
    return null;
  }

  const isBasicPlanWarning = isBasic && !isPro;
  const showPromoNotification = hasPromo && hasActivePromo;

  if (showPromoNotification) {
    return (
      <PromoPill
        pillLabel={promoMetadata.headerPill}
        promoCode={promoCode}
        messageMd={promoMetadata.headerBannerMd}
      />
    );
  }

  if (isBasicPlanWarning) {
    return <BasicUpgradeButton />;
  }

  return null;
};
