import styled from 'styled-components';

import { typographyV2 } from '~/design-tokens/typography/Typography.styles';

import { TextWithBgAlign } from './text-with-bg-utils';

const positionToTextAnchor: Record<string, string> = {
  [TextWithBgAlign.Bottom]: 'middle',
  [TextWithBgAlign.Top]: 'middle',
  [TextWithBgAlign.Left]: 'end',
  [TextWithBgAlign.TopLeft]: 'start',
  [TextWithBgAlign.BottomLeft]: 'start',
};

export const Rect = styled.rect`
  fill: var(--background-soft);
  opacity: 0.9;
  stroke: none;
`;

export const Text = styled.text<{ $position: TextWithBgAlign }>`
  fill: currentColor;
  ${typographyV2.bodySm};
  dominant-baseline: ${({ $position }) =>
    $position === TextWithBgAlign.Right && 'middle'};
  text-anchor: ${({ $position }) => positionToTextAnchor[$position]};
`;

export const Group = styled.g<{ $isSelected: boolean }>`
  ${({ $isSelected }) =>
    $isSelected &&
    `
      ${Rect} {
        fill: currentColor;
        opacity: 0.9;
      }

      ${Text} {
        fill: var(--background-soft);
      }
    `}
`;
