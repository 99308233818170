import styled, { css } from 'styled-components';

import { typographyV2 } from '~/design-tokens/typography/Typography.styles';

import { LineVariants } from './VerticalGridLines.types';

export const VerticalLine = styled.line<{ $variant?: LineVariants }>`
  stroke: var(--border-default);
  stroke-width: 2px;

  ${props =>
    props.$variant === 'muted' &&
    css`
      stroke: var(--border-soft);
      stroke-width: 1px;
    `};

  ${props =>
    props.$variant === 'selected' &&
    css`
      stroke: var(--border-primary);
    `};
`;

export const Label = styled.text`
  ${typographyV2.titleSm};
  fill: var(--text-soft);
  font-family: var(--font-nort);
  text-transform: uppercase;
`;
