export const EQUITIES_FILTER = {
  properties: {
    asset_cls: 'class_equity',
  },
};

export const CURRENCIES_FILTER = {
  properties: {
    asset_cls: 'class_fx',
  },
};

export const COMMODITIES_FILTER = {
  properties: {
    asset_cls: 'class_commodity',
  },
};

export const BEYOND_STOCKS_FILTER = {
  properties: {
    asset_cls: 'class_fi',
  },
};
