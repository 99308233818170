import styled, { css } from 'styled-components';

import { TooltipContainer } from '~/shared/components/tooltip-container/TooltipContainer';

export const StyledTooltipContainer = styled(TooltipContainer)`
  border: none;
  background: none;
  box-shadow: none;
  position: relative;
  max-width: 100%;
  padding: 0px 12px;
`;

export const IconContainer = styled.div<{ $direction: string }>`
  position: absolute;
  cursor: pointer;
  svg {
    ${({ $direction }) => {
      if ($direction === 'bullish' || $direction === 'multi-bullish') {
        return css`
          path {
            fill: var(--viz-bullish);
          }
        `;
      }
      if ($direction === 'bearish' || $direction === 'multi-bearish') {
        return css`
          path {
            fill: var(--viz-bearish);
          }
        `;
      }
      if ($direction === 'multi-mixed') {
        return css`
          path:nth-child(2) {
            fill: var(--viz-bullish);
          }
          path:nth-last-child(2) {
            fill: var(--viz-bearish);
          }
        `;
      }
      return '';
    }}
  }
`;
