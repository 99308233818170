import { v4 } from 'uuid';

import { chatEndpoints } from '../../../api/chat/chat-services';
import { Message, MessageStatus, SendMessageProps } from './chat-store.types';

interface UpdatedMessagesProps {
  requestId: string;
  newMessage: SendMessageProps;
  existingMessages: Message[];
}
export const updatedMessages = ({
  requestId,
  newMessage,
  existingMessages,
}: UpdatedMessagesProps): Message[] => {
  const newMessageData = {
    reply: newMessage.reply,
    ...(newMessage.question && { question: newMessage.question }),
    status: MessageStatus.Sent,
    isActive: true,
    requestId,
    service: newMessage.service,
  };

  if (newMessage.overrideRequestId) {
    return existingMessages.map(m =>
      m.requestId === newMessage.overrideRequestId ? newMessageData : m
    );
  }

  return [
    ...existingMessages.map(m => {
      return { ...m, isActive: false };
    }),
    newMessageData,
  ];
};

export const getEndpointDetails = (
  newMessage: SendMessageProps,
  requestId: string,
  abortSignal: AbortSignal,
  sessionId: string
) => {
  const defaultBody = {
    session_id: sessionId,
  };
  const defaultOptions: RequestInit = {
    signal: abortSignal,
    headers: {
      'X-Request-Id': requestId,
    },
    body: JSON.stringify(defaultBody),
  };

  const endPointDetails = {
    'archaic-secretary': {
      url: chatEndpoints.archaicSecretary,
      options: defaultOptions,
    },
    orchestrator: {
      url: chatEndpoints.orchestrator,
      options: {
        ...defaultOptions,
        body: JSON.stringify({
          ...defaultBody,
          question: newMessage.question,
          entities: newMessage.entities,
          intents: newMessage.intents,
          scopes: newMessage.scopes,
        }),
      },
    },
    'insight-assistant': {
      url: chatEndpoints.insightAssitant,
      options: {
        ...defaultOptions,
        body: JSON.stringify({
          ...defaultBody,
          insight_id: newMessage.reply?.insight_id,
        }),
      },
    },
  };
  return endPointDetails[newMessage.service];
};

interface HandleSentMessageReturn {
  requestId: string;
  url: string;
  options: RequestInit;
  updatedMessages: Message[];
}

export const handleSentMessage = (
  newMessage: SendMessageProps,
  existingMessages: Message[],
  abortSignal: AbortSignal,
  sessionId: string
): HandleSentMessageReturn => {
  const requestId = v4();
  const { url, options } = getEndpointDetails(
    newMessage,
    requestId,
    abortSignal,
    sessionId
  );

  return {
    requestId,
    url,
    options,
    updatedMessages: updatedMessages({
      requestId,
      newMessage,
      existingMessages,
    }),
  };
};
