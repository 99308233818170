import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  GtkCardData,
  gtkMapping,
} from '~/views/analyze/asset-overview/components/sidebar/components/rocketship-tab/helpers/RocketshipTabHelpers';
import { GtKItem } from '~/views/analyze/hooks/use-gtk/useGtK';

import * as SharedStyles from '../shared.styles';
import * as S from './GtkItem.styles';

export interface GtkItemProps {
  onGtkClick: (gtk: GtkCardData) => void;
  filteredGtks: GtKItem[];
}

export const GtkItem = ({ onGtkClick, filteredGtks }: GtkItemProps) => {
  const { t } = useTranslation('analyze');

  return (
    <div>
      {filteredGtks.map((item, idx) => {
        const gtk = gtkMapping(item, t);
        return (
          <S.Card
            key={idx}
            data-testid="gtk-item-card"
            onClick={() => onGtkClick(gtk)}
          >
            <SharedStyles.CardText>{gtk.name}</SharedStyles.CardText>
            <SharedStyles.CardImageWrapper>
              {gtk.imgNode}
            </SharedStyles.CardImageWrapper>
          </S.Card>
        );
      })}
    </div>
  );
};
