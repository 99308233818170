import { Alert, borderRadius, typographyV2 } from '@toggle/design-system';
import styled, { css } from 'styled-components';

import { ProgressRoot } from '~/views/chat/components/progress/Progress.styles';

export const MessageRoot = styled.div<{
  $isActive: boolean;
  $questionHeight?: number;
}>`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 20px;
  margin-bottom: 16px;
  background-color: var(--surface-default);
  ${borderRadius.r3}
  border: 1px solid transparent;
  &:focus-visible {
    outline: 1px solid var(--border-focus);
  }
  &:hover {
    border: 1px solid var(--border-hover);
  }

  ${({ $questionHeight }) =>
    $questionHeight &&
    css`
      scroll-margin-top: calc(20px + ${$questionHeight}px);
    `}

  ${({ $isActive }) =>
    $isActive &&
    css`
      border: 1px solid var(--border-default);
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3),
        0px 8px 12px 6px rgba(0, 0, 0, 0.15);
    `}
`;

export const ReceivedMessage = styled.div`
  padding: 20px;
  background-color: var(--surface-pressed);
  ${borderRadius.r2}
`;

export const UserMessageText = styled.p`
  ${typographyV2.bodyBase};
  color: var(--text-default);
  margin-bottom: 16px;
`;

export const ButtonContainer = styled.div<{ $messageMatch: boolean }>`
  display: flex;
  justify-content: space-between;
  ${({ $messageMatch }) =>
    $messageMatch
      ? css`
          margin-top: 16px;
          max-height: 100px;
          opacity: 1;
        `
      : css`
          max-height: 0px;
          opacity: 0;
          pointer-events: none;
        `};
  transition: max-height 0.25s ease, opacity ease 0.2s;
`;

export const ActionButtons = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const InlineProgress = styled.div`
  display: flex;

  ${ProgressRoot} {
    margin: 0;
    padding: 0;
  }
`;

export const StyledAlert = styled(Alert)`
  width: 100%;
  margin-bottom: 16px;
`;
