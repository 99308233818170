import React from 'react';
import { useTranslation } from 'react-i18next';

import * as S from '../Content.styles';

export function FullAssetPictureContent() {
  const { t } = useTranslation('helperWidget');
  return (
    <S.Content>
      <S.Paragraph>
        {t('helperWidget:insight.content.fullAssetPicture.firstParagraph')}
      </S.Paragraph>
    </S.Content>
  );
}
