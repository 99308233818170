import { ButtonGroup } from '@toggle/design-system';
import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  top: 8px;
`;

export const StyledButtonGroup = styled(ButtonGroup)`
  white-space: nowrap;
`;
