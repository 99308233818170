import { deviceConstraints } from '@toggle/design-system';
import styled from 'styled-components';

import { Box } from '../box';
import * as QuestionnaireSharedStyles from '../questionnaire-shared-styles/QuestionnaireSharedStyles.styles';

export const Container = styled.div`
  display: grid;
  grid-template-areas:
    'tradeIdea'
    'asset';
  @media ${deviceConstraints.desktopSmall} {
    grid-template-areas: 'tradeIdea asset';
    grid-gap: 16px;
  }
`;

export const TradeIdeaBox = styled(Box)`
  grid-area: tradeIdea;
`;

export const AssetBox = styled(Box)`
  grid-area: asset;
`;

export const Img = styled(QuestionnaireSharedStyles.Img)`
  @media ${deviceConstraints.desktopSmall} {
    height: 130px;
  }
`;
