import { deviceConstraints, Icon, typography } from '@toggle/design-system';
import styled, { css } from 'styled-components';

import { ExploreArticleDirection } from '~/declarations/toggle-api-enums';
import { InsightSummary } from '~/views/analyze/asset-overview/components/sidebar/components/insight-summary/InsightSummary';

export const getBullishBearishStyles = (type: ExploreArticleDirection) =>
  type === ExploreArticleDirection.Bullish
    ? css`
        color: var(--viz-bullish);
      `
    : css`
        color: var(--viz-bearish);
      `;

export const Wrapper = styled.div<{
  $fullHeight?: boolean;
  $isExpanded?: boolean;
}>`
  display: flex;
  flex-direction: column;
  position: fixed;
  bottom: 0;
  width: 368px;
  min-height: 100%;
  border-radius: 4px;
  box-shadow: 0 0 4px var(--base--100);
  background-color: var(--base--100);

  ${({ $fullHeight }) => $fullHeight && 'height: 100%;'};
  ${({ $isExpanded }) => $isExpanded && 'height: auto;'};

  @media ${deviceConstraints.tablet} {
    position: relative;
    width: unset;
  }

  @media ${deviceConstraints.desktopSmall} {
    min-height: unset;
  }
`;

export const Overlay = styled.div`
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(var(--base--100-rgb), 0.75);
  z-index: var(--z-index-app-header);
`;

export const InsightCard = styled.article`
  > * {
    margin: 0;
  }

  position: relative;
  width: 368px;
  height: 200px;

  @media ${deviceConstraints.tablet} {
    width: 568px;
    height: 255px;
  }

  @media ${deviceConstraints.desktopSmall} {
    width: 265px;
    height: auto;
  }

  @media ${deviceConstraints.desktop} {
    width: 375px;
  }
`;

export const InsightCardBody = styled.div`
  flex-grow: 1;
  padding: 24px 0 21px 40px;
  margin-top: 0;

  @media ${deviceConstraints.tablet} {
    display: flex;
    align-items: center;
    padding: 4px 18px 16px 16px;

    & > div {
      flex-basis: 50%;
    }
  }

  @media ${deviceConstraints.desktopSmall} {
    align-items: flex-start;
    display: block;
  }

  @media ${deviceConstraints.desktop} {
    padding: 16px 0 24px 16px;
  }
`;

export const PriceChange = styled.h2<{ type: ExploreArticleDirection }>`
  font-family: var(--font-roboto-mono);
  font-weight: 600;
  ${typography.elephant};
  line-height: 1.2;

  @media ${deviceConstraints.desktopSmall} {
    ${typography.rhino};
  }

  ${({ type }) => getBullishBearishStyles(type)};
`;

export const PriceContainer = styled.div`
  @media ${deviceConstraints.tablet} {
    align-self: flex-start;
    padding-top: 28px;
  }
  @media ${deviceConstraints.desktopSmall} {
    padding-top: 0;
  }
`;

export const Text = styled.span`
  ${typography.emu};
  margin-right: 6px;

  @media ${deviceConstraints.desktop} {
    ${typography.dingo};
  }
`;

export const Info = styled.p`
  @media ${deviceConstraints.smartphone} {
    ${typography.emu};
    padding: 24px 12px 16px;
  }

  @media ${deviceConstraints.desktop} {
    ${typography.dingo};
    padding: 26px 16px 16px;
  }
`;

export const Box = styled.div`
  display: flex;
  align-items: center;
`;

export const CollapseButton = styled.div`
  display: flex;
  align-items: center;
  padding-top: 7px;
  font-weight: 500;
  cursor: pointer;
  ${typography.dingo};
  border-top: 1px solid var(--primary-color);
  color: var(--primary-color);
`;

export const StatusText = styled.h2`
  font-weight: 500;
  ${typography.elephant};

  @media ${deviceConstraints.desktopSmall} {
    ${typography.rhino};
  }
`;

export const InfoIcon = styled(Icon)`
  margin-top: 4px;
`;

export const ChevronDownIcon = styled(Icon)`
  margin: 0 13px;
`;

const showOnMobile = css`
  display: block;
  @media ${deviceConstraints.tablet} {
    display: none;
  }
`;

export const showOnTablet = css`
  display: none;
  @media ${deviceConstraints.tablet} {
    display: block;
  }
  @media ${deviceConstraints.desktopSmall} {
    display: none;
  }
`;

export const showOnDesktopSmall = css`
  display: none;
  @media ${deviceConstraints.desktopSmall} {
    display: block;
  }
`;

export const hideOnDesktopSmall = css`
  display: block;
  @media ${deviceConstraints.desktopSmall} {
    display: none;
  }
`;

export const StyledInsightSummary = styled(InsightSummary)<{
  constraint?: 'mobile' | 'tablet' | 'desktop';
}>`
  ${({ constraint }) => constraint === 'mobile' && showOnMobile};
  ${({ constraint }) => constraint === 'tablet' && showOnTablet};
  ${({ constraint }) => constraint === 'desktop' && showOnDesktopSmall};
`;
