export const margin = { top: 16, right: 12, bottom: 16, left: 8 };
export const BAR_PADDING_VERTICAL = 38;
export const TICK_COUNT = 10;
export const LABEL_HEIGHT = 16;
export const BAR_HEIGHT = 16;
export const BAR_CHART_WIDTH = 392;
export const LABEL_WIDTH = 263;
export const ROW_HEIGHT = 48;
export const PORTFOLIO_ITEM_LEFT_PADDING = 24;
export const CHART_WIDTH = 756;
