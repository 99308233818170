import {
  Button,
  customScrollMinimal,
  deviceConstraints,
  typography,
} from '@toggle/design-system';
import styled, { css } from 'styled-components';

import { ArticleCard as SharedArticleCard } from '~/shared/components/article-card/ArticleCard';
import { LoadingView } from '~/shared/components/loading-view/LoadingView';
import { StatsCard as DefaultStatsCard } from '~/views/explore/feed/partials/stats-card/StatsCard';

const desktopFilterWidth = '312px';
const mobileFiltersAnimationDuration = '150ms';

export const ExploreFeed = styled.main`
  color: var(--base--0);
  background: var(--base--100);
  position: relative;
  display: grid;
  grid-template-rows: 1fr;
  width: 100%;
  grid-template-areas: 'feedContainer';

  @media ${deviceConstraints.desktopSmall} {
    grid-template-areas: 'filtersDesktop feedContainer';
    grid-template-columns: ${desktopFilterWidth} auto;
  }

  @media ${deviceConstraints.desktop} {
    max-width: 1280px;

    grid-template-columns: minmax(315px, auto) minmax(auto, 965px);
    margin: 0 auto;
  }
`;

export const ExploreFeedContainer = styled.section`
  grid-area: feedContainer;
  margin: 20px 16px;

  @media ${deviceConstraints.desktopSmall} {
    margin: 40px 20px 0px;
  }

  @media ${deviceConstraints.desktop} {
    margin: 40px 76px 0px 70px;
  }

  @media ${deviceConstraints.desktopLarge} {
    margin: 40px 0px 0px 70px;
  }

  &:focus {
    outline: none;
  }
`;

const marginCentered = css`
  @media ${deviceConstraints.desktopSmall} {
    margin: 0 auto;
  }
`;

export const ExploreFeedList = styled.section`
  ${marginCentered};
  grid-area: feedList;
`;

const feedCard = css`
  @media ${deviceConstraints.desktopSmall} {
    margin-bottom: 16px;
  }
`;

export const StatsCard = styled(DefaultStatsCard)`
  ${feedCard};
`;

export const ArticleCard = styled(SharedArticleCard)`
  ${feedCard};
`;

export const LoadingContainer = styled(LoadingView)`
  height: 64px;
  ${feedCard}
`;

export const Overlay = styled.div`
  display: block;
  position: fixed;
  left: 0px;
  background-color: rgba(var(--base--100-rgb), 0.6);

  @media ${deviceConstraints.desktopSmall} {
    display: none;
  }
`;

export const ExploreFeedFilters = styled.section<{ isMobile: boolean }>`
  display: block;
  height: 100%;
  width: 300px;
  position: fixed;
  left: -300px;
  top: 0;
  background-color: var(--base--95);
  transition: left ${mobileFiltersAnimationDuration} ease-out;

  ${customScrollMinimal};
  overflow: hidden;

  @media ${deviceConstraints.desktopSmall} {
    grid-area: filtersDesktop;
    top: unset;
    left: unset;
    width: ${desktopFilterWidth};
    height: calc(100% - var(--app-header-height));
    overflow-y: auto;
    background-color: var(--base--100);
  }

  ${({ isMobile }) =>
    isMobile &&
    css`
      left: 0;
      z-index: var(--z-index-app-header);

      ${Overlay} {
        left: 300px;
        top: 0;
        right: 0;
        bottom: 0;
        transition: left ${mobileFiltersAnimationDuration} ease-out;
      }
    `};
`;

export const ScreensStickyWrapper = styled.div`
  position: sticky;
  font-size: 16px;
  margin: -20px -10px 0;
  padding: 15px 10px 12px;
  background-color: var(--base--100);
  top: var(--app-header-height);
  z-index: 1;

  @media ${deviceConstraints.desktopSmall} {
    padding: 40px 10px 20px;
    margin: -40px -10px 0;
  }
`;

export const MobileButtons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const OpenFiltersButton = styled(Button)`
  padding: 8px 24px;
  margin-left: auto;
  ${typography.emu};

  svg {
    margin-right: 4px;
  }
`;
