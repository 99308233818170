import { deviceConstraints, Icon, typography } from '@toggle/design-system';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  background-color: var(--base--100);
  align-items: center;
  padding: 0 16px;

  @media ${deviceConstraints.tablet} {
    align-items: center;
    padding: 0;
  }

  & .menuWrapper {
    width: 100%;
  }

  & .menuInner {
    display: flex;
  }

  & .arrowDisabled {
    visibility: hidden;
  }
`;

export const OverviewLink = styled(Link)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 12px 14px;
  border: 1px solid rgba(255, 255, 255, 0.16);
  border-radius: 4px;

  &:hover {
    border-color: var(--primary-color);
    background-color: rgba(var(--primary-color-rgb), 0.08);
  }

  & > span {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    ${typography.emu};
    color: var(--base--20);
    text-transform: capitalize;
  }

  &:hover > span {
    color: var(--primary-color);
  }

  @media ${deviceConstraints.smartphone} {
    margin-top: 16px;
    max-width: 369px;
    margin-bottom: 8px;
  }

  @media ${deviceConstraints.smartphoneOrTablet} {
    margin-top: 16px;
    max-width: 369px;
    margin-bottom: 16px;
    align-self: center;
  }

  @media ${deviceConstraints.tablet} {
    max-width: 300px;
    margin-bottom: 8px;
  }

  @media (min-width: 880px) and (max-width: 980px) {
    width: 100%;
    margin-bottom: 18px;
  }

  @media ${deviceConstraints.desktopSmall} {
    margin-bottom: 24px;
  }

  @media ${deviceConstraints.desktop} {
    max-width: unset;
  }
`;

const arrowCss = `
  cursor: pointer;
  position: absolute;
  bottom: 18px;
`;

export const PrevArrow = styled(Icon).attrs({
  size: 15,
  iconName: 'ChevronLightLeft',
})`
  ${arrowCss};
  left: 0;
`;

export const NextArrow = styled(Icon).attrs({
  size: 15,
  iconName: 'ChevronLightRight',
})`
  ${arrowCss};
`;

export const ChevronRight = styled(Icon).attrs({
  size: 15,
  iconName: 'ChevronLightRight',
})`
  display: none;

  @media ${deviceConstraints.desktopSmall} {
    display: block;
  }
`;

export const LockedIcon = styled(Icon).attrs({
  size: 15,
  iconName: 'Lock',
})``;

export const MenuContainer = styled.div`
  display: flex;

  @media ${deviceConstraints.tablet} {
    width: 100%;
  }

  @media ${deviceConstraints.desktopSmall} { {
    flex-direction: column;
  }
`;

export const MenuItemContent = styled.div`
  height: 100%;
  padding: 12px 14px;

  @media ${deviceConstraints.desktopSmall} {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 12px 16px;
  }
`;

export const MenuItem = styled.div<{
  $isLocked: boolean;
  $isActive: boolean;
}>`
  width: 100%;
  border-bottom: 1px solid var(--base--90);
  ${typography.emu}
  font-weight: 500;
  cursor: pointer;

  &:hover {
    background-color: var(--list-item-hover-bg);
  }

  @media ${deviceConstraints.smartphone} {
    display: flex;
    height: 100%;
  }

  @media ${deviceConstraints.tablet} {
    height: auto;
  }

  @media ${deviceConstraints.desktopSmall} {
    justify-content: space-between;
    align-items: flex-start;
    ${typography.dingo}
  }

  ${props =>
    props.$isActive &&
    `
      background-color: var(--article-sidebar-item-active-bg);
      & > div {
        color: var(--primary-color);
      }
    `}

  ${props =>
    props.$isLocked &&
    `
      position: relative;
      min-width: 220px;

      @media ${deviceConstraints.tablet} {
        min-width: auto;
      }

      ${MenuItemContent} {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: var(--base--75);
      }

      &:nth-of-type(2) {
        min-width: 160px;
      }

      & ${LockedIcon} {
        fill: var(--base--75);

        @media ${deviceConstraints.smartphone} {
          margin-left: 5px;
          margin-bottom: 3px;
        }
      }
    `}
`;
