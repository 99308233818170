import React, { FC } from 'react';

import * as S from './MarketIndicator.styles';

interface MarketIndicatorProps {
  className?: string;
  isMarketOpen: boolean;
  liveText: string;
  closeText: string;
}

export const MarketIndicator: FC<MarketIndicatorProps> = ({
  className,
  isMarketOpen,
  liveText,
  closeText,
}) => {
  return (
    <S.Market
      data-testid="market-status"
      className={className}
      $marketOpen={isMarketOpen}
    >
      <S.DotIcon iconName="Dot" size={16} $marketOpen={isMarketOpen} />
      {isMarketOpen ? liveText : closeText}
    </S.Market>
  );
};
