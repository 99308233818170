import { isBefore } from 'date-fns';
import React, { FC, ReactElement } from 'react';
import { matchRoutes, Navigate, useLocation } from 'react-router-dom';

import {
  accountRoutes,
  appPaths,
  AppRouteSections,
  authPaths,
} from '~/routes/app-paths';
import { useDocuments } from '~/shared/hooks/use-documents/useDocuments';
import { useQuery } from '~/shared/hooks/use-query/useQuery';
import { useRemoteStorage } from '~/shared/hooks/use-remote-storage';
import { useSubscription } from '~/stores/use-subscription/useSubscription';
import { useUser } from '~/stores/use-user/useUser';

import { ReAgreeTos } from '../pdf-viewer/re-agree-tos/ReAgreeTos';

interface AuthenticatedRedirectProps {
  section?: AppRouteSections;
  children: ReactElement;
}

const ONBOARDING_RELEASE_DATE = new Date('2021-06-07T00:00:00.000Z');

export const AuthenticatedRedirect: FC<AuthenticatedRedirectProps> = ({
  section,
  children,
}) => {
  const location = useLocation();
  const { query } = useQuery();
  const user = useUser(s => s.user);
  const hasPermission = useSubscription(s => s.hasPermission);
  const { docsPendingConsent, consentToDocument } = useDocuments(state => ({
    docsPendingConsent: state.docsPendingConsent,
    consentToDocument: state.consentToDocument,
  }));

  const { items, loadingStorage } = useRemoteStorage(({ items, loading }) => ({
    items,
    loadingStorage: loading,
  }));

  const userCompletedOnboarding =
    items?.ONBOARDING_SKIPPED ?? items?.ONBOARDING_INVESTOR_ACTION;
  const isPreOnboarding =
    !!user?.created_at &&
    isBefore(new Date(user.created_at), ONBOARDING_RELEASE_DATE);

  if (section && !hasPermission(section)) {
    return <Navigate to={appPaths.notFound} />;
  }

  const isQuestionnaire = matchRoutes(
    [appPaths.questionnaire].map(path => ({ path })),
    location.pathname
  );

  if (!loadingStorage && !userCompletedOnboarding && !isQuestionnaire) {
    return (
      <Navigate
        to={appPaths.questionnaire}
        state={{ showSkippableView: isPreOnboarding }}
        replace
      />
    );
  }

  if ([authPaths.login, authPaths.signUp].includes(location.pathname)) {
    const promoCode = query.get('promo');
    const redirect = {
      [authPaths.login]: appPaths.myToggle,
      [authPaths.signUp]: appPaths.questionnaire,
    };
    const pathname = promoCode
      ? `${appPaths.myAccount.subscription}/${accountRoutes.selectPlan}`
      : redirect[location.pathname];
    const search = promoCode ? `promo=${promoCode}` : location.search;
    return <Navigate to={`${pathname}?${search}`} replace />;
  }

  if (docsPendingConsent.length && !isQuestionnaire) {
    return (
      <ReAgreeTos
        doc={docsPendingConsent[0]}
        handleDocumentAcceptance={id => consentToDocument(id)}
      />
    );
  }
  return children;
};
