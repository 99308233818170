import { css } from 'styled-components';

export const values = {
  r05: '2px',
  r1: '4px',
  r2: '8px',
  r3: '12px',
  r4: '16px',
};

export const borderRadius = {
  r05: css`
    border-radius: ${values.r05};
  `,
  r1: css`
    border-radius: ${values.r1};
  `,
  r2: css`
    border-radius: ${values.r2};
  `,
  r3: css`
    border-radius: ${values.r3};
  `,
  r4: css`
    border-radius: ${values.r4};
  `,
  rFull: css`
    border-radius: 50%;
  `,
};
