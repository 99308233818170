import React, { FC, ReactNode } from 'react';

import * as S from './PaywallModal.styles';

interface PaywallModalProps {
  onClose?: () => void;
  children?: ReactNode;
  isOpen: boolean;
}
export const PaywallModal: FC<PaywallModalProps> = ({
  onClose,
  isOpen,
  children,
}) => {
  return (
    <S.PaywallModal isOpen={isOpen} onClose={onClose}>
      <S.Content>{children}</S.Content>
    </S.PaywallModal>
  );
};
