import { typography } from '@toggle/design-system';
import styled from 'styled-components';

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  padding: 20px 16px;
  gap: 20px;
  height: 100%;
  overflow-y: auto;
`;

export const Row = styled.div`
  display: flex;
  padding: 8px 0px;
`;

export const Footer = styled.div`
  padding: 16px;
  border-top: solid 1px var(--base--80);
`;

export const Label = styled.p`
  flex: 1;
  ${typography.emu}
  text-align: left;
  display: flex;
  align-items: center;
`;

export const Price = styled.p`
  ${typography.emu}
  text-align: right;
`;

export const ToolTipWrapper = styled.span`
  margin-left: 10px;
  display: flex;
`;

export const ExpirationWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: self-start;
`;

export const InputWrapper = styled.div`
  flex: 1;
  min-width: 65%;

  input {
    width: 0px;
  }
`;

export type PriceType = 'live' | 'delayed' | 'frozen';

const priceTypeColor: Record<PriceType, string> = {
  live: 'var(--success--default)',
  delayed: 'var(--warning--default)',
  frozen: 'var(--base--5)',
};

export const Dot = styled.span<{ type: PriceType }>`
  display: inline-block;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  margin: 0px 0px 2px 8px;
  background-color: ${p => priceTypeColor[p.type]};
`;
