import styled from 'styled-components';

export const Section = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  padding: 20px;
`;

export const Message = styled.p`
  white-space: pre-line;
  font-size: 18px;
  margin: 20px 0;
  text-align: center;
`;
