import { Entity } from '@toggle/toggle';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { EntitySearchType } from '~/api/entities/entity-constants';
import { useTagsSearch } from '~/shared/hooks/use-tags-search';

import { useAssetsLists } from '../../hooks/use-assets-lists/useAssetsLists';
import { UseTurboChartReturn } from '../../hooks/use-turbo-chart/useTurboChart';
import { ChartSearchType, SearchAssetPayload } from '../../types/search.types';
import { ChipsSelector } from './components/chips-selector/ChipsSelector';
import { List } from './components/list/List';
import { SearchInput } from './components/search-input/SearchInput';
import * as S from './SearchModal.styles';

export interface SearchModalProps {
  activeAssets: Entity[];
  changingAsset?: Entity;
  onClose: () => void;
  removeActiveAsset: UseTurboChartReturn['removeActiveAsset'];
  onAssetChange: UseTurboChartReturn['onAssetChange'];
  type: ChartSearchType;
}

export const SearchModal = ({
  activeAssets,
  onClose,
  removeActiveAsset,
  changingAsset,
  onAssetChange,
  type,
}: SearchModalProps) => {
  const { t } = useTranslation(['chart', 'common']);

  const {
    addAssetToRecent,
    assetsList,
    activeListType,
    availableListTypes,
    setActiveListType,
  } = useAssetsLists();

  const {
    tags: searchResults,
    loading,
    query: searchQuery,
    searchTags,
  } = useTagsSearch({
    searchType: EntitySearchType.EntityAndEconomies,
  });

  const handleSearch = (value: string) => {
    searchTags(value);
  };

  const containsEntity = (list: Entity[], entity: Entity) =>
    list.some(item => item.default_snake === entity.default_snake);

  const addActiveAsset = async (entity: Entity) => {
    const exists = containsEntity(activeAssets, entity);

    if (!exists) {
      await addAssetToRecent(entity);
      onAssetChange({
        asset: entity,
        type,
        assetToChange: changingAsset,
      } as SearchAssetPayload);
      if (type !== ChartSearchType.Compare) {
        onClose();
      }
    }
  };

  const filterItemsByActive = (
    itemsToFilter: Entity[],
    activeItems: Entity[]
  ) => itemsToFilter.filter(item => !containsEntity(activeItems, item));

  const filteredItems =
    assetsList && activeListType !== 'recent'
      ? filterItemsByActive(assetsList, activeAssets)
      : assetsList;

  const filteredSearchResults = filterItemsByActive(
    searchResults,
    activeAssets
  );

  const showNoSearchResults =
    !loading && !filteredSearchResults.length && searchQuery?.length > 1;

  const translationKeys = {
    [ChartSearchType.Primary]: 'chart:widget.compareAssets.dataSearchTitle',
    [ChartSearchType.Change]: 'chart:widget.compareAssets.changeDataTitle',
    [ChartSearchType.Compare]: 'chart:widget.compareAssets.compareTitle',
  };

  const secondaryAssets = activeAssets.slice(1);

  return (
    <S.StyledModal
      data-testid="search-modal"
      isOpen
      title={t(translationKeys[type])}
      onClose={onClose}
    >
      <S.Container>
        <SearchInput value={searchQuery} onChange={handleSearch} />

        {!searchQuery && (
          <S.ChipsWrapper data-testid="chips-wrapper">
            <ChipsSelector
              activeListType={activeListType}
              listTypes={availableListTypes}
              onChange={setActiveListType}
            />
          </S.ChipsWrapper>
        )}

        <S.ScrollableList>
          {!!secondaryAssets.length && type === ChartSearchType.Compare && (
            <List
              data-testid="active-assets-list"
              title={t('chart:widget.compareAssets.listTitles', {
                context: 'current',
              })}
              items={secondaryAssets}
              onRemove={e => removeActiveAsset(e.default_snake)}
              isActive
            />
          )}

          {!searchQuery && filteredItems && (
            <List
              data-testid="assets-list"
              /* i18next-extract-mark-context-next-line ["recent", "watchlist", "portfolio"] */
              title={t('chart:widget.compareAssets.listTitles', {
                context: activeListType,
              })}
              items={filteredItems}
              onClick={addActiveAsset}
            />
          )}

          {searchQuery && (
            <div>
              {!!filteredSearchResults.length && (
                <List
                  data-testid="search-results"
                  items={filteredSearchResults}
                  onClick={addActiveAsset}
                  highlighted={searchQuery}
                />
              )}

              {showNoSearchResults && (
                <S.HelpText data-testid="no-search-results">
                  {t('chart:widget.compareAssets.noSearchResults')}
                </S.HelpText>
              )}
            </div>
          )}
        </S.ScrollableList>
      </S.Container>
    </S.StyledModal>
  );
};
