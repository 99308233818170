import { AnnotationLayer, PDFDocumentProxy, renderTextLayer } from 'pdfjs-dist';
import {
  EventBus,
  LinkTarget,
  PDFLinkService,
} from 'pdfjs-dist/web/pdf_viewer';
import React, { FC, useEffect, useRef } from 'react';

import { useWindowSize } from '~/shared/hooks/UseWindowSize';

import * as S from './PDFPage.styles';

interface PDFPageProps {
  doc: PDFDocumentProxy;
  page: number;
  className?: string;
}

export const PDFPage: FC<PDFPageProps> = ({
  doc,
  page: pageNum,
  className,
}) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const textLayerRef = useRef<HTMLDivElement>(null);
  const { windowWidth } = useWindowSize();

  useEffect(() => {
    const handler = async () => {
      const canvas = canvasRef.current as HTMLCanvasElement;
      const width = canvas.getBoundingClientRect().width;
      const page = await doc.getPage(pageNum);
      const originViewport = page.getViewport({ scale: 1 });

      const desiredScale =
        (width / originViewport.width) * window.devicePixelRatio;
      let viewport = page.getViewport({ scale: desiredScale });

      const context = canvas.getContext('2d') as CanvasRenderingContext2D;
      canvas.height = viewport.height;
      canvas.width = viewport.width;
      canvas.style.width = `${viewport.width / window.devicePixelRatio}px`;
      canvas.style.height = `${viewport.height / window.devicePixelRatio}px`;
      await page.render({
        canvasContext: context,
        viewport: viewport,
      }).promise;
      const textContent = await page.getTextContent();
      const textContainer = document.createDocumentFragment();
      const textViewport = page.getViewport({
        scale: desiredScale / window.devicePixelRatio,
      });
      await renderTextLayer({
        textContent,
        container: textContainer,
        viewport: textViewport,
        textDivs: [],
      }).promise;

      textLayerRef.current?.append(textContainer);

      viewport = viewport.clone({
        dontFlip: true,
      });
      const annotations = await page.getAnnotations();
      const linkService = new PDFLinkService({
        eventBus: new EventBus(),
        externalLinkTarget: LinkTarget.BLANK,
      });

      if (textLayerRef.current) {
        AnnotationLayer.render({
          viewport: viewport,
          div: textLayerRef.current as HTMLDivElement,
          annotations,
          page,
          linkService,
          renderForms: false,
          downloadManager: null,
        });
      }
    };

    handler();
  }, [pageNum, doc, windowWidth]);

  return (
    <S.StyledPageWrapper className={className}>
      <S.StyledPageTextLayer ref={textLayerRef} className={className} />
      <S.StyledPageCanvasLayer ref={canvasRef} className={className} />
    </S.StyledPageWrapper>
  );
};
