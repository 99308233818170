import React, { FC } from 'react';

import * as S from './ProgressBar.styles';

interface ProgressBarProps {
  duration: string;
  delay: string;
  isFrozen?: boolean;
  className?: string;
}

export const ProgressBar: FC<ProgressBarProps> = ({
  isFrozen,
  duration,
  delay,
  className,
}) => (
  <S.Bar className={className}>
    <S.Line
      data-testid="progress-line"
      animationDelay={delay}
      animationDuration={duration}
      isFrozen={isFrozen}
    />
  </S.Bar>
);
