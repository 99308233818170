import { borderRadius, typographyV2 } from '@toggle/design-system';
import styled from 'styled-components';

import { FlagIcon } from '~/shared/components/flag-icon/FlagIcon';

export const ExchangeCardRoot = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const Details = styled.div`
  display: flex;
  flex-direction: column;
  text-align: right;
`;

export const Title = styled.span`
  ${typographyV2.bodyXs};
  color: var(--text-default);
  text-transform: uppercase;
`;

export const Subtitle = styled.span`
  ${typographyV2.bodyXs};
  color: var(--text-soft);
`;

export const Country = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledFlagIcon = styled(FlagIcon)`
  ${borderRadius.rFull};
  width: 24px;
  height: 24px;
`;
