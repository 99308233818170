import { Button, TabLines } from '@toggle/design-system';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BuyingPowerResponse } from '~/api/trading/trading-schema';
import { OrderType } from '~/api/trading/trading-service';
import { OptionalN } from '~/declarations/standard';
import {
  LimitOrder,
  LimitOrderValues,
} from '~/global/trading-drawer/limit-order/LimitOrder';
import {
  LinkedInputValues,
  MarketOrder,
} from '~/global/trading-drawer/market-order/MarketOrder';
import {
  Expiration,
  Order,
  OrderSide,
  Transaction,
} from '~/global/trading-drawer/use-transaction/useTransaction';
import { formatAssetPrice } from '~/shared/utils/currency/currency';
import {
  gaClickClosePosition,
  gaClickOrderType,
} from '~/shared/utils/ganalytics/tradingGA';

import { getPriceDecimals } from '../shared/get-price-decimals/getPriceDecimals';
import { useTradingBrokerWs } from '../use-broker-ws/useTradingBrokerWs';
import * as S from './OrderTrading.styles';
import { Position } from './position/Position';

export interface OrderTradingProps {
  reviewOrder: () => void;
  updateOrder: (props: { type: OrderType; order?: Partial<Order> }) => void;
  isValidTransaction: boolean;
  transaction: Transaction;
  buyingPower: OptionalN<BuyingPowerResponse>;
  statementPosition: number;
}

export const OrderTrading: FC<OrderTradingProps> = ({
  reviewOrder,
  updateOrder,
  isValidTransaction,
  transaction,
  buyingPower,
  statementPosition,
}) => {
  const { t } = useTranslation('portfolio');
  const [activeTab, setActiveTab] = useState(transaction.type);
  const { assetLivePrice } = useTradingBrokerWs(state => ({
    assetLivePrice: Object.values(state.assetLivePrices)[0],
  }));

  useEffect(() => {
    setActiveTab(transaction.type);
  }, [transaction.type]);

  const handleMarketOrderPrice = (orderM: LinkedInputValues) => {
    const quantity = parseFloat(orderM.quantity) || 0;
    const price = parseFloat(orderM.price) || 0;
    updateOrder({ type: OrderType.Market, order: { quantity, price } });
  };

  const handleMarketOrderExpiration = (expiration: Expiration) => {
    updateOrder({ type: OrderType.Market, order: { expiration } });
  };

  const handleLimitOrder = (order: LimitOrderValues) => {
    updateOrder({
      type: OrderType.Limit,
      order,
    });
  };

  const resetOrder = (type: OrderType) => {
    const quantity = 0;
    const price = 0;
    const expiration = Expiration.Day;

    updateOrder({
      type,
      order: { quantity, price, expiration },
    });
  };

  const onTabChange = (tabIndex: number, orderType: OrderType) => {
    if (orderType !== transaction.type) {
      resetOrder(orderType);
    }
    setActiveTab(orderType);
    gaClickOrderType(orderType);
  };

  const onClosePositionClick = (quantity: number) => {
    const price =
      (transaction.type === OrderType.Market && assetLivePrice?.last) ||
      transaction.order.price;

    updateOrder({
      type: transaction.type,
      order: { quantity, price },
    });
    gaClickClosePosition();
  };

  const {
    order: { side, ticker },
  } = transaction;
  const title = side === OrderSide.BUY ? 'portfolio:buy' : 'portfolio:sell';

  const priceDecimals = assetLivePrice?.last
    ? getPriceDecimals(assetLivePrice.last, transaction.priceDisplayRule)
    : 0;
  const tradingProviderLabel = t('portfolio:tradingProvider', {
    context: transaction.brokerId,
  });

  const handleReviewClick = () => {
    reviewOrder();
  };
  return (
    <S.ContentContainer>
      <S.HeaderContainer>
        <S.Title data-testid="order-title">
          {t(title, { ticker })}
          <S.ExchangeText>{ticker}</S.ExchangeText>
        </S.Title>
        <Position
          statementPosition={statementPosition}
          onClick={onClosePositionClick}
          side={side}
        />
      </S.HeaderContainer>
      <S.BodyContainer>
        <S.TabLinesContainer>
          <TabLines<OrderType>
            activeTab={activeTab}
            onChange={onTabChange}
            tabs={[
              {
                tabKey: OrderType.Market,
                tabLabel: t('portfolio:marketOrder'),
                renderPanel: (
                  <MarketOrder
                    marketPrice={assetLivePrice?.last}
                    isDelayed={!!assetLivePrice?.delayed}
                    isFrozen={!!assetLivePrice?.frozen}
                    handleMarketOrderPrice={handleMarketOrderPrice}
                    handleMarketOrderExpiration={handleMarketOrderExpiration}
                    order={transaction.order}
                    priceDecimals={priceDecimals}
                    tradingProviderLabel={tradingProviderLabel}
                  />
                ),
              },
              {
                tabKey: OrderType.Limit,
                tabLabel: t('portfolio:limitOrder'),
                renderPanel: (
                  <LimitOrder
                    order={transaction.order}
                    onChange={handleLimitOrder}
                    priceDecimals={priceDecimals}
                    tradingProviderLabel={tradingProviderLabel}
                  />
                ),
              },
            ]}
          />
        </S.TabLinesContainer>
      </S.BodyContainer>
      <S.FooterContainer>
        {buyingPower && (
          <S.TextBottom>
            {t('portfolio:buyingPower', {
              amount: formatAssetPrice(
                buyingPower.buying_power,
                buyingPower.currency
              ),
            })}
          </S.TextBottom>
        )}

        <S.Footer>
          <Button
            label={t('portfolio:review')}
            fullWidth
            disabled={!isValidTransaction}
            onClick={handleReviewClick}
          />
        </S.Footer>
      </S.FooterContainer>
    </S.ContentContainer>
  );
};
