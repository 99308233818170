import { Loader } from '@toggle/design-system';
import times from 'lodash/times';
import {
  getDocument,
  GlobalWorkerOptions,
  version as pdfjsVersion,
} from 'pdfjs-dist';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DocumentItem } from '~/api/legal-documents/legal-documents-schema';
import { config } from '~/config';
import { useAPI } from '~/shared/hooks/use-api/useAPI';

import * as S from './PDFViewer.styles';

GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjsVersion}/build/pdf.worker.min.js`;

const {
  api: { root: apiRoot },
} = config;

interface PDFViewerProps {
  doc: DocumentItem;
}

export const PDFViewer: FC<PDFViewerProps> = ({ doc }) => {
  const { t } = useTranslation('common');
  const { data, loading, error } = useAPI(
    () => getDocument(new URL(doc.link, apiRoot).href).promise,
    {
      initialState: { data: undefined, loading: true },
    }
  );

  const [numPages, setNumPages] = useState(0);

  const docRef = useRef<HTMLElement>(null);

  useEffect(() => {
    if (data) {
      setNumPages(data.numPages ?? 0);
    }
  }, [data]);

  const shouldShowPDF = !loading && !error && !!data && numPages > 0;

  return (
    <S.Main ref={docRef}>
      {loading && <Loader />}
      {error && <p>{t('common:documents.documentLoadError')}</p>}
      {shouldShowPDF &&
        times(numPages, i => (
          <S.StyledPDFPage key={i} page={i + 1} doc={data} />
        ))}
    </S.Main>
  );
};
