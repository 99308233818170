import { TFunction } from 'i18next';
import React, { FC, memo, ReactNode, RefObject } from 'react';

import { ArticleV3 } from '~/declarations/toggle-api';

import { BackTest, QualityChecklist, WhyItMightMove } from '../../partials';

interface CollapsePanelBodyProps {
  article: ArticleV3;
  t: TFunction;
  mightMoveSectionRef: RefObject<HTMLHeadingElement>;
  qualityChecklistSectionRef: RefObject<HTMLHeadingElement>;
  backTestSectionRef: RefObject<HTMLHeadingElement>;
  children?: ReactNode;
}

export const CollapsePanelBody: FC<CollapsePanelBodyProps> = memo(
  ({ article, t, children, ...sectionsRefs }) => (
    <>
      {article.why_it_might_move.length > 0 && (
        <WhyItMightMove
          t={t}
          imgName={article.why_it_might_move[0].link}
          ref={sectionsRefs.mightMoveSectionRef}
          data={article}
        />
      )}
      <QualityChecklist
        t={t}
        stars={article.stars}
        checklistData={article.high_conviction_report}
        ref={sectionsRefs.qualityChecklistSectionRef}
      />
      <BackTest t={t} article={article} ref={sectionsRefs.backTestSectionRef} />
    </>
  )
);
