import { Button } from '@toggle/design-system';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import ErrorRobot from './cache-error.svg';
import * as S from './CacheError.styles';

interface CacheErrorProps {
  onClick?: () => void;
  className?: string;
}

export const CacheError: FC<CacheErrorProps> = ({
  onClick = () => window.location.reload(),
  className,
}) => {
  const { t } = useTranslation('error');

  return (
    <S.Section data-testid="cache-error-view" className={className}>
      <ErrorRobot alt={t('error:notFound.pageText')} viewBox="0 0 74 70" />
      <S.Message>{t('error:cacheError.message')}</S.Message>
      <Button
        data-testid="cache-error-view-btn"
        label={t('error:cacheError.reload')}
        onClick={onClick}
        variant="secondary"
      />
    </S.Section>
  );
};
