import { Button, Icon } from '@toggle/design-system';
import { useOutsideClick } from '@toggle/helpers';
import React, { FC, ReactNode, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { BasicUpgradeButton } from '~/shared/components/header-notification-buttons/basic-upgrade-button/BasicUpgradeButton';
import { gaMyAccountClickContactUs } from '~/shared/utils/ganalytics/myAccountGA';

import {
  HeaderMenuItems,
  MenuLinkTypes,
} from '../../config/get-menu-data/getMenuData';
import * as S from './MobileMenu.styles';

export interface MobileMenuProps {
  items: HeaderMenuItems[];
  closeMobileMenu: () => void;
  searchButton: ReactNode;
  isBasic: boolean;
}

export const MobileMenu: FC<MobileMenuProps> = ({
  items,
  closeMobileMenu,
  searchButton,
  isBasic,
}) => {
  const { t } = useTranslation('header');

  const mobileMenuRef = useRef(null);
  useOutsideClick({ ref: mobileMenuRef, callback: () => closeMobileMenu() });

  return (
    <S.Menu data-testid="mobile-menu">
      <S.MobileMenuContainer ref={mobileMenuRef}>
        <S.MobileMenuHeader>
          <Button
            variant="tertiary"
            iconName={'Close'}
            onClick={closeMobileMenu}
          />
          {isBasic && <BasicUpgradeButton />}
        </S.MobileMenuHeader>
        <S.MobileMenuSearchWrapper>{searchButton}</S.MobileMenuSearchWrapper>

        <S.MobileMenu ref={mobileMenuRef}>
          {items.map((itemData, index) => (
            <S.MobileMenuItem key={index}>
              {itemData.heading && (
                <S.ListHeading data-testid="menu-heading">
                  {itemData.heading}
                </S.ListHeading>
              )}
              {itemData.linkType === MenuLinkTypes.EXTERNAL ? (
                <S.ExternalItemContainer>
                  <a
                    data-testid="external-link"
                    href={itemData.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => {
                      if (
                        itemData.label === t('header:secondaryMenu.contactUs')
                      ) {
                        gaMyAccountClickContactUs('mobile_menu');
                      }
                    }}
                  >
                    {itemData.label}
                  </a>
                  {itemData.iconName && (
                    <Icon size={20} iconName={itemData.iconName} />
                  )}
                </S.ExternalItemContainer>
              ) : (
                <S.MobileMenuItemContainer>
                  {itemData.link ? (
                    <S.MenuItemInternal
                      to={itemData.link}
                      data-testid="internal-link"
                    >
                      {itemData.label}
                    </S.MenuItemInternal>
                  ) : (
                    <S.MenuItemButton
                      type="button"
                      onClick={() => {
                        itemData.onClick?.();
                      }}
                    >
                      {itemData.label}
                    </S.MenuItemButton>
                  )}
                </S.MobileMenuItemContainer>
              )}
            </S.MobileMenuItem>
          ))}
        </S.MobileMenu>
      </S.MobileMenuContainer>
    </S.Menu>
  );
};
