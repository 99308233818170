import { Button, Spinner } from '@toggle/design-system';
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { config } from '~/config';
import { CloseModal } from '~/global/trading-drawer/close-modal/CloseModal';
import ErrorRobot from '~/views/error/cache-error/cache-error.svg';

import { SubmittedOrderState } from '../../trading/utils/trading-utils';
import * as S from './OrderConfirmationLoading.styles';

export interface OrderConfirmationLoadingProps {
  reviewOrder: () => void;
  hideTradingView: () => void;
  editOrder: () => void;
  orderState: SubmittedOrderState;
  errorMessage: string;
  tradingProviderLabel: string;
}

export const RetriesLimit = 5;
export const RetriesWithShortMessage = 2;

export const OrderConfirmationLoading = ({
  reviewOrder,
  hideTradingView,
  orderState,
  editOrder,
  errorMessage,
  tradingProviderLabel,
}: OrderConfirmationLoadingProps) => {
  const { t } = useTranslation(['portfolio', 'error']);
  const [retriesMade, setRetriesMade] = useState(0);
  const [isAlertOpened, setIsAlertOpened] = useState(false);

  const showEnhancedError = retriesMade > RetriesWithShortMessage;
  const retriesLimitExceeded = retriesMade === RetriesLimit;

  const handleRetry = () => {
    setRetriesMade(s => s + 1);
    reviewOrder();
  };

  const alertSecondaryAction = () => {
    setIsAlertOpened(false);
    setRetriesMade(s => s + 1);
    reviewOrder();
  };

  const alertPrimaryAction = () => {
    setIsAlertOpened(false);
    hideTradingView();
  };

  return (
    <S.Wrapper data-testid="confirmation-loader">
      {orderState === SubmittedOrderState.ReviewLoading ? (
        <>
          <Spinner />
          <S.Message>
            {t('portfolio:reviewLoading', {
              tradingProvider: tradingProviderLabel,
            })}
          </S.Message>
        </>
      ) : (
        <>
          <ErrorRobot alt={t('error:notFound.pageText')} viewBox="0 0 74 70" />
          <S.Title data-testid="error-message">
            {errorMessage || t('portfolio:reviewError')}
          </S.Title>
          {showEnhancedError && (
            <S.Message>
              <Trans
                components={[
                  <a
                    key=""
                    href={config.urls.supportNewTicket}
                    target="_blank"
                    rel="noopener noreferrer"
                  />,
                ]}
                tOptions={{ tradingProvider: tradingProviderLabel }}
                i18nKey="portfolio:reviewErrorEnhanced"
                t={t}
              />
            </S.Message>
          )}
          <S.Buttons>
            {errorMessage && (
              <Button
                data-testid="error-edit-order"
                label={t('portfolio:editOrder')}
                iconName="Edit"
                onClick={editOrder}
              />
            )}
            {!errorMessage && !retriesLimitExceeded && (
              <Button
                data-testid="confirmation-try-again"
                label={t('portfolio:confirmationScreens.tryAgain')}
                iconName="Sync"
                onClick={handleRetry}
                variant={showEnhancedError ? 'secondary' : 'primary'}
              />
            )}
            {showEnhancedError && (
              <Button
                data-testid="confirmation-close-window"
                label={t('portfolio:confirmationScreens.closeWindow')}
                onClick={() => setIsAlertOpened(true)}
              />
            )}
          </S.Buttons>
        </>
      )}
      <CloseModal
        isOpen={isAlertOpened}
        secondaryAction={alertSecondaryAction}
        primaryAction={alertPrimaryAction}
        onClose={() => setIsAlertOpened(false)}
        content={t('portfolio:cancellingOrder')}
        continueLabel={
          retriesLimitExceeded ? undefined : t('portfolio:trySubmitAgain')
        }
        confirmLabel={t('portfolio:confirmationScreens.confirm')}
      />
    </S.Wrapper>
  );
};
