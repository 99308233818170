import { Button, DropdownListItem } from '@toggle/design-system';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import * as S from './FeedbackButton.styles';

export interface FeedbackButtonProps {
  id: string;
  feedback?: string;
  handleFeedback: (id: string, reason: string) => void;
}

export const FeedbackButton = ({
  id,
  feedback,
  handleFeedback,
}: FeedbackButtonProps) => {
  const { t } = useTranslation('common');
  const [isOpen, setIsOpen] = useState(false);

  const reasons = [
    {
      label: t('chat:didNotAnswerMyQuestion'),
      key: 'didNotAnswerMyQuestion',
    },
    {
      label: t('chat:wrongAsset'),
      key: 'wrongAsset',
    },
    {
      label: t('chat:staleData'),
      key: 'staleData',
    },
    {
      label: t('chat:somethingElse'),
      key: 'somethingElse',
    },
  ];

  const handleSelectItem = (reason: string) => {
    handleFeedback(id, reason);
  };

  if (feedback) {
    return (
      <S.ButtonContainer data-testid="feedback-button">
        <S.NotRelevantButton
          label={feedback}
          variant="tertiary"
          iconName="ThumbsDown"
          iconPosition="left"
        />
      </S.ButtonContainer>
    );
  }
  return (
    <S.FeedbackDropdown
      dropdownItems={reasons}
      shouldShowItems={isOpen}
      onItemsShowChange={s => setIsOpen(s)}
      selectItem={item => handleSelectItem(item.label)}
      renderDropdownItem={(item, isActive, isFocused) => (
        <DropdownListItem
          label={item.label}
          isActive={isActive}
          isFocused={isFocused}
        />
      )}
      strategy="fixed"
      placement="bottom-end"
    >
      <S.ButtonContainer data-testid="feedback-dropdown">
        <Button
          label={t('chat:notRelevant')}
          variant="tertiary"
          iconName="ThumbsDown"
          iconPosition="left"
          onClick={() => setIsOpen(i => !i)}
          data-testid="feedback-dropdown-button"
        />
      </S.ButtonContainer>
    </S.FeedbackDropdown>
  );
};
