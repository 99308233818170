import React from 'react';

import { SplashScreen } from '~/shared/components/splash-screen/SplashScreen';
import { SPLASH_SCREEN_LABELS_KEYS } from '~/shared/components/splash-screen/utils/splashScreenConstants';

import * as S from './LoadingBars.styles';

const SCENARIO_SEARCH_DATA = [
  {
    label: SPLASH_SCREEN_LABELS_KEYS.understandingQuery,
    durationMs: 4000,
  },
  { label: SPLASH_SCREEN_LABELS_KEYS.analyzing, durationMs: 7000 },
  {
    label: SPLASH_SCREEN_LABELS_KEYS.summarizing,
    durationMs: 10000,
  },
];

export const LoadingBars = () => (
  <S.LoadingBarsRoot data-testid="loading-bars">
    <S.Container>
      <SplashScreen
        dataIsLoading
        showImage={false}
        showCompleteLabel={false}
        data={SCENARIO_SEARCH_DATA}
      />
    </S.Container>
  </S.LoadingBarsRoot>
);
