import { Button as DefaultButton } from '@toggle/design-system';
import styled, { css } from 'styled-components';

export const OrientationRegisterBanner = styled.div`
  padding: 16px;
`;

export const TextContent = styled.p`
  font-size: 16px;
`;

const ButtonStyles = css`
  margin-top: 32px;
  width: 100%;
`;

export const Link = styled.a`
  ${ButtonStyles}
`;

export const Button = styled(DefaultButton)`
  ${ButtonStyles}
`;
