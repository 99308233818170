import { create } from '~/stores/create-store/createStore';

export interface BookmarkState {
  bookmarks: Record<string, boolean>;
  addBookmark: (id: string) => void;
  removeBookmark: (id: string) => void;
}

export const useBookmarkStore = create<BookmarkState>((set, get) => ({
  bookmarks: {},
  addBookmark: (id: string) => {
    set({ bookmarks: { ...get().bookmarks, [id]: true } });
  },
  removeBookmark: (id: string) => {
    set({ bookmarks: { ...get().bookmarks, [id]: false } });
  },
}));
