import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

import { ArticleV3 } from '~/declarations/toggle-api.d';
import { ArticleComponentType } from '~/declarations/toggle-api-enums';
import { useSubscription } from '~/stores/use-subscription/useSubscription';
import { FormattedRelativeDate } from '~/views/news/components/formatted-relative-date/FormattedRelativeDate';

import { LinkMarkdown } from '../link-markdown/LinkMarkdown';
import * as S from './Overview.styles';

export const Overview = forwardRef<
  HTMLHeadingElement,
  { article: ArticleV3; articleID: string }
>(({ article, articleID }, ref) => {
  const { t } = useTranslation('common');

  const isPro = useSubscription(state => state.isPro());
  const compassArticle = article.compass_id ? articleID : undefined;
  return (
    <S.OverviewSection
      data-testid="overview-section"
      aria-labelledby="article-overview"
    >
      <S.Title ref={ref}>{article.title}</S.Title>
      <S.Details>
        <S.Author>
          {t('common:by')} {article.author}
        </S.Author>
        <FormattedRelativeDate date={article.created_on} />
      </S.Details>
      {article.overview.map(({ component_type, component_data, link }, i) => {
        switch (component_type) {
          case ArticleComponentType.TEXT:
            return (
              <S.MdContainer
                key={i}
                markdownProps={{
                  children: component_data,
                  components: {
                    a: ({ href, children, title }) => (
                      <LinkMarkdown href={href} title={title}>
                        {children}
                      </LinkMarkdown>
                    ),
                  },
                }}
              />
            );
          case ArticleComponentType.PNG:
            return (
              <S.ImageSection
                key={i}
                link={link}
                imageName={`${article.entity.tag_name}-projection`}
                tag={isPro ? article.entity.tag : ''}
                articleID={compassArticle}
              >
                <S.Image
                  src={link}
                  alt="chart"
                  onError={event =>
                    ((event.target as HTMLImageElement).src =
                      '/app/assets/explore/locked-article.png')
                  }
                />
              </S.ImageSection>
            );
          default:
            return undefined;
        }
      })}
    </S.OverviewSection>
  );
});
