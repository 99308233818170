import { deviceConstraints, Icon, typography } from '@toggle/design-system';
import styled from 'styled-components';

import { FormattedRelativeDate } from '~/views/news/components/formatted-relative-date/FormattedRelativeDate';

export const Article = styled.article`
  display: grid;
  position: relative;
  grid-template-areas:
    'head head'
    'body image'
    'foot foot';
  grid-template-columns: 68% 32%;
  grid-template-rows: max-content auto 48px;
  justify-content: space-between;
  align-items: stretch;
  margin-bottom: 16px;
  border-radius: 4px;
  background-color: var(--base--95);
  color: var(--base--0);
  overflow: hidden;
  overflow-wrap: break-word;
  word-wrap: break-word;

  @media ${deviceConstraints.tablet} {
    grid-template-areas:
      'head image'
      'body image'
      'foot image';
  }

  &:hover {
    box-shadow: 0 0 8px 0 var(--base--100-rgb);
    cursor: pointer;
  }

  > * {
    margin-top: unset;
  }
`;

export const Body = styled.div`
  grid-area: body;
  padding: 0 16px 16px;

  @media ${deviceConstraints.desktopLarge} {
    padding-top: 24px;
  }

  > h2 {
    ${typography.dingo};
    height: 96px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    font-weight: 400;

    @media ${deviceConstraints.tablet} {
      height: 72px;
      -webkit-line-clamp: 3;
    }

    @media ${deviceConstraints.desktopSmall} {
      height: 96px;
      -webkit-line-clamp: 3;
      ${typography.coyote}
      font-weight: 300;
    }

    @media ${deviceConstraints.desktop} {
      ${typography.chimp}
      font-weight: 400;
    }
  }
`;

export const LockedIcon = styled(Icon).attrs({
  iconName: 'Lock',
  size: 24,
})`
  fill: var(--copilot--default);
  margin-bottom: -4px;
  margin-left: 4px;
`;

export const CreatedOn = styled(FormattedRelativeDate)`
  display: inline-block;
  margin-top: 6px;
  color: var(--base--60);
  ${typography.koala};
`;

export const LockedContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  align-items: center;
  justify-content: center;
  ${typography.coyote};
  color: var(--primary-color);

  & p {
    display: none;
  }

  &:hover {
    background-color: rgb(31, 35, 40, 0.9);
    & p {
      display: block;
    }
  }
`;

export const ImageContainer = styled.div`
  grid-area: image;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center;
  background-color: var(--base--95);

  img,
  svg {
    display: block;
    max-width: 312px;
    max-height: 100%;
    width: 100%;
    background-color: var(--base--95);
  }
`;
