import {
  ChartAPIReturn,
  INSIGHTS_IN_DOMAIN_CHANGE_CUSTOM_EVENT,
  InsightsInDomain,
  InsightsInDomainDetail,
  InsightsInDomainEvent,
} from '@toggle/chart';
import React, {
  FC,
  MutableRefObject,
  RefObject,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { ArticleSummary, NewsItem } from '~/declarations/toggle-api-news';

import { EventsConfig } from '../events-modal/EventsModal';
import { ChartInsightIcon } from './chart-insight-icon/ChartInsightIcon';
import * as S from './Insights.styles';
import { ActiveInsightsGroup } from './insights.types';
import {
  mapArticleInsight,
  mapInsightsToDetails,
  mapNewsInsight,
} from './utils/inisights-utils/insights-utils';
import {
  getNextIndexes,
  getPrevIndexes,
} from './utils/slides-utils/slides-utils';

export interface InsightsProps {
  chartApiRef: MutableRefObject<ChartAPIReturn | null>;
  canvasRef: RefObject<HTMLCanvasElement>;
  articleInsights: ArticleSummary[];
  newsInsights: NewsItem[];
  eventsConfig: EventsConfig;
}

export const Insights: FC<InsightsProps> = ({
  chartApiRef,
  canvasRef,
  articleInsights,
  newsInsights,
  eventsConfig,
}) => {
  const { t } = useTranslation('chart');
  const [activeInsightsGroup, setActiveInsightsGroup] =
    useState<ActiveInsightsGroup | null>(null);
  const [isPrevBtnEnabled, setIsPrevBtnEnabled] = useState(false);
  const [isNextBtnEnabled, setIsNextBtnEnabled] = useState(false);
  const [insightsInDomain, setInsightsInDomain] = useState<InsightsInDomain[]>(
    []
  );

  const [zoomIntoViewText, setZoomIntoViewText] =
    useState<InsightsInDomainDetail['helperText']>(null);

  useEffect(() => {
    const canvasElement = canvasRef.current;
    if (!canvasElement) {
      return undefined;
    }

    const insightsInDomainListener = (event: Event) => {
      const detail = (event as InsightsInDomainEvent).detail;
      if (detail.insightsInDomain) {
        setInsightsInDomain(detail.insightsInDomain);
      }
      setActiveInsightsGroup(null);
      setZoomIntoViewText(detail.helperText);
    };

    canvasElement.addEventListener(
      INSIGHTS_IN_DOMAIN_CHANGE_CUSTOM_EVENT,
      insightsInDomainListener
    );

    return () => {
      canvasElement.removeEventListener(
        INSIGHTS_IN_DOMAIN_CHANGE_CUSTOM_EVENT,
        insightsInDomainListener
      );
    };
  }, [eventsConfig]);

  useEffect(() => {
    if (!chartApiRef.current) {
      return;
    }
    const mappedArticleInsights = eventsConfig.TMI
      ? articleInsights.map(mapArticleInsight)
      : [];

    const mappedNewsInsights = eventsConfig.TNI
      ? newsInsights.map(mapNewsInsight)
      : [];

    chartApiRef.current.displayInsights([
      ...mappedArticleInsights,
      ...mappedNewsInsights,
    ]);
  }, [eventsConfig, articleInsights, newsInsights]);

  const updateActiveInsightsGroup = (
    insightsInDomainIndex: number,
    groupedInsightsIndex: number
  ) => {
    const groupedByDate = insightsInDomain[insightsInDomainIndex];
    const groupedByType = groupedByDate.groupedInsights[groupedInsightsIndex];

    const insightsDetails = mapInsightsToDetails(
      groupedByType.insights,
      articleInsights,
      newsInsights
    );

    setActiveInsightsGroup({
      insightsDetails,
      groupedInsightsIndex,
      insightsInDomainIndex,
    });

    setIsNextBtnEnabled(
      insightsInDomainIndex !== insightsInDomain.length - 1 ||
        groupedInsightsIndex !== groupedByDate.groupedInsights.length - 1
    );
    setIsPrevBtnEnabled(
      insightsInDomainIndex !== 0 || groupedInsightsIndex !== 0
    );
  };

  const onArrowClick = (
    direction: 'next' | 'prev',
    insightsByDateIndex: number,
    insightsByTypeIndex: number
  ) => {
    let indexes;

    if (direction === 'next') {
      const insightsByDateLength = insightsInDomain.length;
      const insightsByTypeLength =
        insightsInDomain[insightsByDateIndex].groupedInsights.length;

      indexes = getNextIndexes(
        insightsByDateIndex,
        insightsByTypeIndex,
        insightsByDateLength,
        insightsByTypeLength
      );
    } else {
      const prevInsightsByDateIndex = insightsByDateIndex - 1;
      const prevNestedArrayLength =
        insightsInDomain[prevInsightsByDateIndex].groupedInsights.length;

      indexes = getPrevIndexes(
        insightsByDateIndex,
        insightsByTypeIndex,
        prevNestedArrayLength
      );
    }

    updateActiveInsightsGroup(indexes.mainIndex, indexes.nestedIndex);
  };

  const onClose = () => {
    setActiveInsightsGroup(null);
  };

  return (
    <>
      {insightsInDomain.map((insightsInDomain, insightsInDomainIndex) =>
        insightsInDomain.groupedInsights.map(
          (insightsGroupByType, groupedInsightsIndex) => (
            <ChartInsightIcon
              key={insightsGroupByType.type}
              activeInsightsGroup={activeInsightsGroup}
              groupedInsights={insightsGroupByType}
              groupedInsightsIndex={groupedInsightsIndex}
              insightsInDomainIndex={insightsInDomainIndex}
              hasStackedInsights={insightsInDomain.groupedInsights.length > 1}
              insightIconRight={insightsInDomain.right}
              insightIconTop={insightsInDomain.top}
              isNextBtnEnabled={isNextBtnEnabled}
              isPrevBtnEnabled={isPrevBtnEnabled}
              onArrowClick={onArrowClick}
              onClose={onClose}
              onClick={updateActiveInsightsGroup}
            />
          )
        )
      )}
      {zoomIntoViewText && (
        <S.ZoomIntoViewText
          data-testid="zoom-in-required"
          style={{
            top: zoomIntoViewText.y,
            right: zoomIntoViewText.right,
          }}
        >
          {t('chart:zoomInToView')}
        </S.ZoomIntoViewText>
      )}
    </>
  );
};
