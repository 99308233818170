import { Icon, typography } from '@toggle/design-system';
import styled from 'styled-components';

export const Header = styled.header`
  grid-area: head;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;

  ${typography.emu};

  & svg {
    flex-shrink: 0;
  }
`;

export const LeftContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 4px;
`;

export const StarsContainer = styled.div`
  display: flex;
  color: var(--warning--default);

  > span {
    margin-left: 2px;
  }
`;

export const Dot = styled.span`
  display: inline-block;
  height: 4px;
  width: 4px;
  background-color: var(--base--75);
  margin: 0px 8px;
  border-radius: 50%;
  align-self: center;
`;

export const FireIcon = styled(Icon).attrs({
  iconName: 'Fire',
  size: 18,
})`
  fill: var(--red--1);
  margin-right: 8px;
`;

export const Tag = styled.p`
  padding-right: 8px;
  text-transform: capitalize;
`;

export const NewBadge = styled.span`
  display: inline-flex;
  padding: 0px 4px;
  align-items: center;
  justify-content: center;
  color: var(--base--gray);
  border-radius: 4px;
  ${typography.koala};
  font-weight: 500;
  background-color: var(--primary-color);
`;

const sectors: Record<string, string> = {
  tech: 'var(--indigo--2)',
  communications: 'var(--indigo--1)',
  consumer_discretionary: 'var(--deep-purple--2)',
  consumer_staples: 'var(--deep-purple--1)',
  energy: 'var(--deep-orange--3)',
  financials: 'var(--red--1)',
  health_care: 'var(--cyan--1)',
  industrials: 'var(--teal--1)',
  materials: 'var(--warning--default)',
  real_estate: 'var(--success--default)',
  utilities: 'var(--pink--3)',
  equities: 'var(--light-green--4)',
  fixed_income: 'var(--lime--2)',
  fx: 'var(--orange--1)',
  commodities: 'var(--lime--4)',
  crypto: 'var(--pink--1)',
};

export const Sector = styled.div<{ sector: string }>`
  text-align: right;
  color: ${props => sectors[props.sector]};
`;
