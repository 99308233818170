import { z } from 'zod';

const valuesSchema = z.object({
  last_value: z.number(),
  last_value_formatted: z.string(),
  last_update: z.string(),
});
const latestValuesSchema = z.object({
  ['mom(horiz=1d)']: valuesSchema.optional(),
  ['mom(horiz=1w)']: valuesSchema.optional(),
  ['mom(horiz=1m)']: valuesSchema.optional(),
  ['mom(horiz=3m)']: valuesSchema.optional(),
  ['mom(horiz=6m)']: valuesSchema.optional(),
  ['mom(horiz=12m)']: valuesSchema.optional(),
  ['pe_ibes_forward_12m']: valuesSchema.optional(),
  ['pe_ibes_trailing_12m']: valuesSchema.optional(),
  ['price_rsi(horiz=14d)']: valuesSchema.optional(),
});

export const keyValueLegacySchema = z.object({
  explanation: z.string(),
  expression: z.string(),
  group_code: z.string(),
  group_icon_url: z.string(),
  group_name: z.string(),
  label: z.string(),
  value_string: z.string(),
});
export const keyValueSchema = z.object({
  key: z.string(),
  display: z.string(),
  value_fmt: z.string().optional(),
  unit: z.string().optional(),
});

export type KeyValueLegacy = z.infer<typeof keyValueLegacySchema>;
export type KeyValue = z.infer<typeof keyValueSchema>;

const relevanceSchema = z.object({
  id: z.number(),
  expression: z.string(),
  last_value: z.number(),
  name: z.string(),
  image_url: z.string(),
  explanation: z.string(),
  connected_article_title: z.string(),
  connected_article_url: z.string(),
  scenario_condition: z.record(z.string()),
  group_code: z.string(),
  group_name: z.string(),
  group_icon_url: z.string(),
  snake_last_date: z.string(),
});

export const entityReport = {
  getEntityRelevance: {
    path: (tag: string) => `entity-report/v1/entity/${tag}/relevance` as const,
    schema: z.array(relevanceSchema),
  },
  getEntityKeyValuesLegacy: {
    path: (tag: string) => `entity-report/v1/entity/${tag}/key-values` as const,
    schema: z.array(keyValueLegacySchema),
  },
  getEntityKeyValues: {
    path: (tag: string) => `entity-report/v2/entity/${tag}/key-values` as const,
    schema: z.array(keyValueSchema),
  },
  getEntityLatestValues: {
    path: (tag: string) =>
      `entity-report/v1/entity/${tag}/latest-values` as const,
    schema: latestValuesSchema,
  },
} as const;
