import { typography } from '@toggle/design-system';
import styled, { keyframes } from 'styled-components';

export const LoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
`;

export const LoaderText = styled.div`
  ${typography.emu};
  margin-bottom: 12px;
`;

export const LoaderBar = styled.div`
  position: relative;
  height: 2px;
  width: 250px;
  background-color: var(--base--15);
  border-radius: 4px;
  overflow: hidden;
`;

const loader = keyframes`
    from {
      transform:translateX(-50%);
    }
    to {
        transform:translateX(150%);
    }
`;

export const LoaderLine = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 120px;
  background-color: var(--primary-color);
  animation: ${loader} 1.5s infinite linear;
  border-radius: 4px;
`;
