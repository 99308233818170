import { TFunction } from 'i18next';
import groupBy from 'lodash/groupBy';
import React, { FC, MouseEvent } from 'react';

import { ArticleDirection } from '~/declarations/toggle-api-enums';
import { ArticleInsightCard } from '~/views/analyze/hooks/use-article-insight-cards/useInsightCards';

import * as S from './ChatArticleInsights.styles';

export interface ChatArticleInsightsProps {
  articleInsights: ArticleInsightCard[];
  loadedInsightIds: string[] | undefined;
  ticker: string;
  t: TFunction;
  onClick: (articleInsight: ArticleInsightCard) => void;
}

export const ChatArticleInsights: FC<ChatArticleInsightsProps> = ({
  loadedInsightIds,
  articleInsights,
  ticker,
  t,
  onClick,
}) => {
  const groupedByDirection = groupBy(articleInsights, i => i.direction);

  const onButtonClick =
    (insight: ArticleInsightCard) => (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      onClick(insight);
    };

  return (
    <S.Container data-testid="chat-article-insights">
      <S.Title>
        {t('chat:articleInsights.title', { ticker: ticker.toUpperCase() })}
      </S.Title>
      {Object.keys(groupedByDirection).map(direction => (
        <S.Row
          data-testid={`direction-${direction}`}
          $isBullish={Number(direction) === ArticleDirection.Bullish}
          key={direction}
        >
          <S.DirectionLabel>
            {t('chat:articleInsights.direction', {
              context: direction,
            })}
          </S.DirectionLabel>
          <S.InsightsContainer>
            {groupedByDirection[direction].map(i => (
              <S.InsightButton
                key={i.article_id}
                variant="tertiary"
                size="small"
                className={
                  loadedInsightIds?.includes(i.article_id as string)
                    ? 'visited'
                    : undefined
                }
                data-testid="chat-insight-button"
                label={
                  <>
                    <S.Logo src={i.card_icon} alt="insight-logo" />
                    {i.card_text}
                  </>
                }
                onClick={onButtonClick(i)}
              />
            ))}
          </S.InsightsContainer>
        </S.Row>
      ))}
    </S.Container>
  );
};
