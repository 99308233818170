import styled, { css } from 'styled-components';

import { typographyV2 } from '~/design-tokens/typography/Typography.styles';

const SharedAxisStyles = css`
  .tick > line {
    fill: var(--border-soft);
    stroke: var(--border-soft);
    stroke-width: 2;
  }
  .domain {
    fill: var(--border-soft);
    stroke: var(--border-soft);
    stroke-width: 2;
  }
`;

export const ChartAxisG = styled.g<{ $showLine?: boolean }>`
  ${SharedAxisStyles}
  ${({ $showLine = false }) =>
    $showLine &&
    css`
      .domain {
        display: none;
      }
      .tick > line {
        display: none;
      }
    `}
  .tick text {
    font-family: var(--font-nort);
    fill: var(--text-soft);
    ${typographyV2.bodyXs};
  }
`;

export const AxisText = styled.text`
  ${typographyV2.bodyXs};
  fill: var(--text-soft);
`;

export const AxisLine = styled.line`
  stroke: var(--border-soft);
`;
