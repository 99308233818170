import { Entity } from '@toggle/toggle';

export enum ChartSearchType {
  Primary,
  Change,
  Compare,
}

export type SearchAssetPayload =
  | AssetPrimaryPayload
  | AssetComparePayload
  | AssetChangePayload;

interface AssetPrimaryPayload {
  type: ChartSearchType.Primary;
  asset: Entity;
}

interface AssetComparePayload {
  type: ChartSearchType.Compare;
  asset: Entity;
}
interface AssetChangePayload {
  type: ChartSearchType.Change;
  asset: Entity;
  assetToChange: Entity;
}
