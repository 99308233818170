import throttle from 'lodash/throttle';
import { StoreApi } from 'zustand';

import { tradingEndpoints } from '~/api/trading/trading-service';
import { BaseWs } from '~/shared/services/base-ws/BaseWs';
import { create } from '~/stores/create-store/createStore';

export enum PriceWebSocketState {
  Loading,
  Error,
  Connected,
}

export enum TradingWSEvents {
  Status = 'status',
  Subscribed = 'subscribed',
  Unsubscribed = 'unsubscribed',
  Update = 'update',
  Error = 'error',
}

interface TradingWSSendSubscribe {
  op: string;
  channel: string;
  market: string;
}

interface InitialiseProps {
  loginId: string;
  brokerId: string;
  securityIds: string[];
}

export interface StatusMessage {
  event: TradingWSEvents.Status;
  status: 'connected';
}

export interface SubscribedMessage {
  event: TradingWSEvents.Subscribed;
  channel: 'ticker';
  market: string;
}

export interface UpdateMessage {
  event: TradingWSEvents.Update;
  channel: 'ticker';
  market: string;
  last: number;
  ask: number;
  bid: number;
  mid: number;
  time: string;
  delayed: boolean;
  frozen: boolean;
}

export enum TradingWsErrors {
  PricesNotAvailable = 'prices-not-available',
  AuthorizationWithdrawn = 'authorization-withdrawn',
  InvalidMessageFormat = 'invalid-message-format',
  UntradableAsset = 'untradable-asset',
}
export interface ErrorMessage {
  event: 'error';
  message: TradingWsErrors;
}

export type TradingWsEventMessage =
  | StatusMessage
  | SubscribedMessage
  | UpdateMessage
  | ErrorMessage;

export interface BrokerWsStore {
  assetLivePrices: Record<string, UpdateMessage>;
  error?: TradingWsErrors;
  wsCurrent?: BaseWs<TradingWsEventMessage>;
  connection: PriceWebSocketState;
  initialise: (props: InitialiseProps) => void;
  subscribe: (ws: BaseWs<TradingWsEventMessage>, securityIds: string[]) => void;
  unsubscribe: () => void;
}

const defaultWsStoreState = {
  assetLivePrices: {},
  wsCurrent: undefined,
  error: undefined,
  connection: PriceWebSocketState.Loading,
};

const onPriceUpdate = (
  set: StoreApi<BrokerWsStore>['setState'],
  updateMsg: UpdateMessage
) => {
  set(state => {
    return {
      ...state,
      connection: PriceWebSocketState.Connected,
      error: undefined,
      assetLivePrices: {
        ...state.assetLivePrices,
        [updateMsg.market]: updateMsg,
      },
    };
  });
};

const initialiseTradingWs = (
  { loginId, brokerId, securityIds }: InitialiseProps,
  set: StoreApi<BrokerWsStore>['setState']
) => {
  try {
    const ws = new BaseWs<TradingWsEventMessage>(
      tradingEndpoints.brokerWs({
        brokerId,
        loginId,
      })
    );
    ws.onmessage = throttle((wsResponse: TradingWsEventMessage) => {
      if (!ws.closed) {
        if (
          wsResponse.event === TradingWSEvents.Status &&
          wsResponse.status === 'connected'
        ) {
          subscribeAssetPrice(ws, securityIds);
        }
        if (wsResponse.event === TradingWSEvents.Update) {
          onPriceUpdate(set, wsResponse);
        }
        if (wsResponse.event === TradingWSEvents.Error) {
          set({
            connection: PriceWebSocketState.Error,
            error: wsResponse.message,
          });
        }
      }
    }, 1000);
    set({ wsCurrent: ws });
  } catch {
    set({ wsCurrent: undefined });
  }
};

const subscribeAssetPrice = (
  ws: BaseWs<TradingWsEventMessage>,
  securityIds: string[]
) => {
  securityIds.forEach(tickerId => {
    const message: TradingWSSendSubscribe = {
      op: 'subscribe',
      channel: 'ticker',
      market: tickerId,
    };
    ws.sendMessage(JSON.stringify(message));
  });
};

const unsubscribe = (
  set: StoreApi<BrokerWsStore>['setState'],
  get: StoreApi<BrokerWsStore>['getState']
) => {
  get().wsCurrent?.unsubscribe();
  set(defaultWsStoreState);
};

export const createUseBrokerWs = () =>
  create<BrokerWsStore>((set, get) => ({
    ...defaultWsStoreState,
    initialise: props => initialiseTradingWs(props, set),
    subscribe: subscribeAssetPrice,
    unsubscribe: () => unsubscribe(set, get),
  }));
