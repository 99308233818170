import React from 'react';

import { Button } from '~/components/button/Button';

import * as S from './PaywallContent.styles';

export interface PaywallContentProps {
  planName: string;
  planBilling: string;
  planPrice: string;
  isFromIb: boolean;
  primaryAction?: () => void;
  secondaryAction?: () => void;
  title: string;
  description: string;
  content?: {
    imgSrc: string;
    webpSrc: string;
    imgAlt: string;
    benefits: string[];
  };
  primaryLabel: string;
  secondaryLabel: string;
}

export const PaywallContent = ({
  planName,
  planPrice,
  planBilling,
  primaryAction,
  secondaryAction,
  isFromIb,
  title,
  description,
  content,
  primaryLabel,
  secondaryLabel,
}: PaywallContentProps) => {
  return (
    <>
      <S.Header>
        <S.TextContainer>
          <S.Title>{title}</S.Title>
          <S.Description>{description}</S.Description>
        </S.TextContainer>
        {content && (
          <S.Picture>
            <source srcSet={content.webpSrc} type="image/webp" />
            <img src={content.imgSrc} alt={content.imgAlt} />
          </S.Picture>
        )}
      </S.Header>
      <S.Separator />
      {!isFromIb && (
        <div>
          <S.PlanName>{planName}</S.PlanName>
          <S.PlanPriceAndBillingContainer>
            <S.PlanPrice>{planPrice}</S.PlanPrice>
            <S.PlanBilling>/{planBilling}</S.PlanBilling>
          </S.PlanPriceAndBillingContainer>
        </div>
      )}
      <S.List>
        {content?.benefits.map((item, index) => (
          <S.ListItem key={index}>
            <S.CustomIcon iconName="Checkmark" size={20} />
            {item}
          </S.ListItem>
        ))}
      </S.List>
      <S.Footer>
        <Button
          size="large"
          data-testid="paywall-primary-button"
          label={primaryLabel}
          onClick={primaryAction}
          fullWidth
        />
        <Button
          size="large"
          variant="tertiary"
          data-testid="paywall-secondary-button"
          label={secondaryLabel}
          onClick={secondaryAction}
          fullWidth
        />
      </S.Footer>
    </>
  );
};
