import { z } from 'zod';

export const POJSObject = <T extends z.ZodTypeAny>(schema: T) =>
  z.record(schema);

export type StaticMessage = z.infer<typeof staticMessageSchema>;
export const staticMessageSchema = z.object({
  message: z.string(),
});

export const languageStringsSchema = z.object({
  chinese: z.string(),
  english: z.string(),
  japanese: z.string(),
  kanji: z.string(),
  korean: z.string(),
});
