import { TFunction } from 'i18next';
import React, { forwardRef, useRef } from 'react';

import { useFilterData } from '~/views/explore/filter/useFilterData';

import * as S from './Feed.styles';

const MAX_ARTICLES = 3;

interface FeedProps {
  articleId: string;
  t: TFunction;
}

export const Feed = forwardRef<HTMLHeadingElement, FeedProps>(
  ({ t, articleId }, h2Ref) => {
    const ref = useRef<HTMLElement>(null);

    const { data, loading, error } = useFilterData();

    const articles = data
      .filter(article => article.article_id !== articleId)
      .slice(0, MAX_ARTICLES)
      .map((d, i) => (
        <S.LastArticleCard
          articleIdx={i}
          key={d.created_on}
          data={d}
          location="Article"
          inArticleFeed
        />
      ));

    return (
      <S.Container ref={ref}>
        <S.FeedTitle ref={h2Ref}>
          {t('article:sidebarMenu.moreLikeThis')}
        </S.FeedTitle>
        {!error && articles}
        <S.LoadingContainer loading={loading} />
      </S.Container>
    );
  }
);
