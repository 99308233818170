import { z } from 'zod';

import { episodeSchema } from './compass-schema';

export const dominoPredictionSchema = z.object({
  relative_idx: z.number(),
  low: z.number(),
  median: z.number(),
  high: z.number(),
});

export const dominoSchema = z.object({
  entity: z.string(),
  prediction: z.array(dominoPredictionSchema),
  episodes: z.array(episodeSchema),
});

const dominoResponseSchema = z.array(dominoSchema);

export const scenario = {
  domino: {
    path: `scenario-orchestrator/v1/compass`,
    schema: dominoResponseSchema,
  },
} as const;

export type Domino = z.infer<typeof dominoSchema>;
export type DominoResponse = z.infer<typeof dominoResponseSchema>;
