import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { BoxProps } from '../box';
import * as QuestionnaireSharedStyles from '../questionnaire-shared-styles/QuestionnaireSharedStyles.styles';
import * as S from './UserInterest.styles';

export enum UserInterestOptions {
  tradeIdea = 'tradeIdea',
  asset = 'asset',
}

interface NextActionProps {
  handleClick: (option: UserInterestOptions) => void;
  active?: UserInterestOptions;
}

export const UserInterest: FC<NextActionProps> = ({ handleClick, active }) => {
  const { t } = useTranslation('onboarding');

  return (
    <S.Container>
      <S.TradeIdeaBox<FC<BoxProps<typeof UserInterestOptions.tradeIdea>>>
        left={
          <>
            <QuestionnaireSharedStyles.Text isLarge>
              {t('onboarding:questionnaire.next.idea')}
            </QuestionnaireSharedStyles.Text>
            <QuestionnaireSharedStyles.Text>
              {t('onboarding:questionnaire.next.big')}
            </QuestionnaireSharedStyles.Text>
          </>
        }
        right={
          <S.Img
            src="/app/assets/onboarding/trade-idea.png"
            alt={t('onboarding:questionnaire.header.tradeIdeaAlt')}
          />
        }
        id={UserInterestOptions.tradeIdea}
        onClick={handleClick}
        active={active === UserInterestOptions.tradeIdea}
      />
      <S.AssetBox<FC<BoxProps<typeof UserInterestOptions.asset>>>
        left={
          <>
            <QuestionnaireSharedStyles.Text isLarge>
              {t('onboarding:questionnaire.next.asset')}
            </QuestionnaireSharedStyles.Text>
            <QuestionnaireSharedStyles.Text>
              {t('onboarding:questionnaire.next.curious')}
            </QuestionnaireSharedStyles.Text>
          </>
        }
        right={
          <S.Img
            src="/app/assets/onboarding/asset.png"
            alt={t('onboarding:questionnaire.header.assetAlt')}
          />
        }
        id={UserInterestOptions.asset}
        onClick={handleClick}
        active={active === UserInterestOptions.asset}
      />
    </S.Container>
  );
};
