import React, { FC, useState } from 'react';

import { MappedEntity } from '~/shared/hooks/use-entities';

import { LogoImage, LogoPlaceholder } from './components';

export interface LogoProps {
  entity: MappedEntity;
  className?: string;
  logoUrl: string;
}

export const Logo: FC<LogoProps> = ({ logoUrl, entity, className }) => {
  const [hasError, setHasError] = useState(false);
  const showFallbackLogo = !logoUrl || hasError;

  return showFallbackLogo ? (
    <LogoPlaceholder entity={entity} className={className} />
  ) : (
    <LogoImage
      entity={entity}
      className={className}
      logoUrl={logoUrl}
      onError={() => setHasError(true)}
    />
  );
};
