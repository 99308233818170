/* eslint-disable max-lines-per-function */
import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { PlaceOrderResponse } from '~/api/trading/trading-schema';
import { OptionalN } from '~/declarations/standard';
import { OrderConfirmationLoading } from '~/global/trading-drawer/order-confirmation/error/OrderConfirmationLoading';
import {
  TradingErrors,
  useTransaction,
} from '~/global/trading-drawer/use-transaction/useTransaction';
import { appPaths, queryKeys } from '~/routes/app-paths';
import {
  gaClickCancelOrder,
  gaClickEditOrder,
  gaClickOrderStatus,
  gaClickReview,
  gaClickSubmit,
  gaClickViewOnProvider,
  gaTryAgain,
  OrderFormType,
} from '~/shared/utils/ganalytics/tradingGA';
import { Portfolio } from '~/stores/use-portfolio/usePortfolio';
import { TradingProvider } from '~/stores/use-portfolio/utils/use-portfolio-utils';
import { userStatementsStore } from '~/stores/user-statements-store/userStatementsStore';
import { PortfolioPageViews } from '~/views/portfolio-page/PortfolioPageViews';
import { usePortfolioPage } from '~/views/portfolio-page/store/use-portfolio-page/usePortfolioPage';

import {
  ConfirmationScreen,
  ProcessingState,
} from '../confirmation-screen/ConfirmationScreen';
import { OrderConfirmation } from '../order-confirmation/OrderConfirmation';
import { OrderTrading } from '../order-trading/OrderTrading';
import {
  SecondaryConfirmation,
  SecondaryConfirmationState,
} from '../secondary-confirmation/SecondaryConfirmation';
import type { CloseModalSettings } from '../trading-drawer-content/TradingDrawerContent';
import {
  isPreviewSuccess,
  isReviewRequired,
  mapTradingProviderToContactLink,
  SubmittedOrderState,
} from './utils/trading-utils';

export interface TradingProps {
  handleDrawerCloseClick: () => void;
  processingState: ProcessingState;
  setProcessingState: Dispatch<SetStateAction<ProcessingState>>;
  orderState: SubmittedOrderState;
  setOrderState: (state: SubmittedOrderState) => void;
  setCloseModalSettings: Dispatch<SetStateAction<CloseModalSettings>>;
  tradingProviderLabel: string;
}

export const Trading: FC<TradingProps> = ({
  handleDrawerCloseClick,
  processingState,
  setProcessingState,
  orderState,
  setOrderState,
  setCloseModalSettings,
  tradingProviderLabel,
}) => {
  const { t } = useTranslation('portfolio');
  const navigate = useNavigate();
  const [tryCount, setTryCount] = useState(0);
  const [previewConfirmationLoadingError, setPreviewConfirmationLoadingError] =
    useState('');
  const [previewConfirmationError, setPreviewConfirmationError] = useState('');
  const [orderErrorMessage, setOrderErrorMessage] = useState('');
  const [secondaryConfirmationState, setSecondaryConfirmationState] =
    useState<OptionalN<SecondaryConfirmationState>>(null);
  const newlyCreatedOrderId = useRef<string>();

  const {
    updateOrder,
    isValidTransaction,
    previewOrder,
    submitOrder,
    transaction,
    hideTradingView,
    getBuyingPower,
    buyingPower,
    portfolio,
    replyOrder,
  } = useTransaction(
    ({
      isValidTransaction,
      updateOrder,
      submitOrder,
      previewOrder,
      transaction,
      hideTradingView,
      getBuyingPower,
      buyingPower,
      portfolio,
      replyOrder,
    }) => ({
      isValidTransaction: isValidTransaction(),
      updateOrder,
      submitOrder,
      transaction,
      previewOrder,
      hideTradingView,
      getBuyingPower,
      buyingPower,
      portfolio: portfolio as Portfolio,
      replyOrder,
    })
  );
  const setActivePortfolioId = usePortfolioPage(s => s.setActivePortfolioId);
  const userStatements = userStatementsStore(s => s.userStatements);
  const statement = userStatements.find(
    s =>
      s.portfolio.id === portfolio.id &&
      s.security.ticker === transaction.order.ticker
  );
  const statementPosition = statement?.position ?? 0;

  useEffect(() => {
    getBuyingPower();
  }, []);

  useEffect(() => {
    if (tryCount >= 3) {
      setOrderState(SubmittedOrderState.Processing);
      setProcessingState('fatal');
    }
  }, [tryCount]);

  const incTryNumber = () => setTryCount(count => count + 1);

  const editOrder = () => {
    setOrderState(SubmittedOrderState.Idle);
    gaClickEditOrder();
  };

  const review = async () => {
    gaClickReview(location.pathname, OrderFormType.Form);
    if (!isReviewRequired(transaction)) {
      setOrderState(SubmittedOrderState.Review);
      return;
    }

    setOrderState(SubmittedOrderState.ReviewLoading);
    const output = await previewOrder();
    if (isPreviewSuccess(output)) {
      setOrderState(SubmittedOrderState.Review);
      setPreviewConfirmationError(output.data.error_message ?? '');
      return;
    }

    if (output.error === TradingErrors.UntradableAsset) {
      setProcessingState('cannotTrade');
      setOrderState(SubmittedOrderState.Processing);
      return;
    }

    if (
      output.error !== TradingErrors.ApiError &&
      output.error !== TradingErrors.InvalidTransaction
    ) {
      setPreviewConfirmationLoadingError(output.error);
    }
    setOrderState(SubmittedOrderState.ReviewError);
  };

  const handleOrderStatus = () => {
    gaClickOrderStatus();
    navigate(
      `${appPaths.portfolio}?${queryKeys.view}=${PortfolioPageViews.Orders}`,
      {
        state: {
          newlyCreatedOrderId: newlyCreatedOrderId.current,
        },
      }
    );
    setActivePortfolioId(portfolio.id);
    hideTradingView();
  };

  const handleContactProvider = (tradingProvider: TradingProvider) => {
    gaClickViewOnProvider(tradingProvider);
    window?.open(mapTradingProviderToContactLink[tradingProvider], '_blank');
  };

  const handleOrderResponse = (data: PlaceOrderResponse) => {
    setTryCount(0);
    newlyCreatedOrderId.current = data.order_id;
    if ('confirmation_id' in data) {
      setOrderState(SubmittedOrderState.ConfirmationRequired);
      setSecondaryConfirmationState({
        confirmation_id: data.confirmation_id,
        confirmation_messages: data.confirmation_messages,
      });
    } else {
      setProcessingState('submitted');
    }
  };

  const processOrder = async () => {
    setOrderState(SubmittedOrderState.Processing);
    setProcessingState('processing');
    setOrderErrorMessage('');

    const output = await submitOrder();

    if ('data' in output) {
      handleOrderResponse(output.data);
    } else {
      if (output.message) {
        setOrderErrorMessage(output.message);
      }

      if (output.error === TradingErrors.UntradableAsset) {
        setProcessingState('cannotTrade');
        return;
      }

      setProcessingState('error');
      incTryNumber();
    }
  };

  const onUnderstoodClick = async (confirmationId: string) => {
    setProcessingState('processing');
    setOrderState(SubmittedOrderState.Processing);
    setSecondaryConfirmationState(null);
    const output = await replyOrder({
      confirmation_id: confirmationId,
    });
    setProcessingState(output.error ? 'fatal' : 'submitted');
  };

  const handleTryAgain = () => {
    gaTryAgain(location.pathname, OrderFormType.Form);
    processOrder();
  };

  const handleCancelation = (withOutWarning = false) => {
    if (withOutWarning) {
      hideTradingView();
      return;
    }

    setCloseModalSettings({
      isOpen: true,
      title: t('portfolio:cancellingOrder'),
      continueLabel: t('portfolio:trySubmitAgain'),
      secondaryAction: handleTryAgain,
    });
    gaClickCancelOrder();
  };

  const onSubmitNewOrder = () => {
    gaClickSubmit(location.pathname, OrderFormType.Form);
    processOrder();
  };

  const inReviewLoading =
    orderState === SubmittedOrderState.ReviewLoading ||
    orderState === SubmittedOrderState.ReviewError;

  const inReview = orderState === SubmittedOrderState.Review || inReviewLoading;

  return (
    <>
      {orderState === SubmittedOrderState.Idle && (
        <OrderTrading
          updateOrder={updateOrder}
          isValidTransaction={isValidTransaction}
          reviewOrder={review}
          transaction={transaction}
          statementPosition={statementPosition}
          buyingPower={buyingPower}
        />
      )}
      {inReviewLoading && (
        <OrderConfirmationLoading
          reviewOrder={review}
          hideTradingView={hideTradingView}
          orderState={orderState}
          editOrder={editOrder}
          errorMessage={previewConfirmationLoadingError}
          tradingProviderLabel={tradingProviderLabel}
        />
      )}
      {inReview && (
        <OrderConfirmation
          processOrder={onSubmitNewOrder}
          editOrder={editOrder}
          transaction={transaction}
          statementPosition={statementPosition}
          errorMessage={previewConfirmationError}
          tradingProviderLabel={tradingProviderLabel}
        />
      )}
      {secondaryConfirmationState && (
        <SecondaryConfirmation
          {...secondaryConfirmationState}
          onUnderstoodClick={onUnderstoodClick}
        />
      )}
      {orderState === SubmittedOrderState.Processing && (
        <ConfirmationScreen
          state={processingState}
          errorMessage={orderErrorMessage}
          tradingProvider={transaction.brokerId}
          handleCloseWindow={handleDrawerCloseClick}
          handleTryAgain={handleTryAgain}
          handleCancelation={handleCancelation}
          handleOrderStatus={handleOrderStatus}
          handleContactProvider={handleContactProvider}
        />
      )}
    </>
  );
};
