import { typography } from '@toggle/design-system';
import styled from 'styled-components';

export const BoxTextLg = styled.p`
  ${typography.coyote};
`;

export const BoxText = styled.p`
  ${typography.emu};
`;
