import {
  borderRadius,
  customScrollMinimal,
  elevation,
} from '@toggle/design-system';
import styled from 'styled-components';

export const ChatRoot = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: var(--background-default);
  padding-bottom: 75px;
`;

export const Content = styled.div`
  ${customScrollMinimal};
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 16px 0px;
  width: 100%;
`;

export const Footer = styled.footer`
  position: fixed;
  bottom: 0;
  width: 100%;

  padding: 16px;
  background: var(--background-soft);
  border-top: 1px solid var(--border-soft);
  box-shadow: 0px -2px 3px rgba(0, 0, 0, 0.3),
    0px -6px 10px 4px rgba(0, 0, 0, 0.15);
`;

export const ErrorWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 8px;
  margin-bottom: 16px;
  background-color: var(--background-default);
  ${borderRadius.r3};
  &:focus-visible {
    outline: 1px solid var(--border-focus);
  }
  &:hover {
    border: 1px solid var(--border-hover);
  }
  border: 1px solid var(--border-default);
  ${elevation.strong};
`;
