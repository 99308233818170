// TODO: Move this to core funcitonaility of the Tablke component
import {
  Button,
  Icon,
  SmartTableColumn,
  Tooltip,
  TooltipTrigger,
} from '@toggle/design-system';
import React, { useState } from 'react';

import * as S from './TableColumnsEditor.styles';

export interface TableColumnsEditorTranslations {
  editColumnTooltip: string;
}

export interface TableColumnsEditorProps<T> {
  columns: SmartTableColumn<T>[];
  activeColumnKeys: string[];
  onActiveColumnToggle: (
    name: Extract<keyof T, string>,
    isChecked: boolean
  ) => void;
  i18nTranslations: {
    editColumnTooltip: string;
  };
}

export function TableColumnsEditor<T>({
  columns,
  activeColumnKeys,
  onActiveColumnToggle,
  i18nTranslations,
}: Readonly<TableColumnsEditorProps<T>>) {
  const [searchValue, setSearchValue] = useState('');

  const hidableColumns = columns.filter(column => !column.disableHiding);
  const filteredColumns = searchValue
    ? hidableColumns.filter(column =>
        column.label
          .toLocaleLowerCase()
          .includes(searchValue.toLocaleLowerCase())
      )
    : hidableColumns;

  return (
    <S.AddColumnTableHeader isSorted alignment="end">
      <S.ColumnsDropdown
        inPortal
        beforeResultsNode={
          <S.SearchInput
            data-testid="search-input"
            size="medium"
            leftIcon={<Icon iconName={'Search'} size={18} />}
            onChange={e => setSearchValue(e.target.value)}
          />
        }
        placement="bottom-end"
        onItemsShowChange={() => setSearchValue('')}
        dropdownItems={filteredColumns}
        shouldCloseOnSelect={false}
        shouldShowItems={false}
        renderDropdownItem={item => (
          <S.ItemRow key={item.key} data-testid="dropdown-item">
            <S.DropdownCheckbox
              data-testid="column-checkbox"
              name={item.key}
              label={item.label}
              checked={activeColumnKeys.includes(item.key)}
              onChange={() =>
                onActiveColumnToggle(
                  item.key,
                  !activeColumnKeys.includes(item.key)
                )
              }
              size="small"
            />
          </S.ItemRow>
        )}
      >
        <div>
          <Tooltip
            isTouchDevice={false}
            trigger={TooltipTrigger.Hover}
            label={i18nTranslations.editColumnTooltip}
            inPortal
          >
            <Button
              data-testid="open-dropdown-btn"
              iconName="Pencil"
              variant="secondary"
              size="xsmall"
            />
          </Tooltip>
        </div>
      </S.ColumnsDropdown>
    </S.AddColumnTableHeader>
  );
}
