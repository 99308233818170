import { z } from 'zod';

const watchlistSummarySchema = z.object({
  summary: z.string(),
});

export const watchlistSummary = {
  getWatchlistSummary: {
    path: (watchlistId: string) => `watchlist-summary/v1/${watchlistId}`,
    schema: watchlistSummarySchema,
  },
};

export type WatchlistSummary = z.infer<typeof watchlistSummarySchema>;
