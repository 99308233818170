export enum FeatureFlags {
  ENABLE_CHAT_REALZ = 'ENABLE_CHAT_REALZ',
  ENABLE_CHART_COMMAND = 'ENABLE_CHART_COMMAND',
  SHOW_IB_ALERT = 'SHOW_IB_ALERT',
  ENABLE_CHART_V2 = 'ENABLE_CHART_V2',
  SCENARIO_COMMANDER = 'SCENARIO_COMMANDER',
}

export interface Flags {
  [key: string]: boolean | undefined;
}

export type FeatureFlagsKeys = keyof Flags;
