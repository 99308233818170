import ReactGA from 'react-ga4';

import {
  BillingCycle,
  SubscriptionName,
} from '~/api/subscription/subscription-schema';
import { MyAccountViewPanelIds } from '~/views/my-account/utils/MyAccountViewPanelIds';

import { pageSource } from './commonGA';

const CATEGORIES = {
  myAccount: 'My_account',
};
export { CATEGORIES as MY_ACCOUNT_CATEGORIES };

const ACTIONS = {
  clickSubscription: 'Click_subscription',
  clickUpdateWatchlist: 'Click_update_watchlist',
  clickConnectPortfolio: 'Click_connect_portfolio',
  clickAppearance: 'Click_appearance',
  subscriptionPlanChange: 'Subscription_plan_change',
  subscriptionBillingChange: 'Subscription_billing_change',
  subscriptionPaymentChange: 'Subscription_payment_change',
  watchlistAdd: 'Watchlist_add',
  watchlistRemove: 'Watchlist_remove',
  clickContactUs: 'Click_contact_us',
  clickDowngrade: 'Click_downgrade',
  clickGoBack: 'Click_go_back',
  subscriptionConfirmed: 'Subscription_confirmed',
  clickPill: 'Click_pill',
  clickSubscribeButton: 'Click_subscribe_button',
  clickSecurity: 'Click_security',
  clickChangePassword: 'Click_change_password',
  updatePassword: 'Update_password',
  clickResendEmail: 'Click_resend_email',
  clickDontRecognizeEmail: 'Click_dont_recognize_email',
  resetPassword: 'Reset_password',
  cancelResetPassword: 'Cancel_reset_password',
};
export { ACTIONS as MY_ACCOUNT_ACTIONS };

export function gaMyAccountClickTab(tabId: MyAccountViewPanelIds) {
  const tabsActions = {
    [MyAccountViewPanelIds.Subscription]: ACTIONS.clickSubscription,
    [MyAccountViewPanelIds.Watchlist]: ACTIONS.clickUpdateWatchlist,
    [MyAccountViewPanelIds.Portfolio]: ACTIONS.clickConnectPortfolio,
    [MyAccountViewPanelIds.Security]: ACTIONS.clickSecurity,
    [MyAccountViewPanelIds.Appearance]: ACTIONS.clickAppearance,
  };
  ReactGA.event({
    category: CATEGORIES.myAccount,
    action: tabsActions[tabId],
  });
}

export function gaMyAccountChangePlan(
  prevSub?: SubscriptionName,
  nextSub?: SubscriptionName,
  isTrial = false
) {
  ReactGA.event({
    category: CATEGORIES.myAccount,
    action: ACTIONS.subscriptionPlanChange,
    label: `current:${isTrial ? 'Trial' : prevSub};next:${nextSub}`,
  });
}

export function gaMyAccountChangeBilling(frequency?: BillingCycle) {
  ReactGA.event({
    category: CATEGORIES.myAccount,
    action: ACTIONS.subscriptionBillingChange,
    label: `billing:${frequency}`,
  });
}

export function gaMyAccountChangePayment() {
  ReactGA.event({
    category: CATEGORIES.myAccount,
    action: ACTIONS.subscriptionPaymentChange,
  });
}

export function gaMyAccountUpdateWatchlist(
  tag: string,
  action: 'add' | 'remove',
  count: number
) {
  ReactGA.event({
    category: CATEGORIES.myAccount,
    action: action === 'add' ? ACTIONS.watchlistAdd : ACTIONS.watchlistRemove,
    label: `tag:${tag};assetsInWatchlist:${count}`,
  });
}

export function gaMyAccountClickContactUs(
  location:
    | 'header'
    | 'mobile_menu'
    | 'portfolio_toast'
    | 'subscription_plan'
    | 'ao_locked_insight'
    | 'helper_widget'
) {
  ReactGA.event({
    category: CATEGORIES.myAccount,
    action: ACTIONS.clickContactUs,
    label: `location:${location}`,
  });
}

export function gaMyAccountClickDowngrade() {
  ReactGA.event({
    category: CATEGORIES.myAccount,
    action: ACTIONS.clickDowngrade,
  });
}

export function gaMyAccountClickGoBack() {
  ReactGA.event({
    category: CATEGORIES.myAccount,
    action: ACTIONS.clickGoBack,
  });
}

export function gaMyAccountSubscriptionConfirmed() {
  ReactGA.event({
    category: CATEGORIES.myAccount,
    action: ACTIONS.subscriptionConfirmed,
  });
}

export function gaHeaderClickPill() {
  ReactGA.event({
    category: CATEGORIES.myAccount,
    action: ACTIONS.clickPill,
    label: `location:${pageSource}`,
  });
}

export function gaClickSubscribeButton(
  location: 'notification_banner' | 'header_trial_error'
) {
  ReactGA.event({
    category: CATEGORIES.myAccount,
    action: ACTIONS.clickSubscribeButton,
    label: `location:${location}`,
  });
}

export function gaMyAccountClickChangePassword() {
  ReactGA.event({
    category: CATEGORIES.myAccount,
    action: ACTIONS.clickChangePassword,
  });
}

export function gaMyAccountUpdatePassword(isLogoutChecked: boolean) {
  ReactGA.event({
    category: CATEGORIES.myAccount,
    action: ACTIONS.updatePassword,
    label: `logoutBoxChecked:${isLogoutChecked}`,
  });
}

export function gaMyAccountClickResendEmail() {
  ReactGA.event({
    category: CATEGORIES.myAccount,
    action: ACTIONS.clickResendEmail,
  });
}

export function gaMyAccountClickDontRecognizeEmail() {
  ReactGA.event({
    category: CATEGORIES.myAccount,
    action: ACTIONS.clickDontRecognizeEmail,
  });
}

export function gaMyAccountResetPassword(isLogoutChecked: boolean) {
  ReactGA.event({
    category: CATEGORIES.myAccount,
    action: ACTIONS.resetPassword,
    label: `logoutBoxChecked:${isLogoutChecked}`,
  });
}

export function gaMyAccountCancelResetPassword() {
  ReactGA.event({
    category: CATEGORIES.myAccount,
    action: ACTIONS.cancelResetPassword,
  });
}
