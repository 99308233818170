import React, { forwardRef, ReactNode, useState } from 'react';
import { CSSTransition } from 'react-transition-group';

import { OptionalU } from '~/declarations/standard';

import * as S from './Collapse.styles';

interface CollapseProps {
  collapsed: boolean;
  className?: string;
  timeout?: number;
  children: ReactNode;
}

export const Collapse = forwardRef<HTMLDivElement, CollapseProps>(
  ({ collapsed, className, children, timeout = 250 }, ref) => {
    const [height, setHeight] = useState<OptionalU<number | string>>(
      collapsed ? 0 : 'auto'
    );

    const onEnter = () => {
      setHeight(0);
    };

    const handleExitAndEntering = (node: HTMLElement) => {
      const value = node.scrollHeight;
      setHeight(value);
    };

    const onExiting = () => {
      setHeight(0);
    };

    const onEntered = () => {
      setHeight(undefined);
    };

    return (
      <CSSTransition
        in={!collapsed}
        timeout={timeout}
        onEnter={onEnter}
        onEntering={handleExitAndEntering}
        onEntered={onEntered}
        onExiting={onExiting}
        onExit={handleExitAndEntering}
      >
        <S.Body
          style={{ height, transitionDuration: `${timeout}ms` }}
          className={className}
          data-testid="collapse-body"
          ref={ref}
        >
          {children}
        </S.Body>
      </CSSTransition>
    );
  }
);
