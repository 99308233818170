import { Icon, Input } from '@toggle/design-system';
import { decimalCount, useOutsideClick } from '@toggle/helpers';
import { times } from 'number-precision';
import React, { ChangeEvent, FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Expiration,
  Order,
} from '~/global/trading-drawer/use-transaction/useTransaction';
import { TooltipContainer } from '~/shared/components/tooltip-container/TooltipContainer';
import { formatAssetPrice } from '~/shared/utils/currency/currency';
import { gaChooseExpiration } from '~/shared/utils/ganalytics/tradingGA';

import { ExpirationList } from '../expiration-list/ExpirationList';
import { QuantityInput } from '../quantity-input/QuantityInput';
import { BidMidAskPopup } from './bid-mid-ask-popup/BidMidAskPopup';
import { DecimalsTooltip } from './decimals-tooltip/DecimalsTooltip';
import * as S from './LimitOrder.styles';

export type LimitOrderValues = Partial<
  Pick<Order, 'quantity' | 'price' | 'expiration'>
>;

export interface LimitOrderProps {
  onChange: (i: LimitOrderValues) => void;
  order: Order;
  priceDecimals: number;
  tradingProviderLabel: string;
}

export const LimitOrder: FC<LimitOrderProps> = ({
  onChange,
  order,
  priceDecimals,
  tradingProviderLabel,
}) => {
  const { t } = useTranslation('portfolio');
  const [price, setPrice] = useState(order.price ? order.price.toString() : '');
  const [isAutocompleteOpen, setIsAutocompleteOpen] = useState(false);
  const autocompleteMenuRef = useRef(null);
  useOutsideClick({
    ref: autocompleteMenuRef,
    callback: () => setIsAutocompleteOpen(false),
  });
  const [decimalRuleValid, setDecimalRuleValid] = useState({
    price: true,
    quantity: true,
  });

  const handlePriceChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setDecimalRuleValid(s => ({
      ...s,
      price: decimalCount(value) <= priceDecimals,
    }));
    setPrice(value);
  };

  const handleQuantityChange = (value: string) => {
    const numValue = value ? parseFloat(value) : 0;
    setDecimalRuleValid(s => ({
      ...s,
      quantity: decimalCount(value) <= priceDecimals,
    }));
    onChange({ quantity: numValue });
  };

  const handleExpirationChange = (key: Expiration) => {
    onChange({ expiration: key });
    setIsAutocompleteOpen(false);
    gaChooseExpiration(key);
  };

  const handleAutocompleteClick = (value: number) => {
    setPrice(formatAssetPrice(value));
    setIsAutocompleteOpen(false);
  };

  const total = () => {
    const { quantity, price, currency } = order;
    return formatAssetPrice(times(quantity, price), currency);
  };

  useEffect(() => {
    const numValue = price ? parseFloat(price) : 0;
    onChange({ price: numValue });
  }, [price]);

  return (
    <S.Container data-testid="limit-order">
      <S.Row>
        <S.Label>
          {t('portfolio:limitPrice')}
          <TooltipContainer label={t('portfolio:limitPriceTooltip')}>
            <S.ToolTipWrapper>
              <Icon iconName="QuestionMarkFill" size={14} />
            </S.ToolTipWrapper>
          </TooltipContainer>
        </S.Label>

        <S.InputWrapper ref={autocompleteMenuRef}>
          <DecimalsTooltip
            isOpen={!decimalRuleValid.price}
            label={t('portfolio:fractionalPriceExceeded', {
              count: priceDecimals,
            })}
          >
            <Input
              leftIcon={<S.CurrencyIcon>{order.currencySymbol}</S.CurrencyIcon>}
              type="number"
              name="price"
              rightAlignText
              onChange={handlePriceChange}
              data-testid="limit-order-price"
              value={price}
              onClick={() => setIsAutocompleteOpen(c => !c)}
            />
          </DecimalsTooltip>
          {isAutocompleteOpen && (
            <BidMidAskPopup
              sendAutocompleteClick={handleAutocompleteClick}
              currency={order.currency}
            />
          )}
        </S.InputWrapper>
      </S.Row>
      <S.Row>
        <S.Label>{t('portfolio:quantity')}</S.Label>
        <S.InputWrapper>
          <QuantityInput
            value={order.quantity ? order.quantity.toString() : ''}
            onChange={handleQuantityChange}
            isDecimalExceeded={!decimalRuleValid.quantity}
            tradingProviderLabel={tradingProviderLabel}
          />
        </S.InputWrapper>
      </S.Row>
      <S.Price>
        {order.currency}
        <S.Total data-testid="total">{total()}</S.Total>
      </S.Price>
      <S.ExpirationWrapper>
        <S.Label>{t('portfolio:expiration')}</S.Label>
        <S.InputWrapper>
          <ExpirationList
            value={order.expiration}
            onChange={handleExpirationChange}
          />
        </S.InputWrapper>
      </S.ExpirationWrapper>
    </S.Container>
  );
};
