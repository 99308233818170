export enum NumericalCellValueStyle {
  Positive,
  Negative,
  Neutral,
}

export const checkCellValueStyle = (
  value: number,
  comparison = 0
): NumericalCellValueStyle => {
  if (!value) {
    return NumericalCellValueStyle.Neutral;
  }
  if (value > comparison) {
    return NumericalCellValueStyle.Positive;
  } else {
    return NumericalCellValueStyle.Negative;
  }
};
