import { Entity } from '~/api/entities/entity-schema';
import { formatEntity } from '~/shared/hooks/use-entities';

import { SearchAutocomplete } from '../components/search-autocomplete-item/SearchAutocompleteItem';

export function hasEntityLoaded(items: SearchAutocomplete[], value: string) {
  return value ? items.some(({ rightText }) => rightText === value) : true;
}

export const mapEntityItems = (tags: Entity[]): SearchAutocomplete[] =>
  tags.map(item => {
    const name = item.name_short || item.name_full;
    const itemExchangeCode = item.exchange.code ? `:${item.exchange.code}` : '';
    const mappedEntity = formatEntity(item);

    return {
      leftText: `${item.ticker}${itemExchangeCode}`.toUpperCase(),
      rightText: name,
      tag: item.tag,
      id: item.id,
      defaultSnake: item.default_snake,
      description: name,
      entity: mappedEntity,
    };
  });
