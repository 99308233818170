import React, { FC } from 'react';

import Logo from '../../../static/logo.svg';
import * as S from './Loader.styles';

interface LoaderProps {
  className?: string;
}

export const Loader: FC<LoaderProps> = ({ className }) => (
  <S.Loader data-testid="loading-view" className={className}>
    <S.InnerBox>
      <S.LogoBox>
        <Logo />
      </S.LogoBox>
      <S.ProgressBar>
        <S.ProgressBarMeter />
      </S.ProgressBar>
    </S.InnerBox>
  </S.Loader>
);
