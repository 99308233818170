import { z } from 'zod';

export type AuthDataTwoFactorPayload = z.infer<typeof authDataTwoFactorSchema>;
export type AuthDataPayload = z.infer<typeof authSchema>;
export type CheckResetPasswordTokenResponse = z.infer<
  typeof checkResetPasswordTokenSchema
>;
export type ResetPasswordResponse = z.infer<typeof resetPasswordSchema>;
export type LoginValidationResponse = z.infer<
  typeof LoginValidationResultSchema
>;
export type ChangePasswordResponse = z.infer<
  typeof ChangePasswordResponseSchema
>;
export type CheckPasswordData = z.infer<typeof CheckPasswordDataSchema>;

enum TwoFactorMethod {
  Totp = 'totp',
  Email = 'email',
  Backup = 'backup',
}

export const authSchema = z.object({
  user_id: z.string(),
  user_role: z.string(),
  email: z.string(),
  source: z.number(),
  expiry: z.string(),
  token: z.string(),
});

const twoFactorMethods = z.array(
  z.enum([TwoFactorMethod.Totp, TwoFactorMethod.Email, TwoFactorMethod.Backup])
);

export const authDataTwoFactorSchema = z.object({
  user_id: z.string(),
  two_factor_methods: twoFactorMethods,
});

export const checkResetPasswordTokenSchema = z.object({
  token: z.string(),
  is_used: z.boolean(),
  is_expired: z.boolean(),
  not_found: z.boolean(),
});

export const resetPasswordSchema = z.object({
  message: z.string(),
});

export const LoginValidationResultSchema = z
  .object({
    message: z.string(),
  })
  .or(z.object({ error: z.string() }));

export const ChangePasswordResponseSchema = z.object({
  email: z.string(),
  user_id: z.string(),
});

export const CheckPasswordDataSchema = z.object({
  validated: z.boolean(),
});
