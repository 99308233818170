import { AlertType, Button } from '@toggle/design-system';
import { keyboard } from '@toggle/helpers';
import React, { KeyboardEvent, ReactNode, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { CopyToClipboard } from '~/views/chat/components/copy-to-clipboard/CopyToClipboard';

import {
  Message,
  MessageStatus,
  SendMessageProps,
} from '../../use-chat-store/chat-store.types';
import { AmbiguousEntities } from '../ambiguous-entities/AmbiguousEntities';
import { ChatMarkdown } from '../chat-markdown/ChatMarkdown';
import {
  ChatMessageActionButton,
  ChatMessageActionButtonProps,
} from '../chat-message-action-button/ChatMessageActionButton';
import { CogSearchBreakdown } from '../cog-search-breakdown/CogSearchBreakdown';
import { FeedbackButton } from '../feedback-button/FeedbackButton';
import { Progress } from '../progress/Progress';
import * as S from './ChatMessage.styles';

export interface ChatMessageProps {
  activeMessage?: Message;
  message: Message;
  messageIndex: number;
  afterReceivedMessageNode?: ReactNode;
  updateActiveMessage: (index: number, options?: Message) => void;
  handleFeedback: (id: string, reason: string) => void;
  actionButtonProps: ChatMessageActionButtonProps | null;
  handleStreamAbort: () => void;
  sendStreamRequest: (message: SendMessageProps) => void;
}

export const ChatMessage = ({
  activeMessage,
  message,
  messageIndex,
  updateActiveMessage,
  handleFeedback,
  afterReceivedMessageNode,
  actionButtonProps,
  handleStreamAbort,
  sendStreamRequest,
}: ChatMessageProps) => {
  const { t } = useTranslation('common');
  const messageElementRef = useRef<HTMLDivElement>(null);
  const questionRef = useRef<HTMLParagraphElement>(null);
  const handleClick = () => updateActiveMessage(messageIndex);

  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.target !== messageElementRef.current) {
      return;
    }

    if (
      event.key === keyboard.enter.key ||
      event.code === keyboard.space.code
    ) {
      event.preventDefault();
      handleClick();
    }
  };

  const { status, reply, isActive, question, service, requestId } = message;

  const streamActiveMessage = MessageStatus.Sent === status || reply?.isPending;
  const showCopyToClipboard = !reply?.isPending && reply?.answer;

  return (
    <S.MessageRoot
      data-testid="chat-message"
      tabIndex={0}
      onClick={handleClick}
      onKeyDown={handleKeyDown}
      $isActive={isActive}
      $questionHeight={questionRef.current?.offsetHeight}
      ref={messageElementRef}
    >
      <S.UserMessageText ref={questionRef}>{question}</S.UserMessageText>
      <S.ReceivedMessage>
        {reply?.orchestratorMetadata?.ambiguous_entities && question && (
          <AmbiguousEntities
            orchestratorMetadata={reply.orchestratorMetadata}
            onConfirm={selectedTags => {
              sendStreamRequest({
                service: 'orchestrator',
                question: question,
                entities: selectedTags,
                intents: reply.orchestratorMetadata?.intents,
                scopes: reply.orchestratorMetadata?.scopes,
                overrideRequestId: requestId,
              });
            }}
          />
        )}
        {reply?.answer ? (
          <>
            <ChatMarkdown markdown={reply.answer} />
            {reply.isError && (
              <S.StyledAlert
                type={AlertType.Warning}
                variant="inline"
                title={t('chat:error.title')}
                message={t('chat:error.message')}
              />
            )}
            {service === 'orchestrator' && (
              <CogSearchBreakdown message={message} />
            )}
            {!reply.isError && (
              <S.ButtonContainer
                data-testid="button-container"
                $messageMatch={activeMessage === message}
              >
                {reply.draftEmail && !streamActiveMessage && (
                  <Button
                    label={t('chat:draftEmail')}
                    variant="tertiary"
                    iconName="EnvelopeSolid"
                    iconPosition="left"
                    onClick={event => {
                      event.stopPropagation();
                      sendStreamRequest({
                        service: 'archaic-secretary',
                      });
                    }}
                  />
                )}

                {streamActiveMessage && (
                  <S.ActionButtons>
                    <S.InlineProgress>
                      <Progress />
                    </S.InlineProgress>
                    <Button
                      data-testid="abort-button"
                      label={t('chat:stopGenerating')}
                      variant="tertiary"
                      iconPosition="left"
                      iconName="Close"
                      onClick={event => {
                        event.stopPropagation();
                        handleStreamAbort();
                      }}
                    />
                  </S.ActionButtons>
                )}

                {message.service === 'archaic-secretary' &&
                  showCopyToClipboard &&
                  !streamActiveMessage && (
                    <S.ActionButtons>
                      <CopyToClipboard text={reply.answer} />
                    </S.ActionButtons>
                  )}

                {actionButtonProps && !streamActiveMessage && (
                  <ChatMessageActionButton {...actionButtonProps} />
                )}
                {reply.answer && !streamActiveMessage && (
                  <FeedbackButton
                    id={requestId}
                    handleFeedback={handleFeedback}
                    feedback={reply.feedback}
                  />
                )}
              </S.ButtonContainer>
            )}
          </>
        ) : (
          !reply?.isError && <Progress />
        )}
      </S.ReceivedMessage>
      {!streamActiveMessage && afterReceivedMessageNode}
    </S.MessageRoot>
  );
};
