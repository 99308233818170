import styled from 'styled-components';

export const LogoWrapper = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 2px;
  background-color: var(--base--0);
  overflow: hidden;
`;
