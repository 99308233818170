import { BaseScrollGlobalStyle, Loader } from '@toggle/design-system';
import React, { FC, Suspense, useEffect } from 'react';

import { ToastsLayout } from '~/global/toasts-layout/ToastsLayout';
import { Routes } from '~/routes';
import { livePriceWebSocket } from '~/stores/live-price-websocket/livePriceWebSocket';
import { getSession, useAuth } from '~/stores/use-auth/useAuth';

import { config } from './config';
import { Maintenance } from './global/maintenance/Maintenance';
import { PaywallContainer } from './global/paywall/PaywallContainer';
import { ThemeController } from './global/theme-controller/ThemeController';
import { useMediaQuery, useMediaQueryV2 } from './shared/hooks/MediaQueryHook';
import {
  useWsNotifier,
  WsNotifierResponse,
} from './shared/hooks/use-ws-notifier/useWsNotifier';
import { BaseWs } from './shared/services/base-ws/BaseWs';
import { gaInit } from './shared/utils/ganalytics';
import { HelperStore } from './stores/use-helper/useHelper';
import { useNavigator } from './stores/use-navigator/useNavigator';
import { useSystemFlags } from './stores/use-system-flags/useSystemFlags';
import { setCurrentLanguage } from './utils/i18n';

gaInit(config.gaTrackingId);

export const App: FC = () => {
  const { initStores, authProccessing, setAuthProccessing } = useAuth();
  const { listenToNavigator, language: navigatorLanguage } = useNavigator();
  const {
    systemFlags: { languages },
    fetchSystemFlags,
    checkIsInMaintenance,
  } = useSystemFlags();

  const initializeMediaQuery = useMediaQuery(s => s.initialize);
  const initializeMediaQueryV2 = useMediaQueryV2(s => s.initialize);
  const { init } = useWsNotifier();

  let notificationWs: BaseWs<WsNotifierResponse>;

  const initSession = async () => {
    const session = await getSession();
    if (session) {
      await initStores(session);
      notificationWs = init();
    }
    setAuthProccessing(false);
  };

  useEffect(() => {
    setCurrentLanguage(languages);
  }, [languages, navigatorLanguage]);

  useEffect(() => {
    fetchSystemFlags();
    initSession();
    initializeMediaQuery();
    initializeMediaQueryV2();

    return () => {
      livePriceWebSocket.getState().closeConnection();
      notificationWs?.unsubscribe();
    };
  }, []);

  useEffect(() => {
    listenToNavigator();
  }, []);

  if (checkIsInMaintenance()) {
    return (
      <Suspense fallback={<Loader />}>
        <Maintenance />
      </Suspense>
    );
  }

  return (
    <Suspense fallback={<Loader />}>
      <ThemeController />
      {authProccessing ? (
        <Loader />
      ) : (
        <HelperStore>
          <BaseScrollGlobalStyle />
          <PaywallContainer />
          <ToastsLayout />
          <Routes />
        </HelperStore>
      )}
    </Suspense>
  );
};
