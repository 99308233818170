import { deviceConstraints } from '@toggle/design-system';
import styled from 'styled-components';

export const BoxContainer = styled.div<{ $isActive: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 4px;
  border: ${props =>
    `solid 1px ${
      props.$isActive ? 'var(--primary-color)' : 'var(--base--90)'
    }`};
  color: ${props => props.$isActive && 'var(--primary-color)'};
  cursor: pointer;
  margin-bottom: 8px;
  padding: 10px;
  @media ${deviceConstraints.desktopSmall} {
    padding: 16px;
    margin-bottom: 16px;
  }

  > div:first-child {
    flex: 1;
    font-size: 18px;
  }
`;
