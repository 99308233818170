import { Alert, AlertType, typography } from '@toggle/design-system';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

interface BannerProps {
  isArticlePage: boolean;
  isBannerAvailable: boolean;
}

export const Banner = styled.div<BannerProps>`
  ${props =>
    props.isBannerAvailable &&
    css`
      padding: 16px 12px;
    `}

  ${props =>
    props.isArticlePage &&
    css`
      margin-bottom: 10px;
    `}
`;

export const BaseNotification = styled(Alert).attrs({
  type: AlertType.Info,
})`
  width: 100%;
`;

export const FeatureItem = styled.li`
  list-style-type: disc;
  ${typography.emu};
`;

export const FeaturesLink = styled(Link)`
  text-decoration: underline;
`;
