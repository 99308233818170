import React from 'react';
import { useTranslation } from 'react-i18next';

import * as S from './ServerError.styles';

export const ServerError = () => {
  const { t } = useTranslation('error');

  const title = t('error:serverError.title');
  const message = t('error:serverError.message');
  const actionText = t('error:serverError.actionText');

  return (
    <S.Container data-testid="server-error">
      <S.Img src="/app/assets/errors/error-500.png" alt="Error 500" />
      <S.Title>
        {title}
        <br />
        {message}
        <br />
        {actionText}
      </S.Title>
    </S.Container>
  );
};
