import { bisector } from 'd3-array';

import { Domain } from '~/types/axis.types';
import {
  CreateChartOptionsWithColors,
  DomainTimeSeries,
  XAxis,
  YAxis,
} from '~/types/create.types';
import {
  ChartAssetData,
  Timeseries,
  TimeSeriesItem,
} from '~/types/timeseries.types';
import { drawGradientArea } from '~/utils/gradient/gradient-utils';

interface DecimalIndexDomainPoint {
  y: YAxis;
  x: XAxis;
  startPointIdx: number;
  endPointIdx: number;
  currentX: number;
  y1: number;
  y2: number;
}

//equation of the line(between 2 points)
//(x - x1)/(x2 - x1) = (y - y1)/(y2 - y1)
const getLineFormula = (x1: number, y1: number, x2: number, y2: number) => {
  const xDiff = x2 - x1;
  const yDiff = y2 - y1;

  return {
    x: (y: number) => ((y - y1) * xDiff + x1 * yDiff) / yDiff,
    y: (x: number) => (yDiff * (x - x1) + xDiff * y1) / xDiff,
  };
};

const getDecimalIndexDomainPoint = ({
  y,
  x,
  currentX,
  startPointIdx,
  endPointIdx,
  y1,
  y2,
}: DecimalIndexDomainPoint) => {
  const lineFormula = getLineFormula(
    x.xScale(startPointIdx),
    y1,
    x.xScale(endPointIdx),
    y2
  );
  const yCoord = Math.max(
    y.yScale.range()[1],
    Math.min(lineFormula.y(currentX), y.yScale.range()[0])
  );

  return { x: lineFormula.x(yCoord), y: yCoord };
};
export interface DrawLineProps {
  context: CanvasRenderingContext2D;
  domain: Domain;
  options: CreateChartOptionsWithColors;
  x: XAxis;
  y: YAxis;
  lastDataIdx: number;
  timeseries: Timeseries;
  lineColorOverride: string;
  tsByTime: ChartAssetData['tsByTime'];
  primaryAsset: ChartAssetData;
  getYCoordinate: (value: number) => number;
  domainTimeSeries: DomainTimeSeries;
}

//this function is used to draw multiple line charts
//the x coordinates are based from the primary asset data point("time" property)
//the y coordinated are based from the primary asset data point("time" property) but with the "close" value from the current asset
//example:
//primary asset data time range is ["2023-11-02T00:00:00Z","2023-11-03T00:00:00Z","2023-11-05T00:00:00Z"]
//secondary asset data time range is ["2023-11-02T00:00:00Z","2023-11-03T00:00:00Z","2023-11-04T00:00:00Z"]
//so as the result secondary chart will be plotted only at "2023-11-02T00:00:00Z", "2023-11-03T00:00:00Z" as primary chart doesn't have "2023-11-04T00:00:00Z"
// eslint-disable-next-line complexity
export const drawLine = ({
  lineColorOverride,
  timeseries,
  options,
  context,
  domain,
  x,
  y,
  getYCoordinate,
  lastDataIdx,
  tsByTime,
  primaryAsset,
  domainTimeSeries,
}: DrawLineProps) => {
  const primaryTs = primaryAsset.ts;
  const roundedDomain: Domain = [Math.ceil(domain[0]), Math.ceil(domain[1])];
  const bis = bisector((d: TimeSeriesItem) => d.time);
  const y1Index = bis.left(timeseries, primaryTs[roundedDomain[0]].time);
  const startIdx =
    timeseries[y1Index] &&
    (primaryAsset.tsByTime.get(timeseries[y1Index].time) ??
      bis.left(primaryTs, timeseries[y1Index].time));

  if (startIdx === undefined || startIdx > domain[1]) {
    return;
  }

  const lastIdx = Math.min(lastDataIdx, domain[1]);
  const isStartSame =
    domain[0] === roundedDomain[0] &&
    primaryTs[roundedDomain[0]].time === timeseries[y1Index].time;
  const y2Index = isStartSame ? y1Index : Math.max(0, y1Index - 1);
  const y1 = getYCoordinate(timeseries[y1Index].close);
  const y2 = getYCoordinate(timeseries[y2Index].close);

  context.strokeStyle = lineColorOverride ?? options.colors.line.default;
  context.lineWidth = 2;
  context.beginPath();

  //integer domain
  if (domain[0] === roundedDomain[0] || y1 === y2) {
    context.moveTo(y1Index === y2Index ? x.xScale(startIdx) : 0, y1);
  } else {
    //for decimal indexes in domain we need to calculate first y point for that decimal index
    //canonical of line is used to get the x for given y coordinate and vise versa
    const { x: xCoord, y: yCoord } = getDecimalIndexDomainPoint({
      x,
      y,
      currentX: 0,
      startPointIdx: roundedDomain[0],
      endPointIdx: Math.floor(domain[0]),
      y1,
      y2,
    });
    context.moveTo(xCoord, yCoord);
  }

  for (let i = isStartSame ? startIdx + 1 : startIdx; i < lastIdx; i++) {
    if (tsByTime.get(primaryTs[i].time) !== undefined) {
      context.lineTo(
        x.xScale(i),
        getYCoordinate(
          timeseries[tsByTime.get(primaryTs[i].time) as number].close
        )
      );
    }
  }

  const y3Index = domainTimeSeries.endIndex;
  const isEndSame =
    domain[1] === roundedDomain[1] &&
    timeseries[y3Index] &&
    primaryTs[Math.floor(domain[1])].time === timeseries[y3Index].time;
  const y4Index = isEndSame
    ? y3Index
    : Math.min(timeseries.length - 1, y3Index + 1);

  if (!timeseries[y3Index] || !timeseries[y4Index]) {
    context.stroke();
    return;
  }

  const y3 = getYCoordinate(timeseries[y3Index].close);
  const y4 = getYCoordinate(timeseries[y4Index].close);

  //similar logic as for the start point
  if (domain[1] === roundedDomain[1] || y3 === y4) {
    const index =
      y3Index === y4Index
        ? primaryAsset.tsByTime.get(timeseries[y3Index].time) ??
          roundedDomain[1]
        : domain[1];
    context.lineTo(x.xScale(index), y3);
  } else {
    const { x: xCoord, y: yCoord } = getDecimalIndexDomainPoint({
      x,
      y,
      currentX: x.xScale(domain[1]),
      startPointIdx: Math.floor(domain[1]),
      endPointIdx: roundedDomain[1],
      y1: y3,
      y2: y4,
    });
    context.lineTo(xCoord, yCoord);
  }

  context.stroke();

  if (options.config.lineGradient) {
    drawGradientArea({
      context,
      options,
      color: lineColorOverride,
      gradientStops: options.config.lineGradient,
    });
  }
};
