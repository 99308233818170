import { typographyV2 } from '@toggle/design-system';
import styled from 'styled-components';

export const CogSearchBreakdownRoot = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  padding-bottom: 20px;
`;

export const Divider = styled.div`
  border-top: 1px var(--border-default) solid;
  margin-bottom: 20px;
`;

export const Title = styled.p`
  ${typographyV2.bodyBase}
  color: var(--text-default);
  margin-bottom: 20px;
`;

export const FileLink = styled.a`
  display: flex;
  align-items: center;
  gap: 8px;
  max-width: 100%;
  border-radius: 4px;
  cursor: pointer;
  background-color: transparent;
  border: 1px solid var(--border-default);
  padding: 6px 12px;

  &:hover {
    border-color: var(--border-hover);
    background-color: var(--surface-hover);
  }
`;

export const HTMLIcon = styled.img`
  height: 16px;
  width: 16px;
`;

export const TitleText = styled.span`
  ${typographyV2.bodySm}
  font-weight: 500;
  color: var(--text-default);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
