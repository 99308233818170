import React, { HTMLAttributes } from 'react';

import {
  SvgIconNames,
  SvgIconsMap,
} from '~/design-tokens/iconography/SvgIcons';

export interface IconProps extends HTMLAttributes<SVGSVGElement> {
  iconName: SvgIconNames;
  className?: string;
  size?: number;
  viewBox?: string;
  fillColor?: string;
}

export const Icon = ({
  iconName,
  className,
  size = 40,
  viewBox = '0 0 40 40',
  fillColor = 'white',
  ...rest
}: IconProps) => {
  const SVGIcon = SvgIconsMap[iconName];
  return (
    <>
      {SVGIcon ? (
        <SVGIcon
          width={size}
          height={size}
          viewBox={viewBox}
          fill={fillColor}
          className={className}
          data-testid={`${iconName}-icon`}
          xmlns="http://www.w3.org/2000/svg"
          {...rest}
        />
      ) : null}
    </>
  );
};
