import {
  Alert,
  borderRadius,
  elevation,
  typographyV2,
} from '@toggle/design-system';
import styled, { css } from 'styled-components';

export const MessageGroup = styled.div``;

export const Message = styled.div<{
  $isActive: boolean;
  $questionHeight?: number;
  $isUserMessage?: boolean;
}>`
  ${borderRadius.r3};
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0 auto 16px;
  max-width: 790px;
  width: 100%;
  border: 1px solid transparent;
  padding: ${({ $isUserMessage }) => ($isUserMessage ? '16px;' : '8px')};
  background-color: ${({ $isUserMessage }) =>
    $isUserMessage ? 'var(--surface-default)' : 'var(--background-default)'};

  &:focus-visible {
    outline: 1px solid var(--border-focus);
  }
  &:hover {
    border: 1px solid var(--border-hover);
  }

  ${({ $questionHeight }) =>
    $questionHeight &&
    css`
      scroll-margin-top: calc(20px + ${$questionHeight}px);
    `}

  ${({ $isActive }) =>
    $isActive &&
    css`
      border: 1px solid var(--border-default);
      ${elevation.strong};
    `}
`;

export const ReceivedMessage = styled.div`
  padding: 20px;
  background-color: var(--surface-pressed);
  ${borderRadius.r2}
`;

export const UserMessageText = styled.p`
  ${typographyV2.bodyBase};
  color: var(--text-default);
`;

export const ButtonContainer = styled.div<{ $messageMatch: boolean }>`
  display: flex;
  justify-content: space-between;
  ${({ $messageMatch }) =>
    $messageMatch
      ? css`
          margin-top: 16px;
          max-height: 100px;
          opacity: 1;
        `
      : css`
          max-height: 0px;
          opacity: 0;
          pointer-events: none;
        `};
  transition: max-height 0.25s ease, opacity ease 0.2s;
`;

export const ActionButtons = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const StyledAlert = styled(Alert)`
  width: 100%;
  margin-bottom: 16px;
`;
