import styled, { keyframes } from 'styled-components';

import { typographyV2 } from '~/design-tokens/typography/Typography.styles';

export const RootSVG = styled.svg`
  color: var(--text-soft);
`;

export const AxisLine = styled.line`
  stroke: var(--border-default);
`;

export const AxisText = styled.text`
  ${typographyV2.bodyXs};
  fill: var(--text-soft);
`;

const slideIn = keyframes`
  from {
    transform: translateY(5%);
  }
  to {
    transform: translateY(0);
  }
`;

export const Circle = styled.circle`
  stroke: #fff;
  stroke-width: 1px;
  fill: var(--background-default);
`;

export const Rect = styled.rect<{ hasProfit: boolean }>`
  fill: ${props =>
    props.hasProfit ? 'var(--viz-bullish)' : 'var(--viz-bearish)'};
`;

export const AnimatedGroup = styled.g`
  animation: ${slideIn} 0.3s cubic-bezier(0.25, 0.5, 0.5, 1.5); ;
`;
