import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SplashLoader, SplashLoaderItem } from './partials';
import * as S from './SplashScreen.styles';
import { SplashScreenData } from './utils/splashScreenConstants';

const TIME_PER_ITEM = 500;

function getDelay(state: LoaderState, idx: number) {
  let delay = 0;

  if (idx > 0) {
    const prev = state[idx - 1];
    delay = prev.duration + prev.delay;
  }

  return delay;
}

function getLoaderState(data: SplashScreenData[]) {
  return data.reduce((state, next, idx) => {
    const textCount = next.values?.length ?? 0;
    const duration = next.durationMs || textCount * TIME_PER_ITEM;
    const delay = getDelay(state, idx);
    state[idx] = {
      completed: false,
      key: !idx ? next.values?.[0] : '',
      label: next.label,
      duration,
      delay,
      isFrozen: false,
    };
    return state;
  }, {} as LoaderState);
}

interface LoaderState {
  [key: number]: {
    completed: boolean;
    isFrozen: boolean;
    key?: string;
    label: string;
    duration: number;
    delay: number;
  };
}

export interface SplashScreenProps {
  data?: SplashScreenData[];
  tagDisplayValue?: string;
  dataIsLoading: boolean;
  onLoadCompleted?: () => void;
  showPrimaryLoader?: boolean;
  showImage?: boolean;
  showCompleteLabel?: boolean;
}

export const SplashScreen = ({
  dataIsLoading,
  data = [],
  tagDisplayValue,
  onLoadCompleted,
  showPrimaryLoader = true,
  showImage = true,
  showCompleteLabel = true,
}: SplashScreenProps) => {
  const { t } = useTranslation('analyze');

  const [loaderState, setLoaderState] = useState(getLoaderState(data));
  let { current: currentBar } = useRef(0);
  let { current: textIdx } = useRef(1);
  const interval = useRef<ReturnType<typeof setInterval>>();

  function getNewState(
    state: LoaderState,
    currentBar: number,
    completed: boolean,
    isLastTextInData: boolean
  ): LoaderState {
    const values = data[currentBar].values;
    const key = values?.[textIdx];

    let newState = {
      ...state,
      [currentBar]: {
        ...state[currentBar],
        key,
      },
    };

    if (completed) {
      newState = {
        ...newState,
        [currentBar - 1]: {
          ...state[currentBar - 1],
          completed: true,
        },
      };
    } else if (isLastTextInData) {
      newState = {
        ...state,
        [currentBar]: {
          ...newState[currentBar],
          isFrozen: true,
        },
      };
    }

    return newState;
  }

  function updateLoaderText() {
    const values = data[currentBar].values;
    const completed = textIdx === values?.length;
    if (completed) {
      currentBar++;
      textIdx = 0;
    }

    const lastElement = data[data.length - 1];
    const isLastTextInData =
      !!lastElement.values &&
      textIdx === lastElement.values.length - 1 &&
      currentBar === data.length - 1;

    setLoaderState(state =>
      getNewState(state, currentBar, completed, isLastTextInData)
    );

    textIdx += 1;
  }

  function forceAnimationEnd() {
    setLoaderState(state =>
      Object.values(state).reduce(
        (l, next, idx) => ({
          ...l,
          [idx]: {
            ...next,
            delay: 0,
            duration: 0,
            isFrozen: false,
            completed: true,
          },
        }),
        {}
      )
    );

    if (onLoadCompleted) {
      setTimeout(() => {
        onLoadCompleted();
      });
    }
  }

  function launchInterval() {
    return setInterval(() => {
      if (
        !(
          textIdx === data[data.length - 1].values?.length &&
          currentBar === data.length - 1
        )
      ) {
        updateLoaderText();
      } else {
        if (interval.current) {
          clearInterval(interval.current);
        }
      }
    }, TIME_PER_ITEM);
  }

  useEffect(() => {
    if (dataIsLoading && !interval.current) {
      interval.current = launchInterval();
    } else if (!dataIsLoading && interval.current) {
      forceAnimationEnd();
    }

    return () => {
      if (interval.current) {
        clearInterval(interval.current);
      }
    };
  }, [dataIsLoading]);

  return (
    <S.SplashScreen data-testid="splash-screen">
      <S.Loaders>
        {tagDisplayValue && (
          <S.Heading>
            {t('analyze:splashScreen.analyzing', { tag: tagDisplayValue })}
          </S.Heading>
        )}
        {showPrimaryLoader ? (
          Object.values(loaderState).map(
            ({ label, key, duration, delay, completed, isFrozen }, idx) => (
              <SplashLoaderItem
                key={idx}
                t={t}
                entityLabel={key && t(`analyze:analysis.labels.${key}`)}
                duration={`${duration}ms`}
                delay={`${delay}ms`}
                label={t(`analyze:splashScreen.labels.${label}`)}
                isFrozen={isFrozen}
                completed={completed}
                showCompleteLabel={showCompleteLabel}
              />
            )
          )
        ) : (
          <SplashLoader />
        )}
      </S.Loaders>
      {showImage && (
        <S.Image>
          <img src="/app/assets/analyze/splash-loader.svg" alt="loader" />
        </S.Image>
      )}
    </S.SplashScreen>
  );
};
