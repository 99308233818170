import { deviceConstraints, typography } from '@toggle/design-system';
import styled from 'styled-components';

export const HeaderSection = styled.section`
  position: relative;

  @media ${deviceConstraints.tablet} {
    grid-area: feedHeader;
  }

  @media ${deviceConstraints.desktop} {
    width: 100%;
    margin: auto;
  }
`;

export const FilteredInsightsLabel = styled.p`
  margin-bottom: 20px;
  ${typography.dingo}

  span {
    color: var(--primary-color);
  }
`;
