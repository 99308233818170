import { config } from '~/config';
import { APIFetch } from '~/shared/services/api-fetch';
import { HttpMethods } from '~/shared/services/api-fetch/constants';
import { validateApiFetch } from '~/shared/services/api-fetch/validate-api-fetch/validateApiFetch';

import {
  AllPaymentsPayload,
  allPaymentsSchema,
  ClientSecretPayload,
  clientSecretPayload,
} from './payments-schema';

const {
  api: { root: apiRoot },
} = config;

const paymentsEndpoint = `${apiRoot}payment/v1/secret`;
const paymentsListEndpoint = `${apiRoot}payment/v1/payments`;

export const getClientSecret = () => {
  return validateApiFetch<ClientSecretPayload>({
    endpoint: paymentsEndpoint,
    schema: clientSecretPayload,
  });
};

export const postClientSecret = (clientSecret: string) => {
  return APIFetch(paymentsEndpoint, {
    method: HttpMethods.Post,
    body: JSON.stringify({
      client_secret: clientSecret,
    }),
  });
};

export const deleteClientSecret = (clientSecret: string) => {
  return APIFetch(paymentsEndpoint, {
    method: HttpMethods.Delete,
    body: JSON.stringify({
      client_secret: clientSecret,
    }),
  });
};

export const getAllPayments = async (signal?: AbortSignal) => {
  return validateApiFetch<AllPaymentsPayload>({
    endpoint: paymentsListEndpoint,
    schema: allPaymentsSchema,
    requestInfo: {
      signal,
    },
  });
};
