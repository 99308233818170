import { deviceConstraints, Icon, typography } from '@toggle/design-system';
import styled, { css } from 'styled-components';

interface StyledIconsProps {
  $size: 'md' | 'lg' | 'sm';
}

export const StyledIcon = styled(Icon).attrs<StyledIconsProps>(({ $size }) => {
  let size = 20;
  switch ($size) {
    case 'lg':
      size = 30;
      break;
    case 'sm':
      size = 15;
    default:
      break;
  }
  return { size };
})<StyledIconsProps>`
  vertical-align: middle;
  position: relative;
  top: -2px;
`;

export const ShareButtons = styled.div<{
  inOpenMode?: boolean;
  titleHidden?: boolean;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  &:hover {
    cursor: pointer;
  }

  ${({ inOpenMode, titleHidden }) =>
    (inOpenMode || titleHidden) &&
    css`
      > div:last-child {
        margin-right: 0;
      }

      ${titleHidden ? '> div' : '> div:not(:first-child)'} {
        color: var(--base--20);
        cursor: pointer;

        &:hover {
          color: var(--primary-color);
        }

        ${typography.koala};
      }
    `};
`;

export const ShareButton = styled.div<{ inOpenMode?: boolean }>`
  display: flex;
  flex: 0 1 100%;
  justify-content: flex-end;
  margin-right: 12px;
  align-items: center;

  ${({ inOpenMode }) =>
    inOpenMode &&
    css`
      span {
        margin-right: 8px;
      }

      &:hover {
        cursor: default;
      }
    `};
`;

export const ShareButtonText = styled.p<{ isUppercase?: boolean }>`
  margin-left: 8px;
  white-space: nowrap;
  ${typography.emu};

  ${({ isUppercase }) => isUppercase && 'text-transform: uppercase;'};
`;

export const CopyLink = styled.div`
  &:focus {
    outline: none;
  }
`;

export const AlertTooltip = styled.span`
  margin: 0 12px 0 4px;

  @media ${deviceConstraints.desktop} {
    margin: 0 16px 0 8px;
  }

  &:hover {
    svg {
      fill: var(--primary-color);
    }
  }
`;
