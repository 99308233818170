import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { appPaths } from '~/routes/app-paths';
import { NoWatchlist } from '~/shared/components/no-watchlist/NoWatchlist';

import { InsightsEmptyState } from '../insights-empty-state/InsightsEmptyState';
import { StatsCard } from '../stats-card/StatsCard';

interface EmptyStateProps {
  hasWatchListFilter: boolean;
  hasActiveTag: boolean;
}

export const EmptyState = ({
  hasWatchListFilter,
  hasActiveTag,
}: EmptyStateProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation('explore');

  return (
    <>
      <StatsCard />
      {hasWatchListFilter && !hasActiveTag ? (
        <NoWatchlist
          title={t('explore:noWatchlist.title')}
          content={t('explore:noWatchlist.content')}
          buttonContent={t('explore:noWatchlist.watchlistBtn')}
          imageAlt={t('explore:noWatchlist.connect')}
          handleOnClick={() => navigate(appPaths.myAccount.watchlist)}
        />
      ) : (
        <InsightsEmptyState />
      )}
    </>
  );
};
