import { typographyV2 } from '@toggle/design-system';
import styled, { css } from 'styled-components';

export const sharedAOWidgetCard = css`
  padding: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--border-soft);
  cursor: pointer;
  &:hover {
    background-color: var(--surface-hover);
  }
`;

export const CardText = styled.p`
  ${typographyV2.bodyBase}
`;

export const SpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CardImageWrapper = styled.div`
  margin-left: 12px;
  width: 24px;
`;
