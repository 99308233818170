import { z } from 'zod';

import { conditionSchema } from './compass-schema';

export const scenarioConditionsSchema = z.object({
  entity: z.string(),
  snake: z.string(),
  conditions: z.array(conditionSchema),
});

export const scenarioConditions = {
  getById: {
    path: (id: string) => `analysis-scenarios/v1/scenario/${id}`,
    schema: scenarioConditionsSchema,
  },
};

export type ScenarioConditions = z.infer<typeof scenarioConditionsSchema>;
