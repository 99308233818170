import styled, { css } from 'styled-components';

import { typography } from '../../design-tokens/typography/Typography.styles';
import { Icon } from '../icon/Icon';

export const ToggleContainer = styled.label`
  display: flex;
  gap: 12px;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
`;

export const ToggleInput = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  transition: all 0.3s ease;
`;

export const Text = styled.span`
  ${typography.emu}
  color: var(--base--0);
`;

interface SliderIconType {
  $isVisible: boolean;
  $isChecked: boolean;
}

export const SliderIcon = styled(Icon)<SliderIconType>`
  transition: 0.4s;
  opacity: ${({ $isVisible }) => ($isVisible ? `1` : `0`)};
`;

export const IconContainer = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

export const Slider = styled.span<{
  $isChecked: boolean;
  $size: 'medium' | 'small';
}>`
  position: relative;
  display: inline-flex;
  align-items: center;
  height: ${props => (props.$size === 'medium' ? '24px' : '18px')};
  width: ${props => (props.$size === 'medium' ? '44px' : '32px')};
  cursor: pointer;
  background-color: var(--base--80);
  border-radius: 18px;
  transition: 0.4s;

  ${IconContainer} {
    transform: ${props =>
      props.$isChecked ? 'translateX(100%)' : 'translateX(3px)'};
    transition: 0.4s;
    height: ${props => (props.$size === 'medium' ? '20px' : '14px')};
    width: ${props => (props.$size === 'medium' ? '20px' : '14px')};
    border-radius: 50%;
    background-color: var(--base--0);
  }

  ${({ $isChecked }) =>
    $isChecked &&
    css`
      background-color: var(--primary-color);
    `}
`;
