import { formatDateToLocale, formatDecimal, Formats } from '@toggle/helpers';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Optional } from '~/declarations/standard';
import { ArticleHitRatio, ArticleV3 } from '~/declarations/toggle-api';
import { useNavigator } from '~/stores/use-navigator/useNavigator';

import { createSuffixFromMethod } from '../../article-constants';
import * as S from './ArticleSidebarSummary.styles';

interface ArticleSidebarSummaryProps {
  article: Optional<ArticleV3>;
}

function formatTarget(media: number, method: string) {
  const suffix = createSuffixFromMethod(method);
  const value = `${Number.parseFloat(media.toString()).toFixed(2)}${suffix}`;
  return <>{value}</>;
}

function formatDirection(direction: string) {
  return (
    <S.IconWrapper>
      <S.DirectionArrow
        size={16}
        iconName={direction === 'Bullish' ? 'ArrowUp' : 'ArrowDown'}
      />
      {direction}
    </S.IconWrapper>
  );
}

export function ArticleSidebarSummary({ article }: ArticleSidebarSummaryProps) {
  const { t, i18n } = useTranslation('article');
  const navigatorCountry = useNavigator(state => state.country);

  if (!article) {
    return null;
  }

  const {
    default_method,
    entity,
    direction,
    stars,
    hit_rate_out_of_sample,
    hit_ratio,
    last_value,
    last_value_reference_date,
    median_return,
  } = article;

  /* i18next-extract-mark-context-next-line ["1w", "2w", "1m", "3m", "6m"] */
  const horizonNameTranslated = t('article:horizons.horizon_name', {
    context: getHorizonName(hit_ratio).toLowerCase(),
  });

  /* i18next-extract-mark-context-next-line ["bullish", "bearish"] */
  const directionName = t('article:summary.direction', {
    context: direction.tag_name?.toLowerCase(),
  });

  const lastPriceString = t('article:summary.lastPrice', {
    lastPrice: formatDecimal(i18n.language, Number(last_value)),
    date: formatDateToLocale(
      navigatorCountry,
      last_value_reference_date,
      Formats['dd MMM, yyyy']
    ),
  });

  const summaryItems = [
    { key: S.SummaryItemKeys.Name, value: entity.tag_name },
    { key: S.SummaryItemKeys.Direction, value: formatDirection(directionName) },
    { key: S.SummaryItemKeys.Star, value: stars },
    {
      key: S.SummaryItemKeys.Target,
      value: formatTarget(median_return, default_method),
    },
    { key: S.SummaryItemKeys.Horizon, value: horizonNameTranslated },
    {
      key: S.SummaryItemKeys.Rate,
      value: `${formatDecimal(i18n.language, hit_rate_out_of_sample)}%`,
    },
    { key: S.SummaryItemKeys.Price, value: lastPriceString },
  ];

  return (
    <S.ArticleSidebarSummary>
      <S.ArticleSidebarSummaryHeader>
        {t('article:summary.title')}
      </S.ArticleSidebarSummaryHeader>
      <S.ArticleSidebarSummaryList>
        {summaryItems.map(({ key, value }) => (
          <S.ArticleSidebarSummaryItem
            key={key}
            $itemKeys={key}
            data-testid={`summary-item-${key}`}
          >
            <S.ArticleSidebarSummaryItemName>
              {t(`article:summary.${key}`)}
            </S.ArticleSidebarSummaryItemName>
            <S.ArticleSidebarSummaryItemContent>
              {key === 'star' && (
                <S.ArticleSidebarSummaryItemContentIcon
                  iconName="Rating"
                  size={19}
                />
              )}

              {value}
            </S.ArticleSidebarSummaryItemContent>
          </S.ArticleSidebarSummaryItem>
        ))}
      </S.ArticleSidebarSummaryList>
    </S.ArticleSidebarSummary>
  );
}

function getHorizonName(ArticleHitRatios: ArticleHitRatio[]): string {
  const bestArticleHitRatio = ArticleHitRatios.find(
    ArticleHitRatio => ArticleHitRatio.is_best
  );

  if (bestArticleHitRatio) {
    return bestArticleHitRatio.horizon_name;
  }

  return '';
}
