import { customScrollMinimal } from '@toggle/design-system';
import styled from 'styled-components';

export const ChatRoot = styled.div`
  display: grid;
  grid-template-columns: auto 500px;
  height: 100%;
  background-color: var(--background-default);
`;

export const ChatSection = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  flex: 2;
`;

export const Content = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 16px;
  ${customScrollMinimal}
`;

export const Footer = styled.div`
  padding: 16px 16px 0 16px;
  background: var(--background-soft);
  border-top: 1px solid var(--border-soft);
  box-shadow: 0px -2px 3px rgba(0, 0, 0, 0.3),
    0px -6px 10px 4px rgba(0, 0, 0, 0.15);
`;

export const WidgetSection = styled.div`
  display: flex;
  flex: 1;
  background-color: var(--background-soft);
  border-left: 1px solid var(--border-soft);
  overflow-y: auto;
  ${customScrollMinimal}
`;
