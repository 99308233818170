import { getOffset } from '@toggle/helpers';

import { User, UserAddress, UserAddressPayload } from '~/api/user/user-schema';
import {
  getUserAddress,
  getUserData,
  getUserPortfolioSettings,
  putOnboardingState,
  putUserAddress,
  putUserData,
  putUserPortfolioSettings,
  putUserTimezone,
} from '~/api/user/user-service';
import {
  PortfolioSettings,
  UpdatePortfolioSettingsPayload,
} from '~/declarations/toggle-api.d';
import { OnboardingStates } from '~/declarations/toggle-api-enums';
import { APIResponse } from '~/shared/services/api-fetch';
import { FetchError } from '~/shared/services/fetch/FetchErrors';
import { Tracking } from '~/shared/services/tracking';
import { gaPortfolioSendDefaultCurrency } from '~/shared/utils/ganalytics';
import { usePortfolio } from '~/stores/use-portfolio/usePortfolio';

import { create } from '../create-store/createStore';

interface UserStore {
  user?: User;
  address?: UserAddress;
  portfolioSettings: PortfolioSettings;
  loading: boolean;
  error: boolean;
  initialize: () => Promise<void>;
  updateOnboardingState: (state: OnboardingStates) => Promise<void>;
  updateUser: (user: Partial<User>) => Promise<void>;
  refreshUser: () => Promise<void>;
  getPortfolioSettings: () => Promise<void>;
  updatePortfolioSettings: (
    portfolioUpdate: UpdatePortfolioSettingsPayload
  ) => Promise<void>;
  updateUserAddress: (address: UserAddressPayload) => Promise<void>;
  isAuthenticated: () => boolean;
}

const syncTimeZone = async (tzName: string, tzOffset: number) => {
  try {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const offset = getOffset();
    if (tzName !== timezone || tzOffset !== offset) {
      const result = await putUserTimezone({
        name: timezone,
        offset,
      });
      if (result instanceof Error) {
        throw result;
      }
    }
  } catch (error) {
    Tracking.captureException(error as Error);
  }
};

const getUserAddressData = async () => {
  try {
    const response = await getUserAddress();
    return response;
  } catch (error) {
    return undefined;
  }
};

export const useUser = create<UserStore>((set, get) => ({
  user: undefined,
  address: undefined,
  portfolioSettings: {
    reportingCurrency: null,
  },
  loading: false,
  error: false,
  initialize: async () => {
    set({ loading: true });
    const userData = await getUserData();
    const [address] = await Promise.all([
      getUserAddressData(),
      get().getPortfolioSettings(),
    ]);

    if (userData instanceof Error) {
      set({ error: true, loading: false });
    } else {
      syncTimeZone(userData.tz_name, userData.tz_offset);
      set({
        address,
        user: userData,
        loading: false,
      });
    }
  },
  refreshUser: async () => {
    const userData = await getUserData();
    if (userData instanceof Error) {
      set({ error: true, loading: false });
    } else {
      set({
        user: {
          ...(get().user as User),
          ...userData,
        },
        loading: false,
      });
    }
  },
  updateOnboardingState: async (onboardingState: OnboardingStates) => {
    const res = await putOnboardingState(onboardingState);
    if (res instanceof Error) {
      set({ error: true, loading: false });
    } else {
      set({
        user: {
          ...(get().user as User),
          onboarding_state: onboardingState,
        },
      });
    }
  },
  updateUser: async (dataToUpdate: Partial<User>) => {
    const { ...userData } = get().user as User;
    const updated = await putUserData({
      ...userData,
      ...dataToUpdate,
    });
    if (!(updated instanceof Error)) {
      set(state => ({
        user: {
          ...(state.user as User),
          ...updated,
        },
      }));
    }
  },
  getPortfolioSettings: async () => {
    try {
      const portfolioSettings = await getUserPortfolioSettings();
      set({
        portfolioSettings: {
          ...get().portfolioSettings,
          reportingCurrency: portfolioSettings,
        },
      });
    } catch (error) {
      if (error instanceof FetchError) {
        if (error.status !== APIResponse.NOT_FOUND) {
          Tracking.captureException(error as Error);
        }
      }
    }
  },
  updatePortfolioSettings: async portfolioUpdate => {
    const portfolioSettings = await putUserPortfolioSettings(portfolioUpdate);
    if (!(portfolioSettings instanceof Error)) {
      gaPortfolioSendDefaultCurrency(portfolioSettings.currency);
    }
    set({
      portfolioSettings: {
        ...get().portfolioSettings,
        reportingCurrency:
          portfolioSettings instanceof Error ? null : portfolioSettings,
      },
    });

    usePortfolio.getState().initialize();
  },
  isAuthenticated: () => !!get().user,
  updateUserAddress: async (address: UserAddressPayload) => {
    const response = await putUserAddress(address);
    set({ address: response });
  },
}));
