import React from 'react';
import { StoreApi } from 'zustand';

import { Entity } from '~/api/entities/entity-schema';
import { MODAL_TYPES } from '~/global/paywall/services/modal-types/modalTypes';
import { usePaywallStore } from '~/global/paywall/stores/usePaywallStore';
import {
  ApiFetchResponse,
  APIResponse,
  HttpError,
} from '~/shared/services/api-fetch';
import { FetchError } from '~/shared/services/fetch/FetchErrors';
import { gaWatchlistHitLimit } from '~/shared/utils/ganalytics';
import { ToastPosition, useToasts } from '~/stores/use-toasts/useToasts';
import { WatchlistAmountAlert } from '~/stores/use-user-entities/watchlist-alerts/WatchlistAmountAlert';

import { create } from '../create-store/createStore';
import { fetchUserEntities } from './services/user-entities';
import { addTag, deleteTag, Tag } from './services/watchlist-services';

export const SUGGESTED_WATCHLIST_LENGTH = 151;
export const ALERT_AMOUNT_TOAST_ID = 'watchlist-amount-toast-id';

export enum WATCHLIST_ACTIONS {
  REMOVE = 'remove',
  ADD = 'add',
}

interface Entities {
  portfolios: Entity[];
  watchlist: Entity[];
}

interface Watchlist {
  alertDismissed: boolean;
  addTag: (tag: string) => Promise<Tag | Error>;
  removeTag: (tag: string) => Promise<Error | Entity[]>;
  checkIsInWatchlist: (tag: string) => boolean;
}

interface UserEntitiesStore {
  entities: Entities;
  portfolios?: [];
  watchlist: Watchlist;
  init: () => Promise<void>;
}

const init = async (set: StoreApi<UserEntitiesStore>['setState']) => {
  const { watchlist, portfolios } = await fetchUserEntities();
  set({
    entities: { watchlist, portfolios },
  });
};

const handleWatchlistAdd = (
  get: StoreApi<UserEntitiesStore>['getState'],
  set: StoreApi<UserEntitiesStore>['setState'],
  response: ApiFetchResponse<Tag | HttpError>,
  entityTag: string
) => {
  const { showToast } = useToasts.getState();
  const { openModal } = usePaywallStore.getState();

  if (
    response instanceof FetchError &&
    response?.status === APIResponse.TOO_MANY_REQUESTS
  ) {
    gaWatchlistHitLimit(entityTag, 'watchlist-button');
    openModal(MODAL_TYPES.WATCHLIST_LIMIT);
  }

  if (
    get().entities.watchlist.length > SUGGESTED_WATCHLIST_LENGTH &&
    !get().watchlist.alertDismissed
  ) {
    showToast({
      id: ALERT_AMOUNT_TOAST_ID,
      hideToast: false,
      position: ToastPosition.TopRight,
      content: (
        <WatchlistAmountAlert
          onClose={() =>
            set(state => ({
              watchlist: { ...state.watchlist, alertDismissed: true },
            }))
          }
        />
      ),
    });
  }
};

const handleWatchlistRemove = (
  get: StoreApi<UserEntitiesStore>['getState']
) => {
  const { removeToast, toastExists } = useToasts.getState();
  if (
    get().entities.watchlist.length <= SUGGESTED_WATCHLIST_LENGTH &&
    toastExists(ALERT_AMOUNT_TOAST_ID)
  ) {
    removeToast(ALERT_AMOUNT_TOAST_ID);
  }
};

export const useUserEntities = create<UserEntitiesStore>((set, get) => ({
  entities: { watchlist: [], portfolios: [] },
  init: () => init(set),
  watchlist: {
    tags: [],
    alertDismissed: false,
    addTag: async (tag: string) => {
      const response = await addTag(tag);
      const { watchlist } = await fetchUserEntities();
      if (
        !(response instanceof Error || response instanceof HttpError) &&
        watchlist.length
      ) {
        set(state => ({
          entities: { ...state.entities, watchlist },
        }));
      }
      handleWatchlistAdd(get, set, response, tag);
      return response;
    },
    removeTag: async (tag: string) => {
      const response = await deleteTag(tag);
      if (response instanceof Error) {
        return response;
      }
      const filteredEntity = get().entities.watchlist.filter(
        t => t.tag !== tag
      );
      set(state => ({
        entities: {
          ...state.entities,
          watchlist: filteredEntity,
        },
      }));
      handleWatchlistRemove(get);
      return filteredEntity;
    },
    checkIsInWatchlist: (tagToCheck: string) =>
      get().entities.watchlist.some(e => e.tag === tagToCheck),
  },
}));
