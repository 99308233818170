import { z } from 'zod';

import { AssetSubClassCode } from './entity-constants';

export type TSMeta = z.infer<typeof tsMetaSchema>;
export const tsMetaSchema = z.object({
  snake: z.string(),
  name: z.string(),
  entity: z.string(),
  currency: z.string(),
  latest_date: z.string().datetime(),
  latest_price: z.number(),
  ticker: z.string(),
});

const fieldSchema = z.object({
  name: z.string(),
});

const tsResultSchema = z.object({
  fields: z.array(fieldSchema),
  primaryKey: z.array(z.string()),
});

export type TSDatum = z.infer<typeof datumSchema>;
const datumSchema = z.object({
  index: z.string(),
  value: z.number(),
});

const resultSchema = z.object({
  schema: tsResultSchema,
  data: z.array(datumSchema),
});

export type TimeSeriesData = z.infer<typeof timeSeriesDataSchema>;
export const timeSeriesDataSchema = z.object({
  meta: tsMetaSchema,
  result: resultSchema,
});

export enum FeatureIds {
  TLI = 'TLI',
  RFI = 'RFI',
  PI = 'PI',
  TI = 'TI',
}

export const featureIds = [
  FeatureIds.TLI,
  FeatureIds.RFI,
  FeatureIds.PI,
  FeatureIds.TI,
];

export enum DisplayFormat {
  CURRENCY = 'currency',
  PERCENT = 'perc',
  NUMBER = 'number',
  BOOLEAN = 'bool',
}

const searchSchema = z.object({
  keywords: z.array(z.string()).optional().nullable(),
  rank: z.number(),
});

export type NameLanguages = z.infer<typeof nameLanguagesSchema>;
const nameLanguagesSchema = z.object({
  english: z.string(),
  korean: z.string(),
  japanese: z.string(),
  kanji: z.string(),
  chinese: z.string(),
});

export enum MethodSubClass {
  Unspecified,
  Activity,
  Beta,
  Confidence,
  Consumer,
  Correlation,
  EconomicGrowthGauge,
  EconomicInflationGauge,
  Employment,
  EntryPoint,
  EquityConsensusTrend,
  EquityConsensusTrendSecondary,
  EquityFundamentals,
  EquityFundamentalEstimates,
  EquityGrowthGauge,
  EquityYield,
  Event,
  FactorLoading,
  FinancialConditions,
  Flows,
  Fundamentals,
  Geopolitical,
  Government,
  Growth,
  Housing,
  Income,
  LeadingIndicator,
  MainMarketPrice,
  MainYield,
  MarketData,
  MarketPrice,
  Metadata,
  Momentum,
  MoneySupply,
  OptionPricing,
  Pmi,
  Population,
  Positioning,
  PositioningData,
  Price,
  PriceIndex,
  Reserves,
  Seasonality,
  Sentiment,
  SentimentDetail,
  TechnicalAnalysis,
  TechnicalAnalysisData,
  Trade,
  Valuation,
  Volatility,
  VolumeAnalysis,
  Yield,
  OnChainMetrics,
}

export const fundamentalMethods = [
  MethodSubClass.Beta,
  MethodSubClass.EquityConsensusTrend,
  MethodSubClass.EquityConsensusTrendSecondary,
  MethodSubClass.Fundamentals,
  MethodSubClass.Growth,
  MethodSubClass.MainYield,
  MethodSubClass.EquityFundamentals,
  MethodSubClass.EquityFundamentalEstimates,
  MethodSubClass.EquityGrowthGauge,
  MethodSubClass.EquityYield,
  MethodSubClass.Valuation,
  MethodSubClass.Yield,
  MethodSubClass.OnChainMetrics,
];

export const macroMethods = [
  MethodSubClass.Activity,
  MethodSubClass.Confidence,
  MethodSubClass.Consumer,
  MethodSubClass.EconomicGrowthGauge,
  MethodSubClass.EconomicInflationGauge,
  MethodSubClass.Employment,
  MethodSubClass.Event,
  MethodSubClass.FinancialConditions,
  MethodSubClass.Geopolitical,
  MethodSubClass.Government,
  MethodSubClass.Housing,
  MethodSubClass.Income,
  MethodSubClass.LeadingIndicator,
  MethodSubClass.MoneySupply,
  MethodSubClass.Pmi,
  MethodSubClass.Population,
  MethodSubClass.Reserves,
];

export const technicalsMethods = [
  MethodSubClass.Correlation,
  MethodSubClass.EntryPoint,
  MethodSubClass.FactorLoading,
  MethodSubClass.Flows,
  MethodSubClass.MainMarketPrice,
  MethodSubClass.MarketData,
  MethodSubClass.MarketPrice,
  MethodSubClass.Momentum,
  MethodSubClass.OptionPricing,
  MethodSubClass.Positioning,
  MethodSubClass.PositioningData,
  MethodSubClass.Price,
  MethodSubClass.PriceIndex,
  MethodSubClass.Seasonality,
  MethodSubClass.Sentiment,
  MethodSubClass.SentimentDetail,
  MethodSubClass.TechnicalAnalysis,
  MethodSubClass.TechnicalAnalysisData,
  MethodSubClass.Trade,
  MethodSubClass.Volatility,
  MethodSubClass.VolumeAnalysis,
];

export const indexesMethods = [
  AssetSubClassCode.EquityIndex,
  AssetSubClassCode.EquitySector,
  AssetSubClassCode.FutureEquity,
];

export const fxMethods = [AssetSubClassCode.MajorCross];

export const economicsMethods = [
  AssetSubClassCode.EcoCountry,
  AssetSubClassCode.EcoArea,
];

export const commoditiesMethods = [
  AssetSubClassCode.FutureEnergy,
  AssetSubClassCode.FutureFood,
  AssetSubClassCode.FutureGrains,
  AssetSubClassCode.FutureMetals,
  AssetSubClassCode.FutureSofts,
];

export const govBondsMethods = [
  AssetSubClassCode.FiSov,
  AssetSubClassCode.FutureBonds,
];

export const stocksMethods = [AssetSubClassCode.EquityStock];

export const groupedSubClassMap = {
  indexes: indexesMethods,
  fx: fxMethods,
  economics: economicsMethods,
  commodities: commoditiesMethods,
  govBonds: govBondsMethods,
  stocks: stocksMethods,
};

export type SnakeMeta = z.infer<typeof snakeMetaSchema>;
const thresholdSchema = z.object({
  good: z.number().optional(),
  neutral: z.number().optional(),
  bad: z.number().optional(),
});
export type SnakeMetaThreshold = z.infer<typeof thresholdSchema>;

export const snakeMetaSchema = z.object({
  active: z.boolean(),
  alified_last_timestamp: z.string().datetime(),
  before_last_timestamp: z.number(),
  before_last_value: z.number(),
  construct_scenarios: z.boolean(),
  currency: z.string(),
  data_entitlement: z.string(),
  data_source: z.string(),
  display_format: z.union([z.nativeEnum(DisplayFormat), z.literal('')]),
  entity: z.string(),
  field: z.string(),
  gated: z.boolean(),
  is_percentage: z.boolean(),
  last_timestamp: z.number(),
  last_value: z.number(),
  method_sub_class: z.nativeEnum(MethodSubClass),
  method_sub_class_group: z.number(),
  method_sub_class_name: z.string(),
  method_sub_class_sub_group: z.number(),
  name: nameLanguagesSchema,
  search: searchSchema,
  snake: z.string(),
  stationary: z.boolean(),
  threshold: thresholdSchema.optional(),
});
export const snakeMetaArrSchema = z.array(snakeMetaSchema);

export type SnakeMetaResponseV2 = z.infer<typeof snakeMetaResponseV2Schema>;
export const snakeMetaResponseV2Schema = z.record(z.string(), snakeMetaSchema);
