import {
  LegendMoreMenu,
  RESAMPLE_BUTTON_ID,
  ResampleIntervals,
  SeriesType,
  TimeSeriesItem,
} from '@toggle/chart';
import { Icon, Tooltip } from '@toggle/design-system';
import { useOutsideClick } from '@toggle/helpers';
import { Entity, SnakeMeta } from '@toggle/toggle';
import React, { RefObject, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { formatEntity } from '~/shared/hooks/use-entities/useEntities';

import { UseTurboChartReturn } from '../../hooks/use-turbo-chart/useTurboChart';
import { LegendPrice } from '../legend-price/LegendPrice';
import * as S from './LegendItem.styles';

export interface LegendItemProps {
  entity: Entity;
  resample: ResampleIntervals;
  isPrimary?: boolean;
  legendColor: string;
  lastTSPrice: TimeSeriesItem;
  isIndicator: boolean;
  multiplePlotted: boolean;
  isHidden: boolean;
  onRemoveItem: UseTurboChartReturn['removeActiveAsset'];
  onChangeTicker: (isPrimary: boolean, entity: Entity) => void;
  onHideAsset: (entity: Entity, isHidden: boolean) => void;
  onChangeResample: () => void;
  onChangeLineColor: (colorId: number) => void;
  colorArray: string[];
  canvasRef: RefObject<HTMLCanvasElement>;
  selectedSeriesType?: SeriesType;
  snakeMeta?: SnakeMeta;
}

export const LegendItem = ({
  entity,
  resample,
  isPrimary = false,
  legendColor,
  lastTSPrice,
  isIndicator,
  multiplePlotted,
  onRemoveItem,
  onChangeTicker,
  onHideAsset,
  onChangeResample,
  onChangeLineColor,
  colorArray,
  canvasRef,
  selectedSeriesType,
  isHidden,
  snakeMeta,
}: LegendItemProps) => {
  const { t } = useTranslation('turbo-chart');
  const [isHighlighted, setIsHighlighted] = useState(false);
  const [isMoreMenuOpen, setIsMoreMenuOpen] = useState(false);
  const legendItemRef = useRef(null);

  const handleChangeColour = (colorIndex: number) => {
    onChangeLineColor(colorIndex);
  };

  const mappedEntity = formatEntity(entity);

  const handleHideAsset = () => {
    onHideAsset(entity, !isHidden);
  };

  const isMinuteResample = ![
    ResampleIntervals.FifteenMinutes,
    ResampleIntervals.FiveMinutes,
    ResampleIntervals.OneMinute,
    ResampleIntervals.ThirtyMinutes,
  ].includes(resample);

  useOutsideClick({
    ref: legendItemRef,
    callback: () => setIsHighlighted(false),
    eventType: 'click',
  });

  return (
    <S.LegendItemRoot
      data-testid="legend-item"
      ref={legendItemRef}
      $isHighlighted={isMoreMenuOpen || isHighlighted}
    >
      <S.LegendItemDetails>
        <S.AssetContainer data-testid="asset-container">
          {isPrimary ? (
            <S.StyledLogo entity={entity} isDarkTheme />
          ) : (
            <S.ChartLineColorDot
              data-testid="chart-line-color-dot"
              $color={legendColor}
            />
          )}
          <S.Asset $isPrimary={isPrimary}>
            <Tooltip
              label={t('chart:changeData')}
              closeLabel={t('chart:tapToClose')}
              isTouchDevice={false}
              inPortal
            >
              <S.Button
                data-testid="legend-ticker"
                $isUpperCase
                onClick={() => onChangeTicker(isPrimary, entity)}
              >
                {isPrimary || isIndicator ? entity.name : entity.ticker}
              </S.Button>
            </Tooltip>
            {isPrimary && (
              <>
                <S.SeperatorDot iconName="Dot" size={6} />
                <Tooltip
                  label={t('chart:changeInterval')}
                  closeLabel={t('chart:tapToClose')}
                  isTouchDevice={false}
                  inPortal
                >
                  <S.Button
                    data-testid="legend-resample"
                    $isUpperCase={isMinuteResample}
                    onClick={onChangeResample}
                    id={RESAMPLE_BUTTON_ID}
                  >
                    {resample}
                  </S.Button>
                </Tooltip>
              </>
            )}
            {entity.exchange.code && (
              <>
                <S.SeperatorDot iconName="Dot" size={6} />
                <S.Label>{entity.exchange.code}</S.Label>
              </>
            )}
          </S.Asset>
        </S.AssetContainer>

        <S.MenuButtonContainer>
          {!isPrimary && (
            <LegendMoreMenu
              chartColor={legendColor}
              colorArray={colorArray}
              onColorSelect={handleChangeColour}
              onMenuOpen={open => setIsMoreMenuOpen(open)}
            />
          )}

          <Tooltip
            label={isHidden ? t('chart:show') : t('chart:hide')}
            closeLabel={t('chart:tapToClose')}
            isTouchDevice={false}
            inPortal
          >
            <S.MenuIconSpan>
              <Icon
                iconName={isHidden ? 'HidePassword' : 'ShowPassword'}
                size={12}
                onClick={handleHideAsset}
              />
            </S.MenuIconSpan>
          </Tooltip>
          {multiplePlotted && !isPrimary && (
            <Tooltip
              label={t('chart:remove')}
              closeLabel={t('chart:tapToClose')}
              isTouchDevice={false}
              inPortal
            >
              <S.MenuIconSpan>
                <Icon
                  iconName="Close"
                  size={12}
                  onClick={() => onRemoveItem(entity.default_snake)}
                />
              </S.MenuIconSpan>
            </Tooltip>
          )}
        </S.MenuButtonContainer>
      </S.LegendItemDetails>

      <S.PriceContainer>
        {snakeMeta && (
          <LegendPrice
            entity={mappedEntity}
            snakeMeta={snakeMeta}
            isChartHovered={false}
            isPrimary={isPrimary}
            legendColor={legendColor}
            shouldShowLivePrice={false}
            canvasRef={canvasRef}
            selectedSeriesType={selectedSeriesType}
            lastTSPrice={lastTSPrice}
          />
        )}
      </S.PriceContainer>
    </S.LegendItemRoot>
  );
};
