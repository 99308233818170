import styled from 'styled-components';

export const MidPath = styled.path`
  stroke: var(--base--0);
  stroke-dasharray: 2, 2;
  stroke-width: 2px;
  fill-opacity: 0;
`;

export const PlotPath = styled.path`
  stroke: var(--primary-color);
  stroke-width: 1px;
  fill-opacity: 0;
`;

export const Svg = styled.svg`
  width: 100%;
  height: 100%;
`;
