import { elevation } from '@toggle/design-system';
import styled, { css } from 'styled-components';

import { TooltipContainer } from '~/shared/components/tooltip-container/TooltipContainer';

import { HeaderNotificationTypeEnum } from './HeaderNotificationTooltip';

interface ArrowProps {
  type: HeaderNotificationTypeEnum;
}

interface StyledTooltipProps {
  $type: HeaderNotificationTypeEnum;
}

export const StyledTooltip = styled(TooltipContainer)<StyledTooltipProps>`
  padding: 0;
  width: 740px;
  max-width: unset;
  border: none;
  background: var(--surface-default);
  ${elevation.strong};
`;

export const Arrow = styled.span<ArrowProps>`
  position: absolute;
  top: -8px;
  &:before {
    content: '';
    left: -6.5px;
    width: 13px;
    height: 13px;
    position: absolute;
    transform: rotate(45deg);
    border-top-left-radius: 4px;
    ${({ type }) => {
      switch (type) {
        case HeaderNotificationTypeEnum.ERROR:
          return css`
            background: var(--failure--dark);
            border: solid var(--failure--default);
          `;
        default:
          return css`
            background: var(--copilot--dark);
            border: solid var(--copilot--default);
          `;
      }
    }}
    border-width: 1px 0 0 1px;
  }
`;
