import { AssetLogo } from '@toggle/design-system';
import { highlight } from '@toggle/helpers';
import { Entity } from '@toggle/toggle';
import React from 'react';

import * as S from './AssetCard.styles';

export interface AssetCardProps {
  entity: Entity;
  highlighted?: string;
}

export const AssetCard = ({ entity, highlighted }: AssetCardProps) => {
  const { ticker, name } = entity;

  return (
    <S.AssetCardRoot data-testid={`asset-card-${ticker}`}>
      <AssetLogo entity={entity} isDarkTheme />
      <S.Details>
        <S.Title>
          {highlighted ? highlight(ticker, highlighted) : ticker}
        </S.Title>
        <S.Subtitle>
          {highlighted ? highlight(name, highlighted) : name}
        </S.Subtitle>
      </S.Details>
    </S.AssetCardRoot>
  );
};
