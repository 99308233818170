import React, { FC, ReactNode, useState } from 'react';

import {
  POP_UP_KEYS,
  useRemoteStorage,
} from '~/shared/hooks/use-remote-storage';

import * as S from './BetaModal.styles';

interface BetaModalProps {
  title?: string;
  message: string;
  image?: ReactNode;
  btnLabel: string;
  checkBoxLabel: string;
  storageKey: POP_UP_KEYS;
  closeCallback: (isChecked: boolean) => void;
}

export const BetaModal: FC<BetaModalProps> = ({
  title,
  message,
  image,
  btnLabel,
  checkBoxLabel,
  storageKey,
  closeCallback,
}) => {
  const { storeItems, items } = useRemoteStorage(({ storeItems, items }) => ({
    storeItems,
    items,
  }));

  const [display, setDisplay] = useState(items && !items[storageKey]);
  const [noRepeat, setNoRepeat] = useState(false);

  const close = () => {
    closeCallback(noRepeat);
    try {
      if (noRepeat) {
        storeItems({ [storageKey]: 'active' });
      }
    } finally {
      setDisplay(false);
    }
  };

  const handleDoNotShow = () => setNoRepeat(noRepeat => !noRepeat);

  return (
    <S.StyledModal
      withClose
      isOpen={!!display}
      onClose={close}
      title={title}
      primaryBtnProps={{
        label: btnLabel,
        onClick: close,
      }}
    >
      <S.Container>
        <S.Main>
          <S.Content>{message}</S.Content>
          <S.CheckboxContainer>
            <S.StyledCheckbox
              name="modal"
              label={checkBoxLabel}
              checked={noRepeat}
              onChange={handleDoNotShow}
            />
          </S.CheckboxContainer>
        </S.Main>
        <S.ImageContainer>{image}</S.ImageContainer>
      </S.Container>
    </S.StyledModal>
  );
};
