import React from 'react';
import { useTranslation } from 'react-i18next';

import { CognitiveSearch } from '~/api/scenario-commander/scenario-commander-schema';

import { ChatMarkdown } from '../chat-markdown/ChatMarkdown';
import * as S from './CogSearchBreakdown.styles';

export interface CogSearchBreakdownProps {
  data: CognitiveSearch;
}

export const CogSearchBreakdown = ({ data }: CogSearchBreakdownProps) => {
  const { t } = useTranslation('common');

  return (
    <S.CogSearchBreakdownRoot data-testid="cog-search-breakdown">
      {data.summary && (
        <>
          <S.Title>{t('chat:cognitiveSearch.title')}</S.Title>
          <ChatMarkdown markdown={data.summary} />
        </>
      )}
      {!!data.metadata?.documents?.length && (
        <S.Container>
          {data.metadata.documents.map((doc, index) => (
            <S.FileLink
              key={index}
              data-testid="document-link"
              href={doc.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <S.HTMLIcon
                src="/app/assets/icons/html-icon.png"
                alt={t('chat:cognitiveSearch.htmlIconImgAlt')}
              />
              <S.TitleText>{doc.title}</S.TitleText>
            </S.FileLink>
          ))}
        </S.Container>
      )}
    </S.CogSearchBreakdownRoot>
  );
};
