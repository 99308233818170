import React, { FC } from 'react';

import { TooltipContainerProps } from '~/shared/components/tooltip-container/TooltipContainer';

import * as S from './DecimalTooltip.styles';

interface DecimalsTooltipProps
  extends Pick<TooltipContainerProps, 'isOpen' | 'children' | 'label'> {}

export const DecimalsTooltip: FC<DecimalsTooltipProps> = ({
  isOpen,
  label,
  children,
}) => {
  return (
    <S.InputTooltip isOpen={isOpen} label={label} disabled={!isOpen}>
      {children}
    </S.InputTooltip>
  );
};
