import styled from 'styled-components';

import { typography } from '~/design-tokens/typography/Typography.styles';

import { LinePlot as DefaultLinePlot } from '../high-low-triple-line-chart/components/line-plot/LinePlot';

export const BandChartRoot = styled.div`
  width: 100%;
  height: 100%;
`;

export const Plot = styled.svg`
  width: 100%;
  height: 180px;
`;

export const LinePlot = styled(DefaultLinePlot)`
  stroke-width: 2;
  color: var(--border-primary);
`;

export const AxisLine = styled.line`
  stroke: var(--border-soft);
`;

export const AxisText = styled.text`
  font-family: var(--font-roboto-mono);
  ${typography.quokka};
  fill: var(--text-soft);
`;
