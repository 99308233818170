import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Collapse } from '~/shared/components/collapse';
import { TooltipContainer } from '~/shared/components/tooltip-container/TooltipContainer';
import { CARD_TYPES } from '~/views/analyze/overview-constants';
import {
  InsightStatus,
  MappedArticle,
} from '~/views/analyze/utils/insights-helpers';

import * as S from '../CardSharedStyles.styles';

interface BullishBearishCardBodyProps {
  insight: MappedArticle;
  isSummaryExpanded: boolean;
  isTablet: boolean;
  isMobile: boolean;
  toggleSummaryExpanding: () => void;
  cardType: CARD_TYPES;
}

export const BullishBearishCardBody: FC<BullishBearishCardBodyProps> = ({
  insight,
  isSummaryExpanded,
  toggleSummaryExpanding,
  cardType,
  isMobile,
  isTablet,
}) => {
  const { t } = useTranslation('analyze');

  return (
    <>
      <S.InsightCardBody>
        <S.PriceContainer>
          {insight.status === InsightStatus.WORKED && (
            <S.StatusText>{t('insights.cards.worked')}</S.StatusText>
          )}
          {insight.status === InsightStatus.FAILED && (
            <S.StatusText>{t('insights.cards.stopped')}</S.StatusText>
          )}
          {insight.status === InsightStatus.ACTIVE && (
            <>
              <S.PriceChange type={insight.direction}>
                {insight.pnlText}
              </S.PriceChange>
              <S.Box>
                <S.Text>{t(`analyze:insights.cards.${cardType}Text`)}</S.Text>
                <TooltipContainer
                  label={t('analyze:insights.cards.infoTooltip')}
                >
                  <span>
                    <S.InfoIcon
                      iconName="InformationBoldCircle"
                      fillColor="var(--primary-color)"
                      size={18}
                      data-testid="info-circle"
                    />
                  </span>
                </TooltipContainer>
              </S.Box>
            </>
          )}
        </S.PriceContainer>
        {isTablet && (
          <S.StyledInsightSummary insight={insight} constraint="tablet" />
        )}
      </S.InsightCardBody>
      {isSummaryExpanded && (
        <S.CollapseButton
          onClick={toggleSummaryExpanding}
          role="button"
          data-testid="collapse-button"
        >
          <S.ChevronDownIcon
            iconName="ChevronBoldDown"
            size={14}
            fillColor="var(--primary-color)"
          />
          <span>{t('analyze:insights.cards.hideDetails')}</span>
        </S.CollapseButton>
      )}
      {isMobile && (
        <Collapse collapsed={!isSummaryExpanded}>
          <S.StyledInsightSummary insight={insight} constraint="mobile" />
        </Collapse>
      )}
    </>
  );
};
