import { deviceConstraints } from '@toggle/design-system';
import styled, { css } from 'styled-components';

export const Menu = styled.aside<{ isGuest: boolean; withBanner: boolean }>`
  grid-area: menu;
  z-index: 1;
  position: sticky;
  top: calc(var(--app-mobile-banner-height) + 40px);

  ${({ withBanner }) =>
    withBanner &&
    css`
      margin-bottom: 15px;
      top: calc(var(--app-mobile-banner-height) + 162px);
    `}

  ${({ isGuest }) =>
    isGuest &&
    css`
      margin-bottom: 0;
      top: calc(var(--app-mobile-banner-height) + 134px);
    `}

  @media ${deviceConstraints.tablet} {
    padding-left: 0;
    padding-right: 0;
    top: calc(var(--app-header-height) + 40px);

    ${({ withBanner }) =>
      withBanner &&
      css`
        top: calc(var(--app-header-height) + 138px);
        margin-bottom: 0;
      `}

    ${({ isGuest }) =>
      isGuest &&
      css`
        top: 170px;
      `}
  }

  @media ${deviceConstraints.desktopSmall} {
    position: static;
  }

  @media ${deviceConstraints.desktopLarge} {
    position: sticky;
    top: calc(var(--app-header-height) + 72px);
    padding-top: 16px;

    ${({ withBanner }) =>
      withBanner &&
      css`
        top: calc(var(--app-header-height) + 96px);
        margin-bottom: 0;
      `}
  }
`;

export const Summary = styled.aside<{ withBanner: boolean }>`
  grid-area: summary;

  @media ${deviceConstraints.desktopSmall} {
    margin: 16px 0 0 0;
  }

  @media ${deviceConstraints.desktop} {
    margin: 0 24px 0 0;
    padding-top: 24px;

    ${({ withBanner }) =>
      withBanner &&
      css`
        top: calc(var(--app-header-height) + 96px);
        margin-bottom: 0;
      `}
  }

  @media ${deviceConstraints.desktopLarge} {
    position: sticky;
    top: calc(40px + var(--app-header-height));

    ${({ withBanner }) =>
      withBanner &&
      css`
        top: calc(var(--app-header-height) + 96px);
        margin-bottom: 0;
      `}
  }
`;

export const Share = styled.aside`
  display: none;

  @media ${deviceConstraints.desktopSmall} {
    padding-top: 20px;
    display: block;
    align-self: flex-start;
  }

  @media ${deviceConstraints.desktopLarge} {
    position: fixed;
    width: 338px;
    top: 690px;
    padding-top: 0px;
  }

  height: 280px;
  grid-area: share;
`;
