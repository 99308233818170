import { UserRole } from '~/api/subscription/subscription-schema';

import { MODAL_TYPES } from '../modal-types/modalTypes';

const proModalTypes = new Set([
  MODAL_TYPES.DEFAULT_PRO,
  MODAL_TYPES.BEYOND_STOCKS,
  MODAL_TYPES.MARKET_INDICATORS,
]);

export const getSubscriptionRoleByModalType = (
  modalType = MODAL_TYPES.DEFAULT
) => {
  if (proModalTypes.has(modalType)) {
    return UserRole.Pro;
  }

  return UserRole.Copilot;
};
