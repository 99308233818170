import { Input, typographyV2 } from '@toggle/design-system';
import styled from 'styled-components';

export const SearchInputRoot = styled.div`
  width: 100%;
  flex-shrink: 0;

  border-top: 1px solid var(--border-soft);
  border-bottom: 1px solid var(--border-soft);
  display: flex;
  align-items: center;

  ${Input.Styles.LeftIcon} svg {
    fill: var(--text-soft);
  }

  ${Input.Styles.Container} {
    width: 100%;
  }

  ${Input.Styles.InputWrapper} {
    border: none;
    background-color: transparent;
    padding-left: 20px;
  }
`;

export const ClearButton = styled.button`
  ${typographyV2.bodySm};
  color: var(--text-soft);
  cursor: pointer;
  border: none;
  background: none;

  &:hover {
    color: var(--text-regular);
  }
`;
