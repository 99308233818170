import { z } from 'zod';

export const conditionSchema = z.object({
  entity: z.string(),
  entityName: z.string().optional(),
  formattedTicker: z.string().optional(),
  snake: z.string(),
  snakeName: z.string().optional(),
  condition: z.string(),
  order: z.number().optional(),
  value: z.number().optional(),
  duration: z.number().optional(),
  duration_unit: z.string().optional(),
  min_value: z.number().optional(),
  max_value: z.number().optional(),
});

export enum QualityChecklistKeys {
  CrossBusinessCycle = 'cross_business_cycle',
  SeveralEpisodes = 'several_episodes',
  Sparse = 'sparse',
  Frequent = 'frequent',
  HighHitRatioInSample = 'high_hit_ratio_in_sample',
  HighHitRatioOutSample = 'high_hit_ratio_out_sample',
  HighPathRiskReturn = 'high_path_risk_return',
  HighPnlDrawdownRatio = 'high_pnl_drawdown_ratio',
}

const periodSchema = z.enum(['1w', '2w', '1m', '3m', '6m']);
export const periods: Period[] = periodSchema._def.values;

const checklistItemSchema = z.object({
  star: z.boolean(),
  value: z.union([z.number(), z.boolean()]),
});

export const checklistSchema = z.record(
  z.nativeEnum(QualityChecklistKeys),
  checklistItemSchema
);

export const qualityChecklistSchema = z.object({
  period: periodSchema,
  checklist: checklistSchema,
});

export const predictionSchema = z.object({
  low: z.number(),
  median: z.number(),
  high: z.number(),
});

const pastEpisodeSchema = z.object({
  data: z.array(z.number()).nullable(),
  date: z.string(),
});

export const episodeSchema = z.object({
  period: periodSchema,
  dates: z.array(pastEpisodeSchema),
  is_best: z.boolean().optional(),
  best: z.number(),
  worst: z.number(),
  median: z.number(),
  upside: z.number(),
  downside: z.number(),
});

export const compassRequestSchema = z.object({
  entity: z.string(),
  snake: z.string(),
  date: z.string(),
  conditions: z.array(conditionSchema),
});

export const compassResponseSchema = z.object({
  prediction: z.array(predictionSchema),
  quality_checklist: z.array(qualityChecklistSchema),
  episodes: z.array(episodeSchema),
});

export const compass = {
  postCompass: {
    path: 'analysis/v1/compass',
    schema: compassResponseSchema,
  },
};

export type Period = z.infer<typeof periodSchema>;
export type Checklist = z.infer<typeof checklistSchema>;
export type Condition = z.infer<typeof conditionSchema>;
export type CompassRequestPayload = z.infer<typeof compassRequestSchema>;
export type CompassData = z.infer<typeof compassResponseSchema>;
export type Episode = z.infer<typeof episodeSchema>;
export type PastEpisode = z.infer<typeof pastEpisodeSchema>;
export type Prediction = z.infer<typeof predictionSchema>;
export type QualityChecklistItem = z.infer<typeof qualityChecklistSchema>;
