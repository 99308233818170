import styled, { css, Interpolation } from 'styled-components';

import { typography } from '~/design-tokens/typography/Typography.styles';

import { Badge } from '../badge/Badge';
import { Icon } from '../icon/Icon';

export const TabContainer = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const TabPanel = styled.div`
  width: 100%;
  height: 100%;
`;

export const Header = styled.header<{
  styles?: Interpolation<React.CSSProperties>;
}>`
  width: 100%;
  border: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  transition: 0.4s;
  border-bottom: solid 1px var(--border-default);
  ${({ styles }) => styles}
`;

export const Tab = styled.div<{ $fullWidth?: boolean }>`
  display: flex;
  margin-right: 20px;
  ${({ $fullWidth }) =>
    $fullWidth &&
    css`
      flex: 1;
      justify-content: center;
      margin-right: 0px;
    `};
`;

export const IconWrapper = styled.span<{ $onlyIcon: boolean }>`
  margin-right: ${({ $onlyIcon }) => ($onlyIcon ? '0px' : '6px')};
  display: flex;
  align-items: center;
`;

export const TabIcon = styled(Icon)<{ $active: boolean }>`
  transition: 0.2s;
  fill: ${({ $active }) =>
    $active ? 'var(--icon-primary)' : 'var(--icon-default)'};
`;

export const StyledBadge = styled(Badge)`
  margin-left: 6px;
`;

export const TabButton = styled.button<{
  $active: boolean;
  $fullWidth?: boolean;
}>`
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: inherit;
  background-color: transparent;
  padding: 8px 4px;
  margin-bottom: -1px;
  border: none;
  ${typography.emu};
  color: var(--text-default);
  border-bottom: solid 2px transparent;
  text-transform: capitalize;
  transition: 0.2s;

  ${({ disabled }) =>
    disabled
      ? `
      color: var(--text-disabled);
      cursor: default;
    `
      : `
      &:hover {
        color: var(--text-primary);
        ${TabIcon} {
          fill: var(--icon-primary);
        }
      }
    `}

  ${({ $fullWidth }) =>
    $fullWidth &&
    css`
      width: 100%;
    `};
  ${({ $active }) =>
    $active &&
    css`
      color: var(--text-primary);
      border-bottom: solid 2px var(--border-primary);
    `};
  &:focus {
    outline: none;
  }
`;
