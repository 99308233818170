import {
  ChartAssetData,
  ChartPane,
  GUTTER_SIZE_CUSTOM_EVENT,
  PaneActions,
  PriceDisplay,
} from '@toggle/chart';
import React, { FC, RefObject, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { UseTurboChartReturn } from '../../hooks/use-turbo-chart/useTurboChart';
import { AxisCurrency } from '../axis-currency/AxisCurrency';
import { InsightLoader } from '../insights/insight-loader/InsightLoader';
import { PriceDisplayGroup } from '../price-display-group/PriceDisplayGroup';

export interface ChartAreaOverlayProps {
  chartData: ChartAssetData[];
  canvasRef: RefObject<HTMLCanvasElement>;
  insightsLoading: boolean;
  showPriceDisplayGroup: boolean;
  priceDisplay: PriceDisplay;
  changeYAxisSettings: UseTurboChartReturn['changeYAxisSettings'];
  chartSettings: UseTurboChartReturn['chartSettings'];
  chartPaneActions: UseTurboChartReturn['chartPaneActions'];
}

export interface GutterSize {
  x: number;
  y: number;
  paneLimitExceeded: boolean;
  panes: ChartPane[];
  multiAxisSpacing: { xStart: number; width: number; snake: string }[] | null;
}

export const ChartAreaOverlay: FC<ChartAreaOverlayProps> = ({
  chartData,
  canvasRef,
  insightsLoading,
  showPriceDisplayGroup,
  priceDisplay,
  changeYAxisSettings,
  chartSettings,
  chartPaneActions,
}) => {
  const { t } = useTranslation('chart');

  const [gutterSize, setGutterSize] = useState<GutterSize>({
    x: 40,
    y: 40,
    multiAxisSpacing: null,
    paneLimitExceeded: false,
    panes: [],
  });

  useEffect(() => {
    const canvasElement = canvasRef.current;
    if (!canvasElement) {
      return undefined;
    }

    const onGutterSizeChange = (event: Event) => {
      const gutter = (event as CustomEvent<GutterSize>).detail;
      setGutterSize(gutter);
    };

    canvasElement.addEventListener(
      GUTTER_SIZE_CUSTOM_EVENT,
      onGutterSizeChange
    );

    return () => {
      canvasElement.removeEventListener(
        GUTTER_SIZE_CUSTOM_EVENT,
        onGutterSizeChange
      );
    };
  }, [gutterSize]);

  return (
    <>
      <AxisCurrency
        chartData={chartData}
        priceDisplay={chartSettings.priceDisplay}
        gutterSize={gutterSize}
        chartSettings={chartSettings}
      />
      {insightsLoading && (
        <InsightLoader
          title={t('chart:loadingEvents')}
          gutterSize={gutterSize}
        />
      )}
      {showPriceDisplayGroup && (
        <PriceDisplayGroup
          chartSettings={chartSettings}
          gutterSize={gutterSize}
          labels={[t('chart:priceYield'), t('chart:indexedTo100')]}
          changeYAxisSettings={changeYAxisSettings}
          activeIndex={priceDisplay === 'price' ? 0 : 1}
        />
      )}
      <PaneActions
        chartPanes={gutterSize.panes}
        paneDetails={undefined}
        onPaneRemove={chartPaneActions.removePane}
        onPaneMove={chartPaneActions.movePane}
        onPaneCollapse={chartPaneActions.collapsePane}
        onPaneMaximize={chartPaneActions.maximizePane}
      />
    </>
  );
};
