import styled from 'styled-components';

import { customScrollMinimal } from '~/components/custom-scroll/CustomScroll.styles';
import { typographyV2 } from '~/design-tokens/typography/Typography.styles';

export const Aside = styled.aside`
  position: fixed;
  top: var(--app-header-height, 0);
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  width: 288px;
  border-right: 1px solid var(--border-soft);
`;

export const Title = styled.h2`
  ${typographyV2.titleXL}
  padding:20px 24px;
`;

export const Categories = styled.div`
  ${customScrollMinimal};
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  border-top: 1px solid var(--border-soft);
  padding: 16px 0px;
  gap: 16px;
  overflow-y: scroll;
`;
