import ReactGA from 'react-ga4';

import {
  Portfolio,
  PortfolioProvider,
} from '~/stores/use-portfolio/usePortfolio';

type PortfolioProviderGroup = PortfolioProvider & {
  portfolios: Omit<Portfolio, 'provider'>[];
};

const CATEGORIES = {
  portfolio: 'Portfolio',
};
export { CATEGORIES as PORTFOLIO_CATEGORIES };

const ACTIONS = {
  setPortfolioDefaultCurrency: 'Portfolio_default_currency',
  setPortfolioConnectAttempt: 'Portfolio_connect_attempt',
  setPortfolioConnectResult: 'Portfolio_connect_result',
  disconnectPortfolio: 'Disconnect_portfolio',
  stopDisconnectPortfolio: 'Portfolio_stop_disconnection',
  reconnectPortfolio: 'Reconnect_portfolio',
  cancelReportCurrency: 'Portfolio_cancel_report_currency',
  reportCurrency: 'Portfolio_report_currency',
  clickChangeAlias: 'Click_change_alias',
  cancelSetAlias: 'Portfolio_cancel_set_alias',
  clickReportCurrency: 'Click_report_currency',
  saveAlias: 'Portfolio_save_alias',
  clickSaveCurrency: 'Portfolio_click_save_currency',
  userTypedCurrency: 'Portfolio_user_typed_currency',
};
export { ACTIONS as PORTFOLIO_ACTIONS };

export function gaPortfolioSendDefaultCurrency(currency: string) {
  ReactGA.event({
    category: CATEGORIES.portfolio,
    action: ACTIONS.setPortfolioDefaultCurrency,
    label: `currency:${currency}`,
  });
}

export function gaPortfolioConnectAttempt(connectType: string) {
  ReactGA.event({
    category: CATEGORIES.portfolio,
    action: ACTIONS.setPortfolioConnectAttempt,
    label: `connectType:${connectType}`,
  });
}

export function gaPortfolioSendConnectResult(result: string) {
  ReactGA.event({
    category: CATEGORIES.portfolio,
    action: ACTIONS.setPortfolioConnectResult,
    label: result,
  });
}

export function gaPortfolioDisconnect(
  portfolios: Omit<Portfolio, 'provider'>[] | PortfolioProviderGroup[],
  providerName = ''
) {
  const providerNameString = providerName ? `provider:${providerName};` : '';
  const portfoliosString = portfolios.map(({ name }) => name).join(',');
  ReactGA.event({
    category: CATEGORIES.portfolio,
    action: ACTIONS.disconnectPortfolio,
    label: `${providerNameString}portfolios:${portfoliosString}`,
  });
}

export function gaPortfolioStopDisconnect() {
  ReactGA.event({
    category: CATEGORIES.portfolio,
    action: ACTIONS.stopDisconnectPortfolio,
  });
}

export function gaPortfolioReconnect(portfolios: string, location: string) {
  ReactGA.event({
    category: CATEGORIES.portfolio,
    action: ACTIONS.reconnectPortfolio,
    label: `portfolios:${portfolios};location:${location}`,
  });
}

export function gaPortfolioCancelReportCurrency() {
  ReactGA.event({
    category: CATEGORIES.portfolio,
    action: ACTIONS.cancelReportCurrency,
  });
}

export function gaPortfolioReportCurrency(before: string, after: string) {
  ReactGA.event({
    category: CATEGORIES.portfolio,
    action: ACTIONS.reportCurrency,
    label: `Before:${before};After:${after}`,
  });
}

export function gaPortfolioClickChangeAlias() {
  ReactGA.event({
    category: CATEGORIES.portfolio,
    action: ACTIONS.clickChangeAlias,
  });
}

export function gaPortfolioCancelSetAlias() {
  ReactGA.event({
    category: CATEGORIES.portfolio,
    action: ACTIONS.cancelSetAlias,
  });
}

export function gaPortfolioClickReportCurrency() {
  ReactGA.event({
    category: CATEGORIES.portfolio,
    action: ACTIONS.clickReportCurrency,
  });
}

export function gaPortfolioSaveAlias(alias: string) {
  ReactGA.event({
    category: CATEGORIES.portfolio,
    action: ACTIONS.saveAlias,
    label: `alias:${alias}`,
  });
}

export function gaPortfolioUserTypedCurrency(currency: string) {
  ReactGA.event({
    category: CATEGORIES.portfolio,
    action: ACTIONS.userTypedCurrency,
    label: `currency:${currency}`,
  });
}

export function gaPortfolioClickSaveCurrency(currency: string) {
  ReactGA.event({
    category: CATEGORIES.portfolio,
    action: ACTIONS.clickSaveCurrency,
    label: `Currency:${currency}`,
  });
}
