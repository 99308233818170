import { useOutsideClick } from '@toggle/helpers';
import React, { FC, RefObject, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import * as S from './FilterScreenPopup.styles';

export interface FilterScreenPopupProps {
  isTouchDevice?: boolean;
  showSave?: boolean;
  showRevert?: boolean;
  onRename: () => void;
  onDelete: () => void;
  onSave?: () => void;
  onRevert?: () => void;
  onHide: () => void;
  outsideClickRef?: RefObject<HTMLElement>;
}

export const FilterScreenPopup: FC<FilterScreenPopupProps> = ({
  showRevert = false,
  showSave = false,
  isTouchDevice = false,
  onHide,
  onRename,
  onDelete,
  onSave,
  onRevert,
  outsideClickRef,
}) => {
  const { t } = useTranslation('explore');
  const ref = useRef(null);

  useOutsideClick({ ref: outsideClickRef ?? ref, callback: onHide });

  return (
    <S.Wrapper role="dialog" ref={ref} data-testid="filter-screen-popup">
      {isTouchDevice && (
        <>
          {showRevert && (
            <S.Text onClick={onRevert}>{t('explore:screens.revert')}</S.Text>
          )}
          {showSave && (
            <S.Text onClick={onSave}>{t('explore:screens.save')}</S.Text>
          )}
        </>
      )}
      <S.Text onClick={onRename}>{t('explore:screens.rename')}</S.Text>
      <S.Red onClick={onDelete}>{t('explore:screens.delete')}</S.Red>
    </S.Wrapper>
  );
};
