import { Chip } from '@toggle/design-system';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ListType } from '~/views/turbo-chart/hooks/use-assets-lists/useAssetsLists';

import * as S from './ChipsSelector.styles';

export interface ChipsSelectorProps {
  activeListType: ListType;
  onChange: (listType: ListType) => void;
  listTypes: ListType[];
}

export const ChipsSelector = ({
  activeListType,
  onChange,
  listTypes,
}: ChipsSelectorProps) => {
  const { t } = useTranslation('chart');

  return (
    <S.ChipsSelectorRoot data-testid="chips-selector">
      {listTypes.map(type => (
        <Chip
          key={type}
          /* i18next-extract-mark-context-next-line ["recent", "watchlist", "portfolio"] */
          label={t('chart:widget.compareAssets.chipsSelector', {
            context: type,
          })}
          onClick={() => onChange(type)}
          isActive={activeListType === type}
          variation="outline"
        />
      ))}
    </S.ChipsSelectorRoot>
  );
};
