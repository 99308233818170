import { typographyV2 } from '@toggle/design-system';
import styled from 'styled-components';

export const Item = styled.li`
  display: flex;
  align-items: center;
  gap: 12px;
  ${typographyV2.bodySm};

  & svg {
    flex-shrink: 0;
    fill: var(--icon-primary);
  }
`;
