import React, { FC, ReactNode, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useUser } from '~/stores/use-user/useUser';
import { AnalyzeViews } from '~/views/analyze/AnalyzeViews';

import { appPaths, queryKeys } from '../../routes/app-paths';
import {
  GaLocations,
  gaSetScreenName,
  gaSetUserIDInGA,
} from '../../shared/utils/ganalytics';
import { PortfolioPageViews } from '../../views/portfolio-page/PortfolioPageViews';

const viewToGA: Record<string, GaLocations> = {
  [AnalyzeViews.ASSET_OVERVIEW]: GaLocations.asset_overview,
  [AnalyzeViews.CHART]: GaLocations.chart,
  [AnalyzeViews.SCENARIO]: GaLocations.scenario,
  [PortfolioPageViews.Positions]: GaLocations.portfolio_positions,
  [PortfolioPageViews.Performance]: GaLocations.portfolio_performance,
  [PortfolioPageViews.Orders]: GaLocations.portfolio_orders,
};

interface AppHistoryProps {
  children: ReactNode;
}

export const AppHistory: FC<AppHistoryProps> = props => {
  const { user } = useUser(({ user }) => ({ user }));
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (user?.id) {
      gaSetUserIDInGA(user.id);
    }

    mapScreenNameGA();
  }, [user, location]);

  const mapScreenNameGA = () => {
    let basePath = '';

    // removes '/' takes first word from the path & replaces - with _ e.g. /asset-overview/tsla becomes asset_overview
    const justBaseRoute = location.pathname.split('/')[1].replace('-', '_');
    if (Object.values<string>(GaLocations).includes(justBaseRoute)) {
      basePath = justBaseRoute;
    }

    // Used to handle deep subscription routes /my-account/subscription/confirm-basic becomes just /my-account/subscription
    const first2Path = location.pathname.split('/').slice(0, 3).join('/');
    switch (first2Path) {
      case appPaths.myAccount.watchlist:
        basePath = GaLocations.my_account_watchlist;
        break;
      case appPaths.myAccount.portfolio:
        basePath = GaLocations.my_account_portfolio;
        break;
      case appPaths.myAccount.subscription:
        basePath = GaLocations.my_account_subscription;
        break;
      case appPaths.myAccount.security:
        basePath = GaLocations.my_account_security;
        break;
      case appPaths.myAccount.appearance:
        basePath = GaLocations.my_account_appearance;
        break;
      default:
        break;
    }

    // Handles search paths like /analyze/de_nyse?view=overview -> asset_overview
    const viewKey = new URLSearchParams(location.search).get('view');

    if (viewKey) {
      basePath = viewToGA[viewKey];
    } else if (location.pathname === appPaths.portfolio) {
      basePath = GaLocations.portfolio_positions;
    }

    gaSetScreenName(basePath as GaLocations);
  };

  useEffect(() => {
    if (user) {
      const queryParams = new URLSearchParams(location.search);
      const redirectTo = queryParams.get(queryKeys.redirect);
      if (redirectTo) {
        navigate(redirectTo, { replace: true });
        return;
      }
    }
  }, [location, user, navigate]);

  return <>{props.children}</>;
};
