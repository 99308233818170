import { deviceConstraints, typographyV2 } from '@toggle/design-system';
import styled from 'styled-components';

export const PlanContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 12px;

  @media ${deviceConstraints.desktopSmall} {
    align-items: center;
    width: 200px;
  }
`;

export const PlanLabel = styled.p`
  ${typographyV2.titleMd}
`;

export const PlanPrice = styled.p`
  ${typographyV2.bodyXs};
  margin-bottom: 8px;
`;
