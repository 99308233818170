import remove from 'lodash/remove';
import { ReactNode } from 'react';

import { create } from '~/stores/create-store/createStore';

export enum ToastPosition {
  Top = 'TOP',
  TopLeft = 'TOP_LEFT',
  TopRight = 'TOP_RIGHT',
  Bottom = 'BOTTOM',
  BottomLeft = 'BOTTOM_LEFT',
  BottomRight = 'BOTTOM_RIGHT',
}

export interface Toast {
  id: number | string;
  content: ReactNode;
  hideToast: boolean;
  timeout: number;
  position: ToastPosition;
  scope: string;
}

interface ToastsStore {
  toasts: Toast[];
  maxToasts: number;
  showToast: (toast: Partial<Toast> & { content: ReactNode }) => void;
  removeToast: (toastId: number | string) => void;
  clearAllToasts: () => void;
  clearScopedToasts: (scope: string) => void;
  toastExists: (toastId: number | string) => boolean;
}

const defaultToastProps = {
  hideToast: true,
  timeout: 5000,
  position: ToastPosition.Top,
  scope: '',
};

export const useToasts = create<ToastsStore>((set, get) => ({
  toasts: [],
  maxToasts: 5,
  showToast: toast => {
    // setTimeout disables butch update (ReactDOM.flushSync won't work here)
    setTimeout(() => {
      if (get().toasts.some(t => t.id === toast.id)) {
        return;
      }

      const newToast = {
        id: new Date().getTime() + get().toasts.length,
        ...defaultToastProps,
        ...toast,
      };
      const newToasts = [...get().toasts, newToast];

      const presentToasts = get().toasts.filter(
        toast => toast.position === newToast.position
      );
      if (presentToasts.length === get().maxToasts) {
        remove(newToasts, t => t.id === presentToasts[0].id);
      }

      set({ toasts: newToasts });

      if (newToast.hideToast) {
        const hideTimeout = setTimeout(() => {
          get().removeToast(newToast.id);
          clearTimeout(hideTimeout);
        }, newToast.timeout);
      }
    }, 0);
  },

  removeToast: toastId => {
    set({ toasts: get().toasts.filter(toast => toast.id !== toastId) });
  },
  clearAllToasts: () => {
    set({ toasts: [] });
  },
  toastExists: toastId => get().toasts.some(toast => toast.id === toastId),
  clearScopedToasts: (scope: string) => {
    set({ toasts: get().toasts.filter(toasts => toasts.scope !== scope) });
  },
}));
