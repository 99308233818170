import { SnakeMeta, SnakeMetaThreshold } from '@toggle/toggle';

import { BaseChartAPIProps, ChartPane } from '~/types/create.types';
import { ChartAssetData, ThresholdInterval } from '~/types/timeseries.types';
import {
  MIN_THRESHOLD_BAND_WIDTH,
  PANES_DIVIDER_HEIGHT,
} from '~/utils/constants';

export interface DataWithThreshold extends ChartAssetData {
  snakeMeta: SnakeMeta & { threshold: SnakeMetaThreshold };
}

type Keys = keyof SnakeMetaThreshold;

export const drawThreshold = (
  { context, options }: BaseChartAPIProps,
  chartAssetData: DataWithThreshold,
  pane: ChartPane
) => {
  const threshold = chartAssetData.snakeMeta.threshold;
  const yScale = pane.y[0].yScale;
  const maxThresholdValue =
    threshold.good && threshold.bad
      ? Math.max(threshold['good'], threshold['bad'])
      : null;

  const mapping: Record<Keys, { color: string; dashOffset?: number[] }> = {
    good: { color: options.colors.bars.up },
    bad: { color: options.colors.bars.down, dashOffset: [4, 6] },
    neutral: { color: options.colors.border.default },
  };

  Object.entries(mapping).forEach(([key, value]) => {
    const thresholdValue = threshold[key as Keys];
    const y = yScale(thresholdValue as number);

    if (
      typeof thresholdValue === 'number' &&
      y >= pane.options.gutters.top &&
      y <= pane.options.height + pane.options.gutters.top
    ) {
      if (key !== 'neutral') {
        drawThresholdRect({
          context,
          color: value.color,
          isUp: thresholdValue === maxThresholdValue,
          pane,
          options,
          yCoord: y,
        });
      }

      drawHorizontalLine({
        context,
        dashOffset: value.dashOffset,
        lineColor: value.color,
        lineWidth: 1,
        x1: 0,
        x2: pane.options.width - options.gutters.y,
        y,
      });
    }
  });
};

interface DrawHorizontalLineProps {
  context: BaseChartAPIProps['context'];
  dashOffset?: number[];
  lineWidth: number;
  lineColor: string;
  x1: number;
  x2: number;
  y: number;
}

const drawHorizontalLine = ({
  context,
  dashOffset,
  lineWidth,
  lineColor,
  x1,
  x2,
  y,
}: DrawHorizontalLineProps) => {
  if (dashOffset) {
    context.lineWidth = lineWidth;
    context.strokeStyle = lineColor;
    context.setLineDash(dashOffset);
    context.beginPath();
    context.moveTo(x1, y);
    context.lineTo(x2, y);
    context.stroke();
    context.closePath();
  } else {
    context.fillStyle = lineColor;
    context.fillRect(x1, y, x2 - x1, lineWidth);
  }
};

interface DrawThresholdRectProps {
  context: BaseChartAPIProps['context'];
  options: BaseChartAPIProps['options'];
  isUp: boolean;
  pane: ChartPane;
  color: string;
  yCoord: number;
}

const drawThresholdRect = ({
  context,
  isUp,
  color,
  pane,
  options,
  yCoord,
}: DrawThresholdRectProps) => {
  context.save();
  context.globalCompositeOperation = 'source-atop';
  context.fillStyle = color;
  context.fillRect(
    0,
    isUp ? pane.options.gutters.top + PANES_DIVIDER_HEIGHT : yCoord,
    pane.options.width - options.gutters.y,
    isUp
      ? yCoord - pane.options.gutters.top - PANES_DIVIDER_HEIGHT
      : pane.options.gutters.top + pane.options.height - yCoord
  );
  context.restore();
};

export const drawThresholdBands = ({
  base,
  thresholdIntervals,
  pane,
}: {
  base: BaseChartAPIProps;
  thresholdIntervals: ThresholdInterval[];
  pane: ChartPane;
}) => {
  const { context, options, x } = base;
  context.save();
  base.context.globalCompositeOperation = 'destination-over';

  thresholdIntervals.forEach(i => {
    context.fillStyle = options.colors.threshold[i.direction];
    const x1 = x.xScale(i.start);
    const x2 = Math.min(
      x.xScale(i.end),
      base.options.width - base.options.gutters.y
    );
    context.fillRect(
      x1,
      pane.options.gutters.top,
      Math.max(MIN_THRESHOLD_BAND_WIDTH, x2 - x1),
      pane.options.height
    );
  });
  context.restore();
};
