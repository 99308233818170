import { MouseEvent, RefObject, useEffect } from 'react';

const defaultCheck = <T extends Element>(ref: RefObject<T>, e: MouseEvent) =>
  ref.current && !ref.current.contains(e.target as Element);

interface UseOutsideClickProps<T> {
  ref: RefObject<T>;
  callback: () => void;
  isClickedOutside?: (ref: RefObject<T>, e: MouseEvent) => boolean;
  eventType?: 'mousedown' | 'click';
}

export const useOutsideClick = <T extends Element>({
  ref,
  callback,
  isClickedOutside,
  eventType = 'mousedown',
}: UseOutsideClickProps<T>) => {
  const handleClick = (e: MouseEvent) => {
    if (isClickedOutside) {
      if (isClickedOutside(ref, e)) {
        callback();
      }
    } else if (defaultCheck(ref, e)) {
      callback();
    }
  };

  useEffect(() => {
    //@ts-ignore
    document.addEventListener(eventType, handleClick);
    return () => {
      //@ts-ignore
      document.removeEventListener(eventType, handleClick);
    };
  }, [callback]);
};
