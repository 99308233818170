import { Input } from '@toggle/design-system';
import React, { ChangeEvent, FC } from 'react';
import { useTranslation } from 'react-i18next';

import { DecimalsTooltip } from '../limit-order/decimals-tooltip/DecimalsTooltip';

export interface QuantityInputProps {
  onChange: (value: string) => void;
  value: string;
  onFocus?: () => void;
  onBlur?: () => void;
  isDecimalExceeded: boolean;
  tradingProviderLabel: string;
}

export const QuantityInput: FC<QuantityInputProps> = ({
  onChange,
  onFocus,
  onBlur,
  value,
  isDecimalExceeded,
  tradingProviderLabel,
}) => {
  const { t } = useTranslation('portfolio');

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    onChange(value);
  };

  return (
    <DecimalsTooltip
      isOpen={isDecimalExceeded}
      label={t('portfolio:fractionalQuantityExceeded', {
        tradingProvider: tradingProviderLabel,
      })}
    >
      <Input
        type="number"
        step="any"
        autoComplete="off"
        rightAlignText
        name="quantity"
        onChange={handleChange}
        value={value}
        onFocus={onFocus}
        onBlur={onBlur}
        data-testid="quantity-input"
      />
    </DecimalsTooltip>
  );
};
