export const hslToRgb = ({ h, s, l }: HSL): RGB => {
  const c = (1 - Math.abs(2 * l - 1)) * s;
  const x = c * (1 - Math.abs(((h / 60) % 2) - 1));
  const m = l - c / 2;

  let r = 0,
    g = 0,
    b = 0;

  if (0 <= h && h < 60) {
    r = c;
    g = x;
    b = 0;
  } else if (60 <= h && h < 120) {
    r = x;
    g = c;
    b = 0;
  } else if (120 <= h && h < 180) {
    r = 0;
    g = c;
    b = x;
  } else if (180 <= h && h < 240) {
    r = 0;
    g = x;
    b = c;
  } else if (240 <= h && h < 300) {
    r = x;
    g = 0;
    b = c;
  } else if (300 <= h && h < 360) {
    r = c;
    g = 0;
    b = x;
  }

  return {
    r: Math.round((r + m) * 255),
    g: Math.round((g + m) * 255),
    b: Math.round((b + m) * 255),
  };
};

type HSL = { h: number; s: number; l: number };
type RGB = { r: number; g: number; b: number };

export const rgbToHex = ({ r, g, b }: RGB) => {
  const rHex = r.toString(16).padStart(2, '0');
  const gHex = g.toString(16).padStart(2, '0');
  const bHex = b.toString(16).padStart(2, '0');
  return `#${rHex}${gHex}${bHex}`;
};

// Match the HSL string hsl(131, 43.0%, 57.2%)
// Examples https://regex101.com/r/Ceb5x8/1
const RegExpHSL = /^hsl\(\s*(\d+)\s*,\s*([\d.]+)%\s*,\s*([\d.]+)%\s*\)$/;

export const hslToHex = (hsl: string): string => {
  const hslMatch = RegExpHSL.exec(hsl);

  if (!hslMatch) {
    throw new Error('Invalid HSL format');
  }

  const h = parseInt(hslMatch[1], 10);
  const s = parseFloat(hslMatch[2]) / 100;
  const l = parseFloat(hslMatch[3]) / 100;

  const { r, g, b } = hslToRgb({ h, s, l });

  return rgbToHex({ r, g, b });
};
