import { Entity, SnakeMeta } from '@toggle/toggle';
import { useEffect, useState } from 'react';

import {
  getIndicatorSnakes,
  postTimeSeriesFiltered,
} from '~/api/timeseries/timeseries-service';
import { Tracking } from '~/shared/services/tracking/sentry';

interface IndicatorData {
  snakeItems: SnakeMeta[];
  tliItems: SnakeMeta[];
}

export const useIndicatorModal = (activeAssets: Entity[]) => {
  const [data, setData] = useState<IndicatorData>({
    snakeItems: [],
    tliItems: [],
  });
  const [isSnakesLoading, setIsSnakesLoading] = useState(true);
  const [isTliSnakesLoading, setIsTliSnakesLoading] = useState(true);

  const getTimeSeriesMenuItems = async () => {
    try {
      setIsSnakesLoading(true);
      const entities = activeAssets.map(item => item.tag);
      const res = await postTimeSeriesFiltered(entities);

      if (res instanceof Error) {
        setIsSnakesLoading(false);
        throw res;
      }
      const mappedTimeSeries = res
        .filter(({ search: { rank } }) => rank !== -1)
        .sort((a, b) => b.search.rank - a.search.rank);
      return mappedTimeSeries;
    } catch (e) {
      Tracking.captureException(e as Error);
      return undefined;
    } finally {
      setIsSnakesLoading(false);
    }
  };

  const getTliSnakes = async () => {
    try {
      setIsTliSnakesLoading(true);
      const res = await getIndicatorSnakes();

      if (res instanceof Error) {
        setIsTliSnakesLoading(false);
        throw res;
      }

      return Object.values(res);
    } catch (e) {
      Tracking.captureException(e as Error);
      return undefined;
    } finally {
      setIsTliSnakesLoading(false);
    }
  };

  const setListItems = async () => {
    const [snakeItems, tliItems] = await Promise.all([
      getTimeSeriesMenuItems(),
      getTliSnakes(),
    ]);
    if (snakeItems && tliItems) {
      setData({
        snakeItems,
        tliItems,
      });
    }
  };

  useEffect(() => {
    setListItems();
  }, []);

  return {
    data,
    isSnakesLoading,
    isTliSnakesLoading,
  };
};
