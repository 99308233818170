import { RefObject } from 'react';

type CalcDistance = (
  current: HTMLElement,
  scrollLeft: number,
  containerWidth: number
) => number;

interface ItemRef<T> {
  current: T;
}

export const hasRefs = <T>(
  chipRefs: RefObject<T>[]
): chipRefs is ItemRef<T>[] => chipRefs.every(c => !!c.current);

const getRightDistance: CalcDistance = (current, scrollLeft, containerWidth) =>
  scrollLeft + containerWidth - current.offsetLeft - current.offsetWidth;

const getLeftDistance: CalcDistance = (current, scrollLeft) =>
  current.offsetLeft - scrollLeft + current.clientWidth;

interface FindItemProps<T> {
  itemsRefs: ItemRef<T>[];
  direction: 'left' | 'right';
  scrollLeft: number;
  containerWidth: number;
}

export const findItem = <T extends HTMLElement>({
  itemsRefs,
  direction,
  scrollLeft,
  containerWidth,
}: FindItemProps<T>) => {
  const calcDistance: CalcDistance =
    direction === 'right' ? getRightDistance : getLeftDistance;

  const [chip] = itemsRefs.reduce((res, item) => {
    const distance = calcDistance(item.current, scrollLeft, containerWidth);

    if (
      !res[0] ||
      (direction === 'left' && distance >= 0 && res[1] < 0) ||
      (direction === 'right' && distance < 0 && res[1] > 0)
    ) {
      return [item, distance];
    }
    return res;
  }, [] as unknown as [ItemRef<T>, number]);

  return chip;
};

export const isWithinContainer = (
  el: HTMLElement,
  container: HTMLUListElement
) => {
  const isLeftEdgeVisible =
    el.offsetLeft > container.scrollLeft &&
    el.offsetLeft - container.scrollLeft <= container.offsetWidth;
  const isRightEdgeVisible =
    el.offsetLeft + el.offsetWidth <
    container.scrollLeft + container.offsetWidth;

  return isLeftEdgeVisible && isRightEdgeVisible;
};
