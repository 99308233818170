import { StoreApi } from 'zustand';

import { TwoFactorMethod } from '~/api/auth/auth-services';
import {
  BackupCodesType,
  TwofaStatus,
} from '~/api/two-factor-auth/two-factor-auth-schema';
import {
  get2faStatus,
  getBackupCodes,
} from '~/api/two-factor-auth/two-factor-auth-service';
import { create } from '~/stores/create-store/createStore';

export interface TwofaStore {
  twoFaStatus?: TwofaStatus;
  backupCodes?: BackupCodesType;
  is2faEnabled: boolean;
  isEmailEnabled: boolean;
  isTotpEnabled: boolean;
  fetchBackupCodes: () => Promise<void>;
  fetch2faStatus: () => Promise<void>;
}

const fetch2faStatus = async (set: StoreApi<TwofaStore>['setState']) => {
  const status = await get2faStatus();
  if (status instanceof Error) {
    throw status;
  }

  const isEmailEnabled = status.enabled.includes(TwoFactorMethod.Email);
  const isTotpEnabled = status.enabled.includes(TwoFactorMethod.Totp);
  const is2faEnabled = status.enabled.length > 0;

  set({
    twoFaStatus: status,
    is2faEnabled,
    isTotpEnabled,
    isEmailEnabled,
  });
};

const fetchBackupCodes = async (set: StoreApi<TwofaStore>['setState']) => {
  try {
    const backupCodes = await getBackupCodes();
    if (backupCodes instanceof Error) {
      throw backupCodes;
    }
    set({
      backupCodes: backupCodes,
    });
  } catch (error) {
    throw error;
  }
};

export const use2fa = create<TwofaStore>(set => ({
  twoFaStatus: undefined,
  backupCodes: undefined,
  is2faEnabled: false,
  isEmailEnabled: false,
  isTotpEnabled: false,
  fetchBackupCodes: async () => fetchBackupCodes(set),
  fetch2faStatus: async () => fetch2faStatus(set),
}));
