import { Alert } from '@toggle/design-system';
import { addHours } from 'date-fns';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useDismissContinuous } from '~/shared/hooks/use-dismiss-continuous/useDismissContinuous';

const DISMISSED_KEY = '__toggle_dismissed_grace_period_alert';

export interface AlertGracePeriodProps {
  hoursRemaining: number;
  openEditCardModal: () => void;
}

export const AlertGracePeriod: FC<AlertGracePeriodProps> = ({
  hoursRemaining,
  openEditCardModal,
}) => {
  const { t } = useTranslation('subscription');

  const endDate = addHours(new Date(), hoursRemaining);
  const { isVisible, handleDismiss } = useDismissContinuous({
    endDate,
    cookieKey: DISMISSED_KEY,
    showBeforeInDays: 5,
  });

  const onClick = () => {
    handleDismiss();
    openEditCardModal();
  };

  return isVisible ? (
    <Alert
      data-testid="grace-period-toast-alert"
      title={t('subscription:alerts.gracePeriod.title')}
      message={t('subscription:alerts.gracePeriod.message')}
      primaryBtn={{
        label: t('subscription:alerts.gracePeriod.primaryBtnLabel'),
        onClick: onClick,
      }}
      onClose={handleDismiss}
    />
  ) : null;
};
