import { deviceConstraints, typography } from '@toggle/design-system';
import styled from 'styled-components';

interface Props {
  completed: boolean;
}

export const LoaderItem = styled.div`
  margin-bottom: 32px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--base--0);
`;

export const Label = styled.div`
  ${typography.dingo}

  @media ${deviceConstraints.desktopSmall} {
    ${typography.coyote}
  }
`;

export const Entity = styled.p<Props>`
  ${typography.emu}
  color: ${({ completed }) => completed && 'var(--primary-color)'};
  display: flex;
`;

export const IconWrap = styled.span`
  display: flex;
  align-items: center;
  margin-right: 4px;
`;
