import React from 'react';

import * as S from './ToggleLogotype.styles';

export interface ToggleLogotypeProps {
  isExpanded?: boolean;
}

export const ToggleLogotype = ({
  isExpanded = false,
  ...rest
}: ToggleLogotypeProps) => {
  return (
    <S.ToggleLogotypeRoot isExpanded={isExpanded} {...rest}>
      <svg
        fill="none"
        width="100"
        height="16"
        viewBox="0 0 100 16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="m7.794 3.3762h4.4506v-2.2334h-11.387v2.2334h4.451v11.481h2.4856v-11.481z" />
        <path d="m98.974 1.1428h-10.151v13.714h10.248v-2.1747h-7.858v-3.6638h6.8783v-2.1742h-6.8783v-3.5269h7.7601v-2.1747z" />
        <path d="m76.582 14.857h9.6791v-2.1747h-7.2694v-11.54h-2.4097v13.714z" />
        <path d="m73.166 12.992v-5.8011h-2.3789v4.7094c-0.4267 0.3189-0.93 0.5725-1.5097 0.7665-0.5796 0.1908-1.2028 0.2858-1.8692 0.2858-0.7199 0-1.3694-0.1245-1.9495-0.3745-0.5796-0.25-1.0794-0.5985-1.4993-1.0394-0.4198-0.4408-0.743-0.9636-0.9695-1.572-0.2265-0.60524-0.34-1.266-0.34-1.9766 0-0.67065 0.1167-1.3055 0.35-1.8977 0.2333-0.59494 0.5533-1.1146 0.9595-1.5617 0.4067-0.44754 0.8865-0.8028 1.4394-1.0591 0.5533-0.2567 1.1497-0.38483 1.7897-0.38483 0.4394 0 0.8429 0.03629 1.2093 0.10842 0.3667 0.07212 0.7031 0.17113 1.0094 0.29612 0.3069 0.12499 0.6001 0.27955 0.8802 0.46368 0.2796 0.18413 0.5528 0.38796 0.8193 0.61151l1.5596-1.835c-0.36-0.3024-0.7299-0.56895-1.1098-0.79922-0.3799-0.22983-0.783-0.42426-1.2092-0.58195-0.4267-0.15815-0.8897-0.27955-1.3899-0.36512-0.4997-0.085567-1.0558-0.12813-1.669-0.12813-1.0531 0-2.0226 0.19085-2.9091 0.57209-0.8865 0.3817-1.649 0.89779-2.2891 1.5492-0.6395 0.65094-1.1361 1.4107-1.4892 2.279-0.3532 0.86821-0.5302 1.7888-0.5302 2.7623 0 1.0129 0.1734 1.9537 0.5202 2.8219 0.3463 0.8683 0.8361 1.6213 1.4693 2.2593 0.6332 0.6379 1.3962 1.1379 2.289 1.4994 0.8929 0.362 1.886 0.5426 2.979 0.5426 0.6128 0 1.1993-0.0556 1.7594-0.1676 0.5597-0.1115 1.083-0.2661 1.5691-0.4637 0.4866-0.1971 0.9396-0.4274 1.36-0.6903 0.4194-0.2635 0.8029-0.5394 1.1492-0.8293z" />
        <path d="m57.224 12.992v-5.8011h-2.3793v4.7094c-0.4263 0.3189-0.9296 0.5725-1.5093 0.7665-0.5796 0.1908-1.2028 0.2858-1.8692 0.2858-0.7199 0-1.3694-0.1245-1.9495-0.3745-0.5797-0.25-1.0794-0.5985-1.4993-1.0394-0.4198-0.4408-0.743-0.9636-0.9695-1.572-0.2265-0.60524-0.34-1.266-0.34-1.9766 0-0.67065 0.1167-1.3055 0.35-1.8977 0.2333-0.59494 0.5528-1.1146 0.9595-1.5617 0.4063-0.44754 0.8865-0.8028 1.4394-1.0591 0.5533-0.2567 1.1497-0.38483 1.7893-0.38483 0.4398 0 0.8429 0.03629 1.2096 0.10842 0.3663 0.07212 0.7031 0.17113 1.0095 0.29612 0.3069 0.12499 0.6001 0.27955 0.8797 0.46368 0.2801 0.18413 0.5533 0.38796 0.8198 0.61151l1.5596-1.835c-0.36-0.3024-0.7299-0.56895-1.1098-0.79922-0.3799-0.22983-0.783-0.42426-1.2097-0.58195-0.4262-0.15815-0.8896-0.27955-1.3894-0.36512-0.4997-0.085567-1.0562-0.12813-1.6695-0.12813-1.0526 0-2.0221 0.19085-2.9086 0.57209-0.8865 0.3817-1.6495 0.89779-2.2891 1.5492-0.64 0.65094-1.1365 1.4107-1.4897 2.279-0.3531 0.86821-0.5297 1.7888-0.5297 2.7623 0 1.0129 0.1734 1.9537 0.5197 2.8219 0.3468 0.8683 0.8366 1.6213 1.4698 2.2593 0.6327 0.6379 1.3962 1.1379 2.289 1.4994 0.8929 0.362 1.886 0.5426 2.979 0.5426 0.6128 0 1.1993-0.0556 1.7589-0.1676 0.5601-0.1115 1.083-0.2661 1.5696-0.4637 0.4866-0.1971 0.9396-0.4274 1.3595-0.6903 0.4199-0.2635 0.803-0.5394 1.1497-0.8293z" />
        <path
          d="m22.538 3.1589c2.6621 0 4.8397 2.1856 4.8397 4.8571 0 2.6715-2.1776 4.8572-4.8397 4.8572-2.6617 0-4.8394-2.1857-4.8394-4.8572 0-2.6715 2.1777-4.8571 4.8394-4.8571zm0-2.2857c-3.9142 0-7.1168 3.2144-7.1168 7.1429 0 3.929 3.2026 7.1429 7.1168 7.1429 3.9145 0 7.1172-3.2139 7.1172-7.1429 0-3.9285-3.2027-7.1429-7.1172-7.1429z"
          className="core"
        />
        <path
          d="m34.817 0.85714h-5.4918c1.8569 1.8027 3.0172 4.3402 3.0172 7.1429 0 2.8027-1.1603 5.3401-3.0172 7.1429h5.4918c3.8691 0 7.0341-3.214 7.0341-7.1429 0-3.9285-3.165-7.1429-7.0341-7.1429z"
          className="core"
        />
      </svg>
    </S.ToggleLogotypeRoot>
  );
};
