import { deviceConstraints } from '@toggle/design-system';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import ToggleLogo from '~/assets/core/toggle-logo.svg';
import ToggleLogoMini from '~/assets/core/toggle-logo-mobile.svg';

export const Container = styled.header`
  position: fixed;
  top: 0;
  z-index: var(--z-index-app-header);
  display: flex;
  align-items: center;
  width: 100vw;
  height: var(--app-header-height);
  padding: 10px 6px;
  border-bottom: 1px solid var(--base--70);
  background-color: var(--base--100);
  color: var(--base--0);
  gap: 28px;
  width: 100%;

  @media ${deviceConstraints.desktop} {
    padding: 12px 24px;
  }
  @media print {
    display: none;
  }
`;

export const LogoContainer = styled.section`
  grid-area: 1 / 1 / 2 / 2;
  height: 24px;
  display: flex;
  @media ${deviceConstraints.desktopSmall} {
    align-items: center;
  }
`;
export const MainMenu = styled.section`
  flex: 1;
  justify-self: center;
  display: flex;
  justify-content: center;
`;

const sharedLogo = css`
  height: 24px;

  margin-left: 24px;
  [class*='logoColor'] {
    fill: var(--primary-color);
  }

  @media ${deviceConstraints.desktopSmall} {
    height: 24px;
    margin-left: 0px;
    width: 128px;
  }
`;
export const ToggleLogoMiniStyled = styled(ToggleLogoMini)`
  ${sharedLogo};
`;

export const ToggleLogoStyled = styled(ToggleLogo)`
  ${sharedLogo};
`;

export const ToggleLink = styled(Link)`
  display: flex;
`;

export const ProBadge = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  padding: 1px 3px;
  margin-left: 4px;
  border-radius: 4px;
  background-color: var(--blue-dark);
  color: var(--text-link);
  text-transform: uppercase;
  font-size: 11px;
  line-height: 14px;
`;
