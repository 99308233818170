import { borderRadius, ButtonLink, typographyV2 } from '@toggle/design-system';
import styled from 'styled-components';

import { SimpleChart } from '~/views/analyze/asset-overview/components/sidebar/components/insight-modal/simple-chart/SimpleChart';

export const GtkDetailsRoot = styled.div`
  display: flex;
  flex-direction: column;
`;

export const BackButton = styled(ButtonLink)`
  margin-bottom: 24px;
  align-self: flex-start;
`;

export const ChartContainer = styled.div`
  ${borderRadius.r3}
  border: 1px solid var(--border-soft);
  padding: 12px;
`;

export const StyledSimpleChart = styled(SimpleChart)`
  height: 100px;
`;

export const ChartTitle = styled.p`
  ${typographyV2.bodyBase}
  margin-bottom: 4px;
`;

export const ChartRange = styled.p`
  margin-top: 4px;
  ${typographyV2.titleXs}
  color: var(--text-soft);
`;

export const TitleContainer = styled.div`
  padding: 4px 12px;
  display: flex;
  margin-bottom: 12px;
  justify-content: space-between;
`;

export const Title = styled.p`
  ${typographyV2.titleMd}
`;

export const TitleImgWrapper = styled.div`
  margin-left: 12px;
  width: 24px;
`;

export const GtkDescription = styled.p`
  margin-top: 12px;
  ${typographyV2.bodySm}
  color: var(--text-soft);
`;
