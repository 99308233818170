import debounce from 'lodash/debounce';
import { useEffect, useRef, useState } from 'react';

import { EntitySearchType } from '~/api/entities/entity-constants';
import { Entity, UseTagSearchParams } from '~/api/entities/entity-schema';
import { postEntitiesByQuery } from '~/api/entities/entity-service';
import { QUERY_DEBOUNCE_OPTS, QUERY_DELAY } from '~/config/constants';
import { Tracking } from '~/shared/services/tracking';

interface SnakeDescriptionBasicWeighted {
  description: string;
  snake: string;
  pos_weight: number;
  weight: number;
}

export interface TimeSeriesSnakesQueryResponse {
  query_text: string;
  result: SnakeDescriptionBasicWeighted[];
}

interface TagSearchProps {
  searchType: EntitySearchType;
  params?: UseTagSearchParams;
}

interface UseTagsData<T> {
  tags: T[];
}

interface UseTagSearchData<T> extends UseTagsData<T> {
  query: string;
}

interface UseTagSearchResult<T = Entity | TimeSeriesSnakesQueryResponse>
  extends UseTagSearchData<T> {
  searchTags(query: string): void;
  loading: boolean;
}

const FETCH_BY_QUERY = {
  [EntitySearchType.Entity]: (query: string, params?: UseTagSearchParams) =>
    postEntitiesByQuery(query, EntitySearchType.Entity, params),
  [EntitySearchType.Currency]: (query: string, params?: UseTagSearchParams) =>
    postEntitiesByQuery(query, EntitySearchType.Currency, params),
  [EntitySearchType.Economies]: (query: string, params?: UseTagSearchParams) =>
    postEntitiesByQuery(query, EntitySearchType.Economies, params),
  [EntitySearchType.EntityAndEconomies]: (
    query: string,
    params?: UseTagSearchParams
  ) => postEntitiesByQuery(query, EntitySearchType.EntityAndEconomies, params),
};

const isSnakeResponse = (
  result: Entity[] | TimeSeriesSnakesQueryResponse
): result is TimeSeriesSnakesQueryResponse => {
  return 'result' in result;
};

export const extractTags = (
  fetchResult: Entity[] | TimeSeriesSnakesQueryResponse
): Entity[] | SnakeDescriptionBasicWeighted[] =>
  isSnakeResponse(fetchResult) ? fetchResult.result : fetchResult;

export function useTagsSearch<
  T extends Entity | SnakeDescriptionBasicWeighted = Entity
>({ searchType, params }: TagSearchProps): UseTagSearchResult<T> {
  const [search, setSearch] = useState<UseTagSearchData<T>>({
    query: '',
    tags: [],
  });
  const [loading, setLoading] = useState(false);
  const [inputQuery, setInputQuery] = useState<string>('');
  const prevQuery = useRef<string>();

  const debouncedTagsSearchQuery = useRef(
    debounce(
      async (query: string, searchType: EntitySearchType) => {
        prevQuery.current = query;
        if (!query) {
          setSearch({ query: '', tags: [] });
        } else {
          try {
            setLoading(true);

            const fetchResult = await FETCH_BY_QUERY[searchType](query, params);

            if (fetchResult instanceof Error) {
              throw fetchResult;
            }

            if (prevQuery.current === query) {
              setSearch({ query, tags: extractTags(fetchResult) as T[] });
            }
          } catch (error) {
            Tracking.captureException(error as Error);
            setSearch({ query, tags: [] });
          } finally {
            setLoading(false);
          }
        }
      },
      QUERY_DELAY,
      QUERY_DEBOUNCE_OPTS
    )
  ).current;

  const searchTags = (query: string) => {
    setLoading(true);
    setInputQuery(query);
    if (inputQuery !== search.query) {
      setSearch({ ...search, query });
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    debouncedTagsSearchQuery(inputQuery, searchType);
    return debouncedTagsSearchQuery.cancel;
  }, [inputQuery, searchType]);

  return { query: inputQuery, tags: search.tags, loading, searchTags };
}
