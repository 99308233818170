import { queryString } from '@toggle/helpers';
import React, { FC } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { appPaths, authPaths, queryKeys } from '~/routes/app-paths';
import { useSubscription } from '~/stores/use-subscription/useSubscription';
import { useUser } from '~/stores/use-user/useUser';
import { ArticleContent } from '~/views/article/content/ArticleContent';
import { useArticleContext } from '~/views/article/context/ArticleContext';
import { NotFound } from '~/views/error/not-found/NotFound';

import * as S from './ArticleView.styles';

export const ArticleView: FC = () => {
  const { data, isFetching, limitError, isError } = useArticleContext();
  const article = data.article;
  const location = useLocation();
  const isAuthenticated = useUser(state => state.isAuthenticated());
  const isBasic = useSubscription(state => state.isBasic());

  const redirectRoute = isAuthenticated
    ? appPaths.upgrade
    : authPaths.login +
      queryString({
        [queryKeys.redirect]: location.pathname,
        [queryKeys.limited]: true,
      });

  return (
    <>
      {isFetching && <S.StyledLoader />}
      {article && !isFetching && (
        <ArticleContent isAuthenticated={isAuthenticated} isBasic={isBasic} />
      )}
      {!isFetching &&
        isError &&
        (limitError ? <Navigate to={redirectRoute} replace /> : <NotFound />)}
    </>
  );
};
