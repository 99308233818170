import styled, { css } from 'styled-components';

import { borderRadius } from '~/design-tokens/border-radius/borderRadius';
import { typographyV2 } from '~/design-tokens/typography/Typography.styles';

import { Icon } from '../icon/Icon';
import { Tooltip } from '../tooltip/Tooltip';
import { CheckboxVariant } from './CheckBox';

type CheckBoxColors = {
  [variant in CheckboxVariant]: {
    hoverBorderColor?: string;
    backgroundColor?: string;
    borderColor: string;
    checkedBackgroundColor: string;
    checkedBorderColor: string;
  };
};

interface CheckboxLabelProps {
  $size: 'medium' | 'small';
  $variant: CheckboxVariant;
  $locked: boolean;
  $isChecked: boolean;
}

const colors: CheckBoxColors = {
  primary: {
    hoverBorderColor: 'var(--border-hover)',
    borderColor: 'var(--border-default)',
    checkedBackgroundColor: 'var(--surface-primary)',
    checkedBorderColor: 'var(--border-primary)',
  },
  critical: {
    borderColor: 'var(--border-critical)',
    checkedBackgroundColor: 'var(--surface-critical)',
    checkedBorderColor: 'var(--border-critical)',
  },
  disabled: {
    borderColor: 'var(--border-disabled)',
    checkedBackgroundColor: 'var(--surface-neutral)',
    checkedBorderColor: 'var(--surface-neutral)',
  },
};

const setColors = (variant: CheckboxVariant, isChecked?: boolean) => {
  const {
    hoverBorderColor,
    backgroundColor,
    borderColor,
    checkedBackgroundColor,
    checkedBorderColor,
  } = colors[variant];

  return css`
    border-color: ${isChecked ? checkedBorderColor : borderColor};
    background-color: ${isChecked ? checkedBackgroundColor : backgroundColor};
    &:hover {
      border-color: ${isChecked ? checkedBorderColor : hoverBorderColor};
    }
  `;
};

export const StyledTooltip = styled(Tooltip)`
  width: 220px;
  padding: 10px 14px;
  pointer-events: none;
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CheckboxElement = styled.input`
  position: absolute;
  appearance: none;
`;

export const CheckIcon = styled(Icon)`
  fill: var(--text-on-primary);
`;

export const Checkmark = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  height: 18px;
  width: 18px;
  margin-right: 8px;
  margin-top: 3px;
  border: 1px solid var(--border-default);
  ${borderRadius.r1}

  ${CheckboxElement}:focus-visible + & {
    outline-offset: 2px;
    outline: var(--border-focus) solid 2px;
  }
`;

export const CheckboxLabel = styled.span<{ $withTooltip: boolean }>`
  border-bottom: ${props =>
    props.$withTooltip && '1px dashed var(--border-default)'};
`;

export const Checkbox = styled.label<CheckboxLabelProps>`
  display: inline-flex;
  cursor: ${props => props.$variant !== 'disabled' && 'pointer'};

  ${Checkmark} {
    ${({ $variant, $isChecked }) => setColors($variant, $isChecked)};
  }

  &:hover ${Checkmark} {
    border-color: ${({ $variant, $isChecked }) =>
      $isChecked
        ? colors[$variant].checkedBorderColor
        : colors[$variant].hoverBorderColor};
  }

  ${CheckboxLabel} {
    ${props =>
      props.$size === 'medium' ? typographyV2.bodyBase : typographyV2.bodySm};
    color: ${props =>
      props.$locked || props.$variant === 'disabled'
        ? 'var(--surface-neutral)'
        : 'var(--text-default)'};
  }

  ${CheckIcon} {
    display: ${({ $isChecked }) => ($isChecked ? 'inline-flex' : 'none')};
  }
`;

export const LockIcon = styled(Icon)`
  margin-right: 12px;
  fill: var(--surface-neutral);
`;

export const ErrorText = styled.p`
  display: block;
  text-align: left;
  color: var(--text-critical);
  ${typographyV2.bodySm}
`;
