export const APIResponse = {
  NOT_FOUND: 404,
  SERVER_ERROR: 500,
  UNAUTHORISED: 401,
  BAD_REQUEST: 400,
  UPGRADE_REQUIRED: 426,
  TOO_MANY_REQUESTS: 429,
  PRECONDITION: 428,
  PAYMENT_REQUIRED: 402,
  MISDIRECTED_REQUEST: 421,
};

export enum HttpMethods {
  Get = 'get',
  Post = 'post',
  Delete = 'delete',
  Put = 'put',
}
