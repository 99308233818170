import { Domain } from '~/types/axis.types';

export interface DataObject {
  [key: string]: number;
}

interface MinMax {
  min: string;
  max: string;
}

export function extent(data: DataObject[], minMax: MinMax): Domain {
  if (!data.length) {
    return [0, 0];
  }

  let min = data[0][minMax.min];
  let max = data[0][minMax.max];

  for (const item of data) {
    if (item[minMax.min] < min) {
      min = item[minMax.min];
    }
    if (item[minMax.max] > max) {
      max = item[minMax.max];
    }
  }

  return [min, max];
}
