import { format, isWithinInterval, parse } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { TFunction } from 'i18next';

import { PreliminaryPrice } from '~/api/price/price-schema';
import { SnakeMeta } from '~/api/timeseries/timeseries-schema';
import { LivePriceInfo } from '~/shared/hooks/use-live-price/use-live-price-store/useLivePriceStore';
import { EST_TIME_ZONE } from '~/views/analyze/utils/asset-box/asset-box-helpers';

import { PriceBase, PriceDisplayReturn } from './price-display.types';
import { formatPrimaryPrice } from './primary/primary-price';
import { formatSecondaryPrice } from './secondary/secondary-price';

interface MapPriceDisplayProps {
  exchange: string;
  livePriceInfo?: LivePriceInfo;
  preliminaryPrice?: PreliminaryPrice;
  meta?: SnakeMeta;
  isCurrency: boolean;
  isHovered: boolean;
  t: TFunction;
}

/**
 * Main formatting function that returns price details for v2 price display
 *
 * Details: https://app.clickup.com/10607188/v/dc/a3pjm-10088/a3pjm-15448
 * @returns primary price and secondary price details
 */
export const formatPriceDisplay = ({
  exchange,
  livePriceInfo,
  preliminaryPrice,
  meta,
  isCurrency,
  isHovered,
  t,
}: MapPriceDisplayProps): PriceDisplayReturn => {
  const isSupported = checkSupportedExchange(exchange);
  const marketHours = getMarketHours();

  const livePrice =
    livePriceInfo?.lastKnownPrice !== undefined
      ? livePriceInfo.lastKnownPrice
      : meta?.last_value;
  const price =
    livePriceInfo?.price !== undefined ? livePriceInfo.price : livePrice;

  const base: PriceBase = {
    price,
    priceChange: livePriceInfo?.priceChange,
    isLive: livePriceInfo?.isLive,
    marketHours,
    isSupported,
    asOfDate:
      livePriceInfo?.time && isHovered
        ? format(Date.parse(livePriceInfo?.time), 'PPP')
        : undefined,
  };

  return {
    primary:
      isSupported && !isHovered
        ? formatPrimaryPrice(base, isCurrency, meta, preliminaryPrice)
        : base,
    secondary: isSupported
      ? formatSecondaryPrice(base, t, livePriceInfo)
      : undefined,
  };
};

/**
 * Function that returns an object of boolean values representing different states of market hours based on EST timezone
 *
 * Details: https://app.clickup.com/10607188/v/dc/a3pjm-10088/a3pjm-15448?block=block-fb9fdbe5-3e88-4a90-8a9c-15927f7de1cf
 */
export const getMarketHours = () => {
  const utcZoned = utcToZonedTime(new Date(), EST_TIME_ZONE);

  const dayStart = parse('00:00:00', 'HH:mm:ss', utcZoned);
  const coreTradingStart = parse('9:30:00', 'HH:mm:ss', utcZoned);
  const coreTradingEnd = parse('16:00:00', 'HH:mm:ss', utcZoned);
  const dayEnd = parse('23:59:59', 'HH:mm:ss', utcZoned);

  const isPreMarket = isWithinInterval(utcZoned, {
    start: dayStart,
    end: coreTradingStart,
  });
  const isMarketHours = isWithinInterval(utcZoned, {
    start: coreTradingStart,
    end: coreTradingEnd,
  });
  const isAfterMarket = isWithinInterval(utcZoned, {
    start: coreTradingEnd,
    end: dayEnd,
  });

  return {
    isPreMarket,
    isAfterMarket,
    isMarketHours,
  };
};

/**
 * Returns a boolean value for if a specific exchange is supported to display v2 price display
 *
 * Details: https://app.clickup.com/10607188/v/dc/a3pjm-10088/a3pjm-15448?block=block-9e8a31ee-2874-41c1-a8fe-158a9bd178e4
 */
export const checkSupportedExchange = (exchange: string) => {
  return ['nyse', 'nasd', 'arca', 'bats'].includes(exchange);
};
