import React, {
  createContext,
  MouseEvent,
  MutableRefObject,
  PropsWithChildren,
  RefObject,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { ZendeskAPI } from 'react-zendesk';

import { config } from '~/config';
import { OptionalN } from '~/declarations/standard';
import { appPaths, queryKeys } from '~/routes/app-paths';
import { Breadcrumb } from '~/shared/components/breadcrumbs/Breadcrumbs';
import {
  STORAGE_KEYS,
  useRemoteStorage,
} from '~/shared/hooks/use-remote-storage';
import {
  gaHelperClickHome,
  gaHelperToggleExpand,
} from '~/shared/utils/ganalytics';
import { AnalyzeViews } from '~/views/analyze/AnalyzeViews';
import {
  AllChecksToBeActivated,
  HELPER_VIEW_IDS,
  HelperConfigItem,
} from '~/views/helper/HelperConfig';
import { helperViewConfigs } from '~/views/helper/HelperViewConfigs';

const { supportUrl } = config;

export interface HighlightedSectionsRefs {
  [key: string]: RefObject<HTMLDivElement>;
}

interface HelperStoreContextValue {
  isExpanded: boolean;
  expandHelper: () => void;
  collapseHelper: () => void;
  hoveredUiSectionId: AllChecksToBeActivated | null;
  setHelperHoverBehavior: (id: AllChecksToBeActivated) => {
    onMouseOver: (e: React.MouseEvent) => void;
    onMouseLeave: () => void;
  };
  currentViewTitle: string;
  currentViewGa: MutableRefObject<string>;
  isTriggerTooltipShownOnLoad: boolean;
  setIsTriggerTooltipShownOnLoad: React.Dispatch<React.SetStateAction<boolean>>;
  currentHelperViewKeyState: HELPER_VIEW_IDS;
  currentAppViewKey: HELPER_VIEW_IDS | undefined;
  setCurrentHelperViewKey: (viewKey: HELPER_VIEW_IDS) => void;
  setCurrentAppViewKey: (viewKey: HELPER_VIEW_IDS | undefined) => void;
  isCurrentViewChecked: boolean;
  translationKey: string;
  Component: React.ComponentType<any>;
  currentViewBreadcrumbs: Breadcrumb[];
  isZendeskOnline: boolean;
  setIsZendeskOnline: React.Dispatch<React.SetStateAction<boolean>>;
  isHoveredUiNotMastered: boolean;
}

const HelperStoreContext = createContext<HelperStoreContextValue>(
  {} as HelperStoreContextValue
);

export function HelperStore({ children }: PropsWithChildren<{}>) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [hoveredUiSectionId, setHoveredUiSectionId] =
    useState<AllChecksToBeActivated | null>(null);
  const [currentHelperViewKeyState, setCurrentHelperViewKeyState] =
    useState<HELPER_VIEW_IDS>(HELPER_VIEW_IDS.HOME);
  const [currentAppViewKey, setStateCurrentAppViewKey] = useState<
    HELPER_VIEW_IDS | undefined
  >();
  const [isCurrentViewChecked, setIsCurrentViewChecked] = useState(true);
  const [currentViewTitle, setCurrentViewTitle] = useState('');
  const currentViewGa = useRef('');
  const [isZendeskOnline, setIsZendeskOnline] = useState(false);
  const [isTriggerTooltipShownOnLoad, setIsTriggerTooltipShownOnLoad] =
    useState(false);

  const { t } = useTranslation('helperWidget');
  const location = useLocation();
  const storageItems = useRemoteStorage(state => state.items);

  const analyzeHelperViews = {
    [AnalyzeViews.ASSET_OVERVIEW]: HELPER_VIEW_IDS.ANALYZE,
    [AnalyzeViews.SCENARIO]: HELPER_VIEW_IDS.SCENARIO,
    [AnalyzeViews.CHART]: undefined,
    [AnalyzeViews.POSITIONS]: undefined,
  };

  const {
    Component,
    translationKey,
    breadcrumbs = [],
  } = helperViewConfigs[currentHelperViewKeyState] as HelperConfigItem;
  const currentViewBreadcrumbs: Breadcrumb[] = breadcrumbs.map(
    ({ linkTo, ...crumb }) => ({
      ...crumb,
      text: t(`helperWidget:breadcrumbs.${crumb.text}`),
      onClick: linkTo
        ? () => {
            linkTo === HELPER_VIEW_IDS.HOME &&
              gaHelperClickHome(currentViewGa.current);
            setCurrentHelperViewKey(linkTo as HELPER_VIEW_IDS);
          }
        : undefined,
    })
  );
  const isHoveredUiNotMastered =
    storageItems &&
    hoveredUiSectionId &&
    !storageItems[hoveredUiSectionId as STORAGE_KEYS];

  const expandHelper = () => {
    if (!currentAppViewKey) {
      return;
    }
    gaHelperToggleExpand(true, currentViewGa.current);
    setCurrentHelperViewKey(currentAppViewKey);
    setIsTriggerTooltipShownOnLoad(false);
    setIsExpanded(true);
  };

  const collapseHelper = () => {
    gaHelperToggleExpand(false, currentViewGa.current);
    setIsExpanded(false);
  };

  const setHelperHoverBehavior = (id: AllChecksToBeActivated) => ({
    onMouseOver: (e: MouseEvent) => {
      e.stopPropagation();
      if (hoveredUiSectionId !== id) {
        setHoveredUiSectionId(id);
      }
    },
    onMouseLeave: () => setHoveredUiSectionId(null),
  });

  const setCurrentAppViewKey = (viewKey: HELPER_VIEW_IDS | undefined) => {
    setStateCurrentAppViewKey(viewKey);
    setCurrentHelperViewKey(viewKey ?? HELPER_VIEW_IDS.HOME);
    setIsExpanded(false);
  };

  const checkIfCurrentViewChecked = (viewKey: HELPER_VIEW_IDS) => {
    let isEveryChecked = false;
    const allToBeChecked = helperViewConfigs[viewKey]?.allChecks;
    if (storageItems && allToBeChecked) {
      isEveryChecked = allToBeChecked.every(
        key => storageItems[key as STORAGE_KEYS] === 'active'
      );
    }
    return isEveryChecked;
  };

  const setCurrentHelperViewKey = (viewKey: HELPER_VIEW_IDS) => {
    const isEveryChecked = checkIfCurrentViewChecked(viewKey);
    // !isExpanded responsible for redirecting to HOME on helper EXPAND ONLY
    let newViewKey =
      isEveryChecked && !isExpanded ? HELPER_VIEW_IDS.HOME : viewKey;
    if (newViewKey === HELPER_VIEW_IDS.SUPPORT) {
      newViewKey = HELPER_VIEW_IDS.HOME;
      isZendeskOnline
        ? ZendeskAPI('webWidget', 'open')
        : window.open(supportUrl, '_blank');
    }

    setCurrentHelperViewKeyState(newViewKey);
    setCurrentViewTitle(
      t(`helperWidget:${helperViewConfigs[newViewKey]?.translationKey}.title`)
    );
    currentViewGa.current = helperViewConfigs[newViewKey]?.gaName ?? '';
  };

  useEffect(() => {
    const isEveryChecked = checkIfCurrentViewChecked(currentHelperViewKeyState);
    setIsCurrentViewChecked(isEveryChecked);
  }, [storageItems, currentHelperViewKeyState]);

  // switching between Analyze tabs doesn't trigger route change,
  // so we need to check tabs change manually
  useEffect(() => {
    if (location.pathname.includes(appPaths.analyze)) {
      const queryParams = new URLSearchParams(location.search);
      const viewKey = queryParams.get(
        queryKeys.view
      ) as OptionalN<AnalyzeViews>;
      setCurrentAppViewKey(
        viewKey ? analyzeHelperViews[viewKey] : HELPER_VIEW_IDS.ANALYZE
      );
    }
  }, [location]);

  useEffect(() => {
    let timer = 0;
    if (
      !isExpanded &&
      currentHelperViewKeyState !== HELPER_VIEW_IDS.HOME &&
      !checkIfCurrentViewChecked(currentHelperViewKeyState)
    ) {
      setIsTriggerTooltipShownOnLoad(true);
      timer = window.setTimeout(() => {
        setIsTriggerTooltipShownOnLoad(false);
      }, 6000);
    }
    return () => {
      clearTimeout(timer);
      setIsTriggerTooltipShownOnLoad(false);
    };
  }, [currentHelperViewKeyState]);

  const helperState: HelperStoreContextValue = {
    currentHelperViewKeyState,
    currentAppViewKey,
    isCurrentViewChecked,
    isExpanded,
    isZendeskOnline,
    translationKey,
    hoveredUiSectionId,
    currentViewTitle,
    currentViewGa,
    isTriggerTooltipShownOnLoad,
    Component,
    currentViewBreadcrumbs,
    isHoveredUiNotMastered: !!isHoveredUiNotMastered,
    expandHelper,
    collapseHelper,
    setHelperHoverBehavior,
    setCurrentAppViewKey,
    setCurrentHelperViewKey,
    setIsZendeskOnline,
    setIsTriggerTooltipShownOnLoad,
  };

  return (
    <HelperStoreContext.Provider value={helperState}>
      {children}
    </HelperStoreContext.Provider>
  );
}

export function useHelper() {
  return useContext(HelperStoreContext);
}
