import { TFunction } from 'i18next';
import React, { forwardRef } from 'react';

import { ArticleV3 } from '~/declarations/toggle-api-news';
import { Fundamentals } from '~/views/article/partials/fundamentals-section';

import { ImageOverlay } from '../image-overlay';
import * as S from './WhyItMightMove.styles';

interface WhyItMightMoveProps {
  data: ArticleV3;
  imgName: string;
  t: TFunction;
}

export const WhyItMightMove = forwardRef<
  HTMLHeadingElement,
  WhyItMightMoveProps
>(({ imgName, data, t }, ref) => {
  return (
    <S.Container>
      <S.Title ref={ref}>{t('article:whyItMightMove.title')}</S.Title>
      <ImageOverlay
        link={imgName}
        imageName={`${data.entity.tag_name}-history`}
      >
        <img src={imgName} alt="chart" />
      </ImageOverlay>
      <Fundamentals t={t} data={data} />
    </S.Container>
  );
});
