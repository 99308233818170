import { RefObject, useEffect } from 'react';

export interface UseChatScrollParams {
  messages: Array<unknown>;
  chatContentRef: RefObject<HTMLDivElement>;
  activeMessageIdx: number;
}

export const useChatScroll = ({
  messages,
  chatContentRef,
  activeMessageIdx,
}: UseChatScrollParams) => {
  useEffect(() => {
    const scrollContainer = chatContentRef.current;
    const activeMessageNode =
      scrollContainer &&
      (scrollContainer.children[activeMessageIdx] as HTMLElement);
    const isFullyVisible =
      activeMessageNode &&
      activeMessageNode.offsetTop >= scrollContainer.scrollTop &&
      activeMessageNode.offsetTop + activeMessageNode.offsetHeight <=
        scrollContainer.scrollTop + scrollContainer.offsetHeight;

    if (!activeMessageNode || isFullyVisible) {
      return;
    }

    const scrollToLast = activeMessageIdx === messages.length - 1;

    activeMessageNode.scrollIntoView({
      block: scrollToLast ? 'start' : 'center',
      behavior: 'smooth',
    });
  }, [activeMessageIdx, messages]);
};
