import { deviceConstraints, Icon, typography } from '@toggle/design-system';
import styled, { css } from 'styled-components';

export const BaseDropdownMenu = css`
  background-color: var(--base--90);
  border: 1px solid var(--base--70);
  border-radius: 4px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 0px 1px rgba(0, 0, 0, 0.3);
  width: 280px;
  position: absolute;
`;

export const BaseDropdownListItem = css`
  display: grid;
  grid-template-rows: auto;
  gap: 10px;
  padding: 8px 12px;
  align-items: center;
  &:hover {
    background-color: var(--base--80);
    cursor: pointer;
  }
`;

export const DropdownMenu = styled.div`
  ${BaseDropdownMenu};
  right: 0;
  top: calc(100% + 14px);

  @media ${deviceConstraints.tablet} {
    top: calc(100% + 8px);
  }
`;

export const ProfileSection = styled.div`
  padding: 12px;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: 'image details';
  align-items: center;
`;

export const ProfileImage = styled.img`
  grid-area: image;
`;

export const ProfileDetails = styled.div`
  grid-area: details;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto;
  grid-template-areas:
    'name'
    'subscription';
  margin-left: 16px;
`;

export const ProfileName = styled.p`
  grid-area: name;
  ${typography.emu};
`;

export const ProfileSubscription = styled.p`
  grid-area: subscription;
  ${typography.quokka};
  color: var(--primary-color);
  text-transform: capitalize;
`;

export const DropdownList = styled.ul`
  list-style-type: none;
`;

export const DropdownListItem = styled.li`
  ${typography.emu};
  ${BaseDropdownListItem};
  grid-template-columns: auto 1fr auto;
  grid-template-areas: 'icon label badge';
`;

export const BadgeContainer = styled.div`
  grid-area: badge;
  display: inline-flex;
  align-items: center;
`;

export const DividerLine = styled.div`
  height: 1px;
  width: 100%;
  background-color: var(--base--70);
`;

export const CenteredIcon = styled(Icon)`
  align-self: center;
`;
