import React from 'react';
import { useTranslation } from 'react-i18next';

import { FAQ_SECTION_IDS } from '~/views/helper/HelperConfig';

import { HelperWidgetSection } from '../../partials/accordion/HelperWidgetAccordion';
import { HelperContentProps } from '../content';
import { BaseFaqContent } from './BaseFaqContent';
import { ConnectPortfolioContent } from './ConnectPortfolioContent';
import { DataSourceContent } from './DataSourceContent';
import * as S from './FaqHelperContent.styles';
import { InsightContent } from './InsightContent';
import { InvestContent } from './InvestContent';
import { NoInsightsContent } from './NoInsightsContent';
import { PrivacyContent } from './PrivacyContent';
import { WhatActionableContent } from './WhatActionableContent';

export const FaqHelperContent = (props: HelperContentProps) => {
  const { t } = useTranslation('helperWidget');

  const sections: HelperWidgetSection[] = [
    {
      id: FAQ_SECTION_IDS.FAQ_INSIGHTS_ID,
      title: t('helperWidget:faq.insight.title'),
      gaTitle: 'What is a TOGGLE Insight?',
      content: <InsightContent />,
      canBeChecked: false,
      separateSection: true,
      hideMarker: true,
    },
    {
      id: FAQ_SECTION_IDS.FAQ_INVEST_ID,
      title: t('helperWidget:faq.invest.title'),
      gaTitle: 'How can TOGGLE help me invest?',
      content: <InvestContent />,
      canBeChecked: false,
      separateSection: true,
      hideMarker: true,
    },
    {
      id: FAQ_SECTION_IDS.FAQ_ANALYZE_ID,
      title: t('helperWidget:faq.analyze.title'),
      gaTitle: 'How often does TOGGLE analyze assets?',
      content: <BaseFaqContent tKey={'helperWidget:faq.analyze.content'} />,
      canBeChecked: false,
      separateSection: true,
      hideMarker: true,
    },
    {
      id: FAQ_SECTION_IDS.FAQ_ASSETS_COVER_ID,
      title: t('helperWidget:faq.assetsCover.title'),
      gaTitle: 'What assets does TOGGLE Cover?',
      content: <BaseFaqContent tKey={'helperWidget:faq.assetsCover.content'} />,
      canBeChecked: false,
      separateSection: true,
      hideMarker: true,
    },
    {
      id: FAQ_SECTION_IDS.FAQ_NO_INSIGHTS_ID,
      title: t('helperWidget:faq.noInsights.title'),
      gaTitle: 'Why are there no Insights for my stock?',
      content: <NoInsightsContent />,
      canBeChecked: false,
      separateSection: true,
      hideMarker: true,
    },
    {
      id: FAQ_SECTION_IDS.FAQ_PORTFOLIO_ID,
      title: t('helperWidget:faq.portfolio.title'),
      gaTitle: 'Connecting Your Portfolio',
      content: <BaseFaqContent tKey={'helperWidget:faq.portfolio.content'} />,
      canBeChecked: false,
      separateSection: true,
      hideMarker: true,
    },
    {
      id: FAQ_SECTION_IDS.FAQ_BROKER_COVER_ID,
      title: t('helperWidget:faq.brokerCover.title'),
      gaTitle: 'What Brokers do you cover?',
      content: <BaseFaqContent tKey={'helperWidget:faq.brokerCover.content'} />,
      canBeChecked: false,
      separateSection: true,
      hideMarker: true,
    },
    {
      id: FAQ_SECTION_IDS.FAQ_CONNECT_PORTFOLIO_ID,
      title: t('helperWidget:faq.connectPortfolio.title'),
      gaTitle: 'How does TOGGLE connect to my portfolio?',
      content: <ConnectPortfolioContent zendeskOnline={props.zendeskOnline} />,
      canBeChecked: false,
      separateSection: true,
      hideMarker: true,
    },
    {
      id: FAQ_SECTION_IDS.FAQ_PRIVACY_ID,
      title: t('helperWidget:faq.privacy.title'),
      gaTitle: 'How safe is my information?',
      content: <PrivacyContent />,
      canBeChecked: false,
      separateSection: true,
      hideMarker: true,
    },
    {
      id: FAQ_SECTION_IDS.FAQ_WHAT_ACTIONABLE_ID,
      title: t('helperWidget:faq.whatActionable.title'),
      gaTitle: 'How do we determine what is actionable?',
      content: <WhatActionableContent />,
      canBeChecked: false,
      separateSection: true,
      hideMarker: true,
    },
    {
      id: FAQ_SECTION_IDS.FAQ_DATA_SOURCE_ID,
      title: t('helperWidget:faq.dataSource.title'),
      gaTitle: 'Where do you get your data?',
      content: <DataSourceContent />,
      canBeChecked: false,
      separateSection: true,
      hideMarker: true,
    },
  ];

  return <S.FaqAccordion sections={sections} sendGaOnExpand {...props} />;
};
