import ReactGA from 'react-ga4';

const CATEGORIES = {
  watchlist: 'Watchlist',
};
export { CATEGORIES as WATCHLIST_CATEGORIES };

const ACTIONS = {
  watchlistLimitHit: 'Watchlist_limit_hit',
  watchlistAdd: 'Add',
  watchlistRemove: 'Remove',
};
export { ACTIONS as WATCHLIST_ACTIONS };

export function gaWatchlistHitLimit(
  tag: string,
  location: 'watchlist-button' | 'my-account'
) {
  ReactGA.event({
    category: CATEGORIES.watchlist,
    action: ACTIONS.watchlistLimitHit,
    label: `tag:${tag};location:${location}`,
  });
}

type LocationType =
  | 'search'
  | 'popular-security-item'
  | 'analyze-sidebar-tabs'
  | 'analyze-header';

export function gaWatchlistBtnToggle(
  isActive: boolean,
  tagName: string,
  location: LocationType
) {
  ReactGA.event({
    category: CATEGORIES.watchlist,
    action: isActive ? ACTIONS.watchlistAdd : ACTIONS.watchlistRemove,
    label: `tagName:${tagName};location:${location}`,
  });
}
