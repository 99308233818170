import * as Sentry from '@sentry/react';
import { CaptureContext } from '@sentry/types';
import React from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

import { config } from '~/config';

const initialise = () => {
  if (!config.app.isLocalEnvironment) {
    Sentry.init({
      dsn: config.sentry.dsn,
      integrations: [
        new Sentry.BrowserTracing({
          routingInstrumentation: Sentry.reactRouterV6Instrumentation(
            React.useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes
          ),
        }),
      ],
      tracesSampleRate: 0.5,
      environment: config.app.environment,
    });
  }
};

const captureException = (
  exception: Error | unknown,
  captureContext?: CaptureContext
) =>
  config.app.isLocalEnvironment
    ? console.log({
        sentry: exception,
        ...(captureContext && { captureContext }),
      })
    : Sentry.captureException(exception, captureContext);

const captureMessage = (message: string) =>
  config.app.isLocalEnvironment
    ? console.log({ sentry: message })
    : Sentry.captureMessage(message);

export const Tracking = {
  initialise,
  captureException,
  captureMessage,
};
