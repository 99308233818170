import React from 'react';
import { useTranslation } from 'react-i18next';

import * as S from '../Content.styles';

export function SharingInsightContent() {
  const { t } = useTranslation('helperWidget');

  return (
    <S.Content>
      <S.Paragraph>
        {t('helperWidget:insight.content.sharingInsight.firstParagraph')}
      </S.Paragraph>
    </S.Content>
  );
}
