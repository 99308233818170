import { StoreApi } from 'zustand';

import { ENDPOINT_URLS } from '~/global/toggle-api';
import { APIFetch } from '~/shared/services/api-fetch';

import {
  ConditionsTemplatesResponse,
  ExperimentStoreState,
} from '../../useExperimentStore';

type TemplateConditionsSliceType = ExperimentStoreState['templateConditions'];

export const templateConditionsSlice = (
  set: StoreApi<TemplateConditionsSliceType>['setState']
): TemplateConditionsSliceType => ({
  loading: false,
  data: undefined,
  error: undefined,
  fetch: async () => {
    try {
      const data = await APIFetch<ConditionsTemplatesResponse>(
        ENDPOINT_URLS.EXPERIMENT_CONDITION_TEMPLATES
      );
      if (data instanceof Error) {
        throw data;
      }
      set({
        loading: false,
        error: undefined,
        data: data.conditions.map(c => {
          return {
            condition: c,
            label: c.display || '',
            isActive: false,
            isCustom: false,
          };
        }),
      });
    } catch (e) {
      set(() => ({
        loading: false,
        error: e as Error,
        data: undefined,
      }));
    }
  },
});
