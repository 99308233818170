export enum MediaQueries {
  LARGE_DESKTOP = 'screen and (min-width: 1920px)',
  DESKTOP = 'screen and (min-width: 1280px)',
  SMALL_DESKTOP = 'screen and (min-width: 880px)',
  TABLET = 'screen and (min-width: 600px)',
  MOBILE = 'screen and (min-width: 400px)',
  SMALL_MOBILE = 'screen and (max-width: 399px)',
}

export enum MediaQueriesColorSchemes {
  DARK_MODE = '(prefers-color-scheme: dark)',
}

export enum MediaQueriesOrientation {
  PORTRAIT = '(orientation: portrait)',
  LANDSCAPE = '(orientation: landscape)',
}
