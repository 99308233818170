import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { useRemoteStorage } from '~/shared/hooks/use-remote-storage';
import { useHelper } from '~/stores/use-helper/useHelper';
import { useUser } from '~/stores/use-user/useUser';
import { HELPER_VIEW_IDS } from '~/views/helper/HelperConfig';

import { helperViewConfigs } from '../HelperViewConfigs';
import { OrientationRegisterBanner } from './content/orientation-register-banner/OrientationRegisterBanner';
import * as S from './HelperWidget.styles';

export const HelperWidget = () => {
  const { t } = useTranslation('helperWidget');
  const user = useUser(({ user }) => user);
  const { items, storeItems } = useRemoteStorage(({ items, storeItems }) => ({
    items,
    storeItems,
  }));
  const {
    isExpanded,
    collapseHelper,
    currentHelperViewKeyState,
    setCurrentHelperViewKey,
    isCurrentViewChecked,
    translationKey,
    Component,
    currentViewBreadcrumbs,
    isZendeskOnline,
  } = useHelper();

  const defaultExpandedId = helperViewConfigs[
    currentHelperViewKeyState
  ]?.autoExpandableItemsIds?.find(id => items?.[id] !== 'active');
  const title = t(`helperWidget:${translationKey}.title`);
  const subTitlei18nKey = isCurrentViewChecked
    ? `${translationKey}.subTitleComplete`
    : `${translationKey}.subTitle`;

  if (!isExpanded) {
    return null;
  }
  return (
    <S.HelperWrapper as="aside" data-testid="helper-widget">
      <S.FixedWrapper>
        <S.Header data-testid="helper-header">
          <S.Control>
            <S.Nav>
              <S.HelperBreadcrumbs breadcrumbs={currentViewBreadcrumbs} />
            </S.Nav>
            <S.CollapseBtn onClick={collapseHelper} data-testid="collapse-btn">
              {t('helperWidget:hideButtonLabel')}
            </S.CollapseBtn>
          </S.Control>
          <S.Title>
            {`${title} ${
              currentHelperViewKeyState === HELPER_VIEW_IDS.HOME
                ? user?.first_name
                : ''
            }`}
          </S.Title>
          <S.SubTitle>
            <Trans t={t} i18nKey={subTitlei18nKey} defaults=" " />
          </S.SubTitle>
          {isCurrentViewChecked ? (
            <S.RobotCompleteImg data-testid="helper-robot-success-img" />
          ) : (
            <S.RobotImg data-testid="helper-robot-img" />
          )}
        </S.Header>
        <S.ContentWrapper>
          <S.HelperWidgetContentContainer>
            <S.HelperRegisterBannerAccordion
              sections={[
                {
                  id: 'orientationRegisterBanner',
                  title: t('helperWidget:orientation.title'),
                  gaTitle: 'Learn How to Use TOGGLE like a PRO',
                  content: <OrientationRegisterBanner />,
                  canBeChecked: false,
                  hideMarker: true,
                },
              ]}
              storeItems={items ? Object.keys(items) : []}
              setStoreItems={storeItems}
              sendGaOnExpand
            />
            <Component
              storeItems={items ? Object.keys(items) : []}
              setStoreItems={storeItems}
              setActiveViewKey={setCurrentHelperViewKey}
              defaultExpandedId={defaultExpandedId}
              zendeskOnline={isZendeskOnline}
              isActiveViewChecked={isCurrentViewChecked}
            />
          </S.HelperWidgetContentContainer>
        </S.ContentWrapper>
      </S.FixedWrapper>
      <S.HelperZendeskLauncher zendeskOnline={isZendeskOnline} />
    </S.HelperWrapper>
  );
};
