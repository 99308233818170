import React, { ErrorInfo, PureComponent } from 'react';

import { Tracking } from '~/shared/services/tracking';
import { CacheError, ServerError } from '~/views/error';

export interface ErrorBoundaryProps {
  children?: React.ReactNode;
  pathname?: string;
  fallback?: React.ReactNode;
  onError?: (error: Error, info: ErrorInfo) => void;
}

interface State {
  hasError: boolean;
  isChunkError: boolean;
}

export const isChunkedLoadError = (error: Error): boolean => {
  const chunkFailedMessage = /Loading chunk [\d]+ failed/;

  return (
    error.name === 'ChunkLoadError' && chunkFailedMessage.test(error.message)
  );
};

export class ErrorBoundary extends PureComponent<ErrorBoundaryProps, State> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false, isChunkError: false };
  }

  static getDerivedStateFromError(error: Error) {
    if (isChunkedLoadError(error)) {
      return { isChunkError: true };
    }
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.props.onError?.(error, errorInfo);
    Tracking.captureException(error, { extra: { errorInfo } });
  }

  componentDidUpdate(prevProps: ErrorBoundaryProps) {
    if (
      (this.state.hasError || this.state.isChunkError) &&
      prevProps.pathname !== this.props.pathname
    ) {
      this.setState({ hasError: false, isChunkError: false });
    }
  }

  render() {
    if (this.state.hasError) {
      return this.props.fallback ?? <ServerError />;
    }
    if (this.state.isChunkError) {
      return <CacheError />;
    }

    return this.props.children;
  }
}
