import { BaseChartAPIProps } from '~/types/create.types';
import { isWithinYAxis } from '~/utils/axis/axis-utils';

import { ChartStoreReturn } from '../create/chart-store/chartStore';
import { dispatchRightClickEvent } from '../events/events';

export const onMouseRightClick = (
  event: MouseEvent,
  chartStore: ChartStoreReturn
) => {
  const base = chartStore.getState().base as BaseChartAPIProps;
  event.preventDefault();

  dispatchRightClickEvent({
    base,
    mouseX: event.offsetX,
    mouseY: event.offsetY,
    isWithinYAxis: isWithinYAxis(event, base.options),
  });
};
