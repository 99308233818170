import { typography } from '@toggle/design-system';
import styled from 'styled-components';

export const MarkdownLink = styled.a`
  display: inline-flex;
  color: var(--primary-color);
  border-bottom: 1px solid transparent;
  ${typography.dingo}

  &:hover {
    border-bottom: 1px solid var(--primary-color);
    color: var(--primary-color);
  }
`;
