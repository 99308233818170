import React, { FC, memo, useState } from 'react';

import { gaBookmark } from '~/shared/utils/ganalytics';

import * as S from './BookmarkHeader.styles';

interface BookmarkHeaderProps {
  articleID: string;
  bookmarked: boolean;
}

export const BookmarkHeader: FC<BookmarkHeaderProps> = memo(
  ({ articleID, bookmarked }) => {
    const [active, setActive] = useState(bookmarked);

    const toggleBookmarked = (bookmarked: boolean) => {
      setActive(bookmarked);
      gaBookmark(articleID, bookmarked, 'article-header');
    };

    return (
      <S.SectorHeader $bookmarked={active} data-testid="container">
        <S.HeaderBookmarkButton
          articleID={articleID}
          bookmarked={active}
          onBookmarkToggle={toggleBookmarked}
        />
        <S.Bar />
      </S.SectorHeader>
    );
  }
);
