const COUNTRIES = new Map<string, string>([
  ['US', 'us'],
  ['CANADA', 'can'],
  ['UK', 'uk'],
  [
    'EUROPE',
    'eu,uk,aut,deu,dnk,esp,fra,gre,hun,ire,isl,ita,nld,nor,pol,por,rus,swe,swi,cze,bel,fin,rou,svn,svk,est,lva,ltu',
  ],
  ['AUSTRALIA', 'aus'],
  ['CHINA', 'chn,twn'],
  ['HONG_KONG', 'hk'],
  ['INDIA', 'ind'],
  ['JAPAN', 'jap'],
  ['KOREA', 'kor'],
  ['BRAZIL', 'bra'],
  ['MEXICO', 'mex'],
  ['SOUTH_AFRICA', 'zaf'],
  ['MIDDLE_EAST', 'sau,egy,qa,om,kw'],
]);

const COUNTRIES_OPTIONS = Array.from(COUNTRIES.keys());

export const GEO_KEYS = [
  ...COUNTRIES_OPTIONS,
  'ASIA',
  'EM_MARKETS',
  'REST_OF_WORLD',
];
