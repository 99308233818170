import React from 'react';
import { useTranslation } from 'react-i18next';

import { appPaths } from '~/routes/app-paths';
import { usePortfolio } from '~/stores/use-portfolio/usePortfolio';
import { useUserEntities } from '~/stores/use-user-entities/useUserEntities';
import { MY_TOGGLE_SECTION_IDS } from '~/views/helper/HelperConfig';

import {
  HelperWidgetAccordion,
  HelperWidgetSection,
} from '../../partials/accordion/HelperWidgetAccordion';
import { HelperContentProps } from '../content';
import * as S from '../Content.styles';
import { AssetsSection, PortfolioWatchlistSection } from './partials';

export const MyToggleHelperContent = (props: HelperContentProps) => {
  const { t } = useTranslation('helperWidget');
  const tags = useUserEntities(state => state.entities.watchlist);
  const { portfolios, loading } = usePortfolio(({ portfolios, loading }) => ({
    portfolios,
    loading,
  }));

  const watchlistExits = !!tags.length;

  const sections: HelperWidgetSection[] = [
    {
      id: MY_TOGGLE_SECTION_IDS.MY_TOGGLE_CONNECT_PORTFOLIO_ID,
      title: t('helperWidget:myToggle.content.sectionsTitles.connectPortfolio'),
      gaTitle: 'Connect a portfolio',
      content: () => (
        <PortfolioWatchlistSection
          loading={loading}
          btnLabel={t('myToggle:noPortfolio.connect')}
          navigateUrl={appPaths.myAccount.portfolio}
          text={
            portfolios.length
              ? t('helperWidget:myToggle.content.portfolio.connected')
              : t('helperWidget:myToggle.content.portfolio.noConnected')
          }
        />
      ),
      separateSection: true,
      hideMarker: true,
    },
    {
      id: MY_TOGGLE_SECTION_IDS.MY_TOGGLE_SET_UP_WL_ID,
      title: watchlistExits
        ? t('helperWidget:myToggle.content.sectionsTitles.changeWatchlist')
        : t('helperWidget:myToggle.content.sectionsTitles.setUpWatchlist'),
      gaTitle: watchlistExits ? 'Change watchlist' : 'Set up Watchlist',
      content: () => (
        <PortfolioWatchlistSection
          loading={false}
          btnLabel={
            watchlistExits
              ? t(
                  'helperWidget:myToggle.content.sectionsTitles.changeWatchlist'
                )
              : t('helperWidget:myToggle.content.sectionsTitles.setUpWatchlist')
          }
          navigateUrl={appPaths.myAccount.watchlist}
          text={
            watchlistExits
              ? t('helperWidget:myToggle.content.watchlist.exists')
              : t('helperWidget:myToggle.content.watchlist.notExists')
          }
        />
      ),
      separateSection: true,
      hideMarker: true,
    },
    {
      id: MY_TOGGLE_SECTION_IDS.MY_TOGGLE_MASTERCLASS_ID,
      title: t('helperWidget:myToggle.content.sectionsTitles.master'),
      gaTitle: 'Master My TOGGLE',
      hideMarker: true,
    },
    {
      id: MY_TOGGLE_SECTION_IDS.MY_TOGGLE_ASSETS_CHECK_ID,
      title: t('helperWidget:myToggle.content.sectionsTitles.assets'),
      gaTitle: 'Assets',
      content: <AssetsSection />,
    },
    {
      id: MY_TOGGLE_SECTION_IDS.MY_TOGGLE_DRIVERS_ID,
      title: t('helperWidget:myToggle.content.sectionsTitles.drivers'),
      gaTitle: 'Drivers',
      content: (
        <S.Content>
          {t('helperWidget:myToggle.content.drivers.firstParagraph')}
          <S.DriversImgBox>
            <img
              src="/app/assets/helper-widget/my-toggle-drivers.png"
              alt="my-toggle drivers"
            />
          </S.DriversImgBox>
        </S.Content>
      ),
    },
    {
      id: MY_TOGGLE_SECTION_IDS.MY_TOGGLE_FILTERS_CHECK_ID,
      title: t('helperWidget:myToggle.content.sectionsTitles.filters'),
      gaTitle: 'Filters',
      content: (
        <S.Content>
          {t('helperWidget:myToggle.content.filters.firstParagraph')}
        </S.Content>
      ),
    },
    {
      id: MY_TOGGLE_SECTION_IDS.MY_TOGGLE_OPEN_ASSET_OVERVIEW_ID,
      title: t('helperWidget:myToggle.content.sectionsTitles.openAsset'),
      gaTitle: 'Open Asset Overview',
      content: (
        <S.Content>
          {t('helperWidget:myToggle.content.openAsset.firstParagraph')}
        </S.Content>
      ),
    },
  ];

  return (
    <HelperWidgetAccordion sections={sections} sendGaOnExpand {...props} />
  );
};
