import { StripeElementStyle } from '@stripe/stripe-js';
import { deviceConstraints, typographyV2 } from '@toggle/design-system';
import styled from 'styled-components';

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 36px 12px 0;
  @media ${deviceConstraints.desktopSmall} {
    margin: 36px 20px 0;
  }

  button + button {
    margin-left: 8px;
  }
`;

interface InputWrapperProps {
  $focused?: boolean;
  $error?: boolean;
}

export const InputContainer = styled.div<InputWrapperProps>`
  border: 1px solid var(--border-default);
  background-color: var(--surface-default);
  border-radius: 4px;
  padding: 8px 12px;

  ${({ $error }) =>
    $error &&
    `
      border-color: var(--border-critical);
      background-color: var(--surface-default);
    `}

  ${({ $focused }) =>
    $focused
      ? `
          border-color: var(--border-primary);
        `
      : `
          &:hover {
            border-color: var(--border-hover);
          }
        `}
`;

export const CardStyle: Partial<StripeElementStyle> = {
  base: {
    color: '#fff',
    fontFamily: 'nort, sans-serif, Arial, sans-serif',
    fontSize: '16px',
    backgroundColor: '#202425',
    '::placeholder': {
      color: '#fff',
    },
    ':-webkit-autofill': {
      color: '#fff',
    },
  },
  invalid: {
    color: '#f16a50',
    iconColor: '#f16a50',
  },
};

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

export const ErrorLabel = styled.p`
  color: var(--text-critical);
  ${typographyV2.bodySm}
`;
