import { Portfolio, PortfolioGroup } from '~/stores/use-portfolio/usePortfolio';

export const createPortfolioGroups = (portfolios: Portfolio[]) => {
  const normalizedGroups = portfolios.reduce((groups, item) => {
    const { provider, ...portfolio } = item;
    const key = portfolio.login_id;

    if (groups[key]) {
      groups[key].portfolios.push(portfolio);
      if (portfolio.last_statement_date > groups[key].lastStatementDate) {
        groups[key].lastStatementDate = portfolio.last_statement_date;
      }
      if (portfolio.created_at < groups[key].createdAt) {
        groups[key].createdAt = portfolio.created_at;
      }
    } else {
      groups[key] = {
        id: item.login_id,
        lastStatementDate: item.last_statement_date,
        createdAt: item.created_at,
        provider,
        portfolios: [portfolio],
      };
    }
    return groups;
  }, {} as { [key: string]: PortfolioGroup });

  return Object.values(normalizedGroups);
};
