import { deviceConstraints, typography } from '@toggle/design-system';
import styled from 'styled-components';

export const MainContainer = styled.main`
  display: grid;
  grid-template-areas:
    'robot title'
    'video video'
    'suggestions suggestions';
  padding: 40px 16px;
  width: 100%;

  @media ${deviceConstraints.desktopSmall} {
    margin: 100px auto;
    max-width: 1000px;
    grid-template-areas:
      'robot title . .'
      'video video . suggestions';
    grid-template-columns: 50px minmax(550px, 1fr) 40px minmax(1fr, 370px);
  }
`;

export const RobotImg = styled.img`
  grid-area: robot;
  margin-bottom: -32px;
  height: 127px;
  margin-top: 32px;
`;

export const Title = styled.h1`
  grid-area: title;
  padding-bottom: 16px;
  padding-left: 16px;
  font-size: 22px;
  margin-top: auto;
`;

export const VideoContainer = styled.div`
  grid-area: video;
  background-color: #ccc;
  height: 366px;
  width: 100%;
`;

export const SuggestionsSection = styled.section`
  grid-area: suggestions;
  margin-left: 40px;
  margin-top: 20px;

  @media ${deviceConstraints.desktopSmall} {
    margin-top: 0;
  }
`;

export const SuggestionsText = styled.h2`
  ${typography.coyote};
  margin-bottom: 20px;
`;

export const TagSearchContainer = styled.div`
  margin-bottom: 20px;
  [class*='autocompleteInputWrapper'] {
    border-bottom: 1px solid var(--base--75);
  }
`;
