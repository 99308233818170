import { RefObject, useEffect, useState } from 'react';

export function useElemSize<T extends Element>(ref: RefObject<T>) {
  const [size, setSize] = useState({
    left: 0,
    top: 0,
    width: 0,
    height: 0,
  });

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    const rect = ref.current.getBoundingClientRect();

    const changed = Object.entries(size).some(
      ([key, val]) => rect[key as keyof DOMRect] !== val
    );
    if (changed) {
      setSize({
        width: rect.width,
        height: rect.height,
        left: rect.left,
        top: rect.top,
      });
    }
  });

  return size;
}
