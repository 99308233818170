import groupBy from 'lodash/groupBy';

import {
  ExploreFilterOption,
  FilterOption,
} from '~/api/explore-filter/explore-filter-schema';
import {
  Filter,
  OptionWithPropertiesOnly,
} from '~/views/explore/store/use-explore-filters/utils/useExploreFiltersUtils';

export const getFilters = (
  filterOptions: ExploreFilterOption[],
  filters: string[]
): FilterOption[] => {
  return filterOptions.reduce<FilterOption[]>((r, a) => {
    return [...r, ...a.options.filter(i => filters.includes(i.key))];
  }, []);
};

export const groupFilters = <T extends OptionWithPropertiesOnly>(
  filters: T[]
) => {
  return filters.reduce<Record<string, string[]>>((res, next) => {
    const value = Object.entries(next.properties ?? {}).reduce(
      (accu, [key, value]) => {
        const nextValue =
          typeof value === 'string'
            ? [...(res[key] || []), value]
            : [...(res[key] || []), ...value];

        return { ...accu, [key]: nextValue };
      },
      {}
    );

    return { ...res, ...value };
  }, {});
};

export const makeSlug = (filters: Record<string, string[]>): string => {
  const ret = [];
  for (const [key, value] of Object.entries(filters)) {
    ret.push(
      `${encodeURIComponent(key)}=${encodeURIComponent(
        [...new Set(value)].toString()
      )}`
    );
  }
  return ret.join('&');
};

export const makeFilterSlug = (
  filterOptions: ExploreFilterOption[],
  selectedFilters: Filter[]
): string => {
  try {
    const filters = getFilters(
      filterOptions,
      Object.values(selectedFilters).map(({ key }) => key)
    );

    const filtersPayload = groupFilters(filters);

    return new URLSearchParams(filtersPayload as any).toString();
  } catch (error) {
    return '';
  }
};

export function makeFiltersNamesString(filters: Filter[]): string {
  const filtersByType = groupBy(filters, 'type');
  const types = Object.keys(filtersByType);

  return types
    .reduce((filtersString, type) => {
      const filtersNamesCommaSeparated = filtersByType[type]
        .map(filter => filter.name)
        .join(', ');

      return filtersString.concat(`${type}: ${filtersNamesCommaSeparated}; `);
    }, '')
    .trimEnd();
}
