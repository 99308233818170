import { EntityAnalyticsReportType } from '~/shared/services/overview-widget/overview-widget-service';

interface AlertSignalDescriptionData {
  alertShortName: string;
  include: boolean;
  low: string;
  high: string;
}

export type AlertSignalDescription = Record<
  EntityAnalyticsReportType,
  AlertSignalDescriptionData
>;

export const ALERT_DESCRIPTION_DATA_MAP: AlertSignalDescription = {
  [EntityAnalyticsReportType.VALUATION]: {
    alertShortName: 'Valuation',
    include: true,
    low: 'Cheap',
    high: 'Expensive',
  },
  [EntityAnalyticsReportType.ENTRY_POINT]: {
    alertShortName: 'Entry Point',
    include: true,
    low: 'Oversold',
    high: 'Overbought',
  },
  [EntityAnalyticsReportType.POSITIONING]: {
    alertShortName: 'Positioning',
    include: true,
    low: 'Short',
    high: 'High',
  },
  [EntityAnalyticsReportType.MOMENTUM]: {
    alertShortName: 'Momentum',
    include: true,
    low: 'Weak',
    high: 'Strong',
  },
  [EntityAnalyticsReportType.EQUITY_GROWTH_GAUGE]: {
    alertShortName: 'Earnings Growth',
    include: true,
    low: 'Low',
    high: 'High',
  },
  [EntityAnalyticsReportType.VOLUME_ANALYSIS]: {
    alertShortName: 'Volume Analysis',
    include: true,
    low: 'Low',
    high: 'High',
  },
  [EntityAnalyticsReportType.EQUITY_YIELD]: {
    alertShortName: 'Equity Yields',
    include: true,
    low: 'Poor',
    high: 'Rich',
  },
  [EntityAnalyticsReportType.EQUITY_CONSENSUS_TREND]: {
    alertShortName: 'Analyst Revisions',
    include: true,
    low: 'Negative',
    high: 'Positive',
  },
  [EntityAnalyticsReportType.ECONOMIC_GROWTH_GAUGE]: {
    alertShortName: 'Economic Growth',
    include: true,
    low: 'Recession',
    high: 'Expansion',
  },
  [EntityAnalyticsReportType.ECONOMIC_INFLATION_GAUGE]: {
    alertShortName: 'Inflation',
    include: true,
    low: 'Deflation',
    high: 'Inflation',
  },
  [EntityAnalyticsReportType.FINANCIAL_CONDITIONS]: {
    alertShortName: 'Financial Conditions',
    include: true,
    low: 'Loose',
    high: 'Tight',
  },
  [EntityAnalyticsReportType.VOLATILITY]: {
    alertShortName: 'Volatility',
    include: true,
    low: 'Low',
    high: 'High',
  },
  [EntityAnalyticsReportType.MAIN_MARKET_PRICE]: {
    alertShortName: 'Price Range',
    include: true,
    low: 'Low',
    high: 'High',
  },
  [EntityAnalyticsReportType.MAIN_YIELD]: {
    alertShortName: 'Yield Range',
    include: true,
    low: 'Low',
    high: 'High',
  },
  [EntityAnalyticsReportType.TECHNICAL_ANALYSIS]: {
    alertShortName: 'Technicals',
    include: true,
    low: 'Low',
    high: 'High',
  },
  [EntityAnalyticsReportType.GEOPOLITICAL]: {
    alertShortName: 'Political Indicators',
    include: true,
    low: 'Unstable',
    high: 'Stable',
  },
  [EntityAnalyticsReportType.SEASONALITY]: {
    alertShortName: 'Seasonality',
    include: false,
    low: 'Low',
    high: 'High',
  },
  [EntityAnalyticsReportType.SENTIMENT]: {
    alertShortName: 'Sentiment',
    include: true,
    low: 'Weak',
    high: 'Strong',
  },
};
