import { StoreApi } from 'zustand';

import { ENDPOINT_URLS } from '~/global/toggle-api';
import {
  APIFetch,
  APIResponse,
  HttpMethods,
} from '~/shared/services/api-fetch';
import { FetchError } from '~/shared/services/fetch/FetchErrors';
import { fetchSnakeMetaV2 } from '~/shared/services/overview-widget/overview-widget-service';

import {
  ConditionEnum,
  ConditionsPayload,
  getConditionPermissions,
} from '../../../experiment/components/condition-builder/utils/conditionHelpers';
import {
  CompassData,
  ConditionListItem,
  ExperimentStoreState,
} from '../../useExperimentStore';
import { CompassFormat } from '../../useExperimentStore.enums';

type CompassSliceType = ExperimentStoreState['compass'];

const fixCondition = (conditionListItem: ConditionListItem) => {
  const condition = conditionListItem.condition;
  const isCustom = conditionListItem.isCustom;
  const conditionPermissions = getConditionPermissions(condition.condition);
  if (
    condition.condition === ConditionEnum.between &&
    condition.min_value &&
    condition.max_value
  ) {
    const [min, max] = [condition.min_value, condition.max_value].sort(
      (a, b) => a - b
    );
    return {
      ...condition,
      min_value: min,
      max_value: max,
    };
  }
  if (conditionPermissions.isPercentageValue && condition.value && isCustom) {
    const percentageDividedValue = condition.value / 100;

    return {
      ...condition,
      value: percentageDividedValue,
    };
  }
  return condition;
};

export const compassSlice = (
  set: StoreApi<CompassSliceType>['setState'],
  get: StoreApi<ExperimentStoreState>['getState']
): CompassSliceType => ({
  loading: false,
  empty: false,
  compassData: undefined,
  error: undefined,
  getData: async (
    entity,
    snake,
    scenarioConditions: ConditionsPayload[] = [],
    date?: string
  ) => {
    const conditions = get()
      .scenarioConditions.map(fixCondition)
      .concat(scenarioConditions);

    set({
      loading: true,
      error: undefined,
      empty: false,
      compassData: undefined,
    });

    try {
      const experimentPromise = APIFetch<CompassData>(
        ENDPOINT_URLS.EXPERIMENT_COMPASS,
        {
          method: HttpMethods.Post,
          body: JSON.stringify({ entity, snake, conditions, date }),
        }
      );
      const snakeMetaPromise = fetchSnakeMetaV2([snake]);
      const [data, snakeMeta] = await Promise.all([
        experimentPromise,
        snakeMetaPromise,
      ]);
      if (data instanceof Error) {
        if (
          data instanceof FetchError &&
          data.status === APIResponse.BAD_REQUEST
        ) {
          set({ empty: true, loading: false });
          return;
        }
        throw data;
      }
      if (data instanceof Blob && data.size === 0) {
        set({ empty: true, loading: false });
        return;
      }
      if (snakeMeta instanceof Error) {
        throw snakeMeta;
      }
      set({
        loading: false,
        empty: !data.episodes.length,
        compassData: {
          ...data,
          format: snakeMeta[snake].is_percentage
            ? CompassFormat.PERCENT
            : CompassFormat.ABSOLUTE,
        },
      });
    } catch (error) {
      set({ error: error as Error, loading: false });
    }
  },
});
