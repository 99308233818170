import { GroupedInsights } from '@toggle/chart';
import { TooltipTrigger } from '@toggle/design-system';
import React, { FC, MouseEvent } from 'react';

import ArticleInsightBearish from '~/assets/chart/article_insight_bearish.svg';
import ArticleInsightBullish from '~/assets/chart/article_insight_bullish.svg';
import ArticleInsightMultiBearish from '~/assets/chart/article_insight_multi-bearish.svg';
import ArticleInsightMultiBullish from '~/assets/chart/article_insight_multi-bullish.svg';
import ArticleInsightMultiMixed from '~/assets/chart/article_insight_multi-mixed.svg';

import { ChartInsightTooltipContent } from '../chart-insight-tooltip-content/ChartInsightTooltipContent';
import { ActiveInsightsGroup } from '../insights.types';
import * as S from './ChartInsightIcon.styles';

interface ChartInsightIconProps {
  activeInsightsGroup: ActiveInsightsGroup | null;
  groupedInsights: GroupedInsights;
  groupedInsightsIndex: number;
  insightsInDomainIndex: number;
  hasStackedInsights: boolean;
  insightIconRight: number;
  insightIconTop: number;
  isNextBtnEnabled: boolean;
  isPrevBtnEnabled: boolean;
  onArrowClick: (
    direction: 'next' | 'prev',
    currentInsightsByDateIndex: number,
    currentInsightsByTypeIndex: number
  ) => void;
  onClick: (
    currentInsightsByDateIndex: number,
    currentInsightsByTypeIndex: number
  ) => void;
  onClose: () => void;
}

const mapInsightToIcon = {
  article: {
    bullish: ArticleInsightBullish,
    bearish: ArticleInsightBearish,
    'multi-bullish': ArticleInsightMultiBullish,
    'multi-bearish': ArticleInsightMultiBearish,
    'multi-mixed': ArticleInsightMultiMixed,
  },
};

const ICON_WIDTH = 16;
const ICON_HEIGHT = 20;
const ICON_SCALE = 1.25;
const ICON_Y_AXIS_MARGIN = 4;

export const ChartInsightIcon: FC<ChartInsightIconProps> = ({
  activeInsightsGroup,
  groupedInsights,
  groupedInsightsIndex,
  insightsInDomainIndex,
  onArrowClick,
  isPrevBtnEnabled,
  isNextBtnEnabled,
  insightIconTop,
  hasStackedInsights,
  onClick,
  onClose,
  insightIconRight,
}) => {
  const InsightIcon = mapInsightToIcon.article[groupedInsights.direction];

  const isOpen =
    activeInsightsGroup?.insightsInDomainIndex === insightsInDomainIndex &&
    activeInsightsGroup?.groupedInsightsIndex === groupedInsightsIndex;

  const iconOffsetY = groupedInsightsIndex * ICON_HEIGHT * ICON_SCALE;
  const iconRight = insightIconRight - ICON_WIDTH / 2;
  const iconTop =
    insightIconTop - ICON_HEIGHT - ICON_Y_AXIS_MARGIN - iconOffsetY;
  const defaultTransform = `scale(1)`;
  const activeTransform = `scale(${ICON_SCALE})`;

  const onMouseLeave = (e: MouseEvent<HTMLDivElement>) => {
    if (!isOpen) {
      e.currentTarget.style.transform = defaultTransform;
    }
  };

  const onMouseOver = (e: MouseEvent<HTMLDivElement>) => {
    if (!isOpen) {
      e.currentTarget.style.transform = activeTransform;
    }
  };

  const isBottomOfStackedInsights =
    groupedInsightsIndex === 0 && hasStackedInsights;

  return (
    <S.StyledTooltipContainer
      hasArrow={false}
      key={groupedInsights.type}
      trigger={TooltipTrigger.Click}
      isOpen={isOpen}
      onClose={onClose}
      label={
        isOpen && (
          <ChartInsightTooltipContent
            activeInsightsGroup={activeInsightsGroup}
            insightsInDomainIndex={insightsInDomainIndex}
            isPrevBtnEnabled={isPrevBtnEnabled}
            isNextBtnEnabled={isNextBtnEnabled}
            groupedInsightsIndex={groupedInsightsIndex}
            onArrowClick={onArrowClick}
          />
        )
      }
      placement="top"
      strategy="fixed"
      offsetY={isBottomOfStackedInsights ? -20 : 0}
    >
      <S.IconContainer
        data-testid={`${groupedInsights.direction}-${groupedInsights.type}-${insightsInDomainIndex}`}
        onClick={() => onClick(insightsInDomainIndex, groupedInsightsIndex)}
        $direction={groupedInsights.direction}
        style={{
          top: iconTop,
          right: iconRight,
          transform: isOpen ? activeTransform : defaultTransform,
        }}
        onMouseOver={onMouseOver}
        onMouseLeave={onMouseLeave}
      >
        <InsightIcon
          width={ICON_WIDTH}
          height={ICON_HEIGHT}
          viewBox={`0 0 ${ICON_WIDTH} ${ICON_HEIGHT}`}
        />
      </S.IconContainer>
    </S.StyledTooltipContainer>
  );
};
