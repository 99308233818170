import { Button } from '@toggle/design-system';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import DesktopOnly from '~/assets/analyze/desktop-only.svg';

import * as S from './MobileNotSupported.styles';

export const MobileNotSupported: FC<{ message: string }> = ({ message }) => {
  const { t } = useTranslation('common');
  const navigate = useNavigate();

  return (
    <S.MobileNotSupportedRoot data-testid="mobile-not-supported">
      <DesktopOnly
        alt={t('common:desktopOnlyAlt')}
        title={t('common:desktopOnlyAlt')}
      />
      <S.Message>{message}</S.Message>
      <Button
        data-testid="go-back-button"
        label={t('common:goBackBtn')}
        onClick={() => navigate(-1)}
      />
    </S.MobileNotSupportedRoot>
  );
};
