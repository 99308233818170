import styled from 'styled-components';

import { borderRadius } from '~/design-tokens/border-radius/borderRadius';
import { typographyV2 } from '~/design-tokens/typography/Typography.styles';
import { deviceConstraints } from '~/utils/breakpoints';

import { Button } from '../button/Button';

export const Backdrop = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--background-backdrop);
  backdrop-filter: blur(1px);
  opacity: 0;
  transition: all 100ms cubic-bezier(0.4, 0, 0.2, 1);
  transition-delay: 200ms;
  display: flex;
  align-items: center;
  justify-content: center;

  &.active {
    transition-duration: 250ms;
    transition-delay: 0ms;
    opacity: 1;
  }
`;

export const Container = styled.div`
  position: fixed;
  inset: 0;
  z-index: 6;
`;

export const ModalContainer = styled.div<{ $fillContainer: boolean }>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid var(--border-soft);
  ${borderRadius.r2};
  background: var(--background-soft);
  padding-bottom: 24px;

  @media ${deviceConstraints.desktopSmall} {
    width: auto;
    ${({ $fillContainer }) => $fillContainer && `width: 90%`}
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 6px 6px 18px 12px;
  @media ${deviceConstraints.desktopSmall} {
    padding: 6px 6px 18px 20px;
  }
  & + * {
    padding: 0 12px;
    @media ${deviceConstraints.desktopSmall} {
      padding: 0 20px;
    }
  }
`;

export const TextContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin: 10px 0 18px;
  @media ${deviceConstraints.desktopSmall} {
    margin: 18px 0 18px;
  }
`;

export const Title = styled.h4`
  ${typographyV2.titleLg};
`;

export const Description = styled.p`
  ${typographyV2.bodySm};
  color: var(--text-soft);
`;

export const CloseButton = styled(Button)`
  margin-left: auto;
  z-index: 1;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 36px 12px 0;
  @media ${deviceConstraints.desktopSmall} {
    margin: 36px 20px 0;
  }

  button + button {
    margin-left: 8px;
  }
`;
