const background = {
  '--background-default': '#f8f9fa',
};

const surface = {
  '--surface-primary': '#0091ff',
  '--surface-pressed': '#e6e8eb',
};

const border = {
  '--border-soft': '#dfe3e6',
};

export const crystalLight = {
  ...background,
  ...surface,
  ...border,
};
