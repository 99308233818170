/* eslint-disable max-lines-per-function */
import { Alert, AlertType, Icon, TooltipTrigger } from '@toggle/design-system';
import { useOutsideClick } from '@toggle/helpers';
import xorBy from 'lodash/xorBy';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { MODAL_TYPES } from '~/global/paywall/services/modal-types/modalTypes';
import { usePaywallStore } from '~/global/paywall/stores/usePaywallStore';
import { useTouchDevice } from '~/shared/hooks/MediaQueryHook';
import {
  gaExploreSendFilters,
  gaExploreUpdateFiltersScreen,
} from '~/shared/utils/ganalytics';
import { useHelper } from '~/stores/use-helper/useHelper';
import { useSubscription } from '~/stores/use-subscription/useSubscription';
import { ToastPosition, useToasts } from '~/stores/use-toasts/useToasts';
import { makeFiltersNamesString } from '~/views/explore/filter/makeFilterSlug';
import { ScreensTabsSelect } from '~/views/explore/filter-screens/screens-tabs-select/ScreensTabsSelect';
import { FILTER_SCREENS_LIMIT } from '~/views/explore/store/use-explore-filters/utils/useExploreFiltersUtils';
import { exploreSectionsRefs } from '~/views/helper/helper-widget/content/explore/ExploreHelperContent';

import { EXPLORE_SECTION_IDS } from '../../helper/HelperConfig';
import { iconNameTranslate } from '../feed/partials/filters/Filter';
import { FilterScreenPopup } from '../filter-screen-popup/FilterScreenPopup';
import { FilterScreen, useExploreFilters } from '../store/use-explore-filters';
import * as S from './FilterScreens.styles';

interface FilterScreensProps {
  openScreenModal: () => void;
  openMobileFilters: () => void;
  activeScreen: FilterScreen;
}

export const FilterScreens = ({
  openScreenModal,
  openMobileFilters,
  activeScreen,
}: FilterScreensProps) => {
  const { t } = useTranslation('explore');
  const [activeScreenId, setActiveScreenId] = useState('');
  const [tabsSelectOpened, setTabsSelectOpened] = useState(false);
  const {
    updateFilter,
    screens,
    setActiveScreen,
    revertScreen,
    saveScreen,
    deleteScreen,
  } = useExploreFilters(s => ({
    updateFilter: s.updateFilter,
    screens: s.screens,
    setActiveScreen: s.setActiveScreen,
    revertScreen: s.revertScreen,
    saveScreen: s.saveScreen,
    deleteScreen: s.deleteScreen,
  }));
  const isBasic = useSubscription(state => state.isBasic());
  const openModal = usePaywallStore(state => state.openModal);
  const isTouchDevice = useTouchDevice();

  const tabsWrapperRef = useRef<HTMLDivElement>(null);
  useOutsideClick({
    ref: tabsWrapperRef,
    callback: () => setTabsSelectOpened(false),
  });

  const filters = activeScreen.filters;
  const originFilters = activeScreen.originFilters;
  const changedFiltersCount = xorBy(filters, originFilters, s => s.key).length;
  const screenChanged = !!changedFiltersCount;
  const { setHelperHoverBehavior } = useHelper();
  const showToast = useToasts(state => state.showToast);

  const showWarningToast = () =>
    showToast({
      id: 'warning-toast',
      hideToast: true,
      position: ToastPosition.TopRight,
      content: (
        <Alert
          type={AlertType.Warning}
          title={t('explore:screens.toastMessage')}
        />
      ),
    });

  const onRename = () => {
    setActiveScreenId('');
    openScreenModal();
  };
  const onHide = () => setActiveScreenId('');
  const onDelete = () => deleteScreen(activeScreen);

  const toggleTabSelect = () => {
    setTabsSelectOpened(s => !s);
    setActiveScreenId('');
  };
  const togglePopup = () => {
    setActiveScreenId(s => (s ? '' : activeScreen.id));
    setTabsSelectOpened(false);
  };

  const saveAction = () => {
    !originFilters.length
      ? gaExploreSendFilters(makeFiltersNamesString(filters))
      : gaExploreUpdateFiltersScreen(makeFiltersNamesString(filters));

    if (isBasic) {
      openModal(MODAL_TYPES.SAVED_FILTERS);
    } else {
      const checkLimit = () =>
        screens.length >= FILTER_SCREENS_LIMIT
          ? showWarningToast()
          : openScreenModal();
      originFilters.length ? saveScreen(activeScreen.name) : checkLimit();
    }
  };

  const handleTabClick = (screen: FilterScreen, screenIdx: number) => {
    if (activeScreen.id !== screen.id) {
      setActiveScreen(screen);
      setTabsSelectOpened(false);
      isTouchDevice &&
        screenIdx === 0 &&
        !screen.filters.length &&
        openMobileFilters();
    }
  };

  const homeTabLabel = (
    <S.HomeTabWrapper>
      <Icon iconName="Home" size={24} />
      {isTouchDevice && <S.HomeName>{t('explore:screens.home')}</S.HomeName>}
    </S.HomeTabWrapper>
  );

  return (
    <S.FilterScreens
      data-testid="filter-screens"
      ref={
        exploreSectionsRefs[EXPLORE_SECTION_IDS.EXPLORE_SAVED_FILTERS_CHECK_ID]
      }
      {...setHelperHoverBehavior(
        EXPLORE_SECTION_IDS.EXPLORE_SAVED_FILTERS_CHECK_ID
      )}
    >
      <S.ScreenTabsWrapper ref={tabsWrapperRef}>
        {isTouchDevice && (
          <ScreensTabsSelect
            toggleTabSelect={toggleTabSelect}
            activeScreen={activeScreen}
            togglePopup={togglePopup}
            saveAction={saveAction}
            activeScreenId={activeScreenId}
            screenChanged={screenChanged}
            revertScreen={revertScreen}
            onRename={onRename}
            onDelete={onDelete}
            onHide={onHide}
            parentRef={tabsWrapperRef}
            homeTabLabel={homeTabLabel}
          />
        )}
        <S.ScreenTabs
          isMobile={isTouchDevice}
          isSelectOpened={tabsSelectOpened}
          data-testid="screen-tabs-wrapper"
        >
          {screens.map((screen, screenIdx) => (
            <S.ScreenTab
              key={screen.id}
              isMobile={isTouchDevice}
              isActive={screen.id === activeScreen.id}
              isHomeScreenTab={screenIdx === 0}
              onClick={() => handleTabClick(screen, screenIdx)}
              data-testid="filter-screen-tab"
            >
              <S.TabLabel key={screen.id}>
                <S.EllipsisDetector
                  key={screen.name}
                  label={screen.name || homeTabLabel}
                  trigger={[TooltipTrigger.Hover]}
                  disabled={isTouchDevice && screen.id !== activeScreen.id}
                  inPortal
                >
                  <S.TooltipTarget isHomeScreenTab={screenIdx === 0}>
                    {screen.name || homeTabLabel}
                  </S.TooltipTarget>
                </S.EllipsisDetector>

                {!!activeScreen.originFilters.length && !isTouchDevice && (
                  <S.TabMenuIcon>
                    <Icon
                      iconName="Ellipsis"
                      fillColor="var(--primary-color)"
                      size={16}
                      data-testid="screen-tab-menu-icon"
                      onClick={togglePopup}
                    />
                  </S.TabMenuIcon>
                )}
                {activeScreenId === screen.id && !isTouchDevice && (
                  <FilterScreenPopup
                    onRename={onRename}
                    onDelete={onDelete}
                    onHide={onHide}
                  />
                )}
              </S.TabLabel>
            </S.ScreenTab>
          ))}
        </S.ScreenTabs>
      </S.ScreenTabsWrapper>

      <S.ScreenContent>
        {activeScreen.id === screens[0].id && !filters.length && (
          <S.EmptyScreenMessage>
            {t('explore:screens.emptyScreenMessage')}
          </S.EmptyScreenMessage>
        )}
        <S.ChipsContainer>
          {filters.map(filter => (
            <S.FilterChip
              key={filter.key}
              label={filter.name}
              leftIcon={iconNameTranslate(filter.icon)}
              onClick={() => updateFilter(filter)}
            />
          ))}
        </S.ChipsContainer>
        {screenChanged && !isTouchDevice && (
          <S.ScreenButtons
            noMarginButtons={!filters.length}
            data-testid="screen-desktop-buttons"
          >
            <S.ScreenButtonsMsg>
              {originFilters.length
                ? t('explore:screens.changesMade', {
                    count: changedFiltersCount,
                  })
                : t('explore:screens.filtersAdded', {
                    count: changedFiltersCount,
                  })}
            </S.ScreenButtonsMsg>
            {!!originFilters.length && (
              <S.ScreenButton onClick={revertScreen}>
                {t('explore:screens.revert')}
              </S.ScreenButton>
            )}
            {!!filters.length && (
              <S.ScreenButton onClick={saveAction}>
                {t('explore:screens.save')}
              </S.ScreenButton>
            )}
          </S.ScreenButtons>
        )}
      </S.ScreenContent>
    </S.FilterScreens>
  );
};
