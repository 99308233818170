import React, { forwardRef } from 'react';

import { Input, InputProps } from '../../input/Input';
import { TRIGGER_INPUT_ID } from '../Dropdown';

export const AutocompleteInput = forwardRef<HTMLInputElement, InputProps>(
  (props, ref) => {
    return (
      <Input
        id={TRIGGER_INPUT_ID}
        type="text"
        autoComplete="off"
        spellCheck={false}
        role="combobox"
        ref={ref}
        {...props}
      />
    );
  }
);
