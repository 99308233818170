import { Icon, SvgIconNames } from '@toggle/design-system';
import {
  AssetClassCode,
  AssetClassMap,
  AssetSector,
  AssetSubClassCode,
  AssetSubClassMap,
  Class,
  MappedEntity,
} from '@toggle/toggle';
import React, { FC, useMemo } from 'react';

import * as Shared from '../../AssetLogo.styles';
import * as S from './LogoPlaceholder.styles';

const chartLineIcon = 'ChartLine';

const GISC_TO_ICON: Record<string, SvgIconNames> = {
  [AssetSector.CommunicationServices]: 'SatelliteDish',
  [AssetSector.ConsumerDiscretionary]: 'TShirt',
  [AssetSector.ConsumerStaples]: 'Washer',
  [AssetSector.Energy]: 'OilCan',
  [AssetSector.Financials]: 'MoneyCheckEdit',
  [AssetSector.HealthCare]: 'HospitalAlt',
  [AssetSector.Industrials]: 'Tools',
  [AssetSector.Materials]: 'Construction',
  [AssetSector.RealEstate]: 'Sign',
  [AssetSector.Utilities]: 'FaucetDrip',
  [AssetSector.InformationTechnology]: 'ComputerClassic',
  [AssetSector.Other]: chartLineIcon,
};

const SUB_CLASS_TO_ICON: Record<string, SvgIconNames> = {
  [AssetSubClassMap[AssetSubClassCode.FutureElectricity]]: 'Bolt',
  [AssetSubClassMap[AssetSubClassCode.FutureEnergy]]: 'OilCan',
  [AssetSubClassMap[AssetSubClassCode.FutureFood]]: 'Cow',
  [AssetSubClassMap[AssetSubClassCode.FutureGrains]]: 'Corn',
  [AssetSubClassMap[AssetSubClassCode.CommodityIndex]]: chartLineIcon,
  [AssetSubClassMap[AssetSubClassCode.FutureMetals]]: 'Construction',
  [AssetSubClassMap[AssetSubClassCode.FutureSofts]]: 'MugHot',
};

const getLogo = (entity: MappedEntity) => {
  switch (true) {
    case entity.class === Class.ClassStock:
      return (
        <S.LogoPlaceholderStyled data-testid="logo">
          {entity.name_short[0]}
        </S.LogoPlaceholderStyled>
      );
    case entity.asset_class === AssetClassMap[AssetClassCode.Equity]:
      return (
        <S.IconWrap>
          <Icon
            size={22}
            data-testid="logo"
            iconName={
              entity.gics.sector.code
                ? GISC_TO_ICON[entity.gics.sector.code]
                : GISC_TO_ICON[AssetSector.Other]
            }
          />
        </S.IconWrap>
      );
    case entity.asset_class === AssetClassMap[AssetClassCode.Fi]:
      return (
        <S.IconWrap>
          <Icon size={22} iconName="BadgePercentage" data-testid="logo" />
        </S.IconWrap>
      );
    case entity.sub_class === AssetSubClassMap[AssetSubClassCode.FxCrypto]:
      return (
        <S.CryptoImage
          src="/terminal/static/images/icons/generic-cryptocurrency.svg"
          alt="crypto-icon"
          data-testid="logo"
        />
      );
    case entity.asset_class === AssetClassMap[AssetClassCode.Fx]:
    case entity.asset_class === AssetClassMap[AssetClassCode.Currency]:
      return (
        <S.IconWrap>
          <Icon size={22} iconName="Coin" data-testid="logo" />
        </S.IconWrap>
      );
    case !!SUB_CLASS_TO_ICON[entity.sub_class]:
      return (
        <S.IconWrap>
          <Icon
            size={22}
            data-testid="logo"
            iconName={SUB_CLASS_TO_ICON[entity.sub_class]}
          />
        </S.IconWrap>
      );
    default:
      return (
        <S.IconWrap>
          <Icon
            size={22}
            data-testid="logo"
            iconName={GISC_TO_ICON[AssetSector.Other]}
          />
        </S.IconWrap>
      );
  }
};

interface LogoPlaceholderProps {
  entity: MappedEntity;
  className?: string;
  size: number;
}

export const LogoPlaceholder: FC<LogoPlaceholderProps> = ({
  entity,
  className,
  size,
}) => {
  const logoMemo = useMemo(() => {
    return getLogo(entity);
  }, [entity]);
  return (
    <Shared.LogoWrapper
      $size={size}
      className={className}
      data-testid="logo-placeholder"
    >
      {logoMemo}
    </Shared.LogoWrapper>
  );
};
