import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const ButtonWrapper = styled.div`
  padding: 6px 0;
`;

export const Pre = styled.p`
  white-space: pre-line;
`;

export const Button = styled(Link)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  padding: 8px 16px;
  border-radius: 4px;
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
  background-color: var(--base--100);
`;
