import { format } from 'date-fns';
import { TFunction } from 'i18next';

import { Entity } from '~/api/entities/entity-schema';
import { postEntities } from '~/api/entities/entity-service';
import { OptionalN } from '~/declarations/standard';
import {
  ArticleDirection,
  ExploreArticleDirection,
} from '~/declarations/toggle-api-enums';
import { ENDPOINT_URLS } from '~/global/toggle-api';
import { useAPI } from '~/shared/hooks/use-api/useAPI';
import { FILTER, MappedEntity } from '~/shared/hooks/use-entities';
import { fetchNews, NEWS_TYPE } from '~/shared/hooks/use-news';
import { APIFetch } from '~/shared/services/api-fetch/APIFetch';
import {
  fetchConnectedEntities,
  fetchSnakeMetaV2,
} from '~/shared/services/overview-widget/overview-widget-service';
import { ExploreFeedResponse } from '~/views/explore';

import { ARTICLE_INSIGHTS_START_DATE } from '../../overview-constants';
import {
  batchRequestHandler,
  mapRelatedEntity,
} from './use-related-assets-helpers';

export interface RelatedAssetType {
  id: string;
  analysisType: string;
  newInsightsCount: number;
  hasNewArticles: boolean;
  hasNewsHighlights: boolean;
  direction: OptionalN<ArticleDirection | ExploreArticleDirection>;
  recentArticleInsightDate: string;
  tag: string;
  name: string | undefined;
  priceBefore?: number;
  latestPrice: number | undefined;
  entity: MappedEntity;
  priceSuffix: string;
  lastTimestamp: number;
}

export const fetchInsights = (tags: string[]) => {
  const apiDateFormat = 'yyyyMMdd';
  const startDate = ARTICLE_INSIGHTS_START_DATE;

  return Promise.all(
    tags.map(tag =>
      APIFetch<ExploreFeedResponse>(
        `${
          ENDPOINT_URLS.ARTICLE_EXPLORE
        }?inc_gtk=true&entity=${tag}&from_date=${format(
          startDate,
          apiDateFormat
        )}`
      )
    )
  ).then(res => res.map(r => (r instanceof Error ? [] : r.result)));
};

export const fetchAssetsData = async (
  entities: Entity[],
  t: TFunction
): Promise<RelatedAssetType[]> => {
  if (!entities.length) {
    return [];
  }

  const snakeMetaPromise = fetchSnakeMetaV2(
    entities.map(f => f.default_snake)
  ).then(batchRequestHandler);

  const articlesPromise = fetchInsights(entities.map(f => f.tag));

  const newsPromise = fetchNews({
    type: NEWS_TYPE.AI,
    tickers: entities.filter(f => f.ticker).map(f => f.ticker),
    page: 0,
    limit: 60,
  });

  const [snakeMeta, articles, news] = await Promise.all([
    snakeMetaPromise,
    articlesPromise,
    newsPromise,
  ]);

  return entities.map((e, idx) =>
    mapRelatedEntity({ entity: e, snakeMeta, articles: articles[idx], news, t })
  );
};

export const useRelatedAssets = (entityTag: string, t: TFunction) => {
  const fetchRelatedAssets = async () => {
    const connectedEntities = await fetchConnectedEntities(entityTag);

    if (connectedEntities instanceof Error) {
      throw new Error('Failed to fetch connected entities');
    }

    const filtered = await postEntities({
      args: connectedEntities,
      filter: FILTER.tag,
    });
    if (filtered instanceof Error) {
      throw new Error('Failed to fetch entity');
    }

    filtered.sort(
      (a, b) =>
        connectedEntities.indexOf(a.tag) - connectedEntities.indexOf(b.tag)
    );

    return fetchAssetsData(filtered, t);
  };

  return useAPI(fetchRelatedAssets, {
    deps: [entityTag],
  });
};
