import { customScrollMinimal } from '@toggle/design-system';
import styled from 'styled-components';

import { PDFPage } from './PDFPage';

export const StyledPDFPage = styled(PDFPage)`
  width: 100%;
  margin-bottom: 8px;
`;

export const Main = styled.main`
  flex: 1;
  overflow: auto;
  ${customScrollMinimal}
`;
