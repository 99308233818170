import React, {
  forwardRef,
  ReactNode,
  TextareaHTMLAttributes,
  useState,
} from 'react';

import * as S from './ChatInput.styles';

export interface ChatInputProps
  extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  children?: ReactNode;
}

const ROWS_MIN = 1;
const ROWS_MAX = 3;
const TEXTAREA_LINE_HEIGHT = 24;

export const ChatInput = forwardRef<HTMLTextAreaElement, ChatInputProps>(
  ({ children, onChange, disabled = false, ...rest }, ref) => {
    const [rows, setRows] = useState(ROWS_MIN);

    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      const previousRows = event.target.rows;
      event.target.rows = ROWS_MIN;

      const currentRows = Math.ceil(
        event.target.scrollHeight / TEXTAREA_LINE_HEIGHT
      );

      if (currentRows === previousRows) {
        event.target.rows = currentRows;
      }

      if (currentRows >= ROWS_MAX) {
        event.target.rows = ROWS_MAX;
        event.target.scrollTop = event.target.scrollHeight;
      }

      setRows(currentRows < ROWS_MAX ? currentRows : ROWS_MAX);
      onChange?.(event);
    };

    return (
      <S.ChatInputWrapper>
        <S.TextareaElement
          data-testid="chat-input"
          as="textarea"
          ref={ref}
          onChange={handleChange}
          rows={rows}
          disabled={disabled}
          {...rest}
        />
        {children}
      </S.ChatInputWrapper>
    );
  }
);
