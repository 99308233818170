import { TFunction } from 'i18next';
import React, { FC, useRef } from 'react';

import { config } from '~/config';
import { ArticleV3 } from '~/declarations/toggle-api';
import { accountRoutes, appPaths } from '~/routes/app-paths';
import { useMediaQuery } from '~/shared/hooks/MediaQueryHook';
import { MediaQueries } from '~/shared/hooks/MediaQueryList';
import { useLocationKeepQueryParams } from '~/shared/hooks/use-location-keep-query-params/useLocationKeepQueryParams';
import { gaArticleShowDetails } from '~/shared/utils/ganalytics/articleGA';

import { CollapsePanelBody, Feed, Overview } from '../../partials';
import { BookmarkHeader } from '../bookmark-header/BookmarkHeader';
import { SidebarItems } from './components/SidebarItems';
import * as S from './FullArticle.styled';

interface FullArticleProps {
  article: ArticleV3;
  articleID: string;
  t: TFunction;
  limit?: number;
  consumed?: number;
  isAuthenticated: boolean;
  isBasic: boolean;
}

export interface CollapseRef {
  uncollapseIfNeeded: Function;
  collapsed: boolean;
}

const {
  urls: { learnMoreLink, signUpLink },
} = config;

export const FullArticle: FC<FullArticleProps> = ({
  isAuthenticated,
  isBasic,
  article,
  articleID,
  t,
  limit,
  consumed,
}) => {
  const overviewSectionRef = useRef<HTMLHeadingElement>(null);
  const mightMoveSectionRef = useRef<HTMLHeadingElement>(null);
  const qualityChecklistSectionRef = useRef<HTMLHeadingElement>(null);
  const backTestSectionRef = useRef<HTMLHeadingElement>(null);
  const moreLikeThisSectionRef = useRef<HTMLHeadingElement>(null);
  const sectionsRefs = [
    overviewSectionRef,
    mightMoveSectionRef,
    qualityChecklistSectionRef,
    backTestSectionRef,
    moreLikeThisSectionRef,
  ];
  const parentRef = useRef<CollapseRef>(null);

  const learnMoreHref = useLocationKeepQueryParams(learnMoreLink, [
    'ref',
    'promo',
  ]);
  const signUpHref = useLocationKeepQueryParams(signUpLink, ['ref', 'promo']);

  const currentMedia = useMediaQuery(s => s.currentMedia);
  const isDesktop = [MediaQueries.SMALL_DESKTOP, MediaQueries.DESKTOP].includes(
    currentMedia
  );
  const isLargeDesktop = [MediaQueries.LARGE_DESKTOP].includes(currentMedia);
  const isMiddleDesktop = isDesktop && !isLargeDesktop;
  const isGuest = !isAuthenticated;

  const displayBanner = isBasic || isGuest;

  return (
    <S.ArticleContainer>
      {isAuthenticated ? (
        <S.BookmarkSection>
          <BookmarkHeader
            articleID={articleID}
            bookmarked={article.bookmarked}
          />
        </S.BookmarkSection>
      ) : (
        <a href={learnMoreHref} target="_blank" rel="noopener noreferrer">
          <S.GuestHeader>{t('auth:signInBanner.subHeader')}</S.GuestHeader>
        </a>
      )}
      {displayBanner && (
        <S.BannerContainer isGuest={isGuest}>
          <S.LimitBanner isGuest={isGuest}>
            {isAuthenticated ? (
              <p>
                {t('article:limit.message', { limit, consumed })}
                <S.SubscribeLink
                  to={`${appPaths.myAccount.subscription}/${accountRoutes.selectPlan}`}
                >
                  {t('article:limit.subscribe')}
                </S.SubscribeLink>
              </p>
            ) : (
              <S.SubscribeLink to={signUpHref}>
                {t('article:limit.message', { limit, consumed })}{' '}
                <span>{t('article:limit.guestMessage')}</span>
              </S.SubscribeLink>
            )}
          </S.LimitBanner>
        </S.BannerContainer>
      )}
      <S.Article data-testid="article-section">
        {isMiddleDesktop ? (
          <S.ScrollableSidebar data-testid="scrollable-sidebar">
            <SidebarItems
              article={article}
              sectionsRefs={sectionsRefs}
              parentRef={parentRef}
              isGuest={isGuest}
              isBasic={isBasic}
              displayBanner={displayBanner}
            />
          </S.ScrollableSidebar>
        ) : (
          <SidebarItems
            article={article}
            sectionsRefs={sectionsRefs}
            isGuest={isGuest}
            isBasic={isBasic}
            parentRef={parentRef}
            displayBanner={displayBanner}
          />
        )}
        <S.Overview>
          <Overview
            article={article}
            ref={overviewSectionRef}
            articleID={articleID}
          />
        </S.Overview>
        <S.StyledCollapsePanel
          defaultText={t('article:whyItMightMove.hideDetails')}
          collapsedText={t('article:whyItMightMove.moreDetails')}
          isThemeButtonStyle
          parentRef={parentRef}
          isDisabledBtn={isBasic}
          onCollapsed={isCollapsed => gaArticleShowDetails(isCollapsed)}
        >
          {!isBasic && (
            <CollapsePanelBody
              article={article}
              t={t}
              mightMoveSectionRef={mightMoveSectionRef}
              qualityChecklistSectionRef={qualityChecklistSectionRef}
              backTestSectionRef={backTestSectionRef}
            />
          )}
        </S.StyledCollapsePanel>
        {isAuthenticated && (
          <S.ArticleFooter>
            <Feed t={t} ref={moreLikeThisSectionRef} articleId={articleID} />
          </S.ArticleFooter>
        )}
      </S.Article>
    </S.ArticleContainer>
  );
};
