import {
  autoUpdate,
  flip,
  FloatingFocusManager,
  FloatingList,
  FloatingNode,
  FloatingPortal,
  FloatingTree,
  safePolygon,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useFloatingNodeId,
  useFloatingParentNodeId,
  useFloatingTree,
  useHover,
  useInteractions,
  useListItem,
  useListNavigation,
  useMergeRefs,
  useRole,
  useTypeahead,
} from '@floating-ui/react';
import React, {
  cloneElement,
  createContext,
  Dispatch,
  FocusEvent,
  forwardRef,
  HTMLProps,
  MouseEvent,
  ReactElement,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import * as S from './MultiDropdown.styles';

const MenuContext = createContext<{
  getItemProps: (userProps?: HTMLProps<HTMLElement>) => Record<string, unknown>;
  activeIndex: number | null;
  setActiveIndex: Dispatch<SetStateAction<number | null>>;
  isOpen: boolean;
}>({
  getItemProps: () => ({}),
  activeIndex: null,
  setActiveIndex: () => {},
  isOpen: false,
});

interface MenuProps {
  baseComponent: ReactElement;
  children?: ReactNode;
  onOpen?: (open: boolean, event?: Event) => void;
}

export const MenuComponent = forwardRef<
  HTMLDivElement,
  MenuProps & HTMLProps<HTMLDivElement>
>(({ children, baseComponent, onOpen, ...props }, forwardedRef) => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const elementsRef = useRef<Array<HTMLDivElement | null>>([]);
  const labelsRef = useRef<Array<string | null>>([]);
  const parent = useContext(MenuContext);

  const tree = useFloatingTree();
  const nodeId = useFloatingNodeId();
  const parentId = useFloatingParentNodeId();
  const item = useListItem();

  const isNested = parentId != null;

  const handleOpenChange = (open: boolean, event?: Event) => {
    setIsOpen(open);
    onOpen?.(open, event);
  };

  const { floatingStyles, refs, context } = useFloating<HTMLDivElement>({
    nodeId,
    open: isOpen,
    onOpenChange: handleOpenChange,
    placement: isNested ? 'right-start' : 'bottom-start',
    middleware: [flip(), shift()],
    whileElementsMounted: autoUpdate,
  });

  const hover = useHover(context, {
    enabled: isNested,
    delay: { open: 75 },
    handleClose: safePolygon({ blockPointerEvents: true }),
  });
  const click = useClick(context, {
    event: 'mousedown',
    toggle: !isNested,
    ignoreMouse: isNested,
  });
  const role = useRole(context, { role: 'menu' });
  const dismiss = useDismiss(context, { bubbles: true });
  const listNavigation = useListNavigation(context, {
    listRef: elementsRef,
    activeIndex,
    nested: isNested,
    onNavigate: setActiveIndex,
  });
  const typeahead = useTypeahead(context, {
    listRef: labelsRef,
    onMatch: isOpen ? setActiveIndex : undefined,
    activeIndex,
  });

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions(
    [hover, click, role, dismiss, listNavigation, typeahead]
  );

  // Event emitter allows you to communicate across tree components.
  // This effect closes all menus when an item gets clicked anywhere
  // in the tree.
  useEffect(() => {
    if (!tree) {
      return;
    }

    function onSubMenuOpen(event: { nodeId: string; parentId: string }) {
      if (event.nodeId !== nodeId && event.parentId === parentId) {
        handleOpenChange(false);
      }
    }

    tree.events.on('click', () => handleOpenChange(false));
    tree.events.on('menuopen', onSubMenuOpen);

    return () => {
      tree.events.off('click', () => handleOpenChange(false));
      tree.events.off('menuopen', onSubMenuOpen);
    };
  }, [tree, nodeId, parentId]);

  useEffect(() => {
    if (isOpen && tree) {
      tree.events.emit('menuopen', { parentId, nodeId });
    }
  }, [tree, isOpen, nodeId, parentId]);

  const triggerComponent = cloneElement(baseComponent, {
    'data-testid': 'dropdown-trigger',
    role: isNested ? 'menuitem' : undefined,
    ...getReferenceProps(
      parent.getItemProps({
        ...props,
        onFocus(event: FocusEvent<HTMLDivElement>) {
          props.onFocus?.(event);
        },
      })
    ),
  });

  return (
    <FloatingNode id={nodeId}>
      <div ref={useMergeRefs([refs.setReference, item.ref, forwardedRef])}>
        {triggerComponent}
      </div>

      <MenuContext.Provider
        value={{
          activeIndex,
          setActiveIndex,
          getItemProps,
          isOpen,
        }}
      >
        <FloatingList elementsRef={elementsRef} labelsRef={labelsRef}>
          {isOpen && (
            <FloatingPortal>
              <FloatingFocusManager
                context={context}
                modal={false}
                initialFocus={isNested ? -1 : 0}
                returnFocus={!isNested}
              >
                <S.Menu
                  ref={refs.setFloating}
                  style={floatingStyles}
                  {...getFloatingProps()}
                >
                  {children}
                </S.Menu>
              </FloatingFocusManager>
            </FloatingPortal>
          )}
        </FloatingList>
      </MenuContext.Provider>
    </FloatingNode>
  );
});

interface MenuItemProps {
  customComponent: ReactElement;
}

export const MultiDropdownItem = forwardRef<
  HTMLDivElement,
  MenuItemProps & HTMLProps<HTMLDivElement>
>(({ customComponent, ...props }, forwardedRef) => {
  const menu = useContext(MenuContext);
  const item = useListItem();
  const tree = useFloatingTree();

  const customMenuItemComponent = cloneElement(customComponent, {
    ref: useMergeRefs([item.ref, forwardedRef]),
    role: 'menuitem',
    ...menu.getItemProps({
      onClick(event: MouseEvent<HTMLDivElement>) {
        props.onClick?.(event);
        tree?.events.emit('click');
      },
      onFocus(event: FocusEvent<HTMLDivElement>) {
        props.onFocus?.(event);
      },
    }),
    ...props,
  });

  return <S.MenuItem>{customMenuItemComponent}</S.MenuItem>;
});

export const MultiDropdown = forwardRef<
  HTMLDivElement,
  MenuProps & HTMLProps<HTMLDivElement>
>((props, ref) => {
  const parentId = useFloatingParentNodeId();

  if (parentId === null) {
    return (
      <FloatingTree>
        <MenuComponent {...props} ref={ref} />
      </FloatingTree>
    );
  }

  return <MenuComponent {...props} ref={ref} />;
});
