import { deviceConstraints, typography } from '@toggle/design-system';
import styled from 'styled-components';

import { ImageOverlay } from '../image-overlay';

export const BacktestSample = styled.tbody`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: var(--base--0);
  padding-bottom: 40px;

  @media ${deviceConstraints.tablet} {
    flex-direction: row;
  }

  td {
    display: block;
  }

  th,
  td {
    ${typography.dingo}
    padding: 0 0 16px 0;
  }
`;

export const BacktestSampleLeft = styled.tr`
  @media ${deviceConstraints.smartphone} {
    margin-right: 0;
  }

  @media ${deviceConstraints.tablet} {
    margin-right: 30px;
  }
`;

export const BacktestImage = styled.img`
  margin-bottom: 40px;
`;
export const BacktestFigure = styled(ImageOverlay)`
  margin-top: 32px;
`;

export const BacktestSection = styled.section`
  p,
  h4 {
    ${typography.emu}
    padding-left: 16px;

    @media ${deviceConstraints.tablet} {
      padding-left: 0;
    }
  }
`;

export const DisclaimerText = styled.p`
  color: var(--base--35);
  ${typography.koala}
`;

export const Title = styled.h2`
  color: var(--base--70);
  position: relative;
  padding-bottom: 6px;
  margin-bottom: 32px;
  padding-left: 16px;

  @media ${deviceConstraints.tablet} {
    padding-left: 0;
  }

  &:before {
    content: '';
    display: inline-block;
    width: 100%;
    @media ${deviceConstraints.tablet} {
      width: 288px;
    }

    left: 0;
    height: 2px;
    position: absolute;
    top: 100%;
    background-color: var(--base--70);
  }
`;
