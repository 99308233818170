import { config } from '~/config';
import { HttpMethods } from '~/shared/services/api-fetch/constants';
import { validateApiFetch } from '~/shared/services/api-fetch/validate-api-fetch/validateApiFetch';

import {
  ExploreFilterPayload,
  exploreFilterPayloadSchema,
  UserFilterPayload,
  UserFilterResponse,
  userFilterResponseArrSchema,
  userFilterResponseSchema,
} from './explore-filter-schema';

const {
  api: { root: apiRoot },
} = config;

export const exploreFilterEndpoints = {
  exploreFilters: `${apiRoot}explore-filters/v1/filters`,
  userFilters: (screenId?: string) =>
    `${apiRoot}explore-filters/v1/user-filters${
      screenId ? `/${screenId}` : ''
    }`,
};

export const getExploreFilterOptions = () => {
  return validateApiFetch<ExploreFilterPayload>({
    endpoint: exploreFilterEndpoints.exploreFilters,
    schema: exploreFilterPayloadSchema,
  });
};

export const getUserFilters = () => {
  return validateApiFetch<UserFilterResponse[]>({
    endpoint: exploreFilterEndpoints.userFilters(),
    schema: userFilterResponseArrSchema,
  });
};

export const postUserFilter = (newFilterPayload: UserFilterPayload) => {
  return validateApiFetch<UserFilterResponse>({
    endpoint: exploreFilterEndpoints.userFilters(),
    schema: userFilterResponseSchema,
    requestInfo: {
      method: HttpMethods.Post,
      body: JSON.stringify(newFilterPayload),
    },
  });
};

export const putUserFilter = (
  screenId: string,
  updatePayload: UserFilterPayload
) => {
  return validateApiFetch<UserFilterResponse>({
    endpoint: exploreFilterEndpoints.userFilters(screenId),
    schema: userFilterResponseSchema,
    requestInfo: {
      method: HttpMethods.Put,
      body: JSON.stringify(updatePayload),
    },
  });
};

export const deleteUserFilter = (screenId: string) => {
  return validateApiFetch<UserFilterResponse>({
    endpoint: exploreFilterEndpoints.userFilters(screenId),
    requestInfo: {
      method: HttpMethods.Delete,
    },
  });
};
