import {
  BaseThemeNames,
  DEFAULT_THEME,
  ThemeNames,
  themes,
} from '@toggle/design-system';

import { BlindOptions } from '../../theme.types';

export const isValidThemeName = (value: any): value is ThemeNames => {
  return value in themes;
};

export const splitThemeName = (themeName: ThemeNames) => {
  const parts = themeName.split('_');
  const baseName = parts[0] as BaseThemeNames;
  const blindOption = parts[1] as BlindOptions | undefined;

  return { baseName, blindOption };
};

export const createThemeName = (
  baseName: BaseThemeNames,
  blindOption?: BlindOptions
) => (blindOption ? `${baseName}_${blindOption}` : baseName);

export const getValidThemeName = (
  baseName: BaseThemeNames,
  blindOption?: BlindOptions
) => {
  const themeName = createThemeName(baseName, blindOption);

  if (isValidThemeName(themeName)) {
    return themeName;
  }

  return DEFAULT_THEME;
};
