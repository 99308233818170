import { typography } from '@toggle/design-system';
import styled from 'styled-components';

interface DividerProps {
  isNotEnough: boolean;
  isNoResults: boolean;
}

const getMargin = (props: DividerProps) => {
  const noResults = props.isNoResults ? '20px 0px 0px' : '12px 0px';
  return props.isNotEnough ? '38px 0px 12px' : noResults;
};

export const Divider = styled.div<DividerProps>`
  display: flex;
  align-items: center;
  ${typography.koala}

  margin: ${getMargin};
  color: var(--base--20);

  &::before,
  &::after {
    content: '';
    height: 1px;
    background-color: var(--base--80);
    flex-grow: 1;
  }

  &::before {
    margin-right: 40px;
  }

  &::after {
    margin-left: 40px;
  }
`;
