import React from 'react';
import { useTranslation } from 'react-i18next';

import * as S from './JpmSearchProgress.styles';

const DEFAULT_JPM_SEARCH_DURATION = 60000; // 60s

export const JpmSearchProgress = () => {
  const { t } = useTranslation('common');

  return (
    <S.Container>
      <S.JpmProgressBar
        duration={`${DEFAULT_JPM_SEARCH_DURATION}ms`}
        delay="100ms"
      />
      <S.Title>{t('chat:cognitiveSearch.searching')}</S.Title>
    </S.Container>
  );
};
