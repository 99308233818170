import { Icon, typography } from '@toggle/design-system';
import styled from 'styled-components';

export const Market = styled.div<{ $marketOpen: boolean }>`
  display: flex;
  align-items: center;
  ${typography.quokka}
  color: ${({ $marketOpen }) =>
    $marketOpen ? 'var(--success--default)' : 'var(--base--35)'};
`;

export const DotIcon = styled(Icon)<{ $marketOpen: boolean }>`
  fill: ${({ $marketOpen }) =>
    $marketOpen ? 'var(--success--default)' : 'var(--base--35)'};
  margin-right: 8px;
`;
