import { typographyV2 } from '@toggle/design-system';
import styled from 'styled-components';

export const Container = styled.div`
  background-color: var(--background-default);
  max-width: 774px;
  display: flex;
  flex-direction: column;
  padding: 0px 8px;
`;

export const Header = styled.header`
  padding: 8px;
`;

export const Footer = styled.footer`
  padding: 8px 0px;
`;
export const Title = styled.h2`
  ${typographyV2.titleSm};
  color: var(--text-soft);
  padding: 8px 0px;
`;
export const ConditionText = styled.p`
  ${typographyV2.titleBase};
`;
export const OccurrenceText = styled.span`
  padding-left: 8px;
`;

export const HorizonContainer = styled.div`
  width: 240px;
`;
