import { typography } from '@toggle/design-system';
import styled from 'styled-components';

export const ActionMenuList = styled.ul`
  list-style-type: none;
`;

export const ActionContainer = styled.li`
  width: 100%;
  cursor: pointer;

  &:first-child {
    margin-bottom: 8px;
  }
`;

export const ActionButton = styled.a`
  width: 100%;
  border: 1px solid rgba(255, 255, 255, 0.16);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-transform: uppercase;
  color: var(--base--20);
  padding: 12px;
  ${typography.emu}

  &:hover {
    background-color: rgba(var(--menu-active-item-border-rgb), 0.4);
    color: var(--primary-color);
    svg {
      fill: var(--primary-color);
    }
  }
`;
