import { ScaleLinear } from 'd3';

const eps = 1e-6;

export const numZoom = (
  origin: ScaleLinear<number, number>,
  dest: ScaleLinear<number, number>
) => {
  // calc zoom translate values to map domain from dest to origin scale

  const originalRange = origin.range();
  const destRange = origin.domain().map(dest);

  const zoom =
    originalRange[1] == originalRange[0]
      ? 1
      : (destRange[1] - destRange[0]) / (originalRange[1] - originalRange[0]);
  const start = origin.range()[0];
  const destStart = dest(origin.domain()[0]) / zoom;
  const offset = destStart - start;

  return { zoom, offset };
};

export const fixEmptyDomain = (scale: ScaleLinear<number, number>) => {
  if (scale.domain()[0] === scale.domain()[1]) {
    return scale
      .copy()
      .domain([scale.domain()[0] - eps, scale.domain()[0] + eps]);
  }
  return scale;
};

export const getDirectionColor = (isBullish?: boolean) =>
  isBullish ? 'var(--viz-bullish)' : 'var(--viz-bearish)';
