import { Alert, AlertType } from '@toggle/design-system';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { SubscriptionName } from '~/api/subscription/subscription-schema';
import { useDismissContinuous } from '~/shared/hooks/use-dismiss-continuous/useDismissContinuous';
import { useSubscription } from '~/stores/use-subscription/useSubscription';
import { formatTrialDays } from '~/views/my-account/subscription/utils/subscription-view-utils';

import * as S from './AlertTrialEnds.styles';

export const DISMISSED_KEY = '__toggle_dismissed_trial_notification';
const SHOW_ALERT_BEFORE_IN_DAYS = 7;

export interface AlertTrialEndsProps {
  upgradeToPro: () => void;
  redirectToPlanMatrixPage: () => void;
}

export const AlertTrialEnds: FC<AlertTrialEndsProps> = ({
  upgradeToPro,
  redirectToPlanMatrixPage,
}) => {
  const { t } = useTranslation('subscription');

  const { trialTo, upcomingSubscription } = useSubscription(state => ({
    trialTo: state.userSubscription.trial_to,
    upcomingSubscription: state.userSubscription?.upcoming_subscription?.name,
  }));

  const hasNotSubscribed = upcomingSubscription === SubscriptionName.Basic;

  const { isVisible, handleDismiss } = useDismissContinuous({
    endDate: trialTo,
    cookieKey: DISMISSED_KEY,
    showBeforeInDays: SHOW_ALERT_BEFORE_IN_DAYS,
  });

  if (!isVisible || !(trialTo && hasNotSubscribed)) {
    return null;
  }

  const onPrimaryBtnClick = () => {
    upgradeToPro();
    handleDismiss();
  };

  const onSecondaryBtnClick = () => {
    redirectToPlanMatrixPage();
    handleDismiss();
  };

  const trialDaysLeft = formatTrialDays(t, trialTo);

  return (
    <S.AlertTrialEndsRoot>
      <Alert
        data-testid="alert-trial-ends"
        variant="toast"
        type={AlertType.Warning}
        title={t('subscription:alerts.trialEnds.title', {
          trialDaysLeft,
        })}
        message={t('subscription:alerts.trialEnds.message')}
        onClose={handleDismiss}
        primaryBtn={{
          label: t('subscription:alerts.trialEnds.primaryBtnLabel'),
          onClick: onPrimaryBtnClick,
        }}
        secondaryBtn={{
          label: t('subscription:alerts.trialEnds.secondaryBtnLabel'),
          onClick: onSecondaryBtnClick,
        }}
      />
    </S.AlertTrialEndsRoot>
  );
};
