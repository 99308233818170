import React from 'react';
import { useTranslation } from 'react-i18next';

import * as S from './InsightsEmptyState.styles';

export function InsightsEmptyState() {
  const { t } = useTranslation('explore');

  return (
    <S.NoInsights data-testid="insights-empty-state">
      <S.Header>{t('explore:noInsights.header')}</S.Header>
      <S.Subheader>{t('explore:noInsights.subheader')}</S.Subheader>
      <S.Image
        src={'/app/assets/explore/empty-state.png'}
        srcSet="/app/assets/explore/empty-state@2x.png 2x, /app/assets/explore/empty-state@3x.png 3x"
      />
    </S.NoInsights>
  );
}
