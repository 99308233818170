import { ShortDescription, Spinner } from '@toggle/design-system';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { MappedEntity, useEntities } from '~/shared/hooks/use-entities';
import { ConditionEnum } from '~/views/analyze/experiment/components/condition-builder/utils/conditionHelpers';
import { ArticleInsightCard } from '~/views/analyze/hooks/use-article-insight-cards/useInsightCards';
import {
  CompassDataWithFormat,
  CompassPastEpisodes,
  useExperimentStore,
} from '~/views/analyze/store/useExperimentStore';

import { EntityHeader } from '../shared/entity-header/EntityHeader';
import { EpisodesTable } from './episodes-table/EpisodesTable';
import { EvidenceTable } from './evidence-table/EvidenceTable';
import {
  getStarTotal,
  mapExperimentToEvidenceData,
} from './helpers/scenario-helpers';
import { PeriodView } from './period-view/PeriodView';
import * as S from './ScenarioWidget.styles';

export interface ScenarioWidgetProps {
  entityTag: string;
  articleInsight?: ArticleInsightCard;
}

export const ScenarioWidget = ({
  entityTag,
  articleInsight,
}: ScenarioWidgetProps) => {
  const { data } = useEntities({
    entities: [entityTag],
  });
  const entity = data?.[0];

  const {
    loading,
    experimentData,
    loadCompass,
    fetchExperimentDataByScenarioId,
  } = useExperimentStore(state => ({
    experimentData: state.compass.compassData,
    loadCompass: state.loadCompass,
    loading: state.compass.loading,
    fetchExperimentDataByScenarioId: state.fetchExperimentDataByScenarioId,
  }));

  useEffect(() => {
    if (articleInsight) {
      fetchExperimentDataByScenarioId({
        scenarioId: articleInsight.compass_id,
        date: articleInsight.created_at.split('T')[0],
        entityTag: articleInsight.entity.tag,
        snake: articleInsight.entity.default_snake,
      });
    } else if (entity) {
      loadCompass({
        entityTag: entity.tag,
        snake: `${entity.tag}.price.ds`,
        scenarioConditions: [
          {
            order: 0,
            display: 'US Yield Curve Inverts',
            entity: 'sov_us_usd_2y_10y_curve',
            snake: 'sov_us_usd_2y_10y_curve.yield',
            condition: ConditionEnum.crosses_below,
            value: 0,
          },
        ],
      });
    }
  }, [entity, articleInsight]);

  if (!entity || loading || !experimentData) {
    return (
      <S.SpinnerContainer>
        <Spinner />
      </S.SpinnerContainer>
    );
  }

  return <Scenario entity={entity} experimentData={experimentData} />;
};

const Scenario = ({
  entity,
  experimentData,
}: {
  entity: MappedEntity;
  experimentData: CompassDataWithFormat;
}) => {
  const { t } = useTranslation(['widget', 'common', 'analyze']);
  const [selectedPeriod, setSelectedPeriod] = useState<CompassPastEpisodes>();

  const evidenceExperimentData = mapExperimentToEvidenceData(
    experimentData.episodes
  );
  const totalPastEpisodesDates = evidenceExperimentData.length;

  const [checklistData, starsAmount] = useMemo(() => {
    if (selectedPeriod) {
      const stars = getStarTotal(selectedPeriod.period, experimentData);
      return [stars.checklistPeriod, stars.starCount];
    }
    return [undefined, undefined];
  }, [experimentData, selectedPeriod]);

  return (
    <S.ScenarioWidgetRoot data-testid="widget-scenario">
      {selectedPeriod ? (
        <PeriodView
          selectedPeriod={selectedPeriod}
          goBack={() => setSelectedPeriod(undefined)}
          starsAmount={starsAmount}
          checklistData={checklistData}
        />
      ) : (
        <>
          <EntityHeader entity={entity} />
          <ShortDescription
            heading={t('scenario.outcomes')}
            text={t('scenario.outcomeMovement', {
              asset: entity.ticker.toUpperCase(),
              count: totalPastEpisodesDates,
            })}
          />
          <EpisodesTable
            setSelectedPeriod={setSelectedPeriod}
            experimentData={experimentData}
          />
          <ShortDescription
            heading={t('scenario.evidence')}
            text={t('scenario.evidenceMovement', {
              count: totalPastEpisodesDates,
            })}
          />
          <EvidenceTable evidenceExperimentData={evidenceExperimentData} />
        </>
      )}
    </S.ScenarioWidgetRoot>
  );
};
