import { Property } from 'csstype';
import React, { ReactNode, useEffect } from 'react';

import { StyledComponent } from '~/common/styled-component';
import { Portal } from '~/components/portal';

import * as S from './Drawer.styles';

export enum CloseTrigger {
  CloseButtonClick = 'CloseButtonClick',
  BackgroundClick = 'BackgroundClick',
  EscapeKey = 'EscapeKey',
}

export interface DrawerProps {
  headerTitle?: ReactNode;
  headerSubtitle?: ReactNode;
  isOpen: boolean;
  handleCloseClick: () => void;
  closeTriggers?: CloseTrigger | CloseTrigger[];
  closeIcon?: boolean;
  className?: string;
  children: ReactNode;
  drawerWidth?: Property.Width;
}

export const Drawer: StyledComponent<DrawerProps, typeof S> = ({
  className,
  headerTitle,
  headerSubtitle,
  handleCloseClick,
  closeTriggers = [
    CloseTrigger.CloseButtonClick,
    CloseTrigger.BackgroundClick,
    CloseTrigger.EscapeKey,
  ],
  isOpen,
  children,
  closeIcon = true,
  drawerWidth = '400px',
  ...rest
}) => {
  const closeTriggersArray = Array.isArray(closeTriggers)
    ? closeTriggers
    : [closeTriggers];

  useEffect(() => {
    const keyHandler = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        handleCloseClick();
      }
    };

    if (closeTriggersArray.includes(CloseTrigger.EscapeKey) && isOpen) {
      window.addEventListener('keyup', keyHandler);
    }

    return () => {
      window.removeEventListener('keyup', keyHandler);
    };
  }, [isOpen, handleCloseClick]);

  const onBackgroundOverlayClick = () => {
    if (closeTriggersArray.includes(CloseTrigger.BackgroundClick)) {
      handleCloseClick();
    }
  };

  const onCloseButtonClick = () => {
    if (closeTriggersArray.includes(CloseTrigger.CloseButtonClick)) {
      handleCloseClick();
    }
  };

  return (
    <Portal>
      <S.RootContainer className={className}>
        <S.BackgroundOverlay
          onClick={onBackgroundOverlayClick}
          isOpen={isOpen}
          data-testid="background-overlay"
        />
        <S.Container
          data-testid="drawer-container"
          isOpen={isOpen}
          $width={drawerWidth}
          {...rest}
        >
          <S.Header>
            <div>
              {headerTitle && <S.Title>{headerTitle}</S.Title>}
              {headerSubtitle && <S.Subtitle>{headerSubtitle}</S.Subtitle>}
            </div>

            {closeIcon && (
              <S.CloseButton
                data-testid="drawer-header-close-btn"
                onClick={onCloseButtonClick}
                iconName="Close"
                variant="tertiary"
              />
            )}
          </S.Header>
          <S.Body>{children}</S.Body>
        </S.Container>
      </S.RootContainer>
    </Portal>
  );
};

Drawer.Styled = S;
