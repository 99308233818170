import React, { FC, ImgHTMLAttributes } from 'react';

import * as S from './FixedIncomeLogo.styles';

interface ImageProps extends ImgHTMLAttributes<HTMLImageElement> {
  'data-testid'?: string;
}

interface FixedIncomeLogoProps {
  entityTag: string;
  imgProps: ImageProps;
}

const tenorDurationRegExp = /([\d]+[a-z])_?([\d]+[a-z])?/;

const getTenorDuration = (entityTag: string) => {
  const match = entityTag.match(tenorDurationRegExp);
  if (match) {
    return match[2] ? `${match[1]}${match[2]}` : match[1];
  }

  return '';
};

export const FixedIncomeLogo: FC<FixedIncomeLogoProps> = ({
  entityTag,
  imgProps,
}) => {
  const tenorDuration = getTenorDuration(entityTag);
  return (
    <>
      {tenorDuration && <S.Badge>{tenorDuration}</S.Badge>}
      <img {...imgProps} />
    </>
  );
};
