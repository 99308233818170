import { customScrollMinimal } from '@toggle/design-system';
import styled from 'styled-components';

export const LegendContainer = styled.div`
  overflow-y: scroll;
  max-height: 400px;
  ${customScrollMinimal}
  position: absolute;
  top: 8px;
  left: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
