import { deviceConstraints, typography } from '@toggle/design-system';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import LogoPath from '~/assets/core/toggle-logo.svg';

export const wrapperCss = css`
  background: var(--base--95);
  padding: 44px 40px 40px;
`;

export const Main = styled.main`
  max-width: 480px;
  margin: 6% auto;
`;

export const Logo = styled(LogoPath)`
  display: block;
  height: 24px;
  width: 156px;
  margin: 0 auto;

  [class*='logoColor'] {
    fill: var(--primary-color);
  }
`;

export const WrapperWithBg = styled.div`
  ${wrapperCss}
`;

export const Heading = styled.h1`
  margin-bottom: 32px;
  ${typography.tiger};
  @media ${deviceConstraints.smartphone} {
    ${typography.chimp};
  }
`;

export const SendButtonWrapper = styled.div`
  margin: 34px 0 20px 0;
`;

export const CenterContent = styled.div`
  text-align: center;
`;

export const CancelButton = styled(Link)`
  ${typography.koala};
  font-weight: 600;

  &,
  &:hover {
    color: var(--copilot--default);
  }

  &:hover {
    text-decoration: underline;
  }
`;
