import React from 'react';
import { useTranslation } from 'react-i18next';

import { postJpmSearch } from '~/api/chat/chat-services';
import { useAPI } from '~/shared/hooks/use-api/useAPI';

import { Message } from '../../use-chat-store/chat-store.types';
import { ChatMarkdown } from '../chat-markdown/ChatMarkdown';
import { JpmSearchProgress } from '../jpm-search-progress/JpmSearchProgress';
import * as S from './CogSearchBreakdown.styles';

export interface CogSearchBreakdownProps {
  message: Message;
}

export const CogSearchBreakdown = ({ message }: CogSearchBreakdownProps) => {
  const { t } = useTranslation('common');

  const { data: cogSearch, loading } = useAPI(
    () =>
      postJpmSearch(
        message.question as string,
        message.service ? [message.service] : undefined,
        message.reply?.metaData?.entity.tag
          ? [message.reply?.metaData?.entity.tag]
          : undefined
      ),
    {
      shouldFetch: !!message.question,
    }
  );

  return (
    <S.CogSearchBreakdownRoot data-testid="cog-search-breakdown">
      {cogSearch?.summary && (
        <>
          <S.Title>{t('chat:cognitiveSearch.title')}</S.Title>
          <ChatMarkdown markdown={cogSearch.summary} />
        </>
      )}
      {!!cogSearch?.metadata?.documents?.length && (
        <S.Container>
          {cogSearch.metadata.documents.map((doc, index) => (
            <S.FileLink
              key={index}
              data-testid="document-link"
              href={doc.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <S.HTMLIcon
                src="/app/assets/icons/html-icon.png"
                alt={t('chat:cognitiveSearch.htmlIconImgAlt')}
              />
              <S.TitleText>{doc.title}</S.TitleText>
            </S.FileLink>
          ))}
        </S.Container>
      )}
      {message.isActive && loading && <JpmSearchProgress />}
    </S.CogSearchBreakdownRoot>
  );
};
