import styled from 'styled-components';

import { PriceStatus } from '~/views/analyze/utils/asset-box/asset-box-helpers';

import { MarketIndicator } from './market-indicator/MarketIndicator';

interface PriceChangeProps {
  $status: PriceStatus;
}

const priceChangeColors = {
  default: 'var(--base--60)',
  negative: 'var(--viz-bearish)',
  positive: 'var(--viz-bullish)',
};

export const LivePriceBox = styled.div`
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  display: flex;
`;

export const PriceContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const LivePrice = styled.span`
  font-family: var(--font-roboto-mono);
  padding-bottom: 0;
`;

export const PriceChange = styled.span<PriceChangeProps>`
  padding-left: 8px;
  font-family: var(--font-roboto-mono);
  color: ${({ $status }) => priceChangeColors[$status]};
`;

export const Divider = styled.span<PriceChangeProps>`
  margin: 0;
  color: ${({ $status }) => priceChangeColors[$status]};
`;

export const MarketIndicatorStyled = styled(MarketIndicator)`
  margin-left: 10px;
  white-space: nowrap;
`;
