import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { MobileNotSupported } from '~/shared/components/mobile-not-supported/MobileNotSupported';
import { useTouchDevice } from '~/shared/hooks/MediaQueryHook';
import { useChatScroll } from '~/shared/hooks/use-chat-scroll/useChatScroll';

import {
  ArticleInsightCard,
  useInsightCards,
} from '../analyze/hooks/use-article-insight-cards/useInsightCards';
import * as S from './Chat.styles';
import { ChatArticleInsights } from './components/chat-article-insights/ChatArticleInsights';
import { ChatDisclaimer } from './components/chat-disclaimer/ChatDisclaimer';
import { ChatMessage } from './components/chat-message/ChatMessage';
import { ChatSubmit } from './components/chat-submit/ChatSubmit';
import { ChatWelcome } from './components/chat-welcome/ChatWelcome';
import { ChatWidget } from './components/chat-widget/ChatWidget';
import { getActionButtonConfig } from './helpers/chat-helpers';
import { useStreamRequest } from './hooks/use-stream-request/useStreamRequest';
import { Message, MessageStatus } from './use-chat-store/chat-store.types';
import { useChatStore } from './use-chat-store/useChatStore';

export const Chat = () => {
  const isTouchDevice = useTouchDevice();
  const { t } = useTranslation(['common', 'chat']);
  const chatContentRef = useRef<HTMLDivElement>(null);
  const { messages, updateActiveMessage, handleFeedback } = useChatStore();
  const { sendStreamRequest, abortRequest } = useStreamRequest();
  const [activeArticleInsight, setActiveArticleInsight] =
    useState<ArticleInsightCard>();

  const hasMessages = messages.length > 0;
  const activeMessageIdx = messages.findIndex(m => m.isActive);
  const activeMessage: Message | undefined = messages[activeMessageIdx];
  const shouldFetchInsights =
    activeMessage?.reply?.metaData?.intent === 'entity-report';
  const messageMetadata = activeMessage?.reply?.metaData;

  const { data: articleInsights } = useInsightCards<
    Record<string, ArticleInsightCard[]>
  >(activeMessage?.reply?.metaData?.entity.tag ?? '', {
    initialState: {
      data: {},
      loading: false,
    },
    shouldFetch: shouldFetchInsights,
    mapDataFn: (next, current) => {
      if (!next.length) {
        return current;
      }
      return {
        ...current,
        [next[0].entity.tag]: next,
      };
    },
  });

  useChatScroll({ messages, activeMessageIdx, chatContentRef });

  if (isTouchDevice) {
    return <MobileNotSupported message={t('chat:mobileBlockedMsg')} />;
  }

  const onArticleInsightClick =
    (messageIndex: number) => (articleInsight: ArticleInsightCard) => {
      setActiveArticleInsight(articleInsight);
      const currentMessage = messages[messageIndex];
      if (articleInsight.article_id && currentMessage.reply?.metaData?.entity) {
        sendStreamRequest({
          service: 'insight-assistant',
          question: t('chat:articleInsights.question', {
            ticker: articleInsight.entity.ticker.toUpperCase(),
            name: articleInsight.card_text,
          }),
          reply: {
            answer: '',
            insight_id: articleInsight.article_id,
            metaData: {
              supported: true,
              entity: currentMessage.reply.metaData.entity,
              intent: 'scenario',
            },
          },
        });
      }
    };

  const onActiveMessageUpdate = (index: number) => {
    const message = messages[index];

    if (message.reply?.metaData?.entity && message.reply.insight_id) {
      const entity = message.reply.metaData.entity;
      const id = message.reply.insight_id;
      const insight = articleInsights[entity.tag].find(
        i => i.article_id === id
      );
      setActiveArticleInsight(insight);
    } else {
      setActiveArticleInsight(undefined);
    }

    updateActiveMessage(index);
  };

  const loadedInsightIdsMap = messages.reduce<Record<string, string[]>>(
    (result, message) => {
      if (!message.reply?.insight_id || !message.reply.metaData?.entity.tag) {
        return result;
      }

      if (message.reply.success || message.status === MessageStatus.Sent) {
        const insightId = message.reply.insight_id;
        const tag = message.reply.metaData.entity.tag;
        if (result[tag]) {
          result[tag].push(insightId);
        } else {
          result[tag] = [insightId];
        }
      }

      return result;
    },
    {}
  );

  return (
    <S.ChatRoot data-testid="chat">
      <S.ChatSection>
        <S.Content ref={chatContentRef}>
          {hasMessages ? (
            messages.map((message, index) => {
              const metaData = message.reply?.metaData;
              const entity = metaData?.entity;

              return (
                <ChatMessage
                  key={index}
                  activeMessage={activeMessage}
                  message={message}
                  messageIndex={index}
                  updateActiveMessage={onActiveMessageUpdate}
                  handleFeedback={handleFeedback}
                  handleStreamAbort={abortRequest}
                  sendStreamRequest={sendStreamRequest}
                  actionButtonProps={getActionButtonConfig({
                    t,
                    metaData,
                    activeArticleInsight,
                  })}
                  afterReceivedMessageNode={
                    entity &&
                    metaData.intent === 'entity-report' &&
                    articleInsights[entity.tag] &&
                    !!articleInsights[entity.tag].length && (
                      <ChatArticleInsights
                        loadedInsightIds={loadedInsightIdsMap[entity.tag]}
                        articleInsights={articleInsights[entity.tag]}
                        ticker={entity.ticker}
                        onClick={onArticleInsightClick(index)}
                        t={t}
                      />
                    )
                  }
                />
              );
            })
          ) : (
            <ChatWelcome />
          )}
        </S.Content>

        <S.Footer>
          <ChatSubmit
            placeholder={t('chat:inputPlaceholder')}
            submitCallback={message => {
              sendStreamRequest({
                service: 'orchestrator',
                question: message,
              });
              setActiveArticleInsight(undefined);
            }}
          />
          <ChatDisclaimer />
        </S.Footer>
      </S.ChatSection>
      <S.WidgetSection>
        {messageMetadata && (
          <ChatWidget
            intent={messageMetadata.intent}
            entity={messageMetadata.entity}
            articleInsight={activeArticleInsight}
          />
        )}
      </S.WidgetSection>
    </S.ChatRoot>
  );
};
