import { OptionalU } from '~/declarations/standard';

import { Fetch } from '../fetch/Fetch';
import { FetchError } from '../fetch/FetchErrors';
import {
  addAuthHeaders,
  addDefaultFetchOptions,
  addRequestId,
  checkForErrors,
} from './APIFetchDecorators';
import { APIResponse } from './constants';

export interface APIFetchOptions {
  requiresAuth?: boolean;
  retried?: boolean;
}

export type APIFetchArgs =
  | Parameters<typeof fetch>
  | [RequestInfo, OptionalU<RequestInit>, OptionalU<APIFetchOptions>?];

export type ApiFetchResponse<T> = T | Error;

interface APIStructuredResponseMetaDefault {
  limit?: number;
  total_count?: number;
}

export interface APIStructuredResponseErrorItem {
  id?: string;
  description: string;
  field_name?: string;
}

export interface APIStructuredResponse<
  R = unknown,
  M = APIStructuredResponseMetaDefault
> {
  meta: M;
  result?: R;
  errors?: APIStructuredResponseErrorItem[];
}

export interface APIMetaResultResponse<
  R = unknown,
  M = APIStructuredResponseMetaDefault
> {
  meta: M;
  result: R;
}

export type ApiFetchPostProcessArgs<T> = [APIFetchArgs, ApiFetchResponse<T>];

const chain = <T extends unknown[]>(
  fns: Array<(...arg: T) => Promise<T> | T>
) => {
  return (arg: T): Promise<T> => {
    const initial = Promise.resolve(arg);
    //@ts-ignore
    return fns.reduce(
      //@ts-ignore
      (promise, f) => promise.then((args: T) => f(...args)),
      initial
    );
  };
};

const preProcess = chain<APIFetchArgs>([
  addDefaultFetchOptions,
  addRequestId,
  addAuthHeaders,
]);
const postProcess = chain([checkForErrors]);

const shouldRetry = <T>(
  response: ApiFetchResponse<T>,
  proceedArgs: APIFetchArgs
) => {
  const retried = proceedArgs[2]?.retried;
  return (
    response instanceof FetchError &&
    response.status === APIResponse.UPGRADE_REQUIRED &&
    !retried
  );
};

export async function APIFetch<D>(
  ...args: APIFetchArgs
): Promise<ApiFetchResponse<D>> {
  try {
    const proceedArgs = await preProcess(args);
    const [req, options] = proceedArgs;
    const response = await Fetch<D>(req, options);

    const proceedResponse = [
      proceedArgs,
      response,
    ] as ApiFetchPostProcessArgs<D>;

    const [, proceedRet] = await postProcess(proceedResponse);
    if (shouldRetry(response, proceedArgs)) {
      const params = [
        args[0],
        args[1],
        { ...args[2], retried: true },
      ] as APIFetchArgs;
      return APIFetch(...params);
    }
    return proceedRet;
  } catch (e) {
    return e as Error;
  }
}
