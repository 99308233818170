import React, { useEffect, useRef } from 'react';

interface VideoProps extends React.HTMLAttributes<HTMLIFrameElement> {
  onVideoClick?: (iframe: HTMLIFrameElement) => void;
  src: string;
}

export function Video({ onVideoClick, ...props }: VideoProps) {
  const iframeRef = useRef<null | HTMLIFrameElement>(null);

  useEffect(() => {
    const onBlur = () => {
      if (
        onVideoClick &&
        document.activeElement &&
        iframeRef.current &&
        iframeRef.current === document.activeElement
      ) {
        onVideoClick(iframeRef.current);
      }
    };

    window.addEventListener('blur', onBlur);

    return () => {
      window.removeEventListener('blur', onBlur);
    };
  }, []);

  return (
    <iframe
      {...props}
      ref={iframeRef}
      width="100%"
      height="100%"
      frameBorder="0"
      allow="autoplay; encrypted-media"
      allowFullScreen
      title="video"
      data-testid="video-iframe"
    />
  );
}
