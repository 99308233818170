import { useDisableScroll } from '@toggle/helpers';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useMobileMenu } from '~/stores/use-mobile-menu/useMobileMenu';

export const useHandleMobileMenu = () => {
  const { isMobileMenuOpened, setIsMobileMenuOpened, setIsMobileMenuClosed } =
    useMobileMenu();
  const location = useLocation();
  useDisableScroll(isMobileMenuOpened);

  useEffect(() => {
    setIsMobileMenuClosed();
  }, [location]);

  return {
    isMobileMenuOpened,
    openMobileMenu: setIsMobileMenuOpened,
    closeMobileMenu: setIsMobileMenuClosed,
  };
};
