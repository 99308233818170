import { StoreApi } from 'zustand';

import { create } from '~/stores/create-store/createStore';
import { useUser } from '~/stores/use-user/useUser';

import {
  fetchUserStatements,
  StatementAssetClass,
  UserStatement,
} from './services/fetch-user-statements/fetchUserStatements';
import { getStatementsEntities } from './services/get-statements-entities/getStatementsEntities';
import {
  MappedSingleStatement,
  MappedStatements,
  mapStatements,
} from './services/portfolio-statements/portfolioStatements';

export interface UserStatementsStore {
  isLoading: boolean;
  isError: boolean;
  userStatements: UserStatement[];
  mappedStatements: MappedStatements;
  abortController?: AbortController;
  fetchStatements: () => Promise<void>;
  clear: () => void;
  getUserStatementByTicker: (ticker: string) => UserStatement | undefined;
  getUserStatementsWithoutCash: () => UserStatement[];
  getMappedStatementByPortfolioId: (
    portfolioId: string
  ) => MappedSingleStatement | undefined;
  getSubscribableTickers: (portfolioId: string) => string[];
}

const fetchStatements = async (
  set: StoreApi<UserStatementsStore>['setState'],
  get: StoreApi<UserStatementsStore>['getState']
) => {
  if (get().abortController) {
    get().abortController?.abort();
  }

  const abortController = new AbortController();

  set({
    isLoading: true,
    isError: false,
    abortController: abortController,
  });

  const { signal } = abortController;

  const statements = await fetchUserStatements(signal);

  if (signal.aborted) {
    return;
  }

  if (statements instanceof Error) {
    set({ isLoading: false, isError: true });
    return;
  }

  const statementsWithEntities = await getStatementsEntities(statements.result);
  if (statementsWithEntities instanceof Error) {
    set({ isLoading: false, isError: true });
    return;
  }

  const portfolioSettings = useUser.getState().portfolioSettings;

  const mappedStatements = mapStatements(
    statementsWithEntities,
    portfolioSettings
  );

  set({
    isLoading: false,
    userStatements: statementsWithEntities,
    mappedStatements: mappedStatements,
    abortController: undefined,
  });
};

export const defaultState = {
  isLoading: false,
  isError: false,
  userStatements: [],
  mappedStatements: {},
  abortController: undefined,
};

export const userStatementsStore = create<UserStatementsStore>((set, get) => ({
  ...defaultState,
  fetchStatements: () => fetchStatements(set, get),

  clear: () => set({ ...defaultState }),
  getMappedStatementByPortfolioId: portfolioId => {
    const { mappedStatements } = get();

    return mappedStatements[portfolioId];
  },

  getSubscribableTickers: portfolioId => {
    const { userStatements } = get();

    return userStatements
      .filter(
        ({ security, portfolio }) =>
          !!security.subscribable_ticker && portfolio.id === portfolioId
      )
      .map(({ security }) => security.subscribable_ticker!);
  },

  getUserStatementByTicker: ticker => {
    const { userStatements } = get();

    return userStatements.find(({ security }) => security.ticker === ticker);
  },

  getUserStatementsWithoutCash: () => {
    const { userStatements } = get();

    return userStatements.filter(
      ({ security }) => security.asset_class !== StatementAssetClass.Cash
    );
  },
}));
