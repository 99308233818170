import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { accountRoutes, appPaths } from '~/routes/app-paths';
import {
  gaHeaderClickPill,
  gaHeaderNotificationAction,
} from '~/shared/utils/ganalytics';
import { useSubscription } from '~/stores/use-subscription/useSubscription';

import * as S from './BasicUpgradeButton.styles';

export const BasicUpgradeButton = () => {
  const { t } = useTranslation('subscription');
  const navigate = useNavigate();
  const userRole = useSubscription(s => s.userSubscription.role);

  const handleUpgrade = () => {
    gaHeaderNotificationAction('plan-warning', userRole, 'confirm');
    gaHeaderClickPill();
    navigate(`${appPaths.myAccount.subscription}/${accountRoutes.selectPlan}`);
  };

  return (
    <S.UpgradeButton
      onClick={handleUpgrade}
      label={t('subscription:Basic.button')}
      variant="secondary"
      size="small"
      data-testid="basic-upgrade-button"
    />
  );
};
