import { borderRadius, typographyV2 } from '@toggle/design-system';
import styled from 'styled-components';

import { LinePlot as DefaultLinePlot } from '~/shared/components/chart/plots/LinePlot';

export const ChartContainer = styled.div`
  border: 1px solid var(--border-soft);
  ${borderRadius.r3}
`;

export const LivePriceViewContainer = styled.div`
  margin: 12px 0px 0px 12px;
`;

export const Plot = styled.svg`
  width: 100%;
  height: 180px;
`;

export const LinePlot = styled(DefaultLinePlot)`
  stroke-width: 2;
  color: var(--primary-color);
`;

export const AxisLine = styled.line`
  stroke: var(--border-default);
`;

export const AxisText = styled.text`
  font-family: var(--font-roboto-mono);
  ${typographyV2.titleXs};
  fill: var(--text-soft);
`;
