import { Button } from '@toggle/design-system';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { TradingWsErrors } from '~/stores/use-broker-ws/useBrokerWs';
import { TradingProvider } from '~/stores/use-portfolio/utils/use-portfolio-utils';

import { BrokerTitle } from '../broker-title/BrokerTitle';
import * as S from './Connection.styles';

export interface ConnectionProps {
  error?: TradingWsErrors;
  handleRetry?: () => void;
  portfolioName: string;
  tradingProvider: TradingProvider;
  handleClose?: () => void;
}

export const Connection: FC<ConnectionProps> = ({
  error,
  handleRetry,
  portfolioName,
  handleClose,
  tradingProvider,
}) => {
  const { t } = useTranslation('portfolio');
  const getErrorState = (err?: TradingWsErrors) => {
    switch (err) {
      case undefined:
        return {
          header: (
            <S.HeaderImg
              data-testid="header-image"
              alt=""
              src="/app/assets/trading/moon.png"
            />
          ),
          imgFooter: '/app/assets/trading/flying.png',
          title: t('portfolio:connection.connecting', {
            context: tradingProvider,
          }),
          button: undefined,
        };
      case TradingWsErrors.AuthorizationWithdrawn:
        return {
          header: undefined,
          imgFooter: '/app/assets/trading/lost.png',
          title: t('portfolio:connection.authTitle'),
          message: t('portfolio:connection.authMessage'),
          button: (
            <Button
              data-testid="retry-button"
              label={t('portfolio:connection.retry')}
              variant="secondary"
              iconName="Sync"
              onClick={handleRetry}
            />
          ),
        };
      case TradingWsErrors.PricesNotAvailable:
      case TradingWsErrors.InvalidMessageFormat:
      case TradingWsErrors.UntradableAsset:
        return {
          header: undefined,
          imgCenter: '/app/assets/trading/lonely_2.png',
          title: t('portfolio:connection.priceTitle'),
          message: t('portfolio:connection.priceMessage', {
            context: tradingProvider,
          }),
          button: (
            <S.CloseButton
              data-testid="retry-button"
              label={t('portfolio:connection.closeWindow')}
              onClick={handleClose}
            />
          ),
        };
      default:
        return {
          header: undefined,
          imgFooter: '/app/assets/trading/lost.png',
          title: t('portfolio:connection.lost'),
          button: (
            <Button
              data-testid="retry-button"
              label={t('portfolio:connection.retry')}
              variant="secondary"
              iconName="Sync"
              onClick={handleRetry}
            />
          ),
        };
    }
  };

  const state = getErrorState(error);

  return (
    <S.Container>
      {state?.header}
      <S.Body>
        <BrokerTitle
          tradingProvider={tradingProvider}
          portfolioName={portfolioName}
        />
        {state?.imgCenter && (
          <S.CenterImg
            alt={t('portfolio:connection.centerImgAlt')}
            src={state.imgCenter}
          />
        )}
        <S.Title>{state.title}</S.Title>
        {state?.message && <S.Message>{state.message}</S.Message>}
        {state?.button}
      </S.Body>
      {state?.imgFooter && (
        <S.FooterImg
          alt={t('portfolio:connection.footerImgAlt')}
          src={state.imgFooter}
        />
      )}
    </S.Container>
  );
};
