import { typography } from '@toggle/design-system';
import styled, { css } from 'styled-components';

import { BookmarkButton } from '~/views/news/components/bookmark-button/BookmarkButton';

interface StyledBookmarkButtonProps {
  $active?: boolean;
}

export const StyledBookmarkButton = styled(
  BookmarkButton
)<StyledBookmarkButtonProps>`
  padding: 14px 16px;

  svg {
    fill: var(--base--45);
  }

  &:hover {
    color: var(--primary-color);
    background-color: var(--base--90);

    svg {
      fill: var(--primary-color);
    }
  }

  ${({ $active }) =>
    $active &&
    css`
      color: var(--primary-color);
      svg {
        fill: var(--primary-color);
      }
    `}
`;

export const Footer = styled.footer`
  grid-area: foot;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: unset;
  border-top: 1px solid var(--base--95);
  color: var(--base--45);
  ${typography.emu};
`;
