import { config } from '~/config';
import { APIFetch } from '~/shared/services/api-fetch';
import { HttpMethods } from '~/shared/services/api-fetch/constants';
import { validateApiFetch } from '~/shared/services/api-fetch/validate-api-fetch/validateApiFetch';

import { DocumentItem, documentSchema } from './legal-documents-schema';

const {
  api: { root: apiRoot },
} = config;

export const documentsEndpoints = {
  getDocument: (name: string) => `${apiRoot}legal-documents/v1/doc/${name}`,
  consentToDoc: (docId: string) =>
    `${apiRoot}legal-documents/v1/consent/${docId}`,
  pendingConsentDoc: `${apiRoot}legal-documents/v1/consent?pending=true`,
  latestDocs: `${apiRoot}legal-documents/v1/latest`,
};

export const getDocumentsPendingConsent = () => {
  return validateApiFetch<DocumentItem[]>({
    endpoint: documentsEndpoints.pendingConsentDoc,
    schema: documentSchema,
  });
};

export const putDocumentConsent = (id: string) => {
  return APIFetch(documentsEndpoints.consentToDoc(id), {
    method: HttpMethods.Put,
    body: JSON.stringify({
      accepted: true,
    }),
  });
};

export const getSignUpDocuments = () => {
  return validateApiFetch({
    endpoint: documentsEndpoints.latestDocs,
    schema: documentSchema,
  });
};
