import { Chip, typographyV2 } from '@toggle/design-system';
import styled, { css } from 'styled-components';

export const SharedButtonContainer = styled.div<{ $hasLabel: boolean }>`
  ${({ $hasLabel }) =>
    $hasLabel &&
    css`
      ${typographyV2.bodyBase}
      display: flex;
      padding: 0px;
      width: 100%;
      button {
        width: 100%;
        padding: 0px 16px;
        gap: 0px;
        justify-content: start;
        border-radius: 0px;
      }
      div {
        margin-right: 8px;
        line-height: 24px;
      }
      span {
        ${typographyV2.bodyBase}
        line-height: 24px;
        text-transform: uppercase;
        color: var(--text-default);
      }
      &:hover {
        background-color: rgba(var(--menu-active-item-border-rgb), 0.4);
        span {
          color: var(--text-primary);
        }
        svg {
          fill: var(--text-primary);
        }
      }
    `}
`;

export const Shared = styled(Chip)`
  border: none;
  background: transparent;
  height: 40px;
  padding: 0px;
  &:hover {
    color: var(--text-primary);
    svg {
      fill: var(--text-primary);
    }
  }
`;
