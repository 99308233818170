import React from 'react';
import { useTranslation } from 'react-i18next';

import * as S from './../Content.styles';

export function HistoryChartContent() {
  const { t } = useTranslation('helperWidget');
  return (
    <S.Content>
      <S.Paragraph>
        {t('helperWidget:insight.content.historyChart.firstParagraph')}
      </S.Paragraph>
      <S.Paragraph>
        {t('helperWidget:insight.content.historyChart.secondParagraph')}
      </S.Paragraph>
    </S.Content>
  );
}
