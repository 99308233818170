import { DocumentItem } from '~/api/legal-documents/legal-documents-schema';
import {
  getDocumentsPendingConsent,
  putDocumentConsent,
} from '~/api/legal-documents/legal-documents-service';
import { create } from '~/stores/create-store/createStore';

interface DocStore {
  loading: boolean;
  error: boolean;
  docsPendingConsent: DocumentItem[];
  consentToDocument: (id: string) => Promise<void>;
  getDocsPendingConsent: () => Promise<void>;
}

export const useDocuments = create<DocStore>((set, get) => ({
  docsPendingConsent: [],
  error: false,
  loading: false,
  getDocsPendingConsent: async () => {
    try {
      set({ error: false, loading: true });
      const req = await getDocumentsPendingConsent();
      if (!(req instanceof Error)) {
        set({ docsPendingConsent: req, loading: false });
      }
    } catch (error) {
      set({ error: true, loading: false });
    }
  },
  consentToDocument: async (id: string) => {
    try {
      set({ loading: true });
      const req = await putDocumentConsent(id);
      if (!(req instanceof Error)) {
        const updated = get().docsPendingConsent.filter(i => i.id !== id);
        set({ docsPendingConsent: updated, loading: false });
      }
    } catch {
      set({ error: true, loading: false });
    }
  },
}));
