export const incrementIndex = (index: number, length: number) =>
  Math.min(index + 1, length - 1);

export const decrementIndex = (index: number) => Math.max(index - 1, 0);

export const getNextIndexes = (
  mainIndex: number,
  nestedIndex: number,
  mainLength: number,
  nestedLength: number
) => {
  const nextNestedIndex = nestedIndex + 1;
  const nextMainIndex = mainIndex + 1;

  if (nextNestedIndex < nestedLength) {
    return {
      mainIndex,
      nestedIndex: nextNestedIndex,
    };
  }

  if (nextMainIndex < mainLength) {
    return {
      mainIndex: incrementIndex(mainIndex, mainLength),
      nestedIndex: 0,
    };
  }

  return {
    mainIndex,
    nestedIndex,
  };
};

export const getPrevIndexes = (
  mainIndex: number,
  nestedIndex: number,
  prevNestedArrayLength: number
) => {
  if (nestedIndex > 0) {
    return {
      mainIndex,
      nestedIndex: decrementIndex(nestedIndex),
    };
  }

  return {
    mainIndex: decrementIndex(mainIndex),
    nestedIndex: decrementIndex(prevNestedArrayLength),
  };
};
