import styled from 'styled-components';

export const Badge = styled.span`
  background-color: var(--success--default);
  border-radius: 4px;
  padding: 4px 8px;
  font-size: 14px;
  color: var(--success--dark);
  display: inline-flex;
  cursor: default;
  outline: none;
  margin: 0 12px;
`;
