import React, { FC } from 'react';

export interface StopGradient {
  offset: number;
  stopColor: string;
  stopOpacity?: number;
}

export interface GradientDefProps {
  gradientId: string;
  gradientY1?: number | string;
  stops?: StopGradient[];
}
const stopColor = 'var(--primary-color)';
export const primaryGradientStops: StopGradient[] = [
  { offset: 0, stopColor, stopOpacity: 0.2 },
  { offset: 100, stopColor, stopOpacity: 0 },
];

export const GradientDef: FC<GradientDefProps> = ({
  gradientId,
  gradientY1,
  stops,
}) => {
  return (
    <defs>
      <linearGradient id={gradientId} x1="0" x2="0" y1={gradientY1} y2="1">
        {(stops ?? []).map(({ offset, stopColor, stopOpacity }) => (
          <stop
            key={`gradient-${offset}`}
            offset={`${offset}%`}
            style={{ stopColor, stopOpacity }}
          />
        ))}
      </linearGradient>
    </defs>
  );
};
