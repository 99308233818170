import { Icon, TableCell, TableHeader } from '@toggle/design-system';
import { checkCellValueStyle } from '@toggle/helpers';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  CompassDataWithFormat,
  CompassPastEpisodes,
} from '~/views/analyze/store/useExperimentStore';

import { formatPercentage, getStarTotal } from '../helpers/scenario-helpers';
import * as S from './EpisodesTable.styles';

type EpisodeTableHeadings =
  | 'horizon'
  | 'typicalPercent'
  | 'medianPercent'
  | 'quality';

export interface EpisodesTableProps {
  setSelectedPeriod: (e: CompassPastEpisodes) => void;
  experimentData?: CompassDataWithFormat;
}

export const EpisodesTable = ({
  setSelectedPeriod,
  experimentData,
}: EpisodesTableProps) => {
  const { t } = useTranslation('widget');
  const TABLE_HEADING: EpisodeTableHeadings[] = [
    'horizon',
    'typicalPercent',
    'medianPercent',
    'quality',
  ];

  return (
    <S.EpisodesTable
      TableHeaderContent={
        <tr>
          {TABLE_HEADING.map((name, i) => (
            <TableHeader
              key={i}
              alignment={i === 0 ? 'start' : 'end'}
              isSortable={false}
            >
              {t(`scenario.${name}`)}
            </TableHeader>
          ))}
        </tr>
      }
      TableBodyContent={
        <>
          {experimentData?.episodes.map((e, i) => (
            <S.BodyRow
              data-testid="occurance-table-row"
              key={i}
              onClick={() => setSelectedPeriod(e)}
            >
              <S.EvidenceTableCell
                data-testid="occurrence-episode-cell"
                alignment="start"
              >
                {e.period.toUpperCase()}
              </S.EvidenceTableCell>
              <TableCell data-testid="typical-trading-range" alignment="end">
                <S.NumericText $numericalStyle={checkCellValueStyle(e.worst)}>
                  {formatPercentage(e.worst)}
                </S.NumericText>
                <S.Dash>-</S.Dash>
                <S.NumericText $numericalStyle={checkCellValueStyle(e.best)}>
                  {formatPercentage(e.best)}
                </S.NumericText>
              </TableCell>
              <TableCell
                data-testid="median"
                alignment="end"
                numericalValueStyle={checkCellValueStyle(e.median)}
              >
                {formatPercentage(e.median)}
              </TableCell>
              <TableCell data-testid="star-episode-cell" alignment="end">
                <S.QualityIconWrapper>
                  <Icon
                    iconName="Rating"
                    size={16}
                    fillColor={
                      e.is_best ? 'var(--icon-primary)' : 'var(--icon-soft)'
                    }
                  />
                  <S.QualityText isBest={e.is_best}>
                    {getStarTotal(e.period, experimentData).starCount}
                  </S.QualityText>
                </S.QualityIconWrapper>
              </TableCell>
            </S.BodyRow>
          ))}
        </>
      }
    />
  );
};
