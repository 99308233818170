import React, { FC } from 'react';

import { Class } from '~/api/entities/entity-constants';
import { MappedEntity } from '~/shared/hooks/use-entities';

import * as S from '../../Logo.styles';
import { FixedIncomeLogo } from '../fixed-income-logo/FixedIncomeLogo';

export interface LogoImageProps {
  entity: MappedEntity;
  className?: string;
  logoUrl: string;
  onError: () => void;
}

export const LogoImage: FC<LogoImageProps> = ({
  entity,
  className,
  logoUrl,
  onError,
}) => {
  const imgProps = {
    src: logoUrl,
    alt: '',
    ['data-testid']: 'logo',
    onError,
  };

  return (
    <S.LogoWrapper className={className}>
      {entity.class === Class.ClassFi ? (
        <FixedIncomeLogo
          imgProps={{ ...imgProps, ['data-testid']: 'fi-logo' }}
          className={className}
          entityTag={entity.tag}
        />
      ) : (
        <img {...imgProps} />
      )}
    </S.LogoWrapper>
  );
};
