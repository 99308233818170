import { TabLines, typography } from '@toggle/design-system';
import styled from 'styled-components';

export const ContentContainer = styled.div`
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: 1fr;
  grid-template-areas: 'header' 'content' 'footer';
  overflow-y: auto;
  flex-grow: 1;
`;

export const HeadingContainer = styled.div`
  grid-area: header;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: auto 1fr;
  align-items: center;
`;

export const Heading = styled.h2`
  ${typography.dingo}
  margin-left: 8px;
`;

export const HeaderContainer = styled.div`
  padding: 16px;
  grid-area: header;
`;

export const Title = styled.h1`
  ${typography.tiger}
  margin-bottom: 4px;
`;

export const ExchangeText = styled.span`
  ${typography.koala}
  color: var(--base--40);
  padding-left: 4px;
`;

export const BodyContainer = styled.div`
  overflow-y: auto;
  grid-area: content;
`;

export const FooterContainer = styled.div`
  grid-area: footer;
`;

export const Footer = styled.div`
  padding: 16px;
  border-top: solid 1px var(--base--80);
`;

export const TextBottom = styled.p`
  ${typography.emu}
  color: var(--base--40);
  padding-bottom: 8px;
  padding: 8px 16px;
  text-align: center;
`;

export const TabLinesContainer = styled.div`
  ${TabLines.Styled.Header} {
    padding-left: 16px;
  }
  height: 100%;
`;
