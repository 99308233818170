import {
  AutocompleteInput,
  DropdownProps,
  InputProps,
} from '@toggle/design-system';
import React, { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { EntitySearchType } from '~/api/entities/entity-constants';
import { useTagsSearch } from '~/shared/hooks/use-tags-search';
import {
  gaSendFailedSearch,
  gaSendSearch,
} from '~/shared/utils/ganalytics/commonGA';
import { TradingProvider } from '~/stores/use-portfolio/utils/use-portfolio-utils';
import { useUserEntities } from '~/stores/use-user-entities/useUserEntities';

import { ClearButtons } from './components/clear-buttons/ClearButtons';
import {
  SearchAutocomplete,
  SearchAutocompleteItem,
} from './components/search-autocomplete-item/SearchAutocompleteItem';
import { SearchDivider } from './components/search-divider/SearchDivider';
import { SearchIcon } from './components/search-icon/SearchIcon';
import { SearchTradeMessage } from './components/search-trade-message/SearchTradeMessage';
import * as S from './Search.styles';
import { hasEntityLoaded, mapEntityItems } from './utils/searchUtils';

interface SearchAutocompleteItemProps {
  componentOnItemStart?: (item: SearchAutocomplete) => React.ReactElement;
  componentOnItemEnd?: (item: SearchAutocomplete) => React.ReactElement;
  withWatchlistButton?: boolean;
}

interface SearchInputProps extends InputProps {
  inputPlaceholder?: string;
  showClearButton: (value: string) => boolean;
  showSearchButton: (value: string) => boolean;
}

export interface SearchProps {
  initialValue?: string;
  onFocus?: () => void;
  navigationCallback: (item: SearchAutocomplete, name: string) => void;
  openTradeSearch?: (
    value: string,
    provider: TradingProvider,
    itemsCount: number
  ) => void;
  searchType?: EntitySearchType;
  autocompleteProps?: Partial<DropdownProps<SearchAutocomplete>>;
  searchAutocompleteItemProps?: SearchAutocompleteItemProps;
  searchInputProps?: Partial<SearchInputProps>;
  onClear?: () => void;
  onChange?: (value: string) => void;
  closeSearch?: () => void;
  isWatchlistOnly?: boolean;
  showPlaceholder?: boolean;
  showTradeMessage?: boolean;
  showResultsDivider?: boolean;
  noResultsNode?: ReactNode;
  handleClose?: () => void;
  tradingProviders?: TradingProvider[];
}

const defaultSearchAutocompleteItemProps: SearchAutocompleteItemProps = {
  withWatchlistButton: true,
};

const defaultInputProps: SearchInputProps = {
  inputPlaceholder: '',
  autoFocus: false,
  showClearButton: (value: string) => value.length > 0,
  showSearchButton: () => true,
};

export const Search = ({
  initialValue = '',
  navigationCallback,
  searchType = EntitySearchType.Entity,
  searchAutocompleteItemProps = defaultSearchAutocompleteItemProps,
  autocompleteProps,
  searchInputProps = defaultInputProps,
  onFocus,
  onChange,
  onClear,
  openTradeSearch,
  noResultsNode,
  tradingProviders,
  isWatchlistOnly = false,
  showPlaceholder = false,
  showTradeMessage = false,
  showResultsDivider = false,
  handleClose,
}: SearchProps) => {
  const {
    autoFocus,
    inputPlaceholder,
    showClearButton,
    showSearchButton,
    ...inputProps
  } = {
    ...defaultInputProps,
    ...searchInputProps,
  };

  const tags = useUserEntities(state => state.entities.watchlist);
  const { t } = useTranslation('header');
  const [value, setValue] = useState(initialValue);
  const [items, setItems] = useState<SearchAutocomplete[]>([]);
  const { tags: tagList, searchTags } = useTagsSearch({ searchType });
  const [showNoRes, setShowNoRes] = useState(false);
  const [showNotEnough, setShowNotEnough] = useState(false);
  const [showDivider, setShowDivider] = useState(false);

  useEffect(() => {
    setShowNoRes(!!value.length && !tagList.length);
    setShowNotEnough(
      !!value.length && tagList.length > 0 && tagList.length < 5
    );
    setShowDivider(!!value.length);

    setItems(mapEntityItems(tagList));
  }, [tagList, tags]);

  useEffect(() => {
    if (initialValue) {
      setValue(initialValue);
      searchTags(initialValue);
    }
  }, [initialValue]);

  const handleSearch = (value: string) => {
    setValue(value);
    searchTags(value);
    clearSearchArea();
    if (!value) {
      setItems([]);
    }
    onChange?.(value);
  };

  const handleFailedSearch = (isUserInitiated = false) => {
    if (!hasEntityLoaded(items, value)) {
      gaSendFailedSearch(value, isUserInitiated);
    }
  };

  const handleClear = () => {
    handleFailedSearch();
    searchTags('');
    setValue('');
    setItems([]);
    clearSearchArea();
    onClear?.();
  };

  const clearSearchArea = () => {
    setShowNotEnough(false);
    setShowNoRes(false);
    setShowDivider(false);
  };

  const loadEntity = (item: SearchAutocomplete) => {
    const name = item.rightText || item.leftText;
    const activeSuggestionIndex = items.findIndex(i => i.id === item.id);
    const activeSuggestionPosition = activeSuggestionIndex + 1;

    gaSendSearch(value, item, activeSuggestionPosition);

    setValue(name);
    navigationCallback(item, name);
  };

  const renderDropdownItem = (item: SearchAutocomplete, isActive: boolean) => (
    <SearchAutocompleteItem
      key={item.id}
      item={item}
      isActive={isActive}
      highlighted={value}
      withWatchlistButton={searchAutocompleteItemProps.withWatchlistButton}
      componentOnItemStart={searchAutocompleteItemProps.componentOnItemStart}
      componentOnItemEnd={searchAutocompleteItemProps.componentOnItemEnd}
    />
  );

  return (
    <>
      <S.TypedStyledDropdown<SearchAutocomplete>
        dropdownItems={items}
        $isWatchlistOnly={isWatchlistOnly}
        onFocus={onFocus}
        renderDropdownItem={renderDropdownItem}
        selectItem={isWatchlistOnly ? undefined : loadEntity}
        onOutsideClick={handleFailedSearch}
        shouldCloseOnOutsideClick={autocompleteProps?.shouldCloseOnOutsideClick}
        shouldCloseOnSelect={!isWatchlistOnly}
        afterInListNode={
          showResultsDivider &&
          showDivider &&
          !showNoRes && (
            <SearchDivider
              isNotEnough
              isNoResults={false}
              text={t('header:search.endOfResults')}
            />
          )
        }
        afterResultsNode={
          showResultsDivider &&
          showDivider &&
          showNoRes && (
            <SearchDivider
              isNoResults={showNoRes}
              text={t('header:search.noResults')}
            />
          )
        }
        hasV2Styles={false}
        enableFloating={false}
      >
        <AutocompleteInput
          value={value}
          leftIcon={showSearchButton(value) && <SearchIcon />}
          rightIcon={
            <>
              {showClearButton(value) && (
                <ClearButtons showClearButton handleClear={handleClear} />
              )}
              {handleClose && (
                <S.CloseIcon
                  iconName="Close"
                  size={18}
                  onClick={handleClose}
                  data-testid="close-search-icon"
                />
              )}
            </>
          }
          autoFocus={autoFocus}
          placeholder={inputPlaceholder}
          onChange={e => handleSearch(e.target.value)}
          {...inputProps}
        />
      </S.TypedStyledDropdown>
      {!value.length && showPlaceholder && (
        <S.ItemsPlaceholder>
          {t('header:search.placeholder')}
        </S.ItemsPlaceholder>
      )}
      {showNoRes && noResultsNode}
      {showTradeMessage && (showNotEnough || showNoRes) && openTradeSearch && (
        <SearchTradeMessage
          t={t}
          isNotEnough={showNotEnough}
          tradingProviders={tradingProviders ?? []}
          searchValue={value}
          onClick={provider => openTradeSearch(value, provider, items.length)}
        />
      )}
    </>
  );
};
