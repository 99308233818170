import React, {
  forwardRef,
  ForwardRefExoticComponent,
  HTMLAttributes,
  ReactNode,
  RefAttributes,
} from 'react';

import { Icon } from '~/components/icon/Icon';
import { SvgIconNames } from '~/design-tokens/iconography/SvgIcons';

import * as S from './Badge.styles';

export type BadgeSize = 'small' | 'medium';
export type BadgeStyle = 'solid' | 'outline';
export type BadgeVariant = 'textual' | 'numerical';
export type BadgeColor =
  | 'neutral'
  | 'primary'
  | 'error'
  | 'success'
  | 'warning';

export interface BadgeProps extends HTMLAttributes<HTMLDivElement> {
  variant?: BadgeVariant;
  size?: BadgeSize;
  color?: BadgeColor;
  badgeStyle?: BadgeStyle;
  label?: ReactNode;
  leftIcon?: SvgIconNames;
  rightIcon?: SvgIconNames;
  leftSection?: React.ReactNode;
}

export const Badge = forwardRef<HTMLDivElement, BadgeProps>(
  (
    {
      color = 'primary',
      size = 'medium',
      badgeStyle = 'solid',
      variant = 'textual',
      label,
      leftIcon,
      rightIcon,
      leftSection,
      ...rest
    },
    ref
  ) => {
    if (variant === 'numerical') {
      return (
        <S.BadgeRoot
          ref={ref}
          data-testid="badge-numerical"
          size={size}
          {...rest}
        >
          <S.BadgeNumerical size={size}>{label}</S.BadgeNumerical>
        </S.BadgeRoot>
      );
    }

    return (
      <S.BadgeRoot ref={ref} data-testid="badge-textual" size={size} {...rest}>
        <S.BadgeTextual
          id="badge-textual"
          size={size}
          color={color}
          $style={badgeStyle}
        >
          {leftSection}
          {leftIcon && <Icon iconName={leftIcon} size={16} />}
          {label}
          {rightIcon && <Icon iconName={rightIcon} size={16} />}
        </S.BadgeTextual>
      </S.BadgeRoot>
    );
  }
) as ForwardRefExoticComponent<BadgeProps & RefAttributes<HTMLDivElement>> & {
  Styled: typeof S;
};

Badge.Styled = S;
