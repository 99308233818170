import {
  AssetClassMap,
  AssetClassNames,
  AssetSectors,
  AssetSubClassMap,
  AssetSubClassNames,
} from '~/api/entities/entity-constants';
import { Entity } from '~/api/entities/entity-schema';
import { postEntities } from '~/api/entities/entity-service';
import { useAPI } from '~/shared/hooks/use-api/useAPI';

type SectorType = AssetSectors | AssetClassNames | AssetSubClassNames;

interface MappedEntityValues {
  asset_class: AssetClassNames | '';
  sub_class: AssetSubClassNames | '';
  sector: SectorType | '';
  entitySubheading: string | '';
}

export type MappedEntity = Omit<Entity, 'asset_class' | 'sub_class'> &
  MappedEntityValues;

export interface UseEntityReturn {
  loading: boolean;
  error?: Error;
  data?: MappedEntity[];
}

interface UseEntityParams {
  entities: string[];
  byId?: boolean;
}

type UseEntities = (params: UseEntityParams) => UseEntityReturn;

export enum FILTER {
  id = 1,
  tag = 2,
}

const makeRequestBody = (entities: string[], byId: boolean) => ({
  filter: byId ? FILTER.id : FILTER.tag,
  args: entities,
});

export const mapAssetClass = (
  entity: Entity
): Pick<MappedEntity, 'asset_class' | 'sub_class'> => ({
  asset_class:
    entity.asset_class !== 0 ? AssetClassMap[entity.asset_class] : '',
  sub_class: entity.sub_class !== 0 ? AssetSubClassMap[entity.sub_class] : '',
});

export const mapEntitySubheading = (entity: Entity): string => {
  const entitySubheading = entity.exchange.code
    ? `${entity.ticker}:${entity.exchange.code}`
    : entity.ticker;

  return entitySubheading.toUpperCase();
};

export const formatEntity = (entity: Entity): MappedEntity => {
  const { asset_class, sub_class } = mapAssetClass(entity);
  const entitySubheading = mapEntitySubheading(entity);

  return {
    ...entity,
    asset_class,
    sub_class,
    entitySubheading,
    sector: entity.gics.sector.code || asset_class || sub_class || '',
  };
};

const mapEntities = (entities: Entity[]) => entities.map(formatEntity);

export const useEntities: UseEntities = ({ entities, byId = false }) => {
  const { data, loading, error } = useAPI<Entity[], undefined, MappedEntity[]>(
    signal => postEntities(makeRequestBody(entities, byId), signal),
    {
      initialState: { data: undefined, loading: true },
      shouldFetch: !!entities.length,
      deps: [[...entities].sort((a, b) => a.localeCompare(b)).toString(), byId],
      mapDataFn: next => mapEntities(next),
    }
  );

  return {
    loading,
    error,
    data,
  };
};
