import { deviceConstraints, typography } from '@toggle/design-system';
import styled from 'styled-components';

const mediumDeviceConstraint =
  'screen and (min-width: 880px) and (max-width: 1100px)';

export const SplashScreen = styled.div`
  display: flex;
  width: 100%;
  margin: 0 auto;
  height: calc(100vh - var(--app-header-height));

  @media ${deviceConstraints.smartphone} {
    max-width: 320px;
    flex-direction: column;
  }

  @media ${deviceConstraints.smartphoneOrTablet} {
    max-width: 320px;
    flex-direction: column;
  }

  @media ${deviceConstraints.tablet} {
    max-width: 520px;
  }

  @media ${deviceConstraints.desktopSmall} {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    max-width: 1024px;
  }

  @media ${mediumDeviceConstraint} {
    max-width: 690px;
  }

  @media ${deviceConstraints.desktop} {
    max-width: 1128px;
  }
`;

export const Loaders = styled.div`
  display: flex;
  flex-direction: column;

  @media ${deviceConstraints.smartphone} {
    margin-right: 0;
    margin-top: 52px;
  }
  @media ${deviceConstraints.smartphoneOrTablet} {
    margin-right: 0;
    margin-top: 52px;
  }

  @media ${deviceConstraints.desktopSmall} {
    margin-right: 120px;
    flex-basis: 100%;
  }

  @media ${mediumDeviceConstraint} {
    margin-right: 87px;
  }
`;

export const Heading = styled.h3`
  margin-bottom: 51px;

  @media ${deviceConstraints.smartphone} {
    text-align: center;
    ${typography.coyote};
    margin-bottom: 24px;
  }

  @media ${deviceConstraints.smartphoneOrTablet} {
    text-align: center;
    ${typography.coyote};
    margin-bottom: 24px;
  }

  @media ${deviceConstraints.desktopSmall} {
    text-align: left;
  }

  @media ${deviceConstraints.desktop} {
    ${typography.tiger};
  }
`;

export const Image = styled.div`
  @media ${deviceConstraints.smartphone} {
    min-width: none;
    width: 270px;
    margin: 0 auto;
    margin-top: 38px;
  }

  @media ${deviceConstraints.smartphoneOrTablet} {
    min-width: none;
    width: 270px;
    margin: 0 auto;
    margin-top: 38px;
  }

  @media ${deviceConstraints.tablet} {
    width: 320px;
  }

  @media ${deviceConstraints.desktopSmall} {
    min-width: 400px;
    margin-top: 0;
  }

  @media ${mediumDeviceConstraint} {
    min-width: 267px;
  }

  @media ${deviceConstraints.desktop} {
    min-width: 608px;
  }
`;
