import { deviceConstraints, Icon, RadioGroup } from '@toggle/design-system';
import styled from 'styled-components';

import { SelectBtnGroup as SharedSelectBtnGroup } from '~/shared/components/select-btn-group/SelectBtnGroup';
import { TooltipContainer } from '~/shared/components/tooltip-container/TooltipContainer';

export const StyledTooltip = styled(TooltipContainer)`
  width: 220px;
  padding: 10px 14px;
`;

export const TooltipIconWrapper = styled.span`
  display: inline-flex;
`;

export const RadioTooltipIcon = styled(Icon)`
  max-height: 38px;
`;

export const Filter = styled.div`
  .checkboxItem {
    display: flex;
    align-items: center;
    padding-left: 16px;
    margin-left: 8px;
    position: relative;
  }

  .checkboxItem:hover {
    background-color: var(--base--95);
  }
`;

export const FilterGroupHeading = styled.h3<{ disabled?: boolean }>`
  font-size: 18px;
  padding-left: 16px;
  padding-bottom: 16px;
  ${({ disabled }) => disabled && 'color: var(--base--85);'}
`;

export const SelectBtnGroup = styled(SharedSelectBtnGroup)`
  margin-left: 16px;

  .stars,
  .direction {
    background-color: var(--base--95);

    @media ${deviceConstraints.desktopSmall} {
      background-color: var(--base--100);
    }

    svg {
      fill: currentColor;
    }

    &.btnGroupSelected {
      background-color: var(--primary-color);
      border-color: var(--primary-color);
      color: var(--base--100);
    }
  }
`;

export const IconWrapper = styled.span`
  margin-left: 12px;

  & > svg {
    vertical-align: middle;
    position: relative;
    top: -1px;
  }
`;

export const IconLabel = styled.span`
  padding-top: 2px;
`;

export const StyledRadioGroup = styled(RadioGroup)`
  ${RadioGroup.Styled.RadioLabel} {
    display: flex;
    align-items: center;
    padding-left: 16px;
    margin-left: 8px;
    position: relative;

    &:hover {
      background-color: var(--base--95);
    }
  }

  ${RadioGroup.Styled.StyledIcon} {
    &.fire,
    &.check,
    &.bookmark {
      margin-right: 4px;
      font-size: 16px;
      padding-bottom: 2px;
      left: -2px;
      position: relative;
    }

    &.fire {
      fill: var(--pink--4);
    }

    &.check {
      fill: var(--success--default);
    }

    &.bookmark {
      fill: var(--primary-color);
    }

    &.star,
    &.arrow-up,
    &.arrow-down {
      margin-right: 8px;
    }

    &.star {
      font-size: 12px;
      margin-right: 8px;
    }

    &.arrow-up,
    &.arrow-down {
      font-size: 14px;
    }
  }
`;
