import { Loader } from '@toggle/design-system';
import React, { FC, ReactNode, useEffect, useState } from 'react';

import {
  ONBOARDING_KEYS,
  STORAGE_ITEMS,
  useRemoteStorage,
} from '~/shared/hooks/use-remote-storage';
import { OPTIONS_TO_SHOW_GEO } from '~/views/questionnaire/trade/Trade';

import { GEO_KEYS } from '../../explore/store/filterOptions/categories/geography';
import { generateExploreFilters } from '../filters';
import {
  InvestorOptions,
  InvestorStyles,
  OnboardingContext,
  TradeOptions,
  TradeStyles,
  UserInterestOptions,
} from './OnboardingContext';

interface OnboardingStoreProps {
  children: ReactNode;
}

export const OnboardingStore: FC<OnboardingStoreProps> = ({ children }) => {
  const { items, storeItems, loading } = useRemoteStorage(
    ({ items, storeItems, loading }) => ({
      items,
      storeItems,
      loading,
    })
  );

  const [tradeOptions, setTradeOptions] = useState<TradeOptions>({
    stocks: false,
    currencies: false,
    commodities: false,
    crypto: false,
    options: false,
    fixedIncome: false,
    ...GEO_KEYS.reduce((acc, geoKey) => ({ ...acc, [geoKey]: false }), {}),
  });

  const [investorOptions, setInvestorOptions] = useState<InvestorOptions>({
    fundamentals: false,
    technical: false,
  });

  const [userInterest, setUserInterest] = useState<UserInterestOptions>();

  const hydrateState = (items: STORAGE_ITEMS) => {
    setTradeOptions(o => ({
      ...o,
      ...(items?.ONBOARDING_TRADE_OPTIONS as any),
    }));
    setInvestorOptions(o => ({
      ...o,
      ...(items?.ONBOARDING_INVESTOR_STYLE as any),
    }));
    if (items?.ONBOARDING_INVESTOR_ACTION) {
      setUserInterest(items.ONBOARDING_INVESTOR_ACTION as UserInterestOptions);
    }
  };

  useEffect(() => {
    if (items) {
      hydrateState(items);
    }
  }, [
    items?.ONBOARDING_INVESTOR_STYLE,
    items?.ONBOARDING_TRADE_OPTIONS,
    items?.ONBOARDING_INVESTOR_ACTION,
  ]);

  const handleTradeClick = (id: TradeStyles) => {
    const geoValues = () =>
      GEO_KEYS.reduce((a, c) => ({ ...a, [c]: false }), {});

    setTradeOptions(o => ({
      ...o,
      [id]: !o[id],
      ...(OPTIONS_TO_SHOW_GEO.every(opt => (opt === id ? o[opt] : !o[opt]))
        ? geoValues()
        : {}),
    }));
  };

  const handleInvestorClick = (id: InvestorStyles | 'both') => {
    if (id === 'both') {
      setInvestorOptions(o => ({
        ...o,
        fundamentals: true,
        technical: true,
      }));
    } else {
      setInvestorOptions(o => ({
        ...o,
        [id]: !o[id],
      }));
    }
  };

  const persistToStorage = async () => {
    const filters = generateExploreFilters(tradeOptions);

    await storeItems({
      [ONBOARDING_KEYS.ONBOARDING_TRADE_OPTIONS]: tradeOptions,
      [ONBOARDING_KEYS.ONBOARDING_INVESTOR_STYLE]: investorOptions,
      [ONBOARDING_KEYS.ONBOARDING_INVESTOR_ACTION]: userInterest,
      [ONBOARDING_KEYS.ONBOARDING_INITIAL_EXPLORE_FILTERS]: filters,
    });
  };

  const storeOnboardingSkip = async () => {
    await storeItems({
      [ONBOARDING_KEYS.ONBOARDING_SKIPPED]: 'true',
    });
  };

  if (loading && !items) {
    return <Loader />;
  }

  const handleUserInterest = (option: UserInterestOptions) => {
    setUserInterest(option);
  };

  return (
    <OnboardingContext.Provider
      value={{
        tradeOptions,
        investorOptions,
        userInterest,
        handleTradeClick,
        handleInvestorClick,
        handleUserInterest,
        persistToStorage,
        storeOnboardingSkip,
      }}
    >
      {children}
    </OnboardingContext.Provider>
  );
};
