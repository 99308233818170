import React, { useRef } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import * as styles from '~/global/toasts-layout/ToastsLayout.styled';
import { Toast, useToasts } from '~/stores/use-toasts/useToasts';

const { offsetBetweenToasts, toastTransitionDuration, transitionClass, ...S } =
  styles;

export const ToastsLayout = () => {
  const toastsNodes = useRef<Map<number | string, HTMLDivElement>>(new Map());
  const toasts = useToasts(state => state.toasts);

  for (const toastId of toastsNodes.current.keys()) {
    if (!toasts.some(t => t.id === toastId)) {
      toastsNodes.current.delete(toastId);
    }
  }

  const yOffsets = toasts.map((toast: Toast, toastIndex: number) =>
    Array.from(toastsNodes.current.values())
      .slice(0, toastIndex)
      .reduce((offsetY, node, nodeIndex) => {
        if (toast.position === toasts[nodeIndex].position) {
          return offsetY + node.clientHeight + offsetBetweenToasts;
        }
        return offsetY;
      }, 0)
  );

  toastsNodes.current.clear();

  return (
    <S.ToastsWrapper data-testid="toasts-wrapper">
      <TransitionGroup component={null}>
        {toasts.map((toast, toastIndex) => (
          <CSSTransition
            key={toast.id}
            id={toast.id}
            timeout={toastTransitionDuration}
            classNames={transitionClass}
          >
            <S.Toast
              key={toast.id}
              ref={toastRef => {
                toastRef && toastsNodes.current.set(toast.id, toastRef);
              }}
              offsetY={yOffsets[toastIndex]}
              position={toast.position}
              data-testid="global-toast"
            >
              {toast.content}
            </S.Toast>
          </CSSTransition>
        ))}
      </TransitionGroup>
    </S.ToastsWrapper>
  );
};
