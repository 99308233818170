import { Icon } from '@toggle/design-system';
import { TFunction } from 'i18next';
import React, { forwardRef } from 'react';

import { TooltipContainer } from '~/shared/components/tooltip-container/TooltipContainer';

import * as S from './QualityChecklist.styles';

interface ChecklistData {
  [key: string]: boolean;
}

interface QualityChecklistProps {
  stars: number;
  checklistData: ChecklistData;
  t: TFunction;
}

export const QualityChecklist = forwardRef<
  HTMLHeadingElement,
  QualityChecklistProps
>(({ stars, checklistData, t }, ref) => {
  const checklistEntries = Object.entries(checklistData);

  return (
    <S.Container>
      <S.Title ref={ref}>{t('common:qualityChecklist.labels.title')}</S.Title>
      <S.Wrapper>
        <S.ChecklistOverall>
          <S.Stars>
            <S.StarsRow>
              {checklistEntries.map((_, idx) => (
                <S.Star
                  iconName="Rating"
                  key={idx}
                  data-testid="star-row"
                  $yellow={idx < stars}
                  size={20}
                />
              ))}
            </S.StarsRow>
            <S.StarsInfo>{`${stars} / ${checklistEntries.length}`}</S.StarsInfo>
          </S.Stars>
          <S.ChecklistInfo>
            {t('common:qualityChecklist.labels.info')}
          </S.ChecklistInfo>
        </S.ChecklistOverall>
        <S.Details>
          {checklistEntries.map(([key, showStar]) => {
            return (
              <S.DetailsRow key={key}>
                <S.DetailsRowText>
                  {t(`common:qualityChecklist.labels.${key}`)}
                  <TooltipContainer
                    hidePadding={{
                      top: 100,
                    }}
                    label={t(`common:qualityChecklist.tooltips.${key}`)}
                  >
                    <S.QuestionMarkWrapper>
                      <Icon
                        iconName="QuestionMarkFill"
                        size={16}
                        fillColor="var(--primary-color)"
                      />
                    </S.QuestionMarkWrapper>
                  </TooltipContainer>
                </S.DetailsRowText>
                <S.Star
                  iconName="Rating"
                  data-testid={showStar ? 'star-list-starred' : 'star-list'}
                  $yellow={showStar}
                  size={20}
                />
              </S.DetailsRow>
            );
          })}
        </S.Details>
      </S.Wrapper>
    </S.Container>
  );
});
