import { DateString } from '~/declarations/toggle-api';
import { ENDPOINT_URLS } from '~/global/toggle-api';
import {
  APIFetch,
  APIMetaResultResponse,
  HttpMethods,
} from '~/shared/services/api-fetch';
import { Portfolio } from '~/stores/use-portfolio/usePortfolio';

export enum StatementAssetClass {
  Future = 'pos_type_future',
  Cash = 'pos_type_cash',
  Commodity = 'pos_type_commodity',
  Option = 'pos_type_option',
  Unknown = 'pos_type_unknown',
  Cfd = 'pos_type_cfd',
  Bond = 'pos_type_bond',
  Mutual_fund = 'pos_type_mutual_fund',
  Index_option = 'pos_type_index_option',
  Stock = 'pos_type_stock',
  Etf = 'pos_type_etf',
  Crypto = 'pos_type_crypto',
  Future_option = 'pos_type_future_option',
  Warrant = 'pos_type_warrant',
  Fx = 'pos_type_fx',
}

export const statementAssetClassValues = Object.values(StatementAssetClass);

interface Price {
  date: string;
  value: number;
}

export interface UserStatementSecurity {
  id: string;
  external_id: string;
  name: string;
  description: string;
  entity: string;
  ticker: string;
  subscribable_ticker?: string;
  exchange: string;
  asset_class: StatementAssetClass;
  currency: string;
  tick: number;
  contract_multiplier: number;
  contract_underlying_price: number;
  contract_expiry_date: DateString;
  contract_underlying_ticker: string;
  days_to_expiry: number;
  option_type: string;
  option_strike_price: number;
  currency_risk: null;
  cusip: string;
  sedol: string;
  multiplier: number;
}

export interface UserStatement {
  id: string;
  statement_date: DateString;
  position: number;
  rate: number;
  price: number;
  amount_security: number;
  amount_reporting: number;
  notional_security: number;
  notional_reporting: number;
  pnl_security: number;
  pnl_reporting: number;
  strategy1: string;
  strategy2: string;
  strategy3: string;
  amount_perc: number;
  notional_perc: number;
  price_date?: DateString;
  price_streaming: boolean;
  ticker: string;
  average_cost: number;
  portfolio: Portfolio;
  prices?: Price[];
  security: UserStatementSecurity;
}

export const fetchUserStatements = async (signal?: AbortSignal) => {
  return APIFetch<APIMetaResultResponse<UserStatement[]>>(
    ENDPOINT_URLS.USER_STATEMENT,
    {
      method: HttpMethods.Get,
      signal,
    }
  );
};
