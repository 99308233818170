import { Icon } from '@toggle/design-system';
import styled from 'styled-components';

export const ChevronRight = styled(Icon).attrs({
  iconName: 'ChevronLightRight',
  fillColor: 'var(--primary-color)',
  size: 16,
})<{ $isExpanded: boolean }>`
  vertical-align: middle;
  margin-right: 8px;
  transform: rotate(${props => (props.$isExpanded ? 90 : 0)}deg);
`;
