import { createContext, useContext } from 'react';

export type TradeStyles =
  | 'stocks'
  | 'currencies'
  | 'commodities'
  | 'crypto'
  | 'options'
  | 'fixedIncome';
export type InvestorStyles = 'fundamentals' | 'technical';
export type TradeOptions = Record<TradeStyles, boolean> & {
  [geo: string]: boolean;
};
export type InvestorOptions = Record<InvestorStyles, boolean>;

export enum UserInterestOptions {
  tradeIdea = 'tradeIdea',
  asset = 'asset',
}

interface OnboardingContextValue {
  tradeOptions: TradeOptions;
  investorOptions: InvestorOptions;
  userInterest?: UserInterestOptions;
  handleTradeClick: (id: TradeStyles) => void;
  handleInvestorClick: (id: InvestorStyles | 'both') => void;
  handleUserInterest: (interest: UserInterestOptions) => void;
  persistToStorage: () => Promise<void>;
  storeOnboardingSkip: () => Promise<void>;
}
export const OnboardingContext = createContext({} as OnboardingContextValue);

export const useOnboardingContext = () => useContext(OnboardingContext);
