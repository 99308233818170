import { TFunction } from 'i18next';
import React, { FC } from 'react';
import { Trans } from 'react-i18next';

import { TradingProvider } from '~/stores/use-portfolio/utils/use-portfolio-utils';

import * as S from './SearchTradeMessage.styles';

interface SearchTradeMessageProps {
  searchValue: string;
  t: TFunction;
  onClick: (provider: TradingProvider) => void;
  isNotEnough: boolean;
  tradingProviders: TradingProvider[];
}

export const SearchTradeMessage: FC<SearchTradeMessageProps> = ({
  onClick,
  searchValue,
  t,
  isNotEnough,
  tradingProviders,
}) => {
  return (
    <S.Container isNotEnough={isNotEnough}>
      <S.TradeText>
        {isNotEnough
          ? t('header:search.notEnoughResults')
          : t('header:search.cantFind')}
      </S.TradeText>
      <div>
        {tradingProviders.map(provider => (
          <S.OpenTradeSearch
            key={provider}
            variant="secondary"
            label={
              <Trans
                t={t}
                i18nKey={'header:search.searchIn'}
                values={{ query: searchValue }}
                context={provider}
                components={[<S.TradeBtnMark key="mark" />]}
              />
            }
            onClick={() => onClick(provider)}
            data-testid="open-trade-search-btn"
          />
        ))}
      </div>
    </S.Container>
  );
};
