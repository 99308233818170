import {
  ChartAssetData,
  defaultColors,
  getStartOfRangeHorizon,
  PaneData,
  RANGE_HORIZON_DEFAULT,
  RangeHorizon,
  Timeseries,
  TimeSeriesItem,
} from '@toggle/chart';
import { Entity, SnakeMeta, TSDatum } from '@toggle/toggle';

import { fetchSnakeMetaV2 } from '~/shared/services/overview-widget/overview-widget-service';

import { ChartSearchType, SearchAssetPayload } from '../../types/search.types';

export const lineColors = Object.values(defaultColors.multi);

export const getLineColor = (chartData: ChartAssetData[]) => {
  const fallback = defaultColors.line.default;
  if (!chartData.length) {
    return fallback;
  }

  const foundColor = lineColors.find(color =>
    chartData.every(item => item.lineColorOverride !== color)
  );

  return foundColor ?? fallback;
};

interface CreateChartDataItemProps {
  entity: Entity;
  ts: Timeseries;
  lineColor: string;
  primaryAsset?: ChartAssetData;
  isHidden?: ChartAssetData['isHidden'];
  snakeMeta?: SnakeMeta;
}

export const createChartDataItem = ({
  entity,
  ts,
  lineColor,
  primaryAsset,
  isHidden = false,
  snakeMeta,
}: CreateChartDataItemProps) => {
  const filteredTs =
    !primaryAsset || primaryAsset.entity.default_snake === entity.default_snake
      ? ts
      : ts.filter(t => primaryAsset.tsByTime.get(t.time) !== undefined);

  const tsByTime = new Map();
  filteredTs.forEach((item, idx) => {
    tsByTime.set(item.time, idx);
  });

  return {
    entity,
    ts: filteredTs,
    originalTs: ts,
    lineColorOverride: lineColor,
    tsByTime,
    isHidden,
    snakeMeta,
  };
};

export const createChartDataFromPrimaryAsset = ({
  chartData,
  asset,
  ts,
  snakeMeta,
}: {
  chartData: ChartAssetData[];
  asset: Entity;
  ts: TimeSeriesItem[];
  snakeMeta?: SnakeMeta;
}) => {
  const primaryAsset = createChartDataItem({
    entity: asset,
    ts,
    lineColor: defaultColors.line.default,
    snakeMeta,
  });
  return [
    primaryAsset,
    ...chartData.map(item =>
      createChartDataItem({
        entity: item.entity,
        ts: item.originalTs,
        lineColor: item.lineColorOverride,
        primaryAsset,
        isHidden: item.isHidden,
        snakeMeta: item.snakeMeta,
      })
    ),
  ];
};

export const createTsDataFromSnake = (ts: TSDatum[]) => {
  const result: TimeSeriesItem[] = [];

  ts.forEach(item => {
    if (item.value !== null) {
      result.push({
        time: item.index,
        open: 0,
        high: 0,
        low: 0,
        close: item.value,
      });
    }
  });

  return result;
};

export const getPrimaryAssetHorizons = (ts: Timeseries) => {
  const endDate = new Date(ts[ts.length - 1].time);
  const startDate = new Date(ts[0].time);
  const horizons = Object.values(RangeHorizon).filter(range => {
    const start = getStartOfRangeHorizon(range, endDate);
    return start === undefined || start >= startDate.getTime();
  });
  const defaultHorizon = horizons.find(
    horizon => horizon === RANGE_HORIZON_DEFAULT
  );

  return {
    activeHorizon:
      defaultHorizon ??
      (horizons[horizons.length - 2] || horizons[horizons.length - 1]),
    horizons,
  };
};

export const getChartDataSlice = (
  chartData: ChartAssetData[],
  payload: SearchAssetPayload
) => {
  if (payload.type === ChartSearchType.Primary) {
    return chartData.slice(1);
  }

  if (payload.type === ChartSearchType.Compare) {
    return chartData;
  }

  return chartData.filter(
    item => item.entity.default_snake !== payload.assetToChange.default_snake
  );
};

export const getSnakeMeta = async (
  defaultSnake: string
): Promise<SnakeMeta | undefined> => {
  const snakeMetaV2 = await fetchSnakeMetaV2([defaultSnake]);
  try {
    if (snakeMetaV2 instanceof Error) {
      throw snakeMetaV2;
    }
    const snakeMeta = snakeMetaV2[defaultSnake];
    return {
      ...snakeMeta,
      last_timestamp: snakeMeta.last_timestamp / 1e6,
      before_last_timestamp: snakeMeta.before_last_timestamp / 1e6,
    };
  } catch (e) {
    return undefined;
  }
};

export const updatePrimaryPane = (
  chartPanes: PaneData[],
  chartAssetData: ChartAssetData[]
): PaneData[] => [{ ...chartPanes[0], chartAssetData }, ...chartPanes.slice(1)];

// Asset change
interface HandleAssetChangeProps {
  chartData: ChartAssetData[];
  newData: ChartAssetData[];
  asset: Entity;
  prices: TimeSeriesItem[];
  snakeMeta?: SnakeMeta;
}

export const handleNonPrimaryAssetChange = (
  type: ChartSearchType,
  sharedProps: HandleAssetChangeProps
): ChartAssetData[] => {
  return type === ChartSearchType.Compare
    ? handleCompareAssetChange(sharedProps)
    : handleChangeAssetChange(sharedProps);
};

export const handleCompareAssetChange = ({
  chartData,
  newData,
  asset,
  prices,
  snakeMeta,
}: HandleAssetChangeProps): ChartAssetData[] => {
  const newLineColor = getLineColor(chartData);
  return [
    ...newData,
    createChartDataItem({
      entity: asset,
      ts: prices,
      lineColor: newLineColor,
      primaryAsset: newData[0],
      snakeMeta,
    }),
  ];
};

export const handleChangeAssetChange = ({
  chartData,
  newData,
  asset,
  prices,
  snakeMeta,
}: HandleAssetChangeProps): ChartAssetData[] => {
  return chartData.reduce((result, item) => {
    const idx = newData.findIndex(
      d => d.entity.default_snake === item.entity.default_snake
    );
    const data =
      idx >= 0
        ? newData[idx]
        : createChartDataItem({
            entity: asset,
            ts: prices,
            lineColor: item.lineColorOverride,
            primaryAsset: result[0],
            snakeMeta,
          });
    result.push(data);
    return result;
  }, [] as ChartAssetData[]);
};
