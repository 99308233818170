import { deviceConstraints } from '@toggle/design-system';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import ToggleLogo from '~/assets/core/toggle-logo.svg';
import ToggleMobileLogo from '~/assets/core/toggle-logo-mobile.svg';

const LinkButton = styled(Link)`
  font-size: 16px;
  padding: 4px 8px;
  border-radius: 4px;
  @media ${deviceConstraints.desktopSmall} {
    padding: 8px 16px;
  }
`;

export const LoginButton = styled(LinkButton)`
  border: 1px solid var(--primary-color);
  background: transparent;
  color: var(--primary-color);
  margin-left: auto;

  &:hover {
    color: var(--primary-color);
  }
`;

export const SignupButton = styled(LinkButton)`
  border: 1px solid var(--primary-color);
  background: var(--primary-color);
  color: var(--base--gray);

  &:hover {
    color: var(--base--gray);
  }
`;

export const LogoLink = styled(Link)`
  grid-area: logo;
  text-align: left;
`;

export const StyledMobileLogo = styled(ToggleMobileLogo)`
  line-height: 24px;
  vertical-align: middle;

  [class*='logoColor'] {
    fill: var(--copilot--default);
  }
`;

export const StyledLogo = styled(ToggleLogo)`
  height: 24px;
  vertical-align: middle;

  [class*='logoColor'] {
    fill: var(--copilot--default);
  }
`;

export const Header = styled.header`
  position: fixed;
  z-index: var(--z-index-app-header);
  display: flex;
  gap: 12px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: var(--app-header-height);
  padding: 10px;
  text-align: center;
  box-shadow: none;
  background-color: var(--base--100);

  ${StyledLogo} {
    display: none;
  }

  @media ${deviceConstraints.tablet} {
    padding: 7px 16px;
  }

  @media ${deviceConstraints.desktopSmall} {
    padding: 12px 24px;

    ${StyledLogo} {
      display: block;
    }

    ${StyledMobileLogo} {
      display: none;
    }
  }

  @media ${deviceConstraints.desktop} {
    padding: 12px 24px;
  }
`;
