import { Dropdown, DropdownListItem, Select } from '@toggle/design-system';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Expiration } from '~/global/trading-drawer/use-transaction/useTransaction';

interface Item {
  key: Expiration;
  label: string;
}

interface ExpirationListProps {
  onChange: (key: Expiration) => void;
  value: Expiration;
}

export const ExpirationList: FC<ExpirationListProps> = ({
  onChange,
  value,
}) => {
  const { t } = useTranslation('portfolio');
  const items: Item[] = [
    {
      label: t('portfolio:expiration', { context: Expiration.Day }),
      key: Expiration.Day,
    },
    {
      label: t('portfolio:expiration', { context: Expiration.GoodTillCancel }),
      key: Expiration.GoodTillCancel,
    },
    {
      label: t('portfolio:expiration', {
        context: Expiration.ImmediateOrCancel,
      }),
      key: Expiration.ImmediateOrCancel,
    },
  ];
  const activeIndicatorIdx = items.findIndex(item => item.key === value);
  const [selectedIndex, setSelectedIndex] = useState(activeIndicatorIdx);

  const handleSelectItem = (item: Item, index: number) => {
    onChange(item.key);
    setSelectedIndex(index);
  };

  const [isOpen, setIsOpen] = useState(false);

  return (
    <Dropdown
      dropdownItems={items}
      activeItemIdx={activeIndicatorIdx}
      shouldShowItems={isOpen}
      onItemsShowChange={s => setIsOpen(s)}
      selectItem={(item, _, index) => handleSelectItem(item, index)}
      renderDropdownItem={(item, isActive, isFocused) => (
        <DropdownListItem
          label={item.label}
          isActive={isActive}
          isFocused={isFocused}
        />
      )}
    >
      <Select
        inputProps={{
          value: items[selectedIndex]?.label ?? items[0]?.label,
        }}
        isActive={isOpen}
      />
    </Dropdown>
  );
};
