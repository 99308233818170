import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { appPaths } from '~/routes/app-paths';
import { Topic } from '~/views/helper/helper-widget/content/Content.styles';

import * as S from './OnboardingHelperContent.styles';

export function QuestionnaireContent() {
  const navigate = useNavigate();
  const { t } = useTranslation('helperWidget');

  return (
    <>
      <Topic data-testid="questionnaire-content">
        {t('helperWidget:onboarding.content.questionnaireContent.title')}
      </Topic>
      <S.Button
        variant="secondary"
        label={t('helperWidget:onboarding.content.questionnaireContent.button')}
        onClick={() => navigate(appPaths.questionnaire)}
        data-testid="questionnaire-btn"
      />
    </>
  );
}
