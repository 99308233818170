import styled, { css } from 'styled-components';

import { typographyV2 } from '~/design-tokens/typography/Typography.styles';

export const EntityDetailsRoot = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin: 16px;
`;

export const LeftImageContainer = styled.div`
  flex: 0 0 40px;
`;

export const TitleContainer = styled.div<{ $isLargeTitle: boolean }>`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  ${({ $isLargeTitle }) =>
    $isLargeTitle
      ? css`
          ${typographyV2.titleLg}
        `
      : css`
          ${typographyV2.titleBase}
        `}
`;

export const Tag = styled.p`
  ${typographyV2.bodySm}
  color: var(--text-soft);
  text-transform: uppercase;
`;
