import React from 'react';

import * as S from './Spinner.styles';

interface SpinnerProps {
  size?: 'small' | 'large';
}

export const Spinner = ({ size = 'large' }: SpinnerProps) => (
  <S.SpinnerIcon data-testid="spinner" $size={size} />
);
