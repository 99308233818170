import { borderRadius } from '@toggle/design-system';
import styled from 'styled-components';

export const ListItemRoot = styled.button`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 8px;
  cursor: pointer;
  border: none;
  background: none;

  &:hover:not(:has([role='button']:hover)) {
    background-color: var(--surface-hover);
  }

  &:focus-visible {
    outline: 2px solid var(--border-focus);
  }
`;

export const Details = styled.div`
  display: flex;
  gap: 16px;
`;

export const Close = styled.div`
  ${borderRadius.r1};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  cursor: pointer;

  &:hover {
    background-color: var(--surface-hover);
  }

  &:focus-visible {
    outline: 2px solid var(--border-focus);
  }
`;
