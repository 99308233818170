import { Button } from '@toggle/design-system';
import { formatPriceWithCurrency } from '@toggle/helpers';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Subscription } from '~/api/subscription/subscription-schema';
import { TooltipContainer } from '~/shared/components/tooltip-container/TooltipContainer';
import { useMediaQuery } from '~/shared/hooks/MediaQueryHook';
import { MediaQueries } from '~/shared/hooks/MediaQueryList';
import * as Shared from '~/views/my-account/subscription/plan-matrix/components/plan-comparison/PlanComparison.styles';
import { ComparisonTable } from '~/views/my-account/subscription/plan-matrix/utils/comparison-table-data';
import { getYearlyPricePerMonth } from '~/views/my-account/subscription/utils/subscription-view-utils';

import * as S from './BasicPaywall.styles';
import { BasicPaywallPlan } from './components/basic-paywall-plan/BasicPaywallPlan';

export interface BasicPaywallProps {
  subscriptions: Subscription[];
  table: ComparisonTable;
  onClose: () => void;
  upgradeSubscription: (subscription: Subscription) => void;
  redirectToPlans: () => void;
}

export const BasicPaywall: FC<BasicPaywallProps> = ({
  subscriptions,
  onClose,
  table,
  upgradeSubscription,
  redirectToPlans,
}) => {
  const { t } = useTranslation(['paywall', 'subscription']);
  const currentMedia = useMediaQuery(s => s.currentMedia);
  const isMobileOrTablet = [
    MediaQueries.SMALL_MOBILE,
    MediaQueries.MOBILE,
    MediaQueries.TABLET,
  ].includes(currentMedia);

  const plans = subscriptions.map(subscription => {
    const yearlyPricePerMonth = getYearlyPricePerMonth(subscription);
    const price = formatPriceWithCurrency(yearlyPricePerMonth);
    const perMonthLabel = t('subscription:plan.perMonth', { price });
    const planPriceLabel = t('paywall:planPrice', {
      price: perMonthLabel,
    });

    return (
      <BasicPaywallPlan
        key={subscription.role}
        role={subscription.role}
        planPriceLabel={planPriceLabel}
        planName={t('subscription:role', { context: subscription.role })}
        buttonLabel={t('subscription:upgrade', { context: subscription.role })}
        upgradeSubscription={() => upgradeSubscription(subscription)}
      />
    );
  });

  return (
    <S.StyledModal
      isOpen
      data-testid={isMobileOrTablet ? 'mobile-basic-paywall' : 'basic-paywall'}
      onClose={onClose}
    >
      <S.Container>
        {isMobileOrTablet ? (
          <>
            {subscriptions.map((s, idx) => (
              <S.MobilePlanContainer key={s.role}>
                {plans[idx]}
                {table.data.map(item => (
                  <S.FeatureRow key={item.name}>
                    <S.Column>
                      <TooltipContainer
                        label={t(item.description)}
                        showUnderline
                      >
                        <S.Label>{t(item.name)}</S.Label>
                      </TooltipContainer>
                    </S.Column>
                    <S.Text key={s.role}>
                      <S.StyledIcon
                        size={16}
                        $isIncluded={item.feature.slice(1)[idx].isIncluded}
                      />
                    </S.Text>
                  </S.FeatureRow>
                ))}
              </S.MobilePlanContainer>
            ))}
          </>
        ) : (
          <>
            <S.PlansContainer>{plans}</S.PlansContainer>
            <Shared.RowHeader>{t(table.section)}</Shared.RowHeader>
            {table.data.map(item => (
              <S.FeatureRow key={item.name}>
                <S.Column>
                  <TooltipContainer label={t(item.description)} showUnderline>
                    <S.Label>{t(item.name)}</S.Label>
                  </TooltipContainer>
                </S.Column>
                {item.feature.slice(1).map((feature, idx) => (
                  <S.Text key={idx}>
                    <S.StyledIcon size={16} $isIncluded={feature.isIncluded} />
                  </S.Text>
                ))}
              </S.FeatureRow>
            ))}
          </>
        )}
        <S.Footer>
          <Button
            variant="empty"
            onClick={redirectToPlans}
            label={t('paywall:comparePlans')}
          />
        </S.Footer>
      </S.Container>
    </S.StyledModal>
  );
};
