import { create } from '~/stores/create-store/createStore';
import { StatementAssetClass } from '~/stores/user-statements-store/services/fetch-user-statements/fetchUserStatements';
import { MappedSingleStatement } from '~/stores/user-statements-store/services/portfolio-statements/portfolioStatements';
import { userStatementsStore } from '~/stores/user-statements-store/userStatementsStore';

export const groupingOrder = [
  StatementAssetClass.Option,
  StatementAssetClass.Index_option,
  StatementAssetClass.Cfd,
  StatementAssetClass.Stock,
  StatementAssetClass.Etf,
  StatementAssetClass.Mutual_fund,
  StatementAssetClass.Crypto,
  StatementAssetClass.Future_option,
  StatementAssetClass.Future,
  StatementAssetClass.Fx,
  StatementAssetClass.Commodity,
  StatementAssetClass.Bond,
  StatementAssetClass.Unknown,
  StatementAssetClass.Warrant,
];

export interface PortfolioPageStore {
  isBalanceVisible: boolean;
  activePortfolioId: string;
  setActivePortfolioId: (portfolioId: string) => void;
  getActiveMappedStatements: () => MappedSingleStatement | undefined;
  getSubscribableTickers: () => string[];
  resetPortfolioPage: () => void;
  setIsBalanceVisible: (value: boolean) => void;
}

const defaultPortfolioPageStore = {
  activePortfolioId: '',
};

export const usePortfolioPage = create<PortfolioPageStore>((set, get) => ({
  activePortfolioId: '',
  isBalanceVisible: true,
  setActivePortfolioId: portfolioId => {
    set({
      activePortfolioId: portfolioId,
    });
  },
  getActiveMappedStatements: () => {
    const { activePortfolioId } = get();
    const { getMappedStatementByPortfolioId } = userStatementsStore.getState();

    return getMappedStatementByPortfolioId(activePortfolioId);
  },

  getSubscribableTickers: () => {
    const { activePortfolioId } = get();
    const { getSubscribableTickers } = userStatementsStore.getState();

    return getSubscribableTickers(activePortfolioId);
  },

  resetPortfolioPage: () => set({ ...defaultPortfolioPageStore }),
  setIsBalanceVisible: isBalanceVisible => set({ isBalanceVisible }),
}));
