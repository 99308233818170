import { Button, deviceConstraints, typography } from '@toggle/design-system';
import styled, { css } from 'styled-components';

export const Container = styled.div<{ isNotEnough: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${props =>
    props.isNotEnough
      ? 'padding: 0px 12px 43px;'
      : css`
          flex-grow: 1;
          justify-content: center;
          padding: 0px 12px;

          @media ${deviceConstraints.desktopSmall} {
            justify-content: flex-end;
            padding: 0px 12px 32px;
          }
        `}
`;

export const TradeText = styled.p`
  ${typography.emu};
  margin-bottom: 12px;
  text-align: center;
`;

export const TradeBtnMark = styled.mark`
  font-weight: 600;
  color: inherit;
  background: none;
`;

export const OpenTradeSearch = styled(Button)`
  width: 100%;

  & + & {
    margin-top: 8px;
  }

  ${Button.Styled.Label} {
    display: inline-block;
  }
`;
