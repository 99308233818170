import { z } from 'zod';

export type DocumentItem = z.infer<typeof documentItemSchema>;

export const documentItemSchema = z.object({
  id: z.string(),
  name: z.string(),
  title: z.string(),
  link: z.string(),
  changes: z.string().optional(),
});

export const documentSchema = z.array(documentItemSchema);
