import { TFunction } from 'i18next';
import map from 'lodash/map';
import React, { FC } from 'react';

import { ArticleHitRatio } from '~/declarations/toggle-api-news';
import { ContentAlignments } from '~/shared/components/table/Table';

import * as S from './HorizonTable.styles';

interface FundamentalsProps {
  hitRatio: ArticleHitRatio[];
  bestRatioIndex: number;
  t: TFunction;
  suffix: string;
}

export const HorizonTable: FC<FundamentalsProps> = ({
  hitRatio,
  bestRatioIndex,
  t,
  suffix,
}) => {
  const ratioTableHeader = map(hitRatio, item => {
    /* i18next-extract-mark-context-next-line ["1w", "2w", "1m", "3m", "6m"] */
    return t('article:horizons.horizon_name', {
      context: item.horizon_name.toLowerCase(),
    });
  });
  const medianRow = map(hitRatio, 'median');
  const formattedMedianRow = medianRow.map(v => `${v.toFixed(2)}${suffix}`);
  formattedMedianRow.unshift(t('article:whyItMightMove.fundamentals.median'));
  const hitRatioRow = map(hitRatio, 'hit_ratio');
  const formattedHitRatioRow = hitRatioRow.map(v => `${v.toFixed(2)}%`);
  formattedHitRatioRow.unshift(
    t('article:whyItMightMove.fundamentals.hitRatio')
  );

  return (
    <S.StyledTable
      rows={[
        ['', ...ratioTableHeader],
        formattedMedianRow,
        formattedHitRatioRow,
      ]}
      columnWidths={['18.5%', '16.4%', '16.4%', '16.4%', '16.4%', '16.4%']}
      columnContentAlignments={[
        '',
        ContentAlignments.CENTER,
        ContentAlignments.CENTER,
        ContentAlignments.CENTER,
        ContentAlignments.CENTER,
        ContentAlignments.CENTER,
      ]}
      markedIndex={bestRatioIndex !== -1 ? bestRatioIndex + 1 : -1}
      headerType="horizontal"
      zebraType="columns"
      zebraOrder="odd"
    />
  );
};
