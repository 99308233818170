import {
  customScrollMinimal,
  deviceConstraints,
  typography,
} from '@toggle/design-system';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { CollapsePanel } from '../../partials';

export const StyledCollapsePanel = styled(CollapsePanel)`
  @media ${deviceConstraints.desktopSmall} {
    padding-bottom: 20px;
  }

  @media ${deviceConstraints.desktop} {
    padding-bottom: 30px;
    margin-top: 0;
  }

  grid-area: collapse-panel;
`;

export const LimitBanner = styled.div<{ isGuest: boolean }>`
  padding: 16px 8px;
  border-radius: 4px;
  background-color: var(--base--90);
  width: 100%;
  text-align: center;
  ${typography.dingo};

  @media ${deviceConstraints.tablet} {
    padding: 16px;
  }

  display: flex;
  align-items: center;
  justify-content: center;
  height: 104px;

  > * {
    max-height: 100%;
    overflow: hidden;
  }

  @media ${deviceConstraints.tablet} {
    height: 80px;
  }
  @media ${deviceConstraints.desktopSmall} {
    height: 56px;
    padding-left: 155px;

    ${({ isGuest }) =>
      isGuest &&
      css`
        padding-left: 0;
      `}
  }
`;

export const ArticleContainer = styled.div`
  flex-direction: column;
`;

export const Article = styled.main`
  color: var(--base--0);
  background: var(--base--100);
  position: relative;
  display: grid;
  grid-gap: 10px;

  grid-template-areas:
    'menu'
    'overview'
    'summary'
    'collapse-panel'
    'footer';
  grid-template-columns: 100%;

  @media ${deviceConstraints.smartphoneOrTablet} {
    grid-template-columns: minmax(100%, auto);
  }

  @media ${deviceConstraints.tablet} {
    padding: 0 16px;
  }

  @media ${deviceConstraints.desktopSmall} {
    grid-template-areas:
      'sidebar overview'
      'sidebar collapse-panel'
      'sidebar footer';
    grid-template-columns: minmax(288px, 350px) minmax(620px, 1fr);
    column-gap: 24px;
    padding: 32px 24px 0px 24px;
  }

  @media (min-width: 880px) and (max-width: 980px) {
    grid-template-columns: 220px minmax(590px, 1fr);
    column-gap: 32px;
  }

  @media ${deviceConstraints.desktop} {
    padding: 24px 24px 0;
  }

  @media ${deviceConstraints.desktopLarge} {
    grid-template-areas:
      'menu overview summary'
      'share overview .'
      '. collapse-panel .'
      'footer footer footer';
    grid-template-columns: minmax(338px, 1fr) minmax(816px, 1fr) minmax(
        338px,
        1fr
      );
    column-gap: 190px;
    padding-top: 0;
  }
`;

export const BookmarkSection = styled.section`
  position: sticky;
  top: var(--app-mobile-banner-height);
  z-index: 2;
  background-color: var(--base--100);

  @media ${deviceConstraints.tablet} {
    top: var(--app-header-height);
  }

  @media ${deviceConstraints.desktopSmall} {
    top: var(--app-header-height);
  }
`;

export const ScrollableSidebar = styled.section`
  grid-area: sidebar;
  ${customScrollMinimal};
  overflow-y: auto;
  overflow-x: hidden;
  position: fixed;
  z-index: 1;
  background-color: var(--base--100);
  height: 100%;
  width: 350px;
  padding: 0 16px 200px 0;

  @media ${deviceConstraints.desktopSmall} {
    width: 28%;
  }

  @media (min-width: 880px) and (max-width: 980px) {
    width: 220px;
  }

  @media ${deviceConstraints.desktop} {
    width: 350px;
  }
`;

export const Overview = styled.section`
  grid-area: overview;
  @media ${deviceConstraints.desktopLarge} {
    padding-top: 24px;
  }
`;

export const ArticleFooter = styled.footer`
  grid-area: footer;
  display: flex;
  padding-bottom: 20vh;

  @media ${deviceConstraints.desktopSmall} {
    background-color: var(--base--100);
    justify-content: flex-start;
  }

  @media ${deviceConstraints.desktop} {
    padding-left: 24px;
    margin-left: -24px;
    background-color: var(--base--100);
  }

  @media ${deviceConstraints.desktopLarge} {
    padding-left: 0;
    align-items: center;
    justify-content: center;
    margin-right: 0;
  }
`;

export const BannerContainer = styled.section<{ isGuest: boolean }>`
  padding: 18px 16px 0;
  top: calc(var(--app-header-height) + 40px);
  z-index: 1;
  position: sticky;
  background-color: var(--base--100);
  ${({ isGuest }) =>
    isGuest &&
    css`
      padding-bottom: 12px;
      top: var(--app-header-height);
    `}

  @media ${deviceConstraints.desktopSmall} {
    padding: 0 24px;

    ${({ isGuest }) =>
      isGuest &&
      css`
        padding-top: 18px;
      `}
  }
`;

export const GuestHeader = styled.h2`
  padding: 8px 0;
  text-align: center;
  font-size: 16px;
  font-weight: normal;
  color: var(--base--100);
  line-height: 2;
  background-color: var(--copilot--default);

  a {
    text-decoration: underline;
  }
`;

export const SubscribeLink = styled(Link)`
  color: var(--copilot--default);
`;
