import { typography } from '@toggle/design-system';
import styled from 'styled-components';

export const PositionText = styled.p`
  ${typography.dingo}
`;

export const PositionSubText = styled.a`
  ${typography.koala}
  float: right;
  color: var(--primary-color);
  cursor: pointer;
`;
