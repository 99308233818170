import React, { useState } from 'react';

import { ArticleSummary } from '~/declarations/toggle-api-news';
import { appPaths } from '~/routes/app-paths';
import { gaBookmark } from '~/shared/utils/ganalytics';
import { useHelper } from '~/stores/use-helper/useHelper';
import { exploreSectionsRefs } from '~/views/helper/helper-widget/content/explore/ExploreHelperContent';
import { EXPLORE_SECTION_IDS } from '~/views/helper/HelperConfig';
import { ShareButtons } from '~/views/news/components/share-buttons/ShareButtons';

import * as S from './ArticleCardFooter.styles';

export interface ArticleCardFooterProps {
  className?: string;
  data: ArticleSummary;
  articleIdx?: number;
}

export function ArticleCardFooter({
  data,
  className,
  articleIdx,
}: ArticleCardFooterProps) {
  const [bookmarked, setBookmarked] = useState(data.bookmarked);

  const onBookmarkToggle = (bookmarked: boolean) => {
    gaBookmark(data.article_id, bookmarked, 'article-preview');
    setBookmarked(bookmarked);
  };

  const { setHelperHoverBehavior } = useHelper();

  return (
    <S.Footer className={className}>
      {data.article_id && (
        <>
          <div
            onClick={e => e.stopPropagation()}
            onKeyDown={e => e.stopPropagation()}
            data-testid="bookmark-wrapper"
            ref={
              articleIdx === 0
                ? exploreSectionsRefs[
                    EXPLORE_SECTION_IDS.EXPLORE_BOOKMARK_CHECK_ID
                  ]
                : null
            }
            {...(articleIdx === 0 &&
              setHelperHoverBehavior(
                EXPLORE_SECTION_IDS.EXPLORE_BOOKMARK_CHECK_ID
              ))}
          >
            <S.StyledBookmarkButton
              data-testid="bookmark-button"
              articleID={data.article_id}
              bookmarked={data.bookmarked}
              onBookmarkToggle={onBookmarkToggle}
              $active={bookmarked}
            />
          </div>
          <div
            onClick={e => e.stopPropagation()}
            onKeyDown={e => e.stopPropagation()}
            data-testid="share-wrapper"
          >
            <ShareButtons
              link={`${window.location.origin}${appPaths.article}/${data.article_id}`}
              title={data.ellipsis}
              tags={data.tags || []}
              isOpen
              isReddit
              iconSize="md"
              gaLocation="article-preview"
            />
          </div>
        </>
      )}
    </S.Footer>
  );
}
