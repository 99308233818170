import { typography } from '@toggle/design-system';
import styled from 'styled-components';

export const HeadingContainer = styled.div`
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: auto 1fr;
  align-items: center;
`;

export const Heading = styled.h2`
  ${typography.dingo}
  margin-left: 8px;
`;
