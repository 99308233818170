import { Loader } from '@toggle/design-system';
import React, { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { TRADESTATION_CUSTOM_EVENT } from '~/api/portfolio-tradestation-oauth/oauth-tradestation-schema';

export function TradestationCallback() {
  const search = useLocation().search;
  const query = useMemo(() => {
    return new URLSearchParams(search);
  }, [search]);

  useEffect(() => {
    if (window.opener) {
      const event = new CustomEvent(TRADESTATION_CUSTOM_EVENT, {
        detail: {
          code: query.get('code'),
        },
      });
      window.opener.dispatchEvent(event);
    }
  }, []);

  return <Loader />;
}
