import React, { FC, Fragment, ReactNode } from 'react';

import { EllipsisDetector } from '~/shared/components/elipsis-detector/EllipsisDetector';
import { MappedEntity } from '~/shared/hooks/use-entities';
import { gaWatchlistBtnToggle } from '~/shared/utils/ganalytics';
import { WatchlistButton } from '~/views/analyze/components/watchlist-button/WatchlistButton';

import * as S from './SearchAutocompleteItem.styles';

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions#escaping
const escapeRegExp = (string: string) => {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
};

export interface SearchAutocomplete {
  entity?: MappedEntity;
  leftText: string;
  rightText: string;
  tag: string;
  id: string;
  defaultSnake: string;
  description: string;
}

interface SearchAutocompleteItemProps {
  highlighted: string;
  item: SearchAutocomplete;
  isActive: boolean;
  componentOnItemStart?: (item: SearchAutocomplete) => React.ReactElement;
  componentOnItemEnd?: (item: SearchAutocomplete) => React.ReactElement;
  withWatchlistButton?: boolean;
}

export const highlightParts = (
  text: string,
  highlighted: string
): ReactNode | null => {
  if (text) {
    const parts = text.split(
      new RegExp(`(${escapeRegExp(highlighted)})`, 'gi')
    );
    return parts.map((part, i) => (
      <Fragment key={i}>
        {part.toUpperCase() === highlighted.toUpperCase() ? (
          <S.HighlightedMark data-testid="highlighted">
            {part}
          </S.HighlightedMark>
        ) : (
          part
        )}
      </Fragment>
    ));
  }
  return null;
};

export const SearchAutocompleteItem: FC<SearchAutocompleteItemProps> = ({
  highlighted,
  item,
  componentOnItemStart,
  componentOnItemEnd,
  withWatchlistButton,
  isActive,
}) => {
  return (
    <>
      <S.LeftPart data-testid="autocomplete-part">
        {withWatchlistButton && (
          <WatchlistButton
            entityId={item.id}
            entityTag={item.tag}
            onClickCallback={isActive =>
              gaWatchlistBtnToggle(isActive, item.leftText, 'search')
            }
            small
            placement={'top-end'}
          />
        )}
        &nbsp;
        {componentOnItemStart?.(item)}
        <EllipsisDetector label={item.leftText}>
          <S.LeftText data-testid="leftText">
            {isActive
              ? item.leftText
              : highlightParts(item.leftText, highlighted)}
          </S.LeftText>
        </EllipsisDetector>
        <EllipsisDetector label={item.rightText}>
          <S.RightText data-testid="rightText">
            {isActive
              ? item.rightText
              : highlightParts(item.rightText, highlighted)}
          </S.RightText>
        </EllipsisDetector>
      </S.LeftPart>
      {componentOnItemEnd?.(item)}
    </>
  );
};
