import { config } from '~/config';
import { validateApiFetch } from '~/shared/services/api-fetch/validate-api-fetch/validateApiFetch';

import {
  PreliminaryPrice,
  preliminaryPriceSchema,
  PriceHistory,
  priceHistorySchema,
  Resolution,
} from './price-schema';

export const priceEndpoints = {
  prices: `${config.api.root}price-history/v1/prices`,
  preliminaryPrice: (subscribableTicker: string) =>
    `${config.api.root}price-history/v1/market-close?ticker=${subscribableTicker}`,
};

export const getPreliminaryPrice = (subscribableTicker: string) => {
  return validateApiFetch<PreliminaryPrice>({
    endpoint: priceEndpoints.preliminaryPrice(subscribableTicker),
    schema: preliminaryPriceSchema,
  });
};
export interface GetPricesProps {
  ticker?: string;
  entityTag: string;
  resolution: Resolution;
  dateFrom?: string;
  dateTo?: string;
}

export const getPrices = async ({
  ticker,
  entityTag,
  resolution,
  dateFrom,
  dateTo,
}: GetPricesProps): Promise<PriceHistory> => {
  const priceUrl = new URL(priceEndpoints.prices);
  ticker && priceUrl.searchParams.append('ticker', ticker);
  priceUrl.searchParams.append('entity_tag', entityTag);
  priceUrl.searchParams.append('resolution', resolution);
  dateFrom && priceUrl.searchParams.append('from', dateFrom);
  dateTo && priceUrl.searchParams.append('to', dateTo);

  return validateApiFetch({
    endpoint: priceUrl.href,
    schema: priceHistorySchema,
  });
};
