import { Button, Icon } from '@toggle/design-system';
import { formatPriceWithCurrency } from '@toggle/helpers';
import { format } from 'date-fns';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ExploreArticleDirection } from '~/declarations/toggle-api-enums';
import { ArticleSummary } from '~/declarations/toggle-api-news';
import { METHOD_SUB_CLS_GROUPING } from '~/global/method_sub_cls_grouping';
import { openArticleInNewTab } from '~/views/analyze/utils/insights-helpers';

import * as S from './ChartArticleInsightCard.styles';

interface ChartArticleInsightCardProps {
  data: ArticleSummary;
}

const MAX_STARS = 8;

export const ChartArticleInsightCard: FC<ChartArticleInsightCardProps> = ({
  data,
}) => {
  const { t } = useTranslation('chart');

  const analysisType = METHOD_SUB_CLS_GROUPING[data.type_name];
  const isBullish = data.direction === ExploreArticleDirection.Bullish;
  const date = new Date(data.last_value_reference_date ?? data.created_on);

  const rows = [
    {
      title: t('chart:analysisType'),
      label: (
        <S.RowLabel>
          {t('chart:analysisType', { context: analysisType })}
        </S.RowLabel>
      ),
    },
    {
      title: t('chart:attractiveHorizon'),
      label: (
        <S.RowLabel>
          {t('chart:attractiveHorizon', { context: data.horizon })}
        </S.RowLabel>
      ),
    },
    {
      title: t('chart:medianReturn'),
      label: (
        <S.MedianReturn>
          {isBullish
            ? `+ ${data.median_return.toFixed(2)}`
            : `${data.median_return.toFixed(2)}`}
        </S.MedianReturn>
      ),
    },
    {
      title: t('chart:stars', { context: data.stars }),
      label: (
        <S.StarsLabel>
          <Icon iconName="Rating" size={20} />
          <S.RowLabel>
            {data.stars}/{MAX_STARS}
          </S.RowLabel>
        </S.StarsLabel>
      ),
    },
  ];

  return (
    <S.Container
      $isBullish={isBullish}
      data-testid="chart-article-insight-card"
    >
      <S.TypeTitle>
        {t('chart:articleInsight', { context: data.direction })}
        <mark>&#8480;</mark>
      </S.TypeTitle>
      <S.Title>{data.type}</S.Title>
      <S.RowsContainer>
        {rows.map((row, idx) => (
          <S.Row key={idx}>
            <S.RowTitle>{row.title}</S.RowTitle>
            {row.label}
          </S.Row>
        ))}
      </S.RowsContainer>
      <S.HelperText>
        {t('chart:analysisBased', {
          price: formatPriceWithCurrency(data.last_value),
          date: format(date, 'PPP'),
        })}
      </S.HelperText>
      <Button
        label={t('chart:fullDetails')}
        size="small"
        fullWidth
        onClick={() => openArticleInNewTab(data.article_id as string)}
      />
    </S.Container>
  );
};
