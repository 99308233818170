import { z } from 'zod';

export type PreliminaryPrice = z.infer<typeof preliminaryPriceSchema>;
export const preliminaryPriceSchema = z.object({
  time: z.string().datetime({ offset: true }),
  close: z.number(),
});

export type Resolution = `${number}${'m' | 'h' | 'D' | 'W' | 'M' | 'Y'}`;
export type PriceHistory = z.infer<typeof priceHistorySchema>;

export const priceHistorySchema = z.array(
  z.object({
    time: z.string().datetime(),
    open: z.number(),
    high: z.number(),
    low: z.number(),
    close: z.number(),
  })
);
