import styled, { css } from 'styled-components';

import { ROW_HEIGHT } from '../constants';

export const BackgroundRect = styled.rect`
  outline: none;
  fill: transparent;
  rx: 8px;
  width: 100%;
  opacity: 0.5;
  height: ${ROW_HEIGHT}px;
  transform: translateY(-${ROW_HEIGHT / 3}px);
`;

export const Button = styled.g<{ $isInteractive: boolean }>`
  outline: none;

  ${({ $isInteractive }) =>
    $isInteractive &&
    css`
      cursor: pointer;

      &:hover ${BackgroundRect} {
        fill: var(--surface-hover);
      }

      &:focus-visible ${BackgroundRect} {
        stroke: var(--border-focus);
      }

      &:active ${BackgroundRect} {
        fill: var(--surface-pressed);
      }
    `}
`;
