import { deviceConstraints, typography } from '@toggle/design-system';
import styled from 'styled-components';

import { Table } from '~/shared/components/table/Table';

export const TablesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: 24px 16px;

  @media ${deviceConstraints.tablet} {
    margin-left: 0px;
    margin-right: 0px;
  }
`;

export const StyledTable = styled(Table)`
  margin-bottom: 0;

  &:not(:last-child) {
    margin-right: 16px;
  }

  ${Table.Styled.RowCell} {
    padding: 8px 16px;
    min-width: initial;

    &:last-child {
      text-align: right;
    }
  }

  ${Table.Styled.HeaderCell} {
    border-bottom: 4px solid var(--primary-color);
  }
`;

export const Note = styled.p`
  ${typography.dingo};
  margin-left: 16px;

  @media ${deviceConstraints.tablet} {
    margin-left: 0;
  }
`;
