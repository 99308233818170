import { useState } from 'react';

import { useUser } from '~/stores/use-user/useUser';

import { Tracking } from '../tracking';
import { sendTicket, ZendeskTicket } from './zendeskService';

export enum ZendeskTickets {
  PortfolioFail = 'PORTFOLIO_FAIL_TICKET',
  FailedTradingOrder = 'FAILED_TRADING_ORDER',
  ForgotEmail = 'FORGOT_EMAIL_TICKET',
  FailedCancelOrder = 'FAILED_CANCEL_ORDER',
  TwoFaContact = 'TWO_FA_CONTACT',
}

export const ticketMessages = {
  [ZendeskTickets.PortfolioFail]: {
    subject: 'Failed portfolio connection',
    commentBody:
      'It looks like this IB portfolio is already connected on another account',
  },
  [ZendeskTickets.FailedTradingOrder]: {
    subject: 'Failed to place order',
    commentBody: 'It looks like this IB portfolio could not place an order',
  },
  [ZendeskTickets.ForgotEmail]: {
    subject: 'Email address issue',
    commentBody: 'I have an issue with the current email address on my account',
  },
  [ZendeskTickets.FailedCancelOrder]: {
    subject: 'Failed to cancel order',
    commentBody: "It looks like this order can't be cancelled",
  },
  [ZendeskTickets.TwoFaContact]: {
    subject: 'Issues with 2FA',
    commentBody: 'I’m facing issues with 2FA',
  },
};

export const useZendeskService = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useUser(state => ({ user: state.user }));
  const { email = '', full_name = '' } = user ?? {};

  const createTicket = async (
    ticketType: ZendeskTickets,
    userEmail?: string
  ) => {
    const ticket: ZendeskTicket = {
      ...ticketMessages[ticketType],
      requester: {
        email: userEmail || email,
        name: full_name,
      },
    };

    setIsLoading(true);
    try {
      const fetchResponse = await sendTicket(ticket);

      if (fetchResponse instanceof Error) {
        throw fetchResponse;
      }
    } catch (error) {
      Tracking.captureException(error as Error);
      Tracking.captureMessage(JSON.stringify(ticket));
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    createTicket,
  };
};
