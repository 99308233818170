import React from 'react';
import { useTranslation } from 'react-i18next';

import * as S from './ChatWelcome.styles';

export const ChatWelcome = () => {
  const { t } = useTranslation('chat');
  return (
    <S.ChatWelcomeRoot data-testid="chat-welcome">
      <S.WaveEmoji>👋</S.WaveEmoji>
      <S.WelcomeMessage>{t('chat:welcomeMessage')}</S.WelcomeMessage>
    </S.ChatWelcomeRoot>
  );
};
