import React, { FC } from 'react';

import { ProgressBar } from '~/shared/components/splash-screen/partials/progress-bar/ProgressBar';

import { GutterSize } from '../../chart-area-overlay/ChartAreaOverlay';
import * as S from './InsightLoader.styles';

export interface InsightLoaderProps {
  title: string;
  gutterSize: GutterSize;
}

export const InsightLoader: FC<InsightLoaderProps> = ({
  title,
  gutterSize,
}) => {
  return (
    <S.Container
      style={{ right: `${gutterSize.y + 10}px` }}
      data-testid="insight-loader"
    >
      <S.Text>{title}</S.Text>
      <ProgressBar duration="8000ms" delay="100ms" />
    </S.Container>
  );
};
