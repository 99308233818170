export const SHARE_BUTTONS = {
  EMAIL: 'email',
  COPYLINK: 'copylink',
  REDDIT: 'reddit',
  TWITTER: 'twitter',
  FACEBOOK: 'facebook',
} as const;

export type SHARE_BUTTONS_NAMES =
  typeof SHARE_BUTTONS[keyof typeof SHARE_BUTTONS];
