import React, { FC, ReactNode } from 'react';

import * as S from './CloseModal.styles';

export interface CloseModalProps {
  isOpen: boolean;
  primaryAction: () => void;
  secondaryAction?: () => void;
  title?: string;
  content?: ReactNode;
  continueLabel?: string;
  primaryButtonDisabled?: boolean;
  confirmLabel: string;
  onClose: () => void;
}

export const CloseModal: FC<CloseModalProps> = ({
  isOpen,
  title,
  content,
  continueLabel,
  confirmLabel,
  primaryAction,
  primaryButtonDisabled = false,
  secondaryAction,
  onClose,
}) => {
  return (
    <S.StyledModal
      isOpen={isOpen}
      onClose={onClose}
      data-testid="close-modal"
      cancelBtnProps={
        continueLabel
          ? {
              label: continueLabel,
              onClick: secondaryAction,
              'data-testid': 'modal-cancel-btn',
            }
          : undefined
      }
      primaryBtnProps={{
        label: confirmLabel,
        onClick: primaryAction,
        disabled: primaryButtonDisabled,
        'data-testid': 'modal-action-btn',
      }}
      title={title}
    >
      {content && <S.ModalContent>{content}</S.ModalContent>}
    </S.StyledModal>
  );
};
