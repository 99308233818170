import {
  Button,
  ButtonLink,
  CheckBox,
  InputProps,
} from '@toggle/design-system';
import { useNetworkStatus } from '@toggle/helpers';
import React, {
  ChangeEvent,
  FormEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { appPaths, authPaths } from '~/routes/app-paths';
import { LoadingView } from '~/shared/components/loading-view/LoadingView';
import { usePasswordValidation } from '~/shared/hooks/use-password-validation/usePasswordValidation';
import { gaMyAccountResetPassword } from '~/shared/utils/ganalytics/myAccountGA';

import * as AuthSharedStyles from '../auth-shared-styles/AuthSharedStyles.styles';
import { usePasswordInputState } from '../passwordInputHook/PasswordInputHook';
import { useResetPasswordState } from './ResetPasswordHook';
import * as S from './ResetPasswordView.styles';

const passwordDefinition: InputProps = {
  id: 'sign-up-password',
  name: 'password',
  'data-testid': 'password-input',
};

const confirmPasswordDefinition: InputProps = {
  id: 'sign-up-confirm-password',
  name: 'confirm-password',
  'data-testid': 'confirm-password-input',
};

export function ResetPasswordView() {
  const { t } = useTranslation(['auth', 'common', 'settings']);

  const {
    password,
    setPassword,
    confirmedPassword,
    setConfirmedPassword,
    arePasswordMatching,
  } = usePasswordInputState();
  const { isOnline, checkNetworkStatus } = useNetworkStatus();
  const {
    validate: validatePassword,
    hasError: hasPasswordError,
    error: passwordError,
    clearError: clearPasswordError,
  } = usePasswordValidation();
  const [islogoutChecked, setIslogoutChecked] = useState(true);

  const { resetState, resetPassword, checkToken, checkTokenState } =
    useResetPasswordState();

  useEffect(() => {
    checkToken();
  }, []);

  const error = useMemo(() => {
    return resetState.error || checkTokenState.error;
  }, [checkTokenState, resetState]);

  const isFormDisabled =
    !arePasswordMatching || resetState.isPending || hasPasswordError;

  const onPasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const onPasswordBlur = (event: ChangeEvent<HTMLInputElement>) => {
    validatePassword(event.target.value);
  };

  const onConfirmedPasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    setConfirmedPassword(event.target.value);
  };

  const handleCheckboxToggle = (name: string, isChecked: boolean) => {
    setIslogoutChecked(isChecked);
  };

  const onSubmit = useCallback(
    async (e: FormEvent) => {
      e.preventDefault();

      const online = await checkNetworkStatus();
      if (!online) {
        return null;
      }

      if (!arePasswordMatching) {
        return null;
      }
      gaMyAccountResetPassword(islogoutChecked);

      return resetPassword({
        password,
        confirmedPassword,
        logoutAll: islogoutChecked,
      });
    },
    [password, confirmedPassword, arePasswordMatching, islogoutChecked]
  );

  return (
    <AuthSharedStyles.Main>
      <LoadingView loading={!(checkTokenState.isSuccess || error)}>
        <AuthSharedStyles.Logo alt="logo" title="logo" />
        <S.ErrorContainer>
          {isOnline && error && (
            <S.NoLongerValidWrapper>
              <S.ErrorView data-testid="errorView">
                <S.ErrorTitle>{t(`auth:resetPassword.${error}`)}</S.ErrorTitle>
                <Link to={authPaths.forgotPassword}>
                  <Button
                    fullWidth
                    label={t('auth:resetPassword.linkToForgotPassword')}
                  />
                </Link>
                <S.BackToLoginLink to={{ pathname: authPaths.login }}>
                  <ButtonLink label={t('auth:resetPassword.backToLogin')} />
                </S.BackToLoginLink>
              </S.ErrorView>
            </S.NoLongerValidWrapper>
          )}

          {checkTokenState.isSuccess && !error && (
            <S.Form data-testid="resetPasswordForm" onSubmit={onSubmit}>
              <AuthSharedStyles.Heading>
                {t('auth:resetPassword.title')}
              </AuthSharedStyles.Heading>

              <S.StyledPasswordInput
                {...passwordDefinition}
                onChange={onPasswordChange}
                onBlur={onPasswordBlur}
                onFocus={clearPasswordError}
                label={t('auth:form.passwordLabel')}
                hasError={hasPasswordError}
                errorText={passwordError}
              />
              <S.StyledPasswordInput
                {...confirmPasswordDefinition}
                value={confirmedPassword}
                onChange={onConfirmedPasswordChange}
                label={t('auth:form.confirmPasswordLabel')}
                hasError={!!confirmedPassword && !arePasswordMatching}
                errorText={t('auth:resetPassword.notMatch')}
              />

              {!isOnline && <S.ErrorTitle>{t('auth:noInternet')}</S.ErrorTitle>}
              <CheckBox
                name="logoutCheckbox"
                label={t('settings:security.logOutAll')}
                checked={islogoutChecked}
                onChange={handleCheckboxToggle}
              />

              <AuthSharedStyles.CenterContent>
                <AuthSharedStyles.SendButtonWrapper>
                  <Button
                    fullWidth
                    data-testid="submit-button"
                    disabled={isFormDisabled}
                    isLoading={resetState.isPending}
                    label={t('common:change')}
                  />
                </AuthSharedStyles.SendButtonWrapper>
                <AuthSharedStyles.CancelButton as="a" href={appPaths.base}>
                  {t('common:cancel')}
                </AuthSharedStyles.CancelButton>
              </AuthSharedStyles.CenterContent>
            </S.Form>
          )}
        </S.ErrorContainer>
      </LoadingView>
    </AuthSharedStyles.Main>
  );
}
