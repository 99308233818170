import { Button, customScrollMinimal } from '@toggle/design-system';
import styled from 'styled-components';

export const Modal = styled.div`
  position: fixed;
  z-index: var(--z-index-app-header);
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`;

export const Content = styled.div`
  background-color: var(--background-default);
  height: 100%;
`;

export const Header = styled.header`
  display: flex;
  justify-items: space-between;
  align-items: center;
  padding-right: 16px;
  min-height: 40px;
  border-bottom: 1px solid var(--border-soft);
`;

export const Body = styled.section`
  ${customScrollMinimal};
  position: relative;
  height: calc(100% - 41px);
`;

export const CloseButton = styled(Button)`
  margin-left: auto;
`;
