import { deleteCookie, queryString, setCookie } from '@toggle/helpers';
import { add, differenceInHours } from 'date-fns';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  AuthData,
  AuthDataTwoFactor,
  getAuthToken,
  getRefreshToken,
  LoginProps,
  postLogin,
  postLogout,
  postRegister,
} from '~/api/auth/auth-services';
import { config } from '~/config';
import { authPaths, queryKeys } from '~/routes/app-paths';
import { useDocuments } from '~/shared/hooks/use-documents/useDocuments';
import { useRemoteStorage } from '~/shared/hooks/use-remote-storage';
import { gaCompleteRegistrationEvent } from '~/shared/utils/ganalytics';
import { use2fa } from '~/stores/use-2fa/use2fa';
import { useFeatureFlags } from '~/stores/use-feature-flags/useFeatureFlags';
import { usePortfolio } from '~/stores/use-portfolio/usePortfolio';
import { usePromoCodeStore } from '~/stores/use-promo-code/usePromoCodeStore';
import { useSubscription } from '~/stores/use-subscription/useSubscription';
import { useUser } from '~/stores/use-user/useUser';
import { useUserEntities } from '~/stores/use-user-entities/useUserEntities';
import { useExploreFilters } from '~/views/explore/store/use-explore-filters';

import { resetStores } from '../create-store/createStore';
import { useAuthStore } from './store/authStore';

const { appConfirmedPWCookie, appSessionCookieAuth } = config;

export interface SignUpProps {
  email: string;
  password: string;
  firstName: string;
  lastName?: string;
  referralCode?: string;
  promoCode?: string;
  documentIds?: string[];
}

interface UseAuth {
  initStores: (session: AuthData) => Promise<void>;
  login: (login: LoginProps) => Promise<AuthData | AuthDataTwoFactor | Error>;
  signUp: (user: SignUpProps) => Promise<AuthData | AuthDataTwoFactor | Error>;
  logout: () => Promise<void>;
  authProccessing: boolean;
  setAuthProccessing: (authProccessing: boolean) => void;
  setPasswordCheckedCookie: () => void;
}

const dueToExpire = (timestamp: Date): boolean => {
  const HOURS_BEFORE_RENEW = 48;
  return differenceInHours(timestamp, new Date()) < HOURS_BEFORE_RENEW;
};

export const getSession = async (): Promise<AuthData | undefined> => {
  try {
    const session = await getAuthToken();
    if (session instanceof Error) {
      throw session;
    }
    if (dueToExpire(new Date(session.expiry))) {
      const newSession = await getRefreshToken();
      if (newSession instanceof Error) {
        return session;
      }
      return newSession;
    }
    return session;
  } catch (error) {
    return undefined;
  }
};

export const useAuth = (): UseAuth => {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    resetAuthStore,
    updateAuthStore,
    authProccessing,
    setAuthProccessing,
  } = useAuthStore(state => ({
    resetAuthStore: state.destroy,
    updateAuthStore: state.update,
    authProccessing: state.authProccessing,
    setAuthProccessing: state.setAuthProccessing,
  }));
  const initUserEntities = useUserEntities(state => state.init);
  const initializeUser = useUser(state => state.initialize);
  const initializeSubscription = useSubscription(state => state.initialize);
  const initializeRemoteStorage = useRemoteStorage(state => state.initialize);
  const initializeFlagsStore = useFeatureFlags(state => state.initialize);
  const initializePromoCodeStore = usePromoCodeStore(state => state.initialize);
  const initializePortfolio = usePortfolio(state => state.initialize);
  const initialize2faStatus = use2fa(state => state.fetch2faStatus);
  const getDocsPendingConsent = useDocuments(
    state => state.getDocsPendingConsent
  );

  const clearExploreFilters = useExploreFilters(s => s.clearExploreFilters);

  const initStores = async (session: AuthData) => {
    setAuthProccessing(true);
    updateAuthStore(session);
    await initializeUser();
    setAppSessionCookie();
    await Promise.all([
      initializeRemoteStorage(),
      initializeFlagsStore(),
      initializeSubscription(),
      initUserEntities(),
      initializePortfolio(),
      initializePromoCodeStore(),
      initialize2faStatus(),
      getDocsPendingConsent(),
    ]);
    setAuthProccessing(false);
  };

  const setPasswordCheckedCookie = () => {
    const fiveMins = add(new Date(), { minutes: 5 });
    setCookie(appConfirmedPWCookie, undefined, { expires: fiveMins });
  };

  const setAppSessionCookie = () => {
    setCookie(appSessionCookieAuth, undefined, { expires: 365 });
  };

  const login = async ({ username, password }: LoginProps) => {
    const session = await postLogin({ username, password });
    if (!(session instanceof Error)) {
      setPasswordCheckedCookie();
      await initStores(session as AuthData);
    }
    return session;
  };

  const signUp = async (userDetails: SignUpProps) => {
    const registered = await postRegister(userDetails);
    if (registered instanceof Error) {
      return registered;
    }
    gaCompleteRegistrationEvent();
    return login({
      username: userDetails.email,
      password: userDetails.password,
    });
  };

  const logout = async () => {
    deleteCookie(appConfirmedPWCookie);
    deleteCookie(appSessionCookieAuth);
    resetStores();
    resetAuthStore();
    clearExploreFilters();
    navigate(
      authPaths.login +
        queryString({
          [queryKeys.redirect]: `${location.pathname}${location.search}`,
        })
    );
    await postLogout();
  };

  return {
    signUp,
    login,
    logout,
    initStores,
    authProccessing,
    setAuthProccessing,
    setPasswordCheckedCookie,
  };
};
