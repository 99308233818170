import {
  AutocompleteInput,
  DropdownProps,
  TRIGGER_INPUT_ID,
} from '@toggle/design-system';
import { getCookie } from '@toggle/helpers';
import partition from 'lodash/partition';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Location } from 'react-router-dom';

import { TradeSearchSecurity } from '~/api/trading/trading-schema';
import {
  OrderSide,
  useTransaction,
} from '~/global/trading-drawer/use-transaction/useTransaction';
import { setEndOfDayCookie } from '~/shared/hooks/use-is-tag-new-today/useIsTagNewToday';
import { gaClickBuy, gaClickSell } from '~/shared/utils/ganalytics/tradingGA';
import { Portfolio } from '~/stores/use-portfolio/usePortfolio';
import { TradingProvider } from '~/stores/use-portfolio/utils/use-portfolio-utils';

import { ClearButtons } from '../search/components/clear-buttons/ClearButtons';
import { SearchDivider } from '../search/components/search-divider/SearchDivider';
import { SearchIcon } from '../search/components/search-icon/SearchIcon';
import { OutOfSyncView } from './components/out-of-sync-view/OutOfSyncView';
import { SelectTradePortfolio } from './components/select-trade-portfolio/SelectTradePortfolio';
import { TradeSearchItem } from './components/trade-search-item/TradeSearchItem';
import { TradeSearchLoader } from './components/trade-search-loader/TradeSearchLoader';
import { useTradeSearch } from './hooks/useTradeSearch';
import * as S from './TradeSearch.styles';

export interface TradeSearchProps {
  initialValue: string;
  isMobile: boolean;
  tradingPortfolios: Portfolio[];
  tradingProvider: TradingProvider;
  location: Location;
  closeSearch: () => void;
}

const SYNC_LATER_COOKIE = 'sync_later';

export const TradeSearch = ({
  initialValue,
  isMobile,
  tradingPortfolios,
  tradingProvider,
  location,
  closeSearch,
}: TradeSearchProps) => {
  const createOrder = useTransaction(state => state.createOrder);
  const { t } = useTranslation('header');
  const [asset, setAsset] = useState<{
    action: OrderSide;
    item: TradeSearchSecurity;
  }>();

  const [showNoRes, setShowNoRes] = useState(false);
  const [showSelectTradePortfolio, setShowSelectTradePortfolio] =
    useState(false);
  const syncLaterDate = getCookie(SYNC_LATER_COOKIE);
  const [activePortfolios, outOfSyncPortfolios] = useMemo(
    () => partition(tradingPortfolios, p => p.active),
    [tradingPortfolios]
  );
  const isAllOutOfSync =
    outOfSyncPortfolios.length === tradingPortfolios.length;
  const [showOutOfSyncPortfolios, setShowOutOfSyncPortfolios] = useState(
    isAllOutOfSync
      ? true
      : !!outOfSyncPortfolios.length && !syncLaterDate && !initialValue
  );
  const { data, loading, searchValue, onSearchChange } = useTradeSearch(
    isAllOutOfSync ? '' : initialValue,
    tradingProvider,
    tradingPortfolios[0].login_id
  );

  useEffect(() => {
    if (!outOfSyncPortfolios.length) {
      document.getElementById(TRIGGER_INPUT_ID)?.focus();
    }
    setShowSelectTradePortfolio(false);
  }, [tradingProvider, outOfSyncPortfolios]);

  useEffect(() => {
    setShowNoRes(!!searchValue && !loading && !!data && !data.length);
  }, [data, loading]);

  const handleSearch = (value: string) => {
    onSearchChange(value);
    setShowNoRes(false);
    setShowOutOfSyncPortfolios(!value && !syncLaterDate);
    setShowSelectTradePortfolio(false);
  };

  const handleClear = () => {
    onSearchChange('');
    setShowNoRes(false);
    setShowOutOfSyncPortfolios(!syncLaterDate);
    setShowSelectTradePortfolio(false);
  };

  const sendGaEvent = (actionType: OrderSide, item: TradeSearchSecurity) => {
    if (actionType === OrderSide.BUY) {
      gaClickBuy(location.pathname, item.ticker);
    }

    if (actionType === OrderSide.SELL) {
      gaClickSell(location.pathname, item.ticker);
    }
  };

  const onTradeActionBtnClick = (
    actionType: OrderSide,
    item: TradeSearchSecurity
  ) => {
    if (tradingPortfolios.length === 1) {
      createOrder({
        brokerId: tradingProvider,
        portfolio: tradingPortfolios[0],
        securityId: item.id,
        currency: item.currency,
        ticker: item.ticker,
        side: actionType,
        priceDisplayRule: item?.display_rule?.display_rule_steps || [],
      });
    } else {
      setAsset({ item, action: actionType });
      setShowSelectTradePortfolio(true);
    }
    sendGaEvent(actionType, item);
  };

  const onBackToSearch = () => {
    setShowSelectTradePortfolio(false);
  };

  const onPortfolioClick = (portfolio: Portfolio) => {
    if (asset) {
      createOrder({
        brokerId: tradingProvider,
        portfolio,
        securityId: asset.item.id,
        currency: asset.item.currency,
        ticker: asset.item.ticker,
        side: asset.action,
        priceDisplayRule: asset.item.display_rule.display_rule_steps || [],
      });
    }
  };

  const onDoItLaterClick = () => {
    setShowOutOfSyncPortfolios(false);
    setEndOfDayCookie(SYNC_LATER_COOKIE);
  };

  const renderDropdownItem = (
    item: TradeSearchSecurity,
    isActive: boolean,
    isFocused: boolean
  ) => (
    <TradeSearchItem
      t={t}
      item={item}
      isFocused={isFocused}
      onBtnClick={onTradeActionBtnClick}
      highlighted={searchValue}
    />
  );

  const showOutOfSyncView = !!outOfSyncPortfolios.length;
  const showBanner = !isAllOutOfSync && showOutOfSyncView;

  return (
    <>
      <S.TypedStyledDropdown<FC<DropdownProps<TradeSearchSecurity>>>
        $showBanner={showBanner}
        $showSelectTradePortfolio={showSelectTradePortfolio}
        dropdownItems={loading ? [] : data ?? []}
        renderDropdownItem={renderDropdownItem}
        shouldCloseOnSelect={false}
        shouldCloseOnOutsideClick={false}
        beforeResultsNode={
          loading && (
            <TradeSearchLoader
              text={t('header:search.loading', { context: tradingProvider })}
            />
          )
        }
        afterResultsNode={
          <>
            {showNoRes && (
              <SearchDivider
                isNoResults
                text={t('header:search.noResults', {
                  context: tradingProvider,
                })}
              />
            )}
          </>
        }
        shouldDisableItemMouseEvents={isMobile}
        hasV2Styles={false}
        enableFloating={false}
      >
        <AutocompleteInput
          value={searchValue}
          onChange={e => handleSearch(e.target.value)}
          leftIcon={<SearchIcon />}
          rightIcon={
            <>
              {searchValue.length > 0 && (
                <ClearButtons handleClear={handleClear} />
              )}
              {closeSearch && isMobile && (
                <S.CloseIcon
                  iconName="Close"
                  size={18}
                  onClick={closeSearch}
                  data-testid="close-search-icon"
                />
              )}
            </>
          }
        />
      </S.TypedStyledDropdown>
      {showSelectTradePortfolio && (
        <SelectTradePortfolio
          t={t}
          isMobile={isMobile}
          tradingPortfolios={activePortfolios}
          onBackToSearch={onBackToSearch}
          onPortfolioClick={onPortfolioClick}
        />
      )}
      {showOutOfSyncView && (
        <OutOfSyncView
          showBanner={showBanner}
          showOutOfSyncPortfolios={showOutOfSyncPortfolios}
          outOfSyncPortfolios={outOfSyncPortfolios}
          isAllOutOfSync={isAllOutOfSync}
          isMobile={isMobile}
          onDoItLaterClick={onDoItLaterClick}
        />
      )}
    </>
  );
};
