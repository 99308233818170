import {
  HighLowDataPoint,
  PlotLine,
  PlotTypes,
  StopGradient,
  TSPoint,
} from '../../HighLowTripleLineChart.types';

const toGradientStops = (stopColor: string): StopGradient[] => {
  return [
    {
      offset: 0,
      stopColor,
      stopOpacity: 1,
    },
    {
      offset: 100,
      stopColor,
      stopOpacity: 1,
    },
  ];
};

type PlotGradientStopsMap = {
  [key in PlotTypes]?: StopGradient[];
};

const GRADIENTS_STOPS: PlotGradientStopsMap = {
  [PlotTypes.Positive]: toGradientStops(`var(--viz-bullish-soft)`),
  [PlotTypes.Negative]: toGradientStops(`var(--viz-bearish-soft)`),
  [PlotTypes.Median]: undefined,
};

const toTsPoint =
  <K extends keyof HighLowDataPoint>(key: K) =>
  (
    val: HighLowDataPoint,
    idx: number
  ): TSPoint<number, HighLowDataPoint[K]> => ({
    index: idx,
    value: val[key],
  });

export const getPlots = (ts: HighLowDataPoint[]): PlotLine[] => {
  const [max, mid, min] = [
    ts.map(toTsPoint('high')),
    ts.map(toTsPoint('median')),
    ts.map(toTsPoint('low')),
  ];

  return [
    {
      ts: max,
      type: PlotTypes.Positive,
      gradients: [
        {
          y0: (d, idx) => Math.max(min[idx].value, Math.min(0, d.value)),
          y1: (d: TSPoint<number, number>) => d.value,
          gradientStops: GRADIENTS_STOPS.positive,
        },
      ],
    },
    {
      ts: min,
      type: PlotTypes.Negative,
      gradients: [
        {
          y0: (d: TSPoint<number, number>) => Math.min(d.value, 0),
          y1: (_, idx) => Math.min(0, max[idx].value),
          gradientStops: GRADIENTS_STOPS.negative,
        },
      ],
    },
    {
      ts: mid,
      type: PlotTypes.Median,
    },
  ];
};
