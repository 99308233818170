import { StrictUnion } from '@toggle/helpers';

import { postEntities } from '~/api/entities/entity-service';
import {
  CognitiveSearch,
  Condition,
  DominoScenarioResponse,
  ScenarioCommanderResponse,
} from '~/api/scenario-commander/scenario-commander-schema';
import {
  postCogSearch,
  postDominoScenario,
  postScenarioCommander,
} from '~/api/scenario-commander/scenario-commander-services';
import { mapEntitySubheading } from '~/shared/hooks/use-entities';
import { APIResponse } from '~/shared/services/api-fetch';
import { FetchError } from '~/shared/services/fetch/FetchErrors';

export type ResponseMessage = StrictUnion<
  | {
      type: 'CognitiveSearch';
      data?: CognitiveSearch;
      question: string;
      error?: boolean;
    }
  | {
      type: 'DominoScenario';
      data?: DominoScenarioResponse;
      occurrences?: { episodes: number; tradingDays: number };
      scenario: ScenarioCommanderResponse;
      error?: boolean;
    }
  | {
      type: 'PastEpisodes';
      condition: string;
      data: DominoScenarioResponse;
      scenario: ScenarioCommanderResponse;
      error?: boolean;
    }
>;

type HandleQuestion = (question: string) => Promise<
  | ResponseMessage
  | {
      error: true;
    }
>;

export const handleQuestion: HandleQuestion = async question => {
  try {
    const scenario = await postScenarioCommander(question);
    return { type: 'DominoScenario', scenario };
  } catch (error) {
    if (
      error instanceof FetchError &&
      error.status === APIResponse.MISDIRECTED_REQUEST
    ) {
      return {
        type: 'CognitiveSearch',
        question,
      };
    }
    return {
      error: true,
    };
  }
};

export const spx = {
  tag: 'spx',
  name: 'S&P 500',
  snake: 'spx.price.ds',
  exchange: '',
  ticker: '',
};

export const mapConditionWithEntity = async (conditions: Condition[]) => {
  try {
    const tags = conditions.map(e => e.entity);
    const entities = await postEntities({ filter: 2, args: tags });

    const getEntityName = (tag: string) => {
      const found = entities.find(e => e.tag === tag);

      if (found) {
        return `${found.name} (${mapEntitySubheading(found)})`;
      }
      return tag;
    };
    return conditions.map(c => ({
      ...c,
      entity: getEntityName(c.entity),
    }));
  } catch {
    return conditions;
  }
};

const mapOccurrences = (scenarios: DominoScenarioResponse) => {
  return Object.values(scenarios).reduce(
    (a, c) => {
      const dates = c?.episodes[0].dates;
      if (dates) {
        return a.episodes > dates.length
          ? a
          : {
              episodes: dates.length,
              tradingDays: dates.reduce((a, b) => a + b.data.length, 0),
            };
      }
      return a;
    },
    { episodes: 0, tradingDays: 0 }
  );
};

export const handleResponse = async (
  response: ResponseMessage
): Promise<ResponseMessage> => {
  try {
    if (response.type === 'DominoScenario') {
      const orchestrator = await postDominoScenario({
        entities: [...response.scenario.entities, spx],
        conditions: [...response.scenario.conditions],
      });

      if (orchestrator instanceof Error) {
        return {
          ...response,
          error: true,
        };
      }

      const conditions = await mapConditionWithEntity(
        response.scenario.conditions
      );

      const occurrences = mapOccurrences(orchestrator);

      return {
        ...response,
        scenario: {
          ...response.scenario,
          conditions,
        },
        occurrences,
        data: orchestrator,
      };
    }

    if (response.type === 'CognitiveSearch') {
      const cogSearch = await postCogSearch(response.question);
      return { ...response, data: cogSearch };
    }

    return { ...response };
  } catch {
    return {
      ...response,
      error: true,
    };
  }
};
