export const STOCKS_FILTERS = {
  INSTRUMENTS: ['STOCKS', 'INST_ETFS'],
};

export const CURRENCIES_FILTERS = {
  INSTRUMENTS: ['MAJOR_FX', 'MINOR_FX'],
};

export const COMMODITIES_FILTERS = {
  INSTRUMENTS: ['COMM_FUTURES'],
};

export const CRYPTO_FILTERS = {
  INSTRUMENTS: ['CRYPTO'],
};

export const OPTIONS_FILTERS = {
  INSTRUMENTS: ['STOCKS', 'EQUITY_IDX'],
};

export const BEYOND_STOCKS_FILTERS = {
  INSTRUMENTS: ['INST_BEYOND_STOCKS'],
};

export const GEOGRAPHY_OPTIONS = {
  US: {
    properties: {
      eco: ['us'],
    },
  },
  CANADA: {
    properties: {
      eco: ['can'],
    },
  },
  UK: {
    properties: {
      eco: ['uk'],
    },
  },
  EUROPE: {
    properties: {
      eco: [
        'eu',
        'uk',
        'aut',
        'deu',
        'dnk',
        'esp',
        'fra',
        'gre',
        'hun',
        'ire',
        'isl',
        'ita',
        'nld',
        'nor',
        'pol',
        'por',
        'rus',
        'swe',
        'swi',
        'cze',
        'bel',
        'fin',
        'rou',
        'svn',
        'svk',
        'est',
        'lva',
        'ltu',
      ],
    },
  },
  AUSTRALIA: {
    properties: {
      eco: ['aus'],
    },
  },
  CHINA: {
    properties: {
      eco: ['chn', 'twn'],
    },
  },
  HONG_KONG: {
    properties: {
      eco: ['hk'],
    },
  },
  INDIA: {
    properties: {
      eco: ['ind'],
    },
  },
  JAPAN: {
    properties: {
      eco: ['jap'],
    },
  },
  KOREA: {
    properties: {
      eco: ['kor'],
    },
  },
  BRAZIL: {
    properties: {
      eco: ['bra'],
    },
  },
  MEXICO: {
    properties: {
      eco: ['mex'],
    },
  },
  SOUTH_AFRICA: {
    properties: {
      eco: ['zaf'],
    },
  },
  MIDDLE_EAST: {
    properties: {
      eco: ['sau', 'egy', 'qa', 'om', 'kw'],
    },
  },
  ASIA: {
    properties: {
      region: ['region_asia'],
    },
  },
  EM_MARKETS: {
    properties: {
      region: ['region_bric', 'region_ceemea', 'region_latam'],
    },
  },
  REST_OF_WORLD: {
    properties: {
      region: [
        'wor',
        'g10',
        'asia',
        'asiaxj',
        'latam',
        'dm',
        'em',
        'idn',
        'mys',
        'nzl',
        'phl',
        'sgp',
        'tha',
        'tur',
        'egy',
        'isr',
        'leb',
        'vnm',
        'arg',
        'col',
        'ceemea',
        'bric',
        'chl',
        'sau',
        'per',
        'uae',
        'irn',
      ],
    },
  },
};
