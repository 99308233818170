import {
  deviceConstraints,
  Icon,
  Input,
  typography,
} from '@toggle/design-system';
import styled, { css } from 'styled-components';

import { TooltipContainer } from '~/shared/components/tooltip-container/TooltipContainer';

export const Container = styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Labels = styled.div`
  flex: 1;
  gap: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Label = styled.p`
  ${typography.emu}
  height: 42px;
  text-align: left;
  display: flex;
  align-items: center;
`;

export const Inputs = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: column;
  justify-content: space-between;
  min-width: 65%;

  input {
    width: 0px;
  }

  @media ${deviceConstraints.desktopSmall} {
    flex: 1;
  }
`;

export const Item = styled.div`
  flex: 1;
`;

export const IconWrapper = styled.div<{ $active: boolean }>`
  background-color: var(--base--100);
  border-radius: 50%;
  display: inline-block;
  width: 32px;
  height: 32px;
  margin-right: -15px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ $active }) =>
    $active &&
    css`
      border: 1px solid var(--primary-color);
    `}
`;

export const LinkIcon = styled(Icon)`
  transform: rotate(90deg);
`;

export const CurrencyIcon = styled.span`
  ${typography.emu}
  color: var(--base--40)
`;

export const CurrencyInput = styled(Input)`
  cursor: default;
`;

export const InputTooltip = styled(TooltipContainer)`
  z-index: 101;
`;
