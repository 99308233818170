import React from 'react';

import { Video } from '~/shared/components/video/Video';
import { HELPER_VIDEO_LINKS } from '~/views/helper/HelperConfig';

import { VideoWrapper } from '../Content.styles';
import * as S from './FaqHelperContent.styles';

export function InvestContent() {
  return (
    <S.FaqContent>
      <VideoWrapper>
        <Video src={HELPER_VIDEO_LINKS.FAQ_INVEST_LINK} />
      </VideoWrapper>
    </S.FaqContent>
  );
}
