import { SearchTabsOptions } from '../../use-global-search/useGlobalSearch';
import { Portfolio } from '../usePortfolio';

export enum TradingProvider {
  Ib = 'interactive-brokers',
  Tradestation = 'tradestation',
}

export enum PriceProvider {
  Nasdaq = 'Nasdaq',
  Ib = 'Ib',
  Tradestation = 'Tradestation',
}

export const ENABLED_TRADING_PROVIDERS: string[] = [
  TradingProvider.Ib,
  TradingProvider.Tradestation,
];

export const isTradingEnabledForPortfolio = (p: Portfolio) =>
  ENABLED_TRADING_PROVIDERS.includes(p.provider.external_id);

export const mapSearchTabToTradingProvider: Partial<
  Record<SearchTabsOptions, TradingProvider>
> = {
  [SearchTabsOptions.Ib]: TradingProvider.Ib,
  [SearchTabsOptions.Tradestation]: TradingProvider.Tradestation,
};

export const mapTradingProviderToSearchTab: Record<string, SearchTabsOptions> =
  {
    [TradingProvider.Ib]: SearchTabsOptions.Ib,
    [TradingProvider.Tradestation]: SearchTabsOptions.Tradestation,
  };

export const mapTradingProviderToPriceProvider: Record<string, PriceProvider> =
  {
    [TradingProvider.Ib]: PriceProvider.Ib,
    [TradingProvider.Tradestation]: PriceProvider.Tradestation,
  };
