import { Button } from '@toggle/design-system';
import styled from 'styled-components';

export const Nav = styled.nav`
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1px;
  border-radius: 8px 8px 0px 0px;
  background-color: var(--base--90);

  &::after {
    content: '';
    height: 2px;
    width: 100%;
    position: absolute;
    top: calc(100% - 1px);
    background-color: var(--primary-color);
  }
`;

export const PortfolioIconWrapper = styled.div`
  border-radius: 50%;
  overflow: hidden;
  margin-right: 8px;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
`;

export const CloseButton = styled(Button)`
  margin: 4px 4px 6px;
  height: 22px;
  width: 22px;
  min-width: 22px;
`;
