import styled from 'styled-components';

import { Button } from '~/components/button/Button';
import { Dropdown, DropdownProps } from '~/components/dropdown/Dropdown';
import { DropdownList } from '~/components/dropdown/dropdown-list/DropdownList';
import { DropdownListItem } from '~/components/dropdown/dropdown-list-item/DropdownListItem';
import { borderRadius } from '~/design-tokens/border-radius/borderRadius';
import { typographyV2 } from '~/design-tokens/typography/Typography.styles';

export const StyledButton = styled(Button)`
  display: none;
  border: transparent;
  transform: rotate(90deg);
`;

export const StyledPillContainer = styled.div`
  display: inline-flex;
  width: fit-content;
  justify-content: center;
  align-items: center;
`;

export const PillDropdownRoot = styled.div<{
  $showOptions: boolean;
  $hasOptions: boolean;
}>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  white-space: nowrap;
  ${borderRadius.r2}
  ${typographyV2.bodySm}
  border: 1px solid transparent;
  background-color: var(--surface-default);
  color: var(--text-default);
  padding: 4px 4px;
  gap: 8px;
  &:hover {
    border: 1px solid var(--border-hover);
    ${StyledButton} {
      ${({ $hasOptions }) => $hasOptions && 'display: flex;'}
    }
  }
  &:focus-visible {
    outline: 2px solid var(--border-focus);
  }
  &:focus-within {
    border: 1px solid var(--border-hover);
    ${StyledButton} {
      ${({ $hasOptions }) => $hasOptions && 'display: flex;'}
    }
  }
`;

export const Label = styled.span`
  ${typographyV2.bodySm};
  display: flex;
  align-items: center;
  flex-direction: row;
  margin: 0 4px;
  text-transform: capitalize;
`;

export const StyledPillDropdown = styled(Dropdown)`
  min-width: 50px;
  width: fit-content;
  ${DropdownList.Styled.DropdownListRoot} {
    width: 288px;
    white-space: nowrap;
    max-height: 264px;
    overflow: auto;
  }
  ${DropdownListItem.Styled.Item} {
    margin: 8px;
    &:focus-visible {
      outline: 2px solid var(--border-focus);
    }
    &::before {
      background-color: none;
    }
  }
` as <T>(props: DropdownProps<T>) => JSX.Element;
