import { Button, ButtonLink, typography } from '@toggle/design-system';
import styled, { keyframes } from 'styled-components';

import { ProcessingState } from './ConfirmationScreen';

const blackColor = 'var(--base--100)';
interface StyleProps {
  state: ProcessingState;
}

const statusColors: Record<
  ProcessingState,
  { primary: string; secondary: string }
> = {
  processing: {
    primary: blackColor,
    secondary: 'var(--base--0)',
  },
  processingExtended: {
    primary: blackColor,
    secondary: 'var(--base--0)',
  },
  submitted: {
    primary: 'var(--success--default)',
    secondary: blackColor,
  },
  cannotTrade: {
    primary: 'var(--warning--default)',
    secondary: blackColor,
  },
  error: {
    primary: 'var(--failure--default)',
    secondary: blackColor,
  },
  fatal: {
    primary: 'var(--failure--default)',
    secondary: blackColor,
  },
};

export const Container = styled.div<StyleProps>`
  flex-grow: 1;
  background-color: ${({ state }) => statusColors[state].primary};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`;

export const Image = styled.div<StyleProps>`
  color: ${({ state }) => statusColors[state].secondary};
  height: 200px;
  width: 320px;
  padding: 20px;
  margin: 50px 0px 20px;
`;

export const Body = styled.div`
  flex: 1;
`;
export const Title = styled.p<StyleProps>`
  ${typography.chimp}
  color: ${({ state }) => statusColors[state].secondary};
  text-align: center;
  white-space: pre-line;
`;

export const Message = styled.p<StyleProps>`
  ${typography.emu}
  color: ${({ state }) => statusColors[state].secondary};
  text-align: center;
`;

export const ActionButtonContainer = styled.div`
  display: flex;
  gap: 8px;
  margin: 16px;
  width: 100%;
  max-width: 240px;
`;

export const SecondaryActionButtonContainer = styled.div`
  display: flex;
`;

export const SecondaryActionButton = styled(Button)<StyleProps>`
  color: ${({ state }) => statusColors[state].primary};
`;

export const ActionButton = styled(Button)`
  background-color: transparent;
  border: 1px solid var(--base--100);
  color: var(--base--100);

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
    color: var(--base--100);
  }
`;

export const TryAgainButton = styled(Button)`
  background-color: var(--base--100);
  color: var(--failure--default);

  &:hover {
    background-color: var(--base--95);
  }
`;

export const CustomButtonLink = styled(ButtonLink)`
  color: var(--base--100);
`;

const float = keyframes`
  0% {
		transform: translateY(0px) rotate(0deg);
	}
	50% {
		transform: translateY(-10px) rotate(-6deg);;
	}
	100% {
		transform: translateY(0px) rotate(0deg);
	}
`;

export const Float = styled.img`
  animation: ${float} 6s ease-in-out infinite;
`;
