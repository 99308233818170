import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Video } from '~/shared/components/video/Video';
import { HELPER_VIDEO_LINKS } from '~/views/helper/HelperConfig';

import * as S from './FaqHelperContent.styles';

export function WhatActionableContent() {
  const { t } = useTranslation('helperWidget');
  return (
    <S.FaqContent>
      <S.VideoWrapper>
        <Video src={HELPER_VIDEO_LINKS.FAQ_WHAT_ACTIONABLE_LINK} />
      </S.VideoWrapper>
      <Trans
        t={t}
        i18nKey="helperWidget:faq.whatActionable.content"
        components={[<span key="" />]}
      />
    </S.FaqContent>
  );
}
