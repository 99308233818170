import classNames from 'classnames';
import React from 'react';

import { OptionalU } from '~/declarations/standard';

import type { BorderType, HeaderType } from './Table.styles';
import * as S from './Table.styles';

export type ZebraType = 'rows' | 'columns';
export type ZebraOrder = 'odd' | 'even';

export enum ContentAlignments {
  START = 'flex-start',
  CENTER = 'center',
  END = 'flex-end',
}

export interface TableProps {
  rows: Array<Array<string | number>>;
  columnWidths: string[];
  columnContentAlignments?: string[];
  markedIndex?: number;
  className?: string;
  headerType?: HeaderType;
  zebraType?: ZebraType;
  zebraOrder?: ZebraOrder;
  borderType?: BorderType;
  onRowHover?: (rowIdx: number) => void;
  onRowLeave?: (rowIdx: number) => void;
  hoveredRow?: number;
}

export const Table = ({
  rows,
  columnWidths,
  columnContentAlignments,
  markedIndex,
  className,
  headerType,
  zebraType,
  zebraOrder = 'even',
  borderType = 'Bottom',
  onRowHover,
  onRowLeave,
  hoveredRow,
}: TableProps) => {
  if (!rows.length) {
    return null;
  }

  return (
    <S.StyledTable data-testid="table" className={className}>
      {rows.map((row, rowIndex) => (
        <S.Row
          key={`${rowIndex}`}
          isHovered={hoveredRow === rowIndex}
          onMouseOver={() => onRowHover?.(rowIndex)}
          onMouseLeave={() => onRowLeave?.(rowIndex)}
          data-testid="table-row"
        >
          {row.map((cellContent, cellIndex) => {
            const _isHeaderCell = isHeaderCell(headerType, rowIndex, cellIndex);
            const TableCell = _isHeaderCell ? S.HeaderCell : S.RowCell;

            return (
              <TableCell
                data-testid="table-cell"
                key={`${rowIndex}-${cellIndex}`}
                borderType={borderType}
                headerType={_isHeaderCell ? headerType : undefined}
                className={classNames({
                  zebraCell: isZebraCell(
                    zebraType,
                    zebraOrder,
                    rowIndex,
                    cellIndex
                  ),
                  markedCell: isMarkedCell(
                    markedIndex,
                    rowIndex,
                    cellIndex,
                    headerType
                  ),
                })}
                style={{
                  flexBasis: `${columnWidths[cellIndex]}`,
                  justifyContent: `${
                    columnContentAlignments?.[cellIndex] ?? ''
                  }`,
                }}
              >
                {cellContent}
              </TableCell>
            );
          })}
        </S.Row>
      ))}
    </S.StyledTable>
  );
};

const isZebraCell = (
  zebraType: OptionalU<ZebraType>,
  zebraOrder: ZebraOrder,
  rowIdx: number,
  cellIdx: number
) => {
  if (!zebraType) {
    return false;
  }
  const idx = zebraType === 'rows' ? rowIdx : cellIdx;
  const isEven = (idx + 1) % 2 === 0;
  return zebraOrder === 'even' ? isEven : !isEven;
};

const isHeaderCell = (
  headerType: OptionalU<HeaderType>,
  rowIdx: number,
  cellIdx: number
) => {
  if (!headerType) {
    return undefined;
  }
  const idx = headerType === 'horizontal' ? rowIdx : cellIdx;
  return idx === 0;
};

const isMarkedCell = (
  markedIndex: OptionalU<number>,
  rowIdx: number,
  cellIdx: number,
  headerType: HeaderType = 'horizontal'
) => {
  if (markedIndex === undefined) {
    return false;
  }
  const idx = headerType === 'horizontal' ? cellIdx : rowIdx;
  return idx === markedIndex;
};

Table.Styled = S;
