import styled from 'styled-components';

import { typographyV2 } from '~/design-tokens/typography/Typography.styles';

export const RootSVG = styled.svg`
  color: var(--text-soft);
  border-radius: 4px;
`;

export const AxisLine = styled.line`
  stroke: var(--border-default);
`;

export const AxisText = styled.text<{ isHeader?: boolean }>`
  ${typographyV2.bodyXs};
  fill: var(--text-soft);
  text-anchor: ${props => (props.isHeader ? 'end' : 'middle')};
`;

export const LabelHeader = styled.text`
  ${typographyV2.bodyXs};
  fill: var(--text-soft);
`;
