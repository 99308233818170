import styled from 'styled-components';

export const AoWidgetRoot = styled.div`
  display: grid;
  grid-template-rows: auto auto 1fr;
  gap: 20px;
  width: 100%;
  padding: 20px 24px;
  overflow: hidden;
`;
