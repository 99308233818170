import styled, { css } from 'styled-components';

import { ToggleLogotype } from '~/common/toggle-logotype/ToggleLogotype';
import { elevation } from '~/design-tokens/elevation/elevation';
import { timingFunction } from '~/utils/transitions';

import { CategoryGroup } from '../side-navigation/components/category-group/CategoryGroup';
import { COLLAPSED_WIDTH_PX, EXPANDED_WIDTH_PX } from './SideNavigationV2';

export const LogoButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  background-color: transparent;
`;

export const AsideExpandable = styled.aside<{ isExpanded: boolean }>`
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  border-right: 1px solid var(--border-soft);
  height: 100vh;
  width: ${({ isExpanded }) =>
    isExpanded ? EXPANDED_WIDTH_PX : COLLAPSED_WIDTH_PX};
  transition: width 0.3s ${timingFunction.base};
  background-color: var(--background-soft);
  z-index: 1;
`;

export const Header = styled.header`
  position: relative;
  padding: 20px;
  border-bottom: 1px solid var(--border-soft);
  height: 60px;
`;

export const StyledLogotype = styled(ToggleLogotype)<{ isExpanded: boolean }>`
  transition: transform 300ms ${timingFunction.base};

  ${({ isExpanded }) =>
    !isExpanded &&
    css`
      transform: translateX(-15px);
    `};
`;

export const ToggleButtonWrapper = styled.div`
  ${elevation.strong};
  position: absolute;
  right: 0;
  bottom: 0;
  transform: translate(50%, 50%);
  border-radius: 50%;
`;

export const ToggleButton = styled.button<{ isExpanded: boolean }>`
  border: 1px solid var(--border-soft);
  background-color: var(--background-default);
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover,
  &:focus-visible {
    border-color: var(--surface-primary);
    background-color: var(--surface-primary);
    svg {
      fill: var(--text-on-primary);
    }
  }

  ${({ isExpanded }) =>
    isExpanded &&
    css`
      transform: rotate(180deg);
    `};
`;

export const Categories = styled.div<{ isExpanded: boolean }>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
  padding: 16px 8px 16px 0;
  gap: 16px;
  overflow-x: clip;

  ${CategoryGroup.Styled.MainLabel},
  ${CategoryGroup.Styled.BadgeWrapper} {
    transition: opacity 0.3s ${timingFunction.base};
  }

  ${({ isExpanded }) =>
    isExpanded
      ? css`
          ${CategoryGroup.Styled.MainLabel} {
            opacity: 1;
          }
        `
      : css`
          ${CategoryGroup.Styled.ItemWrapper} {
            ::before {
              transform: translateX(-100%);
            }
          }

          ${CategoryGroup.Styled.ItemBackground} {
            transform: translateX(6px);
            width: calc(100% - 18px);
          }

          ${CategoryGroup.Styled.MainLabel},
          ${CategoryGroup.Styled.BadgeWrapper} {
            opacity: 0;
          }
        `}
`;
