import debounce from 'lodash/debounce';
import { useEffect, useRef, useState } from 'react';

import { TradeSearchSecurity } from '~/api/trading/trading-schema';
import { getTradeSearch } from '~/api/trading/trading-service';
import { QUERY_DELAY } from '~/config/constants';

export const useTradeSearch = (
  initialValue: string,
  providerId: string,
  loginId: string
) => {
  const [searchValue, setSearchValue] = useState(initialValue);
  const [data, setData] = useState<TradeSearchSecurity[]>();
  const [loading, setLoading] = useState(false);
  const abortController = useRef<AbortController>();

  const debouncedFn = useRef(
    debounce(
      async (searchValue: string, providerId: string, loginId: string) => {
        try {
          abortController.current = new AbortController();

          const data = await getTradeSearch({
            searchValue,
            providerId,
            loginId,
            abortSignal: abortController.current.signal,
          });

          if (abortController.current.signal.aborted) {
            return;
          }

          if (data instanceof Error) {
            throw data;
          }
          setLoading(false);
          setData(data.securities);
        } catch (error) {
          if (error instanceof Error && error?.name !== 'AbortError') {
            setLoading(false);
          }
          setData([]);
        }
      },
      QUERY_DELAY
    )
  ).current;

  const onSearchChange = (searchValue: string) => {
    abortController.current?.abort();
    debouncedFn.cancel();

    setSearchValue(searchValue);
    setLoading(!!searchValue);
    if (!searchValue) {
      setData(undefined);
    } else {
      debouncedFn(searchValue, providerId, loginId);
    }
  };

  useEffect(() => {
    onSearchChange(initialValue);
  }, [initialValue, providerId]);

  return { data, loading, onSearchChange, searchValue };
};
