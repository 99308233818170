import { Dispatch, SetStateAction, useState } from 'react';

interface PasswordInputState {
  password: string;
  setPassword: Dispatch<SetStateAction<string>>;
  confirmedPassword: string;
  setConfirmedPassword: Dispatch<SetStateAction<string>>;
  arePasswordMatching: boolean;
}

export function usePasswordInputState(): PasswordInputState {
  const [password, setPassword] = useState('');
  const [confirmedPassword, setConfirmedPassword] = useState('');

  return {
    password,
    setPassword,
    confirmedPassword,
    setConfirmedPassword,
    arePasswordMatching: !!confirmedPassword && password === confirmedPassword,
  };
}
