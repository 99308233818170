import { typography } from '@toggle/design-system';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Menu = styled.nav`
  position: fixed;
  inset: 0;
  z-index: var(--z-index-app-header);
  background-color: var(--base--black);
  transition: 0.5s;
`;
export const MobileMenuContainer = styled.div`
  background-color: var(--base--100);
  width: 80%;
  height: 100%;
`;

export const MobileMenuSearchWrapper = styled.div`
  padding: 32px 16px;
`;

export const MobileMenuHeader = styled.header`
  background-color: var(--base--100);
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--base--70);
  padding: 10px 8px;
  justify-content: space-between;
`;

export const MobileMenu = styled.ul`
  display: flex;
  flex-direction: column;
  height: 100%;
  &&& {
    padding: 10px 16px;
  }
  overflow-x: hidden;
`;

export const MobileMenuItem = styled.li`
  ${typography.dingo};
  margin-bottom: 20px;
`;

export const MenuItemButton = styled.button`
  background: none;
  border: none;
  color: inherit;
`;

export const MobileMenuItemContainer = styled.div`
  display: flex;
  align-items: center;
  justify-items: center;
`;
export const ExternalItemContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

interface MenuItemInternalProps {
  $disabled?: boolean;
}

export const MenuItemInternal = styled(Link)<MenuItemInternalProps>`
  ${props =>
    props.$disabled &&
    'color: var(--base--75); cursor: default; pointer-events: none;'}
`;

export const ListHeading = styled.p`
  ${typography.emu};
  color: var(--base--60);
  font-weight: 500;
  margin: 22px 0 10px 0;
`;
