import { deviceConstraints, Icon, typography } from '@toggle/design-system';
import styled, { css } from 'styled-components';

import { ExploreArticleDirection } from '~/declarations/toggle-api-enums';
import { getBullishBearishStyles } from '~/views/analyze/asset-overview/components/sidebar/components/bullish-bearish-card/CardSharedStyles.styles';
import { PriceStatus } from '~/views/analyze/utils/asset-box/asset-box-helpers';

const sideStyles = css`
  @media ${deviceConstraints.desktopSmall} {
    flex-basis: 50%;
  }
`;

export const InsightSummary = styled.div`
  margin: 0 16px 8px 40px;
  @media ${deviceConstraints.tablet} {
    margin: 0;
  }

  @media ${deviceConstraints.desktopSmall} {
    padding-top: 16px;
  }
`;

export const InsightSummaryItem = styled.div`
  ${typography.emu};
  padding: 7px 0;
  border-bottom: 1px solid var(--base--95);
  display: flex;
  align-items: center;

  @media ${deviceConstraints.desktop} {
    ${typography.dingo};
  }
`;

export const LeftSide = styled.p`
  ${sideStyles};
  flex-basis: 70%;

  @media ${deviceConstraints.desktop} {
    flex-basis: 60%;
  }
`;

export const RightSide = styled.p`
  ${sideStyles};
  display: flex;
  flex-basis: 30%;

  @media ${deviceConstraints.desktop} {
    flex-basis: 40%;
  }
`;

export const StarsText = styled(RightSide)`
  color: var(--warning--default);
`;

export const DirectionText = styled(RightSide)<{
  type: ExploreArticleDirection;
}>`
  text-transform: uppercase;
  ${({ type }) => getBullishBearishStyles(type)};
`;

export const RatingIcon = styled(Icon)`
  margin-left: 2px;
  margin-top: 3px;
`;

export const RightSideWithPriceStatus = styled(RightSide)<{
  status: PriceStatus;
}>`
  ${({ status }) =>
    status === PriceStatus.negative &&
    getBullishBearishStyles(ExploreArticleDirection.Bearish)};
  ${({ status }) =>
    status === PriceStatus.positive &&
    getBullishBearishStyles(ExploreArticleDirection.Bullish)};
`;
