import {
  SortDirection,
  Table,
  TableCell,
  TableHeader,
} from '@toggle/design-system';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PastEpisode } from '~/api/scenario-commander/scenario-commander-schema';
import {
  sortDateData,
  sortNumberData,
} from '~/views/scenario-commander/utils/table-utils/table-utils';

import * as S from './PastEpisodesTable.styles';

export enum TableHeaderEnum {
  Date = 'date',
  TradingDays = 'trading_days',
}

export interface PastEpisodesTableProps {
  pastEpisodes: PastEpisode[];
}

export const PastEpisodesTable = ({ pastEpisodes }: PastEpisodesTableProps) => {
  const { t } = useTranslation('scenarioCommander');
  const [activeHeader, setActiveHeader] = useState(TableHeaderEnum.Date);
  const [filteredData, setFilteredData] = useState<PastEpisode[]>(() =>
    sortDateData(SortDirection.dsc, pastEpisodes)
  );

  const tableHeaderData = [
    {
      name: t('scenarioCommander:pastEpisodes.episodeDate'),
      type: TableHeaderEnum.Date,
      alignment: 'start',
    },
    {
      name: t('scenarioCommander:pastEpisodes.tradingDays'),
      type: TableHeaderEnum.TradingDays,
    },
  ];

  const handleSort = (sort: SortDirection, selectedHeader: TableHeaderEnum) => {
    if (selectedHeader === TableHeaderEnum.Date) {
      const sortedDates = sortDateData(sort, pastEpisodes);
      setFilteredData([...sortedDates]);
    } else {
      const sortedDates = sortNumberData(sort, pastEpisodes);
      setFilteredData([...sortedDates]);
    }

    setActiveHeader(selectedHeader);
  };

  const totalEpisodesCount = pastEpisodes.length;
  const totalTradingDaysCount = pastEpisodes.reduce(
    (totalLength, pastEpisode) => totalLength + pastEpisode.data.length,
    0
  );

  return (
    <S.PastEpisodesTableRoot>
      <Table
        TableHeaderContent={
          <>
            <tr>
              {tableHeaderData.map(d => (
                <TableHeader
                  data-testid={`table-header-${d.type}`}
                  key={d.type}
                  alignment="start"
                  isSorted={d.type === activeHeader}
                  isSortable
                  headerClick={sortDirection =>
                    handleSort(sortDirection, d.type)
                  }
                >
                  {d.name}
                </TableHeader>
              ))}
            </tr>
            <S.ExtraTableHeaderRow>
              <th>
                {t('scenarioCommander:pastEpisodes.totalEpisodes', {
                  count: totalEpisodesCount,
                })}
              </th>
              <th>
                {t('scenarioCommander:pastEpisodes.totalTradingDays', {
                  count: totalTradingDaysCount,
                })}
              </th>
            </S.ExtraTableHeaderRow>
          </>
        }
        TableBodyContent={filteredData.map((d, i) => (
          <tr key={`${d.date}-${i}`}>
            <TableCell data-testid={`past-episode-${i}`} alignment="start">
              {d.date}
            </TableCell>
            <TableCell alignment="start">{d.data.length}</TableCell>
          </tr>
        ))}
      />
    </S.PastEpisodesTableRoot>
  );
};
