import { AssetClassCode, Entity, PrimaryMethod } from '@toggle/toggle';

export const isEntity = (input: any): input is Entity => {
  return input?.primary_method !== undefined;
};

export const checkIsIndicator = (entity: any): boolean =>
  isEntity(entity) &&
  (entity.primary_method !== PrimaryMethod.Price ||
    entity.asset_class === AssetClassCode.Fx);
