import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { MobileNotSupported } from '~/shared/components/mobile-not-supported/MobileNotSupported';
import { useTouchDevice } from '~/shared/hooks/MediaQueryHook';
import { useChatScroll } from '~/shared/hooks/use-chat-scroll/useChatScroll';

import { ChatSubmit } from './components/chat-submit/ChatSubmit';
import { ChatWelcome } from './components/chat-welcome/ChatWelcome';
import { ErrorMessage } from './components/error-message/ErrorMessage';
import { Messages } from './components/messages/Messages';
import * as S from './ScenarioCommander.styles';
import { useScenarioCommander } from './store/useScenarioCommander';

export const ScenarioCommander = () => {
  const isTouchDevice = useTouchDevice();
  const { t } = useTranslation(['chat', 'analyze', 'scenarioCommander']);
  const chatContentRef = useRef<HTMLDivElement>(null);
  const { sendMessage, messages, error } = useScenarioCommander();

  const activeMessageIdx = messages.length - 1;
  useChatScroll({ messages, activeMessageIdx, chatContentRef });

  if (isTouchDevice) {
    return <MobileNotSupported message={t('chat:mobileBlockedMsg')} />;
  }

  return (
    <S.ChatRoot data-testid="scenario-commander">
      <S.Content ref={chatContentRef}>
        {error && (
          <S.ErrorWrapper>
            <ErrorMessage />
          </S.ErrorWrapper>
        )}
        {messages.length ? <Messages messages={messages} /> : <ChatWelcome />}
      </S.Content>

      <S.Footer>
        <ChatSubmit
          placeholder={t('chat:inputPlaceholder')}
          submitCallback={message => {
            sendMessage(message);
          }}
        />
      </S.Footer>
    </S.ChatRoot>
  );
};
