import styled, { css } from 'styled-components';

export const Stop = styled.stop<{
  $stopColor: string;
  $stopOpacity?: number;
}>`
  ${({ $stopColor, $stopOpacity }) => css`
    stop-color: ${$stopColor};
    stop-opacity: ${$stopOpacity};
  `};
`;
