import sortBy from 'lodash/sortBy';

import { DisplayRuleStep } from '~/api/trading/trading-schema';

export const getPriceDecimals = (
  price: number,
  rules: DisplayRuleStep[]
): number => {
  let output = 2;
  try {
    const sortedRules = sortBy(rules, 'minimum').reverse();
    for (const element of sortedRules) {
      if (price >= element.minimum) {
        output = element.decimal_digits;
        break;
      }
    }
    return output;
  } catch {
    return output;
  }
};
