import { deviceConstraints, typography } from '@toggle/design-system';
import styled, { css } from 'styled-components';

export const Img = styled.img`
  height: 55px;
  margin-left: 16px;
  @media ${deviceConstraints.desktopSmall} {
    height: 140px;
  }
`;

export const Text = styled.p<{ isLarge?: boolean }>`
  padding-bottom: 8px;
  ${typography.emu};

  @media ${deviceConstraints.desktopSmall} {
    ${props =>
      props.isLarge
        ? css`
            padding-bottom: 16px;
            ${typography.chimp};
          `
        : css`
            ${typography.dingo}
          `}
  }
`;
