import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import * as S from './FaqHelperContent.styles';

export function PrivacyContent() {
  const { t } = useTranslation('helperWidget');
  return (
    <S.FaqContent>
      <Trans
        t={t}
        i18nKey="helperWidget:faq.privacy.content"
        components={[
          <S.List key="list">
            <li />
          </S.List>,
          <S.Link
            decorated
            key="link-a"
            href="https://plaid.com/legal/#consumers"
            rel="noopener noreferrer"
            target="_blank"
          />,
          <S.Link
            decorated
            key="link-b"
            href="https://my.plaid.com/"
            rel="noopener noreferrer"
            target="_blank"
          />,
        ]}
      />
    </S.FaqContent>
  );
}
