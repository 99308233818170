import styled from 'styled-components';

export const Chevron = styled.div<{ isScrolledToEnd: boolean }>`
  position: absolute;
  top: 50%;
  ${props => (props.isScrolledToEnd ? 'left:-16px' : 'right:-16px')};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transform: translateY(-50%);
  transition: all 150ms ease-in-out;
  cursor: pointer;
`;

export const ItemsContainer = styled.ul`
  display: flex;
  align-items: center;
  width: 100%;
  user-select: none;
  touch-action: pan-x;
  background: var(--background-default);
  scrollbar-width: none;
  overflow-x: auto;
  white-space: nowrap;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const Container = styled.div`
  width: 100%;
  position: relative;
`;
