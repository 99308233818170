import { SvgIconNames } from '@toggle/design-system';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { config } from '~/config';
import { publicRoutes } from '~/routes/app-paths';
import {
  gaClickMenuHeader,
  gaMyAccountClickContactUs,
} from '~/shared/utils/ganalytics';
import { useHelper } from '~/stores/use-helper/useHelper';

import { MenuParentItem } from '../default-header/AppHeaderDefault';
import * as D from '../header-my-account-dropdown/HeaderMyAccountDropdown.styles';
import * as S from './HeaderSupportDropdown.styles';

const {
  urls: { supportRoot, webinarLink },
} = config;

export enum SupportDropdownGaName {
  HowToUseThisPage = 'How to use this page',
  LearningCenter = 'Learning Center',
  JoinOurWebinar = 'Join our Webinar',
  ContactUs = 'Contact Us',
}

interface HeaderSupportDropdownProps {
  setIsDropdownMenuOpen: (value: boolean) => void;
}

interface MenuDataType {
  link?: string;
  name: string;
  iconName?: SvgIconNames;
  onClick?: () => void;
  hidden?: boolean;
  gaName: SupportDropdownGaName;
}

export const HeaderSupportDropdown: FC<HeaderSupportDropdownProps> = ({
  setIsDropdownMenuOpen,
}) => {
  const { t } = useTranslation('header');
  const { expandHelper, currentAppViewKey } = useHelper();

  const menuData: MenuDataType[] = [
    {
      name: t('header:secondaryMenu.howToUseThisPage'),
      onClick: expandHelper,
      hidden: !currentAppViewKey,
      gaName: SupportDropdownGaName.HowToUseThisPage,
    },
    {
      link: publicRoutes.learnCenter,
      name: t('header:secondaryMenu.learningCenter'),
      iconName: 'ExternalLink',
      gaName: SupportDropdownGaName.LearningCenter,
    },
    {
      link: webinarLink,
      name: t('header:secondaryMenu.joinOurWebinar'),
      gaName: SupportDropdownGaName.JoinOurWebinar,
    },
    {
      link: supportRoot,
      name: t('header:secondaryMenu.contactUs'),
      onClick: () => gaMyAccountClickContactUs('header'),
      gaName: SupportDropdownGaName.ContactUs,
    },
  ];

  const filteredMenuData = menuData.filter(item => !item.hidden);

  const handleMenuItemClick = (item: MenuDataType) => {
    gaClickMenuHeader(item.gaName, MenuParentItem.Support);
    setIsDropdownMenuOpen(false);
    item.onClick?.();
  };

  return (
    <S.DropdownMenu data-testid="header-support-dropdown-menu">
      <D.DropdownList>
        {filteredMenuData.map((item, index) => (
          <li key={index}>
            <S.DropdownListItem
              as={item.link ? 'a' : 'span'}
              data-testid="external-link"
              href={item.link}
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => handleMenuItemClick(item)}
            >
              <p>{item.name}</p>
              {item.iconName && (
                <S.MenuIcon iconName={item.iconName} size={20} />
              )}
            </S.DropdownListItem>
          </li>
        ))}
      </D.DropdownList>
    </S.DropdownMenu>
  );
};
