import { deviceConstraints, typography } from '@toggle/design-system';
import styled from 'styled-components';

export const BannerText = styled.p`
  padding: 16px 8px;
  border-radius: 4px;
  background-color: var(--base--90);
  width: 100%;
  text-align: center;
  ${typography.dingo};

  @media ${deviceConstraints.tablet} {
    padding: 16px;
  }
`;
