import { queryString } from '@toggle/helpers';
import { useState } from 'react';
import { Location, useLocation, useNavigate } from 'react-router-dom';

import {
  postCheckResetPasswordToken,
  postResetPassword,
  ResetPasswordData,
} from '~/api/auth/auth-services';
import { appPaths, authPaths, queryKeys } from '~/routes/app-paths';
import { APIFetchError } from '~/shared/services/api-fetch/APIFetchError';
import { Tracking } from '~/shared/services/tracking';
import { useAuth } from '~/stores/use-auth/useAuth';

interface AsyncActionState {
  isPending: boolean;
  isSuccess: boolean;
  error: string;
}

interface ResetPasswordHookResult {
  resetState: AsyncActionState;
  checkTokenState: AsyncActionState;
  checkToken: () => Promise<void>;
  resetPassword: (resetData: Omit<ResetPasswordData, 'token'>) => Promise<void>;
}

const initialAsyncState: AsyncActionState = {
  isPending: false,
  isSuccess: false,
  error: '',
};

export function useResetPasswordState(): ResetPasswordHookResult {
  const [resetState, setResetState] = useState(initialAsyncState);
  const [checkTokenState, setCheckTokenState] = useState(initialAsyncState);
  const navigate = useNavigate();
  const location = useLocation();
  const { logout } = useAuth();

  async function reset(passwordData: Omit<ResetPasswordData, 'token'>) {
    setResetState({ ...initialAsyncState, isPending: true });
    const token = getTokenFromUrl(location);
    const result = await postResetPassword({
      password: passwordData.password,
      confirmedPassword: passwordData.confirmedPassword,
      token: token,
      logoutAll: passwordData.logoutAll,
    });

    if (result instanceof Error || result.message !== 'ok') {
      if (result instanceof APIFetchError && result?.data?.[0]) {
        switch (result.data[0].description) {
          case 'token expired': {
            setResetState({ ...initialAsyncState, error: 'expired' });
            return;
          }
          case 'token already used': {
            setResetState({ ...initialAsyncState, error: 'used' });
            return;
          }
          case 'token not found': {
            setResetState({ ...initialAsyncState, error: 'notFound' });
            return;
          }
          default: {
            setResetState({ ...initialAsyncState, error: 'internal' });
            return;
          }
        }
      }
      setResetState({ ...initialAsyncState, error: 'internal' });
    } else {
      setResetState({ ...initialAsyncState, isSuccess: true });
      logout();
      navigate(
        authPaths.login +
          queryString({
            [queryKeys.redirect]: appPaths.myAccount.security,
          })
      );
    }
  }

  async function checkToken() {
    setCheckTokenState({ ...initialAsyncState, isPending: true });
    const token = getTokenFromUrl(location);
    if (!token) {
      setCheckTokenState({ ...initialAsyncState, error: 'notFound' });
      return;
    }
    const result = await postCheckResetPasswordToken(token);

    try {
      if (result instanceof Error) {
        throw result;
      }
      if (result.is_expired) {
        throw new Error('expired');
      }
      if (result.is_used) {
        throw new Error('used');
      }
      if (result.not_found) {
        throw new Error('notFound');
      }
      setCheckTokenState({ ...initialAsyncState, isSuccess: true });
    } catch (e) {
      Tracking.captureException(e as Error);
      setCheckTokenState({ ...initialAsyncState, error: (e as Error).message });
    }
  }

  return {
    resetPassword: reset,
    resetState,
    checkToken,
    checkTokenState,
  };
}

function getTokenFromUrl(location: Location): string {
  const urlParams = new URLSearchParams(location.search);
  return urlParams.get('token') ?? '';
}
