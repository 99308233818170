import { numberRoundedToPrecision } from '@toggle/helpers';
import { select } from 'd3-selection';
import { D3ZoomEvent, zoom, ZoomBehavior, zoomIdentity } from 'd3-zoom';

import { Domain } from '~/types/axis.types';
import {
  BaseChartAPIProps,
  CreateChartOptionsWithColors,
} from '~/types/create.types';
import { isWithinYAxis } from '~/utils/axis/axis-utils';
import { getPaneDividerIndex } from '~/utils/pane/pane-utils';
import { numZoom } from '~/utils/zoom/zoom-utils';

import { ChartStoreReturn } from '../create/chart-store/chartStore';
import { reDraw } from '../create/redraw/redraw';
import {
  dispatchDomainChangeEvent,
  dispatchInsightsInDomainEvent,
} from '../events/events';

let zoomBehavior: ZoomBehavior<HTMLCanvasElement, unknown>;
let hasDomainChanged = false;

const isRightClick = (sourceEvent: MouseEvent) =>
  sourceEvent.button === 2 || sourceEvent.buttons === 2;

const shouldIgnoreEvent = (
  sourceEvent: MouseEvent,
  options: CreateChartOptionsWithColors
) => {
  if (!sourceEvent) {
    return false;
  }

  return isRightClick(sourceEvent) || isWithinYAxis(sourceEvent, options);
};

export const onZoom =
  (chartStore: ChartStoreReturn) =>
  (e: D3ZoomEvent<SVGSVGElement, unknown>) => {
    const previousBase = chartStore.getState().base as BaseChartAPIProps;
    let newBase = previousBase;
    if (shouldIgnoreEvent(e.sourceEvent, previousBase.options)) {
      hasDomainChanged = false;
      return;
    }

    const newDomain = e.transform
      .rescaleX(previousBase.fullXScale)
      .domain() as Domain;
    newDomain[0] = numberRoundedToPrecision(newDomain[0]);
    newDomain[1] = numberRoundedToPrecision(newDomain[1]);

    if (
      e.sourceEvent &&
      (newDomain[0] !== previousBase.domain[0] ||
        newDomain[1] !== previousBase.domain[1])
    ) {
      hasDomainChanged = true;
      newBase = chartStore.getState().updateDomain(newDomain);
      reDraw({ chartStore });
    }

    dispatchInsightsInDomainEvent(newBase);
  };

export const onZoomEnd =
  (chartStore: ChartStoreReturn) =>
  (e: D3ZoomEvent<SVGSVGElement, unknown>) => {
    const base = chartStore.getState().base as BaseChartAPIProps;
    if (
      shouldIgnoreEvent(e.sourceEvent, base.options) ||
      (e.sourceEvent && !hasDomainChanged)
    ) {
      return;
    }
    hasDomainChanged = false;

    dispatchDomainChangeEvent(base, !e.sourceEvent);
  };

export const initZoom = (
  canvasElement: HTMLCanvasElement,
  chartStore: ChartStoreReturn
) => {
  zoomBehavior = zoom<HTMLCanvasElement, unknown>().filter(e => !e.shiftKey);
  zoomBehavior.on('zoom', onZoom(chartStore));
  zoomBehavior.on('end', onZoomEnd(chartStore));
  zoomBehavior.filter(
    e =>
      getPaneDividerIndex(
        e,
        chartStore.getState().base as BaseChartAPIProps
      ) === undefined
  );
  updateZoom(canvasElement, chartStore);
  select(canvasElement).call(zoomBehavior);
  select(canvasElement).on('dblclick.zoom', null);
};

export const removeZoom = () => {
  zoomBehavior.on('zoom', null);
};

export const updateZoom = (
  canvasElement: HTMLCanvasElement,
  chartStore: ChartStoreReturn
) => {
  const { options, fullXScale, maxChartZoom, x } = chartStore.getState()
    .base as BaseChartAPIProps;
  const viewportWidth = options.width - options.gutters.y;
  const viewportHeight = options.height - options.gutters.x;
  const { zoom: zoomNumber, offset } = numZoom(fullXScale, x.xScale);

  const zoomTransform = zoomIdentity.scale(zoomNumber).translate(offset, 0);

  zoomBehavior
    .translateExtent([
      [0, 0],
      [viewportWidth, viewportHeight],
    ])
    .extent([
      [0, 0],
      [viewportWidth, viewportHeight],
    ])
    .scaleExtent([zoomIdentity.k, maxChartZoom]);

  select(canvasElement).call(zoomBehavior.transform, zoomTransform);
};
