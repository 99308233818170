import { ENDPOINT_URLS } from '~/global/toggle-api';
import { APIFetch } from '~/shared/services/api-fetch/APIFetch';

import { BookmarkStatusResponse } from './BookmarkStatusService.d';

enum BookMarkAction {
  SAVE = 'put',
  DELETE = 'delete',
}

const fetchBookmark = (articleId: string, method: string) =>
  APIFetch<BookmarkStatusResponse>(
    `${ENDPOINT_URLS.ARTICLE_BASE_V1}/${articleId}/bookmark`,
    {
      method,
    }
  );

export const addArticleToBookmarks = (articleId: string) =>
  fetchBookmark(articleId, BookMarkAction.SAVE);

export const removeArticleFromBookmarks = (articleId: string) =>
  fetchBookmark(articleId, BookMarkAction.DELETE);
