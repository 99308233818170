import { BaseChartAPIProps } from '~/types/create.types';

import { ChartStoreReturn } from '../create/chart-store/chartStore';
import { reDraw } from '../create/redraw/redraw';
import { dispatchPriceHoverEvent } from '../events/events';

export const onMouseLeave = (chartStore: ChartStoreReturn) => {
  const base = chartStore.getState().base as BaseChartAPIProps;

  base.panes.forEach(pane => {
    pane.chartAssetData.forEach(item => {
      dispatchPriceHoverEvent({
        base,
        entity: item.entity,
        value: undefined,
        previousValue: undefined,
      });
    });
  });
  reDraw({ chartStore });
};
