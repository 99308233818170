import { useUser } from '~/stores/use-user/useUser';

import {
  PromoMetadata,
  verifyPromoCode,
} from '../../shared/services/promo-code/promo-code-service';
import { create } from '../create-store/createStore';

type State = {
  promoMetadata: PromoMetadata;
  promoCode: string;
};

type Actions = {
  initialize: () => Promise<void>;
  setPromoData: (state: Partial<State>) => void;
};

type PromoCodeStore = State & Actions;

const initialState = {
  promoMetadata: {},
  promoCode: '',
};

export const usePromoCodeStore = create<PromoCodeStore>(set => ({
  ...initialState,
  initialize: async () => {
    try {
      const userPromoCode = useUser.getState().user?.promo_code;

      if (userPromoCode) {
        const response = await verifyPromoCode(userPromoCode);

        if (response instanceof Error) {
          throw response;
        }

        set({ promoMetadata: response.metadata, promoCode: userPromoCode });
      }
    } catch (e) {
      set({ promoMetadata: {} });
    }
  },
  setPromoData: (promoData: Partial<State>) =>
    set(s => ({ ...s, ...promoData })),
}));
