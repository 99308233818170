import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  DominoScenarioResponse,
  ScenarioCommanderResponse,
} from '~/api/scenario-commander/scenario-commander-schema';

import { PastEpisodesTable } from '../past-episodes-table/PastEpisodesTable';
import * as S from './PastEpisodes.styles';

export interface PastEpisodesProps {
  domino: DominoScenarioResponse;
  scenario: ScenarioCommanderResponse;
  condition: string;
}

export const PastEpisodes = ({
  domino,
  scenario,
  condition,
}: PastEpisodesProps) => {
  const { t } = useTranslation('scenarioCommander');

  if (!domino) {
    return null;
  }

  const [firstEntity] = scenario.entities;
  const pastEpisodes = domino[firstEntity.tag]?.episodes[0]?.dates ?? [];

  return (
    <S.PastEpisodesRoot data-testid="past-episodes">
      <div>
        <S.Title>{t('scenarioCommander:pastEpisodes.condition.title')}</S.Title>
        <S.Description>{condition}</S.Description>
      </div>
      <div>
        <S.Title>{t('scenarioCommander:pastEpisodes.episodes.title')}</S.Title>
        <S.Description>
          {t('scenarioCommander:pastEpisodes.episodes.description')}
        </S.Description>
      </div>
      <PastEpisodesTable pastEpisodes={pastEpisodes} />
    </S.PastEpisodesRoot>
  );
};
