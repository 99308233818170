import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { gaExploreClearAllFilters } from '~/shared/utils/ganalytics';

import * as S from './ClearAllButton.styles';

interface ClearAllButtonProps {
  onClick: () => void;
}

export const ClearAllButton: FC<ClearAllButtonProps> = ({ onClick }) => {
  const { t } = useTranslation('explore');

  return (
    <S.Button
      data-testid="clear-all-button"
      iconName="Close"
      variant="secondary"
      label={t('explore:header.clearAllLabel')}
      onClick={() => {
        gaExploreClearAllFilters();
        onClick();
      }}
    />
  );
};
