import { ChartAssetData, ResampleIntervals, SeriesType } from '@toggle/chart';
import { Entity } from '@toggle/toggle';
import React, { RefObject } from 'react';

import { checkIsIndicator } from '../../utils/entity-utils/entity-utils';
import { LegendItem } from '../legend-item/LegendItem';
import * as S from './Legend.styles';

export interface LegendProps {
  chartData: ChartAssetData[];
  selectedResample: ResampleIntervals;
  lineColorArray: string[];
  removeAsset: (defaultSnake: string) => void;
  onChangeResample: () => void;
  onChangeTicker: (isPrimary: boolean, entity: Entity) => void;
  onHideAsset: (entity: Entity, isHidden: boolean) => void;
  onChangeLineColor: (colorId: number, defaultSnake: string) => void;
  canvasRef: RefObject<HTMLCanvasElement>;
  selectedSeriesType?: SeriesType;
}

export const Legend = ({
  chartData,
  selectedResample,
  lineColorArray,
  removeAsset,
  onChangeResample,
  onChangeTicker,
  onHideAsset,
  onChangeLineColor,
  canvasRef,
  selectedSeriesType,
}: LegendProps) => {
  return (
    <S.LegendContainer data-testid="legend">
      {chartData.map((data, i) => (
        <LegendItem
          key={data.entity.default_snake}
          entity={data.entity}
          isIndicator={checkIsIndicator(data.entity)}
          lastTSPrice={data.ts[data.ts.length - 1]}
          resample={selectedResample}
          isPrimary={i === 0}
          legendColor={data.lineColorOverride}
          multiplePlotted={chartData.length > 1}
          onRemoveItem={removeAsset}
          onChangeResample={onChangeResample}
          onChangeTicker={onChangeTicker}
          onHideAsset={onHideAsset}
          onChangeLineColor={id =>
            onChangeLineColor(id, data.entity.default_snake)
          }
          colorArray={lineColorArray}
          canvasRef={canvasRef}
          selectedSeriesType={selectedSeriesType}
          isHidden={!!data.isHidden}
          snakeMeta={data.snakeMeta}
        />
      ))}
    </S.LegendContainer>
  );
};
