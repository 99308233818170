import { Icon } from '@toggle/design-system';
import * as React from 'react';

import * as S from './Breadcrumbs.styles';

export interface Breadcrumb {
  id?: string;
  text?: React.ReactNode | string;
  onClick?: (event: React.MouseEvent<HTMLElement>, id?: string) => void;
  href?: string;
  target?: string;
  isHome?: boolean;
}

interface BreadcrumbsProps {
  breadcrumbs: Breadcrumb[];
  className?: string;
  breadcrumbRenderer?: (props: Breadcrumb) => React.ReactNode;
}

export const Breadcrumbs = ({
  breadcrumbs,
  className,
  breadcrumbRenderer,
}: BreadcrumbsProps) => {
  return (
    <S.List className={className} data-testid="breadcrumbs-list">
      {breadcrumbs.map((breadcrumb, i) =>
        breadcrumbRenderer ? (
          breadcrumbRenderer(breadcrumb)
        ) : (
          <li key={i}>
            {breadcrumb.onClick || breadcrumb.href ? (
              <a
                href={breadcrumb.href}
                target={breadcrumb.target}
                onClick={e => breadcrumb?.onClick?.(e, breadcrumb.id)}
              >
                {breadcrumb.text}
              </a>
            ) : (
              <span>{breadcrumb.text}</span>
            )}
            {i < breadcrumbs.length - 1 && (
              <Icon
                iconName="ChevronLightRight"
                fillColor="var(--base--20)"
                size={14}
              />
            )}
          </li>
        )
      )}
    </S.List>
  );
};
