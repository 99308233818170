import { useState } from 'react';

interface NetworkStatus {
  isOnline: boolean;
  checkNetworkStatus: () => Promise<boolean>;
}

type UseNetworkStatus = () => NetworkStatus;

export const useNetworkStatus: UseNetworkStatus = () => {
  const [isOnline, setIsOnline] = useState(true);

  const checkIsOnline = async () => {
    if (!window.navigator.onLine) {
      return false;
    }
    const url = new URL(window.location.origin);
    url.searchParams.set('rand', Date.now().toString());

    try {
      const response = await fetch(url.toString(), {
        method: 'HEAD',
        cache: 'no-cache',
      });

      return response.ok;
    } catch {
      return false;
    }
  };

  const checkNetworkStatus = async () => {
    const online = await checkIsOnline();
    setIsOnline(online);
    return online;
  };

  return { isOnline, checkNetworkStatus };
};
