import { TFunction } from 'i18next';
import { StoreApi } from 'zustand';

import { jointInsightGtkMapping } from '../../../asset-overview/components/sidebar/components/rocketship-tab/helpers/RocketshipTabHelpers';
import { fetchInsightCards } from '../../../hooks/use-article-insight-cards/useInsightCards';
import { fetchGtK } from '../../../hooks/use-gtk/useGtK';
import { ExperimentStoreState } from '../../useExperimentStore';

type JointInsightGtkSliceType = ExperimentStoreState['jointInsightsGtk'];

export const jointInsightGtkSlice = (
  set: StoreApi<JointInsightGtkSliceType>['setState']
): ExperimentStoreState['jointInsightsGtk'] => ({
  loading: false,
  data: undefined,
  error: undefined,
  fetch: async (entity: string, t: TFunction, articleId?: string) => {
    try {
      const [insights, gtk] = await Promise.all([
        fetchInsightCards(entity, articleId),
        fetchGtK(entity),
      ]);
      if (insights instanceof Error) {
        throw insights;
      } else if (gtk instanceof Error) {
        throw gtk;
      }

      set({
        loading: false,
        error: undefined,
        data: jointInsightGtkMapping(insights, gtk, t),
      });
    } catch (e) {
      set({
        loading: false,
        error: e as Error,
        data: undefined,
      });
    }
  },
});
