import { Domain } from './axis.types';
import { ChartPane, YAxisSize } from './create.types';
import { InsightsInDomain, TimeSeriesItem } from './timeseries.types';

export const DOMAIN_CHANGE_CUSTOM_EVENT = 'domainChange';
export const INSIGHTS_IN_DOMAIN_CHANGE_CUSTOM_EVENT = 'insightsInDomainChange';
export const PRICE_HOVER_CUSTOM_EVENT = 'priceHover';
export const GUTTER_SIZE_CUSTOM_EVENT = 'gutterSize';
export const RIGHT_CLICK_EVENT = 'rightClick';
export const HOVER_CUSTOM_EVENT = 'hover';

export interface InsightsInDomainDetail {
  insightsInDomain?: InsightsInDomain[];
  helperText: {
    y: number;
    right: number;
  } | null;
}

export interface DomainChangeDetail {
  domain: Domain;
  roundedDomain: Domain;
  isSameDomain: boolean;
}

export interface InsightsInDomainEvent
  extends CustomEvent<InsightsInDomainDetail> {
  type: typeof INSIGHTS_IN_DOMAIN_CHANGE_CUSTOM_EVENT;
}

export interface DomainChangeEvent extends CustomEvent<DomainChangeDetail> {
  type: typeof DOMAIN_CHANGE_CUSTOM_EVENT;
}

export interface PriceHover {
  currentPrice?: TimeSeriesItem;
  previousPrice?: TimeSeriesItem;
  entitySnake: string;
}

export interface PriceHoverEvent extends CustomEvent<PriceHover> {
  type: typeof PRICE_HOVER_CUSTOM_EVENT;
}

export interface GutterSize {
  x: number;
  y: number;
  paneLimitExceeded: boolean;
  panes: ChartPane[];
  yAxisSizes: YAxisSize[];
}

export interface GutterSizeEvent extends CustomEvent<GutterSize> {
  type: typeof GUTTER_SIZE_CUSTOM_EVENT;
}

export interface RightClickEvent {
  mouseX: number;
  mouseY: number;
  isWithinYAxis?: boolean;
}

export interface PaneDetails {
  pane: ChartPane;
  index: number;
  isPrimary: boolean;
}

export interface HoverEvent {
  isWithinYAxis: boolean;
  paneDetails?: PaneDetails;
}
