import { StoreApi } from 'zustand';

import { PrimaryMethod } from '~/api/entities/entity-schema';
import { PriceUpdate } from '~/shared/services/price-ws/PriceWS';
import { create } from '~/stores/create-store/createStore';
import { livePriceWebSocket } from '~/stores/live-price-websocket/livePriceWebSocket';
import {
  getPriceChange,
  getTickerForLivePrice,
  isFX,
  PriceStatus,
} from '~/views/analyze/utils/asset-box/asset-box-helpers';

import { MappedEntity } from '../../use-entities';

export interface LivePriceChange {
  status: PriceStatus;
  change: string;
  proportionChange: string;
}

export interface LivePriceInfo {
  price: number;
  priceChange: LivePriceChange;
  isCurrency: boolean;
  time: string;
  lastKnownPrice: number;
  isLive: boolean;
  priceBefore: number;
}

export interface LivePriceData {
  [key: string]: LivePriceInfo;
}

export type SubscribeFn = (priceEntities: PriceEntity[]) => () => void;

interface LivePriceState {
  livePriceData: LivePriceData;
  subscribe: SubscribeFn;
}

export interface PriceEntity {
  entity: MappedEntity;
  lastKnownPrice: number;
  priceBefore: number;
  time?: string;
}

const anythingButLetter = /[^a-z]/g;

export const createLivePriceData = ({
  entity,
  lastKnownPrice,
  priceBefore,
  time = '',
}: PriceEntity) => ({
  price: lastKnownPrice,
  isCurrency: isFX(entity.sub_class),
  priceChange: getPriceChange({
    lastPrice: priceBefore,
    newPrice: lastKnownPrice,
    isCurrency: isFX(entity.sub_class),
    isPrice: entity.primary_method === PrimaryMethod.Price,
  }).priceChange,
  lastKnownPrice,
  time,
  isLive: false,
  priceBefore,
});

const priceUpdater =
  (set: StoreApi<LivePriceState>['setState']) =>
  ({ price, time, ticker }: PriceUpdate) => {
    set(state => {
      const stateTicker = ticker.toLowerCase().replace(anythingButLetter, '');
      const tickerData = state.livePriceData[stateTicker];

      if (tickerData && tickerData.price !== price) {
        const lastKnownPrice = tickerData.lastKnownPrice;

        const { priceChange } = getPriceChange({
          lastPrice: lastKnownPrice,
          newPrice: price,
          isCurrency: tickerData.isCurrency,
        });

        return {
          ...state,
          livePriceData: {
            ...state.livePriceData,
            [stateTicker]: {
              price,
              priceChange,
              lastKnownPrice,
              isLive: true,
              time,
              priceBefore: tickerData.priceBefore,
              isCurrency: tickerData.isCurrency,
            },
          },
        };
      }

      return state;
    });
  };

const subscribe = ({
  priceEntities,
  get,
  set,
}: {
  priceEntities: PriceEntity[];
  set: StoreApi<LivePriceState>['setState'];
  get: StoreApi<LivePriceState>['getState'];
}) => {
  const livePriceData = get().livePriceData;
  const { data: newLivePriceData, tickers } = priceEntities.reduce(
    ({ data, tickers }, priceEntity) => {
      const entity = priceEntity.entity;
      const ticker = entity.ticker;
      const tickerForLivePrice = getTickerForLivePrice(entity);

      if (!data[ticker] && tickerForLivePrice) {
        return {
          data: {
            ...data,
            [priceEntity.entity.ticker]: createLivePriceData(priceEntity),
          },
          tickers: tickerForLivePrice
            ? [...tickers, tickerForLivePrice]
            : tickers,
        };
      }
      return { data, tickers };
    },
    { data: livePriceData, tickers: [] as string[] }
  );

  const updateHandler = priceUpdater(set);
  const { subscribe: subscribeWS } = livePriceWebSocket.getState();
  subscribeWS({ tickers, updateHandler });

  set({ livePriceData: { ...livePriceData, ...newLivePriceData } });
  return unsubscribe({ tickers, priceEntities, get, set });
};

const unsubscribe =
  ({
    tickers,
    priceEntities,
    get,
    set,
  }: {
    tickers: string[];
    priceEntities: PriceEntity[];
    get: StoreApi<LivePriceState>['getState'];
    set: StoreApi<LivePriceState>['setState'];
  }) =>
  () => {
    const { livePriceData } = get();
    const { unsubscribe: unsubscribeWS } = livePriceWebSocket.getState();

    if (!tickers.length) {
      return;
    }

    unsubscribeWS(tickers);

    const withoutTickers = priceEntities.reduce<LivePriceData>(
      (livePriceData, { entity }) => {
        const { [entity.ticker]: excluded, ...rest } = livePriceData;
        return rest;
      },
      livePriceData
    );

    set({ livePriceData: withoutTickers });
  };

export const useLivePriceStore = create<LivePriceState>((set, get) => ({
  livePriceData: {},
  subscribe: (priceEntities: PriceEntity[]) =>
    subscribe({ get, set, priceEntities }),
}));
