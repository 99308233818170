import { format } from 'date-fns';
import find from 'lodash/find';
import { StoreApi } from 'zustand';

import {
  BankCard,
  Subscription,
  UserPaymentMethod,
  UserRole,
  UserSubscription,
} from '~/api/subscription/subscription-schema';
import {
  deleteBankCard,
  getBankCard,
  getSubscription,
  postUserSubscription,
  PostUserSubscriptionProps,
} from '~/api/subscription/subscription-service';
import { RBACRules } from '~/global/rbac-rules/rbac-rules';
import { AppRouteSections } from '~/routes/app-paths';
import { APIResponse } from '~/shared/services/api-fetch/constants';
import { FetchError } from '~/shared/services/fetch/FetchErrors';
import { Tracking } from '~/shared/services/tracking';

import { create } from '../create-store/createStore';

const RESTRICTED_SUBSCRIPTION_NAMES = ['LegacyPro'];

export const isExpired = (year: number, month: number): boolean => {
  const today = new Date();
  const someday = new Date();
  someday.setFullYear(year, month, 1);

  return someday < today;
};

export interface SubscriptionStore {
  isError: boolean;
  isFetching: boolean;
  userSubscription: UserSubscription;
  subscriptions: Subscription[] | null;
  cardError: boolean;
  nextPaymentDateFormatted?: string;
  subscriptionChanged: boolean;
  hasActivePromo: boolean;
  setError: (isError: boolean) => void;
  card: BankCard | null;
  initialize: () => Promise<void>;
  subscribe: ({
    productId,
    priceId,
  }: PostUserSubscriptionProps) => Promise<void>;
  getCard: () => Promise<void>;
  getSubscriptionByRole: (role: UserRole) => Subscription | undefined;
  showSubscriptionPanel: () => boolean;
  isBasic: () => boolean;
  isCopilot: () => boolean;
  isPro: () => boolean;
  isFromIb: () => boolean;
  isTrialUser: () => boolean;
  isSubscribedViaApple: () => boolean;
  hasPermission: (page: AppRouteSections) => boolean;
  downgradeTo: (role: UserRole) => Promise<void>;
  removeCard: () => Promise<void>;
}

const getUserCard = async (set: StoreApi<SubscriptionStore>['setState']) => {
  try {
    const card = await getBankCard();
    if (card instanceof Error) {
      throw card;
    }
    set({ card, cardError: false });
  } catch (e) {
    if (APIResponse.NOT_FOUND !== (e as FetchError).status) {
      Tracking.captureException(e as Error);
    } else {
      set({ cardError: true });
    }
  }
};

function isUserHasActivePromo(userSubscription: UserSubscription) {
  return (
    !!userSubscription.promo || !!userSubscription.upcoming_subscription?.promo
  );
}

export const findSubscriptionByName = (
  subscriptions: Subscription[],
  subscriptionName?: string
) => {
  return find(subscriptions, {
    name: subscriptionName,
  });
};

export const getNextPaymentDateFormatted = (
  userSubscription: UserSubscription
) => {
  const { starting_on } = userSubscription?.upcoming_subscription ?? {};

  return starting_on && format(new Date(starting_on), 'PPP');
};

const getUserSubscriptions = async (
  set: StoreApi<SubscriptionStore>['setState']
) => {
  try {
    const subscription = await getSubscription();
    if (subscription instanceof Error) {
      throw subscription;
    }

    set({
      nextPaymentDateFormatted: getNextPaymentDateFormatted(subscription.user),
      subscriptions: subscription.available,
      userSubscription: subscription.user,
      hasActivePromo: !isUserHasActivePromo(subscription.user),
    });
  } catch (e) {
    Tracking.captureException(e as Error);
  }
};

const initialize = async (set: StoreApi<SubscriptionStore>['setState']) => {
  await getUserCard(set);
  await getUserSubscriptions(set);
};

const subscribe = async (
  { productId, priceId, promoCode }: PostUserSubscriptionProps,
  set: StoreApi<SubscriptionStore>['setState']
) => {
  try {
    set({
      isFetching: true,
      isError: false,
    });

    const userSubscription = await postUserSubscription({
      productId,
      priceId,
      promoCode,
    });

    if (userSubscription instanceof Error) {
      throw userSubscription;
    }

    set(state => ({
      nextPaymentDateFormatted: getNextPaymentDateFormatted(userSubscription),
      userSubscription,
      subscriptionChanged:
        state.userSubscription?.name !== userSubscription.name,
      hasActivePromo: !isUserHasActivePromo(userSubscription),
    }));
  } catch (e) {
    if (e instanceof FetchError && e.status !== APIResponse.PAYMENT_REQUIRED) {
      Tracking.captureException(e);
    }
    throw e;
  } finally {
    set({
      isFetching: false,
    });
  }
};

export const useSubscription = create<SubscriptionStore>((set, get) => ({
  isError: false,
  isFetching: false,
  userSubscription: {} as UserSubscription,
  subscriptions: null,
  card: null,
  plan: null,
  subscriptionChanged: false,
  hasActivePromo: false,
  cardError: false,
  setError: isError =>
    set({
      isError,
    }),
  subscribe: props => subscribe(props, set),
  initialize: async () => initialize(set),
  getCard: async () => getUserCard(set),
  getSubscriptionByRole: (role: UserRole) =>
    find(get().subscriptions, {
      role,
    }),
  showSubscriptionPanel: () => {
    const subscriptionName = get().userSubscription?.name;
    return (
      !!subscriptionName &&
      !RESTRICTED_SUBSCRIPTION_NAMES.includes(subscriptionName)
    );
  },
  isBasic: () => get().userSubscription.role === UserRole.Basic,
  isCopilot: () => get().userSubscription.role === UserRole.Copilot,
  isPro: () => get().userSubscription.role === UserRole.Pro,
  isFromIb: () =>
    get().userSubscription.payment_method === UserPaymentMethod.ThirdParty,
  isTrialUser: () => get().userSubscription.trial && !get().isFromIb(),
  isSubscribedViaApple: () =>
    get().userSubscription.payment_method === UserPaymentMethod.AppStore ||
    get().userSubscription.upcoming_subscription?.payment_method ===
      UserPaymentMethod.AppStore,
  hasPermission: (page: AppRouteSections) => {
    const role = get().userSubscription.role;
    return RBACRules[role].includes(page);
  },
  downgradeTo: async (role: UserRole) => {
    const { getSubscriptionByRole, subscribe, setError } = get();
    const planFromRole = getSubscriptionByRole(role);
    if (!planFromRole) {
      return;
    }

    try {
      const priceId = planFromRole.prices[0].id;
      await subscribe({ productId: planFromRole.id, priceId });
    } catch (error) {
      setError(true);
    }
  },
  removeCard: async () => {
    try {
      await deleteBankCard();
      set({ card: null, cardError: false });
    } catch (e) {
      set({ cardError: true });
    }
  },
}));
