import { decimalCount, getCurrencySymbol } from '@toggle/helpers';

import {
  BuyingPowerResponse,
  DisplayRuleStep,
  PlaceOrderResponse,
  PreviewResponse,
} from '~/api/trading/trading-schema';
import {
  getBuyingPower,
  OrderType,
  postOrder,
  PostOrderPayload,
  postPreviewOrder,
  postReplyOrder,
  ReplyOrderPayload,
} from '~/api/trading/trading-service';
import { OptionalN } from '~/declarations/standard';
import { APIResponse } from '~/shared/services/api-fetch';
import { FetchError } from '~/shared/services/fetch/FetchErrors';
import { create } from '~/stores/create-store/createStore';
import { useGlobalSearch } from '~/stores/use-global-search/useGlobalSearch';
import { Portfolio } from '~/stores/use-portfolio/usePortfolio';
import { TradingProvider } from '~/stores/use-portfolio/utils/use-portfolio-utils';

import { getPriceDecimals } from '../shared/get-price-decimals/getPriceDecimals';

export interface Order {
  securityId: string;
  quantity: number;
  ticker: string;
  side: OrderSide;
  currency: string;
  currencySymbol: string;
  price: number;
  expiration: Expiration;
  preview?: PreviewResponse;
}

export interface Transaction {
  order: Order;
  priceDisplayRule: DisplayRuleStep[];
  brokerId: TradingProvider;
  type: OrderType;
}

export enum OrderSide {
  BUY = 'buy',
  SELL = 'sell',
}

export enum Expiration {
  Day = 'day',
  GoodTillCancel = 'good_till_cancel',
  ImmediateOrCancel = 'immediate_or_cancel',
  Open = 'open',
  Close = 'close',
}

export enum OrderStatus {
  PENDING = 'pending',
  FILLED = 'filled',
  PARTIALLY_FILLED = 'partially_filled',
  CANCELLED = 'cancelled',
}

export enum TradingErrors {
  UntradableAsset = 'UntradableAsset',
  ApiError = 'ApiError',
  InvalidTransaction = 'InvalidTransaction',
}

export interface TradingOrder {
  id: string;
  security_id: string;
  exchange: string;
  currency: string;
  commission_currency: string;
  remaining_quantity: number;
  filled_quantity: number;
  status: OrderStatus;
  type?: OrderType;
  ticker: string;
  side: OrderSide;
  limit_price: OptionalN<number>;
  average_price: OptionalN<number>;
  total: number;
  commission: number;
  expiration: OptionalN<Expiration>;
  updated_at: string;
}

interface CreateOrderProps {
  brokerId: TradingProvider;
  portfolio: Portfolio;
  securityId: string;
  ticker: string;
  currency: string;
  side: OrderSide;
  priceDisplayRule: DisplayRuleStep[];
}

export interface OrderSuccess {
  data: PlaceOrderResponse;
  error: null;
}

export interface OrderError {
  error: string;
  message?: string;
}
export interface PreviewSuccess {
  error: null;
  data: PreviewResponse;
}

export interface BuyingPowerSuccess {
  error: null;
  data: BuyingPowerResponse;
}

interface UseTransaction {
  transaction: Transaction;
  error?: string;
  portfolio: OptionalN<Portfolio>;
  processing: boolean;
  isViewOpen: boolean;
  buyingPower: OptionalN<BuyingPowerResponse>;
  createOrder: (props: CreateOrderProps) => void;
  updateOrder: (props: { type: OrderType; order?: Partial<Order> }) => void;
  previewOrder: () => Promise<PreviewSuccess | OrderError>;
  getBuyingPower: () => Promise<void>;
  submitOrder: () => Promise<OrderSuccess | OrderError>;
  hideTradingView: () => void;
  isValidTransaction: () => boolean;
  replyOrder: (
    props: Omit<ReplyOrderPayload, 'login_id'>
  ) => Promise<{ error: OptionalN<string> }>;
}

export const validateTransaction = (transaction: Transaction) => {
  const { order, type } = transaction;
  if (order.preview?.initial_margin && order.preview.initial_margin < 0) {
    return false;
  }

  const priceDecimals = getPriceDecimals(
    order.price,
    transaction.priceDisplayRule
  );

  if (
    decimalCount(order.price) > priceDecimals ||
    decimalCount(order.quantity) > priceDecimals
  ) {
    return false;
  }

  const validOrder = !!(
    order.securityId &&
    order.ticker &&
    order.side &&
    order.quantity &&
    order.currency &&
    order.expiration
  );
  const validLimitOrder = !!(validOrder && order.price);
  if (type === OrderType.Limit && validLimitOrder) {
    return true;
  }

  if (type === OrderType.Market && validOrder) {
    return true;
  }
  return false;
};

export const defaultTransaction: Transaction = {
  order: {
    securityId: '',
    side: OrderSide.BUY,
    ticker: '',
    currency: '',
    currencySymbol: '',
    price: 0,
    quantity: 0,
    expiration: Expiration.Day,
  },
  priceDisplayRule: [],
  brokerId: TradingProvider.Ib,
  type: OrderType.Market,
};

export const useTransaction = create<UseTransaction>((set, get) => ({
  transaction: defaultTransaction,
  error: undefined,
  processing: false,
  isViewOpen: false,
  portfolio: null,
  buyingPower: null,
  hideTradingView: () =>
    set({ isViewOpen: false, transaction: defaultTransaction }),
  createOrder: ({
    brokerId,
    currency,
    securityId,
    side,
    ticker,
    portfolio,
    priceDisplayRule,
  }) => {
    useGlobalSearch.getState().closeSearch();
    set(s => ({
      transaction: {
        type: OrderType.Market,
        brokerId,
        order: {
          ...s.transaction.order,
          securityId,
          side,
          ticker,
          currency,
          currencySymbol: getCurrencySymbol(currency),
        },
        priceDisplayRule,
      },
      portfolio,
      isViewOpen: true,
    }));
  },
  updateOrder: ({ type, order }) => {
    set(state => ({
      transaction: {
        ...state.transaction,
        type,
        order: {
          ...state.transaction?.order,
          ...order,
        },
      },
    }));
  },
  previewOrder: async () => {
    const { portfolio, transaction, updateOrder } = get();

    if (!validateTransaction(transaction) || !portfolio) {
      return { error: TradingErrors.InvalidTransaction };
    }

    const { external_id: accountId, login_id: loginId } = portfolio;
    const { order, type, brokerId } = transaction;
    const { securityId, price, currencySymbol, ...orderRest } = order;
    const mappedOrder = {
      security_id: securityId,
      login_id: loginId,
      ...orderRest,
    };

    try {
      const request = await postPreviewOrder(
        { brokerId, accountId, type },
        {
          ...mappedOrder,
          ...(type === OrderType.Limit && { price }),
        }
      );

      if (request instanceof Error) {
        throw request;
      }

      updateOrder({
        type,
        order: { preview: request },
      });

      return { error: null, data: request };
    } catch (e) {
      if (e instanceof FetchError) {
        if (e.status === APIResponse.BAD_REQUEST) {
          return { error: TradingErrors.UntradableAsset };
        }

        if (e.data.message) {
          return { error: e.data.message };
        }
      }

      return { error: TradingErrors.ApiError };
    }
  },
  getBuyingPower: async () => {
    const { brokerId } = get().transaction;
    const { external_id: accountId, login_id: loginId } = get()
      .portfolio as Portfolio;
    const response = await getBuyingPower({ brokerId, accountId, loginId });
    if (!(response instanceof Error)) {
      set({ buyingPower: response });
    }
  },
  submitOrder: async () => {
    const { portfolio, transaction } = get();

    if (!validateTransaction(transaction) || !portfolio) {
      return { error: TradingErrors.InvalidTransaction };
    }

    const { external_id: accountId, login_id: loginId } = portfolio;
    const { order, brokerId, type } = transaction;
    const { securityId, price, ...orderRest } = order;
    const mappedOrder: PostOrderPayload = {
      security_id: securityId,
      login_id: loginId,
      price,
      ...orderRest,
    };

    try {
      const request = await postOrder(
        { brokerId, accountId, type },
        mappedOrder
      );

      if (request instanceof Error) {
        throw request;
      }

      if (!('confirmation_id' in request)) {
        set({
          transaction: defaultTransaction,
        });
      }

      return { error: null, data: request };
    } catch (e) {
      if (e instanceof FetchError) {
        if (e.status === APIResponse.BAD_REQUEST) {
          return {
            error: TradingErrors.UntradableAsset,
            message: e.data?.message,
          };
        }

        if (e.status === APIResponse.SERVER_ERROR) {
          return { error: TradingErrors.ApiError, message: e.data?.message };
        }
      }

      return { error: TradingErrors.ApiError };
    }
  },
  isValidTransaction: () => {
    return validateTransaction(get().transaction);
  },
  replyOrder: async ({ confirmation_id, confirm = true }) => {
    const brokerId = get().transaction.brokerId;
    try {
      const response = await postReplyOrder(brokerId, {
        login_id: (get().portfolio as Portfolio).login_id,
        confirmation_id,
        confirm,
      });
      if (response instanceof Error) {
        throw response;
      }
      return {
        error: null,
      };
    } catch {
      return {
        error: TradingErrors.ApiError,
      };
    } finally {
      set({
        transaction: defaultTransaction,
      });
    }
  },
}));
