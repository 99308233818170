import { Icon, SvgIconNames } from '@toggle/design-system';
import React, { FC } from 'react';

import { ExploreFilterOption } from '~/api/explore-filter/explore-filter-schema';
import { OptionalU } from '~/declarations/standard';
import { MODAL_TYPES } from '~/global/paywall/services/modal-types/modalTypes';
import { usePaywallStore } from '~/global/paywall/stores/usePaywallStore';
import { CheckBoxGroup } from '~/shared/components/checkbox-group';
import { Filter as FilterType } from '~/views/explore/store/use-explore-filters';

import * as S from './Filter.styles';

interface HighlightBehaviourProps {
  ref: React.RefObject<HTMLDivElement>;
  onMouseOver: (e: React.MouseEvent) => void;
  onMouseLeave: () => void;
}

export interface FilterProps {
  type: string;
  option: ExploreFilterOption;
  highlightBehaviour: HighlightBehaviourProps | null;
  handleChange: (filter: FilterType) => void;
  filters: FilterType[];
  className?: string;
}

export const iconNameTranslate = (
  iconName: OptionalU<string>
): OptionalU<SvgIconNames> => {
  switch (iconName) {
    case 'star':
      return 'Rating';
    case 'arrow-up':
      return 'ArrowUp';
    case 'arrow-down':
      return 'ArrowDown';
    case 'check':
      return 'Checkmark';
    case 'chart-pie':
      return 'ChartPie';
    case 'watchlist':
      return 'Watchlist';
    case 'briefcase':
      return 'Briefcase';
    case 'fire':
      return 'Fire';
    case 'bookmark':
      return 'BookmarkFill';
    case 'lock':
      return 'Lock';
  }
  return undefined;
};

export const Filter: FC<FilterProps> = ({
  type,
  option,
  filters,
  highlightBehaviour,
  handleChange,
  className,
}) => {
  const openModal = usePaywallStore(state => state.openModal);

  const handleFilter = (key: string) => {
    const currentOption = option.options.find(o => o.key === key);
    if (!currentOption) {
      return;
    }

    if (currentOption?.locked || option?.locked) {
      openModal(MODAL_TYPES.EXPLORE_FILTERS);
      return;
    }

    handleChange({
      type,
      key: currentOption.key,
      name: currentOption.name,
      icon: currentOption.icon,
      displayType: option.type,
    });
  };

  const getFilters = (filters: FilterType[], type: string) => {
    return filters.filter(f => type === f.type).map(({ key }) => key);
  };

  return (
    <S.Filter className={className}>
      <div {...highlightBehaviour}>
        <S.FilterGroupHeading disabled={option.locked}>
          {option.name}
          {option.tip && (
            <S.StyledTooltip
              hidePadding={{ top: 72 }}
              label={option.tip}
              placement="top-start"
              inPortal
            >
              <S.IconWrapper>
                <Icon
                  size={18}
                  iconName="QuestionMarkFill"
                  data-testid="tooltip"
                />
              </S.IconWrapper>
            </S.StyledTooltip>
          )}
        </S.FilterGroupHeading>
        {option.type === 'checkbox' && (
          <CheckBoxGroup
            itemClassName="checkboxItem"
            onChange={handleFilter}
            items={option.options.map(op => ({
              id: `${option.key}-${op.key}`,
              key: op.key,
              label: op.name,
              tooltip: op.tip,
            }))}
            value={getFilters(filters, type)}
          />
        )}
        {option.type === 'radio' && (
          <S.StyledRadioGroup
            onChange={handleFilter}
            items={option.options.map(op => {
              const iconName = iconNameTranslate(op.icon);
              return {
                id: op.key,
                label: op.name,
                name: op.name,
                tooltip: op.tip ? (
                  <S.StyledTooltip label={op.tip} />
                ) : undefined,
                icon: iconName && {
                  iconName,
                  size: 20,
                  className: op.icon,
                  'data-testid': 'filter-icon',
                },
                locked: op.locked,
              };
            })}
            activeId={getFilters(filters, type)[0]}
          />
        )}

        {option.type === 'set' && (
          <S.SelectBtnGroup
            locked={option.locked}
            onChange={handleFilter}
            items={option.options.map(op => {
              const iconName = iconNameTranslate(op.icon);
              return {
                key: op.key,
                className: type.toLowerCase(),
                iconName,
                label: op.name,
              };
            })}
            value={getFilters(filters, type)}
          />
        )}
      </div>
    </S.Filter>
  );
};
