import {
  deleteClientSecret,
  getClientSecret,
  postClientSecret,
} from '~/api/payments/payments-service';
import { Tracking } from '~/shared/services/tracking';
import { create } from '~/stores/create-store/createStore';

interface PaymentsStoreState {
  error: boolean;
  clientSecret?: string;
}
interface PaymentsStoreActions {
  getClientSecret: () => Promise<void>;
  confirmPayment: (token: string) => Promise<void>;
  deletePayment: () => Promise<void>;
  clearClientSecret: () => void;
}

const initialPaymentState: PaymentsStoreState = {
  error: false,
  clientSecret: undefined,
};

export const usePayments = create<PaymentsStoreState & PaymentsStoreActions>(
  (set, get) => ({
    ...initialPaymentState,
    getClientSecret: async () => {
      try {
        const { client_secret } = await getClientSecret();
        set({ clientSecret: client_secret });
      } catch (error) {
        Tracking.captureException(error);
        set({ error: true });
      }
    },
    confirmPayment: async stripeClientSecret => {
      try {
        await postClientSecret(stripeClientSecret);
      } catch (error) {
        Tracking.captureException(error);
        set({ error: true, clientSecret: undefined });
      }
    },
    deletePayment: async () => {
      try {
        set({ error: false });
        const { clientSecret } = get();
        await deleteClientSecret(clientSecret as string);
      } catch (error) {
        Tracking.captureException(error);
        set({ error: true });
      } finally {
        set({ clientSecret: undefined });
      }
    },
    clearClientSecret: () => set({ clientSecret: undefined }),
  })
);
