import {
  customScrollMinimal,
  Modal,
  typographyV2,
} from '@toggle/design-system';
import styled from 'styled-components';

export const StyledModal = styled(Modal)`
  ${Modal.Styled.Header} {
    padding-bottom: 16px;
  }

  ${Modal.Styled.TextContainer} {
    margin-top: 12px;
    margin-bottom: 0;
  }

  ${Modal.Styled.ModalContainer} {
    display: flex;
    flex-direction: column;
    width: 600px;
    height: 100%;
    max-height: 471px;
    padding: 0;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 406px;
  padding: 0;
`;

export const ChipsWrapper = styled.div`
  flex-shrink: 0;
`;

export const ScrollableList = styled.div`
  ${customScrollMinimal};
  flex-grow: 1;
  overflow: auto;
  position: relative;
`;

export const HelpText = styled.p`
  ${typographyV2.bodySm};
  color: var(--text-soft);
  padding: 12px 16px;
`;
