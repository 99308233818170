import { typographyV2 } from '@toggle/design-system';
import styled from 'styled-components';

export const AssetCardRoot = styled.div`
  display: flex;
  gap: 8px;
`;

export const Details = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;

  mark {
    color: var(--text-primary);
    background-color: transparent;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  gap: 4px;
  white-space: nowrap;
`;

export const Title = styled.span`
  ${typographyV2.bodySm};
  color: var(--text-default);
  text-transform: uppercase;
`;

export const Snake = styled.span`
  ${typographyV2.bodySm};
  color: var(--text-default);
`;

export const Subtitle = styled.span`
  ${typographyV2.bodyXs};
  color: var(--text-soft);
`;
