import React from 'react';

import { ChartArticleInsightCard } from '../chart-article-insight-card/ChartArticleInsightCard';
import { ChartNewsInsightCard } from '../chart-news-insight-card/ChartNewsInsightCard';
import { InsightDetails } from '../insights.types';

export interface ChartInsightCardProps {
  insightDetails: InsightDetails;
}

export const ChartInsightCard = ({ insightDetails }: ChartInsightCardProps) => {
  if (insightDetails.type === 'news') {
    return <ChartNewsInsightCard data={insightDetails.item} />;
  }

  if (insightDetails.type === 'article') {
    return <ChartArticleInsightCard data={insightDetails.item} />;
  }

  return null;
};
