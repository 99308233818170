import { Y_AXIS_MIN_WIDTH } from '@toggle/chart';
import { Badge, Tooltip } from '@toggle/design-system';
import React from 'react';

import * as S from './AxisUnit.styles';

export interface AxisUnitProps {
  label: string;
  tooltipLabel: string;
  isMixed: boolean;
  gutterWidth: number;
  offset?: number;
}

export const AxisUnit = ({
  label,
  tooltipLabel,
  isMixed,
  offset,
  gutterWidth,
}: AxisUnitProps) => {
  return (
    <S.Container
      $offset={offset}
      $width={gutterWidth || Y_AXIS_MIN_WIDTH}
      data-testid="axis-unit-root"
    >
      <Tooltip
        label={tooltipLabel}
        isTouchDevice={false}
        closeLabel=""
        disabled={!isMixed}
        inPortal
      >
        <Badge
          label={<S.Label data-testid="unit-badge">{label}</S.Label>}
          color="neutral"
          badgeStyle="outline"
          size="small"
        />
      </Tooltip>
    </S.Container>
  );
};
