import { typographyV2 } from '@toggle/design-system';
import styled from 'styled-components';

export const Summary = styled.span`
  display: -webkit-box;
  -webkit-line-clamp: 5;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  ${typographyV2.bodyBase};
  margin-bottom: 16px;
  flex-grow: 1;
`;
