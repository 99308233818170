import { config } from '~/config';

const {
  api: { root: apiRoot },
} = config;

const snakeRoot = `${apiRoot}ca/v2`;

export const ENDPOINT_URLS = {
  EXPLORE_METRICS: `${apiRoot}stats/v1/writer`,
  IB_LOGIN: `${apiRoot}p/v1/ib/login-url`,
  IB_VERIFIER_TOKEN: `${apiRoot}p/v1/ib/verifier-token`,

  PLAIN_NEWS: `${apiRoot}news/v2/plain`,
  AI_NEWS: `${apiRoot}news/v2/ai`,
  ALL_NEWS: `${apiRoot}news/v2/all`,
  ENTITY_REPORT: `${apiRoot}entity-report/v1/entity`,
  ARTICLE_INSIGHT_CARDS: `${apiRoot}feed/v1/cards/insight`,
  OW_ASSET_ITEMS_V3: `${apiRoot}co/v3/w/f`,
  OW_SNAKE_BY_NAME: `${snakeRoot}/q`,
  OW_SNAKE_BY_ENTITY: `${snakeRoot}/w/p`,
  PLAID_GET_LINK_TOKEN: `${apiRoot}plaid/v1/link`,
  PLAID_SET_ACCESS_TOKEN: `${apiRoot}plaid/v1/access-token`,
  PORTFOLIO: `${apiRoot}p/v1/portfolio`,
  PENDING_PORTFOLIO: `${apiRoot}p/v1/pending-portfolios`,
  ARTICLE_BASE_V1: `${apiRoot}a/v1/a`,
  ARTICLE_BASE_V2: `${apiRoot}a/v2/a`,
  ARTICLE_EXPLORE: `${apiRoot}a/v2/explore`,
  SNAKE_META_V2: `${apiRoot}snake/v2`,
  REMOTE_STORAGE: `${apiRoot}flags/v1`,
  PAYMENT_VERIFICATION: `${apiRoot}payment/v1/verification`,
  SUBSCRIPTIONS_PROMO_CODE: `${apiRoot}subscriptions/v1/promo-code`,
  CONNECTED_ENTITIES: `${apiRoot}kg/v1/connected-entities/proximity`,
  SYSTEM_FLAGS: `${apiRoot}system/flags/v1`,
  ARTICLES_LIMITS: `${apiRoot}a/v1/user-consumption`,
  EXPERIMENT_COMPASS: `${apiRoot}analysis/v1/compass`,
  EXPERIMENT_CONDITION_TEMPLATES: `${apiRoot}analysis-scenarios/v1/suggestions`,
  EXPERIMENT_SCENARIO_BY_ID: `${apiRoot}analysis-scenarios/v1/scenario`,
  WATCHLIST: `${apiRoot}watchlist/v1`,
  WATCHLIST_SUGGESTED: `${apiRoot}watchlist/v1/suggested`,
  PORTFOLIO_ALERTS: `${apiRoot}p/v1/alert`,
  SCENARIO_VALIDATION_ROOT: `${apiRoot}u/v1/validate-compass`,
  USER_ENTITIES: `${apiRoot}user-entities/v1`,
  USER_ENTITIES_V3: `${apiRoot}user-entities/v3`,
  USER_STATEMENT: `${apiRoot}p/v1/user-statement`,
  TRADING_ORDERS: `${apiRoot}trading/v1/orders`,
  timeSeries: `${apiRoot}snake/v1/filtered`,
  keyValue: (tag: string) =>
    `${apiRoot}entity-report/v1/entity/${tag}/key-values`,
  performanceChart: (accountId: string) =>
    `${apiRoot}portfolio-stats/v1/nav/portfolio_external_id/${accountId}`,
};
