import ReactGA from 'react-ga4';

import { UserRole } from '~/api/subscription/subscription-schema';

const CATEGORIES = {
  article: 'Article',
};
export { CATEGORIES as ARTICLE_CATEGORIES };

const ACTIONS = {
  goToOverview: 'Go_to_overview',
  articleTooltipHover: 'Tooltip_hover',
  articleTooltipClick: 'Tooltip_click',
  articleImageCopy: 'Article_image_copy',
  articleImageDownload: 'Article_image_download',
  articleLhsPanelClick: 'Lhs_panel_clicked',
  insightLimitHit: 'Insight_limit',
  articleClickMoreDetails: 'Click_article_more_details',
  articleClickHideDetails: 'Click_article_hide_details',
  articleOpenInScenario: 'Article_open_in_scenario',
};
export { ACTIONS as ARTICLE_ACTIONS };

export function gaArticleGoToOverview(entity: string) {
  ReactGA.event({
    category: CATEGORIES.article,
    action: ACTIONS.goToOverview,
    label: `entity:${entity}`,
  });
}

export function gaArticleTooltipAction(
  action: 'hover' | 'click',
  tooltipTarget?: string
) {
  ReactGA.event({
    category: CATEGORIES.article,
    action:
      action === 'hover'
        ? ACTIONS.articleTooltipHover
        : ACTIONS.articleTooltipClick,
    label: `tooltipTarget:${tooltipTarget ?? ''}`,
  });
}

export function gaArticleImageAction(action: 'copy' | 'download') {
  ReactGA.event({
    category: CATEGORIES.article,
    action:
      action === 'copy'
        ? ACTIONS.articleImageCopy
        : ACTIONS.articleImageDownload,
  });
}

export function gaArticleLHSPanelAction(menuItem: string) {
  ReactGA.event({
    category: CATEGORIES.article,
    action: ACTIONS.articleLhsPanelClick,
    label: `menuItem:${menuItem}`,
  });
}

export function gaArticleHitInsightsLimit() {
  ReactGA.event({
    category: CATEGORIES.article,
    action: ACTIONS.insightLimitHit,
    label: `subscription:${UserRole.Basic}`,
  });
}

export function gaArticleShowDetails(isCollapsed: boolean) {
  ReactGA.event({
    category: CATEGORIES.article,
    action: isCollapsed
      ? ACTIONS.articleClickHideDetails
      : ACTIONS.articleClickMoreDetails,
  });
}

export function gaArticleOpenInScenario() {
  ReactGA.event({
    category: CATEGORIES.article,
    action: ACTIONS.articleOpenInScenario,
  });
}
