import {
  amber,
  amberDark,
  blueDark,
  crimsonDark,
  goldDark,
  grassDark,
  grayDark,
  tealDark,
  tomatoDark,
  violetDark,
  yellowDark,
} from '@radix-ui/colors';

import { hslToHex } from '~/utils/color-conversion/color-conversion';

const background = {
  '--background-default': 'rgba(9, 9, 9, 1)',
  '--background-soft': 'rgba(16, 16, 16, 1)',
  '--background-backdrop': 'rgba(0, 0, 0, 0.7)',
};

const surface = {
  '--surface-default': grayDark.gray3,
  '--surface-hover': grayDark.gray4,
  '--surface-pressed': grayDark.gray5,
  '--surface-active': grayDark.gray5,
  '--surface-inverse': '#fff',
  '--surface-primary': amberDark.amber9,
  '--surface-success': grassDark.grass3,
  '--surface-warning': yellowDark.yellow3,
  '--surface-critical': tomatoDark.tomato3,
  '--surface-neutral': grayDark.gray9,
  '--surface-white': '#fff',
};

const text = {
  '--text-default': '#fff',
  '--text-soft': hslToHex(grayDark.gray11),
  '--text-disabled': grayDark.gray9,
  '--text-inverse': grayDark.gray1,
  '--text-primary': amberDark.amber9,
  '--text-success': grassDark.grass11,
  '--text-warning': yellowDark.yellow11,
  '--text-critical': tomatoDark.tomato11,
  '--text-on-primary': grayDark.gray1,
  '--text-on-critical': '#fff',
  '--text-link': blueDark.blue11,
};

const icon = {
  '--icon-default': '#fff',
  '--icon-soft': grayDark.gray11,
  '--icon-disabled': grayDark.gray9,
  '--icon-inverse': grayDark.gray1,
  '--icon-primary': amberDark.amber9,
  '--icon-success': grassDark.grass11,
  '--icon-warning': yellowDark.yellow11,
  '--icon-critical': tomatoDark.tomato11,
  '--icon-on-primary': grayDark.gray1,
  '--icon-on-critical': '#fff',
};

const border = {
  '--border-default': grayDark.gray9,
  '--border-soft': hslToHex(grayDark.gray6),
  '--border-hover': hslToHex(grayDark.gray11),
  '--border-disabled': grayDark.gray6,
  '--border-primary': hslToHex(amberDark.amber9),
  '--border-success': grassDark.grass11,
  '--border-warning': yellowDark.yellow11,
  '--border-critical': tomatoDark.tomato11,
  '--border-focus': amber.amber4,
};

const action = {
  '--action-primary-default': amberDark.amber9,
  '--action-primary-hover': amberDark.amber10,
  '--action-primary-disabled': grayDark.gray4,
  '--action-primary-soft-hover': 'rgba(255, 178, 36, 0.15)',
  '--action-critical-default': tomatoDark.tomato9,
  '--action-critical-hover': tomatoDark.tomato10,
  '--action-critical-disabled': grayDark.gray4,
  '--action-soft-hover': tomatoDark.tomato4,
};

const grassDark11Rgb = '99, 193, 116';
const tomatoDark11Rgb = '241, 106, 80';
const blueDark11Rgb = '82, 169, 255';

const visual = {
  '--viz-bullish': hslToHex(grassDark.grass11),
  '--viz-bullish-rgb': grassDark11Rgb,
  '--viz-bullish-soft': grassDark.grass4,
  '--viz-bearish': hslToHex(tomatoDark.tomato11),
  '--viz-bearish-rgb': tomatoDark11Rgb,
  '--viz-bearish-soft': tomatoDark.tomato4,
  '--chart-colors-list': `
    ${hslToHex(amberDark.amber9)},
    ${hslToHex(violetDark.violet10)},
    ${hslToHex(tealDark.teal10)},
    ${hslToHex(goldDark.gold10)},
    ${hslToHex(crimsonDark.crimson10)}
  `,
};

export const spaceDark = {
  ...background,
  ...surface,
  ...border,
  ...text,
  ...icon,
  ...action,
  ...visual,
};

export const spaceDark_protanopia = {
  ...spaceDark,
  '--viz-bearish': hslToHex(blueDark.blue11),
  '--viz-bearish-rgb': blueDark11Rgb,
  '--viz-bearish-soft': blueDark.blue4,
};

export const spaceDark_deuteranopia = {
  ...spaceDark,
  '--viz-bullish': hslToHex(blueDark.blue11),
  '--viz-bullish-rgb': blueDark11Rgb,
  '--viz-bullish-soft': blueDark.blue4,
};
