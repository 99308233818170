import { typography } from '@toggle/design-system';
import styled from 'styled-components';

export const NoInsights = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
`;

export const Header = styled.h1`
  ${typography.chimp};
  font-weight: 500;
  margin-bottom: 8px;
`;

export const Subheader = styled.h2`
  ${typography.dingo};
  margin-bottom: 64px;
`;

export const Image = styled.img`
  max-height: 780px;
`;
