import { ButtonLink, Icon, typographyV2 } from '@toggle/design-system';
import styled, { css } from 'styled-components';

import { QualityChecklistSection } from '~/views/analyze/experiment/components/experiment-quality-check-list/quality-checklist-section/QualityChecklistSection';

export const PeriodViewRoot = styled.div`
  padding: 16px;
`;

export const BackButton = styled(ButtonLink)`
  margin-bottom: 24px;
  align-self: flex-start;
`;

export const QualityTitle = styled.h2`
  ${typographyV2.titleLg}
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  margin-top: 32px;
`;

export const ActiveStar = styled(Icon)<{ $active: boolean }>`
  &:not(:first-child) {
    margin-left: 6px;
  }
  fill: ${({ $active }) =>
    $active ? 'var(--icon-primary)' : 'var(--icon-soft)'};
`;

export const QualityChecklistSectionStyled = styled(QualityChecklistSection)<{
  $isTop?: boolean;
}>`
  margin: 0px;
  ${({ $isTop }) =>
    $isTop
      ? css`
          border-bottom-left-radius: 0px;
          border-bottom-right-radius: 0px;
          border-bottom: none;
        `
      : css`
          border-top-left-radius: 0px;
          border-top-right-radius: 0px;
          border-top: none;
        `}
`;
