import { ENDPOINT_URLS } from '~/global/toggle-api';
import { APIFetch } from '~/shared/services/api-fetch/APIFetch';
import { HttpError } from '~/shared/services/api-fetch/APIFetchError';
import { HttpMethods } from '~/shared/services/api-fetch/constants';

export interface Tag {
  tag: string;
  tag_name: string;
  type: string;
  type_name: string;
  ngrams: string;
  rank: number;
  start: number;
  matched: number;
}

interface WatchlistResponse {
  tags: Tag[];
}
interface SuggestedWatchlistResponse {
  suggested: Tag[];
}

export const fetchTags = async () => {
  try {
    const result = await APIFetch<WatchlistResponse | Error>(
      ENDPOINT_URLS.WATCHLIST
    );
    if (result instanceof Error) {
      throw result;
    }
    return result.tags;
  } catch (error) {
    return [];
  }
};
export const fetchSuggestedTags = async () => {
  try {
    const result = await APIFetch<SuggestedWatchlistResponse | Error>(
      ENDPOINT_URLS.WATCHLIST_SUGGESTED
    );
    if (result instanceof Error) {
      throw result;
    }
    return result.suggested;
  } catch (error) {
    return [];
  }
};

export const addTag = async (tag: string) => {
  return APIFetch<Tag | HttpError>(`${ENDPOINT_URLS.WATCHLIST}/${tag}`, {
    method: HttpMethods.Post,
  });
};

export const deleteTag = async (tag: string) => {
  return APIFetch(`${ENDPOINT_URLS.WATCHLIST}/${tag}`, {
    method: HttpMethods.Delete,
  });
};
