import {
  getStartOfRangeUTC,
  numberRoundedToPrecision,
  RangeHorizon,
} from '@toggle/helpers';
import * as d3 from 'd3';
import { differenceInCalendarDays } from 'date-fns';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { TSDatum } from '~/api/timeseries/timeseries-schema';
import { OptionalU } from '~/declarations/standard';
import { appPaths } from '~/routes/app-paths';
import { AnimatedDomain } from '~/shared/components/chart/Chart';
import { useRemoteStorage } from '~/shared/hooks/use-remote-storage';

import { AO_SELECTED_HORIZON } from '../use-remote-storage/storage-keys';

export interface DateRange {
  range: [number, number];
  label: RangeHorizon;
}

export interface ChartDomain extends AnimatedDomain<number> {
  changedByRangeChart: boolean;
  changedByChat?: boolean;
}

export interface UpdateChartDomain {
  (args: {
    domain: [number, number];
    animated?: boolean;
    changedByRangeChart?: boolean;
    changedByChat?: boolean;
    horizon?: RangeHorizon;
  }): void;
}

function sortTimeRanges(
  startDate: Date,
  endDate: Date,
  horizons = Object.values(RangeHorizon)
) {
  return horizons
    .map(range => ({
      label: range,
      start: getStartOfRangeUTC(range, endDate),
    }))
    .filter(x => x.start === undefined || x.start >= startDate.getTime())
    .sort((r1, r2) => {
      if (r1.start === undefined) {
        return 1;
      } else if (r2.start === undefined) {
        return -1;
      } else {
        return r2.start - r1.start;
      }
    });
}

function getActiveHorizon({
  dateRanges,
  storedHorizon,
  isTouchDevice,
}: {
  dateRanges: DateRange[];
  storedHorizon: RangeHorizon | undefined;
  isTouchDevice: boolean;
}) {
  const defaultHorizon = (
    dateRanges.find(range => range.label === RangeHorizon.ThreeMonths) ??
    dateRanges.find(range => range.label === RangeHorizon.OneMonth) ??
    dateRanges[0]
  ).label;

  return storedHorizon && !isTouchDevice
    ? dateRanges.find(d => d.label === storedHorizon)?.label ?? defaultHorizon
    : defaultHorizon;
}

function getChartDomain({
  dateRanges,
  activeHorizon,
}: {
  dateRanges: DateRange[];
  activeHorizon: RangeHorizon;
}) {
  const defaultDomain = (
    dateRanges.find(
      dateRange => dateRange.label === RangeHorizon.ThreeMonths
    ) ??
    dateRanges.find(range => range.label === RangeHorizon.OneMonth) ??
    dateRanges[0]
  ).range;

  return (
    dateRanges.find(d => d.label === activeHorizon)?.range ?? defaultDomain
  );
}

const AO_REGEXP = new RegExp(`${appPaths.analyze}\/`);
const MIN_DAYS_OFFSET = 2;
const MAX_RANGE = 100;

const DEFAULT_HORIZONS = [
  RangeHorizon.TwelveDays,
  RangeHorizon.OneMonth,
  RangeHorizon.ThreeMonths,
  RangeHorizon.SixMonths,
  RangeHorizon.YearToDate,
  RangeHorizon.OneYear,
  RangeHorizon.ThreeYears,
  RangeHorizon.FiveYears,
  RangeHorizon.TenYears,
  RangeHorizon.Max,
];

interface UseDateRangeParams {
  snakeData: TSDatum[];
  isTouchDevice?: boolean;
  paddingRight?: number;
  horizons?: RangeHorizon[];
}

export const useDateRange = ({
  snakeData,
  paddingRight,
  isTouchDevice = false,
  horizons = DEFAULT_HORIZONS,
}: UseDateRangeParams) => {
  const [dateRanges, setDateRanges] = useState<DateRange[]>([]);
  const [activeHorizon, setActiveHorizon] = useState<RangeHorizon>();
  const [chartDomain, setChartDomain] = useState<ChartDomain>();
  const { items, storeItems } = useRemoteStorage();
  const isAssetOverview = AO_REGEXP.test(window.location.href);

  const dateScale = useMemo(() => {
    if (!paddingRight || !snakeData.length) {
      return d3.scaleOrdinal<number, Date, Date>([], []);
    }

    const dates = snakeData.map(p => new Date(p.index));
    const extendedRange =
      dates.length + Math.min(Math.round(dates.length * 2), MAX_RANGE);
    let i = dates.length;
    let lastKnownDate = dates[dates.length - 1];
    const weekDays = [0, 6];
    while (i <= extendedRange) {
      lastKnownDate = new Date(lastKnownDate);
      lastKnownDate.setUTCDate(lastKnownDate.getUTCDate() + 1);
      if (!weekDays.includes(lastKnownDate.getUTCDay())) {
        i++;
        dates.push(lastKnownDate);
      }
    }

    return d3.scaleOrdinal(
      dates.map((_, i) => i),
      dates.slice()
    );
  }, [snakeData, paddingRight]);

  const updateChartDomain: UpdateChartDomain = useCallback(
    ({
      domain,
      animated = false,
      changedByRangeChart = false,
      changedByChat = false,
    }) => {
      domain[0] = numberRoundedToPrecision(domain[0]);
      domain[1] = numberRoundedToPrecision(domain[1]);

      setChartDomain(prevChartDomain => {
        const prevDomain = prevChartDomain?.domain;
        return prevDomain &&
          prevDomain[0] === domain[0] &&
          prevDomain[1] === domain[1]
          ? { domain: prevDomain, animated, changedByRangeChart, changedByChat }
          : { domain, animated, changedByRangeChart, changedByChat };
      });

      setActiveHorizon(prevHorizon => {
        const newDateRange = dateRanges.find(
          ({ range }) => domain[0] === range[0] && domain[1] === range[1]
        );

        if (!prevHorizon) {
          return newDateRange?.label;
        }

        const prevDateRange = dateRanges.find(d => d.label === prevHorizon);
        const areRangesEqual =
          prevDateRange?.range[0] === newDateRange?.range[0] &&
          prevDateRange?.range[1] === newDateRange?.range[1];

        return areRangesEqual ? prevHorizon : newDateRange?.label;
      });
    },
    [dateRanges]
  );

  const changeHorizon = useCallback(
    (horizon: RangeHorizon) => {
      const dateRange = dateRanges.find(({ label }) => label === horizon);
      if (dateRange) {
        setActiveHorizon(dateRange.label);
        updateChartDomain({ domain: dateRange.range, animated: true });
      }

      if (isAssetOverview) {
        storeItems({ [AO_SELECTED_HORIZON]: horizon });
      }
    },
    [dateRanges, isAssetOverview]
  );

  const handleSnakeData = (ts: TSDatum[], horizons: RangeHorizon[]) => {
    const lastIdx = ts.length - 1;
    const tsEndDate = new Date(ts[lastIdx].index);
    const tsStartDate = new Date(ts[0].index);
    const sortedTimeRanges = sortTimeRanges(tsStartDate, tsEndDate, horizons);
    const pointDates = paddingRight
      ? dateScale.range()
      : ts.map(p => new Date(p.index));

    const dateRanges: DateRange[] = paddingRight
      ? sortedTimeRanges.map(r => {
          if (!r.start) {
            const max = Math.min(Math.round(paddingRight * lastIdx), MAX_RANGE);
            return {
              label: r.label,
              range: [0, lastIdx + Math.max(MIN_DAYS_OFFSET, max)],
            };
          }

          const diff = differenceInCalendarDays(tsEndDate, r.start);
          const daysToAdd = Math.max(
            MIN_DAYS_OFFSET,
            Math.min(Math.round(paddingRight * diff), MAX_RANGE)
          );
          const endIdx = lastIdx + daysToAdd;
          const finalEndDate = dateScale.range()[endIdx];
          const utcStart = getStartOfRangeUTC(r.label, finalEndDate);
          const startIdx = utcStart
            ? d3.bisectLeft(pointDates, new Date(utcStart))
            : 0;

          return {
            label: r.label,
            range: [startIdx, endIdx],
          };
        })
      : sortedTimeRanges.map(r => ({
          label: r.label,
          range: [
            r.start ? d3.bisectLeft(pointDates, new Date(r.start)) : 0,
            lastIdx,
          ],
        }));

    const storedHorizon = items?.AO_SELECTED_HORIZON as OptionalU<RangeHorizon>;
    const activeHorizon = getActiveHorizon({
      dateRanges,
      storedHorizon,
      isTouchDevice,
    });
    const chartDomain = {
      domain: getChartDomain({
        dateRanges,
        activeHorizon,
      }),
      animated: true,
      changedByRangeChart: false,
      changedByChat: false,
    };

    setDateRanges(dateRanges);
    setActiveHorizon(activeHorizon);
    setChartDomain(chartDomain);
  };

  useEffect(() => {
    if (snakeData.length > 0) {
      handleSnakeData(snakeData, horizons);
    }
  }, [snakeData, horizons]);

  const domainDates = useMemo(() => {
    if (!chartDomain?.domain || !snakeData) {
      return null;
    }

    const domain = chartDomain.domain;
    const start = dateScale(Math.round(domain[0]));
    const maxDomainEnd = Math.min(snakeData.length - 1, domain[1]);
    const end = dateScale(Math.round(maxDomainEnd));
    return [start, end] as [Date, Date];
  }, [snakeData, chartDomain]);

  return {
    domainDates,
    dateScale,
    dateRanges,
    activeHorizon,
    setActiveHorizon: changeHorizon,
    dateRange: chartDomain,
    updateChartDomain,
  };
};
