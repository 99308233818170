import styled from 'styled-components';

import { Modal } from '~/components/modal/Modal';
import { breakpoints } from '~/design-tokens/breakpoints/breakpoints';

export const PaywallModal = styled(Modal)`
  ${Modal.Styled.ModalContainer} {
    width: min(calc(100% - 16px), 600px);
  }

  ${Modal.Styled.Header} {
    padding-bottom: 6px;
  }
`;

export const Content = styled.div`
  padding: 0px 16px 8px;

  @media ${breakpoints.lg} {
    padding: 0px 32px 8px;
  }
`;
