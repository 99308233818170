import { useEffect, useRef } from 'react';

import { OptionalU } from '~/declarations/standard';

export const useHistoricalEffect = <T extends unknown[]>(
  effect: (current: OptionalU<T>, next: T) => void,
  deps: T
): void => {
  const prev = useRef<T>();
  useEffect(() => {
    effect(prev.current, deps);
    prev.current = deps;
  }, deps);
};
