import React from 'react';
import { useTranslation } from 'react-i18next';

import { TooltipContainer } from '~/shared/components/tooltip-container/TooltipContainer';

import * as S from './ConditionLiveBadge.styles';

interface ConditionLiveBadgeProps {
  withTooltip?: boolean;
  className?: string;
}

export const ConditionLiveBadge = ({
  withTooltip = true,
  className,
}: ConditionLiveBadgeProps) => {
  const { t } = useTranslation('analyze');
  return (
    <TooltipContainer
      label={t('analyze:experiment.liveTooltip')}
      disabled={!withTooltip}
    >
      <S.Badge className={className} data-testid="live-badge">
        {t('analyze:experiment.liveBadge')}
      </S.Badge>
    </TooltipContainer>
  );
};
