import styled, { keyframes } from 'styled-components';

const slideDown = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-40px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const MaintenanceBannerRoot = styled.div`
  animation: ${slideDown} 0.2s ease-out;
  position: absolute;
  z-index: calc(var(--z-index-app-header) - 1);
  right: 0;
  padding: 10px;
`;
