import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export const useLocationKeepQueryParams = (
  url: string,
  keepParams: string[]
) => {
  const { search } = useLocation();

  return useMemo(() => {
    const existingParams = new URLSearchParams(search);
    const resultUrl = new URL(url, window.location.href);
    keepParams.forEach(p => {
      const paramValue = existingParams.get(p);
      if (paramValue) {
        resultUrl.searchParams.set(p, paramValue);
      }
    });

    if (window.location.origin === resultUrl.origin) {
      return resultUrl.href.substring(window.location.origin.length);
    }
    return resultUrl.href;
  }, [url, search, keepParams.join()]);
};
