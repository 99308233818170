import styled, { css } from 'styled-components';

import { timingFunction } from '~/utils/transitions';

export const ToggleLogotypeRoot = styled.div<{ isExpanded: boolean }>`
  path {
    transition: opacity 300ms ${timingFunction.base};
    fill: #fff;

    &:last-child {
      fill: var(--icon-primary);
    }
  }

  ${({ isExpanded }) =>
    !isExpanded &&
    css`
      path:not(.core) {
        opacity: 0;
      }
    `}
`;
