import { queryString } from '@toggle/helpers';

import { config } from '~/config';
import { queryKeys } from '~/routes/app-paths';
import { HttpMethods } from '~/shared/services/api-fetch';
import { validateApiFetch } from '~/shared/services/api-fetch/validate-api-fetch/validateApiFetch';

import { UserAddressPayload } from '../user/user-schema';
import {
  BankCard,
  bankCardResponseSchema,
  PriceWithTax,
  priceWithTaxResponseSchema,
  SubscriptionResponse,
  subscriptionSchema,
  subscriptionUserSchema,
  UserSubscription,
} from './subscription-schema';

const {
  api: { root: apiRoot },
} = config;

export const subscriptionEndpoints = {
  subscription: `${apiRoot}subscriptions/v2`,
  pricePreview: `${apiRoot}subscriptions/v2/price-preview`,
  userSubscription: `${apiRoot}subscriptions/v2/user`,
  subscriptionPrice: (priceId: string, queryParams: string) =>
    `${apiRoot}subscriptions/v1/price/${priceId}${queryParams}`,
  paymentCard: `${apiRoot}payment/v1/card`,
};

export const getSubscription = () => {
  return validateApiFetch<SubscriptionResponse>({
    endpoint: subscriptionEndpoints.subscription,
    schema: subscriptionSchema,
  });
};

export interface PostUserSubscriptionProps {
  productId: string;
  priceId?: string;
  promoCode?: string;
}

export const postUserSubscription = async ({
  productId,
  priceId,
  promoCode,
}: PostUserSubscriptionProps) => {
  return validateApiFetch<UserSubscription>({
    endpoint: subscriptionEndpoints.userSubscription,
    schema: subscriptionUserSchema,
    requestInfo: {
      method: HttpMethods.Post,
      body: JSON.stringify({
        subscription_id: productId,
        price_id: priceId,
        promo_code: promoCode,
      }),
    },
  });
};

export const getPriceWithTax = (priceId: string, promo?: string) => {
  const queryParams = queryString({
    [queryKeys.promoCode]: promo,
  });
  return validateApiFetch<PriceWithTax>({
    endpoint: subscriptionEndpoints.subscriptionPrice(priceId, queryParams),
    schema: priceWithTaxResponseSchema,
  });
};

export interface PricePreviewRequest {
  address?: UserAddressPayload;
  priceId: string;
  signal?: AbortSignal;
  promoCode?: string;
}

export const postPricePreview = ({
  address,
  priceId,
  signal,
  promoCode,
}: PricePreviewRequest) => {
  return validateApiFetch<PriceWithTax>({
    endpoint: subscriptionEndpoints.pricePreview,
    schema: priceWithTaxResponseSchema,
    requestInfo: {
      method: HttpMethods.Post,
      body: JSON.stringify({
        price_id: priceId,
        address,
        promo_code: promoCode,
      }),
      signal,
    },
  });
};

export const getBankCard = () => {
  return validateApiFetch<BankCard>({
    endpoint: subscriptionEndpoints.paymentCard,
    schema: bankCardResponseSchema,
  });
};

export const deleteBankCard = () => {
  validateApiFetch<void>({
    endpoint: subscriptionEndpoints.paymentCard,
    requestInfo: {
      method: HttpMethods.Delete,
    },
  });
};
