import { Button, fontWeight, typography } from '@toggle/design-system';
import styled from 'styled-components';

import { getDirectionColor } from '~/shared/components/chart/utils';

export const Container = styled.div`
  margin-top: 16px;
`;

export const Title = styled.h6`
  margin: 0px 0px 18px;
  color: var(--text-soft);
  font-weight: ${fontWeight.medium};
  ${typography.koala};
`;

export const Row = styled.div<{ $isBullish?: boolean }>`
  display: flex;
  align-items: center;
  color: ${props => getDirectionColor(props.$isBullish)};

  &:not(:first-child) {
    margin-top: 12px;
  }
`;

export const DirectionLabel = styled.p`
  ${typography.koala};
  margin-right: 8px;
`;

export const InsightsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

export const Logo = styled.img`
  width: 20px;
`;

export const InsightButton = styled(Button)`
  border-radius: 16px;
  color: inherit;
  font-weight: ${fontWeight.medium};

  &.visited {
    border-color: var(--border-soft);
    pointer-events: none;
  }
`;
