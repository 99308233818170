import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useHelper } from '~/stores/use-helper/useHelper';
import { useExploreFilters } from '~/views/explore';
import { exploreSectionsRefs } from '~/views/helper/helper-widget/content/explore/ExploreHelperContent';
import { EXPLORE_SECTION_IDS } from '~/views/helper/HelperConfig';

import { ClearAllButton } from '../clear-all-button/ClearAllButton';
import * as S from './ExploreFilters.styles';

interface ExploreFiltersProps {
  closeMobileDrawer: () => void;
}

export const ExploreFilters: FC<ExploreFiltersProps> = ({
  closeMobileDrawer,
}) => {
  const { t } = useTranslation('explore');
  const { setHelperHoverBehavior } = useHelper();

  const { filters, loading, filterOptions, updateFilter, clearFilters } =
    useExploreFilters(s => ({
      filters: s.filters,
      loading: s.loading,
      updateFilter: s.updateFilter,
      clearFilters: s.clearFilters,
      filterOptions: s.filterOptions,
    }));

  return loading ? (
    <S.FiltersLoader defaultView />
  ) : (
    <S.ExploreFilterContainer>
      <S.ExploreFilterHeader>
        <S.ExploreFilterTitle>
          {t('explore:filters.label')}
        </S.ExploreFilterTitle>
        {Object.keys(filters).length > 0 && (
          <ClearAllButton onClick={clearFilters} />
        )}
      </S.ExploreFilterHeader>
      <S.ExploreFiltersList>
        {filterOptions.map(option => (
          <S.ExploreFilterGroup
            highlightBehaviour={
              option.key === 'TOP_SCREENS'
                ? {
                    ref: exploreSectionsRefs[
                      EXPLORE_SECTION_IDS.EXPLORE_TOP_SCREENS_CHECK_ID
                    ],
                    ...setHelperHoverBehavior(
                      EXPLORE_SECTION_IDS.EXPLORE_TOP_SCREENS_CHECK_ID
                    ),
                  }
                : null
            }
            key={option.key}
            type={option.key}
            option={option}
            handleChange={updateFilter}
            filters={filters}
          />
        ))}
      </S.ExploreFiltersList>
      <S.FilterMobileFooter>
        <S.ShowInsightsBtn
          label={t('explore:filters.mobileButtons.apply')}
          onClick={closeMobileDrawer}
          data-testid="cancel-filter-btn"
          disabled={!filters.length}
        />
      </S.FilterMobileFooter>
    </S.ExploreFilterContainer>
  );
};
