import styled from 'styled-components';

import { TextWithBg } from '~/charts/text-with-bg/TextWithBg';
import { breakpoints } from '~/design-tokens/breakpoints/breakpoints';
import { typographyV2 } from '~/design-tokens/typography/Typography.styles';

export const Value = styled.text<{ $hasProfit: boolean }>`
  ${typographyV2.bodyXs};
  fill: ${props =>
    props.$hasProfit ? 'var(--viz-bullish)' : 'var(--viz-bearish)'};

  text-anchor: start;

  @media ${breakpoints.sm} {
    text-anchor: middle;
  }
`;

export const StyledTextWithBg = styled(TextWithBg)<{ $hasProfit: boolean }>`
  & text {
    ${typographyV2.bodyXs};
    fill: ${props =>
      props.$hasProfit ? 'var(--viz-bullish)' : 'var(--viz-bearish)'};
  }
`;
