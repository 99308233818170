import { deviceConstraints, typography } from '@toggle/design-system';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 12px;
  padding-top: 8px;
  align-items: center;
  justify-content: space-between;
`;

export const PositionTile = styled.div<{ $right?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px 16px;
  background-color: var(--base--90);
  border-radius: 8px;

  grid-area: 1 / 1 / 2 / 2;
  ${({ $right }) =>
    $right &&
    css`
      grid-area: 1 / 2 / 2 / 3;
    `}
`;

export const Title = styled.p`
  @media ${deviceConstraints.smartphone} {
    ${typography.koala}
  }

  ${typography.emu}
`;

export const PositionNumber = styled.p`
  ${typography.coyote}
  font-weight: 500;
`;

export const IconWrapper = styled.div`
  grid-area: 1 / 1 / 2 / 3;
  height: 24px;
  width: 24px;
  display: flex;
  justify-self: center;
  justify-content: center;
  align-items: center;
  z-index: 1;
  background-color: var(--base--95);
  border-radius: 50%;
`;
