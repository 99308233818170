import { Entity } from '@toggle/toggle';
import React from 'react';

import { ListItem } from '../list-item/ListItem';
import * as S from './List.styles';

export interface ListProps {
  items: Entity[];
  isActive?: boolean;
  highlighted?: string;
  title?: string;
  onClick?(item: Entity): void;
  onRemove?(item: Entity): void;
}

export const List = ({
  items,
  isActive,
  highlighted,
  title,
  onClick,
  onRemove,
  ...rest
}: ListProps) => (
  <S.ListRoot data-testid="list" {...rest}>
    {title && <S.ListTitle>{title}</S.ListTitle>}
    <S.List>
      {items.map((item, index) => (
        <ListItem
          key={`${index}-${item.ticker}-${item.exchange.code}`}
          entity={item}
          highlighted={highlighted}
          onClick={() => onClick?.(item)}
          onRemove={() => onRemove?.(item)}
          isActive={isActive}
        />
      ))}
    </S.List>
  </S.ListRoot>
);
