import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import * as S from '../../../Content.styles';

export const AssetsSection: FC = () => {
  const { t } = useTranslation('helperWidget');
  return (
    <S.Content>
      <p>{t('helperWidget:myToggle.content.assets.firstParagraph')}</p>
    </S.Content>
  );
};
