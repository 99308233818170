import * as d3 from 'd3';
import { ScaleLinear } from 'd3';
import React, { useEffect, useMemo, useRef } from 'react';

import { TSPoint } from '../Chart';
import { useAnimatedScale } from '../hooks/useAnimatedScale';
import * as S from './LinePlot.styles';

export interface LinePlotProps<I = unknown, V = unknown> {
  ts: Array<TSPoint<I, V>>;
  scaleX: ScaleLinear<number, number>;
  scaleY: ScaleLinear<number, number>;
  className?: string;
  selected?: TSPoint<I, V>;
  transition?: d3.Transition<SVGSVGElement, unknown, null, undefined>;
  curve?: d3.CurveFactory;
}

const linePath =
  (
    scaleX: ScaleLinear<number, number>,
    scaleY: ScaleLinear<number, number>,
    curve: d3.CurveFactory
  ) =>
  (ts: Array<TSPoint<number, number>>) =>
    d3
      .line<typeof ts[0]>()
      .x(d => scaleX(d.index))
      .y(d => scaleY(d.value))
      .curve(curve)(ts);

export function LinePlot({
  ts,
  scaleX,
  scaleY,
  className,
  selected,
  transition,
  curve = d3.curveLinear,
  ...rest
}: LinePlotProps<number, number>) {
  const pathRef = useRef<SVGPathElement>(null);

  const { scaleX: drawScaleX, scaleY: drawScaleY } = useAnimatedScale(
    scaleX,
    scaleY,
    pathRef,
    ts,
    transition
  );

  const path = useMemo(
    () => linePath(drawScaleX, drawScaleY, curve)(ts),
    [ts, drawScaleX, drawScaleY]
  );

  useEffect(() => {
    const path = pathRef.current as SVGPathElement;
    d3.select(path).data([ts]);
  }, [ts]);

  return (
    <g className={className} {...rest}>
      <S.LinePlotPath
        ref={pathRef}
        data-testid="line-plot"
        vectorEffect="non-scaling-stroke"
        d={path ?? ''}
      />
      {selected && (
        <S.Circle
          cx={scaleX(selected.index)}
          cy={scaleY(selected.value)}
          r="4"
          fill="currentColor"
          stroke="#191d22"
          strokeDasharray="none"
          data-testid="selected-point"
        />
      )}
    </g>
  );
}
