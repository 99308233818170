import { z } from 'zod';

import { CreditCardScheme } from '../subscription/subscription-schema';

export type ClientSecretPayload = z.infer<typeof clientSecretPayload>;
export type AllPaymentsPayload = z.infer<typeof allPaymentsSchema>;
export type SinglePayment = z.infer<typeof singlePaymentSchema>;

export const clientSecretPayload = z.object({
  client_secret: z.string(),
});

export const singlePaymentSchema = z.object({
  date: z.string(),
  description: z.string(),
  billing_cycle: z.string(),
  card_scheme: z.union([z.nativeEnum(CreditCardScheme), z.literal('')]),
  card_last_4: z.string(),
  amount: z.string(),
  tax_amount: z.string(),
  total: z.string(),
});

export const allPaymentsSchema = z.array(singlePaymentSchema);
