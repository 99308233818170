import React from 'react';

import { UpgradeNotice } from '~/global/upgrade-notice/UpgradeNotice';
import { lazyComponentLoader } from '~/shared/utils/component-loader/componentLoader';
import { Article } from '~/views/article/Article';
import { ForgotPasswordView } from '~/views/auth/forgotPassword';
import { IBCallback } from '~/views/auth/portfolio/ib/IBCallback';
import { TradestationCallback } from '~/views/auth/portfolio/tradestation/TradestationCallback';
import { ResetPasswordView } from '~/views/auth/resetPassword/ResetPasswordView';
import { Verify } from '~/views/auth/verify';
import { HELPER_VIEW_IDS } from '~/views/helper/HelperConfig';
import { Questionnaire, Suggestion } from '~/views/questionnaire';
import { ScenarioCommander } from '~/views/scenario-commander/ScenarioCommander';
import { TurboChart } from '~/views/turbo-chart/TurboChart';

import { FeatureFlags } from '../stores/use-feature-flags/feature-flags.types';
import { Chat } from '../views/chat/Chat';
import { NotFound } from '../views/error/not-found/NotFound';
import { ServerError } from '../views/error/server-error/ServerError';
import {
  appPaths,
  AppRouteSections,
  authPaths,
  featurePaths,
} from './app-paths';
import { AppRoute, FeatureRoutes } from './AppRoutes.d';

const articleRoute = {
  path: `${appPaths.article}/:articleID`,
  element: <Article />,
  section: AppRouteSections.Feed,
  helperViewId: HELPER_VIEW_IDS.INSIGHT,
};

const createRoutesFromPathArray = (
  paths: string[],
  route: AppRoute
): AppRoute[] => paths.map(path => ({ path, ...route }));

const MyToggle = lazyComponentLoader(
  () =>
    import(
      /* webpackPrefetch: true */ /* webpackChunkName: "my-toggle" */ '~/views/my-toggle'
    ),
  'my-toggle'
);
const Explore = lazyComponentLoader(
  () =>
    import(
      /*webpackPrefetch: true */ /* webpackChunkName: "explore" */ '~/views/explore'
    ),
  'explore'
);
const Analyze = lazyComponentLoader(
  () =>
    import(
      /*webpackPrefetch: true */ /* webpackChunkName: "analyze" */ '~/views/analyze'
    ),
  'analyze'
);
const MyAccount = lazyComponentLoader(
  () =>
    import(
      /* webpackPrefetch: true */ /* webpackChunkName: "my-account" */ '~/views/my-account'
    ),
  'my-account'
);
const SignUp = lazyComponentLoader(
  () => import(/* webpackChunkName: "signUp" */ '~/views/auth/sign-up'),
  'signUp'
);
const LoginView = lazyComponentLoader(
  () => import(/* webpackChunkName: "loginView" */ '~/views/auth/login-view'),
  'login-view'
);
const Portfolio = lazyComponentLoader(
  () => import(/* webpackChunkName: "portfolio" */ '~/views/portfolio-page'),
  'portfolio'
);

export const routes: AppRoute[] = [
  {
    path: appPaths.myToggle,
    element: <MyToggle />,
    helperViewId: HELPER_VIEW_IDS.MYTOGGLE,
    section: AppRouteSections.MyToggle,
  },
  {
    path: appPaths.portfolio,
    element: <Portfolio />,
    section: AppRouteSections.Portfolio,
  },
  {
    path: appPaths.explore,
    element: <Explore />,
    helperViewId: HELPER_VIEW_IDS.EXPLORE,
    section: AppRouteSections.Explore,
  },
  ...createRoutesFromPathArray(
    [
      appPaths.analyze,
      `${appPaths.analyze}/:entityTag`,
      `${appPaths.analyze}/:entityTag/:snakeName`,
    ],
    {
      element: <Analyze />,
      helperViewId: HELPER_VIEW_IDS.ANALYZE,
      section: AppRouteSections.Analyze,
    }
  ),
  {
    path: appPaths.upgrade,
    element: <UpgradeNotice />,
    section: AppRouteSections.Account,
  },
  ...createRoutesFromPathArray(
    [
      appPaths.myAccount.portfolio,
      appPaths.myAccount.watchlist,
      appPaths.myAccount.security,
      appPaths.myAccount.appearance,
    ],
    { element: <MyAccount />, section: AppRouteSections.Account }
  ),
  {
    path: `${appPaths.myAccount.subscription}/*`,
    element: <MyAccount />,
    section: AppRouteSections.Account,
  },
  ...createRoutesFromPathArray(['/ib', '/app/ib-auth'], {
    element: <IBCallback />,
  }),
  {
    path: '/app/trade-station-auth',
    element: <TradestationCallback />,
  },
  {
    path: appPaths.questionnaire,
    element: <Questionnaire />,
  },
  {
    path: appPaths.suggestion,
    element: <Suggestion />,
  },
  articleRoute,
  {
    path: appPaths.notFound,
    element: <NotFound />,
  },
];

export const authRoutes: AppRoute[] = [
  ...createRoutesFromPathArray([authPaths.login, appPaths.base], {
    element: <LoginView />,
  }),
  {
    path: authPaths.signUp,
    element: <SignUp />,
  },
  articleRoute,
];

export const commonRoutes: AppRoute[] = [
  {
    path: appPaths.error,
    element: <ServerError />,
  },
  {
    path: appPaths.verifyEmail,
    element: <Verify />,
  },
  {
    path: authPaths.resetPassword,
    element: <ResetPasswordView />,
  },
  ...createRoutesFromPathArray(
    [
      authPaths.forgotPassword,
      `${authPaths.forgotPassword}/:${appPaths.emailReset}`,
    ],
    {
      element: <ForgotPasswordView />,
    }
  ),
];

export const featureRoutes: FeatureRoutes = {
  [FeatureFlags.ENABLE_CHAT_REALZ]: {
    path: appPaths.aiChat,
    element: <Chat />,
  },
  [FeatureFlags.SCENARIO_COMMANDER]: {
    path: appPaths.scenarioCommander,
    element: <ScenarioCommander />,
  },
  [FeatureFlags.ENABLE_CHART_V2]: {
    path: featurePaths.turboChart,
    element: <TurboChart />,
    section: AppRouteSections.TurboChart,
  },
};

export const AppRoutes = [...authRoutes, ...commonRoutes, ...routes];
