import { TooltipTrigger } from '@toggle/design-system';
import React, {
  FC,
  ReactElement,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { BypassRef } from '../bypass-ref/BypassRef';
import { TooltipContainerProps } from '../tooltip-container/TooltipContainer';
import * as S from './HeaderNotificationTooltip.styles';

export enum HeaderNotificationTypeEnum {
  WARNING = 'warning',
  ERROR = 'error',
}

interface HeaderNotificationTooltipProps
  extends Omit<TooltipContainerProps, 'position' | 'open'> {
  customArrow?: boolean;
  type?: HeaderNotificationTypeEnum;
  children: ReactElement;
  isOpen: boolean;
  showArrow?: boolean;
}

const ARROW_WIDTH = 7;

export const HeaderNotificationTooltip: FC<HeaderNotificationTooltipProps> = ({
  children,
  type = HeaderNotificationTypeEnum.WARNING,
  customArrow,
  showArrow,
  label,
  offsetX,
  isOpen,
  ...rest
}) => {
  const handleRef = useRef<HTMLDivElement>(null);

  const [arrowPosition, setArrowPosition] = useState<number>(0);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  useEffect(() => {
    if (handleRef.current) {
      setArrowPosition(handleRef.current.getBoundingClientRect().width / 2);
    }
    setTooltipOpen(isOpen);
  }, [isOpen]);

  const content = useMemo(() => {
    return customArrow ? (
      <>
        <S.Arrow
          data-testid="notification-custom-arrow"
          type={type}
          style={{
            right: arrowPosition,
            transform: offsetX
              ? `translateX(${-(offsetX + ARROW_WIDTH)}px)`
              : undefined,
          }}
        />
        {label}
      </>
    ) : (
      label
    );
  }, [customArrow, arrowPosition, label, offsetX, type]);

  return (
    <S.StyledTooltip
      trigger={[TooltipTrigger.Click]}
      placement="bottom-end"
      isOpen={tooltipOpen}
      offsetY={customArrow ? 12 : 0}
      offsetX={offsetX}
      hasArrow={showArrow && !customArrow}
      strategy={'fixed'}
      label={content}
      {...rest}
      $type={type}
    >
      <BypassRef originRef={handleRef}>{children}</BypassRef>
    </S.StyledTooltip>
  );
};
