import ReactGA from 'react-ga4';

const CATEGORIES = {
  scenario: 'Scenario',
};
export { CATEGORIES as SCENARIO_CATEGORIES };

const ACTIONS = {
  addedTemplateCondition: 'Added_template_condition',
  assetToAnalyze: 'Asset_to_analyze',
  clickedBuildMyOwn: 'Clicked_build_my_own',
  completedCustomCondition: 'Completed_custom_condition',
  reset: 'Reset',
  eyeHidden: 'Eye_hidden',
  eyeEnabled: 'Eye_enabled',
  analyzeConditions: 'Analyze_conditions',
  scenarioRetry: 'Retry',
  scenarioChangeHorizon: 'Change_horizon',
  clickedRating: 'Clicked_rating',
  scenarioConditionSearch: 'Scenario_condition_search',
  hoverScatterChart: 'Hover_scatter_chart',
  hoverPredictionChart: 'Hover_prediction_chart',
  clickInsightCondition: 'Click_insight_condition',
};
export { ACTIONS as SCENARIO_ACTIONS };

export function gaScenarioAddTemplateCondition(condition: string) {
  ReactGA.event({
    category: CATEGORIES.scenario,
    action: ACTIONS.addedTemplateCondition,
    label: `condition:${condition}`,
  });
}

export function gaScenarioSendAnalyzedAsset(tag: string) {
  ReactGA.event({
    category: CATEGORIES.scenario,
    action: ACTIONS.assetToAnalyze,
    label: `tag:${tag}`,
  });
}

export function gaScenarioClickBuildMyOwn() {
  ReactGA.event({
    category: CATEGORIES.scenario,
    action: ACTIONS.clickedBuildMyOwn,
  });
}

export function gaScenarioCompleteCustomCondition(condition: string) {
  ReactGA.event({
    category: CATEGORIES.scenario,
    action: ACTIONS.completedCustomCondition,
    label: `condition:${condition}`,
  });
}

export function gaScenarioReset() {
  ReactGA.event({
    category: CATEGORIES.scenario,
    action: ACTIONS.reset,
  });
}

export function gaScenarioToggleEye(isActive: boolean) {
  ReactGA.event({
    category: CATEGORIES.scenario,
    action: isActive ? ACTIONS.eyeEnabled : ACTIONS.eyeHidden,
  });
}

export function gaScenarioSendAnalyzedConditions(conditionsList: string) {
  ReactGA.event({
    category: CATEGORIES.scenario,
    action: ACTIONS.analyzeConditions,
    label: `conditionsList:${conditionsList}`,
  });
}

export function gaScenarioRetry(
  errorStatus: number | undefined,
  message: string
) {
  ReactGA.event({
    category: CATEGORIES.scenario,
    action: ACTIONS.scenarioRetry,
    label: `errorStatus:${errorStatus};message:${message}`,
  });
}

export function gaScenarioChangeHorizon(horizon: string) {
  ReactGA.event({
    category: CATEGORIES.scenario,
    action: ACTIONS.scenarioChangeHorizon,
    label: `horizon:${horizon}`,
  });
}

export function gaScenarioClickRating(stars: number) {
  ReactGA.event({
    category: CATEGORIES.scenario,
    action: ACTIONS.clickedRating,
    label: `stars:${stars}`,
  });
}

export function gaScenarioConditionSearch(searchedValue: string) {
  ReactGA.event({
    category: CATEGORIES.scenario,
    action: ACTIONS.scenarioConditionSearch,
    label: `searchedValue:${searchedValue}`,
  });
}

export function gaScenarioHoverChart(chart: 'scatter' | 'prediction') {
  ReactGA.event({
    category: CATEGORIES.scenario,
    action:
      chart === 'scatter'
        ? ACTIONS.hoverScatterChart
        : ACTIONS.hoverPredictionChart,
    label: `interacted:true`,
  });
}

export function gaClickInsightCondition(title: string, aoCardType: string) {
  ReactGA.event({
    category: CATEGORIES.scenario,
    action: ACTIONS.clickInsightCondition,
    label: `title:${title};ao_card_type:${aoCardType}`,
  });
}
