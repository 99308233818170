import {
  cloneElement,
  forwardRef,
  MutableRefObject,
  ReactElement,
  useCallback,
} from 'react';

import { OptionalN } from '~/declarations/standard';

interface BypassRefProps {
  originRef: MutableRefObject<OptionalN<HTMLElement>>;
  children: OptionalN<ReactElement>;
}

export const BypassRef = forwardRef<HTMLElement, BypassRefProps>(
  ({ children, originRef }, ref) => {
    const refCallback = useCallback((el: HTMLElement | null) => {
      originRef.current = el;
      if (typeof ref === 'function') {
        ref(el);
      } else if (ref) {
        ref.current = el;
      }
    }, []);

    return (
      children &&
      cloneElement(children, {
        ref: refCallback,
      })
    );
  }
);
