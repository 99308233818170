import {
  borderRadius,
  Chip,
  Dropdown,
  DropdownList,
  DropdownProps,
} from '@toggle/design-system';
import styled from 'styled-components';

export const ChartTypeDropdown = styled(Dropdown)`
  ${DropdownList.Styled.DropdownListRoot} {
    width: 272px;
    overflow-y: hidden;
  }
` as <T>(props: DropdownProps<T>) => JSX.Element;

export const ChartTypeSelectorRoot = styled.div`
  display: flex;
  ${borderRadius.r2}
  padding: 4px;
  background-color: var(--surface-default);
  gap: 4px;
  & > ${Dropdown.Styled.Container} {
    justify-content: center;
  }
`;
export const StyledChip = styled(Chip)`
  height: 28px;
  width: 32px;
`;
