import {
  BuyingPowerResponse,
  buyingPowerResponseSchema,
  DisplayRuleStep,
  PlaceOrderResponse,
  placeOrderSchema,
  PreviewResponse,
  previewResponseSchema,
  replyOrderPayloadSchema,
  ReplyOrderResponse,
  TradeSearchResponse,
  tradeSearchSchema,
} from '~/api/trading/trading-schema';
import { config } from '~/config';
import {
  Expiration,
  OrderSide,
} from '~/global/trading-drawer/use-transaction/useTransaction';
import { HttpMethods } from '~/shared/services/api-fetch/constants';
import { validateApiFetch } from '~/shared/services/api-fetch/validate-api-fetch/validateApiFetch';

const {
  api: { root: apiRoot, wsBrokerRoot },
} = config;

export interface PreviewOrderPayload {
  login_id: string;
  security_id: string;
  quantity: number;
  side: OrderSide;
  currency: string;
  expiration: Expiration;
}

export interface PostOrderPayload extends PreviewOrderPayload {
  price: number;
}

export interface OrderUrlProps {
  brokerId: string;
  accountId: string;
  type: OrderType;
}

export interface BuyingPowerUrlProps {
  brokerId: string;
  accountId: string;
  loginId: string;
}

export interface DeleteOrderProps {
  loginId: string;
  brokerId: string;
  accountId: string;
  orderId: string;
}

export enum OrderType {
  Limit = 'limit',
  Market = 'market',
}

interface SearchAssetProps {
  providerId: string;
  searchValue: string;
  loginId: string;
}

interface SecuritiesInfoProps {
  providerId: string;
  securities: string;
  loginId: string;
}

export const tradingEndpoints = {
  postOrder: ({ brokerId, accountId, type }: OrderUrlProps) =>
    `${apiRoot}trading/v1/brokers/${brokerId}/accounts/${accountId}/live/${type}-orders`,
  previewOrder: ({
    brokerId,
    accountId,
    type,
  }: {
    brokerId: string;
    accountId: string;
    type: OrderType;
  }) =>
    `${apiRoot}trading/v1/brokers/${brokerId}/accounts/${accountId}/preview/${type}-orders`,
  deleteOrder: ({ orderId, brokerId, accountId, loginId }: DeleteOrderProps) =>
    `${apiRoot}trading/v1/brokers/${brokerId}/accounts/${accountId}/orders/${orderId}?login_id=${loginId}`,
  buyingPower: ({ brokerId, accountId, loginId }: BuyingPowerUrlProps) =>
    `${apiRoot}trading/v1/brokers/${brokerId}/accounts/${accountId}/buying-power?login_id=${loginId}`,
  replyOrder: (brokerId: string) =>
    `${apiRoot}trading/v1/brokers/${brokerId}/order-reply`,
  searchAsset: ({ providerId, searchValue, loginId }: SearchAssetProps) =>
    `${apiRoot}trading/v1/brokers/${providerId}/securities/search?q=${searchValue}&login_id=${loginId}`,
  securityInfo: ({ providerId, securities, loginId }: SecuritiesInfoProps) =>
    `${apiRoot}trading/v1/brokers/${providerId}/securities?securities=${securities}&login_id=${loginId}`,
  brokerWs: ({ brokerId, loginId }: { brokerId: string; loginId: string }) =>
    `${wsBrokerRoot}${brokerId}/v1/ws?login_id=${loginId}`,
};

export const postOrder = async (
  urlProps: OrderUrlProps,
  order: PostOrderPayload
) => {
  return validateApiFetch<PlaceOrderResponse>({
    endpoint: tradingEndpoints.postOrder(urlProps),
    schema: placeOrderSchema,
    requestInfo: {
      method: HttpMethods.Post,
      body: JSON.stringify(order),
    },
  });
};

export const postPreviewOrder = async (
  urlProps: OrderUrlProps,
  order: PreviewOrderPayload
) => {
  return validateApiFetch<PreviewResponse>({
    endpoint: tradingEndpoints.previewOrder(urlProps),
    schema: previewResponseSchema,
    requestInfo: {
      method: HttpMethods.Post,
      body: JSON.stringify(order),
    },
  });
};

export const getBuyingPower = (props: BuyingPowerUrlProps) => {
  return validateApiFetch<BuyingPowerResponse>({
    endpoint: tradingEndpoints.buyingPower(props),
    schema: buyingPowerResponseSchema,
  });
};

export interface ReplyOrderPayload {
  confirmation_id: string;
  login_id: string;
  confirm?: boolean;
}

export const postReplyOrder = (
  brokerId: string,
  payload: ReplyOrderPayload
) => {
  return validateApiFetch<ReplyOrderResponse>({
    endpoint: tradingEndpoints.replyOrder(brokerId),
    schema: replyOrderPayloadSchema,
    requestInfo: {
      method: HttpMethods.Post,
      body: JSON.stringify(payload),
    },
  });
};

export const deleteOrder = (props: DeleteOrderProps) => {
  return validateApiFetch<Blob>({
    endpoint: tradingEndpoints.deleteOrder(props),
    requestInfo: {
      method: HttpMethods.Delete,
    },
  });
};

type GetTradeSearchProps = SearchAssetProps & { abortSignal: AbortSignal };

export const getTradeSearch = async (props: GetTradeSearchProps) => {
  const { abortSignal, ...endpointProps } = props;

  return validateApiFetch<TradeSearchResponse>({
    endpoint: tradingEndpoints.searchAsset(endpointProps),
    schema: tradeSearchSchema,
    requestInfo: {
      signal: abortSignal,
    },
  });
};

export const getSecurityInfo = async (
  props: SecuritiesInfoProps
): Promise<DisplayRuleStep[]> => {
  try {
    const security = await validateApiFetch<TradeSearchResponse>({
      endpoint: tradingEndpoints.securityInfo(props),
      schema: tradeSearchSchema,
    });
    return security.securities[0].display_rule.display_rule_steps;
  } catch {
    return [];
  }
};
