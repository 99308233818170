import { SvgIconNames } from '@toggle/design-system';
import { ComponentType } from 'react';

import { Breadcrumb } from '~/shared/components/breadcrumbs/Breadcrumbs';
import { STORAGE_KEYS } from '~/shared/hooks/use-remote-storage';

export interface HelperConfigItem {
  translationKey: string;
  gaName: string;
  breadcrumbs?: Array<Breadcrumb & { linkTo?: HELPER_VIEW_IDS }>;
  wrapperHelperClassName?: string;
  Component: ComponentType<any>;
  allChecks?: string[];
  autoExpandableItemsIds?: STORAGE_KEYS[];
  proOnly?: boolean;
  icon?: SvgIconNames;
}

export type HelperViewConfigsType = {
  [key in HELPER_VIEW_IDS]?: HelperConfigItem;
};

export enum ANALYZE_SECTION_IDS {
  ANALYZE_INTRO_ID = 'ANALYZE_INTRO_ID',
  ANALYZE_MASTERCLASS_ID = 'ANALYZE_MASTERCLASS_ID',
  ANALYZE_ASSET_CHECK_ID = 'ANALYZE_ASSET_CHECK_ID',
  ANALYZE_ARROWS_CHECK_ID = 'ANALYZE_ARROWS_CHECK_ID',
  ANALYZE_WHAT_IS_MOVING_CHECK_ID = 'ANALYZE_WHAT_IS_MOVING_CHECK_ID',
  ANALYZE_KEYS_VALUES_CHECK_ID = 'ANALYZE_KEYS_VALUES_CHECK_ID',
  ANALYZE_NEWS_CHECK_ID = 'ANALYZE_NEWS_CHECK_ID',
  ANALYZE_RELATED_ASSETS_CHECK_ID = 'ANALYZE_RELATED_ASSETS_CHECK_ID',
}

const ANALYZE_CHECKS_TO_BE_ACTIVATED = [
  ANALYZE_SECTION_IDS.ANALYZE_ASSET_CHECK_ID,
  ANALYZE_SECTION_IDS.ANALYZE_ARROWS_CHECK_ID,
  ANALYZE_SECTION_IDS.ANALYZE_KEYS_VALUES_CHECK_ID,
  ANALYZE_SECTION_IDS.ANALYZE_WHAT_IS_MOVING_CHECK_ID,
  ANALYZE_SECTION_IDS.ANALYZE_NEWS_CHECK_ID,
  ANALYZE_SECTION_IDS.ANALYZE_RELATED_ASSETS_CHECK_ID,
];
export const ANALYZE_AUTO_EXPANDABLE_IDS = [
  ANALYZE_SECTION_IDS.ANALYZE_INTRO_ID,
];
export const ANALYZE_ALL_CHECKED = [
  ...ANALYZE_CHECKS_TO_BE_ACTIVATED,
  ANALYZE_SECTION_IDS.ANALYZE_INTRO_ID,
];

export enum EXPLORE_SECTION_IDS {
  EXPLORE_INTRO_ID = 'EXPLORE_INTRO_ID',
  EXPLORE_MASTERCLASS_ID = 'EXPLORE_MASTERCLASS_ID',
  EXPLORE_INSIGHTS_STATS_CHECK_ID = 'EXPLORE_INSIGHTS_STATS_CHECK_ID',
  EXPLORE_TOP_SCREENS_CHECK_ID = 'EXPLORE_TOP_SCREENS_CHECK_ID',
  EXPLORE_FILTERS_CHECK_ID = 'EXPLORE_FILTERS_CHECK_ID',
  EXPLORE_INSIGHT_CARD_CHECK_ID = 'EXPLORE_INSIGHT_CARD_CHECK_ID',
  EXPLORE_BOOKMARK_CHECK_ID = 'EXPLORE_BOOKMARK_CHECK_ID',
  EXPLORE_SAVED_FILTERS_CHECK_ID = 'EXPLORE_SAVED_FILTERS_CHECK_ID',
}

const EXPLORE_CHECKS_TO_BE_ACTIVATED = [
  EXPLORE_SECTION_IDS.EXPLORE_INSIGHTS_STATS_CHECK_ID,
  EXPLORE_SECTION_IDS.EXPLORE_TOP_SCREENS_CHECK_ID,
  EXPLORE_SECTION_IDS.EXPLORE_FILTERS_CHECK_ID,
  EXPLORE_SECTION_IDS.EXPLORE_INSIGHT_CARD_CHECK_ID,
  EXPLORE_SECTION_IDS.EXPLORE_BOOKMARK_CHECK_ID,
  EXPLORE_SECTION_IDS.EXPLORE_SAVED_FILTERS_CHECK_ID,
];
export const EXPLORE_AUTO_EXPANDABLE_IDS = [
  EXPLORE_SECTION_IDS.EXPLORE_INTRO_ID,
];
export const EXPLORE_ALL_CHECKED = [
  ...EXPLORE_CHECKS_TO_BE_ACTIVATED,
  EXPLORE_SECTION_IDS.EXPLORE_INTRO_ID,
];

export enum MY_TOGGLE_SECTION_IDS {
  MY_TOGGLE_CONNECT_PORTFOLIO_ID = 'MY_TOGGLE_CONNECT_PORTFOLIO_ID',
  MY_TOGGLE_SET_UP_WL_ID = 'MY_TOGGLE_SET_UP_WL_ID',
  MY_TOGGLE_MASTERCLASS_ID = 'MY_TOGGLE_MASTERCLASS_ID',
  MY_TOGGLE_ASSETS_CHECK_ID = 'MY_TOGGLE_ASSETS_CHECK_ID',
  MY_TOGGLE_FILTERS_CHECK_ID = 'MY_TOGGLE_FILTERS_CHECK_ID',
  MY_TOGGLE_OPEN_ASSET_OVERVIEW_ID = 'MY_TOGGLE_OPEN_ASSET_OVERVIEW_ID',
  MY_TOGGLE_DRIVERS_ID = 'MY_TOGGLE_DRIVERS_ID',
}

const MY_TOGGLE_CHECKS_TO_BE_ACTIVATED = [
  MY_TOGGLE_SECTION_IDS.MY_TOGGLE_ASSETS_CHECK_ID,
  MY_TOGGLE_SECTION_IDS.MY_TOGGLE_FILTERS_CHECK_ID,
  MY_TOGGLE_SECTION_IDS.MY_TOGGLE_OPEN_ASSET_OVERVIEW_ID,
  MY_TOGGLE_SECTION_IDS.MY_TOGGLE_DRIVERS_ID,
];
export const MY_TOGGLE_AUTO_EXPANDABLE_IDS = [
  MY_TOGGLE_SECTION_IDS.MY_TOGGLE_CONNECT_PORTFOLIO_ID,
  MY_TOGGLE_SECTION_IDS.MY_TOGGLE_SET_UP_WL_ID,
];
export const MY_TOGGLE_ALL_CHECKED = [
  ...MY_TOGGLE_CHECKS_TO_BE_ACTIVATED,
  ...MY_TOGGLE_AUTO_EXPANDABLE_IDS,
];

export enum INSIGHT_SECTION_IDS {
  INSIGHT_INTRO_ID = 'INSIGHT_INTRO_ID',
  INSIGHT_MASTERCLASS_ID = 'INSIGHT_MASTERCLASS_ID',
  INSIGHT_PROJECTION_CHART_ID = 'INSIGHT_PROJECTION_CHART_ID',
  INSIGHT_NAVIGATION_MENU_ID = 'INSIGHT_NAVIGATION_MENU_ID',
  INSIGHT_SUMMARY_ID = 'INSIGHT_SUMMARY_ID',
  INSIGHT_HISTORY_CHART_ID = 'INSIGHT_HISTORY_CHART_ID',
  INSIGHT_STARS_ID = 'INSIGHT_STARS_ID',
  INSIGHT_PAST_PERFORMANCE_ID = 'INSIGHT_PAST_PERFORMANCE_ID',
  INSIGHT_SHARING_INSIGHT_ID = 'INSIGHT_SHARING_INSIGHT_ID',
  INSIGHT_FULL_ASSET_PICTURE_ID = 'INSIGHT_FULL_ASSET_PICTURE_ID',
}

const INSIGHT_CHECKS_TO_BE_ACTIVATED = [
  INSIGHT_SECTION_IDS.INSIGHT_PROJECTION_CHART_ID,
  INSIGHT_SECTION_IDS.INSIGHT_NAVIGATION_MENU_ID,
  INSIGHT_SECTION_IDS.INSIGHT_SUMMARY_ID,
  INSIGHT_SECTION_IDS.INSIGHT_HISTORY_CHART_ID,
  INSIGHT_SECTION_IDS.INSIGHT_STARS_ID,
  INSIGHT_SECTION_IDS.INSIGHT_PAST_PERFORMANCE_ID,
  INSIGHT_SECTION_IDS.INSIGHT_SHARING_INSIGHT_ID,
  INSIGHT_SECTION_IDS.INSIGHT_FULL_ASSET_PICTURE_ID,
];
export const INSIGHT_AUTO_EXPANDABLE_IDS = [
  INSIGHT_SECTION_IDS.INSIGHT_INTRO_ID,
];
export const INSIGHT_ALL_CHECKED = [
  ...INSIGHT_CHECKS_TO_BE_ACTIVATED,
  INSIGHT_SECTION_IDS.INSIGHT_INTRO_ID,
];

export enum ONBOARDING_SECTION_IDS {
  ONBOARDING_QUESTIONNAIRE_ID = 'ONBOARDING_QUESTIONNAIRE_ID',
  ONBOARDING_HELLO_ID = 'ONBOARDING_HELLO_ID',
}

export const ONBOARDING_AUTO_EXPANDABLE_IDS = [
  ONBOARDING_SECTION_IDS.ONBOARDING_HELLO_ID,
];

const ONBOARDING_CHECKS_TO_BE_ACTIVATED = [
  ONBOARDING_SECTION_IDS.ONBOARDING_QUESTIONNAIRE_ID,
];

export const ONBOARDING_ALL_CHECKED = [
  ...ONBOARDING_CHECKS_TO_BE_ACTIVATED,
  // TODO: we will come back to it when we have a video
  // ONBOARDING_SECTION_IDS.ONBOARDING_HELLO_ID,
];

export enum SCENARIO_SECTION_IDS {
  MASTERCLASS_ID = 'SCENARIO_MASTERCLASS_ID',
  SELECT_ASSET_ID = 'SCENARIO_SELECT_ASSET_ID',
  ADD_CONDITIONS_ID = 'SCENARIO_ADD_CONDITIONS_ID',
  QUALITY_CHECK_ID = 'SCENARIO_QUALITY_CHECK_ID',
  RESULTS_ID = 'SCENARIO_RESULTS_ID',
  PAST_EPISODES_ID = 'SCENARIO_PAST_EPISODES_ID',
}

const SCENARIO_CHECKS_TO_BE_ACTIVATED = [
  SCENARIO_SECTION_IDS.SELECT_ASSET_ID,
  SCENARIO_SECTION_IDS.ADD_CONDITIONS_ID,
  SCENARIO_SECTION_IDS.QUALITY_CHECK_ID,
  SCENARIO_SECTION_IDS.RESULTS_ID,
  SCENARIO_SECTION_IDS.PAST_EPISODES_ID,
];
export const SCENARIO_ALL_CHECKED = [...SCENARIO_CHECKS_TO_BE_ACTIVATED];

export enum FAQ_SECTION_IDS {
  FAQ_INSIGHTS_ID = 'FAQ_INSIGHTS_ID',
  FAQ_INVEST_ID = 'FAQ_INVEST_ID',
  FAQ_ANALYZE_ID = 'FAQ_ANALYZE_ID',
  FAQ_ASSETS_COVER_ID = 'FAQ_ASSETS_COVER_ID',
  FAQ_NO_INSIGHTS_ID = 'FAQ_NO_INSIGHTS_ID',
  FAQ_PORTFOLIO_ID = 'FAQ_PORTFOLIO_ID',
  FAQ_BROKER_COVER_ID = 'FAQ_BROKER_COVER_ID',
  FAQ_CONNECT_PORTFOLIO_ID = 'FAQ_CONNECT_PORTFOLIO_ID',
  FAQ_PRIVACY_ID = 'FAQ_PRIVACY_ID',
  FAQ_WHAT_ACTIONABLE_ID = 'FAQ_WHAT_ACTIONABLE_ID',
  FAQ_DATA_SOURCE_ID = 'FAQ_DATA_SOURCE_ID',
}

export const highlightSectionClass = 'highlightSection';

export enum HELPER_VIEW_IDS {
  HOME = 'HELPER_HOME_VIEW_ID',
  EXPLORE = 'HELPER_EXPLORE_VIEW_ID',
  ANALYZE = 'HELPER_ANALYZE_VIEW_ID',
  SCENARIO = 'HELPER_SCENARIO_VIEW_ID',
  MYTOGGLE = 'HELPER_MYTOGGLE_VIEW_ID',
  INSIGHT = 'HELPER_INSIGHT_VIEW_ID',
  ONBOARDING = 'HELPER_ONBOARDING_VIEW_ID',
  PORTFOLIO = 'HELPER_PORTFOLIO_VIEW_ID',
  GLOSSARY = 'HELPER_GLOSSARY_VIEW_ID',
  FAQ = 'HELPER_FAQ_VIEW_ID',
  SUPPORT = 'HELPER_SUPPORT_VIEW_ID',
}

export type helperViewKeysList = {
  [key in HELPER_VIEW_IDS]?: string;
};

interface HelperViewKeysInterface {
  main: helperViewKeysList;
  additional: helperViewKeysList;
}

export const helperViewKeys: HelperViewKeysInterface = {
  main: {
    [HELPER_VIEW_IDS.ONBOARDING]: 'onboarding',
    [HELPER_VIEW_IDS.EXPLORE]: 'explore',
    [HELPER_VIEW_IDS.ANALYZE]: 'analyze',
    [HELPER_VIEW_IDS.SCENARIO]: 'scenario',
    [HELPER_VIEW_IDS.MYTOGGLE]: 'myToggle',
    [HELPER_VIEW_IDS.INSIGHT]: 'insight',
    // @TODO: uncomment when corresponding portfolio content will be created
    // [HELPER_VIEW_IDS.PORTFOLIO]: 'portfolio',
  },
  additional: {
    [HELPER_VIEW_IDS.GLOSSARY]: 'glossary',
    [HELPER_VIEW_IDS.FAQ]: 'faq',
    [HELPER_VIEW_IDS.SUPPORT]: 'support',
  },
};

export enum HELPER_VIDEO_LINKS {
  EXPLORE_LINK = 'https://www.youtube.com/embed/NJyI_d6zuU8',
  INSIGHT_LINK = 'https://www.youtube.com/embed/AVQa1pLu_nc',
  ANALYZE_LINK = 'https://www.youtube.com/embed/KszsaurkJn8',
  ONBOARDING_LINK = '',
  FAQ_INSIGHT_LINK = 'https://www.youtube.com/embed/yKEaKMUFjHU',
  FAQ_INVEST_LINK = 'https://www.youtube.com/embed/wPo0zC6doSc',
  FAQ_WHAT_ACTIONABLE_LINK = 'https://www.youtube.com/embed/kvieOkx998I',
}

export type AllChecksToBeActivated =
  | typeof ANALYZE_CHECKS_TO_BE_ACTIVATED[number]
  | typeof SCENARIO_CHECKS_TO_BE_ACTIVATED[number]
  | typeof EXPLORE_CHECKS_TO_BE_ACTIVATED[number]
  | typeof MY_TOGGLE_CHECKS_TO_BE_ACTIVATED[number]
  | typeof INSIGHT_CHECKS_TO_BE_ACTIVATED[number];

export const GLOSSARY_TRANSLATION_KEY_ITEMS = [
  'A',
  'H',
  'I',
  'J',
  'K',
  'Q',
  'W',
  'X',
  'Z',
  'DividendYield',
  'BVSAnalystEstimates',
  'BVS12MForwardGrowth',
  'BVS12MForwardGrowth%',
  'BVS1MAnalystRevisionsDown',
  'BVS1MAnalystRevisionsUp',
  'BVSForwardChangeIn3M',
  'CapexAnalystEstimates',
  'Capex1MAnalystRevisionsDown',
  'Capex1MAnalystRevisionsUp',
  'Carry',
  'CashEPS12MForwardGrowth',
  'CashEPS12MForwardGrowth%',
  'CashEPSForwardChangeIn3M',
  'CFSAnalystEstimates',
  'CF/S12MForwardGrowth',
  'CF/S12MForwardGrowth%',
  'CF/S1MAnalystRevisionsDown',
  'CF/S1MAnalystRevisionsUp',
  'CF/SForwardChangein3M',
  'Consecutiveupdays',
  'DPS#AnalystEstimates',
  'DPS12MForwardGrowth',
  'DPS12MForwardGrowth%',
  'DPS1MAnalystRevisionsDown',
  'DPS1MAnalystRevisionsUp',
  'DPSForwardChangein3M',
  'equityRiskPremiumForward',
  'equityRiskPremiumTrailing',
  'earningsYieldForward',
  'earningsYieldTrailing',
  'EBITDA#AnalystEstimates',
  'EBITDA12MForwardGrowth',
  'EBITDA12MForwardGrowth%',
  'EBITDA1MAnalystRevisionsDown',
  'EBITDA1MAnalystRevisionsUp',
  'EBITDAForwardChangein3M',
  'EPS#AnalystEstimates',
  'EPS12MForwardGrowth',
  'EPS12MForwardGrowth%',
  'EPS1MAnalystRevisionsDown',
  'EPS1MAnalystRevisionsUp',
  'EPSForwardChangein3M',
  'ETFNAV',
  'FCF/S12MForwardGrowth',
  'FCF/S12MForwardGrowth%',
  'FCF/SForwardChangein3M',
  'ForwardBV/S',
  'ForwardCapex',
  'ForwardCashEPS',
  'ForwardCF/S',
  'ForwardDPS',
  'ForwardEBITDA',
  'ForwardEPS',
  'ForwardEPS2ndFiscalYear',
  'ForwardEPSNextFiscalYear',
  'ForwardFCF/S',
  'ForwardGAAPEPS',
  'ForwardP/B',
  'ForwardP/E',
  'ForwardSales',
  'GAAPEPS12MForwardGrowth',
  'GAAPEPS12MForwardGrowth%',
  'GAAPEPSForwardChangein3M',
  'Longfuturespositioning',
  'LongTermGrowth',
  'MarketCap',
  'Momentum',
  'NetFuturesPositioning',
  'NumberOfShares',
  'OptionAdjustedSpread',
  'P/CashEarnings',
  'Percentageof+veBV/SRevisions',
  'Percentageof+veCapExRevisions',
  'Percentageof+veCF/SRevisions',
  'Percentageof+veDPSRevisions',
  'Percentageof+veEBITDARevisions',
  'Percentageof+veEPSRevisions',
  'Percentageof+veSalesRevisions',
  'PremiumtoNAV',
  'PremiumtoPMIFairValue',
  'PremiumtoRatesFairValue',
  'Price',
  'PriceHigh',
  'PriceLow',
  'PriceMACD',
  'PriceOpen',
  'PriceRSI',
  'RealizedVolatility',
  'Return/Volatility',
  'Sales#AnalystEstimates',
  'Sales12MForwardGrowth',
  'Sales12MForwardGrowth%',
  'Sales1MAnalystRevisionsDown',
  'Sales1MAnalystRevisionsUp',
  'SalesForwardChangein3M',
  'SeasonalityReturns',
  'Shortfuturespositioning',
  'StochasticD',
  'StochasticK',
  'StochasticKDspread',
  'SurpriseMove',
  'TotalReturn',
  'TradeWeightedPrice',
  'TradeWeightedPrice(DM)',
  'TradeWeightedPrice(EM)',
  'TrailingBV/S',
  'TrailingCapex',
  'TrailingCashEPS',
  'TrailingCF/S',
  'TrailingDPS',
  'TrailingEBITDA',
  'TrailingEPS',
  'TrailingFCF/S',
  'TrailingGAAPEPS',
  'TrailingP/B',
  'TrailingP/E',
  'TrailingSales',
  'UnadjustedVolume',
  'Volume',
  'YieldMACD',
  'YieldRSI',
  'YieldtoMaturity',
  'YieldtoRedemption',
  'YieldtoWorst',
];
