import styled, { css, keyframes } from 'styled-components';

import { borderRadius } from '~/design-tokens/border-radius/borderRadius';
import { typographyV2 } from '~/design-tokens/typography/Typography.styles';

import type { Size, Variant } from './Button';

export const ButtonRoot = styled.button<{
  variant: Variant;
  size?: Size;
  iconOnly?: boolean;
  fullWidth?: boolean;
  ref: React.Ref<HTMLButtonElement> | null;
}>`
  ${borderRadius.r1};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  white-space: nowrap;
  border: none;

  :disabled {
    cursor: default;
  }

  ${({ size }) => setSize(size)};
  ${({ variant }) => setColors(variant)};

  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
    `}

  ${({ iconOnly }) =>
    iconOnly &&
    css`
      padding: 0px;
    `}
`;

export const Label = styled.span<{ isReversed: boolean }>`
  display: flex;
  align-items: center;
  gap: 8px;
  flex-direction: ${({ isReversed }) => (isReversed ? 'row-reverse' : 'row')};
`;

export const Loading = styled.span`
  width: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Text = styled.span`
  color: inherit;
`;

const spin = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

export const Spinner = styled.div`
  width: 16px;
  height: 16px;
  border: 2px solid currentColor;
  border-top-color: transparent;
  border-radius: 50%;
  animation: ${spin} 1.5s linear infinite;
`;

const setSize = (size?: Size) => {
  switch (size) {
    case 'xsmall':
      return css`
        ${typographyV2.uiSm};
        min-width: 20px;
        height: 20px;
        padding: 6px 12px;
      `;
    case 'small':
      return css`
        ${typographyV2.uiSm};
        min-width: 32px;
        height: 32px;
        padding: 6px 12px;
      `;

    case 'medium':
      return css`
        ${typographyV2.uiSm};
        min-width: 36px;
        height: 36px;
        padding: 8px 12px;
      `;
    case 'large':
      return css`
        ${typographyV2.uiBase};
        min-width: 40px;
        height: 40px;
        padding: 8px 12px;
      `;
    case 'xl':
      return css`
        ${typographyV2.uiBase};
        min-width: 48px;
        height: 48px;
        padding: 12px;
      `;
    default:
      return '';
  }
};

const setColors = (variant: Variant) => {
  switch (variant) {
    case 'primary':
      return css`
        background-color: var(--action-primary-default);
        color: var(--text-on-primary);
        border: 1px solid transparent;

        svg {
          fill: var(--icon-on-primary);
        }

        &:hover {
          background-color: var(--action-primary-hover);
        }

        &:focus-visible {
          outline: 2px solid var(--border-focus);
          border: 1px solid var(--surface-default);
        }

        :disabled {
          background-color: var(--action-primary-disabled);
          color: var(--text-disabled);

          svg {
            fill: var(--icon-disabled);
          }
        }
      `;

    case 'secondary':
      return css`
        background-color: transparent;
        color: var(--text-primary);
        border: 1px solid var(--border-primary);

        svg {
          fill: var(--icon-primary);
        }

        &:hover {
          background-color: var(--action-primary-soft-hover);
          color: var(--text-primary);
        }

        &:focus-visible {
          outline: 2px solid var(--border-focus);
          border: 1px solid transparent;
        }

        :disabled {
          border: 1px solid var(--border-disabled);
          background-color: transparent;
          color: var(--text-disabled);

          svg {
            fill: var(--icon-disabled);
          }
        }
      `;

    case 'tertiary':
      return css`
        background-color: transparent;
        color: var(--text-default);
        border: 1px solid var(--border-default);

        svg {
          fill: var(--icon-default);
        }

        &:hover {
          background-color: var(--surface-hover);
          border: 1px solid var(--border-hover);
        }

        &:focus-visible {
          outline: 2px solid var(--border-focus);
          border: 1px solid transparent;
        }

        :disabled {
          border: 1px solid var(--border-disabled);
          background-color: transparent;
          color: var(--text-disabled);

          svg {
            fill: var(--icon-disabled);
          }
        }
      `;

    case 'empty':
      return css`
        color: var(--text-default);
        background-color: transparent;
        border: 1px solid transparent;

        svg {
          fill: var(--icon-default);
        }

        &:hover {
          background-color: var(--surface-hover);
        }

        &:focus-visible {
          outline: 2px solid var(--border-focus);
        }

        :disabled {
          border: 1px solid var(--border-disabled);
          background-color: transparent;
          color: var(--text-disabled);

          svg {
            fill: var(--icon-disabled);
          }
        }
      `;

    case 'critical':
      return css`
        background-color: var(--action-critical-default);
        color: var(--text-on-critical);
        border: 1px solid transparent;

        svg {
          fill: var(--icon-on-critical);
        }

        &:hover {
          background-color: var(--action-critical-hover);
        }

        &:focus-visible {
          outline: 2px solid var(--border-focus);
          border: 1px solid var(--surface-default);
        }

        :disabled {
          background-color: var(--action-critical-disabled);
          color: var(--text-disabled);

          svg {
            fill: var(--icon-disabled);
          }
        }
      `;

    default:
      return '';
  }
};
