import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { formatAssetPrice } from '~/shared/utils/currency/currency';

import { useTradingBrokerWs } from '../../use-broker-ws/useTradingBrokerWs';
import * as S from './BidMidAskPopup.styles';

export interface BidMidAskPopupProps {
  sendAutocompleteClick: (value: number) => void;
  currency: string;
}

const Pill = ({ label, type }: { label: string; type: S.PillType }) => (
  <S.AutocompletePill type={type}>
    <S.Dot type={type} />
    {label}
  </S.AutocompletePill>
);

export const BidMidAskPopup: FC<BidMidAskPopupProps> = ({
  sendAutocompleteClick,
  currency,
}) => {
  const { t } = useTranslation('portfolio');
  const assetLivePrice = useTradingBrokerWs(
    state => Object.values(state.assetLivePrices)[0]
  );

  if (!assetLivePrice) {
    return null;
  }

  const bidWithCurrency = formatAssetPrice(assetLivePrice.bid, currency);
  const midWithCurrency = formatAssetPrice(assetLivePrice.mid, currency);
  const askWithCurrency = formatAssetPrice(assetLivePrice.ask, currency);
  const lastWithCurrency = formatAssetPrice(assetLivePrice.last, currency);

  return (
    <S.AutocompletePopup data-testid="autocomplete-popup">
      {assetLivePrice?.frozen ? (
        <>
          <Pill label={t('portfolio:marketClose')} type="close" />
          <S.AutocompletePopupText
            data-testid="frozen-price"
            onClick={() => sendAutocompleteClick(assetLivePrice.bid)}
          >
            {t('portfolio:last', {
              value: lastWithCurrency,
            })}
          </S.AutocompletePopupText>
        </>
      ) : (
        <>
          <Pill
            label={
              assetLivePrice.delayed
                ? t('portfolio:delayed')
                : t('portfolio:live')
            }
            type={assetLivePrice.delayed ? 'delayed' : 'live'}
          />
          <S.AutocompletePopupText
            data-testid="bid-text"
            onClick={() => sendAutocompleteClick(assetLivePrice.bid)}
          >
            {t('portfolio:bid', {
              value: bidWithCurrency,
            })}
          </S.AutocompletePopupText>
          <S.AutocompletePopupDivider />
          <S.AutocompletePopupText
            data-testid="mid-text"
            onClick={() => sendAutocompleteClick(assetLivePrice.mid)}
          >
            {t('portfolio:mid', {
              value: midWithCurrency,
            })}
          </S.AutocompletePopupText>
          <S.AutocompletePopupDivider />
          <S.AutocompletePopupText
            data-testid="ask-text"
            onClick={() => sendAutocompleteClick(assetLivePrice.ask)}
          >
            {t('portfolio:ask', {
              value: askWithCurrency,
            })}
          </S.AutocompletePopupText>
        </>
      )}
    </S.AutocompletePopup>
  );
};
