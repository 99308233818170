import { z } from 'zod';

export const IB_OAUTH_CODE_EVENT = 'IB_OAUTH_CODE_EVENT';

const oAuthIBPayloadSchema = z.object({
  request_token: z.string(),
  verifier_token: z.string(),
});

export const oAuthIBResponseSchema = z.object({
  result: z.object({
    login_url: z.string(),
  }),
});

export type OAuthIBPayload = z.infer<typeof oAuthIBPayloadSchema>;
export type OAuthIBResponse = z.infer<typeof oAuthIBResponseSchema>;
