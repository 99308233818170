import { Button } from '@toggle/design-system';
import { keyboard } from '@toggle/helpers';
import { TFunction } from 'i18next';
import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import IBLogo from '~/assets/core/ib-logo-small.svg';
import TradestationLogo from '~/assets/portfolio/tradestation-logo.svg';
import { SearchAutocomplete } from '~/global/app-search/search/components/search-autocomplete-item/SearchAutocompleteItem';
import { getModalTypeByEntity } from '~/global/paywall/services/paywall-service/paywallService';
import { usePaywallStore } from '~/global/paywall/stores/usePaywallStore';
import { appPaths, queryKeys } from '~/routes/app-paths';
import { useTouchDevice } from '~/shared/hooks/MediaQueryHook';
import {
  gaClickSearchTab,
  gaClickSearchToTradeButton,
} from '~/shared/utils/ganalytics/commonGA';
import { gaScenarioSendAnalyzedAsset } from '~/shared/utils/ganalytics/scenarioGA';
import {
  SearchTabsOptions,
  useGlobalSearch,
} from '~/stores/use-global-search/useGlobalSearch';
import { Portfolio, usePortfolio } from '~/stores/use-portfolio/usePortfolio';
import {
  mapSearchTabToTradingProvider,
  mapTradingProviderToSearchTab,
  TradingProvider,
} from '~/stores/use-portfolio/utils/use-portfolio-utils';
import { useSubscription } from '~/stores/use-subscription/useSubscription';
import { AnalyzeViews } from '~/views/analyze/AnalyzeViews';

import { Search } from '../search/Search';
import { SearchTabs } from '../search-tabs/SearchTabs';
import { TradeSearch } from '../trade-search/TradeSearch';

export interface GeneralSearchProps {
  onSearch: () => void;
  onFocus?: () => void;
  isMobile: boolean;
  isTradingEnabled: boolean;
}

const getTabs = (
  t: TFunction,
  mapTradingProviderToPortfolios: Record<TradingProvider, Portfolio[]>
) => {
  const tabs = [
    {
      tabLabel: t('header:search.insights'),
      imgNode: (
        <img
          src={'/app/assets/core/mobile-app-copilot-icon.png'}
          alt={t('header:logo.altImage')}
        />
      ),
      tabKey: SearchTabsOptions.Insights,
    },
  ];

  if (mapTradingProviderToPortfolios[TradingProvider.Ib].length) {
    tabs.push({
      tabLabel: t('portfolio:tradingProvider', { context: TradingProvider.Ib }),
      imgNode: <IBLogo />,
      tabKey: SearchTabsOptions.Ib,
    });
  }

  if (mapTradingProviderToPortfolios[TradingProvider.Tradestation].length) {
    tabs.push({
      tabLabel: t('portfolio:tradingProvider', {
        context: TradingProvider.Tradestation,
      }),
      imgNode: (
        <TradestationLogo
          width={20}
          height={20}
          alt={t('settings:portfolio.tsPortfolio.logoAlt')}
        />
      ),
      tabKey: SearchTabsOptions.Tradestation,
    });
  }

  return tabs;
};

export const GeneralSearch: FC<GeneralSearchProps> = ({
  onSearch,
  onFocus,
  isMobile,
  isTradingEnabled,
}) => {
  const { t } = useTranslation(['analyze', 'settings', 'portfolio']);
  const {
    closeSearch,
    withWatchlistBtn,
    navigationUrl,
    isWatchlistOnly,
    setActiveTab,
    activeTab,
    tradeSearchInitialValue,
    setTradeSearchInitialValue,
  } = useGlobalSearch();
  const { isBasic } = useSubscription(({ isBasic }) => ({
    isBasic: isBasic(),
  }));
  const openModal = usePaywallStore(state => state.openModal);
  const isTouchDevice = useTouchDevice();

  const { getPortfoliosByProvider, getConnectedTradingProviders } =
    usePortfolio(s => ({
      getPortfoliosByProvider: s.getPortfoliosByProvider,
      getConnectedTradingProviders: s.getConnectedTradingProviders,
    }));
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const handleEsc = (event: KeyboardEvent) => {
      if (event.key === keyboard.esc.key) {
        closeSearch();
      }
    };
    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
      setActiveTab(SearchTabsOptions.Insights);
    };
  }, []);

  useEffect(() => {
    if (activeTab === SearchTabsOptions.Insights) {
      setTradeSearchInitialValue('');
    }
  }, [activeTab]);

  const navigationCallback = (item: SearchAutocomplete) => {
    const { tag, entity } = item;
    const experimentQuery = `?${queryKeys.view}=${AnalyzeViews.SCENARIO}`;
    const withExperimentQuery = location.search === experimentQuery;
    if (withExperimentQuery) {
      gaScenarioSendAnalyzedAsset(tag);
    }

    if (entity?.gated) {
      const modalType = getModalTypeByEntity(
        {
          asset_class: entity.asset_class,
          sub_class: entity.sub_class,
          country: entity.country,
        },
        isBasic
      );
      openModal(modalType);
    } else {
      navigate(
        navigationUrl ||
          `${appPaths.analyze}/${tag}${
            withExperimentQuery ? experimentQuery : ''
          }`
      );
      onSearch();
    }
  };

  const onTabChange = (activeTab: SearchTabsOptions) => {
    setActiveTab(activeTab);
    gaClickSearchTab(activeTab);
  };

  const openTradeSearch = (
    initialValue: string,
    tradingProvider: TradingProvider,
    searchResultsCount: number
  ) => {
    setActiveTab(mapTradingProviderToSearchTab[tradingProvider]);
    setTradeSearchInitialValue(initialValue);
    gaClickSearchToTradeButton(initialValue, searchResultsCount);
  };

  const onSearchValueClear = () => {
    setTradeSearchInitialValue('');
  };

  const mapTradingProviderToPortfolios = {
    [TradingProvider.Ib]: getPortfoliosByProvider(TradingProvider.Ib),
    [TradingProvider.Tradestation]: getPortfoliosByProvider(
      TradingProvider.Tradestation
    ),
  };
  const tabs = getTabs(t, mapTradingProviderToPortfolios);
  const tradingProvider = mapSearchTabToTradingProvider[activeTab];
  const connectedTradingProviders = getConnectedTradingProviders();

  return (
    <>
      {isTradingEnabled && (
        <SearchTabs
          isTouchDevice={isTouchDevice}
          activeTab={activeTab}
          tabs={tabs}
          onChange={onTabChange}
          closeSearch={closeSearch}
        />
      )}
      {activeTab === SearchTabsOptions.Insights && (
        <Search
          autocompleteProps={{
            shouldCloseOnOutsideClick: false,
          }}
          searchAutocompleteItemProps={{
            withWatchlistButton: withWatchlistBtn,
            componentOnItemEnd:
              isMobile || isWatchlistOnly
                ? undefined
                : () => (
                    <Button
                      data-testid="asset-overview-button"
                      size="medium"
                      label={t('analyze:sidebarMenu.overview')}
                    />
                  ),
          }}
          navigationCallback={navigationCallback}
          searchInputProps={
            isMobile
              ? undefined
              : {
                  autoFocus: true,
                }
          }
          onFocus={onFocus}
          isWatchlistOnly={isWatchlistOnly}
          onChange={setTradeSearchInitialValue}
          onClear={onSearchValueClear}
          openTradeSearch={openTradeSearch}
          showTradeMessage={isTradingEnabled}
          showPlaceholder
          showResultsDivider
          handleClose={isMobile ? () => closeSearch() : undefined}
          tradingProviders={connectedTradingProviders}
        />
      )}
      {tradingProvider &&
        !!mapTradingProviderToPortfolios[tradingProvider].length && (
          <TradeSearch
            isMobile={isMobile}
            initialValue={tradeSearchInitialValue}
            tradingPortfolios={mapTradingProviderToPortfolios[tradingProvider]}
            tradingProvider={tradingProvider}
            location={location}
            closeSearch={closeSearch}
          />
        )}
    </>
  );
};
