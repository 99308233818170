import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { StripeCardElementChangeEvent } from '@stripe/stripe-js';
import React, { forwardRef, useImperativeHandle, useState } from 'react';

import * as S from './StripeForm.styles';

export interface StripeFormProps {
  ready: boolean;
  inputError?: string;
  clientSecret: string;
  onReady: () => void;
  handleFormChange: (event: StripeCardElementChangeEvent) => void;
}

export const StripeForm = forwardRef<any, StripeFormProps>(
  ({ ready, onReady, inputError, clientSecret, handleFormChange }, ref) => {
    const [focus, setFocus] = useState(false);
    const stripe = useStripe();
    const elements = useElements();

    const handleSubmit = async () => {
      const cardElement = elements?.getElement(CardElement);
      if (!stripe || !elements || !cardElement) {
        return null;
      }

      const payload = await stripe.confirmCardSetup(clientSecret, {
        payment_method: {
          card: cardElement,
        },
      });

      if (payload.error) {
        return payload.error;
      }

      if (
        payload.setupIntent.status === 'succeeded' &&
        payload.setupIntent.client_secret
      ) {
        return payload.setupIntent.client_secret;
      }

      return null;
    };

    /**
     * This is required to call onSubmit function in parent component. useStripe hook can only be called under Elements component.
     * We need this callback in the parent component, which is the modal, to call it on primary btn click or "Enter" key
     */
    useImperativeHandle(
      ref,
      () => ({
        onSubmit: handleSubmit,
      }),
      [ready, elements]
    );

    return (
      <S.Container>
        <S.InputContainer
          data-testid="stripe-input"
          $error={!!inputError}
          $focused={focus}
        >
          <CardElement
            onChange={handleFormChange}
            onReady={onReady}
            options={{ style: S.CardStyle }}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
          />
        </S.InputContainer>
        {inputError && (
          <S.ErrorLabel data-testid="error-text">{inputError}</S.ErrorLabel>
        )}
      </S.Container>
    );
  }
);
