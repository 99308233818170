import { subDays } from 'date-fns';
import { TFunction } from 'i18next';
import uniqBy from 'lodash/uniqBy';
import { MutableRefObject, useEffect, useRef, useState } from 'react';

import { TSDatum } from '~/api/timeseries/timeseries-schema';
import { OptionalN, OptionalU } from '~/declarations/standard';
import { ArticleSummary, ExploreFeedResponse } from '~/declarations/toggle-api';
import { useAPI } from '~/shared/hooks/use-api/useAPI';
import { MappedEntity } from '~/shared/hooks/use-entities';
import { fetchFeed } from '~/shared/services/feed/feed';
import {
  gaAnalyzeClickDisplayInsights,
  gaAnalyzeClickPastInsights,
} from '~/shared/utils/ganalytics';
import {
  articleStarsFilter,
  getArticleDisplayDate,
  mapArticle,
  MappedArticle,
} from '~/views/analyze/utils/insights-helpers';

export const MONTHS_TO_SHOW_PAST_INSIGHTS = 6;

const isSubdomainOfPrevLoadedDomains = (
  oldDomains: [Date, Date][],
  newDomain: [Date, Date]
) => {
  let subdomain: OptionalN<[Date, Date]> = null;

  for (const domain of oldDomains) {
    const isFullSubdomain =
      newDomain[0] >= domain[0] && newDomain[1] <= domain[1];
    const isPartialSubdomain = !(
      newDomain[0] >= domain[1] || newDomain[1] <= domain[0]
    );

    if (isFullSubdomain) {
      return true;
    } else if (isPartialSubdomain) {
      const firstDate: Date =
        !subdomain || subdomain[0] >= domain[0] ? domain[0] : subdomain[0];
      const secondDate: Date =
        !subdomain || subdomain[1] <= domain[1] ? domain[1] : subdomain[1];

      subdomain = [firstDate, secondDate];
    }
  }

  return (
    !!subdomain && newDomain[0] >= subdomain[0] && newDomain[1] <= subdomain[1]
  );
};

export interface UsePastInsightParams {
  entity: MappedEntity;
  domain: null | [Date, Date];
  articles: ArticleSummary[];
  ts: TSDatum[];
  t: TFunction;
}

export const usePastInsight = ({
  entity,
  domain,
  articles,
  ts,
  t,
}: UsePastInsightParams) => {
  const [showPastInsights, setShowPastInsights] = useState(false);
  const prevDomain: MutableRefObject<[Date, Date] | null> = useRef(null);
  const domainsWithLoadedInsights = useRef<[Date, Date][]>([]);
  const [displayInsightsBtnVisible, setDisplayInsightsBtnVisible] =
    useState(true);

  useEffect(() => {
    return () => {
      setShowPastInsights(false);
      setDisplayInsightsBtnVisible(true);
      pastInsights.resetState();
      domainsWithLoadedInsights.current = [];
    };
  }, [entity]);

  useEffect(() => {
    const domainChanged =
      !!prevDomain.current && !!domain && prevDomain.current !== domain;

    if (domainChanged && showPastInsights) {
      const mayHaveUnloadedInsights = !isSubdomainOfPrevLoadedDomains(
        domainsWithLoadedInsights.current,
        domain
      );
      setDisplayInsightsBtnVisible(mayHaveUnloadedInsights);
    }

    prevDomain.current = domain;
  }, [domain, showPastInsights]);

  const onSwitchClick = (isSwitchTurnedOn: boolean) => {
    gaAnalyzeClickPastInsights(isSwitchTurnedOn);
    setShowPastInsights(isSwitchTurnedOn);
    setDisplayInsightsBtnVisible(!isSwitchTurnedOn);
    if (!isSwitchTurnedOn) {
      domainsWithLoadedInsights.current = [];
      pastInsights.resetState();
    }
  };

  const onDisplayInsightsBtnClick = () => {
    gaAnalyzeClickDisplayInsights();
    setDisplayInsightsBtnVisible(false);
    setShowPastInsights(true);
    if (showPastInsights) {
      pastInsights.refetch();
    }
  };

  const pastInsightsFetch = async (abort?: AbortSignal) => {
    if (domain) {
      const firstActiveInsightDate =
        articles.length > 0
          ? getArticleDisplayDate(articles[articles.length - 1])
          : null;
      const startDate =
        !firstActiveInsightDate || domain[0] < firstActiveInsightDate
          ? domain[0]
          : null;
      const endDate =
        !firstActiveInsightDate || domain[1] < firstActiveInsightDate
          ? domain[1]
          : subDays(firstActiveInsightDate, 1);

      if (startDate && entity) {
        domainsWithLoadedInsights.current.push(domain);

        return fetchFeed({
          entityTag: entity.tag,
          startDate,
          endDate,
          abort,
        });
      }
    }

    return undefined;
  };

  const mapDataFn = (
    next: ExploreFeedResponse | undefined,
    current: MappedArticle[]
  ): MappedArticle[] => {
    if (!ts.length) {
      return [];
    }

    if (next) {
      return uniqBy(
        current.concat(
          next.result
            .filter(articleStarsFilter)
            .map(article => mapArticle({ entity, article, ts, t }))
        ),
        s => s.id
      );
    }

    return current;
  };

  const pastInsights = useAPI<
    OptionalU<ExploreFeedResponse>,
    MappedArticle[],
    MappedArticle[]
  >(pastInsightsFetch, {
    shouldFetch: showPastInsights,
    mapDataFn,
    initialState: { loading: false, data: [] },
  });

  return {
    showPastInsights,
    pastInsights,
    onSwitchClick,
    onDisplayInsightsBtnClick,
    displayInsightsBtnVisible,
  };
};
