import { ButtonLink, deviceConstraints } from '@toggle/design-system';
import styled from 'styled-components';

export const ImageOverlayFigcaption = styled.figcaption`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-height: 48px;
  opacity: 0;
  background-color: var(--base--100);
  transition: opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  padding: 12px 16px;

  @media ${deviceConstraints.tablet} {
    padding: 12px 0;
  }
`;

export const ImageOverlayFigure = styled.figure`
  position: relative;

  &:hover ${ImageOverlayFigcaption} {
    opacity: 1;
  }
`;

export const Group = styled.div`
  display: flex;
  gap: 16px;
`;

export const StyledButtonLink = styled(ButtonLink).attrs({
  iconPosition: 'right',
  iconSize: 18,
})``;
