import React, { FC, ReactNode } from 'react';

interface HighlightedTextProps {
  className?: string;
  children: ReactNode;
}

export const HighlightedText: FC<HighlightedTextProps> = ({
  children,
  className,
}) => {
  return <span className={className}>{children}</span>;
};
