import { ENDPOINT_URLS } from '~/global/toggle-api';
import { useAPI } from '~/shared/hooks/use-api/useAPI';
import { APIFetch } from '~/shared/services/api-fetch';

import { GTKConditionResponse } from '../../experiment/components/condition-builder/utils/conditionHelpers';
import { InsightEventType } from '../use-article-insight-cards/useInsightCards';

export interface GtKItem {
  expression: string;
  last_value: number;
  name: string;
  image_url: string;
  explanation: string;
  connected_article_title?: string;
  connected_article_url?: string;
  scenario_condition: GTKConditionResponse[];
  group_code: InsightEventType;
  group_name: string;
  group_icon_url: string;
  snake_last_date: string;
}

export const fetchGtK = (entityTag: string) =>
  APIFetch<GtKItem[]>(`${ENDPOINT_URLS.ENTITY_REPORT}/${entityTag}/relevance`);

export const useGtK = (entityTag: string) =>
  useAPI(() => fetchGtK(entityTag), {
    deps: [entityTag],
    initialState: { data: [], loading: true },
  });
