import { Accordion } from '@toggle/design-system';
import styled from 'styled-components';

import { pulse } from '~/global/styles/Animation.styles';

export const CheckedMarker = styled.span`
  margin-right: 10px;
  vertical-align: middle;
  position: relative;

  svg {
    transform: rotate(0deg) !important;

    path {
      stroke-width: 3px;
      stroke: var(--light-green--4);
    }
  }
`;

export const UncheckedMarker = styled.span`
  display: inline-block;
  width: 9px;
  height: 9px;
  background-color: var(--primary-color);
  border-radius: 50%;
  margin-right: 12px;
`;

export const HelperAccordion = styled(Accordion)`
  ${Accordion.Styled.AccordionItem} {
    margin-bottom: 8px;
    border: 1px solid var(--base--80);
    border-radius: 4px;
    transition: 0.2s linear;
    font-size: 16px;
    color: var(--base--0);

    &:hover {
      border-color: var(--primary-color);
    }
  }

  ${Accordion.Styled.TitleContainer} {
    justify-content: space-between;
    padding: 8px 8px 8px 16px;
    text-decoration: none !important;
    font-weight: 500;
    line-height: 1.41;
    color: inherit;
    font-size: 16px;

    &:hover {
      background-color: #ffffff0a;
    }
  }

  ${Accordion.Styled.IconStyled} {
    transform: rotate(0deg);
  }

  ${Accordion.Styled.Content} {
    padding: 0 0 1px 0; // 1px fixes decimal height in node.clientHeight
    line-height: 1.33;
  }

  .accordion-expanded {
    background-color: var(--base--90);

    ${CheckedMarker} {
      &:after {
        position: absolute;
        top: -7px;
        left: -9px;
        content: '';
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background-color: var(--light-green--4);
        animation: ${pulse} 2s linear forwards;
        pointer-events: none;
      }
    }

    ${Accordion.Styled.IconStyled} {
      transform: rotate(180deg);
    }
  }

  .hoveredUiSection {
    border-color: var(--primary-color);
  }

  .emptyAccordionItem {
    margin-bottom: 16px;
    border: none;

    ${Accordion.Styled.TitleContainer} {
      &,
      &:hover {
        background-color: transparent;
        font-size: 18px;
        flex-direction: row;
        padding: 0;
      }
    }
  }

  .separateAccordionItem {
    border: none;
    background-color: transparent !important;
    margin-bottom: 24px;

    ${Accordion.Styled.TitleContainer} {
      margin-left: -8px;
      width: calc(100% + 8px);
      font-size: 18px;
      padding: 8px;
      line-height: 1;
      border-radius: 4px;
    }

    ${Accordion.Styled.Content} {
      padding: 16px 0 1px;
      line-height: 1.33;
    }
  }
`;
