import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { appPaths } from '~/routes/app-paths';

import * as S from './NotFound.styles';

export const NotFound = () => {
  const { t } = useTranslation('error');
  const title = t('error:notFound.title');
  const navigate = useNavigate();

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <S.Main data-testid="not-found-page">
        <S.MainHeading>{t('error:notFound.mainHeading')}</S.MainHeading>
        <S.NotFoundSection>
          <S.Image alt={title} />
          <S.Title data-testid="title">{t('error:notFound.pageTitle')}</S.Title>
          <S.Description>{t('error:notFound.pageText')}</S.Description>
          <S.LinkButton
            data-testid="back-action"
            onClick={() => navigate(appPaths.myToggle)}
            label={t('error:notFound.pageButtonText')}
            size="large"
          />
        </S.NotFoundSection>
      </S.Main>
    </>
  );
};
