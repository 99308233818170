import { z } from 'zod';

// Explore filters
export type ExploreFilterPayload = z.infer<typeof exploreFilterPayloadSchema>;
export type ExploreFilterOption = ExploreFilterPayload['categories'][0];
export type FilterOption = ExploreFilterPayload['categories'][0]['options'][0];
export type OptionProperties =
  ExploreFilterPayload['categories'][0]['options'][0]['properties'];

const filterOptionSchema = z.object({
  name: z.string(),
  key: z.string(),
  properties: z.record(z.union([z.string(), z.array(z.string())])).optional(),
  icon: z.string().optional(),
  tip: z.string().optional(),
  locked: z.boolean().optional(),
});

const exploreFilterOptionSchema = z.object({
  key: z.string(),
  name: z.string(),
  type: z.enum(['set', 'checkbox', 'radio']),
  tip: z.string().optional(),
  options: z.array(filterOptionSchema),
  locked: z.boolean().optional(),
});

export const exploreFilterPayloadSchema = z.object({
  categories: z.array(exploreFilterOptionSchema),
});

// User filters
export type UserFilterResponse = z.infer<typeof userFilterResponseSchema>;
export type UserFilterPayload = z.infer<typeof UserFilterPayloadSchema>;
export const UserFilterPayloadSchema = z.object({
  name: z.string(),
  filters: z.record(z.array(z.string())),
});

export type UserFilterResponseFilters = UserFilterResponse['filters'];

export const userFilterResponseSchema = z.object({
  id: z.string(),
  name: z.string(),
  filters: z.record(z.array(z.string())),
});

export const userFilterResponseArrSchema = z.array(userFilterResponseSchema);
