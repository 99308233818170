import {
  ButtonLink,
  deviceConstraints,
  typography,
} from '@toggle/design-system';
import styled, { keyframes } from 'styled-components';

const float = keyframes`
  0% {
		transform: translateY(0px) rotate(0deg);
	}
	50% {
		transform: translateY(-10px) rotate(-6deg);;
	}
	100% {
		transform: translateY(0px) rotate(0deg);
	}
`;

export const HeaderImg = styled.img`
  margin-left: auto;
`;

export const Body = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
export const Title = styled.h2`
  ${typography.chimp};
  text-align: center;

  @media ${deviceConstraints.desktopSmall} {
    ${typography.tiger}
  }

  padding: 16px 0px;
`;

export const CloseButton = styled(ButtonLink)`
  color: var(--base-100);
  position: absolute;
  bottom: 40px;
`;

export const Message = styled.p`
  ${typography.emu}
  padding: 12px;
  text-align: center;
`;
export const FooterImg = styled.img`
  margin-right: auto;
  animation: ${float} 6s ease-in-out infinite;
`;
export const CenterImg = styled.img`
  margin: 20px auto;
  animation: ${float} 6s ease-in-out infinite;
`;
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;
