import { SchemaValidationError } from '@toggle/helpers';
import { ZodError, ZodIssue, ZodSchema } from 'zod';

import { Tracking } from '~/shared/services/tracking';

import { APIFetch } from '../APIFetch';

export interface ValidateFetchProps<T> {
  endpoint: string;
  schema?: ZodSchema<T>;
  requestInfo?: RequestInit;
}

export const validateApiFetch = async <T>(
  params: ValidateFetchProps<T>
): Promise<T> => {
  try {
    const response = await APIFetch<T>(params.endpoint, params.requestInfo);
    if (response instanceof Error) {
      throw response;
    }
    params.schema?.parse(response);
    return response;
  } catch (error) {
    if (error instanceof ZodError) {
      const flattenedError = error.flatten((issue: ZodIssue) => ({
        message: issue.message,
        errorCode: issue.code,
        path: issue.path,
      }));
      const validationError = new SchemaValidationError(
        params.endpoint,
        flattenedError
      );
      Tracking.captureException(validationError);
      throw validationError;
    }
    throw error as Error;
  }
};
