import { SvgIconNames } from '@toggle/design-system';
import * as React from 'react';

import { config } from '~/config';

import { ShareMenu } from '../share-menu/ShareMenu';
import { ActionMenuProps } from './ActionMenu';

const {
  urls: { supportNewTicket: supportNewTicketURL },
} = config;

enum ArticleAction {
  Share = 'share',
  Feedback = 'feedback',
}

export interface MenuAction {
  type: ArticleAction;
  link?: string;
  component?: (props: ActionMenuProps) => JSX.Element;
  icon: SvgIconNames;
}

const shareAction: MenuAction = {
  type: ArticleAction.Share,
  icon: 'Share',
  link: undefined,
  component: (article: ActionMenuProps) => (
    <ShareMenu article={article} gaLocation="article-sidebar" />
  ),
};

const feedbackAction: MenuAction = {
  type: ArticleAction.Feedback,
  component: undefined,
  link: supportNewTicketURL,
  icon: 'CommentSmile',
};

export const ACTIONS = [shareAction, feedbackAction];
