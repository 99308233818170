import { TFunction } from 'i18next';
import React, { FC } from 'react';

import { ArticleV3 } from '~/declarations/toggle-api-news';
import { ContentAlignments } from '~/shared/components/table/Table';
import { useMediaQuery } from '~/shared/hooks/MediaQueryHook';
import { MediaQueries } from '~/shared/hooks/MediaQueryList';

import { createSuffixFromMethod } from '../../article-constants';
import { LinkMarkdown } from '../link-markdown/LinkMarkdown';
import * as S from './Fundamentals.styles';
import { HorizonTable } from './horizon-table';
import { HorizonTableMobile } from './horizon-table-mobile';

interface FundamentalsProps {
  data: ArticleV3;
  t: TFunction;
  className?: string;
}

export const Fundamentals: FC<FundamentalsProps> = ({ className, t, data }) => {
  const currentMedia = useMediaQuery(s => s.currentMedia);
  const isMobile = [MediaQueries.SMALL_MOBILE, MediaQueries.MOBILE].includes(
    currentMedia
  );

  const { why_it_might_move, list_of_conditions, hit_ratio } = data;
  const hitRatioExist = hit_ratio?.length;

  const bestRatioIndex = hit_ratio.findIndex(h => h.is_best);

  const suffix = createSuffixFromMethod(data.default_method);

  return (
    <S.FundamentalsContainer className={className}>
      <S.FundamentalsName>
        <S.FundamentalsNameTitle>
          {t('article:whyItMightMove.fundamentals.title')}
        </S.FundamentalsNameTitle>
        <S.FundamentalsNameSubtitle>
          {t('article:whyItMightMove.fundamentals.subtitle')}
        </S.FundamentalsNameSubtitle>
      </S.FundamentalsName>
      <S.FundamentalsContent>
        {list_of_conditions && (
          <S.StyledTable
            rows={[
              Object.keys(list_of_conditions[0]),
              ...list_of_conditions.map(({ driver, condition }) => [
                driver,
                condition,
              ]),
            ]}
            columnWidths={['60%', '40%']}
            columnContentAlignments={['', ContentAlignments.END]}
            headerType="horizontal"
            zebraType="columns"
            zebraOrder="odd"
          />
        )}
        <S.Description
          markdownProps={{
            children: why_it_might_move[1].component_data,
            components: {
              strong: props => <S.HighlightedTextStyled {...props} />,
              a: LinkMarkdown,
            },
          }}
        />
        <S.EpisodeDatesPanel data={data} t={t} />
        {hitRatioExist > 0 && isMobile && (
          <HorizonTableMobile
            hitRatio={hit_ratio}
            bestRatioIndex={bestRatioIndex}
            t={t}
            suffix={suffix}
          />
        )}
        {hitRatioExist > 0 && !isMobile && (
          <HorizonTable
            hitRatio={hit_ratio}
            bestRatioIndex={bestRatioIndex}
            t={t}
            suffix={suffix}
          />
        )}
        <S.HitRatioNote
          markdownProps={{
            children: why_it_might_move[2].component_data,
            components: {
              strong: ({ children }) => (
                <S.HighlightedTextStyled>{children}</S.HighlightedTextStyled>
              ),
              a: LinkMarkdown,
              link: props => <LinkMarkdown {...props} />,
            },
          }}
        />
      </S.FundamentalsContent>
    </S.FundamentalsContainer>
  );
};
