import styled, { css } from 'styled-components';

import { typographyV2 } from '~/design-tokens/typography/Typography.styles';

export const BreadCrumbWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
`;

export const CrumbWrapper = styled.span<{ $disabled: boolean }>`
  color: var(--text-soft);
  ${({ $disabled = false }) =>
    !$disabled &&
    css`
      &:hover {
        color: var(--text-primary);
        cursor: pointer;
      }
    `}
`;

export const SeparatorWrapper = styled.span`
  padding: 0 8px;
`;

export const BreadCrumb = styled.span`
  color: var(--text-soft);
  ${typographyV2.bodySm}
`;
