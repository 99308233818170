import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { BoxProps } from '../box';
import * as QuestionnaireSharedStyles from '../questionnaire-shared-styles/QuestionnaireSharedStyles.styles';
import { InvestorOptions, InvestorStyles } from '../store/OnboardingContext';
import * as S from './Investor.styles';

interface InvestorProps {
  handleClick: (id: InvestorStyles | 'both') => void;
  investorOptions: InvestorOptions;
}

export const Investor: FC<InvestorProps> = ({
  investorOptions,
  handleClick,
}) => {
  const { t } = useTranslation('onboarding');

  return (
    <S.Container>
      <S.FundamentalsBox<FC<BoxProps<'fundamentals'>>>
        left={
          <>
            <QuestionnaireSharedStyles.Text isLarge>
              {t('onboarding:questionnaire.investor.fundamentals')}
            </QuestionnaireSharedStyles.Text>
            <QuestionnaireSharedStyles.Text>
              {t('onboarding:questionnaire.investor.10ks')}
            </QuestionnaireSharedStyles.Text>
          </>
        }
        right={
          <QuestionnaireSharedStyles.Img
            src="/app/assets/onboarding/fundamentals.png"
            alt={t('onboarding:questionnaire.header.fundamentalsAlt')}
          />
        }
        active={!!investorOptions.fundamentals}
        id="fundamentals"
        onClick={handleClick}
      />
      <S.TechnicalBox<FC<BoxProps<'technical'>>>
        left={
          <>
            <QuestionnaireSharedStyles.Text isLarge>
              {t('onboarding:questionnaire.investor.technical')}
            </QuestionnaireSharedStyles.Text>
            <QuestionnaireSharedStyles.Text>
              {t('onboarding:questionnaire.investor.charts')}
            </QuestionnaireSharedStyles.Text>
          </>
        }
        right={
          <QuestionnaireSharedStyles.Img
            src="/app/assets/onboarding/technical.png"
            alt={t('onboarding:questionnaire.header.technicalAlt')}
          />
        }
        id="technical"
        onClick={handleClick}
        active={investorOptions.technical}
      />
      <S.BothBox<FC<BoxProps<'both'>>>
        left={t('onboarding:questionnaire.investor.both')}
        id="both"
        onClick={handleClick}
        active={investorOptions.fundamentals && investorOptions.technical}
      />
    </S.Container>
  );
};
