import React from 'react';
import { useTranslation } from 'react-i18next';

import * as S from '../Content.styles';

export function WhatIsMoving() {
  const { t } = useTranslation('helperWidget');
  return (
    <S.Content data-testid="what-is-moving-content">
      <S.Paragraph>
        {t('helperWidget:analyze.content.whatIsMovingContent.title1')}
      </S.Paragraph>
      <S.Paragraph>
        {t('helperWidget:analyze.content.whatIsMovingContent.title2')}
      </S.Paragraph>
    </S.Content>
  );
}
