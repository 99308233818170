import {
  Button,
  ButtonGroup,
  HorizontalBarChart,
  Icon,
} from '@toggle/design-system';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Condition,
  DominoScenarioResponse,
  Period,
  ScenarioCommanderResponse,
} from '~/api/scenario-commander/scenario-commander-schema';

import { useScenarioCommander } from '../../store/useScenarioCommander';
import {
  isWatchlistScenario,
  mapDomino,
} from '../../utils/domino-utils/domino-utils';
import { ErrorMessage } from '../error-message/ErrorMessage';
import * as S from './DominoWidget.styles';

export interface DominoWidgetProps {
  data: DominoScenarioResponse;
  scenario: ScenarioCommanderResponse;
  showPastEpisodesButton: boolean;
  occurrences?: { episodes: number; tradingDays: number };
}

export const DominoWidget = ({
  data,
  scenario,
  showPastEpisodesButton,
  occurrences,
}: DominoWidgetProps) => {
  const { t } = useTranslation(['scenarioCommander']);
  const isWatchlist = isWatchlistScenario(scenario);
  const groupTitle = isWatchlist
    ? t('scenarioCommander:domino.watchlistTitle')
    : scenario.portfolio_name;

  const getConditionList = (conditions: Condition[]): string => {
    return conditions
      .map(
        ({
          condition,
          duration,
          duration_unit,
          value,
          max_value,
          min_value,
          entity,
        }) => {
          const period = t(`scenarioCommander:domino.period.${duration_unit}`, {
            count: duration,
          });
          const formattedValue =
            ['rises_by', 'falls_by'].includes(condition) && value
              ? value * 100
              : value;

          return t(`scenarioCommander:domino.conditions.${condition}`, {
            entity,
            duration,
            period,
            value: formattedValue,
            max_value,
            min_value,
          });
        }
      )
      .join(t(`scenarioCommander:domino.and`));
  };

  const condition = getConditionList(scenario.conditions);

  const mappedDomino = mapDomino(data, scenario.entities);

  const [activeHorizon, setActiveHorizon] = useState<Period>('3m');

  const { addMessage, retryLast } = useScenarioCommander(state => ({
    addMessage: state.addMessage,
    retryLast: state.retryLast,
  }));

  const showPastEpisodes = () => {
    if (data) {
      addMessage(t('scenarioCommander:domino.pastEpisodes'), {
        type: 'PastEpisodes',
        data,
        condition,
        scenario,
      });
    }
  };

  const period = mappedDomino[activeHorizon];
  const horizon = Object.keys(mappedDomino) as Period[];

  if (Object.keys(mappedDomino).length === 0 && !period) {
    return <ErrorMessage handleRetry={retryLast} />;
  }

  const handleHorizonSelection = (i: number) => {
    setActiveHorizon(horizon[i]);
  };
  const horizonLabels = horizon.map(s => s.toUpperCase());
  const activeHorizonIndex = horizon.indexOf(activeHorizon);
  const spx = period.filter(p => p.tag === 'spx');

  const portfoliosWithOutSpx = period.filter(p => p.tag !== 'spx');
  const benchmark = groupTitle
    ? [
        ...spx,
        {
          label: groupTitle,
          tag: groupTitle,
          median: isWatchlist
            ? 0
            : portfoliosWithOutSpx.reduce((a, v) => a + v.median, 0) /
              portfoliosWithOutSpx.length,
        },
      ]
    : spx;

  return (
    <React.Fragment>
      <S.Header>
        <S.Title>{t('domino.condition')}</S.Title>
        <S.ConditionText>{condition}</S.ConditionText>
        <S.ConditionText>
          <Icon iconName={'ArrowRight'} size={14} />
          <S.OccurrenceText>
            {t('domino.occurrences', occurrences)}
          </S.OccurrenceText>
        </S.ConditionText>
        <S.Title>{t('domino.reaction')}</S.Title>
        <S.HorizonContainer>
          <ButtonGroup
            labels={horizonLabels}
            onClick={handleHorizonSelection}
            activeIndex={activeHorizonIndex}
          />
        </S.HorizonContainer>
      </S.Header>

      <HorizontalBarChart
        headerTitle={t('domino.headerTitle')}
        headerLabel={t('domino.headerLabel')}
        medianLabel={t('domino.medianLabel')}
        data={portfoliosWithOutSpx}
        headerData={benchmark}
      />

      <S.Footer>
        {showPastEpisodesButton && (
          <Button
            data-testid="show-past-episodes-dates"
            label={t('domino.pastEpisodes')}
            onClick={showPastEpisodes}
            iconName="SendMessage"
            iconPosition="right"
            variant="tertiary"
          />
        )}
      </S.Footer>
    </React.Fragment>
  );
};
