import { HttpMethods } from '~/shared/services/api-fetch';
import { addAuthHeaders } from '~/shared/services/api-fetch/APIFetchDecorators';
import convertChunksToJson from '~/views/chat/helpers/convert-chunks-to-json/convertChunksToJson';

import { ChatStreamChunk } from './readable-streams.types';

interface CreateReadableStreamProps {
  url: string;
  options?: RequestInit;
  onReadChunk: (chunks: ChatStreamChunk[]) => void;
}

export async function createReadableStream({
  url,
  options,
  onReadChunk,
}: CreateReadableStreamProps) {
  try {
    const [request, headers] = await addAuthHeaders(url, {
      method: HttpMethods.Post,
      ...options,
    });

    const response = await fetch(request, headers);

    if (!response.ok || !response.body) {
      onReadChunk([
        {
          event: 'gpt-complete',
          data: false,
          isError: true,
        },
      ]);

      return;
    }

    const reader = response.body.getReader();
    let prevChunks: ChatStreamChunk[] = [];
    while (true) {
      const { done, value } = await reader.read();
      if (done) {
        if (prevChunks[prevChunks.length - 1]?.event !== 'gpt-complete') {
          onReadChunk([
            {
              event: 'gpt-complete',
              data: false,
              isError: true,
            },
          ]);
        }
        break;
      }

      const chunk = new TextDecoder('utf-8').decode(value);
      const chunks = convertChunksToJson(chunk);
      prevChunks = chunks;
      onReadChunk(chunks);
    }
  } catch (error) {
    if (error instanceof DOMException && error.name == 'AbortError') {
      onReadChunk([{ event: 'gpt-complete', data: false }]);
      return;
    }

    onReadChunk([{ event: 'gpt-complete', data: false, isError: true }]);
  }
}
