import React from 'react';
import { useTranslation } from 'react-i18next';

import { Video } from '~/shared/components/video/Video';

import { HELPER_VIDEO_LINKS } from '../../../HelperConfig';
import * as S from '../Content.styles';

interface InsightIntroProps {
  onVideoClick: () => void;
}

export function InsightIntro({ onVideoClick }: InsightIntroProps) {
  const { t } = useTranslation('helperWidget');
  return (
    <div data-testid="insight-intro">
      <S.Topic>{t('helperWidget:insight.content.intro.title')}</S.Topic>
      <S.VideoWrapper>
        <Video
          src={HELPER_VIDEO_LINKS.INSIGHT_LINK}
          onVideoClick={onVideoClick}
        />
      </S.VideoWrapper>
    </div>
  );
}
