import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import NoImage from '~/assets/explore/no-image.svg';
import { ArticleSummary } from '~/declarations/toggle-api-news';

export interface ArticleImageProps {
  data: ArticleSummary;
  className?: string;
  locked?: boolean;
}

export const ArticleImage: FC<ArticleImageProps> = ({
  data,
  className,
  locked,
}) => {
  const { t } = useTranslation('feed');
  const { ellipsis, version, chart_link } = data;
  if (locked) {
    return (
      <img
        className={className}
        alt={ellipsis}
        src="/app/assets/explore/locked-article.png"
      />
    );
  }
  if ([2, 3].includes(version)) {
    return (
      <img
        className={className}
        alt={ellipsis}
        src={chart_link}
        onError={event =>
          ((event.target as HTMLImageElement).src =
            '/app/assets/explore/locked-article.png')
        }
      />
    );
  } else {
    return <NoImage alt={t('article:altNoImage')} />;
  }
};
