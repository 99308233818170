import { useCallback, useEffect, useMemo, useState } from 'react';

import { Entity } from '~/api/entities/entity-schema';
import {
  gaMyAccountUpdateWatchlist,
  gaWatchlistHitLimit,
} from '~/shared/utils/ganalytics';
import { useSubscription } from '~/stores/use-subscription/useSubscription';
import {
  fetchSuggestedTags,
  Tag,
} from '~/stores/use-user-entities/services/watchlist-services';
import { useUserEntities } from '~/stores/use-user-entities/useUserEntities';

interface TagsIdMap {
  [id: string]: boolean;
}

type AddSuggestion = (tag: Tag, index: number) => void;

interface TickersState {
  suggestionTags: Tag[];
  activeTags: Entity[];
  newTagIdsMap: TagsIdMap;
  addTag: (tag: Tag) => Promise<void>;
  removeTag: (tag: string) => Promise<void>;
  addSuggestion: AddSuggestion;
  exceedLimitError: boolean;
}

const TIME_TO_SHOW_NEWLY_ADDED = 1000;

export const useTickersData = (): TickersState => {
  const userWatchLimit = useSubscription(state => (state.isBasic() ? 10 : 0));
  const { tags, add, remove } = useUserEntities(
    ({ watchlist: { addTag, removeTag }, entities }) => ({
      tags: entities.watchlist,
      add: addTag,
      remove: removeTag,
    })
  );
  const [exceedLimitError, setExceedLimitError] = useState(false);
  const [suggestionTags, setSuggestionTags] = useState<Tag[]>([]);
  const [newTagIdsMap, setNewTagIdsMap] = useState<TagsIdMap>({});
  const canAddTags = useMemo(() => {
    return userWatchLimit === 0 || !tags || tags.length < userWatchLimit;
  }, [userWatchLimit, tags]);

  const getSuggested = async () => {
    const suggested = await fetchSuggestedTags();
    setSuggestionTags(suggested);
  };

  useEffect(() => {
    getSuggested();
  }, []);

  const onExceedLimitError = (tag: Tag) => {
    setExceedLimitError(true);
    gaWatchlistHitLimit(tag.tag, 'my-account');
  };

  const addTag = async (tag: Tag) => {
    if (canAddTags) {
      await add(tag.tag);
      addToNewlyAdded(tag);
      gaMyAccountUpdateWatchlist(tag.tag, 'add', tags.length + 1);
    } else {
      onExceedLimitError(tag);
    }
  };

  const removeTag = async (tag: string) => {
    await remove(tag);
    gaMyAccountUpdateWatchlist(tag, 'remove', tags.length - 1);
  };

  const addSuggestion: AddSuggestion = useCallback(
    (tag, index) => {
      if (canAddTags) {
        const updatedSuggestions = [...suggestionTags];
        updatedSuggestions.splice(index, 1);
        setSuggestionTags(updatedSuggestions);
        addTag(tag);
      } else {
        onExceedLimitError(tag);
      }
    },
    [suggestionTags, addTag, canAddTags]
  );

  const addToNewlyAdded = (tag: Tag) => {
    setNewTagIdsMap(current => ({
      ...current,
      [tag.tag]: true,
    }));
    setTimeout(() => {
      setNewTagIdsMap(current => {
        const { [tag.tag]: currentTag, ...rest } = current;
        return rest;
      });
    }, TIME_TO_SHOW_NEWLY_ADDED);
  };

  useEffect(() => {
    if (exceedLimitError && canAddTags) {
      setExceedLimitError(false);
    }
  }, [exceedLimitError, canAddTags]);

  return {
    suggestionTags,
    activeTags: tags,
    newTagIdsMap,
    addTag,
    removeTag,
    addSuggestion,
    exceedLimitError,
  };
};
