import { TabLines } from '@toggle/design-system';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { MappedEntity } from '~/shared/hooks/use-entities';
import { GtkCardData } from '~/views/analyze/asset-overview/components/sidebar/components/rocketship-tab/helpers/RocketshipTabHelpers';
import { GtKItem, useGtK } from '~/views/analyze/hooks/use-gtk/useGtK';
import { useAnalyzeStore } from '~/views/analyze/store/AnalyzeStore';

import { EmptyInsights } from '../empty-insights/EmptyInsights';
import { GtkItem } from '../gtk-item/GtkItem';
import { NewsItem } from '../news-item/NewsItem';
import * as S from './WidgetTabs.styles';

export interface WidgetTabsProps {
  entity: MappedEntity;
  updateGtk: (selectedGtk: GtkCardData) => void;
}

enum TabNames {
  News = 'news',
  Fundamentals = 'fundamentals',
  Technicals = 'technicals',
}

export const WidgetTabs = ({ entity, updateGtk }: WidgetTabsProps) => {
  const { t } = useTranslation('common');
  const [selectedTab, setSelectedTab] = useState<TabNames>(TabNames.News);
  const { aiNews } = useAnalyzeStore();
  const { data: gtk } = useGtK(entity.tag);

  const [groupFundamentals, groupTechnicals] = useMemo(() => {
    const groupFundamentals: GtKItem[] = gtk.filter(
      g => g.group_code === 'group_fundamental'
    );
    const groupTechnicals: GtKItem[] = gtk.filter(
      g => g.group_code === 'group_technical'
    );

    return [groupFundamentals, groupTechnicals];
  }, [gtk]);

  const hasNews = aiNews.length > 0;
  const hasFundamentals = groupFundamentals.length > 0;
  const hasTechnicals = groupTechnicals.length > 0;

  useEffect(() => {
    if (hasNews) {
      setSelectedTab(TabNames.News);
    } else if (hasFundamentals) {
      setSelectedTab(TabNames.Fundamentals);
    } else if (hasTechnicals) {
      setSelectedTab(TabNames.Technicals);
    }
  }, [hasNews, hasFundamentals, hasTechnicals, entity]);

  const onChangeHandler = (index: number, tabName: TabNames) => {
    setSelectedTab(tabName);
  };

  return (
    <S.TabsLinesContainer>
      <TabLines<TabNames>
        activeTab={selectedTab}
        onChange={onChangeHandler}
        tabs={[
          {
            tabKey: TabNames.News,
            tabLabel: t('chat:news'),
            renderPanel: hasNews ? (
              <NewsItem aiNews={aiNews} />
            ) : (
              <EmptyInsights isNewsEmpty />
            ),
          },
          {
            tabKey: TabNames.Fundamentals,
            tabLabel: t('chat:fundamentals'),
            renderPanel: hasFundamentals ? (
              <GtkItem
                filteredGtks={groupFundamentals}
                onGtkClick={gtk => updateGtk(gtk)}
              />
            ) : (
              <EmptyInsights />
            ),
          },
          {
            tabKey: TabNames.Technicals,
            tabLabel: t('chat:technicals'),
            renderPanel: hasTechnicals ? (
              <GtkItem
                filteredGtks={groupTechnicals}
                onGtkClick={gtk => updateGtk(gtk)}
              />
            ) : (
              <EmptyInsights />
            ),
          },
        ]}
      />
    </S.TabsLinesContainer>
  );
};
