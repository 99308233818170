import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
   0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
`;

const spinnerWidthLarge = '4px';
const spinnerWidthSmall = '3px';
export const SpinnerIcon = styled.div<{ $size: 'small' | 'large' }>`
  border-width: ${({ $size }) =>
    $size === 'small' ? spinnerWidthSmall : spinnerWidthLarge};
  border-style: solid;
  border-color: var(--icon-default) transparent var(--icon-default)
    var(--icon-default);
  width: ${({ $size }) => ($size === 'small' ? '16px' : '40px')};
  height: ${({ $size }) => ($size === 'small' ? '16px' : '40px')};
  border-radius: 50%;
  position: relative;
  animation: ${rotate} 1s linear infinite;

  &:before,
  &:after {
    content: '';
    width: ${({ $size }) =>
      $size === 'small' ? spinnerWidthSmall : spinnerWidthLarge};
    height: ${({ $size }) =>
      $size === 'small' ? spinnerWidthSmall : spinnerWidthLarge};
    border-radius: 50%;
    background: var(--icon-default);
    position: absolute;
  }

  &:before {
    top: ${({ $size }) => ($size === 'small' ? '-1px' : '2px')};
    right: ${({ $size }) => ($size === 'small' ? '-1px' : '1px')};
  }

  &:after {
    bottom: ${({ $size }) => ($size === 'small' ? '-1px' : '2px')};
    right: ${({ $size }) => ($size === 'small' ? '-1px' : '1px')};
  }
`;
