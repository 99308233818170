import React, { FC } from 'react';

import { PortfolioIconWrapper } from '~/global/app-search/search-tabs/SearchTabs.styles';

import * as S from './SelectPortfolioItem.styles';

interface SelectPortfolioItemProps {
  name: string;
  positionsCount: string;
  logo: string;
  altText: string;
  onClick: () => void;
}

export const SelectPortfolioItem: FC<SelectPortfolioItemProps> = ({
  name,
  positionsCount,
  onClick,
  logo,
  altText,
}) => (
  <S.ItemContainer onClick={onClick} data-testid="select-portfolio-item">
    <S.NameWrapper>
      <PortfolioIconWrapper>
        <img src={logo} alt={altText} />
      </PortfolioIconWrapper>
      <S.Name>{name}</S.Name>
    </S.NameWrapper>
    <S.PositionsText>{positionsCount}</S.PositionsText>
  </S.ItemContainer>
);
