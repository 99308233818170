import styled from 'styled-components';

export const StyledPageWrapper = styled.div`
  position: relative;
`;
export const StyledPageCanvasLayer = styled.canvas`
  width: 100%;
`;

export const StyledPageTextLayer = styled.div`
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  text-align: initial;
  right: 0;
  bottom: 0;
  overflow: hidden;
  opacity: 0.2;
  line-height: 1;
  text-size-adjust: none;
  forced-color-adjust: none;

  span,
  br {
    color: transparent;
    position: absolute;
    white-space: pre;
    cursor: text;
    transform-origin: 0% 0%;
  }

  > section {
    position: absolute;
  }

  section > a {
    display: block;
    height: 100%;
  }

  span.markedContent {
    top: 0;
    height: 0;
  }

  .highlight {
    margin: -1px;
    padding: 1px;
    background-color: rgba(180, 0, 170, 1);
    border-radius: 4px;
  }

  .highlight.appended {
    position: initial;
  }

  .highlight.begin {
    border-radius: 4px 0 0 4px;
  }

  .highlight.end {
    border-radius: 0 4px 4px 0;
  }

  .highlight.middle {
    border-radius: 0;
  }

  .highlight.selected {
    background-color: rgba(0, 100, 0, 1);
  }

  ::-moz-selection {
    background: rgba(0, 0, 255, 1);
  }

  ::selection {
    background: rgba(0, 0, 255, 1);
  }

  br::-moz-selection {
    background: transparent;
  }
  br::selection {
    background: transparent;
  }

  .endOfContent {
    display: block;
    position: absolute;
    left: 0;
    top: 100%;
    right: 0;
    bottom: 0;
    z-index: -1;
    cursor: default;
    user-select: none;
  }

  .endOfContent.active {
    top: 0;
  }
`;
