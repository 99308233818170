import {
  AssetLogo,
  borderRadius,
  Icon,
  typographyV2,
} from '@toggle/design-system';
import styled, { css } from 'styled-components';

export const MenuButtonContainer = styled.div`
  display: none;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin: 0px 8px;
`;

export const PriceContainer = styled.div`
  display: flex;
  align-items: center;
`;

const highlightedState = css`
  border: 1px solid var(--border-hover);
  ${MenuButtonContainer} {
    display: flex;
  }
  ${PriceContainer} {
    display: none;
  }
`;

export const LegendItemDetails = styled.div`
  display: flex;
  align-items: center;
  ${borderRadius.r1}
`;

export const LegendItemRoot = styled.div<{ $isHighlighted: boolean }>`
  padding: 4px;
  display: flex;
  align-items: center;
  background-color: var(--background-soft);
  border: 1px solid var(--border-soft);
  margin-bottom: 8px;
  ${borderRadius.r1}
  ${({ $isHighlighted }) => $isHighlighted && highlightedState}
  &:has(${LegendItemDetails}:hover) {
    ${highlightedState}
  }
`;

export const AssetContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledLogo = styled(AssetLogo)`
  height: 20px;
  width: 20px;
`;

export const Label = styled.p`
  text-transform: uppercase;
`;

export const Button = styled.button<{
  $isUpperCase: boolean;
}>`
  padding: 0;
  text-transform: ${props => (props.$isUpperCase ? 'uppercase' : 'lowercase')};
  background: none;
  border: 0;
  color: inherit;
  font-size: inherit;
  cursor: pointer;
`;

export const Asset = styled.div<{ $isPrimary: boolean }>`
  display: flex;
  align-items: center;
  ${typographyV2.bodyBase}
  margin-right: 16px;
  margin-left: 4px;
  ${({ $isPrimary }) =>
    $isPrimary
      ? css`
          ${typographyV2.bodyBase}
        `
      : css`
          ${typographyV2.bodyXs}
        `}
`;

export const SeperatorDot = styled(Icon)`
  margin: 0px 4px;
  fill: var(--icon-default);
`;

export const ChartLineColorDot = styled.div<{ $color: string }>`
  border-radius: 50%;
  height: 8px;
  width: 8px;
  background-color: ${props => props.$color};
`;

export const MenuIconSpan = styled.span`
  cursor: pointer;
  height: 12px;
`;
