import styled from 'styled-components';

export const Form = styled.form`
  margin: 0 auto;
  max-width: 790px;
`;

export const SubmitButton = styled.button`
  position: absolute;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  background: none;
  border: none;
  outline: none;
  color: inherit;
  padding: 8px 12px;
  height: 100%;
  max-height: 46px;
  cursor: pointer;
  transition: color 300ms cubic-bezier(0.4, 0, 0.2, 1);

  :hover,
  :focus {
    color: var(--icon-primary);
  }

  :disabled {
    color: var(--icon-disabled);
  }
`;
