import { deviceConstraints, Icon, typography } from '@toggle/design-system';
import styled from 'styled-components';

export const Container = styled.section`
  padding-bottom: 56px;
`;
export const Wrapper = styled.div`
  @media ${deviceConstraints.tablet} {
    display: flex;
    flex-direction: column;
  }
  @media ${deviceConstraints.desktopSmall} {
    display: flex;
    flex-basis: 32%;
    justify-content: space-between;
    flex-direction: row;
  }
`;

export const Star = styled(Icon)<{ $yellow: boolean }>`
  &:not(:first-child) {
    margin-left: 6px;
  }
  fill: ${({ $yellow }) =>
    $yellow ? 'var(--warning--default)' : 'var(--base--75)'};
`;

export const Stars = styled.div`
  @media ${deviceConstraints.tablet} {
    padding-right: 50px;
  }
  @media ${deviceConstraints.desktopSmall} {
    padding-right: 0;
  }
`;

export const StarsRow = styled.div`
  display: flex;
  color: var(--base--0);
`;

export const StarsInfo = styled.div`
  font-size: 22px;
  padding: 8px 0 16px 0;
`;

export const ChecklistInfo = styled.h4`
  @media ${deviceConstraints.tablet} {
    flex-basis: 67%;
  }

  ${typography.emu}
`;

export const ChecklistOverall = styled.div`
  padding-left: 16px;

  @media ${deviceConstraints.tablet} {
    display: flex;
    justify-content: space-between;
    padding: 0 16px;
  }
  @media ${deviceConstraints.desktopSmall} {
    flex-basis: 32%;
    display: block;
    padding: 0;
  }
`;

export const QuestionMarkWrapper = styled.span`
  margin-left: 10px;
`;

export const Details = styled.ul`
  align-self: flex-end;

  @media ${deviceConstraints.desktopSmall} {
    flex-basis: 60%;
  }
`;

export const DetailsRow = styled.li`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--base--90);
  ${typography.dingo}
  padding: 16px 19px 8px 16px;

  &:first-child {
    padding-top: 32px;
  }
  @media ${deviceConstraints.desktopSmall} {
    &:first-child {
      padding-top: 0;
    }
  }
`;

export const DetailsRowText = styled.p`
  padding-right: 26px;

  @media ${deviceConstraints.tablet} {
    padding-right: 118px;
  }
  @media ${deviceConstraints.desktopSmall} {
    padding-right: 30px;
  }
  @media ${deviceConstraints.desktop} {
    padding-right: 0;
  }
`;

export const Title = styled.h2`
  color: var(--base--70);
  position: relative;
  padding-bottom: 6px;
  margin-bottom: 32px;
  padding-left: 16px;

  @media ${deviceConstraints.tablet} {
    padding-left: 0;
  }

  &:before {
    content: '';
    display: inline-block;
    width: 100%;

    @media ${deviceConstraints.tablet} {
      width: 288px;
    }
    left: 0;
    height: 2px;
    position: absolute;
    top: 100%;
    background-color: var(--base--70);
  }
`;
