import { create } from '../create-store/createStore';

export interface Navigator {
  country: string;
  language: string;
  setCountry: (country: string) => void;
  listenToNavigator: () => void;
}

export const getLanguageAndCountry = () => {
  const navLanguageArray = navigator.language.split('-');
  const country = navLanguageArray.length > 1 ? navLanguageArray[1] : 'US';
  const language = navLanguageArray[0];
  return { country, language };
};

export const useNavigator = create<Navigator>(set => ({
  country: getLanguageAndCountry().country,
  language: getLanguageAndCountry().language,
  setCountry: (country: string) => set({ country }),
  listenToNavigator: () => {
    if (typeof window === 'undefined') {
      throw new Error('This listener should be not used on server side');
    }
    window.onlanguagechange = () => {
      const { language, country } = getLanguageAndCountry();
      set({ language, country });
    };
  },
}));
