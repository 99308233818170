import { PaneData } from '@toggle/chart';
import { DropdownProps } from '@toggle/design-system';
import React, { FC, ReactNode } from 'react';

import { RangeHorizon, ResampleIntervals } from '~/types/resample.types';
import type { SeriesType } from '~/types/series.types';

import {
  ChartTypeSelector,
  ChartTypeSelectorProps,
} from '../chart-type-selector/ChartTypeSelector';
import { ChipHorizonSelector } from '../chip-horizon-selector/ChipHorizonSelector';
import {
  ResampleOption,
  ResampleSelector,
} from '../resample-selector/ResampleSelector';
import * as S from './ViewController.styles';

export interface ViewControllerProps {
  enabledResamples: ResampleIntervals[];
  horizonRanges: RangeHorizon[];
  selectedRange: RangeHorizon | null;
  selectedResample: ResampleIntervals;
  selectedSeriesType?: SeriesType;
  paneData?: PaneData[];
  onRangeChange: (range: RangeHorizon) => void;
  onResampleChange: (resample: ResampleIntervals) => void;
  onChartTypeChange: ChartTypeSelectorProps['onChange'];
  onItemsShowChange: DropdownProps<ResampleOption>['onItemsShowChange'];
  isResampleDropdownOpen: boolean;
  disableMenuBar: boolean;
  disable1DHorizon: boolean;
  isPrimaryAssetSinglePrice?: boolean;
  isSimpleChart?: boolean;
  renderFullScreenButton?: () => ReactNode;
}

export const ViewController: FC<ViewControllerProps> = ({
  selectedRange,
  selectedResample,
  paneData,
  onRangeChange,
  onResampleChange,
  onChartTypeChange,
  horizonRanges,
  disable1DHorizon,
  isResampleDropdownOpen,
  disableMenuBar,
  isPrimaryAssetSinglePrice,
  onItemsShowChange,
  enabledResamples,
  isSimpleChart = false,
  renderFullScreenButton,
}) => {
  const disabledRange = disable1DHorizon ? [horizonRanges[0]] : [];
  const disabledHorizons = disableMenuBar ? horizonRanges : disabledRange;

  const maxHorizonIndex = isSimpleChart
    ? horizonRanges.findIndex(h => h === RangeHorizon.ThreeYears)
    : horizonRanges.length - 1;
  const filteredHorizonsRanged = horizonRanges.slice(0, maxHorizonIndex + 1);

  return (
    <S.ViewControllerRoot
      data-testid="view-controller"
      $isSimpleChart={isSimpleChart}
    >
      {horizonRanges && (
        <ChipHorizonSelector
          horizonRanges={filteredHorizonsRanged}
          selectedRange={selectedRange}
          onChange={onRangeChange}
          disabledHorizons={disabledHorizons}
        />
      )}

      {!isSimpleChart && (
        <ResampleSelector
          selectedResample={selectedResample}
          onChange={onResampleChange}
          enabledResamples={enabledResamples}
          isResampleDropdownOpen={isResampleDropdownOpen}
          disableMenuBar={disableMenuBar}
          onItemsShowChange={onItemsShowChange}
        />
      )}

      <S.FlexWrapper>
        <ChartTypeSelector
          paneData={paneData}
          onChange={onChartTypeChange}
          disableMenuBar={disableMenuBar}
          isPrimaryAssetSinglePrice={isPrimaryAssetSinglePrice}
        />
        {renderFullScreenButton?.()}
      </S.FlexWrapper>
    </S.ViewControllerRoot>
  );
};
