import {
  defaultColors,
  nonIntradayResamples,
  PaneData,
  PriceDisplay,
  ResampleIntervals,
  TimeSeriesItem,
  YAxisType,
} from '@toggle/chart';
import { Entity } from '@toggle/toggle';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { postEntities } from '~/api/entities/entity-service';
import { FILTER } from '~/shared/hooks/use-entities';

import { ChartSearchType } from '../../types/search.types';
import {
  buildChartSearchParams,
  ChartSearchParamsProps,
  checkSearchParams,
  createChartPanesData,
  getChartSettingsFromSearchParams,
  getValidHorizon,
  ValidChartSettings,
} from '../../utils/chart-settings-utils.ts/chart-settings-utils';
import {
  createChartDataItem,
  getPrimaryAssetHorizons,
  getSnakeMeta,
  lineColors,
} from '../../utils/chart-utils/chart-utils';
import { checkIsIndicator } from '../../utils/entity-utils/entity-utils';
import {
  getAssetEmptyResamples,
  getAssetResample,
} from '../../utils/resample-utils/resample-utils';
import {
  GetTimeSeriesProps,
  OnChartDataReadyProps,
  UseTurboChartProps,
} from '../use-turbo-chart/useTurboChart';

export interface UseChartSettingsProps {
  onChartDataReady: (props: OnChartDataReadyProps) => void;
  getTimeSeries: (
    props: GetTimeSeriesProps
  ) => Promise<TimeSeriesItem[] | undefined>;
  openSearch: UseTurboChartProps['openSearch'];
  setHasError: Dispatch<SetStateAction<boolean>>;
  chartPanes: PaneData[];
  setChartPanes: Dispatch<SetStateAction<PaneData[]>>;
}

export const useChartSettings = ({
  onChartDataReady,
  getTimeSeries,
  openSearch,
  setHasError,
  chartPanes,
  setChartPanes,
}: UseChartSettingsProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const chartSettings = getChartSettingsFromSearchParams(searchParams);

  useEffect(() => {
    if (checkSearchParams(chartSettings)) {
      handleInitialChartLoad(chartSettings);
    } else {
      openSearch(ChartSearchType.Primary);
      setSearchParams('', { replace: true });
    }
  }, []);

  const handleInitialChartLoad = async ({
    assets,
    horizon,
    resample,
    seriesType,
    domain,
    hidden,
    yAxisType,
    priceDisplay,
  }: ValidChartSettings) => {
    try {
      const tags = assets.map(asset => asset.tag);
      const entities = await postEntities({
        args: tags,
        filter: FILTER.tag,
      });

      const sortedAssets = assets.sort((a, b) => a.paneIndex - b.paneIndex);
      const assetEntities: Entity[] = [];
      const paneStartIndexes: number[] = [];

      sortedAssets.forEach((asset, index) => {
        const entity = entities.find(entity => entity.tag === asset.tag);
        if (entity) {
          if (paneStartIndexes[asset.paneIndex] === undefined) {
            paneStartIndexes.push(index);
          }

          assetEntities.push({
            ...entity,
            default_snake: asset.snake,
            name: asset.name,
          });
        }
      });

      const primaryEntity = assetEntities[0];
      const hasIndicatorInList = assetEntities.some(checkIsIndicator);
      const validResample = getAssetResample({
        asset: assetEntities[0],
        type: ChartSearchType.Compare,
        selectedResample: resample,
        isIndicator: hasIndicatorInList,
      });
      const shouldFetchDaily = !nonIntradayResamples.includes(validResample);

      const [dailyTs, currentResamplePrices, snakeMetas] = await Promise.all([
        shouldFetchDaily
          ? getTimeSeries({
              latestAsset: primaryEntity,
              resolution: ResampleIntervals.OneDay,
            })
          : Promise.resolve([]),
        Promise.all(
          assetEntities.map(asset =>
            getTimeSeries({
              latestAsset: asset,
              resolution: validResample,
            })
          )
        ),
        Promise.all(
          assetEntities.map(asset => getSnakeMeta(asset.default_snake))
        ),
      ]);

      const nonIntradayPrices = shouldFetchDaily
        ? dailyTs
        : currentResamplePrices[0];

      const hasAllPrices = (
        prices: Array<TimeSeriesItem[] | undefined>
      ): prices is Array<TimeSeriesItem[]> =>
        prices.every(item => item?.length);

      if (!nonIntradayPrices || !hasAllPrices(currentResamplePrices)) {
        throw new Error('Some data is missing');
      }

      const primaryChartData = createChartDataItem({
        entity: primaryEntity,
        ts: currentResamplePrices[0],
        lineColor: defaultColors.line.default,
        isHidden: hidden?.includes(primaryEntity.tag),
        snakeMeta: snakeMetas[0],
      });
      const secondaryChartData = assetEntities.slice(1).map((entity, idx) =>
        createChartDataItem({
          entity,
          ts: currentResamplePrices[idx + 1],
          lineColor: lineColors[idx],
          primaryAsset: primaryChartData,
          isHidden: hidden?.includes(entity.default_snake),
          snakeMeta: snakeMetas[idx + 1],
        })
      );
      const chartPanes: PaneData[] = createChartPanesData({
        chartAssetData: [primaryChartData, ...secondaryChartData],
        paneStartIndexes,
        seriesType,
        priceDisplay,
        yAxisType,
      });

      const emptyResamples = getAssetEmptyResamples(
        primaryEntity,
        hasIndicatorInList
      );
      const assetHorizons = getPrimaryAssetHorizons(nonIntradayPrices);
      const validHorizon = getValidHorizon({
        horizon,
        chartData: chartPanes[0].chartAssetData,
        emptyResamples,
        hasIndicatorInList,
        resample,
      });

      onChartDataReady({
        assetHorizons,
        chartPanes,
        emptyResamples,
        validResample,
      });
      updateSearchParams({
        chartPanes,
        resample: validResample,
        horizon: validHorizon,
        seriesType,
        domain,
      });
    } catch (error) {
      setHasError(true);
      setSearchParams('', { replace: true });
    }
  };

  const updateSearchParams = (props: Partial<ChartSearchParamsProps>) => {
    const urlSearchParams = buildChartSearchParams({ searchParams, ...props });
    setSearchParams(urlSearchParams, { replace: true });
  };

  const changeYAxisSettings = ({
    type,
    priceDisplay,
  }: {
    type?: YAxisType;
    priceDisplay?: PriceDisplay;
  }) => {
    updateSearchParams({
      yAxisType: type,
      priceDisplay,
    });
    const finalType = type ?? chartSettings.yAxisType;
    const finalPriceDisplay = priceDisplay ?? chartSettings.priceDisplay;
    setChartPanes([
      {
        ...chartPanes[0],
        yAxisType: finalType,
        priceDisplay: finalPriceDisplay,
      },
      ...chartPanes.slice(1),
    ]);
  };

  return {
    chartSettings,
    updateSearchParams,
    changeYAxisSettings,
  };
};
