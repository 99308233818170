import { TableCell, TableHeader } from '@toggle/design-system';
import React from 'react';
import remarkGfm from 'remark-gfm';

import * as S from './ChatMarkdown.styles';

export interface ChatMarkdownProps {
  markdown: string;
}

export const ChatMarkdown = ({ markdown }: ChatMarkdownProps) => {
  // TODO this is fix for table markdown. Need to check if BE/DS can fix the GPT response to not include underscore with tables
  // Discussion: https://toggleglobal.slack.com/archives/C053NT28Q8M/p1686422095030189
  const formattedMarkdown = markdown.replace(/\|_/g, '| ');

  return (
    <S.MessageMarkdown
      markdownProps={{
        remarkPlugins: [remarkGfm],
        children: formattedMarkdown,
        components: {
          h1: ({ children }) => <S.MarkdownH1>{children}</S.MarkdownH1>,
          h2: ({ children }) => <S.MarkdownH2>{children}</S.MarkdownH2>,
          h3: ({ children }) => <S.MarkdownH3>{children}</S.MarkdownH3>,
          h4: ({ children }) => <S.MarkdownH4>{children}</S.MarkdownH4>,
          h5: ({ children }) => <S.MarkdownH5>{children}</S.MarkdownH5>,
          p: ({ children }) => <S.MarkdownP>{children}</S.MarkdownP>,
          a: ({ href, children, title }) => (
            <S.MarkdownA
              href={href}
              title={title}
              target="_blank"
              rel="noopener noreferrer"
            >
              {children}
            </S.MarkdownA>
          ),
          ul: ({ children }) => <S.MarkdownUL>{children}</S.MarkdownUL>,
          td: ({ children }) => <TableCell>{children}</TableCell>,
          th: ({ children }) => <TableHeader>{children}</TableHeader>,
          table: ({ children }) => (
            <S.MarkdownTableContainer>
              <S.MarkdownTable>{children}</S.MarkdownTable>
            </S.MarkdownTableContainer>
          ),
        },
      }}
    />
  );
};
