import { Entity } from '~/api/entities/entity-schema';
import {
  User,
  UserAddress,
  UserAddressPayload,
  userAddressSchema,
  userSchema,
  UserTimeZone,
} from '~/api/user/user-schema';
import { config } from '~/config';
import { OnboardingStates } from '~/declarations/toggle-api-enums';
import { UpdatePortfolioSettingsPayload } from '~/declarations/toggle-api-portfolio';
import { HttpMethods } from '~/shared/services/api-fetch/constants';
import { validateApiFetch } from '~/shared/services/api-fetch/validate-api-fetch/validateApiFetch';

import { StaticMessage, staticMessageSchema } from '../standard-schema';

const {
  api: { root: apiRoot },
} = config;

export const userEndpoints = {
  user: `${apiRoot}u/v1/user`,
  updateUser: (userId?: string) => `${apiRoot}u/v1/user/${userId}`,
  userTimezone: `${apiRoot}u/v1/tz`,
  userOnboarding: `${apiRoot}u/v1/user-ob`,
  userPortfolioSettings: `${apiRoot}p/v3/settings/portfolio`,
  address: `${apiRoot}u/v1/address`,
};

export const getUserData = () => {
  return validateApiFetch<User>({
    endpoint: userEndpoints.user,
    schema: userSchema,
  });
};

export const putUserData = (userData: User) => {
  return validateApiFetch<User>({
    endpoint: userEndpoints.updateUser(userData.id),
    schema: userSchema,
    requestInfo: {
      method: HttpMethods.Put,
      body: JSON.stringify(userData),
    },
  });
};

export const putUserTimezone = (userTimeZone: UserTimeZone) => {
  return validateApiFetch<StaticMessage>({
    endpoint: userEndpoints.userTimezone,
    schema: staticMessageSchema,
    requestInfo: {
      method: HttpMethods.Put,
      body: JSON.stringify(userTimeZone),
    },
  });
};

export const putOnboardingState = (state: OnboardingStates) => {
  return validateApiFetch<StaticMessage>({
    endpoint: userEndpoints.userOnboarding,
    schema: staticMessageSchema,
    requestInfo: {
      method: HttpMethods.Put,
      body: JSON.stringify({ onboarding_state: state }),
    },
  });
};

export const getUserPortfolioSettings = () => {
  // it returns 404 when user hasn't set his base currency yet
  // can be checked with: response.status === APIResponse.NOT_FOUND
  return validateApiFetch<Entity>({
    endpoint: userEndpoints.userPortfolioSettings,
  });
};

export function putUserPortfolioSettings(
  portfolioSettings: UpdatePortfolioSettingsPayload
) {
  return validateApiFetch<Entity>({
    endpoint: userEndpoints.userPortfolioSettings,
    requestInfo: {
      method: HttpMethods.Put,
      body: JSON.stringify(portfolioSettings),
    },
  });
}

// TODO remove after goal deployment
export const putUserAddress = (payload: UserAddressPayload) => {
  return validateApiFetch<UserAddress>({
    endpoint: userEndpoints.address,
    schema: userAddressSchema,
    requestInfo: {
      method: HttpMethods.Put,
      body: JSON.stringify(payload),
    },
  });
};

// TODO remove after goal deployment
export const getUserAddress = async () => {
  return validateApiFetch<UserAddress>({
    endpoint: userEndpoints.address,
    schema: userAddressSchema,
  });
};
