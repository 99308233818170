import { getCookie, setCookie } from '@toggle/helpers';

export const RELOAD_COOKIE_PRE_FIX = 'toggle.alreadyReloaded.';
export const reloadAppOnce = (page: string) => {
  const COOKIE_KEY_ALREADY_RELOADED = `${RELOAD_COOKIE_PRE_FIX}${page}`;
  const hasAlreadyReloaded = getCookie(COOKIE_KEY_ALREADY_RELOADED);
  if (hasAlreadyReloaded) {
    throw new Error('Lazy load error after reload');
  }

  setCookie(COOKIE_KEY_ALREADY_RELOADED);

  window.location.reload();
};
