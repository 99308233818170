import React, { FC } from 'react';

import {
  PaywallContent,
  PaywallContentProps,
} from './components/paywall-content/PaywallContent';
import { PaywallModal } from './components/paywall-modal/PaywallModal';

export interface PaywallProps extends PaywallContentProps {
  isOpen: boolean;
  onClose: () => void;
}

export const Paywall: FC<PaywallProps> = ({
  isOpen,
  onClose,
  ...paywallContentProps
}) => {
  return (
    <PaywallModal onClose={onClose} isOpen={isOpen}>
      <PaywallContent {...paywallContentProps} />
    </PaywallModal>
  );
};
