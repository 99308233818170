import { Icon } from '@toggle/design-system';
import { isMac, useSearchHotkey } from '@toggle/helpers';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { EllipsisDetector } from '~/shared/components/elipsis-detector/EllipsisDetector';
import { MappedEntity } from '~/shared/hooks/use-entities';

import * as S from './SearchButton.styles';

export interface SearchButtonProps {
  onClick: () => void;
  selectedSearchEntity?: MappedEntity;
  isMobile: boolean;
}

export const SearchButton: FC<SearchButtonProps> = ({
  onClick,
  selectedSearchEntity,
  isMobile,
  ...rest
}) => {
  const { t } = useTranslation('header');
  const searchText = selectedSearchEntity
    ? selectedSearchEntity.name_short || selectedSearchEntity.name_full
    : t('header:search.searchTitle');

  useSearchHotkey(onClick);

  return (
    <S.SearchLarge
      data-testid="search-button"
      onClick={onClick}
      isTouchDevice={isMobile}
      {...rest}
    >
      <Icon
        iconName="Search"
        data-testid="header-search-icon"
        size={20}
        title={t('header:svgIcons.iconActionSearch')}
        fillColor="var(--text-soft)"
      />
      {selectedSearchEntity ? (
        <EllipsisDetector label={searchText}>
          <S.SearchLargeText>{searchText}</S.SearchLargeText>
        </EllipsisDetector>
      ) : (
        <S.SearchLargeText>{searchText}</S.SearchLargeText>
      )}
      {!isMobile && (
        <S.Hotkey data-testid="hotkey">
          {isMac()
            ? t('header:search.macHotkey')
            : t('header:search.defaultHotkey')}
        </S.Hotkey>
      )}
    </S.SearchLarge>
  );
};
