import React from 'react';

import { BAR_HEIGHT, LABEL_WIDTH, ROW_HEIGHT } from '../constants';
import * as S from '../HorizontalBarChart.styles';

interface BarProps {
  value: number;
  index: number;
  xScale: d3.ScaleLinear<number, number, never>;
}

export const Bar = ({ value, index, xScale }: BarProps) => (
  <S.Rect
    data-testid="bar"
    x={xScale(value < 0 ? value : 0) + LABEL_WIDTH}
    y={index * ROW_HEIGHT}
    width={Math.abs(xScale(value) - xScale(0))}
    height={BAR_HEIGHT}
    hasProfit={value > 0}
  />
);
