import { HighlightedSectionsRefs } from '~/stores/use-helper/useHelper';

import { highlightSectionClass } from '../../HelperConfig';

export const useHighlightSectionHandler = (
  sectionsRefs: HighlightedSectionsRefs
) => {
  const handleMouseEnter = (id: string) => {
    const section = sectionsRefs[id]?.current;

    if (section) {
      section.classList.add(highlightSectionClass);
    }
  };

  const handleMouseLeave = (id: string) => {
    const section = sectionsRefs[id]?.current;

    if (section) {
      section.classList.remove(highlightSectionClass);
    }
  };

  return [handleMouseEnter, handleMouseLeave];
};
