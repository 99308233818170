import { bisector } from 'd3-array';
import { isSameDay } from 'date-fns';

import { Domain } from '~/types/axis.types';
import { XAxis, YAxis } from '~/types/create.types';
import {
  ChartAssetData,
  ChartInsight,
  GroupedInsights,
  GroupInsightsDirection,
  InsightDirection,
  InsightsInDomain,
  TimeSeriesItem,
} from '~/types/timeseries.types';

type InsightsByType = Record<string, GroupedInsights>;
export type InsightsByDate = Record<string, InsightsByType>;

export const getGroupDirection = (
  mainDirection: GroupInsightsDirection,
  secondDirection: InsightDirection
): GroupInsightsDirection => {
  const formattedMainDirection = mainDirection.includes('-')
    ? mainDirection.split('-')[1]
    : mainDirection;

  return formattedMainDirection === secondDirection
    ? `multi-${secondDirection}`
    : `multi-mixed`;
};

export const groupSingleInsight = (
  insight: ChartInsight,
  insightsByDate: InsightsByDate
) => {
  const date = insight.date.toISOString();

  if (!insightsByDate[date]) {
    insightsByDate[date] = {};
  }

  const type = insight.type;
  const group = insightsByDate[date][type];

  if (!group) {
    insightsByDate[date][type] = {
      date: insight.date,
      direction: insight.direction,
      insights: [insight],
      type,
    };
  } else {
    group.direction = getGroupDirection(group.direction, insight.direction);
    group.insights.push(insight);
  }

  return insightsByDate;
};

export const groupInsightsByDate = (insights: ChartInsight[]) =>
  insights.reduce((insightsByDate: InsightsByDate, insight: ChartInsight) => {
    groupSingleInsight(insight, insightsByDate);
    return insightsByDate;
  }, {});

export interface InsightInDomainProps {
  primaryAsset: ChartAssetData;
  domain: Domain;
  insights?: ChartInsight[];
  y: YAxis[];
  x: XAxis;
  width: number;
}

export const getInsightsInDomain = ({
  primaryAsset,
  domain,
  insights,
  y,
  x,
  width,
}: InsightInDomainProps) => {
  const insightsInDomain: InsightsInDomain[] = [];
  if (!insights?.length) {
    return insightsInDomain;
  }
  const data = primaryAsset.ts;
  const bis = bisector((d: TimeSeriesItem) => new Date(d.time).getTime());
  const roundedDomain = [Math.ceil(domain[0]), Math.floor(domain[1])];
  const top = y[0].yScale.range()[0];

  const groupedInsightsByDate: InsightsByDate = groupInsightsByDate(insights);

  Object.entries(groupedInsightsByDate).forEach(
    ([dateKey, groupedInsights]) => {
      const date1 = new Date(dateKey);
      const dateTime = date1.getTime();
      const index = bis.left(
        data,
        dateTime,
        roundedDomain[0],
        roundedDomain[1]
      );
      const date2 = new Date(data[index].time);
      const isExactDate = dateTime === date2.getTime();
      const isPrevDaySame =
        !isExactDate &&
        !!data[index - 1] &&
        isSameDay(new Date(data[index - 1].time), date1);
      const isFirstDataPointOfDay =
        isExactDate || (isSameDay(date1, date2) && !isPrevDaySame);

      if (isFirstDataPointOfDay) {
        insightsInDomain.push({
          top,
          right: width - x.xScale(index),
          date: new Date(dateKey),
          groupedInsights: Object.values(groupedInsights),
        });
      }
    }
  );

  //this is required for simplifying arrow navigation
  //closest to the left edge will have 0 index, closest to the right edge - last index
  return insightsInDomain.sort((a, b) => b.right - a.right);
};
