import isEqual from 'lodash/isEqual';
import { useEffect, useReducer, useState } from 'react';

import { Optional, OptionalU } from '~/declarations/standard';
import { Article, ArticleV2, ArticleV3 } from '~/declarations/toggle-api';
import {
  MutableDataReducerActionTypes,
  WithParsedQuery,
} from '~/global/toggle-app.d';
import { AppPathArticleData } from '~/routes/AppRoutes.d';
import { APIResponse } from '~/shared/services/api-fetch';
import { FetchError } from '~/shared/services/fetch/FetchErrors';
import { gaArticleHitInsightsLimit } from '~/shared/utils/ganalytics';
import { useArticleLimit } from '~/stores/use-article-limit/useArticleLimit';
import { useUser } from '~/stores/use-user/useUser';

import { fetchArticleFromAPI } from '../services';

interface UseArticleData<T = Article> {
  article: Optional<T>;
}

interface ArticleDataHookData extends UseArticleData {
  isFetching: boolean;
  isError: boolean;
}

type ArticleReducerActionType = MutableDataReducerActionTypes | 'setBookmark';

type ArticleDataHookConfig = WithParsedQuery<AppPathArticleData>;

interface ArticleReducerDispatchAction {
  type: ArticleReducerActionType;
  fetching?: boolean;
  error?: boolean;
  article?: Article;
  config?: ArticleDataHookConfig;
  isBookmarked?: boolean;
}

export interface UseArticleDataResult<T = ArticleV3 | ArticleV2> {
  config: ArticleDataHookConfig;
  data: UseArticleData<T>;
  isFetching: boolean;
  fetchArticle: (articleID: string) => void;
  setBookmark: (isBookmarked: boolean) => void;
  isError: boolean;
  limitError: boolean;
}

interface ArticleDataHookInternalData extends ArticleDataHookData {
  config: ArticleDataHookConfig;
}

function createInitialState(
  config: ArticleDataHookConfig
): ArticleDataHookInternalData {
  return {
    config,
    article: null,
    isFetching: true,
    isError: false,
  };
}

function articleReducer(
  state: ArticleDataHookInternalData,
  action: ArticleReducerDispatchAction
): ArticleDataHookInternalData {
  let updatedState: ArticleDataHookInternalData;

  switch (action.type) {
    case 'fetching':
      const isFetching =
        (action.fetching as boolean) === undefined
          ? true
          : (action.fetching as boolean);
      updatedState =
        isFetching === state.isFetching ? state : { ...state, isFetching };
      break;
    case 'error':
      updatedState = {
        ...state,
        isError: true,
        isFetching: false,
      };
      break;
    case 'data':
      const article = action.article as Article;
      updatedState = {
        ...state,
        article,
        isFetching: false,
      };
      break;
    case 'setBookmark':
      const updateArticle = {
        ...{
          article: {
            ...state.article,
            ...{ bookmarked: action.isBookmarked },
          } as Article,
        },
      };
      updatedState = {
        ...state,
        ...updateArticle,
      };
      break;
    case 'reset':
      updatedState = createInitialState(action.config!);
      break;
    default:
      updatedState = state;
  }

  return updatedState;
}

export function useArticleData(
  articleViewData: ArticleDataHookConfig
): UseArticleDataResult {
  const isAuthenticated = useUser(state => state.isAuthenticated());
  const [limitError, setLimitError] = useState(false);
  const setLimits = useArticleLimit(state => state.setData);

  const [data, setData] = useReducer(
    articleReducer,
    createInitialState(articleViewData)
  );

  const {
    config: { articleID },
  } = data;

  async function fetchData(articleID: OptionalU<string>) {
    const res = await fetchArticleFromAPI(articleID, isAuthenticated);

    if (res instanceof Error) {
      if (
        res instanceof FetchError &&
        res.status === APIResponse.TOO_MANY_REQUESTS
      ) {
        setLimitError(true);
        gaArticleHitInsightsLimit();
      }
      setData({
        type: 'error',
      });
    } else {
      const { article, limit, consumed, duration } = res;

      setLimits({
        limit,
        consumed,
        duration,
      });
      setData({
        type: 'data',
        article,
      });
    }
  }

  function setBookmark(isBookmarked: OptionalU<boolean>) {
    setData({
      type: 'setBookmark',
      isBookmarked,
    });
  }

  function fetchArticle(articleID: OptionalU<string>) {
    setData({
      type: 'fetching',
    });
    fetchData(articleID);
  }

  useEffect(() => {
    fetchArticle(articleID);
  }, []);

  useEffect(() => {
    if (!isEqual(data.config, articleViewData)) {
      setData({
        type: 'reset',
        config: articleViewData,
      });
      fetchArticle(articleViewData.articleID);
    }
  });

  const { isError, isFetching, config, ...feedData } = data;

  return {
    config: articleViewData,
    data: feedData,
    fetchArticle,
    setBookmark,
    isFetching,
    isError,
    limitError,
  };
}
