import { v4 as uuid } from 'uuid';
import { StoreApi } from 'zustand';

import {
  ExploreFilterOption,
  OptionProperties,
  UserFilterResponse,
  UserFilterResponseFilters,
} from '~/api/explore-filter/explore-filter-schema';

import { UseExploreFiltersStore } from './../useExploreFilters';

export interface OptionWithPropertiesOnly {
  properties?: OptionProperties;
}

export interface Filter {
  type: string;
  name: string;
  key: string;
  icon?: string;
  displayType?: 'set' | 'checkbox' | 'radio';
}

export interface FilterScreen {
  id: string;
  name: string;
  filters: Filter[];
  originFilters: Filter[];
}

export const EXPLORE_FILTERS_LS_KEY = 'explore-filters';
export const FILTER_SCREENS_LIMIT = 6;
export const FILTER_SCREEN_LS_KEY = 'active-explore-filter-screen';

export const createScreen = (screen?: Partial<FilterScreen>): FilterScreen => ({
  id: uuid(),
  name: '',
  filters: [],
  originFilters: [],
  ...screen,
});

export const createUniqueScreenName = (
  screens: FilterScreen[],
  activeScreen: FilterScreen,
  name: string
): string => {
  const screenWithSameName = screens.find(s => s.name === name);
  const isRenameWithSameName = screenWithSameName?.id === activeScreen.id;

  if (!screenWithSameName || isRenameWithSameName) {
    return name;
  }

  let num = 1;
  const regex = new RegExp(`${name} #(\\d+)$`);

  screens.forEach(screen => {
    const matches = screen.name.match(regex);

    if (matches && +matches[1] >= num) {
      num = +matches[1] + 1;
    }
  });

  return `${name} #${num}`;
};
export const formatFiltersForSave = (filters: Filter[]) =>
  filters.reduce<UserFilterResponseFilters>((filters, filter) => {
    if (filters[filter.type]) {
      return {
        ...filters,
        [filter.type]: [...filters[filter.type], filter.key],
      };
    }

    return {
      ...filters,
      [filter.type]: [filter.key],
    };
  }, {});

export const formatFilterScreenPayload = (
  filters: UserFilterResponseFilters,
  filterOptions: ExploreFilterOption[]
) =>
  Object.entries(filters).reduce<Filter[]>((filters, [key, value]) => {
    const currentOption = filterOptions.find(o => o.key === key);
    if (currentOption) {
      currentOption.options.forEach(option => {
        if (value.includes(option.key)) {
          filters.push({
            type: currentOption.key,
            key: option.key,
            name: option.name,
            icon: option.icon,
            displayType: currentOption.type,
          });
        }
      });
    }

    return filters;
  }, []);

export const getScreensConfig = (
  originScreens: UserFilterResponse[],
  get: StoreApi<UseExploreFiltersStore>['getState']
): { activeScreen: FilterScreen | null; screens: FilterScreen[] } => {
  const screens = originScreens.map(screen => {
    const formattedFilters = formatFilterScreenPayload(
      screen.filters,
      get().filterOptions
    );
    return {
      ...screen,
      filters: formattedFilters,
      originFilters: formattedFilters,
    };
  });

  const savedActiveScreen = get().activeScreen;
  const activeScreen =
    savedActiveScreen && screens.find(({ id }) => id === savedActiveScreen.id)
      ? savedActiveScreen
      : screens[0];
  return { activeScreen, screens };
};
