import {
  deviceConstraints,
  Dropdown,
  DropdownListOption,
  DropdownProps,
  Icon,
  Input,
  typography,
} from '@toggle/design-system';
import styled from 'styled-components';

import { searchDropdownWrapper } from './SharedSearch.styles';

export const TypedStyledDropdown = styled(Dropdown)<{
  $isWatchlistOnly: boolean;
}>`
  ${Input.Styles.InputWrapper} {
    padding: 0;
    height: 40px;
    @media ${deviceConstraints.desktopSmall} {
      height: 48px;
    }
    border: none;
    background: transparent;
    border-bottom: 1px solid var(--base--75);

    ${Input.Styles.InputElement} {
      background: none;
      padding-left: 10px;
    }
  }

  ${Dropdown.Styled.DropdownListContainer} {
    ${searchDropdownWrapper}
  }

  ${DropdownListOption.Styled.ListOption} {
    display: flex;
    align-items: center;
    font-size: 16px;
    height: 44px;
    line-height: 43px;
    padding-right: 4px;
    color: var(--base--0);

    cursor: ${({ $isWatchlistOnly }) =>
      $isWatchlistOnly ? 'default' : 'pointer'};

    > button {
      visibility: hidden;
    }

    &:hover {
      > button {
        visibility: visible;
      }
    }
  }

  ${Input.Styles.InputElement} {
    width: 100%;
  }
` as <T>(
  props: DropdownProps<T> & {
    $isWatchlistOnly: boolean;
  }
) => JSX.Element;

export const ItemsPlaceholder = styled.p`
  ${typography.emu}
  padding: 12px;
`;

export const CloseIcon = styled(Icon)`
  margin-right: 16px;
`;
