import ReactGA from 'react-ga4';

import { OrderType } from '~/api/trading/trading-service';
import { Expiration } from '~/global/trading-drawer/use-transaction/useTransaction';
import { TradingProvider } from '~/stores/use-portfolio/utils/use-portfolio-utils';

const CATEGORIES = {
  trading: 'Trading',
  close: 'Close',
  confirm: 'Confirm',
  review: 'Review',
  submit: 'Submit',
  portfolio: 'Portfolio',
  tryAgain: 'Try Again',
};
export { CATEGORIES as TRADING_CATEGORIES };

const ACTIONS = {
  clickBuy: 'Click_buy',
  clickSell: 'Click_sell',
  clickTrade: 'Click_trade',
  clickClosePosition: 'Click_close_position',
  clickEditOrder: 'Click_edit_order',
  clickOrderType: 'Click_order_type',
  clickOrderStatus: 'Click_view_order_status',
  clickViewOnIB: 'Click_view_on_IB',
  clickViewOnTradestation: 'Click_view_on_Tradestation',
  expandNewPositionMessage: 'Expand_new_position_message',
  chooseExpiration: 'Choose_expiration',
  clickClosePopup: 'Click_close_popup',
  clickCloseDrawer: 'Click_close_drawer',
  clickConfirm: 'Click_confirm',
  clickReview: 'Click_review',
  clickSubmit: 'Click_submit',
  clickCancelOrder: 'Click_cancel_order',
  clickTryAgain: 'Click_try_again',
};

export { ACTIONS as TRADING_ACTIONS };

export function gaClickBuy(location: string, tag: string) {
  ReactGA.event({
    category: CATEGORIES.trading,
    action: ACTIONS.clickBuy,
    label: `location:${location};tag:${tag}`,
  });
}

export function gaClickSell(location: string, tag: string) {
  ReactGA.event({
    category: CATEGORIES.trading,
    action: ACTIONS.clickSell,
    label: `location:${location};tag:${tag}`,
  });
}

export function gaClickTrade(location: string) {
  ReactGA.event({
    category: CATEGORIES.trading,
    action: ACTIONS.clickTrade,
    label: `location:${location}`,
  });
}

export function gaClickEditOrder() {
  ReactGA.event({
    category: CATEGORIES.trading,
    action: ACTIONS.clickEditOrder,
  });
}

export function gaClickOrderType(orderType: OrderType) {
  ReactGA.event({
    category: CATEGORIES.trading,
    action: ACTIONS.clickOrderType,
    label: `orderType:${orderType}`,
  });
}

export function gaClickOrderStatus() {
  ReactGA.event({
    category: CATEGORIES.trading,
    action: ACTIONS.clickOrderStatus,
  });
}

export function gaClickViewOnProvider(tradingProvider: TradingProvider) {
  const mapTradingProviderToAction = {
    [TradingProvider.Ib]: ACTIONS.clickViewOnIB,
    [TradingProvider.Tradestation]: ACTIONS.clickViewOnTradestation,
  };
  ReactGA.event({
    category: CATEGORIES.trading,
    action: mapTradingProviderToAction[tradingProvider],
  });
}

export function gaExpandNewPositionMessage() {
  ReactGA.event({
    category: CATEGORIES.trading,
    action: ACTIONS.expandNewPositionMessage,
  });
}

export function gaClickClosePosition() {
  ReactGA.event({
    category: CATEGORIES.trading,
    action: ACTIONS.clickClosePosition,
  });
}

export function gaChooseExpiration(expiration: Expiration) {
  let expirationType = '';
  switch (expiration) {
    case Expiration.Day:
      expirationType = 'end of trading day';
      break;
    case Expiration.GoodTillCancel:
      expirationType = 'good until canceled';
      break;
    default:
      break;
  }

  ReactGA.event({
    category: CATEGORIES.trading,
    action: ACTIONS.chooseExpiration,
    label: `expirationType:${expirationType}`,
  });
}

export enum OrderFormType {
  Form = 'order form',
  History = 'order history',
}

export function gaClickClosePopup(location: string, formType: OrderFormType) {
  ReactGA.event({
    category: CATEGORIES.close,
    action: ACTIONS.clickClosePopup,
    label: `location:${location};formType:${formType}`,
  });
}

export function gaClickCloseDrawer(location: string, formType: OrderFormType) {
  ReactGA.event({
    category: CATEGORIES.close,
    action: ACTIONS.clickCloseDrawer,
    label: `location:${location};formType:${formType}`,
  });
}

export function gaClickConfirm() {
  ReactGA.event({
    category: CATEGORIES.confirm,
    action: ACTIONS.clickConfirm,
  });
}

export function gaClickReview(location: string, formType: OrderFormType) {
  ReactGA.event({
    category: CATEGORIES.review,
    action: ACTIONS.clickReview,
    label: `location:${location};formType:${formType}`,
  });
}

export function gaClickSubmit(location: string, formType: OrderFormType) {
  ReactGA.event({
    category: CATEGORIES.submit,
    action: ACTIONS.clickSubmit,
    label: `location:${location};formType:${formType}`,
  });
}

export function gaClickCancelOrder() {
  ReactGA.event({
    category: CATEGORIES.portfolio,
    action: ACTIONS.clickCancelOrder,
  });
}

export function gaTryAgain(location: string, formType: OrderFormType) {
  ReactGA.event({
    category: CATEGORIES.tryAgain,
    action: ACTIONS.clickTryAgain,
    label: `location:${location};formType:${formType}`,
  });
}
