import styled, { css } from 'styled-components';

import { typographyV2 } from '~/design-tokens/typography/Typography.styles';

export const ButtonGroupRoot = styled.div`
  display: flex;
  width: 100%;

  background-color: var(--background-soft);
  border: 1px solid var(--border-soft);

  border-radius: 4px;
  padding: 4px;
  gap: 4px;
`;

export const ButtonGroupItem = styled.button<{ isActive?: boolean }>`
  ${typographyV2.bodySm};
  background: none;
  border: none;
  width: 100%;

  color: var(--text-default);
  border-radius: 4px;
  padding: 2px 4px;
  cursor: pointer;
  text-transform: capitalize;

  &:hover {
    background-color: var(--surface-hover);
  }

  ${props =>
    props.isActive &&
    css`
      color: var(--text-primary);
      background-color: var(--action-primary-soft-hover);

      &:hover {
        background-color: var(--action-primary-soft-hover);
      }
    `}
`;
