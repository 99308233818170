const lightModeFaviconFileName = 'copilot-favicon';
const darkModeFaviconFileName = 'copilot-dark-mode-favicon';

export function changeFavicon(isDarkMode: boolean) {
  const faviconFileName = isDarkMode
    ? darkModeFaviconFileName
    : lightModeFaviconFileName;
  const faviconElement = document.querySelector('link[rel="icon"]');

  faviconElement?.setAttribute(
    'href',
    `/app/assets/core/${faviconFileName}.png`
  );
}
