import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ArticleV3 } from '~/declarations/toggle-api';
import { UpdateBrowserTitle } from '~/shared/utils/update-browser-title/UpdateBrowserTitle';
import { useArticleLimit } from '~/stores/use-article-limit/useArticleLimit';
import { NotFound } from '~/views/error/not-found/NotFound';

import { useArticleContext } from '../context/ArticleContext';
import { FullArticle } from '../partials';

interface ArticleProps {
  isAuthenticated: boolean;
  isBasic: boolean;
}

export const ArticleContent: FC<ArticleProps> = ({
  isAuthenticated,
  isBasic,
}) => {
  const { t } = useTranslation(['article', 'common', 'signInBanner']);
  const limits = useArticleLimit(state => state.data);
  const {
    data: { article },
    config: { articleID },
  } = useArticleContext<ArticleV3>();

  if (!article) {
    return <NotFound />;
  }
  return (
    <>
      <UpdateBrowserTitle
        title={`${article.title_details.ticker.split(':')[0]} | ${
          article.direction.tag_name
        } ${t('article:title')}`}
      />
      <FullArticle
        isAuthenticated={isAuthenticated}
        article={article}
        articleID={articleID}
        t={t}
        limit={limits?.limit}
        consumed={limits?.consumed}
        isBasic={isBasic}
      />
    </>
  );
};
