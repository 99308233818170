import { v4 } from 'uuid';

import { APIFetchError } from '~/shared/services/api-fetch/APIFetchError';
import { useAuthStore } from '~/stores/use-auth/store/authStore';

import { FetchError } from '../fetch/FetchErrors';
import {
  APIFetchArgs,
  APIFetchOptions,
  ApiFetchPostProcessArgs,
  APIStructuredResponse,
} from './APIFetch';
import { APIResponse } from './constants';

const requestIDHeaderName = 'X-Request-ID';
const authHeaderName = 'Authorization';

const DEFAULT_API_OPTIONS: APIFetchOptions = {
  requiresAuth: true,
};

const defaultHeaders: Headers = new Headers({
  'Content-Type': 'application/json',
});

const defaultFetchOptions: Partial<RequestInit> = {
  cache: 'no-cache',
};

export function addRequestId(...fetchParams: APIFetchArgs): APIFetchArgs {
  const [request, params, apiParams] = fetchParams;

  const headers = new Headers(params?.headers);
  if (!headers.has(requestIDHeaderName)) {
    headers.set(requestIDHeaderName, v4());
  }
  //@ts-ignore
  return [request, { ...params, headers }, apiParams];
}

export function addDefaultFetchOptions(
  ...fetchParams: APIFetchArgs
): APIFetchArgs {
  const [req, fetchOptions = {}, apiParams] = fetchParams;
  const headers = new Headers(defaultHeaders);
  new Headers(fetchOptions.headers).forEach((v, k) => headers.set(k, v));
  //@ts-ignore
  return [req, { ...defaultFetchOptions, ...fetchOptions, headers }, apiParams];
}

export async function addAuthHeaders(
  ...fetchParams: APIFetchArgs
): Promise<APIFetchArgs> {
  const [request, params, options] = fetchParams;
  const apiOptions = { ...DEFAULT_API_OPTIONS, ...options };

  const { requiresAuth } = apiOptions;
  const headers = new Headers(params?.headers);

  const token = useAuthStore.getState()?.token;
  if (requiresAuth && !headers.has(authHeaderName) && token) {
    headers.set(authHeaderName, `Bearer ${token}`);
  }
  //@ts-ignore
  return [request, { ...params, headers }, options];
}

export async function checkForErrors<T>(
  ...args: ApiFetchPostProcessArgs<T>
): Promise<ApiFetchPostProcessArgs<T>> {
  const [fetchParams, response] = args;
  const { errors } = (response as unknown as APIStructuredResponse) || {};

  if (
    response instanceof FetchError &&
    response.status === APIResponse.UPGRADE_REQUIRED
  ) {
    await useAuthStore.getState().refreshToken();
  }

  if (errors) {
    const [url] = fetchParams;
    return [fetchParams, new APIFetchError(url as string, errors)];
  }
  return [fetchParams, response];
}
