import type { ChatStreamChunk } from '~/views/chat/services/readable-streams/readable-streams.types';
import {
  Message,
  MessageStatus,
} from '~/views/chat/use-chat-store/chat-store.types';

import { OrchestratorChatMetadata } from '../helpers/chat.types';
import { getIntentFromResponse } from './getIntentFromResponse';

const handleOrchestratorEvent = (
  messageToUpdate: Message,
  chunkMetadata: OrchestratorChatMetadata,
  requestId: string
): Message => ({
  isActive: true,
  requestId: requestId,
  question: messageToUpdate.question,
  service: 'orchestrator',
  status: MessageStatus.Sent,
  reply: {
    answer: '',
    metaData: getIntentFromResponse(chunkMetadata),
    draftEmail: chunkMetadata.scopes?.includes('portfolio'),
    orchestratorMetadata: chunkMetadata,
  },
});

export const handleMessageChunks = (
  foundMessage: Message,
  chunk: ChatStreamChunk,
  requestId: string
): Message => {
  const currentAnswer = foundMessage.reply?.answer ?? '';
  switch (chunk.event) {
    case 'analysis':
      return handleOrchestratorEvent(foundMessage, chunk.data, requestId);
    case 'gpt-chunk':
      return {
        ...foundMessage,
        reply: {
          ...foundMessage.reply,
          answer: currentAnswer + chunk.data,
        },
      };
    case 'gpt-complete':
      return {
        ...foundMessage,
        status: MessageStatus.Received,
        reply: {
          ...foundMessage.reply,
          answer: currentAnswer,
          success: chunk.data,
          isPending: false,
          isError: chunk.isError,
        },
      };
    default:
      return foundMessage;
  }
};
