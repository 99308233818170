import { useDisableScroll } from '@toggle/helpers';
import React, { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import * as S from './FullscreenModal.styles';

export interface FullscreenModalProps {
  onClose?: () => void;
  children: ReactNode;
  header?: ReactNode;
}

export const FullscreenModal: FC<FullscreenModalProps> = ({
  onClose,
  children,
  header,
}) => {
  const { t } = useTranslation(['common']);
  useDisableScroll(true);

  return (
    <S.Modal>
      <S.Content>
        <S.Header>
          {header}
          {onClose && (
            <S.CloseButton
              data-testid="close-button"
              onClick={onClose}
              iconName="Close"
              size="small"
              variant="empty"
              aria-label={t('common:close')}
            />
          )}
        </S.Header>
        <S.Body>{children}</S.Body>
      </S.Content>
    </S.Modal>
  );
};
