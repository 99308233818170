import { OutsideHours } from '@toggle/design-system';
import { TFunction } from 'i18next';

import { LivePriceInfo } from '~/shared/hooks/use-live-price/use-live-price-store/useLivePriceStore';

import { PriceBase, SecondaryPrice } from '../price-display.types';

/**
 * Formats details from price, translation files and live price that returns a SecondaryPrice object.
 */
export const formatSecondaryPrice = (
  base: PriceBase,
  t: TFunction,
  livePriceInfo?: LivePriceInfo
): SecondaryPrice => {
  const premarketText: OutsideHours = {
    label: t('analyze:analysis.preMarket'),
    tag: 'pre',
  };
  const afterMarketText: OutsideHours = {
    label: t('analyze:analysis.afterHours'),
    tag: 'after',
  };

  const aftermarket = base.marketHours.isAfterMarket
    ? afterMarketText
    : undefined;
  const outsideHours = base.marketHours.isPreMarket
    ? premarketText
    : aftermarket;

  return {
    ...base,
    outsideHours,
    isLive: !base.marketHours.isMarketHours && livePriceInfo?.isLive,
  };
};
