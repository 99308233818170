import { TFunction } from 'i18next';
import React, { FC, forwardRef, ReactElement } from 'react';
import remarkGfm from 'remark-gfm';

import { ArticleComponent, ArticleV3 } from '~/declarations/toggle-api.d';
import { ArticleComponentType } from '~/declarations/toggle-api-enums';
import { ErrorBoundary } from '~/shared/components/error-boundary';
import { Markdown } from '~/shared/components/markdown/Markdown';

import { LinkMarkdown } from '../link-markdown/LinkMarkdown';
import * as S from './BackTest.styles';

interface BackTestProps {
  article: ArticleV3;
  t: TFunction;
}

interface TableRendererProps {
  markdownChildren: ReactElement[];
}

const BacktestTableRenderer: FC<TableRendererProps> = ({
  markdownChildren,
}) => {
  const [headerCfg] = markdownChildren[0].props.children;
  const [bodyCfg] = markdownChildren[1].props.children;
  const topLeftHeader = headerCfg.props.children[0];
  const topRightHeader = headerCfg.props.children[1];
  const leftBodyContent = bodyCfg.props.children[0];
  const rightBodyContent = bodyCfg.props.children[1];
  return (
    <table data-testid="backtest-table">
      <S.BacktestSample>
        <S.BacktestSampleLeft>
          {topLeftHeader}
          {leftBodyContent}
        </S.BacktestSampleLeft>
        <tr>
          {topRightHeader}
          {rightBodyContent}
        </tr>
      </S.BacktestSample>
    </table>
  );
};

function mapBackTest(backtest: ArticleComponent, imageName: string, i: number) {
  switch (backtest.component_type) {
    case ArticleComponentType.PNG:
      return (
        <S.BacktestFigure
          key={i}
          link={backtest.link}
          imageName={`${imageName}-backtest`}
        >
          <S.BacktestImage src={backtest.link} alt="backtest-chart" />
        </S.BacktestFigure>
      );
    case ArticleComponentType.TEXT:
      return (
        <Markdown
          key={i}
          markdownProps={{
            remarkPlugins: [remarkGfm],
            children: backtest.component_data,
            components: {
              a: ({ href, children, title }) => (
                <LinkMarkdown href={href} title={title}>
                  {children}
                </LinkMarkdown>
              ),
              table: ({ children }) => (
                <BacktestTableRenderer
                  markdownChildren={children as ReactElement[]}
                />
              ),
            },
          }}
        />
      );
    default:
      return null;
  }
}

function mapDisclaimer(disclaimer: ArticleComponent, i: number) {
  return disclaimer.component_type === ArticleComponentType.TEXT ? (
    <Markdown
      key={i}
      markdownProps={{
        children: disclaimer.component_data,
        components: {
          p: ({ children }) => (
            <S.DisclaimerText data-testid="disclaimer-text">
              {children}
            </S.DisclaimerText>
          ),
        },
      }}
    />
  ) : null;
}

export const BackTest = forwardRef<HTMLHeadingElement, BackTestProps>(
  ({ t, article }, ref) => {
    return (
      <S.BacktestSection data-testid="backtest-section">
        <S.Title ref={ref}>{t('article:sidebarMenu.backtest')}</S.Title>
        <ErrorBoundary>
          {article.backtest.map((backtestData, i) =>
            mapBackTest(backtestData, article.entity.tag_name, i)
          )}
        </ErrorBoundary>

        {article.disclaimer.map(mapDisclaimer)}
      </S.BacktestSection>
    );
  }
);
