import { TableCell, TableHeader } from '@toggle/design-system';
import { checkCellValueStyle } from '@toggle/helpers';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { EvidenceEpisode, formatPercentage } from '../helpers/scenario-helpers';
import * as S from './EvidenceTable.styles';

export interface EvidenceTableProps {
  evidenceExperimentData: EvidenceEpisode[];
}

export const EvidenceTable = ({
  evidenceExperimentData,
}: EvidenceTableProps) => {
  const { t } = useTranslation('widget');

  const EVIDENCE_HEADER_DATA: string[] = [
    t('scenario.pastEpisode'),
    t('scenario.oneWeekPercentage'),
    t('scenario.twoWeekPercentage'),
    t('scenario.oneMonthPercentage'),
    t('scenario.threeMonthPercentage'),
    t('scenario.sixMonthPercentage'),
  ];

  return (
    <S.EvidenceTable
      TableHeaderContent={
        <tr>
          {EVIDENCE_HEADER_DATA.map((name, i) => (
            <TableHeader
              key={i}
              alignment={i === 0 ? 'start' : 'end'}
              isSortable={false}
            >
              {name}
            </TableHeader>
          ))}
        </tr>
      }
      TableBodyContent={
        <>
          {evidenceExperimentData.map((episode, i) => (
            <S.BodyRow key={i}>
              <S.EpisodeTableCell
                data-testid="evidence-episode-cell"
                alignment="start"
              >
                {`${t('scenario.episode')} ${i + 1}`}
                <S.SpecialMessage data-testid={`evidence-message-${i}`}>
                  {t('scenario.dateForTrading', {
                    date: episode.date,
                    count: episode.count,
                  })}
                </S.SpecialMessage>
              </S.EpisodeTableCell>
              {episode.episodeData.map((d, episodeIndex) => (
                <TableCell
                  key={episodeIndex}
                  alignment="end"
                  numericalValueStyle={checkCellValueStyle(+d.averageData)}
                >
                  {formatPercentage(d.averageData)}
                </TableCell>
              ))}
            </S.BodyRow>
          ))}
        </>
      }
    />
  );
};
