import { z } from 'zod';

const cognitiveSearchDocumentsSchema = z.object({
  id: z.string().optional(),
  title: z.string().optional(),
  date: z.string().optional(),
  link: z.string().optional(),
});

const cognitiveSearchMetadataSchema = z.object({
  documents: z.array(cognitiveSearchDocumentsSchema).optional(),
});

export type CognitiveSearch = z.infer<typeof cognitiveSearchSchema>;
export const cognitiveSearchSchema = z.object({
  request_id: z.string().optional(),
  summary: z.string().optional(),
  metadata: cognitiveSearchMetadataSchema.optional(),
});
