import styled from 'styled-components';

export const Table = styled.table<{
  $withBodyRowHover: boolean;
  $isHeaderSticky: boolean;
}>`
  border-spacing: 0;
  width: 100%;

  ${props =>
    props.$withBodyRowHover &&
    `
      tbody tr:hover {
        background-color: var(--surface-hover);
        td {
          background-color: var(--surface-hover);
        }
      }
    `}

  ${props =>
    props.$isHeaderSticky &&
    `th { 
      position: sticky;
      top: 0;
      z-index: 1;
     }`}
`;

export const FixedTableCell = `
  &::before {
    position: absolute;
    top: 0;
    right: -1px;
    bottom: 0;
    width: 15px;
    transform: translate(100%);
    transition: box-shadow 0.3s;
    content: '';
    pointer-events: none;
    box-shadow: inset 8px 0px 12px -6px rgba(0, 0, 0, 0.15);
  }
  position: sticky;
  left: 0px;
  z-index: 1;
  border-right: 1px solid var(--border-default);
  background-color: var(--background-soft);
`;
