import classNames from 'classnames';
import React, { HTMLAttributes } from 'react';

import countryCodesAlpha3 from '~/shared/utils/country-iso-codes/country-iso-alpha-three.json';
import countryCodes from '~/shared/utils/country-iso-codes/country-iso-codes.json';

import * as S from './FlagIcon.styles';

export interface FlagIconProps extends HTMLAttributes<HTMLSpanElement> {
  countryCode?: string;
  size?: 'sm' | 'md' | 'lg';
  className?: string;
}

export const FlagIcon = ({
  countryCode = '',
  size = 'md',
  className,
  ...rest
}: FlagIconProps) => {
  const countryCodeUpperCase = countryCode.toUpperCase();

  if (!countryCode) {
    return null;
  }

  if (
    !countryCodesAlpha3.includes(countryCodeUpperCase) &&
    !countryCodes.includes(countryCodeUpperCase)
  ) {
    return (
      <S.Fallback size={size} {...rest} data-testid="fallback">
        {countryCodeUpperCase}
      </S.Fallback>
    );
  }

  return (
    <S.Flag
      size={size}
      className={classNames(`fflag-${countryCodeUpperCase}`, className)}
      {...rest}
    />
  );
};
