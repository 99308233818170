import { TooltipTrigger } from '@toggle/design-system';
import React from 'react';

import { TooltipContainer } from '~/shared/components/tooltip-container/TooltipContainer';

import * as S from './ShareButtons.styles';

export interface ShareButtonsTooltipProps {
  text: string;
}

export function ShareButtonsTooltip({
  text,
  children,
}: React.PropsWithChildren<ShareButtonsTooltipProps>) {
  return (
    <TooltipContainer
      label={<span style={{ color: 'var(--base--95)' }}>{text}</span>}
      trigger={[TooltipTrigger.Hover]}
    >
      <S.AlertTooltip data-testid="alert-tooltip">{children}</S.AlertTooltip>
    </TooltipContainer>
  );
}
