import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { SubscriptionName } from '~/api/subscription/subscription-schema';
import { accountRoutes, appPaths } from '~/routes/app-paths';
import { useMediaQuery } from '~/shared/hooks/MediaQueryHook';
import { MediaQueries } from '~/shared/hooks/MediaQueryList';
import { usePromoCodeStore } from '~/stores/use-promo-code/usePromoCodeStore';
import { useSubscription } from '~/stores/use-subscription/useSubscription';

import { gaClickSubscribeButton } from '../../utils/ganalytics';
import * as S from './NotificationBanner.styles';

export const NotificationBanner: FC<{ pathname: string }> = ({ pathname }) => {
  const { t } = useTranslation('subscription');
  const navigate = useNavigate();

  const { userSubscription, hasActivePromo, isPro } = useSubscription(
    ({ userSubscription, hasActivePromo, isPro }) => ({
      userSubscription,
      hasActivePromo,
      isPro: isPro(),
    })
  );

  const promoMetadata = usePromoCodeStore(state => state.promoMetadata);

  const currentMedia = useMediaQuery(s => s.currentMedia);
  const isMobile = [MediaQueries.SMALL_MOBILE, MediaQueries.MOBILE].includes(
    currentMedia
  );
  const isSmallMobile = currentMedia === MediaQueries.SMALL_MOBILE;

  const routesWithBanner = [
    RegExp(`${appPaths.base}$`),
    RegExp(appPaths.explore),
    RegExp(`${appPaths.analyze}/.+`),
    RegExp(`${appPaths.article}.+`),
    RegExp(appPaths.myToggle),
  ];

  const isWithBanner = routesWithBanner.some(path => path.test(pathname));
  const isArticlePage = RegExp(`${appPaths.article}.+`).test(pathname);
  const isAlreadyPro = isPro
    ? userSubscription.upcoming_subscription?.name === SubscriptionName.Pro ||
      !userSubscription.trial
    : false;

  if (!userSubscription || isAlreadyPro || !isWithBanner || !isMobile) {
    return null;
  }

  const handleSubscribe = () => {
    gaClickSubscribeButton('notification_banner');
    navigate(`${appPaths.myAccount.subscription}/${accountRoutes.selectPlan}`);
  };

  const showPromoNotification =
    hasActivePromo && !!promoMetadata.headerPill && isSmallMobile;

  return (
    <S.Banner
      isArticlePage={isArticlePage}
      isBannerAvailable={showPromoNotification}
      data-testid="notification-banner"
    >
      {showPromoNotification && (
        <S.BaseNotification
          title={t('subscription:subscribeAndGet', {
            promoText: promoMetadata.headerPill,
          })}
          primaryBtn={{
            label: t('subscription:subscribe'),
            onClick: handleSubscribe,
          }}
        />
      )}
    </S.Banner>
  );
};
