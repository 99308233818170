import { Button, deviceConstraints } from '@toggle/design-system';
import styled, { css } from 'styled-components';

export const BtnsContainer = styled.div`
  display: flex;
  opacity: 0;
  position: absolute;
  right: 4px;
  transition: opacity 100ms ease-in-out;

  @media ${deviceConstraints.desktopSmall} {
    position: static;
  }
`;

const ellipsisCss = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-grow: 1;
  padding-right: 16px;
  ${ellipsisCss};
`;

const desktopActiveItemCss = css`
  ${InfoContainer} {
    display: flex;
  }
`;

const mobileActiveItemCss = css`
  ${BtnsContainer} {
    opacity: 1;
  }

  ${InfoContainer} {
    display: none;
  }
`;

export const ItemContainer = styled.div<{ isFocused: boolean }>`
  display: flex;
  align-items: center;
  padding-left: 12px;
  position: relative;

  ${props =>
    props.isFocused
      ? mobileActiveItemCss
      : css`
          &:hover {
           ${mobileActiveItemCss}
        `};

  @media ${deviceConstraints.desktopSmall} {
    ${props =>
      props.isFocused
        ? desktopActiveItemCss
        : css`
            &:hover {
              ${desktopActiveItemCss}
            }
          `}
  }
`;

export const LeftText = styled.span`
  font-size: 18px;
  font-weight: 500;
  flex-basis: 50%;
  padding-right: 12px;
  ${ellipsisCss}

  @media ${deviceConstraints.desktopSmall} {
    flex-basis: 30%;
  }
`;

export const RightText = styled.span`
  font-size: 16px;
`;

export const Exchange = styled(RightText)`
  color: var(--base--40);
  ${ellipsisCss}
`;

export const ContractType = styled(RightText)`
  color: var(--base--40);
`;

export const Dot = styled.span`
  width: 2px;
  height: 2px;
  border-radius: 50%;
  background-color: var(--base--40);
  margin: 0px 4px;
`;

export const BuyBtn = styled(Button)`
  height: 36px;
  width: 60px;
`;

export const SellBtn = styled(BuyBtn)`
  margin-left: 8px;
  background-color: var(--primary-color-dark);
`;
