import { AssetSubClassName } from '~/api/entities/entity-constants';
import { Entity } from '~/api/entities/entity-schema';
import { TSDatum } from '~/api/timeseries/timeseries-schema';
import { TSPoint } from '~/shared/components/chart/Chart';
import { MappedEntity } from '~/shared/hooks/use-entities/useEntities';
import {
  createLivePriceData,
  LivePriceData,
  LivePriceInfo,
} from '~/shared/hooks/use-live-price';

export const EST_TIME_ZONE = 'America/New_York';

export enum PriceStatus {
  default = 'default',
  positive = 'positive',
  negative = 'negative',
}

interface PriceChangeParams {
  lastPrice: number;
  newPrice: number;
  isCurrency?: boolean;
  isPrice?: boolean;
}

function getCommonChangeParams(
  lastPrice: number,
  newPrice: number,
  decimals: number
) {
  let change = (0).toFixed(decimals);
  let status = PriceStatus.default;
  const absoluteChange = newPrice - lastPrice;

  if (!lastPrice) {
    return { sign: '', change, status, isChanged: false };
  }

  const sign = Math.sign(absoluteChange) >= 0 ? '+' : '-';
  const isChanged = absoluteChange !== 0;

  if (isChanged) {
    change = `${sign}${Math.abs(absoluteChange).toFixed(decimals)}`;
    status = sign === '+' ? PriceStatus.positive : PriceStatus.negative;
  }

  return { sign, change, status, isChanged };
}

export function getPriceChange({
  lastPrice,
  newPrice,
  isCurrency = false,
  isPrice = true,
}: PriceChangeParams) {
  const suffix = isPrice ? '%' : 'bps';
  let proportionChange = `0.00${suffix}`;
  const { sign, status, change, isChanged } = getCommonChangeParams(
    lastPrice,
    newPrice,
    isCurrency ? 4 : 2
  );

  if (isChanged) {
    const percentageValue = isPrice
      ? (newPrice / lastPrice - 1) * 100
      : (newPrice - lastPrice) * 100;

    proportionChange = `${sign}${Math.abs(percentageValue).toFixed(
      2
    )}${suffix}`;
  }

  return {
    priceChange: {
      status,
      change,
      proportionChange,
    },
  };
}

export function getTickerForLivePrice(
  entity: Pick<Entity, 'subscribable_ticker'>
) {
  return entity.subscribable_ticker;
}

export const isPriceSnake = (entity: MappedEntity, snakeName = '') =>
  snakeName === entity.default_snake;

export const isFX = (subClass: string) =>
  subClass === AssetSubClassName.MajorCross ||
  subClass === AssetSubClassName.GenericCross;

export const getPriceData = ({
  hoveredChartPoint,
  livePriceData,
  entity,
  ts,
}: {
  hoveredChartPoint: TSPoint<number, number> | undefined;
  livePriceData: LivePriceData;
  entity: MappedEntity;
  ts: TSDatum[];
}): LivePriceInfo => {
  if (hoveredChartPoint) {
    return createLivePriceData({
      entity,
      lastKnownPrice: hoveredChartPoint.value,
      priceBefore:
        ts[hoveredChartPoint.index - 1]?.value || hoveredChartPoint.value,
      time: ts[hoveredChartPoint.index].index,
    });
  }

  if (!livePriceData[entity.ticker] && ts.length) {
    return createLivePriceData({
      entity,
      lastKnownPrice: ts[ts.length - 1].value,
      priceBefore: ts[ts.length - 2].value || ts[ts.length - 1].value,
      time: ts[ts.length - 1].index,
    });
  }

  return livePriceData[entity.ticker];
};
