import { ENDPOINT_URLS } from '~/global/toggle-api';
import {
  APIFetch,
  APIMetaResultResponse,
} from '~/shared/services/api-fetch/APIFetch';
import { HttpMethods } from '~/shared/services/api-fetch/constants';
import {
  PendingPortfolio,
  Portfolio,
} from '~/stores/use-portfolio/usePortfolio';

export const fetchPortfolios = async () => {
  return APIFetch<APIMetaResultResponse<Portfolio[]>>(ENDPOINT_URLS.PORTFOLIO);
};

export const fetchPendingPortfolios = async () => {
  return APIFetch<APIMetaResultResponse<PendingPortfolio[]>>(
    ENDPOINT_URLS.PENDING_PORTFOLIO
  );
};

export const removeSinglePortfolio = (loginId: string) => {
  return APIFetch(`${ENDPOINT_URLS.PORTFOLIO}?login_id=${loginId}`, {
    method: HttpMethods.Delete,
  });
};

export const setPortfolioAlias = async (
  portfolioId: string | number,
  name: string
) => {
  return APIFetch(`${ENDPOINT_URLS.PORTFOLIO}/${portfolioId}`, {
    method: HttpMethods.Put,
    body: JSON.stringify({
      name,
    }),
  });
};
