export enum OnboardingStates {
  INITIAL = 0,
  CURRENCY_SET = 1,
  PORTFOLIO_ADDED = 2,
  PORTFOLIO_SET = 3,
  COMPLETE = 4,
}

export enum ExploreArticleDirection {
  Bullish = 0,
  Bearish = 1,
  Locked = 2,
}

export enum ArticleDirection {
  Bullish = 0,
  Bearish = 1,
}

export enum ArticleComponentType {
  TEXT = 'text',
  PNG = 'png',
}

export enum EntityTagTypes {
  Commodity = 'commodity',
  Credit = 'credit',
  Equity = 'equity',
  Etf = 'etf',
  Future = 'future',
  Fi = 'fi',
  Fx = 'fx',
  Stock = 'stock',
}
