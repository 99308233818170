import ReactGA from 'react-ga4';

import { UserRole } from '~/api/subscription/subscription-schema';
import { ExploreArticleDirection } from '~/declarations/toggle-api-enums';
import { CARD_TYPES } from '~/views/analyze/overview-constants';

const CATEGORIES = {
  analyze: 'Analyze',
  assetOverview: 'Asset Overview',
};
export { CATEGORIES as ANALYZE_CATEGORIES };

const ACTIONS = {
  expandLegend: 'Expand_legend',
  collapseLegend: 'Collapse_legend',
  aboutCompany: 'About_company',
  chartInsightClick: 'Chart_insight_click',
  chartInsightHover: 'Chart_insight_hover',
  clickRelatedInsight: 'Click_related_insight',
  clickAnalyzeNews: 'Click_analyze_news',
  tabSelected: 'Tab_selected',
  readFullInsight: 'Read_full_insight',
  swipeInsight: 'Swipe_insight',
  expandInsight: 'Expand_insight',
  collapseInsight: 'Collapse_insight',
  expandInsightExplanation: 'Expand_insight_explanation',
  collapseInsightExplanation: 'Collapse_insight_explanation',
  emptyFundamentals: 'Empty_fundamentals',
  newsScroll: 'News_scroll',
  upgradeClick: 'Upgrade_click',
  lockedAsset: 'Locked_asset',
  clickPastInsights: 'Click_past_insights',
  clickDisplayInsights: 'Click_display_insights',
  clickAOCard: 'Click_ao_card',
  clickAOLearnCenterLink: 'Click_ao_learn_center_link',
  closeAOCardPopup: 'Close_ao_card_popup',
  clickChartCard: 'Click_chart_card',
  hoverKeyValuesQuestionmark: 'Hover_key_values_questionmark',
  maxChartInteraction: 'Max_chart_interaction',
  runScenarioInPopup: 'Run_scenario_in_popup',
  clickAOPositionsButton: 'Click_ao_positions_button',
};

export { ACTIONS as ANALYZE_ACTIONS };

export type AOCardType = 'insight' | 'gtk' | 'news';
export type AOCardPopupLocation = 'insight' | 'gtkcard' | 'news';

const directionToHuman = (d: ExploreArticleDirection) => {
  switch (d) {
    case ExploreArticleDirection.Bearish:
      return 'bearish';
    case ExploreArticleDirection.Bullish:
      return 'bullish';
    case ExploreArticleDirection.Locked:
      return 'locked';
    default:
      return 'n/a';
  }
};

export function gaAnalyzeExpandLegend(isOpen: boolean) {
  ReactGA.event({
    category: CATEGORIES.analyze,
    action: isOpen ? ACTIONS.expandLegend : ACTIONS.collapseLegend,
  });
}

export function gaAnalyzeClickAboutCompany(tag: string) {
  ReactGA.event({
    category: CATEGORIES.analyze,
    action: ACTIONS.aboutCompany,
    label: `tag:${tag}`,
  });
}

export function gaAnalyzeInsightChartIconAction(
  id: string,
  direction: ExploreArticleDirection,
  action: 'click' | 'hover',
  worked = false,
  active = false
) {
  ReactGA.event({
    category: CATEGORIES.analyze,
    action:
      action === 'click'
        ? ACTIONS.chartInsightClick
        : ACTIONS.chartInsightHover,
    label: `id:${id};direction:${directionToHuman(
      direction
    )};worked:${worked};active:${active}`,
  });
}

export function gaAnalyzeClickRelatedAsset(
  tag: string,
  isActiveInsight = false,
  hasNewsHighlight = false
) {
  ReactGA.event({
    category: CATEGORIES.analyze,
    action: ACTIONS.clickRelatedInsight,
    label: `tag:${tag};isActiveInsight:${isActiveInsight};hasNewsHighlight:${hasNewsHighlight}`,
  });
}

export function gaAnalyzeClickNewsItem(
  id: string,
  title: string,
  highlighted: boolean,
  label: string,
  direction: CARD_TYPES,
  tab: 'summary' | 'news'
) {
  ReactGA.event({
    category: CATEGORIES.analyze,
    action: ACTIONS.clickAnalyzeNews,
    label: `id:${id};title:${title};highlighted:${highlighted};label:${label};direction:${direction};tab:${tab}`,
  });
}

export function gaAnalyzeSelectTab(tab: string) {
  ReactGA.event({
    category: CATEGORIES.analyze,
    action: ACTIONS.tabSelected,
    label: `tabName:${tab}`,
  });
}

export function gaAnalyzeReadFullInsight(id: string, defaultMethod: string) {
  ReactGA.event({
    category: CATEGORIES.analyze,
    action: ACTIONS.readFullInsight,
    label: `insightId:${id};defaultMethod:${defaultMethod}`,
  });
}

export function gaAnalyzeSwipeInsight(direction: 'next' | 'prev') {
  ReactGA.event({
    category: CATEGORIES.analyze,
    action: ACTIONS.swipeInsight,
    label: `direction:${direction}`,
  });
}

export function gaAnalyzeToggleExpandInsight(expand: boolean, id: string) {
  ReactGA.event({
    category: CATEGORIES.analyze,
    action: expand ? ACTIONS.expandInsight : ACTIONS.collapseInsight,
    label: `insightId:${id}`,
  });
}

export function gaAnalyzeToggleExpandExplanation(
  expand: boolean,
  cardType: string
) {
  ReactGA.event({
    category: CATEGORIES.analyze,
    action: expand
      ? ACTIONS.expandInsightExplanation
      : ACTIONS.collapseInsightExplanation,
    label: `cardType:${cardType}`,
  });
}

export function gaAnalyzeSendEmptyFundamentals(tag: string) {
  ReactGA.event({
    category: CATEGORIES.analyze,
    action: ACTIONS.emptyFundamentals,
    label: `tag:${tag}`,
  });
}

export function gaAnalyzeScrollNews() {
  ReactGA.event({
    category: CATEGORIES.analyze,
    action: ACTIONS.newsScroll,
  });
}

export function gaAnalyzeUpgradeInsight(
  location: 'locked-insight' | 'insight-button'
) {
  ReactGA.event({
    category: CATEGORIES.analyze,
    action: ACTIONS.upgradeClick,
    label: `subscription:${UserRole.Basic};location:${location}`,
  });
}

export function gaAnalyzeSendGatedAsset(tag: string, subscription: string) {
  ReactGA.event({
    category: CATEGORIES.analyze,
    action: ACTIONS.lockedAsset,
    label: `tag:${tag};subscription:${subscription}`,
  });
}

export function gaAnalyzeClickPastInsights(isOn: boolean) {
  ReactGA.event({
    category: CATEGORIES.analyze,
    action: ACTIONS.clickPastInsights,
    label: `status:${isOn ? 'on' : 'off'}`,
  });
}

export function gaAnalyzeClickDisplayInsights() {
  ReactGA.event({
    category: CATEGORIES.analyze,
    action: ACTIONS.clickDisplayInsights,
  });
}

export function gaAnalyzeClickAOCard(
  title: string,
  isLocked: boolean,
  aoCardType: 'insight' | 'gtk' | 'news'
) {
  ReactGA.event({
    category: CATEGORIES.analyze,
    action: ACTIONS.clickAOCard,
    label: `title:${title};isLocked:${isLocked};ao_card_type:${aoCardType}`,
  });
}
export function gaAnalyzeClickAOLearnCenterLink(
  type: AOCardType,
  link: string
) {
  ReactGA.event({
    category: CATEGORIES.analyze,
    action: ACTIONS.clickAOLearnCenterLink,
    label: `type:${type};link:${link}`,
  });
}
export function gaAnalyzeCloseAOCardPopup(
  location: 'insight' | 'gtkcard' | 'news'
) {
  ReactGA.event({
    category: CATEGORIES.analyze,
    action: ACTIONS.closeAOCardPopup,
    label: `location:${location}`,
  });
}
export function gaAnalyzeClickChartCard(title: string) {
  ReactGA.event({
    category: CATEGORIES.analyze,
    action: ACTIONS.clickChartCard,
    label: `title:${title}`,
  });
}

export function gaAnalyzeHoverKeyValueTooltip(tooltipInfo: string) {
  ReactGA.event({
    category: CATEGORIES.analyze,
    action: ACTIONS.hoverKeyValuesQuestionmark,
    label: `keyValue:${tooltipInfo}`,
  });
}

export function gaMaxChartInteraction(location: 'chart' | 'analyze') {
  ReactGA.event({
    category: CATEGORIES.analyze,
    action: ACTIONS.maxChartInteraction,
    label: `interacted:true;location:${location}`,
  });
}

export function gaRunScenarioInPopup(title: string, aoCardType: string) {
  ReactGA.event({
    category: CATEGORIES.analyze,
    action: ACTIONS.runScenarioInPopup,
    label: `title:${title};ao_card_type:${aoCardType}`,
  });
}

export enum OpenPositionsButton {
  AboveChart = 'above chart',
  SidebarMenu = 'left hand side menu',
}

export function gaClickAOPositionsButton(
  openPositionsButton: OpenPositionsButton
) {
  ReactGA.event({
    category: CATEGORIES.assetOverview,
    action: ACTIONS.clickAOPositionsButton,
    label: `buttonPosition:${openPositionsButton}`,
  });
}
