import { ChartInsight } from '@toggle/chart';
import { startOfDayUTC } from '@toggle/helpers';

import {
  ArticleDirection,
  ExploreArticleDirection,
} from '~/declarations/toggle-api-enums';
import { ArticleSummary, NewsItem } from '~/declarations/toggle-api-news';

import { InsightDetails } from '../../insights.types';

export const mapArticleInsight = (a: ArticleSummary): ChartInsight => ({
  id: a.id,
  type: 'article',
  direction:
    a.direction === ExploreArticleDirection.Bullish ? 'bullish' : 'bearish',
  date: new Date(a.last_value_reference_date ?? a.created_on),
  entity: a.entity,
});

export const mapNewsInsight = (n: NewsItem): ChartInsight => ({
  id: n.id,
  type: 'news',
  direction: n.direction === ArticleDirection.Bullish ? 'bullish' : 'bearish',
  date: startOfDayUTC(new Date(n.publication_date)),
  entity: '',
});

export const mapInsightsToDetails = (
  insights: ChartInsight[],
  articleInsights: ArticleSummary[],
  newsInsights: NewsItem[]
): InsightDetails[] =>
  insights
    .map(insight => {
      let item;

      if (insight.type === 'article') {
        item = articleInsights.find(article => article.id === insight.id);
      } else if (insight.type === 'news') {
        item = newsInsights.find(news => news.id === insight.id);
      }

      return (
        item && {
          type: insight.type,
          item,
        }
      );
    })
    .filter((element): element is InsightDetails => !!element);
