/* eslint-disable max-lines-per-function */
import {
  ChartAPIReturn,
  ChartAssetData,
  PaneData,
  RangeHorizon,
  ResampleIntervals,
  SeriesType,
} from '@toggle/chart';
import { Entity, Resolution } from '@toggle/toggle';
import { useRef, useState } from 'react';
import { v4 } from 'uuid';

import { getPrices } from '~/api/price/price-service';
import { fetchSnakeByName } from '~/shared/services/overview-widget/overview-widget-service';
import { Tracking } from '~/shared/services/tracking/sentry';

import { ChartSearchType, SearchAssetPayload } from '../../types/search.types';
import {
  createChartDataFromPrimaryAsset,
  createTsDataFromSnake,
  getChartDataSlice,
  getPrimaryAssetHorizons,
  getSnakeMeta,
  handleNonPrimaryAssetChange,
  lineColors,
  updatePrimaryPane,
} from '../../utils/chart-utils/chart-utils';
import { checkIsIndicator } from '../../utils/entity-utils/entity-utils';
import {
  getAssetResample,
  getChartDataEmptyResamples,
} from '../../utils/resample-utils/resample-utils';
import { useChartErrors } from '../use-chart-errors/useChartErrors';
import { useChartPane } from '../use-chart-pane/useChartPane';
import { useChartSettings } from '../use-chart-settings/useChartSettings';
import { useResample } from '../use-resample/useResample';

export interface GetTimeSeriesProps {
  latestAsset: Entity;
  resolution: Resolution;
  changingAsset?: Entity;
  dateFrom?: string;
  dateTo?: string;
}

export interface OnChartDataReadyProps {
  assetHorizons: ReturnType<typeof getPrimaryAssetHorizons>;
  chartPanes: PaneData[];
  emptyResamples: ResampleIntervals[];
  validResample: ResampleIntervals;
}

export interface UseTurboChartProps {
  openSearch: (type: ChartSearchType, changingAsset?: Entity) => void;
}

export type UseTurboChartReturn = ReturnType<typeof useTurboChart>;

export const useTurboChart = ({ openSearch }: UseTurboChartProps) => {
  const [chartPanes, setChartPanes] = useState<PaneData[]>([]);
  const chartData = chartPanes[0]?.chartAssetData || [];
  const activeAssets = chartData.map(c => c.entity);
  const hasIndicatorInList = activeAssets.some(checkIsIndicator);

  const [horizonRanges, setHorizonRanges] = useState<RangeHorizon[]>(() =>
    Object.values(RangeHorizon)
  );
  const chartApiRef = useRef<null | ChartAPIReturn>(null);

  const { chartError } = useChartErrors({ chartData });

  const chartPaneActions = useChartPane({
    ...chartError,
    chartData,
    chartPanes,
    setChartPanes,
  });

  const onChartDataReady = ({
    assetHorizons,
    chartPanes,
    emptyResamples,
    validResample,
  }: OnChartDataReadyProps) => {
    setHorizonRanges(assetHorizons.horizons);
    resample.setEmptyResamples(emptyResamples);
    updateChartData(chartPanes, validResample);
  };

  const getTimeSeries = async ({
    latestAsset,
    dateFrom,
    dateTo,
    resolution,
  }: GetTimeSeriesProps) => {
    try {
      chartError.setHasError(false);
      if (checkIsIndicator(latestAsset)) {
        const snakeData = await fetchSnakeByName(latestAsset.default_snake);

        if (snakeData instanceof Error) {
          throw snakeData;
        }

        return createTsDataFromSnake(snakeData.result.data);
      } else {
        const prices = await getPrices({
          ticker: latestAsset.subscribable_ticker,
          entityTag: latestAsset.tag,
          resolution: resolution,
          dateFrom,
          dateTo,
        });

        return prices;
      }
    } catch (error) {
      Tracking.captureException(error as Error);
      chartError.setHasError(true);
      return undefined;
    }
  };

  const { chartSettings, updateSearchParams, changeYAxisSettings } =
    useChartSettings({
      ...chartError,
      getTimeSeries,
      onChartDataReady,
      openSearch,
      chartPanes,
      setChartPanes,
    });

  const {
    resample: selectedResample,
    seriesType: selectedSeriesType,
    priceDisplay,
    yAxisType,
  } = chartSettings;

  const {
    changeRange,
    updateChartData,
    onDomainChange,
    disable1DHorizon,
    resample,
  } = useResample({
    ...chartError,
    chartPanes,
    chartData,
    hasIndicatorInList,
    chartSettings,
    updateSearchParams,
    setChartPanes,
  });

  const onAssetChange = async (payload: SearchAssetPayload) => {
    const { asset, type } = payload;
    chartError.setHasEmpty(false);
    const isIndicator = checkIsIndicator(asset);
    const nextResample = getAssetResample({
      asset,
      type,
      isIndicator,
      selectedResample,
    });

    const [prices, snakeMeta] = await Promise.all([
      getTimeSeries({
        latestAsset: asset,
        resolution: nextResample,
      }),
      getSnakeMeta(asset.default_snake),
    ]);

    if (!prices) {
      return;
    }

    if (!prices.length) {
      chartError.setAssetWithError(asset);
      chartError.handleEmptyChart(asset.name);
      return;
    }

    const newData = await resample.fetchResampleIfNeeded(
      getChartDataSlice(chartData, payload),
      nextResample
    );

    let newChartData: ChartAssetData[],
      horizon: RangeHorizon | null | undefined,
      seriesType: SeriesType | undefined;

    if (type === ChartSearchType.Primary) {
      newChartData = createChartDataFromPrimaryAsset({
        chartData: newData,
        asset,
        ts: prices,
        snakeMeta,
      });
      const assetHorizons = getPrimaryAssetHorizons(newChartData[0].ts);
      horizon = assetHorizons.activeHorizon;
      seriesType =
        isIndicator || !selectedSeriesType ? 'line' : selectedSeriesType;
      setHorizonRanges(assetHorizons.horizons);
    } else {
      newChartData = handleNonPrimaryAssetChange(type, {
        chartData,
        newData,
        asset,
        prices,
        snakeMeta,
      });
    }

    const newChartPanes: PaneData[] = [
      {
        id: v4(),
        seriesType: selectedSeriesType,
        priceDisplay,
        yAxisType,
        chartAssetData: newChartData,
      },
      ...chartPanes.slice(1),
    ];
    resample.setEmptyResamples(getChartDataEmptyResamples(newChartData));
    updateChartData(newChartPanes, nextResample);
    updateSearchParams({
      chartPanes: newChartPanes,
      resample: nextResample,
      horizon,
      seriesType,
    });
  };

  const removeActiveAsset = (defaultSnake: string) => {
    const filteredData = chartData.filter(
      a => a.entity.default_snake !== defaultSnake
    );
    const newChartPanes = updatePrimaryPane(chartPanes, filteredData);
    updateChartData(newChartPanes);
    updateSearchParams({
      chartPanes: newChartPanes,
    });
    resample.setEmptyResamples(getChartDataEmptyResamples(filteredData));
  };

  const hideActiveAsset = async (entity: Entity, isHidden: boolean) => {
    const updatedData = chartData.map(a => {
      if (a.entity.default_snake === entity.default_snake) {
        return { ...a, isHidden };
      }
      return a;
    });
    const hidden = updatedData
      .filter(item => item.isHidden)
      .map(item => item.entity.default_snake);
    const newChartPanes = updatePrimaryPane(chartPanes, updatedData);
    updateChartData(newChartPanes);
    updateSearchParams({
      hidden: hidden.length ? hidden : null,
    });
  };

  const changeChartLineColor = (colorId: number, default_snake: string) => {
    const updatedData = chartData.map(a => {
      if (a.entity.default_snake === default_snake) {
        return { ...a, lineColorOverride: lineColors[colorId] };
      }
      return a;
    });
    const newChartPanes = updatePrimaryPane(chartPanes, updatedData);
    updateChartData(newChartPanes);
  };

  const changeSeriesType = (seriesType: SeriesType) => {
    setChartPanes([{ ...chartPanes[0], seriesType }, ...chartPanes.slice(1)]);
    updateSearchParams({
      seriesType,
    });
  };

  return {
    ...chartError,
    ...resample,
    chartPaneActions,
    chartData,
    activeAssets,
    removeActiveAsset,
    hideActiveAsset,
    changeRange,
    lineColors,
    changeChartLineColor,
    horizonRanges,
    changeSeriesType,
    chartApiRef,
    onAssetChange,
    hasIndicatorInList,
    disable1DHorizon,
    onDomainChange,
    updateSearchParams,
    chartSettings,
    changeYAxisSettings,
    chartPanes,
  };
};
