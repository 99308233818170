import { StrictUnion } from '@toggle/helpers';

import { ChatStreamChunk } from '~/views/chat/services/readable-streams/readable-streams.types';

import {
  OrchestratorChatEntity,
  OrchestratorChatMetadata,
} from '../helpers/chat.types';

export enum MessageStatus {
  Sent = 'sent',
  Received = 'received',
}

export type SupportedIntents = 'entity-report' | 'earnings' | 'scenario';

type ChatServices = 'orchestrator' | 'insight-assistant' | 'archaic-secretary';

export interface Message {
  question?: string;
  requestId: string;
  status: MessageStatus;
  isActive: boolean;
  service?: ChatServices;
  reply?: {
    success?: boolean;
    insight_id?: string;
    answer: string;
    draftEmail?: boolean;
    metaData?: Metadata;
    feedback?: string;
    isPending?: boolean;
    isError?: boolean;
    orchestratorMetadata?: OrchestratorChatMetadata;
  };
}

export interface Metadata {
  supported: boolean;
  intent: string;
  entity: OrchestratorChatEntity;
}

interface SendArchaicSecretary {
  service: 'archaic-secretary';
}

interface SendOrchestrator {
  service: 'orchestrator';
  question: string;
  entities?: string[];
  intents?: string[] | null;
  scopes?: string[];
  overrideRequestId?: string;
}

interface SendArticleInsight {
  service: 'insight-assistant';
  question: string;
  reply: Message['reply'];
}

export type SendMessageProps = StrictUnion<
  SendOrchestrator | SendArticleInsight | SendArchaicSecretary
>;

export interface ChatStore {
  sessionId: string;
  isResponsePending: boolean;
  activeMessage?: Message;
  messages: Message[];
  sendMessage(
    message: SendMessageProps,
    abortSignal: AbortSignal
  ): Promise<void>;
  updateActiveMessage(index: number): void;
  handleFeedback(id: string, feedback: string): void;
  appendChunkToMessage(requestId: string, chunk: ChatStreamChunk): void;
}
