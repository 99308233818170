import { typographyV2 } from '@toggle/design-system';
import styled from 'styled-components';

import { ProgressBar } from '~/shared/components/splash-screen/partials';

export const Container = styled.div`
  position: absolute;
  top: calc(100% - 1px);
  left: 20px;
  right: 20px;
`;

export const JpmProgressBar = styled(ProgressBar)`
  background: var(--border-default);
  height: 2px;
`;

export const Title = styled.p`
  margin: 16px 0px;
  color: var(--text-soft);
  ${typographyV2.bodyXs};
`;
