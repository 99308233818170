export enum Indicator {
  TLI = 'TLI',
  RFI = 'RFI',
  PI = 'PI',
  TI = 'TI',
}

export type Variant = 'bullish' | 'bearish';

const DEFAULT_AREA: [Variant, Variant] = ['bullish', 'bearish'];

export interface Threshold {
  up: number;
  down: number;
  mid: number;
  variants: [Variant, Variant];
}

export const indicatorsThresholds: Record<Indicator, Threshold> = {
  [Indicator.TLI]: {
    up: 0.15,
    down: -0.15,
    variants: DEFAULT_AREA,
    mid: 0,
  },
  [Indicator.RFI]: {
    up: 0.75,
    down: -0.75,
    variants: ['bearish', 'bullish'],
    mid: 0,
  },
  [Indicator.PI]: {
    up: 95,
    down: 5,
    variants: ['bearish', 'bullish'],
    mid: 50,
  },
  [Indicator.TI]: {
    up: 95,
    down: 5,
    variants: DEFAULT_AREA,
    mid: 50,
  },
};

type getDirectionProps = {
  indicator: Indicator;
  up: number;
  down: number;
  value: number;
};

export const getDirection = ({
  indicator,
  up,
  down,
  value,
}: getDirectionProps) => {
  const { variants } = indicatorsThresholds[indicator];
  const inversed = variants[1] === 'bullish';

  let direction = 'neutral';
  if (value > up) {
    direction = inversed ? 'bearish' : 'bullish';
  } else if (value < down) {
    direction = inversed ? 'bullish' : 'bearish';
  }

  return direction;
};
