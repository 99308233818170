import { formatInteger } from '@toggle/helpers';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { MediaQueries } from '~/shared/hooks/MediaQueryList';
import {
  MetricsBreadcrumbs,
  useMetricsBreadcrumbs,
} from '~/shared/hooks/use-metrics-breadcrumbs/useMetricsBreadcrumbs';
import { useHelper } from '~/stores/use-helper/useHelper';
import { exploreSectionsRefs } from '~/views/helper/helper-widget/content/explore/ExploreHelperContent';
import { EXPLORE_SECTION_IDS } from '~/views/helper/HelperConfig';

import * as S from './StatsCard.styles';

const BREADCRUMBS_KEYS_ORDER = ['snakes', 'dislocations', 'articles_produced'];

interface StatsCardProps {
  className?: string;
}

export const StatsCard: FC<StatsCardProps> = ({ className }) => {
  const { t, i18n } = useTranslation('explore');
  const breadcrumbs = useMetricsBreadcrumbs();

  const { setHelperHoverBehavior } = useHelper();

  return (
    <S.StatsCard
      className={className}
      data-testid="stats-card"
      ref={
        exploreSectionsRefs[EXPLORE_SECTION_IDS.EXPLORE_INSIGHTS_STATS_CHECK_ID]
      }
      {...setHelperHoverBehavior(
        EXPLORE_SECTION_IDS.EXPLORE_INSIGHTS_STATS_CHECK_ID
      )}
    >
      <S.StatsCardHeading>
        {t('explore:metricsBreadcrumbs.todayOnToggle')}
      </S.StatsCardHeading>
      <S.StatsCardStats>
        <S.ArrowBg>
          <S.ArrowDown />
        </S.ArrowBg>
        {breadcrumbs.data &&
          BREADCRUMBS_KEYS_ORDER.map(key => (
            <S.StatsContent key={key}>
              <S.StatsContentNumber>
                {breadcrumbs.data?.[key as keyof MetricsBreadcrumbs]
                  ? formatInteger(
                      i18n.language,
                      breadcrumbs.data[
                        key as keyof MetricsBreadcrumbs
                      ] as number
                    )
                  : t('explore:metricsBreadcrumbs.inProgress')}
              </S.StatsContentNumber>
              <S.StatsContentTitle>
                {t(`explore:metricsBreadcrumbs.${key}`, {
                  dayOfData: t('common:days.today'),
                })}
              </S.StatsContentTitle>
              <S.StatsContentSubtitle>
                {t(`explore:metricsBreadcrumbs.${key}Sub`)}
              </S.StatsContentSubtitle>
            </S.StatsContent>
          ))}
      </S.StatsCardStats>
      <S.StatsCardImage>
        <img
          src="/app/assets/explore/analyzing.png"
          srcSet="/app/assets/explore/analyzing.png 530w, /app/assets/explore/analyzing@2x.png 800w"
          sizes={`${MediaQueries.LARGE_DESKTOP} 800px, 530px`}
          alt={t('explore:metricsBreadcrumbs.industrialRobotsAlt')}
        />
      </S.StatsCardImage>
    </S.StatsCard>
  );
};
