import { isiOSDevice } from '@toggle/helpers';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { config } from '~/config';
import { NO_IOS_BANNER_PATHS } from '~/routes/app-paths';

import * as S from './MobileAppBanner.styles';

interface MobileAppBannerProps {
  path: string;
}

const { appStoreLink } = config;

export const MobileAppBanner: FC<MobileAppBannerProps> = ({ path }) => {
  const { t } = useTranslation('common');
  const [showBanner, setShowBanner] = useState(true);

  const download = () => window.open(appStoreLink, '_blank');

  if (!isiOSDevice() || NO_IOS_BANNER_PATHS.includes(path) || !showBanner) {
    return null;
  }

  return (
    <S.Container data-testid="mobile-app-banner" className="mobile-app-banner">
      <S.CloseIcon
        onClick={() => setShowBanner(false)}
        data-testid="close-icon"
      />
      <S.AppIcon
        src="/app/assets/core/mobile-app-copilot-icon.png"
        alt={t('common:mobileAppBanner.altText')}
      />
      <S.TextContainer>
        <strong>{t('common:mobileAppBanner.title')}</strong> <br />
        {t('common:mobileAppBanner.text')}
      </S.TextContainer>
      <S.AppStoreButton
        src="/app/assets/core/download-on-app-store.png"
        data-testid="download"
        onClick={download}
        alt={t('common:mobileAppBanner.appStoreAltText')}
      />
    </S.Container>
  );
};
