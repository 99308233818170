import { isToday } from 'date-fns';
import { TFunction } from 'i18next';
import React, { ReactNode } from 'react';

import { Entity } from '~/api/entities/entity-schema';
import { OptionalN } from '~/declarations/standard';
import { ArticleDirection } from '~/declarations/toggle-api-enums';
import { NewsItem } from '~/declarations/toggle-api-news';
import { GTKConditionResponse } from '~/views/analyze/experiment/components/condition-builder/utils/conditionHelpers';
import {
  ArticleInsightCard,
  InsightEventType,
} from '~/views/analyze/hooks/use-article-insight-cards/useInsightCards';
import { GtKItem } from '~/views/analyze/hooks/use-gtk/useGtK';
import { getInsightCardConfig } from '~/views/analyze/InsightCardsConfig';
import { CARD_TYPES } from '~/views/analyze/overview-constants';

import { ConnectedArticle } from '../../rocketship-insight-card/RocketshipInsightCard';

export const imgNode = (src: string, t: TFunction, locked = false) => (
  <img
    src={locked ? '/app/assets/overview-widget/insight-lock.svg' : src}
    alt={t('analyze:analysis.modal.alt')}
  />
);

export interface InsightCardData {
  name: string;
  description?: string;
  type?: string;
  insightType: InsightEventType;
  imgNode: ReactNode;
  insightTypeText: string;
  star?: number;
  isNew?: boolean;
  locked?: boolean;
  insightId?: string;
  bookmarked?: boolean;
  direction: OptionalN<ArticleDirection>;
  connectedArticle?: ConnectedArticle;
  compassId?: string;
  articleId?: string;
  lastValue?: number;
  iconUrl?: string;
  summary?: string;
  url?: string;
  testId?: string;
  className?: string;
  createdAt?: string;
}

export interface GtkCardData {
  name: string;
  description: string;
  type?: string;
  insightType: InsightEventType;
  imgNode: ReactNode;
  insightTypeText: string;
  direction: OptionalN<ArticleDirection>;
  iconUrl?: string;
  isNew?: boolean;
  lastValue?: number;
  expression: string;
  scenarioCondition?: GTKConditionResponse[];
  lastDate?: string;
}

export interface NewsCardData {
  name: string;
  description?: string;
  insightType: InsightEventType;
  imgNode: ReactNode;
  insightTypeText: string;
  isNew?: boolean;
  locked?: boolean;
  direction: OptionalN<ArticleDirection>;
  summary?: string;
  url?: string;
  createdAt?: string;
}

export interface MappedInsightArticle extends InsightCardData {
  stars: number;
  driverEntity: Entity;
}

export interface JointInsightGtkData {
  // gtk
  expression?: string;
  lastDate?: string;
  scenarioCondition?: GTKConditionResponse[];
  iconUrl?: string;
  // insight
  articleId?: OptionalN<string>;
  compassId?: string;
  createdAt?: string;
  star?: number;
  type?: string;
  locked?: boolean;
  // both
  description?: string;
  name: string;
  insightType: InsightEventType;
  insightTypeText: string;
  imgNode: ReactNode;
  direction: OptionalN<ArticleDirection>;
  isNew?: boolean;
}

export const jointInsightGtkMapping = (
  insight: ArticleInsightCard[],
  gtk: GtKItem[],
  t: TFunction
): JointInsightGtkData[] => {
  const mappedGtk: JointInsightGtkData[] = gtk.map(g => ({
    expression: g.expression,
    lastDate: g.snake_last_date,
    scenarioCondition: g.scenario_condition,
    iconUrl: g.group_icon_url,
    description: g.explanation,
    name: g.name,
    insightType: g.group_code,
    insightTypeText: t('analyze:rocketTab.insightCard', {
      context: g.group_code,
    }),
    imgNode: imgNode(g.image_url, t),
    direction: null,
    isNew: true,
  }));

  const mappedInsight: JointInsightGtkData[] = insight.map(i => {
    const locked = i.direction === null;
    return {
      articleId: i.article_id ?? null,
      compassId: i.compass_id,
      createdAt: i.created_at,
      type: i.card_type,
      star: locked ? undefined : i.stars,
      locked,
      description: getInsightCardConfig(
        i.card_type,
        i.direction === ArticleDirection.Bullish
          ? CARD_TYPES.BULLISH
          : CARD_TYPES.BEARISH,
        t
      )?.intuition,
      name: locked ? t('analyze:insights.subscribe') : i.card_text,
      insightType: i.event_type,
      insightTypeText: i.event_text,
      imgNode: imgNode(i.card_icon, t, locked),
      direction: i.direction,
      isNew: !locked && i.is_new,
    };
  });

  return [...mappedInsight, ...mappedGtk];
};

export const insightMapping = (
  insight: ArticleInsightCard,
  t: TFunction
): MappedInsightArticle => {
  const locked = insight.direction === null;
  const description = getInsightCardConfig(
    insight.card_type,
    insight.direction === ArticleDirection.Bullish
      ? CARD_TYPES.BULLISH
      : CARD_TYPES.BEARISH,
    t
  )?.intuition;
  return {
    type: insight.card_type,
    description,
    direction: insight.direction,
    imgNode: imgNode(insight.card_icon, t, locked),
    insightType: insight.event_type,
    insightTypeText: insight.event_text,
    name: locked ? t('analyze:insights.subscribe') : insight.card_text,
    star: locked ? undefined : insight.stars,
    stars: insight.stars,
    isNew: !locked && insight.is_new,
    locked,
    bookmarked: insight.bookmarked,
    compassId: insight.compass_id,
    articleId: insight.article_id ?? undefined,
    driverEntity: insight.driver_entity,
    createdAt: insight.created_at,
  };
};

export const gtkMapping = (gtk: GtKItem, t: TFunction): GtkCardData => ({
  direction: null,
  imgNode: imgNode(gtk.image_url, t),
  insightType: gtk.group_code,
  insightTypeText: t('analyze:rocketTab.insightCard', {
    context: gtk.group_code,
  }),
  iconUrl: gtk.group_icon_url,
  name: gtk.name,
  isNew: true,
  lastValue: gtk.last_value,
  expression: gtk.expression,
  ...(gtk.connected_article_url &&
    gtk.connected_article_title && {
      connectedArticle: {
        title: gtk.connected_article_title,
        url: gtk.connected_article_url,
      },
    }),
  scenarioCondition: gtk.scenario_condition,
  lastDate: gtk.snake_last_date,
  description: gtk.explanation,
});

export const aiNewsMapping = (
  newsItem: NewsItem,
  t: TFunction
): NewsCardData => {
  const locked = newsItem.direction === null;

  return {
    direction: newsItem.direction,
    description: t('analyze:analysis.modal.toggleNewsDescription'),
    imgNode: imgNode(newsItem.group, t, locked),
    insightType: 'group_news',
    insightTypeText: t('analyze:rocketTab.insightCard', {
      context: 'group_news',
    }),
    name: locked ? t('analyze:insights.subscribe') : newsItem.title,
    locked,
    isNew: isToday(new Date(newsItem.publication_date)),
    summary: newsItem.summary,
    url: newsItem.url,
    createdAt: newsItem.publication_date,
  };
};
