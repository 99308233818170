import { formatPriceWithCurrency } from '@toggle/helpers';
import find from 'lodash/find';
import isUndefined from 'lodash/isUndefined';
import { useTranslation } from 'react-i18next';

import {
  BillingCycle,
  Price,
  SubscriptionName,
} from '~/api/subscription/subscription-schema';

interface GetPricesProp {
  billingCycle?: BillingCycle;
  title?: string;
  prices: Price[];
}

interface GetPricesReturn {
  priceYearly: string;
  priceMonthly: string;
  showPriceYearly: boolean;
  isBasic: boolean;
  priceId: string;
}
type GetPrices = (props: GetPricesProp) => GetPricesReturn;

export const getPriceByBillingCycle = (
  prices?: Price[],
  billingCycle?: BillingCycle
) => {
  if (!prices) {
    return undefined;
  }

  if (prices.length === 1) {
    const [firstPrice] = prices;
    return firstPrice;
  }

  return find<Price>(prices, {
    billing_cycle: billingCycle,
  });
};

export const usePrices: GetPrices = ({ billingCycle, title, prices }) => {
  const { t } = useTranslation('subscription');
  const isBasic = title === SubscriptionName.Basic;
  const showPriceYearly = billingCycle === BillingCycle.year;

  const getYearlyPerMonth = (value?: number) => value && value / 12;

  const renderPrice = (value?: number, currency?: string) => {
    if (isUndefined(value) || !currency) {
      return '';
    }

    return formatPriceWithCurrency(value, currency);
  };

  const {
    price,
    currency,
    id = '',
  } = getPriceByBillingCycle(prices, billingCycle) ?? {};
  let priceYearly = '';
  let priceMonthly = '';

  if (isBasic) {
    priceMonthly = renderPrice(price, currency);
  } else {
    if (showPriceYearly) {
      priceMonthly = t('subscription:plan.perMonth', {
        price: renderPrice(getYearlyPerMonth(price), currency),
      });
      priceYearly = t('subscription:plan.perYear', {
        price: renderPrice(price, currency),
      });
    } else {
      priceMonthly = t('subscription:plan.perMonth', {
        price: renderPrice(price, currency),
      });
    }
  }

  return {
    priceYearly,
    priceMonthly,
    showPriceYearly,
    isBasic,
    priceId: id,
  };
};
