import { deviceConstraints, Icon, typography } from '@toggle/design-system';
import styled, { css } from 'styled-components';

import { BookmarkIcon } from '~/views/analyze/asset-overview/components/sidebar/components/bookmark-icon/BookmarkIcon';
import { CARD_TYPES } from '~/views/analyze/overview-constants';

const defaultRightEdgeOffset = '32px';
const desktopSmallRightEdgeOffset = '37px';
const transitionDuration = '200ms';

const cardHeaderColors = {
  [CARD_TYPES.BEARISH]: {
    title: 'var(--viz-bearish)',
    chevron: 'var(--viz-bearish)',
  },
  [CARD_TYPES.BULLISH]: {
    title: 'var(--viz-bullish)',
    chevron: 'var(--viz-bullish)',
  },
  [CARD_TYPES.LOCKED]: {
    title: 'var(--copilot--hover)',
    chevron: 'var(--copilot--default)',
  },
  [CARD_TYPES.NEWS]: { title: 'var(--lime--3)', chevron: 'var(--lime--4)' },
};

export const InsightCardHeader = styled.div`
  position: relative;
  flex-grow: 1;
`;

export const InsightTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  height: 40px;
  padding-left: 16px;
  border-top-left-radius: 4px;
  border-bottom-right-radius: 33px;
  z-index: 2;
  ${typography.emu};

  @media ${deviceConstraints.desktopSmall} {
    padding-left: 12px;
    height: 60px;
    border-bottom-right-radius: 40px;
  }
  @media ${deviceConstraints.desktop} {
    ${typography.dingo};
    height: 48px;
    border-bottom-right-radius: 33px;
  }
`;

export const InsightCardTitle = styled.p`
  color: var(--base--100);
`;

export const ChevronWithTextWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  position: absolute;
  cursor: pointer;
  right: calc(${defaultRightEdgeOffset} / 2);
`;

export const ChevronDown = styled(Icon)`
  transition: all ${transitionDuration} cubic-bezier(0.165, 0.84, 0.44, 1);
  vertical-align: middle;
  margin-top: 1px;
`;

export const HiddenText = styled.p`
  position: absolute;
  display: inline-flex;
  text-transform: uppercase;
  color: var(--base--100);
  opacity: 0;
  right: 0;
  transition: all ${transitionDuration} ease-in-out;
  ${typography.emu};
`;

export const ChevronWrapper = styled.div<{
  showInfo?: boolean;
  withHover?: boolean;
}>`
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  width: calc(100% + ${defaultRightEdgeOffset});
  height: 100%;
  position: absolute;
  border-bottom-right-radius: 48px;
  top: 0;
  transition: all ${transitionDuration} cubic-bezier(0.165, 0.84, 0.44, 1);
  z-index: 1;

  @media ${deviceConstraints.tablet} {
    width: calc(100% + ${defaultRightEdgeOffset});
    border-bottom-right-radius: 64px;
  }

  @media ${deviceConstraints.desktopSmall} {
    width: calc(100% + ${desktopSmallRightEdgeOffset});
  }

  @media ${deviceConstraints.desktop} {
    width: calc(100% + ${defaultRightEdgeOffset});
    border-bottom-right-radius: 48px;
  }

  ${({ showInfo }) =>
    showInfo &&
    css`
      // '&' - for overwriting stiles in media
      & {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 0;

        width: calc(100% + ${defaultRightEdgeOffset} + 50px);

        ${ChevronWithTextWrapper} {
          flex-direction: row;
          align-items: flex-start;
          right: 10px;
        }

        ${ChevronDown} {
          margin-right: 4px;
          transform: rotate(180deg);
        }

        ${HiddenText} {
          position: static;
          opacity: 1;
        }
      }
    `};

  @media ${deviceConstraints.tablet} {
    ${({ showInfo }) =>
      showInfo &&
      css`
        width: calc(100% + ${defaultRightEdgeOffset} + 42px);

        ${ChevronWithTextWrapper} {
          right: 8px;
        }
      `}
  }

  @media ${deviceConstraints.desktopSmall} {
    ${({ showInfo, withHover }) =>
      (showInfo || withHover) &&
      css`
        ${showInfo ? '&, ' : ''}${withHover ? '&:hover ' : ''} {
          width: calc(100% + ${desktopSmallRightEdgeOffset} + 28px);

          ${ChevronWithTextWrapper} {
            flex-direction: column;
            align-items: center;
          }

          ${ChevronDown} {
            margin-right: 0;
          }
        }
      `}

    ${({ showInfo, withHover }) =>
      !showInfo &&
      withHover &&
      css`
        &:hover {
          border-top-right-radius: 4px;
          border-bottom-right-radius: 0;

          ${HiddenText} {
            position: static;
            opacity: 1;
          }
        }
      `}
  }

  @media ${deviceConstraints.desktop} {
    ${({ showInfo, withHover }) =>
      (showInfo || withHover) &&
      css`
        ${showInfo ? '&,' : ''}${withHover ? '&:hover' : ''} {
          width: calc(100% + ${defaultRightEdgeOffset} + 40px);

          ${ChevronWithTextWrapper} {
            flex-direction: row;
            right: 4px;
          }

          ${ChevronDown} {
            margin-right: 4px;
          }
        }
      `}
  }
`;

export const InsightCardHeaderWrapper = styled.div<{ iconType: CARD_TYPES }>`
  display: flex;

  ${InsightTitleWrapper} {
    background-color: ${({ iconType }) => cardHeaderColors[iconType].title};
  }

  ${ChevronWrapper} {
    background-color: ${({ iconType }) => cardHeaderColors[iconType].chevron};
  }
`;

export const BookmarkContainer = styled.div`
  margin: 0 9px 0 calc(21px + ${defaultRightEdgeOffset});

  @media ${deviceConstraints.tablet} {
    margin: 0 12px 0 calc(10px + ${defaultRightEdgeOffset});
  }

  @media ${deviceConstraints.desktopSmall} {
    margin: 0 8px 0 ${desktopSmallRightEdgeOffset};
  }

  @media ${deviceConstraints.desktop} {
    margin: 0 11px 0 calc(9px + ${defaultRightEdgeOffset});
  }
`;

export const StyledBookmarkIcon = styled(BookmarkIcon)`
  z-index: auto;

  .activeCardBookmarkBtn {
    &::after {
      left: -10px;
      top: -2px;
      width: 40px;
      height: 40px;

      @media ${deviceConstraints.desktopSmall} {
        top: 10px;
      }

      @media ${deviceConstraints.desktop} {
        top: 0;
      }
    }
  }
`;
