import { createGlobalStyle } from 'styled-components';

export const fontFamily = {
  nort: "'nort', sans-serif, Helvetica, Arial, Verdana, Tahoma",
  robotoMono: "'Roboto Mono', monospace, Helvetica, Arial, Verdana, Tahoma",
};

export const Fonts = {
  '--font-nort': fontFamily.nort,
  '--font-roboto-mono': fontFamily.robotoMono,
};

export const GlobalFonts = createGlobalStyle`
  /* nort */
  @import url('https://use.typekit.net/gep3ucs.css');
  /* roboto mono */
  @import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@400;500&display=swap');

  :root {
    ${Fonts}
  }


  body {
    font-family: var(--font-nort);
    color: var(--text-default);
  }
`;
