import { STORAGE_ITEMS } from '~/shared/hooks/use-remote-storage/storage-keys';
import {
  FeatureFlags,
  Flags,
} from '~/stores/use-feature-flags/feature-flags.types';

export const mapItemsToFeatureFlags = (
  items: STORAGE_ITEMS
): Flags | undefined => {
  if (typeof items.feature_flags !== 'object') {
    return undefined;
  }

  // @ts-ignore
  const enableChat = items.feature_flags.enable_chat === 'true';
  const enableChartCommand =
    // @ts-ignore
    items.feature_flags.enable_chart_commander === 'true';

  return {
    ...(enableChat && { [FeatureFlags.ENABLE_CHAT_REALZ]: true }),
    ...(enableChartCommand && { [FeatureFlags.ENABLE_CHART_COMMAND]: true }),
  };
};
