import styled from 'styled-components';

import { typographyV2 } from '~/design-tokens/typography/Typography.styles';

export const ViewAllButton = styled.text`
  ${typographyV2.bodySm};
  fill: var(--text-default);
  margin: 20px;
`;

export const ViewAllButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const BorderPath = styled.path`
  stroke: var(--border-soft);
`;

export const BackgroundPath = styled.path`
  fill: var(--background-default);
`;
