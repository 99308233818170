import classNames from 'classnames';
import React, { FC, MouseEvent, useState } from 'react';

import { TooltipContainer } from '~/shared/components/tooltip-container/TooltipContainer';

import * as S from './BookmarkIcon.styles';

export interface BookmarkIconProps {
  tooltipContent?: string | JSX.Element;
  isBookmarked?: boolean;
  isLocked?: boolean;
  withTooltipAnimation?: boolean;
  isTooltipOpen?: boolean;
  bookmarkSize?: number;
  onBookmark?: () => void;
  className?: string;
  activeClassName?: string;
}

const TOOLTIP_TIMEOUT = 2000;

export const BookmarkIcon: FC<BookmarkIconProps> = ({
  tooltipContent,
  onBookmark,
  isLocked,
  isBookmarked,
  isTooltipOpen,
  withTooltipAnimation = true,
  bookmarkSize = 13,
  className,
  activeClassName,
  ...tooltipProps
}) => {
  const [isOpen, setIsOpen] = useState(isTooltipOpen);
  const [isAnimating, setIsAnimating] = useState(false);

  const handleBookmark = (e: MouseEvent) => {
    e.stopPropagation();

    if (withTooltipAnimation && !isBookmarked) {
      setIsOpen(true);
      setIsAnimating(true);
      const timer = setTimeout(() => {
        setIsOpen(false);
        setIsAnimating(false);
        clearTimeout(timer);
      }, TOOLTIP_TIMEOUT);
    }

    onBookmark?.();
  };

  return (
    <S.TooltipWrapper className={className}>
      <TooltipContainer
        isOpen={isOpen}
        disabled
        label={tooltipContent}
        placement="top-start"
        {...tooltipProps}
        strategy="fixed"
      >
        <S.BookmarkWrapper
          isAnimating={isAnimating}
          className={classNames(isAnimating && activeClassName)}
        >
          <S.StyledIcon
            data-testid="bookmark-button"
            role="button"
            iconName={'BookmarkFill'}
            size={bookmarkSize}
            onClick={handleBookmark}
            $isBookmarked={isBookmarked}
            $isLocked={isLocked}
          />
        </S.BookmarkWrapper>
      </TooltipContainer>
    </S.TooltipWrapper>
  );
};
