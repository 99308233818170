import { AssetClassCode, AssetClassMap } from '~/api/entities/entity-constants';
import { POJSObject } from '~/declarations/standard';
import { EntityAnalyticsReportType } from '~/shared/services/overview-widget/overview-widget-service';

export enum SPLASH_SCREEN_LABELS_KEYS {
  fundamentals = 'fundamentals',
  technicals = 'technicals',
  macro = 'macro',
  pastEpisodes = 'pastEpisodes',
  analyzingData = 'analyzingData',
  evaluatingScenario = 'evaluatingScenario',
  understandingQuery = 'understandingQuery',
  searchingDocument = 'searchingDocument',
  summarizing = 'summarizing',
  understanding = 'understanding',
  analyzing = 'analyzing',
}

export interface SplashScreenData {
  label: string;
  values?: EntityAnalyticsReportType[];
  durationMs?: number;
}

const DEFAULT_FUNDAMENTALS_KEYS = [EntityAnalyticsReportType.VALUATION];
const EQUITY_FUNDAMENTALS_KEYS = [
  ...DEFAULT_FUNDAMENTALS_KEYS,
  EntityAnalyticsReportType.EQUITY_YIELD,
  EntityAnalyticsReportType.EQUITY_GROWTH_GAUGE,
  EntityAnalyticsReportType.EQUITY_CONSENSUS_TREND,
];

const DEFAULT_TECHNICALS_KEYS = [
  EntityAnalyticsReportType.ENTRY_POINT,
  EntityAnalyticsReportType.POSITIONING,
  EntityAnalyticsReportType.MOMENTUM,
  EntityAnalyticsReportType.SEASONALITY,
  EntityAnalyticsReportType.SENTIMENT,
  EntityAnalyticsReportType.MAIN_MARKET_PRICE,
  EntityAnalyticsReportType.MAIN_YIELD,
  EntityAnalyticsReportType.TECHNICAL_ANALYSIS,
  EntityAnalyticsReportType.VOLATILITY,
];

const DEFAULT_MACRO_KEYS = [
  EntityAnalyticsReportType.ECONOMIC_GROWTH_GAUGE,
  EntityAnalyticsReportType.ECONOMIC_INFLATION_GAUGE,
  EntityAnalyticsReportType.FINANCIAL_CONDITIONS,
  EntityAnalyticsReportType.GEOPOLITICAL,
];

export const DEFAULT_SPLASH_SCREEN_DATA = [
  {
    label: SPLASH_SCREEN_LABELS_KEYS.fundamentals,
    values: DEFAULT_FUNDAMENTALS_KEYS,
  },
  {
    label: SPLASH_SCREEN_LABELS_KEYS.technicals,
    values: DEFAULT_TECHNICALS_KEYS,
  },
  {
    label: SPLASH_SCREEN_LABELS_KEYS.macro,
    values: DEFAULT_MACRO_KEYS,
  },
];

export const EQUITY_SPLASH_SCREEN_DATA = [
  {
    label: SPLASH_SCREEN_LABELS_KEYS.fundamentals,
    values: EQUITY_FUNDAMENTALS_KEYS,
  },
  ...DEFAULT_SPLASH_SCREEN_DATA.slice(1),
];

const SPLASH_SCREEN_MAP: POJSObject<SplashScreenData[]> = {
  [AssetClassMap[AssetClassCode.Equity]]: EQUITY_SPLASH_SCREEN_DATA,
};

export function getSplashScreenData(assetClass: string) {
  return SPLASH_SCREEN_MAP[assetClass] || DEFAULT_SPLASH_SCREEN_DATA;
}
