import { escapeBackslashQuery } from '@toggle/helpers/src/utils/strings/strings';

import { EntitySearchType } from '~/api/entities/entity-constants';
import {
  Entity,
  entityArrSchema,
  FetchEntityRequestBody,
  UseTagSearchParams,
} from '~/api/entities/entity-schema';
import { config } from '~/config';
import { HttpMethods } from '~/shared/services/api-fetch/constants';
import { validateApiFetch } from '~/shared/services/api-fetch/validate-api-fetch/validateApiFetch';

const {
  api: { root: apiRoot },
} = config;

export const entityEndpoints = {
  entity: `${apiRoot}entity/v2/filtered`,
  popularEntities: `${apiRoot}entity/v2/search/popular`,
  searchEntities: `${apiRoot}entity/v3/search/entities`,
  searchCurrencies: `${apiRoot}entity/v3/search/currencies`,
  searchEconomies: `${apiRoot}entity/v3/search/economies`,
  searchEntityAndEconomies: `${apiRoot}entity/v3/search/entities-economy-methods`,
};

const SearchQueryEndpoints = {
  [EntitySearchType.Entity]: entityEndpoints.searchEntities,
  [EntitySearchType.Currency]: entityEndpoints.searchCurrencies,
  [EntitySearchType.Economies]: entityEndpoints.searchEconomies,
  [EntitySearchType.EntityAndEconomies]:
    entityEndpoints.searchEntityAndEconomies,
};

export function postEntitiesByQuery(
  query: string,
  searchType: EntitySearchType,
  params: UseTagSearchParams = {}
) {
  const { limit, ...body } = params;
  const endPoint = SearchQueryEndpoints[searchType];
  const limits = limit ? `?limit=${limit}` : '';

  return validateApiFetch<Entity[]>({
    endpoint: `${endPoint}${limits}`,
    schema: entityArrSchema,
    requestInfo: {
      method: HttpMethods.Post,
      body: query
        ? JSON.stringify({ q: escapeBackslashQuery(query), ...body })
        : null,
    },
  });
}

export const postEntities = (
  entities: FetchEntityRequestBody,
  signal?: AbortSignal
) => {
  return validateApiFetch<Entity[]>({
    endpoint: entityEndpoints.entity,
    schema: entityArrSchema,
    requestInfo: {
      method: HttpMethods.Post,
      body: JSON.stringify(entities),
      signal,
    },
  });
};

export const getPopularEntities = () => {
  return validateApiFetch<Entity[]>({
    endpoint: entityEndpoints.popularEntities,
    schema: entityArrSchema,
  });
};
