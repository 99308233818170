import { ChartAssetData, PriceDisplay, Y_AXIS_MIN_WIDTH } from '@toggle/chart';
import { TFunction } from 'i18next';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { UseTurboChartReturn } from '../../hooks/use-turbo-chart/useTurboChart';
import { checkIsIndicator } from '../../utils/entity-utils/entity-utils';
import { AxisUnit } from '../axis-unit/AxisUnit';
import { GutterSize } from '../chart-area-overlay/ChartAreaOverlay';

const getTooltipLabel = (t: TFunction, currencies: string[]) => {
  if (currencies.length === 2) {
    return t('chart:mixedUnitsTooltip', {
      firstCurrency: currencies[0],
      secondCurrency: currencies[1],
    });
  } else if (currencies.length > 2) {
    return currencies.join(', ');
  }

  return '';
};

interface CurrencyList {
  label: string;
  width: number;
  xStart?: number;
}

const getCurrencies = (
  chartData: ChartAssetData[],
  priceDisplay: PriceDisplay,
  gutterSize: GutterSize,
  isAxisSplit: boolean
): CurrencyList[] => {
  if (priceDisplay === 'percentage') {
    return [{ label: '%', width: Y_AXIS_MIN_WIDTH, xStart: undefined }];
  }

  const multiAxisSpacing = gutterSize.multiAxisSpacing;
  const preFilteredChartData = chartData.filter(chartAsset => {
    const isNoCurrencyIndicator =
      checkIsIndicator(chartAsset.entity) && !chartAsset.snakeMeta?.currency;
    return !isNoCurrencyIndicator && !chartAsset.isHidden;
  });

  if (multiAxisSpacing && isAxisSplit) {
    return preFilteredChartData
      .filter(chartAsset =>
        multiAxisSpacing.some(s => s.snake === chartAsset.entity.default_snake)
      )
      .map((chartAsset, i) => {
        const matchedAsset = multiAxisSpacing[i];

        return {
          label: chartAsset.entity.currency.toUpperCase(),
          xStart: matchedAsset ? matchedAsset.xStart - matchedAsset.width : 0,
          width: matchedAsset ? matchedAsset.width : Y_AXIS_MIN_WIDTH,
        };
      });
  } else {
    return preFilteredChartData.map(chartAsset => ({
      label: chartAsset.entity.currency.toUpperCase(),
      xStart: 0,
      width: Y_AXIS_MIN_WIDTH,
    }));
  }
};

export interface AxisCurrencyProps {
  chartData: ChartAssetData[];
  priceDisplay: PriceDisplay;
  gutterSize: GutterSize;
  chartSettings: UseTurboChartReturn['chartSettings'];
}

export const AxisCurrency = ({
  chartData,
  priceDisplay,
  gutterSize,
  chartSettings,
}: AxisCurrencyProps) => {
  const { t } = useTranslation('chart');
  const isAxisSplit = chartSettings.yAxisType === 'split';

  const currencies = getCurrencies(
    chartData,
    priceDisplay,
    gutterSize,
    isAxisSplit
  );

  if (!currencies.length) {
    return null;
  }

  const uniqueCurrencies = new Set(currencies.map(c => c.label));
  const isMixed = uniqueCurrencies.size > 1 && !isAxisSplit;
  const label = isMixed ? t('chart:mixedUnits') : currencies[0].label;
  const tooltipLabel = getTooltipLabel(t, Array.from(uniqueCurrencies));

  return (
    <div data-testid="axis-currency">
      {isAxisSplit ? (
        currencies.map((c, i) => (
          <AxisUnit
            key={i}
            label={c.label}
            tooltipLabel={tooltipLabel}
            isMixed={isMixed}
            offset={c.xStart}
            gutterWidth={c.width}
          />
        ))
      ) : (
        <AxisUnit
          label={label}
          tooltipLabel={tooltipLabel}
          isMixed={isMixed}
          gutterWidth={gutterSize.y}
        />
      )}
    </div>
  );
};
