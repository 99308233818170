import { deviceConstraints, Modal, typography } from '@toggle/design-system';
import styled from 'styled-components';

export const StyledModal = styled(Modal)`
  ${Modal.Styled.ModalContainer} {
    display: flex;
    flex-direction: column;
    min-width: 320px;
    max-width: 400px;

    @media ${deviceConstraints.tablet} {
      min-width: 400px;
    }
  }
`;

export const ModalContent = styled.div`
  ${typography.emu};
`;
