import { customScrollMinimal, typographyV2 } from '@toggle/design-system';
import styled from 'styled-components';

import { Markdown } from '~/shared/components/markdown/Markdown';

export const MessageMarkdown = styled(Markdown)`
  ${typographyV2.bodyBase};
  margin-bottom: 16px;
  overflow: auto;
`;

export const MarkdownH1 = styled.h1`
  ${typographyV2.title3XL}
`;

export const MarkdownH2 = styled.h2`
  ${typographyV2.title2XL}
`;

export const MarkdownH3 = styled.h3`
  ${typographyV2.titleXL}
`;

export const MarkdownH4 = styled.h4`
  ${typographyV2.titleLg}
`;

export const MarkdownH5 = styled.h5`
  ${typographyV2.titleMd}
`;

export const MarkdownP = styled.p`
  ${typographyV2.bodyBase}
`;

export const MarkdownA = styled.a`
  ${typographyV2.bodyBase}
  color: var(--text-primary);
`;

export const MarkdownUL = styled.ul`
  && {
    list-style-type: disc;
    margin: 1em 0px;
    padding: 0px 40px;
  }
`;

export const MarkdownTableContainer = styled.div`
  overflow: scroll;
  margin: 1em 0px;
  ${customScrollMinimal}
`;

export const MarkdownTable = styled.table`
  border-spacing: 1px;
  width: 100%;

  tr {
    background-color: var(--background-default);
  }

  td {
    border-bottom: 1px solid var(--border-soft);
  }

  tbody tr:hover {
    background-color: var(--surface-hover);
  }
`;
