import { typographyV2 } from '@toggle/design-system';
import styled from 'styled-components';

export const ListRoot = styled.div`
  padding: 12px 0;
`;

export const ListTitle = styled.span`
  ${typographyV2.bodySm};
  color: var(--text-soft);
  padding: 0 16px;
  margin-bottom: 8px;
`;

export const List = styled.div`
  padding: 0 8px;
`;
