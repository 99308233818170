import { Loader } from '@toggle/design-system';
import { queryString } from '@toggle/helpers/src/utils/strings/strings';
import classNames from 'classnames';
import React, { Suspense, useMemo } from 'react';
import {
  Navigate,
  Route,
  Routes as RouterRoutes,
  useLocation,
} from 'react-router-dom';

import { AppHeader } from '~/global/app-header/AppHeader';
import { AppHistory } from '~/global/app-history/AppHistory';
import { AppSearch } from '~/global/app-search/AppSearch';
import { AppMetadata } from '~/shared/components/app-meta-data/AppMetadata';
import { ErrorBoundary } from '~/shared/components/error-boundary/ErrorBoundary';
import { NotificationBanner } from '~/shared/components/notification-banner/NotificationBanner';
import {
  FeatureFlagsKeys,
  Flags,
} from '~/stores/use-feature-flags/feature-flags.types';
import { useFeatureFlags } from '~/stores/use-feature-flags/useFeatureFlags';
import { useUser } from '~/stores/use-user/useUser';
import { AnalyzeViews } from '~/views/analyze/AnalyzeViews';

import { AuthenticatedView } from '../global/authenticated-view';
import { MaintenanceBanner } from '../global/maintenance-banner/MaintenanceBanner';
import { NotificationAlerts } from '../global/notification-alerts/NotificationAlerts';
import { MobileAppBanner } from '../shared/components/mobile-app-banner';
import {
  accountRoutes,
  appPaths,
  authPaths,
  NO_HEADER_PARAMS,
  queryKeys,
} from './app-paths';
import { authRoutes, commonRoutes, featureRoutes, routes } from './AppRoutes';
import { AppRoute, FeatureRoutes } from './AppRoutes.d';
import { Redirect } from './Redirect';

export const filterFeatureRoutes = (
  featureRoutes: FeatureRoutes,
  features?: Flags
) => {
  if (!features) {
    return [];
  }

  return Object.entries(features).reduce<AppRoute[]>(
    (routes, [featureKey, value]) => {
      const route = featureRoutes[featureKey as FeatureFlagsKeys];
      if (value && route) {
        return [...routes, route];
      }

      return routes;
    },
    []
  );
};

export const Routes = () => {
  const location = useLocation();
  const { flags } = useFeatureFlags();
  const isAuthenticated = useUser(state => state.isAuthenticated());

  const memoizedRoutes = useMemo(() => {
    const appRoutes: AppRoute[] = [
      ...commonRoutes,
      ...filterFeatureRoutes(featureRoutes, flags),
      ...(isAuthenticated ? routes : authRoutes),
    ];
    return appRoutes.map((routeConfig, index) => {
      return (
        <Route
          path={routeConfig.path}
          element={
            <AuthenticatedView
              isAuthenticated={isAuthenticated}
              config={routeConfig}
            />
          }
          key={index}
        />
      );
    });
  }, [isAuthenticated]);

  return (
    <>
      <AppMetadata path={location.pathname} />
      <MobileAppBanner path={location.pathname} />
      <AppHeader
        path={location.pathname}
        params={location.search}
        isAuthenticated={isAuthenticated}
      />
      <AppSearch path={location.pathname} />
      <main
        className={classNames(
          'main-container',
          NO_HEADER_PARAMS.includes(location.search) &&
            'main-container-no-header'
        )}
        data-testid="main-container"
      >
        <AppHistory>
          <ErrorBoundary pathname={location.pathname}>
            <MaintenanceBanner />
            <NotificationBanner pathname={location.pathname} />
            <NotificationAlerts />
            <Suspense fallback={<Loader />}>
              <RouterRoutes>
                <Route
                  path={'/auth'}
                  element={<Navigate to={authPaths.login} replace />}
                />
                <Route
                  path={'/f/a/:id'}
                  element={
                    <Redirect from="/f/a/:id" to={`${appPaths.article}/:id`} />
                  }
                />
                <Route
                  path={'/a'}
                  element={
                    <Navigate
                      to={`${appPaths.analyze}/?view=${AnalyzeViews.SCENARIO}`}
                      replace
                    />
                  }
                />
                <Route
                  path={'/analytics'}
                  element={
                    <Navigate
                      to={`${appPaths.analyze}/?view=${AnalyzeViews.SCENARIO}`}
                      replace
                    />
                  }
                />
                <Route
                  path={'/explore/a/:id'}
                  element={
                    <Redirect
                      from="/explore/a/:id"
                      to={`${appPaths.article}/:id`}
                    />
                  }
                />
                <Route
                  path={'/settings'}
                  element={
                    <Navigate to={appPaths.myAccount.portfolio} replace />
                  }
                />
                <Route
                  path={'/my-account'}
                  element={
                    <Navigate to={appPaths.myAccount.portfolio} replace />
                  }
                />
                <Route
                  path={'/chart/:entityTag'}
                  element={
                    <Redirect
                      from="/chart/:entityTag"
                      to={`${appPaths.analyze}/:entityTag?view=${AnalyzeViews.CHART}`}
                    />
                  }
                />
                <Route
                  path={'/chart/:entityTag/:snakeName'}
                  element={
                    <Redirect
                      from="/chart/:entityTag/:snakeName"
                      to={`${appPaths.analyze}/:entityTag/:snakeName?view=${AnalyzeViews.CHART}`}
                    />
                  }
                />
                <Route
                  path={'/promo/:id'}
                  element={
                    <Redirect
                      from="/promo/:id"
                      to={
                        isAuthenticated
                          ? `${appPaths.myAccount.subscription}/${accountRoutes.selectPlan}?promo=:id`
                          : `${authPaths.signUp}?promo=:id`
                      }
                    />
                  }
                />
                <Route
                  path={'/overview/:ticker'}
                  element={
                    <Redirect
                      from={'/overview/:ticker'}
                      to={`${appPaths.analyze}/:ticker`}
                    />
                  }
                />
                <Route
                  path="/sign-in"
                  element={<Navigate to={authPaths.login} replace />}
                />
                {memoizedRoutes}
                {!isAuthenticated && (
                  <Route
                    path="/*"
                    element={
                      <Navigate
                        to={
                          authPaths.login +
                          queryString({
                            [queryKeys.redirect]: `${location.pathname}${location.search}`,
                          })
                        }
                        replace
                      />
                    }
                  />
                )}
              </RouterRoutes>
            </Suspense>
          </ErrorBoundary>
        </AppHistory>
      </main>
    </>
  );
};
