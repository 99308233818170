import { deviceConstraints, typography } from '@toggle/design-system';
import styled from 'styled-components';

export const MainContainer = styled.main`
  flex-direction: column;
  padding: 0px 40px 16px;
  top: 88px;
  transition: top 1s ease;
  margin: auto;

  @media ${deviceConstraints.desktopSmall} {
    width: 880px;
  }

  @media ${deviceConstraints.desktop} {
    width: 1280px;
  }
`;

export const Header = styled.header`
  padding-top: 32px;
  height: calc(100vh - #{64px});
  @media ${deviceConstraints.tablet} {
    padding-top: 42px;
    height: calc(100vh - 88px - 42px);
  }
`;

export const HeaderBody = styled.div`
  display: flex;
  justify-content: space-between;
  height: fit-content;
  position: relative;
`;

export const HeaderLeftSide = styled.div`
  display: flex;
  justify-content: space-between;
  height: fit-content;
  position: relative;

  display: flex;
  flex-direction: column;

  @media ${deviceConstraints.desktopSmall} {
    margin-right: 40px;
  }
`;

export const HeaderTitle = styled.h1`
  font-size: 24px;
`;

export const WaveImg = styled.img`
  display: inline;
  height: 30px;
  padding-right: 16px;
`;

export const RobotImg = styled.img`
  height: 160px;

  @media ${deviceConstraints.tablet} {
    height: 240px;
  }

  @media ${deviceConstraints.desktopSmall} {
    height: 430px;
  }
`;

export const HeaderText = styled.div`
  padding: 16px 16px 32px 0px;
  ${typography.emu};

  @media ${deviceConstraints.desktopSmall} {
    font-size: 24px;
  }

  & > p {
    margin-bottom: 16px;
  }
`;

export const HeaderButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const HeaderButtonWrapper = styled.div<{ isLetsGo: boolean }>`
  position: absolute;
  display: block;
  width: 100%;
  max-height: 48px;
  top: ${props => (props.isLetsGo ? 'calc(100%)' : 'calc(100% + 64px)')};

  @media ${deviceConstraints.tablet} {
    top: ${props =>
      props.isLetsGo ? 'calc(100% + 32px)' : 'calc(100% + 100px)'};
  }

  @media ${deviceConstraints.desktopSmall} {
    position: static;
    width: 235px;
    margin-top: auto;
  }
`;

export const Section = styled.section`
  flex: 1;
  min-height: calc(100vh - 64px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 64px;
  padding: 32px 0px;

  @media ${deviceConstraints.desktopSmall} {
    min-height: calc(100vh - 88px);
  }
`;

export const SectionTitle = styled.h2`
  padding-top: 80px;
  font-size: 18px;
  padding-bottom: 16px;

  @media ${deviceConstraints.desktopSmall} {
    padding-top: 70px;
    font-size: 24px;
    padding-bottom: 32px;
  }
`;

export const SectionButton = styled.div`
  width: 100%;
  @media ${deviceConstraints.desktopSmall} {
    width: 235px;
  }
`;
