import { z } from 'zod';

export const TRADESTATION_CUSTOM_EVENT = 'TRADESTATION_CUSTOM_EVENT';

const oAuthTradestationPayloadSchema = z.object({
  code: z.string(),
});

export const oAuthTradestationResponseSchema = z.object({
  login_url: z.string(),
});

export type OAuthTradestationPayload = z.infer<
  typeof oAuthTradestationPayloadSchema
>;
export type OAuthTradestationResponse = z.infer<
  typeof oAuthTradestationResponseSchema
>;
