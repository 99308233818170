import styled, { keyframes } from 'styled-components';

const move = keyframes`
    0% {
      background-position: 0 0;
    }
    100% {
      background-position: 35px 35px;
    }
`;

export const SplashLoader = styled.div`
  height: 16px;
  padding: 3px;
  border-radius: 25px;
  box-sizing: content-box;
  background: var(--base--75);
`;

export const Line = styled.span`
  position: relative;
  display: block;
  height: 100%;
  border-radius: 20px;
  background-image: var(--base--75);
  overflow: hidden;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-image: linear-gradient(
      -45deg,
      var(--base--90) 25%,
      transparent 25%,
      transparent 50%,
      var(--base--90) 50%,
      var(--base--90) 75%,
      transparent 75%,
      transparent 100%
    );
    background-size: 35px 35px;
    animation: ${move} 1s linear infinite;
  }
`;
