import { Paywall } from '@toggle/design-system';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
  BillingCycle,
  Subscription,
  UserRole,
} from '~/api/subscription/subscription-schema';
import { accountRoutes, appPaths } from '~/routes/app-paths';
import { useAPI } from '~/shared/hooks/use-api/useAPI';
import { useArticleLimit } from '~/stores/use-article-limit/useArticleLimit';
import { useSubscription } from '~/stores/use-subscription/useSubscription';
import { fetchArticlesLimits } from '~/views/article/services';
import { useNavigateToCheckout } from '~/views/my-account/subscription/hooks/use-navigate-to-checkout/useNavigateToCheckout';
import { PlanCardType } from '~/views/my-account/subscription/plan-matrix/utils/plan-matrix.types';
import { usePrices } from '~/views/my-account/subscription/utils/get-prices/getPrices';
import { BasicPaywall } from '~/views/my-toggle/components/basic-paywall/BasicPaywall';

import { getSubscriptionRoleByModalType } from './services/modal-type-to-subscription-plan/modalTypeToSubscriptionPlan';
import { MODAL_TYPES } from './services/modal-types/modalTypes';
import {
  paywallBasicTypes,
  paywallTypes,
} from './services/paywall-types/paywallTypes';
import { usePaywallStore } from './stores/usePaywallStore';

export const PaywallContainer = () => {
  const { t } = useTranslation(['subscription', 'paywall']);
  const navigate = useNavigate();
  const navigateToCheckout = useNavigateToCheckout();
  const { getSubscriptionByRole, isCopilot, isBasic, isFromIb, subscriptions } =
    useSubscription(state => ({
      getSubscriptionByRole: state.getSubscriptionByRole,
      isBasic: state.isBasic(),
      isCopilot: state.isCopilot(),
      isFromIb: state.isFromIb(),
      subscriptions: state.subscriptions,
    }));
  let { isOpen, type, hideModal } = usePaywallStore();
  const { setData, articlesLimits, clearData } = useArticleLimit(state => ({
    setData: state.setData,
    clearData: state.clearData,
    articlesLimits: state.data,
  }));

  const { data: articleLimit } = useAPI(
    abortSignal => fetchArticlesLimits(abortSignal),
    {
      shouldFetch: isBasic,
    }
  );

  useEffect(() => {
    if (articleLimit) {
      setData(articleLimit);
    }
    return () => {
      clearData();
    };
  }, [articleLimit]);

  if (type === MODAL_TYPES.DEFAULT) {
    type = isCopilot ? MODAL_TYPES.DEFAULT_PRO : MODAL_TYPES.DEFAULT;
  }

  const subscriptionPlan = getSubscriptionRoleByModalType(type);
  const subscription = getSubscriptionByRole(subscriptionPlan);
  const billingCycle = BillingCycle.year;

  const price = usePrices({
    prices: subscription?.prices ?? [],
    billingCycle,
  });

  if (!subscription || !subscriptions || !isOpen) {
    return null;
  }

  const upgradeSubscription = (newSubscription: Subscription) => {
    if (!isFromIb) {
      navigateToCheckout({
        type:
          newSubscription.role === UserRole.Pro
            ? PlanCardType.Pro
            : PlanCardType.Copilot,
        billingCycle: billingCycle,
      });
      hideModal();
    } else {
      redirectToPlans();
    }
  };

  const redirectToPlans = () => {
    navigate(
      isFromIb
        ? appPaths.myAccount.subscription
        : `${appPaths.myAccount.subscription}/${accountRoutes.selectPlan}`
    );

    hideModal();
  };

  if (isBasic) {
    return (
      <BasicPaywall
        subscriptions={subscriptions.filter(s => s.role !== UserRole.Basic)}
        table={paywallBasicTypes[type ?? MODAL_TYPES.DEFAULT]}
        onClose={hideModal}
        upgradeSubscription={upgradeSubscription}
        redirectToPlans={redirectToPlans}
      />
    );
  }

  const paywallContent = paywallTypes[type ?? MODAL_TYPES.DEFAULT];
  paywallContent.benefits = paywallContent.benefits.map(b => t(b));
  const [planPrice, planBilling] = price.priceMonthly.split('/');
  /* i18next-extract-mark-context-next-line ["basic", "copilot", "pro"] */
  const planName = t('subscription:role', { context: subscription.role });

  return (
    <Paywall
      isOpen
      onClose={hideModal}
      title={t(`paywall:${type}.title`)}
      description={t(`paywall:${type}.description`, {
        limit: articlesLimits?.limit,
        consumed: articlesLimits?.consumed,
      })}
      content={paywallContent}
      primaryLabel={t('paywall:upgradeTo', {
        plan: planName,
      })}
      secondaryLabel={t('paywall:compareAllPlans')}
      isFromIb={isFromIb}
      primaryAction={() => upgradeSubscription(subscription)}
      secondaryAction={redirectToPlans}
      planName={planName}
      planPrice={planPrice}
      planBilling={planBilling}
    />
  );
};
