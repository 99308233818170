import { borderRadius, Icon } from '@toggle/design-system';
import styled, { css } from 'styled-components';

export const LegendMoreMenuRoot = styled.div`
  display: flex;
  position: relative;
  height: 20px;
  width: 20px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  &:hover {
    background-color: var(--surface-hover);
  }
`;

export const MenuIcon = styled(Icon)`
  transform: rotate(90deg);
  cursor: pointer;
`;

export const IconWrapper = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ColorPicker = styled.div`
  padding: 8px;
  background-color: var(--surface-default);
  outline: 1px solid var(--border-soft);
  ${borderRadius.r1}
  display: flex;
  flex-wrap: wrap;
  position: fixed;
  width: 170px;
  gap: 8px;
`;

export const LeftIcon = styled(Icon)`
  fill: currentColor;
`;

export const SmallColorIcon = styled.div<{ $color: string }>`
  ${borderRadius.rFull}
  background-color: ${props => props.$color};
  width: 16px;
  height: 16px;
`;

export const ColorIcon = styled.div<{ $color: string; $isSelected: boolean }>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  background-color: ${props => props.$color};
  ${borderRadius.rFull};

  svg {
    fill: var(--icon-on-primary);
  }

  ${props =>
    props.$isSelected &&
    css`
      border: 2px solid black;
      outline: 1px solid ${props.$color};
    `}

  &:hover {
    cursor: pointer;
    border: 2px solid black;
    outline: 1px solid ${props => props.$color};
  }
`;
