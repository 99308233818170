import { typographyV2 } from '@toggle/design-system';
import styled from 'styled-components';

export const Container = styled.div`
  text-align: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 50px 0px;
`;

export const Title = styled.h2`
  ${typographyV2.titleBase};
`;

export const Description = styled.p`
  ${typographyV2.bodySm};
  color: var(--text-soft);
  padding: 4px 0px 16px;
`;
