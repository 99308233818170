import { Button } from '@toggle/design-system';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { PlaceOrderSchemaConformation } from '~/api/trading/trading-schema';

import * as S from './SecondaryConfirmation.styles';

export type SecondaryConfirmationState = Omit<
  PlaceOrderSchemaConformation,
  'message' | 'order_id'
>;

export interface SecondaryConfirmationProps extends SecondaryConfirmationState {
  onUnderstoodClick: (confirmationId: string) => void;
}

export const SecondaryConfirmation: FC<SecondaryConfirmationProps> = ({
  confirmation_messages,
  confirmation_id,
  onUnderstoodClick,
}) => {
  const { t } = useTranslation('portfolio');
  return (
    <S.Container data-testid="secondary-confirmation">
      <S.MessageContainer data-testid="secondary-confirmation-message">
        {confirmation_messages.map((message, idx) => (
          <S.Message key={idx} dangerouslySetInnerHTML={{ __html: message }} />
        ))}
      </S.MessageContainer>
      <S.Footer>
        <Button
          size="large"
          label={t('portfolio:secondaryOrderConfirmationBtn')}
          onClick={() => onUnderstoodClick(confirmation_id)}
          data-testid="understood-button"
          fullWidth
        />
      </S.Footer>
    </S.Container>
  );
};
