import styled from 'styled-components';

import { borderRadius } from '~/design-tokens/border-radius/borderRadius';
import {
  fontSize,
  typographyV2,
} from '~/design-tokens/typography/Typography.styles';
import { deviceConstraints } from '~/utils/breakpoints';

import { AlertType, AlertVariant } from './Alert';

export interface AlertStyledProps {
  type: AlertType;
  variant: AlertVariant;
  responsive?: boolean;
}

const BorderColor: Record<AlertType, string> = {
  error: 'var(--border-critical)',
  warning: 'var(--border-warning)',
  success: 'var(--border-success)',
  info: 'var(--border-default)',
};

const Bg: Record<AlertType, string> = {
  success: 'var(--surface-success)',
  warning: 'var(--surface-warning)',
  error: 'var(--surface-critical)',
  info: 'var(--surface-default)',
};

export const Button = styled.span<Pick<AlertStyledProps, 'responsive'>>`
  width: auto;
  margin-top: 8px;
  border-radius: 4px;
  font-size: 14px;
  padding: 7px 8px 5px;
  line-height: 20px;
  border-style: none;
  cursor: pointer;
  color: var(--base--100);
  text-align: center;
`;

export const Alert = styled.div<AlertStyledProps>`
  display: flex;
  position: relative;
  ${borderRadius.r1};
  padding: ${props =>
    props.variant === 'inline' ? '16px 16px 16px 13px' : '16px'};
  color: var(--base--0);
  border: ${props =>
    props.variant === 'toast' && `1px solid ${BorderColor[props.type]}`};
  border-left: ${props =>
    props.variant === 'inline' && `4px solid ${BorderColor[props.type]}`};
  background-color: ${({ type }) => Bg[type]};
  width: 343px;
  max-width: 100%;

  @media ${deviceConstraints.tablet} {
    width: 480px;
  }

  @media ${deviceConstraints.desktop} {
    width: 600px;
  }

  ${Button} {
    background-color: ${({ type }) => BorderColor[type]};
  }

  & svg {
    display: block;
  }
`;

export const Icon = styled.div`
  margin-right: 8px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  ${fontSize.f75}
`;

export const Title = styled.p`
  ${typographyV2.titleSm};
  flex-grow: 1;
`;

export const ContentSection = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const Message = styled.div`
  ${typographyV2.bodySm};
`;

export const Close = styled.button`
  cursor: pointer;
  border: none;
  background: none;
  margin-left: 12px;
  padding: 4px;

  svg {
    fill: var(--icon-soft);
  }

  &:hover svg {
    fill: var(--icon-default);
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  margin-top: 12px;

  > button:first-child {
    margin-right: 8px;
  }
`;
