import { Button, typography } from '@toggle/design-system';
import styled from 'styled-components';

export const BookmarkButton = styled(Button)`
  ${typography.emu}
  text-transform: uppercase;
  background-color: transparent;
  color: inherit;
  border-radius: 0px;
  height: auto;

  &:hover {
    background-image: unset;
  }

  &:focus {
    outline: none;
    border: 1px solid transparent;
  }
`;
