import { Tooltip, TooltipProps } from '@toggle/design-system';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useTouchDevice } from '../../hooks/MediaQueryHook';
import * as S from './TooltipContainer.styles';

export type TooltipContainerProps = Omit<
  TooltipProps,
  'closeLabel' | 'isTouchDevice' | 'children'
> & { children?: TooltipProps['children']; showUnderline?: boolean };

export const TooltipContainer = (props: TooltipContainerProps) => {
  const isTouchDevice = useTouchDevice();
  const { t } = useTranslation('common');

  if (!props.children) {
    return null;
  }

  return (
    <Tooltip
      {...props}
      closeLabel={t('common:tapToClose')}
      isTouchDevice={isTouchDevice}
    >
      {props.showUnderline ? (
        <S.UnderlineText>{props.children}</S.UnderlineText>
      ) : (
        props.children
      )}
    </Tooltip>
  );
};
