import {
  differenceInDays,
  endOfMonth,
  format,
  isAfter,
  isBefore,
  isWithinInterval,
  startOfMonth,
  subMonths,
} from 'date-fns';
import { TFunction } from 'i18next';

import {
  BankCard,
  BillingCycle,
  Subscription,
  SubscriptionName,
  UserRole,
  UserSubscription,
  UserSubscriptionWithUpcoming,
} from '~/api/subscription/subscription-schema';
import { UserAddress } from '~/api/user/user-schema';

import {
  ChangingPlanTier,
  PlanCardType,
} from '../plan-matrix/utils/plan-matrix.types';

export const formatTrialDays = (
  t: TFunction,
  trialTo?: string,
  now = Date.now()
) => {
  const trialDays = trialTo
    ? differenceInDays(new Date(trialTo), now)
    : undefined;
  return trialDays === undefined
    ? ''
    : t('subscription:days', { count: trialDays });
};

export const mapToCardPlanFeatures: Record<PlanCardType, string[]> = {
  [PlanCardType.Basic]: [],
  [PlanCardType.Copilot]: [
    'unlimitedInsights',
    'accessToInsights',
    'realTime',
    'newsInsights',
    'tli',
    'global',
  ],
  [PlanCardType.Pro]: [
    'allIndicators',
    'scenarioTool',
    'chartCommander',
    'allAssets',
    'weeklyBriefs',
    'whiteGlove',
  ],
  [PlanCardType.Custom]: [
    'chat',
    'api',
    'integration',
    'proprietary',
    'addToggle',
    'bulkLicense',
  ],
};

export const mapPlanCardToUserRole: Partial<Record<PlanCardType, UserRole>> = {
  [PlanCardType.Basic]: UserRole.Basic,
  [PlanCardType.Copilot]: UserRole.Copilot,
  [PlanCardType.Pro]: UserRole.Pro,
};

type RolesWithFeatures = UserRole.Copilot | UserRole.Pro;
export const isRoleWithFeatures = (
  role: UserRole
): role is RolesWithFeatures => {
  return role in mapToCardPlanFeatures;
};

export const getProPlanCardLabels = (
  t: TFunction,
  { role, upcoming_subscription, trial }: UserSubscription
) => {
  const isProUpcoming = upcoming_subscription?.name === SubscriptionName.Pro;
  if (trial) {
    return {
      title: isProUpcoming
        ? t('subscription:role', { context: UserRole.Pro })
        : t('subscription:subscribeToPro'),
      featuresTitle: t('subscription:keepAccess'),
    };
  }

  const isPro = role === UserRole.Pro && !upcoming_subscription;
  if (isPro) {
    return {
      title: t('subscription:role', { context: UserRole.Pro }),
      featuresTitle: t('subscription:everythingInCopilot'),
    };
  }

  const isCopilot = role === UserRole.Copilot && !upcoming_subscription;

  if (isCopilot) {
    return {
      title: t('subscription:upgrade', { context: UserRole.Pro }),
      featuresTitle: t('subscription:gainAccess'),
    };
  }

  const isDowngradeOnPro =
    role === UserRole.Pro &&
    upcoming_subscription &&
    [SubscriptionName.Basic, SubscriptionName.Copilot].includes(
      upcoming_subscription.name
    );

  if (isDowngradeOnPro) {
    return {
      title: t('subscription:upgrade', { context: UserRole.Pro }),
      featuresTitle: t('subscription:keepAccess'),
    };
  }

  return {
    title: t('subscription:subscribeToPro'),
    featuresTitle: t('subscription:gainAccess'),
  };
};

export const getProPrice = (
  subscriptions: Subscription[] | null,
  defaultPrice = 100
) => {
  const proSubscription = subscriptions?.find(s => s.role === UserRole.Pro);
  const yearlyPricePerMonth = getYearlyPricePerMonth(proSubscription);
  return yearlyPricePerMonth ? yearlyPricePerMonth : defaultPrice;
};

export const getYearlyPricePerMonth = (subscription?: Subscription) => {
  const yearlyPrice = subscription?.prices.find(
    p => p.billing_cycle === BillingCycle.year
  )?.price;

  return yearlyPrice ? yearlyPrice / 12 : 0;
};

export const formatNextBillingDate = (
  userSubscription: UserSubscription,
  startingOn?: string,
  fallback = 'N/A'
) => {
  const dateFormat = 'd LLL y';
  if (startingOn) {
    return format(new Date(startingOn), dateFormat);
  }

  const nextPayment =
    userSubscription.next_payment ??
    userSubscription.upcoming_subscription?.next_payment;
  return nextPayment ? format(new Date(nextPayment), dateFormat) : fallback;
};

export const isSubscriptionCancelled = (
  { role, upcoming_subscription }: UserSubscription,
  card: BankCard | null,
  address?: UserAddress
) =>
  (!!card || !!address) &&
  role !== UserRole.Basic &&
  !!upcoming_subscription &&
  upcoming_subscription.name === SubscriptionName.Basic;

export const isSubscribedToPaidPlan = ({
  role,
  upcoming_subscription,
  trial,
}: UserSubscription) =>
  (role !== UserRole.Basic && !trial && !upcoming_subscription) ||
  (upcoming_subscription &&
    upcoming_subscription.name !== SubscriptionName.Basic);

const TRIAL_ENDS_BEFORE_IN_DAYS = 7;

export const isTrialEnding = (trialTo?: string) => {
  if (!trialTo) {
    return false;
  }

  const today = new Date();
  const until = new Date(trialTo);
  const isValid = isBefore(today, until);
  const diff = differenceInDays(until, today);
  return isValid && diff <= TRIAL_ENDS_BEFORE_IN_DAYS;
};

export const isSubscribedWhileOnTrial = (
  userSubscription: UserSubscription | UserSubscriptionWithUpcoming
): userSubscription is UserSubscriptionWithUpcoming =>
  userSubscription.trial &&
  !!userSubscription.upcoming_subscription &&
  [SubscriptionName.Copilot, SubscriptionName.Pro].includes(
    userSubscription.upcoming_subscription.name
  );

export const isBillingFrequencyChanged = (
  userSubscription: UserSubscription | UserSubscriptionWithUpcoming
): userSubscription is UserSubscriptionWithUpcoming =>
  !userSubscription.trial &&
  !!userSubscription.upcoming_subscription &&
  userSubscription.upcoming_subscription.name === userSubscription.name &&
  userSubscription.upcoming_subscription.billing_cycle !==
    userSubscription.billing_cycle;

export const isDowngradedToCopilot = (
  userSubscription: UserSubscription | UserSubscriptionWithUpcoming
): userSubscription is UserSubscriptionWithUpcoming =>
  userSubscription.role === UserRole.Pro &&
  !userSubscription.trial &&
  !!userSubscription.upcoming_subscription &&
  userSubscription.upcoming_subscription.role === UserRole.Copilot;

const ABOUT_TO_EXPIRE_ADDITIONAL_MONTHS = 1;

export const checkCardExpiry = ({
  expiry_year: expiryYear,
  expiry_month: expiryMonth,
}: BankCard) => {
  const today = new Date();
  const expiryMonthIndex = expiryMonth - 1;
  const expiryDate = new Date(expiryYear, expiryMonthIndex);

  const expiryPeriodStarts = subMonths(
    startOfMonth(expiryDate),
    ABOUT_TO_EXPIRE_ADDITIONAL_MONTHS
  );
  const expiryPeriodEnds = endOfMonth(expiryDate);

  const isAboutToExpire = isWithinInterval(today, {
    start: expiryPeriodStarts,
    end: expiryPeriodEnds,
  });

  const isCardExpired = isAfter(today, expiryPeriodEnds);

  return {
    expiryDate: expiryPeriodEnds,
    isAboutToExpire,
    isCardExpired,
  };
};

export const getSubscribeButtonLabel = (
  t: TFunction,
  tier: ChangingPlanTier,
  planType: PlanCardType
) => {
  if (tier === ChangingPlanTier.Same || tier === ChangingPlanTier.Initial) {
    return t('subscription:subscribe');
  }

  if (tier === ChangingPlanTier.Upgrading) {
    return t('subscription:planMatrix.upgradeTo', {
      plan: t('subscription:role', { context: planType }),
    });
  }

  return t('subscription:planMatrix.downgradeTo', {
    plan: t('subscription:role', { context: planType }),
  });
};

export const getSelectedPriceForLocation = (
  userSubscription: UserSubscription,
  subscriptions: Subscription[]
) => {
  const subscriptionName =
    userSubscription.upcoming_subscription?.name ?? userSubscription.name;
  const billingCycle =
    userSubscription.upcoming_subscription?.billing_cycle ??
    userSubscription.billing_cycle;
  const subscription = subscriptions.find(s => s.name === subscriptionName);

  return !subscription || subscriptionName === SubscriptionName.Basic
    ? undefined
    : subscription.prices.find(p => p.billing_cycle === billingCycle);
};
