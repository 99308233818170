import React from 'react';
import { useTranslation } from 'react-i18next';

import * as S from './ChatEmptyState.styles';

export const ChatEmptyState = () => {
  const { t } = useTranslation('common');

  return (
    <S.ChatEmptyStateRoot data-testid="chat-empty-state">
      <S.ChatEmptyStateImage
        alt={t('chat:noWidgetImageAlt')}
        src="/app/assets/chat/not-found.png"
      />
      <S.ChatEmptyStateText>{t('chat:noWidget')}</S.ChatEmptyStateText>
    </S.ChatEmptyStateRoot>
  );
};
