import { Domain } from '~/types/axis.types';
import { PaneData } from '~/types/create.types';
import { ThresholdInterval } from '~/types/timeseries.types';

export const getThresholdIntervals = (domain: Domain, panes: PaneData[]) => {
  const thresholdIndicators = panes
    .slice(1)
    .flatMap(p => p.chartAssetData)
    .filter(item => !item.isHidden && item.snakeMeta?.threshold);

  const checker = (i: ThresholdInterval) =>
    //fully visible
    (i.start >= domain[0] && i.end <= domain[1]) ||
    //partially visible
    (i.end > domain[1] && i.start < domain[1]) ||
    (i.start < domain[0] && i.end > domain[0]);

  return thresholdIndicators.length === 1
    ? thresholdIndicators[0].thresholdIntervals?.filter(checker)
    : undefined;
};
