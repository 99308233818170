import { cryptoRandom } from '@toggle/helpers';
import * as d3 from 'd3';
import { ScaleLinear } from 'd3';
import React, { useMemo, useRef } from 'react';

import { Scale, TSPoint } from '../Chart';
import { useAnimatedScale } from '../hooks/useAnimatedScale';
import { GradientDef, GradientDefProps } from './GradientDef';

export interface GradientAreaProps
  extends Omit<GradientDefProps, 'gradientId'> {
  ts: Array<TSPoint<number, number>>;
  scaleX: ScaleLinear<number, number>;
  scaleY: ScaleLinear<number, number>;
  className?: string;
  transition?: d3.Transition<SVGSVGElement, unknown, null, undefined>;
  y0?: D3AreaCoordinate<TSPoint<number, number>>;
  y1?: D3AreaCoordinate<TSPoint<number, number>>;
  curve?: d3.CurveFactory;
}

export type D3AreaCoordinate<T> =
  | number
  | ((d: T, index: number, data: T[]) => number);

const chartGradientPath = <I, V>(
  xScale: Scale<I>,
  yScale: Scale<V>,
  y0: D3AreaCoordinate<TSPoint<I, V>>,
  y1: D3AreaCoordinate<TSPoint<I, V>>,
  curve: d3.CurveFactory
) => {
  const toAreaPoint =
    (line: D3AreaCoordinate<TSPoint<I, V>>) =>
    (p: TSPoint<I, V>, idx: number, arr: TSPoint<I, V>[]) => {
      const y = typeof line === 'number' ? line : line(p, idx, arr);
      return yScale(y);
    };

  return d3
    .area<TSPoint<I, V>>()
    .x(d => xScale(d.index))
    .y(toAreaPoint(y0))
    .curve(curve)
    .y1(toAreaPoint(y1));
};

export function GradientArea({
  ts,
  scaleX,
  scaleY,
  stops,
  className,
  transition,
  y1 = scaleY.domain()[0],
  y0 = (p: TSPoint<number, number>) => p.value,
  curve = d3.curveLinear,
  gradientY1 = 0,
}: GradientAreaProps) {
  const areaRef = useRef<SVGPathElement>(null);

  const { scaleX: drawScaleX, scaleY: drawScaleY } = useAnimatedScale(
    scaleX,
    scaleY,
    areaRef,
    ts,
    transition
  );

  const gradientPath = useMemo(
    () =>
      chartGradientPath<number, number>(
        drawScaleX,
        drawScaleY,
        y0,
        y1,
        curve
      )(ts),
    [drawScaleX, drawScaleY, y1]
  );

  const uniqueGradientID = useRef(
    `gradient-${Date.now()}-${cryptoRandom()}`
  ).current;

  return (
    <>
      <GradientDef
        gradientY1={gradientY1}
        stops={stops}
        gradientId={uniqueGradientID}
      />
      <path
        ref={areaRef}
        d={gradientPath ?? ''}
        fill={`url(#${uniqueGradientID})`}
        data-testid="gradient-path"
        className={className}
      />
    </>
  );
}
