import { z } from 'zod';

import {
  AssetClassCode,
  AssetSector,
  AssetSubClassCode,
} from '~/api/entities/entity-constants';

const assetClassCodes = z.nativeEnum(AssetClassCode);
const assetSubClassCodes = z.nativeEnum(AssetSubClassCode);
const assetSectors = z.nativeEnum(AssetSector);

export enum PrimaryMethod {
  Price = 'price',
  Yield = 'yield',
  OptionAdjSpread = 'option_adj_spread',
  SpreadPrice = 'spread_price',
  PriceTwi = 'price_twi',
  Cesi = 'cesi',
  GdpLevel = 'gdp_level',
  PmiNewOrders = 'pmi_new_orders',
}

const primaryMethodSchema = z.nativeEnum(PrimaryMethod);

export type Gics = z.infer<typeof gicsSchema>;
const gicsSchema = z.object({
  industry_group: z.object({
    code: z.string(),
  }),
  sector: z.object({
    code: z.union([assetSectors, z.literal('')]),
    name: z.string(),
  }),
});

export type Search = z.infer<typeof searchSchema>;
const searchSchema = z.object({
  rank: z.number(),
});

export type Entity = z.infer<typeof entitySchema>;
export const entitySchema = z.object({
  tag: z.string(),
  id: z.string(),
  active: z.boolean(),
  asset_class: assetClassCodes,
  class: z.number(),
  country: z
    .object({
      long: z.string(),
      short: z.string().optional(),
    })
    .optional(),
  currency: z.string(),
  default_snake: z.string(),
  exchange: z.object({
    code: z.string(),
  }),
  gics: gicsSchema,
  primary_method: z.union([primaryMethodSchema, z.literal('')]),
  search: searchSchema,
  sub_class: assetSubClassCodes,
  subscribable_ticker: z.string().optional(),
  ticker: z.string(),
  logo_url: z.string(),
  logo_urls: z.object({
    dark_svg: z.string(),
    light_svg: z.string(),
    dark_png: z.string(),
    light_png: z.string(),
  }),
  gated: z.boolean(),
  name: z.string(),
  name_full: z.string(),
  name_short: z.string(),
  company_description: z.string().optional(),
  has_news: z.boolean().optional(),
  overview_disabled: z.boolean().optional(),
});

export const entityArrSchema = z.array(entitySchema);

interface UseTagSearchBodyParams {
  user_profile?: boolean;
  currencies?: boolean;
  assets?: boolean;
}

export type UseTagSearchParams = UseTagSearchBodyParams & { limit?: number };

export interface FetchEntityRequestBody {
  filter: 1 | 2;
  args: string[];
}
