import { Input } from '@toggle/design-system';
import { keyboard } from '@toggle/helpers';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ModalLarge } from '~/shared/components';

export interface FilterScreenModalProps {
  name?: string;
  onSave: (value: string) => void;
  onClose: () => void;
}

export const FilterScreenModal: FC<FilterScreenModalProps> = ({
  name = '',
  onClose,
  onSave,
}) => {
  const { t } = useTranslation(['explore', 'settings']);
  const [value, setValue] = useState(name);
  const [saveBtnEnabled, setSaveBtnEnabled] = useState(false);

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === keyboard.enter.key && value) {
      onSave(value.trim());
    }
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.value;
    setValue(name);
    setSaveBtnEnabled(!!name.length);
  };

  return (
    <ModalLarge
      isOpen
      onClose={onClose}
      title={t('explore:nameScreen')}
      primaryBtnProps={{
        label: t('settings:unsavedChanges.saveBtnLabel'),
        disabled: !saveBtnEnabled,
        onClick: () => onSave(value.trim()),
        'data-testid': 'modal-large-footer-btn',
      }}
    >
      <Input
        id="screen-name-input"
        name="screen-name-input"
        placeholder={t('explore:nameScreenPlaceholder')}
        className="filterScreenModalInput"
        value={value}
        onKeyDown={onKeyDown}
        onChange={onChange}
        helperText={!name ? t('explore:nameInputHelperText') : ''}
        data-testid="screen-name-input"
      />
    </ModalLarge>
  );
};
