export const EFT_FILTER = {
  properties: {
    cls: 'etf',
  },
};

export const CRYPTO_FILTER = {
  properties: {
    sub_cls: 'fx_crypto',
  },
};
