import {
  Button,
  Dropdown,
  DropdownList,
  DropdownProps,
} from '@toggle/design-system';
import styled from 'styled-components';

export const NotRelevantButton = styled(Button)`
  color: var(--text-critical);
  border-color: var(--border-critical);
  cursor: default;
  pointer-events: none;
  & svg {
    fill: var(--icon-critical);
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`;

export const FeedbackDropdown = styled(Dropdown)`
  margin-left: auto;
  ${Dropdown.Styled.DropdownListContainer} {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  ${DropdownList.Styled.DropdownListRoot} {
    width: max-content;
  }
` as <T>(props: DropdownProps<T>) => JSX.Element;
