import { isBarBullish, TimeSeriesItem } from '@toggle/chart';
import React from 'react';

import * as S from './OHLCPrice.styles';

export interface OHLCPriceProps {
  price: TimeSeriesItem;
  priceFormatter: (price: number) => string;
}

export const OHLCPrice = ({ price, priceFormatter }: OHLCPriceProps) => {
  const data = [
    { label: 'O', price: price.open },
    { label: 'H', price: price.high },
    { label: 'L', price: price.low },
    { label: 'C', price: price.close },
  ];

  const isBullish = isBarBullish(price);

  return (
    <S.OHLCPriceRoot data-testid="ohlc-price">
      {data.map((d, i) => (
        <S.PriceContainer key={i}>
          <S.Label>{d.label}</S.Label>
          <S.Price data-testid={`ohlc-${d.label}-price`} $isBullish={isBullish}>
            {priceFormatter(d.price)}
          </S.Price>
        </S.PriceContainer>
      ))}
    </S.OHLCPriceRoot>
  );
};
