import hash from 'object-hash';

import { config } from '~/config';
import { Tracking } from '~/shared/services/tracking';

const {
  app: { appKey },
} = config;

function getCanvasPrint() {
  try {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    if (!ctx) {
      return '';
    }
    const text =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ`~1!2@3#4$5%6^7&8*9(0)-_=[{]}|;:',<.>/?";
    ctx.textBaseline = 'top';
    ctx.font = "14px 'Arial'";
    ctx.textBaseline = 'alphabetic';
    ctx.fillStyle = '#f60';
    ctx.fillRect(125, 1, 62, 20);
    ctx.fillStyle = '#069';
    ctx.fillText(text, 2, 15);
    ctx.fillStyle = 'rgba(102, 204, 0, 0.7)';
    ctx.fillText(text, 4, 17);
    ctx.shadowBlur = 10;
    ctx.shadowColor = 'blue';
    ctx.fillRect(-20, 10, 234, 5);

    return canvas.toDataURL();
  } catch (error) {
    Tracking.captureException(error as Error);
    return '';
  }
}

export function getFingerprint(key?: string): string {
  const { language } = window.navigator;
  const { colorDepth } = window.screen;
  const canvasPrint = getCanvasPrint();
  const keyLowerCase = key?.toLowerCase();

  const data = [language, colorDepth, canvasPrint, keyLowerCase, appKey];

  return hash(data.filter(Boolean));
}
