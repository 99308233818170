import * as d3 from 'd3';
import { ScaleLinear } from 'd3';
import { RefObject, useMemo } from 'react';

import { useHistoricalEffect } from '~/shared/hooks/useHistoricalEffect';

import { maybeAnimated } from '../../snake-chart/snake-chart-utils';
import { TSPoint } from '../Chart';
import { fixEmptyDomain, numZoom } from '../utils';

export function useAnimatedScale<AElement extends d3.BaseType>(
  xScale: ScaleLinear<number, number>,
  yScale: ScaleLinear<number, number>,
  ref: RefObject<SVGElement>,
  ts: Array<TSPoint<number, number>>,
  transition?: d3.Transition<AElement, unknown, null, undefined>
) {
  const maxScaleX = useMemo(
    () => d3.scaleLinear().range([0, ts.length]).domain(xScale.domain()),
    [ts, xScale]
  );

  const midScaleY = useMemo(() => {
    const domain = d3.extent<number>(ts.map(p => p.value)) as number[];
    const squareMid = Math.sqrt(domain[1] - domain[0]);
    const newDomain = [domain[0], domain[0] + squareMid];

    return fixEmptyDomain(
      d3.scaleLinear().range(newDomain.slice().reverse()).domain(newDomain)
    );
  }, [ts]);

  const { xZoom, yZoom, xOffset, yOffset } = useMemo(() => {
    const { zoom: xZoom, offset: xOffset } = numZoom(maxScaleX, xScale);
    const { zoom: yZoom, offset: yOffset } = numZoom(midScaleY, yScale);

    return {
      xZoom,
      xOffset,
      yZoom,
      yOffset,
    };
  }, [xScale, yScale, midScaleY, maxScaleX]);

  const transform = useMemo(() => {
    return xZoom === 0 || yZoom === 0
      ? undefined
      : `scale(${xZoom}, ${yZoom}) translate(${xOffset}, ${yOffset})`;
  }, [xZoom, yZoom, xOffset, yOffset]);

  const initialTransform = useMemo(() => {
    return xZoom === 0 || yZoom === 0
      ? undefined
      : `scale(${xZoom}, 0.001) translate(${xOffset}, ${yOffset})`;
  }, [xZoom, yZoom, xOffset, yOffset]);

  useHistoricalEffect(
    (current, next) => {
      const [transform, initialTransform, transition] = next as [
        string,
        string,
        d3.Transition<SVGSVGElement, unknown, null, undefined>
      ];

      const selection = d3.select(ref.current as SVGElement);

      if (!current?.[0]) {
        selection.attr('transform', initialTransform);
        maybeAnimated(
          selection,
          transition
        )(selection => {
          selection.attr('transform', transform);
        });
      } else {
        maybeAnimated(
          selection,
          transition
        )(selection => {
          selection.attr('transform', transform);
        });
      }
    },
    [transform, initialTransform, transition, ts]
  );

  return useMemo(
    () => ({
      scaleX: maxScaleX,
      scaleY: midScaleY,
    }),
    [maxScaleX, midScaleY]
  );
}
