import React, { FC, ReactNode } from 'react';

import { appPaths } from '~/routes/app-paths';
import { TooltipContainer } from '~/shared/components/tooltip-container/TooltipContainer';
import { gaArticleTooltipAction } from '~/shared/utils/ganalytics';

import * as S from './LinkMarkdown.styles';

export interface LinkMarkdownProps {
  title?: string;
  href?: string | undefined;
  children: ReactNode;
}

const overviewRegExp = /^\/overview/;

export const LinkMarkdown: FC<LinkMarkdownProps> = ({
  title,
  href = '',
  children,
}) => {
  const realHref = href.replace(overviewRegExp, appPaths.analyze);

  const gaLabel = (children as string[]).join('; ');

  return title ? (
    <TooltipContainer label={title}>
      <S.MarkdownLink
        rel="noopener noreferrer"
        href={realHref !== 'tooltip' ? realHref : undefined}
        onMouseOver={() => gaArticleTooltipAction('hover', gaLabel)}
        onClick={() => gaArticleTooltipAction('click', gaLabel)}
        target="_blank"
      >
        {children}
      </S.MarkdownLink>
    </TooltipContainer>
  ) : (
    <S.MarkdownLink rel="noopener noreferrer" href={realHref}>
      {children}
    </S.MarkdownLink>
  );
};
