import { Entity } from '@toggle/toggle';
import { useState } from 'react';

import { useUserEntities } from '~/stores/use-user-entities/useUserEntities';

import { useRecentList } from '../use-recent-list/useRecentList';

export type ListType = 'recent' | 'watchlist' | 'portfolio';

const sortAsc = (entities: Entity[]) =>
  [...entities].sort((a, b) => a.ticker.localeCompare(b.ticker));

export const useAssetsLists = () => {
  const [activeListType, setActiveListType] = useState<ListType>('recent');
  const entities = useUserEntities(state => state.entities);
  const { addAssetToRecent, recentList } = useRecentList();

  const lists: Map<ListType, Entity[]> = new Map();

  lists.set('recent', recentList);

  if (entities.watchlist.length) {
    const sortedWatchlist = sortAsc(entities.watchlist);
    lists.set('watchlist', sortedWatchlist);
  }

  if (entities.portfolios.length) {
    const sortedPortfolios = sortAsc(entities.portfolios);
    lists.set('portfolio', sortedPortfolios);
  }

  const availableListTypes = Array.from(lists.keys());
  const assetsList = lists.get(activeListType);

  return {
    addAssetToRecent,
    assetsList,
    activeListType,
    availableListTypes,
    setActiveListType,
  };
};
