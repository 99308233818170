import React, { FC, ReactElement } from 'react';

import * as S from './Table.styles';

export interface TableProps {
  className?: string;
  withBodyRowHover?: boolean;
  TableHeaderContent: ReactElement | ReactElement[];
  TableBodyContent: ReactElement | ReactElement[];
  isHeaderSticky?: boolean;
}

export const Table: FC<TableProps> = ({
  withBodyRowHover = true,
  TableHeaderContent,
  TableBodyContent,
  className,
  isHeaderSticky = false,
}) => {
  return (
    <S.Table
      className={className}
      $withBodyRowHover={withBodyRowHover}
      $isHeaderSticky={isHeaderSticky}
    >
      <thead>{TableHeaderContent}</thead>
      <tbody>{TableBodyContent}</tbody>
    </S.Table>
  );
};
