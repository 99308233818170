import { config } from '~/config';
import { Flags } from '~/stores/use-feature-flags/feature-flags.types';

export const getConfigFlags = () => {
  const { featureFlags } = config.app;

  if (!featureFlags) {
    return undefined;
  }

  return enableFlags(featureFlags);
};

const FEATURE_QUERY_KEY = 'feature';

export const getPathnameFlags = () => {
  if (config.app.isDevEnvironment || config.app.isLocalEnvironment) {
    const params = new URLSearchParams(window.location.search);
    const featureParam = params.get(FEATURE_QUERY_KEY);

    if (!featureParam) {
      return undefined;
    }

    const pathnameFlags = featureParam.split(',');
    return enableFlags(pathnameFlags);
  }

  return undefined;
};

function enableFlags(flags: string[]): Flags {
  return flags.reduce(
    (prevFlags, nextFlag) => ({
      ...prevFlags,
      [nextFlag]: true,
    }),
    {} as Flags
  );
}
