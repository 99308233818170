import * as React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import { config } from '~/config';
import { appPaths, authPaths } from '~/routes/app-paths';

const {
  app: { version },
  urls: { socialMediaImageLink },
} = config;

interface AppMetadataProps {
  path: string;
}

export const getTitleFromPath = (path: string) => {
  if (path.includes(appPaths.myAccount.subscription)) {
    return 'seo:myAccountSubscription.title';
  }
  const titles = {
    [authPaths.login]: 'seo:login.title',
    [authPaths.signUp]: 'seo:signUp.title',
    [authPaths.forgotPassword]: 'seo:forgotPassword.title',
    [appPaths.myToggle]: 'seo:myToggle.title',
    [appPaths.portfolio]: 'seo:portfolio.title',
    [appPaths.aiChat]: 'seo:aiChat.title',
    [appPaths.scenarioCommander]: 'seo:scenarioCommander.title',
    [appPaths.myAccount.portfolio]: 'seo:myAccountPortfolio.title',
    [appPaths.myAccount.security]: 'seo:myAccountSecurity.title',
    [appPaths.myAccount.watchlist]: 'seo:myAccountWatchlist.title',
  };
  return titles[path] || 'seo:default.title';
};

export const AppMetadata = ({ path }: AppMetadataProps) => {
  const { t } = useTranslation('seo');

  const titleKey = getTitleFromPath(path);
  const title = t(titleKey);

  const description = t('seo:default.description');

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="title" content={title} />
      <meta name="description" content={description} />
      <meta name="tog-version" content={version} />
      <meta property="og:title" content={title} />
      <meta property="og:url" content="https://toggle.ai" />
      <meta property="og:type" content="website" />
      <meta property="og:description" content={description} />
      <meta property="og:locale" content="en_GB" />
      <meta property="og:image" content={socialMediaImageLink} />
      <meta property="og:image:secure_url" content={socialMediaImageLink} />
      <meta
        property="og:image:alt"
        content="TOGGLE. Invest rationally, not rashly"
      />
      <meta property="og:image:type" content="image/png" />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="628" />
    </Helmet>
  );
};
