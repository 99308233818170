import { Icon } from '@toggle/design-system';
import { keyboard } from '@toggle/helpers';
import { Entity } from '@toggle/toggle';
import React from 'react';

import { AssetCard } from '../asset-card/AssetCard';
import { ExchangeCard } from '../exchange-card/ExchangeCard';
import * as S from './ListItem.styles';

export interface ListItemProps {
  entity: Entity;
  isActive?: boolean;
  highlighted?: string;
  onRemove?(): void;
  onClick?(): void;
}

export const ListItem = ({
  entity,
  isActive,
  highlighted,
  onRemove,
  onClick,
}: ListItemProps) => {
  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (
      event.key === keyboard.enter.key ||
      event.code === keyboard.space.code
    ) {
      event.preventDefault();
      onRemove?.();
    }
  };

  return (
    <S.ListItemRoot data-testid={`list-item-${entity.id}`} onClick={onClick}>
      <AssetCard entity={entity} highlighted={highlighted} />
      <S.Details>
        <ExchangeCard entity={entity} />
        {isActive && (
          <S.Close
            data-testid="close-button"
            tabIndex={0}
            role="button"
            onClick={onRemove}
            onKeyDown={handleKeyDown}
          >
            <Icon iconName="Close" size={16} />
          </S.Close>
        )}
      </S.Details>
    </S.ListItemRoot>
  );
};
