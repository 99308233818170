import { borderRadius } from '@toggle/design-system';
import styled from 'styled-components';

export const ChipHorizonSelectorRoot = styled.div`
  display: flex;
  ${borderRadius.r2}
  padding: 4px;
  background-color: var(--surface-default);
  gap: 4px;

  button {
    text-transform: uppercase;
  }
`;
