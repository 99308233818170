import { useEffect, useState } from 'react';

import { APICallStatus } from '~/declarations/standard';
import { ExploreMetrics } from '~/declarations/toggle-api-explore.d';
import { fetchExploreMetrics } from '~/shared/services/explore/explore-service';

export interface MetricsBreadcrumbs {
  date: string;
  articles_produced: number;
  dislocations: number;
  snakes: number;
}

const emptyMetricsBreadcrumbs: APICallStatus<MetricsBreadcrumbs> = {
  loading: false,
};

export function useMetricsBreadcrumbs(): APICallStatus<MetricsBreadcrumbs> {
  const [metricsBreadcrumbs, setMetricsBreadcrumbs] = useState<
    APICallStatus<MetricsBreadcrumbs>
  >(emptyMetricsBreadcrumbs);

  useEffect(() => {
    (async () => {
      setMetricsBreadcrumbs({
        loading: true,
      });
      try {
        const metrics = await fetchExploreMetrics();
        if (metrics instanceof Error) {
          throw metrics;
        }
        setMetricsBreadcrumbs({
          loading: false,
          data: convertMetricsToBreadcrumbs(metrics),
        });
      } catch (e) {
        setMetricsBreadcrumbs({
          loading: false,
          error: (e as Error).message,
        });
      }
    })();
  }, []);

  return metricsBreadcrumbs;
}

function convertMetricsToBreadcrumbs(
  metrics: ExploreMetrics
): MetricsBreadcrumbs {
  const {
    date,
    articles_produced,
    snakes,
    fundamental_reports,
    relevance_reports,
  } = metrics;

  return {
    articles_produced,
    snakes,
    dislocations: fundamental_reports + relevance_reports,
    date,
  };
}
