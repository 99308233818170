import { Property } from 'csstype';
import React, { ReactNode, useEffect, useRef, useState } from 'react';

import { TableCell, TableHeader } from '../table';
import { SmartTableCell } from './smart-table-cell/SmartTableCell';
import * as S from './SmartTable.styles';
import { SmartTableCellProps, SmartTableDataItem } from './SmartTable.types';
import {
  TableColumnsEditor,
  TableColumnsEditorTranslations,
} from './table-columns-editor/TableColumnsEditor';

export type StringKeys<T> = Extract<keyof T, string>;

export type SmartTableColumn<T> = {
  [ColumnKey in StringKeys<T>]: {
    key: ColumnKey;
    label: string;
    alignment?: Property.TextAlign;
    disableHiding?: boolean;
    hiddenByDefault?: boolean;
    sticky?: boolean;
    render?: (value: T[ColumnKey], item: T) => ReactNode;
    renderHeader?: () => ReactNode;
    renderCell?: (props: SmartTableCellProps<T>) => ReactNode;
  };
}[StringKeys<T>];

export interface SmartTableProps<T> {
  className?: string;
  withBodyRowHover?: boolean;
  isHeaderSticky?: boolean;
  editableColumns?: boolean;
  columns: SmartTableColumn<T>[];
  data: SmartTableDataItem<T>[];
  alignment?: Property.TextAlign;
  i18nTranslations?: {
    editColumnTooltip: string;
  };
  onActiveColumnsChange?: (newColumns: StringKeys<T>[]) => void;
  renderActionColumn?: (item: SmartTableDataItem<T>) => ReactNode;
  onRowClick?: (item: SmartTableDataItem<T>) => void;
  activeColumnChangeHandler?: (
    activeColumnKeys: StringKeys<T>[],
    name: StringKeys<T>,
    isChecked: boolean
  ) => StringKeys<T>[];
}

export const SmartTable = <T extends {}>({
  withBodyRowHover = true,
  className,
  isHeaderSticky = false,
  editableColumns = false,
  columns,
  data,
  alignment = 'left',
  i18nTranslations,
  onActiveColumnsChange,
  onRowClick,
  renderActionColumn,
  activeColumnChangeHandler,
}: SmartTableProps<T>) => {
  const columnsActiveByDefault = columns
    .filter(c => !c.hiddenByDefault)
    .map(c => c.key);

  const ref = useRef<HTMLTableElement>(null);
  const [hasScrollbar, setHasScrollbar] = useState(false);
  const [activeColumnKeys, setActiveColumnKeys] = useState(
    columnsActiveByDefault
  );

  const handleActiveColumnsChange = (
    name: StringKeys<T>,
    isChecked: boolean
  ) => {
    let keys: StringKeys<T>[];
    if (activeColumnChangeHandler) {
      const stringKeys = activeColumnChangeHandler(
        activeColumnKeys,
        name,
        isChecked
      );
      keys = stringKeys;
    } else {
      keys = isChecked
        ? [...activeColumnKeys, name]
        : activeColumnKeys.filter(key => key !== name);
    }

    setActiveColumnKeys(keys);
    onActiveColumnsChange?.(keys);
  };

  useEffect(() => {
    const table = ref.current as unknown as HTMLTableElement;
    const isScrollable =
      table &&
      table!.parentElement!.clientWidth < table!.parentElement!.scrollWidth;
    setHasScrollbar(isScrollable);
  }, [activeColumnKeys]);

  const activeColumns = columns.filter(column =>
    activeColumnKeys.includes(column.key)
  );

  const tableHead = activeColumns.map(column => {
    const content = column.renderHeader ? column.renderHeader() : column.label;
    const headerAlignement = column.alignment || alignment;
    return column.sticky ? (
      <S.StickyTableHeader
        alignment={headerAlignement}
        key={column.key}
        $displayBorder={hasScrollbar}
      >
        {content}
      </S.StickyTableHeader>
    ) : (
      <TableHeader key={column.key} alignment={headerAlignement}>
        {content}
      </TableHeader>
    );
  });

  return (
    <S.Table
      className={className}
      $withBodyRowHover={withBodyRowHover}
      $isHeaderSticky={isHeaderSticky}
      ref={ref}
    >
      <thead>
        <tr>
          {tableHead}

          {editableColumns && (
            <TableColumnsEditor<T>
              columns={columns}
              activeColumnKeys={activeColumnKeys}
              onActiveColumnToggle={handleActiveColumnsChange}
              i18nTranslations={
                i18nTranslations as TableColumnsEditorTranslations
              }
            />
          )}
          {renderActionColumn && <TableHeader />}
        </tr>
      </thead>
      <tbody>
        {data.map(item => (
          <S.TableRow
            data-testid="table-data-row"
            onClick={() => onRowClick?.(item)}
            key={item.key}
          >
            {activeColumns.map(column => {
              return (
                column.renderCell?.({
                  item,
                  column,
                  hasScrollbar,
                  alignment,
                }) || (
                  <SmartTableCell
                    key={column.key}
                    item={item}
                    column={column}
                    alignment={alignment}
                    hasScrollbar={hasScrollbar}
                  />
                )
              );
            })}
            {editableColumns && (
              <TableCell key="settings" alignment={alignment} empty />
            )}
            {renderActionColumn && (
              <S.ActionTableCell
                key="actions"
                alignment={alignment}
                isNumber={false}
              >
                <div>{renderActionColumn(item)}</div>
              </S.ActionTableCell>
            )}
          </S.TableRow>
        ))}
      </tbody>
    </S.Table>
  );
};

SmartTable.Styled = S;
SmartTable.TableCell = SmartTableCell;
