import { borderRadius, breakpoints, typographyV2 } from '@toggle/design-system';
import styled from 'styled-components';

export const PlanComparisonRoot = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
`;

export const HeaderTitle = styled.p`
  ${typographyV2.titleXL}
  @media ${breakpoints.lg} {
    ${typographyV2.title2XL}
  }
`;

export const RowContainer = styled.div<{ $isStickyHeader?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  ${borderRadius.r3}
  border: 1px solid var(--border-soft);
  background-color: var(--surface-default);
  margin-bottom: 16px;
  ${props =>
    props.$isStickyHeader &&
    `
      background-color: var(--background-default);
      border: none;
      position: sticky;
      top: 0;
      border-radius: 0;
      z-index: 1;
      margin-bottom: 24px;
      padding-top: 20px;
      
  `}

  @media ${breakpoints.lg} {
    border: none;
    border-radius: 0;
    background-color: var(--background-default);
    flex-direction: row;
    align-items: ${props => props.$isStickyHeader && 'center'};
  }
`;

export const RowHeader = styled.p`
  ${typographyV2.titleMd}
  display: flex;
  justify-content: flex-start;
  flex: 1;
  border-radius: 8px;
  ${borderRadius.r2}
  padding: 16px;
  @media ${breakpoints.lg} {
    background-color: var(--surface-default);
    ${typographyV2.titleLg}
  }
`;

export const RowName = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: space-between;
  padding: 16px;
  @media ${breakpoints.lg} {
    max-width: 400px;
    justify-content: flex-start;
    flex-basis: 0;
  }
`;

export const RowNameText = styled.p`
  ${typographyV2.bodyBase}
`;

export const RowGroup = styled.div<{ $isStickyHeader?: boolean }>`
  display: flex;
  justify-content: space-between;
  padding: 16px;
  ${props =>
    props.$isStickyHeader
      ? `
      background-color: var(--background-backdrop);
      margin-top: 20px;
      align-items: flex-start;
      `
      : ` 
      border-top: 1px solid var(--border-soft);
      align-items: center;
      `}
  @media ${breakpoints.lg} {
    border: none;
    min-width: 800px;
    background-color: var(--background-default);
    padding: 0px;
    margin-top: 0px;
  }
`;

export const RowChoice = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  ${typographyV2.bodyBase}
`;
