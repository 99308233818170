import {
  Button,
  deviceConstraints,
  Modal,
  typography,
  typographyV2,
} from '@toggle/design-system';
import styled from 'styled-components';

export const ViewerSection = styled.section`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  flex-grow: 1;
  @media ${deviceConstraints.tablet} {
    width: 100%;
  }
`;

export const StyledModal = styled(Modal)`
  ${Modal.Styled.ModalContainer} {
    display: flex;
    flex-direction: column;
    height: calc(100% - 32px);
    width: calc(100% - 32px);
    left: 16px;
    top: 16px;
    transform: none;

    @media ${deviceConstraints.tablet} {
      width: 600px;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
`;

export const Header = styled.header`
  ${typography.dingo};
  padding-bottom: 16px;
`;

export const Title = styled.h1`
  ${typographyV2.titleLg};
`;

export const Subtext = styled.p`
  ${typography.koala};
  color: var(--base--40);
  margin: 8px 0px;
`;

export const Subtitle = styled.h2`
  ${typography.emu};
  margin: 8px 0px;
`;

export const WhatsNewList = styled.ul`
  && {
    list-style-type: disc;
    margin: 8px 16px;
  }
`;

export const WhatsNewListItem = styled.li`
  ${typography.koala};
`;

export const BoldListText = styled.span`
  ${typography.emu};
  font-weight: 500;
`;

export const ContactText = styled.p`
  ${typography.koala};
  color: var(--base--40);
  padding-top: 16px;

  @media ${deviceConstraints.tablet} {
    text-align: left;
  }
`;

export const AcceptButton = styled(Button)`
  align-self: end;
  margin-left: 8px;
`;
