import { typography } from '@toggle/design-system';
import styled from 'styled-components';

export const List = styled.ul`
  display: flex;
  flex-wrap: nowrap;
  height: 30px;
  align-items: center;

  li {
    display: flex;
    align-items: center;
    a,
    span {
      ${typography.koala};
      color: var(--base--20);
      cursor: pointer;
    }
    span {
      cursor: default;
    }
  }
`;
