import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ZendeskAPI } from 'react-zendesk';

import { config } from '~/config';
import { gaMyAccountClickContactUs } from '~/shared/utils/ganalytics';

import { ContentProps } from '../content.d';
import * as S from './FaqHelperContent.styles';

export function ConnectPortfolioContent({ zendeskOnline }: ContentProps) {
  const { t } = useTranslation('helperWidget');

  const contactUsHandler = () => {
    gaMyAccountClickContactUs('helper_widget');
    zendeskOnline
      ? ZendeskAPI('webWidget', 'open')
      : window.open(config.supportUrl, '_blank');
  };

  return (
    <S.FaqContent>
      <Trans t={t} i18nKey="helperWidget:faq.connectPortfolio.content">
        <S.Link onClick={contactUsHandler} data-testid="contact-link" />
      </Trans>
    </S.FaqContent>
  );
}
