import React from 'react';

import { AnyView } from '~/routes/AppRoutes.d';
import { isChunkedLoadError } from '~/shared/components/error-boundary';
import { Tracking } from '~/shared/services/tracking';
import { reloadAppOnce } from '~/shared/utils/reload-app-once/reloadAppOnce';

interface ComponentLoader {
  default: AnyView;
}
type LazyComponent = () => Promise<ComponentLoader>;

export function componentLoader(
  lazyComponent: LazyComponent,
  reloadKey: string
): Promise<ComponentLoader> {
  return new Promise((resolve, reject) => {
    lazyComponent()
      .then(resolve)
      .catch((error: Error) => {
        try {
          if (isChunkedLoadError(error)) {
            reloadAppOnce(reloadKey);
          }
          reject(error);
        } catch (err) {
          Tracking.captureMessage('Lazy loading failed');
          reject(error);
        }
      });
  });
}

export function lazyComponentLoader(
  lazyComponent: LazyComponent,
  reloadKey: string
): React.LazyExoticComponent<AnyView> {
  return React.lazy(() => componentLoader(lazyComponent, reloadKey));
}
