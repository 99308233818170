import { AccordionProps, Icon } from '@toggle/design-system';
import classNames from 'classnames';
import React, { FC, ReactNode, useEffect, useState } from 'react';

import { STORAGE_ITEMS } from '~/shared/hooks/use-remote-storage/storage-keys';
import { gaHelperClickContent } from '~/shared/utils/ganalytics';
import {
  HighlightedSectionsRefs,
  useHelper,
} from '~/stores/use-helper/useHelper';

import { useHighlightSectionHandler } from '../../hooks/useHighlightSectionHandler';
import * as S from './HelperWidgetAccordion.styles';

export interface HelperWidgetSection {
  id: string;
  title: string;
  gaTitle: string;
  content?:
    | ReactNode
    | ((activateSection: (sectionId: string) => void) => ReactNode);
  canBeChecked?: boolean;
  handleMouse?: boolean;
  separateSection?: boolean;
  hideMarker?: boolean;
}

export interface HelperWidgetAccordionProps {
  sections: HelperWidgetSection[];
  sectionRefs?: HighlightedSectionsRefs;
  setStoreItems: (items: STORAGE_ITEMS) => void;
  storeItems: string[];
  defaultExpandedId?: string;
  sendGaOnExpand?: boolean;
  accordionItemClassName?: string;
  className?: string;
}

export function HelperWidgetAccordion({
  sections,
  sectionRefs = {},
  storeItems,
  setStoreItems,
  defaultExpandedId,
  sendGaOnExpand,
  accordionItemClassName,
  className,
}: HelperWidgetAccordionProps) {
  const [expandedSectionId, setExpandedSectionId] = useState(defaultExpandedId);
  const { hoveredUiSectionId, currentViewGa } = useHelper();
  const [handleMouseEnter, handleMouseLeave] =
    useHighlightSectionHandler(sectionRefs);

  const checkIsSectionActive = (sectionId: string) =>
    storeItems.some(item => item === sectionId);
  const activateSection = (sectionId: string) => {
    sectionId && setStoreItems({ [sectionId]: 'active' });
  };

  useEffect(() => {
    if (expandedSectionId && !checkIsSectionActive(expandedSectionId)) {
      activateSection(expandedSectionId);
    }
  }, [expandedSectionId]);

  const helperSections = sections.map(
    ({
      id,
      title,
      gaTitle,
      content,
      canBeChecked = true,
      handleMouse,
      separateSection,
      hideMarker,
    }) => {
      const isActive = canBeChecked && checkIsSectionActive(id);
      const marker = isActive ? (
        <S.CheckedMarker>
          <Icon
            iconName="Checkmark"
            size={14}
            fillColor="var(--light-green--4)"
          />
        </S.CheckedMarker>
      ) : (
        <S.UncheckedMarker />
      );

      return {
        id,
        title: (
          <>
            {!hideMarker && marker}
            {title}
          </>
        ),
        gaTitle: gaTitle,
        icon: <Icon iconName="ChevronLightDown" size={14} />,
        hideIcon: !content,
        props: {
          onMouseEnter: () => handleMouse && handleMouseEnter(id),
          onMouseLeave: () => handleMouse && handleMouseLeave(id),
        },
        content:
          typeof content === 'function' ? content(activateSection) : content,
        className: classNames(
          {
            hoveredUiSection: hoveredUiSectionId === id,
            emptyAccordionItem: !content,
            separateAccordionItem: separateSection,
          },
          accordionItemClassName
        ),
      };
    }
  );

  return (
    <S.HelperAccordion<FC<AccordionProps<typeof helperSections[0]>>>
      withBackground={false}
      items={helperSections}
      defaultExpandedId={defaultExpandedId}
      onItemClick={(accordionItem, isExpanded) => {
        setExpandedSectionId(accordionItem.id);
        if (sendGaOnExpand && isExpanded) {
          gaHelperClickContent(currentViewGa.current, accordionItem.gaTitle);
        }
      }}
      className={className}
    />
  );
}
