import { typography } from '@toggle/design-system';
import styled from 'styled-components';

import { Table } from '~/shared/components/table/Table';

export const StyledTable = styled(Table)`
  ${Table.Styled.RowCell} {
    min-width: 90px;

    &.markedCell {
      background-color: rgba(var(--primary-color-rgb), 0.05);
      border: 2px solid var(--primary-color);
      border-bottom-color: transparent;
      border-top: none;
    }
  }

  ${Table.Styled.HeaderCell} {
    ${typography.dingo};
    border-bottom: 4px solid var(--primary-color);

    &.markedCell {
      color: var(--base--black);
      border-radius: 8px 8px 0 0;
      background-color: var(--primary-color) !important;
    }
  }
`;
