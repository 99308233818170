import { Button, typography } from '@toggle/design-system';
import styled from 'styled-components';

export const Container = styled.div`
  text-align: center;
  margin: auto;
`;

export const Title = styled.h2`
  ${typography.chimp}
  margin-bottom: 8px;
`;

export const Content = styled.p`
  ${typography.dingo}
`;

export const Image = styled.img`
  width: 100%;
  max-width: 400px;
  margin: 25px auto;
`;

export const ButtonStyled = styled(Button)`
  display: inline-flex;
`;
