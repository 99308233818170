import { select } from 'd3';
import React, {
  MutableRefObject,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
} from 'react';

import { StyledComponent } from '~/common/styled-component';

import { TextWithBgAlign } from './text-with-bg-utils';
import * as S from './TextWithBg.styles';

export interface TextWithBgProps {
  position: TextWithBgAlign;
  className?: string;
  rectClass?: string;
  selected?: boolean;
  translateX: number;
  translateY: number;
  leftBorder?: number;
  rightBorder?: number;
  elref?: MutableRefObject<SVGGElement | null>;
  children?: ReactNode;
}

export const TextWithBg: StyledComponent<TextWithBgProps, typeof S> = ({
  translateX,
  translateY,
  position,
  className,
  children,
  selected = false,
  rightBorder,
  leftBorder,
  rectClass,
  elref,
  ...rest
}) => {
  const textRef = useRef<SVGTextElement>(null);
  const rectRef = useRef<SVGRectElement>(null);
  const gRef = useRef<SVGGElement>();

  const setGRef = useCallback(
    (node: SVGGElement | null) => {
      gRef.current = node ?? undefined;
      if (elref) {
        elref.current = node;
      }
    },
    [elref]
  );

  useEffect(() => {
    const textNode = textRef.current;
    if (textNode?.getBBox) {
      const bBox = textNode.getBBox();
      select(rectRef.current)
        .attr('x', bBox.x - 6)
        .attr('y', bBox.y - 4)
        .attr('width', bBox.width + 12)
        .attr('height', bBox.height + 8);

      let dx = 0;

      if (leftBorder && bBox.width + 12 + (bBox.x - 6) >= translateX) {
        dx = bBox.width + 12 + (bBox.x - 6) + leftBorder;
      }

      if (rightBorder && translateX + bBox.width + 12 >= rightBorder) {
        dx = rightBorder - (translateX + bBox.width + 12);
      }
      select(gRef.current as SVGGElement).attr(
        'transform',
        `translate(${translateX + dx},${translateY})`
      );
    }
  });

  let textParams = {};
  switch (position) {
    case TextWithBgAlign.Bottom:
      textParams = { x: 0, y: 8, dy: '0.71em' };
      break;
    case TextWithBgAlign.BottomLeft:
      textParams = { x: 8, y: 8, dy: '0.71em' };
      break;
    case TextWithBgAlign.Right:
      textParams = { x: 8 };
      break;
    case TextWithBgAlign.Top:
      textParams = { x: 0, y: -8 };
      break;
    case TextWithBgAlign.TopLeft:
      textParams = { x: 8, y: -8 };
      break;
    default:
      textParams = { x: -8 };
      break;
  }

  return (
    <S.Group
      className={className}
      $isSelected={selected}
      ref={setGRef}
      {...rest}
    >
      <S.Rect className={rectClass} ref={rectRef} x="0" />
      <S.Text $position={position} ref={textRef} {...textParams}>
        {children}
      </S.Text>
    </S.Group>
  );
};

TextWithBg.Styled = S;
