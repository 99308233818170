import { Icon } from '@toggle/design-system';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { TagInfo } from '~/declarations/toggle-api';
import { gaArticleLHSPanelAction } from '~/shared/utils/ganalytics';

import * as S from './ActionMenu.styles';
import { ACTIONS } from './ActionMenuData';

export interface ActionMenuProps {
  sharedLink: string;
  title: string;
  tags: TagInfo[];
}

export const ActionMenu = (props: ActionMenuProps) => {
  const { t } = useTranslation('common');

  return (
    <S.ActionMenuList data-testid="action-buttons">
      {ACTIONS.map(action => (
        <S.ActionContainer
          key={action.type}
          data-testid="action-button"
          onClick={() =>
            gaArticleLHSPanelAction(
              t('common:share.label', { context: action.type })
            )
          }
        >
          {action.component ? (
            action.component(props)
          ) : (
            <S.ActionButton
              href={action.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('common:share.label', { context: action.type })}
              {action.icon && <Icon iconName={action.icon} size={20} />}
            </S.ActionButton>
          )}
        </S.ActionContainer>
      ))}
    </S.ActionMenuList>
  );
};
