import React from 'react';
import { useTranslation } from 'react-i18next';

import { config } from '~/config';

import * as S from './ChatDisclaimer.styles';

export const ChatDisclaimer = () => {
  const { t } = useTranslation('common');

  return (
    <S.ChatDisclaimerRoot data-testid="chat-disclaimer">
      <S.ExternalLink
        title={t('chat:disclaimerLinkTitle')}
        href={config.urls.disclaimerGenAi}
        target="_blank"
      >
        {t('chat:disclaimerLink')}
      </S.ExternalLink>
    </S.ChatDisclaimerRoot>
  );
};
