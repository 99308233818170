import { deviceConstraints, Icon } from '@toggle/design-system';
import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  z-index: var(--z-index-app-header);
  top: 0;
  width: 100vw;
  max-height: var(--app-mobile-banner-height);
  padding: 8px 12px;
  display: flex;
  align-items: center;
  transform: scale(1);
  background-color: var(--primary-color);
  border-color: var(--primary-color);

  @media ${deviceConstraints.smartphone} {
    padding: 5px 7px;
  }
`;

export const CloseIcon = styled(Icon).attrs({
  iconName: 'Close',
  fillColor: 'var(--base--100)',
  size: 12,
})`
  width: 14px;
  cursor: pointer;
  margin-right: 5px;
`;

export const AppIcon = styled.img`
  width: 40px;
  height: 40px;
`;

export const AppStoreButton = styled.img`
  height: 36px;
  width: 96px;
`;

export const TextContainer = styled.p`
  @media ${deviceConstraints.smartphone} {
    padding-left: 7px;
    font-size: 14px;
  }
  flex: auto;
  color: var(--base--100);
  padding-left: 10px;
  font-size: 16px;
`;
