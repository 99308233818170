export enum AppRouteSections {
  Feed,
  Explore,
  Portfolio,
  Account,
  MyToggle,
  Chart,
  Analyze,
  Scenario,
  Support,
  Positions,
  GenAI,
  TurboChart,
}

export const authPaths = {
  login: '/login',
  signUp: '/sign-up',
  forgotPassword: '/forgot-password',
  resetPassword: '/reset-password',
};

export const iframePaths = {
  paymentConfirmation: '/payment-confirmation',
  ibConnection: '/ib',
};

export const queryParamPaths = {
  noHeader: '?header=no-header',
};

export const appPaths = {
  base: '/',
  app: '/app',
  questionnaire: '/questionnaire',
  suggestion: '/suggestion',
  explore: '/explore',
  portfolio: '/portfolio',
  myToggle: '/my-toggle',
  myAccount: {
    base: '/my-account',
    portfolio: '/my-account/portfolio',
    subscription: '/my-account/subscription',
    watchlist: '/my-account/watchlist',
    security: '/my-account/security',
    appearance: '/my-account/appearance',
  },
  aiChat: '/app/ai-chat',
  scenarioCommander: '/app/scenario-commander',
  article: '/article',
  upgrade: '/upgrade',
  analyze: '/analyze',
  emailReset: 'emailToReset',
  verifyEmail: '/verify-email',
  error: '/error',
  notFound: '/*',
};

export const accountRoutes = {
  checkout: 'checkout',
  billingInformation: 'billing-information',
  selectPlan: 'plans',
};

export const queryKeys = {
  redirect: 'redirectTo',
  view: 'view',
  limited: 'limited',
  promoCode: 'promo_code',
};

export const publicRoutes = {
  learnCenter: '/investing',
};

export const featurePaths = {
  turboChart: '/app/chart',
};

export const SubscriptionUpgradeRouteParams = {
  BASIC_ONLY_5_STAR_ALLOWED: 1,
  BASIC_NO_INSIGHTS_REMAINING: 2,
  BASIC_INSIGHTS_REMAINING: 4,
  COPILOT_CANNOT_VIEW_ASSET: 5,
  COPILOT_INSIGHTS_REMAINING: 6,
  COPILOT_CUSTOM_CONDITIONS: 8,
  BASIC_LIMIT: 9,
  BASIC_NO_SAVE_FILTER: 12,
};

export const NO_HEADER_PATHS = [
  ...Object.values(iframePaths),
  ...Object.values(authPaths),
];

export const NO_HEADER_PARAMS = [...Object.values(queryParamPaths)];

export const QUESTIONNAIRE_SUGGESTION_PATHS = [
  appPaths.questionnaire,
  appPaths.suggestion,
];

export const NO_IOS_BANNER_PATHS = [
  ...QUESTIONNAIRE_SUGGESTION_PATHS,
  ...NO_HEADER_PATHS,
];
