import styled, { keyframes } from 'styled-components';

const slideDown = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-40px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const AlertTrialEndsRoot = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  animation: ${slideDown} 0.2s ease-in;
  padding: 8px 14px;
`;
