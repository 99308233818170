import { TabPanel } from '@toggle/design-system';
import React, { FC } from 'react';

import { SearchTabsOptions } from '~/stores/use-global-search/useGlobalSearch';

import * as S from './SearchTabs.styles';

interface SearchTab {
  tabKey: SearchTabsOptions;
  tabLabel: string;
  imgNode?: JSX.Element;
}

export interface SearchTabsProps {
  activeTab: SearchTabsOptions;
  tabs: SearchTab[];
  isTouchDevice: boolean;
  onChange: (activeTab: SearchTabsOptions) => void;
  closeSearch: VoidFunction;
}

export const SearchTabs: FC<SearchTabsProps> = ({
  activeTab,
  onChange,
  isTouchDevice,
  tabs,
  closeSearch,
}) => {
  return (
    <S.Nav data-testid="search-tabs">
      <TabPanel<SearchTabsOptions>
        activeTab={activeTab}
        onChange={(_, tabKey) => onChange(tabKey)}
        tabs={tabs}
      />

      {!isTouchDevice && (
        <S.CloseButton
          data-testid="close-button"
          onClick={closeSearch}
          iconName="Close"
          variant="empty"
          size="small"
        />
      )}
    </S.Nav>
  );
};
