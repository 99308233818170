import './index.css';
import './polyfill';
import './i18n';

import { getApplicationContainer } from '@toggle/helpers';
import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import { Tracking } from '~/shared/services/tracking';

import { App } from './App';

Tracking.initialise();

const root = createRoot(getApplicationContainer());

root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);
