import { config } from '~/config';
import { APIFetch } from '~/shared/services/api-fetch';
import { HttpMethods } from '~/shared/services/api-fetch/constants';
import { validateApiFetch } from '~/shared/services/api-fetch/validate-api-fetch/validateApiFetch';
import { Tracking } from '~/shared/services/tracking';

import { CognitiveSearch, cognitiveSearchSchema } from './chat-schema';

const {
  api: { root: apiRoot },
} = config;

export const chatEndpoints = {
  feedback: `${apiRoot}relevance-feedback/v1`,
  archaicSecretary: `${apiRoot}archaic-secretary/v1/stream`,
  orchestrator: `${apiRoot}orchestrator/v1/stream`,
  insightAssitant: `${apiRoot}insight-assistant/v1/stream`,
  jpmSearch: `${apiRoot}jpm-search/v1/search`,
};

export const postFeedback = (request_id: string, reason: string) => {
  try {
    APIFetch(chatEndpoints.feedback, {
      method: HttpMethods.Post,
      body: JSON.stringify({
        request_id,
        reason,
      }),
    });
  } catch (error) {
    Tracking.captureException(error);
  }
};

export const postJpmSearch = (
  question: string,
  intents?: string[],
  entityTags?: string[]
): Promise<CognitiveSearch> =>
  validateApiFetch({
    endpoint: chatEndpoints.jpmSearch,
    schema: cognitiveSearchSchema,
    requestInfo: {
      method: HttpMethods.Post,
      body: JSON.stringify({
        question,
        intents,
        entity_tags: entityTags,
      }),
    },
  });
