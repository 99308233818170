import { Badge, Icon, SvgIconNames } from '@toggle/design-system';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { appPaths } from '~/routes/app-paths';
import {
  gaClickGoToWebsite,
  gaClickLogoutButton,
  gaClickMenuHeader,
} from '~/shared/utils/ganalytics';
import { use2fa } from '~/stores/use-2fa/use2fa';
import { useAuth } from '~/stores/use-auth/useAuth';
import { useSubscription } from '~/stores/use-subscription/useSubscription';
import { useUser } from '~/stores/use-user/useUser';

import { MenuParentItem } from '../default-header/AppHeaderDefault';
import * as S from './HeaderMyAccountDropdown.styles';

export enum MyAccountDropdownGaName {
  Portfolio = 'Portfolio',
  Subscription = 'Subscription',
  Watchlist = 'Watchlist',
  Security = 'Security',
  Appearance = 'Appearance',
}

interface HeaderMyAccountDropdownProps {
  setIsDropdownMenuOpen: (value: boolean) => void;
}

interface MenuDataType {
  link: string;
  name: string;
  iconName: SvgIconNames;
  gaName: MyAccountDropdownGaName;
  badgeText?: string;
}

export const HeaderMyAccountDropdown: FC<HeaderMyAccountDropdownProps> = ({
  setIsDropdownMenuOpen,
}) => {
  const { t } = useTranslation('header');
  const { userSubscription, showSubscriptionPanel, isTrialUser } =
    useSubscription(
      ({ userSubscription, showSubscriptionPanel, isTrialUser }) => ({
        userSubscription,
        showSubscriptionPanel: showSubscriptionPanel(),
        isTrialUser: isTrialUser(),
      })
    );
  const { logout } = useAuth();
  const { user } = useUser(({ user }) => ({
    user,
  }));
  const is2faEnabled = use2fa(state => state.is2faEnabled);

  const userRole = userSubscription.role;
  const isVerified = !!user?.email_verified;
  const factorMessage = !is2faEnabled
    ? t('header:secondaryMenu.2faNotEnabledPill')
    : undefined;

  const menuData: MenuDataType[] = [
    {
      link: appPaths.myAccount.portfolio,
      name: t('header:secondaryMenu.portfolio'),
      iconName: 'BriefcaseLight',
      gaName: MyAccountDropdownGaName.Portfolio,
    },
    ...(showSubscriptionPanel
      ? ([
          {
            link: appPaths.myAccount.subscription,
            name: t('header:secondaryMenu.subscription'),
            iconName: 'CreditCardLight',
            gaName: MyAccountDropdownGaName.Subscription,
            badgeText: isTrialUser
              ? t('header:secondaryMenu.trialEnding')
              : undefined,
          },
        ] as MenuDataType[])
      : []),
    {
      link: appPaths.myAccount.watchlist,
      name: t('header:secondaryMenu.watchlist'),
      iconName: 'Watchlist',
      gaName: MyAccountDropdownGaName.Watchlist,
    },
    {
      link: appPaths.myAccount.security,
      name: t('header:secondaryMenu.security'),
      iconName: 'LockLight',
      gaName: MyAccountDropdownGaName.Security,
      badgeText: !isVerified
        ? t('header:secondaryMenu.verifyEmail')
        : factorMessage,
    },
    {
      link: appPaths.myAccount.appearance,
      name: t('header:secondaryMenu.appearance'),
      iconName: 'ShowPassword',
      gaName: MyAccountDropdownGaName.Appearance,
    },
  ];

  const handleItemClick = (item: MenuDataType, badgeText?: string) => {
    gaClickMenuHeader(item.gaName, MenuParentItem.MyAccount, badgeText);
    setIsDropdownMenuOpen(false);
  };

  const handleGoToWebsiteClick = () => {
    gaClickGoToWebsite();
    setIsDropdownMenuOpen(false);
  };

  const handleLogoutClick = () => {
    gaClickLogoutButton();
    logout();
  };

  return (
    <S.DropdownMenu data-testid="header-my-account-dropdown-menu">
      <S.ProfileSection>
        <S.ProfileImage
          src="/app/assets/core/astronaut-profile-pic.png"
          alt={t('header:secondaryMenu.astronautIconAlt')}
        />
        <S.ProfileDetails>
          <S.ProfileName>{user?.first_name}</S.ProfileName>
          {userRole && (
            <S.ProfileSubscription data-testid="plan-name">
              {t(`header:secondaryMenu.plan.${userRole}`)}
            </S.ProfileSubscription>
          )}
        </S.ProfileDetails>
      </S.ProfileSection>
      <S.DividerLine />
      <S.DropdownList>
        {menuData.map((item, index) => (
          <li key={index}>
            <S.DropdownListItem
              as={Link}
              data-testid="internal-link"
              to={item.link}
              onClick={() => handleItemClick(item, item.badgeText)}
            >
              <S.CenteredIcon iconName={item.iconName} size={20} />
              {item.name}
              {item.badgeText && (
                <S.BadgeContainer>
                  <Badge
                    size="small"
                    label={item.badgeText}
                    color="warning"
                    badgeStyle="outline"
                  />
                </S.BadgeContainer>
              )}
            </S.DropdownListItem>
          </li>
        ))}
        <S.DividerLine />
        <S.DropdownListItem
          data-testid="go-to-website"
          as="a"
          href={appPaths.base}
          onClick={handleGoToWebsiteClick}
        >
          {t('header:secondaryMenu.goToWebsite')}
        </S.DropdownListItem>
        <S.DividerLine />
        <S.DropdownListItem
          data-testid="logout-btn"
          onClick={handleLogoutClick}
        >
          <Icon iconName="SignOut" size={20} />
          {t('header:secondaryMenu.logout')}
        </S.DropdownListItem>
      </S.DropdownList>
    </S.DropdownMenu>
  );
};
