import React, { createRef } from 'react';
import { useTranslation } from 'react-i18next';

import { EXPLORE_SECTION_IDS } from '~/views/helper/HelperConfig';

import {
  HelperWidgetAccordion,
  HelperWidgetSection,
} from '../../partials/accordion/HelperWidgetAccordion';
import { HelperContentProps } from '../content';
import { BookmarkContent } from './BookmarkContent';
import { CustomScreensContent } from './CustomScreensContent';
import { ExploreIntro } from './ExploreIntro';
import { FiltersContent } from './FiltersContent';
import { HeaderContent } from './HeaderContent';
import { InsightCardContent } from './InsightCardContent';
import { TopScreensContent } from './TopScreensContent';

export const exploreSectionsRefs = {
  [EXPLORE_SECTION_IDS.EXPLORE_INSIGHTS_STATS_CHECK_ID]:
    createRef<HTMLDivElement>(),
  [EXPLORE_SECTION_IDS.EXPLORE_TOP_SCREENS_CHECK_ID]:
    createRef<HTMLDivElement>(),
  [EXPLORE_SECTION_IDS.EXPLORE_FILTERS_CHECK_ID]: createRef<HTMLDivElement>(),
  [EXPLORE_SECTION_IDS.EXPLORE_INSIGHT_CARD_CHECK_ID]:
    createRef<HTMLDivElement>(),
  [EXPLORE_SECTION_IDS.EXPLORE_BOOKMARK_CHECK_ID]: createRef<HTMLDivElement>(),
  [EXPLORE_SECTION_IDS.EXPLORE_SAVED_FILTERS_CHECK_ID]:
    createRef<HTMLDivElement>(),
};

export const ExploreHelperContent = (props: HelperContentProps) => {
  const { t } = useTranslation('helperWidget');

  const sections: HelperWidgetSection[] = [
    {
      id: EXPLORE_SECTION_IDS.EXPLORE_INTRO_ID,
      title: t('helperWidget:explore.content.sectionsTitles.intro'),
      gaTitle: 'TOGGLE Insight Intro',
      content: activateSection => (
        <ExploreIntro
          onVideoClick={() =>
            activateSection(EXPLORE_SECTION_IDS.EXPLORE_INTRO_ID)
          }
        />
      ),
      separateSection: true,
      hideMarker: true,
    },
    {
      id: EXPLORE_SECTION_IDS.EXPLORE_MASTERCLASS_ID,
      title: t('helperWidget:explore.content.sectionsTitles.masterExplore'),
      gaTitle: 'Master Explore',
      hideMarker: true,
    },
    {
      id: EXPLORE_SECTION_IDS.EXPLORE_TOP_SCREENS_CHECK_ID,
      title: t('helperWidget:explore.content.sectionsTitles.topScreens'),
      gaTitle: 'Top Screens',
      content: <TopScreensContent />,
      handleMouse: true,
    },
    {
      id: EXPLORE_SECTION_IDS.EXPLORE_FILTERS_CHECK_ID,
      title: t('helperWidget:explore.content.sectionsTitles.filters'),
      gaTitle: 'Filters',
      content: <FiltersContent />,
      handleMouse: true,
    },
    {
      id: EXPLORE_SECTION_IDS.EXPLORE_INSIGHT_CARD_CHECK_ID,
      title: t('helperWidget:explore.content.sectionsTitles.insightCard'),
      gaTitle: 'Insight Card',
      content: <InsightCardContent />,
      handleMouse: true,
    },
    {
      id: EXPLORE_SECTION_IDS.EXPLORE_INSIGHTS_STATS_CHECK_ID,
      title: t('helperWidget:explore.content.sectionsTitles.header'),
      gaTitle: 'Insight Stats',
      content: <HeaderContent />,
      handleMouse: true,
    },
    {
      id: EXPLORE_SECTION_IDS.EXPLORE_BOOKMARK_CHECK_ID,
      title: t('helperWidget:explore.content.sectionsTitles.bookmark'),
      gaTitle: 'Bookmark',
      content: <BookmarkContent />,
      handleMouse: true,
    },
    {
      id: EXPLORE_SECTION_IDS.EXPLORE_SAVED_FILTERS_CHECK_ID,
      title: t('helperWidget:explore.content.sectionsTitles.customScreens'),
      gaTitle: 'Custom Screens',
      content: <CustomScreensContent />,
      handleMouse: true,
    },
  ];

  return (
    <HelperWidgetAccordion
      sections={sections}
      sectionRefs={exploreSectionsRefs}
      sendGaOnExpand
      {...props}
    />
  );
};
