import React from 'react';

import * as S from './Progress.styles';

export const Progress = () => {
  return (
    <S.ProgressRoot data-testid="progress">
      <S.Dot />
      <S.Dot />
      <S.Dot />
    </S.ProgressRoot>
  );
};
