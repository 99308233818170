import { SvgIconNames } from '@toggle/design-system';
import React, { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { CARD_TYPES } from '~/views/analyze/overview-constants';

import * as S from './CardHeader.styles';

interface CardConfig {
  name: string;
  icon?: ReactElement;
  icon_secondary?: SvgIconNames;
  icon_modification?: SvgIconNames;
}

export interface CardHeaderProps {
  type: CARD_TYPES;
  config: CardConfig;
  bookmarkTooltip?: string;
  insightId?: string;
  withHover?: boolean;
  showInfo?: boolean;
  bookmarked?: boolean;
  isBookmarkLocked?: boolean;
  showBookmark?: boolean;
  showBookmarkTooltip?: boolean;
  withBookmarkAnimation?: boolean;
  showChevron?: boolean;
  onChevronBtnClick?: () => void;
  onBookmarkBtnClick?: () => void;
}

export const CardHeader: FC<CardHeaderProps> = ({
  type,
  config,
  withHover = true,
  showInfo = false,
  bookmarked = false,
  showBookmark = true,
  isBookmarkLocked = false,
  withBookmarkAnimation = false,
  insightId,
  showChevron = true,
  showBookmarkTooltip,
  bookmarkTooltip,
  onChevronBtnClick,
  onBookmarkBtnClick,
}) => {
  const { t } = useTranslation('analyze');

  return (
    <S.InsightCardHeaderWrapper iconType={type}>
      <S.InsightCardHeader data-testid="card-header">
        <S.InsightTitleWrapper>
          {config?.icon}
          <S.InsightCardTitle>{config.name}</S.InsightCardTitle>
        </S.InsightTitleWrapper>
        <S.ChevronWrapper
          showInfo={showInfo}
          withHover={withHover}
          onClick={onChevronBtnClick}
          data-testid="chevron-button"
          role="button"
        >
          {showChevron && (
            <S.ChevronWithTextWrapper>
              <S.ChevronDown
                iconName="ChevronBoldDown"
                size={14}
                fillColor="var(--base--100)"
              />
              <S.HiddenText>
                {showInfo
                  ? t('analyze:insights.cards.hide')
                  : t('analyze:insights.cards.show')}
              </S.HiddenText>
            </S.ChevronWithTextWrapper>
          )}
        </S.ChevronWrapper>
      </S.InsightCardHeader>
      <S.BookmarkContainer>
        {showBookmark && (
          <S.StyledBookmarkIcon
            key={insightId}
            tooltipContent={bookmarkTooltip}
            onBookmark={onBookmarkBtnClick}
            withTooltipAnimation={withBookmarkAnimation}
            isTooltipOpen={showBookmarkTooltip}
            isBookmarked={bookmarked}
            isLocked={isBookmarkLocked}
            bookmarkSize={20}
            activeClassName="activeCardBookmarkBtn"
          />
        )}
      </S.BookmarkContainer>
    </S.InsightCardHeaderWrapper>
  );
};
