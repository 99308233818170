import React, { FC } from 'react';

import * as S from './TradeSearchLoader.styles';

interface TradeSearchLoaderProps {
  text: string;
}

export const TradeSearchLoader: FC<TradeSearchLoaderProps> = ({ text }) => (
  <S.LoaderContainer data-testid="trade-search-loader">
    <S.LoaderText>{text}</S.LoaderText>
    <S.LoaderBar>
      <S.LoaderLine />
    </S.LoaderBar>
  </S.LoaderContainer>
);
