import { OptionalU } from '~/declarations/standard';

import { APIStructuredResponseErrorItem } from './APIFetch';

type APIFetchErrorArgsMessage = OptionalU<string>;
type APIFetchErrorArgsData = OptionalU<APIStructuredResponseErrorItem[]>;

type APIFetchErrorArgs =
  | []
  | [APIFetchErrorArgsMessage]
  | [APIFetchErrorArgsMessage, APIFetchErrorArgsData];

export class APIFetchError extends Error {
  constructor(...args: APIFetchErrorArgs) {
    const [message, apiFetchErrorData] = args;
    super(message);

    this.name = 'APIFetchError';

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, APIFetchError);
    }

    // Custom debugging information
    this.data = apiFetchErrorData;
  }

  data: OptionalU<APIStructuredResponseErrorItem[]>;
}

export class HttpError extends Error {
  constructor(public status: number, public statusText: string) {
    super(statusText);
  }
}
