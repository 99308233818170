import { config } from '~/config';
import { Fetch } from '~/shared/services/fetch/Fetch';

import { HttpMethods } from '../api-fetch/constants';

export interface ZendeskTicket {
  subject: string;
  commentBody: string;
  requester: {
    email: string;
    name: string;
  };
}

export const sendTicket = ({
  subject,
  commentBody,
  requester,
}: ZendeskTicket) => {
  const requestBody = {
    request: {
      subject,
      comment: {
        body: commentBody,
      },
      requester,
    },
  };

  return Fetch(config.zendeskSubdomain, {
    method: HttpMethods.Post,
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(requestBody),
  });
};
