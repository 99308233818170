import { Alert, AlertVariant } from '@toggle/design-system';
import React, { FC, ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  useDismissOnce,
  UseDismissOnceProps,
} from '~/shared/hooks/use-dismiss-once/useDismissOnce';

export interface AlertSubscriptionEndedProps {
  isDowngradeDueToPaymentIssues: boolean;
  variant: AlertVariant;
  buttonLabel: ReactNode;
  dismissOptions: UseDismissOnceProps;
  onButtonClick: () => void;
  shouldDismiss?: boolean;
}

export const AlertSubscriptionEnded: FC<AlertSubscriptionEndedProps> = ({
  variant,
  buttonLabel,
  dismissOptions,
  isDowngradeDueToPaymentIssues,
  shouldDismiss = false,
  onButtonClick,
}) => {
  const { t } = useTranslation('subscription');

  const { hasKey, handleDismiss } = useDismissOnce(dismissOptions);
  const [showSubscriptionEnded, setShowSubscriptionEnded] = useState(
    !hasKey && isDowngradeDueToPaymentIssues
  );

  const onClose = () => {
    if (shouldDismiss) {
      handleDismiss();
    }
    setShowSubscriptionEnded(false);
  };

  const onClick = () => {
    onClose();
    onButtonClick();
  };

  return showSubscriptionEnded ? (
    <Alert
      data-testid={`subscription-has-ended-${variant}-alert`}
      variant={variant}
      title={t('subscription:alerts.paymentMethodSubscriptionEnded.title')}
      message={t('subscription:alerts.paymentMethodSubscriptionEnded.message')}
      primaryBtn={{
        label: buttonLabel,
        onClick,
      }}
      onClose={onClose}
    />
  ) : null;
};
