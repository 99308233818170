import {
  PRICE_HOVER_CUSTOM_EVENT,
  PriceHover,
  PriceHoverEvent,
  SeriesType,
  TimeSeriesItem,
} from '@toggle/chart';
import { SnakeMeta } from '@toggle/toggle';
import React, { RefObject, useEffect, useState } from 'react';

import { MappedEntity } from '~/shared/hooks/use-entities/useEntities';
import {
  getPriceChange,
  isFX,
} from '~/views/analyze/utils/asset-box/asset-box-helpers';

import * as S from '../legend-live-price/LegendLivePrice.styles';
import { OHLCPrice } from '../ohlc-price/OHLCPrice';

export interface LastClosePriceProps {
  entity: MappedEntity;
  snakeMeta: SnakeMeta;
  legendColor: string;
  isPrimary: boolean;
  canvasRef: RefObject<HTMLCanvasElement>;
  seriesType?: SeriesType;
  priceFormatter: (price: number) => string;
  lastTSPrice: TimeSeriesItem;
}

export const LastClosePrice = ({
  entity,
  snakeMeta,
  legendColor,
  isPrimary,
  canvasRef,
  seriesType,
  priceFormatter,
  lastTSPrice,
}: LastClosePriceProps) => {
  const [hoveredPrice, setHoveredPrice] = useState<PriceHover>();
  const isOHLCSeries =
    seriesType && ['bars', 'candlestick'].includes(seriesType);
  const isOHLCPrice = isOHLCSeries && isPrimary;

  useEffect(() => {
    const canvasElement = canvasRef.current;
    if (!canvasElement) {
      return undefined;
    }

    const onPriceHover = (event: Event) => {
      const priceHover = (event as PriceHoverEvent).detail;
      if (priceHover.entitySnake === entity.default_snake) {
        setHoveredPrice(priceHover);
      }
    };
    canvasElement.addEventListener(PRICE_HOVER_CUSTOM_EVENT, onPriceHover);

    return () => {
      canvasElement.removeEventListener(PRICE_HOVER_CUSTOM_EVENT, onPriceHover);
    };
  }, [canvasRef.current, entity]);

  const lastClosePrice =
    hoveredPrice?.currentPrice?.close ?? snakeMeta.last_value;
  const beforeLastPrice =
    hoveredPrice?.previousPrice?.close ?? snakeMeta.before_last_value;

  const { priceChange } = getPriceChange({
    lastPrice: beforeLastPrice,
    newPrice: lastClosePrice ?? 0,
    isCurrency: isFX(entity.sub_class),
  });

  if (isOHLCPrice) {
    return (
      <OHLCPrice
        price={hoveredPrice?.currentPrice ?? lastTSPrice}
        priceFormatter={priceFormatter}
      />
    );
  }

  return (
    <S.PriceContainer
      $color={legendColor}
      $isPrimary={isPrimary}
      data-testid="last-close-price"
    >
      <p>{lastClosePrice ? priceFormatter(lastClosePrice) : lastClosePrice}</p>
      <p>{snakeMeta.currency.toUpperCase()}</p>
      <p>{priceChange.change}</p>
      <p>({priceChange.proportionChange})</p>
    </S.PriceContainer>
  );
};
