import classNames from 'classnames';
import React, {
  HTMLAttributes,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react';

import { Icon } from '~/components/icon/Icon';
import { SvgIconNames } from '~/design-tokens/iconography/SvgIcons';

import * as S from './Accordion.styles';

export interface AccordionItem {
  id?: string;
  titleIcon?: SvgIconNames;
  title: ReactNode;
  content?: ReactNode;
  icon?: JSX.Element;
  hideIcon?: boolean;
  className?: string;
  props?: HTMLAttributes<HTMLDivElement>;
}

export interface AccordionProps<T = AccordionItem>
  extends HTMLAttributes<HTMLDivElement> {
  withBackground?: boolean;
  variant?: 'primary' | 'secondary';
  items: T[];
  onItemClick?: (item: T, isExpanded: boolean) => void;
  collapseAccordionsOnClick?: boolean;
  className?: string;
  itemClassName?: string;
  defaultExpandedId?: string;
}

export const Accordion = <T extends AccordionItem>({
  withBackground = true,
  items,
  onItemClick,
  collapseAccordionsOnClick = true,
  className,
  itemClassName,
  defaultExpandedId,
  variant = 'primary',
  ...rest
}: AccordionProps<T>) => {
  const [expandedItemsIdx, setExpandedItemsIdx] = useState<Set<number>>(
    () => new Set()
  );
  const itemsContentHeights = useRef(new Map());

  const handleClick = (accordion: T, idx: number) => {
    if (!accordion.content) {
      return;
    }
    const newSet: Set<number> = collapseAccordionsOnClick
      ? new Set()
      : new Set(expandedItemsIdx);
    expandedItemsIdx.has(idx) ? newSet.delete(idx) : newSet.add(idx);
    setExpandedItemsIdx(newSet);
    onItemClick?.(accordion, !expandedItemsIdx.has(idx));
  };

  useEffect(() => {
    setExpandedItemsIdx(new Set());
  }, [items.length]);

  useEffect(() => {
    setExpandedItemsIdx(
      new Set(
        defaultExpandedId
          ? [items.findIndex(item => item.id === defaultExpandedId)]
          : []
      )
    );
  }, []);

  const itemsWithIds = items.map((item, idx) => ({
    ...item,
    id: item.id ?? (performance.now() + '').replace('.', '') + idx,
  }));

  return (
    <S.Accordion
      withBackground={withBackground}
      variant={variant}
      className={className}
      data-testid="accordion"
      {...rest}
    >
      {itemsWithIds.map((accordionItem, idx) => (
        <S.AccordionItem
          key={idx}
          data-testid="accordion-item"
          className={classNames(
            expandedItemsIdx.has(idx) && 'accordion-expanded',
            itemClassName,
            accordionItem.className
          )}
          hideIcon={!!accordionItem.hideIcon}
          {...accordionItem.props}
        >
          <S.TitleContainer
            onClick={() => handleClick(accordionItem, idx)}
            aria-controls={accordionItem.id}
            role="button"
            data-testid="accordion-item-button"
            isExpandable={!!accordionItem.content}
          >
            <S.TitleContent>
              {(accordionItem.titleIcon || variant === 'secondary') && (
                <Icon
                  iconName={accordionItem.titleIcon ?? 'StarEmpty'}
                  fillColor="var(--icon-primary)"
                  size={18}
                />
              )}
              {accordionItem.title}
            </S.TitleContent>

            {accordionItem.icon ?? (
              <S.IconStyled
                iconName="ChevronLightRight"
                fillColor="var(--icon-primary)"
                size={16}
                $withDefaultIcon={!accordionItem.icon}
                $expanded={expandedItemsIdx.has(idx)}
              />
            )}
          </S.TitleContainer>
          {!!accordionItem.content && (
            <S.TransitionWrapper
              expanded={expandedItemsIdx.has(idx)}
              contentHeight={itemsContentHeights.current.get(idx)}
              data-testid="transition-wrapper"
            >
              <S.Content
                id={accordionItem.id}
                ref={node =>
                  itemsContentHeights.current.set(idx, node?.clientHeight ?? 0)
                }
              >
                {accordionItem.content}
              </S.Content>
            </S.TransitionWrapper>
          )}
        </S.AccordionItem>
      ))}
    </S.Accordion>
  );
};

Accordion.Styled = S;
