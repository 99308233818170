import { Button, Icon } from '@toggle/design-system';
import React from 'react';
import { useTranslation } from 'react-i18next';

import * as S from './ErrorMessage.styles';

export interface ErrorMessageProps {
  handleRetry?: () => void;
}

export const ErrorMessage = ({ handleRetry }: ErrorMessageProps) => {
  const { t } = useTranslation(['scenarioCommander']);
  return (
    <S.Container data-testid="error-message">
      <Icon
        iconName="ExclamationTriangle"
        size={60}
        viewBox="0 0 60 60"
        fillColor={'none'}
      />
      <S.Title>{t('error.title')}</S.Title>
      <S.Description>{t('error.description')}</S.Description>
      {handleRetry && (
        <Button
          data-testid="retry-button"
          label={t('error.button')}
          onClick={handleRetry}
        />
      )}
    </S.Container>
  );
};
