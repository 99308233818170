export type MethodGroupingValues =
  | 'group_fundamental'
  | 'group_technical'
  | 'group_macro';
interface MethodGrouping {
  [key: string]: MethodGroupingValues;
}

export const METHOD_SUB_CLS_GROUPING: MethodGrouping = {
  valuation: 'group_fundamental',
  entry_point: 'group_technical',
  positioning: 'group_technical',
  momentum: 'group_technical',
  leading_indicator: 'group_fundamental',
  market_data: 'group_technical',
  volume_analysis: 'group_technical',
  equity_growth_gauge: 'group_fundamental',
  equity_consensus_trend: 'group_fundamental',
  economic_growth_gauge: 'group_macro',
  economic_inflation_gauge: 'group_macro',
  financial_conditions: 'group_macro',
  volatility: 'group_technical',
  main_market_price: 'group_technical',
  main_yield: 'group_technical',
  group_technical_analysis: 'group_technical',
  geopolitical: 'group_macro',
  sentiment: 'group_technical',
  seasonality: 'group_technical',
  equity_yield: 'group_fundamental',
  event: 'group_fundamental',
  technical_analysis: 'group_technical',
  on_chain_metrics: 'group_fundamental',
};
