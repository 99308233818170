import { Optional, POJSObject } from '~/declarations/standard';
import { Article } from '~/declarations/toggle-api';
import { ENDPOINT_URLS } from '~/global/toggle-api';
import { APIFetch } from '~/shared/services/api-fetch/APIFetch';
import { getFingerprint } from '~/shared/utils/fingerprint';

function buildArticleEndpoint(articleID: Optional<string>) {
  return `${ENDPOINT_URLS.ARTICLE_BASE_V2}/${articleID}`;
}

function getAuthHeader(isAuthenticated = false): POJSObject<string> {
  return isAuthenticated
    ? {}
    : {
        ['Authorization-User-Fingerprint']: getFingerprint(),
      };
}

export interface ArticleLimits {
  limit: number;
  consumed: number;
  duration: string;
}

interface ArticleResponse extends ArticleLimits {
  article: Article;
}

export function fetchArticleFromAPI(
  articleID: Optional<string>,
  isAuthenticated = false
): Promise<ArticleResponse | Error> {
  const apiFetchOptions = isAuthenticated
    ? {}
    : {
        requiresAuth: false,
      };

  return APIFetch<ArticleResponse>(
    buildArticleEndpoint(articleID),
    {
      headers: getAuthHeader(isAuthenticated),
    },
    apiFetchOptions
  );
}

export const fetchArticlesLimits = async (signal?: AbortSignal) =>
  APIFetch<ArticleLimits>(`${ENDPOINT_URLS.ARTICLES_LIMITS}`, {
    signal,
  });
