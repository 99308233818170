import { parseResponse } from './FetchDecorators';

type FetchParams = Parameters<typeof fetch>;

export const Fetch = async <T>(...params: FetchParams): Promise<T | Error> => {
  const [url, options] = params;
  try {
    const response = await fetch(url, options);
    return await parseResponse(response);
  } catch (e) {
    return e as Error;
  }
};
