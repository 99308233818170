import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import * as S from './EmptyInsights.styles';

interface EmptyInsightsProps {
  isNewsEmpty?: boolean;
}

export const EmptyInsights: FC<EmptyInsightsProps> = ({
  isNewsEmpty = false,
}) => {
  const { t } = useTranslation('common');
  return (
    <S.EmptyInsightsText
      data-testid={isNewsEmpty ? 'empty-news' : 'empty-insights'}
    >
      {isNewsEmpty ? t('chat:emptyNews') : t('chat:emptyInsights')}
    </S.EmptyInsightsText>
  );
};
