import { Property } from 'csstype';
import React, { ReactNode, TableHTMLAttributes, useState } from 'react';

import * as S from './TableHeader.styles';

export enum SortDirection {
  asc,
  dsc,
}

export interface TableHeaderProps
  extends TableHTMLAttributes<HTMLTableCellElement> {
  alignment?: Property.TextAlign;
  isSortable?: boolean;
  isSorted?: boolean;
  isHoverEnabled?: boolean;
  fixed?: boolean;
  headerClick?: (sortDirection: SortDirection) => void;
  children?: ReactNode;
}

export const TableHeader = ({
  alignment = 'end',
  isSortable,
  isSorted,
  isHoverEnabled = true,
  fixed,
  headerClick,
  children,
  ...rest
}: TableHeaderProps) => {
  const [sortDirection, setSortDirection] = useState(SortDirection.dsc);
  const handleClick = () => {
    if (!isSortable) {
      return;
    }
    const changeInDirection =
      sortDirection === SortDirection.dsc
        ? SortDirection.asc
        : SortDirection.dsc;
    headerClick?.(changeInDirection);
    setSortDirection(changeInDirection);
  };

  const barSortDirection =
    sortDirection === SortDirection.dsc ? 'BarsSortDown' : 'BarsSortUp';

  return (
    <S.TableHeader
      data-testid="table-header"
      onClick={headerClick && handleClick}
      $alignment={alignment}
      $isSorted={isSorted}
      $isSortable={isSortable}
      $fixed={fixed}
      $isHoverEnabled={isHoverEnabled}
      {...rest}
    >
      <S.Label $alignment={alignment}>
        {children}
        {isSortable && (
          <S.FilterIcon
            size={12}
            iconName={isSorted ? barSortDirection : 'Sort'}
            $isSorted={isSorted}
          />
        )}
      </S.Label>
    </S.TableHeader>
  );
};
