import { DateValue, differenceInTime } from '@toggle/helpers';
import * as React from 'react';

import { useNavigator } from '~/stores/use-navigator/useNavigator';

interface DateComponentProp {
  date: DateValue;
  className?: string;
}

export function FormattedRelativeDate({
  date,
  className = '',
}: DateComponentProp) {
  const navigatorCountry = useNavigator(state => state.country);

  const datetime = new Date(date).toISOString();
  return (
    <time
      className={className}
      dateTime={datetime}
      data-testid="formatted-relative-date"
    >
      {differenceInTime({
        country: navigatorCountry,
        start: new Date(),
        end: date,
      })}
    </time>
  );
}
