import { PreliminaryPrice, SnakeMeta } from '@toggle/toggle';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { formatPriceDisplay } from '~/shared/components/price-display-view/helpers/price-display-helpers';
import { MappedEntity } from '~/shared/hooks/use-entities/useEntities';
import { useLivePrice } from '~/shared/hooks/use-live-price/useLivePrice';
import { isFX } from '~/views/analyze/utils/asset-box/asset-box-helpers';

import {
  LegendPriceIcons,
  PriceIcon,
} from '../legend-price-icons/LegendPriceIcons';
import * as S from './LegendLivePrice.styles';

export interface LegendLivePriceProps {
  entity: MappedEntity;
  snakeMeta: SnakeMeta;
  preliminaryPrice?: PreliminaryPrice;
  isChartHovered: boolean;
  legendColor: string;
  isPrimary: boolean;
  priceFormatter: (price: number) => string;
}

export const LegendLivePrice = ({
  entity,
  snakeMeta,
  preliminaryPrice,
  isChartHovered,
  legendColor,
  isPrimary,
  priceFormatter,
}: LegendLivePriceProps) => {
  const { t } = useTranslation('chart');
  const priceEntities = [
    {
      entity,
      lastKnownPrice: snakeMeta.last_value,
      priceBefore: snakeMeta.before_last_value,
    },
  ];

  const livePriceInfo = useLivePrice(priceEntities)[entity.ticker];

  if (!livePriceInfo) {
    return null;
  }

  const price = formatPriceDisplay({
    exchange: entity.exchange.code,
    livePriceInfo,
    preliminaryPrice,
    meta: snakeMeta,
    isCurrency: isFX(entity.sub_class),
    isHovered: isChartHovered,
    t,
  });

  const iconMapping: PriceIcon[] = [
    {
      iconName: 'SunRise',
      tooltipLabel: t('chart:preMarketTooltip'),
      color: 'var(--icon-primary)',
      shouldShow: price.primary.marketHours.isPreMarket,
    },
    {
      iconName: 'Sun',
      tooltipLabel: t('chart:marketOpenTooltip'),
      color: 'var(--icon-success)',
      shouldShow: price.primary.marketHours.isMarketHours,
    },
    {
      iconName: 'Moon',
      tooltipLabel: t('chart:marketClosedTooltip'),
      color: 'var(--icon-soft)',
      shouldShow: price.primary.marketHours.isAfterMarket,
    },
  ];

  return (
    <S.LegendPriceRoot data-testid="legend-live-price">
      {isPrimary &&
        iconMapping.map((icon, i) =>
          icon.shouldShow ? (
            <LegendPriceIcons
              key={i}
              iconInfo={icon}
              price={price}
              isPrimary={isPrimary}
            />
          ) : null
        )}

      {price.primary.price !== undefined && (
        <S.PriceContainer $color={legendColor} $isPrimary={isPrimary}>
          {priceFormatter(price.primary.price)}
          {price.primary.priceChange && (
            <>
              <span>{price.primary.priceChange.change}</span>
              <span>({price.primary.priceChange.proportionChange})</span>
            </>
          )}
        </S.PriceContainer>
      )}
    </S.LegendPriceRoot>
  );
};
