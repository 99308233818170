import { deviceConstraints, typography } from '@toggle/design-system';
import styled from 'styled-components';

import { Markdown } from '~/shared/components/markdown/Markdown';
import { Table } from '~/shared/components/table/Table';

import { HighlightedText } from '../highlighted-text/HighlightedText';
import { AllEpisodeDatesPanel } from './all-episode-dates-panel';

export const StyledTable = styled(Table)`
  ${Table.Styled.RowCell} {
    ${typography.dingo};
  }

  ${Table.Styled.HeaderCell} {
    ${typography.emu};
    border-bottom: 4px solid var(--primary-color);
    justify-content: flex-start !important;
  }
`;

export const FundamentalsContainer = styled.section`
  display: flex;
  flex-direction: column;
  padding-top: 32px;

  @media ${deviceConstraints.desktop} {
    flex-direction: row;
  }
`;

export const FundamentalsName = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 25%;
  padding-left: 16px;

  @media ${deviceConstraints.tablet} {
    padding-left: 0;
  }
`;

export const FundamentalsNameTitle = styled.span`
  ${typography.emu}
  color: var(--base--70);
  text-transform: uppercase;
`;

export const FundamentalsNameSubtitle = styled.span`
  ${typography.coyote}
`;

export const FundamentalsContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 75%;
`;

export const Description = styled(Markdown)`
  margin-top: 24px;
  padding-left: 16px;
  ${typography.dingo}

  @media ${deviceConstraints.tablet} {
    padding-left: 0;
  }
`;

export const EpisodeDatesPanel = styled(AllEpisodeDatesPanel)`
  margin-bottom: 24px;
`;

export const HitRatioNote = styled(Markdown)`
  ${typography.dingo}
  margin: 24px 16px 0px;

  @media ${deviceConstraints.tablet} {
    margin-left: 0px;
    margin-right: 0px;
  }
`;

export const HighlightedTextStyled = styled(HighlightedText)`
  color: var(--primary-color);
`;
