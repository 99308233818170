import debounce from 'lodash/debounce';
import { RefObject, useEffect } from 'react';

import { allMenuItems } from './article-sidebar-menu-items';

const SCROLL_ACTION_INTERVAL = 100;

const rootContainer = window;

export function useSpyScrollOnSections(
  setActive: (id: string) => void,
  isSectionCollapsed: (idx: number) => boolean,
  sectionsRefs?: Array<RefObject<HTMLHeadingElement>>
) {
  useEffect(() => {
    if (sectionsRefs) {
      const activateSectionInView = () => {
        const sectionInViewId = getSectionIdInView(
          sectionsRefs,
          isSectionCollapsed
        );

        if (sectionInViewId) {
          setActive(sectionInViewId);
        }
      };

      const lazyActivateSectionInView = debounce(() => {
        activateSectionInView();
      }, SCROLL_ACTION_INTERVAL);

      rootContainer.addEventListener('scroll', lazyActivateSectionInView);
      return () =>
        rootContainer.removeEventListener('scroll', lazyActivateSectionInView);
    } else {
      return undefined;
    }
  }, []);
}

function getSectionIdInView(
  sectionRefs: Array<RefObject<HTMLHeadingElement>>,
  isSectionCollapsed: (idx: number) => boolean
) {
  const sectionInView = sectionRefs.find((sectionRef, idx) => {
    const isCollapsed = isSectionCollapsed(idx);
    return !isCollapsed && isInView(sectionRef);
  });

  if (sectionInView) {
    return allMenuItems[sectionRefs.indexOf(sectionInView)].translateKey;
  }

  return null;
}

const isInView = (section: RefObject<HTMLHeadingElement>) => {
  if (!section?.current) {
    return false;
  }
  const rect = section.current.getBoundingClientRect();
  return rect.top >= 0 && rect.bottom <= window.innerHeight;
};
