import { prevWorkingDayUTC, startOfDayUTC } from '@toggle/helpers';
import { ArticleHorizon } from '@toggle/toggle';
import { bisector } from 'd3';
import { add } from 'date-fns';
import { TFunction } from 'i18next';

import {
  AssetClassNames,
  AssetSubClassNames,
  Class,
} from '~/api/entities/entity-constants';
import { PrimaryMethod } from '~/api/entities/entity-schema';
import { TSDatum } from '~/api/timeseries/timeseries-schema';
import { OptionalU, POJSObject } from '~/declarations/standard';
import { ArticleSummary, NewsItem } from '~/declarations/toggle-api.d';
import {
  EntityTagTypes,
  ExploreArticleDirection,
} from '~/declarations/toggle-api-enums';
import {
  METHOD_SUB_CLS_GROUPING,
  MethodGroupingValues,
} from '~/global/method_sub_cls_grouping';
import { appPaths } from '~/routes/app-paths';
import { POISignal, TSPoint } from '~/shared/components/chart/Chart';
import { ALERT_DESCRIPTION_DATA_MAP } from '~/shared/components/signal-summary/AlertSignalDescriptionByType';
import { MappedEntity } from '~/shared/hooks/use-entities';
import { EntityAnalyticsReportType } from '~/shared/services/overview-widget/overview-widget-service';

import { MIN_ARTICLES_STARS } from '../overview-constants';
import { getPriceChange, PriceStatus } from './asset-box/asset-box-helpers';

export enum InsightStatus {
  ACTIVE,
  WORKED,
  FAILED,
}

interface HorizonMap {
  (lastDate: Date, horizonValue: number): Date;
}

export interface MappedArticle {
  id: string;
  articleId: string | null;
  stars: number;
  direction: ExploreArticleDirection;
  lastValue: number;
  defaultMethod: string;
  medianReturn: number;
  horizon: ArticleHorizon;
  displayDate: Date;
  cls: EntityTagTypes;
  asset_cls: AssetClassNames;
  sub_cls: AssetSubClassNames;
  analysisType: MethodGroupingValues;
  eco: string;
  index: number;
  signals: POISignal[];
  lastPrice: {
    date: Date;
    index: number;
    value: number;
  };
  isActive: boolean;
  pnlText: string;
  pnlStatus: PriceStatus;
  status: InsightStatus;
  name: string;
  bookmarked: boolean;
  hitRate: string;
  occurrencesNumber: number;
  new: boolean;
  locked: boolean;
  insight_card: string;
}

enum HorizonRangeFormat {
  DAYS = 'D',
  WEEKS = 'W',
  MONTHS = 'M',
}

const HORIZON_MAP: POJSObject<HorizonMap> = {
  [HorizonRangeFormat.DAYS]: (lastDate: Date, horizonValue: number) =>
    add(lastDate, {
      days: horizonValue,
    }),
  [HorizonRangeFormat.WEEKS]: (lastDate: Date, horizonValue: number) =>
    add(lastDate, {
      weeks: horizonValue,
    }),
  [HorizonRangeFormat.MONTHS]: (lastDate: Date, horizonValue: number) =>
    add(lastDate, {
      months: horizonValue,
    }),
};

interface MapArticleArgs {
  entity: MappedEntity;
  article: ArticleSummary;
  ts: TSPoint<string, number>[];
  t: TFunction;
}

const isArticleActive = (
  horizonPoints: Array<TSPoint<string, number> | undefined>,
  lastHorizonDate: Date
) => {
  const [highPoint, lowPoint] = horizonPoints;
  return lastHorizonDate > new Date() && !highPoint && !lowPoint;
};

interface ArticleHorizonArgs {
  ts: TSPoint<string, number>[];
  lastHorizonDate: Date;
  articleIndex: number;
  signals: POISignal[];
}

export const getArticleHorizonPoints = ({
  ts,
  lastHorizonDate,
  articleIndex,
  signals,
}: ArticleHorizonArgs) => {
  if (!signals.length) {
    return [undefined, undefined];
  }

  const horizonIndex = bisector<TSPoint<string, number>, Date>(
    d => new Date(d.index)
  ).left(ts, lastHorizonDate);
  const horizonData = ts.slice(articleIndex, horizonIndex);
  const [high, low] = signals;
  const highPoint = horizonData.find(p => p.value >= high.value);
  const lowPoint = horizonData.find(p => p.value <= low.value);
  return [highPoint, lowPoint];
};

export const mapArticle = ({
  entity,
  article,
  ts,
  t,
}: MapArticleArgs): MappedArticle => {
  const correspondingAlertData =
    ALERT_DESCRIPTION_DATA_MAP[article.type_name as EntityAnalyticsReportType];
  const displayDate = getArticleDisplayDate(article);
  const index = bisector<TSPoint<string, number>, Date>(
    d => new Date(d.index)
  ).left(ts, displayDate);
  const signals = getArticleSignals(article, ts[index], entity);
  const lastHorizonDate = lastWorkingDayInHorizon(
    displayDate,
    article.horizon
  )!;
  const lastPrice = getLastArticlePrice(lastHorizonDate, ts);
  const horizonPoints = getArticleHorizonPoints({
    ts,
    lastHorizonDate,
    articleIndex: index,
    signals,
  });
  const isActive = isArticleActive(horizonPoints, lastHorizonDate);

  const { text, priceStatus, status } = isActive
    ? getActiveArticlePnl({
        lastPrice: lastPrice.value,
        targetPrice: signals[0]?.value,
        stopPrice: signals[1]?.value,
        articleDirection: article.direction,
        articleLastValue: article.last_value,
        isPrice: article.default_method === PrimaryMethod.Price,
        t,
      })
    : getArticlePnlWithinHorizon({
        lastPrice: lastPrice.value,
        articleDirection: article.direction,
        articleLastValue: ts[index]?.value || article.last_value,
        isPrice: article.default_method === PrimaryMethod.Price,
        horizonPoints,
        signals,
      });

  return {
    id: article.id,
    articleId: article.article_id,
    bookmarked: article.bookmarked,
    stars: article.stars,
    direction: article.direction,
    lastValue: article.last_value,
    defaultMethod: article.default_method,
    medianReturn: article.median_return,
    horizon: article.horizon,
    cls: article.cls,
    asset_cls: article.asset_cls,
    sub_cls: article.sub_cls,
    eco: article.eco,
    analysisType: METHOD_SUB_CLS_GROUPING[article.type_name],
    displayDate,
    index,
    signals,
    lastPrice,
    isActive,
    status,
    pnlText: text,
    pnlStatus: priceStatus,
    hitRate: article.hit_rate_out_of_sample
      ? `${article.hit_rate_out_of_sample.toFixed(2)}%`
      : '',
    occurrencesNumber: article.num_episodes,
    new: article.new,
    locked: !article.article_id,
    insight_card: article.insight_card,
    name: correspondingAlertData
      ? correspondingAlertData.alertShortName
      : article.type,
  };
};

export const mapArticleWithLivePrice = (
  livePrice: number,
  article: MappedArticle,
  t: TFunction
) => {
  const { text, status, priceStatus } = getActiveArticlePnl({
    lastPrice: livePrice,
    targetPrice: article.signals[0]?.value,
    stopPrice: article.signals[1]?.value,
    articleDirection: article.direction,
    articleLastValue: article.lastValue,
    isPrice: article.defaultMethod === PrimaryMethod.Price,
    t,
  });

  return { ...article, status, pnlText: text, pnlStatus: priceStatus };
};

const getHorizonDateUnitsAndValue = (articleHorizon: ArticleHorizon) => {
  const parsedDateParamsGrouped = articleHorizon.match(
    /horizon_(?<dateVal>\d)(?<dateUnits>\D)/
  );

  const dateVal = parsedDateParamsGrouped?.groups?.dateVal;
  const dateUnits = parsedDateParamsGrouped?.groups?.dateUnits;

  return {
    dateVal: dateVal && Number(dateVal),
    dateUnits: dateUnits?.toUpperCase(),
  };
};

const lastWorkingDayInHorizon = (start: Date, horizon: ArticleHorizon) => {
  const { dateVal, dateUnits } = getHorizonDateUnitsAndValue(horizon);
  if (!dateVal || !dateUnits) {
    return null;
  }
  const endOfHorizon = HORIZON_MAP[dateUnits](start, dateVal);
  return prevWorkingDayUTC(endOfHorizon);
};

interface ExpiredPnlArgs {
  lastPrice: number;
  articleDirection: ExploreArticleDirection;
  articleLastValue: number;
  isPrice: boolean;
  horizonPoints: Array<TSPoint<string, number> | undefined>;
  signals: POISignal[];
}

export const getArticlePnlWithinHorizon = ({
  lastPrice,
  articleDirection,
  articleLastValue,
  isPrice,
  horizonPoints,
  signals,
}: ExpiredPnlArgs) => {
  let status: InsightStatus;
  let horizonLastArticleValue = lastPrice;
  const isBullish = articleDirection === ExploreArticleDirection.Bullish;
  const [highPoint, lowPoint] = horizonPoints;
  const [highSignal, lowSignal] = signals;

  if (highPoint && lowPoint) {
    if (isBullish) {
      const isHighLess = highPoint.index < lowPoint.index;
      status = isHighLess ? InsightStatus.WORKED : InsightStatus.FAILED;
      horizonLastArticleValue = isHighLess ? highSignal.value : lowSignal.value;
    } else {
      const isLowLess = lowPoint.index < highPoint.index;
      status = isLowLess ? InsightStatus.WORKED : InsightStatus.FAILED;
      horizonLastArticleValue = isLowLess ? lowSignal.value : highSignal.value;
    }
  } else if (highPoint && lowPoint === undefined) {
    status = isBullish ? InsightStatus.WORKED : InsightStatus.FAILED;
    horizonLastArticleValue = highSignal.value;
  } else if (lowPoint && highPoint === undefined) {
    status = isBullish ? InsightStatus.FAILED : InsightStatus.WORKED;
    horizonLastArticleValue = lowSignal.value;
  }

  //it never crossed either high/low within the horizon
  else {
    if (isBullish) {
      status =
        horizonLastArticleValue > articleLastValue
          ? InsightStatus.WORKED
          : InsightStatus.FAILED;
    } else {
      status =
        horizonLastArticleValue < articleLastValue
          ? InsightStatus.WORKED
          : InsightStatus.FAILED;
    }
  }

  const change = getPriceChange({
    lastPrice: articleLastValue,
    newPrice: horizonLastArticleValue,
    isPrice,
  });

  return {
    priceStatus: change.priceChange.status,
    text: change.priceChange.proportionChange,
    status,
  };
};

interface ActivePnlArgs {
  lastPrice: number;
  targetPrice: OptionalU<number>;
  stopPrice: OptionalU<number>;
  articleDirection: ExploreArticleDirection;
  articleLastValue: number;
  isPrice: boolean;
  t: TFunction;
}

export const getActiveArticlePnl = ({
  lastPrice,
  stopPrice,
  targetPrice,
  articleDirection,
  articleLastValue,
  isPrice,
  t,
}: ActivePnlArgs) => {
  let status = InsightStatus.ACTIVE,
    priceStatus = PriceStatus.default,
    text = t('myToggle:unmapped.N/A'),
    change: ReturnType<typeof getPriceChange>;

  if (
    targetPrice === undefined ||
    stopPrice === undefined ||
    articleDirection === ExploreArticleDirection.Locked
  ) {
    return {
      status,
      text,
      priceStatus,
    };
  }

  if (articleDirection === ExploreArticleDirection.Bullish) {
    if (lastPrice < stopPrice) {
      status = InsightStatus.FAILED;

      change = getPriceChange({
        lastPrice: articleLastValue,
        newPrice: stopPrice,
        isPrice,
      });
    } else if (lastPrice > targetPrice) {
      status = InsightStatus.WORKED;

      change = getPriceChange({
        lastPrice: articleLastValue,
        newPrice: targetPrice,
        isPrice,
      });
    } else {
      change = getPriceChange({
        lastPrice,
        newPrice: targetPrice,
        isPrice,
      });
    }
  } else {
    if (lastPrice > targetPrice) {
      status = InsightStatus.FAILED;

      change = getPriceChange({
        lastPrice: articleLastValue,
        newPrice: targetPrice,
        isPrice,
      });
    } else if (lastPrice < stopPrice) {
      status = InsightStatus.WORKED;

      change = getPriceChange({
        lastPrice: articleLastValue,
        newPrice: stopPrice,
        isPrice,
      });
    } else {
      change = getPriceChange({
        lastPrice,
        newPrice: targetPrice,
        isPrice,
      });
    }
  }

  text = change.priceChange.proportionChange;
  priceStatus = change.priceChange.status;
  return { text, status, priceStatus };
};

export const getArticleSignals = (
  {
    signal_high,
    signal_low,
    direction,
    last_value_reference_date,
    last_value,
  }: ArticleSummary,
  tsPoint: TSPoint<string, number>,
  entity: OptionalU<MappedEntity>
): POISignal[] => {
  if (
    signal_high === undefined ||
    signal_low === undefined ||
    direction === ExploreArticleDirection.Locked
  ) {
    return [];
  }

  const isBullish = direction === ExploreArticleDirection.Bullish;
  let signalHigh = signal_high,
    signalLow = signal_low;

  if (
    last_value_reference_date &&
    (entity?.class === Class.ClassEtf || entity?.class === Class.ClassStock)
  ) {
    const priceOnChart = tsPoint?.value;

    if (priceOnChart !== undefined) {
      const ratio = priceOnChart / last_value;
      signalHigh *= ratio;
      signalLow *= ratio;
    }
  }

  return [
    {
      value: signalHigh,
      type: isBullish
        ? ExploreArticleDirection.Bullish
        : ExploreArticleDirection.Bearish,
    },
    {
      value: signalLow,
      type: isBullish
        ? ExploreArticleDirection.Bearish
        : ExploreArticleDirection.Bullish,
    },
  ];
};

export const getArticleDisplayDate = ({
  created_on,
  last_value_reference_date,
}: ArticleSummary) => {
  if (last_value_reference_date) {
    const strippedReferenceDate = new Date(last_value_reference_date);
    strippedReferenceDate.setUTCHours(0, 0, 0, 0);
    return strippedReferenceDate;
  }

  const strippedCreationDate = new Date(created_on);
  strippedCreationDate.setUTCHours(0, 0, 0, 0);

  const poiDate = new Date(strippedCreationDate);
  poiDate.setDate(
    strippedCreationDate.getUTCDate() -
      (strippedCreationDate.getUTCDate() === 1 ? 3 : 1)
  );

  return poiDate;
};

export function openArticleInNewTab(id: string) {
  const articlePath = `${appPaths.article}/${id}`;
  window.open(articlePath, '_blank', 'noopener=yes,noreferrer=yes');
}

export const getLastArticlePrice = (
  horizonEndDate: Date | null,
  ts: Array<TSPoint<string, number>>
) => {
  const lastTSPoint = ts[ts.length - 1];
  const lastTSDate = new Date(lastTSPoint.index);

  if (!horizonEndDate || horizonEndDate > lastTSDate) {
    return {
      value: lastTSPoint.value,
      date: new Date(lastTSPoint.index),
      index: ts.length - 1,
    };
  }

  const horizonEndIndex = bisector<TSPoint<string, number>, Date>(
    d => new Date(d.index)
  ).left(ts, horizonEndDate);
  const data = ts[horizonEndIndex];

  return {
    date: new Date(data.index),
    index: horizonEndIndex,
    value: data.value,
  };
};

export const articleStarsFilter = (a: ArticleSummary) =>
  a.stars >= MIN_ARTICLES_STARS;

export const filterInDomain = <T extends { index: number }>(
  domain: [number, number],
  array: Array<T>
) => {
  const indexMin = Math.round(domain[0]);
  const indexMax = Math.round(domain[1]);

  return array.filter(a => a.index >= indexMin && a.index <= indexMax);
};

export const mapNews = (n: NewsItem, ts: TSDatum[], lastSnackTime: number) => {
  const startOfTheDay = startOfDayUTC(new Date(n.publication_date)).getTime();
  const pointIndex = ts.findIndex(
    p => new Date(p.index).getTime() >= startOfTheDay
  );
  const index =
    pointIndex === -1 && lastSnackTime <= startOfTheDay
      ? ts.length
      : pointIndex;

  return {
    ...n,
    index,
  };
};
