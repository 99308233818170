import { borderRadius, typographyV2 } from '@toggle/design-system';
import styled from 'styled-components';

export const BlockHorizonSelectorRoot = styled.div`
  display: flex;
  padding: 4px;
  ${borderRadius.r2}
  border: 1px solid var(--border-soft);
  gap: 4px;
`;

export const ButtonTile = styled.div<{
  $isActive: boolean;
  $isDisabled: boolean;
}>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4px;
  color: var(--text-default);
  ${borderRadius.r1}
  ${props =>
    props.$isActive &&
    `
      background-color: var(--surface-default);
    `}

    ${props =>
    props.$isDisabled &&
    `
      border-color: var(--border-disabled);
      pointer-events: none;
    `}
  &:hover {
    background-color: var(--surface-hover);
    cursor: pointer;
  }
`;

export const TileLabel = styled.p<{
  $color?: string;
  $isDisabled: boolean;
}>`
  ${typographyV2.bodySm}
  color: ${props =>
    props.$isDisabled ? 'var(--text-disabled)' : props.$color};
`;
