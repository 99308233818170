import JSCookie, { CookieAttributes } from 'js-cookie';

export const getCookie = (key: string) => JSCookie.get(key);

export const deleteCookie = (key: string) => JSCookie.remove(key);

export const setCookie = (
  key: string,
  value: string = key,
  additionalParams?: CookieAttributes
) => JSCookie.set(key, value, { expires: 1, ...additionalParams });
