import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import * as S from './FaqHelperContent.styles';

export function NoInsightsContent() {
  const { t } = useTranslation('helperWidget');

  return (
    <S.FaqContent>
      <Trans i18nKey="helperWidget:faq.noInsights.content" t={t} />
    </S.FaqContent>
  );
}
