import { ExploreHelperContent } from '~/views/helper/helper-widget/content/explore/ExploreHelperContent';
import { FaqHelperContent } from '~/views/helper/helper-widget/content/faq/FaqHelperContent';
import { GlossaryHelperContent } from '~/views/helper/helper-widget/content/glossary/GlossaryHelperContent';
import { HomeHelperContent } from '~/views/helper/helper-widget/content/home/HomeHelperContent';
import { InsightHelperContent } from '~/views/helper/helper-widget/content/insight/InsightHelperContent';
import { MyToggleHelperContent } from '~/views/helper/helper-widget/content/my-toggle/MyToggleHelperContent';
import { OnboardingHelperContent } from '~/views/helper/helper-widget/content/onboard/OnboardingHelperContent';
import { ScenarioHelperContent } from '~/views/helper/helper-widget/content/scenario/ScenarioHelperContent';
import {
  ANALYZE_ALL_CHECKED,
  ANALYZE_AUTO_EXPANDABLE_IDS,
  EXPLORE_ALL_CHECKED,
  EXPLORE_AUTO_EXPANDABLE_IDS,
  HELPER_VIEW_IDS,
  HelperViewConfigsType,
  INSIGHT_ALL_CHECKED,
  INSIGHT_AUTO_EXPANDABLE_IDS,
  MY_TOGGLE_ALL_CHECKED,
  MY_TOGGLE_AUTO_EXPANDABLE_IDS,
  ONBOARDING_ALL_CHECKED,
  ONBOARDING_AUTO_EXPANDABLE_IDS,
  SCENARIO_ALL_CHECKED,
} from '~/views/helper/HelperConfig';

import { AnalyzeHelperContent } from './helper-widget/content/overview/AnalyzeHelperContent';

export const helperViewConfigs: HelperViewConfigsType = {
  [HELPER_VIEW_IDS.HOME]: {
    translationKey: 'home',
    gaName: 'Hi',
    Component: HomeHelperContent,
  },

  [HELPER_VIEW_IDS.ONBOARDING]: {
    translationKey: 'onboarding',
    gaName: 'Onboarding',
    breadcrumbs: [
      { text: 'home', linkTo: HELPER_VIEW_IDS.HOME },
      { text: 'onboarding' },
    ],
    Component: OnboardingHelperContent,
    autoExpandableItemsIds: ONBOARDING_AUTO_EXPANDABLE_IDS,
    allChecks: ONBOARDING_ALL_CHECKED,
    icon: 'SignPost',
  },
  [HELPER_VIEW_IDS.ANALYZE]: {
    translationKey: 'analyze',
    gaName: 'Asset Overview',
    breadcrumbs: [
      { text: 'home', linkTo: HELPER_VIEW_IDS.HOME },
      { text: 'assetOverview' },
    ],
    Component: AnalyzeHelperContent,
    autoExpandableItemsIds: ANALYZE_AUTO_EXPANDABLE_IDS,
    allChecks: ANALYZE_ALL_CHECKED,
    icon: 'Binoculars',
  },

  [HELPER_VIEW_IDS.SCENARIO]: {
    translationKey: 'scenario',
    gaName: 'Scenario',
    breadcrumbs: [
      { text: 'home', linkTo: HELPER_VIEW_IDS.HOME },
      { text: 'scenario' },
    ],
    Component: ScenarioHelperContent,
    allChecks: SCENARIO_ALL_CHECKED,
    proOnly: true,
    icon: 'Vial',
  },

  [HELPER_VIEW_IDS.EXPLORE]: {
    translationKey: 'explore',
    gaName: 'Explore',
    breadcrumbs: [
      { text: 'home', linkTo: HELPER_VIEW_IDS.HOME },
      { text: 'explore' },
    ],
    Component: ExploreHelperContent,
    autoExpandableItemsIds: EXPLORE_AUTO_EXPANDABLE_IDS,
    allChecks: EXPLORE_ALL_CHECKED,
    icon: 'Lightbulb',
  },

  [HELPER_VIEW_IDS.MYTOGGLE]: {
    translationKey: 'myToggle',
    gaName: 'My TOGGLE',
    breadcrumbs: [
      { text: 'home', linkTo: HELPER_VIEW_IDS.HOME },
      { text: 'myToggle' },
    ],
    Component: MyToggleHelperContent,
    autoExpandableItemsIds: MY_TOGGLE_AUTO_EXPANDABLE_IDS,
    allChecks: MY_TOGGLE_ALL_CHECKED,
    icon: 'ToggleOff',
  },

  [HELPER_VIEW_IDS.INSIGHT]: {
    translationKey: 'insight',
    gaName: 'TOGGLE Insight',
    breadcrumbs: [
      { text: 'home', linkTo: HELPER_VIEW_IDS.HOME },
      { text: 'insight' },
    ],
    wrapperHelperClassName: 'helper-article-wrapper',
    autoExpandableItemsIds: INSIGHT_AUTO_EXPANDABLE_IDS,
    Component: InsightHelperContent,
    allChecks: INSIGHT_ALL_CHECKED,
    icon: 'BookOpenReader',
  },

  [HELPER_VIEW_IDS.GLOSSARY]: {
    translationKey: 'glossary',
    gaName: 'Glossary',
    breadcrumbs: [
      { text: 'home', linkTo: HELPER_VIEW_IDS.HOME },
      { text: 'glossary' },
    ],
    Component: GlossaryHelperContent,
    icon: 'Book',
  },

  [HELPER_VIEW_IDS.SUPPORT]: {
    translationKey: 'home',
    gaName: 'Hi',
    Component: HomeHelperContent,
    icon: 'Comment',
  },

  [HELPER_VIEW_IDS.FAQ]: {
    translationKey: 'faq',
    gaName: 'FAQs',
    breadcrumbs: [
      { text: 'home', linkTo: HELPER_VIEW_IDS.HOME },
      { text: 'faq' },
    ],
    Component: FaqHelperContent,
    icon: 'Question',
  },
};
