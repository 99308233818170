import { typographyV2 } from '@toggle/design-system';
import styled from 'styled-components';

export const PastEpisodesRoot = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Title = styled.p`
  ${typographyV2.titleSm};
  color: var(--text-soft);
  margin-bottom: 8px;
`;

export const Description = styled.p`
  ${typographyV2.bodyBase};
`;
