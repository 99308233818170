import React, { FC } from 'react';

import { gaClickMenuHeader } from '~/shared/utils/ganalytics';

import { HeaderMenuItems } from '../../config/get-menu-data/getMenuData';
import { MenuParentItem } from '../default-header/AppHeaderDefault';
import * as S from './MainMenu.styles';

export interface AppHeaderMenuProps {
  items: HeaderMenuItems[];
}

export const MainMenu: FC<AppHeaderMenuProps> = ({ items }) => (
  <S.MainMenuNav>
    <S.MenuContainer data-testid="main-menu-container">
      {items.map((itemData, index) => (
        <S.MenuItem
          key={index}
          data-testid="menu-item"
          onClick={() => {
            gaClickMenuHeader(itemData.gaName, MenuParentItem.MainMenu);
            itemData.onClick?.();
          }}
        >
          {itemData.link ? (
            <S.MenuLink data-testid="active-item" to={itemData.link}>
              <span>{itemData.label}</span>
            </S.MenuLink>
          ) : (
            <S.MenuButton>{itemData.label}</S.MenuButton>
          )}
        </S.MenuItem>
      ))}
    </S.MenuContainer>
  </S.MainMenuNav>
);
