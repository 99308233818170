import { z } from 'zod';

export type PlaceOrderResponse = z.infer<typeof placeOrderSchema>;

export type PlaceOrderSchemaConformation = z.infer<
  typeof placeOrderSchemaConformation
>;

export type PlaceOrderSchemaSuccess = z.infer<typeof placeOrderSchemaSuccess>;

export type PreviewResponse = z.infer<typeof previewResponseSchema>;

export type BuyingPowerResponse = z.infer<typeof buyingPowerResponseSchema>;

export type ReplyOrderResponse = z.infer<typeof replyOrderPayloadSchema>;

const placeOrderSchemaSuccess = z.object({
  order_id: z.string(),
  message: z.string().optional(),
});

const placeOrderSchemaConformation = z.object({
  order_id: z.string(),
  message: z.string(),
  confirmation_id: z.string(),
  confirmation_messages: z.array(z.string()),
});

export const placeOrderSchema = z.union([
  placeOrderSchemaSuccess,
  placeOrderSchemaConformation,
]);

export const previewResponseSchema = z.object({
  est_commission: z.number(),
  commission_currency: z.string(),
  initial_margin: z.number().optional(),
  initial_margin_before: z.number().optional(),
  margin_currency: z.string(),
  equity_with_loan: z.number().optional(),
  maintenance_margin: z.number().optional(),
  maintenance_margin_before: z.number().optional(),
  error_message: z.string().nullable(),
});

export const buyingPowerResponseSchema = z.object({
  currency: z.string(),
  buying_power: z.number(),
});

export const replyOrderPayloadSchema = z.object({ message: z.string() });

export type TradeSearchSecurity = z.infer<typeof tradeSearchSecuritySchema>;

export type DisplayRuleStep = z.infer<typeof displayRuleStep>;

const displayRuleStep = z.object({
  decimal_digits: z.number(),
  minimum: z.number(),
});

export type DisplayRule = z.infer<typeof displayRule>;

const displayRule = z.object({
  display_rule_steps: z.array(displayRuleStep),
});

export const tradeSearchSecuritySchema = z.object({
  id: z.string(),
  name: z.string(),
  ticker: z.string(),
  exchange: z.string(),
  currency: z.string(),
  display_rule: displayRule,
});

export type TradeSearchResponse = z.infer<typeof tradeSearchSchema>;

export const tradeSearchSchema = z.object({
  securities: z.array(tradeSearchSecuritySchema),
});
