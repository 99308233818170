import { typographyV2 } from '@toggle/design-system';
import { NumericalCellValueStyle } from '@toggle/helpers';
import styled from 'styled-components';

export const RiskReturnContainer = styled.section`
  border: solid 1px var(--border-soft);
  background-color: var(--surface-default);
  padding: 16px;
  border-radius: 8px;
`;

export const RiskReturnTitle = styled.h3`
  ${typographyV2.titleBase};
  color: var(--text-default);
  padding-bottom: 10px;
`;

export const RiskReturnText = styled.h3<{
  $numericalStyle: NumericalCellValueStyle;
}>`
  ${typographyV2.bodySm};
  color: var(--text-soft);
  display: flex;
  justify-content: space-between;
  padding-bottom: 8px;
  span {
    ${typographyV2.bodyBase};
    color: ${({ $numericalStyle }) => {
      const isPositive =
        $numericalStyle === NumericalCellValueStyle.Positive
          ? 'var(--viz-bullish)'
          : 'var(--viz-bearish)';
      return $numericalStyle === NumericalCellValueStyle.Neutral
        ? 'var(--text-default)'
        : isPositive;
    }};
  }
`;
