import styled, { css } from 'styled-components';

import { Icon } from '~/components/icon/Icon';
import { borderRadius } from '~/design-tokens/border-radius/borderRadius';
import { typographyV2 } from '~/design-tokens/typography/Typography.styles';

interface AccordionProps {
  withBackground?: boolean;
  variant?: 'primary' | 'secondary';
}

export const TitleContainer = styled.div<{ isExpandable: boolean }>`
  color: var(--text-primary);
  ${typographyV2.bodyBase}
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  margin: 0;
  gap: 4px;

  &:hover {
    cursor: ${({ isExpandable }) => (isExpandable ? 'pointer' : 'default')};
  }
`;

export const TitleContent = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  & svg {
    margin-bottom: 3px;
  }
`;

export const IconStyled = styled(Icon)<{
  $withDefaultIcon: boolean;
  $expanded: boolean;
}>`
  flex-shrink: 0;
  transition: transform 0.4s cubic-bezier(0.75, 0.02, 0.5, 1);
  display: block;
  max-width: 100%;
  ${({ $expanded, $withDefaultIcon }) =>
    $withDefaultIcon ? `transform: rotate(${$expanded ? -90 : 90}deg)` : ''}
`;

export const Content = styled.div`
  padding: 0 10px 8px;
  ${typographyV2.bodyBase}
  overflow: hidden;
  color: var(--text-default);
`;

export const TransitionWrapper = styled.div<{
  contentHeight: number;
  expanded: boolean;
}>`
  overflow: hidden;
  transition: height 0.4s cubic-bezier(0.75, 0.02, 0.5, 1);
  height: ${({ contentHeight, expanded }) => (expanded ? contentHeight : 0)}px;
`;

export const AccordionItem = styled.div<{ hideIcon: boolean }>`
  margin-bottom: 20px;
  ${borderRadius.r2};

  ${IconStyled} {
    ${({ hideIcon }) => hideIcon && 'display: none;'}
  }

  ${Content} {
    ${({ hideIcon }) => hideIcon && 'padding-left: 10px;'}
  }
`;

export const Accordion = styled.div<AccordionProps>`
  ${({ variant }) =>
    variant === 'secondary' &&
    css`
      ${AccordionItem}:not(:first-child):not(:last-child) {
        border-top: 0;
        border-radius: 0px;
      }
      ${AccordionItem}:first-child {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
      ${AccordionItem}:last-child {
        border-top: 0;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    `}
  ${AccordionItem} {
    background-color: ${({ withBackground }) =>
      withBackground ? 'var(--surface-default)' : 'transparent'};

    ${({ variant }) =>
      variant === 'secondary' &&
      css`
        ${TitleContent} {
          color: var(--text-default);
        }
        ${Content} {
          ${typographyV2.bodySm}
          color: var(--text-soft);
          padding: 0 40px 13px 40px;
        }
        margin-bottom: 0px;
        background-color: var(--background-default);
        border: 1px solid var(--border-soft);
      `}

    &:hover {
      background-color: var(--surface-hover);
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
`;
