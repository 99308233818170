/* eslint-disable max-lines-per-function */
import {
  ChartEmpty,
  ChartError,
  ChartMenuBarIds,
  DOMAIN_CHANGE_CUSTOM_EVENT,
  i18n,
  MenuBar,
  ViewController,
} from '@toggle/chart';
import { useWindowResize } from '@toggle/helpers';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { I18nextProvider, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { config } from '~/config';
import { MobileNotSupported } from '~/shared/components/mobile-not-supported/MobileNotSupported';
import { useTouchDevice } from '~/shared/hooks/MediaQueryHook';
import { ChartArea } from '~/views/turbo-chart/components/chart-area/ChartArea';

import {
  EventsConfig,
  EventsModal,
} from './components/events-modal/EventsModal';
import { FullscreenModal } from './components/fullscreen-modal/FullscreenModal';
import {
  IndicatorModal,
  IndicatorModalProps,
} from './components/indicator-modal/IndicatorModal';
import { Legend } from './components/legend/Legend';
import { PanesLimitModal } from './components/panes-limit-modal/PanesLimitModal';
import { SearchModal } from './components/search-modal/SearchModal';
import { useSearchModal } from './hooks/use-search-modal/useSearchModal';
import { useTurboChart } from './hooks/use-turbo-chart/useTurboChart';
import * as S from './TurboChart.styles';
import { ChartSearchType } from './types/search.types';
import { checkIsIndicator } from './utils/entity-utils/entity-utils';

export const TurboChart = () => {
  const [isEventsModalOpen, setIsEventsModalOpen] = useState(false);
  const [isIndicatorSearchOpen, setIsIndicatorSearchOpen] = useState(false);
  const [isResampleDropdownOpen, setIsResampleDropdownOpen] = useState(false);
  const [isPanesLimitModalOpen, setIsPanesLimitModalOpen] = useState(false);

  const [eventsConfig, setEventsConfig] = useState<EventsConfig>({
    TMI: true,
    TNI: true,
  });
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const panesRefLimitExceeded = useRef(false);
  const targetRef = useRef<HTMLDivElement>(null);

  const {
    closeSearch,
    isAssetSearchOpen,
    openSearch,
    searchType,
    changingAsset,
  } = useSearchModal();

  const {
    assetWithError,
    enabledResamples,
    removeActiveAsset,
    hideActiveAsset,
    activeAssets,
    chartData,
    changeRange,
    changeResample,
    changeChartLineColor,
    lineColors,
    changeSeriesType,
    chartApiRef,
    horizonRanges,
    onAssetChange,
    hasError,
    hasEmpty,
    disable1DHorizon,
    onDomainChange,
    changeYAxisSettings,
    chartSettings,
    chartPanes,
    chartPaneActions,
  } = useTurboChart({ openSearch });

  //this is to prevent translations lazy loading when any modal is opened from menu bar
  //resulting in unmounting and remounting full DOM
  const { t } = useTranslation(['chart', 'analyze', 'countries']);

  const isTouchDevice = useTouchDevice();
  const { width, height } = useWindowResize({ targetRef });
  const navigate = useNavigate();

  //useLayoutEffect is necessary, affects the order of calls
  useLayoutEffect(() => {
    const canvasElement = canvasRef.current;
    canvasElement?.addEventListener(DOMAIN_CHANGE_CUSTOM_EVENT, onDomainChange);

    return () => {
      canvasElement?.removeEventListener(
        DOMAIN_CHANGE_CUSTOM_EVENT,
        onDomainChange
      );
    };
  }, [canvasRef.current, onDomainChange]);

  useEffect(() => {
    return () => {
      chartApiRef.current?.reset();
    };
  }, []);

  if (isTouchDevice) {
    return <MobileNotSupported message={t('chart:widget.mobileBlockedMsg')} />;
  }

  const toggleResampleDropdown = () => {
    setIsResampleDropdownOpen(!isResampleDropdownOpen);
  };

  const closeChartModal = () => {
    navigate(-1);
  };

  const onMenuItemClick = (id: ChartMenuBarIds) => {
    if (id === 'events') {
      setIsEventsModalOpen(true);
    } else if (id === 'compare') {
      openSearch(ChartSearchType.Compare);
    } else if (id === 'indicators') {
      setIsIndicatorSearchOpen(true);
    }
  };

  const onIndicatorSelect: IndicatorModalProps['onIndicatorSelect'] = (
    indicator,
    asset
  ) => {
    if (panesRefLimitExceeded.current) {
      setIsPanesLimitModalOpen(true);
      return;
    }

    chartPaneActions.addIndicatorPane(indicator, asset);
  };

  const refreshPage = () => {
    navigate(0);
  };

  const { horizon, resample, domain, seriesType } = chartSettings;

  return (
    <>
      {isAssetSearchOpen && (
        <SearchModal
          type={searchType}
          activeAssets={activeAssets}
          changingAsset={changingAsset}
          onAssetChange={onAssetChange}
          onClose={closeSearch}
          removeActiveAsset={removeActiveAsset}
        />
      )}
      {isIndicatorSearchOpen && (
        <IndicatorModal
          activeAssets={activeAssets}
          onIndicatorSelect={onIndicatorSelect}
          onClose={() => setIsIndicatorSearchOpen(false)}
        />
      )}
      {isPanesLimitModalOpen && (
        <PanesLimitModal onClose={() => setIsPanesLimitModalOpen(false)} />
      )}
      {isEventsModalOpen && (
        <EventsModal
          onClose={() => setIsEventsModalOpen(false)}
          eventsConfig={eventsConfig}
          setEventsConfig={setEventsConfig}
        />
      )}

      <I18nextProvider i18n={i18n}>
        <FullscreenModal
          header={
            <MenuBar
              onPrimarySearch={() => openSearch(ChartSearchType.Primary)}
              onMenuItemClick={onMenuItemClick}
              disabledItems={!chartData.length}
            />
          }
          onClose={closeChartModal}
        >
          <ViewController
            enabledResamples={enabledResamples}
            horizonRanges={horizonRanges}
            selectedRange={horizon}
            selectedResample={resample}
            selectedSeriesType={seriesType}
            onRangeChange={changeRange}
            onResampleChange={changeResample}
            onChartTypeChange={changeSeriesType}
            isResampleDropdownOpen={isResampleDropdownOpen}
            disableMenuBar={!chartData.length}
            onItemsShowChange={setIsResampleDropdownOpen}
            isPrimaryAssetSinglePrice={checkIsIndicator(chartData[0]?.entity)}
            disable1DHorizon={disable1DHorizon}
          />

          <S.ChartAreaWrapper ref={targetRef}>
            {hasError ? (
              <ChartError
                supportEmail={config.supportEmail}
                onRetry={refreshPage}
              />
            ) : hasEmpty && !chartData.length && assetWithError ? (
              <ChartEmpty
                assetName={assetWithError.name}
                onSearchAgain={() => openSearch(searchType)}
              />
            ) : null}

            {!!chartData.length && !!width && (
              <ChartArea
                canvasRef={canvasRef}
                chartApiRef={chartApiRef}
                chartData={chartData}
                options={{
                  width,
                  height,
                  gutters: {
                    x: 40,
                    y: 40,
                  },
                  // TODO when this is packaged make sure this parent componet adds robot mono and nort
                  font: {
                    default: '12px Nort',
                    mono: '12px Roboto Mono',
                  },
                }}
                chartSettings={chartSettings}
                eventsConfig={eventsConfig}
                domain={domain}
                changeYAxisSettings={changeYAxisSettings}
                chartPanes={chartPanes}
                chartPaneActions={chartPaneActions}
              />
            )}
            {!!chartData.length && (
              <Legend
                selectedResample={resample}
                removeAsset={removeActiveAsset}
                onChangeResample={toggleResampleDropdown}
                onChangeTicker={(isPrimary, e) =>
                  openSearch(
                    isPrimary
                      ? ChartSearchType.Primary
                      : ChartSearchType.Change,
                    e
                  )
                }
                lineColorArray={lineColors}
                onChangeLineColor={changeChartLineColor}
                onHideAsset={hideActiveAsset}
                chartData={chartData}
                canvasRef={canvasRef}
                selectedSeriesType={seriesType}
              />
            )}
          </S.ChartAreaWrapper>
        </FullscreenModal>
      </I18nextProvider>
    </>
  );
};
