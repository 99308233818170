import ReactGA from 'react-ga4';

const CATEGORIES = {
  global: 'Global',
};
export { CATEGORIES as GLOBAL_CATEGORIES };

const ACTIONS = {
  clickForgotPassword: 'Click_forgot_password',
};
export { ACTIONS as GLOBAL_ACTIONS };

export function gaGlobalClickForgotPassword(location: 'login' | 'security') {
  ReactGA.event({
    category: CATEGORIES.global,
    action: ACTIONS.clickForgotPassword,
    label: `location:${location}`,
  });
}
