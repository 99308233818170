import React from 'react';
import { useTranslation } from 'react-i18next';

import { Video } from '~/shared/components/video/Video';

import { HELPER_VIDEO_LINKS } from '../../../HelperConfig';
import * as S from '../Content.styles';

interface ExploreIntroProps {
  onVideoClick: () => void;
}

export function ExploreIntro({ onVideoClick }: ExploreIntroProps) {
  const { t } = useTranslation('helperWidget');
  return (
    <div data-testid="asset-overview">
      <S.Topic>{t('helperWidget:explore.content.insightsIntro.title')}</S.Topic>
      <S.VideoWrapper>
        <Video
          src={HELPER_VIDEO_LINKS.EXPLORE_LINK}
          onVideoClick={onVideoClick}
        />
      </S.VideoWrapper>
    </div>
  );
}
