import { typography } from '@toggle/design-system';
import styled from 'styled-components';

export const Badge = styled.span`
  position: absolute;
  height: 16px;
  padding: 0px 4px;
  border-radius: 4px;
  border: 1px solid var(--base--40);
  background-color: var(--base--0);
  color: var(--base--100);
  ${typography.quoll}
`;
