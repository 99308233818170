import { arcticLight } from './arctic-light';
import { crystalLight } from './crystal-light';
import {
  orangeDark,
  orangeDark_deuteranopia,
  orangeDark_protanopia,
} from './orange-dark';
import {
  spaceDark,
  spaceDark_deuteranopia,
  spaceDark_protanopia,
} from './space-dark';

const baseThemes = {
  arcticLight,
  crystalLight,
  spaceDark,
  orangeDark,
};

// colors used in the chart need to be in the HEX format, [8693rnq8z]
export const themes = {
  ...baseThemes,
  spaceDark_deuteranopia,
  spaceDark_protanopia,
  orangeDark_deuteranopia,
  orangeDark_protanopia,
};
export const DEFAULT_THEME = 'orangeDark';
export const DEFAULT_THEME_V2 = 'spaceDark';

export type BaseThemeNames = keyof typeof baseThemes;
export type ThemeNames = keyof typeof themes;
