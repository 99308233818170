import { nice } from 'd3-array';

import { humanFriendlyTicks } from '../human-friendly-ticks/human-friendly-ticks';

const APPROXIMATE_TICKS_COUNT = 5;

interface GetChartTicksProps {
  min: number;
  max: number;
  tickCount?: number;
}

export const getHumanFriendlyTicks = ({
  min,
  max,
  tickCount = APPROXIMATE_TICKS_COUNT,
}: GetChartTicksProps) => {
  const tickScale = nice(min, max, tickCount);
  const { ticks } = humanFriendlyTicks(tickScale, tickCount);

  return {
    chartTicks: ticks,
    minRounded: tickScale[0],
    maxRounded: tickScale[1],
  };
};
