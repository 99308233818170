import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { config } from '~/config';

import * as S from './OrientationRegisterBanner.styles';

const {
  urls: { webinarLink },
} = config;

export const OrientationRegisterBanner: FC = () => {
  const { t } = useTranslation('helperWidget');
  const FORM_REGISTER_LINK = webinarLink;

  return (
    <S.OrientationRegisterBanner data-testid="orientation-register-banner">
      <S.TextContent>{t('helperWidget:orientation.message')}</S.TextContent>

      <S.Link
        data-testid="register-btn"
        href={FORM_REGISTER_LINK}
        target={'_blank'}
        rel="noopener noreferrer"
      >
        <S.Button label={t('helperWidget:orientation.registerNow')} />
      </S.Link>
    </S.OrientationRegisterBanner>
  );
};
