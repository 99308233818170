import { deviceConstraints, Icon, typography } from '@toggle/design-system';
import styled, { css } from 'styled-components';

export enum SummaryItemKeys {
  Name = 'name',
  Direction = 'direction',
  Star = 'star',
  Target = 'target',
  Horizon = 'horizon',
  Rate = 'rate',
  Price = 'price',
}

export const ArticleSidebarSummary = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ArticleSidebarSummaryHeader = styled.h3`
  width: 100%;
  padding: 6px 0 6px 0px;
  border-bottom: 2px solid var(--base--70);
  color: var(--base--70);
  ${typography.emu}
  font-weight: 500;
  text-transform: uppercase;

  @media ${deviceConstraints.smartphone} {
    margin-bottom: 16px;
    padding-left: 16px;
    text-align: left;
  }

  @media ${deviceConstraints.smartphoneOrTablet} {
    margin-bottom: 24px;
    padding-left: 16px;
  }

  @media ${deviceConstraints.tablet} {
    padding-left: 0px;
  }
`;

export const ArticleSidebarSummaryList = styled.ul`
  display: grid;
  grid-template-areas:
    'name'
    'direction'
    'star'
    'target'
    'horizon'
    'rate'
    'price';

  @media ${deviceConstraints.smartphone} {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
  }
  @media ${deviceConstraints.smartphoneOrTablet} {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
  }

  @media ${deviceConstraints.tablet} {
    display: grid;
    grid-template-areas:
      'name  direction star'
      'target horizon rate'
      'price price none';
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 16px;
    justify-content: stretch;
  }

  @media ${deviceConstraints.desktopSmall} {
    display: grid;
    grid-template-areas:
      'name'
      'direction'
      'star'
      'target'
      'horizon'
      'rate'
      'price';
    grid-template-columns: auto;
  }
`;

export const ArticleSidebarSummaryItem = styled.li<{
  $itemKeys: SummaryItemKeys;
}>`
  display: flex;
  &:nth-child(even) {
    background-color: var(--base--100);
  }

  @media ${deviceConstraints.smartphoneOrTablet} {
    width: 48%;
    margin: 0 4px;
  }

  @media ${deviceConstraints.tablet} {
    flex-direction: column;
    width: 100%;
    margin: 0;
    &:nth-child(even) {
      background-color: unset;
    }
  }

  @media ${deviceConstraints.desktop} {
    flex-direction: row;
    &:nth-child(even) {
      background-color: var(--base--100);
    }
  }

  ${({ $itemKeys }) => setItemKey($itemKeys)};
`;

const setItemKey = (itemKey: SummaryItemKeys) => {
  switch (itemKey) {
    case SummaryItemKeys.Name:
      return css`
        display: flex;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;

        grid-area: name;
        @media ${deviceConstraints.smartphoneOrTablet} {
          order: 1;
        }
      `;

    case SummaryItemKeys.Target:
      return css`
        grid-area: target;
        @media ${deviceConstraints.smartphoneOrTablet} {
          order: 7;
        }
      `;
    case SummaryItemKeys.Direction:
      return css`
        grid-area: direction;
        @media ${deviceConstraints.smartphoneOrTablet} {
          order: 3;
        }
        text-transform: capitalize;
      `;
    case SummaryItemKeys.Star:
      return css`
        grid-area: star;

        @media ${deviceConstraints.smartphoneOrTablet} {
          order: 5;
        }
      `;

    case SummaryItemKeys.Rate:
      return css`
        grid-area: rate;
        @media ${deviceConstraints.smartphoneOrTablet} {
          order: 4;
        }
      `;

    case SummaryItemKeys.Horizon:
      return css`
        grid-area: horizon;
        @media ${deviceConstraints.smartphoneOrTablet} {
          order: 2;
        }
      `;
    case SummaryItemKeys.Price:
      return css`
        grid-area: price;
        @media ${deviceConstraints.smartphoneOrTablet} {
          order: 6;
        }
      `;

    default:
      return '';
  }
};

export const ArticleSidebarSummaryItemName = styled.div`
  width: 120px;
  display: grid;
  grid-template-areas:
    'name'
    'target'
    'direction'
    'star'
    'rate'
    'horizon'
    'price';
  padding: 8px;
  border-right-width: 2px;
  border-right-style: solid;
  border-right-color: var(--primary-color);
  color: var(--base--0);
  ${typography.emu}
  font-weight: 500;
  text-transform: capitalize;

  @media ${deviceConstraints.smartphoneOrTablet} {
    padding: 2% 4%;
  }

  @media ${deviceConstraints.tablet} {
    width: 100%;
    border-right: none;
    border-bottom: 1px solid var(--base--90);
    background-color: var(--base--100);

    color: var(--base--35);
    font-weight: normal;
  }

  @media ${deviceConstraints.desktop} {
    width: 42%;
    padding: 16px 8px 8px 16px;
    border-right-width: 2px;
    border-right-style: solid;
    border-bottom: none;
    color: var(--base--0);
    ${typography.dingo}
    font-weight: 500;
    background-color: unset;
    border-right-color: var(--primary-color);
  }
`;

export const ArticleSidebarSummaryItemContent = styled.div`
  width: 163px;
  padding: 8px 16px;
  color: var(--base--0);
  ${typography.emu}
  white-space: normal;
  text-overflow: ellipsis;
  overflow: hidden;
  display: flex;
  align-items: center;

  @media ${deviceConstraints.smartphoneOrTablet} {
    padding: 2% 4%;
  }

  @media ${deviceConstraints.tablet} {
    width: 100%;
    ${typography.dingo}
    text-align: right;
  }

  @media ${deviceConstraints.desktop} {
    width: 58%;
    padding: 16px 16px 8px 16px;
    text-align: left;
  }
`;

export const IconWrapper = styled.span`
  display: flex;
  align-items: center;
`;

export const DirectionArrow = styled(Icon)`
  margin: 0px 6px 3px -1px;
`;

export const ArticleSidebarSummaryItemContentIcon = styled(Icon)`
  margin-bottom: 2px;
  margin-right: 2px;
`;
