import React, { FC, ReactNode } from 'react';

import * as S from './LoadingView.styles';

interface LoadingViewProps {
  loading?: boolean;
  className?: string;
  children?: ReactNode;
}

export const LoadingView: FC<LoadingViewProps> = ({
  loading,
  children,
  className,
}) =>
  loading ? (
    <S.Container data-testid="loading-view" className={className}>
      <S.Indicator />
    </S.Container>
  ) : (
    <>{children}</>
  );
