import { SeriesType, TimeSeriesItem } from '@toggle/chart';
import { PreliminaryPrice, SnakeMeta } from '@toggle/toggle';
import React, { RefObject, useCallback } from 'react';

import { MappedEntity } from '~/shared/hooks/use-entities/useEntities';
import { globalPriceFormatter } from '~/shared/utils/asset-price/assetPrice';
import { priceSuffix } from '~/shared/utils/currency/currency';

import { LastClosePrice } from '../last-close-price/LastClosePrice';
import { LegendLivePrice } from '../legend-live-price/LegendLivePrice';

export interface LegendPriceProps {
  entity: MappedEntity;
  snakeMeta?: SnakeMeta;
  preliminaryPrice?: PreliminaryPrice;
  isChartHovered: boolean;
  legendColor: string;
  isPrimary: boolean;
  shouldShowLivePrice: boolean;
  canvasRef: RefObject<HTMLCanvasElement>;
  selectedSeriesType?: SeriesType;
  lastTSPrice: TimeSeriesItem;
}

export const LegendPrice = ({
  entity,
  snakeMeta,
  preliminaryPrice,
  isChartHovered,
  legendColor,
  isPrimary,
  shouldShowLivePrice,
  canvasRef,
  selectedSeriesType,
  lastTSPrice,
}: LegendPriceProps) => {
  const priceFormatter = useCallback(
    (price: number) =>
      globalPriceFormatter({
        entity,
        price,
        priceSuffix: snakeMeta
          ? priceSuffix(snakeMeta.display_format, entity.currency)
          : '',
        shouldShowCurrency: false,
      }),
    [entity]
  );

  if (!snakeMeta?.last_timestamp) {
    return null;
  }

  //TODO will add back in full live price when we have figured out the OHLC live legend
  if (!shouldShowLivePrice) {
    return (
      <LastClosePrice
        entity={entity}
        snakeMeta={snakeMeta}
        legendColor={legendColor}
        isPrimary={isPrimary}
        canvasRef={canvasRef}
        priceFormatter={priceFormatter}
        seriesType={selectedSeriesType}
        lastTSPrice={lastTSPrice}
      />
    );
  }

  return (
    <LegendLivePrice
      entity={entity}
      isChartHovered={isChartHovered}
      legendColor={legendColor}
      isPrimary={isPrimary}
      priceFormatter={priceFormatter}
      snakeMeta={snakeMeta}
      preliminaryPrice={preliminaryPrice}
    />
  );
};
