import React, { FC } from 'react';

import * as S from './NoWatchlist.styles';

interface NoWatchlistProps {
  title: string;
  content: string;
  buttonContent: string;
  imageAlt: string;
  handleOnClick: () => void;
}

export const NoWatchlist: FC<NoWatchlistProps> = ({
  title,
  content,
  buttonContent,
  imageAlt,
  handleOnClick,
}) => (
  <S.Container data-testid="no-watchlist">
    <S.Title>{title}</S.Title>
    <S.Content>{content}</S.Content>
    <S.Image src="/app/assets/explore/connect-watchlist.png" alt={imageAlt} />
    <S.ButtonStyled
      variant="secondary"
      label={buttonContent}
      onClick={handleOnClick}
    />
  </S.Container>
);
