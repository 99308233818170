import { Button } from '@toggle/design-system';
import React, { FC, RefObject, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { appPaths } from '~/routes/app-paths';

import { Investor } from './investor';
import * as S from './Questionnaire.styles';
import { OnboardingStore, useOnboardingContext } from './store';
import { Trade } from './trade';
import { UserInterest, UserInterestOptions } from './user-interest';

export const QuestionnaireView: FC = () => {
  const { t } = useTranslation('onboarding');
  const navigate = useNavigate();
  const location = useLocation();
  const headerRef = useRef<HTMLElement>(null);
  const tradeRef = useRef<HTMLElement>(null);
  const investorRef = useRef<HTMLElement>(null);
  const nextActionRef = useRef<HTMLElement>(null);
  const mainRef = useRef<HTMLElement>(null);
  const {
    tradeOptions,
    investorOptions,
    userInterest,
    handleTradeClick,
    handleInvestorClick,
    handleUserInterest,
    persistToStorage,
    storeOnboardingSkip,
  } = useOnboardingContext();

  const showResults = async () => {
    await persistToStorage();
    navigate(
      userInterest === UserInterestOptions.tradeIdea
        ? appPaths.explore
        : appPaths.suggestion,
      { replace: true }
    );
  };

  const handleNextAction = (nextRef: RefObject<HTMLElement>) =>
    nextRef.current?.scrollIntoView({ behavior: 'smooth' });

  const handleSkipClick = async () => {
    await storeOnboardingSkip();
    navigate(appPaths.myToggle, { replace: true });
  };

  const showSkippableView = location.state?.showSkippableView;

  return (
    <S.MainContainer ref={mainRef}>
      <S.Header ref={headerRef}>
        <S.HeaderBody>
          <S.HeaderLeftSide>
            <S.HeaderTitle>
              {t('onboarding:questionnaire.header.title')}
              <S.WaveImg
                src="/app/assets/onboarding/wave.png"
                alt={t('onboarding:onboarding:questionnaire.header.waveAlt')}
              />
            </S.HeaderTitle>
            <S.HeaderText>
              <p>
                {showSkippableView
                  ? t('onboarding:questionnaire.header.skippableView.text1')
                  : t('onboarding:questionnaire.header.text1')}
              </p>
              <br />
              <p>
                {showSkippableView
                  ? t('onboarding:questionnaire.header.skippableView.text2')
                  : t('onboarding:questionnaire.header.text2')}
              </p>
            </S.HeaderText>
            <S.HeaderButtonsWrapper>
              <S.HeaderButtonWrapper isLetsGo>
                <Button
                  fullWidth
                  label={t('onboarding:questionnaire.header.button')}
                  onClick={() => handleNextAction(tradeRef)}
                />
              </S.HeaderButtonWrapper>

              {showSkippableView && (
                <S.HeaderButtonWrapper isLetsGo={false}>
                  <Button
                    variant="secondary"
                    fullWidth
                    label={t(
                      'onboarding:questionnaire.header.skippableView.button'
                    )}
                    onClick={handleSkipClick}
                  />
                </S.HeaderButtonWrapper>
              )}
            </S.HeaderButtonsWrapper>
          </S.HeaderLeftSide>
          <S.RobotImg
            src="/app/assets/onboarding/robot.png"
            alt={t('onboarding:questionnaire.header.robotAlt')}
          />
        </S.HeaderBody>
      </S.Header>
      <S.Section ref={tradeRef}>
        <S.SectionTitle>
          {t('onboarding:questionnaire.trade.title')}
        </S.SectionTitle>
        <Trade tradeOptions={tradeOptions} handleClick={handleTradeClick} />
        <S.SectionButton>
          <Button
            fullWidth
            label={t('onboarding:questionnaire.trade.button')}
            disabled={!Object.values(tradeOptions).some(Boolean)}
            onClick={() => handleNextAction(investorRef)}
          />
        </S.SectionButton>
      </S.Section>
      <S.Section ref={investorRef}>
        <S.SectionTitle>
          {t('onboarding:questionnaire.investor.title')}
        </S.SectionTitle>
        <Investor
          investorOptions={investorOptions}
          handleClick={handleInvestorClick}
        />
        <S.SectionButton>
          <Button
            fullWidth
            label={t('onboarding:questionnaire.investor.button')}
            disabled={!Object.values(investorOptions).some(Boolean)}
            onClick={() => handleNextAction(nextActionRef)}
          />
        </S.SectionButton>
      </S.Section>
      <S.Section ref={nextActionRef}>
        <S.SectionTitle>
          {t('onboarding:questionnaire.next.title')}
        </S.SectionTitle>
        <UserInterest active={userInterest} handleClick={handleUserInterest} />
        <S.SectionButton>
          <Button
            fullWidth
            label={t('onboarding:questionnaire.next.button')}
            disabled={
              !Object.values(tradeOptions).some(Boolean) ||
              !Object.values(investorOptions).some(Boolean) ||
              !userInterest
            }
            onClick={showResults}
            data-testid="show-results"
          />
        </S.SectionButton>
      </S.Section>
    </S.MainContainer>
  );
};

export const Questionnaire: FC = () => (
  <OnboardingStore>
    <QuestionnaireView />
  </OnboardingStore>
);
