import { Class, MappedEntity } from '@toggle/toggle';
import React, { FC } from 'react';

import * as S from '../../AssetLogo.styles';
import { FixedIncomeLogo } from '../fixed-income-logo/FixedIncomeLogo';

export interface LogoImageProps {
  entity: MappedEntity;
  className?: string;
  logoUrl: string;
  onError: () => void;
  size: number;
}

export const LogoImage: FC<LogoImageProps> = ({
  entity,
  className,
  logoUrl,
  onError,
  size,
}) => {
  const imgProps = {
    src: logoUrl,
    alt: 'logo-image',
    ['data-testid']: 'logo',
    onError,
  };

  return (
    <S.LogoWrapper $size={size} className={className}>
      {entity.class === Class.ClassFi ? (
        <FixedIncomeLogo
          imgProps={{ ...imgProps, ['data-testid']: 'fi-logo' }}
          entityTag={entity.tag}
        />
      ) : (
        <img {...imgProps} />
      )}
    </S.LogoWrapper>
  );
};
