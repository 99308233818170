import React from 'react';

import * as S from './SkeletonLoader.styles';

interface Area {
  className?: string;
  styles?: React.CSSProperties;
}

export interface SkeletonLoaderProps {
  withWrapper?: boolean;
  areas?: Area[];
  defaultView?: boolean;
}

export const SkeletonLoader = ({
  areas = [],
  withWrapper,
  defaultView,
  ...rest
}: SkeletonLoaderProps) => {
  const loadingAreas = defaultView ? (
    <>
      <S.DefaultTitle data-testid="default-view-title" />
      <S.DefaultContent />
    </>
  ) : (
    areas.map((area, idx) => (
      <S.Area
        key={idx}
        className={area.className}
        style={area.styles}
        data-testid="loading-area"
      />
    ))
  );

  return (
    <S.Wrapper $withWrapper={withWrapper} $defaultView={defaultView} {...rest}>
      {loadingAreas}
    </S.Wrapper>
  );
};
