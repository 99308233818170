import { getTimeMinusOffset } from '@toggle/helpers';
import { parse } from 'date-fns';
import { formatInTimeZone, utcToZonedTime } from 'date-fns-tz';

import { PreliminaryPrice } from '~/api/price/price-schema';
import { SnakeMeta } from '~/api/timeseries/timeseries-schema';
import {
  EST_TIME_ZONE,
  getPriceChange,
} from '~/views/analyze/utils/asset-box/asset-box-helpers';

import { PriceBase } from '../price-display.types';

/**
 * Details: https://app.clickup.com/10607188/v/dc/a3pjm-10088/a3pjm-16588
 */
const checkIsPreliminary = (
  alifiedLastTimestamp: string,
  priceHistoryTime: string
) => {
  const snakeTimeZoned = utcToZonedTime(new Date(alifiedLastTimestamp), 'UTC');
  const priceHistoryZoned = utcToZonedTime(
    new Date(priceHistoryTime),
    EST_TIME_ZONE
  );
  const snakeTimeStart = parse('00:00:00', 'HH:mm:ss', snakeTimeZoned);
  const priceHistoryStart = parse('00:00:00', 'HH:mm:ss', priceHistoryZoned);
  return (
    getTimeMinusOffset(snakeTimeStart) < getTimeMinusOffset(priceHistoryStart)
  );
};

/**
 * Formats details for the primary price that returns a PriceBase object.
 *
 * Details: https://app.clickup.com/10607188/v/dc/a3pjm-10088/a3pjm-15448?&block=block-ee6ab03d-893d-427f-a190-347a0888982b
 */
export const formatPrimaryPrice = (
  base: PriceBase,
  isCurrency: boolean,
  meta?: SnakeMeta,
  preliminaryPrice?: PreliminaryPrice
): PriceBase => {
  const isLive = base.marketHours.isMarketHours && base.isLive;
  const isPreliminary =
    !!meta?.alified_last_timestamp &&
    !!preliminaryPrice &&
    checkIsPreliminary(meta?.alified_last_timestamp, preliminaryPrice.time);

  const officialCloseDetails = !isLive
    ? formatOfficialClose(isCurrency, meta)
    : base;

  const customDetails = isPreliminary
    ? formatPreliminary(preliminaryPrice, isCurrency, meta)
    : officialCloseDetails;

  return {
    ...base,
    isLive,
    ...customDetails,
  };
};

/**
 * Formats preliminary price details used in the primary price display.
 *
 * Details: https://app.clickup.com/10607188/v/dc/a3pjm-10088/a3pjm-15448?block=block-2dab2213-30a7-4bf9-b4e9-77a1a377115c
 */
const formatPreliminary = (
  preliminaryPrice: PreliminaryPrice,
  isCurrency: boolean,
  meta: SnakeMeta
) => ({
  price: preliminaryPrice.close,
  priceChange: getPriceChange({
    lastPrice: meta.last_value ?? 0,
    newPrice: preliminaryPrice.close,
    isCurrency,
  }).priceChange,
  asOfDate: formatInTimeZone(
    preliminaryPrice.time,
    EST_TIME_ZONE,
    'MMMM do, k:mm zzz'
  ),
});

const formatOfficialClose = (isCurrency: boolean, meta?: SnakeMeta) => ({
  price: meta?.last_value,
  priceChange: getPriceChange({
    lastPrice: meta?.before_last_value ?? 0,
    newPrice: meta?.last_value ?? 0,
    isCurrency,
  }).priceChange,
});
