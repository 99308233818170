import { Accordion, typography } from '@toggle/design-system';
import styled from 'styled-components';

import { VideoWrapper as DefaultVideoWrapper } from '~/views/helper/helper-widget/content/Content.styles';
import { HelperWidgetAccordion } from '~/views/helper/helper-widget/partials/accordion/HelperWidgetAccordion';

export const FaqContent = styled.div`
  ${typography.koala};
`;

export const Link = styled.a<{ decorated?: boolean }>`
  &,
  &:hover {
    color: var(--primary-color);
    ${({ decorated }) => decorated && 'text-decoration: underline;'}
  }
`;

export const VideoWrapper = styled(DefaultVideoWrapper)`
  margin-bottom: 15px;
`;

export const List = styled.ul`
  && {
    list-style-type: disc;
    padding-left: 20px;
  }
`;

export const FaqAccordion = styled(HelperWidgetAccordion)`
  ${Accordion.Styled.AccordionItem} {
    margin-bottom: 12px;
    padding-bottom: 14px;
    border-bottom: 1px solid var(--base--80);
    border-radius: 0;

    &:hover {
      border-bottom-color: var(--primary-color);
    }
  }
`;
