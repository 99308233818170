import {
  differenceInBusinessDays,
  differenceInCalendarDays,
  parseISO,
} from 'date-fns';

import type { Portfolio } from '~/stores/use-portfolio/usePortfolio';
import type { UserStatement } from '~/stores/user-statements-store/services/fetch-user-statements/fetchUserStatements';

const MAX_DIFFERENCE_IN_DAYS = 1;

export const checkIsInSync = (
  date: string,
  differenceInDays: (dateLeft: Date, dateRight: Date) => number
) => {
  const today = new Date();
  const diff = differenceInDays(today, parseISO(date));

  return diff <= MAX_DIFFERENCE_IN_DAYS;
};

export const checkIsPortfolioInSync = (portfolio?: Portfolio) =>
  !!portfolio &&
  checkIsInSync(portfolio.last_statement_date, differenceInCalendarDays);

export const checkIsUserStatementInSync = (userStatement: UserStatement) =>
  !!userStatement.price_date &&
  checkIsInSync(userStatement.price_date, differenceInBusinessDays);
