import { useDisableScroll, useScrollListener } from '@toggle/helpers';
import React, { FC, Fragment, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { UserFilterResponseFilters } from '~/api/explore-filter/explore-filter-schema';
import { useTouchDevice } from '~/shared/hooks/MediaQueryHook';
import { useIsTagNewToday } from '~/shared/hooks/use-is-tag-new-today/useIsTagNewToday';
import {
  ONBOARDING_KEYS,
  useRemoteStorage,
} from '~/shared/hooks/use-remote-storage';
import { gaExploreRenameFiltersScreen } from '~/shared/utils/ganalytics';
import { useHelper } from '~/stores/use-helper/useHelper';
import { useSubscription } from '~/stores/use-subscription/useSubscription';
import { useUserEntities } from '~/stores/use-user-entities/useUserEntities';
import { useFilterData } from '~/views/explore';
import { FilterScreenModal } from '~/views/explore/feed/partials/filter-screen-modal/FilterScreenModal';
import { FilterScreens } from '~/views/explore/filter-screens/FilterScreens';
import { exploreSectionsRefs } from '~/views/helper/helper-widget/content/explore/ExploreHelperContent';

import { EXPLORE_SECTION_IDS } from '../../helper/HelperConfig';
import { useTickersData } from '../../my-account/watchlist/use-tickers-data/useTickersData';
import {
  EXPLORE_FILTERS_LS_KEY,
  useExploreFilters,
} from '../store/use-explore-filters';
import * as S from './ExploreFeed.styles';
import { EmptyState } from './partials/empty-state/EmptyState';
import { ExploreFeedHeader } from './partials/explore-feed-header/ExploreFeedHeader';
import { ExploreFilters } from './partials/filters/ExploreFilters';

export const ExploreFeed: FC = () => {
  const { t } = useTranslation(['explore']);
  const [displayMobileMenu, setDisplayMobileMenu] = useState(false);
  const feedContainerRef = useRef<HTMLDivElement>(null);
  const [displayScreenModal, setDisplayScreenModal] = useState(false);
  const { activeTags } = useTickersData();
  const { data, count, loading, loadData, watchListFilter } = useFilterData();
  const userRole = useSubscription(s => s.userSubscription.role);
  const lastAnalysis = useIsTagNewToday(
    `${EXPLORE_FILTERS_LS_KEY}:${userRole}`
  );

  const { items, storeItems } = useRemoteStorage(({ items, storeItems }) => ({
    items,
    storeItems,
  }));
  const watchlistTags = useUserEntities(s => s.entities.watchlist);
  useDisableScroll(displayMobileMenu);

  const initialFilters = (
    !items?.ONBOARDING_COMPLETE
      ? items?.ONBOARDING_INITIAL_EXPLORE_FILTERS
      : null
  ) as UserFilterResponseFilters | null;

  const {
    filters,
    screens,
    initialize,
    saveScreen,
    activeScreen,
    clearScreens,
    updateFilterOptions,
  } = useExploreFilters(s => ({
    filters: s.filters,
    screens: s.screens,
    saveScreen: s.saveScreen,
    activeScreen: s.activeScreen,
    initialize: s.initialize,
    clearScreens: s.clearScreens,
    updateFilterOptions: s.updateFilterOptions,
  }));

  const { setHelperHoverBehavior } = useHelper();
  const isTouchDevice = useTouchDevice();

  const dataNotEmpty = data.length !== 0;
  const filtersApplied = filters.length !== 0;

  useEffect(() => {
    updateFilterOptions();
  }, [watchlistTags]);

  useEffect(() => {
    initialize(lastAnalysis, initialFilters, t);

    return () => {
      clearScreens();
    };
  }, []);

  useEffect(() => {
    if (initialFilters && activeScreen) {
      storeItems({ [ONBOARDING_KEYS.ONBOARDING_COMPLETE]: 'true' });
    }
  }, [activeScreen]);

  useScrollListener(
    feedContainerRef,
    () => {
      if (!loading && dataNotEmpty) {
        loadData();
      }
      return true;
    },
    [loading, dataNotEmpty]
  );

  const onSaveScreen = (screenName: string) => {
    if (activeScreen?.name) {
      gaExploreRenameFiltersScreen(screenName);
    }
    saveScreen(screenName);
    setDisplayScreenModal(false);
  };

  const activeFilterScreen = activeScreen
    ? screens.find(s => s.id === activeScreen.id)
    : null;

  return (
    <S.ExploreFeed>
      <S.ExploreFeedFilters
        id="explore-filters"
        data-testid="explore-filters-container"
        isMobile={displayMobileMenu && isTouchDevice}
        ref={exploreSectionsRefs[EXPLORE_SECTION_IDS.EXPLORE_FILTERS_CHECK_ID]}
        {...setHelperHoverBehavior(
          EXPLORE_SECTION_IDS.EXPLORE_FILTERS_CHECK_ID
        )}
      >
        <ExploreFilters closeMobileDrawer={() => setDisplayMobileMenu(false)} />
        <S.Overlay onClick={() => setDisplayMobileMenu(false)} />
      </S.ExploreFeedFilters>
      <S.ExploreFeedContainer ref={feedContainerRef}>
        {displayScreenModal && (
          <FilterScreenModal
            onClose={() => setDisplayScreenModal(false)}
            onSave={onSaveScreen}
            name={activeScreen?.name}
          />
        )}
        <S.ScreensStickyWrapper>
          {isTouchDevice && (
            <S.MobileButtons data-testid="screens-mobile-buttons">
              <S.OpenFiltersButton
                iconName="Filter"
                size="small"
                label={t('explore:filters.label')}
                onClick={() => setDisplayMobileMenu(true)}
                data-testid="open-filter-btn"
              />
            </S.MobileButtons>
          )}
          {activeFilterScreen && (
            <FilterScreens
              openScreenModal={() => setDisplayScreenModal(true)}
              openMobileFilters={() => setDisplayMobileMenu(true)}
              activeScreen={activeFilterScreen}
            />
          )}
        </S.ScreensStickyWrapper>
        <ExploreFeedHeader
          dataNotEmpty={dataNotEmpty}
          filtersApplied={filtersApplied}
          isLoadingFilters={loading}
          insightCount={count}
          feedData={data}
        />
        <S.ExploreFeedList>
          {dataNotEmpty &&
            data.map((d, i) => (
              <Fragment key={d.created_on}>
                {i === 2 && <S.StatsCard key="stats-card" />}
                <S.ArticleCard
                  location="Explore"
                  data={d}
                  articleIdx={i}
                  inArticleFeed={count === 0}
                />
              </Fragment>
            ))}
          {!dataNotEmpty && !loading && (
            <EmptyState
              hasWatchListFilter={watchListFilter}
              hasActiveTag={activeTags.length > 0}
            />
          )}
          <S.LoadingContainer loading={loading} />
        </S.ExploreFeedList>
      </S.ExploreFeedContainer>
    </S.ExploreFeed>
  );
};
