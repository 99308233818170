import styled, { css } from 'styled-components';

import {
  lineHeight,
  typographyV2,
} from '~/design-tokens/typography/Typography.styles';

import type { OutsideTag, PriceChange } from './PriceDisplay';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const PriceContainer = styled.div<{ $isPrimary: boolean }>`
  display: flex;
  align-items: flex-end;
  gap: 8px;

  ${props =>
    props.$isPrimary
      ? css`
          ${Price} {
            ${typographyV2.titleLg}
          }
          ${PriceChangeContainer} {
            ${typographyV2.bodyBase}
          }
          ${Currency} {
            ${lineHeight.l300};
          }
        `
      : css`
          ${Price} {
            ${typographyV2.titleMd}
          }
          ${PriceChangeContainer} {
            ${typographyV2.bodySm}
          }
          ${Currency} {
            ${lineHeight.l200};
            margin-bottom: 1px;
          }
        `}
`;

export const Price = styled.span`
  font-family: var(--font-roboto-mono);
`;

export const Currency = styled.span`
  ${typographyV2.bodySm}
  text-transform: uppercase;
`;

const priceChangeColors = {
  default: 'var(--text-soft)',
  negative: 'var(--viz-bearish)',
  positive: 'var(--viz-bullish)',
};

export const PriceChangeContainer = styled.div<{
  $status: PriceChange['status'];
}>`
  display: flex;
  gap: 8px;
  color: ${props => priceChangeColors[props.$status]};
  font-family: var(--font-roboto-mono);
  margin-bottom: 1px;
`;

export const LabelsContainer = styled.p`
  display: flex;
`;

export const LiveLabel = styled.span`
  color: var(--text-success);
  ${typographyV2.bodyXs}
`;

export const OutsideHoursTag = styled.span<{ $tagType: OutsideTag }>`
  ${typographyV2.bodyXs}
  color: ${({ $tagType }) =>
    $tagType === 'pre' ? 'var(--text-primary)' : 'var(--text-soft)'};
  margin-right: 12px;
`;
