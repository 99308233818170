import { Button } from '@toggle/design-system';
import React, { FC } from 'react';

import { UserRole } from '~/api/subscription/subscription-schema';

import * as S from './BasicPaywallPlan.styles';

interface BasicPaywallPlanProps {
  role: UserRole;
  planName: string;
  planPriceLabel: string;
  buttonLabel: string;
  upgradeSubscription: () => void;
}

export const BasicPaywallPlan: FC<BasicPaywallPlanProps> = ({
  role,
  planName,
  planPriceLabel,
  buttonLabel,
  upgradeSubscription,
}) => {
  return (
    <S.PlanContainer key={role} data-testid={`${role}-plan`}>
      <S.PlanLabel>{planName}</S.PlanLabel>
      <S.PlanPrice>{planPriceLabel}</S.PlanPrice>
      <Button
        size="small"
        label={buttonLabel}
        variant={role === UserRole.Copilot ? 'secondary' : 'primary'}
        onClick={upgradeSubscription}
        data-testid={`${role}-button`}
      />
    </S.PlanContainer>
  );
};
