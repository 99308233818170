import React, { FC, ReactNode } from 'react';

import { LoadingView } from '~/shared/components/loading-view/LoadingView';
import { Content } from '~/views/helper/helper-widget/content/Content.styles';

import * as S from './PortfolioWatchlistSection.styles';

interface PortfolioWatchlistSectionProps {
  text: string;
  loading: boolean;
  btnLabel: ReactNode;
  navigateUrl: string;
}

export const PortfolioWatchlistSection: FC<PortfolioWatchlistSectionProps> = ({
  text,
  loading,
  btnLabel,
  navigateUrl,
}) => {
  return (
    <Content>
      <LoadingView loading={loading}>
        <S.Pre>{text}</S.Pre>
        <S.ButtonWrapper>
          <S.Button to={navigateUrl}>{btnLabel}</S.Button>
        </S.ButtonWrapper>
      </LoadingView>
    </Content>
  );
};
