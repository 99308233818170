import { typography } from '@toggle/design-system';
import styled from 'styled-components';

export const SelectTradeWrapper = styled.div<{ isMobile: boolean }>`
  position: absolute;
  inset: 0px;
  top: ${props => (props.isMobile ? '42px' : '81px')};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--base--100);
`;

export const SelectPortfolioText = styled.p`
  ${typography.coyote};
  margin-bottom: 16px;
`;

export const BackToSearchText = styled.span`
  ${typography.koala};
  color: var(--primary-color);
  margin-top: 42px;
  border-bottom: 1px solid transparent;
  transition: border-bottom 100ms ease-in-out;
  cursor: pointer;

  &:hover {
    border-bottom: 1px solid var(--primary-color);
  }
`;
