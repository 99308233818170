import type { ArticleSummary } from '~/declarations/toggle-api-news';
import { mapAssetClass, MappedEntity } from '~/shared/hooks/use-entities';
import type { MappedInsightArticle } from '~/views/analyze/asset-overview/components/sidebar/components/rocketship-tab/helpers/RocketshipTabHelpers';
import type { MappedArticle } from '~/views/analyze/utils/insights-helpers';

import { getModalType } from '../modal-types/modalTypes';

export const getModalTypeByArticle = (
  article: ArticleSummary | MappedArticle,
  isBasic = false
) => {
  return getModalType({
    asset_class: article.asset_cls,
    sub_class: article.sub_cls,
    stars: article.stars,
    eco: article.eco,
    isBasic,
  });
};

export const getModalTypeByEntity = (
  entity: Pick<MappedEntity, 'sub_class' | 'asset_class' | 'country'>,
  isBasic = false
) => {
  return getModalType({ ...entity, isBasic });
};

export const getModalTypeByInsightArticle = (
  { stars, driverEntity }: Pick<MappedInsightArticle, 'stars' | 'driverEntity'>,
  isBasic = false
) => {
  const { asset_class, sub_class } = mapAssetClass(driverEntity);

  return getModalType({
    ...driverEntity,
    asset_class,
    sub_class,
    stars,
    isBasic,
  });
};
