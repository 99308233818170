import { z } from 'zod';

import { ArticleHorizon } from './article-schema';

export enum InsightDirection {
  Bullish = 0,
  Bearish = 1,
}

export const insightSchema = z.object({
  id: z.string(),
  article_id: z.string(),
  entity: z.string(),
  direction: z.nativeEnum(InsightDirection),
  ellipsis: z.string(),
  insight_card: z.string(),
  stars: z.number(),
  median_return: z.number(),
  chart_link: z.string(),
  type_name: z.string(),
  horizon: z.nativeEnum(ArticleHorizon),
  created_on: z.string(),
  last_value_reference_date: z.string(),
  last_value: z.number(),
  type: z.string(),
});

const insightsResponseSchema = z.object({
  result: z.array(insightSchema),
  meta: z.object({ count: z.number() }),
});

type GetInsightOptions = {
  entity: string;
  limit?: number;
  fromDate?: string;
  toDate?: string;
  gtk?: boolean;
};

export const insights = {
  getInsight: {
    path: ({ entity, gtk, limit, toDate, fromDate }: GetInsightOptions) => {
      const params = new URLSearchParams({
        entity,
        ...(gtk && { inc_gtk: 'true' }),
        ...(limit && { limit: limit.toString() }),
        ...(toDate && { to_date: toDate }),
        ...(fromDate && { from_date: fromDate }),
      }).toString();
      return `a/v2/explore?${params}`;
    },
    schema: insightsResponseSchema,
  },
};

export type InsightResponse = z.infer<typeof insightsResponseSchema>;
export type Insight = z.infer<typeof insightSchema>;
