import { config } from '~/config';
import { HttpMethods } from '~/shared/services/api-fetch/constants';
import { validateApiFetch } from '~/shared/services/api-fetch/validate-api-fetch/validateApiFetch';

import {
  CognitiveSearch,
  cognitiveSearchSchema,
  DominoScenarioResponse,
  dominoScenarioSchema,
  ScenarioCommanderResponse,
  scenarioCommanderSchema,
} from './scenario-commander-schema';

const { root: apiRoot } = config.api;

export const scenarioCommanderEndpoints = {
  compose: `${apiRoot}scenario-commander/v1/compose`,
  dominoScenario: `${apiRoot}scenario-orchestrator/v1/scenarios`,
  cogSearch: `${apiRoot}jpm-search/v1/search`,
};

export const postScenarioCommander = (
  question: string
): Promise<ScenarioCommanderResponse> =>
  validateApiFetch({
    endpoint: scenarioCommanderEndpoints.compose,
    schema: scenarioCommanderSchema,
    requestInfo: {
      method: HttpMethods.Post,
      body: JSON.stringify({
        question,
      }),
    },
  });

export const postDominoScenario = (
  payload: ScenarioCommanderResponse
): Promise<DominoScenarioResponse> =>
  validateApiFetch({
    endpoint: scenarioCommanderEndpoints.dominoScenario,
    schema: dominoScenarioSchema,
    requestInfo: {
      method: HttpMethods.Post,
      body: JSON.stringify(payload),
    },
  });

export const postCogSearch = (question: string): Promise<CognitiveSearch> =>
  validateApiFetch({
    endpoint: scenarioCommanderEndpoints.cogSearch,
    schema: cognitiveSearchSchema,
    requestInfo: {
      method: HttpMethods.Post,
      body: JSON.stringify({
        question,
        source: 6,
      }),
    },
  });
