import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 50px 40px;
  text-align: center;
  align-items: center;
  flex: 1;
`;

export const Img = styled.img`
  width: 100%;
  height: auto;
  margin-bottom: 50px;
  max-width: 600px;
`;

export const Title = styled.h1`
  color: var(--base--0);
  flex: 1;
`;
