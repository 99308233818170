import { NumericalCellValueStyle } from '@toggle/helpers/src/utils/table/table';
import styled from 'styled-components';

import { typographyV2 } from '~/design-tokens/typography/Typography.styles';

export const MedianText = styled.text<{
  $numericalStyle: NumericalCellValueStyle;
}>`
  ${typographyV2.bodyBase};
  font-family: var(--font-roboto-mono);
  fill: ${({ $numericalStyle }) =>
    ({
      [NumericalCellValueStyle.Positive]: 'var(--viz-bullish)',
      [NumericalCellValueStyle.Negative]: 'var(--viz-bearish)',
      [NumericalCellValueStyle.Neutral]: 'var(--text-default)',
    }[$numericalStyle])};
  text-anchor: end;
`;

export const LabelText = styled.text`
  ${typographyV2.bodyBase};
  fill: var(--text-default);
`;
