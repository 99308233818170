import { ChartAssetData, PaneData } from '@toggle/chart';
import { SvgIconNames } from '@toggle/design-system';
import { isSinglePriceEntity } from '@toggle/helpers';
import React, { useMemo, useState } from 'react';

import type { SeriesType } from '~/types/series.types';

import * as S from './ChartTypeSelector.styles';
import { ChartTypeSelectorItem } from './ChartTypeSelectorItem';

export interface ChartTypeSelectorProps {
  paneData?: PaneData[];
  selectedSeriesType?: SeriesType;
  disableMenuBar: boolean;
  onChange: (
    chartType: SeriesType,
    { snake }: { snake?: string; paneIndex?: number }
  ) => void;
  isPrimaryAssetSinglePrice?: boolean;
  className?: string;
  defaultSnake?: string;
}

interface IndexedChartAsset extends ChartAssetData {
  paneIndex: number;
}

const availableCharts: SeriesType[] = ['line', 'candlestick', 'bars'];

export const chartTypeToIconName: Record<SeriesType, SvgIconNames> = {
  bars: 'Bars',
  candlestick: 'Candlestick',
  line: 'Line',
};

export const ChartTypeSelector = ({
  selectedSeriesType,
  paneData,
  onChange,
  disableMenuBar,
  isPrimaryAssetSinglePrice,
  className,
  defaultSnake,
}: ChartTypeSelectorProps) => {
  const [showChartType, setShowChartType] = useState(false);
  const [chartDropdown, setChartDropdown] = useState<SeriesType>('line');

  const renderDropdownItem = (item: IndexedChartAsset) => {
    const series = item.seriesType ?? 'line';
    const { entity, paneIndex } = item;
    const props = {
      entity,
      series,
      isChecked: series === chartDropdown,
    };
    return (
      <ChartTypeSelectorItem
        {...props}
        onClick={() =>
          onChange(series === chartDropdown ? 'line' : chartDropdown, {
            snake: item.entity.default_snake,
            paneIndex,
          })
        }
      />
    );
  };

  const dropDownItems: IndexedChartAsset[] = useMemo(
    () =>
      paneData?.flatMap(
        (pane, paneIndex) =>
          pane.chartAssetData
            .map(data =>
              isSinglePriceEntity(data.entity)
                ? null
                : {
                    ...data,
                    paneIndex,
                  }
            )
            .filter(Boolean) as IndexedChartAsset[]
      ) ?? [],
    [paneData]
  );

  const activeSeriesTypes = useMemo(
    () =>
      dropDownItems?.reduce(
        (a, data) => {
          a[data?.seriesType ?? 'line'] += 1;
          return a;
        },
        { line: 0, candlestick: 0, bars: 0 } as Record<SeriesType, number>
      ),
    [dropDownItems]
  );

  return (
    <S.ChartTypeSelectorRoot
      className={className}
      data-testid="chart-type-selector"
    >
      {availableCharts.map((chartType, index) => {
        const disableChip =
          disableMenuBar ||
          (['bars', 'candlestick'].includes(chartType) &&
            isPrimaryAssetSinglePrice);
        return (
          <React.Fragment key={`selector-type-${chartType}`}>
            {paneData ? (
              <S.ChartTypeDropdown
                data-testid={`${chartType}-dropdown`}
                key={`${chartType}-${index}`}
                dropdownItems={dropDownItems}
                renderDropdownItem={e => renderDropdownItem(e)}
                enableFloating
                inPortal
                shouldShowItems={chartDropdown === chartType && showChartType}
                shouldCloseOnSelect={false}
                placement="bottom-start"
                shouldSetActiveOnSelect={false}
              >
                <S.StyledChip
                  data-testid={`${chartType}-chart-button`}
                  key={chartType}
                  type="choice"
                  leftIcon={chartTypeToIconName[chartType]}
                  isActive={
                    activeSeriesTypes[chartType] === dropDownItems.length
                  }
                  disabled={disableChip}
                  onClick={() => {
                    setChartDropdown(chartType);
                    setShowChartType(prev =>
                      chartDropdown === chartType ? !prev : true
                    );
                  }}
                />
              </S.ChartTypeDropdown>
            ) : (
              <>
                <S.StyledChip
                  data-testid={`${chartType}-chip-button`}
                  key={chartType}
                  type="choice"
                  leftIcon={chartTypeToIconName[chartType]}
                  isActive={chartType === selectedSeriesType}
                  disabled={disableChip}
                  onClick={() => {
                    onChange(chartType, { snake: defaultSnake });
                  }}
                />
              </>
            )}
          </React.Fragment>
        );
      })}
    </S.ChartTypeSelectorRoot>
  );
};
