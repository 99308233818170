import { Spinner } from '@toggle/design-system';
import { RangeHorizon } from '@toggle/helpers';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  SnakeMetaResponseV2,
  TimeSeriesData,
} from '~/api/timeseries/timeseries-schema';
import { useAPI } from '~/shared/hooks/use-api/useAPI';
import { ApiFetchResponse } from '~/shared/services/api-fetch/APIFetch';
import {
  fetchSnakeByName,
  fetchSnakeMetaV2,
} from '~/shared/services/overview-widget/overview-widget-service';
import { GtkCardData } from '~/views/analyze/asset-overview/components/sidebar/components/rocketship-tab/helpers/RocketshipTabHelpers';
import { filterDataPoints } from '~/views/my-toggle/utils/data-points/dataPoints';

import * as SharedStyles from '../shared.styles';
import * as S from './GtkDetails.styles';

export interface GtkDetailsProps {
  selectedGtk: GtkCardData;
  backButtonClick: () => void;
}

const THREE_YEARS_IN_DAYS = 1095;

export const GtkDetails = ({
  selectedGtk,
  backButtonClick,
}: GtkDetailsProps) => {
  const { t } = useTranslation('common');

  const { data, loading } = useAPI(
    () => fetchSnakeByName(selectedGtk.expression, THREE_YEARS_IN_DAYS),
    {
      mapDataFn: ({ result, meta }: TimeSeriesData) => {
        const ts = filterDataPoints(result.data, RangeHorizon.ThreeYears);
        return {
          ts: ts.map((d, i) => ({ index: i, value: d.value })),
          entity: meta.entity,
        };
      },
      deps: [selectedGtk.expression],
    }
  );

  const { data: gtkSnakeName = '' } = useAPI(
    () => fetchSnakeMetaV2([selectedGtk.expression]),
    {
      mapDataFn: (snakeMeta: ApiFetchResponse<SnakeMetaResponseV2>) => {
        if (!(snakeMeta instanceof Error)) {
          return snakeMeta[selectedGtk.expression]?.name?.english;
        }
        return '';
      },
      deps: [selectedGtk.expression],
    }
  );

  return (
    <S.GtkDetailsRoot data-testid="gtk-details">
      <S.BackButton
        label={t('chat:goBack')}
        iconName="ArrowLeft"
        onClick={backButtonClick}
      />
      <S.TitleContainer>
        <S.Title>{selectedGtk.name}</S.Title>
        <S.TitleImgWrapper>{selectedGtk.imgNode}</S.TitleImgWrapper>
      </S.TitleContainer>
      <S.ChartContainer>
        {!loading && !!data?.ts.length ? (
          <>
            <S.ChartTitle>{gtkSnakeName}</S.ChartTitle>
            <S.ChartTitle>{selectedGtk.lastValue?.toFixed(2)}</S.ChartTitle>
            <S.StyledSimpleChart
              ts={data.ts}
              base={data.ts[data.ts.length - 1].value}
            />
            <S.ChartRange>{t('chat:threeYearHorizon')}</S.ChartRange>
          </>
        ) : (
          <SharedStyles.SpinnerContainer>
            <Spinner />
          </SharedStyles.SpinnerContainer>
        )}
      </S.ChartContainer>
      <S.GtkDescription>{selectedGtk.description}</S.GtkDescription>
    </S.GtkDetailsRoot>
  );
};
