import { TFunction } from 'i18next';

import { CARD_TYPES } from '~/views/analyze/overview-constants';

interface InsightCardProps {
  name: string;
  intuition: string;
}

interface InsightCardsType {
  [CARD_TYPES.BULLISH]: (t: TFunction) => InsightCardProps;
  [CARD_TYPES.BEARISH]: (t: TFunction) => InsightCardProps;
}

export function getInsightCardConfig(
  card: string,
  type: CARD_TYPES.BULLISH | CARD_TYPES.BEARISH,
  t: TFunction
) {
  const config: InsightCardsType = {
    bullish: (t: TFunction) => ({
      name: t([
        `analyze:insights.cards.${card}.bullish.name`,
        'analyze:insights.cards.card_0.bullish.name',
      ]),
      intuition: t([
        `analyze:insights.cards.${card}.bullish.intuition`,
        'analyze:insights.cards.card_0.bullish.intuition',
      ]),
    }),
    bearish: (t: TFunction) => ({
      name: t([
        `analyze:insights.cards.${card}.bearish.name`,
        'analyze:insights.cards.card_0.bearish.name',
      ]),
      intuition: t([
        `analyze:insights.cards.${card}.bearish.intuition`,
        'analyze:insights.cards.card_0.bearish.intuition',
      ]),
    }),
  };

  return config[type](t);
}
