import { FC, ReactNode, useState } from 'react';
import { createPortal } from 'react-dom';

interface PortalProps {
  container?: HTMLElement;
  children: ReactNode;
}

const PORTAL_NODE_ID = 'portal';

const getPortalNode = (container?: HTMLElement) => {
  if (container) {
    return container;
  }

  let element = document.getElementById(PORTAL_NODE_ID);
  if (element) {
    return element;
  }

  element = document.createElement('div');
  element.setAttribute('id', PORTAL_NODE_ID);
  document.body.appendChild(element);
  return element;
};

export const Portal: FC<PortalProps> = ({ children, container }) => {
  const isBrowser = typeof window !== 'undefined';
  if (!isBrowser) {
    return null;
  }
  const [portalNode] = useState<HTMLElement>(() => getPortalNode(container));

  return portalNode ? createPortal(children, portalNode) : null;
};
