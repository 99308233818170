import { Icon } from '@toggle/design-system';
import { TFunction } from 'i18next';
import React, { FC } from 'react';

import { ProgressBar } from '../progress-bar/ProgressBar';
import * as S from './SplashLoaderItem.styles';

interface SplashLoaderItemProps {
  isFrozen: boolean;
  completed: boolean;
  label: string;
  entityLabel?: string;
  duration: string;
  delay: string;
  showCompleteLabel?: boolean;
  t: TFunction;
}

export const SplashLoaderItem: FC<SplashLoaderItemProps> = ({
  isFrozen,
  completed,
  entityLabel,
  label,
  duration,
  delay,
  showCompleteLabel = true,
  t,
}) => (
  <S.LoaderItem>
    <S.Header>
      <S.Label>{label}</S.Label>
      <S.Entity completed={completed}>
        {showCompleteLabel && completed ? (
          <>
            <S.IconWrap>
              <Icon
                iconName="Checkmark"
                size={17}
                fillColor="var(--primary-color)"
                data-testid="icon-check"
              />
            </S.IconWrap>

            {t('analyze:splashScreen.complete')}
          </>
        ) : (
          entityLabel
        )}
      </S.Entity>
    </S.Header>
    <ProgressBar delay={delay} duration={duration} isFrozen={isFrozen} />
  </S.LoaderItem>
);
