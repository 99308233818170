import { v4 } from 'uuid';

import { create } from '~/stores/create-store/createStore';

import {
  handleQuestion,
  handleResponse,
  ResponseMessage,
} from './store-helpers';

export interface Message {
  id: string;
  question: string;
  response?: ResponseMessage;
}

interface ScenarioCommanderStore {
  messages: Message[];
  retryLast: () => Promise<void>;
  error?: boolean;
  sendMessage: (question: string) => Promise<void>;
  addMessage: (question: string, response?: ResponseMessage) => void;
}

export const useScenarioCommander = create<ScenarioCommanderStore>(
  (set, get) => ({
    messages: [],
    retryLast: async () => {
      const messages = get().messages;
      const lastMessage = messages[messages.length - 1];
      set(s => ({
        messages: s.messages.map(m =>
          m.id === lastMessage.id
            ? { ...m, error: false, response: undefined }
            : m
        ),
      }));
      if (lastMessage.response) {
        const response = await handleResponse(lastMessage.response);
        set(s => ({
          messages: s.messages.map(m =>
            m.id === lastMessage.id ? { ...m, response } : m
          ),
        }));
      }
    },
    sendMessage: async question => {
      const id = v4();
      set(s => ({
        messages: [...s.messages, { id, question }],
        error: false,
      }));
      const questionResponse = await handleQuestion(question);
      if (questionResponse?.error) {
        set({ error: true });
      } else {
        set(s => ({
          messages: s.messages.map(m =>
            m.id === id ? { ...m, response: questionResponse } : m
          ),
        }));
        const response = await handleResponse(questionResponse);
        set(s => ({
          messages: s.messages.map(m => (m.id === id ? { ...m, response } : m)),
        }));
      }
    },
    addMessage: (question: string, response?: ResponseMessage) => {
      const id = v4();

      set(s => ({
        messages: [...s.messages, { id, question, response }],
      }));
    },
  })
);
