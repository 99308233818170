import {
  aiInsightsCoverageSectionTitle,
  aiNewsData,
  analyticalToolsSectionTitle,
  articleData,
  assetCoverageData,
  assetCoverageSectionTitle,
  chartData,
  ComparisonTable,
  ComparisonTableData,
  marketIndicatorsData,
  marketIndicatorsSectionTitle,
  monitoringSectionTitle,
  savedFiltersData,
  starInsightsData,
  watchlistData,
} from '~/views/my-account/subscription/plan-matrix/utils/comparison-table-data';

import { MODAL_TYPES } from '../modal-types/modalTypes';

interface PaywallType {
  imgSrc: string;
  webpSrc: string;
  imgAlt: string;
  benefits: string[];
}

const tliAccess = 'paywall:features:tliAccess';
const globalEquity = 'paywall:features:globalEquity';
const insightsStars6to8 = 'paywall:features:insightsStars6to8';

const createComparisonTable = (
  data: ComparisonTableData[],
  section: ComparisonTable['section']
) => ({
  section,
  data,
});

export const paywallBasicTypes: Record<MODAL_TYPES, ComparisonTable> = {
  [MODAL_TYPES.STARS_6_TO_8]: createComparisonTable(
    starInsightsData,
    aiInsightsCoverageSectionTitle
  ),
  [MODAL_TYPES.BEYOND_STOCKS]: createComparisonTable(
    assetCoverageData,
    assetCoverageSectionTitle
  ),
  [MODAL_TYPES.CRYPTO]: createComparisonTable(
    assetCoverageData,
    assetCoverageSectionTitle
  ),
  [MODAL_TYPES.GEOGRAPHY_OUTSIDE_US]: createComparisonTable(
    assetCoverageData,
    assetCoverageSectionTitle
  ),
  [MODAL_TYPES.EXPLORE_FILTERS]: createComparisonTable(
    savedFiltersData,
    monitoringSectionTitle
  ),
  [MODAL_TYPES.SAVED_FILTERS]: createComparisonTable(
    savedFiltersData,
    monitoringSectionTitle
  ),
  [MODAL_TYPES.AI_NEWS]: createComparisonTable(
    aiNewsData,
    aiInsightsCoverageSectionTitle
  ),
  [MODAL_TYPES.MARKET_INDICATORS]: createComparisonTable(
    marketIndicatorsData,
    marketIndicatorsSectionTitle
  ),
  [MODAL_TYPES.WATCHLIST_LIMIT]: createComparisonTable(
    watchlistData,
    monitoringSectionTitle
  ),
  [MODAL_TYPES.ARTICLE_LIMIT]: createComparisonTable(
    articleData,
    aiInsightsCoverageSectionTitle
  ),
  [MODAL_TYPES.BOOKMARKS]: createComparisonTable(
    articleData,
    aiInsightsCoverageSectionTitle
  ),
  [MODAL_TYPES.CHART_PAGE]: createComparisonTable(
    chartData,
    analyticalToolsSectionTitle
  ),
  [MODAL_TYPES.DEFAULT]: createComparisonTable(
    articleData,
    aiInsightsCoverageSectionTitle
  ),
  [MODAL_TYPES.DEFAULT_PRO]: createComparisonTable(
    marketIndicatorsData,
    marketIndicatorsSectionTitle
  ),
};

export const paywallTypes: Record<MODAL_TYPES, PaywallType> = {
  [MODAL_TYPES.DEFAULT]: {
    imgSrc: '/app/assets/paywall/generic.png',
    webpSrc: '/app/assets/paywall/generic.webp',
    imgAlt: 'paywall:imagesAlt:generic',
    benefits: [tliAccess, globalEquity, insightsStars6to8],
  },
  [MODAL_TYPES.DEFAULT_PRO]: {
    imgSrc: '/app/assets/paywall/generic.png',
    webpSrc: '/app/assets/paywall/generic.webp',
    imgAlt: 'paywall:imagesAlt:generic',
    benefits: [
      'paywall:features:fixedIncomeAndMore',
      'paywall:features:customScenario',
    ],
  },
  [MODAL_TYPES.MARKET_INDICATORS]: {
    imgSrc: '/app/assets/paywall/tli.png',
    webpSrc: '/app/assets/paywall/tli.webp',
    imgAlt: 'paywall:imagesAlt:telescope',
    benefits: [tliAccess, 'paywall:features:tliInteract'],
  },
  [MODAL_TYPES.STARS_6_TO_8]: {
    imgSrc: '/app/assets/paywall/stairs-to-star.png',
    webpSrc: '/app/assets/paywall/stairs-to-star.webp',
    imgAlt: 'paywall:imagesAlt:stars',
    benefits: [
      insightsStars6to8,
      tliAccess,
      globalEquity,
      'paywall:features:crypto',
    ],
  },
  [MODAL_TYPES.CRYPTO]: {
    imgSrc: '/app/assets/paywall/crypto.png',
    webpSrc: '/app/assets/paywall/crypto.webp',
    imgAlt: 'paywall:imagesAlt:goldCoins',
    benefits: [
      'paywall:features:cryptoInsights',
      'paywall:features:livePrices',
    ],
  },
  [MODAL_TYPES.EXPLORE_FILTERS]: {
    imgSrc: '/app/assets/paywall/rocket-takeoff.png',
    webpSrc: '/app/assets/paywall/rocket-takeoff.webp',
    imgAlt: 'paywall:imagesAlt:rocket',
    benefits: [
      'paywall:features:allFilters',
      insightsStars6to8,
      'paywall:features:savedFilters',
    ],
  },
  [MODAL_TYPES.SAVED_FILTERS]: {
    imgSrc: '/app/assets/paywall/rocket-takeoff.png',
    webpSrc: '/app/assets/paywall/rocket-takeoff.webp',
    imgAlt: 'paywall:imagesAlt:rocket',
    benefits: [
      'paywall:features:savedFilters',
      'paywall:features:allFilters',
      insightsStars6to8,
    ],
  },
  [MODAL_TYPES.BOOKMARKS]: {
    imgSrc: '/app/assets/paywall/watchlist.png',
    webpSrc: '/app/assets/paywall/watchlist.webp',
    imgAlt: 'paywall:imagesAlt:watchlist',
    benefits: [
      'paywall:features:bookmarks',
      'paywall:features:unlimitedInsights',
      insightsStars6to8,
    ],
  },
  [MODAL_TYPES.BEYOND_STOCKS]: {
    imgSrc: '/app/assets/paywall/commodities.png',
    webpSrc: '/app/assets/paywall/commodities.webp',
    imgAlt: 'paywall:imagesAlt:commodities',
    benefits: [
      'paywall:features:fixedIncomeAndMore',
      'paywall:features:customScenario',
    ],
  },
  [MODAL_TYPES.AI_NEWS]: {
    imgSrc: '/app/assets/paywall/ai_news.png',
    webpSrc: '/app/assets/paywall/ai_news.webp',
    imgAlt: 'paywall:imagesAlt:aiNews',
    benefits: ['paywall:features:aiNews'],
  },
  [MODAL_TYPES.GEOGRAPHY_OUTSIDE_US]: {
    imgSrc: '/app/assets/paywall/globe.png',
    webpSrc: '/app/assets/paywall/globe.webp',
    imgAlt: 'paywall:imagesAlt:globe',
    benefits: [
      'paywall:features:globalInsights',
      'paywall:features:globalRegions',
    ],
  },
  [MODAL_TYPES.WATCHLIST_LIMIT]: {
    imgSrc: '/app/assets/paywall/watchlist.png',
    webpSrc: '/app/assets/paywall/watchlist.webp',
    imgAlt: 'paywall:imagesAlt:watchlist',
    benefits: ['paywall:features:unlimitedWatchlist'],
  },
  [MODAL_TYPES.CHART_PAGE]: {
    imgSrc: '/app/assets/paywall/chart.png',
    webpSrc: '/app/assets/paywall/chart.webp',
    imgAlt: 'paywall:imagesAlt:chart',
    benefits: [
      insightsStars6to8,
      tliAccess,
      globalEquity,
      'paywall:features:crypto',
    ],
  },
  [MODAL_TYPES.ARTICLE_LIMIT]: {
    imgSrc: '/app/assets/paywall/insights.png',
    webpSrc: '/app/assets/paywall/insights.webp',
    imgAlt: 'paywall:imagesAlt:insights',
    benefits: ['paywall:features:unlimitedInsights', insightsStars6to8],
  },
};
