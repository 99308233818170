import { Icon } from '@toggle/design-system';
import { useIsomorphicLayoutEffect } from '@toggle/helpers/src/hooks/use-isomorphic-layout-effect/useIsomorphicLayoutEffect';
import React, {
  Children,
  cloneElement,
  createRef,
  FC,
  MouseEvent,
  MouseEventHandler,
  ReactElement,
  TouchEvent,
  useEffect,
  useRef,
  useState,
} from 'react';

import * as S from './ScrollingMenu.styles';
import {
  findItem,
  hasRefs,
  isWithinContainer,
} from './utils/scrolling-menu-utils';

export interface ScrollingMenuProps {
  className?: string;
  children: ReactElement[];
  activeIdx?: number;
  showArrows?: boolean;
  scrollBehavior?: ScrollBehavior;
}

const MIN_THRESHOLD = 5;

export const ScrollingMenu: FC<ScrollingMenuProps> = ({
  className,
  children,
  activeIdx = -1,
  showArrows = true,
  scrollBehavior = 'auto',
}) => {
  const ref = useRef<HTMLUListElement>(null);
  const initialPosition = useRef({ scrolled: 0, clientX: 0 });
  const isMoving = useRef(false);
  const canMove = useRef(false);
  const enableClick = useRef(true);
  const [isRightChevronVisible, setIsRightChevronVisible] = useState(false);
  const [isLeftChevronVisible, setIsLeftChevronVisible] = useState(false);
  const itemsRefs = children.map(() => createRef<HTMLLIElement>());
  const lastActiveIdx = useRef<number>();

  useIsomorphicLayoutEffect(() => {
    if (activeIdx === -1 || lastActiveIdx.current === activeIdx) {
      return;
    }
    scrollToItem(activeIdx, scrollBehavior);
  }, [activeIdx]);

  useEffect(() => {
    if (showArrows && ref.current) {
      const isScrolledToEnd =
        ref.current.scrollLeft + ref.current.clientWidth >=
        ref.current.scrollWidth;

      setIsLeftChevronVisible(ref.current.scrollLeft > 0);
      setIsRightChevronVisible(!isScrolledToEnd);
    }
  }, [showArrows]);

  const onMouseDown = (
    e: MouseEvent<HTMLUListElement> | TouchEvent<HTMLUListElement>
  ) => {
    if (!ref.current) {
      return;
    }

    initialPosition.current.clientX =
      'touches' in e ? e.touches[0].clientX : e.clientX;
    initialPosition.current.scrolled = ref.current.scrollLeft || 0;
    canMove.current = true;
  };

  const onMouseMove = (
    e: MouseEvent<HTMLUListElement> | TouchEvent<HTMLUListElement>
  ) => {
    const x =
      initialPosition.current.clientX -
      ('touches' in e ? e.touches[0].clientX : e.clientX);
    if (!canMove.current || !ref.current || Math.abs(x) <= MIN_THRESHOLD) {
      return;
    }

    isMoving.current = true;
    ref.current.scrollTo(initialPosition.current.scrolled + x, 0);
    setIsLeftChevronVisible(false);
    setIsRightChevronVisible(false);
  };

  const onMouseUp = () => {
    if (!ref.current || !canMove.current) {
      return;
    }

    canMove.current = false;
    isMoving.current = false;

    const isScrolledToEnd =
      ref.current.scrollLeft + ref.current.clientWidth >=
      ref.current.scrollWidth;

    setIsLeftChevronVisible(ref.current.scrollLeft > 0);
    setIsRightChevronVisible(!isScrolledToEnd);
  };

  const onMouseEnter = () => {
    if (!ref.current) {
      return;
    }

    const isScrolledToEnd =
      ref.current.scrollLeft + ref.current.clientWidth >=
      ref.current.scrollWidth;

    setIsLeftChevronVisible(ref.current.scrollLeft > 0);
    setIsRightChevronVisible(!isScrolledToEnd);
  };

  const onItemMouseUp = () => {
    enableClick.current = !isMoving.current;
  };

  const onItemClick =
    (onClick: MouseEventHandler<HTMLElement>, idx: number) =>
    (e: MouseEvent<HTMLElement>) => {
      scrollToItem(idx, scrollBehavior);
      lastActiveIdx.current = idx;

      if (enableClick.current && onClick) {
        onClick(e);
      }
    };

  const scrollToItem = (idx: number, behavior: ScrollBehavior) => {
    if (!ref.current) {
      return;
    }

    const el = ref.current.children[idx] as HTMLLIElement;
    if (isWithinContainer(el, ref.current)) {
      return;
    }

    const inCenter =
      el.offsetLeft - (ref.current.offsetWidth - el.offsetWidth) / 2;
    ref.current.scrollTo({
      left: inCenter,
      behavior,
    });
  };

  const onChevronClick = (direction: 'left' | 'right') => {
    if (!ref.current || !hasRefs(itemsRefs)) {
      return;
    }

    let isRightVisible = direction === 'left',
      isLeftVisible = direction === 'right',
      left;
    const containerWidth = ref.current.clientWidth;
    const scrollLeft = ref.current.scrollLeft;
    const chip = findItem({ itemsRefs, direction, containerWidth, scrollLeft });

    if (direction === 'right') {
      left = chip.current.offsetLeft;
      isRightVisible = left + containerWidth <= ref.current.scrollWidth;
    } else {
      left =
        chip.current.offsetLeft - (containerWidth - chip.current.clientWidth);
      isLeftVisible = left > 0;
    }

    ref.current.scrollTo({
      left,
      behavior: 'smooth',
    });
    canMove.current = false;
    setIsLeftChevronVisible(isLeftVisible);
    setIsRightChevronVisible(isRightVisible);
  };

  return (
    <S.Container className={className}>
      <S.ItemsContainer
        ref={ref}
        onMouseDown={onMouseDown}
        onMouseMove={onMouseMove}
        onMouseUp={onMouseUp}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseUp}
        onTouchStart={onMouseDown}
        onTouchMove={onMouseMove}
        onTouchEnd={onMouseUp}
        data-testid="scrolling-menu-items-container"
      >
        {Children.map(children, (item, idx) => (
          <li ref={itemsRefs[idx]} data-testid="scrolling-menu-item">
            {cloneElement(item, {
              onMouseUp: onItemMouseUp,
              onClick: onItemClick(item.props.onClick, idx),
            })}
          </li>
        ))}
      </S.ItemsContainer>
      {showArrows && isLeftChevronVisible && (
        <S.Chevron
          isScrolledToEnd
          onClick={() => onChevronClick('left')}
          data-testid="chevron-left"
        >
          <Icon iconName={'ChevronBoldLeft'} size={16} />
        </S.Chevron>
      )}
      {showArrows && isRightChevronVisible && (
        <S.Chevron
          isScrolledToEnd={false}
          onClick={() => onChevronClick('right')}
          data-testid="chevron-right"
        >
          <Icon iconName={'ChevronBoldRight'} size={16} />
        </S.Chevron>
      )}
    </S.Container>
  );
};
