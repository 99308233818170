import { AccordionItem } from '@toggle/design-system';
import { TFunction } from 'i18next';
import debounce from 'lodash/debounce';
import { useEffect, useState } from 'react';

import { QUERY_DEBOUNCE_OPTS, QUERY_DELAY } from '~/config/constants';
import { POJSObject } from '~/declarations/standard';

import { GLOSSARY_TRANSLATION_KEY_ITEMS } from '../../HelperConfig';

interface Records {
  name: string;
  definition: string;
}
interface GlossaryDivision {
  alphabet: string;
  record: Records[];
}

export const useMapGlossaryItems = (t: TFunction, searchString: string) => {
  const [glossarySections, setGlossarySections] = useState<AccordionItem[]>([]);
  const [translatedGlossaryItems, setTranslatedGlossaryItems] = useState<
    Records[]
  >([]);
  const [filteredGlossaryItems, setFilteredGlossaryItems] = useState<Records[]>(
    translatedGlossaryItems
  );

  const filteringItems = (translatedItems: Records[]) => {
    if (searchString) {
      const filteredItems = translatedItems.filter(
        translatedItem =>
          translatedItem.name
            .toLowerCase()
            .indexOf(searchString.toLowerCase()) !== -1 ||
          translatedItem.definition
            .toLowerCase()
            .indexOf(searchString.toLowerCase()) !== -1
      );
      setFilteredGlossaryItems(filteredItems);
    } else {
      setFilteredGlossaryItems(translatedItems);
    }
  };
  const debouncedFilteringItems = debounce(
    filteringItems,
    QUERY_DELAY,
    QUERY_DEBOUNCE_OPTS
  );

  useEffect(() => {
    const translatedItems = GLOSSARY_TRANSLATION_KEY_ITEMS.map(glossaryItem => {
      return {
        name: t(`helperWidget:glossary.items.${glossaryItem}.name`),
        definition: t(`helperWidget:glossary.items.${glossaryItem}.definition`),
      };
    });

    const alphabeticallyGlossaryItems = [...translatedItems].sort((a, b) =>
      a.name.localeCompare(b.name, 'en', { sensitivity: 'base' })
    );

    setTranslatedGlossaryItems(alphabeticallyGlossaryItems);
  }, []);

  useEffect(() => {
    if (searchString) {
      debouncedFilteringItems(translatedGlossaryItems);
    } else {
      filteringItems(translatedGlossaryItems);
    }

    return () => debouncedFilteringItems.cancel();
  }, [searchString, translatedGlossaryItems]);

  useEffect(() => {
    const groupedByAlphabeticallyGlossary = filteredGlossaryItems.reduce<
      POJSObject<GlossaryDivision>
    >((result, glossaryItem: Records) => {
      const alphabet = glossaryItem.name[0];

      if (!result[alphabet]) {
        result[alphabet] = { alphabet, record: [glossaryItem] };
      } else {
        result[alphabet].record.push(glossaryItem);
      }

      return result;
    }, {});

    const glossaryItems = Object.values(groupedByAlphabeticallyGlossary);

    const glossarySections = glossaryItems.reduce<AccordionItem[]>(
      (result, glossaryItem: GlossaryDivision) => {
        const emptyRecordsCondition =
          glossaryItem.record.length === 1 &&
          !glossaryItem.record[0].definition;

        const masterSection: AccordionItem = {
          id: glossaryItem.alphabet,
          title: glossaryItem.alphabet,
        };

        const slaveSections: AccordionItem[] = !emptyRecordsCondition
          ? glossaryItem.record.map(record => {
              return {
                id: record.name,
                title: record.name,
                content: record.definition,
              };
            })
          : [];

        return [...result, masterSection, ...slaveSections];
      },
      []
    );

    setGlossarySections(glossarySections);
  }, [filteredGlossaryItems]);

  return glossarySections;
};
