import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import * as S from '../Content.styles';

export function TopScreensContent() {
  const { t } = useTranslation('helperWidget');
  return (
    <S.Content>
      <S.Paragraph>
        {t('helperWidget:explore.content.topScreens.firstParagraph')}
      </S.Paragraph>
      <S.Paragraph>
        <Trans
          i18nKey="helperWidget:explore.content.topScreens.secondParagraph"
          components={[
            <S.MiddleAlignedIcon
              iconName="Fire"
              size={18}
              fillColor="var(--red--1)"
              key=""
            />,
          ]}
        />
      </S.Paragraph>
      <S.Paragraph>
        <Trans
          i18nKey="helperWidget:explore.content.topScreens.thirdParagraph"
          components={[
            <S.MiddleAlignedIcon
              iconName="ShowPassword"
              size={18}
              fillColor="var(--primary-color)"
              key=""
            />,
          ]}
        />
      </S.Paragraph>
    </S.Content>
  );
}
