import React from 'react';
import { useTranslation } from 'react-i18next';

import { BaseFaqContentProps } from '../content.d';
import * as S from './FaqHelperContent.styles';

export function BaseFaqContent({ tKey }: BaseFaqContentProps) {
  const { t } = useTranslation('helperWidget');

  return <S.FaqContent>{t(tKey)}</S.FaqContent>;
}
