import { Button, deviceConstraints, typography } from '@toggle/design-system';
import styled from 'styled-components';

export const ZendeskCustomLauncher = styled(Button)`
  width: calc(100% - 24px - 24px);
  position: absolute;
  bottom: 25px;
  z-index: calc(var(--z-index-app-header) - 1);
  margin: auto;
  text-align: center;
  border: none;
  justify-content: center;
  height: 40px;

  background-color: var(--primary-color);
  ${typography.emu};

  @media ${deviceConstraints.desktopSmall} {
    bottom: 16px;
  }
`;
