import { customScrollMinimal, TabLines } from '@toggle/design-system';
import styled from 'styled-components';

export const TabsLinesContainer = styled.div`
  overflow-y: scroll;
  ${customScrollMinimal};

  ${TabLines.Styled.Header} {
    justify-content: center;
  }
  ${TabLines.Styled.Tab} {
    &:last-child {
      margin-right: 0px;
    }
  }
  ${TabLines.Styled.TabPanel} {
    overflow-y: auto;
    padding: 12px 0px;
    ${customScrollMinimal};
  }
`;
