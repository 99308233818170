import {
  breakpoints,
  deviceConstraints,
  Dropdown,
  Input,
} from '@toggle/design-system';
import styled, { css } from 'styled-components';

export const BottomSideArea = styled.div`
  position: relative;
  height: 100%;
  background-color: rgba(var(--base--100-rgb), 0.8);
`;

export const DesktopOverlay = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: var(--z-index-app-header);
`;

interface SearchAreaProps {
  isMobile: boolean;
  showSearchTabs: boolean;
}

const getStyles = (props: SearchAreaProps) => {
  if (props.isMobile) {
    return css`
      position: fixed;
      top: '0px';
      width: 100%;
      height: 100%;
      z-index: var(--z-index-app-header);
    `;
  }

  return css`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 560px;
    @media ${breakpoints.md} {
      width: 640px;
    }

    height: ${props.showSearchTabs ? '434px' : '360px'};
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
    border-radius: 8px 8px 0px 0px;
  `;
};

export const SearchArea = styled.div<SearchAreaProps>`
  display: flex;
  flex-direction: column;
  background-color: var(--base--100);
  ${props => getStyles(props)}

  ${Dropdown.Styled.Container} {
    overflow: hidden;
  }

  ${Dropdown.Styled.DropdownListContainer} {
    position: relative;
    top: 0;
    max-height: ${props => {
      const tabSize = props.showSearchTabs ? '352px' : '312px';
      return !props.isMobile ? tabSize : 'none';
    }};
  }

  ${Input.Styles.InputWrapper} {
    padding: 0;
    height: 40px;
    @media ${deviceConstraints.tablet} {
      height: 48px;
    }
    border: none;
    background: transparent;
    border-bottom: 1px solid var(--base--75);
    border-radius: 0px;
    font-weight: 500;

    ${({ isMobile }) =>
      isMobile &&
      css`
        border-radius: 20px;
        border-bottom: none;
        background-color: var(--base--90);
      `}

    ${Input.Styles.InputElement} {
      background: none;
      padding-left: 20px;
    }
  }

  ${Input.Styles.Container} {
    ${({ isMobile }) =>
      isMobile &&
      css`
        padding: 10px 12px;
        border-bottom: 1px solid var(--base--70);
      `}
  }
`;
