import { create as _create, StateCreator } from 'zustand';
import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';

// Taken from zustand docs: https://docs.pmnd.rs/zustand/guides/how-to-reset-state
const resetters: (() => void)[] = [];

export const create = (<T>(f: StateCreator<T> | undefined) => {
  if (f === undefined) {
    return create;
  }
  const store = createWithEqualityFn(f, shallow);
  const initialState = store.getState();
  resetters.push(() => {
    store.setState(initialState, true);
  });
  return store;
}) as typeof _create;

export const resetStores = () => {
  for (const resetter of resetters) {
    resetter();
  }
};
