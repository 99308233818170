import { compareObjectKeys } from '@toggle/helpers';

import { BaseChartAPIProps } from '~/types/create.types';
import {
  DOMAIN_CHANGE_CUSTOM_EVENT,
  DomainChangeEvent,
  GUTTER_SIZE_CUSTOM_EVENT,
  GutterSizeEvent,
  HOVER_CUSTOM_EVENT,
  HoverEvent,
  INSIGHTS_IN_DOMAIN_CHANGE_CUSTOM_EVENT,
  InsightsInDomainEvent,
  PRICE_HOVER_CUSTOM_EVENT,
  PriceHoverEvent,
  RIGHT_CLICK_EVENT,
  RightClickEvent,
} from '~/types/events.types';
import {
  ChartAssetData,
  InsightsInDomain,
  TimeSeriesItem,
} from '~/types/timeseries.types';
import { isWithinYAxis } from '~/utils/axis/axis-utils';
import { TURBO_CHART_INNER_PADDING } from '~/utils/constants';
import { showZoomInToViewText } from '~/utils/dates';
import { getInsightsInDomain } from '~/utils/insights/insights';
import { getActivePanes, getPaneDetails } from '~/utils/pane/pane-utils';

export const dispatchDomainChangeEvent = (
  base: BaseChartAPIProps,
  isSameDomain: boolean
) => {
  const roundedDomain = [Math.ceil(base.domain[0]), Math.floor(base.domain[1])];
  base.canvasElement.dispatchEvent(
    new CustomEvent(DOMAIN_CHANGE_CUSTOM_EVENT, {
      detail: {
        domain: [base.domain[0], base.domain[1]],
        roundedDomain,
        isSameDomain,
      },
    }) as DomainChangeEvent
  );
};

export const dispatchInsightsInDomainEvent = (base: BaseChartAPIProps) => {
  const { domain, x, options, primaryAsset } = base;
  const showHelperText = showZoomInToViewText(primaryAsset.ts, domain);
  const helperText = showHelperText
    ? {
        right: options.width - options.gutters.y - x.xScale.range()[1] / 2,
        y: options.height - options.gutters.x - TURBO_CHART_INNER_PADDING,
      }
    : null;
  const insightsInDomain = showHelperText
    ? []
    : getActivePanes(base.panes).reduce((insights, chartPane) => {
        if (chartPane.y.length) {
          insights.push(
            ...getInsightsInDomain({
              primaryAsset,
              domain,
              x,
              y: chartPane.y,
              insights: chartPane.insights,
              width: base.options.width,
            })
          );
        }

        return insights;
      }, [] as InsightsInDomain[]);
  base.canvasElement.dispatchEvent(
    new CustomEvent(INSIGHTS_IN_DOMAIN_CHANGE_CUSTOM_EVENT, {
      detail: { insightsInDomain, helperText },
    }) as InsightsInDomainEvent
  );
};

interface DispatchPriceHoverEventProps {
  base: BaseChartAPIProps;
  entity: ChartAssetData['entity'];
  value?: TimeSeriesItem;
  previousValue?: TimeSeriesItem;
}

export const dispatchPriceHoverEvent = ({
  base,
  entity,
  value,
  previousValue,
}: DispatchPriceHoverEventProps) => {
  base.canvasElement.dispatchEvent(
    new CustomEvent(PRICE_HOVER_CUSTOM_EVENT, {
      detail: {
        currentPrice: value,
        previousPrice: previousValue,
        entitySnake: entity.default_snake,
      },
    }) as PriceHoverEvent
  );
};

export const dispatchGutterSizeEvent = (
  base: BaseChartAPIProps,
  previousBase?: BaseChartAPIProps
) => {
  if (
    previousBase &&
    compareObjectKeys(base, previousBase, [
      'options.gutters.x',
      'options.gutters.y',
      'panes',
    ])
  ) {
    return;
  }

  const { panes, options } = base;
  const paneLimitExceeded = panes.length >= options.config.paneMaxCount;
  base.canvasElement.dispatchEvent(
    new CustomEvent(GUTTER_SIZE_CUSTOM_EVENT, {
      detail: {
        x: base.options.gutters.x,
        y: base.options.gutters.y,
        paneLimitExceeded,
        panes,
        yAxisSizes: base.yAxisSizes,
      },
    }) as GutterSizeEvent
  );
};

export const dispatchRightClickEvent = ({
  base,
  mouseX,
  mouseY,
  isWithinYAxis,
}: {
  base: BaseChartAPIProps;
  mouseX: number;
  mouseY: number;
  isWithinYAxis: boolean;
}) => {
  base.canvasElement.dispatchEvent(
    new CustomEvent<RightClickEvent>(RIGHT_CLICK_EVENT, {
      detail: {
        mouseX,
        mouseY,
        isWithinYAxis,
      },
    })
  );
};

export const dispatchHoverEvent = (
  event: MouseEvent,
  base: BaseChartAPIProps
) => {
  const detail: HoverEvent = {
    isWithinYAxis: isWithinYAxis(event, base.options),
    paneDetails: getPaneDetails(event, base),
  };

  base.canvasElement.dispatchEvent(
    new CustomEvent<HoverEvent>(HOVER_CUSTOM_EVENT, {
      detail,
    })
  );
};
