import React from 'react';

import * as S from './SplashLoader.styles';

export function SplashLoader() {
  return (
    <S.SplashLoader data-testid="splash-loader">
      <S.Line />
    </S.SplashLoader>
  );
}
