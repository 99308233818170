import { z } from 'zod';

const tagsSchema = z.object({
  tags: z.array(z.string()),
});

export const entities = {
  resolveFromCsv: {
    path: 'entity-resolver/v1/csv',
    schema: tagsSchema,
  },
  searchEntityAndEconomies: { path: 'entity/v3/search/entities-economy' },
  entityLookup: { path: `entity/v3/lookup` },
} as const;
