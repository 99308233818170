import { Icon } from '@toggle/design-system';
import { TFunction } from 'i18next';
import React, { FC } from 'react';

import * as S from './ArticleCardHeader.styles';

export interface ArticleCardHeaderProps {
  t: TFunction;
  stars: number;
  tag: string;
  sector: string;
  increase?: boolean;
  hot?: boolean;
  newArticle: boolean;
  className?: string;
  locked?: boolean;
}

export const ArticleCardHeader: FC<ArticleCardHeaderProps> = ({
  t,
  stars,
  increase,
  className,
  tag,
  sector,
  hot,
  newArticle,
  locked,
}) => (
  <S.Header className={className}>
    <S.LeftContainer>
      <S.StarsContainer>
        <Icon iconName="Rating" fillColor="var(--warning--default)" size={19} />
        <span>{stars}</span>
      </S.StarsContainer>
      <S.Dot />
      {locked ? (
        <Icon iconName="Lock" size={18} fillColor="var(--copilot--default)" />
      ) : (
        <Icon
          iconName={increase ? 'ArrowUp' : 'ArrowDown'}
          fillColor={increase ? 'var(--viz-bullish)' : ' var(--viz-bearish)'}
          size={15}
          data-testid="direction-indicator"
        />
      )}
      <S.Dot />
      {!!hot && <S.FireIcon data-testid="article-hot" />}
      <S.Tag>{tag}</S.Tag>
      {newArticle && (
        <S.NewBadge data-testid="article-new">
          <span>{t('explore:header.newLabel')}</span>
        </S.NewBadge>
      )}
    </S.LeftContainer>
    <S.Sector sector={sector.toLocaleLowerCase().replace(/ /gi, '_')}>
      {sector}
    </S.Sector>
  </S.Header>
);
