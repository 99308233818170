import { Entity } from '~/api/entities/entity-schema';
import { ENDPOINT_URLS } from '~/global/toggle-api';
import { APIFetch } from '~/shared/services/api-fetch/APIFetch';

export const fetchUserEntities = async () => {
  try {
    const res = await APIFetch<UserEntitiesAPIResponse>(
      ENDPOINT_URLS.USER_ENTITIES_V3
    );
    if (res instanceof Error) {
      throw res;
    }
    return {
      watchlist: res.watchlist,
      portfolios: res.portfolio.map(p => p.localized_entity),
    };
  } catch {
    return { watchlist: [], portfolios: [] };
  }
};

interface UserEntitiesAPIResponse {
  watchlist: Entity[];
  portfolio: UserEntitiesPortfolio[];
}

interface UserEntitiesPortfolio {
  localized_entity: Entity;
  portfolios: string[];
}
