import { getOffset } from '@toggle/helpers';

import { config } from '~/config';
import { APIFetch } from '~/shared/services/api-fetch/APIFetch';
import { validateApiFetch } from '~/shared/services/api-fetch/validate-api-fetch/validateApiFetch';
import { SignUpProps } from '~/stores/use-auth/useAuth';

import { HttpMethods } from '../../shared/services/api-fetch/constants';
import { UserRole } from '../subscription/subscription-schema';
import {
  AuthDataPayload,
  AuthDataTwoFactorPayload,
  authSchema,
  ChangePasswordResponse,
  ChangePasswordResponseSchema,
  CheckPasswordData,
  CheckPasswordDataSchema,
  CheckResetPasswordTokenResponse,
  checkResetPasswordTokenSchema,
  LoginValidationResponse,
  LoginValidationResultSchema,
  ResetPasswordResponse,
  resetPasswordSchema,
} from './auth-services-schema';

const {
  api: { root: apiRoot, authRoot },
} = config;
const userRoot = `${apiRoot}u/v1`;

export const authEndpoint = {
  signInV4: `${authRoot}v4/u/login`,
  getToken: `${authRoot}v3/uj`,
  refreshToken: `${authRoot}v2/u/refresh`,
  logout: `${authRoot}v2/u/logout`,
  register: `${userRoot}/user`,
  verifyEmail: `${userRoot}/verify-email`,
  sendVerifyEmail: `${userRoot}/send-verify-email`,
  validateEmail: `${userRoot}/validate-email`,
  requestResetPassword: `${userRoot}/request-password-reset`,
  resetPassword: `${userRoot}/reset-password`,
  checkPasswordResetToken: `${userRoot}/check-password-reset-token`,
  updatePassword: `${authRoot}v1/u`,
  logoutAll: `${authRoot}v1/u/logout-all`,
  checkPassword: `${authRoot}v1/u/check-password`,
};

interface RequestResetPasswordData {
  email: string;
}

export interface ResetPasswordData {
  password: string;
  confirmedPassword: string;
  token: string;
  logoutAll?: boolean;
}

export interface AuthData {
  user_id: string;
  user_role: string;
  subscription?: UserRole;
  email: string;
  source: number;
  expiry: string;
  token: string;
}

export interface AuthDataTwoFactor {
  user_id: string;
  two_factor_methods: TwoFactorMethod[];
}

export interface ChangePasswordRequest {
  email: string;
  old_password: string;
  new_password: string;
  logout_all?: boolean;
}

export enum TwoFactorMethod {
  Totp = 'totp',
  Email = 'email',
  Backup = 'backup',
}

export type LoginProps =
  | {
      username: string;
      password: string;
      two_factor_code?: never;
      two_factor_method?: never;
    }
  | {
      username: string;
      password: string;
      two_factor_code: string;
      two_factor_method: TwoFactorMethod;
    };

export const postLogin = (request: LoginProps) => {
  return APIFetch<AuthDataPayload | AuthDataTwoFactorPayload>(
    authEndpoint.signInV4,
    {
      method: HttpMethods.Post,
      credentials: 'include',
      body: JSON.stringify(request),
    }
  );
};

export const getAuthToken = () => {
  return validateApiFetch<AuthDataPayload>({
    schema: authSchema,
    endpoint: authEndpoint.getToken,
    requestInfo: {
      credentials: 'include',
    },
  });
};

export const getRefreshToken = () => {
  return validateApiFetch<AuthDataPayload>({
    endpoint: authEndpoint.refreshToken,
    schema: authSchema,
    requestInfo: {
      credentials: 'include',
    },
  });
};

export const postLogout = () => {
  return validateApiFetch<AuthDataPayload>({
    endpoint: authEndpoint.logout,
    requestInfo: {
      method: HttpMethods.Post,
      credentials: 'include',
    },
  });
};

export const postRegister = (userDetails: SignUpProps) => {
  const {
    firstName,
    documentIds,
    lastName,
    email,
    password,
    promoCode,
    referralCode,
  } = userDetails;

  const requestBody = {
    email,
    password,
    username: email,
    first_name: firstName,
    last_name: lastName || firstName,
    ...(promoCode && { promo_code: promoCode }),
    ...(referralCode && { referral_code: referralCode }),
    language: 'en',
    document_ids: documentIds,
    tz_offset: getOffset(),
  };

  return validateApiFetch({
    endpoint: authEndpoint.register,
    requestInfo: {
      method: HttpMethods.Post,
      body: JSON.stringify(requestBody),
    },
  });
};

export const postReqResetPassword = ({
  email,
}: RequestResetPasswordData): Promise<ResetPasswordResponse> => {
  return validateApiFetch<ResetPasswordResponse>({
    endpoint: authEndpoint.requestResetPassword,
    schema: resetPasswordSchema,
    requestInfo: {
      method: HttpMethods.Post,
      body: JSON.stringify({ email }),
    },
  });
};

export const postResetPassword = (
  passwordDetails: ResetPasswordData
): Promise<ResetPasswordResponse> => {
  const { token, password, confirmedPassword, logoutAll } = passwordDetails;
  return validateApiFetch<ResetPasswordResponse>({
    endpoint: authEndpoint.resetPassword,
    schema: resetPasswordSchema,
    requestInfo: {
      method: HttpMethods.Post,
      body: JSON.stringify({
        token,
        password,
        verify_password: confirmedPassword,
        logout_all: logoutAll,
      }),
    },
  });
};

export const postCheckResetPasswordToken = (
  token: string
): Promise<CheckResetPasswordTokenResponse> => {
  return validateApiFetch<CheckResetPasswordTokenResponse>({
    endpoint: authEndpoint.checkPasswordResetToken,
    schema: checkResetPasswordTokenSchema,
    requestInfo: {
      method: HttpMethods.Post,
      body: JSON.stringify({ token }),
    },
  });
};

export const postLoginValidation = (email: string, signal?: AbortSignal) => {
  return validateApiFetch<LoginValidationResponse>({
    endpoint: authEndpoint.validateEmail,
    schema: LoginValidationResultSchema,
    requestInfo: {
      method: HttpMethods.Post,
      body: JSON.stringify({ email }),
      signal,
    },
  });
};

export const postUpdatePassword = (passwordPayload: ChangePasswordRequest) => {
  return validateApiFetch<ChangePasswordResponse>({
    endpoint: authEndpoint.updatePassword,
    schema: ChangePasswordResponseSchema,
    requestInfo: {
      method: HttpMethods.Post,
      body: JSON.stringify(passwordPayload),
    },
  });
};

export const postSendVerifyEmail = (email: string) => {
  return validateApiFetch({
    endpoint: authEndpoint.sendVerifyEmail,
    requestInfo: {
      method: HttpMethods.Post,
      body: JSON.stringify({ email }),
    },
  });
};

export const postVerifyEmail = (token: string) => {
  return validateApiFetch({
    endpoint: authEndpoint.verifyEmail,
    requestInfo: {
      method: HttpMethods.Post,
      body: JSON.stringify({ token }),
    },
  });
};

export const postCheckPassword = (username: string, password: string) => {
  return validateApiFetch<CheckPasswordData>({
    endpoint: authEndpoint.checkPassword,
    schema: CheckPasswordDataSchema,
    requestInfo: {
      method: HttpMethods.Post,
      credentials: 'include',
      body: JSON.stringify({
        username,
        password,
      }),
    },
  });
};
