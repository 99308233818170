import { usePrevious, usePreviousMemo } from '@toggle/helpers';
import * as d3 from 'd3';
import React, {
  createContext,
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { Class } from '~/api/entities/entity-constants';
import { SnakeMeta, TSDatum } from '~/api/timeseries/timeseries-schema';
import { OptionalN, OptionalU } from '~/declarations/standard';
import { MappedNews, NewsItem } from '~/declarations/toggle-api.d';
import { ArticleDirection } from '~/declarations/toggle-api-enums';
import { TSPoint } from '~/shared/components/chart/Chart';
import { IndexedTsPoint } from '~/shared/components/snake-chart/SnakeChart';
import { SplashScreen } from '~/shared/components/splash-screen/SplashScreen';
import { getSplashScreenData } from '~/shared/components/splash-screen/utils/splashScreenConstants';
import { useTouchDevice } from '~/shared/hooks/MediaQueryHook';
import { HookState } from '~/shared/hooks/use-api/useAPI-types';
import {
  ChartDomain,
  useDateRange,
} from '~/shared/hooks/use-date-range/useDateRange';
import { MappedEntity } from '~/shared/hooks/use-entities';
import { NEWS_TYPE, useNews } from '~/shared/hooks/use-news';
import { globalPriceFormatter } from '~/shared/utils/asset-price/assetPrice';
import { isEntityFromUS } from '~/shared/utils/entity-from-usa/isEntityFromUS';
import { useSnakeData } from '~/views/analyze/hooks/use-snake-data/useSnakeData';

import { usePastInsight } from '../hooks/use-past-insights/usePastInsights';
import {
  RelatedAssetType,
  useRelatedAssets,
} from '../hooks/use-related-assets';
import { useFeedData } from '../hooks/use-snake-data/useFeedData';
import { ARTICLE_INSIGHTS_START_DATE } from '../overview-constants';
import {
  articleStarsFilter,
  filterInDomain,
  mapArticle,
  mapNews,
  MappedArticle,
} from '../utils/insights-helpers';

export interface HighlightedNewsItem extends MappedNews {
  direction: ArticleDirection;
  index: number;
}

export interface AnalyzeContextData extends ReturnType<typeof useDateRange> {
  ts: TSDatum[];
  meta: OptionalU<SnakeMeta>;
  priceSuffix: OptionalU<string>;
  dateRange: ChartDomain;
  highlightedNews: HighlightedNewsItem[];
  aiNews: NewsItem[];
  relatedAssets: HookState<OptionalU<RelatedAssetType[]>>;
  mappedArticles: MappedArticle[];
  maxZoom: number;
  selectedIconId: string | null;
  setSelectedIconId: React.Dispatch<React.SetStateAction<string | null>>;
  priceFormatter: (val: number, decimals?: number) => string;
  preparedTs: IndexedTsPoint[];
  indexedDateScale: d3.ScaleOrdinal<number, Date, Date>;
  pastInsights: HookState<MappedArticle[]>;
  chartArticleInsights: MappedArticle[];
  showPastInsights: boolean;
  displayInsightsBtnVisible: boolean;
  onSwitchClick: (showPastInsights: boolean) => void;
  onDisplayInsightsBtnClick: () => void;
  yAxisSize?: number;
  setYAxisSize: (size: OptionalU<number>) => void;
  domainDates: null | [Date, Date];
  hoveredChartPoint: TSPoint<number, number> | undefined;
  setHoveredChartPoint: Dispatch<
    SetStateAction<TSPoint<number, number> | undefined>
  >;
}

const AnalyzeContext = createContext<OptionalN<AnalyzeContextData>>(null);
interface AnalyzeStoreProps {
  entity: MappedEntity;
  children: ReactNode;
  shouldHideLoader?: boolean;
}

export const AnalyzeStore: FC<AnalyzeStoreProps> = ({
  entity,
  children,
  shouldHideLoader = false,
}) => {
  const { t } = useTranslation('analyze');
  const prevEntityTag = usePrevious(entity.tag);
  const snakeData = useSnakeData(entity);
  const isTouchDevice = useTouchDevice();
  const { data: news } = useNews({
    type: NEWS_TYPE.AI,
    tickers: [entity.ticker],
    limit: 60,
  });
  const { data: articlesData, loading: articlesLoading } = useFeedData({
    entityTag: entity.tag,
    startDate: ARTICLE_INSIGHTS_START_DATE,
  });
  const relatedAssets = useRelatedAssets(entity.tag, t);

  const [yAxisSize, setYAxisSize] = useState<number>();
  const [selectedIconId, setSelectedIconId] = useState<string | null>(null);
  const [hoveredChartPoint, setHoveredChartPoint] =
    useState<TSPoint<number, number>>();
  const [hideSplashScreen, setHideSplashScreen] = useState(false);
  const dateRange = useDateRange({
    snakeData: snakeData.data?.ts ?? [],
    isTouchDevice,
    paddingRight: isTouchDevice ? 0 : 0.03,
  });

  useEffect(() => {
    setHideSplashScreen(false);
  }, [entity]);

  const maxZoom = useMemo(() => {
    const dateRanges = dateRange.dateRanges;
    if (!dateRanges.length) {
      return Infinity;
    }

    const lastRange = dateRanges[dateRanges.length - 1].range;
    const firstRange = dateRanges[0].range;
    return (lastRange[1] - lastRange[0]) / (firstRange[1] - firstRange[0]);
  }, [dateRange.dateRanges]);

  const priceFormatter = useCallback(
    (price: number, decimals?: number) =>
      globalPriceFormatter(
        {
          entity,
          price,
          priceSuffix: snakeData.data?.priceSuffix ?? '',
        },
        decimals
      ),
    [snakeData.data, entity]
  );

  const aiNews = useMemo<MappedNews[]>(() => {
    if (!snakeData.data?.ts?.length) {
      return [];
    }

    const isUsStock = isEntityFromUS(entity);
    if (
      entity.class === Class.ClassStock &&
      isUsStock &&
      news[entity.ticker]?.length
    ) {
      const { ts } = snakeData.data;
      const lastSnakeDate = new Date(ts[ts.length - 1].index);

      return news[entity.ticker].map(n =>
        mapNews(n, ts, lastSnakeDate.getTime())
      );
    }
    return [];
  }, [news, entity.class, entity.country?.long, snakeData.data?.ts]);

  const highlightedNews = useMemo<HighlightedNewsItem[]>(
    () => aiNews.filter((n): n is HighlightedNewsItem => n.direction !== null),
    [aiNews]
  );

  const preparedTs = useMemo(
    () =>
      (snakeData.data?.ts ?? []).map((p, i) => ({
        index: i,
        date: new Date(p.index),
        value: p.value,
      })),
    [snakeData.data?.ts]
  );

  const {
    onSwitchClick,
    pastInsights,
    showPastInsights,
    onDisplayInsightsBtnClick,
    displayInsightsBtnVisible,
  } = usePastInsight({
    entity,
    domain: dateRange.domainDates,
    articles: articlesData?.result ?? [],
    ts: snakeData.data?.ts ?? [],
    t,
  });

  const mappedArticles = useMemo(() => {
    if (!(articlesData?.result && snakeData.data?.ts.length)) {
      return [];
    }

    const ts = snakeData.data.ts;
    return articlesData?.result
      .filter(articleStarsFilter)
      .map(article => mapArticle({ entity, article, ts, t }));
  }, [articlesData?.result, snakeData.data?.ts]);

  const chartArticleInsights = usePreviousMemo<MappedArticle[]>(
    () => {
      if (!dateRange.dateRange?.domain) {
        return [];
      }

      const domain = dateRange.dateRange?.domain;
      const filteredPast = filterInDomain(domain, pastInsights.data);
      const filteredActive = filterInDomain(
        dateRange.dateRange.domain,
        mappedArticles
      );

      return filteredPast
        .concat(filteredActive)
        .filter((a, idx, arr) => arr.findIndex(art => a.id === art.id) === idx);
    },
    [mappedArticles, pastInsights.data, dateRange.dateRange?.domain],
    (current, prev) => {
      return (
        current.length === prev.length &&
        (current.length === 0 || current[0].id === prev[0].id)
      );
    }
  );

  const storeData = useMemo(
    () => ({
      ts: snakeData.data?.ts ?? [],
      ...dateRange,
      priceFormatter,
      selectedIconId,
      setSelectedIconId,
      meta: snakeData.data?.snakeMeta,
      priceSuffix: snakeData.data?.priceSuffix,
      dateRange: dateRange.dateRange ?? {
        animated: false,
        domain: [0, 0],
        changedByRangeChart: false,
      },
      highlightedNews,
      aiNews,
      relatedAssets,
      mappedArticles,
      maxZoom,
      preparedTs,
      indexedDateScale: dateRange.dateScale,
      onDisplayInsightsBtnClick,
      onSwitchClick,
      pastInsights,
      showPastInsights,
      displayInsightsBtnVisible,
      chartArticleInsights,
      yAxisSize,
      setYAxisSize,
      setHoveredChartPoint,
      hoveredChartPoint,
    }),
    [
      snakeData,
      dateRange,
      news,
      highlightedNews,
      aiNews,
      articlesData,
      maxZoom,
      relatedAssets,
      preparedTs,
      dateRange.dateScale,
      pastInsights,
      showPastInsights,
      displayInsightsBtnVisible,
      yAxisSize,
      hoveredChartPoint,
    ]
  );

  const splashScreenData = getSplashScreenData(entity.asset_class);

  return (entity.tag !== prevEntityTag || !hideSplashScreen) &&
    !shouldHideLoader ? (
    <SplashScreen
      tagDisplayValue={entity.name_short}
      dataIsLoading={snakeData.loading || articlesLoading}
      data={splashScreenData}
      onLoadCompleted={() => setHideSplashScreen(true)}
    />
  ) : (
    <AnalyzeContext.Provider value={storeData}>
      {children}
    </AnalyzeContext.Provider>
  );
};

export const useAnalyzeStore = () => {
  return useContext(AnalyzeContext) as AnalyzeContextData;
};
